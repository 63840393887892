import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaPlus, FaSearch } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';
import { useParams, Link, useNavigate } from 'react-router-dom';

import { Badge, Form, Modal, Progress, Space } from 'antd';
import { TablePaginationConfig, TableProps } from 'antd/lib/table';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';

import CropConditionData from '../../../@types/data/CropConditionData';
import OwnerData from '../../../@types/data/OwnerData';
import PaginationData from '../../../@types/data/PaginationData';
import { ResponseListData } from '../../../@types/data/ResponseData';
import DTOErrorReponse from '../../../@types/dtos/DTOErrorReponse';
import DTOSurvey from '../../../@types/dtos/monitoring/DTOSurvey';
import DTOSurveyStage from '../../../@types/dtos/monitoring/DTOSurveyStage';
import DTOSurveyType from '../../../@types/dtos/monitoring/DTOSurveyType';
import { Breadcrumb, Button, FormItem, IconWithTooltip, Input, ModalStatus, Spin } from '../../../components';
import { TableData } from '../../../compositions';
import { SURVEY_APPROVAL_STATUS_DRAFT } from '../../../configs/constants';
import { Can } from '../../../hooks/ability';
import { usePage } from '../../../hooks/page';
import monitoringApi from '../../../services/api/monitoring';
import { updateData } from '../../../services/table';
import theme from '../../../styles/theme';
import { Formatter } from '../../../utils/Formatter';
import OwnerForm from '../Owner/OwnerForm';
import columns from './columns';
import { SContainer, SFilterContainer, SHeader, STitlePage } from './styles';

export interface PageParams {
  cpr: string;
  registration: string;
  id?: string;
}

const Survey: React.FC = () => {
  const { t, i18n } = useTranslation();
  const params = useParams<any>();
  const [searchForm] = Form.useForm();

  const navigate = useNavigate();
  const { alertStatus } = usePage();

  const [loading, setLoading] = useState(false);
  const [paginationConfig, setPaginationConfig] = useState<TablePaginationConfig>({
    current: 1,
    total: 1,
    pageSize: 20,
  });
  const [data, setData] = useState<DTOSurvey[]>([]);

  const [visibilityApprove, setVisibilityApprove] = useState(false);
  const [loadingApprove, setLoadingApprove] = useState(false);
  const [approveId, setApproveId] = useState('false');

  const loadTableData = useCallback(
    (values: Partial<OwnerForm & PaginationData<OwnerData>>) => {
      setLoading(true);
      const dataSend = {
        params: {
          registration_id: params.registration,
          per_page: paginationConfig.pageSize,
          ...values,
        },
      };
      monitoringApi.survey
        .get(dataSend)
        .then((response: ResponseListData<DTOSurvey>) => {
          const result = response.data.data;

          setData(result.data);
          setPaginationConfig(paginationConfigState => ({
            ...paginationConfigState,
            current: result.current_page,
            total: result.total,
          }));
        })
        .catch((err: DTOErrorReponse) => alertStatus(err, 'error'))
        .finally(() => {
          setLoading(false);
        });
    },
    // eslint-disable-next-line
    [i18n.language, paginationConfig.pageSize, params.registration],
  );

  const handleSuccess = (messageSuccess: string) =>
    ModalStatus({
      type: 'success',
      title: t('modal.success'),
      subTitle: messageSuccess,
    });

  const handleRemoveChange = async (record: any) => {
    ModalStatus({
      type: 'delete',
      title: t('pages.monitoring.survey.delete.title'),
      subTitle: t('pages.monitoring.survey.delete.subtitle'),
      cancelText: t('pages.monitoring.survey.delete.cancel'),
      okText: t('pages.monitoring.survey.delete.confirm'),
      onOk: () => {
        monitoringApi.survey
          .destroy(record.id, {
            params: {},
          })
          .then(response => {
            handleSuccess(response.data.message);
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            handleTableChange({ current: 1 }, { active: [1] }, {}, null);
          })
          .catch((err: DTOErrorReponse) => alertStatus(err, 'error'));
      },
    });
  };

  // Table DATA Config: BEGIN
  const getActions = () => ({
    render: (active: number, record: DTOSurvey) => (
      <>
        <Can I="monitoring.survey.destroy" a="">
          <IconWithTooltip
            action="destroy"
            title={t('pages.monitoring.survey.table.icons.remove')}
            onClick={() => handleRemoveChange(record)}
          />
        </Can>
        <Can I="monitoring.survey.update" a="">
          <IconWithTooltip
            action="edit"
            title={t('pages.monitoring.survey.table.icons.edit')}
            onClick={() => {
              navigate(`/monitoring/cpr/${params.cpr}/registration/${params.registration}/survey/edit/${record.id}`);
            }}
          />
        </Can>
        {record.survey_request && (
          <Link target="_blank" to={`/monitoring/surveyrequest/edit/${record.survey_request.id}`}>
            <IconWithTooltip title={t('pages.monitoring.survey.table.icons.surveyrequest')} action="search" />
          </Link>
        )}

        <Can I="monitoring.survey.approve" a="">
          {record.status_id === SURVEY_APPROVAL_STATUS_DRAFT && (
            <IconWithTooltip
              action="check"
              title={t('pages.monitoring.survey.modalApproval.title')}
              onClick={() => {
                setVisibilityApprove(true);
                setApproveId(record.id);
              }}
            />
          )}
        </Can>

        <Can I="monitoring.survey.index" a="">
          <IconWithTooltip
            action="attachment"
            title={t('pages.monitoring.survey.generate_report')}
            onClick={() => {
              window.open(`${process.env.REACT_APP_REGISTRATION_REPORT}?id=${record.id}&is_survey=true`, '_blank');
            }}
          />
        </Can>
      </>
    ),
  });

  const [tableDataConfig, setTableDataConfig] = useState({
    search: '',
    filtered: {},
    sorter: {},
    getActions,
  });

  const handleTableChange: TableProps<any>['onChange'] = useCallback(
    (
      pagination: TablePaginationConfig,
      filters: Record<string, FilterValue | null>,
      sorter: SorterResult<any> | SorterResult<any>[],
    ) => {
      setLoading(true);
      setTableDataConfig(tableDataConfigState => ({
        ...tableDataConfigState,
        filtered: filters,
        sorter,
      }));

      let direction = '';
      const search: any = {};
      let sort = '';

      if (sorter) {
        const { field, order } = sorter as SorterResult<any>;

        if (order === 'ascend') direction = 'asc';
        if (order === 'descend') direction = 'desc';
        sort = field?.toString() ?? '';
      }

      if (filters) {
        const dataFilter: any = filters;

        if (dataFilter) {
          Object.keys(dataFilter).forEach((key: string) => {
            if (dataFilter[key] !== null && dataFilter[key] !== undefined && dataFilter[key][0]) {
              search[key] = dataFilter[key][0].toString();
            } else if (dataFilter[key] !== null && dataFilter[key] !== undefined) {
              search[key] = dataFilter[key].toString();
            } else {
              search[key] = '';
            }
          });
        }
      }

      setPaginationConfig(paginationConfigState => ({
        ...paginationConfigState,
        pageSize: pagination.pageSize,
        current: pagination.current,
      }));

      loadTableData({
        registration_id: params.registration,
        page: pagination.current,
        per_page: pagination.pageSize,
        sort,
        direction,
        ...search,
      });
    },
    [loadTableData, params.registration],
  );

  const handleFastSearch = () => {
    const value = searchForm.getFieldsValue();
    setTableDataConfig(tableDataConfigState => ({
      ...tableDataConfigState,
      search: value.search,
    }));
    loadTableData({
      search: value.search,
    });
  };

  const tableDataClearAllFilters = () => {
    searchForm.resetFields();
    setTableDataConfig(tableDataConfigState => ({
      ...tableDataConfigState,
      filtered: {},
      search: '',
      sorter: {},
    }));
    loadTableData({ page: 1 });
  };

  // Table DATA Config: END

  useEffect(() => {
    const filters: any = {};

    handleTableChange({ current: 1, pageSize: paginationConfig.pageSize }, filters, {}, null);
  }, [handleTableChange, paginationConfig.pageSize]);

  return (
    <>
      <Breadcrumb
        items={[
          { title: t('breadcrumb.monitoring') },
          { title: t('breadcrumb.cpr'), to: '/monitoring/cpr' },
          { title: t('breadcrumb.registrations'), to: `/monitoring/cpr/${params.cpr}/registration` },
          { title: t('breadcrumb.surveys') },
        ]}
      />

      <SContainer>
        <SHeader>
          <STitlePage>{t('pages.monitoring.survey.title_page')}</STitlePage>

          <Can I="monitoring.survey.store" a="">
            <Button
              status="primary"
              icon={<FaPlus />}
              onClick={() => navigate(`/monitoring/cpr/${params.cpr}/registration/${params.registration}/survey/new`)}
            >
              {t('pages.monitoring.survey.buttonAdd')}
            </Button>
          </Can>
        </SHeader>

        <SFilterContainer>
          <Form
            form={searchForm}
            name="filter-owner"
            className="form-secondary form-filters grid-filters"
            onFinish={handleFastSearch}
          >
            <div className="filter-search">
              <FormItem name="search" label={t('pages.monitoring.survey.search')}>
                <Input />
              </FormItem>
            </div>
            <div className="filter-button">
              <FormItem label="" className="form-item-without-label">
                <Button status="primary" text="uppercase" htmlType="submit">
                  <FaSearch /> {t('form.actions.search')}
                </Button>
              </FormItem>
            </div>
            <div className="filter-clear">
              <Button status="secondary" size="middle" onClick={tableDataClearAllFilters}>
                {t('pages.register.harvest.buttonClearFilter')}
              </Button>
            </div>
          </Form>
        </SFilterContainer>

        <TableData
          rowKey="id"
          columns={columns.survey.map((column: any) => {
            let columnRender: any = {
              ...column,
            };

            if (column.key === 'info') {
              columnRender = {
                ...columnRender,
                render: (_: any, row: DTOSurvey) =>
                  row.culture_id &&
                  row.cpr.culture_id &&
                  row.cpr.culture_id !== row.culture_id && (
                    <IconWithTooltip
                      placement="right"
                      action="high-priority"
                      title={t('pages.monitoring.survey.alert-survey')}
                    />
                  ),
              };
            }

            if (column.key === 'survey_stage') {
              columnRender = {
                ...columnRender,
                render: (value: DTOSurveyStage) => value?.name ?? '',
              };
            }

            if (column.key === 'survey_type') {
              columnRender = {
                ...columnRender,
                render: (value: DTOSurveyType) => value?.name ?? '',
              };
            }

            if (column.key === 'planting_start') {
              columnRender = {
                ...columnRender,
                render: (planting_start: string) => (planting_start != null ? `${Formatter.date(planting_start)}` : ''),
              };
            }

            if (column.key === 'date_survey') {
              columnRender = {
                ...columnRender,
                render: (date_survey: string) => (date_survey != null ? `${Formatter.date(date_survey)}` : ''),
              };
            }

            if (column.key === 'crop_condition') {
              columnRender = {
                ...columnRender,
                render: (value: CropConditionData) => (value ? value.name : ''),
              };
            }

            if (column.key === 'color_condition') {
              columnRender = {
                ...columnRender,
                render: (color_condition: string) => <Badge size="default" color={color_condition} text="" />,
              };
            }

            if (column.key === 'harvested') {
              columnRender = {
                ...columnRender,
                render: (value: any) => {
                  return (
                    <Space>
                      <div style={{ width: 170 }}>
                        <Progress
                          percent={value}
                          size="small"
                          status="normal"
                          strokeColor={value > 30 ? theme.colors.verydanger : theme.colors.warning}
                        />
                      </div>
                    </Space>
                  );
                },
              };
            }

            if (column.key === 'approval_status') {
              columnRender = {
                ...columnRender,
                render: (value: any) => {
                  return value?.name ?? '';
                },
              };
            }

            if (column.key === 'culture') {
              columnRender = {
                ...columnRender,
                render: (value: any) => {
                  return value?.name ?? '';
                },
              };
            }

            return columnRender;
          })}
          dataSource={data}
          loading={loading}
          onChange={handleTableChange}
          pagination={paginationConfig}
          tableConfig={tableDataConfig}
        />
      </SContainer>

      <Modal
        visible={visibilityApprove}
        width="20%"
        title={t('pages.monitoring.survey.modalApprove.title')}
        onCancel={() => setVisibilityApprove(false)}
        closable={!loadingApprove}
        closeIcon={<MdClose onClick={() => setVisibilityApprove(false)} />}
        footer={
          <>
            <div className="buttons">
              <Button
                status="primary"
                disabled={loadingApprove}
                onClick={() => {
                  setLoadingApprove(true);
                  monitoringApi.survey
                    .approve(approveId, {})
                    .then(response => {
                      setData(updateData<DTOSurvey>(response.data, data, paginationConfig));
                      handleSuccess(response.data.message);
                    })
                    .catch((err: DTOErrorReponse) => alertStatus(err, 'error'))
                    .finally(() => {
                      setLoadingApprove(false);
                      setVisibilityApprove(false);
                    });
                }}
              >
                {t('pages.registered-customers.form.buttons.save')}
              </Button>
              <Button status="secondary" onClick={() => setVisibilityApprove(false)}>
                {t('pages.credit-request.modal-reports.buttons.close')}
              </Button>
            </div>
          </>
        }
      >
        <Spin spinning={loadingApprove}>{t('pages.monitoring.survey.modalApprove.confirm')}</Spin>
      </Modal>
    </>
  );
};

export default Survey;

export default {
  survey: [
    {
      title: 'pages.monitoring.surveyrequest.table.header.number',
      dataIndex: 'number',
      key: 'number',
      sorter: true,
    },
    {
      title: 'pages.monitoring.surveyrequest.table.header.farm',
      dataIndex: 'farm',
      key: 'farm',
      sorter: true,
    },
    {
      title: 'pages.monitoring.surveyrequest.table.header.cpr',
      dataIndex: 'cpr',
      key: 'cpr',
      sorter: true,
    },
    {
      title: 'pages.monitoring.surveyrequest.table.header.registration',
      dataIndex: 'registration',
      key: 'registration',
      sorter: true,
    },
    {
      title: 'pages.monitoring.surveyrequest.table.header.city',
      dataIndex: 'city',
      key: 'city',
      sorter: true,
    },
    {
      title: 'pages.monitoring.surveyrequest.table.header.state',
      dataIndex: 'state',
      key: 'state',
      sorter: true,
    },
    {
      title: 'pages.monitoring.surveyrequest.table.header.survey_type',
      dataIndex: 'survey_type',
      key: 'survey_type',
      sorter: true,
    },
    // {
    //   title: 'pages.monitoring.surveyrequest.table.header.status',
    //   dataIndex: 'status',
    //   key: 'status',
    //   sorter: true,
    // },
    {
      title: 'pages.monitoring.surveyrequest.table.header.operator',
      dataIndex: 'operator',
      key: 'operator',
      sorter: true,
    },
    {
      title: 'pages.monitoring.surveyrequest.table.header.deadline',
      dataIndex: 'deadline',
      key: 'deadline',
      sorter: true,
    },
    // {
    //   title: 'pages.monitoring.surveyrequest.table.header.survey_stage',
    //   dataIndex: 'survey_stage',
    //   key: 'survey_stage',
    //   sorter: false,
    // },
    {
      title: 'pages.monitoring.surveyrequest.table.header.actions',
      key: 'action',
      dataIndex: 'action',
      align: 'center',
      width: '150px',
    },
  ],
};

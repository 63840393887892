import { TablePaginationConfig } from 'antd/lib/table';

import { useSustainability } from '../../../../hooks/fetch/useSustainability';

export const useRequestsContract = (dataForm: any) => {
  const { getReports, isFetching } = useSustainability();
  const handleTableChange = async (pagination: TablePaginationConfig, filters: object, sorter: any) => {
    const { data, total } = await getReports({
      params: {
        [dataForm.type]: dataForm.value,
        page: pagination.current,
        per_page: pagination.pageSize,
        sort: sorter.sort,
        direction: sorter.direction,
        ...filters,
      },
    });
    return { data, total };
  };

  return {
    handleTableChange,
    isFetching,
  };
};

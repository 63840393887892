import { useMutation } from '@tanstack/react-query';
import { usePage } from 'hooks/page';
import api from 'services/api/api';

export const useDeleteDocumentToCollectCommentMutation = () => {
  const { alertStatus } = usePage();

  return useMutation({
    mutationKey: ['deleteDocumentToCollectComment'],
    mutationFn: async (commentId: string) => {
      return api.delete(`credit-request/package/comment/${commentId}`);
    },
    onError: err => {
      alertStatus(err.message, 'error');
    },
  });
};

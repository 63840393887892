import React from 'react';
import { useTranslation } from 'react-i18next';
import { AiFillFileText } from 'react-icons/ai';
import { FaFileExcel, FaTimes } from 'react-icons/fa';

import { Can } from 'hooks/ability';
import { useModal } from 'hooks/modals/useModal';
import { useContractContext } from 'pages/Contracts/context/ContractContext';

import { IconWrapper } from '../IconWrapper';
import { DeleteReasonModal } from './DeleteReasonModal';
import { Container, ContractData, ContractName } from './styles';
import { ContractButtonProps } from './types';

export const RelatedDocumentButton = ({
  isMain = false,
  isSelected = false,
  code,
  onClick,
  id,
  isCancelled,
}: ContractButtonProps) => {
  const { t } = useTranslation();
  const { isDeletingRelatedDocument: isDestroyingRelatedDocument, mainContract } = useContractContext();
  const { close, isOpen, open } = useModal();

  const handleDeleteClick = () => {
    open();
  };

  return (
    <Container isSelected={isSelected} isMain={isMain} onClick={onClick ?? undefined}>
      <ContractData>
        <IconWrapper variant={isCancelled ? 'close' : 'default'}>
          {isCancelled ? <FaFileExcel color="red" /> : <AiFillFileText />}
        </IconWrapper>
        <ContractName>
          {`${isMain ? `${t('pages.edocuments.emission.mainContractPrefix')} - ` : ''}${code}`}
        </ContractName>
      </ContractData>
      {!isMain && !isCancelled && mainContract?.active && (
        <Can I="contract.dependency.destroy">
          <IconWrapper
            variant="close"
            disabled={isDestroyingRelatedDocument}
            onClick={e => {
              e.stopPropagation();
              handleDeleteClick();
            }}
          >
            <FaTimes color="red" />
          </IconWrapper>
        </Can>
      )}

      <DeleteReasonModal visible={isOpen} relatedDocumentId={id} onCancel={close} afterClose={close} />
    </Container>
  );
};

import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'components';
import { SignatureSelectButton } from 'pages/Contracts/components/SignatureSelectButton';
import type { ContractSubscriberData } from 'types/Contract/ContractSubscriberData';

import { ButtonsWrapper } from '../styles';

interface Props {
  signatures: ContractSubscriberData[];
  setSelectedSignaturesIds: React.Dispatch<React.SetStateAction<string[]>>;
  selectedSignaturesIds: string[];
}

export const SignaturesList = ({ signatures, setSelectedSignaturesIds, selectedSignaturesIds }: Props) => {
  const { t } = useTranslation();

  const toggleSignature = (signatureId: string) => {
    if (selectedSignaturesIds.includes(signatureId)) {
      setSelectedSignaturesIds(oldSelectedSignaturesIds => oldSelectedSignaturesIds.filter(id => id !== signatureId));
    } else {
      setSelectedSignaturesIds(oldSelectedSignaturesIds => [...oldSelectedSignaturesIds, signatureId]);
    }
  };

  const isSignatureSelected = (signatureId: string) => selectedSignaturesIds.includes(signatureId);

  const selectAll = () => setSelectedSignaturesIds(signatures?.map(signature => signature.id) || []);

  return (
    <>
      {signatures.length ? (
        <ButtonsWrapper>
          <Button onClick={selectAll}>{t('selectAll')}</Button>
        </ButtonsWrapper>
      ) : null}

      {signatures.map(signature => (
        <SignatureSelectButton
          onClick={() => toggleSignature(signature.id)}
          isSelected={isSignatureSelected(signature.id)}
          signature={signature}
        />
      ))}
    </>
  );
};

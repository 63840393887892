import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AiFillFileExcel } from 'react-icons/ai';

import { Col, Form, Input } from 'antd';
import { useForm } from 'antd/lib/form/Form';

import { Button, FormItem } from '../../../../../components';
import theme from '../../../../../styles/theme';
import { DraftModelContext } from '../../../context/DraftModelContext';

export const CancelModalBody = ({ closeModal }: { closeModal: () => void }) => {
  const [form] = useForm();
  const { denyDraft } = useContext(DraftModelContext);
  const { t } = useTranslation();

  const handleDeny = () => {
    form.validateFields().then(data => {
      denyDraft(data.message);
      closeModal();
    });
  };

  return (
    <>
      <h2>{t('pages.edocuments.emission.draftEmission.reproveDraftTitle')}</h2>
      <AiFillFileExcel size={60} color={theme.colors.primary} />
      <Form form={form} style={{ width: '100%' }}>
        <Col xl={24} xxl={24} lg={24} md={24} sm={24} xs={24}>
          <FormItem
            name="message"
            label={t('pages.edocuments.emission.draftEmission.reproveDraftReason')}
            rules={[{ required: true }]}
          >
            <Input.TextArea
              placeholder={t('pages.edocuments.emission.draftEmission.reproveDraftReasonPlaceholder')}
              rows={5}
            />
          </FormItem>
        </Col>
      </Form>
      <Button onClick={handleDeny} permission="contract.validatecontract">
        {t('pages.edocuments.emission.draftEmission.reproveDraftConfirm')}
      </Button>
    </>
  );
};

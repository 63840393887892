export const propsSkeletonCharts = {
  width: '100%',
  minWidth: ['99%', '550px', '550px', '550px'],
  height: '589px',
  borderRadius: '10px',
  margin: '5px',
  number: 1,
};

export const propsFilters = [
  {
    name: 'weekRange',
    key: 1,
    required: true,
  },
  {
    name: 'provider_multiple',
    key: 2,
  },
  {
    name: 'numbers',
    key: 3,
  },
  {
    name: 'start',
    key: 4,
  },
  {
    name: 'end',
    key: 5,
  },
];

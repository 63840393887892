import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ShouldRender } from 'components/ShouldRender';
import { useAlertDialog } from 'components/ui/alert-dialog';
import { Button } from 'components/ui/button';
import { GroupForm } from 'components/ui/group-form';
import { randomUUID } from 'lib/utils';

import { MainClientFormData } from './main-clients/main-clients.form';
import { MainClientModal } from './main-clients/main-clients.modal';
import { MainClientTable } from './main-clients/main-clients.table';
import { useRegisteredCustomerStore } from './stores/use-registered-customer-store';

export const RegisteredCustomerMainClientsSection = () => {
  const { t } = useTranslation();
  const form = useFormContext();
  const { alert } = useAlertDialog();

  const isViewing = useRegisteredCustomerStore(state => state.isViewing);
  const isModulePerson = useRegisteredCustomerStore(state => state.isModulePerson);
  const setIsMainClientModalOpen = useRegisteredCustomerStore(state => state.setIsMainClientModalOpen);
  const mainClientToEdit = useRegisteredCustomerStore(state => state.mainClientToEdit);

  const handleAddMainClient = (data: MainClientFormData) => {
    const mainClients = form.getValues('main_client') as MainClientFormData[];

    form.setValue(
      'main_client',
      [
        ...(mainClients ?? []),
        {
          ...data,
          id: `new-${randomUUID()}`,
        },
      ],
      {
        shouldTouch: true,
      },
    );
  };

  const handleEditMainClient = (data: MainClientFormData) => {
    const mainClients = form.getValues('main_client') as MainClientFormData[];
    const index = mainClients.findIndex(mainClient => mainClient.id === data.id);
    mainClients[index] = data;
    form.setValue('main_client', mainClients, {
      shouldTouch: true,
    });
    alert({
      type: 'success',
      title: t('main-client-updated'),
      description: '',
    });
  };

  return (
    <GroupForm title={t('main-clients')}>
      <ShouldRender condition={!isViewing && isModulePerson}>
        <div className="flex justify-end w-full">
          <Button type="button" variant="default" onClick={() => setIsMainClientModalOpen(true)}>
            {t('add-main-client')}
          </Button>
        </div>
      </ShouldRender>

      <div className="mt-6">
        <MainClientTable />
      </div>

      <MainClientModal onSuccess={mainClientToEdit ? handleEditMainClient : handleAddMainClient} />
    </GroupForm>
  );
};

import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Col, Form, Spin, Modal } from 'antd';
import ContactData from 'types/Contract/ContactData';

import DTOErrorReponse from '../../../../@types/dtos/DTOErrorReponse';
import { Button, Input, ModalStatus, FormItem, Row, Select, IconWithTooltip } from '../../../../components';
import { TableData } from '../../../../compositions';
import { useCache } from '../../../../hooks/cache';
import { usePage } from '../../../../hooks/page';
import monitoringApi from '../../../../services/api/monitoring';
import { formatValuesToFormData } from '../../../../services/form';
import { Coordinate } from '../../../../utils/Coordinate';
import columns from './columns';
import { SFormButtons } from './styles';
import { NodeParam, IRegisterModalProps } from './types';

const FarmModal: React.FC<IRegisterModalProps> = (props: IRegisterModalProps) => {
  const { onOkCallback, onCancelCallback, visibility } = props;
  const { t, i18n } = useTranslation();
  const [form] = Form.useForm();

  const cache = useCache();
  const { alertStatus } = usePage();

  const [loading, setLoading] = useState(false);
  const [degrees, setDegrees] = useState(true);

  const [contact_reference_form] = Form.useForm();
  const [contacts, setContact] = useState([]);

  const [subforms, setSubforms] = useState(null);
  useEffect(() => {
    setSubforms({
      contact_reference: contact_reference_form,
    });
  }, [contact_reference_form]);

  const [subformsLoading] = useState({
    contact_reference: {
      editing: null,
      removing: null,
      form: null,
      other: null,
    },
  });

  const [visibilitySubforms, setVisibilitySubforms] = useState({
    contact_reference: null,
  });

  /**
   * Faz a ação de abrir um modal de subform de acordo com o 'node' passado
   * @param node NodeParam
   */
  async function subformsOpen(node: NodeParam) {
    subforms[node].resetFields();
    setVisibilitySubforms(curState => ({ ...curState, [node]: true }));
  }

  async function handleClickAddRegistrationReference() {
    subformsOpen('contact_reference');
  }

  async function handleTableRegistrationsChange() {
    contact_reference_form
      .validateFields()
      .then(() => {
        setContact([...contacts, contact_reference_form.getFieldsValue()]);

        setLoading(false);

        setVisibilitySubforms(curState => ({
          ...curState,
          contact_reference: false,
        }));
      })
      .catch(() => {
        ModalStatus({
          type: 'error',
          title: t('modal.error'),
          subTitle: t('form.validation.hasErrors'),
        });
        setLoading(false);
      });
  }

  const [formOptions, setFormOptions] = useState({
    state: [],
    cities: {
      city: [],
      birth: [],
      bank: [],
      commercial: [],
      address: [],
    },
    document_type: [],
  });

  const loadCityOptions = useCallback(
    async (stateId: string, node: string) => {
      setFormOptions(state => ({
        ...state,
        cities: {
          ...state.cities,
          [node]: cache.getCityByStateOptions(stateId),
        },
      }));
    },
    [cache],
  );

  const handleSuccess = (messageSuccess: string) =>
    ModalStatus({
      type: 'success',
      title: t('modal.success'),
      subTitle: messageSuccess,
    });

  const tableConfigContact = {
    search: '',
    filtered: {},
    sorter: {},
    nopagination: true,
    getActions: () => ({
      render: (_active: number, record: ContactData, index: number) => (
        <>
          <IconWithTooltip
            action="destroy"
            title={t('destroy')}
            onClick={() => {
              contacts.splice(index, 1);
              setContact([...contacts]);
            }}
          />
        </>
      ),
    }),
  };

  const handleRegister = () => {
    setLoading(true);
    form
      .validateFields()
      .then(() => {
        const formValues = form.getFieldsValue();
        delete formValues.state;
        let formData = new FormData();
        formData = formatValuesToFormData(formValues, formData);

        if (contacts.length > 0) {
          contacts.forEach((record: any, key) => {
            formData.append(`contacts[${key}][type]`, record.type);
            formData.append(`contacts[${key}][value]`, record.value);
            formData.append(`contacts[${key}][description]`, record.description);
          });
        }

        formData.append('language', i18n.language);

        monitoringApi.farm
          .store(formData)
          .then(response => {
            handleSuccess(response.data.message);
            setLoading(false);
            setContact([]);
            onOkCallback(response.data);
          })
          .catch((err: DTOErrorReponse) => {
            alertStatus(err, 'error');
            setLoading(false);
          });
      })
      .catch((err: DTOErrorReponse) => {
        alertStatus(err, 'error');
        setLoading(false);
      });
  };

  // Load FormOptions
  useEffect(() => {
    setFormOptions(curState => ({
      ...curState,
      state: cache.getCreditOptions('state'),
      cities: {
        city: cache.getCreditOptions('city'),
        birth: cache.getCreditOptions('city'),
        bank: cache.getCreditOptions('city'),
        commercial: cache.getCreditOptions('city'),
        address: cache.getCreditOptions('city'),
      },
      document_type: cache.getPersonOptions('document_type_person'),
    }));
  }, [cache]);

  return (
    <>
      <Modal
        title={t('pages.monitoring.farm.registerTitle')}
        visible={visibility}
        onOk={handleRegister}
        okText={t('pages.monitoring.farm.buttonRegister')}
        cancelText={t('pages.monitoring.farm.buttonCancel')}
        onCancel={() => onCancelCallback()}
        afterClose={() => form.resetFields()}
        width="70%"
      >
        <Spin spinning={loading}>
          <Spin spinning={loading}>
            <Form form={form}>
              <FormItem name="id" style={{ display: 'none' }}>
                <Input />
              </FormItem>
              <Row gutter={[26, 26]}>
                <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <FormItem
                    style={{ paddingRight: '10px' }}
                    label={t('pages.monitoring.farm.form.name')}
                    name="name"
                    rules={[{ required: true }]}
                  >
                    <Input type="text" />
                  </FormItem>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <FormItem
                    style={{ paddingRight: '10px' }}
                    label={t('pages.monitoring.farm.form.address')}
                    name="address"
                    rules={[{ required: true }]}
                  >
                    <Input type="text" />
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={[26, 26]}>
                <Col xs={24} sm={18} md={12} lg={12} xl={12} xxl={12}>
                  <FormItem label={t('pages.monitoring.farm.form.state')} name="state_id" rules={[{ required: true }]}>
                    <Select
                      options={formOptions.state}
                      onChange={value => (value ? loadCityOptions(value.toString(), 'bank') : '')}
                    />
                  </FormItem>
                </Col>
                <Col xs={24} sm={18} md={12} lg={12} xl={12} xxl={12}>
                  <FormItem label={t('pages.monitoring.farm.form.city')} name="city_id" rules={[{ required: true }]}>
                    <Select options={formOptions.cities.bank} />
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={[26, 26]}>
                <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={8}>
                  <FormItem label={t('pages.monitoring.farm.form.coordinateType')} name="is_dms">
                    <Select
                      onChange={value => setDegrees(Number(value) === 1)}
                      options={[
                        { value: 1, label: t('pages.monitoring.farm.form.degrees') },
                        { value: 0, label: t('pages.monitoring.farm.form.decimal') },
                      ]}
                    />
                  </FormItem>
                </Col>
                <Col style={{ display: degrees ? 'block' : 'none' }} xs={24} sm={12} md={12} lg={8} xl={8} xxl={8}>
                  <FormItem
                    label={t('pages.monitoring.farm.form.degrees')}
                    name="dms"
                    rules={[
                      {
                        pattern: Coordinate.regexDMS,
                        message: t('pages.monitoring.farm.validator.dms'),
                      },
                    ]}
                  >
                    {/* <MaskedInput
                      mask="11° 11′ 11″ a, 111° 11′ 11″ a"
                     
                      placeholder="__° __′ __″ _, ___° __′ __″ _"
                    /> */}
                    <Input placeholder="__° __′ __″ _, ___° __′ __″ _" />
                  </FormItem>
                </Col>
                <Col style={{ display: !degrees ? 'block' : 'none' }} xs={24} sm={12} md={12} lg={8} xl={8} xxl={8}>
                  <FormItem
                    label={t('pages.monitoring.farm.form.latitude')}
                    name="latitude"
                    rules={[
                      {
                        pattern: Coordinate.regexLat,
                        message: t('pages.monitoring.farm.validator.lat'),
                      },
                    ]}
                  >
                    <Input hidden={degrees} />
                  </FormItem>
                </Col>
                <Col style={{ display: !degrees ? 'block' : 'none' }} xs={24} sm={12} md={12} lg={8} xl={8} xxl={8}>
                  <FormItem
                    label={t('pages.monitoring.farm.form.longitude')}
                    name="longitude"
                    rules={[
                      {
                        pattern: Coordinate.regexLon,
                        message: t('pages.monitoring.farm.validator.lon'),
                      },
                    ]}
                  >
                    <Input hidden={degrees} />
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={[26, 26]}>
                <Col xs={20} sm={20} md={20} lg={20} xl={20} xxl={20} />
                {/* Botão Matícula */}
                <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4} style={{ paddingTop: '5px' }}>
                  <Button block onClick={handleClickAddRegistrationReference}>
                    {t('pages.monitoring.farm.buttonContact')}
                  </Button>
                  <FormItem name="contact_reference" style={{ display: 'none' }}>
                    <Input />
                  </FormItem>
                </Col>
              </Row>
              {/* Tabela Matrícula */}
              <Row gutter={[26, 26]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                  <TableData
                    rowKey="id"
                    columns={columns.map(column => {
                      const columnRenderMatricula: any = column;

                      return columnRenderMatricula;
                    })}
                    dataSource={contacts}
                    loading={loading}
                    tableConfig={tableConfigContact}
                  />
                </Col>
              </Row>
            </Form>
          </Spin>
          {/* Modal Contato  */}
          <Modal
            title={t('pages.monitoring.farm.form.addContact')}
            visible={visibilitySubforms.contact_reference}
            onCancel={() =>
              setVisibilitySubforms(curState => ({
                ...curState,
                contact_reference: false,
              }))
            }
            afterClose={() => contact_reference_form.resetFields()}
            width="50%"
            footer={
              <>
                <SFormButtons>
                  <Button
                    status="secondary"
                    onClick={() => {
                      setVisibilitySubforms(curState => ({
                        ...curState,
                        contact_reference: false,
                      }));
                      contact_reference_form.resetFields();
                    }}
                    disabled={subformsLoading.contact_reference.form}
                  >
                    {t('pages.monitoring.farm.form.buttons.cancel')}
                  </Button>
                  <Button
                    status="primary"
                    onClick={() => handleTableRegistrationsChange()}
                    disabled={subformsLoading.contact_reference.form}
                  >
                    {t('pages.monitoring.farm.form.buttons.save')}
                  </Button>
                </SFormButtons>
              </>
            }
            className="modal-with-custom-footer"
          >
            <Spin spinning={subformsLoading.contact_reference.form}>
              <Form name="subform_contact_reference" form={contact_reference_form}>
                <Row>
                  <Col xs={24} sm={18} md={12} lg={8} xl={8} xxl={8}>
                    <FormItem label={t('pages.monitoring.farm.form.typeContact')} name="type">
                      <Select
                        onChange={value => setDegrees(Number(value) === 1)}
                        options={[
                          { value: 'mail', label: t('pages.monitoring.farm.form.email') },
                          { value: 'phone', label: t('pages.monitoring.farm.form.phone') },
                          { value: 'mobile', label: t('pages.monitoring.farm.form.cel_phone') },
                        ]}
                      />
                    </FormItem>
                  </Col>
                  <Col xs={24} sm={18} md={12} lg={8} xl={8} xxl={8}>
                    <FormItem label={t('pages.monitoring.farm.form.contact')} name="value" rules={[{ required: true }]}>
                      <Input type="text" maxLength={80} />
                    </FormItem>
                  </Col>

                  <Col xs={24} sm={18} md={12} lg={8} xl={8} xxl={8}>
                    <FormItem
                      label={t('pages.monitoring.farm.form.description')}
                      name="description"
                      rules={[{ required: true }]}
                    >
                      <Input type="text" maxLength={150} />
                    </FormItem>
                  </Col>
                </Row>

                <Row />
              </Form>
            </Spin>
          </Modal>
        </Spin>
      </Modal>
    </>
  );
};
export default FarmModal;

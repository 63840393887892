/* eslint-disable react/no-array-index-key */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Spin } from 'antd';
import { TablePaginationConfig } from 'antd/lib/table';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { useAbility } from 'hooks/ability';
import moment from 'moment';
import { useCreditRequestStore } from 'pages/CreditRequest/store/useCreditRequestStore';

import DTOCreditRequestHistory, {
  DTOCreditRequestFieldsHistory,
} from '../../../../../@types/dtos/credit-request/DTOCreditRequestHistory';
import DTOCreditRequestStatusHistory from '../../../../../@types/dtos/credit-request/DTOCreditRequestStatusHistory';
import DTOStatusHistory from '../../../../../@types/dtos/credit-request/DTOStatusHistory';
import DTOResponsePagination from '../../../../../@types/dtos/DTOResponsePagination';
import { EmptyComponent } from '../../../../../components';
import { SelectOptionType } from '../../../../../components/Select/types';
import { TableData } from '../../../../../compositions';
import { TableProps } from '../../../../../compositions/TableData/types';
import appConfig from '../../../../../configs/app';
import { useCreditStatus } from '../../../../../hooks/creditStatus';
import { usePage } from '../../../../../hooks/page';
import creditApi from '../../../../../services/api/credit';
import renderComponent from '../../../../../utils/RenderComponent';
import columns from './columns';
import { Container } from './styles';
import HistoricStatusProps, { DTOStatusHistoryItem } from './types';

const HistoricStatus: React.FC<HistoricStatusProps> = ({ creditRequestId }) => {
  const { t } = useTranslation();
  const { alertStatus } = usePage();
  const { getTeamStatus } = useCreditStatus();
  const ability = useAbility();

  const historicChangelogTablePaginationConfig = useCreditRequestStore(
    state => state.historicChangelogTablePaginationConfig,
  );
  const setHistoricChangelogTablePaginationConfig = useCreditRequestStore(
    state => state.setHistoricChangelogTablePaginationConfig,
  );

  const [loading, setLoading] = useState(false);
  const [isLoadingChangelog, setIsLoadingChangelog] = useState(false);
  const [creditRequestCreatedAt, setCreditRequestCreatedAt] = useState('');
  const [creditRequestStatusHistory, setCreditRequestStatusHistory] = useState<DTOStatusHistory[]>([]);
  const [creditRequestHistory, setCreditRequestHistory] = useState<DTOCreditRequestHistory[]>([]);

  // Table DATA Config: BEGIN
  const tableConfig = {
    search: '',
    filtered: {},
    sorter: {},
  };
  const [tableDataConfig, setTableDataConfig] = useState(tableConfig);

  const getCreditRequestHistory = useCallback((params?: any) => {
    setIsLoadingChangelog(true);
    creditApi.requests
      .getCreditRequestHistory(creditRequestId, { params })
      .then((response: { data: DTOResponsePagination<DTOCreditRequestHistory> }) => {
        setCreditRequestHistory([...response.data.data]);
        setHistoricChangelogTablePaginationConfig({
          ...historicChangelogTablePaginationConfig,
          current: response.data.current_page,
          total: response.data.total,
        });
      })
      .catch(err => {
        alertStatus(err, 'error');
      })
      .finally(() => {
        setIsLoadingChangelog(false);
      });
    // eslint-disable-next-line
  }, []);

  const handleTableChange: TableProps<any>['onChange'] = useCallback(
    (
      pagination: TablePaginationConfig,
      filters: Record<string, FilterValue | null>,
      sorter: SorterResult<any> | SorterResult<any>[],
    ) => {
      setLoading(true);
      setTableDataConfig(tableDataConfigState => ({
        ...tableDataConfigState,
        filtered: filters,
        sorter,
      }));

      let direction = '';
      const search: any = {};
      let sort = '';

      if (sorter) {
        const { field, order } = sorter as SorterResult<any>;

        if (order === 'ascend') direction = 'asc';
        if (order === 'descend') direction = 'desc';
        sort = field?.toString();
      }

      if (filters) {
        const dataFilter: any = filters;

        if (dataFilter) {
          Object.keys(dataFilter).forEach((key: string) => {
            if (dataFilter[key] !== null && dataFilter[key] !== undefined && dataFilter[key][0]) {
              search[key] = dataFilter[key][0].toString();
            } else if (dataFilter[key] !== null && dataFilter[key] !== undefined) {
              search[key] = dataFilter[key].toString();
            } else {
              search[key] = '';
            }
          });
        }
      }

      setHistoricChangelogTablePaginationConfig({
        ...historicChangelogTablePaginationConfig,
        pageSize: pagination.pageSize,
        current: pagination.current,
      });

      if (ability.can('credit.request.history.changelog', ''))
        getCreditRequestHistory({
          page: pagination.current,
          per_page: pagination.pageSize,
          sort,
          direction,
          ...search,
        });
    },
    [getCreditRequestHistory],
  );

  useEffect(() => {
    handleTableChange({ current: 1, pageSize: historicChangelogTablePaginationConfig.pageSize }, {}, {}, null);
    // eslint-disable-next-line
  }, [handleTableChange]);
  // Table DATA Config: END

  const getCreditRequestStatusHistory = useCallback(() => {
    setLoading(true);
    creditApi.requests
      .getCreditRequestStatusHistoryAll(creditRequestId)
      .then((response: { data: DTOCreditRequestStatusHistory }) => {
        setCreditRequestCreatedAt(response.data.created_at);
        setCreditRequestStatusHistory([...response.data.status_history]);
      })
      .catch(err => {
        alertStatus(err, 'error');
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line
  }, []);

  // eslint-disable-next-line
  useEffect(() => getCreditRequestStatusHistory(), [getCreditRequestStatusHistory]);

  function handleExpandRowHistory(record: DTOCreditRequestHistory) {
    return (
      <TableData
        rowKey="field"
        columns={columns.historyRow.map((col: any) => {
          switch (col.key) {
            case 'field':
              return {
                ...col,
                render: (_: any, row: DTOCreditRequestFieldsHistory) => {
                  if (
                    record.operation === 'prepayment' &&
                    (row.field === 'comments' || row.field === 'json_data.price' || row.field === 'requested_amount')
                  ) {
                    return t(`pages.credit-request.form.prepayment_${row.field.replace('.', '_')}`);
                  }
                  return t(`pages.credit-request.form.${row.field.replace('.', '_')}`);
                },
              };
            default:
              return col;
          }
        })}
        dataSource={record.values}
        tableConfig={{ nopagination: true }}
      />
    );
  }

  function getTimeDiff(dateTimeDiff: string, dateTimeCurrent: string): { diffTime: number; diffTimeUnity: string } {
    let diffTime = moment(dateTimeDiff).diff(dateTimeCurrent, 'days');
    let diffTimeUnity = 'in_days';

    if (diffTime === 0) {
      diffTime = moment(dateTimeDiff).diff(dateTimeCurrent, 'hours');
      diffTimeUnity = 'in_hours';
      if (diffTime === 0) {
        diffTime = moment(dateTimeDiff).diff(dateTimeCurrent, 'minutes');
        diffTimeUnity = 'in_minutes';
        if (diffTime === 0) {
          diffTime = moment(dateTimeDiff).diff(dateTimeCurrent, 'seconds');
          diffTimeUnity = 'in_seconds';
        }
      }
    }

    return { diffTime, diffTimeUnity };
  }

  const timelineStatus = useMemo(() => {
    if (creditRequestStatusHistory) {
      const historicInOrder = creditRequestStatusHistory.map(
        ({ created_at, from, to, id, user }: DTOStatusHistory) => ({
          created_at,
          from: from !== null ? (getTeamStatus(from?.id, true) as SelectOptionType) : from,
          to: getTeamStatus(to?.id, true) as SelectOptionType,
          id,
          user: {
            ...user,
            name: `${user.name.substr(0, 30)}${Number(user.name.length) > 30 ? '...' : ''}`,
          },
        }),
      );

      const firstItem = historicInOrder[historicInOrder.length - 1];
      if (firstItem) {
        if (!firstItem.from) {
          historicInOrder[historicInOrder.length - 1] = {
            ...firstItem,
            from: getTeamStatus(firstItem.to?.value as string, true) as SelectOptionType,
            created_at: creditRequestCreatedAt,
          };
        } else {
          historicInOrder.push({ ...firstItem, created_at: creditRequestCreatedAt });
        }
      }

      const timeline = historicInOrder.reverse().reduce((previous, current: DTOStatusHistoryItem, index, arr) => {
        const nextItem: DTOStatusHistoryItem | null = arr[index + 1];
        if (nextItem) {
          const { diffTime, diffTimeUnity } = getTimeDiff(nextItem.created_at, current.created_at);
          previous.push({ ...current, time: diffTime, unity: diffTimeUnity, user: nextItem.user });
        } else {
          previous.push({ ...current });
        }
        return previous;
      }, []);
      return timeline;
    }

    return [];
    // eslint-disable-next-line
  }, [creditRequestStatusHistory, creditRequestCreatedAt]);

  return (
    <>
      <Container>
        <h2>{t('pages.credit-request.historic.status_history_title')}</h2>
        <Spin spinning={loading}>
          <div className="historic-status-bar-container">
            {renderComponent(
              timelineStatus.length > 0,
              timelineStatus.map((item, index) => (
                <>
                  {renderComponent(
                    index === 0,
                    <div
                      className="historic-status-bar-item historic-status-bar-item-first"
                      key={`${index}-${item.id}`}
                    >
                      <div className="historic-status-ball" />
                      <div className="historic-status-ball-label-top">
                        <div>{item.from?.label}</div>
                      </div>
                      <div className="historic-status-ball-label-bottom">
                        {moment(item.created_at).format(
                          `${appConfig.formatDate} [${t('pages.credit-request.historic.times.time_at')}] ${
                            appConfig.formatTime
                          }`,
                        )}
                      </div>
                      <div className="historic-status-bar">
                        <div className="historic-status-bar-label-top">{item.user.name}</div>
                        <div className="historic-status-bar-label-bottom">
                          {`${item.time ?? ''} ${
                            item.unity ? t(`pages.credit-request.historic.times.${item.unity}`) : ''
                          }`}
                        </div>
                      </div>
                    </div>,
                  )}
                  {renderComponent(
                    index > 0 && index < timelineStatus.length - 1,
                    <div className="historic-status-bar-item" key={`${index}-${item.id}`}>
                      <div className="historic-status-ball" />
                      <div className="historic-status-ball-label-top">
                        <div>{item.to?.label}</div>
                      </div>
                      <div className="historic-status-ball-label-bottom">
                        {moment(item.created_at).format(
                          `${appConfig.formatDate} [${t('pages.credit-request.historic.times.time_at')}] ${
                            appConfig.formatTime
                          }`,
                        )}
                      </div>
                      <div className="historic-status-bar">
                        <div className="historic-status-bar-label-top">{item.user.name}</div>
                        <div className="historic-status-bar-label-bottom">
                          {`${item.time} ${t(`pages.credit-request.historic.times.${item.unity}`)}`}
                        </div>
                      </div>
                    </div>,
                  )}
                  {renderComponent(
                    index > 0 && index === timelineStatus.length - 1,
                    <div className="historic-status-bar-item historic-status-bar-item-last" key={`${index}-${item.id}`}>
                      <div className="historic-status-ball" />
                      <div className="historic-status-ball-label-top">
                        <div>{item.to?.label}</div>
                      </div>
                      <div className="historic-status-ball-label-bottom">
                        {moment(item.created_at).format(
                          `${appConfig.formatDate} [${t('pages.credit-request.historic.times.time_at')}] ${
                            appConfig.formatTime
                          }`,
                        )}
                      </div>
                    </div>,
                  )}
                </>
              )),
              <div className="historic-empty">
                <EmptyComponent description={t('pages.credit-request.historic.empty')} />
              </div>,
            )}
          </div>
        </Spin>
        <h2>{t('pages.credit-request.historic.change_history_title')}</h2>
        <div className="historic-changelog-container">
          <TableData
            rowKey="id"
            columns={columns.history}
            loading={isLoadingChangelog}
            expandable={{ expandedRowRender: handleExpandRowHistory }}
            onChange={handleTableChange}
            dataSource={creditRequestHistory}
            pagination={historicChangelogTablePaginationConfig}
            tableConfig={tableDataConfig}
          />
        </div>
      </Container>
    </>
  );
};

export default HistoricStatus;

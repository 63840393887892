import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Container } from './styles';

interface Props {
  toggleCarVisibility: () => void;
}

export const MapsCarMenu = ({ toggleCarVisibility }: Props) => {
  const { t } = useTranslation();

  const [isVisible, setIsVisible] = useState(false);

  const toggle = () => {
    toggleCarVisibility();
    setIsVisible(!isVisible);
  };

  return (
    <Container isSelected={isVisible} onClick={toggle} style={{ left: '0', width: 'max-content' }}>
      {isVisible
        ? t('pages.dashboard.monitoring-prefin.map.hideCar')
        : t('pages.dashboard.monitoring-prefin.map.showCar')}
    </Container>
  );
};

import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useIsMutating } from '@tanstack/react-query';
import { ColumnDef } from '@tanstack/react-table';
import { IconWithTooltip } from 'components';
import { DataTable } from 'components/ui/data-table';
import { Can } from 'hooks/ability';
import { useDeletePackageDocumentToCollectMutation } from 'pages/CreditRequest/api/useDeletePackageDocumentToCollectMutation';
import { useFormalizationGuaranteesStore } from 'pages/CreditRequest/store/useFormalizationGuaranteesStore';
import { usePackageFormStateStore } from 'pages/CreditRequest/store/usePackageFormStateStore';
import { DocumentToCollectData } from 'types/CreditRequest/DocumentToCollectData';
import { DocumentToCollectFormData } from 'types/CreditRequest/forms/DocumentToCollectFormData';

import { DocumentsToCollectModal } from './documents-collect.modal';

type DocumentsToCollectTableProps = {
  onEdit?: (data: DocumentToCollectFormData) => void;
};

export const DocumentsToCollectTable = ({ onEdit }: DocumentsToCollectTableProps) => {
  const { t } = useTranslation();
  const { getValues, setValue } = useFormContext();

  const documentsToCollect = useWatch({ name: 'documentsToCollect' }) as DocumentToCollectData[];

  const setSelectedDocumentToCollectToEdit = usePackageFormStateStore(
    state => state.setSelectedDocumentToCollectToEdit,
  );
  const documentBeingRemovedId = usePackageFormStateStore(state => state.documentBeingRemovedId);
  const selectedPackage = useFormalizationGuaranteesStore(state => state.selectedPackage);
  const setSelectedPackage = useFormalizationGuaranteesStore(state => state.setSelectedPackage);

  const deleteDocumentMutation = useDeletePackageDocumentToCollectMutation();
  const isDeletingDocument = useIsMutating({
    mutationKey: ['deletePackageDocumentToCollect'],
  });

  const handleDelete = async (record: DocumentToCollectData) => {
    if (record.id && !record.id.includes('new-') && !record.template_id) {
      await deleteDocumentMutation.mutateAsync(record.id, {
        onSuccess: () => {
          const documentsToCollect = [...getValues('documentsToCollect')];
          setValue(
            'documentsToCollect',
            documentsToCollect.filter(doc => doc.id !== record.id),
          );
          setSelectedPackage({
            ...selectedPackage,
            items: documentsToCollect.filter(doc => doc.id !== record.id),
          });
        },
      });
    } else {
      const documentsToCollect = [...getValues('documentsToCollect')];
      setValue(
        'documentsToCollect',
        documentsToCollect.filter(doc => doc.id !== record.id),
      );
      setSelectedPackage({
        ...selectedPackage,
        items: documentsToCollect.filter(doc => doc.id !== record.id),
      });
    }
  };

  const columns: ColumnDef<DocumentToCollectData>[] = [
    {
      accessorKey: 'name',
      header: t(
        'pages.credit-request.formalization-guarantees.pre-registration-docs.modalPackage.table.header.document',
      ),
    },
    {
      id: 'actions',
      enablePinning: true,
      accessorKey: 'action',
      size: 100,
      header: t('actions'),
      cell: ({ row }) => {
        const documentToCollect = row.original as DocumentToCollectData;

        return (
          <>
            <IconWithTooltip
              permission="credit.request.package.destroy"
              action="destroy"
              title={t('pages.registered-customers.table.icons.remove')}
              loading={documentToCollect.id && documentBeingRemovedId === documentToCollect.id && !!isDeletingDocument}
              onClick={() => handleDelete(documentToCollect)}
            />
            <Can I="credit.request.package.update">
              <DocumentsToCollectModal
                onSuccess={data => {
                  if (onEdit) onEdit(data);
                }}
              >
                <IconWithTooltip
                  action="edit"
                  onClick={() => {
                    setSelectedDocumentToCollectToEdit(documentToCollect);
                  }}
                  title={t('pages.registered-customers.table.icons.edit')}
                />
              </DocumentsToCollectModal>
            </Can>
          </>
        );
      },
    },
  ];

  return <DataTable columns={columns} data={documentsToCollect} />;
};

import React from 'react';

import { Container, DrillUpLink } from './styles';

import { DrilldownConfig } from '..';

interface Props {
  drilldownHistory: string[];
  doDrillUp: (id: string) => void;
  drilldownConfig: DrilldownConfig[];
}

export const DrillUp = ({ drilldownHistory, doDrillUp, drilldownConfig }: Props) => {
  const getFormattedLabel = (label: string, index: number) => {
    if (drilldownConfig[index].labelFormatter) return drilldownConfig[index].labelFormatter(label);
    return label;
  };

  return (
    <Container>
      {drilldownHistory.map((id, index) => {
        return (
          <DrillUpLink
            key={id}
            onClick={() => {
              doDrillUp(id);
            }}
          >
            <span>{getFormattedLabel(id, index)}</span>
            <span>{index === drilldownHistory.length - 1 ? '' : ' > '}</span>
          </DrillUpLink>
        );
      })}
    </Container>
  );
};

import { useState } from 'react';

import { useAbility } from 'hooks/ability';

import { handleRequest } from '../../services/api/api';
import monitoringApi from '../../services/api/monitoring';

export const useSurveyRequest = () => {
  const ability = useAbility();

  const [isLoading, setIsLoading] = useState(false);

  const getById = async (id: string, params = {}) => {
    const response = ability.can('monitoring.surveyrequest.show', '')
      ? await handleRequest(() => monitoringApi.surveyrequest.getById(id, params), setIsLoading)
      : null;
    return response;
  };

  const store = async (payload: any) => handleRequest(() => monitoringApi.surveyrequest.store(payload), setIsLoading);

  const update = async (id: string, payload: any) =>
    handleRequest(() => monitoringApi.surveyrequest.update(id, payload, true), setIsLoading);

  return { isLoading, store, update, getById };
};

import React from 'react';
import { useTranslation } from 'react-i18next';

import PermissionedComponent from 'components/PermissionedComponent';
import { Button } from 'components/ui/button';
import { GroupForm } from 'components/ui/group-form';
import { useFormalizationGuaranteesStore } from 'pages/CreditRequest/store/useFormalizationGuaranteesStore';

import { PackageList } from './package.list';
import { PackageModal } from './package.modal';

export const Packages = () => {
  const { t } = useTranslation();

  const openPackageModal = useFormalizationGuaranteesStore(state => state.openPackageModal);

  return (
    <GroupForm title={t('pages.credit-request.form.titles.pre-registration-docs')}>
      <PermissionedComponent permission={['credit.request.package.index']}>
        <div className="flex flex-col items-end gap-4 w-full">
          <Button onClick={openPackageModal} permissions={['credit.request.package.store']}>
            {t('pages.credit-request.formalization-guarantees.pre-registration-docs.addTitle')}
          </Button>
          <PackageList />
        </div>

        <PackageModal />
      </PermissionedComponent>
    </GroupForm>
  );
};

import React from 'react';
import { useTranslation } from 'react-i18next';

import { Container, UserImg } from './styles';
import ChatItemProps, { CommentMessage } from './types';

const CommentItem: React.FC<ChatItemProps> = ({ isMe, user, messages, date }) => {
  const { t } = useTranslation();

  return (
    <Container className={`${isMe ? 'request-comments-user-me' : 'request-comments-user-other'}`}>
      <div className="comments">
        {date && (
          <div key={date.key} className="date">
            {`${date.day} ${t(`geral.months.${date.month}`)} ${date.year}`}
          </div>
        )}
        {messages &&
          messages.map(({ comment, id, hour }: CommentMessage, index) => {
            if (messages.length === index + 1) {
              return (
                <div key={id} className="last-comment-item">
                  <div className="comment-container">
                    <div className="comment">
                      {comment} <span className="comment-hour">{hour}</span>
                    </div>
                    <div className="username">{user.name}</div>
                  </div>
                  <div className="perfil-container">
                    {user?.photo ? (
                      <div className="perfil">
                        <UserImg src={user?.photo} alt="avatar" />
                      </div>
                    ) : (
                      <div className="perfil">{user.initials}</div>
                    )}
                  </div>
                </div>
              );
            }
            return (
              <div key={id} className="comment-item">
                <div className="comment">
                  {comment} <span className="comment-hour">{hour}</span>
                </div>
              </div>
            );
          })}
      </div>
    </Container>
  );
};

export default CommentItem;

import React from 'react';

import { Spin } from 'antd';

import {
  CONTRACT_STATUS_APPROVED_EMISSION,
  CONTRACT_STATUS_APPROVED_SIGNATURE,
  CONTRACT_STATUS_VALIDATE_EMISSION,
  CONTRACT_STATUS_VALIDATE_SIGNATURE,
} from '../../../../configs/constants';
import { useContractContext } from '../../context/ContractContext';
import { Stage } from './Stage';
import { Container, Wrapper } from './styles';

export const DraftProgress = () => {
  const { contract, isFetching, needEmissionValidation, needSignatureValidation } = useContractContext();

  const timelinePointsExcludingUnecessaryValidations = contract?.timeline?.filter(timelinePoint => {
    return (
      (timelinePoint.uuid === CONTRACT_STATUS_VALIDATE_EMISSION && needEmissionValidation) ||
      (timelinePoint.uuid === CONTRACT_STATUS_APPROVED_EMISSION && needEmissionValidation) ||
      (timelinePoint.uuid === CONTRACT_STATUS_VALIDATE_SIGNATURE && needSignatureValidation) ||
      (timelinePoint.uuid === CONTRACT_STATUS_APPROVED_SIGNATURE && needSignatureValidation) ||
      (timelinePoint.uuid !== CONTRACT_STATUS_APPROVED_SIGNATURE &&
        timelinePoint.uuid !== CONTRACT_STATUS_VALIDATE_SIGNATURE &&
        timelinePoint.uuid !== CONTRACT_STATUS_APPROVED_EMISSION &&
        timelinePoint.uuid !== CONTRACT_STATUS_VALIDATE_EMISSION &&
        timelinePoint.active)
    );
  });

  return (
    <Wrapper>
      <Spin spinning={isFetching}>
        <Container>
          {timelinePointsExcludingUnecessaryValidations?.map(timelinePoint => (
            <Stage
              key={timelinePoint.status}
              title={timelinePoint.status}
              completed={!!timelinePoint.date}
              username={timelinePoint.user}
              date={timelinePoint.date}
            />
          ))}
        </Container>
      </Spin>
    </Wrapper>
  );
};

import styled, { css } from 'styled-components';

export const TabTitleWrapper = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: end;
  padding-bottom: 20px;
`;

export const Container = styled.div<{ biggerButtonWrapper: boolean }>`
  display: grid;
  grid-template-rows: ${({ biggerButtonWrapper }) => (biggerButtonWrapper ? '80% 20%' : '85% 15%')};
  height: 100%;
  position: relative;
`;

export const Title = styled.h4`
  color: ${({ theme: { colors } }) => colors.primary};
  display: flex;
  gap: 10px;
  align-items: center;
  width: min-content;
`;

export const FormWrapper = styled.div`
  overflow-y: auto;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  a {
    font-size: 0.8rem;
    color: ${({ theme: { colors } }) => colors.greys.grey08};
    text-decoration: underline;
    margin-top: 10px;
  }
`;

export const AddSubscriber = styled.a<{ mini?: boolean }>`
  ${({ mini }) =>
    mini &&
    css`
      font-size: 0.7rem;
    `}
  text-decoration: underline;
`;

export const TabTitleButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
`;

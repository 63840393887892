import { useState } from 'react';

import { handleRequest } from 'services/api/api';
import creditApi from 'services/api/credit';

import { GuaranteesResumeData } from '../../@types/data/GuaranteesResumeData';

export const useGuarantees = () => {
  const [isFetchingStatuses, setIsFetchingStatuses] = useState(false);
  const [isFetchingGuaranteesTypes, setIsFetchingGuaranteesTypes] = useState(false);
  const [isFetchingGuaranteesStatuses, setIsFetchingGuaranteesStatuses] = useState(false);
  const [isFetchingGuaranteesByCreditRequestId, setIsFetchingGuaranteesByCreditRequestId] = useState(false);
  const [isFetchingGuaranteesResume, setIsFetchingGuaranteesResume] = useState(false);
  const [isForcingSapIntegration, setIsForcingSapIntegration] = useState(false);
  const [isUpdatingSapStatus, setIsUpdatingSapStatus] = useState(false);
  const [isFetchingIntegrations, setIsFetchingIntegrations] = useState(false);

  const getStatuses = () => {
    return handleRequest(() => creditApi.partialLimit.status(), setIsFetchingStatuses);
  };

  const getGuaranteesTypes = async (params = {}) => {
    const response = handleRequest(() => creditApi.partialLimit.getTypes(params), setIsFetchingGuaranteesTypes);
    return response;
  };

  const getGuaranteesStatuses = async (params = {}) => {
    const response = handleRequest(() => creditApi.partialLimit.getStatuses(params), setIsFetchingGuaranteesStatuses);
    return response;
  };

  const getGuaranteesByCreditRequestId = async (creditRequestId: string, params = {}) => {
    const response = handleRequest(
      () => creditApi.partialLimit.get(creditRequestId, params),
      setIsFetchingGuaranteesByCreditRequestId,
    );
    return response;
  };

  const getGuaranteesResume = async (creditRequestId: string, params = {}): Promise<GuaranteesResumeData> => {
    const response = await handleRequest(
      () => creditApi.partialLimit.getResume(creditRequestId, params),
      setIsFetchingGuaranteesResume,
    );
    return response;
  };

  const updateSapStatus = async (creditRequestId: string, params = {}) => {
    const response = handleRequest(
      () => creditApi.partialLimit.updateSapStatus(creditRequestId, params),
      setIsUpdatingSapStatus,
    );
    return response;
  };

  const forceSapIntegration = async (guaranteeId: string, params = {}) => {
    const response = handleRequest(
      () => creditApi.partialLimit.forceSapIntegration(guaranteeId, params),
      setIsForcingSapIntegration,
    );
    return response;
  };

  const getIntegrationsByGuaranteeId = async (guaranteeId: string, params = {}) => {
    const response = handleRequest(
      () => creditApi.partialLimit.getIntegrationsByGuaranteeId(guaranteeId, params),
      setIsFetchingIntegrations,
    );
    return response;
  };

  return {
    getStatuses,
    isFetchingStatuses,
    getGuaranteesTypes,
    isFetchingGuaranteesTypes,
    getGuaranteesStatuses,
    isFetchingGuaranteesStatuses,
    getGuaranteesByCreditRequestId,
    isFetchingGuaranteesByCreditRequestId,
    getGuaranteesResume,
    isFetchingGuaranteesResume,
    forceSapIntegration,
    isForcingSapIntegration,
    updateSapStatus,
    isUpdatingSapStatus,
    isFetchingIntegrations,
    getIntegrationsByGuaranteeId,
  };
};

import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useIsMutating } from '@tanstack/react-query';
import { Dialog, DialogContent, DialogFooterForm, DialogHeader } from 'components/ui/dialog';
import { useFormalizationGuaranteesStore } from 'pages/CreditRequest/store/useFormalizationGuaranteesStore';
import { FormRef } from 'types/FormRef';

import { PackageForm, packageFormInitialValues } from './package.form';

export const PackageModal = () => {
  const { t } = useTranslation();
  const formRef = useRef<FormRef>(null);

  const isPackageModalOpen = useFormalizationGuaranteesStore(state => state.isPackageModalOpen);
  const setIsPackageModalOpen = useFormalizationGuaranteesStore(state => state.setIsPackageModalOpen);
  const setSelectedPackage = useFormalizationGuaranteesStore(state => state.setSelectedPackage);
  const selectedPackage = useFormalizationGuaranteesStore(state => state.selectedPackage);

  const isCheckingPackages = useIsMutating({
    mutationKey: ['getCheckPackages'],
  });
  const isStoringPackage = useIsMutating({
    mutationKey: ['storePackage'],
  });
  const isUpdatingPackage = useIsMutating({
    mutationKey: ['updatePackage'],
  });

  return (
    <Dialog open={isPackageModalOpen} onOpenChange={setIsPackageModalOpen}>
      <DialogContent className="w-[100%] h-[100%] max-h-[100vh]">
        <DialogHeader>
          {selectedPackage
            ? t('pages.credit-request.formalization-guarantees.pre-registration-docs.modalPackage.titleEdit')
            : t('pages.credit-request.formalization-guarantees.pre-registration-docs.modalPackage.title')}
        </DialogHeader>

        <PackageForm
          ref={formRef}
          onSuccess={() => {
            setIsPackageModalOpen(false);
          }}
        />

        <DialogFooterForm
          isLoading={!!isCheckingPackages || !!isStoringPackage || !!isUpdatingPackage}
          onConfirm={() => {
            formRef.current?.submit();
          }}
          onCancel={() => {
            setIsPackageModalOpen(false);
            setSelectedPackage(null);
            formRef.current?.form.reset(packageFormInitialValues);
          }}
        />
      </DialogContent>
    </Dialog>
  );
};

import styled from 'styled-components';

export const HidenFooterHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  button {
    border: none;
    background: none;
    cursor: pointer;
    color: ${({ theme: { colors } }) => colors.greys.grey08};

    &:hover {
      color: ${({ theme: { colors } }) => colors.primary};
    }
  }
`;

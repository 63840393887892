import { useState } from 'react';

import { DTOErrorResponse } from '@agrodatabr/agrodataui';
import { useAbility } from 'hooks/ability';

import { DTOSurveyCprModel } from '../../@types/dtos/monitoring/DTOSurveyCprModel';
import monitoringApi from '../../services/api/monitoring';
import { ItemsType, usePage } from '../page';

export const useCpr = () => {
  const { alertStatus } = usePage();
  const ability = useAbility();

  const [isFetching, setIsFetching] = useState(false);

  const get = async (params = {}) => {
    try {
      setIsFetching(true);
      const response = ability.can('monitoring.cpr.index', '') ? await monitoringApi.cpr.get(params) : null;
      return response?.data?.data;
    } catch (err) {
      alertStatus(err as DTOErrorResponse, 'error');
      return null;
    } finally {
      setIsFetching(false);
    }
  };

  const getOptions = async (params = {}) => {
    try {
      const response = await monitoringApi.cpr.get(params);

      return {
        data:
          response?.data?.data?.data?.map(cpr => ({
            key: cpr.id,
            value: cpr.id,
            label: cpr.number,
            ...cpr,
          })) || [],
        lastPage: response?.data?.data?.last_page || 1,
      };
    } catch (err) {
      alertStatus(err as DTOErrorResponse, 'error');
      return { data: [] as ItemsType[], lastPage: 1 };
    } finally {
      setIsFetching(false);
    }
  };

  const getById = async (id: string) => {
    setIsFetching(true);
    const params = {
      params: {
        id,
      },
    };

    try {
      const response = await monitoringApi.cpr.get(params);
      return response.data.data.data[0];
    } catch (err) {
      alertStatus(err as DTOErrorResponse, 'error');
      return null;
    } finally {
      setIsFetching(false);
    }
  };

  const getSurveyCprById = async (id: string): Promise<DTOSurveyCprModel | null> => {
    const cpr = await getById(id);

    if (!cpr) return null;

    return {
      number: cpr.number,
      subsidiary: cpr.subsidiary.name,
      owner: cpr.owner.name,
      farm: cpr.farm.name,
      subdivision: cpr.subdivision,
      cultureName: cpr.culture?.name,
      culture: cpr.culture,
      warranty_area: cpr.warranty_area,
    };
  };

  return { getById, isFetching, getSurveyCprById, get, getOptions };
};

import React, { forwardRef, useEffect, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import Viewer from 'react-viewer';

import { message, Modal } from 'antd';
import { DocxViewer } from 'components/DocxViewer';
import { DOCUMENT_TYPES, IMAGE_TYPES } from 'configs/constants';
import { useAttachment } from 'hooks/fetch/useAttachment';

interface Props {
  document: any & {
    id: string;
    extension: string;
  };
  onClose: () => void;
}

export const checkIfFilePreviewerSupportsExtension = (extension: string) => {
  const extensionLowerCase = extension?.toLowerCase();
  return DOCUMENT_TYPES.includes(extensionLowerCase) || IMAGE_TYPES.includes(extensionLowerCase);
};

export const FilePreviewer = forwardRef(({ document, onClose }: Props, ref) => {
  const { preview: previewAttachment } = useAttachment();
  const { t } = useTranslation();
  const isDocument =
    typeof document === 'string' || !document?.extension || DOCUMENT_TYPES.includes(document?.extension);
  const [isVisible, setIsVisible] = React.useState(false);
  const [url, setUrl] = React.useState('');

  useImperativeHandle(ref, () => ({
    openPreviewer: () => setIsVisible(true),
  }));

  const getFileUrl = async () => {
    message.loading(t('components.filePreviewer.loading'), 2);
    const response = await previewAttachment(document.id);
    if (response) {
      setUrl(response);
      setIsVisible(true);
    }
  };

  useEffect(() => {
    if (document) {
      if (typeof document === 'string') {
        setUrl(document);
        setIsVisible(true);
      } else getFileUrl();
    }
  }, [document]);

  return (
    <>
      {isDocument ? (
        <Modal visible={isVisible} onCancel={onClose} footer={null} width="70%">
          <DocxViewer
            url={url}
            useExternalRenderApi={isDocument && document?.extension !== 'pdf' && typeof document !== 'string'}
          />
        </Modal>
      ) : (
        <Viewer
          downloadable={false}
          visible={isVisible}
          onClose={() => {
            setIsVisible(false);
            onClose();
          }}
          images={[
            {
              src: decodeURIComponent(url),
              alt: '',
            },
          ]}
        />
      )}
    </>
  );
});

import React from 'react';
import { useTranslation } from 'react-i18next';
import { HiOutlineClipboardList } from 'react-icons/hi';
import ReactJson from 'react-json-view';

import { Divider, Modal } from 'antd';
import { Button } from 'components';
import appConfigs from 'configs/app';
import moment from 'moment';
import { safeJsonParse } from 'utils/safeJsonParse';

import { DataIntegrationErros } from '../../../../@types/data/IntegrationDataProvider';
import { SAreaIntegrationErrors } from '../styles';

interface PropsModalErros {
  listErrors: DataIntegrationErros[];
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ModalErrors = ({ listErrors, isVisible, setIsVisible }: PropsModalErros) => {
  const { t } = useTranslation();
  return (
    <Modal visible={isVisible} footer={null} onCancel={() => setIsVisible(false)}>
      {listErrors.map(item => {
        const parsedJSON = item.description ? safeJsonParse(item.description) : [];

        return (
          <SAreaIntegrationErrors key={item.id}>
            <div className="area-integration-errors" key={item.id}>
              <div className="field-error-description">
                {item.created_at ? moment(item.created_at).format(appConfigs.formatDateFullTime) : t('notProvided')}{' '}
              </div>
              <div className="area-error-description">
                <div className="description-error">
                  {parsedJSON.length > 1 ? (
                    <ReactJson src={parsedJSON ? (parsedJSON[1] as any) : ''} />
                  ) : (
                    item.description
                  )}
                </div>
                <div className="copy-button">
                  <Button
                    title={t('clipboardCopy')}
                    size="small"
                    onClick={() => navigator.clipboard.writeText(item.description)}
                  >
                    <span>
                      <HiOutlineClipboardList />
                    </span>
                  </Button>
                </div>
              </div>
            </div>
            <Divider />
          </SAreaIntegrationErrors>
        );
      })}
    </Modal>
  );
};

export const CreditRequestValidator = {
  volume: (
    quantity_kg: number,
    quantity_contract: number,
    quantity_received: number,
    quantity_delivery: number,
  ): boolean => {
    // if (contractTotalVolume > quantityContract) {
    //   return Promise.reject(new Error(t('pages.credit-request.messages.contract_volume_exceeded')));
    // }

    // if (quantityContract <= quantityReceived && value > 0) {
    //   return Promise.reject(new Error(t('pages.credit-request.messages.contract_volume_exceeded')));
    // }

    if (quantity_delivery === 0) {
      if (quantity_contract > 0 && quantity_received === 0 && quantity_kg > quantity_contract) {
        return false;
      }
    } else if (quantity_contract > 0 && (quantity_kg > quantity_contract || quantity_kg > quantity_delivery)) {
      return false;
    }

    return true;
  },
};

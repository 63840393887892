import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;

  h2 {
    color: ${({ theme: { colors } }) => colors.primary};
  }

  a {
    text-decoration: underline;
  }
`;

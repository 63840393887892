const columns: any = [
  {
    title: 'pages.register.priceday.table.header.date',
    dataIndex: 'date',
    key: 'date',
  },
  {
    title: 'pages.register.priceday.table.header.value',
    dataIndex: 'value',
    key: 'value',
  },
  {
    title: 'pages.register.priceday.table.header.currency',
    dataIndex: 'currency_type',
    key: 'currency_type',
  },
  {
    title: 'pages.register.priceday.table.header.sub_division',
    dataIndex: 'sub_division_id',
    key: 'sub_division_id',
  },
  {
    title: 'pages.register.priceday.table.header.subsidiary',
    dataIndex: 'subsidiary_id',
    key: 'subsidiary_id',
  },
  {
    title: 'pages.register.priceday.table.header.modality',
    dataIndex: 'incoterm_id',
    key: 'incoterm_id',
  },
  {
    title: 'pages.register.priceday.table.header.base_port',
    dataIndex: 'base_port',
    key: 'base_port',
  },
  {
    title: 'pages.register.priceday.table.header.actions',
    key: 'action',
    dataIndex: 'action',
    align: 'center',
    width: '80px',
    fixed: 'right',
  },
];

export default columns;

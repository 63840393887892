const physical = [
  {
    title: 'pages.registered-customers.table.headers.stage',
    dataIndex: 'stage',
    key: 'stage',
    sorter: true,
    filtered: false,
    width: '150px',
  },
  {
    title: 'pages.registered-customers.table.headers.name',
    dataIndex: 'name',
    key: 'name',
    sorter: true,
    filtered: false,
  },
  {
    title: 'pages.registered-customers.table.headers.code_erp',
    dataIndex: 'code_erp',
    key: 'code_erp',
    sorter: false,
    filtered: false,
  },
  {
    title: 'pages.registered-customers.table.headers.cpf',
    dataIndex: 'cpf',
    key: 'cpf',
    sorter: false,
    filtered: false,
  },
  {
    title: 'pages.registered-customers.table.headers.email',
    dataIndex: 'email',
    key: 'email',
    sorter: false,
    filtered: false,
  },
  {
    title: 'pages.registered-customers.table.headers.cel',
    dataIndex: 'phone',
    key: 'phone',
    sorter: false,
    filtered: false,
  },
  {
    title: 'pages.registered-customers.table.headers.created_at',
    dataIndex: 'created_at',
    key: 'created_at',
    sorter: true,
    filtered: false,
  },
  {
    title: 'pages.registered-customers.table.headers.actions',
    dataIndex: 'action',
    key: 'action',
    fixed: 'right',
    width: '100px',
  },
];

const legal = [
  {
    title: 'pages.registered-customers.table.headers.stage',
    dataIndex: 'stage',
    key: 'stage',
    sorter: true,
    filtered: false,
    width: '150px',
  },
  {
    title: 'pages.registered-customers.table.headers.company_name',
    dataIndex: 'company_name',
    key: 'company_name',
    sorter: true,
    filtered: false,
  },
  {
    title: 'pages.registered-customers.table.headers.code_erp',
    dataIndex: 'code_erp',
    key: 'code_erp',
    sorter: false,
    filtered: false,
  },
  {
    title: 'pages.registered-customers.table.headers.cnpj',
    dataIndex: 'cnpj',
    key: 'cnpj',
    sorter: false,
    filtered: false,
  },
  {
    title: 'pages.registered-customers.table.headers.cel_number',
    dataIndex: 'phone',
    key: 'phone',
    sorter: false,
    filtered: false,
  },
  {
    title: 'pages.registered-customers.table.headers.created_at',
    dataIndex: 'created_at',
    key: 'created_at',
    sorter: true,
    filtered: false,
  },
  {
    title: 'pages.registered-customers.table.headers.actions',
    dataIndex: 'action',
    key: 'action',
    fixed: 'right',
    width: '100px',
  },
];

export default {
  physical,
  legal,
};

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useTable } from '@agrodatabr/agrodataui';
import { Tabs as ATabs } from 'antd';
import { useSerasaCounterparty } from 'hooks/fetch/useSerasaCounterparty';

import { Spin, Tabs } from '../../../../components';
import { Formatter } from '../../../../utils/Formatter';
import { Container } from '../../../Sustainability/styles';
import { PageParams } from '../../../Sustainability/types';
import { Form } from '../Form';
import { useSerasaCounterparties } from '../hooks/useSerasaCounterparties';
import Comments from './Comments';
import { History } from './History';

export const Edit: React.FC = () => {
  const tableController = useTable();
  const { showSerasa } = useSerasaCounterparty();

  const { form, handleSave, handleChangeDocStatus, typePerson, setTypePerson } = useSerasaCounterparties({
    refresh: tableController.refresh,
  });
  const params = useParams<PageParams>();

  const { id: serasaCounterPartyId } = params;
  const { t } = useTranslation();
  const { TabPane } = ATabs;
  const [loading, setLoading] = useState(false);
  const [counterParty, setCounterParty] = useState<any>();

  const loadData = async () => {
    const response = await showSerasa(serasaCounterPartyId);

    form.setFieldsValue({
      ...counterParty,
      created_at: Formatter.date(response.created_at),
      updated_at: Formatter.date(response.updated_at),
    });

    setCounterParty(response);
  };

  useEffect(() => {
    if (serasaCounterPartyId) {
      loadData();
    }
  }, [serasaCounterPartyId]);

  return (
    <Container>
      <Spin spinning={loading}>
        <Tabs defaultActiveKey="tab-historic">
          <TabPane tab={t('pages.serasacounterparty.form.counterparty')} key="tab-general-data" disabled>
            <div className="content-pannel">
              <Form
                form={form}
                setLoading={setLoading}
                handleSave={handleSave}
                // getStatusOptions={getStatusOptions}
                counterPartyBeingEdited={counterParty}
                loading={loading}
                setIsDocSerasa={handleChangeDocStatus}
                isOpen
                typePerson={typePerson}
                setTypePerson={setTypePerson}
              />
            </div>
          </TabPane>
          {serasaCounterPartyId && (
            <>
              <TabPane tab={t('pages.sustainability.form.comments')} key="tab-comments">
                <Comments serasaCounterpartyId={serasaCounterPartyId} />
              </TabPane>
              <TabPane tab={t('pages.sustainability.form.historic')} key="tab-historic">
                {counterParty && <History counterparty={counterParty} />}
              </TabPane>
            </>
          )}
        </Tabs>
      </Spin>
    </Container>
  );
};

export default Edit;

/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';

import { useIsMutating } from '@tanstack/react-query';
import { FixedFooter, IconWithTooltip } from 'components';
import { ShouldRender } from 'components/ShouldRender';
import { useAlertDialog } from 'components/ui/alert-dialog';
import { Button } from 'components/ui/button';
import { Form } from 'components/ui/form';
import { FormGrid } from 'components/ui/form-grid';
import { FormItem } from 'components/ui/form-item';
import { GroupForm } from 'components/ui/group-form';
import { Input, InputWithMask } from 'components/ui/input';
import Loading from 'components/ui/loading';
import { Select } from 'components/ui/select';
import { LEGAL_PERSON_TYPE, PHYSICAL_PERSON_TYPE, RegisteredPersonDraftStage } from 'configs/constants';
import { set, sub } from 'date-fns';
import { useAuth } from 'hooks/auth';
import { useCache } from 'hooks/cache';
import { cn } from 'lib/utils';
import { LoaderCircle } from 'lucide-react';
import { useGetRegisteredCustomerQuery } from 'pages/CreditRequest/api/use-get-registered-customer-query';
import { useStoreRegisteredCustomerMutation } from 'pages/CreditRequest/api/use-store-registered-customer-mutation';
import { useUpdateRegisteredCustomerMutation } from 'pages/CreditRequest/api/use-update-registered-customer-mutation';
import { RegisteredCustomerData, StageHistoryData } from 'types/CreditRequest/RegisteredCustomerData';
import { Validate } from 'utils/Validate';

import { PageParams } from '../types';
import { BankReferenceFormData } from './bank-reference/bank-reference.form';
import { CofcoOpinionFormData } from './cofco-opinion/cofco-opinion.form';
import { CommercialReferenceFormData } from './commercial-reference/commercial-reference.form';
import { DeliveryHistoryFormData } from './delivery-history/delivery-history.form';
import { EconomicGroupFormData } from './economic-groups/economic-groups.form';
import { useFillProviderFields } from './hooks/use-fill-provider-fields';
import { useFindProvider } from './hooks/use-find-provider';
import { LiquidReceiptFormData } from './liquid-receipt/liquid-receipt.form';
import { MainClientFormData } from './main-clients/main-clients.form';
import { MainProviderFormData } from './main-providers/main-providers.form';
import { PartnerFormData } from './partner/partner.form';
import { RegisteredCustomerAddressSection } from './registered-customer-address.section';
import { RegisteredCustomerBankReferenceSection } from './registered-customer-bank-reference.section';
import { RegisteredCustomerChangeLogSection } from './registered-customer-changelog-section';
import { RegisteredCustomerCofcoOpinionSection } from './registered-customer-cofco-opinion.section';
import { RegisteredCustomerCommercialReferenceSection } from './registered-customer-commecial-reference.section';
import { RegisteredCustomerCompanyData } from './registered-customer-company-data.section';
import { RegisteredCustomerComplianceSection } from './registered-customer-compliance.section';
import { RegisteredCustomerDeliveryHistorySection } from './registered-customer-delivery-history.section';
import { RegisteredCustomerDocumentsSection } from './registered-customer-documents.section';
import { RegisteredCustomerEconomicGroupsSection } from './registered-customer-economic-groups.section';
import { RegisteredCustomerGeneralDataSection } from './registered-customer-general-data.section';
import { RegisteredCustomerGoodsAndDebtsSection } from './registered-customer-goods-debts.section';
import { RegisteredCustomerLiquidReceiptSection } from './registered-customer-liquid-receipt.section';
import { RegisteredCustomerMainClientsSection } from './registered-customer-main-clients.section';
import { RegisteredCustomerMainProvidersSection } from './registered-customer-main-providers.section';
import { RegisteredCustomerPartenersSection } from './registered-customer-partners.section';
import { RegisteredCustomerPersonalData } from './registered-customer-personal-data.section';
import { RegisteredCustomerReferenceSquareSection } from './registered-customer-reference-square.section';
import { RegisteredCustomerSimpleAgriculturalPlanSection } from './registered-customer-simple-agricultural-plan.section';
import { RegisteredCustomerStatusHistorySection } from './registered-customer-status-history.section';
import { RegisteredCustomerFormConverter } from './registered-customer.form.converter';
import { SimpleAgriculturalPlanFormData } from './simple-agricultural-plan/simple-agricultural-plan.form';
import { useRegisteredCustomerStore } from './stores/use-registered-customer-store';

export type PersonType = typeof LEGAL_PERSON_TYPE | typeof PHYSICAL_PERSON_TYPE;

type RegisteredCustomerFormProps = {
  customerId?: string;
  type?: PersonType;
  isModulePerson?: boolean;
  isLoading?: boolean;
};

type RegisteredCustomerFormDataBase = {
  id?: string;
  stage_id?: string;
  name?: string;
  email?: string;
  birth_date?: string;
  rg?: string;
  marital_status_id?: string;
  age?: number;
  cod_datasul?: string;
  sap_bp_subsidiary_code?: string;
  company_name?: string;
  fantasy_name?: string;
  state_registration?: string;
  nationality?: string;
  birth_state_id?: string;
  communion_regime_id?: string;
  city_id?: string;
  date_of_establishment?: string;
  spouse?: {
    id?: string;
    name?: string;
    json_data?: {
      rg?: string;
      cpf?: string;
    };
  }[];
  number_of_dependents?: number;
  father?: {
    id?: string;
    name?: string;
  }[];
  mother?: {
    id?: string;
    name?: string;
  }[];
  addresses?: {
    id?: string;
    zip_code?: string;
    state_id?: string;
    city_id?: string;
    neighborhood?: string;
    street?: string;
    complement?: string;
  }[];
  compliance?: {
    id?: string;
    name?: string;
    employee_branch?: string;
    employee_position?: string;
    employee_relationship?: string;
  }[];
  files?: File[];
  partners?: PartnerFormData[];
  bank_reference?: BankReferenceFormData[];
  main_provider?: MainProviderFormData[];
  main_client?: MainClientFormData[];
  commercial_reference?: CommercialReferenceFormData[];
  economic_group?: EconomicGroupFormData[];
  simple_agricultural_plan?: SimpleAgriculturalPlanFormData[];
  liquid_receipt?: LiquidReceiptFormData[];
  delivery_history?: DeliveryHistoryFormData[];
  cofco_opinion?: CofcoOpinionFormData[];
  stage_history?: StageHistoryData[];
  json_data?: {
    step?: number;
    total_steps?: number;
    code_erp?: string;
    phone_number?: string;
    cel_number?: string;
    years_in_square?: number;
    rural_producer_time?: number;
    rural_registration?: string;
    is_employee_relationship?: boolean;
    irpf_annual_income?: number;
    structure_conservation_id?: string;
    technological_level_id?: string;
    productivity_id?: string;
    activity_diversification_id?: string;
    credit_history_id?: string;
    managerial_skill_id?: string;
    customer_cofco_years?: number;
    own_planting_resource?: number;
    reference_square_id?: string;
    is_new_customer?: boolean;
    has_conflict_with_employee?: boolean;
    participates_in_any_economic_group?: boolean;
    assets_debts?: {
      properties_value_total?: number;
      equipments_value_total?: number;
      debts_value_total?: number;
    };
  };
};

type RegisteredCustomerFormDataPhysical = {
  type?: 'App\\Model\\PhysicalPerson';
  // Has mask
  cpf: string;
  cnpj?: string;
};

type RegisteredCustomerFormDataLegal = {
  type?: 'App\\Model\\LegalPerson';
  // Has mask
  cnpj: string;
  cpf?: string;
};

export type RegisteredCustomerFormData = (RegisteredCustomerFormDataPhysical | RegisteredCustomerFormDataLegal) &
  RegisteredCustomerFormDataBase;

export const RegisteredCustomerForm = ({
  customerId,
  type,
  isModulePerson,
  isLoading,
}: RegisteredCustomerFormProps) => {
  const { t } = useTranslation();
  const params = useParams<PageParams>();
  const isPhysicalPerson = type === PHYSICAL_PERSON_TYPE;
  const { findProvider } = useFindProvider({
    isPhysicalPerson,
  });
  const { userIsLinked } = useAuth();
  const cache = useCache();
  const { alert } = useAlertDialog();
  const navigate = useNavigate();

  const [isRegisteredStatus, setIsRegisteredStatus] = useState(false);

  const setPageStatus = useRegisteredCustomerStore(state => state.setPageStatus);
  const setIsModulePerson = useRegisteredCustomerStore(state => state.setIsModulePerson);
  const isViewing = useRegisteredCustomerStore(state => state.isViewing);
  const isEditing = useRegisteredCustomerStore(state => state.isEditing);
  const isNew = useRegisteredCustomerStore(state => state.isNew);
  const setNotSavedData = useRegisteredCustomerStore(state => state.setNotSavedData);
  const formStatus = useRegisteredCustomerStore(state => state.formStatus);
  const setFormStatus = useRegisteredCustomerStore(state => state.setFormStatus);
  const messagesErrors = useRegisteredCustomerStore(state => state.messagesErrors);
  const setDraftState = useRegisteredCustomerStore(state => state.setDraftState);
  const setMessagesErrors = useRegisteredCustomerStore(state => state.setMessagesErrors);

  const storeRegisteredCustomerMutation = useStoreRegisteredCustomerMutation({
    personType: isPhysicalPerson ? 'physical' : 'legal',
  });
  const updateRegisteredCustomerMutation = useUpdateRegisteredCustomerMutation({
    personType: isPhysicalPerson ? 'physical' : 'legal',
  });

  const loadForm = (registeredCustomerData: RegisteredCustomerData) => {
    const formValues = RegisteredCustomerFormConverter.toForm(registeredCustomerData);
    form.reset({ ...formValues, type: (isPhysicalPerson ? PHYSICAL_PERSON_TYPE : LEGAL_PERSON_TYPE) as any });
    setIsRegisteredStatus(formValues.stage_id === RegisteredPersonDraftStage.REGISTERED);
  };

  const getRegistedCustomerQuery = useGetRegisteredCustomerQuery({
    personType: isPhysicalPerson ? 'physical' : 'legal',
    id: customerId ?? params.id ?? '',
  });

  const form = useForm<RegisteredCustomerFormData>({
    defaultValues: {
      type,
      id: customerId ?? '',
      stage_id: '',
      name: '',
      email: '',
      birth_date: sub(new Date(), { years: 18 }).toISOString(),
      rg: '',
      marital_status_id: '',
      age: 18,
      cod_datasul: '',
      sap_bp_subsidiary_code: '',
      company_name: '',
      fantasy_name: '',
      state_registration: '',
      nationality: 'Brasileiro',
      birth_state_id: '',
      date_of_establishment: '',
      city_id: '',
      spouse: [],
      communion_regime_id: '',
      number_of_dependents: undefined,
      father: [],
      mother: [],
      addresses: [],
      partners: [],
      bank_reference: [],
      main_provider: [],
      main_client: [],
      economic_group: [],
      commercial_reference: [],
      simple_agricultural_plan: [],
      liquid_receipt: [],
      delivery_history: [],
      cofco_opinion: [],
      files: [],
      json_data: {
        step: 1,
        total_steps: isPhysicalPerson ? 13 : 7,
        code_erp: '',
        cel_number: '',
        phone_number: '',
        years_in_square: undefined,
        rural_producer_time: undefined,
        rural_registration: '',
        is_employee_relationship: false,
        irpf_annual_income: undefined,
        structure_conservation_id: '',
        technological_level_id: '',
        productivity_id: '',
        activity_diversification_id: '',
        credit_history_id: '',
        managerial_skill_id: '',
        customer_cofco_years: undefined,
        own_planting_resource: undefined,
        reference_square_id: '',
        is_new_customer: true,
        has_conflict_with_employee: false,
        assets_debts: {
          properties_value_total: undefined,
          equipments_value_total: undefined,
          debts_value_total: undefined,
        },
      },
    },
  });

  const { fillProviderFields } = useFillProviderFields({
    isPhysicalPerson,
    form,
  });

  const isGettingProvider = useIsMutating({
    mutationKey: ['getDatasulSapProvider'],
  });

  const handleFindProvider = async () => {
    const docField = form.getValues(isPhysicalPerson ? 'cpf' : 'cnpj');
    if (docField && (isPhysicalPerson ? Validate.cpf(docField) : Validate.cnpj(docField))) {
      const provider = await findProvider({
        document: docField.replaceAll(/\D/g, ''),
      });
      fillProviderFields(provider);
    }
  };

  const handleValidateDocument = (value: string) => {
    if (isPhysicalPerson ? !Validate.cpf(value) : !Validate.cnpj(value)) return t('invalid-document');
    return true;
  };

  const getRequest = () => {
    return isEditing ? updateRegisteredCustomerMutation.mutateAsync : storeRegisteredCustomerMutation.mutateAsync;
  };

  const handleSubmit = () => {
    form.handleSubmit(async values => {
      const parsedValues = await RegisteredCustomerFormConverter.toApi(values);
      const request = getRequest();
      if (!isEditing) delete parsedValues.id;
      const response = await request(parsedValues, {
        onSuccess: res => {
          alert({
            type: 'success',
            title: t('pages.registered-customers.messages.pf.success'),
          });

          if (isEditing) {
            getRegistedCustomerQuery.refetch();
            setMessagesErrors([]);
            setFormStatus('');
          } else {
            navigate(`/registered-customers/${isPhysicalPerson ? 'physical' : 'legal'}/edit/${(res as any).id}`);
            setMessagesErrors([]);
            setFormStatus('');
          }
        },
      });
    }, console.log)();
  };

  async function validateDraft() {
    // const dataFormPerson = physicalPersonForm.getFieldsValue();

    await form.handleSubmit(
      async () => {
        // Se as validações deram certo mudar status para Draft
        const draftStatus = cache.person_stage?.find((s: any) => s.slug.toLowerCase() === 'draft');
        if (draftStatus) {
          await handleSubmit();
        }
      },
      err => {
        console.error('form', err);
        setMessagesErrors([...messagesErrors, t('pages.registered-customers.errors.pf.other')]);
      },
    )();
  }

  async function confirmDraft() {
    setDraftState('saving');
    setMessagesErrors([]);

    await form.trigger().catch(() => {
      setFormStatus('danger');
    });

    validateDraft();
  }

  async function validateRegister() {
    let errors: string[] = [];

    await form.handleSubmit(
      async values => {
        let finalizeValidation = false;

        if (isPhysicalPerson) {
          // Planos agrícolas
          if (!values.simple_agricultural_plan?.length && isPhysicalPerson) {
            setFormStatus('danger');
            errors = [...errors, t('pages.registered-customers.errors.pf.simple_plans')];
            finalizeValidation = true;
          }

          // Referências Comerciais
          if (!values.commercial_reference?.length && isPhysicalPerson) {
            setFormStatus('danger');
            errors = [...errors, t('pages.registered-customers.errors.pf.provider_reference')];
            finalizeValidation = true;
          }
        }
        if (!isPhysicalPerson) {
          // Socios
          if (!values.partners?.length && !isPhysicalPerson) {
            setFormStatus('danger');
            errors = [...errors, t('pages.registered-customers.errors.pj.partner')];
            finalizeValidation = true;
          }

          // Fornecedores
          if (!values.main_provider?.length && !isPhysicalPerson) {
            setFormStatus('danger');
            errors = [...errors, t('pages.registered-customers.errors.pj.provider_reference')];
            finalizeValidation = true;
          }
          // Clientes
          if (!values.main_client?.length && !isPhysicalPerson) {
            setFormStatus('danger');
            errors = [...errors, t('pages.registered-customers.errors.pj.customer_reference')];
            finalizeValidation = true;
          }
        }

        // Grupos Econômicos
        if (!values.economic_group?.length && values.json_data.participates_in_any_economic_group) {
          setFormStatus('danger');
          errors = [...errors, t('pages.registered-customers.errors.pf.economic_groups')];
          finalizeValidation = true;
        }

        // Referências Bancárias
        if (!values.bank_reference?.length) {
          setFormStatus('danger');
          errors = [...errors, t('pages.registered-customers.errors.pf.bank_reference')];
          finalizeValidation = true;
        }

        if (!finalizeValidation) {
          // Se as validações deram certo mudar status para Pending
          const registeredStatus = cache.person_stage?.find((s: any) => s.slug.toLowerCase() === 'registered');
          if (registeredStatus) {
            form.setValue('stage_id', registeredStatus.id);
            await handleSubmit();
          }
        }
      },
      err => {
        console.error('form', err);
        setFormStatus('danger');
        errors = [...errors, t('pages.registered-customers.errors.pf.other')];
      },
    )();

    setMessagesErrors(errors);
  }

  async function confirmedFinalize() {
    setMessagesErrors([]);
    setDraftState('finalizing');

    await form.trigger().catch(() => {
      setFormStatus('danger');
    });

    validateRegister();
  }

  async function confirmFinalize() {
    return alert({
      type: 'confirm',
      title: t(`modal.warning`),
      subTitle: t('pages.registered-customers.messages.pf.warningFinalize'),
      onOk: confirmedFinalize,
      okText: t('pages.registered-customers.messages.confirmButton'),
    });
  }

  useEffect(() => {
    form.setValue('type', type);
  }, [type]);

  useEffect(() => {
    if (getRegistedCustomerQuery.data) loadForm(getRegistedCustomerQuery.data);
  }, [getRegistedCustomerQuery.data]);

  useEffect(() => {
    setIsModulePerson(isModulePerson);
    setPageStatus(params.permission as 'new' | 'edit' | 'view');
  }, [params.permission, isModulePerson]);

  // !LIBERAR MODAL DE REDIRECIONAR PARA CUSTOMER EXISTENTE

  useEffect(() => {
    if (Object.keys(form.formState.touchedFields).length) setNotSavedData(true);
  }, [JSON.stringify(form.formState.touchedFields)]);

  return (
    <div>
      <Loading
        isLoading={
          getRegistedCustomerQuery.isLoading ||
          storeRegisteredCustomerMutation.isPending ||
          updateRegisteredCustomerMutation.isPending ||
          isLoading
        }
      >
        <Form form={form} onSubmit={handleSubmit} className="gap-2 flex flex-col">
          <GroupForm title={isPhysicalPerson ? t('personal-data') : t('company-data')}>
            <FormGrid>
              <FormItem name="type" label={t('person-type')}>
                <Select
                  className="w-full"
                  options={[
                    {
                      key: PHYSICAL_PERSON_TYPE,
                      value: PHYSICAL_PERSON_TYPE,
                      label: t('geral.person-type.physical'),
                    },
                    {
                      key: LEGAL_PERSON_TYPE,
                      value: LEGAL_PERSON_TYPE,
                      label: t('geral.person-type.legal'),
                    },
                  ]}
                  disabled
                />
              </FormItem>

              <FormItem
                name={isPhysicalPerson ? 'cpf' : 'cnpj'}
                label={isPhysicalPerson ? t('cpf') : t('cnpj')}
                rules={{
                  required: true,
                  validate: {
                    isDocumentValid: handleValidateDocument,
                  },
                }}
              >
                <InputWithMask
                  disabled={isViewing || !isModulePerson}
                  suffix={
                    <ShouldRender
                      condition={!isGettingProvider}
                      fallback={
                        <div className="flex items-center justify-center">
                          <span className="animate-spin min-w-min min-h-min text-primary">
                            <LoaderCircle size={18} />
                          </span>
                        </div>
                      }
                    >
                      <IconWithTooltip
                        action="search"
                        className="icon-in-input-suffix"
                        title={isPhysicalPerson ? t('search-cpf') : t('search-cnpj')}
                        onClick={handleFindProvider}
                      />
                    </ShouldRender>
                  }
                  mask={isPhysicalPerson ? '999.999.999-99' : '99.999.999/9999-99'}
                />
              </FormItem>

              {isPhysicalPerson ? <RegisteredCustomerPersonalData /> : <RegisteredCustomerCompanyData />}
            </FormGrid>
          </GroupForm>

          <RegisteredCustomerAddressSection />

          <RegisteredCustomerComplianceSection personType={isPhysicalPerson ? 'physical' : 'legal'} />

          <ShouldRender condition={!isPhysicalPerson}>
            <RegisteredCustomerPartenersSection />
          </ShouldRender>

          <RegisteredCustomerBankReferenceSection />

          <ShouldRender condition={isPhysicalPerson}>
            <RegisteredCustomerCommercialReferenceSection />
            <RegisteredCustomerGeneralDataSection />
            <RegisteredCustomerGoodsAndDebtsSection />
            <RegisteredCustomerReferenceSquareSection />
            <RegisteredCustomerSimpleAgriculturalPlanSection />
            <RegisteredCustomerLiquidReceiptSection />
            <RegisteredCustomerDeliveryHistorySection />
            <RegisteredCustomerCofcoOpinionSection />
          </ShouldRender>

          <ShouldRender condition={!isPhysicalPerson}>
            <RegisteredCustomerMainProvidersSection />
            <RegisteredCustomerMainClientsSection />
          </ShouldRender>

          <RegisteredCustomerEconomicGroupsSection />

          <RegisteredCustomerDocumentsSection />

          <ShouldRender condition={!isNew}>
            <RegisteredCustomerStatusHistorySection />
            <RegisteredCustomerChangeLogSection
              customerId={customerId ?? params.id ?? ''}
              personType={isPhysicalPerson ? 'physical' : 'legal'}
            />
          </ShouldRender>
        </Form>

        <ShouldRender condition={!isViewing && isModulePerson && userIsLinked && formStatus !== ''}>
          <FixedFooter status={formStatus}>
            <div className="form-footer-feedback">
              {formStatus === 'danger' && (
                <>
                  <div className="feedback-title">{t('pages.registered-customers.errors.pf.title')}</div>
                  {messagesErrors?.map((messageError: string) => (
                    <div className="feedback-description" key={messageError}>
                      {messageError}
                    </div>
                  ))}
                </>
              )}

              {formStatus === 'warning' && (
                <>
                  <div className="feedback-title">{t('pages.registered-customers.errors.pf.warning')}</div>
                </>
              )}
            </div>

            <div className="form-footer-actions gap-4 flex items-center">
              {!isRegisteredStatus && (
                <Button
                  variant={formStatus ? 'secondary' : 'default'}
                  className={cn('border-primary bg-white text-primary')}
                  onClick={confirmDraft}
                  isLoading={
                    storeRegisteredCustomerMutation.isPending ||
                    updateRegisteredCustomerMutation.isPending ||
                    getRegistedCustomerQuery.isLoading ||
                    getRegistedCustomerQuery.isFetching ||
                    !!isGettingProvider
                  }
                  // loading={saving}
                  // disabled={loading || saving || finalizing}
                >
                  {t('pages.registered-customers.form.pf.buttons.save')}
                </Button>
              )}

              <Button
                variant={formStatus ? 'secondary' : 'default'}
                className={cn('border-white bg-primary text-white')}
                onClick={confirmFinalize}
                isLoading={
                  storeRegisteredCustomerMutation.isPending ||
                  updateRegisteredCustomerMutation.isPending ||
                  getRegistedCustomerQuery.isLoading ||
                  getRegistedCustomerQuery.isFetching ||
                  !!isGettingProvider
                }
                // loading={finalizing}
                // disabled={loading || saving || finalizing}
              >
                {t('pages.registered-customers.form.pf.buttons.finalize')}
              </Button>
            </div>
          </FixedFooter>
        </ShouldRender>
      </Loading>
    </div>
  );
};

import React, { forwardRef } from 'react';

import { PermissionedField } from 'components/PermissionedField';
import { ComboBoxInfinite, ComboBoxInfiniteProps } from 'components/ui/combo.infinite';
import { usePagination } from 'hooks/usePagination';
import { useGetNotariesOptionsQuery } from 'pages/CreditRequest/api/useGetNotariesQuery';

type Props<T = unknown> = Omit<ComboBoxInfiniteProps<T>, 'infiniteQuery'> & {
  cityId?: string;
};

export const NotarySelect = forwardRef((props: Props, ref: React.Ref<HTMLInputElement> | null) => {
  const { dispatchPagination, paginationConfig } = usePagination();
  const notariesInfiniteQuery = useGetNotariesOptionsQuery({
    ...paginationConfig,
    city: props?.cityId || '',
  });

  return (
    <PermissionedField permission={['register.notary.index']}>
      <ComboBoxInfinite
        {...props}
        ref={ref}
        infiniteQuery={notariesInfiniteQuery}
        onSearch={value => dispatchPagination({ type: 'SET_SEARCH', payload: value })}
      />
    </PermissionedField>
  );
});
NotarySelect.displayName = 'NotarySelect';

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaInfoCircle } from 'react-icons/fa';

import { AttachmentInput, AttachmentInputTranslation, InfiniteSelect } from '@agrodatabr/agrodataui';
import { Col, Form, InputNumber, Row, Input as AntInput, Tooltip } from 'antd';
import { FormItem } from 'components';
import { FilePreviewer } from 'components/FilePreviewer';
import PermissionedComponent from 'components/PermissionedComponent';
import { ShouldRender } from 'components/ShouldRender';
import { useCreditRequestContext } from 'context/CreditRequestContext';
import { useGuarantees } from 'hooks/fetch/useGuarantees';
import { usePrefinModalityRules } from 'pages/CreditRequest/rules/prefin/usePrefinModalityRules';
import { Formatter } from 'utils/Formatter';

import { GuaranteeTypeData } from '../../../../../../@types/data/GuaranteeTypeData';
import { GuaranteeFormProps } from './GuaranteeForm.types';

export const GuaranteeForm = ({ form, limitTotal, differences }: GuaranteeFormProps) => {
  const { t } = useTranslation();
  const { getGuaranteesTypes } = useGuarantees();
  const { shouldShowLimitBarterInputs, shouldShowLimitCashInputs, shouldShowLimitInputs } = usePrefinModalityRules();
  const { creditForm } = useCreditRequestContext();

  const [fileToPreview, setFileToPreview] = useState<any>(null);

  const attachmentInputTranslation = {
    destroyIconText: t('pages.monitoring.surveyrequest.form.attachments.destroy.icon'),
    downloadIconText: t('pages.monitoring.surveyrequest.form.attachments.download.icon'),
    dragAndDropUploadText: t('pages.monitoring.surveyrequest.form.attachments.drag'),
    existingAttachmentsText: t('pages.monitoring.surveyrequest.form.attachments.existing'),
    onUploadError: t('pages.monitoring.surveyrequest.form.attachments.upload.error'),
    onUploadSuccess: t('pages.monitoring.surveyrequest.form.attachments.upload.success'),
    downloadAllButton: t('downloadAll'),
    downloadingAllMessage: t('messages.downloadingAllFiles'),
    alerts: {
      destroy: {
        success: t('pages.monitoring.surveyrequest.form.attachments.destroy.success'),
      },
    },
  } as AttachmentInputTranslation;

  const handleGetGuaranteeTypes = async (value: string, page: number, perPage: number) => {
    const response = await getGuaranteesTypes({ params: { search: value, page, page_size: perPage } });
    return {
      data: response.data.map((guaranteeType: GuaranteeTypeData) => ({
        ...guaranteeType,
        key: guaranteeType.id,
        value: guaranteeType.id,
        label: guaranteeType.name,
      })),
      lastPage: response.last_page,
    };
  };

  const handlePreviewFile = (document: any) => setFileToPreview(document);

  const handleRemoveAttachment = (file: any) => {
    const newDocuments = form
      .getFieldValue('documents')
      .filter((document: any) => document.uid !== file.uid && !document.id);
    form.setFieldsValue({ documents: newDocuments });
  };

  return (
    <Form form={form}>
      <Row gutter={[26, 26]} justify="center">
        {/* <Col span={8}>
          <FormItem label={t('pages.credit-request.modal-limit.approved_value')} name="approved_value">
            <InputNumber<string>
              value={approvedValue.toString()}
              disabled
              formatter={(value: string | number) => Formatter.money(value, 2, null, true)}
              parser={(value: string) => Formatter.moneyParser(value)}
            />
          </FormItem>
        </Col>
        <Col span={8}>
          <FormItem
            label={t('pages.credit-request.modal-limit.partial_limit_requested')}
            name="limitTotal"
            initialValue={limitTotal}
          >
            <InputNumber<string>
              value={limitTotal.toString()}
              disabled
              formatter={(value: string | number) => Formatter.money(value, 2, null, true)}
              parser={(value: string) => Formatter.moneyParser(value)}
            />
          </FormItem>
        </Col>
        <Col span={8}>
          <FormItem label={t('pages.credit-request.modal-limit.available_value')} name="available_value">
            <InputNumber<string>
              value={limitTotal.toString()}
              disabled
              formatter={(value: string | number) => Formatter.money(value, 2, null, true)}
              parser={(value: string) => Formatter.moneyParser(value, 2)}
            />
          </FormItem>
        </Col> */}
      </Row>
      <Row gutter={26} justify="start">
        <ShouldRender condition={shouldShowLimitInputs}>
          <Col span={8}>
            <FormItem
              label={
                <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                  <span>{t('pages.credit-request.modal-limit.form.value')}</span>
                  <Tooltip title={t('pages.credit-request.modal-limit.form.value_tooltip')}>
                    <span style={{ display: 'flex' }}>
                      <FaInfoCircle />
                    </span>
                  </Tooltip>
                </div>
              }
              name="value"
              rules={[
                () => ({
                  validator(_, value: number) {
                    if (value === undefined) {
                      value = 0;
                    }

                    if (value === 0) {
                      return Promise.reject(t('pages.credit-request.modal-limit.form.validator.required'));
                    }
                    if (value > creditForm.getFieldValue('requested_amount')) {
                      return Promise.reject(
                        t('pages.credit-request.modal-limit.form.validator.requested_value.exceeded'),
                      );
                    }
                    if (value > differences.differenceValue) {
                      return Promise.reject(
                        t('pages.credit-request.modal-limit.form.validator.difference_value.exceeded'),
                      );
                    }
                    return Promise.resolve();
                  },
                  required: true,
                }),
              ]}
            >
              <InputNumber<string>
                formatter={(value: string | number | undefined) => Formatter.money(value ?? 0)}
                parser={(value: string | undefined) => Formatter.moneyParser(value ?? '0')}
                maxLength={20}
              />
            </FormItem>
          </Col>
        </ShouldRender>

        <ShouldRender condition={shouldShowLimitCashInputs}>
          <Col span={8}>
            <FormItem
              label={t('pages.credit-request.modal-limit.value_cash')}
              name="value_cash"
              rules={[
                () => ({
                  validator(_, value: number) {
                    if (value === undefined) {
                      value = 0;
                    }
                    if (value === 0) {
                      return Promise.reject(
                        t('pages.credit-request.modal-limit.form.validator.requested_value_cash.required'),
                      );
                    }
                    if (value > creditForm.getFieldValue('requested_amount_cash')) {
                      return Promise.reject(
                        t('pages.credit-request.modal-limit.form.validator.requested_value_cash.exceeded'),
                      );
                    }
                    if (value > differences.differenceCash) {
                      return Promise.reject(
                        t('pages.credit-request.modal-limit.form.validator.difference_value_cash.exceeded'),
                      );
                    }
                    return Promise.resolve();
                  },
                  required: true,
                }),
              ]}
            >
              <InputNumber<string>
                value={limitTotal.toString()}
                formatter={(value: string | number | undefined) => Formatter.money(value, 2, null, true)}
                parser={(value: string | undefined) => Formatter.moneyParser(value, 2)}
              />
            </FormItem>
          </Col>
        </ShouldRender>

        <ShouldRender condition={shouldShowLimitBarterInputs}>
          <Col span={8}>
            <FormItem
              label={t('pages.credit-request.modal-limit.value_barter')}
              name="value_barter"
              rules={[
                () => ({
                  validator(_, value: number) {
                    if (value === undefined) {
                      value = 0;
                    }
                    if (value === 0) {
                      return Promise.reject(
                        t('pages.credit-request.modal-limit.form.validator.requested_value_barter.required'),
                      );
                    }
                    if (value > creditForm.getFieldValue('requested_amount_barter')) {
                      return Promise.reject(
                        t('pages.credit-request.modal-limit.form.validator.requested_value_barter.exceeded'),
                      );
                    }
                    if (value > differences.differenceBarter) {
                      return Promise.reject(
                        t('pages.credit-request.modal-limit.form.validator.difference_value_barter.exceeded'),
                      );
                    }
                    return Promise.resolve();
                  },
                  required: true,
                }),
              ]}
            >
              <InputNumber<string>
                value={limitTotal.toString()}
                formatter={(value: string | number | undefined) => Formatter.money(value, 2, null, true)}
                parser={(value: string | undefined) => Formatter.moneyParser(value, 2)}
              />
            </FormItem>
          </Col>
        </ShouldRender>

        <PermissionedComponent permission="credit.request.partial.limit.index">
          <Col span={8}>
            <FormItem
              label={t('pages.credit-request.modal-limit.credit_request_partial_limit_type_id')}
              name="credit_request_partial_limit_type_id"
              rules={[{ required: true }]}
            >
              <InfiniteSelect showSearch getOptions={handleGetGuaranteeTypes} />
            </FormItem>
          </Col>
        </PermissionedComponent>

        {/* <Col span={8}>
          <FormItem
            label={t('pages.credit-request.modal-limit.expiration_date')}
            name="expiration_date"
            rules={[{ required: true }]}
          >
            <DatePicker
              locale={ptBR}
              mode="date"
              format={appConfigs.formatDate}
              disabledDate={date => date.isBefore(moment().subtract(1, 'days'))}
            />
          </FormItem>
        </Col> */}
      </Row>

      <Row gutter={[26, 26]} justify="center">
        <Col span={24}>
          <FormItem
            label={t('pages.sustainability.form.registrationModal.attachments')}
            name="documents"
            rules={[{ required: true }]}
            style={{ width: '100%' }}
          >
            <AttachmentInput
              multiple
              translation={attachmentInputTranslation}
              onRemove={handleRemoveAttachment}
              handleDestroy={(() => {
                return new Promise(() => null) as any;
              })()}
              downloadMultiple={() => {
                return new Promise(() => null) as any;
              }}
              handleDownload={() => {
                return new Promise(() => null) as any;
              }}
              handlePreview={async file => {
                handlePreviewFile(file);
                return true;
              }}
            />
          </FormItem>
        </Col>
      </Row>

      <FormItem label={t('pages.credit-request.modal-limit.form.comment')} name="comment" rules={[{ required: true }]}>
        <AntInput.TextArea rows={5} />
      </FormItem>

      {fileToPreview && <FilePreviewer document={fileToPreview} onClose={() => setFileToPreview(null)} />}
    </Form>
  );
};

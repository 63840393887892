import { useState } from 'react';

import { handleRequest } from '../../services/api/api';
import dashboardApi from '../../services/api/dashboard';

export const useDashboardMonitoringPrefin = () => {
  const [isFetchingCharts, setIsFetchingCharts] = useState(false);
  const [isFetchingCards, setIsFetchingCards] = useState(false);
  const [isFetchingResume, setIsFechingResume] = useState(false);

  const getMonitoringPrefinCards = async (params = {}) => {
    const { summary } = await handleRequest(() => dashboardApi.monitoringPrefin.showCards(params), setIsFetchingCards);
    return { summary };
  };

  const getMonitoringPrefinCharts = async (params = {}) => {
    setIsFetchingCharts(true);
    const dataChartVisitsMonitoring = await handleRequest(() =>
      dashboardApi.monitoringPrefin.showChartVisitsMonitoring(params),
    );
    const dataChartVisitsConditionMonitoring = await handleRequest(() =>
      dashboardApi.monitoringPrefin.showChartVisitsCondition(params),
    );
    const dataChartMap = await handleRequest(() => dashboardApi.monitoringPrefin.showMap(params));
    setIsFetchingCharts(false);
    return {
      dataChartVisitsMonitoring,
      dataChartVisitsConditionMonitoring,
      dataChartMap,
    };
  };

  const getMonitoringPrefinResume = async (params?: { params: unknown }, filter = 'all') => {
    const response = await handleRequest(
      () => dashboardApi.monitoringPrefin.showResume({ ...params, parent: filter }),
      setIsFechingResume,
    );
    return response || { data: [] as any[], total: 0, last_page: 0 };
  };

  const getResumeReport = async (params: any) => {
    const response = await handleRequest(() => dashboardApi.reports.listReportResumeMonitoring(params));
    return response;
  };

  const generateExportResume = (values: any) => {
    return dashboardApi.reports.generateExportResumeMonitoring({ ...values });
  };

  return {
    isFetchingCharts,
    isFetchingCards,
    isFetchingResume,
    getMonitoringPrefinCards,
    getMonitoringPrefinCharts,
    getMonitoringPrefinResume,
    getResumeReport,
    generateExportResume,
  };
};

import styled, { keyframes } from 'styled-components';

const fadeInFwd = keyframes`
 0% {
   -webkit-transform: translateZ(-80px);
           transform: translateZ(-80px);
   opacity: 0;
 }
 100% {
   -webkit-transform: translateZ(0);
           transform: translateZ(0);
   opacity: 1;
 }
`;

export const SFormContainer = styled.div`
  display: 'block';
  border: solid 1px ${({ theme: { colors } }) => colors.greys.grey03};
  border-radius: 4px;
  background: #ffffff;
  padding: 8px;
  margin-top: 8px;
  margin-bottom: 8px;
  animation: ${fadeInFwd} 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;

  h2 {
    background: ${({ theme: { colors } }) => colors.greys.grey01};
    color: ${({ theme: { colors } }) => colors.secondary};
    font-family: ${({ theme: { fonts } }) => fonts.primary};
    font-weight: 700;
    font-size: 16px;
    padding: 8px;
    display: block;
    text-align: center;
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 16px;
  }

  .ant-badge-status-dot {
    width: 12px !important;
    height: 12px !important;
  }
`;

export const SFormButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  button + button {
    margin-left: 8px;
  }
`;

import { useState } from 'react';

import SustainabilityData from '../../types/Sustainability/SustainabilityData';
import { handleRequest } from '../../services/api/api';
import sustainabilityApi from '../../services/api/sustainability';

export const useSustainability = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [isFetchingReportStatuses, setIsFetchingReportStatuses] = useState(false);

  const sendRequest = async (request: any) => {
    return handleRequest(request, setIsFetching);
  };

  const showReport = async (sustainabilityId: string): Promise<SustainabilityData | null> => {
    const response = await sendRequest(() => sustainabilityApi.reports.show(sustainabilityId));
    return response;
  };

  const storeReport = async (payload: unknown) => {
    const response = await sendRequest(() => sustainabilityApi.reports.store(payload));
    return response?.data;
  };

  const deleteReport = async (id: string) => {
    const response = await sendRequest(() => sustainabilityApi.reports.destroy(id));
    return response?.data;
  };

  const getAllReports = async (params?: { params: unknown }, filter = 'all') => {
    const response = await sendRequest(() => sustainabilityApi.reports.get({ ...params, parent: filter }));

    return response ?? { data: [] as any[], total: 0, last_page: 0 };
  };

  const getReports = async (params?: { params: unknown }, filter = 'all') => {
    const response = await sendRequest(() => sustainabilityApi.reports.get({ ...params, parent: filter }));

    return response ?? { data: [] as any[], total: 0, last_page: 0 };
  };

  const getReportStatuses = async (sustainabilityReportStatusId: string, sustainabilityId: string) => {
    const response = await handleRequest(
      () => sustainabilityApi.reporttypestatus.get(sustainabilityReportStatusId, sustainabilityId),
      setIsFetchingReportStatuses,
    );
    return response;
  };

  const getReportStatusesOptions = async (sustainabilityReportStatusId: string, sustainabilityId: string) => {
    const response = await getReportStatuses(sustainabilityReportStatusId, sustainabilityId);

    if (!response) return [];

    return response.data.map((sustainabilitType: { sustainability_report_status_next_id: any; status_next: any }) => ({
      key: sustainabilitType.sustainability_report_status_next_id,
      value: sustainabilitType.sustainability_report_status_next_id,
      label: sustainabilitType.status_next.name,
    }));
  };

  return {
    isFetching,
    getReports,
    getAllReports,
    storeReport,
    deleteReport,
    showReport,
    isFetchingReportStatuses,
    getReportStatuses,
    getReportStatusesOptions,
  };
};

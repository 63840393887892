import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ReportModal } from '@agrodatabr/react-report-modal';
import { Select as SelectAnt } from 'antd';

import { getReportModalTranslation } from '../../../../componentsTranslation/reportModal';
import { useCache } from '../../../../hooks/cache';
import documentApi from '../../../../services/api/document';
import { FormItemStyle, FormLabelStyle } from './styles';
import { ReportProps } from './types';

export const Report = ({
  getReport,
  generateExport,
  searchFilters,
  exportingGeneral,
  setExportingGeneral,
  withDateFilter = true,
  hideHarvest = false,
}: ReportProps) => {
  const { t } = useTranslation();
  const { Option } = SelectAnt;
  const { getCreditOptions } = useCache();

  const [harvests, setHarvests] = useState([]);

  useEffect(() => {
    if (!hideHarvest) setHarvests(searchFilters.harvest || []);
  }, [searchFilters.harvest]);

  const handleChange = (value: any) => {
    setHarvests(value || []);
  };

  const disabledOptions = (value: any) => {
    if (harvests.length >= 3) {
      const filter = harvests.filter((item: any) => item === value);
      if (filter.length) return false;
      return true;
    }
    return false;
  };
  const customFilters = () =>
    !hideHarvest ? (
      <FormItemStyle>
        <FormLabelStyle>{t('pages.dashboard.filters.harvest')}:</FormLabelStyle>
        <SelectAnt defaultValue={searchFilters.harvest} onChange={handleChange} mode="multiple">
          {getCreditOptions('harvest')
            .filter((itemValue, index, self) => self.indexOf(itemValue) === index)
            .map(option => (
              <Option key={option.value} disabled={disabledOptions(option.value)} {...option}>
                {option.label}
              </Option>
            ))}
        </SelectAnt>
      </FormItemStyle>
    ) : null;

  const inicialDateValues = () => {
    if (searchFilters.start || searchFilters.end) {
      return { startDate: searchFilters.start, endDate: searchFilters.end };
    }
    return null;
  };
  return (
    <ReportModal
      translation={getReportModalTranslation(t)}
      withDateFilter={withDateFilter}
      width="80%"
      visibility={exportingGeneral}
      filenameKey="name"
      close={() => {
        setExportingGeneral(false);
      }}
      initialDateValues={inicialDateValues()}
      customExtraFilters={customFilters()}
      listReportApi={getReport}
      generateReportApi={values => generateExport({ ...values, harvests })}
      downloadReportApi={documentApi.download}
    />
  );
};

import { FixedType, AlignType } from 'rc-table/lib/interface';

export const columns = [
  {
    dataIndex: 'status',
    key: 'status',
    sorter: false,
    filtered: false,
    width: '80px',
  },
  {
    title: 'pages.credit-request.depositary.table-form.depositary_name',
    dataIndex: 'name',
    key: 'name',
    sorter: false,
    filtered: false,
  },
  {
    title: 'pages.credit-request.depositary.table-form.depositary_rg',
    dataIndex: 'rg',
    key: 'rg',
    sorter: false,
    filtered: false,
    width: '200px',
  },
  {
    title: 'pages.credit-request.depositary.table-form.depositary_cpf',
    dataIndex: 'cpf',
    key: 'cpf',
    sorter: false,
    filtered: false,
    width: '200px',
  },
  {
    title: 'pages.credit-request.depositary.table-form.depositary_spouse_name',
    dataIndex: 'spouse_name',
    key: 'spouse_name',
    sorter: false,
    filtered: false,
  },
  {
    title: 'pages.credit-request.depositary.table-form.depositary_spouse_rg',
    dataIndex: 'spouse_rg',
    key: 'spouse_rg',
    sorter: false,
    filtered: false,
    width: '200px',
  },
  {
    title: 'pages.credit-request.depositary.table-form.depositary_spouse_cpf',
    dataIndex: 'spouse_cpf',
    key: 'spouse_cpf',
    sorter: false,
    filtered: false,
    width: '200px',
  },
  {
    title: 'pages.credit-request.depositary.table-form.actions',
    dataIndex: 'idList',
    key: 'idList',
    align: 'center' as AlignType,
    width: '80px',
    fixed: 'right' as FixedType,
  },
];

import React from 'react';
import { useTranslation } from 'react-i18next';

import { Empty, message, Tooltip } from 'antd';

import { Spin } from '../../../../../components';
import { useModal } from '../../../../../hooks/modals/useModal';
import { usePage } from '../../../../../hooks/page';
import theme from '../../../../../styles/theme';
import { NewRelatedDocumentButton } from '../../../components/NewRelatedDocumentButton';
import { NewRelatedDocumentModal } from '../../../components/NewRelatedDocumentModal';
import { RelatedDocumentButton } from '../../../components/RelatedDocumentButton';
import { RelatedDocumentsTitle } from '../../../components/RelatedDocumentsTitle';
import { useContractContext } from '../../../context/ContractContext';
import { DocumentsList } from './DocumentsList';
import { Container, DocumentsListWrapper } from './styles';

export const RelatedDocuments = () => {
  const { isOpen, close, open } = useModal();
  const { t } = useTranslation();
  const { alertStatus } = usePage();
  const {
    relatedDocuments,
    mainContract,
    isFetching,
    addNewRelatedDocument,
    changeSelectedDocument,
    isDocumentSelected,
    childrenTypes,
    canAddRelatedDocuments,
    isStoringNewRelatedDocument,
    isMainContractCancelled,
  } = useContractContext();

  const handleClickAddDocument = async () => {
    if (childrenTypes.length) open();
    else alertStatus('Esse template não possuí nenhum tipo filho cadastrado', 'error');
  };

  const disabledMessage =
    childrenTypes.length < 1
      ? t('pages.edocuments.emission.alerts.noChildren')
      : t('pages.edocuments.emission.alerts.notEmitted');

  return (
    <Container>
      <Spin spinning={isFetching || isStoringNewRelatedDocument}>
        {mainContract && (
          <RelatedDocumentButton
            code={mainContract?.number}
            isMain
            onClick={() => changeSelectedDocument(mainContract.id)}
            isSelected={isDocumentSelected(mainContract.id)}
            id={mainContract.id}
          />
        )}
        <RelatedDocumentsTitle text={t('pages.edocuments.emission.relatedDocumentsTitle')} />{' '}
        <div>
          {relatedDocuments.length ? (
            <DocumentsListWrapper background={theme.colors.greys.grey01}>
              <DocumentsList />
            </DocumentsListWrapper>
          ) : (
            <Empty
              description={t('pages.edocuments.emission.relatedDocumentsEmpty')}
              style={{ color: theme.colors.greys.grey07 }}
            />
          )}
        </div>
        <Tooltip title={t('pages.edocuments.emission.newAccessory')}>
          <NewRelatedDocumentButton
            disabled={!canAddRelatedDocuments || !mainContract?.active || isMainContractCancelled}
            permissions="contract.dependency.store"
            onClick={handleClickAddDocument}
            onClickDisabled={() => message.warning(disabledMessage)}
          />
        </Tooltip>
        <NewRelatedDocumentModal isOpen={isOpen} onClose={close} onOk={addNewRelatedDocument} />
      </Spin>
    </Container>
  );
};

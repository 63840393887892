import React, { useState, useCallback, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Form as AntForm, Input as AntInput } from 'antd';
import PermissionedComponent from 'components/PermissionedComponent';

import DTOCreditRequestResume from '../../../../../@types/dtos/credit-request/DTOCreditRequestResume';
import { Button, FormItem, EmptyComponent, Spin } from '../../../../../components';
import { PHYSICAL_PERSON_TYPE } from '../../../../../configs/constants';
import { useAuth } from '../../../../../hooks/auth';
import { usePage } from '../../../../../hooks/page';
import creditApi from '../../../../../services/api/credit';
import { Formatter } from '../../../../../utils/Formatter';
import renderComponent from '../../../../../utils/RenderComponent';
import CommentItem from './CommentItem';
import ChatItemProps from './CommentItem/types';
import { Container } from './styles';
import CommentsProps, { CommentRaw, CreditRequestResume } from './types';

const Comments: React.FC<CommentsProps> = ({ creditRequestId }) => {
  const [commentForm] = AntForm.useForm();
  const { t } = useTranslation();
  const { user } = useAuth();
  const { alertStatus } = usePage();

  const scrollObserve = useRef();
  const scrollBottom = useRef<null | HTMLDivElement>(null);

  const [comment, setComment] = useState('');
  const [loading, setLoading] = useState(true);
  const [loadingComments, setLoadingComments] = useState(false);
  const [scrollRadio, setScrollRadio] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [blockLoadComments, setBlockLoadComments] = useState(false);
  const [forceLoadComments, setForceLoadComments] = useState(false);
  const [isSendingComment, setIsSendingComment] = useState(false);
  const [commentsRaw, setCommentsRaw] = useState<CommentRaw[]>([]);
  const [creditRequestResume, setCreditRequestResume] = useState<CreditRequestResume>({} as CreditRequestResume);

  const getComments = useCallback(
    async (pageNumber: number) => {
      if (!creditRequestId) {
        alertStatus('Não encontrado o número da solicitação de crédito!', 'error');
        return;
      }

      if (blockLoadComments && forceLoadComments === false) {
        return;
      }

      setLoading(true);
      setLoadingComments(true);

      await creditApi.requests
        .getComments(creditRequestId, { params: { order_by: 'desc', per_page: 10, page: pageNumber } })
        .then(response => {
          const totalPagesQtd = response.data.data.last_page;
          setTotalPages(totalPagesQtd);

          if (pageNumber === 1) {
            setCommentsRaw([...response.data.data.data]);
          } else {
            setCommentsRaw(commentsState => [...commentsState, ...response.data.data.data]);
          }

          if (scrollBottom && scrollBottom.current) {
            const { scrollHeight } = scrollBottom.current;
            const pageHeight = scrollBottom.current.scrollHeight / pageNumber;
            scrollBottom.current.scrollTop = scrollHeight - (pageHeight * pageNumber - 1) + pageHeight;
          }
        })
        .catch(err => {
          alertStatus(err, 'error');
        })
        .finally(() => {
          setLoading(false);
          setLoadingComments(false);
          setForceLoadComments(false);

          if (pageNumber === 1) {
            if (scrollBottom?.current) {
              scrollBottom.current.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'end' });
              scrollBottom.current.scrollTop = scrollBottom.current.scrollHeight;
            }
          }
        });
    },
    // eslint-disable-next-line
    [alertStatus, blockLoadComments, creditRequestId],
  );

  useEffect(() => {
    getComments(1);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (forceLoadComments === true) {
      getComments(1);
    }
    // eslint-disable-next-line
  }, [forceLoadComments, getComments]);

  const getCreditRequestResume = useCallback(() => {
    creditApi.requests
      .getCreditRequestResume(creditRequestId)
      .then((response: { data: DTOCreditRequestResume }) => {
        const resumeData: DTOCreditRequestResume = response.data;
        let requesterInitials = '';
        let requesterName = '';

        if (resumeData.requester_person) {
          if (resumeData.requester_person.cnpj) {
            requesterName = resumeData.requester_person.company_name;
          } else {
            requesterName = resumeData.requester_person.name;
          }
        } else {
          requesterName = resumeData.json_data?.datasul_contract.fornecedor.nomeFornecedor;
        }

        const requesterNameSplitted = requesterName.split(' ');

        if (requesterNameSplitted.length === 1) {
          requesterInitials = requesterNameSplitted[0].substring(0, 2);
        } else if (requesterNameSplitted.length > 2) {
          requesterInitials = `${requesterNameSplitted[0].substring(0, 1)}${requesterNameSplitted[
            requesterNameSplitted.length - 1
          ].substring(0, 1)}`;
        } else {
          requesterInitials = `${requesterNameSplitted[0].substring(0, 1)}${requesterNameSplitted[1].substring(0, 1)}`;
        }

        const resumeCreditRequest: CreditRequestResume = {
          credit_amount: resumeData.requested_amount,
          requester_name: requesterName.length > 30 ? `${requesterName.substring(0, 27)}...` : requesterName,
          requester_initials: requesterInitials,
          requester_type:
            resumeData.requester_person_type === PHYSICAL_PERSON_TYPE
              ? t('geral.person_type.physical')
              : t('geral.person_type.legal'),
          type_currency: resumeData.currency_type?.slug ?? '',
          last_changed_by: resumeData.last_history_change?.user?.name,
          status: resumeData.last_history_change.to.name,
        };

        setCreditRequestResume(resumeCreditRequest);
      })
      .catch(err => {
        alertStatus(err, 'error');
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line
  }, [alertStatus]);

  useEffect(() => getCreditRequestResume(), [getCreditRequestResume]);

  const intersectionObserver = new IntersectionObserver(entries => {
    const radio = entries[0].intersectionRatio;
    setScrollRadio(Math.round(radio));
  });

  useEffect(() => {
    intersectionObserver.observe(scrollObserve.current);
    return () => {
      intersectionObserver.disconnect();
    };
    // eslint-disable-next-line
  }, [intersectionObserver]);

  useEffect(() => {
    if (scrollBottom) {
      scrollBottom.current.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'end' });
      scrollBottom.current.scrollTop = scrollBottom.current.scrollHeight;
    }
    // eslint-disable-next-line
  }, [scrollBottom]);

  useEffect(() => {
    if (scrollRadio > 0) {
      const newPage = page + 1;

      if (page >= totalPages) {
        setBlockLoadComments(true);
      } else {
        setBlockLoadComments(false);
        setPage(newPage);
        getComments(newPage);
      }
    }
    // eslint-disable-next-line
  }, [scrollRadio, getComments, page, totalPages]);

  const comments = useMemo(() => {
    const commentsReversed = commentsRaw;
    // Agrupa os commentários por datas
    const commentsByDate = commentsReversed.reduceRight((prev: any, cur) => {
      const curDate = cur.created_at.split(' ')[0];
      prev[curDate] = [...(prev[curDate] ?? []), cur];
      return prev;
    }, {});

    // Agrupa as mensagens na sequência por usuários
    const groupedComments = Object.values(commentsByDate).reduce((prev: any, cur: any) => {
      const total = cur.length;
      let prevDate = '';
      const all = [];
      let messages = [];

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < total; i++) {
        const curItem = cur[i];
        const nextItem = i < total ? cur[i + 1] : {};

        // eslint-disable-next-line prefer-destructuring
        prevDate = curItem.created_at.split(' ')[0];

        // Format the message - BEGIN
        const curHour = curItem.created_at.split(' ')[1];
        const curTimes = curHour.split(':');
        const messageComment = {
          id: curItem.id,
          comment: curItem.comment,
          hour: `${curTimes[0]}:${curTimes[1]}`,
        };
        // Format the message - END

        if (nextItem && curItem.user_id === nextItem.user_id) {
          messages.push(messageComment);
        }

        if ((nextItem && curItem.user_id !== nextItem.user_id) || !nextItem) {
          messages.push(messageComment);

          // Get the initials - BEGIN
          let username = curItem.user.name.split('(')[0].trim();
          username = username.split(' ');
          let initials = '';
          if (username.length === 1) {
            initials = username[0].substring(0, 2);
          } else if (username.length > 2) {
            initials = `${username[0].substring(0, 1)}${username[username.length - 1].substring(0, 1)}`;
          } else {
            initials = `${username[0].substring(0, 1)}${username[1].substring(0, 1)}`;
          }
          // Get the initials - END

          const userComments: ChatItemProps = {
            key: curItem.id,
            isMe: curItem.user_id === user.id,
            user: {
              name: curItem.user.name,
              initials,
              photo: curItem.user.photo,
            },
            messages,
          };

          all.push(userComments);
          messages = [];
        }
      }
      prev[prevDate] = all;

      return prev;
    }, {});

    // order comments of each day by time
    Object.keys(groupedComments).forEach(key => {
      (groupedComments as any)[key].sort((a: any, b: any) => {
        const dateA = new Date(a.messages[0].hour);
        const dateB = new Date(b.messages[0].hour);
        return dateA.getTime() - dateB.getTime();
      });
    });

    // order comments by date
    const groupedCommentsOrdered: any = {};
    Object.keys(groupedComments)
      .sort((a, b) => {
        const dateA = new Date(a);
        const dateB = new Date(b);
        return dateA.getTime() - dateB.getTime();
      })
      .forEach(key => {
        groupedCommentsOrdered[key] = (groupedComments as any)[key];
      });

    return groupedCommentsOrdered;
    // eslint-disable-next-line
  }, [commentsRaw, user.id]);

  const sendComment = useCallback(
    async values => {
      if (!creditRequestId) {
        alertStatus('Não encontrado o número da solicitação de crédito!', 'error');
        return;
      }

      setIsSendingComment(true);
      await creditApi.requests
        .comment(creditRequestId, values)
        .then(() => {
          setBlockLoadComments(false);
          setForceLoadComments(true);
          alertStatus('Comentário enviado com sucesso');
          setPage(1);
        })
        .catch(err => {
          alertStatus(err, 'error');
        })
        .finally(() => {
          setComment('');
          commentForm.resetFields();
          setIsSendingComment(false);
        });
    },
    // eslint-disable-next-line
    [alertStatus, commentForm, creditRequestId, getComments],
  );

  return (
    <Spin spinning={loading}>
      <Container>
        <div className="request-comments-container">
          <div className="request-comments-comments">
            <div className="comments-container" ref={scrollBottom}>
              <Spin spinning={loadingComments}>
                <div ref={scrollObserve} style={{ marginTop: '10px' }} />
                {renderComponent(
                  Object.keys(comments).length === 0,
                  <EmptyComponent
                    description={t('pages.credit-request.contact-registration.request-has-no-comments')}
                  />,
                  Object.keys(comments).map((keyComment: any) => {
                    const date = keyComment.split(' ')[0];
                    const times = date.split('-');
                    let commentDate: ChatItemProps | null = {
                      key: date,
                      date: {
                        key: date,
                        day: Number(times[2]),
                        month: times[1],
                        year: Number(times[0]),
                      },
                    };
                    const commentsList: any = comments;
                    let alreadyHasDate = 0;
                    return commentsList[keyComment].map((messageItem: ChatItemProps) => {
                      alreadyHasDate += 1;
                      if (alreadyHasDate === 2) {
                        commentDate = null;
                      }

                      return (
                        <CommentItem
                          key={`${keyComment}-${messageItem.key}`}
                          date={commentDate ? commentDate.date : null}
                          messages={messageItem.messages}
                          user={messageItem.user}
                          isMe={messageItem.isMe}
                        />
                      );
                    });
                  }),
                )}
              </Spin>
            </div>

            <PermissionedComponent
              permission="credit.request.comment.store"
              description={t('permission.message.credit.request.comment.store')}
            >
              <AntForm form={commentForm} onFinish={values => sendComment(values)} className="request-comments-form">
                <FormItem name="comment" extra={`${comment.length}/500`}>
                  <AntInput.TextArea
                    rows={3}
                    maxLength={500}
                    onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                      const { value } = event.target;
                      setComment(value);
                    }}
                  />
                </FormItem>

                <div className="request-comments-form-item-button">
                  <Button htmlType="submit" status="primary" loading={isSendingComment}>
                    {t('pages.credit-request.contact-registration.button.send-message')}
                  </Button>
                </div>
              </AntForm>
            </PermissionedComponent>
          </div>

          <div className="request-comments-client-data">
            {renderComponent(
              !!creditRequestResume && !!creditRequestResume.requester_name,
              <>
                <div className="requester-data-container">
                  <div className="requester-image-container">
                    <div className="requester-initials-container">
                      <span className="requester-initials">{creditRequestResume.requester_initials}</span>
                    </div>
                  </div>
                  <div className="requester-name">{creditRequestResume.requester_name}</div>
                  <div className="requester-person-type">{creditRequestResume.requester_type}</div>
                </div>
                <div className="info-container">
                  <div className="info-requested-amount">
                    {`${t('pages.credit-request.contact-registration.requested_amount')}:`}
                    <br />
                    <span className="info-requested-value">
                      {`${Formatter.money(
                        creditRequestResume.credit_amount,
                        2,
                        creditRequestResume.type_currency,
                        true,
                      )}`}
                    </span>
                  </div>
                  <div className="info-extra">
                    {`${t('pages.credit-request.contact-registration.current_status')}:`}
                    <br />
                    {creditRequestResume.status}
                  </div>
                  <div className="info-extra">
                    {`${t('pages.credit-request.contact-registration.updated_by')}:`}
                    <br />
                    {creditRequestResume.last_changed_by}
                  </div>
                </div>
                <div className="buttons-container">
                  {/* Manter comentado, pois está em definição as ações dos botões (eles já estão ajustados no layout da página) */}
                  {/* <Button status="secondary-outline" block>
                    {t('pages.credit-request.contact-registration.button.change-status')}
                  </Button>
                  <Button status="secondary-outline" block>
                    {t('pages.credit-request.contact-registration.button.transfer-attendance')}
                  </Button>
                  <Button status="secondary-outline" block>
                    {t('pages.credit-request.contact-registration.button.release-credit')}
                  </Button> */}
                </div>
              </>,
            )}
          </div>
        </div>
      </Container>
    </Spin>
  );
};

export default Comments;

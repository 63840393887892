import { useState } from 'react';

import { useAbility } from 'hooks/ability';
import api, { handleRequest } from 'services/api/api';

import { GroupUserData } from '../../@types/data/GroupUserData';
import Pagination from '../../@types/responseKanban/Pagination';

export const useIntegrationGroups = () => {
  const [isStoring, setIsStoring] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isFetchingGroupUsers, setIsFetchingGroupUsers] = useState(false);
  const [isUpdatingGroupUsers, setIsUpdatingGroupUsers] = useState(false);
  const [isFetchingUsersNotInGroup, setIsFetchingUsersNotInGroup] = useState(false);
  const [isUpdatingGroupIntegrationType, setIsUpdatingGroupIntegrationType] = useState(false);
  const [isRemovingUserFromGroup, setIsRemovingUserFromGroup] = useState(false);
  const [isFetchingGroupIntegrations, setIsFetchingGroupIntegrations] = useState(false);
  const [isRemovingGroup, setIsRemovingGroup] = useState(false);
  const ability = useAbility();

  const store = (groupData = {}, params = {}) =>
    handleRequest(
      () => (ability.can('worker.group.store', '') ? api.post(`/worker/group`, groupData, params) : null),
      setIsStoring,
    );

  const updateGroup = (integrationId: string, integrationName: string, params = {}) =>
    handleRequest(() => api.put(`/worker/group/${integrationId}`, integrationName, params), setIsUpdating);

  const getGroups = (params = {}) =>
    handleRequest(
      () => (ability.can('worker.group.store', '') ? api.get('/worker/group', params) : null),
      setIsFetching,
    );

  const getGroupUsersById = (groupId: string, params = {}): Promise<Pagination<GroupUserData>> =>
    handleRequest(
      () =>
        ability.can('worker.group.users.index', '')
          ? api.get(`/worker/group/${groupId}/users?users_not_in_group=0`, params)
          : null,
      setIsFetchingGroupUsers,
    );

  const getUsersNotInGroupById = async (groupId: string, params = {}): Promise<Pagination<any>> =>
    handleRequest(
      () =>
        ability.can('worker.group.users.index', '')
          ? api.get(`/worker/group/${groupId}/users?users_not_in_group=1`, params)
          : null,
      setIsFetchingUsersNotInGroup,
    );

  const updateGroupUsers = (groupId: string, users: string[], params = {}) =>
    handleRequest(
      () =>
        ability.can('worker.group.users.store', '')
          ? api.post(`/worker/group/${groupId}/users`, { users }, params)
          : null,
      setIsUpdatingGroupUsers,
    );

  const updateGroupIntegrationType = (groupId: string, integrationType: string, params = {}) =>
    handleRequest(
      () =>
        ability.can('worker.importexport.store', '')
          ? api.post(
              `/import-export`,
              {
                worker_group_id: groupId,
                worker_type: integrationType,
              },
              params,
            )
          : null,
      setIsUpdatingGroupIntegrationType,
    );

  const removeUserFromGroup = (groupId: string, users: string[]) =>
    handleRequest(
      () => api.post(`/worker/group/${groupId}/users`, { users }, { params: { _method: 'DELETE' } }),
      setIsRemovingUserFromGroup,
    );

  const getGroupIntegrations = (groupId: string, params = {}) =>
    handleRequest(
      () =>
        ability.can('worker.importexport.index', '')
          ? api.get(`/import-export?worker_group_id=${groupId}`, params)
          : null,
      setIsFetchingGroupIntegrations,
    );

  const removeGroup = (groupId: string, params = {}) =>
    handleRequest(() => api.delete(`/worker/group/${groupId}`, params), setIsRemovingGroup);

  return {
    isStoring,
    store,
    isFetching,
    getGroups,
    isUpdating,
    updateGroup,
    isFetchingGroupUsers,
    getGroupUsersById,
    updateGroupUsers,
    isUpdatingGroupUsers,
    isFetchingUsersNotInGroup,
    getUsersNotInGroupById,
    isUpdatingGroupIntegrationType,
    updateGroupIntegrationType,
    isRemovingUserFromGroup,
    removeUserFromGroup,
    isFetchingGroupIntegrations,
    getGroupIntegrations,
    removeGroup,
    isRemovingGroup,
  };
};

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, Link, useLocation } from 'react-router-dom';

import { Result, Form } from 'antd';
import axios from 'axios';
import queryString from 'query-string';

import AuthPage from '../../../components/AuthPage';

const ValidateEmail: React.FC = () => {
  const params = useParams<{ user: string; hash?: string }>();
  const location = useLocation();
  const qs = queryString.parse(location.search);
  const [loading, setLoading] = useState(true);
  const [code, setCode] = useState(0);
  const [message, setMessage] = useState();
  const { t } = useTranslation(['auth']);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/email/verify/${params.user ?? qs.user}/${params.hash ?? qs.hash}`, {
        params: {
          expires: qs.expires,
          signature: qs.signature,
        },
      })
      .then(response => {
        setCode(response.status);
      })
      .catch(err => {
        setCode(err.response.status);
        setMessage(err.response.data.message ?? err.response.statusText);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <AuthPage loading={loading}>
      <Form>
        {code === 200 && (
          <Result
            status="success"
            title={t('validate-email.success')}
            extra={<Link to="/">{t('validate-email.go-home')}</Link>}
          />
        )}

        {code !== 200 && code !== 0 && (
          <Result status="warning" title={message} extra={<Link to="/">{t('validate-email.go-home')}</Link>} />
        )}
      </Form>
    </AuthPage>
  );
};

export default ValidateEmail;

import {
  SUSTAINABILITY_DECISION_APPROVED,
  SUSTAINABILITY_DECISION_APPROVED_COLOR,
  SUSTAINABILITY_DECISION_APPROVED_WITH_RESERVATIONS,
  SUSTAINABILITY_DECISION_APPROVED_WITH_RESERVATIONS_COLOR,
  SUSTAINABILITY_DECISION_DISAPPROVED,
  SUSTAINABILITY_DECISION_DISAPPROVED_COLOR,
  SUSTAINABILITY_DECISION_IN_ANALYSIS,
  SUSTAINABILITY_REGISTRATION_STATUS_APPROVED,
  SUSTAINABILITY_REGISTRATION_STATUS_APPROVED_COLOR,
  SUSTAINABILITY_REGISTRATION_STATUS_APPROVED_WITH_RESERVATIONS,
  SUSTAINABILITY_REGISTRATION_STATUS_APPROVED_WITH_RESERVATIONS_COLOR,
  SUSTAINABILITY_REGISTRATION_STATUS_DISAPPROVED,
  SUSTAINABILITY_REGISTRATION_STATUS_DISAPPROVED_COLOR,
  SUSTAINABILITY_REGISTRATION_STATUS_IN_ANALYSIS_COLOR,
} from 'configs/constants';

export const getDecisionColor = (decisionId: string, isRegistration = false) => {
  if (isRegistration)
    switch (decisionId) {
      case SUSTAINABILITY_REGISTRATION_STATUS_DISAPPROVED:
        return SUSTAINABILITY_REGISTRATION_STATUS_DISAPPROVED_COLOR;
      case SUSTAINABILITY_REGISTRATION_STATUS_APPROVED:
        return SUSTAINABILITY_REGISTRATION_STATUS_APPROVED_COLOR;
      case SUSTAINABILITY_REGISTRATION_STATUS_APPROVED_WITH_RESERVATIONS:
        return SUSTAINABILITY_REGISTRATION_STATUS_APPROVED_WITH_RESERVATIONS_COLOR;
      default:
        return SUSTAINABILITY_REGISTRATION_STATUS_IN_ANALYSIS_COLOR;
    }

  switch (decisionId) {
    case SUSTAINABILITY_DECISION_APPROVED:
      return SUSTAINABILITY_DECISION_APPROVED_COLOR;
    case SUSTAINABILITY_DECISION_DISAPPROVED:
      return SUSTAINABILITY_DECISION_DISAPPROVED_COLOR;
    case SUSTAINABILITY_DECISION_APPROVED_WITH_RESERVATIONS:
      return SUSTAINABILITY_DECISION_APPROVED_WITH_RESERVATIONS_COLOR;
    default:
      return SUSTAINABILITY_DECISION_IN_ANALYSIS;
  }
};

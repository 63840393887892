import React from 'react';
import { useTranslation } from 'react-i18next';

import { Radio } from 'antd';
import { FormItem } from 'components';
import { useCreditRequestStore } from 'pages/CreditRequest/store/useCreditRequestStore';

export const HasAssignesInput = () => {
  const { t } = useTranslation();

  const pageStatus = useCreditRequestStore(state => state.pageStatus);

  return (
    <FormItem
      label={t('pages.admin.team-credit-status.form.rules.target.has_assignees')}
      name="has_assignees"
      initialValue={undefined}
      rules={[{ required: true }]}
    >
      <Radio.Group buttonStyle="solid" disabled={pageStatus.viewing}>
        <Radio.Button value={1}>
          {t('pages.admin.team-credit-status.form.rules.target.is_attachment_required_yes')}
        </Radio.Button>
        <Radio.Button value={0}>
          {t('pages.admin.team-credit-status.form.rules.target.is_attachment_required_not')}
        </Radio.Button>
      </Radio.Group>
    </FormItem>
  );
};

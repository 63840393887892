import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 20px;
`;

export const Title = styled.h4`
  color: ${({ theme: { colors } }) => colors.primary};
  display: flex;
  gap: 10px;
  align-items: center;
`;

import { FixedType, AlignType } from 'rc-table/lib/interface';

export const columnsTimelineManagement = [
  {
    title: 'pages.dashboard.timeline.table.client',
    dataIndex: 'requester',
    key: 'requester',
    sorter: false,
    filtered: false,
    fixed: 'left' as FixedType,
    width: '300px',
  },
  {
    title: 'pages.dashboard.timeline.table.requestNumber',
    dataIndex: 'number',
    key: 'number',
    sorter: false,
    filtered: false,
    fixed: 'left' as FixedType,
    width: '200px',
  },
  {
    title: 'pages.dashboard.timeline.table.status',
    dataIndex: 'status',
    key: 'status',
    sorter: false,
    filtered: false,
    fixed: 'left' as FixedType,
    width: '200px',
  },
  {
    title: 'pages.dashboard.timeline.table.current_status',
    dataIndex: 'current_status',
    key: 'current_status',
    sorter: false,
    filtered: false,
    fixed: 'left' as FixedType,
    width: '200px',
  },
  {
    title: 'pages.dashboard.timeline.table.operation',
    dataIndex: 'operation',
    key: 'operation',
    sorter: false,
    filtered: false,
    fixed: 'left' as FixedType,
    width: '200px',
  },
  {
    title: 'pages.dashboard.timeline.table.totalTime',
    dataIndex: 'total_time',
    key: 'total_time',
    sorter: false,
    filtered: false,
    fixed: 'left' as FixedType,
    width: '150px',
  },
  {
    title: 'pages.dashboard.timeline.table.approvation',
    dataIndex: 'approvation',
    key: 'approvation',
    sorter: false,
    filtered: false,
    width: '150px',
  },
  {
    title: 'pages.dashboard.timeline.table.prefin',
    dataIndex: 'prefin',
    key: 'prefin',
    sorter: false,
    filtered: false,
    width: '150px',
  },
  {
    title: 'pages.dashboard.timeline.table.commercial',
    dataIndex: 'commercial',
    key: 'commercial',
    sorter: false,
    filtered: false,
    width: '150px',
  },
  {
    title: 'pages.dashboard.timeline.table.contract',
    dataIndex: 'contract',
    key: 'contract',
    sorter: false,
    filtered: false,
    width: '150px',
  },
  {
    title: 'pages.dashboard.timeline.table.credit',
    dataIndex: 'credit',
    key: 'credit',
    sorter: false,
    filtered: false,
    width: '150px',
  },
  {
    title: 'pages.dashboard.timeline.table.legal',
    dataIndex: 'legal',
    key: 'legal',
    sorter: false,
    filtered: false,
    width: '150px',
  },
  {
    title: 'pages.dashboard.timeline.table.sustainability',
    dataIndex: 'sustainability',
    key: 'sustainability',
    sorter: false,
    filtered: false,
    width: '160px',
  },
  {
    title: 'pages.dashboard.timeline.table.documents',
    dataIndex: 'documents',
    key: 'documents',
    sorter: false,
    filtered: false,
    width: '150px',
  },
  {
    title: 'pages.dashboard.timeline.table.operational-risk',
    dataIndex: 'operational-risk',
    key: 'operational-risk',
    sorter: false,
    filtered: false,
    width: '180px',
  },
  {
    title: 'pages.dashboard.timeline.table.actions',
    key: 'action',
    dataIndex: 'action',
    width: '90px',
    fixed: 'right' as FixedType,
    align: 'center' as AlignType,
  },
];

export const columnsTimeline = [
  {
    title: 'pages.dashboard.timeline.table.client',
    dataIndex: 'requester',
    key: 'requester',
    sorter: false,
    filtered: false,
    fixed: 'left' as FixedType,
    width: '300px',
  },
  {
    title: 'pages.dashboard.timeline.table.requestNumber',
    dataIndex: 'number',
    key: 'number',
    sorter: false,
    filtered: false,
    fixed: 'left' as FixedType,
    width: '200px',
  },
  {
    title: 'pages.dashboard.timeline.table.status',
    dataIndex: 'status',
    key: 'status',
    sorter: false,
    filtered: false,
    fixed: 'left' as FixedType,
    width: '200px',
  },
  {
    title: 'pages.dashboard.timeline.table.current_status',
    dataIndex: 'current_status',
    key: 'current_status',
    sorter: false,
    filtered: false,
    fixed: 'left' as FixedType,
    width: '200px',
  },
  {
    title: 'pages.dashboard.timeline.table.totalTime',
    dataIndex: 'total_time',
    key: 'total_time',
    sorter: false,
    filtered: false,
    fixed: 'left' as FixedType,
    width: '150px',
  },
  {
    title: 'pages.dashboard.timeline.table.approvation',
    dataIndex: 'approvation',
    key: 'approvation',
    sorter: false,
    filtered: false,
    width: '150px',
  },
  {
    title: 'pages.dashboard.timeline.table.prefin',
    dataIndex: 'prefin',
    key: 'prefin',
    sorter: false,
    filtered: false,
    width: '150px',
  },
  {
    title: 'pages.dashboard.timeline.table.commercial',
    dataIndex: 'commercial',
    key: 'commercial',
    sorter: false,
    filtered: false,
    width: '150px',
  },
  {
    title: 'pages.dashboard.timeline.table.contract',
    dataIndex: 'contract',
    key: 'contract',
    sorter: false,
    filtered: false,
    width: '150px',
  },
  {
    title: 'pages.dashboard.timeline.table.credit',
    dataIndex: 'credit',
    key: 'credit',
    sorter: false,
    filtered: false,
    width: '150px',
  },
  {
    title: 'pages.dashboard.timeline.table.legal',
    dataIndex: 'legal',
    key: 'legal',
    sorter: false,
    filtered: false,
    width: '150px',
  },
  {
    title: 'pages.dashboard.timeline.table.sustainability',
    dataIndex: 'sustainability',
    key: 'sustainability',
    sorter: false,
    filtered: false,
    width: '160px',
  },
  {
    title: 'pages.dashboard.timeline.table.documents',
    dataIndex: 'documents',
    key: 'documents',
    sorter: false,
    filtered: false,
    width: '150px',
  },
  {
    title: 'pages.dashboard.timeline.table.operational-risk',
    dataIndex: 'operational-risk',
    key: 'operational-risk',
    sorter: false,
    filtered: false,
    width: '180px',
  },
  {
    title: 'pages.dashboard.timeline.table.actions',
    key: 'action',
    dataIndex: 'action',
    width: '90px',
    fixed: 'right' as FixedType,
    align: 'center' as AlignType,
  },
];

export const columnsTimelinePrepay = [
  {
    title: 'pages.dashboard.timeline.table.client',
    dataIndex: 'requester',
    key: 'requester',
    sorter: false,
    filtered: false,
    fixed: 'left' as FixedType,
    width: '300px',
  },
  {
    title: 'pages.dashboard.timeline.table.requestNumber',
    dataIndex: 'number',
    key: 'number',
    sorter: false,
    filtered: false,
    fixed: 'left' as FixedType,
    width: '200px',
  },
  {
    title: 'pages.dashboard.timeline.table.contractNumber',
    dataIndex: 'contract_number',
    key: 'contract_number',
    sorter: false,
    filtered: false,
    fixed: 'left' as FixedType,
    width: '200px',
  },
  {
    title: 'pages.dashboard.timeline.table.status',
    dataIndex: 'status',
    key: 'status',
    sorter: false,
    filtered: false,
    fixed: 'left' as FixedType,
    width: '200px',
  },
  {
    title: 'pages.dashboard.timeline.table.current_status',
    dataIndex: 'current_status',
    key: 'current_status',
    sorter: false,
    filtered: false,
    fixed: 'left' as FixedType,
    width: '200px',
  },
  {
    title: 'pages.dashboard.timeline.table.totalTime',
    dataIndex: 'total_time',
    key: 'total_time',
    sorter: false,
    filtered: false,
    fixed: 'left' as FixedType,
    width: '150px',
  },
  {
    title: 'pages.dashboard.timeline.table.approvation',
    dataIndex: 'approvation',
    key: 'approvation',
    sorter: false,
    filtered: false,
    width: '150px',
  },
  {
    title: 'pages.dashboard.timeline.table.prefin',
    dataIndex: 'prefin',
    key: 'prefin',
    sorter: false,
    filtered: false,
    width: '150px',
  },
  {
    title: 'pages.dashboard.timeline.table.commercial',
    dataIndex: 'commercial',
    key: 'commercial',
    sorter: false,
    filtered: false,
    width: '150px',
  },
  {
    title: 'pages.dashboard.timeline.table.contract',
    dataIndex: 'contract',
    key: 'contract',
    sorter: false,
    filtered: false,
    width: '150px',
  },
  {
    title: 'pages.dashboard.timeline.table.credit',
    dataIndex: 'credit',
    key: 'credit',
    sorter: false,
    filtered: false,
    width: '150px',
  },
  {
    title: 'pages.dashboard.timeline.table.legal',
    dataIndex: 'legal',
    key: 'legal',
    sorter: false,
    filtered: false,
    width: '150px',
  },
  {
    title: 'pages.dashboard.timeline.table.sustainability',
    dataIndex: 'sustainability',
    key: 'sustainability',
    sorter: false,
    filtered: false,
    width: '160px',
  },
  {
    title: 'pages.dashboard.timeline.table.documents',
    dataIndex: 'documents',
    key: 'documents',
    sorter: false,
    filtered: false,
    width: '150px',
  },
  {
    title: 'pages.dashboard.timeline.table.operational-risk',
    dataIndex: 'operational-risk',
    key: 'operational-risk',
    sorter: false,
    filtered: false,
    width: '180px',
  },
  {
    title: 'pages.dashboard.timeline.table.actions',
    key: 'action',
    dataIndex: 'action',
    width: '90px',
    fixed: 'right' as FixedType,
    align: 'center' as AlignType,
  },
];

import { useCallback } from 'react';

import { useCache } from 'hooks/cache';

export const useVerifyIsMarried = () => {
  const cache = useCache();

  const verifyIsMarried = useCallback(
    (value: string): boolean => {
      if (!value) return false;
      const maritalState = value.toString();
      const findMarriedState = cache
        .getPersonOptions('marital_status')
        .find(m => m.label.toLowerCase() === 'casado' && m.value === maritalState);

      return Boolean(findMarriedState);
    },
    [cache],
  );

  return { verifyIsMarried };
};

import React from 'react';
import { useTranslation } from 'react-i18next';

import { InfiniteSelect } from '@agrodatabr/agrodataui';
import { FormItem } from 'components';
import { useCreditRequestContext } from 'context/CreditRequestContext';
import { useCustody } from 'hooks/fetch/useCustody';
import { useCreditRequestStore } from 'pages/CreditRequest/store/useCreditRequestStore';
import { Converter } from 'utils/Converter';

export const CustodiaCityIdSelect = () => {
  const { t } = useTranslation();
  const { canEditCustodyFields, creditForm } = useCreditRequestContext();
  const { getCities } = useCustody();

  const setIsBlocking = useCreditRequestStore(state => state.setIsBlocking);
  const custodyStateId = useCreditRequestStore(state => state.custodyStateId);
  const custodyCity = useCreditRequestStore(state => state.custodyCity);
  const setCustodyCityId = useCreditRequestStore(state => state.setCustodyCityId);
  const custodyWarehouseAddressIsLegacy = useCreditRequestStore(state => state.custodyWarehouseAddressIsLegacy);
  const stateIsSelected = useCreditRequestStore(state => state.stateIsSelected);

  const handleGetCityOptions = async (search: string, page: number) => {
    const { data, last_page } = await getCities({
      params: {
        fill: search,
        page,
        state_external_id: custodyStateId,
      },
    });
    return {
      data: data.map((city: any) => Converter.selectOption(city, 'id', 'id', 'name')),
      lastPage: last_page,
    };
  };

  return (
    <FormItem
      label={t('pages.credit-request.form.json_data_warehouse_city_id')}
      name="custodia_city_id"
      rules={[{ required: !custodyWarehouseAddressIsLegacy }]}
      validateTrigger="onSubmit"
      hidden={custodyWarehouseAddressIsLegacy}
      permission="custodia.cities.index"
    >
      <InfiniteSelect
        showSearch
        listHeight={250}
        deps={[custodyStateId]}
        getOptions={handleGetCityOptions}
        options={custodyCity ? [{ ...Converter.selectOption(custodyCity, 'id', 'id', 'name') }] : []}
        disabled={!canEditCustodyFields || !stateIsSelected}
        onChange={(value: any, option: any) => {
          if (Array.isArray(value) ? value.length > 0 : value) {
            setIsBlocking(true);
            setCustodyCityId(option.data.external_id);
          }
          creditForm.setFieldsValue({
            custodia_storage_unit_id: undefined,
            custodia_storage_commodity_division_id: undefined,
            'json_data.warehouse_street': '',
            custodia_approval_status: '',
            custodia_storage_category: '',
          });
        }}
      />
    </FormItem>
  );
};

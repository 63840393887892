import styled from 'styled-components';

export const SUpload = styled.div`
  .anticon-loading {
    display: none;
  }
  .ant-progress-inner {
    display: none;
  }

  .ant-upload-list-item-card-actions {
    display: none;
  }
`;

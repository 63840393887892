import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineFileDone } from 'react-icons/ai';
import { BsCheckAll } from 'react-icons/bs';

import { Col, Row } from 'antd';

import { UploadAttachment } from '../../../../components/UploadAttachment';
import { useDraft } from '../../../../hooks/fetch/useDraft';
import { useContractContext } from '../../context/ContractContext';
import { CustomButton } from '../CustomButton';
import { DraftButton } from '../DraftButton';
import { IconWrapper } from '../IconWrapper';
import { ValidateEmissionModal } from '../ValidateEmissionModal';
import { Container, UploadWrapper, UploadedWrapper } from './styles';

const MAX_FILES = 1;

export const DraftUploadEmission = () => {
  const {
    contractId,
    updateContract,
    contract,
    isValidatedOnEmission,
    needEmissionValidation,
    isSelectedDocumentMain,
    isSelectedContractCancelled,
    canCurrentUserValidateOnEmission,
  } = useContractContext();
  const { uploadDraft } = useDraft();
  const { t } = useTranslation();

  const [file, setFile] = useState<any>(null);
  const [openedModal, setOpenedModal] = useState<'cancel' | 'confirm' | null>();

  const canSubmit = !!file;

  const handleFileChange = (newFile: any) => setFile(newFile.length === 0 ? null : newFile);

  const submitFile = async () => {
    const formData = new FormData();
    formData.append('minuta', file.originFileObj);

    const response = await uploadDraft(contractId, formData);

    if (response) updateContract(response, isSelectedDocumentMain);
  };

  const handleClickDeny = () => setOpenedModal('cancel');

  const handleClickConfirm = () => setOpenedModal('confirm');

  const closeModal = () => setOpenedModal(null);

  const shouldShowUploadInput = !contract?.is_upload;

  const shouldShowValidationButtons =
    needEmissionValidation &&
    !isValidatedOnEmission &&
    !isSelectedContractCancelled &&
    canCurrentUserValidateOnEmission;

  return (
    <Container>
      {shouldShowUploadInput ? (
        <>
          <UploadWrapper>
            <>
              <UploadAttachment maxFiles={MAX_FILES} onChange={handleFileChange} />
            </>
          </UploadWrapper>
          <DraftButton
            disabled={!canSubmit}
            onClick={canSubmit ? submitFile : undefined}
            permissions="contract.uploadminuta"
          >
            <IconWrapper>
              <BsCheckAll />
            </IconWrapper>
            Enviar Minuta
          </DraftButton>
        </>
      ) : (
        <>
          <UploadedWrapper>
            <AiOutlineFileDone size={60} />
            <span>{t('pages.edocuments.emission.draftEmission.draftIssuedWithSucces')}</span>
          </UploadedWrapper>

          {shouldShowValidationButtons && (
            <Row gutter={[20, 20]}>
              <Col xl={24} xxl={12} lg={12} md={12} sm={12} xs={12}>
                <CustomButton onClick={handleClickDeny}>
                  <IconWrapper>
                    <BsCheckAll />
                  </IconWrapper>
                  Recusar
                </CustomButton>
              </Col>

              <Col xl={24} xxl={12} lg={12} md={12} sm={12} xs={12}>
                <CustomButton onClick={handleClickConfirm}>
                  <IconWrapper>
                    <BsCheckAll />
                  </IconWrapper>
                  Validar
                </CustomButton>
              </Col>
            </Row>
          )}
        </>
      )}
      <ValidateEmissionModal type={openedModal} isOpen={!!openedModal} closeModal={closeModal} />
    </Container>
  );
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import { AiFillQuestionCircle } from 'react-icons/ai';

import { Tooltip } from 'antd';
import { FormItem, Input } from 'components';
import { useCreditRequestStore } from 'pages/CreditRequest/store/useCreditRequestStore';

import { StatusTooltipText } from './styles';

const StatusTooltip = () => {
  const { t } = useTranslation();

  return (
    <div>
      <StatusTooltipText>{t('pages.credit-request.form.custodia_approval_status_info')}</StatusTooltipText>
      <ul>
        <li>D - {t('pages.credit-request.form.custodia_approval_status_info_d')}</li>
        <li>R - {t('pages.credit-request.form.custodia_approval_status_info_r')}</li>
        <li>A - {t('pages.credit-request.form.custodia_approval_status_info_a')}</li>
        <li>X - {t('pages.credit-request.form.custodia_approval_status_info_x')}</li>
        <li>B - {t('pages.credit-request.form.custodia_approval_status_info_b')}</li>
        <li>V - {t('pages.credit-request.form.custodia_approval_status_info_v')}</li>
        <li>O - {t('pages.credit-request.form.custodia_approval_status_info_o')}</li>
        <li>U - {t('pages.credit-request.form.custodia_approval_status_info_u')}</li>
      </ul>
    </div>
  );
};

export const CustodiaApprovalStatusInput = () => {
  const { t } = useTranslation();

  const setIsBlocking = useCreditRequestStore(state => state.setIsBlocking);

  return (
    <FormItem
      label={
        <div className="flex gap-2 items-center">
          <span>{t('pages.credit-request.form.custodia_approval_status')}</span>{' '}
          <Tooltip overlay={<StatusTooltip />}>
            <AiFillQuestionCircle size={16} />
          </Tooltip>
        </div>
      }
      name="custodia_approval_status"
    >
      <Input
        disabled
        onChange={value => {
          if (value) {
            setIsBlocking(true);
          }
        }}
      />
    </FormItem>
  );
};

import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';

import { PageParams } from 'pages/CreditRequest/types';

export enum TabEnum {
  GENERAL_DATA = 'tab-general-data',
  CREDIT_REQUEST = 'tab-credit-request',
  COMMENTS = 'tab-comments',
  FORMALIZATION_GUARANTEES = 'tab-formalization-guarantees',
  HISTORIC = 'tab-historic',
  DOCUMENTS = 'tab-documents',
}

type UseHandleActiveTabProps = {
  onSelectTab: { [key in TabEnum]?: () => void };
};

export const useHandleActiveTab = ({ onSelectTab }: UseHandleActiveTabProps) => {
  const params = useParams<PageParams>();
  const { permission: permissionParam, id: idParam, activeTab: activeTabParam } = params;
  const navigate = useNavigate();

  const [selectedTab, setSelectedTab] = useState(TabEnum.GENERAL_DATA);

  const handleSelectTab = (tab: TabEnum) => {
    setSelectedTab(tab);
    if (onSelectTab[tab]) {
      onSelectTab[tab]();
    }
  };

  const handleActiveTab = useCallback(
    (tabParam?: string) => {
      switch (tabParam) {
        case TabEnum.GENERAL_DATA:
          handleSelectTab(TabEnum.GENERAL_DATA);
          break;
        case TabEnum.CREDIT_REQUEST:
          handleSelectTab(TabEnum.CREDIT_REQUEST);
          break;
        case TabEnum.COMMENTS:
          handleSelectTab(TabEnum.COMMENTS);
          break;
        case TabEnum.FORMALIZATION_GUARANTEES:
          handleSelectTab(TabEnum.FORMALIZATION_GUARANTEES);
          break;
        case TabEnum.HISTORIC:
          handleSelectTab(TabEnum.HISTORIC);
          break;
        default:
          handleSelectTab(TabEnum.CREDIT_REQUEST);
          break;
      }

      if (idParam) {
        if (tabParam) {
          navigate(`/credit-request/${permissionParam}/${idParam}/${tabParam}`);
        } else {
          navigate(`/credit-request/${permissionParam}/${idParam}`);
        }
      } else {
        if (tabParam) {
          navigate(`/credit-request/${permissionParam}/${tabParam}`);
        } else {
          navigate(`/credit-request/${permissionParam}`);
        }
      }
    },
    // eslint-disable-next-line
    [permissionParam, idParam],
  );

  useEffect(() => {
    handleActiveTab(activeTabParam);
  }, [activeTabParam]);

  return { selectedTab, handleActiveTab };
};

import React from 'react';
import { useTranslation } from 'react-i18next';

import { TableData, TableTranslation } from '@agrodatabr/agrodataui';
import { ColumnType } from 'antd/lib/table';
import { ContractTypeData } from 'types/Contract/ContractTypeData';

import { IconWithTooltip } from '../../../../components';
import { Can } from '../../../../hooks/ability';
import { columns } from './columns';
import { Filter } from './Filter';
import { TableProps } from './types';

export const Table = ({
  handleChange,
  handleEdit,
  handleRemove,
  isLoading = false,
  controller,
  searchFilters,
  setSearchFilters,
}: TableProps) => {
  const { t } = useTranslation();

  const handleFastSearch = (values?: any) => {
    setSearchFilters(values);
    controller.refresh();
  };

  const getActions = () => ({
    render: (_: unknown, record: ContractTypeData) => (
      <>
        <Can I="contract.template.destroy" a="">
          <IconWithTooltip
            action="destroy"
            title={t('pages.edocuments.contracttypes.table.destroy')}
            onClick={() => handleRemove(record.id)}
          />
        </Can>
        <Can I="contract.template.update" a="">
          <IconWithTooltip
            action="edit"
            title={t('pages.edocuments.contracttypes.table.edit')}
            onClick={() => handleEdit(record.id)}
          />
        </Can>
      </>
    ),
  });

  const customColumns = (column: ColumnType<object>) => {
    column = { ...column, title: t(column.title.toString()) };

    switch (column.key) {
      case 'type':
        column = {
          ...column,
          render: (type: any) => {
            return <span>{type?.name ?? '-'}</span>;
          },
        };
        break;

      default:
        break;
    }
    return column;
  };

  return (
    <div>
      <Filter handleFastSearch={handleFastSearch} />

      <TableData
        columns={columns}
        customColumns={customColumns}
        searchRequest={handleChange}
        searchFilters={searchFilters}
        width="100%"
        tableConfig={{ getActions }}
        pagination={{ position: ['bottomCenter'] }}
        loading={isLoading}
        controller={controller}
        translation={{ pagination: { totalOfRegisters: 'Total de registros' } } as TableTranslation}
      />
    </div>
  );
};

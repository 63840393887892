import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  padding-left: 8px;
  padding-right: 8px;
`;

export const SUpload = styled.div`
  .anticon-loading {
    display: none;
  }
  .ant-progress-inner {
    display: none;
  }

  .ant-upload-list-item-card-actions {
    display: none;
  }
`;

import { useState } from 'react';

import { DTOErrorResponse } from '@agrodatabr/agrodataui';

import adminApi from '../../services/api/admin';
import api, { handleRequest } from '../../services/api/api';
import UserData from '../../types/User/UserData';
import { ItemsType, usePage } from '../page';

export const useUser = () => {
  const [isUpdating, setIsUpdating] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const { alertStatus } = usePage();

  const update = async (id: string, data: any) => handleRequest(() => adminApi.users.update(id, data), setIsUpdating);

  const destroy = async (id: string, params = {}) =>
    handleRequest(() => adminApi.users.destroy(id, params), setIsUpdating);

  const getAll = async (params = {}) => {
    try {
      setIsFetching(true);
      const response = await adminApi.users.get(params);
      return response.data;
    } catch (err) {
      alertStatus(err as DTOErrorResponse, 'error');
      return null;
    } finally {
      setIsFetching(false);
    }
  };

  const getOptionsUsers = async (params = {}) => {
    const response = await getAll(params);

    if (!response)
      return {
        data: [] as ItemsType[],
        lastPage: 1,
      };

    return {
      data: response.data.map((user: UserData) => ({
        key: user.id,
        value: user.id,
        label: user.name,
        email: user.email,
      })),
      lastPage: response.last_page,
    };
  };

  const getNotificationConfigurationsOptions = async (params = {}) => {
    const response = await handleRequest(() => api.get('admin/message/tree', params));
    return response;
  };

  const updateNotificationsConfiguration = async (userId: string, notificationsIds: any, shouldSendEmail: boolean) => {
    // const formData = new FormData();
    // notificationsIds.map((id: any) => formData.append(`messages[${index}]`, id));

    // formData.append('send_email', shouldSendEmail ? '1' : '0');
    // formData.append('_method', 'PUT');
    return handleRequest(
      () =>
        api.put(`admin/user/${userId}/message`, {
          messages: notificationsIds,
          send_email: shouldSendEmail ? '1' : '0',
        }),
      setIsUpdating,
    );
  };

  const getCurrentUserNotificationConfiguration = async (userId: string) => {
    const response = handleRequest(() => api.get(`admin/user/${userId}/message`), setIsFetching);

    return response;
  };

  return {
    update,
    isUpdating,
    destroy,
    getOptionsUsers,
    isFetching,
    updateNotificationsConfiguration,
    getNotificationConfigurationsOptions,
    getCurrentUserNotificationConfiguration,
  };
};

import React, { forwardRef, useEffect, useImperativeHandle } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Form } from 'components/ui/form';
import { FormGrid } from 'components/ui/form-grid';
import { FormItem } from 'components/ui/form-item';
import { Input, InputMoney } from 'components/ui/input';

import { useRegisteredCustomerStore } from '../stores/use-registered-customer-store';

type Props = {
  onSuccess?: (data: LiquidReceiptFormData) => void;
};

export type LiquidReceiptFormData = {
  id?: string;
  livestock?: number;
  livestock_commentary?: string;
  other?: number;
  other_commentary?: string;
};

export const liquidReceiptInitialValues = {
  id: '',
  livestock: 0,
  livestock_commentary: '',
  other: 0,
  other_commentary: '',
};

export const LiquidReceiptForm = forwardRef(({ onSuccess }: Props, ref) => {
  const { t } = useTranslation();
  const form = useForm<LiquidReceiptFormData>({
    defaultValues: liquidReceiptInitialValues,
    mode: 'onBlur',
  });

  const liquidReceiptToEdit = useRegisteredCustomerStore(state => state.liquidReceiptToEdit);

  const sendForm = (values: LiquidReceiptFormData) => {
    onSuccess?.(values);
  };

  const submit = () => {
    form.handleSubmit(sendForm, console.log)();
  };

  const validateLivestockOrOthers = (otherFieldName: string, currentFieldValue: number, otherFieldValue: number) => {
    if (currentFieldValue <= 0 && otherFieldValue <= 0) {
      form.setError(
        otherFieldName as keyof LiquidReceiptFormData,
        t('pages.registered-customers.form.pf.net_revenues.otherOrLivestockRequired'),
      );
      return t('pages.registered-customers.form.pf.net_revenues.otherOrLivestockRequired');
    }
    form.clearErrors('other');
    form.clearErrors('livestock');
    return true;
  };

  useImperativeHandle(ref, () => ({
    form: form as UseFormReturn<LiquidReceiptFormData>,
    submit,
  }));

  useEffect(() => {
    form.reset(liquidReceiptToEdit || liquidReceiptInitialValues);
  }, [liquidReceiptToEdit]);

  return (
    <Form form={form} onSubmit={form.handleSubmit(sendForm)}>
      <FormGrid maxCols={2}>
        <FormItem
          label={t('pages.registered-customers.form.pf.net_revenues.livestock')}
          name="livestock"
          rules={{
            validate: {
              validLifestock: value => {
                return validateLivestockOrOthers('livestock', value, form.getValues('other'));
              },
            },
          }}
        >
          <InputMoney min="0" />
        </FormItem>

        <FormItem
          label={t('pages.registered-customers.form.pf.net_revenues.livestock_commentary')}
          name="livestock_commentary"
        >
          <Input />
        </FormItem>

        <FormItem
          label={t('pages.registered-customers.form.pf.net_revenues.other')}
          name="other"
          rules={{
            validate: {
              validLifestock: value => {
                return validateLivestockOrOthers('other', value, form.getValues('livestock'));
              },
            },
          }}
        >
          <InputMoney min="0" />
        </FormItem>

        <FormItem label={t('pages.registered-customers.form.pf.net_revenues.other_commentary')} name="other_commentary">
          <Input />
        </FormItem>
      </FormGrid>
    </Form>
  );
});

LiquidReceiptForm.displayName = 'LiquidReceiptForm';

import { PaginatedQueryParams, usePaginatedQuery } from 'hooks/usePaginatedQuery';
import CreditRequestData from 'types/CreditRequest/CreditRequestData';

type Params = PaginatedQueryParams;

export const useGetCreditRequestsQuery = ({ dispatchPagination, ...params }: Params) => {
  return usePaginatedQuery<CreditRequestData>({
    queryKey: ['credit-request', params],
    url: `credit-request`,
    permissions: ['credit.request.index'],
    params: {
      ...params,
      dispatchPagination,
    },
  });
};

import { useState } from 'react';

import { DTOErrorResponse } from '@agrodatabr/agrodataui';
import { useAbility } from 'hooks/ability';

import monitoringApi from '../../services/api/monitoring';
import { ItemsType, usePage } from '../page';

export const useCrop = () => {
  const { alertStatus } = usePage();
  const ability = useAbility();

  const [isFetching, setIsFetching] = useState(false);

  const getConditions = async () => {
    try {
      setIsFetching(true);
      if (!ability.can('monitoring.cropcondition.all', '')) return null;
      const {
        data: { data: cropConditions },
      } = await monitoringApi.cropCondition.all();
      return cropConditions;
    } catch (err) {
      alertStatus(err as DTOErrorResponse, 'error');
      return null;
    } finally {
      setIsFetching(false);
    }
  };

  const getConditionsOptions = async (): Promise<ItemsType[]> => {
    const conditions = await getConditions();

    if (!conditions) return [];

    return conditions.map((condition: { id: any; name: any }) => ({
      key: condition.id,
      value: condition.id,
      label: condition.name,
    }));
  };

  return {
    getConditions,
    getConditionsOptions,
    isFetching,
  };
};

import styled, { keyframes } from 'styled-components';

const fadeInFwd = keyframes`
 0% {
   -webkit-transform: translateZ(-80px);
           transform: translateZ(-80px);
   opacity: 0;
 }
 100% {
   -webkit-transform: translateZ(0);
           transform: translateZ(0);
   opacity: 1;
 }
`;

export const SContainer = styled.div`
  padding: 8px 8px 24px;
`;

export const SHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const STitlePage = styled.h2`
  color: ${({ theme: { colors } }) => colors.primary};
  font-family: ${({ theme: { fonts } }) => fonts.primary};
  font-size: 18px;
  font-weight: 600;
`;

export const SFilterBoxContainer = styled.div``;

export const SFilterContainer = styled.div`
  display: flex;
  align-items: flex-end;

  .grid-filters {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    grid-template-areas: 'filter-search filter-button filter-clear';
    padding: 8px 0;
    width: 100%;

    .filter-search {
      grid-area: filter-search;
    }

    .filter-button {
      grid-area: filter-button;
      align-items: flex-end;
      display: flex;
    }

    .filter-clear {
      grid-area: filter-clear;
      align-items: flex-end;
      display: flex;
      justify-content: flex-end;
      padding-bottom: 7px;
    }
  }
`;

interface FormContainer {
  visible: boolean;
}

export const SFormContainer = styled.div<FormContainer>`
  display: ${({ visible }) => (visible ? 'block' : 'none')};
  border: solid 1px ${({ theme: { colors } }) => colors.greys.grey03};
  border-radius: 4px;
  background: #ffffff;
  padding: 8px;
  margin-top: 8px;
  margin-bottom: 8px;
  animation: ${fadeInFwd} 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;

  h2 {
    color: ${({ theme: { colors } }) => colors.greys.grey08};
    font-family: ${({ theme }) => theme.fonts.primary};
    font-size: 16px;
    font-weight: 600;
    display: block;
    margin-bottom: 16px;
  }
`;

export const SFormButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  button + button {
    margin-left: 8px;
  }
`;

import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormItem, Select } from 'components';
import { useCache } from 'hooks/cache';
import { useCreditRequestStore } from 'pages/CreditRequest/store/useCreditRequestStore';

export const JsonDataWarehouseStateIdSelect = () => {
  const { t } = useTranslation();
  const cache = useCache();

  const setIsBlocking = useCreditRequestStore(state => state.setIsBlocking);
  const custodyWarehouseAddressIsLegacy = useCreditRequestStore(state => state.custodyWarehouseAddressIsLegacy);
  const setStateIsSelected = useCreditRequestStore(state => state.setStateIsSelected);
  const setCitiesWarehouse = useCreditRequestStore(state => state.setCitiesWarehouse);
  const pageStatus = useCreditRequestStore(state => state.pageStatus);

  return (
    <FormItem
      label={t('pages.credit-request.form.json_data_warehouse_state_id')}
      name="json_data.warehouse_state_id"
      rules={[{ required: custodyWarehouseAddressIsLegacy }]}
      hidden={!custodyWarehouseAddressIsLegacy}
    >
      <Select
        options={cache.getCreditOptions('state')}
        disabled={pageStatus.viewing}
        onChange={(value: any) => {
          if (value) {
            setIsBlocking(true);
            setStateIsSelected(true);
            setCitiesWarehouse(cache.getCityByStateOptions(value));
          }
        }}
      />
    </FormItem>
  );
};

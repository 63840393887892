import React from 'react';
import { AiFillEye } from 'react-icons/ai';
import { FaCloudDownloadAlt, FaTimes } from 'react-icons/fa';

import { DOCUMENT_TYPES } from 'configs/constants';
import { ContractAttachmentData } from 'types/Contract/ContractAttachmentData';

import { ActionButton } from './ActionButton';
import { Container, Buttons, Name } from './styles';

interface Props {
  handleDeleteAttachment: (attachmentId: string) => void;
  handleShowAttachment: (attachmentId: string, name: string, isDocxOrPdf: boolean) => void;
  handleDownloadAttachment: (attachmentId: string) => void;
  attachment: ContractAttachmentData;
  changeSelectedAttachment: (attachmentId: any) => void;
  isSelected: boolean;
  isCompare: boolean;
}

export const AttachmentRow = ({
  attachment,
  handleDeleteAttachment,
  handleShowAttachment,
  handleDownloadAttachment,
  changeSelectedAttachment,
  isSelected,
  isCompare,
}: Props) => {
  const isDocxOrPdf = DOCUMENT_TYPES.includes(attachment.extension) && attachment.extension !== 'pdf';

  return (
    <Container onClick={() => changeSelectedAttachment(attachment)} isClickable={!!changeSelectedAttachment}>
      <Name isSelected={isSelected}>{attachment?.name}</Name>
      <Buttons>
        <ActionButton
          title="Visualizar"
          action={() =>
            isCompare
              ? () => changeSelectedAttachment(attachment.id)
              : handleShowAttachment(attachment.id, '', isDocxOrPdf)
          }
          icon={AiFillEye}
        />
        <ActionButton title="Baixar" action={() => handleDownloadAttachment(attachment.id)} icon={FaCloudDownloadAlt} />
        <ActionButton title="Remover" action={() => handleDeleteAttachment(attachment.id)} icon={FaTimes} />
      </Buttons>
    </Container>
  );
};

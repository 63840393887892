import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Dialog,
  DialogContent,
  DialogFooterForm,
  DialogHeader,
  DialogPortal,
  DialogTrigger,
} from 'components/ui/dialog';

import { useRegisteredCustomerStore } from '../stores/use-registered-customer-store';
import { cofcoOpinionInitialValues, CofcoOpinionFormData, CofcoOpinionForm } from './cofco-opinion.form';

type Props = {
  children?: React.ReactNode;
  onSuccess?: (data: CofcoOpinionFormData) => void;
};

export const CofcoOpinionModal = ({ children, onSuccess }: Props) => {
  const formRef = useRef<any>(null);
  const { t } = useTranslation();

  const isCofcoOpinionModalOpen = useRegisteredCustomerStore(state => state.isCofcoOpinionModalOpen);
  const setCofcoOpinionToEdit = useRegisteredCustomerStore(state => state.setCofcoOpinionToEdit);
  const cofcoOpinionToEdit = useRegisteredCustomerStore(state => state.cofcoOpinionToEdit);
  const setIsCofcoOpinionModalOpen = useRegisteredCustomerStore(state => state.setIsCofcoOpinionModalOpen);

  return (
    <Dialog
      open={isCofcoOpinionModalOpen}
      onOpenChange={value => {
        setIsCofcoOpinionModalOpen(value);
        if (!value) {
          setCofcoOpinionToEdit(null);
          formRef.current?.form.reset(cofcoOpinionInitialValues);
        }
      }}
    >
      <DialogTrigger>{children}</DialogTrigger>
      <DialogPortal>
        <DialogContent className="md:w-[50%] w-[96%]">
          <DialogHeader>
            {cofcoOpinionToEdit ? t('edit') : t('new-f')} {t('commercial-opinion')}
          </DialogHeader>
          <CofcoOpinionForm
            ref={formRef}
            onSuccess={data => {
              setIsCofcoOpinionModalOpen(false);
              setCofcoOpinionToEdit(null);
              onSuccess?.(data);
              formRef.current?.form.reset(cofcoOpinionInitialValues);
            }}
          />
          <DialogFooterForm
            onConfirm={() => {
              formRef.current?.submit();
            }}
            onCancel={() => {
              formRef.current?.form.reset(cofcoOpinionInitialValues);
              setCofcoOpinionToEdit(null);
              setIsCofcoOpinionModalOpen(false);
            }}
          />
        </DialogContent>
      </DialogPortal>
    </Dialog>
  );
};

import { useState } from 'react';

import { handleRequest } from '../../services/api/api';
import dashboardApi from '../../services/api/dashboard';

export const useDashboardRequestPrefin = () => {
  const [isFetchingCharts, setIsFetchingCharts] = useState(false);
  const [isFetchingCards, setIsFetchingCards] = useState(false);
  const [isFetchingTimeline, setIsFetchingTimeline] = useState(false);

  const getRequestPrefinCards = async (params = {}) => {
    const { summary } = await handleRequest(() => dashboardApi.requestPrefin.showCards(params), setIsFetchingCards);
    return { summary };
  };

  const getRequestPrefinCharts = async (params = {}) => {
    setIsFetchingCharts(true);
    const dataChartCredit = await handleRequest(() => dashboardApi.requestPrefin.showChartCredit(params));
    const dataChartRequestInProgres = await handleRequest(() =>
      dashboardApi.requestPrefin.showChartRequestInProgres(params),
    );
    const dataChartOperationModality = await handleRequest(() =>
      dashboardApi.requestPrefin.showChartOperationModality(params),
    );
    setIsFetchingCharts(false);
    return {
      dataChartCredit,
      dataChartRequestInProgres,
      dataChartOperationModality,
    };
  };

  const getRequestPrefinTimeline = async (params?: { params: unknown }, filter = 'all') => {
    const response = await handleRequest(
      () => dashboardApi.requestPrefin.showTimeline({ ...params, parent: filter }),
      setIsFetchingTimeline,
    );
    return response || { data: [] as any[], total: 0, last_page: 0 };
  };

  const getTimelineReport = async (params: any) => {
    const response = await handleRequest(() => dashboardApi.reports.listReportTimelineRequestPrefin(params));
    return response;
  };

  const generateExportTimeline = (values: any) => {
    return dashboardApi.reports.generateExportTimelineRequestPrefin({ ...values });
  };

  return {
    isFetchingCharts,
    isFetchingCards,
    isFetchingTimeline,
    getRequestPrefinCharts,
    getRequestPrefinCards,
    getRequestPrefinTimeline,
    getTimelineReport,
    generateExportTimeline,
  };
};

import React, { useState } from 'react';

import { useUser } from 'hooks/fetch/useUsers';

export interface NotificationController {
  checkedKeys:
    | React.Key[]
    | {
        checked: React.Key[];
        halfChecked: React.Key[];
      };
  updateKeys: (
    selectedKeys:
      | React.Key[]
      | {
          checked: React.Key[];
          halfChecked: React.Key[];
        },
  ) => void;
  updateConfig: (userId: string) => void;
}

export const useNotificationController = () => {
  const { getCurrentUserNotificationConfiguration } = useUser();

  const [checkedKeys, setCheckedKeys] = useState<React.Key[] | { checked: React.Key[]; halfChecked: React.Key[] }>([]);

  const updateKeys = (selectedKeys: React.Key[] | { checked: React.Key[]; halfChecked: React.Key[] }) =>
    setCheckedKeys(selectedKeys);

  const updateConfig = async (userId: string) => {
    const response = await getCurrentUserNotificationConfiguration(userId);
    setCheckedKeys(response.map((notification: any) => notification.name.replaceAll('.', '-')));
  };

  return { checkedKeys, updateKeys, updateConfig };
};

import React from 'react';

import { InfiniteData, UseInfiniteQueryResult } from '@tanstack/react-query';
import { cn } from 'lib/utils';
import PaginationData from 'types/Api/PaginationData';

import { ComboBox, ComboBoxProps, OptionData } from './combo';

export type ComboBoxInfiniteProps<T = unknown> = Omit<
  ComboBoxProps<T>,
  'options' | 'isLoading' | 'onLoadMore' | 'showLoadMore' | 'initialOptions'
> & {
  infiniteQuery: UseInfiniteQueryResult<InfiniteData<PaginationData<OptionData<T>>>>;
  initialOptions?: OptionData<T>[];
};

const ComboBoxInfiniteWithoutRef = (
  { infiniteQuery, ...props }: ComboBoxInfiniteProps,
  ref: React.Ref<HTMLInputElement>,
) => {
  return (
    <ComboBox
      {...props}
      ref={ref}
      options={infiniteQuery?.data?.pages?.map(page => page?.data ?? []).flat() ?? []}
      className={cn('w-full', props.className)}
      isLoading={infiniteQuery.isFetching || infiniteQuery.isFetchingNextPage || infiniteQuery.isLoading}
      onLoadMore={() => infiniteQuery.fetchNextPage()}
      showLoadMore={infiniteQuery.hasNextPage}
    />
  );
};

export const ComboBoxInfinite = React.forwardRef<HTMLInputElement, ComboBoxInfiniteProps>(ComboBoxInfiniteWithoutRef);

import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Alert, Col, Form as AntForm, List, message, Modal as AntModal, Upload, Row } from 'antd';
import { TablePaginationConfig, TableProps } from 'antd/lib/table';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';

import DTOContractImportations, {
  DTOContractImportationsIndexesNames,
} from '../../../../types/Contract/DTOContractImportations';
import { Breadcrumb, Button, FormItem, Input } from '../../../../components';
import { TableData } from '../../../../compositions';
import { usePage } from '../../../../hooks/page';
import edocumentsApi from '../../../../services/api/edocuments';
import { formatValuesToFormData } from '../../../../services/form';
import { DocumentModelData } from '../../../../types/Document/DocumentModelData';
import DTOUserModelModel from '../../../../types/User/UserData';
import { Formatter } from '../../../../utils/Formatter';
import columns from './columns';
import { Container, SUpload } from './styles';
import { ContractImportationsDataTable } from './types';

const ContractImportations: React.FC = () => {
  const { alertStatus } = usePage();
  const { t, i18n } = useTranslation();
  const [uploadForm] = AntForm.useForm();
  const { Dragger } = Upload;

  const [uploadList, setUploadList] = useState([]);
  const [visibilityUploadModal, setVisibilityUploadModal] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [showResultImportation, setShowResultImportation] = useState(false);
  const [resultImportation, setResultImportation] = useState<{ imported: string[]; errors: string[] }>({
    imported: [],
    errors: [],
  });

  const [data, setData] = useState<DTOContractImportations[]>([]);
  const [loading, setLoading] = useState(false);
  const [paginationConfig, setPaginationConfig] = useState<TablePaginationConfig>({
    current: 1,
    total: 1,
    pageSize: 20,
  });

  // Table DATA Config: BEGIN
  const tableConfig = {
    search: '',
    filtered: {},
    sorter: {},
  };

  const [tableDataConfig, setTableDataConfig] = useState(tableConfig);

  const loadTableData = useCallback(
    (values: ContractImportationsDataTable) => {
      setLoading(true);
      edocumentsApi.contracts.importations
        .get({
          params: {
            ...values,
          },
        })
        .then(response => {
          const result = response.data.data;
          setData(result.data);
          setPaginationConfig(paginationConfigState => ({
            ...paginationConfigState,
            current: result.current_page,
            total: result.total,
          }));
        })
        .catch(err => alertStatus(err, 'error'))
        .finally(() => setLoading(false));
    },
    // eslint-disable-next-line
    [i18n.language, alertStatus],
  );

  const handleTableChange: TableProps<any>['onChange'] = useCallback(
    (
      pagination: TablePaginationConfig,
      filters: Record<string, FilterValue | null>,
      sorter: SorterResult<any> | SorterResult<any>[],
    ) => {
      setLoading(true);
      setTableDataConfig(tableDataConfigState => ({
        ...tableDataConfigState,
        filtered: filters,
        sorter,
      }));

      let direction = '';
      const search: any = {};
      let sort = '';

      if (sorter) {
        const { field, order } = sorter as SorterResult<any>;

        if (order === 'ascend') direction = 'asc';
        if (order === 'descend') direction = 'desc';
        sort = field?.toString();
      }

      if (filters) {
        const dataFilter: any = filters;

        if (dataFilter) {
          Object.keys(dataFilter).forEach((key: string) => {
            if (dataFilter[key] !== null && dataFilter[key] !== undefined && dataFilter[key][0]) {
              search[key] = dataFilter[key][0].toString();
            } else if (dataFilter[key] !== null && dataFilter[key] !== undefined) {
              search[key] = dataFilter[key].toString();
            } else {
              search[key] = '';
            }
          });
        }
      }

      setPaginationConfig(paginationConfigState => ({
        ...paginationConfigState,
        pageSize: pagination.pageSize,
        current: pagination.current,
      }));

      loadTableData({
        page: pagination.current,
        per_page: pagination.pageSize,
        sort,
        direction,
        ...search,
      });
    },
    [loadTableData],
  );

  useEffect(() => {
    const filters: any = {};
    handleTableChange({ current: 1, pageSize: paginationConfig.pageSize }, filters, {}, null);
  }, [handleTableChange, paginationConfig.pageSize]);
  // Table DATA Config: END

  // Upload File: BEGIN
  function handleChangeUploadFile(info: any) {
    let fileList = [...info.fileList];

    // 1. Limit the number of uploaded files
    // Only to show one recent uploaded files, and old ones will be replaced by the new
    fileList = fileList.slice(-1);

    // 2. Read from response and show file link
    fileList = fileList.map(file => {
      if (file.response) {
        // Component will show file.url as link
        file.url = file.response.url;
      }
      return file;
    });
    setUploadList(fileList);
  }

  function handleCustomRequestUploadFile(info: any) {
    const { file } = info;
    uploadForm.setFieldsValue({ document: [file] });
  }

  function handleReset() {
    setVisibilityUploadModal(false);
    setIsUploading(false);
    setUploadList([]);
    uploadForm.resetFields();
  }

  async function handleStore() {
    setVisibilityUploadModal(false);
    setIsUploading(true);

    const formValues = uploadForm.getFieldsValue();
    let formData = new FormData();
    formData = formatValuesToFormData(formValues, formData, ['document']);

    edocumentsApi.contracts.importations
      .store(formData)
      .then(response => {
        const filters: any = {};
        handleTableChange({ current: 1, pageSize: paginationConfig.pageSize }, filters, {}, null);
        message.success(t('pages.edocuments.contracts.importations.uploaded-file'), 4);

        setShowResultImportation(true);
        const { errors, imported } = response.data.data;
        setResultImportation({ errors, imported });
      })
      .catch(err => alertStatus(err, 'error'))
      .finally(() => {
        handleReset();
      });
  }
  // Upload File: END

  return (
    <Container>
      <Breadcrumb
        items={[
          { title: t('breadcrumb.edocuments') },
          { title: t('breadcrumb.contracts'), to: '/edocuments/contracts' },
          { title: t('breadcrumb.contracts-importations') },
        ]}
      />

      <div className="content-container">
        <div className="grid-actions">
          <h2>{t('pages.edocuments.contracts.importations.title')}</h2>
          <div className="grid-button-importation">
            <Button status="primary" onClick={() => setVisibilityUploadModal(true)}>
              {t('pages.edocuments.contracts.importations.new-importation')}
            </Button>
          </div>
        </div>

        {isUploading && (
          <Alert
            message={t('pages.edocuments.contracts.importations.is-uploading-file')}
            type="info"
            showIcon
            closable
          />
        )}

        {showResultImportation && (
          <>
            <Row gutter={[16, 16]}>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <Alert
                  message={t('pages.edocuments.contracts.importations.success-imported-lines')}
                  type="success"
                  showIcon
                />
                <List
                  size="small"
                  bordered
                  dataSource={resultImportation.imported}
                  renderItem={item => <List.Item>{item}</List.Item>}
                />
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <Alert message={t('pages.edocuments.contracts.importations.errors-lines')} type="error" showIcon />
                <List
                  size="small"
                  bordered
                  dataSource={resultImportation.errors}
                  renderItem={item => <List.Item>{item}</List.Item>}
                />
              </Col>
            </Row>
          </>
        )}

        <TableData
          rowKey="id"
          dataSource={data}
          columns={columns.contracts.map(column => {
            switch (column.key) {
              case 'documents':
              case 'user':
                return {
                  ...column,
                  render: (_, record: DTOContractImportations) => {
                    const key: DTOContractImportationsIndexesNames = column.key as DTOContractImportationsIndexesNames;
                    const row: DocumentModelData | DTOUserModelModel = record[key] as
                      | DocumentModelData
                      | DTOUserModelModel;
                    return row ? row.name : '';
                  },
                };
              case 'created_at':
                return {
                  ...column,
                  render: (_, record: DTOContractImportations) => {
                    const key: DTOContractImportationsIndexesNames = column.key as DTOContractImportationsIndexesNames;
                    return record[key] ? Formatter.datetime(record[key].toString()) : '';
                  },
                };
              default:
                return column;
            }
          })}
          loading={loading}
          onChange={handleTableChange}
          pagination={paginationConfig}
          tableConfig={tableDataConfig}
        />
      </div>

      <AntModal
        title={t('pages.edocuments.contracts.importations.title-upload')}
        visible={visibilityUploadModal}
        width="80%"
        footer={
          <>
            <div className="buttons">
              <Button status="primary" onClick={() => handleStore()}>
                {t('pages.edocuments.contracts.importations.send-upload')}
              </Button>
              <Button status="primary-outline" onClick={() => handleReset()}>
                {t('pages.edocuments.contracts.importations.cancel-upload')}
              </Button>
            </div>
          </>
        }
        className="modal-with-custom-footer"
      >
        <AntForm form={uploadForm} name="contracts_importation">
          <FormItem hidden name="language" initialValue={i18n.language}>
            <Input />
          </FormItem>
          <FormItem hidden name="document">
            <Input />
          </FormItem>

          <SUpload>
            <Dragger
              showUploadList
              onChange={handleChangeUploadFile}
              fileList={uploadList}
              customRequest={handleCustomRequestUploadFile}
              accept=".xls,.xlsx"
            >
              {t('pages.edocuments.contracts.importations.upload-file-text')}
            </Dragger>
          </SUpload>
        </AntForm>
      </AntModal>
    </Container>
  );
};

export default ContractImportations;

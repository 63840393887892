import api from './api';

export default {
  contracts: {
    get: async (params: object = null): Promise<any> => {
      return api.get('edocument/contract', params);
    },
    show: async (contractNumber: string, params: object = null): Promise<any> => {
      return api.get(`edocument/contract/${contractNumber}`, params);
    },
    importations: {
      get: async (params: object = null): Promise<any> => {
        return api.get('edocument/contract/import', params);
      },
      store: async (params: object): Promise<any> => {
        return api.post('edocument/contract/import', params);
      },
    },
  },

  template: {
    get: async (params: object = null): Promise<any> => {
      return api.get(`edocument/template`, params);
    },
    all: async (params: object = null): Promise<any> => {
      return api.get(`edocument/template/all`, params);
    },
    update: async (id: string, params: object = null): Promise<any> => {
      return api.put(`edocument/template/${id}`, params);
    },
    store: async (params: object = null): Promise<any> => {
      return api.post(`edocument/template`, params);
    },
    destroy: async (id: string, params: object = null): Promise<any> => {
      return api.delete(`edocument/template/${id}`, params);
    },
    destroyDocument: async (id: string, params: object = null): Promise<any> => {
      return api.delete(`edocument/template/templatedocument/${id}`, params);
    },
  },
};

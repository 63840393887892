import { useMutation } from '@tanstack/react-query';
import { usePage } from 'hooks/page';
import api from 'services/api/api';

export const useUpdatePackageMutation = () => {
  const { alertStatus } = usePage();

  return useMutation({
    mutationKey: ['updatePackage'],
    mutationFn: async (data: FormData) => {
      const response = await api.post(`credit-request/package/${data.get('id')}`, data, {
        params: { _method: 'PUT' },
      });
      if (response.status !== 200) throw new Error(response.data?.message || 'Error updating package');
      if (!response.data) return null;
      return response.data;
    },
    onError: error => {
      alertStatus(error.message, 'error');
    },
  });
};

import styled from 'styled-components';

export const SCoverCard = styled.div`
  width: 50px;
  height: 50px;
  font-size: 32px;
  color: ${({ theme: { colors } }) => colors.white};
  background: ${({ theme: { colors } }) => colors.primary};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SAreaCard = styled.div`
  display: flex;
  margin: 10px;
`;

import styled from 'styled-components';

export const Container = styled.div<{ isMenuCollapsed: boolean }>`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: ${({ isMenuCollapsed }) => (isMenuCollapsed ? '5% 95%' : '20% 80%')};
`;

export const Menu = styled.div`
  background: ${({ theme: { colors } }) => colors.lightGrey};
  height: 100%;
  overflow: hidden;
`;

export const ToggleMenuButton = styled.a<{ isMenuCollapsed: boolean }>`
  padding: 10px;
  float: right;
`;
export const AttachmentsWrapper = styled.div<{ isCollapsed: boolean }>`
  width: ${({ isCollapsed }) => (isCollapsed ? '0px' : '100%')};
  overflow: hidden;
`;

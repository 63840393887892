import { useTranslation } from 'react-i18next';

import { TablePaginationConfig } from 'antd/lib/table';

import { useSerasaParameter } from '../../../../hooks/fetch/useSerasaParameter';
import { useModalMessage } from '../../../../hooks/helpers/useModalMessage';
import { ParameterizationTypesProps } from './types';

export const useSerasaParameterization = ({ refresh }: ParameterizationTypesProps) => {
  const { t } = useTranslation();
  const { getSerasa, update, isFetching } = useSerasaParameter();
  const { handleSuccess } = useModalMessage();

  const handleTableChange = async (pagination: TablePaginationConfig, filters: object, sorter: any) => {
    const { data, total } = await getSerasa({
      params: {
        page: pagination.current,
        per_page: pagination.pageSize,
        sort: sorter.sort,
        direction: sorter.direction,
        ...filters,
      },
    });
    return { data, total };
  };
  const handleEdit = (record: any, setVisibleForm: any, setDataForm: any) => {
    setVisibleForm(true);
    setDataForm(record);
  };

  const handleSave = async (data: any, setVisibleForm: any, setDataForm: any) => {
    const response = await update(data.id, data);
    if (response) {
      handleSuccess(t('pages.serasa.parameterization.message_success'));
      setVisibleForm(false);
      setDataForm({});
      refresh();
    }
  };

  return {
    handleTableChange,
    handleEdit,
    handleSave,
    isFetching,
  };
};

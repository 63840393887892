import { useAuth } from './auth';

export const useKanbanPhases = () => {
  const {
    user: { config: userConfig },
  } = useAuth();

  const getLastSelectedPhase = () => {
    return userConfig.modules.credit.kanban.lastPhase;
  };

  const getLastSelectedTeam = () => {
    return userConfig.modules.credit.kanban.lastTeam;
  };

  return { getLastSelectedPhase, getLastSelectedTeam };
};

import React, { forwardRef, useEffect, useImperativeHandle } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Form } from 'components/ui/form';
import { FormGrid } from 'components/ui/form-grid';
import { FormItem } from 'components/ui/form-item';
import { Input, InputMoney, InputWithMask } from 'components/ui/input';

import { useRegisteredCustomerStore } from '../stores/use-registered-customer-store';

type Props = {
  onSuccess?: (data: CommercialReferenceFormData) => void;
};

export type CommercialReferenceFormData = {
  id?: string;
  name?: string;
  credit_limit?: number;
  warranty?: string;
  phone_number?: string;
  contact_name?: string;
  json_data?: {
    warranty?: string;
    credit_limit?: number;
  };
};

export const commercialReferenceInitialValues = {
  id: '',
  name: '',
  credit_limit: 0,
  warranty: '',
  phone_number: '',
  contact_name: '',
};

export const CommercialReferenceForm = forwardRef(({ onSuccess }: Props, ref) => {
  const { t } = useTranslation();
  const form = useForm<CommercialReferenceFormData>({
    defaultValues: commercialReferenceInitialValues,
    mode: 'onBlur',
  });

  const commercialReferenceToEdit = useRegisteredCustomerStore(state => state.commercialReferenceToEdit);

  const sendForm = (values: CommercialReferenceFormData) => {
    onSuccess?.(values);
  };

  const submit = () => {
    form.handleSubmit(sendForm, console.log)();
  };

  useImperativeHandle(ref, () => ({
    form: form as UseFormReturn<CommercialReferenceFormData>,
    submit,
  }));

  useEffect(() => {
    form.reset(commercialReferenceToEdit || commercialReferenceInitialValues);
  }, [commercialReferenceToEdit]);

  return (
    <Form form={form} onSubmit={form.handleSubmit(sendForm)}>
      <FormGrid maxCols={2}>
        <FormItem
          label={t('pages.registered-customers.form.pf.provider_reference.name')}
          name="name"
          rules={{ required: true }}
        >
          <Input maxLength={200} />
        </FormItem>

        <FormItem label={t('pages.registered-customers.form.pf.provider_reference.credit_limit')} name="credit_limit">
          <InputMoney />
        </FormItem>

        <FormItem label={t('pages.registered-customers.form.pf.provider_reference.warranty')} name="warranty">
          <Input maxLength={200} />
        </FormItem>

        <FormItem
          label={t('pages.registered-customers.form.pf.provider_reference.phone_number')}
          name="phone_number"
          rules={{ required: true }}
        >
          <InputWithMask mask="(99) 9999-9999" placeholder="(__) ____-____" />
        </FormItem>

        <FormItem
          label={t('pages.registered-customers.form.pf.provider_reference.contact_name')}
          name="contact_name"
          rules={{ required: true }}
        >
          <Input maxLength={200} />
        </FormItem>
      </FormGrid>
    </Form>
  );
});

CommercialReferenceForm.displayName = 'CommercialReferenceForm';

import React, { useContext } from 'react';

import { ImageViewerContext } from './context';
import { MagnifingButtons } from './MagnifingButtons';
import { Container, Image } from './styles';

interface Props {
  url: string;
}

export const ImageViewer = ({ url }: Props) => {
  const { scale } = useContext(ImageViewerContext);

  return (
    <Container>
      <div
        style={{
          overflow: 'auto',
          position: 'absolute',
          height: '100%',
          width: '100%',
        }}
      >
        <Image src={url} scale={scale} />
      </div>
      <MagnifingButtons />
    </Container>
  );
};

import React, { useEffect, useState } from 'react';

import { CONTRACT_STATUS_VALIDATE_SIGNATURE, DOCUMENT_TYPES } from 'configs/constants';

import { useAttachment } from '../../../../../hooks/fetch/useAttachment';
import { useContract } from '../../../../../hooks/fetch/useContract';
import { useContractContext } from '../../../context/ContractContext';
import { PreviewCell } from '../PreviewCell';
import { Container } from './styles';

interface Props {
  selectedAttachment: any;
}

export const PreviewPanel = ({ selectedAttachment }: Props) => {
  const { contract } = useContractContext();
  const { preview: previewContract } = useContract();
  const { preview: previewAttachment } = useAttachment();

  const [contractUrl, setContractUrl] = useState('');
  const [attachmentUrl, setAttachmentUrl] = useState('');
  const [isSelectedAttachmentFromGoogleDocs, setIsSelectedAttachmentFromGoogleDocs] = useState(false);

  const getContractUrl = async () => {
    const response = await previewContract(contract.id);
    setContractUrl(response.url);
  };

  const getAttachmentUrl = async () => setAttachmentUrl(await previewAttachment(selectedAttachment.id));

  useEffect(() => {
    getContractUrl();
  }, []);

  useEffect(() => {
    if (selectedAttachment) {
      getAttachmentUrl();
      setIsSelectedAttachmentFromGoogleDocs(DOCUMENT_TYPES.includes(selectedAttachment.extension));
    }
  }, [selectedAttachment]);

  return (
    <Container>
      <PreviewCell
        isDocument
        url={contractUrl}
        isPdf={
          !!contract.timeline?.filter(
            timelinePoint => timelinePoint.uuid === CONTRACT_STATUS_VALIDATE_SIGNATURE && !!timelinePoint.date,
          ).length
        }
      />
      {attachmentUrl && (
        <PreviewCell
          url={attachmentUrl}
          isDocument={isSelectedAttachmentFromGoogleDocs}
          isPdf={selectedAttachment.extension === 'pdf'}
        />
      )}
    </Container>
  );
};

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactJson from 'react-json-view';

import { Modal } from 'antd';

import { useIntegrations } from '../../hooks/useIntegrations';
import { ViewDocumentProps } from './types';

export const ViewDocument = ({ id, setIdDocument, setLoading }: ViewDocumentProps) => {
  const { t } = useTranslation();
  const [jsonDocument, setJsonDocument] = useState({});
  const [modalVisible, setModalVisible] = useState(false);
  const { getFileUrl } = useIntegrations();

  useEffect(() => {
    const getDataFile = async () => {
      setLoading({ type: 'view', loading: true });
      const data = await getFileUrl(id);
      setJsonDocument(data);
      setModalVisible(true);
      setLoading({ type: '', loading: false });
    };
    if (id) getDataFile();
  }, [id]);

  return (
    <Modal
      title={t('pages.serasa.integrations.modal.title')}
      visible={modalVisible}
      width={780}
      onCancel={() => {
        setModalVisible(false);
        setIdDocument('');
      }}
      footer={null}
    >
      <ReactJson src={jsonDocument} collapsed />
    </Modal>
  );
};

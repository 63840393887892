import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineDislike } from 'react-icons/ai';

import { Col, Form, Input, Modal } from 'antd';
import { useForm } from 'antd/lib/form/Form';

import { Button, FormItem, Select } from '../../../../components';
import { useContract } from '../../../../hooks/fetch/useContract';
import theme from '../../../../styles/theme';
import { Container } from './styles';

interface Props {
  isOpen: boolean;
  close: () => void;
  onOk: (values: any) => void;
}

export const CancelSignaturesModal = ({ isOpen, close, onOk }: Props) => {
  const { t } = useTranslation();
  const [form] = useForm();
  const { isFetchingRefusalStatuses, getRefusalStatuses } = useContract();

  const [refusalStatuses, setRefusalStatuses] = React.useState([]);

  const loadRefusalStatuses = async () => {
    const response = await getRefusalStatuses();
    if (response)
      setRefusalStatuses(response.map((status: any) => ({ label: status.name, value: status.id, key: status.id })));
  };

  const handleSubmit = () => form.validateFields().then(values => onOk(values));

  useEffect(() => {
    loadRefusalStatuses();
  }, []);

  return (
    <Modal visible={isOpen} onCancel={close} title="Cancelar Assinaturas" footer={null}>
      <Container>
        <AiOutlineDislike size={60} color={theme.colors.danger} />
        <p>Gostaria realmente de cancelar as assinaturas?</p>

        <Form form={form} style={{ width: '100%' }}>
          <Col xl={24} xxl={24} lg={24} md={24} sm={24} xs={24}>
            <FormItem
              name="obs"
              label={t('pages.edocuments.emission.draftEmission.reproveDraftReason')}
              rules={[{ required: true }]}
            >
              <Input.TextArea
                placeholder={t('pages.edocuments.emission.draftEmission.reproveDraftReasonPlaceholder')}
                rows={5}
              />
            </FormItem>
          </Col>
          <Col xl={24} xxl={24} lg={24} md={24} sm={24} xs={24}>
            <FormItem
              name="refusal_status_id"
              label={t('pages.edocuments.emission.draftEmission.reproveDraftStatus')}
              rules={[{ required: true }]}
            >
              <Select options={refusalStatuses} loading={isFetchingRefusalStatuses} />
            </FormItem>
          </Col>
        </Form>

        <Button status="danger" onClick={handleSubmit}>
          Confirmar
        </Button>

        <a onClick={close}>Voltar</a>
      </Container>
    </Modal>
  );
};

import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Popover } from 'antd';
import { Button } from 'components';
import { useModal } from 'hooks/modals/useModal';

import { Formatter } from '../../../../../../utils/Formatter';
import { Tag } from '../../../../components/Tag';
import { useContractStatus } from '../../../../constants/status';
import { ContractContext } from '../../../../context/ContractContext';
import { ReissueModal } from '../ReissueModal';
import { TitleWrapper, Container, DateInfo, ContractName } from './styles';

export const Header = () => {
  const { contract, isSelectedContractInStatus, isSelectedContractCancelled } = useContext(ContractContext);
  const { getContractStatusDataById } = useContractStatus();
  const { t } = useTranslation();
  const { open: openReissueModal, close: closeReissueModal, isOpen: isReissueModalOpen } = useModal();
  const status = getContractStatusDataById(contract?.contract_status_id);
  const { icon: Icon } = status ?? { icon: 'div' };

  const handleCopyContractIdToClipboard = () => {
    navigator.clipboard.writeText(contract?.id ?? '');
  };

  return (
    <div>
      <Container>
        <TitleWrapper>
          {/* <BiArrowBack /> */}
          <Popover content="Clique para copiar o ID do contrato">
            <ContractName onClick={handleCopyContractIdToClipboard}>
              {`${t('pages.edocuments.emission.filePreviewTitlePrefix')} ${contract?.number ?? ''}`}
            </ContractName>
          </Popover>
          <Tag
            className="text-[14px]"
            style={{
              backgroundColor: status?.background,
              color: status?.color,
            }}
          >
            <Icon />
            {contract?.status?.name}
          </Tag>
        </TitleWrapper>

        <DateInfo>
          {`${t('pages.edocuments.emission.filePreviewDatePrefix')}: ${
            contract && Formatter.datetime(contract?.updated_at)
          }`}
        </DateInfo>
      </Container>
      {(isSelectedContractInStatus('sentSignature') ||
        isSelectedContractInStatus('completedDraft') ||
        isSelectedContractInStatus('waiting') ||
        isSelectedContractInStatus('validateEmission') ||
        isSelectedContractInStatus('approvedSignature') ||
        isSelectedContractInStatus('requestNewSignatures') ||
        isSelectedContractInStatus('partiallySigned')) &&
        !isSelectedContractCancelled && (
          <Button
            style={{ float: 'right' }}
            onClick={openReissueModal}
            permission={
              isSelectedContractInStatus('approvedSignature') ? 'contract.approvedissue' : 'contract.restartcontract'
            }
          >
            {t('pages.edocuments.emission.reissueDraft')}
          </Button>
        )}

      <ReissueModal close={closeReissueModal} isOpen={isReissueModalOpen} />
    </div>
  );
};

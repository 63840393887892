import React from 'react';
import { useTranslation } from 'react-i18next';

import { DatePicker } from 'antd';
import ptBR from 'antd/es/date-picker/locale/pt_BR';
import { FormItem } from 'components';
import { Moment } from 'moment';
import { useCreditRequestStore } from 'pages/CreditRequest/store/useCreditRequestStore';

import appConfigs from '../../../../../configs/app';

interface Props {
  disabledDate?: (current: Moment) => boolean;
}

export const JsonDataExpactedPaymentAtInput = ({ disabledDate }: Props) => {
  const { t } = useTranslation();

  const setIsBlocking = useCreditRequestStore(state => state.setIsBlocking);
  const pageStatus = useCreditRequestStore(state => state.pageStatus);

  return (
    <FormItem
      label={t('pages.credit-request.form.json_data_expected_payment_at')}
      name="json_data.expected_payment_at"
      rules={[{ required: true }]}
    >
      <DatePicker
        locale={ptBR}
        mode="date"
        disabledDate={disabledDate || (() => false)}
        format={appConfigs.formatDate}
        disabled={pageStatus.viewing}
        onChange={value => {
          if (value) {
            setIsBlocking(true);
          }
        }}
      />
    </FormItem>
  );
};

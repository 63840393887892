import React from 'react';

import { Tag } from '../../Tag';
import { TagCheckBoxProps } from './types';

export const TagCheckBox = ({ onClick, isSelected, children, name }: TagCheckBoxProps) => {
  return (
    <Tag className="w-min p-[0px 15px]" onClick={onClick} name={name} selected={isSelected} isCheck>
      {children}
    </Tag>
  );
};

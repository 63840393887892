/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaDownload } from 'react-icons/fa';
import ReactJson from 'react-json-view';
import XMLViewer from 'react-xml-viewer';

import { Collapse, Modal, Spin } from 'antd';
import { Button } from 'components';
import appConfigs from 'configs/app';
import moment from 'moment';
import { handleRequest } from 'services/api/api';
import documentApi from 'services/api/document';
import { fileDownload } from 'utils/FileDownload';

import { DataIntegrationAttachment } from '../../../../@types/data/IntegrationDataProvider';
import { CollapsibleHeader } from './styles';

interface Props {
  isVisible: boolean;
  setModalFileViewVisible: React.Dispatch<React.SetStateAction<boolean>>;
  dataAttachments: DataIntegrationAttachment[];
  sourceIntegration: { source_name: string; source_type: string };
}

export const FileViewModal = ({ setModalFileViewVisible, isVisible, dataAttachments, sourceIntegration }: Props) => {
  const { t } = useTranslation();
  const { Panel } = Collapse;
  const [active, setActive] = useState('');
  const [isLoadingJson, setIsLoadingJson] = useState(false);
  const [attachmentContent, setAttachmentContent] = useState<string>('');
  const [attachmentContentType, setAttachmentContentType] = useState<'xml' | 'json' | 'csv' | 'txt' | null>(null);

  const [isDownloading, setIsDownloading] = useState(false);

  const getAttachmentContentType = (id: string) => {
    const extension = dataAttachments.find(attachment => attachment.id === id)?.extension;
    if (!extension) return null;
    if (extension === 'xml') return 'xml';
    if (extension === 'json') return 'json';
    if (extension === 'csv') return 'csv';
    if (extension === 'txt') return 'txt';
    return null;
  };

  const handleGetAttachmentContent = async (key: string | string[]) => {
    const id = Array.isArray(key) ? key[1] : key;
    setActive(id);
    if (!id) return;
    const response = await handleRequest(() => documentApi.download(id), setIsLoadingJson);

    const reader = new FileReader();
    reader.readAsText(response);
    const textContent = await (response as Blob).text();
    setAttachmentContent(textContent);

    const contentType = getAttachmentContentType(id);
    setAttachmentContentType(contentType);
  };

  return (
    <Modal
      footer={null}
      width="50%"
      title={
        <div style={{ display: 'flex', justifyContent: 'space-between', marginRight: '30px', alignItems: 'center' }}>
          <span>{`${sourceIntegration.source_name} - ${sourceIntegration.source_type}`}</span>
        </div>
      }
      onCancel={() => setModalFileViewVisible(false)}
      visible={isVisible}
    >
      <Spin spinning={isLoadingJson}>
        <Collapse activeKey={active} accordion onChange={handleGetAttachmentContent}>
          {dataAttachments.map(attachment => (
            <Panel
              key={attachment?.id}
              header={
                <CollapsibleHeader>
                  <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                    {`${moment(attachment.created_at).format(appConfigs.formatDateFullTime)} - ${
                      attachment.name?.toUpperCase() ?? t('notProvided')
                    }`}
                  </span>
                  <Button
                    onClick={() =>
                      dataAttachments[0] && fileDownload(attachment?.id, attachment?.name, setIsDownloading)
                    }
                    loading={isDownloading}
                    disabled={isDownloading}
                  >
                    <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                      <span>{t('download.file')}</span>
                      <FaDownload />
                    </div>
                  </Button>
                </CollapsibleHeader>
              }
            >
              {attachmentContentType === 'json' ? (
                <ReactJson src={attachmentContent ? JSON.parse(attachmentContent) : ''} />
              ) : null}

              {attachmentContentType === 'xml' ? <XMLViewer xml={attachmentContent} collapsible /> : null}
            </Panel>
          ))}
        </Collapse>
      </Spin>

      <a id="downloadAnchorElem" style={{ display: 'none' }} />
    </Modal>
  );
};

import { useState } from 'react';

import { AlertStatus } from '@agrodatabr/agrodataui';
import { TablePaginationConfig } from 'antd/lib/table';

import { useSustainability } from '../../../../hooks/fetch/useSustainability';
import { ContractTypesProps } from './types';

export const useSustainabilities = ({ refresh }: ContractTypesProps) => {
  const { getReports, isFetching, deleteReport } = useSustainability();

  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [contractBeingEditedId, setContractBeingEditedId] = useState('');
  const [searchFilters, setSearchFilters] = useState<object>();

  const closeForm = () => {
    setIsFormOpen(false);
    setContractBeingEditedId('');
  };

  const openForm = () => setIsFormOpen(true);

  const toggleForm = () => setIsFormOpen(!isFormOpen);

  const handleTableChange = async (pagination: TablePaginationConfig, filters: object, sorter: any) => {
    const { data, total } = await getReports({
      params: {
        page: pagination.current,
        per_page: pagination.pageSize,
        sort: sorter.sort,
        direction: sorter.direction,
        ...filters,
      },
    });

    return { data, total };
  };
  const handleEdit = (id: string) => {
    window.open(`/sustainability/request-report/${id}`, '_blank');
  };

  const remove = async (id: string) => {
    const response = await deleteReport(id);
    if (response) {
      refresh();
      AlertStatus({ status: 'success', messages: 'Tipo de Contrato removido com sucesso' });
    }
  };

  const handleRemove = async (id: string) => {
    // TODO colocar confirmação
    remove(id);
  };

  return {
    closeForm,
    openForm,
    isFormOpen,
    toggleForm,
    isEditing,
    setIsEditing,
    isFetching,
    handleTableChange,
    handleEdit,
    handleRemove,
    contractBeingEditedId,
    setSearchFilters,
    searchFilters,
  };
};

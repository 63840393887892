import React from 'react';

import { SapSvgLogo } from 'components/SapSvgLogo/SapSvgLogo';
import { TotvsSvgLogo } from 'components/TotvsSvgLogo/TotvsSvgLogo';
import {
  CREDIT_REQUEST_TEAM_STATUS_EDITABLE_RULE,
  OPERATION_SLUG_PREFIN,
  OPERATION_SLUG_PREPAY,
  PHYSICAL_PERSON_TYPE,
} from 'configs/constants';

import { Header } from './styles';
import { CardHeaderProps } from './types';

export const CardHeader = ({ data }: CardHeaderProps) => {
  const {
    requester_person,
    requester_person_type,
    number,
    id,
    team_status,
    status_count,
    operation,
    region,
    json_data,
    datasource,
  } = data;
  const isPhysicalPerson = requester_person_type === PHYSICAL_PERSON_TYPE;

  const isCommercialPending =
    team_status &&
    operation.slug !== OPERATION_SLUG_PREPAY &&
    CREDIT_REQUEST_TEAM_STATUS_EDITABLE_RULE.includes(team_status.id);

  const shouldHideDatasourceIcon =
    !datasource || (operation.slug !== OPERATION_SLUG_PREPAY && operation.slug !== OPERATION_SLUG_PREFIN);
  return (
    <>
      <Header target="_blank" to={`/credit-request/${isCommercialPending ? 'edit' : 'view'}/${id}`}>
        <div className="header-tiny">
          <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>{number}</div>
          <div>{status_count > 0 && <span>{status_count}</span>}</div>
          <div>{shouldHideDatasourceIcon ? null : datasource === 'SAP' ? <SapSvgLogo /> : <TotvsSvgLogo />}</div>
        </div>
        <div className="header-body">
          {operation.slug === 'prepayment' && json_data?.contract?.requester_person_name}
          {operation.slug !== 'prepayment' &&
            (isPhysicalPerson ? requester_person?.name : requester_person?.fantasy_name)}{' '}
          {region && region?.slug ? <span>{region?.slug.toUpperCase()}</span> : ''}
        </div>
      </Header>
    </>
  );
};

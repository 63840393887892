import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Dialog,
  DialogContent,
  DialogFooterForm,
  DialogHeader,
  DialogPortal,
  DialogTrigger,
} from 'components/ui/dialog';

import { useRegisteredCustomerStore } from '../stores/use-registered-customer-store';
import { PartnerForm, PartnerFormData, partnerFormInitialValues } from './partner.form';

type Props = {
  children?: React.ReactNode;
  onSuccess?: (data: PartnerFormData) => void;
};

export const PartnerModal = ({ children, onSuccess }: Props) => {
  const formRef = useRef<any>(null);
  const { t } = useTranslation();

  const isPartnerModalOpen = useRegisteredCustomerStore(state => state.isPartnerModalOpen);
  const setPartnerToEdit = useRegisteredCustomerStore(state => state.setPartnerToEdit);
  const partnerToEdit = useRegisteredCustomerStore(state => state.partnerToEdit);
  const setIsPartnerModalOpen = useRegisteredCustomerStore(state => state.setIsPartnerModalOpen);

  return (
    <Dialog
      open={isPartnerModalOpen}
      onOpenChange={value => {
        setIsPartnerModalOpen(value);
        if (!value) {
          setPartnerToEdit(null);
          formRef.current?.form.reset(partnerFormInitialValues);
        }
      }}
    >
      <DialogTrigger>{children}</DialogTrigger>
      <DialogPortal>
        <DialogContent className="md:w-[50%] w-[96%]">
          <DialogHeader>
            {partnerToEdit ? t('edit') : t('new-m')} {t('partner')}
          </DialogHeader>
          <PartnerForm
            ref={formRef}
            onSuccess={data => {
              setIsPartnerModalOpen(false);
              setPartnerToEdit(null);
              onSuccess?.(data);
              formRef.current?.form.reset(partnerFormInitialValues);
            }}
          />
          <DialogFooterForm
            onConfirm={() => {
              formRef.current?.submit();
            }}
            onCancel={() => {
              formRef.current?.form.reset(partnerFormInitialValues);
              setPartnerToEdit(null);
              setIsPartnerModalOpen(false);
            }}
          />
        </DialogContent>
      </DialogPortal>
    </Dialog>
  );
};

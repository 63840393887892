export const columns: any = [
  {
    title: 'pages.integration.dataProviders.table.header.created_at',
    dataIndex: 'created_at',
    key: 'created_at',
    sorter: true,
  },
  {
    title: 'pages.integration.dataProviders.table.header.user',
    dataIndex: 'user',
    key: 'user',
  },
  {
    title: 'pages.integration.dataProviders.table.header.status',
    dataIndex: 'status',
    key: 'status',
    sorter: true,
  },
  {
    title: 'pages.integration.dataProviders.table.header.actions',
    key: 'action',
    dataIndex: 'action',
    align: 'center',
    width: '130px',
    fixed: 'right',
  },
];

export default columns;

import styled from 'styled-components';

import { ScrollBox } from '../../../../../styles';

export const Container = styled.div`
  background: ${({ theme: { colors } }) => colors.greys.grey01};
  padding: 27px 0;
  display: flex;
  flex-direction: column;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  box-shadow: 0 0 2px ${({ theme: { colors } }) => colors.lightGrey};
  overflow-x: hidden;
  overflow-y: auto;

  .ant-spin-nested-loading {
    height: 100%;
    width: 100%;
    .ant-spin-container {
      height: 100%;
      width: 100%;
    }
  }

  @media (max-width: 1500px) {
    height: 400px;
    padding: 0 0 10px 0;
    grid-column: span 2;
    overflow-y: hidden;

    .ant-spin-container {
      height: 400px;
      overflow-y: auto;
      padding: 0 0 10px 0;
      display: grid;
      grid-template-rows: 1fr 1fr 2fr 1fr;
    }
  }

  @media (max-width: 1100px) {
    grid-column: span 1;
  }
`;

export const DocumentsListWrapper = styled.div`
  ${ScrollBox}
`;

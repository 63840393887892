import { FixedType } from 'rc-table/lib/interface';

export const columns = [
  {
    title: 'pages.sustainability.team-status.table.status_origin',
    dataIndex: 'status_origin',
    key: 'status_origin',
    sorter: false,
    filtered: false,
  },
  {
    title: 'pages.sustainability.team-status.table.status_next',
    dataIndex: 'status_next',
    key: 'status_next',
    sorter: false,
    filtered: false,
  },
  {
    title: 'pages.sustainability.team-status.table.position',
    dataIndex: 'position',
    key: 'position',
    sorter: false,
    filtered: false,
  },
  {
    title: 'pages.sustainability.team-status.table.is_attachment_required',
    dataIndex: 'is_attachment_required',
    key: 'is_attachment_required',
    sorter: true,
    filtered: false,
  },
  {
    title: 'pages.sustainability.team-status.table.is_comments_required',
    dataIndex: 'is_comments_required',
    key: 'is_comments_required',
    sorter: true,
    filtered: false,
  },
  {
    title: 'pages.sustainability.team-status.table.actions',
    dataIndex: 'action',
    key: 'action',
    width: '100px',
    fixed: 'right' as FixedType,
  },
];

import styled from 'styled-components';

export const BorderedBox = styled.div<{ isChildren?: boolean }>`
  border: ${({ isChildren, theme: { colors } }) => (isChildren ? 'none' : `1px dashed ${colors.lightOrange}`)}};
  padding: 10px 5px;
  border-radius: 10px;
  margin-bottom: 10px;
	position: static;
	background: white;

  h4 {
    color: ${({ theme: { colors } }) => colors.greys.grey05};
    font-size: 18px;
    margin-bottom: 10px;
    font-weight: bold;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

import { FixedType } from 'rc-table/lib/interface';

export const columns = [
  {
    title: 'pages.serasacounterparty.table.name',
    dataIndex: 'name',
    key: 'name',
    sorter: true,
  },
  {
    title: 'pages.serasacounterparty.table.doc_number',
    dataIndex: 'doc_number',
    key: 'doc_number',
    sorter: true,
  },
  {
    title: 'pages.serasacounterparty.table.code_datasul',
    dataIndex: 'code_datasul',
    key: 'code_datasul',
    sorter: true,
  },
  {
    title: 'pages.serasacounterparty.table.created_date',
    dataIndex: 'created_at',
    key: 'created_at',
    sorter: true,
  },
  {
    title: 'pages.serasacounterparty.table.status',
    dataIndex: 'status',
    key: 'status',
    sorter: true,
  },
  {
    title: 'pages.serasacounterparty.table.updated_at',
    dataIndex: 'updated_at',
    key: 'updated_at',
    sorter: true,
  },
  {
    title: 'pages.serasacounterparty.table.active',
    dataIndex: 'active',
    key: 'active',
    sorter: true,
  },
  {
    title: 'pages.edocuments.contracttypes.table.actions',
    dataIndex: 'action',
    key: 'action',
    width: '100px',
    fixed: 'right' as FixedType,
  },
];

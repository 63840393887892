import React, { createContext, useContext, useState, useCallback } from 'react';

import { SidebarConfig, sidebarConfigDefault } from '../types/User/UserData';
import SidebarData from '../@types/hooks/sidebar';
import { useAuth } from './auth';

const SidebarContext = createContext<SidebarData>({} as SidebarData);

const SidebarProvider: React.FC = ({ children }) => {
  const { user, updateUserConfig } = useAuth();

  const [isChanging, setIsChanging] = useState(false);
  const [isMini, setIsMini] = useState<boolean>(() => {
    if (user && user.config) {
      const configs = user.config.app?.sidebar;
      if (!configs) {
        const sidebarConfig: SidebarConfig = sidebarConfigDefault;
        return sidebarConfig.isMini;
      }

      return configs.isMini;
    }
    return sidebarConfigDefault.isMini;
  });

  const toggle = useCallback(() => {
    setIsChanging(true);
    const updateConfigs: SidebarConfig = user.config.app.sidebar;
    setIsMini(!updateConfigs.isMini);
    user.config.app.sidebar.isMini = !updateConfigs.isMini;
    updateUserConfig(user.config);

    setIsChanging(false);
  }, [updateUserConfig, user]);

  return (
    <SidebarContext.Provider
      value={{
        isChanging,
        isMini,
        toggle,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};

function useSidebar() {
  return useContext(SidebarContext);
}

export { SidebarProvider, useSidebar };

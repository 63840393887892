import React from 'react';
import { useTranslation } from 'react-i18next';

import { ColumnDef } from '@tanstack/react-table';
import { IconWithTooltip } from 'components';
import { DataTable } from 'components/ui/data-table';
import { Can } from 'hooks/ability';
import { Formatter } from 'utils/Formatter';

export type BaseCommentData = {
  id: string;
  created_at?: string;
  user?: {
    name: string;
  };
  comment: string;
  isNew?: boolean;
  deleted_at?: string;
  updated_at?: string;
  user_id?: string;
};

export type CommentsTableProps = {
  entityBeingDeletedId?: string;
  comments: BaseCommentData[];
  handleDeleteComment: (comment: BaseCommentData) => void;
  deletePermission?: string;
};

export const CommentsTable = ({
  entityBeingDeletedId,
  comments,
  handleDeleteComment,
  deletePermission,
}: CommentsTableProps) => {
  const { t } = useTranslation();

  const columns: ColumnDef<BaseCommentData>[] = [
    {
      accessorKey: 'dt_comment',
      header: t('pages.credit-request.formalization-guarantees.pre-registration-docs.modalDocument.date'),
      cell: ({ row }) => {
        const comment = row.original as BaseCommentData;
        return Formatter.date(comment.created_at);
      },
    },
    {
      accessorKey: 'registered_by',
      header: t('pages.credit-request.formalization-guarantees.pre-registration-docs.modalDocument.registered_by'),
      cell: ({ row }) => {
        const comment = row.original as BaseCommentData;

        return comment.user?.name || '-';
      },
    },
    {
      accessorKey: 'comment',
      header: t('pages.credit-request.formalization-guarantees.pre-registration-docs.modalDocument.comment'),
    },
    {
      id: 'actions',
      enablePinning: true,
      accessorKey: 'action',
      size: 100,
      header: t('actions'),
      cell: ({ row }) => {
        const comment = row.original;

        return (
          <Can I={deletePermission}>
            <IconWithTooltip
              action="destroy"
              title={t('pages.registered-customers.table.icons.remove')}
              onClick={() => handleDeleteComment(comment)}
              loading={entityBeingDeletedId === comment.id}
            />
          </Can>
        );
      },
    },
  ];

  return <DataTable columns={columns as any} data={comments || []} />;
};

import { useState } from 'react';

import { RcFile } from 'antd/lib/upload';

import api, { handleRequest } from '../../services/api/api';
import contractApi from '../../services/api/contract';
import { useContract } from './useContract';

export const useDraft = () => {
  const { getAllTemplates } = useContract();

  const [isFetching, setIsFetching] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const getDraftModel = async (id: string) => {
    const response = await handleRequest(() => contractApi.templates.show(id));
    return response;
  };

  const getDraftModels = async ({ page = 1, perPage = 5, params = {} }) => {
    setIsFetching(true);
    return getAllTemplates({ params: { page, per_page: perPage, ...params } }).finally(() => setIsFetching(false));
  };

  const getFieldsFromDraftModel = async (file: RcFile) => {
    const formData = new FormData();
    formData.append('template', file);

    const response = await handleRequest(() => contractApi.templates.getFields(formData), setIsProcessing);
    return response || { keys: [] };
  };

  const issueDraft = async (contractId: string, contractTemplateId: string, fields: Array<{ [name: string]: any }>) => {
    const response = await handleRequest(() =>
      api.post(`/contract/${contractId}/issue`, {
        contract_template_id: contractTemplateId,
        fields,
        contract_id: contractId,
      }),
    );
    return response;
  };

  const uploadDraft = async (contractId: string, formData: FormData) => {
    formData.append('type', 'emission');
    return handleRequest(() => api.post(`/contract/${contractId}/uploadminuta`, formData), setIsUploading);
  };

  return {
    isFetching,
    getDraftModels,
    getFieldsFromDraftModel,
    isProcessing,
    issueDraft,
    uploadDraft,
    isUploading,
    getDraftModel,
  };
};

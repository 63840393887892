import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Col, Form as AntForm, Row, Spin } from 'antd';
import datasulApi from 'services/api/datasul';
import exceptionHandler from 'services/exceptions';
import { ThirdProviderEnum } from 'types/CreditRequest/enums/ThirdProvidersEnum';

import { Button, FormItem, Input, MaskedInput, Select } from '../../../../components';
import { LEGAL_PERSON_TYPE, PHYSICAL_PERSON_TYPE } from '../../../../configs/constants';
import DTODatasulProvider from '../../../../types/Datasul/DTODatasulProvider';
import { SFormContainer } from '../styles';

interface Props {
  form: any;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  handleSave: () => void;
  counterPartyBeingEdited?: any;
  loading: boolean;
  isOpen: boolean;
  setIsDocSerasa: (value: boolean) => void;
  typePerson: any;
  setTypePerson: (value: any) => void;
}

export const Form = ({
  form,
  setLoading,
  handleSave,
  counterPartyBeingEdited,
  loading,
  isOpen,
  setIsDocSerasa,
  typePerson,
  setTypePerson,
}: Props) => {
  const { t } = useTranslation();

  const [disabledCodeDataSul, setDisabledCodeDataSul] = useState(true);
  const [thirdProviders, setThirdProviders] = useState<ThirdProviderEnum[]>([]);

  const isEditing = !!counterPartyBeingEdited;

  const shouldShowDatasul = thirdProviders?.some(provider => provider.toLowerCase() === 'datasul');
  const shouldShowSap = thirdProviders?.some(provider => provider.toLowerCase() === 'sap');

  const handleChangePersonType = async (value: any) => {
    if (value) {
      form.resetFields();
      setTypePerson(value.toString());
      form.setFieldsValue({
        requester_person_type: value,
      });
    }
  };

  const handleFindProvider = useCallback(async () => {
    const formValues = form.getFieldsValue();

    if (!formValues.doc_number) {
      return;
    }
    const cpf = formValues.doc_number.replace(/\D/g, '');

    setLoading(true);
    await datasulApi.provider
      .search({ params: { search: cpf } }, true)
      .then(response => {
        const data: DTODatasulProvider = response.data as DTODatasulProvider;
        setDisabledCodeDataSul(true);

        const dataThirdProviders: ThirdProviderEnum[] = [];
        if (data.cod_datasul) dataThirdProviders.push('datasul');
        if (data.cod_sap) dataThirdProviders.push('sap');
        setThirdProviders(dataThirdProviders);

        form.setFieldsValue({
          code_datasul: data.cod_datasul || data.cod_datasul === 0 ? data.cod_datasul : undefined,
          cod_sap: data.cod_sap || data.cod_sap === 0 ? data.cod_sap : undefined,
          name: data.name ?? data.fantasy_name,
        });
        form.setFields([
          {
            name: 'doc_number',
            errors: [],
          },
        ]);
        setIsDocSerasa(true);
      })
      .catch(exceptionHandler)
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line
  }, []);

  return (
    <SFormContainer visible={isOpen}>
      <Spin spinning={loading}>
        <h2>
          {counterPartyBeingEdited
            ? t('pages.serasacounterparty.form.titleEdit')
            : t('pages.serasacounterparty.form.title')}
        </h2>

        <AntForm form={form}>
          <FormItem name="id" style={{ display: 'none' }}>
            <Input />
          </FormItem>
          <Row gutter={[26, 26]}>
            <Col xs={24} sm={18} md={12} lg={8} xl={8} xxl={8}>
              <FormItem
                label={t('pages.credit-request.form.requester_person_type')}
                name="requester_person_type"
                rules={[{ required: true }]}
                initialValue={PHYSICAL_PERSON_TYPE}
              >
                <Select
                  options={[
                    {
                      key: 'physical',
                      value: PHYSICAL_PERSON_TYPE,
                      label: t('pages.credit-request.form.requester_person_type_physical'),
                    },
                    {
                      key: 'legal',
                      value: LEGAL_PERSON_TYPE,
                      label: t('pages.credit-request.form.requester_person_type_legal'),
                    },
                  ]}
                  onChange={handleChangePersonType}
                />
              </FormItem>
            </Col>
            <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
              <FormItem
                label={
                  typePerson === PHYSICAL_PERSON_TYPE
                    ? t('pages.sustainability.form.cpf')
                    : t('pages.sustainability.form.cnpj')
                }
                name="doc_number"
              >
                {typePerson === PHYSICAL_PERSON_TYPE ? (
                  <MaskedInput
                    name="doc_number"
                    mask="111.111.111-11"
                    placeholder="___.___.___-__"
                    onBlur={() => {
                      handleFindProvider();
                    }}
                  />
                ) : (
                  <MaskedInput
                    name="doc_number"
                    mask="11.111.111/1111-11"
                    placeholder="__.___.___/____-__"
                    onBlur={() => {
                      handleFindProvider();
                    }}
                  />
                )}
              </FormItem>
            </Col>
            <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
              <FormItem
                style={{ paddingRight: '10px' }}
                label={t('pages.monitoring.farm.form.name')}
                name="name"
                rules={[{ required: true }]}
              >
                <Input type="text" maxLength={255} disabled />
              </FormItem>
            </Col>
            <Col xs={24} sm={24} md={24} lg={6} xl={6} xxl={6} style={{ display: shouldShowDatasul ? '' : 'none' }}>
              <FormItem
                label={t('pages.sustainability.form.code_datasul')}
                name="code_datasul"
                style={{ display: shouldShowDatasul ? '' : 'none' }}
              >
                <Input disabled={disabledCodeDataSul} maxLength={255} />
              </FormItem>
            </Col>

            <Col xs={24} sm={24} md={24} lg={6} xl={6} xxl={6} style={{ display: shouldShowSap ? '' : 'none' }}>
              <FormItem
                label={t('pages.sustainability.form.cod_sap')}
                name="cod_sap"
                style={{ display: shouldShowSap ? '' : 'none' }}
              >
                <Input disabled={disabledCodeDataSul} maxLength={255} />
              </FormItem>
            </Col>
            {isEditing && (
              <>
                {' '}
                <Col xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}>
                  <FormItem label={t('pages.sustainability.form.createdAt')} name="created_at">
                    <Input disabled maxLength={255} />
                  </FormItem>
                </Col>
                <Col xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}>
                  <FormItem label={t('pages.sustainability.form.updatedAt')} name="updated_at">
                    <Input disabled maxLength={255} />
                  </FormItem>
                </Col>
              </>
            )}
          </Row>
          <Row>
            <Col style={{ justifyContent: 'end', width: '100%', display: 'flex' }}>
              <Button loading={loading} onClick={handleSave}>
                {counterPartyBeingEdited ? 'Editar' : 'Salvar'}
              </Button>
            </Col>
          </Row>
        </AntForm>
      </Spin>
    </SFormContainer>
  );
};

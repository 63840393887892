import React from 'react';
import { useTranslation } from 'react-i18next';

import { OptionsType } from '@agrodatabr/agrodataui';
import { Spin, Form as AntForm, Row, Col, DatePicker, InputNumber, FormInstance } from 'antd';
import ptBR from 'antd/es/date-picker/locale/pt_BR';
import { Button, FormItem, Input, ModalStatus, Select } from 'components';
import { BasePortSelect } from 'components/BasePortSelect';
import { SubdivisionSelect } from 'components/SubdivisionSelect';
import { SubsidiarySelect } from 'components/SubsidiarySelect';
import { CURRENCY_USD_ID, SUBDIVISION_CORN_ID, SUBDIVISION_SOYBEAN_ID } from 'configs/constants';
import { useCache } from 'hooks/cache';
import { usePriceDay } from 'hooks/fetch/usePriceDay';
import { useModalMessage } from 'hooks/helpers/useModalMessage';
import { usePage } from 'hooks/page';
import moment from 'moment';
import { Formatter } from 'utils/Formatter';

import type DTOErrorReponse from '../../../../@types/dtos/DTOErrorReponse';
import appConfigs from '../../../../configs/app';
import { SFormButtons, SFormContainer } from '../styles';

interface Props {
  isVisible: boolean;
  handleHideForm: () => void;
  isEditing: boolean;
  refetchTable: () => void;
  form: FormInstance;
  canCreate: boolean;
  canEdit: (date: string) => boolean;
  basePortOptions: OptionsType;
}

export const Form = ({
  isVisible,
  handleHideForm,
  isEditing,
  refetchTable,
  form,
  canCreate,
  canEdit,
  basePortOptions,
}: Props) => {
  const { t } = useTranslation();
  const { store, isStoring, update, isUpdating } = usePriceDay();
  const { alertStatus } = usePage();
  const { handleSuccess } = useModalMessage();
  const { getCreditOptions } = useCache();

  const [isFormLocked, setIsFormLocked] = React.useState(false);

  const storeOrUpdate = () => {
    if (!canCreate && !isEditing) {
      ModalStatus({
        type: 'error',
        title: t('modal.error'),
        subTitle: t('pages.register.priceDay.error'),
      });
      return;
    }

    if (!canEdit(form.getFieldValue('date').toString()) && isEditing) {
      ModalStatus({
        type: 'error',
        title: t('modal.error'),
        subTitle: t('pages.register.priceDay.edit_error'),
      });
      return;
    }
    setIsFormLocked(true);
    form
      .validateFields()
      .then(() => {
        const formValues = form.getFieldsValue();
        const requestMethod = isEditing ? update(form.getFieldValue('id'), formValues) : store(formValues);
        requestMethod
          .then(response => {
            if (response) {
              handleSuccess(
                isEditing ? t('pages.register.priceDay.edit.success') : t('pages.register.priceDay.register.success'),
              );
              refetchTable();
              handleHideForm();
              setIsFormLocked(false);
              form.resetFields();
            }
          })
          .catch((err: DTOErrorReponse) => {
            alertStatus(err, 'error');
            setIsFormLocked(false);
          });
      })
      .catch((err: DTOErrorReponse) => {
        alertStatus(err, 'error');
        setIsFormLocked(false);
      });
  };
  return (
    <SFormContainer visible={isVisible}>
      <Spin spinning={isStoring || isUpdating}>
        <h2>{t('pages.register.priceDay.registerTitle')}</h2>
        <AntForm form={form}>
          <FormItem name="id" style={{ display: 'none' }}>
            <Input />
          </FormItem>
          <Row gutter={[26, 26]}>
            <Col xs={24} sm={12} md={16} lg={16} xl={16} xxl={16}>
              <FormItem
                style={{ paddingRight: '10px' }}
                label={t('pages.register.priceDay.form.date')}
                name="date"
                rules={[{ required: true }]}
                initialValue={moment()}
              >
                <DatePicker locale={ptBR} mode="date" format={appConfigs.formatDate} disabled />
              </FormItem>
            </Col>
            <Col xs={24} sm={12} md={8} lg={8} xl={8} xxl={8}>
              <FormItem
                style={{ paddingLeft: '10px' }}
                label={t('pages.register.priceDay.form.currency')}
                name="currency_type_id"
                rules={[{ required: true }]}
                initialValue={CURRENCY_USD_ID}
              >
                <Select
                  options={getCreditOptions('currency_type').filter(currency => currency.key === CURRENCY_USD_ID)}
                />
              </FormItem>
            </Col>

            <Col xs={24} sm={12} md={8} lg={8} xl={8} xxl={8}>
              <FormItem
                style={{ paddingLeft: '10px' }}
                label={t('pages.register.priceDay.form.price')}
                name="value"
                rules={[{ required: true }]}
              >
                <InputNumber<string>
                  min="0"
                  formatter={(value: string | number) => Formatter.decimal(value, 2, null, null)}
                  parser={(value: string) => Formatter.decimalParser(value)}
                />
              </FormItem>
            </Col>

            <Col xs={24} sm={12} md={8} lg={8} xl={8} xxl={8}>
              <FormItem
                style={{ paddingLeft: '10px' }}
                label={t('pages.register.priceDay.form.subsidiary')}
                name="subsidiary_id"
                rules={[{ required: true }]}
              >
                <SubsidiarySelect disabled={isEditing} />
              </FormItem>
            </Col>

            <Col xs={24} sm={12} md={8} lg={8} xl={8} xxl={8}>
              <FormItem
                style={{ paddingLeft: '10px' }}
                label={t('pages.register.priceDay.form.base_port_id')}
                name="base_port_id"
                rules={[{ required: true }]}
              >
                <BasePortSelect options={basePortOptions} disabled={isEditing} />
              </FormItem>
            </Col>

            <Col xs={24} sm={12} md={8} lg={8} xl={8} xxl={8}>
              <FormItem
                style={{ paddingLeft: '10px' }}
                label={t('pages.register.priceDay.form.subdivision_id')}
                name="sub_division_id"
                rules={[{ required: true }]}
                initialValue={SUBDIVISION_SOYBEAN_ID}
              >
                <SubdivisionSelect disabled={isEditing} whitelist={[SUBDIVISION_SOYBEAN_ID, SUBDIVISION_CORN_ID]} />
              </FormItem>
            </Col>
            <Col xs={24} sm={12} md={8} lg={8} xl={8} xxl={8}>
              <FormItem
                label={t('pages.register.priceDay.form.incoterm_id')}
                name="incoterm_id"
                rules={[{ required: true }]}
              >
                <Select options={getCreditOptions('incoterm')} disabled={isEditing} />
              </FormItem>
            </Col>
          </Row>
          <SFormButtons>
            <Button
              status="secondary"
              htmlType="reset"
              onClick={handleHideForm}
              disabled={isFormLocked || isStoring || isUpdating}
            >
              {t('pages.register.harvest.buttonCancel')}
            </Button>
            {isEditing && (
              <Button
                status="primary"
                htmlType="submit"
                onClick={storeOrUpdate}
                loading={isStoring || isUpdating}
                disabled={isFormLocked || isStoring || isUpdating}
              >
                {t('pages.register.harvest.buttonEdit')}
              </Button>
            )}
            {!isEditing && (
              <Button
                status="primary"
                htmlType="submit"
                onClick={storeOrUpdate}
                loading={isStoring || isUpdating}
                disabled={isFormLocked || isStoring || isUpdating || (!canCreate && !isEditing)}
              >
                {t('pages.register.harvest.buttonRegister')}
              </Button>
            )}
          </SFormButtons>
        </AntForm>
      </Spin>
    </SFormContainer>
  );
};

import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TablePaginationConfig, TableProps } from 'antd/lib/table';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';

import DTOContracts, { DTOContractsIndexNames } from '../../../types/Contract/DTOContracts';
import { Breadcrumb, Button } from '../../../components';
import { TableData } from '../../../compositions';
import ImportModal from '../../../compositions/ImportModal';
import { Can } from '../../../hooks/ability';
import { usePage } from '../../../hooks/page';
import edocumentsApi from '../../../services/api/edocuments';
import { Formatter } from '../../../utils/Formatter';
import columns from './columns';
import { Container } from './styles';
import { ContractsDataTable } from './types';

const Contracts: React.FC = () => {
  const { alertStatus } = usePage();
  const { t, i18n } = useTranslation();

  const [data, setData] = useState<DTOContracts[]>([]);
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [paginationConfig, setPaginationConfig] = useState<TablePaginationConfig>({
    current: 1,
    total: 1,
    pageSize: 20,
  });

  // Table DATA Config: BEGIN
  const tableConfig = {
    search: '',
    filtered: {},
    sorter: {},
  };

  const [tableDataConfig, setTableDataConfig] = useState(tableConfig);

  const loadTableData = useCallback(
    (values: ContractsDataTable) => {
      setLoading(true);
      edocumentsApi.contracts
        .get({
          params: {
            ...values,
          },
        })
        .then(response => {
          const result = response.data.data;
          setData(result.data);
          setPaginationConfig(paginationConfigState => ({
            ...paginationConfigState,
            current: result.current_page,
            total: result.total,
          }));
        })
        .catch(err => alertStatus(err, 'error'))
        .finally(() => setLoading(false));
    },
    // eslint-disable-next-line
    [i18n.language],
  );

  const handleTableChange: TableProps<any>['onChange'] = useCallback(
    (
      pagination: TablePaginationConfig,
      filters: Record<string, FilterValue | null>,
      sorter: SorterResult<any> | SorterResult<any>[],
    ) => {
      setLoading(true);
      setTableDataConfig(tableDataConfigState => ({
        ...tableDataConfigState,
        filtered: filters,
        sorter,
      }));

      let direction = '';
      const search: any = {};
      let sort = '';

      if (sorter) {
        const { field, order } = sorter as SorterResult<any>;

        if (order === 'ascend') direction = 'asc';
        if (order === 'descend') direction = 'desc';
        sort = field?.toString();
      }

      if (filters) {
        const dataFilter: any = filters;

        if (dataFilter) {
          Object.keys(dataFilter).forEach((key: string) => {
            if (dataFilter[key] !== null && dataFilter[key] !== undefined && dataFilter[key][0]) {
              search[key] = dataFilter[key][0].toString();
            } else if (dataFilter[key] !== null && dataFilter[key] !== undefined) {
              search[key] = dataFilter[key].toString();
            } else {
              search[key] = '';
            }
          });
        }
      }

      setPaginationConfig(paginationConfigState => ({
        ...paginationConfigState,
        pageSize: pagination.pageSize,
        current: pagination.current,
      }));

      loadTableData({
        page: pagination.current,
        per_page: pagination.pageSize,
        sort,
        direction,
        ...search,
      });
    },
    // eslint-disable-next-line
    [loadTableData],
  );

  useEffect(() => {
    const filters: any = {};
    handleTableChange({ current: 1, pageSize: paginationConfig.pageSize }, filters, {}, null);
  }, [handleTableChange, paginationConfig.pageSize]);
  // Table DATA Config: END

  return (
    <Container>
      <Breadcrumb items={[{ title: t('breadcrumb.edocuments') }, { title: t('breadcrumb.contracts') }]} />

      <div className="content-container">
        <div className="grid-actions">
          <h2>{t('pages.edocuments.contracts.title')}</h2>
          <div className="grid-button-to-subpage">
            <Can I={['edocument.contract.import.index']} a="">
              <Can I="edocument.contract.import.store" a="">
                <Button status="primary" onClick={() => setModalVisible(true)}>
                  {t('pages.edocuments.contracts.button-importations')}
                </Button>
              </Can>
            </Can>
          </div>
        </div>

        <TableData
          rowKey="id"
          dataSource={data}
          columns={columns.contracts.map(column => {
            switch (column.key) {
              case 'fixed_price':
              case 'quantity_delivery':
                return {
                  ...column,
                  render: (_, record: DTOContracts) => {
                    const key: DTOContractsIndexNames = column.key as DTOContractsIndexNames;
                    return Formatter.money(Number(record[key]), 2, record?.currency_orig.toUpperCase() ?? '', true);
                  },
                };
              case 'payment_forecast':
                return {
                  ...column,
                  render: (_, record: DTOContracts) => {
                    const key: DTOContractsIndexNames = column.key as DTOContractsIndexNames;
                    return record[key] ? Formatter.date(record[key].toString()) : '';
                  },
                };
              case 'subsidiary_id':
                return {
                  ...column,
                  render: (_, record: DTOContracts) => {
                    return record?.subsidiary ? record?.subsidiary.name : '';
                  },
                };
              case 'created_at':
              case 'updated_at':
                return {
                  ...column,
                  render: (_, record: DTOContracts) => {
                    const key: DTOContractsIndexNames = column.key as DTOContractsIndexNames;
                    return record[key] ? Formatter.datetime(record[key].toString()) : '';
                  },
                };
              default:
                return column;
            }
          })}
          loading={loading}
          onChange={handleTableChange}
          pagination={paginationConfig}
          tableConfig={tableDataConfig}
        />

        <ImportModal
          visibility={modalVisible}
          afterClose={() => setModalVisible(false)}
          listImportApi={edocumentsApi.contracts.importations.get}
          generateImportApi={edocumentsApi.contracts.importations.store}
        />
      </div>
    </Container>
  );
};

export default Contracts;

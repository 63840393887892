import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AlertStatus, TablePaginationConfig } from '@agrodatabr/agrodataui';

import { ModalStatus } from '../../../../components';
import { useContract } from '../../../../hooks/fetch/useContract';
import { TemplatesProps } from './types';

export const useTemplatesController = ({ refresh }: TemplatesProps) => {
  const { t } = useTranslation();
  const { isFetching, destroyTemplate, getAllTemplates } = useContract();

  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [templateBeingEditedId, setTemplateBeingEditedId] = useState('');
  const [searchFilters, setSearchFilters] = useState<object>();

  const closeForm = () => {
    setIsFormOpen(false);
    setTemplateBeingEditedId('');
    setIsEditing(false);
  };

  const openForm = () => setIsFormOpen(true);

  const toggleForm = () => {
    if (!isFormOpen) {
      openForm();
    } else closeForm();
  };

  const handleTableChange = async (pagination: TablePaginationConfig, filters: object) => {
    const { data, total } = await getAllTemplates({
      params: { page: pagination.current, per_page: pagination.pageSize, ...filters },
    });

    return { data, total };
  };

  const handleEdit = (id: string) => {
    openForm();
    setIsEditing(true);
    setTemplateBeingEditedId(id);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const remove = async (id: string) => {
    const response = await destroyTemplate(id);
    if (response) {
      refresh();
      AlertStatus({ status: 'success', messages: 'Tipo de Contrato removido com sucesso' });
    }
  };

  const handleRemove = async (id: string) => {
    ModalStatus({
      type: 'delete',
      description: t('pages.edocuments.templates.table.okDelete'),
      onOk: () => remove(id),
    });
  };

  return {
    closeForm,
    openForm,
    isFormOpen,
    toggleForm,
    isEditing,
    setIsEditing,
    isFetching,
    handleTableChange,
    handleEdit,
    handleRemove,
    templateBeingEditedId,
    setSearchFilters,
    searchFilters,
  };
};

import React from 'react';

import { TreeProps } from 'antd/lib/tree/Tree';
import RcTree from 'rc-tree';

import { STree } from './styles';

const TreeFile: React.FC<TreeProps & React.RefAttributes<RcTree>> = () => {
  return <STree showLine />;
};

export default TreeFile;

import React from 'react';

import { Table } from './Table';
import { DocumetsProps } from './types';

export const Documents = ({ documents, downloadDocument }: DocumetsProps) => {
  return (
    <div>
      <Table documents={documents} downloadDocument={downloadDocument} />
    </div>
  );
};

import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ColumnDef } from '@tanstack/react-table';
import { IconWithTooltip } from 'components';
import { ShouldRender } from 'components/ShouldRender';
import { useAlertDialog } from 'components/ui/alert-dialog';
import { DataTable } from 'components/ui/data-table';
import { Can } from 'hooks/ability';
import { useAuth } from 'hooks/auth';
import { useCache } from 'hooks/cache';
import { Formatter } from 'utils/Formatter';

import { useRegisteredCustomerStore } from '../stores/use-registered-customer-store';
import { CofcoOpinionFormData } from './cofco-opinion.form';

export const CofcoOpinionTable = () => {
  const { t } = useTranslation();
  const form = useFormContext();
  const { alert } = useAlertDialog();
  const cache = useCache();
  const { user } = useAuth();

  const cofcoOpinions = form.watch('cofco_opinion') as CofcoOpinionFormData[];

  const setCofcoOpinionToEdit = useRegisteredCustomerStore(state => state.setCofcoOpinionToEdit);
  const setIsCofcoOpinionModalOpen = useRegisteredCustomerStore(state => state.setIsCofcoOpinionModalOpen);
  const isViewing = useRegisteredCustomerStore(state => state.isViewing);

  const onDelete = (id: string) => {
    const cofcoOpinions = form.getValues('cofco_opinion') as CofcoOpinionFormData[];
    form.setValue(
      'cofco_opinion',
      cofcoOpinions.filter(cofcoOpinion => cofcoOpinion.id !== id),
    );
  };

  const handleDelete = (cofcoOpinion: CofcoOpinionFormData) => {
    alert({
      type: 'warning',
      title: t('delete-cofco-opinion'),
      description: t('delete-cofco-opinion-confirm'),
      onOk: async () => {
        if (cofcoOpinion.id) await onDelete(cofcoOpinion.id.toString());
        alert({
          type: 'success',
          title: t('cofco-opinion-deleted'),
          description: '',
        });
      },
    });
  };

  const columns: ColumnDef<CofcoOpinionFormData>[] = [
    {
      accessorKey: 'user_name',
      header: t('commercial-name'),
    },
    {
      accessorKey: 'region_id',
      header: t('regional'),
      cell: ({ row }) => {
        const cofcoOpinion = row.original as CofcoOpinionFormData;
        return cache.getCreditOptions('region').find(region => region.value === cofcoOpinion.region_id)?.label;
      },
    },
    {
      accessorKey: 'subsidiary_id',
      header: t('subsidiary'),
      cell: ({ row }) => {
        const cofcoOpinion = row.original as CofcoOpinionFormData;
        return cache.getCreditOptions('subsidiary').find(state => state.value === cofcoOpinion.subsidiary_id)?.label;
      },
    },
    {
      accessorKey: 'created_at',
      header: t('date'),
      cell: ({ row }) => {
        const cofcoOpinion = row.original as CofcoOpinionFormData;
        return Formatter.date(cofcoOpinion.created_at);
      },
    },
    {
      accessorKey: 'opinion',
      header: t('opinion'),
    },
    {
      id: 'actions',
      enablePinning: true,
      accessorKey: 'action',
      size: 100,
      header: t('actions'),
      cell: ({ row }) => {
        const partner = row.original as CofcoOpinionFormData;

        return (
          <ShouldRender condition={partner.user_id === user.id && !isViewing}>
            <IconWithTooltip
              permission="credit.request.package.destroy"
              action="destroy"
              title={t('pages.registered-customers.table.icons.remove')}
              // loading={partner.id && partnerToDelete === partner.id}
              onClick={() => handleDelete(partner)}
            />
            <Can I="credit.request.package.update">
              <IconWithTooltip
                action="edit"
                onClick={() => {
                  setCofcoOpinionToEdit(partner);
                  setIsCofcoOpinionModalOpen(true);
                }}
                title={t('pages.registered-customers.table.icons.edit')}
              />
            </Can>
          </ShouldRender>
        );
      },
    },
  ];

  return <DataTable columns={columns} data={cofcoOpinions ?? []} />;
};

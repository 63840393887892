import { useGetCacheState } from 'pages/CreditRequest/hooks/useGetCacheState';

export const useIsFethabAttachmentRequired = () => {
  const stateMT = useGetCacheState('MT');

  const checkIsFethabAttachmentRequired = (stateId: string, isFethab: boolean) => {
    const isMT = stateMT && stateMT?.value?.toString() === stateId?.toString();
    return isMT && Boolean(isFethab) === false;
  };

  return { checkIsFethabAttachmentRequired };
};

import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ShouldRender } from 'components/ShouldRender';
import { useAlertDialog } from 'components/ui/alert-dialog';
import { Button } from 'components/ui/button';
import { GroupForm } from 'components/ui/group-form';
import { randomUUID } from 'lib/utils';

import { PartnerFormData } from './partner/partner.form';
import { PartnerModal } from './partner/partner.modal';
import { PartnerTable } from './partner/patner.table';
import { useRegisteredCustomerStore } from './stores/use-registered-customer-store';

export const RegisteredCustomerPartenersSection = () => {
  const { t } = useTranslation();
  const form = useFormContext();
  const { alert } = useAlertDialog();

  const isViewing = useRegisteredCustomerStore(state => state.isViewing);
  const isModulePerson = useRegisteredCustomerStore(state => state.isModulePerson);
  const setIsPartnerModalOpen = useRegisteredCustomerStore(state => state.setIsPartnerModalOpen);
  const partnerToEdit = useRegisteredCustomerStore(state => state.partnerToEdit);

  const handleAddPartner = (data: PartnerFormData) => {
    const partners = form.getValues('partners') as PartnerFormData[];

    form.setValue(
      'partners',
      [
        ...(partners ?? []),
        {
          ...data,
          id: `new-${randomUUID()}`,
        },
      ],
      {
        shouldTouch: true,
      },
    );
  };

  const handleEditPartner = (data: PartnerFormData) => {
    const partners = form.getValues('partners') as PartnerFormData[];
    const index = partners.findIndex(partner => partner.id === data.id);
    partners[index] = data;
    form.setValue('partners', partners, {
      shouldTouch: true,
    });
    alert({
      type: 'success',
      title: t('partner-updated'),
      description: '',
    });
  };

  return (
    <GroupForm title={t('partners')}>
      <ShouldRender condition={!isViewing && isModulePerson}>
        <div className="flex justify-end w-full">
          <Button type="button" variant="default" onClick={() => setIsPartnerModalOpen(true)}>
            {t('add-partner')}
          </Button>
        </div>
      </ShouldRender>

      <div className="mt-6">
        <PartnerTable />
      </div>

      <PartnerModal onSuccess={partnerToEdit ? handleEditPartner : handleAddPartner} />
    </GroupForm>
  );
};

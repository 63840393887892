import React, { forwardRef, useEffect, useImperativeHandle } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ComboBox } from 'components/ui/combo';
import { Form } from 'components/ui/form';
import { FormGrid } from 'components/ui/form-grid';
import { FormItem } from 'components/ui/form-item';
import { Input, InputMoney, InputNumber } from 'components/ui/input';
import { useCache } from 'hooks/cache';

import { useRegisteredCustomerStore } from '../stores/use-registered-customer-store';

type Props = {
  onSuccess?: (data: DeliveryHistoryFormData) => void;
};

export type DeliveryHistoryFormData = {
  id?: string;
  harvest_id?: string;
  product_id?: string;
  amount?: number;
  currency_type_id?: string;
  credit_amount_granted?: number;
  commentary?: string;
};

export const deliveryHistoryInitialValues = {
  id: '',
  harvest_id: '',
  product_id: '',
  amount: 0,
  currency_type_id: '',
  credit_amount_granted: 0,
  commentary: '',
};

export const DeliveryHistoryForm = forwardRef(({ onSuccess }: Props, ref) => {
  const { t } = useTranslation();
  const cache = useCache();
  const form = useForm<DeliveryHistoryFormData>({
    defaultValues: deliveryHistoryInitialValues,
    mode: 'onBlur',
  });

  const currencyTypeId = form.watch('currency_type_id');

  const harvestOptions = cache.getCreditOptions('harvest');
  const cultureOptions = cache.getPersonOptions('culture');
  const currencyTypeOptions = cache.getCreditOptions('currency_type');

  const deliveryHistoryToEdit = useRegisteredCustomerStore(state => state.deliveryHistoryToEdit);

  const sendForm = (values: DeliveryHistoryFormData) => {
    onSuccess?.(values);
  };

  const submit = () => {
    form.handleSubmit(sendForm, console.log)();
  };

  useImperativeHandle(ref, () => ({
    form: form as UseFormReturn<DeliveryHistoryFormData>,
    submit,
  }));

  useEffect(() => {
    form.reset(deliveryHistoryToEdit || deliveryHistoryInitialValues);
  }, [deliveryHistoryToEdit]);

  return (
    <Form form={form} onSubmit={form.handleSubmit(sendForm)}>
      <FormGrid maxCols={2}>
        <FormItem
          label={t('pages.registered-customers.form.pf.delivery_histories.harvest')}
          name="harvest_id"
          rules={{ required: true }}
        >
          <ComboBox options={harvestOptions} />
        </FormItem>

        <FormItem
          label={t('pages.registered-customers.form.pf.delivery_histories.product')}
          name="product_id"
          rules={{ required: true }}
        >
          <ComboBox options={cultureOptions} />
        </FormItem>

        <FormItem
          label={t('pages.registered-customers.form.pf.delivery_histories.amount')}
          name="amount"
          rules={{ required: true }}
        >
          <InputNumber decimals={0} />
        </FormItem>

        <FormItem
          label={t('pages.registered-customers.form.pf.delivery_histories.currency_type')}
          name="currency_type_id"
          rules={{ required: true }}
        >
          <ComboBox options={currencyTypeOptions} />
        </FormItem>

        <FormItem
          label={t('pages.registered-customers.form.pf.delivery_histories.credit_amount_granted')}
          name="credit_amount_granted"
          rules={{ required: true }}
        >
          <InputMoney currency={currencyTypeOptions.find(option => option.value === currencyTypeId)?.label} />
        </FormItem>

        <FormItem
          label={t('pages.registered-customers.form.pf.delivery_histories.commentary')}
          name="commentary"
          rules={{ required: true }}
        >
          <Input />
        </FormItem>
      </FormGrid>
    </Form>
  );
});

DeliveryHistoryForm.displayName = 'DeliveryHistoryForm';

import React, { useState } from 'react';

import { AlertStatus } from '@agrodatabr/agrodataui';
import { Modal, Spin } from 'antd';

import { UploadAttachment } from '../../../../components/UploadAttachment';
import { Container } from './styles';

interface Props {
  close: () => void;
  isOpen: boolean;
  isUploading: boolean;
  onOk: (contract: any) => void;
}

export const UploadSignedContractModal = ({ close, isOpen, onOk, isUploading }: Props) => {
  const [file, setFile] = useState();

  const handleOk = () => {
    if (file) onOk(file);
    else
      AlertStatus({
        messages: { message: 'Ao menos um arquivo deve ser selecionado' },
        status: 'error',
        title: 'Erro',
      });
  };

  return (
    <Modal visible={isOpen} onCancel={close} onOk={handleOk} title="Upload Contrato Assinado" destroyOnClose>
      <Spin spinning={isUploading}>
        <Container>
          <UploadAttachment maxFiles={1} onChange={setFile} />
        </Container>
      </Spin>
    </Modal>
  );
};

import * as React from 'react';
import { useTranslation } from 'react-i18next';

import * as DialogPrimitive from '@radix-ui/react-dialog';
import { cn } from 'lib/utils';
import { X } from 'lucide-react';

import { Button } from './button';

const Dialog = DialogPrimitive.Root;

const DialogTrigger = DialogPrimitive.Trigger;

const DialogPortal = DialogPrimitive.Portal;

const DialogClose = DialogPrimitive.Close;

const DialogOverlay = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Overlay
    ref={ref}
    className={cn(
      'fixed inset-0 z-50 bg-black/80  data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0',
      className,
    )}
    {...props}
  />
));
DialogOverlay.displayName = DialogPrimitive.Overlay.displayName;

const DialogContent = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content>
>(({ className, children, title, ...props }, ref) => {
  const { t } = useTranslation();

  const header = React.Children.toArray(children).find((child: any) => child.type === DialogHeader);
  const footer = React.Children.toArray(children).find(
    (child: any) => child.type === DialogFooter || child.type === DialogFooterForm,
  );
  const filteredChildren = React.Children.toArray(children).filter(
    (child: any) => ![DialogHeader, DialogFooter, DialogFooterForm].includes(child.type),
  );
  return (
    <DialogPortal>
      <DialogOverlay />
      <DialogPrimitive.Content
        ref={ref}
        className={cn(
          'fixed left-[50%] top-[50%] z-50 w-1/2 max-h-[90vh] flex flex-col pb-2 translate-x-[-50%] translate-y-[-50%] border bg-background p-0 shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] sm:rounded-lg',
          className,
        )}
        {...props}
      >
        <div className="font-bold p-4 shadow-sm z-10 relative">
          {header || title || null}
          <DialogPrimitive.Close
            className="absolute top-1/2 right-4 transform -translate-y-1/2 hover:opacity-50 focus:opacity-50"
            title={t('close')}
          >
            <X className="h-4 w-4" />
            <span className="sr-only">Close</span>
          </DialogPrimitive.Close>
        </div>
        <div className="h-full w-full overflow-y-auto overflow-x-hidden p-6">{filteredChildren}</div>
        {footer && <div className="p-4 border-t shadow-sm">{footer}</div>}
      </DialogPrimitive.Content>
    </DialogPortal>
  );
});
DialogContent.displayName = DialogPrimitive.Content.displayName;

const DialogHeader = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
  <div className={cn('flex flex-col space-y-1.5 text-center sm:text-left text-primary', className)} {...props} />
);
DialogHeader.displayName = 'DialogHeader';

const DialogFooter = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
  <div className={cn('flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2', className)} {...props} />
);
DialogFooter.displayName = 'DialogFooter';

const DialogFooterForm = ({
  className,
  children,
  onCancel,
  onConfirm,
  isLoading,
  isReadOnly,
  ...props
}: React.HTMLAttributes<HTMLDivElement> & {
  onConfirm: () => void;
  onCancel: () => void;
  isLoading?: boolean;
  isReadOnly?: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={cn('flex flex-col-reverse sm:flex-row sm:justify-between sm:items-center sm:space-x-2', className)}
      {...props}
    >
      <Button variant="outline" onClick={onCancel} isLoading={isLoading}>
        {t('cancel')}
      </Button>
      <div className="flex gap-2 items-center">
        {children}
        <Button variant="default" onClick={onConfirm} isLoading={isLoading} disabled={isReadOnly}>
          {t('save')}
        </Button>
      </div>
    </div>
  );
};
DialogFooterForm.displayName = 'DialogFooterForm';

const DialogTitle = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Title>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Title
    ref={ref}
    className={cn('text-lg font-semibold leading-none tracking-tight', className)}
    {...props}
  />
));
DialogTitle.displayName = DialogPrimitive.Title.displayName;

const DialogDescription = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Description>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Description ref={ref} className={cn('text-sm text-muted-foreground', className)} {...props} />
));
DialogDescription.displayName = DialogPrimitive.Description.displayName;

export {
  Dialog,
  DialogPortal,
  DialogOverlay,
  DialogClose,
  DialogTrigger,
  DialogContent,
  DialogHeader,
  DialogFooter,
  DialogTitle,
  DialogDescription,
  DialogFooterForm,
};

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  padding: 5px;
  border-radius: 10px;
  background: ${({ theme: { colors } }) => colors.greys.grey04};
  cursor: pointer;

  &:hover {
    box-shadow: 0 0 5px ${({ theme: { colors } }) => `${colors.primary}90`};
  }
`;

export enum IntegrationDataProviderStatus {
  FAILED = 'failed',
  PROCESSING = 'processing',
  PROCESSED = 'processed',
}

export interface IntegrationDataProvider {
  id: string;
  user_id: string;
  status: IntegrationDataProviderStatus;
  group: {
    route: string;
    name: string;
    origin: string;
  };
  model: {
    model_id: string;
  };
  type: any;
  label: any;
  errors: DataIntegrationErros[];
  imported: any;
  total: number;
  created_at: string;
  updated_at: string;
  deleted_at: any;
  source_type: string;
  description: any;
  source_name: string;
  attachments: DataIntegrationAttachment[];
}

export interface DataIntegrationErros {
  created_at: string;
  description: string;
  id: string;
  import_id: string;
  updated_at: string;
}

export interface DataIntegrationAttachment {
  id: string;
  name: string;
  created_at: string;
  extension: string;
}

import React, { useContext, useEffect } from 'react';

import { StageTabList } from '../../../components/StageTabList';
import { ContractContext } from '../../../context/ContractContext';
import { useDraftModelContext } from '../../../context/DraftModelContext';
import { Attachments } from './Attachments';
import { Comments } from './Comments';
import { ContractDetails } from './ContractDetails';
import { DraftEmission } from './DraftEmission';
import { ReprovalHistoric } from './ReprovalHistoric';
import { Signatures } from './Signatures';
import { stages } from './stages';
import { Container } from './styles';

export const ContractStages = () => {
  const { isStageSelected, setSelectedStage, selectedStage } = useContext(ContractContext);
  const { resetTablesState, isIssued } = useDraftModelContext();

  useEffect(() => {
    if (!isIssued) resetTablesState();
  }, [selectedStage]);

  return (
    <Container>
      <StageTabList isStageSelected={isStageSelected} setSelectedStage={setSelectedStage} />

      <span>
        {isStageSelected(stages.get('Details').index) && <ContractDetails changeStage={setSelectedStage} />}
        {isStageSelected(stages.get('Draft').index) && <DraftEmission />}
        {isStageSelected(stages.get('Signature').index) && <Signatures />}
        {isStageSelected(stages.get('Attach').index) && <Attachments />}
        {isStageSelected(stages.get('Comments').index) && <Comments />}
        {isStageSelected(stages.get('ReprovalHistoric').index) && <ReprovalHistoric />}
      </span>
    </Container>
  );
};

import { FixedType, AlignType } from 'rc-table/lib/interface';

export const columns = [
  {
    title: 'pages.dashboard.resume.table.client',
    dataIndex: 'requester',
    key: 'requester',
    sorter: false,
    filtered: false,
    width: '300px',
  },
  {
    title: 'pages.dashboard.resume.table.requestNumber',
    dataIndex: 'number',
    key: 'number',
    sorter: false,
    filtered: false,
    width: '140px',
  },
  {
    title: 'pages.dashboard.resume.table.status',
    dataIndex: 'status',
    key: 'status',
    sorter: false,
    filtered: false,
    width: '200px',
  },
  {
    title: 'pages.dashboard.timeline.table.current_status',
    dataIndex: 'current_status',
    key: 'current_status',
    sorter: false,
    filtered: false,
    width: '300px',
  },
  {
    title: 'pages.dashboard.resume.table.region',
    dataIndex: 'region',
    key: 'region',
    sorter: false,
    filtered: false,
    width: '200px',
  },
  {
    title: 'pages.dashboard.resume.table.operation',
    dataIndex: 'operation',
    key: 'operation',
    sorter: false,
    filtered: false,
    width: '150px',
  },
  {
    title: 'pages.dashboard.resume.table.currency',
    dataIndex: 'currency',
    key: 'currency',
    sorter: false,
    filtered: false,
    width: '100px',
  },
  {
    title: 'pages.dashboard.resume.table.requested_amount',
    dataIndex: 'requested_amount',
    key: 'requested_amount',
    sorter: false,
    filtered: false,
    width: '150px',
  },
  {
    title: 'pages.dashboard.resume.table.approved_value',
    dataIndex: 'approved_value',
    key: 'approved_value',
    sorter: false,
    filtered: false,
    width: '150px',
  },
  {
    title: 'pages.dashboard.resume.table.approval',
    dataIndex: 'approval',
    key: 'approval',
    sorter: false,
    filtered: false,
    width: '150px',
  },
  {
    title: 'pages.dashboard.resume.table.disapproved',
    dataIndex: 'disapproved',
    key: 'disapproved',
    sorter: false,
    filtered: false,
    width: '150px',
  },
  {
    title: 'pages.dashboard.resumeVisits.table.actions',
    key: 'action',
    dataIndex: 'action',
    width: '90px',
    fixed: 'right' as FixedType,
    align: 'center' as AlignType,
  },
];

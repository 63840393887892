import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormItem, Input } from 'components';
import { useCreditRequestStore } from 'pages/CreditRequest/store/useCreditRequestStore';

export const JsonDataPaymentTermInput = () => {
  const { t } = useTranslation();

  const setIsBlocking = useCreditRequestStore(state => state.setIsBlocking);
  const pageStatus = useCreditRequestStore(state => state.pageStatus);

  return (
    <FormItem
      label={t('pages.credit-request.form.json_data_payment_term')}
      name="json_data.payment_term"
      rules={[{ required: true }]}
    >
      <Input
        disabled={pageStatus.viewing}
        onChange={value => {
          if (value) {
            setIsBlocking(true);
          }
        }}
      />
    </FormItem>
  );
};

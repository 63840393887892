import { useState } from 'react';

import datasulApi from 'services/api/datasul';
import { ContractImportData } from 'types/Contract/ContractImportsData';
import { ContractJustificationData } from 'types/Contract/ContractJustificationData';

import { CreditRequestContractData } from '../../@types/data/CreditRequestContractData';
import PaginationData from '../../@types/data/PaginationData';
import api, { handleRequest } from '../../services/api/api';
import contractApi from '../../services/api/contract';
import type { ContractData } from '../../types/Contract/ContractData';

export const useContract = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [isFetchingPreview, setIsFetchingPreview] = useState(false);
  const [isStoring, setIsStoring] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isActivating, setIsActivating] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isReissuing, setIsReissuing] = useState(false);
  const [isAddingAttachments, setIsAddingAttachments] = useState(false);
  const [isFetchingJustifications, setIsFetchingJustifications] = useState(false);
  const [isSendingComment, setIsSendingComment] = useState(false);
  const [isRemovingComment, setIsRemovingComment] = useState(false);
  const [isFetchingRelatedDocumentsTypes, setIsFetchingRelatedDocumentsTypes] = useState(false);
  const [isFetchingRefusalStatuses, setIsFetchingRefusalStatuses] = useState(false);
  const [isFetchingFixations, setIsFetchingFixations] = useState(false);
  const [isFetchingPurchaseTypes, setIsFetchingPurchaseTypes] = useState(false);
  const [isForcingContractDatasul, setIsForcingContractDatasul] = useState(false);
  const [isFetchingDependencies, setIsFetchingDependencies] = useState(false);
  const [isFetchingImports, setIsFetchingImports] = useState(false);
  const [isFetchingContractByNumber, setIsFetchingContractByNumber] = useState(false);
  const [isSendingManualDraftEmissionSigned, setIsSendingManualDraftEmissionSigned] = useState(false);

  const sendRequest = async (request: any) => {
    return handleRequest(request, setIsFetching);
  };

  const getAll = async (params = {}) => {
    const { data, total, last_page } = await handleRequest(() => contractApi.getAll(params), setIsFetching);
    return { data, total, last_page };
  };

  const getAllOptions = async (params = {}) => {
    const { data, total, last_page } = await getAll({ params });
    return {
      data: data.map((contract: ContractData) => ({
        key: contract.id,
        value: contract.id,
        label: contract.number,
        subscribers: contract.subscribers,
      })),
      total,
      last_page,
    };
  };

  const get = async (id: string, params = {}) => handleRequest(() => contractApi.get(id, params), setIsFetching);

  const toggleActive = async (id: string, params = {}) =>
    handleRequest(() => contractApi.toggleActive(id, params), setIsActivating);

  const storeTemplate = async (payload: unknown, params = {}) =>
    handleRequest(() => contractApi.templates.store(payload, params), setIsFetching);

  const updateTemplate = async (id: string, payload = {}, params = {}) =>
    handleRequest(() => contractApi.templates.update(id, payload, params), setIsStoring);

  const destroyTemplate = async (id: string) => sendRequest(() => contractApi.templates.destroy(id));

  const getAllTemplates = async (params = {}) => {
    const { data, total } = await sendRequest(() => contractApi.templates.getAll(params));
    return { data, total };
  };

  const showTemplate = async (id: string, params = {}) => sendRequest(() => contractApi.templates.show(id, params));

  const getDatasulFields = async (params = {}) => sendRequest(() => contractApi.templates.datasulFields(params));

  const getDatasulFieldsOptions = async (params = {}) => {
    const datasulFields = await getDatasulFields(params);
    if (!datasulFields) return [];

    return datasulFields.map((datasulField: string) => ({
      key: datasulField,
      value: datasulField,
      label: datasulField,
    }));
  };

  const preview = async (
    contractId: string,
    payload?: any,
    params = {},
  ): Promise<{ url?: string; extension?: string }> => {
    const response = await handleRequest(
      () => api.post(`contract/${contractId}/preview`, payload, params),
      setIsFetchingPreview,
    );
    return response;
  };

  const uploadSignedContract = async (contractId: string, formData: FormData) => {
    formData.append('type', 'signed');
    return handleRequest(() => api.post(`/contract/${contractId}/uploadminuta`, formData), setIsUploading);
  };

  const validate = async (
    contractId: string,
    stage: 'before' | 'after',
    action: 'approval' | 'refuse',
    obs: string = null,
  ) => {
    return handleRequest(
      () => api.post(`/contract/${contractId}/validatecontract`, { stage, action, obs }),
      setIsFetching,
    );
  };

  const addAttachments = async (contractId: string, attachments: any) =>
    handleRequest(() => api.post(`/contract/${contractId}/uploadextra`, attachments), setIsAddingAttachments);

  const sendComment = async (contractId: string, comment: string, justificationId: string) =>
    handleRequest(
      () => api.post(`/contract/${contractId}/comment/store`, { comment, type_id: justificationId }),
      setIsSendingComment,
    );

  const removeComment = async (commentId: string) =>
    handleRequest(() => api.delete(`/contract/comment/${commentId}/destroy`), setIsRemovingComment);

  const open = async (contractId: string) =>
    handleRequest(() => api.get(`/contract/${contractId}/open`), setIsRemovingComment);

  const getRelatedDocumentsAvaliableTypesForContract = async (contractId: string, params = {}) => {
    const response = await handleRequest(
      () => api.get(`/contract/type/${contractId}/subtypes`, { params }),
      setIsFetchingRelatedDocumentsTypes,
    );
    return response;
  };

  const getRelatedDocumentsAvaliableTypesForContractOptions = async (contractId: string) => {
    const response = await getRelatedDocumentsAvaliableTypesForContract(contractId);
    if (!response) return [];

    return response.map((type: any) => ({
      key: type.id,
      value: type.id,
      label: type.name,
      is_fixation: type.is_fixation,
    }));
  };

  const getDownloadDraftPDF = async (documentId: string) => {
    const response = await handleRequest(() => api.get(`/contract/attachment/${documentId}/to-pdf`), setIsFetching);

    return response;
  };

  const getRefusalStatuses = async () =>
    handleRequest(() => api.get('/contract/status/refusal-statuses'), setIsFetchingRefusalStatuses);

  const updateProperties = async (id: string, values: { [name: string]: unknown }) =>
    handleRequest(() => api.put(`/contract/${id}/update`, values), setIsUpdating);

  const getContractFixations = async (id: string, params: object = {}, withIds = false) => {
    if (withIds)
      return handleRequest(() => api.get(`/contract/${id}/get-db-fixations`, params), setIsFetchingFixations);
    return handleRequest(() => api.get(`/contract/${id}/get-fixations`, params), setIsFetchingFixations);
  };

  const getContractFixationsOptions = async (id: string, params: object = {}) => {
    const response = await getContractFixations(id, params);
    return response;
  };

  const getPurchaseTypes = async (params: { page: number; search: string }) =>
    handleRequest(() => api.get('/contract/purchase-type', { params }), setIsFetchingPurchaseTypes);

  const forceContractDatasul = async (contractNumber: string) =>
    handleRequest(
      () => datasulApi.contractV2.force({ params: { number: contractNumber } }),
      setIsForcingContractDatasul,
    );

  const getJustifications = async (params = {}) => {
    return handleRequest(() => api.get('/contract/comment-type', { params }), setIsFetchingJustifications);
  };

  const getJustificationsOptions = async (params = {}) => {
    const response = await getJustifications(params);

    if (!response) return { data: [] as any[], lastPage: 1 };

    return {
      data: response.data.map((justification: ContractJustificationData) => ({
        key: justification.id,
        label: justification.name,
        value: justification.id,
      })),
      lastPage: response.last_page,
    };
  };

  const getContractDataFromDatasul = async (contractNumber: string) =>
    handleRequest(() => datasulApi.contractV2.search({ params: { search: contractNumber } }));

  const requestReissueDraft = async (contractId: string, obs: string) => {
    const response = await handleRequest(() => api.post(`/contract/${contractId}/restart`, { obs }), setIsReissuing);
    return response;
  };

  const getContractDependencies = async (contractId: string, params = {}) =>
    handleRequest(() => api.get(`/contract/${contractId}/get-dependencies`, params), setIsFetchingDependencies);

  const getImports = async (contractId: string, params = {}): Promise<PaginationData<ContractImportData>> =>
    handleRequest(() => api.get(`/contract/${contractId}/imports`, params), setIsFetchingImports);

  const getContractByNumber = async (
    contractNuumber: string,
    isAdvance = false,
    params = {},
  ): Promise<CreditRequestContractData> =>
    handleRequest(
      () => api.get(`/contract/by-number/${contractNuumber}`, { params: { ...params, advance: isAdvance } }),
      setIsFetchingContractByNumber,
    );

  const forceManualDraftEmissionSigned = async (contractId: string, data = {}) =>
    handleRequest(() => api.post(`/contract/${contractId}/uploadminuta`, data), setIsSendingManualDraftEmissionSigned);

  return {
    isFetching,
    storeTemplate,
    updateTemplate,
    destroyTemplate,
    getAllTemplates,
    showTemplate,
    getDatasulFields,
    getDatasulFieldsOptions,
    isStoring,
    getAll,
    get,
    toggleActive,
    isActivating,
    preview,
    isFetchingPreview,
    isUploading,
    uploadSignedContract,
    validate,
    addAttachments,
    isAddingAttachments,
    sendComment,
    isSendingComment,
    removeComment,
    isRemovingComment,
    open,
    getRelatedDocumentsAvaliableTypesForContract,
    isFetchingRelatedDocumentsTypes,
    getRelatedDocumentsAvaliableTypesForContractOptions,
    getDownloadDraftPDF,
    getRefusalStatuses,
    isFetchingRefusalStatuses,
    updateProperties,
    isUpdating,
    getContractFixationsOptions,
    isFetchingFixations,
    getContractFixations,
    isFetchingPurchaseTypes,
    getPurchaseTypes,
    forceContractDatasul,
    isForcingContractDatasul,
    isFetchingJustifications,
    getJustificationsOptions,
    getJustifications,
    getContractDataFromDatasul,
    isReissuing,
    requestReissueDraft,
    getAllOptions,
    getContractDependencies,
    isFetchingDependencies,
    getImports,
    isFetchingImports,
    getContractByNumber,
    isFetchingContractByNumber,
    forceManualDraftEmissionSigned,
    isSendingManualDraftEmissionSigned,
  };
};

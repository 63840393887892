import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaSearch } from 'react-icons/fa';

import { Form, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import PermissionedComponent from 'components/PermissionedComponent';
import { ReportModal } from 'components/ReportModal';
import { getReportModalTranslation } from 'componentsTranslation/reportModal';
import { useAbility } from 'hooks/ability';
import { useCache } from 'hooks/cache';

import { Input, Button, FormItem } from '../../../../../components';
import { useModal } from '../../../../../hooks/modals/useModal';
import contractApi from '../../../../../services/api/contract';
import documentApi from '../../../../../services/api/document';
import { ContractListFilterMenu } from '../../../components/ContractListFilterMenu';
import { ContractListFilterContext } from '../../../context/ContractListFilterContext';
import {
  FilterButton,
  FilterClear,
  FilterExport,
  FilterHarvest,
  FilterMenuButton,
  FilterSearch,
  SFilterContainer,
} from './styles';

export const Filter = () => {
  const [form] = useForm();
  const { t } = useTranslation();
  const { clearFilter, addFilter, filter } = useContext(ContractListFilterContext);
  const { isOpen, close, open } = useModal();
  const { getCreditOptions } = useCache();
  const ability = useAbility();

  const [isExporting, setIsExporting] = useState(false);

  const openExportModal = () => setIsExporting(true);

  const tableDataClearAllFilters = () => {
    form.resetFields();
    clearFilter();
  };

  const handleFilter = () => addFilter(form.getFieldsValue());

  useEffect(() => {
    if (filter) {
      form.setFieldsValue({ search: filter.search });
    }
  }, [filter]);

  useEffect(() => {
    form.setFieldsValue({ datasource: '' });
  }, []);

  return (
    <SFilterContainer>
      <Form form={form} name="filter-owner" className="form-secondary form-filters grid-filters">
        <FilterSearch>
          <FormItem name="search" label={t('pages.edocuments.contracttypes.search')}>
            <Input placeholder={t(`pages.edocuments.contractlist.filters.search`)} maxLength={255} />
          </FormItem>
        </FilterSearch>

        <FilterHarvest>
          <FormItem name="harvest" label={t('pages.edocuments.contractlist.filters.menu.harvest')}>
            <Select options={getCreditOptions('harvest')} />
          </FormItem>
        </FilterHarvest>
        <FormItem name="datasource" label={t('pages.edocuments.contractlist.filters.menu.datasource')} initialValue="">
          <Select
            options={[
              {
                key: '',
                value: '',
                label: t('pages.edocuments.contractlist.filters.menu.datasource_options.all'),
              },
              {
                key: 'sap',
                value: 'SAP',
                label: 'SAP',
              },
              {
                key: 'datasul',
                value: 'DATASUL',
                label: 'Datasul',
              },
            ]}
          />
        </FormItem>
        <FilterButton>
          <FormItem label="" className="form-item-without-label">
            <Button status="primary" text="uppercase" htmlType="submit" onClick={handleFilter}>
              <FaSearch /> {t('form.actions.search')}
            </Button>
          </FormItem>
        </FilterButton>
        <PermissionedComponent permission="contract.report.control.index">
          <FilterExport>
            <Button status="primary" onClick={openExportModal}>
              {t('export')}
            </Button>
          </FilterExport>
        </PermissionedComponent>
        <FilterClear>
          <Button status="secondary" size="middle" onClick={tableDataClearAllFilters}>
            {t('pages.edocuments.contracttypes.buttonClearFilter')}
          </Button>
        </FilterClear>
        <FilterMenuButton>
          <Button status="primary-outline" size="middle" onClick={open}>
            {t('pages.edocuments.contractlist.buttonFilterMenu')}
          </Button>
        </FilterMenuButton>
      </Form>
      <ContractListFilterMenu isOpen={isOpen} close={close} />

      <ReportModal
        translation={getReportModalTranslation(t)}
        withDateFilter
        width="50%"
        visibility={isExporting}
        afterClose={() => setIsExporting(false)}
        listReportApi={
          ability.can('contract.report.control.index', '')
            ? contractApi.listReport
            : () => new Promise(resolve => resolve([]))
        }
        generateReportPermission="contract.report.control.store"
        generateReportApi={contractApi.generateReport}
        downloadReportApi={documentApi.download}
        rangeLimit={180}
      />
    </SFilterContainer>
  );
};

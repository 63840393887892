import React from 'react';

import { CONTRACT_STATUS_STORED } from 'configs/constants';

import { ContractData } from '../../../../../../types/Contract/ContractData';
import { RelatedDocumentButton } from '../../../../components/RelatedDocumentButton';
import { useContractContext } from '../../../../context/ContractContext';
import { Container } from './styles';

export const DocumentsList = () => {
  const { relatedDocuments, changeSelectedDocument, isDocumentSelected } = useContractContext();

  return (
    <Container>
      {relatedDocuments.map((accessory: ContractData) => (
        <RelatedDocumentButton
          code={accessory.number}
          key={accessory.id}
          onClick={() => changeSelectedDocument(accessory.id)}
          isSelected={isDocumentSelected(accessory.id)}
          id={accessory.id}
          isCancelled={accessory.status.id === CONTRACT_STATUS_STORED}
        />
      ))}
    </Container>
  );
};

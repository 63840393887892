import {
  SURVEY_CROP_CONDITION_VERY_GOOD,
  SURVEY_CROP_CONDITION_GOOD,
  SURVEY_CROP_CONDITION_FAIR,
  SURVEY_CROP_CONDITION_BAD,
  SURVEY_CROP_CONDITION_VERY_BAD,
} from '../../../configs/constants';

export const propsSkeletonCard = {
  minWidth: ['310px', '310px', '310px'],
  height: '126px',
  borderRadius: '10px',
  margin: '5px',
  number: 3,
};

export const propsSkeletonCharts = {
  width: '100%',
  minWidth: ['550px', '550px', '550px'],
  height: '492px',
  borderRadius: '10px',
  margin: '5px',
  number: 3,
};

export const propsFilters = [
  {
    name: 'harvest',
    key: 1,
  },
  {
    name: 'region',
    key: 2,
  },
  {
    name: 'subsidiary',
    key: 3,
  },
  {
    name: 'culture',
    key: 4,
  },
  {
    name: 'surveytype',
    key: 5,
  },
  {
    name: 'owner',
    key: 6,
  },
];

export const useChartsMonitoringProps = () => {
  const propsChartVisitsMonitoring = (data: any, t: any) => {
    if (data.length) {
      const legend = [
        { prop: 'survey', name: t('pages.dashboard.monitoring-prefin.visitsMonitoring.legends.survey') },
        {
          prop: 'requestsSurvey',
          name: t('pages.dashboard.monitoring-prefin.visitsMonitoring.legends.requestsSurvey'),
        },
      ];
      const categories = data.map((item: any) => item.region);

      const colors = [data[0].color_survey, data[0].color_survey_requests];

      const options = (value: string) => {
        switch (value) {
          case 'survey':
            return data.map((item: any) => parseInt(item.survey, 10));
          case 'requestsSurvey':
            return data.map((item: any) => parseInt(item.survey_requests, 10));
          default:
            return '';
        }
      };

      const series = legend.map((item: any) => ({
        name: item.name,
        data: options(item.prop),
      }));

      return {
        key: 1,
        title: t('pages.dashboard.monitoring-prefin.visitsMonitoring.title'),
        categories,
        series,
        colors,
        type: 'column',
      };
    }

    return {
      key: 1,
      title: t('pages.dashboard.monitoring-prefin.visitsMonitoring.title'),
    };
  };

  const propsChartVisitsConditionMonitoring = (data: any, t: any) => {
    if (Object.keys(data).length) {
      const stage = Object.keys(data.crop_conditions).map((item: any) => data.crop_conditions[item]);
      const convertObjectConditions = stage.map((i: any) => Object.keys(i).map((item: any) => i[item]));
      const conditions = convertObjectConditions.map((i: any) => i.filter((item: any) => typeof item !== 'string'));
      const legend = [
        {
          prop: 'veryGood',
          nameColor: 'Muito bom',
          name: t('pages.dashboard.monitoring-prefin.visitsStage.legends.veryGood'),
        },
        { prop: 'good', nameColor: 'Bom', name: t('pages.dashboard.monitoring-prefin.visitsStage.legends.good') },
        { prop: 'fair', nameColor: 'Regular', name: t('pages.dashboard.monitoring-prefin.visitsStage.legends.fair') },
        { prop: 'bad', nameColor: 'Ruim', name: t('pages.dashboard.monitoring-prefin.visitsStage.legends.bad') },
        {
          prop: 'veryBad',
          nameColor: 'Muito Ruim',
          name: t('pages.dashboard.monitoring-prefin.visitsStage.legends.veryBad'),
        },
      ];

      const setArrayConditions = (conditionId: string) => {
        const filterCondition = conditions.map((item: any) =>
          item.filter((c: any) => c.crop_condition_id === conditionId),
        );
        const newArray: any = [];
        filterCondition.forEach((element: any) => {
          if (element.length) newArray.push(parseInt(element[0].count, 10));
          else newArray.push(0);
        });
        return newArray;
      };

      const options = (value: string) => {
        switch (value) {
          case 'veryGood':
            return setArrayConditions(SURVEY_CROP_CONDITION_VERY_GOOD);
          case 'good':
            return setArrayConditions(SURVEY_CROP_CONDITION_GOOD);
          case 'fair':
            return setArrayConditions(SURVEY_CROP_CONDITION_FAIR);
          case 'bad':
            return setArrayConditions(SURVEY_CROP_CONDITION_BAD);
          case 'veryBad':
            return setArrayConditions(SURVEY_CROP_CONDITION_VERY_BAD);
          default:
            return '';
        }
      };

      const series = legend.map((item: any) => ({
        name: item.name,
        data: options(item.prop),
      }));

      const colors: any = [];
      legend.forEach((item: any) => {
        const filter = data.colors.filter((f: any) => f.name === item.nameColor);
        colors.push(filter[0].color);
      });

      const categories = stage.map((item: any) => item.stage);

      return {
        key: 2,
        title: t('pages.dashboard.monitoring-prefin.visitsStage.title'),
        categories,
        type: 'column',
        series,
        colors,
      };
    }

    return {
      key: 2,
      title: t('pages.dashboard.monitoring-prefin.visitsStage.title'),
    };
  };

  const propsChartMap = (data: any) => {
    const newData: any = [];
    data?.forEach((item: any) => {
      if (item.latitude && item.longitude)
        newData.push({
          ...item,
          area: item.warranty_area,
          position: { lat: parseFloat(item.latitude), lng: parseFloat(item.longitude) },
        });
    });
    return newData;
  };
  return {
    propsChartVisitsMonitoring,
    propsChartVisitsConditionMonitoring,
    propsChartMap,
  };
};

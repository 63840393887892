import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaSearch } from 'react-icons/fa';

import { DatePicker, Form } from 'antd';
import ptBR from 'antd/es/date-picker/locale/pt_BR';
import { Button, FormItem } from 'components';
import moment from 'moment';

import appConfigs from '../../../../../configs/app';
import { FilterButton, FilterClear, FilterEnd, FilterStart, SFilterContainer } from './styles';

interface Props {
  changeFilters: (values: { [name: string]: unknown }) => void;
}

export const Filter = ({ changeFilters }: Props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const handleFilter = () => {
    const values = form.getFieldsValue();
    const formattedValues = {} as any;

    if (values.start) formattedValues.start = moment(values.start).format(appConfigs.formatApiDate);
    if (values.end) formattedValues.end = moment(values.end).format(appConfigs.formatApiDate);

    changeFilters(formattedValues);
  };

  const tableDataClearAllFilters = () => {
    form.resetFields();
    changeFilters({});
  };

  return (
    <SFilterContainer>
      <Form form={form} name="filter-owner" className="form-secondary form-filters grid-filters">
        <FilterStart>
          <FormItem name="start" label={t('pages.edocuments.contracttypes.from')}>
            <DatePicker locale={ptBR} mode="date" format={appConfigs.formatDate} />
          </FormItem>
        </FilterStart>
        <FilterEnd>
          <FormItem name="end" label={t('pages.edocuments.contracttypes.to')}>
            <DatePicker
              locale={ptBR}
              disabledDate={currentDate => form.getFieldValue('start') > currentDate}
              mode="date"
              format={appConfigs.formatDate}
            />
          </FormItem>
        </FilterEnd>
        <FilterButton>
          <FormItem label="" className="form-item-without-label">
            <Button status="primary" text="uppercase" htmlType="submit" onClick={handleFilter}>
              <FaSearch /> {t('form.actions.search')}
            </Button>
          </FormItem>
        </FilterButton>
        <FilterClear>
          <Button status="secondary" size="middle" onClick={tableDataClearAllFilters}>
            {t('pages.edocuments.contracttypes.buttonClearFilter')}
          </Button>
        </FilterClear>
      </Form>
    </SFilterContainer>
  );
};

import React from 'react';

import { Container } from './styles';

interface Props {
  children?: React.ReactNode;
  onClick?: () => void;
}

export const AttachmentIconWrapper = ({ children, onClick }: Props) => {
  return <Container onClick={onClick ?? undefined}>{children}</Container>;
};

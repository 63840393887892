import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaMinus, FaPlus, FaSearch } from 'react-icons/fa';

import { Col, Form, Spin } from 'antd';
import { Store } from 'antd/lib/form/interface';
import TextArea from 'antd/lib/input/TextArea';
import Modal from 'antd/lib/modal/Modal';
import { TablePaginationConfig, TableProps } from 'antd/lib/table';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import Dragger from 'antd/lib/upload/Dragger';
import ContactData from 'types/Contract/ContactData';
import { v4 as uuid } from 'uuid';

import PaginationData from '../../../@types/data/PaginationData';
import { ResponseListData } from '../../../@types/data/ResponseData';
import DTOErrorReponse from '../../../@types/dtos/DTOErrorReponse';
import DTOFarmModel from '../../../@types/dtos/monitoring/DTOFarmModel';
import { Breadcrumb, Button, FormItem, IconWithTooltip, Input, ModalStatus, Row, Select } from '../../../components';
import { TableData } from '../../../compositions';
import { Can, useAbility } from '../../../hooks/ability';
import { useAuth } from '../../../hooks/auth';
import { useCache } from '../../../hooks/cache';
import { ItemsType, usePage } from '../../../hooks/page';
import documentApi from '../../../services/api/document';
import monitoringApi from '../../../services/api/monitoring';
import { downloadHandler } from '../../../services/download';
import { downloadDocument, getBase64, getOriginalName } from '../../../services/files';
import { DocumentModelData } from '../../../types/Document/DocumentModelData';
import { Coordinate } from '../../../utils/Coordinate';
import OwnerForm from '../Owner/OwnerForm';
import columns from './columns';
import {
  SContainer,
  SectionTitle,
  SFilterContainer,
  SFormButtons,
  SFormContainer,
  SHeader,
  STitlePage,
} from './styles';
import { NodeParam } from './types';

const Farm: React.FC = () => {
  const { t, i18n } = useTranslation();
  const [form] = Form.useForm();
  const ability = useAbility();

  const { updateUserData } = useAuth();
  const cache = useCache();
  const { alertStatus } = usePage();

  const [loadingButton, setLoadingButton] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);
  const [disabledCities, setDisabledCities] = useState(true);

  const [degrees, setDegrees] = useState(true);
  const attachments: any[] = [];

  const [data, setData] = useState([]);
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [paginationConfig, setPaginationConfig] = useState<TablePaginationConfig>({
    current: 1,
    total: 1,
    pageSize: 20,
  });

  const [contact_reference_form] = Form.useForm();
  const [contacts, setContact] = useState([]);

  const [visibilityForm, setVisibilityForm] = useState(false);

  // Documents
  const [alreadySelectedTypeFile, setAlreadySelectedTypeFile] = useState(false);
  const [alreadySelectedFile, setAlreadySelectedFile] = useState(false);
  const [documents_form] = Form.useForm();
  const [documents, setDocuments] = useState([]);
  // const [idsdocuments, setIdsDocuments] = useState([]);

  const [subforms, setSubforms] = useState(null);
  useEffect(() => {
    setSubforms({
      contact_reference: contact_reference_form,
      documents: documents_form,
    });
  }, [contact_reference_form, documents_form]);

  const [subformsLoading, setSubformsLoading] = useState({
    documents: { editing: null, removing: null, form: null, other: null },
    contact_reference: { editing: null, removing: null, form: null, other: null },
  });

  const [visibilitySubforms, setVisibilitySubforms] = useState({
    contact_reference: null,
    documents: null,
  });

  const updateData = (row: Store) => {
    let dataUpdated: Array<Store> = data;
    if (data.length > 0) {
      const rowIndex = data.findIndex((item: Store) => row.id === item.id);
      // Se encontrar o item remove e adiciona ele atualizado na mesma posição
      if (rowIndex > -1) {
        dataUpdated.splice(rowIndex, 1, row);
      } else {
        if (dataUpdated.length === paginationConfig.pageSize) {
          // Se não encontrar, remove o último (por conta da paginação) e
          // adiciona o item na primeira posição
          dataUpdated.splice(-1, 1);
        }
        dataUpdated = [row, ...dataUpdated];
      }
    } else {
      dataUpdated.push(row);
    }

    setData(dataUpdated);
  };

  async function handleTableRegistrationsChange() {
    contact_reference_form
      .validateFields()
      .then(() => {
        setContact([...contacts, contact_reference_form.getFieldsValue()]);

        setLoading(true);
        setLoading(false);

        setVisibilitySubforms(curState => ({
          ...curState,
          contact_reference: false,
        }));
      })
      .catch(() => {
        setLoading(false);
        setLoadingButton(false);
        setDisabledButton(false);
      });
  }

  const [formOptions, setFormOptions] = useState({
    state: [],
    cities: [],
    document_type: [],
  });

  const loadCityOptions = useCallback(
    async (stateId: string) => {
      setFormOptions(state => ({
        ...state,
        cities: cache.getCityByStateOptions(stateId),
      }));
      setDisabledCities(false);
    },
    [cache],
  );

  // document type
  const [documentType, setDocumentType] = useState<ItemsType[]>(() => {
    let documentTypeOptions: ItemsType[] = [];
    if (ability.can('document.type.index', ''))
      documentApi
        .documentType()
        .then((response: { data: any }) => {
          const { data: documentTypeData } = response.data.data;
          documentTypeOptions = documentTypeData.map((document: { id: any; name: any }) => ({
            key: document.id,
            value: document.id,
            label: document.name,
            saved: true,
          }));
          setDocumentType(documentTypeOptions);
        })
        .catch((err: DTOErrorReponse) => alertStatus(err, 'error'));
    return documentTypeOptions;
  });

  const handleSuccess = (messageSuccess: string) =>
    ModalStatus({
      type: 'success',
      title: t('modal.success'),
      subTitle: messageSuccess,
    });

  const loadTableData = useCallback(
    (values: Partial<OwnerForm & PaginationData<DTOFarmModel>>) => {
      setLoading(true);
      setLoadingButton(true);
      setDisabledButton(true);
      const dataSend = {
        params: {
          per_page: paginationConfig.pageSize,
          ...values,
        },
      };

      monitoringApi.farm
        .get(dataSend)
        .then((response: ResponseListData<DTOFarmModel>) => {
          const result = response.data.data;
          setData(result.data);
          setPaginationConfig(paginationConfigState => ({
            ...paginationConfigState,
            current: result.current_page,
            total: result.total,
          }));
        })
        .catch((err: DTOErrorReponse) => alertStatus(err, 'error'))
        .finally(() => {
          setLoading(false);
          setLoadingButton(false);
          setDisabledButton(false);
        });
    },
    // eslint-disable-next-line
    [i18n.language, paginationConfig.pageSize],
  );

  const handleDisplayForm = () => {
    setVisibilityForm(!visibilityForm);
    setEditing(false);
    setDisabledButton(false);
    setLoadingButton(false);
    setDisabledCities(true);
    form.resetFields();
    setContact([]);
  };

  const handleHideForm = () => {
    setVisibilityForm(false);
    setDocuments([]);
  };

  const handleRemoveContactChange = async (record: any, index: number) => {
    ModalStatus({
      type: 'delete',
      title: t('pages.monitoring.farm.delete.title'),
      subTitle: t('pages.monitoring.farm.delete.subtitle'),
      cancelText: t('pages.monitoring.farm.delete.cancel'),
      okText: t('pages.monitoring.farm.delete.confirm'),
      onOk: () => {
        setLoading(true);
        if (record.id !== undefined) {
          monitoringApi.farm
            .destroyContact(record.id, {
              params: {},
            })
            .then(response => {
              handleSuccess(response.data.message);
              contacts.splice(index, 1);
              setContact([...contacts]);
              setLoading(false);
              // eslint-disable-next-line @typescript-eslint/no-use-before-define
              handleTableChange({ current: 1 }, { active: [1] }, {}, null);
            })
            .catch((err: DTOErrorReponse) => alertStatus(err, 'error'));
        } else {
          contacts.splice(index, 1);
          setContact([...contacts]);
          setLoading(false);
        }
      },
    });
  };

  const handleRemoveChange = async (record: any) => {
    ModalStatus({
      type: 'delete',
      title: t('pages.monitoring.farm.delete.title'),
      subTitle: t('pages.monitoring.farm.delete.subtitle'),
      cancelText: t('pages.monitoring.farm.delete.cancel'),
      okText: t('pages.monitoring.farm.delete.confirm'),
      onOk: () => {
        setLoading(true);
        monitoringApi.farm
          .destroy(record.id, {
            params: {},
          })
          .then(response => {
            handleSuccess(response.data.message);
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            handleTableChange({ current: 1 }, { active: [1] }, {}, null);
          })
          .catch((err: DTOErrorReponse) => alertStatus(err, 'error'));
      },
    });
  };

  const handleRegister = () => {
    setLoading(true);
    setLoadingButton(true);
    setDisabledButton(true);
    form
      .validateFields()
      .then(() => {
        const formValues = form.getFieldsValue();
        formValues.documents = [];
        if (documents.length > 0) {
          documents.forEach((record: any, key) => {
            if (formValues.documents[key]) {
              formValues.documents[key].name = record.name;
              formValues.documents[key].type_id = record.type_id;
              formValues.documents[key].label = 'survey';
              formValues.documents[key].mime = record.mime;
              formValues.documents[key].image = record.image;
              formValues.documents[key].extension = record.mime;
            } else {
              formValues.documents.push(record);
            }
          });
        }

        delete formValues.state;

        if (contacts.length > 0) {
          formValues.contacts = [];
          contacts.forEach((record: any, key) => {
            if (formValues.contacts?.[key]) {
              formValues.contacts[key].type = record.type;
              formValues.contacts[key].value = record.value;
              formValues.contacts[key].description = record.description;
            } else {
              formValues.contacts.push(record);
            }
          });
        }

        formValues.language = i18n.language;

        monitoringApi.farm
          .store(formValues)
          .then((response: ResponseListData<DTOFarmModel>) => {
            const { data: farm } = response.data;
            handleSuccess(response.data.message);
            updateData(farm);
            handleHideForm();
            setLoading(false);
            setLoadingButton(false);
            setDisabledButton(false);
            form.resetFields();
            updateUserData();
          })
          .catch((err: DTOErrorReponse) => {
            alertStatus(err, 'error');
            setLoading(false);
            setLoadingButton(false);
            setDisabledButton(false);
          });
      })
      .catch((err: DTOErrorReponse) => {
        alertStatus(err, 'error');
        setLoading(false);
        setLoadingButton(false);
        setDisabledButton(false);
      });
  };

  const handleEdit = () => {
    setLoading(true);
    setLoadingButton(true);
    setDisabledButton(true);
    form
      .validateFields()
      .then(() => {
        const formValues = form.getFieldsValue();
        formValues.documents = [];
        if (documents.length > 0) {
          documents.forEach((record: any, key) => {
            if (formValues.documents[key]) {
              formValues.documents[key].name = record.name;
              formValues.documents[key].type_id = record.type_id;
              formValues.documents[key].label = 'survey';
              formValues.documents[key].mime = record.mime;
              formValues.documents[key].image = record.image;
              formValues.documents[key].extension = record.mime;
            } else {
              formValues.documents.push(record);
            }
          });
        }

        delete formValues.state;
        if (contacts.length > 0) {
          formValues.contacts = [];
          contacts.forEach((record: any, key) => {
            if (formValues.contacts?.[key]) {
              formValues.contacts[key].type = record.type;
              formValues.contacts[key].value = record.value;
              formValues.contacts[key].description = record.description;
            } else {
              formValues.contacts.push(record);
            }
          });
        }

        formValues.language = i18n.language;

        monitoringApi.farm
          .update(formValues.id, formValues)
          .then((response: ResponseListData<DTOFarmModel>) => {
            const { data: farm } = response.data;
            handleSuccess(response.data.message);
            updateUserData();
            handleHideForm();
            updateData(farm);
            setLoading(false);
            setLoadingButton(false);
            setDisabledButton(false);
            form.resetFields();
          })
          .catch((err: DTOErrorReponse) => {
            alertStatus(err, 'error');
            setLoading(false);
            setLoadingButton(false);
            setDisabledButton(false);
          });
      })
      .catch((err: DTOErrorReponse) => {
        alertStatus(err, 'error');
        setLoading(false);
        setLoadingButton(false);
        setDisabledButton(false);
      });
  };

  // Table DATA Config: BEGIN
  const getActions = () => ({
    render: (active: number, record: DTOFarmModel) => (
      <>
        <Can I="monitoring.farm.destroy" a="">
          <IconWithTooltip
            action="destroy"
            title={t('pages.monitoring.farm.table.icons.remove')}
            onClick={() => handleRemoveChange(record)}
          />
        </Can>
        <Can I="monitoring.farm.update" a="">
          <IconWithTooltip
            action="edit"
            title={t('pages.monitoring.farm.table.icons.edit')}
            onClick={() => {
              loadCityOptions(record.city.state.id);
              form.setFieldsValue({
                ...record,
                state_id: record.city.state.id,
                is_dms: record.is_dms ? 1 : 0,
              });

              setDegrees(record.is_dms);
              setContact(record.contacts);
              setDisabledCities(false);

              setDocuments(record.documents);

              form.scrollToField('name', {
                scrollMode: 'always',
                block: 'start',
                behavior: actions =>
                  actions.forEach(({ el, top }) => {
                    el.scrollTop = top - 280;
                  }),
              });

              setVisibilityForm(true);
              setEditing(true);
              setLoading(false);
              setLoadingButton(false);
              setDisabledButton(false);
            }}
          />
        </Can>
      </>
    ),
  });

  const tableConfigContact = {
    search: '',
    filtered: {},
    sorter: {},
    nopagination: true,
    getActions: () => ({
      render: (_active: number, record: ContactData, index: number) => (
        <>
          <IconWithTooltip
            action="destroy"
            title={t('pages.monitoring.farm.table.icons.remove')}
            onClick={() => {
              handleRemoveContactChange(record, index);
            }}
          />
        </>
      ),
    }),
  };

  const [searchForm] = Form.useForm();
  const tableConfig = {
    search: '',
    filtered: {},
    sorter: {},
    getActions,
  };

  const [tableDataConfig, setTableDataConfig] = useState(tableConfig);

  const handleFastSearch = () => {
    const value = searchForm.getFieldsValue();
    setTableDataConfig(tableDataConfigState => ({
      ...tableDataConfigState,
      search: value.search,
    }));
    loadTableData({
      search: value.search,
    });
  };

  const tableDataClearAllFilters = () => {
    searchForm.resetFields();
    setContact([]);
    setTableDataConfig(tableDataConfigState => ({
      ...tableDataConfigState,
      filtered: {},
      search: '',
      sorter: {},
    }));
    loadTableData({ page: 1 });
  };

  const handleTableChange: TableProps<any>['onChange'] = useCallback(
    (
      pagination: TablePaginationConfig,
      filters: Record<string, FilterValue | null>,
      sorter: SorterResult<any> | SorterResult<any>[],
    ) => {
      setLoading(true);
      setTableDataConfig(tableDataConfigState => ({
        ...tableDataConfigState,
        filtered: filters,
        sorter,
      }));

      let direction = '';
      const search: any = {};
      let sort = '';

      if (sorter) {
        const { field, order } = sorter as SorterResult<any>;

        if (order === 'ascend') direction = 'asc';
        if (order === 'descend') direction = 'desc';
        sort = field?.toString() ?? '';
      }

      if (filters) {
        const dataFilter: any = filters;

        if (dataFilter) {
          Object.keys(dataFilter).forEach((key: string) => {
            if (dataFilter[key] !== null && dataFilter[key] !== undefined && dataFilter[key][0]) {
              search[key] = dataFilter[key][0].toString();
            } else if (dataFilter[key] !== null && dataFilter[key] !== undefined) {
              search[key] = dataFilter[key].toString();
            } else {
              search[key] = '';
            }
          });
        }
      }

      setPaginationConfig(paginationConfigState => ({
        ...paginationConfigState,
        pageSize: pagination.pageSize,
        current: pagination.current,
      }));

      loadTableData({
        page: pagination.current,
        per_page: pagination.pageSize,
        sort,
        direction,
        ...search,
      });
    },
    [loadTableData],
  );
  // Table DATA Config: END

  useEffect(() => {
    const filters: any = {};

    handleTableChange({ current: 1, pageSize: paginationConfig.pageSize }, filters, {}, null);
  }, [handleTableChange, paginationConfig.pageSize]);

  async function subformsOpen(node: NodeParam) {
    subforms[node].resetFields();
    setVisibilitySubforms(curState => ({ ...curState, [node]: true }));
  }

  async function handleClickAddRegistrationReference() {
    subformsOpen('contact_reference');
  }

  async function handleClickAddDocuments() {
    subformsOpen('documents');
    setAlreadySelectedTypeFile(false);
    setAlreadySelectedFile(false);
    subforms.documents.setFieldsValue({ id: uuid() });
  }

  async function handleTableDocumentsChange() {
    documents_form
      .validateFields()
      .then(() => {
        if (documents_form.getFieldsValue().name !== undefined) {
          setDocuments([...documents, { ...documents_form.getFieldsValue(), editing: true }]);

          setLoading(true);
          setLoading(false);

          setVisibilitySubforms(curState => ({
            ...curState,
            documents: false,
          }));
        } else {
          alertStatus(t('form.validation.hasErrors'), 'error');
          setLoading(false);
          setLoadingButton(false);
          setDisabledButton(false);
        }
      })
      .catch(() => {
        setLoading(false);
        setLoadingButton(false);
        setDisabledButton(false);
      });
  }

  async function handleRemoveFile(id: string, index: number, isEditing: boolean) {
    if (!isEditing) await documentApi.delete(id);
    documents.splice(index, 1);
    setDocuments([...documents]);
  }

  // Load FormOptions
  useEffect(() => {
    setFormOptions(curState => ({
      ...curState,
      state: cache.getCreditOptions('state'),
      cities: cache.getCreditOptions('city'),
      document_type: cache.getPersonOptions('document_type_person'),
    }));
  }, [cache]);

  return (
    <>
      <Breadcrumb
        items={[{ title: t('breadcrumb.monitoring') }, { title: t('breadcrumb.farms'), to: '/monitoring/farm' }]}
      />

      <SContainer>
        <SHeader>
          <STitlePage>{t('pages.monitoring.farm.title_page')}</STitlePage>

          <Can I="monitoring.farm.store" a="">
            <Button status="primary" icon={visibilityForm ? <FaMinus /> : <FaPlus />} onClick={handleDisplayForm}>
              {t('pages.monitoring.farm.buttonAdd')}
            </Button>
          </Can>
        </SHeader>

        <SFormContainer visible={visibilityForm}>
          <Spin spinning={loading}>
            <h2>{t('pages.monitoring.farm.registerTitle')}</h2>
            <Form form={form}>
              <FormItem name="id" style={{ display: 'none' }}>
                <Input />
              </FormItem>
              <Row gutter={[26, 26]}>
                <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <FormItem
                    style={{ paddingRight: '10px' }}
                    label={t('pages.monitoring.farm.form.name')}
                    name="name"
                    rules={[{ required: true }]}
                  >
                    <Input type="text" maxLength={255} />
                  </FormItem>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <FormItem
                    style={{ paddingRight: '10px' }}
                    label={t('pages.monitoring.farm.form.address')}
                    name="address"
                    rules={[{ required: true }]}
                  >
                    <Input type="text" maxLength={255} />
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={[26, 26]}>
                <Col xs={24} sm={18} md={12} lg={12} xl={12} xxl={12}>
                  <FormItem label={t('pages.monitoring.farm.form.state')} name="state_id" rules={[{ required: true }]}>
                    <Select
                      options={formOptions.state}
                      onChange={value => (value ? loadCityOptions(value.toString()) : '')}
                    />
                  </FormItem>
                </Col>
                <Col xs={24} sm={18} md={12} lg={12} xl={12} xxl={12}>
                  <FormItem label={t('pages.monitoring.farm.form.city')} name="city_id" rules={[{ required: true }]}>
                    <Select options={formOptions.cities} disabled={disabledCities} />
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={[26, 26]}>
                <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={8}>
                  <FormItem label={t('pages.monitoring.farm.form.coordinateType')} name="is_dms">
                    <Select
                      onChange={value => setDegrees(Number(value) === 1)}
                      options={[
                        { value: 1, label: t('pages.monitoring.farm.form.degrees') },
                        { value: 0, label: t('pages.monitoring.farm.form.decimal') },
                      ]}
                    />
                  </FormItem>
                </Col>
                <Col style={{ display: degrees ? 'block' : 'none' }} xs={24} sm={12} md={12} lg={8} xl={8} xxl={8}>
                  <FormItem
                    label={t('pages.monitoring.farm.form.degrees')}
                    name="dms"
                    rules={[
                      {
                        pattern: Coordinate.regexDMS,
                        message: t('pages.monitoring.farm.validator.dms'),
                      },
                    ]}
                  >
                    {/* <MaskedInput
                      mask="11° 11′ 11″ a, 111° 11′ 11″ a"
                     
                      placeholder="__° __′ __″ _, ___° __′ __″ _"
                    /> */}
                    <Input placeholder="__° __′ __″ _, ___° __′ __″ _" />
                  </FormItem>
                </Col>
                <Col style={{ display: !degrees ? 'block' : 'none' }} xs={24} sm={12} md={12} lg={8} xl={8} xxl={8}>
                  <FormItem
                    label={t('pages.monitoring.farm.form.latitude')}
                    name="latitude"
                    rules={[
                      {
                        pattern: Coordinate.regexLat,
                        message: t('pages.monitoring.farm.validator.lat'),
                      },
                    ]}
                  >
                    <Input hidden={degrees} />
                  </FormItem>
                </Col>
                <Col style={{ display: !degrees ? 'block' : 'none' }} xs={24} sm={12} md={12} lg={8} xl={8} xxl={8}>
                  <FormItem
                    label={t('pages.monitoring.farm.form.longitude')}
                    name="longitude"
                    rules={[
                      {
                        pattern: Coordinate.regexLon,
                        message: t('pages.monitoring.farm.validator.lon'),
                      },
                    ]}
                  >
                    <Input hidden={degrees} />
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={[26, 26]}>
                <Col xs={24} sm={18} md={12} lg={9} xl={8} xxl={8}>
                  <FormItem label={t('pages.monitoring.farm.form.details')} name="details">
                    <TextArea maxLength={3000} />
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={[26, 26]}>
                <Col xs={20} sm={20} md={20} lg={20} xl={20} xxl={20} />
                {/* Botão Matícula */}

                <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                  <Button block onClick={handleClickAddRegistrationReference}>
                    {t('pages.monitoring.farm.buttonContact')}
                  </Button>
                  <FormItem name="contact_reference" style={{ display: 'none' }}>
                    <Input />
                  </FormItem>
                  <FormItem name="contacts" hidden>
                    <Input />
                  </FormItem>
                </Col>
              </Row>
              {/* Tabela Contatos */}
              <Row gutter={[26, 26]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                  <TableData
                    rowKey="id"
                    columns={columns.contacts}
                    dataSource={contacts}
                    loading={loading}
                    tableConfig={tableConfigContact}
                  />
                </Col>
              </Row>

              <SectionTitle>{t('pages.monitoring.farm.form.files')}</SectionTitle>
              <>
                <SFormButtons style={{ paddingTop: '15px' }}>
                  <>
                    <Button status="primary-outline" onClick={handleClickAddDocuments}>
                      {t('pages.monitoring.farm.form.attachFile')}
                    </Button>
                    {editing && (
                      <Button
                        status="primary-outline"
                        onClick={async () => {
                          if (documents.length) {
                            setLoading(true);
                            let c = 0;

                            attachments.push({
                              attachment_id: [],
                            });

                            documents.forEach(element => {
                              attachments[0].attachment_id[c] = element.id;
                              c += 1;
                            });

                            documentApi
                              .downloadmultiplefiles({
                                params: attachments[0],
                                responseType: 'blob',
                              })
                              .then(downloadHandler)
                              .catch((err: DTOErrorReponse) => alertStatus(err, 'error'))
                              .finally(() => {
                                setLoading(false);
                              });
                          } else {
                            ModalStatus({
                              type: 'error',
                              subTitle: t('pages.monitoring.farm.files.download.nothing'),
                            });
                          }
                        }}
                      >
                        <IconWithTooltip
                          action="download"
                          title={t('pages.registered-customers.table.icons.download')}
                        />
                        {t('pages.monitoring.farm.form.downloadall')}
                      </Button>
                    )}
                  </>
                </SFormButtons>
                <>
                  <TableData
                    rowKey="id"
                    columns={columns.documents.map((column: { key: any }) => {
                      let columnRender: any = column;

                      switch (column.key) {
                        case 'type_id':
                          columnRender = {
                            ...columnRender,
                            render: (active: number, record: any) => {
                              const findState = documentType.find(s => s.value === record.type_id);
                              return findState?.label;
                            },
                          };
                          break;
                        case 'action':
                          columnRender = {
                            ...columnRender,
                            render: (active: number, record: DocumentModelData, index: number) => (
                              <>
                                <IconWithTooltip
                                  action="download"
                                  title={t('pages.registered-customers.table.icons.download')}
                                  loading={subformsLoading.documents.other === record.id}
                                  onClick={async () => {
                                    setSubformsLoading(curState => ({
                                      ...curState,
                                      documents: { ...subformsLoading.documents, other: record.id },
                                    }));
                                    if (!record?.image) {
                                      await documentApi
                                        .download(record.id, {
                                          responseType: 'blob',
                                        })
                                        .then(downloadHandler)
                                        .catch((err: DTOErrorReponse) => alertStatus(err, 'error'))
                                        .finally(() => {
                                          setSubformsLoading(curState => ({
                                            ...curState,
                                            documents: { ...subformsLoading.documents, other: null },
                                          }));
                                        });
                                    } else {
                                      setSubformsLoading(curState => ({
                                        ...curState,
                                        documents: { ...subformsLoading.documents, other: null },
                                      }));
                                      downloadDocument(record.image, record.name);
                                    }
                                  }}
                                />

                                <Can I="document.destroy">
                                  <IconWithTooltip
                                    action="destroy"
                                    title={t('pages.registered-customers.table.icons.remove')}
                                    loading={subformsLoading.documents.removing === record.id}
                                    onClick={() => {
                                      ModalStatus({
                                        type: 'delete',
                                        title: t('pages.monitoring.farm.files.delete.title'),
                                        subTitle: t('pages.monitoring.farm.files.delete.subtitle'),
                                        cancelText: t('pages.monitoring.farm.files.delete.cancel'),
                                        okText: t('pages.monitoring.farm.files.delete.confirm'),
                                        onOk: async () => handleRemoveFile(record.id, index, record.editing),
                                      });
                                    }}
                                  />
                                </Can>
                              </>
                            ),
                          };
                          break;
                        default:
                          return columnRender;
                      }

                      return columnRender;
                    })}
                    dataSource={documents || null}
                    tableConfig={{ nopagination: true }}
                    rowClassName={record => record.editing && 'editing'}
                  />
                  <br />
                </>
                <SFormButtons>
                  <Button status="secondary" htmlType="reset" onClick={handleHideForm} disabled={disabledButton}>
                    {t('pages.monitoring.farm.buttonCancel')}
                  </Button>
                  {editing && (
                    <Button
                      status="primary"
                      htmlType="submit"
                      onClick={handleEdit}
                      loading={loadingButton}
                      disabled={disabledButton}
                      permission="monitoring.farm.update"
                    >
                      {t('pages.monitoring.farm.buttonEdit')}
                    </Button>
                  )}
                  {!editing && (
                    <Button
                      status="primary"
                      htmlType="submit"
                      onClick={handleRegister}
                      loading={loadingButton}
                      disabled={disabledButton}
                      permission="monitoring.farm.store"
                    >
                      {t('pages.monitoring.farm.buttonRegister')}
                    </Button>
                  )}
                </SFormButtons>
              </>
            </Form>
          </Spin>
        </SFormContainer>

        {/* Modal Contato  */}
        <Modal
          title={t('pages.monitoring.farm.form.contact')}
          visible={visibilitySubforms.contact_reference}
          onCancel={() =>
            setVisibilitySubforms(curState => ({
              ...curState,
              contact_reference: false,
            }))
          }
          afterClose={() => contact_reference_form.resetFields()}
          width="80%"
          footer={
            <>
              <SFormButtons>
                <Button
                  status="secondary"
                  onClick={() => {
                    setVisibilitySubforms(curState => ({
                      ...curState,
                      contact_reference: false,
                    }));
                    contact_reference_form.resetFields();
                  }}
                  disabled={subformsLoading.contact_reference.form}
                >
                  {t('pages.monitoring.farm.form.buttons.cancel')}
                </Button>
                <Button
                  status="primary"
                  onClick={() => handleTableRegistrationsChange()}
                  disabled={subformsLoading.contact_reference.form}
                >
                  {t('pages.monitoring.farm.form.buttons.save')}
                </Button>
              </SFormButtons>
            </>
          }
          className="modal-with-custom-footer"
        >
          <Spin spinning={subformsLoading.contact_reference.form}>
            <Form name="subform_contact_reference" form={contact_reference_form}>
              <Row>
                <Col xs={24} sm={18} md={12} lg={8} xl={8} xxl={8}>
                  <FormItem
                    label={t('pages.monitoring.farm.form.typeContact')}
                    name="type"
                    rules={[{ required: true }]}
                  >
                    <Select
                      onChange={value => setDegrees(Number(value) === 1)}
                      options={[
                        { value: 'mail', label: t('pages.monitoring.farm.form.email') },
                        { value: 'phone', label: t('pages.monitoring.farm.form.phone') },
                        { value: 'mobile', label: t('pages.monitoring.farm.form.cel_phone') },
                      ]}
                    />
                  </FormItem>
                </Col>
                <Col xs={24} sm={18} md={12} lg={8} xl={8} xxl={8}>
                  <FormItem
                    style={{ paddingRight: '10px' }}
                    label={t('pages.monitoring.farm.form.contact')}
                    name="value"
                    rules={[{ required: true }]}
                  >
                    <Input type="text" maxLength={50} />
                  </FormItem>
                </Col>

                <Col xs={24} sm={18} md={12} lg={8} xl={8} xxl={8}>
                  <FormItem
                    style={{ paddingRight: '10px' }}
                    label={t('pages.monitoring.farm.form.description')}
                    name="description"
                    rules={[{ required: true }]}
                  >
                    <Input type="text" maxLength={50} />
                  </FormItem>
                </Col>
              </Row>

              <Row />
            </Form>
          </Spin>
        </Modal>

        <SFilterContainer>
          <Form
            form={searchForm}
            name="filter-owner"
            className="form-secondary form-filters grid-filters"
            onFinish={handleFastSearch}
          >
            <div className="filter-search">
              <FormItem name="search" label={t('pages.monitoring.farm.search')}>
                <Input placeholder={t(`pages.monitoring.farm.filters.search`)} />
              </FormItem>
            </div>
            <div className="filter-button">
              <FormItem label="" className="form-item-without-label">
                <Button status="primary" text="uppercase" htmlType="submit">
                  <FaSearch /> {t('form.actions.search')}
                </Button>
              </FormItem>
            </div>
            <div className="filter-clear">
              <Button status="secondary" size="middle" onClick={tableDataClearAllFilters}>
                {t('pages.register.harvest.buttonClearFilter')}
              </Button>
            </div>
          </Form>
        </SFilterContainer>

        <TableData
          rowKey="id"
          columns={columns.farm.map((column: any) => {
            let columnRender: any = {
              ...column,
            };

            if (column.key === 'city') {
              columnRender = {
                ...columnRender,
                render: (value: any, row: any) => `${row.city.name}`,
              };
            }

            if (column.key === 'state') {
              columnRender = {
                ...columnRender,
                render: (value: any, row: any) => `${row.city.state.name}`,
              };
            }

            return columnRender;
          })}
          dataSource={data}
          loading={loading}
          onChange={handleTableChange}
          pagination={paginationConfig}
          tableConfig={tableDataConfig}
        />
        <Modal
          title={t('pages.monitoring.cpr.document.modal.title')}
          visible={visibilitySubforms.documents}
          onCancel={() => setVisibilitySubforms(curState => ({ ...curState, documents: false }))}
          afterClose={() => {
            documents_form.resetFields();
            setAlreadySelectedTypeFile(false);
          }}
          width="80%"
          footer={
            <>
              <div className="buttons">
                <Button
                  status="primary"
                  onClick={() => handleTableDocumentsChange()}
                  disabled={subformsLoading.documents.form}
                >
                  {t('pages.registered-customers.form.buttons.save')}
                </Button>

                <Button
                  status="secondary"
                  onClick={() => {
                    setVisibilitySubforms(curState => ({ ...curState, documents: false }));
                    documents_form.resetFields();
                  }}
                  disabled={subformsLoading.documents.form}
                >
                  {t('pages.registered-customers.form.buttons.cancel')}
                </Button>
              </div>
            </>
          }
          className="modal-with-custom-footer"
        >
          <Spin spinning={subformsLoading.documents.form}>
            <Form name="subform_documents" form={documents_form}>
              <FormItem name="id" hidden>
                <Input />
              </FormItem>
              <FormItem name="original" hidden>
                <Input />
              </FormItem>
              <FormItem name="name" hidden>
                <Input />
              </FormItem>
              <FormItem name="mime" hidden>
                <Input />
              </FormItem>
              <FormItem name="image" hidden>
                <Input />
              </FormItem>

              <Row gutter={[8, 8]}>
                <Col xs={24} sm={24} md={24} lg={12} xl={9} xxl={9}>
                  <FormItem
                    label={t('pages.monitoring.farm.form.type_file')}
                    name="type_id"
                    rules={[{ required: true }]}
                    permission="document.type.index"
                  >
                    <Select
                      options={documentType}
                      onChange={() => {
                        setAlreadySelectedTypeFile(true);
                        setAlreadySelectedFile(false);
                      }}
                    />
                  </FormItem>
                </Col>
              </Row>

              {alreadySelectedTypeFile && (
                <>
                  <Row gutter={[8, 8]}>
                    <Col xs={24} sm={24} md={24} lg={12} xl={9} xxl={9}>
                      {alreadySelectedFile ? (
                        <>
                          <div className="selected-document">
                            <span>{documents_form.getFieldValue('name')}</span>
                            <Button
                              status="primary-outline"
                              onClick={() => {
                                setAlreadySelectedFile(false);
                              }}
                            >
                              {t('pages.registered-customers.form.pf.documents.changeFile')}
                            </Button>
                          </div>
                        </>
                      ) : (
                        <>
                          <Dragger
                            className="custom-dragger"
                            accept=".jpg,.png,.jpeg,.pdf"
                            action={(file: any) => {
                              getBase64(file, (documentBase64: any) => {
                                documents_form.setFieldsValue({
                                  image: documentBase64,
                                  mime: file.type,
                                  name: getOriginalName(file.name, true),
                                  original: getOriginalName(file.name),
                                });
                                setAlreadySelectedFile(true);
                              });
                              return file;
                            }}
                            customRequest={() => 'done'}
                          >
                            {t('pages.registered-customers.form.pf.documents.dragText')}
                          </Dragger>
                        </>
                      )}
                    </Col>
                  </Row>
                </>
              )}
            </Form>
          </Spin>
        </Modal>
      </SContainer>
    </>
  );
};

export default Farm;

import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ShouldRender } from 'components/ShouldRender';
import { useAlertDialog } from 'components/ui/alert-dialog';
import { Button } from 'components/ui/button';
import { GroupForm } from 'components/ui/group-form';
import { randomUUID } from 'lib/utils';

import { CommercialReferenceFormData } from './commercial-reference/commercial-reference.form';
import { CommercialReferenceModal } from './commercial-reference/commercial-reference.modal';
import { CommercialReferenceTable } from './commercial-reference/commercial-reference.table';
import { useRegisteredCustomerStore } from './stores/use-registered-customer-store';

export const RegisteredCustomerCommercialReferenceSection = () => {
  const { t } = useTranslation();
  const form = useFormContext();
  const { alert } = useAlertDialog();

  const isViewing = useRegisteredCustomerStore(state => state.isViewing);
  const isModulePerson = useRegisteredCustomerStore(state => state.isModulePerson);
  const setIsCommercialReferenceModalOpen = useRegisteredCustomerStore(
    state => state.setIsCommercialReferenceModalOpen,
  );
  const commercialReferenceToEdit = useRegisteredCustomerStore(state => state.commercialReferenceToEdit);

  const handleAddCommercialReference = (data: CommercialReferenceFormData) => {
    const commercialReferences = form.getValues('commercial_reference') as CommercialReferenceFormData[];

    form.setValue('commercial_reference', [
      ...(commercialReferences ?? []),
      {
        ...data,
        id: `new-${randomUUID()}`,
      },
    ]);
  };

  const handleEditCommercialReference = (data: CommercialReferenceFormData) => {
    const commercialReferences = form.getValues('commercial_reference') as CommercialReferenceFormData[];
    const index = commercialReferences.findIndex(commercialReference => commercialReference.id === data.id);
    commercialReferences[index] = data;
    form.setValue('commercial_reference', commercialReferences, {
      shouldTouch: true,
    });
    alert({
      type: 'success',
      title: t('commercial-reference-updated'),
      description: '',
    });
  };

  return (
    <GroupForm title={t('commecial-references')}>
      <ShouldRender condition={!isViewing && isModulePerson}>
        <div className="flex justify-end w-full">
          <Button type="button" onClick={() => setIsCommercialReferenceModalOpen(true)} variant="default">
            {t('add-commercial-reference')}
          </Button>
        </div>
      </ShouldRender>

      <div className="mt-6">
        <CommercialReferenceTable />
      </div>

      <CommercialReferenceModal
        onSuccess={commercialReferenceToEdit ? handleEditCommercialReference : handleAddCommercialReference}
      />
    </GroupForm>
  );
};

export const appConfigs = {
  clientTitle: process.env.REACT_APP_TITLE_CLIENT,
  companyTitle: process.env.REACT_APP_TITLE_COMPANY,
  companySite: process.env.REACT_APP_SITE_COMPANY,
  formatApiDate: 'YYYY-MM-DD',
  formatDate: 'DD/MM/YYYY',
  formatDateTime: 'DD/MM/YYYY HH:mm',
  formatDateFullTime: 'DD/MM/YYYY HH:mm:ss',
  formatTime: 'HH:mm',
};

export default appConfigs;

import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ColumnDef } from '@tanstack/react-table';
import { IconWithTooltip } from 'components';
import { ShouldRender } from 'components/ShouldRender';
import { useAlertDialog } from 'components/ui/alert-dialog';
import { DataTable } from 'components/ui/data-table';
import { Can } from 'hooks/ability';
import { useCache } from 'hooks/cache';
import { Formatter } from 'utils/Formatter';

import { useRegisteredCustomerStore } from '../stores/use-registered-customer-store';
import { MainProviderFormData } from './main-providers.form';

export const MainProvidersTable = () => {
  const { t } = useTranslation();
  const form = useFormContext();
  const { alert } = useAlertDialog();
  const cache = useCache();

  const mainProviders = form.watch('main_provider') as MainProviderFormData[];

  const setMainProviderToEdit = useRegisteredCustomerStore(state => state.setMainProviderToEdit);
  const setIsMainProviderModalOpen = useRegisteredCustomerStore(state => state.setIsMainProviderModalOpen);
  const isViewing = useRegisteredCustomerStore(state => state.isViewing);

  const onDelete = (id: string) => {
    const mainProviders = form.getValues('main_provider') as MainProviderFormData[];
    form.setValue(
      'main_provider',
      mainProviders.filter(bankReference => bankReference.id !== id),
    );
  };

  const handleDelete = (mainProvider: MainProviderFormData) => {
    alert({
      type: 'warning',
      title: t('delete-main-provider'),
      description: t('delete-main-provider-confirm', {
        mainProvider: mainProvider.name,
      }),
      onOk: async () => {
        if (mainProvider.id) await onDelete(mainProvider.id.toString());
        alert({
          type: 'success',
          title: t('main-provider-deleted'),
          description: '',
        });
      },
    });
  };

  const columns: ColumnDef<MainProviderFormData>[] = [
    {
      accessorKey: 'name',
      header: t('pages.registered-customers.form.pj.provider_reference.name'),
    },
    {
      accessorKey: 'state_id',
      header: t('pages.registered-customers.form.pj.provider_reference.state_id'),
      cell: ({ row }) => {
        const mainProvider = row.original as MainProviderFormData;
        return cache.getCreditOptions('state').find(state => state.value === mainProvider.state_id)?.label;
      },
    },
    {
      accessorKey: 'city_id',
      header: t('pages.registered-customers.form.pj.provider_reference.city_id'),
      cell: ({ row }) => {
        const mainProvider = row.original as MainProviderFormData;
        return cache.getCityByStateOptions(mainProvider.state_id).find(city => city.value === mainProvider.city_id)
          ?.label;
      },
    },
    {
      accessorKey: 'phone_number',
      header: t('pages.registered-customers.form.pj.provider_reference.phone_number'),
      cell: ({ row }) => {
        const mainProvider = row.original as MainProviderFormData;
        return mainProvider.phone_number?.replaceAll(/\D/g, '')?.length === 11
          ? Formatter.cellphoneNumber(mainProvider.phone_number)
          : Formatter.phoneNumber(mainProvider.phone_number);
      },
    },
    {
      accessorKey: 'contact_name',
      header: t('pages.registered-customers.form.pj.bank_reference.contact_name'),
    },
    {
      id: 'actions',
      enablePinning: true,
      accessorKey: 'action',
      size: 100,
      header: t('actions'),
      cell: ({ row }) => {
        const partner = row.original as MainProviderFormData;

        return (
          <ShouldRender condition={!isViewing}>
            <IconWithTooltip
              permission="credit.request.package.destroy"
              action="destroy"
              title={t('pages.registered-customers.table.icons.remove')}
              // loading={partner.id && partnerToDelete === partner.id}
              onClick={() => handleDelete(partner)}
            />
            <Can I="credit.request.package.update">
              <IconWithTooltip
                action="edit"
                onClick={() => {
                  setMainProviderToEdit(partner);
                  setIsMainProviderModalOpen(true);
                }}
                title={t('pages.registered-customers.table.icons.edit')}
              />
            </Can>
          </ShouldRender>
        );
      },
    },
  ];

  return <DataTable columns={columns} data={mainProviders ?? []} />;
};

/* eslint-disable react/no-array-index-key */
import React from 'react';

import { Skeleton } from 'antd';

import { AreaSkeleton } from './styles';
import { PropsSkeleton } from './types';

const { Input } = Skeleton;
export const CSkeleton = ({ style, numberItems }: PropsSkeleton) => {
  const arraySkeleton = new Array(numberItems).fill(null).map((item: any, key: any) => ({
    height: style.height,
    borderRadius: style.borderRadius,
    margin: style.margin,
    minWidth: style.minWidth ? style.minWidth[key] : 'auto',
  }));
  return (
    <>
      {arraySkeleton.map((item: any, key: any) => (
        <AreaSkeleton key={key} style={{ margin: item.margin, width: '100%', flex: '2', minWidth: item.minWidth }}>
          <Input
            active
            style={{
              width: '100%',
              height: item.height,
              borderRadius: item.borderRadius,
              minWidth: item.minWidth,
            }}
          />
        </AreaSkeleton>
      ))}
    </>
  );
};

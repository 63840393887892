import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding-top: 16px;

  h2 {
    margin-top: 8px;
    width: 100%;
		margin-bottom: 0;
  }
}`;

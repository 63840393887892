import React from 'react';

import { FormItem, Input } from 'components';

export const ContractIdInput = () => {
  return (
    <>
      <FormItem name="contract_id" hidden>
        <Input disabled />
      </FormItem>
    </>
  );
};

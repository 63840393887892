import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  AttachmentInput,
  AttachmentInputTranslation,
  InfiniteSelect,
  InfiniteSelectAsync,
} from '@agrodatabr/agrodataui';
import { Col, DatePicker, Descriptions, Form, Select as AntSelect, message } from 'antd';
import ptBR from 'antd/es/date-picker/locale/pt_BR';
import { useForm } from 'antd/lib/form/Form';
import TextArea from 'antd/lib/input/TextArea';
import PermissionedComponent from 'components/PermissionedComponent';
import { PermissionedField } from 'components/PermissionedField';
import { Can, useAbility } from 'hooks/ability';
import moment, { Moment } from 'moment';

import { Button, FormItem, Input, Row, Spin, Breadcrumb } from '../../../../components';
import appConfigs from '../../../../configs/app';
import { useCpr } from '../../../../hooks/fetch/useCpr';
import { useRegistration } from '../../../../hooks/fetch/useRegistration';
import documentApi from '../../../../services/api/document';
import { useFormController } from '../controllers/useFormController';
import { SFormButtons, SFormContainer } from '../styles';
import { SurveyRequestFormProps } from './types';

export const SurveyRequestFormPage = () => <SurveyRequestForm />;

export const SurveyRequestForm = ({ cprId = '', registrationId = '', modal = undefined }: SurveyRequestFormProps) => {
  const [form] = useForm();
  const { t } = useTranslation();
  const [attachments, setAttachments] = useState<any[]>([]);
  const { getById: getCprById, isFetching: isFetchingCpr } = useCpr();
  const { get: getRegistrationById, isFetching: isFetchingRegistration } = useRegistration();
  const ability = useAbility();

  const {
    isLoading,
    isLoadingButton,
    isDisabledButton,
    isFetchingRegistrations,
    isFetchingOperators,
    getOperators,
    loadSurveyStages,
    isFetchingCprs,
    surveyTypes,
    handleRegister,
    handleEdit,
    isEditing,
    previousSurveyStageOption,
    handleChangeCpr,
    handleCancel,
    getCprsOptions,
    getRegistrationsOptions,
    selectedCpr,
    setSelectedCpr,
    setCurrentCpr,
    setCurrentRegistration,
    currentCpr,
    currentRegistration,
    handleChangeRegistration,
    selectedRegistration,
    canEdit,
    currentSurveyStage,
    currentOperator,
  } = useFormController({ form, setAttachments, modal });

  const attachmentInputTranslation = {
    destroyIconText: t('pages.monitoring.surveyrequest.form.attachments.destroy.icon'),
    downloadIconText: t('pages.monitoring.surveyrequest.form.attachments.download.icon'),
    dragAndDropUploadText: t('pages.monitoring.surveyrequest.form.attachments.drag'),
    existingAttachmentsText: t('pages.monitoring.surveyrequest.form.attachments.existing'),
    onUploadError: t('pages.monitoring.surveyrequest.form.attachments.upload.error'),
    onUploadSuccess: t('pages.monitoring.surveyrequest.form.attachments.upload.success'),
  } as AttachmentInputTranslation;

  const [allowEditing, setAllowEditing] = useState(false);

  useEffect(() => {
    (async () => {
      await Promise.all([
        (async () => {
          if (cprId) setCurrentCpr(await getCprById(cprId));
        })(),
        (async () => {
          if (registrationId) setCurrentRegistration(await getRegistrationById(registrationId));
        })(),
      ]);
    })();
    setAllowEditing(canEdit());
  }, [cprId]);

  return (
    <>
      {!modal && (
        <Breadcrumb
          items={[
            { title: t('breadcrumb.monitoring') },
            { title: t('breadcrumb.surveyrequests'), to: '/monitoring/surveyrequest' },
            { title: t('breadcrumb.surveyrequest') },
          ]}
        />
      )}
      <SFormContainer>
        <Spin spinning={isLoading}>
          <h2>{t('pages.monitoring.surveyrequest.form.survey_information')}</h2>

          <h2>CPR</h2>
          <PermissionedComponent
            permission="monitoring.registration.show"
            description={t('permission.message.monitoring.registration.show')}
          >
            <Descriptions bordered title="" size="small">
              <Descriptions.Item label={t('pages.monitoring.cpr.table.header.number')}>
                {selectedCpr?.number ?? currentCpr?.number}
              </Descriptions.Item>
              <Descriptions.Item label={t('pages.monitoring.cpr.table.header.farm')}>
                {selectedCpr?.farm?.name ?? currentCpr?.farm.name}
              </Descriptions.Item>
              <Descriptions.Item label={t('pages.monitoring.cpr.table.header.warranty_area')}>
                {selectedCpr?.warranty_area ?? currentCpr?.warranty_area}
              </Descriptions.Item>
              <Descriptions.Item label={t('pages.monitoring.cpr.table.header.culture')}>
                {selectedCpr?.culture?.name ?? currentCpr?.culture?.name}
              </Descriptions.Item>
              <Descriptions.Item span={2} label={t('pages.monitoring.cpr.table.header.owner')}>
                {selectedCpr?.owner?.name ?? currentCpr?.owner?.name}
              </Descriptions.Item>
              <Descriptions.Item label={t('pages.monitoring.cpr.table.header.registration_number')}>
                {selectedRegistration?.number ?? currentRegistration?.number}
              </Descriptions.Item>
              <Descriptions.Item label={t('pages.monitoring.cpr.table.header.estimated_production')}>
                {selectedRegistration?.estimated_production ?? currentRegistration?.estimated_production}
              </Descriptions.Item>
              <Descriptions.Item label={t('pages.monitoring.cpr.table.header.total_area')}>
                {selectedRegistration?.total_area ?? currentRegistration?.total_area ?? '0'}
              </Descriptions.Item>
              <Descriptions.Item label={t('pages.monitoring.cpr.table.header.longitude')}>
                {selectedRegistration?.longitude ?? currentRegistration?.longitude ?? t('not_provided')}
              </Descriptions.Item>
              <Descriptions.Item label={t('pages.monitoring.cpr.table.header.latitude')}>
                {selectedRegistration?.latitude ?? currentRegistration?.latitude ?? t('not_provided')}
              </Descriptions.Item>
            </Descriptions>
          </PermissionedComponent>
          <br />

          {allowEditing ? (
            <Form form={form} validateTrigger={['onSubmit', 'onBlur']}>
              <FormItem name="id" style={{ display: 'none' }}>
                <Input />
              </FormItem>
              <Row gutter={[26, 26]}>
                <Col xs={24} sm={18} md={12} lg={9} xl={8} xxl={8}>
                  <PermissionedField permission="monitoring.cpr.index">
                    <FormItem
                      rules={[{ required: true }]}
                      label={t('pages.monitoring.surveyrequest.form.cpr')}
                      name="cpr_id"
                      initialValue={cprId ?? ''}
                    >
                      <InfiniteSelect
                        options={currentCpr && [{ key: currentCpr.id, value: currentCpr.id, label: currentCpr.number }]}
                        loading={isFetchingCpr || isFetchingCprs}
                        disabled={!!cprId}
                        getOptions={getCprsOptions}
                        onChange={(a: any, option: any) => {
                          handleChangeCpr(option);
                        }}
                        showSearch
                        onClear={() => setSelectedCpr(undefined)}
                      />
                    </FormItem>
                  </PermissionedField>
                </Col>
                <Col xs={24} sm={18} md={12} lg={9} xl={8} xxl={8}>
                  <FormItem
                    rules={[{ required: true }]}
                    label={t('pages.monitoring.surveyrequest.form.registration')}
                    name="registration_id"
                    initialValue={registrationId ?? ''}
                  >
                    <Can I="monitoring.registration.all">
                      <InfiniteSelect
                        loading={isFetchingRegistration || isFetchingRegistrations}
                        deps={!registrationId && [selectedCpr]}
                        disabled={registrationId && !!registrationId}
                        options={
                          currentRegistration && [
                            {
                              key: currentRegistration.id,
                              value: currentRegistration.id,
                              label: currentRegistration.number,
                            },
                          ]
                        }
                        showSearch
                        getOptions={getRegistrationsOptions}
                        onChange={(_: unknown, registration: any) => handleChangeRegistration(registration)}
                      />
                    </Can>
                  </FormItem>
                </Col>
                <Col xs={24} sm={18} md={12} lg={9} xl={8} xxl={8}>
                  <FormItem
                    rules={[{ required: true }]}
                    label={t('pages.monitoring.surveyrequest.form.type_survey')}
                    name="survey_type_id"
                  >
                    <PermissionedField permission="monitoring.survey.type.index">
                      <AntSelect options={surveyTypes} />
                    </PermissionedField>
                  </FormItem>
                </Col>
                <Col xs={24} sm={18} md={12} lg={9} xl={8} xxl={8}>
                  <PermissionedField permission="monitoring.survey.stage.index">
                    <FormItem
                      rules={[{ required: true }]}
                      label={t('pages.monitoring.survey.form.survey_stage')}
                      name="survey_stage_id"
                    >
                      <InfiniteSelectAsync
                        showSearch
                        searchRequest={loadSurveyStages}
                        initialRequest
                        options={previousSurveyStageOption ? [previousSurveyStageOption] : []}
                      />
                    </FormItem>
                  </PermissionedField>
                </Col>
                <Col xs={24} sm={18} md={12} lg={9} xl={8} xxl={8}>
                  <FormItem
                    label={t('pages.monitoring.surveyrequest.form.deadline')}
                    name="deadline"
                    rules={[{ required: true }]}
                  >
                    <DatePicker
                      locale={ptBR}
                      mode="date"
                      format={appConfigs.formatDate}
                      // reduz 1 dia porque o moment conta o dia atual como o dia passado
                      disabledDate={(current: Moment) => current && current.isBefore(moment().subtract(1, 'days'))}
                    />
                  </FormItem>
                </Col>
                <Col xs={24} sm={18} md={12} lg={9} xl={8} xxl={8}>
                  <FormItem
                    label={t('pages.monitoring.surveyrequest.form.operator')}
                    name="operator_id"
                    rules={[{ required: true }]}
                  >
                    <InfiniteSelectAsync
                      loading={isFetchingOperators}
                      initialRequest
                      searchRequest={async () => getOperators()}
                    />
                  </FormItem>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                  <FormItem label={t('pages.monitoring.surveyrequest.form.comments')} name="comments">
                    <TextArea autoSize={{ minRows: 3, maxRows: 5 }} maxLength={1000} />
                  </FormItem>
                </Col>
              </Row>

              {/* Documentos */}
              <FormItem label={t('pages.monitoring.surveyrequest.form.attachments.label')} name="documents[]">
                <AttachmentInput
                  multiple
                  translation={attachmentInputTranslation}
                  getFiles={async () => ({
                    data: { data: attachments, total: attachments.length, page: 1, per_page: 10 },
                  })}
                  handleDestroy={documentApi.delete}
                  handleDownload={documentApi.download}
                />
              </FormItem>

              <SFormButtons style={{ paddingTop: '15px' }}>
                <>
                  <Button status="secondary" htmlType="reset" onClick={handleCancel}>
                    {t('pages.monitoring.survey.buttonCancel')}
                  </Button>
                  {isEditing ? (
                    <Button
                      status="primary"
                      htmlType="submit"
                      onClick={handleEdit}
                      loading={isLoadingButton}
                      disabled={isDisabledButton}
                      permission="monitoring.surveyrequest.update"
                    >
                      {t('pages.monitoring.survey.buttonEdit')}
                    </Button>
                  ) : (
                    <Button
                      status="primary"
                      htmlType="submit"
                      onClick={handleRegister}
                      loading={isLoadingButton}
                      disabled={isDisabledButton}
                      permission="monitoring.surveyrequest.store"
                    >
                      {t('pages.monitoring.survey.buttonRegister')}
                    </Button>
                  )}
                </>
              </SFormButtons>
            </Form>
          ) : (
            <Form form={form}>
              <Row gutter={[26, 26]}>
                <Col xs={24} sm={18} md={12} lg={9} xl={8} xxl={8}>
                  <FormItem label={t('pages.monitoring.surveyrequest.form.cpr')}>
                    <Input disabled value={currentCpr?.number} />
                  </FormItem>
                </Col>
                <Col xs={24} sm={18} md={12} lg={9} xl={8} xxl={8}>
                  <FormItem label={t('pages.monitoring.surveyrequest.form.registration')}>
                    <Input disabled value={currentRegistration?.number} />
                  </FormItem>
                </Col>
                <Col xs={24} sm={18} md={12} lg={9} xl={8} xxl={8}>
                  <FormItem label={t('pages.monitoring.surveyrequest.form.type_survey')} name="survey_type_id">
                    <AntSelect disabled options={surveyTypes} />
                  </FormItem>
                </Col>
                <Col xs={24} sm={18} md={12} lg={9} xl={8} xxl={8}>
                  <FormItem label={t('pages.monitoring.survey.form.survey_stage')}>
                    <Input disabled value={currentSurveyStage?.name} />
                  </FormItem>
                </Col>
                <Col xs={24} sm={18} md={12} lg={9} xl={8} xxl={8}>
                  <FormItem label={t('pages.monitoring.surveyrequest.form.deadline')} name="deadline">
                    <DatePicker locale={ptBR} mode="date" format={appConfigs.formatDate} disabled />
                  </FormItem>
                </Col>
                <Col xs={24} sm={18} md={12} lg={9} xl={8} xxl={8}>
                  <FormItem label={t('pages.monitoring.survey.form.operator')}>
                    <Input disabled value={currentOperator?.name} />
                  </FormItem>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                  <FormItem label={t('pages.monitoring.surveyrequest.form.comments')} name="comments">
                    <TextArea autoSize={{ minRows: 3, maxRows: 5 }} maxLength={1000} disabled />
                  </FormItem>
                </Col>
              </Row>

              <FormItem label={t('pages.monitoring.surveyrequest.form.attachments.label')} name="documents[]">
                <AttachmentInput
                  disabled
                  multiple
                  translation={attachmentInputTranslation}
                  getFiles={async () => ({
                    data: { data: attachments, total: attachments.length, page: 1, per_page: 10 },
                  })}
                  handleDestroy={
                    ability.can('document.destroy', '')
                      ? documentApi.delete
                      : (_: string, _2: any) => new Promise(resolve => resolve(message.error(t('noPermission'))))
                  }
                  handleDownload={documentApi.download}
                />
              </FormItem>
            </Form>
          )}
        </Spin>
      </SFormContainer>
    </>
  );
};

import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FaPlus, FaMinus, FaSearch } from 'react-icons/fa';
import ReactQuill from 'react-quill';

import 'react-quill/dist/quill.snow.css';
import { Col, Form, Spin, DatePicker } from 'antd';
import ptBR from 'antd/es/date-picker/locale/pt_BR';
import { Store } from 'antd/lib/form/interface';
import { TableProps, TablePaginationConfig } from 'antd/lib/table';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import parse from 'html-react-parser';
import moment from 'moment';

import PaginationData from '../../../@types/data/PaginationData';
import { ResponseListData } from '../../../@types/data/ResponseData';
import DTOErrorReponse from '../../../@types/dtos/DTOErrorReponse';
import { Breadcrumb, Button, Input, ModalStatus, FormItem, Row, IconWithTooltip } from '../../../components';
import { TableData } from '../../../compositions';
import appConfigs from '../../../configs/app';
import { Can } from '../../../hooks/ability';
import { useAuth } from '../../../hooks/auth';
import { usePage } from '../../../hooks/page';
import adminApi from '../../../services/api/admin';
import { Formatter } from '../../../utils/Formatter';
import columns from './columns';
import {
  SContainer,
  SHeader,
  STitlePage,
  SFilterContainer,
  SFormContainer,
  SFormButtons,
  SReactQuill,
  SDescription,
} from './styles';
import { ChangeLogData, ChangeLogForm } from './types';

const ChangeLog: React.FC = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const { updateUserData } = useAuth();
  const { alertStatus } = usePage();

  const [loadingButton, setLoadingButton] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);

  const [data, setData] = useState([]);
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [paginationConfig, setPaginationConfig] = useState<TablePaginationConfig>({
    current: 1,
    total: 1,
    pageSize: 20,
  });
  const [visibilityForm, setVisibilityForm] = useState(false);
  const [descriptionValue, setDescriptionValue] = useState('');

  const updateData = (row: Store) => {
    let dataUpdated: Array<Store> = data;
    if (data.length > 0) {
      const rowIndex = data.findIndex((item: Store) => row.id === item.id);
      // Se encontrar o item remove e adiciona ele atualizado na mesma posição
      if (rowIndex > -1) {
        dataUpdated.splice(rowIndex, 1, row);
      } else {
        if (dataUpdated.length === paginationConfig.pageSize) {
          // Se não encontrar, remove o último (por conta da paginação) e
          // adiciona o item na primeira posição
          dataUpdated.splice(-1, 1);
        }
        dataUpdated = [row, ...dataUpdated];
      }
    } else {
      dataUpdated.push(row);
    }

    setData(dataUpdated);
  };

  const handleSuccess = (messageSuccess: string) =>
    ModalStatus({
      type: 'success',
      title: t('modal.success'),
      subTitle: messageSuccess,
    });

  const loadTableData = useCallback(
    (values: Partial<ChangeLogForm & PaginationData<ChangeLogData>>) => {
      setLoading(true);
      setLoadingButton(true);
      setDisabledButton(true);
      const dataSend = {
        params: {
          per_page: paginationConfig.pageSize,
          ...values,
        },
      };

      adminApi.changelog
        .get(dataSend)
        .then((response: ResponseListData<ChangeLogData>) => {
          const result = response.data.data;
          setData(result.data);
          setPaginationConfig(paginationConfigState => ({
            ...paginationConfigState,
            current: result.current_page,
            total: result.total,
          }));
        })
        .catch((err: DTOErrorReponse) => alertStatus(err, 'error'))
        .finally(() => {
          setLoading(false);
          setLoadingButton(false);
          setDisabledButton(false);
        });
    },
    [paginationConfig.pageSize, alertStatus],
  );

  const handleDisplayForm = () => {
    setVisibilityForm(!visibilityForm);
    setEditing(false);
    setDisabledButton(false);
    setLoadingButton(false);
    setDescriptionValue('');
    form.resetFields();
  };

  const handleHideForm = () => {
    setVisibilityForm(false);
  };

  const handleRemoveChange = async (record: any) => {
    ModalStatus({
      type: 'delete',
      title: t('pages.admin.changelog.delete.title'),
      subTitle: t('pages.admin.changelog.delete.subtitle'),
      cancelText: t('pages.admin.changelog.delete.cancel'),
      okText: t('pages.admin.changelog.delete.confirm'),
      onOk: () => {
        adminApi.changelog
          .delete(record.id, {
            params: {},
          })
          .then(() => {
            handleSuccess(t('pages.admin.changelog.delete.deleted'));
            handleTableChange({ current: 1 }, { active: [1] }, {}, null);
          })
          .catch((err: DTOErrorReponse) => alertStatus(err, 'error'));
      },
    });
  };

  const handleRegister = () => {
    setLoading(true);
    setLoadingButton(true);
    setDisabledButton(true);
    form
      .validateFields()
      .then(() => {
        let formValues = form.getFieldsValue();
        const startDate = formValues.date_range[0];
        const endDate = formValues.date_range[1];

        formValues = { ...formValues, start_date: startDate, end_date: endDate, description: descriptionValue };
        adminApi.changelog
          .store({
            ...formValues,
          })
          .then((response: ResponseListData<ChangeLogData>) => {
            const { data: changelog } = response.data;
            handleSuccess(t('pages.admin.changelog.form.messages.registerSuccess'));
            updateData(changelog);
            handleHideForm();
            setLoading(false);
            setLoadingButton(false);
            setDisabledButton(false);
            form.resetFields();
            updateUserData();
          })
          .catch((err: DTOErrorReponse) => {
            alertStatus(err, 'error');
            setLoading(false);
            setLoadingButton(false);
            setDisabledButton(false);
          });
      })
      .catch(() => {
        setLoading(false);
        setLoadingButton(false);
        setDisabledButton(false);
      });
  };

  const handleEdit = () => {
    setLoading(true);
    setLoadingButton(true);
    setDisabledButton(true);
    form
      .validateFields()
      .then(() => {
        let formValues = form.getFieldsValue();
        const startDate = formValues.date_range[0];
        const endDate = formValues.date_range[1];

        formValues = { ...formValues, start_date: startDate, end_date: endDate, description: descriptionValue };

        adminApi.changelog
          .update(formValues.id, {
            ...formValues,
          })
          .then((response: ResponseListData<ChangeLogData>) => {
            const { data: changelog } = response.data;
            handleSuccess(t('pages.admin.changelog.form.messages.editSuccess'));
            updateData(changelog);
            handleHideForm();
            setLoading(false);
            setLoadingButton(false);
            setDisabledButton(false);
            form.resetFields();
            updateUserData();
          })
          .catch(e => {
            alertStatus(e, 'error');
            setLoading(false);
            setLoadingButton(false);
            setDisabledButton(false);
          });
      })
      .catch((err: DTOErrorReponse) => {
        alertStatus(err, 'error');
        setLoading(false);
        setLoadingButton(false);
        setDisabledButton(false);
      });
  };

  // Table DATA Config: BEGIN
  const getActions = () => ({
    render: (active: number, record: ChangeLogData) => (
      <>
        <Can I="admin.changelog.destroy">
          <IconWithTooltip
            action="destroy"
            title={t('pages.admin.changelog.table.icons.remove')}
            onClick={() => handleRemoveChange(record)}
          />
        </Can>
        <Can I="admin.changelog.update">
          <IconWithTooltip
            action="edit"
            title={t('pages.admin.changelog.table.icons.edit')}
            onClick={() => {
              form.setFieldsValue({
                ...record,
                date_range: [Formatter.date(record.start_date, true), Formatter.date(record.end_date, true)],
              });
              setDescriptionValue(record.description);

              form.scrollToField('version', {
                scrollMode: 'always',
                block: 'start',
                behavior: actions =>
                  actions.forEach(({ el, top }) => {
                    el.scrollTop = top - 280;
                  }),
              });

              setVisibilityForm(true);
              setEditing(true);
              setLoading(false);
              setLoadingButton(false);
              setDisabledButton(false);
            }}
          />
        </Can>
      </>
    ),
  });

  const [searchForm] = Form.useForm();
  const tableConfig = {
    search: '',
    filtered: {},
    sorter: {},
    getActions,
  };

  const [tableDataConfig, setTableDataConfig] = useState(tableConfig);

  const handleFastSearch = () => {
    const value = searchForm.getFieldsValue();
    setTableDataConfig(tableDataConfigState => ({
      ...tableDataConfigState,
      search: value.search,
    }));
    loadTableData({
      search: value.search,
    });
  };

  const tableDataClearAllFilters = () => {
    searchForm.resetFields();
    setTableDataConfig(tableDataConfigState => ({
      ...tableDataConfigState,
      filtered: {},
      search: '',
      sorter: {},
    }));
    loadTableData({ page: 1 });
  };

  const handleTableChange: TableProps<any>['onChange'] = useCallback(
    (
      pagination: TablePaginationConfig,
      filters: Record<string, FilterValue | null>,
      sorter: SorterResult<any> | SorterResult<any>[],
    ) => {
      setLoading(true);
      setTableDataConfig(tableDataConfigState => ({
        ...tableDataConfigState,
        filtered: filters,
        sorter,
      }));

      let direction = '';
      const search: any = {};
      let sort = '';

      if (sorter) {
        const { field, order } = sorter as SorterResult<any>;

        if (order === 'ascend') direction = 'asc';
        if (order === 'descend') direction = 'desc';
        sort = field?.toString();
      }

      if (filters) {
        const dataFilter: any = filters;

        if (dataFilter) {
          Object.keys(dataFilter).forEach((key: string) => {
            if (dataFilter[key] !== null && dataFilter[key] !== undefined && dataFilter[key][0]) {
              search[key] = dataFilter[key][0].toString();
            } else if (dataFilter[key] !== null && dataFilter[key] !== undefined) {
              search[key] = dataFilter[key].toString();
            } else {
              search[key] = '';
            }
          });
        }
      }

      setPaginationConfig(paginationConfigState => ({
        ...paginationConfigState,
        pageSize: pagination.pageSize,
        current: pagination.current,
      }));

      loadTableData({
        page: pagination.current,
        per_page: pagination.pageSize,
        sort,
        direction,
        ...search,
      });
    },
    [loadTableData],
  );
  // Table DATA Config: END

  useEffect(() => {
    const filters: any = {};

    handleTableChange({ current: 1, pageSize: paginationConfig.pageSize }, filters, {}, null);
  }, [handleTableChange, paginationConfig.pageSize]);

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
      ['link'],
      ['clean'],
    ],
  };

  const formats = ['header', 'bold', 'italic', 'underline', 'strike', 'blockquote', 'list', 'bullet', 'indent', 'link'];

  return (
    <>
      <Breadcrumb
        items={[{ title: t('breadcrumb.admin') }, { title: t('breadcrumb.changelog'), to: '/admin/changelog' }]}
      />

      <SContainer>
        <SHeader>
          <STitlePage>{t('pages.admin.changelog.page_title')}</STitlePage>

          <Can I="admin.changelog.store" a="">
            <Button status="primary" icon={visibilityForm ? <FaMinus /> : <FaPlus />} onClick={handleDisplayForm}>
              {t('pages.admin.changelog.buttonAdd')}
            </Button>
          </Can>
        </SHeader>

        <SFormContainer visible={visibilityForm}>
          <Spin spinning={loading}>
            <h2>{t('pages.admin.changelog.form.title')}</h2>
            <Form form={form}>
              <FormItem name="id" style={{ display: 'none' }}>
                <Input />
              </FormItem>
              <Row>
                <Col span={24}>
                  <FormItem
                    style={{ paddingRight: '10px' }}
                    label={t('pages.admin.changelog.form.version')}
                    name="version"
                    rules={[{ required: true }]}
                  >
                    <Input type="text" />
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem
                    label={t('pages.admin.changelog.form.sprint_date')}
                    name="date_range"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <DatePicker.RangePicker locale={ptBR} format={appConfigs.formatDate} />
                  </FormItem>
                </Col>
                <Col span={24}>
                  <SReactQuill>
                    <FormItem label={t('pages.admin.changelog.form.description')}>
                      <ReactQuill
                        value={descriptionValue}
                        onChange={setDescriptionValue}
                        theme="snow"
                        modules={modules}
                        formats={formats}
                      />
                    </FormItem>
                  </SReactQuill>
                </Col>
              </Row>
              <SFormButtons>
                <Button status="secondary" htmlType="reset" onClick={handleHideForm} disabled={disabledButton}>
                  {t('pages.admin.changelog.form.cancelButton')}
                </Button>

                <Can I="admin.changelog.update" a="">
                  {editing && (
                    <Button
                      status="primary"
                      htmlType="submit"
                      onClick={handleEdit}
                      loading={loadingButton}
                      disabled={disabledButton}
                    >
                      {t('pages.admin.changelog.form.editButton')}
                    </Button>
                  )}
                </Can>
                <Can I="admin.changelog.store" a="">
                  {!editing && (
                    <Button
                      status="primary"
                      htmlType="submit"
                      onClick={handleRegister}
                      loading={loadingButton}
                      disabled={disabledButton}
                    >
                      {t('pages.admin.changelog.form.registerButton')}
                    </Button>
                  )}
                </Can>
              </SFormButtons>
            </Form>
          </Spin>
        </SFormContainer>

        <SFilterContainer>
          <Form
            form={searchForm}
            name="filter-changelog"
            className="form-secondary form-filters grid-filters"
            onFinish={handleFastSearch}
          >
            <div className="filter-search">
              <FormItem name="search" label={t('pages.admin.changelog.filter.search')}>
                <Input />
              </FormItem>
            </div>
            <div className="filter-button">
              <FormItem label="" className="form-item-without-label">
                <Button status="primary" text="uppercase" htmlType="submit">
                  <FaSearch /> {t('pages.admin.changelog.filter.searchButton')}
                </Button>
              </FormItem>
            </div>
            <div className="filter-clear">
              <Button status="secondary" size="middle" onClick={tableDataClearAllFilters}>
                {t('pages.admin.changelog.filter.clearFiltersButton')}
              </Button>
            </div>
          </Form>
        </SFilterContainer>

        <TableData
          rowKey="id"
          columns={columns.map((column: any) => {
            let columnRender: any = {
              ...column,
            };

            if (column.dataIndex === 'description') {
              columnRender = {
                ...columnRender,
                render: (value: any) => value && <SDescription>{parse(value)}</SDescription>,
              };
            }

            if (column.dataIndex === 'start_date') {
              columnRender = {
                ...columnRender,
                render: (value: any) => value && moment(value).format(appConfigs.formatDate),
              };
            }

            if (column.dataIndex === 'end_date') {
              columnRender = {
                ...columnRender,
                render: (value: any) => value && moment(value).format(appConfigs.formatDate),
              };
            }

            return columnRender;
          })}
          dataSource={data}
          loading={loading}
          onChange={handleTableChange}
          pagination={paginationConfig}
          tableConfig={tableDataConfig}
        />
      </SContainer>
    </>
  );
};

export default ChangeLog;

const columns = [
  {
    title: 'pages.monitoring.farm.form.typeContact',
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: 'pages.monitoring.farm.form.contact',
    dataIndex: 'value',
    key: 'value',
  },
  {
    title: 'pages.monitoring.farm.form.description',
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: 'pages.monitoring.farm.table.header.actions',
    key: 'action',
    dataIndex: 'action',
  },
];

export default columns;

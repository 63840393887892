import React from 'react';
import { useTranslation } from 'react-i18next';

import { Form, Input, Modal } from 'antd';
import { FormItem } from 'components';
import { useContractContext } from 'pages/Contracts/context/ContractContext';

interface Props {
  isOpen: boolean;
  close: () => void;
}

export const ReissueModal = ({ isOpen, close }: Props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { reissueDraft } = useContractContext();

  const handleSubmit = () => {
    form.validateFields().then(values => {
      reissueDraft(values.justification);
      form.resetFields();
      close();
    });
  };

  return (
    <Modal visible={isOpen} onCancel={close} onOk={handleSubmit}>
      <Form form={form}>
        <FormItem
          name="justification"
          rules={[{ required: true }]}
          label={t('pages.edocuments.emission.reissueModal.observation')}
        >
          <Input.TextArea maxLength={255} />
        </FormItem>
      </Form>
    </Modal>
  );
};

import { useCallback } from 'react';

import { divisions } from 'configs/divisions';
import { useCache } from 'hooks/cache';

import { Divisions } from '../store/slices/creditRequestSlices/divisionSlice';
import { useCreditRequestStore } from '../store/useCreditRequestStore';

export const useLoadSubdivisions = () => {
  const cache = useCache();

  const setSubDivisionOptions = useCreditRequestStore(state => state.setSubDivisionOptions);

  const loadSubDivisions = useCallback(
    (divisionId: Divisions) => {
      let listOfSubDivisions = cache.getCreditOptions('sub_division');
      if (divisions[divisionId]) {
        listOfSubDivisions = cache
          .getCreditOptions('sub_division')
          .filter(s => divisions[divisionId].includes(s.value));
      }
      setSubDivisionOptions(listOfSubDivisions);
    },
    // eslint-disable-next-line
    [cache],
  );

  return { loadSubDivisions };
};

import { OPERATION_PREFIN_ID } from '../../../configs/constants';
import { useCreditRequest } from '../../../hooks/fetch/useCreditRequest';
import { useUser } from '../../../hooks/fetch/useUsers';
import { CreditRequestRequestFormProps } from './types';

export const useFormController = ({ form }: CreditRequestRequestFormProps) => {
  const { getOptions } = useCreditRequest();
  const { getOptionsUsers } = useUser();

  const loadCreditRequestsByPrefin = (value: string, page = 1) =>
    getOptions({ params: { search: value, page, operation: OPERATION_PREFIN_ID } });

  const loadUsers = (value: string, page = 1) =>
    getOptionsUsers({ params: { search: value, page, sort: 'name', direction: 'asc' } });

  return {
    form,
    loadCreditRequestsByPrefin,
    loadUsers,
  };
};

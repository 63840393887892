import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaLock } from 'react-icons/fa';

import { useAbility } from 'hooks/ability';
import { cn } from 'lib/utils';

type Props = {
  permission?: string | string[];
  needAllPermissions?: boolean;
  children: React.ReactNode;
  className?: string;
  description?: string;
};

const PermissionedComponent = ({ permission, children, needAllPermissions, className, description }: Props) => {
  const ability = useAbility();
  const { t } = useTranslation();

  const hasPermission = Array.isArray(permission)
    ? needAllPermissions
      ? permission.every(p => ability.can(p, ''))
      : permission.some(p => ability.can(p, ''))
    : ability.can(permission, '');

  if (!permission || !permission.length || hasPermission) {
    return <>{children}</>;
  }
  return (
    <div
      className={cn(
        'bg-gray-400/20 relative rounded-md w-full py-14 text-lg overflow-hidden overflow-y-auto',
        className,
      )}
    >
      <span className="flex gap-1 items-center text-primary flex-col">
        <div className="flex gap-2 items-center">
          <FaLock />
          {t('noPermission')}
        </div>
        <span className="text-sm text-center">{description}</span>
      </span>
    </div>
  );
};

export default PermissionedComponent;

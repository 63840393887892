const columns: any = [
  {
    title: 'pages.admin.changelog.table.header.version',
    dataIndex: 'version',
    key: 'version',
    sorter: true,
    align: 'center',
    width: '150px',
  },
  {
    title: 'pages.admin.changelog.table.header.start_date',
    dataIndex: 'start_date',
    key: 'start_date',
    sorter: true,
    align: 'center',
    width: '200px',
  },
  {
    title: 'pages.admin.changelog.table.header.end_date',
    dataIndex: 'end_date',
    key: 'end_date',
    sorter: true,
    align: 'center',
    width: '200px',
  },
  {
    title: 'pages.admin.changelog.table.header.description',
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: 'pages.admin.changelog.table.header.action',
    key: 'action',
    dataIndex: 'action',
    align: 'center',
    width: '180px',
    fixed: 'right',
  },
];

export default columns;

import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AiFillCheckCircle } from 'react-icons/ai';
import { FaHandPaper } from 'react-icons/fa';

import { RadioChangeEvent } from 'antd/lib/radio';
import { Button } from 'components';
import { Can } from 'hooks/ability';
import { useModal } from 'hooks/modals/useModal';
import { useContractContext } from 'pages/Contracts/context/ContractContext';
import { useTheme } from 'styled-components';

import { DraftFormatRadio } from '../../../../components/DraftFormatRadio';
import { DraftUploadEmission } from '../../../../components/DraftUploadEmission';
import { Tab } from '../../../../components/Tab';
import { DraftModelContext } from '../../../../context/DraftModelContext';
import { DraftModelEmission } from './DraftModelEmission';
import { ForceManualEmissionModal } from './ForceManualEmissionModal';
import { formats } from './formats';
import { DraftEmissionSelectedOptions, RadioGroup, Title, Body } from './styles';

export const DraftEmission = () => {
  const { selectedContract } = useContractContext();
  const theme = useTheme();
  const {
    selectedDraftModel,
    isModelConfirmed,
    unconfirmModel,
    setSelectedDraftModelId,
    selectedEmissionFormatName,
    setSelectedEmissionFormatName,
    isFormatSelected,
    isIssued,
  } = useContext(DraftModelContext);
  const { t } = useTranslation();

  const {
    open: openForceManualEmissionModal,
    isOpen: isOpenForceManualEmissionModal,
    close: closeForceManualEmissionModal,
  } = useModal();

  const shouldRenderRadio = (format: any) => selectedEmissionFormatName === '' || isFormatSelected(format);

  const renderRadio = (format: any) => {
    return (
      <DraftFormatRadio
        name={format.name}
        value={format.value}
        key={format.value}
        label={format.label}
        icon={format.icon}
        onChangeButtonClick={() => {
          setSelectedEmissionFormatName('');
          unconfirmModel();
          setSelectedDraftModelId('');
        }}
        showChangeButton={!isIssued}
        isSelected={isFormatSelected(format)}
      />
    );
  };

  const handleDraftModelChangeButtonClick = () => {
    unconfirmModel();
    setSelectedDraftModelId('');
  };

  return (
    <Tab overflowY="auto">
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'start' }}>
        <Title>
          {isIssued && <AiFillCheckCircle color={theme.colors.success} />}
          {t('pages.edocuments.emission.draftEmission.title')}
        </Title>
        <span>
          <Can I="contract.uploadminuta">
            <Button
              status="primary"
              onClick={openForceManualEmissionModal}
              size="small"
              title={t('pages.edocuments.emission.draftEmission.forceManualEmission')}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <span style={{ display: 'flex' }}>
                <FaHandPaper />
              </span>
            </Button>
          </Can>
        </span>
      </div>

      <ForceManualEmissionModal isOpen={isOpenForceManualEmissionModal} close={closeForceManualEmissionModal} />

      <p style={{ fontSize: '12px', marginBottom: '46px' }}>
        {t('pages.edocuments.emission.draftEmission.description')}
      </p>

      <Body>
        <DraftEmissionSelectedOptions>
          <RadioGroup
            onChange={(evt: RadioChangeEvent) => setSelectedEmissionFormatName(evt.target.value)}
            value={selectedEmissionFormatName}
          >
            {Array.from(formats)
              .filter(
                ([type]) =>
                  (type === 'Upload' && selectedContract.type?.can_upload) ||
                  (type === 'Model' && selectedContract.type?.can_template),
              )
              .map(([, format]) => shouldRenderRadio(format) && renderRadio(format))}
          </RadioGroup>

          {selectedDraftModel && isModelConfirmed && (
            <DraftFormatRadio
              name={selectedDraftModel.number}
              value={selectedDraftModel.id}
              key={selectedDraftModel.id}
              label={selectedDraftModel.number}
              onChangeButtonClick={handleDraftModelChangeButtonClick}
              checked
              isSelected
              showChangeButton={!isIssued}
              onShowButtonClick={() => {
                window.open(`/edocuments/templates?templateId=${selectedDraftModel?.id}`, '_blank');
              }}
            />
          )}
        </DraftEmissionSelectedOptions>

        {selectedEmissionFormatName === 'model' && (
          <div>
            <DraftModelEmission />
          </div>
        )}
        {selectedEmissionFormatName === 'upload' && <DraftUploadEmission />}
      </Body>
    </Tab>
  );
};

import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { RegisteredCustomerFormData } from 'pages/RegisteredCustomers/DetailView/registered-customer.form';
import api from 'services/api/api';
import exceptionHandler from 'services/exceptions';

type Props = {
  personType: 'legal' | 'physical';
} & UseMutationOptions;

export const useStoreRegisteredCustomerMutation = ({ personType }: Props) => {
  return useMutation({
    mutationFn: async (data: RegisteredCustomerFormData) => {
      const response = await api.post(personType === 'legal' ? 'person/legal' : 'person/physical', data);
      return response.data.data;
    },
    mutationKey: ['storeRegisteredCustomer'],
    onError: err => {
      exceptionHandler(err);
    },
  });
};

import React from 'react';
import { useTranslation } from 'react-i18next';

import { TableData, TablePaginationConfig, TableTranslation } from '@agrodatabr/agrodataui';
import { useSerasaCounterparty } from 'hooks/fetch/useSerasaCounterparty';
import { Formatter } from 'utils/Formatter';

import { columns } from './columns';
import { Container } from './styles';

interface Props {
  counterpartyId: string;
}

export const LogsTable = ({ counterpartyId }: Props) => {
  const { t } = useTranslation();
  const { getLogs } = useSerasaCounterparty();

  const handleSearchLogs = async (pagination: TablePaginationConfig) => {
    const response = await getLogs(counterpartyId, pagination);
    return response;
  };

  const handleCustomColumns = (column: any) => {
    column = {
      ...column,
      title: t(column.title),
    };

    if (column.key === 'updated_at') {
      column = {
        ...column,
        render: (updatedAt: string) => {
          return <span>{Formatter.date(updatedAt)}</span>;
        },
      };
    }

    return column;
  };

  return (
    <Container>
      <TableData
        searchRequest={handleSearchLogs}
        columns={columns}
        width="100%"
        customColumns={handleCustomColumns}
        pagination={{ position: ['bottomCenter'] }}
        translation={{ pagination: { totalOfRegisters: t('table.totalOfRegisters') } } as TableTranslation}
      />
    </Container>
  );
};

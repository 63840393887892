import { FixedType } from 'rc-table/lib/interface';

export const columns = [
  {
    title: 'pages.edocuments.contracttypes.table.name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'pages.edocuments.contracttypes.table.isParent',
    dataIndex: 'is_parent',
    key: 'is_parent',
  },
  {
    title: 'pages.edocuments.contracttypes.table.isFixation',
    dataIndex: 'is_fixation',
    key: 'is_fixation',
  },
  {
    title: 'pages.edocuments.contracttypes.table.parent',
    dataIndex: 'parents',
    key: 'parents',
  },
  {
    title: 'pages.edocuments.contracttypes.table.actions',
    dataIndex: 'action',
    key: 'action',
    width: '100px',
    fixed: 'right' as FixedType,
  },
];

const columns: any = [
  {
    title: 'pages.admin.permission.table.header.name',
    dataIndex: 'name',
    key: 'name',
    sorter: true,
  },
  {
    title: 'pages.admin.permission.table.header.action',
    key: 'action',
    dataIndex: 'action',
    align: 'center',
    width: '150px',
    fixed: 'right',
  },
];

export default columns;

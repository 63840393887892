import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaUpload } from 'react-icons/fa';
import { useParams } from 'react-router';

import { Col, Form, Pagination, Row, Spin, Upload, message } from 'antd';
import { Button, FormItem, IconWithTooltip, Input, Select } from 'components';
import { FilePreviewer, checkIfFilePreviewerSupportsExtension } from 'components/FilePreviewer';
import {
  CREDIT_REQUEST_TEAM_STATUS_EDITABLE_RULE_PREFIN_SUGAR_MILLS,
  CREDIT_REQUEST_TEAM_STATUS_EDITABLE_RULE_PREPAY,
  DOCUMENT_TYPE_INSS,
  DOCUMENT_TYPE_SINTEGRA,
  OPERATION_DELIVERED_PRODUCT_ADVANCE_ID,
  OPERATION_PREFIN_SUGAR_MILLS_ID,
  OPERATION_PREPAY_ID,
} from 'configs/constants';
import { useCreditRequestContext } from 'context/CreditRequestContext';
import { useCache } from 'hooks/cache';
import { useCreditRequest } from 'hooks/fetch/useCreditRequest';
import { useDownload } from 'hooks/helpers/useDownload';
import { usePage } from 'hooks/page';
import { useLoadCreditRequestDocuments } from 'pages/CreditRequest/hooks/useLoadCreditRequestDocuments';
import { useCreditRequestStore } from 'pages/CreditRequest/store/useCreditRequestStore';
import { PageParams } from 'pages/CreditRequest/types';
import document from 'services/api/document';
import { downloadHandler } from 'services/download';
import { downloadDocument, getOriginalName } from 'services/files';
import { useTheme } from 'styled-components';
import { Formatter } from 'utils/Formatter';

import { FileContainer } from 'pages/CreditRequest/components/templates/DetailView/styles';
// eslint-disable-next-line import-helpers/order-imports
import type DTOErrorReponse from '../../../../../@types/dtos/DTOErrorReponse';

export const DocumentsList = () => {
  const params = useParams<PageParams>();
  const { id: idParam } = params;

  const theme = useTheme();
  const { t } = useTranslation();
  const cache = useCache();
  const { downloadMultiple } = useDownload();
  const { downloadNotDownloadedFiles } = useCreditRequest();
  const { alertStatus } = usePage();
  const { loadDocuments } = useLoadCreditRequestDocuments();

  const { fileToPreview, setFileToPreview, handlePreviewFile, teamStatusCurrent, creditForm } =
    useCreditRequestContext();

  const selectedOperationId = useCreditRequestStore(state => state.selectedOperationId);
  const documentList = useCreditRequestStore(state => state.documentList);
  const setDocumentList = useCreditRequestStore(state => state.setDocumentList);
  const documentLoading = useCreditRequestStore(state => state.documentLoading);
  const setDocumentLoading = useCreditRequestStore(state => state.setDocumentLoading);
  const documentTotal = useCreditRequestStore(state => state.documentTotal);
  const pageStatus = useCreditRequestStore(state => state.pageStatus);
  const creditRequestId = useCreditRequestStore(state => state.creditRequestId);

  const [isLoadingDocuments, setIsLoadingDocuments] = useState(false);

  async function uploadFiles(info: any) {
    const file: any = {
      file: info.file,
      name: getOriginalName(info.file.name, true),
      type_id: null,
    };

    setDocumentList([...documentList, file]);
  }

  const fileTypes = useMemo(() => {
    if (selectedOperationId === OPERATION_PREPAY_ID) {
      return cache.getCreditOptions('document_type_credit');
    }

    if (selectedOperationId === OPERATION_DELIVERED_PRODUCT_ADVANCE_ID) {
      return cache
        .getCreditOptions('document_type_credit')
        .filter((x: any) => x.slug === 'contract' || x.slug === 'other');
    }

    return cache
      .getCreditOptions('document_type_credit')
      .filter(
        (x: any) =>
          (x.slug !== 'proof_payroll_inss' || x.id === DOCUMENT_TYPE_INSS) &&
          (x.slug !== 'sintegra_document' || x.id === DOCUMENT_TYPE_SINTEGRA),
      );
  }, [cache, selectedOperationId]);

  const handleDownloadAllDocuments = async () => {
    message.loading(t('messages.downloadingAllFiles'), 3);
    await downloadMultiple(documentList.map(doc => doc.id));
  };

  const handleDownloadNotDownloadedDocuments = async () => {
    message.loading(t('messages.downloadingNotDownloadedFiles'), 3);
    await downloadNotDownloadedFiles(idParam);
    setDocumentList(documentList.map(doc => ({ ...doc, downloads_user: 1 })));
  };

  const handleChangeDocumentList = async (page: number, pageSize: number) => {
    try {
      setIsLoadingDocuments(true);
      const response = await loadDocuments({ params: { page, per_page: pageSize } });
      const newDocuments = response.map((doc: any) => ({
        ...doc,
        created_at: Formatter.datetime(doc.created_at, false),
      }));
      setDocumentList(newDocuments);
      creditForm.setFieldsValue({
        documents: newDocuments,
      });
    } catch (err) {
      alertStatus(err as string, 'error');
    } finally {
      setIsLoadingDocuments(false);
    }
  };

  return (
    <>
      {/* Documentos */}
      <div className="group-form">
        <h2>{t('pages.credit-request.form.titles.documents')}</h2>
        <>
          {!pageStatus.viewing ||
          (selectedOperationId === OPERATION_PREPAY_ID &&
            CREDIT_REQUEST_TEAM_STATUS_EDITABLE_RULE_PREPAY.includes(teamStatusCurrent?.key)) ||
          (selectedOperationId === OPERATION_PREFIN_SUGAR_MILLS_ID &&
            CREDIT_REQUEST_TEAM_STATUS_EDITABLE_RULE_PREFIN_SUGAR_MILLS.includes(teamStatusCurrent?.key)) ? (
            // eslint-disable-next-line react/jsx-indent
            <div className="group-header-actions">
              <FileContainer>
                <Upload multiple showUploadList={false} customRequest={async (info: any) => uploadFiles(info)}>
                  <Button status="primary-outline" icon={<FaUpload />}>
                    {t('pages.credit-request.form.documents.select_files')}
                  </Button>
                </Upload>
              </FileContainer>
            </div>
          ) : null}
          <FormItem name="documents" hidden>
            <Input />
          </FormItem>

          <Row gutter={[26, 26]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <Spin spinning={isLoadingDocuments}>
                <Form.List name="documents">
                  {fields => (
                    <>
                      {fields.map((field: any) => (
                        <Row gutter={[26, 26]} key={`field-list-item-${field.key}-${documentList[field.name]?.name}`}>
                          <FormItem name={[field.name, 'id']} hidden>
                            <Input />
                          </FormItem>
                          <FormItem name={[field.name, 'file']} hidden>
                            <Input />
                          </FormItem>

                          <Col
                            xs={24}
                            sm={24}
                            md={24}
                            lg={pageStatus.viewing || pageStatus.editing ? 6 : 12}
                            xl={pageStatus.viewing || pageStatus.editing ? 6 : 12}
                            xxl={pageStatus.viewing || pageStatus.editing ? 6 : 12}
                          >
                            {documentList[field.name] &&
                            documentList[field.name].id &&
                            documentList[field.name].name ? (
                              <FormItem
                                label={t('pages.credit-request.form.documents.name')}
                                name={[field.name, 'name']}
                              >
                                <Input disabled />
                              </FormItem>
                            ) : (
                              <FormItem
                                label={t('pages.credit-request.form.documents.name')}
                                name={[field.name, 'name']}
                                extra={t('pages.credit-request.form.documents.the_file_was_not_send')}
                                validateStatus="warning"
                              >
                                <Input readOnly />
                              </FormItem>
                            )}
                          </Col>

                          <Col
                            xs={24}
                            sm={24}
                            md={24}
                            lg={pageStatus.viewing || pageStatus.editing ? 6 : 8}
                            xl={pageStatus.viewing || pageStatus.editing ? 6 : 8}
                            xxl={pageStatus.viewing || pageStatus.editing ? 6 : 8}
                          >
                            <FormItem
                              label={t('pages.credit-request.form.documents.type_id')}
                              name={[field.name, 'type_id']}
                              rules={[{ required: true }]}
                            >
                              <Select
                                options={fileTypes}
                                onChange={(value: any, option: any) => {
                                  const newDocumentList = documentList;
                                  newDocumentList[field.name].type_id = option?.value;
                                  setDocumentList(newDocumentList);
                                }}
                                disabled={
                                  pageStatus.viewing &&
                                  !(
                                    selectedOperationId === OPERATION_PREPAY_ID &&
                                    CREDIT_REQUEST_TEAM_STATUS_EDITABLE_RULE_PREPAY.includes(teamStatusCurrent?.key)
                                  )
                                }
                              />
                            </FormItem>
                          </Col>
                          {(pageStatus.viewing || pageStatus.editing) && (
                            <Col xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}>
                              <FormItem
                                label={t('pages.credit-request.form.documents.date')}
                                name={[field.name, 'created_at']}
                              >
                                <Input readOnly />
                              </FormItem>
                            </Col>
                          )}

                          <Col xs={24} sm={24} md={12} lg={2} xl={2} xxl={2}>
                            <div className="action-buttons">
                              <IconWithTooltip
                                action="download"
                                title={
                                  documentList[field.name]?.downloads_user
                                    ? t('pages.credit-request.table.icons.hasDownloaded')
                                    : t('pages.credit-request.table.icons.download')
                                }
                                loading={
                                  documentList[field.name]?.id && documentLoading === documentList[field.name].id
                                }
                                color={
                                  documentList[field.name]?.downloads_user ? theme.colors.success : theme.colors.text
                                }
                                onClick={async () => {
                                  if (
                                    (pageStatus.editing || pageStatus.viewing) &&
                                    documentList[field.name] &&
                                    documentList[field.name].id
                                  ) {
                                    await document
                                      .download(documentList[field.name].id)
                                      .then((response: any) => {
                                        downloadHandler(response);
                                        if (!documentList[field.name]?.downloads_user) {
                                          const newDocumentList = [...documentList];
                                          newDocumentList[field.name].downloads_user = 1;
                                          newDocumentList[field.name].created_at = Formatter.datetime(
                                            newDocumentList[field.name].created_at,
                                          );
                                          newDocumentList[field.name].updated_at = Formatter.datetime(
                                            newDocumentList[field.name].updated_at,
                                          );
                                          setDocumentList(newDocumentList);
                                        }
                                      })
                                      .catch((err: DTOErrorReponse) => alertStatus(err, 'error'))
                                      .finally(() => setDocumentLoading(null));
                                  } else {
                                    downloadDocument(documentList[field.name].image, documentList[field.name].name);
                                    if (!documentList[field.name]?.downloads_user) {
                                      const newDocumentList = [...documentList];
                                      newDocumentList[field.name].downloads_user = 1;
                                      newDocumentList[field.name].created_at = Formatter.datetime(
                                        newDocumentList[field.name].created_at,
                                      );
                                      newDocumentList[field.name].updated_at = Formatter.datetime(
                                        newDocumentList[field.name].updated_at,
                                      );
                                      setDocumentList(newDocumentList);
                                    }
                                    setDocumentLoading(null);
                                  }
                                }}
                              />
                              {!pageStatus.new &&
                                checkIfFilePreviewerSupportsExtension(documentList[field.name]?.extension) && (
                                  <IconWithTooltip
                                    action="detail"
                                    title={t('pages.credit-request.table.icons.preview')}
                                    loading={
                                      documentList[field.name]?.id && documentLoading === documentList[field.name].id
                                    }
                                    onClick={() => {
                                      handlePreviewFile(documentList[field.name]);
                                    }}
                                  />
                                )}
                            </div>
                          </Col>
                        </Row>
                      ))}
                    </>
                  )}
                </Form.List>
              </Spin>
              {creditRequestId && (
                <Pagination
                  defaultCurrent={1}
                  total={documentTotal}
                  onChange={handleChangeDocumentList}
                  showSizeChanger
                />
              )}
            </Col>
          </Row>
          <Row gutter={[26, 26]}>
            <span
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'end',
                gap: '10px',
                marginTop: '10px',
                marginRight: '10px',
              }}
            >
              <Button
                disabled={!documentList.filter(doc => doc.downloads_user === 0).length || pageStatus.new}
                onClick={handleDownloadNotDownloadedDocuments}
                permission="credit.request.attachment.downloadnotdownloaded"
              >
                {t('downloadNotDownloaded')}
              </Button>
              <Button disabled={!documentList.length || pageStatus.new} onClick={handleDownloadAllDocuments}>
                {t('downloadAll')}
              </Button>
            </span>
          </Row>
        </>

        {fileToPreview && <FilePreviewer document={fileToPreview} onClose={() => setFileToPreview(null)} />}
      </div>
    </>
  );
};

import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormItem, Select } from 'components';
import { useCache } from 'hooks/cache';
import { useCreditRequestStore } from 'pages/CreditRequest/store/useCreditRequestStore';

export const JsonDataWarrantyTypesSelect = () => {
  const { t } = useTranslation();
  const cache = useCache();

  const setIsBlocking = useCreditRequestStore(state => state.setIsBlocking);
  const pageStatus = useCreditRequestStore(state => state.pageStatus);

  return (
    <FormItem
      label={t('pages.credit-request.form.json_data_warranty_types')}
      name="json_data.warranty_types"
      rules={[{ required: true }]}
    >
      <Select
        mode="multiple"
        options={cache.getCreditOptions('warranty_type')}
        disabled={pageStatus.viewing}
        onChange={(value: any) => {
          if (value) {
            setIsBlocking(true);
          }
        }}
      />
    </FormItem>
  );
};

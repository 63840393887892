import React from 'react';

import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { cn } from 'lib/utils';

type Props = {
  id: string;
  className?: string;
  children: React.ReactNode;
};

export const SortableItem = ({ id, className, ...props }: Props) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div {...props} ref={setNodeRef} style={style} {...attributes} {...listeners} className={cn(className)}>
      {/* ... */}
    </div>
  );
};

import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ShouldRender } from 'components/ShouldRender';
import { useAlertDialog } from 'components/ui/alert-dialog';
import { Button } from 'components/ui/button';
import { FormItem } from 'components/ui/form-item';
import { GroupForm } from 'components/ui/group-form';
import { Switch } from 'components/ui/switch';
import { PHYSICAL_PERSON_TYPE } from 'configs/constants';
import { randomUUID } from 'lib/utils';

import { EconomicGroupFormData } from './economic-groups/economic-groups.form';
import { EconomicGroupModal } from './economic-groups/economic-groups.modal';
import { EconomicGroupTable } from './economic-groups/economic-groups.table';
import { useRegisteredCustomerStore } from './stores/use-registered-customer-store';

export const RegisteredCustomerEconomicGroupsSection = () => {
  const { t } = useTranslation();
  const form = useFormContext();
  const { alert } = useAlertDialog();

  const cnpj = form.watch(`cnpj`);
  const cpf = form.watch(`cpf`);
  const personType = form.watch('type');
  const isPhysicalPerson = personType === PHYSICAL_PERSON_TYPE;
  const participatesInAnyEconomicGroup = useWatch({ name: 'json_data.participates_in_any_economic_group' });
  const economicGroups = form.watch('economic_group') as EconomicGroupFormData[];

  const isViewing = useRegisteredCustomerStore(state => state.isViewing);
  const isModulePerson = useRegisteredCustomerStore(state => state.isModulePerson);
  const setIsEconomicGroupModalOpen = useRegisteredCustomerStore(state => state.setIsEconomicGroupModalOpen);
  const economicGroupToEdit = useRegisteredCustomerStore(state => state.economicGroupToEdit);

  async function handleEconomicGroupsClear(value: boolean) {
    if (!value) form.setValue('economic_group', []);
  }

  async function confirmEconomicGroupsClear(value: boolean) {
    if (economicGroups && economicGroups.length > 0 && !value) {
      return alert({
        type: 'confirm',
        title: t(`modal.warning`),
        subTitle: t('pages.registered-customers.messages.pf.warningEconomicGroups'),
        onOk: () => handleEconomicGroupsClear(value),
        okText: t('pages.registered-customers.messages.confirmButton'),
        onCancel: () => form.setValue('json_data.participates_in_any_economic_group', true),
      });
    }
    return true;
  }

  const handleAddEconomicGroup = (data: EconomicGroupFormData) => {
    const economicGroups = form.getValues('economic_group') as EconomicGroupFormData[];

    form.setValue(
      'economic_group',
      [
        ...(economicGroups ?? []),
        {
          ...data,
          id: `new-${randomUUID()}`,
        },
      ],
      {
        shouldTouch: true,
      },
    );
  };

  const handleEditEconomicGroup = (data: EconomicGroupFormData) => {
    const economicGroups = form.getValues('economic_group') as EconomicGroupFormData[];
    const index = economicGroups.findIndex(economicGroup => economicGroup.id === data.id);
    economicGroups[index] = data;
    form.setValue('economic_group', economicGroups, {
      shouldTouch: true,
    });
    alert({
      type: 'success',
      title: t('economic-group-updated'),
      description: '',
    });
  };

  return (
    <GroupForm title={t('economic-groups')}>
      <FormItem
        label={t('pages.registered-customers.form.pj.economic_groups.participates_in_any_economic_group', {
          docType: isPhysicalPerson ? 'CPF' : 'CNPJ',
          doc: isPhysicalPerson ? cpf ?? '000.000.000-00' : cnpj ?? '00.000.000/0000-00',
        })}
        name="json_data.participates_in_any_economic_group"
      >
        <Switch
          onValueChange={value => {
            confirmEconomicGroupsClear(value);
          }}
          disabled={isViewing || !isModulePerson}
        />
      </FormItem>
      <ShouldRender condition={!isViewing && isModulePerson && participatesInAnyEconomicGroup}>
        <div className="flex justify-end w-full">
          <Button variant="default" type="button" onClick={() => setIsEconomicGroupModalOpen(true)}>
            {t('add-economic-groups')}
          </Button>
        </div>
      </ShouldRender>

      <ShouldRender condition={participatesInAnyEconomicGroup}>
        <div className="mt-6">
          <EconomicGroupTable />
        </div>
        <EconomicGroupModal
          onSuccess={economicGroupToEdit ? handleEditEconomicGroup : handleAddEconomicGroup}
          personType={isPhysicalPerson ? 'physical' : 'legal'}
        />
      </ShouldRender>
    </GroupForm>
  );
};

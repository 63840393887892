import theme from '../../../styles/theme';

export const propsSkeletonCard = {
  width: '300px',
  height: '126px',
  borderRadius: '10px',
  margin: '5px',
  number: 5,
};

export const propsSkeletonCharts = {
  width: '100%',
  minWidth: ['99%', '550px', '550px'],
  height: '492px',
  borderRadius: '10px',
  margin: '5px',
  number: 3,
};

export const propsFilters = [
  {
    name: 'harvest',
    key: 1,
  },
  {
    name: 'region',
    key: 2,
  },
  {
    name: 'subsidiary',
    key: 3,
  },
  {
    name: 'division',
    key: 4,
  },
  {
    name: 'sub_division',
    key: 5,
  },
  {
    name: 'requester',
    key: 6,
  },
  {
    name: 'requesterDate',
    key: 7,
  },
  {
    name: 'start',
    key: 8,
  },
  {
    name: 'end',
    key: 9,
  },
];

export const useChartProps = () => {
  const propsChartCreditWarrantyOperation = (data: any, t: any) => {
    if (Object.keys(data).length) {
      const newData = Object.keys(data).map((item: any) => data[item]);
      const legend = [
        { prop: 'requestedAmount', name: t('pages.dashboard.warranty-prefin.creditOperation.legends.requested') },
        { prop: 'approvedValue', name: t('pages.dashboard.warranty-prefin.creditOperation.legends.approval') },
      ];

      const colors = [newData[0].color_requested_amount, newData[0].color_approved_value];

      const values = (value: string) => {
        switch (value) {
          case 'requestedAmount':
            return newData.map((item: any) => ({
              name: item.region,
              y: parseInt(item.sum_requested_amount, 10),
              drilldown: `${item.region}-${value}`,
            }));
          case 'approvedValue':
            return newData.map((item: any) => ({
              name: item.region,
              y: parseInt(item.sum_approved_value, 10),
              drilldown: `${item.region}-${value}`,
            }));
          default:
            return '';
        }
      };

      const amount = (value: string) => {
        switch (value) {
          case 'requestedAmount':
            return newData.map((item: any) => ({
              name: item.region,
              y: parseInt(item.count_requested_amount, 10),
              drilldown: `${item.region}-${value}`,
            }));
          case 'approvedValue':
            return newData.map((item: any) => ({
              name: item.region,
              y: parseInt(item.count_approved_value, 10),
              drilldown: `${item.region}-${value}`,
            }));
          default:
            return '';
        }
      };

      const option1 = legend.map((item: any) => ({
        name: item.name,
        data: values(item.prop),
        typeFormatter: 'abbreviatedValue',
      }));

      const option2 = legend.map((item: any) => ({
        name: item.name,
        data: amount(item.prop),
        typeFormatter: 'default',
      }));

      const drilldownValues = (value: string, dataProp: any) => {
        switch (value) {
          case 'requestedAmount':
            return parseInt(dataProp.sum_requested_amount, 10);
          case 'approvedValue':
            return parseInt(dataProp.sum_approved_value, 10);
          default:
            return '';
        }
      };

      const drilldownAmount = (value: string, dataProp: any) => {
        switch (value) {
          case 'requestedAmount':
            return parseInt(dataProp.count_requested_amount, 10);
          case 'approvedValue':
            return parseInt(dataProp.count_approved_value, 10);
          default:
            return '';
        }
      };

      const drilldownAssembleOption1 = newData.map((d: any) => {
        const subsidiary = Object.keys(d.subsidiary).map((item: any) => d.subsidiary[item]);
        return legend.map((l: any) => ({
          name: l.name,
          id: `${d.region}-${l.prop}`,
          data: subsidiary.map((r: any) => [r.name, drilldownValues(l.prop, r)]),
        }));
      });
      const drilldownOption1: any = [];
      drilldownAssembleOption1.forEach((d: any) => d.forEach((dd: any) => drilldownOption1.push(dd)));

      const drilldownAssembleOption2 = newData.map((d: any) => {
        const subsidiary = Object.keys(d.subsidiary).map((item: any) => d.subsidiary[item]);
        return legend.map((l: any) => ({
          name: l.name,
          id: `${d.region}-${l.prop}`,
          data: subsidiary.map((r: any) => [r.name, drilldownAmount(l.prop, r)]),
        }));
      });
      const drilldownOption2: any = [];
      drilldownAssembleOption2.forEach((d: any) => d.forEach((dd: any) => drilldownOption2.push(dd)));

      return {
        key: 1,
        title: t('pages.dashboard.warranty-prefin.creditOperation.title'),
        buttons: [
          { label: t('pages.dashboard.warranty-prefin.creditOperation.buttons.value'), value: 0 },
          { label: t('pages.dashboard.warranty-prefin.creditOperation.buttons.amount'), value: 1 },
        ],
        colors,
        type: 'column',
        // yAxisText: 'Valor',
        buttonOptions: [option1, option2],
        drilldownOptions: [drilldownOption1, drilldownOption2],
        defaultExpand: 'auto',
      };
    }

    return {
      key: 1,
      title: t('pages.dashboard.warranty-prefin.creditOperation.title'),
      defaultExpand: 'auto',
    };
  };
  const propsChartRequestInProgressWarranty = (data: any, t: any) => {
    if (Object.keys(data).length) {
      const newData = Object.keys(data).map((item: any) => data[item]);
      newData.reverse();
      const option1 = [
        {
          name: t('pages.dashboard.requests-prefin.requestProgress.title'),
          data: newData.map((item: any) => ({ name: item.phase, y: parseInt(item.count, 10), drilldown: item.phase })),
          showInLegend: false,
          typeFormatter: 'default',
        },
      ];

      const option2 = [
        {
          name: t('pages.dashboard.requests-prefin.requestProgress.title'),
          data: newData.map((item: any) => ({ name: item.phase, y: parseInt(item.days, 10), drilldown: item.phase })),
          showInLegend: false,
          typeFormatter: 'intConverter',
        },
      ];

      const drilldownOption1 = newData.map((item: any) => {
        const status = Object.keys(item.status).map((s: any) => item.status[s]);
        const option = {
          name: item.phase,
          id: item.phase,
          data: status.map((s: any) => [s.status, parseInt(s.count, 10)]),
        };
        return option;
      });

      const drilldownOption2 = newData.map((item: any) => {
        const status = Object.keys(item.status).map((s: any) => item.status[s]);
        const option = {
          name: item.phase,
          id: item.phase,
          data: status.map((s: any) => [s.status, parseInt(s.days, 10)]),
        };
        return option;
      });

      return {
        key: 2,
        title: t('pages.dashboard.warranty-prefin.requestProgress.title'),
        buttons: [
          { label: t('pages.dashboard.warranty-prefin.requestProgress.buttons.amount'), value: 0 },
          { label: t('pages.dashboard.warranty-prefin.requestProgress.buttons.days'), value: 1 },
        ],
        colors: [`${theme.colors.info}`],
        type: 'column',
        // yAxisText: 'Valor',
        buttonOptions: [option1, option2],
        drilldownOptions: [drilldownOption1, drilldownOption2],
      };
    }

    return {
      key: 2,
      title: t('pages.dashboard.warranty-prefin.requestProgress.title'),
    };
  };

  const propsCardChart = (data: any) => {
    const props = Object.keys(data).map(item => {
      return {
        description: item,
        title: `${data[item]}`,
        key: item,
      };
    });
    return props;
  };

  return {
    propsChartCreditWarrantyOperation,
    propsChartRequestInProgressWarranty,
    propsCardChart,
  };
};

export const columns = [
  {
    title: 'pages.credit-request.status-history.from',
    dataIndex: 'from',
    key: 'from',
    sorter: false,
    filtered: false,
  },
  {
    title: 'pages.credit-request.status-history.to',
    dataIndex: 'to',
    key: 'to',
    sorter: false,
    filtered: false,
  },
  {
    title: 'pages.credit-request.status-history.who',
    dataIndex: 'who',
    key: 'who',
    sorter: false,
    filtered: false,
  },
  {
    title: 'pages.credit-request.status-history.when',
    dataIndex: 'when',
    key: 'when',
    sorter: false,
    filtered: false,
  },
  {
    title: 'pages.credit-request.status-history.description',
    dataIndex: 'description',
    key: 'description',
    sorter: false,
    filtered: false,
  },
  {
    title: 'pages.credit-request.status-history.documents',
    dataIndex: 'documents',
    key: 'documents',
    sorter: false,
    filtered: false,
  },
];

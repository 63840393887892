import React from 'react';

import { FormItem, Input } from 'components';

export const DatasulContractInput = () => {
  return (
    <FormItem name="json_data.datasul_contract" hidden>
      <Input />
    </FormItem>
  );
};

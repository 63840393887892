import { Divider } from 'antd';
import styled, { keyframes } from 'styled-components';

import { FormContainer } from './types';

const fadeInFwd = keyframes`
 0% {
   -webkit-transform: translateZ(-80px);
           transform: translateZ(-80px);
   opacity: 0;
 }
 100% {
   -webkit-transform: translateZ(0);
           transform: translateZ(0);
   opacity: 1;
 }
`;

export const Container = styled.div`
  height: calc(100% - 150px);
  padding: 0;

  .content-container {
    padding: 8px 8px 24px;

    .grid-actions {
      display: grid;
      grid-template-columns: 2fr 1fr;
      grid-template-areas: '. grid-button-action-header';
      gap: 8px;
      padding: 8px;

      h2 {
        color: ${({ theme: { colors } }) => colors.primary};
        font-family: ${({ theme: { fonts } }) => fonts.primary};
        font-size: 18px;
        font-weight: 600;
      }

      .grid-button-action-header {
        grid-area: grid-button-action-header;
        margin-top: 0;
        display: flex;
        justify-content: flex-end;
      }
    }
  }

  .bordered-box {
    border: dashed 1px ${({ theme: { colors } }) => colors.greys.grey03};
    border-radius: 4px;
    margin-bottom: 12px;
    margin-top: 8px;
    padding: 8px;
  }

  .button-left {
    display: flex;
    justify-content: flex-end;
    padding-top: 16px;
  }

  .ant-transfer-list {
    width: 40%;
  }
`;

export const SFormContainer = styled.div<FormContainer>`
  display: ${({ visible }) => (visible ? 'block' : 'none')};
  border: solid 1px ${({ theme: { colors } }) => colors.greys.grey03};
  border-radius: 4px;
  background: #ffffff;
  padding: 8px;
  margin-top: 8px;
  margin-bottom: 8px;
  animation: ${fadeInFwd} 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;

  h2 {
    color: ${({ theme: { colors } }) => colors.greys.grey08};
    font-family: ${({ theme }) => theme.fonts.primary};
    font-size: 16px;
    font-weight: 600;
    display: block;
    margin-bottom: 16px;
  }
`;

export const SFilterContainer = styled.div`
  display: flex;
  align-items: flex-end;

  .grid-filters {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    grid-template-areas: 'filter-search filter-button filter-clear';
    padding: 8px 0;
    width: 100%;

    .filter-search {
      grid-area: filter-search;
    }

    .filter-button {
      grid-area: filter-button;
      align-items: flex-end;
      display: flex;
    }

    .filter-clear {
      grid-area: filter-clear;
      align-items: flex-end;
      display: flex;
      justify-content: flex-end;
      padding-bottom: 7px;
    }
  }
`;

export const SFormButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  button + button {
    margin-left: 8px;
  }
`;

export const SDivider = styled(Divider)`
  &.ant-divider-with-text {
    margin: 0;
    color: ${({ theme: { colors } }) => colors.primary};
  }
`;

export const ContainerColorPicker = styled.div`
  background-color: #ffffff;
  border-radius: 4px;
  display: block;
  height: 32px;
  width: 100%;
  position: relative;
`;

interface ButtonColorPickerProps {
  colorHex: string;
}

export const ButtonColorPicker = styled.button<ButtonColorPickerProps>`
  border: solid 1px ${({ theme: { colors } }) => colors.greys.grey03};
  border-radius: 4px;
  background-color: ${({ colorHex }) => colorHex};
  cursor: pointer;
  display: block;
  height: 100%;
  width: 100%;
`;

export const BoxColorPicker = styled.div`
  background: ${({ theme: { colors } }) => colors.greys.grey01};
  box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 4px;
  position: absolute;
  left: 0;
  top: 32px;
  width: 100%;
  z-index: 10;

  .rightClose {
    color: ${({ theme: { colors } }) => colors.text};
    cursor: pointer;
    font-size: 16px;
    position: absolute;
    right: 4px;
    top: 4px;
  }
`;

import { useMutation } from '@tanstack/react-query';
import api from 'services/api/api';

type UseGetPackagesMutationProps = {
  params: {
    params: {
      checkName?: string;
      checkRegistration?: string;
      requesterPerson?: string;
      [key: string]: any;
    };
    [key: string]: any;
  };
};

export const useGetCheckPackagesMutation = () => {
  return useMutation({
    mutationKey: ['getCheckPackages'],
    mutationFn: async (props?: UseGetPackagesMutationProps) => {
      const params = props?.params;
      const response = await api.get('credit-request/package/checkPackage', params);
      if (response.status !== 200) throw new Error(response.data?.message || 'Error fetching package types');
      if (!response.data) return null;
      return response.data;
    },
  });
};

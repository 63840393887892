import styled from 'styled-components';

export const Container = styled.div<{ status: 'normal' | 'declined' | 'autoResponded' }>`
  background: ${({ theme: { colors }, status }) => {
    if (status === 'declined') return colors.lightRed;
    if (status === 'autoResponded') return colors.lightOrange;
    return colors.white;
  }};
  border-radius: 10px;
  padding: 10px;
  user-select: none;

  .ant-select-auto-complete {
    border: none !important;
  }

  .ant-form-item {
    margin-bottom: 10px;
  }

  .ant-form-item-label label {
    font-size: 12px !important;
  }

  input {
    font-size: 13px !important;
  }
`;

export const IconsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const RoleSelectWrapper = styled.div`
  display: flex;
  align-items: center;
  line-height: 39px;

  .ant-form-item-control-input-content {
    display: flex;
  }

  .ant-form-item {
    margin-bottom: 0;
  }

  .ant-select {
    border-radius: 15px !important;
    overflow: hidden !important;
    border: none !important;
    width: 120px;
  }

  .ant-select-selector {
    background: ${({ theme: { colors } }) => colors.lightOrange} !important;
    font-size: 0.8rem !important;
  }

  .ant-select-selection-item {
    font-weight: bold;
    height: auto;
    padding: 0 !important;
  }

  .anticon-close-circle {
    background: ${({ theme: { colors } }) => colors.lightOrange};
  }
`;

export const CloseIconWrapper = styled.span`
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const SorterWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: top;

  .ant-input {
    padding: 0;
  }
`;

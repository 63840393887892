import React from 'react';
import { useTranslation } from 'react-i18next';

import { Table } from 'antd';

import {
  TEAM_COMMERCIAL,
  TEAM_APPROVATION,
  TEAM_CREDIT,
  TEAM_PREFIN,
  TEAM_LEGAL,
  TEAM_OPERATIONAL_RISK,
  TEAM_CONTRACT,
  TEAM_DOCUMENTS,
  TEAM_SUSTAINABILITY,
} from '../../../../configs/constants';

export const summary = (pageData: any, management: boolean, prepay: boolean) => {
  const { t } = useTranslation();
  let totalTime = 0;

  const filterTotalTime = pageData.map((i: any) => i.total_time);
  filterTotalTime.forEach((item: any) => {
    const convert = parseInt(item, 10);
    totalTime += convert;
  });

  const getValue = (teamId: string) => {
    let value = 0;
    const filter = pageData.map((i: any) => i.time_per_team.filter((f: any) => f.team_id === teamId));
    filter.forEach((item: any) => {
      let convert = 0;
      if (item.length) {
        convert = item[0].days !== null && parseInt(item[0].days, 10);
      }
      value += convert;
    });
    return value === 0 ? '' : value;
  };

  const numberColumnsCount = management || prepay ? 4 : 3;

  return (
    <Table.Summary.Row>
      <Table.Summary.Cell index={0} />
      <Table.Summary.Cell index={1} />
      <Table.Summary.Cell index={2} />
      <Table.Summary.Cell index={3} />
      {(management || prepay) && <Table.Summary.Cell index={4} />}
      <Table.Summary.Cell index={numberColumnsCount + 1}>
        <strong style={{ display: 'flex' }}>{t('pages.dashboard.timeline.totals')}</strong>
      </Table.Summary.Cell>
      <Table.Summary.Cell index={numberColumnsCount + 2}>
        <strong style={{ display: 'flex' }}>{totalTime}</strong>
      </Table.Summary.Cell>
      <Table.Summary.Cell index={numberColumnsCount + 3}>
        <strong style={{ display: 'flex' }}>{getValue(TEAM_APPROVATION)}</strong>
      </Table.Summary.Cell>
      <Table.Summary.Cell index={numberColumnsCount + 4}>
        <strong style={{ display: 'flex' }}>{getValue(TEAM_PREFIN)}</strong>
      </Table.Summary.Cell>
      <Table.Summary.Cell index={numberColumnsCount + 5}>
        <strong style={{ display: 'flex' }}>{getValue(TEAM_COMMERCIAL)}</strong>
      </Table.Summary.Cell>
      <Table.Summary.Cell index={numberColumnsCount + 6}>
        <strong style={{ display: 'flex' }}>{getValue(TEAM_CONTRACT)}</strong>
      </Table.Summary.Cell>
      <Table.Summary.Cell index={numberColumnsCount + 7}>
        <strong style={{ display: 'flex' }}>{getValue(TEAM_CREDIT)}</strong>
      </Table.Summary.Cell>
      <Table.Summary.Cell index={numberColumnsCount + 8}>
        <strong style={{ display: 'flex' }}>{getValue(TEAM_LEGAL)}</strong>
      </Table.Summary.Cell>
      <Table.Summary.Cell index={numberColumnsCount + 9}>
        <strong style={{ display: 'flex' }}>{getValue(TEAM_SUSTAINABILITY)}</strong>
      </Table.Summary.Cell>
      <Table.Summary.Cell index={numberColumnsCount + 10}>
        <strong style={{ display: 'flex' }}>{getValue(TEAM_DOCUMENTS)}</strong>
      </Table.Summary.Cell>
      <Table.Summary.Cell index={numberColumnsCount + 11}>
        <strong style={{ display: 'flex' }}>{getValue(TEAM_OPERATIONAL_RISK)}</strong>
      </Table.Summary.Cell>
    </Table.Summary.Row>
  );
};

import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ColumnDef } from '@tanstack/react-table';
import { IconWithTooltip } from 'components';
import { ShouldRender } from 'components/ShouldRender';
import { useAlertDialog } from 'components/ui/alert-dialog';
import { DataTable } from 'components/ui/data-table';
import { Can } from 'hooks/ability';
import { Formatter } from 'utils/Formatter';

import { useRegisteredCustomerStore } from '../stores/use-registered-customer-store';
import { EconomicGroupFormData } from './economic-groups.form';

export const EconomicGroupTable = () => {
  const { t } = useTranslation();
  const form = useFormContext();
  const { alert } = useAlertDialog();

  const economicGroups = form.watch('economic_group') as EconomicGroupFormData[];

  const setEconomicGroupToEdit = useRegisteredCustomerStore(state => state.setEconomicGroupToEdit);
  const setIsEconomicGroupModalOpen = useRegisteredCustomerStore(state => state.setIsEconomicGroupModalOpen);
  const isViewing = useRegisteredCustomerStore(state => state.isViewing);

  const onDelete = (id: string) => {
    const economicGroups = form.getValues('economic_group') as EconomicGroupFormData[];
    form.setValue(
      'economic_group',
      economicGroups.filter(economicGroup => economicGroup.id !== id),
    );
  };

  const handleDelete = (economicGroup: EconomicGroupFormData) => {
    alert({
      type: 'warning',
      title: t('delete-economic-group'),
      description: t('delete-economic-group-confirm', {
        economicGroup: economicGroup.name,
      }),
      onOk: async () => {
        if (economicGroup.id) await onDelete(economicGroup.id.toString());
        alert({
          type: 'success',
          title: t('economic-group-deleted'),
          description: '',
        });
      },
    });
  };

  const columns: ColumnDef<EconomicGroupFormData>[] = [
    {
      accessorKey: 'name',
      header: t('name'),
    },
    {
      accessorKey: 'doc_number',
      header: t('document'),
      cell: ({ row }) => {
        const mainProvider = row.original as EconomicGroupFormData;
        if (!mainProvider.doc_number) return '';
        return mainProvider.doc_number.replaceAll(/\D/g, '').length === 11
          ? Formatter.cpf(mainProvider.doc_number)
          : Formatter.cnpj(mainProvider.doc_number);
      },
    },
    {
      id: 'actions',
      enablePinning: true,
      accessorKey: 'action',
      size: 100,
      header: t('actions'),
      cell: ({ row }) => {
        const partner = row.original as EconomicGroupFormData;

        return (
          <ShouldRender condition={!isViewing}>
            <IconWithTooltip
              permission="credit.request.package.destroy"
              action="destroy"
              title={t('pages.registered-customers.table.icons.remove')}
              // loading={partner.id && partnerToDelete === partner.id}
              onClick={() => handleDelete(partner)}
            />
            <Can I="credit.request.package.update">
              <IconWithTooltip
                action="edit"
                onClick={() => {
                  setEconomicGroupToEdit(partner);
                  setIsEconomicGroupModalOpen(true);
                }}
                title={t('pages.registered-customers.table.icons.edit')}
              />
            </Can>
          </ShouldRender>
        );
      },
    },
  ];

  return <DataTable columns={columns} data={economicGroups ?? []} />;
};

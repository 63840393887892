import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaPlus, FaSearch } from 'react-icons/fa';
import { useNavigate, Link } from 'react-router-dom';

import { Form } from 'antd';
import { TablePaginationConfig, TableProps } from 'antd/lib/table';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import moment from 'moment';

import OwnerData from '../../../@types/data/OwnerData';
import PaginationData from '../../../@types/data/PaginationData';
import DTOErrorReponse from '../../../@types/dtos/DTOErrorReponse';
import DTORegistrationModel from '../../../@types/dtos/monitoring/DTORegistrationModel';
import DTOSurveyType from '../../../@types/dtos/monitoring/DTOSurveyType';
import DTOOperator from '../../../@types/dtos/monitoring/SurveyRequest/DTOOperator';
import DTOSurveyRequest from '../../../@types/dtos/monitoring/SurveyRequest/DTOSurveyRequest';
import { Breadcrumb, Button, FormItem, IconWithTooltip, Input, ModalStatus } from '../../../components';
import { TableData } from '../../../compositions';
import appConfigs from '../../../configs/app';
import { SURVEY_APPROVAL_STATUS_APPROVED } from '../../../configs/constants';
import { Can } from '../../../hooks/ability';
import { usePage } from '../../../hooks/page';
import monitoringApi from '../../../services/api/monitoring';
import OwnerForm from '../Owner/OwnerForm';
import columns from './columns';
import { SContainer, SFilterContainer, SHeader, STitlePage } from './styles';

const SurveyRequest: React.FC = () => {
  const { t, i18n } = useTranslation();
  const { alertStatus } = usePage();
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [paginationConfig, setPaginationConfig] = useState<TablePaginationConfig>({
    current: 1,
    total: 1,
    pageSize: 20,
  });

  const handleSuccess = (messageSuccess: string) =>
    ModalStatus({
      type: 'success',
      title: t('modal.success'),
      subTitle: messageSuccess,
    });

  const loadTableData = useCallback(
    (values: Partial<OwnerForm & PaginationData<OwnerData>>) => {
      setLoading(true);
      monitoringApi.surveyrequest
        .getAll({
          params: {
            per_page: paginationConfig.pageSize,
            ...values,
          },
        })
        .then(response => {
          const result = response.data;
          setData(result.data);

          setPaginationConfig(paginationConfigState => ({
            ...paginationConfigState,
            current: result.current_page,
            total: result.total,
          }));
        })
        .catch((err: DTOErrorReponse) => alertStatus(err, 'error'))
        .finally(() => {
          setLoading(false);
        });
    },
    // eslint-disable-next-line
    [i18n.language, paginationConfig.pageSize],
  );

  const handleRemoveChange = async (record: any) => {
    ModalStatus({
      type: 'delete',
      title: t('pages.monitoring.survey.delete.title'),
      subTitle: t('pages.monitoring.survey.delete.subtitle'),
      cancelText: t('pages.monitoring.survey.delete.cancel'),
      okText: t('pages.monitoring.survey.delete.confirm'),
      onOk: () => {
        monitoringApi.surveyrequest
          .destroy(record.id, {
            params: {},
          })
          .then(() => {
            handleSuccess('success');
            handleTableChange({ current: 1 }, { active: [1] }, {}, null);
          })
          .catch((err: DTOErrorReponse) => alertStatus(err, 'error'));
      },
    });
  };

  // Table DATA Config: BEGIN
  const getActions = () => ({
    render: (active: number, record: DTOSurveyRequest) => {
      return (
        <>
          <Can I="monitoring.surveyrequest.destroy" a="">
            <IconWithTooltip
              action="destroy"
              title={t('pages.monitoring.survey.table.icons.remove')}
              onClick={() => handleRemoveChange(record)}
            />
          </Can>
          <Can I="monitoring.surveyrequest.update" a="">
            <IconWithTooltip
              action="edit"
              title={t('pages.monitoring.survey.table.icons.edit')}
              onClick={() => navigate(`/monitoring/surveyrequest/edit/${record.id}`)}
            />
          </Can>

          {record.survey ? (
            <Link
              to={`/monitoring/cpr/${record.registration.cpr_id}/registration/${record.registration_id}/survey/edit/${record.survey.id}`}
              className="link"
            >
              {record.survey.status_id === SURVEY_APPROVAL_STATUS_APPROVED ? (
                <IconWithTooltip
                  placement="left"
                  action="approval"
                  title={t('pages.monitoring.surveyrequest.approved')}
                />
              ) : (
                <IconWithTooltip
                  placement="left"
                  action="fc-search"
                  title={t('pages.monitoring.surveyrequest.attached')}
                />
              )}
            </Link>
          ) : (
            <Link
              to={
                `/monitoring/cpr/${record.registration.cpr_id}/registration/${record.registration_id}/survey/new` +
                `?survey_request=${record.id}&survey_type=${record.survey_type_id}&survey_stage=${
                  record.survey_stage_id ?? ''
                }&operator_id=${record.operator_id}`
              }
              className="link"
            >
              <Can I="monitoring.survey.store" a="">
                <IconWithTooltip placement="left" action="create" title={t('pages.monitoring.surveyrequest.create')} />
              </Can>
            </Link>
          )}
        </>
      );
    },
  });

  const [searchForm] = Form.useForm();
  const tableConfig = {
    search: '',
    filtered: {},
    sorter: {},
    getActions,
  };

  const [tableDataConfig, setTableDataConfig] = useState(tableConfig);

  const handleFastSearch = () => {
    const value = searchForm.getFieldsValue();
    setTableDataConfig(tableDataConfigState => ({
      ...tableDataConfigState,
      search: value.search,
    }));
    loadTableData({
      search: value.search,
    });
  };

  const tableDataClearAllFilters = () => {
    searchForm.resetFields();
    setTableDataConfig(tableDataConfigState => ({
      ...tableDataConfigState,
      filtered: {},
      search: '',
      sorter: {},
    }));
    loadTableData({ page: 1 });
  };

  const handleTableChange: TableProps<any>['onChange'] = useCallback(
    (
      pagination: TablePaginationConfig,
      filters: Record<string, FilterValue | null>,
      sorter: SorterResult<any> | SorterResult<any>[],
    ) => {
      setLoading(true);
      setTableDataConfig(tableDataConfigState => ({
        ...tableDataConfigState,
        filtered: filters,
        sorter,
      }));

      let direction = '';
      const search: any = {};
      let sort = '';

      if (sorter) {
        const { field, order } = sorter as SorterResult<any>;

        if (order === 'ascend') direction = 'asc';
        if (order === 'descend') direction = 'desc';
        sort = field?.toString() ?? '';
      }

      if (filters) {
        const dataFilter: any = filters;

        if (dataFilter) {
          Object.keys(dataFilter).forEach((key: string) => {
            if (dataFilter[key] !== null && dataFilter[key] !== undefined && dataFilter[key][0]) {
              search[key] = dataFilter[key][0].toString();
            } else if (dataFilter[key] !== null && dataFilter[key] !== undefined) {
              search[key] = dataFilter[key].toString();
            } else {
              search[key] = '';
            }
          });
        }
      }

      setPaginationConfig(paginationConfigState => ({
        ...paginationConfigState,
        pageSize: pagination.pageSize,
        current: pagination.current,
      }));

      loadTableData({
        page: pagination.current,
        per_page: pagination.pageSize,
        sort,
        direction,
        ...search,
      });
    },
    [loadTableData],
  );
  // Table DATA Config: END

  useEffect(() => {
    const filters: any = {};

    handleTableChange({ current: 1, pageSize: paginationConfig.pageSize }, filters, {}, null);
  }, [handleTableChange, paginationConfig.pageSize]);

  return (
    <>
      <Breadcrumb
        items={[
          { title: t('breadcrumb.monitoring') },
          { title: t('breadcrumb.surveyrequests'), to: `/monitoring/surveyrequest` },
        ]}
      />

      <SContainer>
        <SHeader>
          <STitlePage>{t('pages.monitoring.surveyrequest.title_page')}</STitlePage>

          <Can I="monitoring.survey.store" a="">
            <Button
              status="primary"
              icon={<FaPlus />}
              onClick={() => {
                navigate(`/monitoring/surveyrequest/new`);
              }}
            >
              {t('pages.monitoring.surveyrequest.buttonAdd')}
            </Button>
          </Can>
        </SHeader>

        <SFilterContainer>
          <Form
            form={searchForm}
            name="filter-owner"
            className="form-secondary form-filters grid-filters"
            onFinish={handleFastSearch}
          >
            <div className="filter-search">
              <FormItem name="search" label={t('pages.monitoring.survey.search')}>
                <Input />
              </FormItem>
            </div>
            <div className="filter-button">
              <FormItem label="" className="form-item-without-label">
                <Button status="primary" text="uppercase" htmlType="submit">
                  <FaSearch /> {t('form.actions.search')}
                </Button>
              </FormItem>
            </div>
            <div className="filter-clear">
              <Button status="secondary" size="middle" onClick={tableDataClearAllFilters}>
                {t('pages.register.harvest.buttonClearFilter')}
              </Button>
            </div>
          </Form>
        </SFilterContainer>

        <TableData
          rowKey="id"
          columns={columns.survey.map((column: any) => {
            let columnRender: any = {
              ...column,
            };

            if (column.key === 'farm') {
              columnRender = {
                ...columnRender,
                render: (value: any, record: any) => {
                  return record?.registration?.cpr?.farm?.name ?? '-';
                },
              };
            }

            if (column.key === 'cpr') {
              columnRender = {
                ...columnRender,
                render: (value: any, record: any) => {
                  return record?.registration?.cpr?.number ?? '-';
                },
              };
            }

            if (column.key === 'registration') {
              columnRender = {
                ...columnRender,
                render: (value: DTORegistrationModel) => {
                  return value.number ?? '-';
                },
              };
            }

            if (column.key === 'city') {
              columnRender = {
                ...columnRender,
                render: (value: any, record: any) => {
                  return record?.registration?.cpr?.farm?.city.name ?? '-';
                },
              };
            }

            if (column.key === 'state') {
              columnRender = {
                ...columnRender,
                render: (value: any, record: any) => {
                  return record?.registration?.cpr?.farm?.city?.state.code ?? '-';
                },
              };
            }

            if (column.key === 'survey_type') {
              columnRender = {
                ...columnRender,
                render: (value: DTOSurveyType) => {
                  return value.name ?? '-';
                },
              };
            }

            if (column.key === 'operator') {
              columnRender = {
                ...columnRender,
                render: (value: DTOOperator) => {
                  return value.name ?? '-';
                },
              };
            }

            if (column.key === 'deadline') {
              columnRender = {
                ...columnRender,
                render: (value: Date) => {
                  return moment(value).format(appConfigs.formatDate);
                },
              };
            }

            return columnRender;
          })}
          dataSource={data}
          loading={loading}
          onChange={handleTableChange}
          pagination={paginationConfig}
          tableConfig={tableDataConfig}
        />
      </SContainer>
    </>
  );
};

export default SurveyRequest;

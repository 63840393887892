import { Form } from 'antd';
import styled, { css } from 'styled-components';

import { FormItemProps } from './types';

export const SFormItem = styled(Form.Item)<FormItemProps>`
  &.ant-form-item {
    display: flex;
    flex-direction: column;
    margin-bottom: ${({ distance }) => (distance === 'small' ? '8px' : '16px')};
    padding-left: 4px;
    padding-right: 4px;

    ${({ hidden }) => {
      if (hidden) {
        return css`
          display: none;
        `;
      }
      return css``;
    }}

    & > .ant-form-item-label {
      display: block !important;
      color: ${({ theme }) => theme.colors.secondary};
      font-family: ${({ theme }) => theme.fonts.primary};
      font-size: 14px;
      font-weight: 700;
      line-height: 1.5;
      margin-bottom: 3px;
      margin-left: 2px;
      min-height: 16px;
      padding-bottom: 0;
      text-align: left;
      width: 100%;

      & > label {
        height: auto;
        line-height: 1.5;

        &::after {
          content: '';
        }
      }
    }

    & .ant-form-item-control-input {
      display: flex;
      align-items: baseline;
    }

    & > .ant-form-item-control {
      display: flex;
      justify-content: flex-start;
    }

    .ant-btn {
      margin: 7px 0;
    }
  }

  .ant-input-number-group-addon {
    border: none !important;
    background: none !important;
  }

  & .ant-radio-button-wrapper-checked.ant-radio-button-wrapper-disabled {
    color: #f5f5f5;
    background: #d9d9d9;
  }
`;

export default SFormItem;

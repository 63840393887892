import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ColumnDef } from '@tanstack/react-table';
import { IconWithTooltip } from 'components';
import { ShouldRender } from 'components/ShouldRender';
import { useAlertDialog } from 'components/ui/alert-dialog';
import { DataTable } from 'components/ui/data-table';
import { Can } from 'hooks/ability';
import { useCache } from 'hooks/cache';
import { Formatter } from 'utils/Formatter';

import { useRegisteredCustomerStore } from '../stores/use-registered-customer-store';
import { DeliveryHistoryFormData } from './delivery-history.form';

export const DeliveryHistoryTable = () => {
  const { t } = useTranslation();
  const form = useFormContext();
  const { alert } = useAlertDialog();
  const cache = useCache();

  const deliveryHistorys = form.watch('delivery_history') as DeliveryHistoryFormData[];

  const setDeliveryHistoryToEdit = useRegisteredCustomerStore(state => state.setDeliveryHistoryToEdit);
  const setIsDeliveryHistoryModalOpen = useRegisteredCustomerStore(state => state.setIsDeliveryHistoryModalOpen);
  const isViewing = useRegisteredCustomerStore(state => state.isViewing);

  const onDelete = (id: string) => {
    const deliveryHistorys = form.getValues('delivery_history') as DeliveryHistoryFormData[];
    form.setValue(
      'delivery_history',
      deliveryHistorys.filter(deliveryHistory => deliveryHistory.id !== id),
    );
  };

  const handleDelete = (deliveryHistory: DeliveryHistoryFormData) => {
    alert({
      type: 'warning',
      title: t('delete-delivery-history'),
      description: t('delete-delivery-history-confirm'),
      onOk: async () => {
        if (deliveryHistory.id) await onDelete(deliveryHistory.id.toString());
        alert({
          type: 'success',
          title: t('delivery-history-deleted'),
          description: '',
        });
      },
    });
  };

  const columns: ColumnDef<DeliveryHistoryFormData>[] = [
    {
      accessorKey: 'harvest_id',
      header: t('harvest'),
      cell: ({ row }) => {
        const mainProvider = row.original as DeliveryHistoryFormData;
        return cache.getCreditOptions('harvest').find(option => option.value === mainProvider.harvest_id)?.label;
      },
    },
    {
      accessorKey: 'product_id',
      header: t('culture'),
      cell: ({ row }) => {
        const mainProvider = row.original as DeliveryHistoryFormData;
        return cache.getPersonOptions('culture').find(option => option.value === mainProvider.product_id)?.label;
      },
    },
    {
      accessorKey: 'amount',
      header: t('amount-tons'),
      cell: ({ row }) => {
        const mainProvider = row.original as DeliveryHistoryFormData;
        return Formatter.decimal(mainProvider.amount, 2, 3);
      },
    },
    {
      accessorKey: 'currency_type',
      header: t('currency'),
      cell: ({ row }) => {
        const mainProvider = row.original as DeliveryHistoryFormData;
        return cache
          .getCreditOptions('currency_type')
          .find(option => option.value === mainProvider.currency_type_id)
          ?.tag.toUpperCase();
      },
    },
    {
      accessorKey: 'credit_amount_granted',
      header: t('credit_amount_granted'),
      cell: ({ row }) => {
        const mainProvider = row.original as DeliveryHistoryFormData;
        return Formatter.money(mainProvider.credit_amount_granted, 2, 'R$');
      },
    },
    {
      accessorKey: 'commentary',
      header: t('commentary'),
    },
    {
      id: 'actions',
      enablePinning: true,
      accessorKey: 'action',
      size: 100,
      header: t('actions'),
      cell: ({ row }) => {
        const partner = row.original as DeliveryHistoryFormData;

        return (
          <ShouldRender condition={!isViewing}>
            <IconWithTooltip
              permission="credit.request.package.destroy"
              action="destroy"
              title={t('pages.registered-customers.table.icons.remove')}
              // loading={partner.id && partnerToDelete === partner.id}
              onClick={() => handleDelete(partner)}
            />
            <Can I="credit.request.package.update">
              <IconWithTooltip
                action="edit"
                onClick={() => {
                  setDeliveryHistoryToEdit(partner);
                  setIsDeliveryHistoryModalOpen(true);
                }}
                title={t('pages.registered-customers.table.icons.edit')}
              />
            </Can>
          </ShouldRender>
        );
      },
    },
  ];

  return <DataTable columns={columns} data={deliveryHistorys ?? []} />;
};

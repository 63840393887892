import { FixedType } from 'rc-table/lib/interface';

export const columns = [
  {
    title: 'pages.serasa.integrations.documents.table.name',
    dataIndex: 'name',
    key: 'name',
    sorter: false,
    filtered: false,
  },
  {
    title: 'pages.serasa.integrations.documents.table.created_at',
    dataIndex: 'created_at',
    key: 'created_at',
    sorter: false,
    filtered: false,
  },
  {
    title: 'pages.serasa.integrations.documents.table.actions',
    dataIndex: 'id',
    key: 'id',
    width: '100px',
    fixed: 'center' as FixedType,
  },
];

import React, { useEffect, useState } from 'react';

import { Modal } from 'antd';

import { CancelModalBody } from './CancelModalBody';
import { ConfirmModalBody } from './ConfirmModalBody';
import { ModalBody } from './styles';

interface Props {
  isOpen: boolean;
  type: 'cancel' | 'confirm';
  closeModal: () => void;
}

export const ValidateEmissionModal = ({ isOpen = false, type, closeModal }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(isOpen);

  useEffect(() => {
    setIsModalOpen(isOpen);
  }, [isOpen]);

  return (
    <Modal visible={isModalOpen} onCancel={closeModal} footer={null}>
      <ModalBody>
        {type === 'cancel' ? (
          <CancelModalBody closeModal={closeModal} />
        ) : type === 'confirm' ? (
          <ConfirmModalBody closeModal={closeModal} />
        ) : (
          ''
        )}
      </ModalBody>
    </Modal>
  );
};

import { PaginatedQueryParams, usePaginatedQuery } from 'hooks/usePaginatedQuery';
import { CreditRequestPackageData } from 'types/CreditRequest/CreditRequestPackageData';

export const useGetCreditRequestPackagesQuery = (creditRequestId: string, params?: PaginatedQueryParams) => {
  return usePaginatedQuery<CreditRequestPackageData>({
    queryKey: ['credit-request/package', creditRequestId],
    staleTime: 1000 * 60 * 5,
    url: `credit-request/package`,
    enabled: !!creditRequestId,
    params: {
      ...params,
      params: { credit_request_id: creditRequestId },
    },
  });
};

import React from 'react';

import { Container } from './styles';
import { CardOperationDetailsProps } from './types';

export const CardOperationDetails = ({ data }: CardOperationDetailsProps) => {
  const { division, sub_division, operation } = data;

  if (division && sub_division && operation) {
    return (
      <Container>
        {`${division.name} / ${sub_division.name} / `} <span className="card-tagg">{operation.name}</span>
      </Container>
    );
  }

  return <div />;
};

import React from 'react';

import { cva } from 'class-variance-authority';
import { cn } from 'lib/utils';

import { TagProps } from './types';

const tagVariants = cva(
  'select-none rounded-full flex items-center gap-2 justify-center whitespace-nowrap text-auto w-min-content p-[5px_10px]',
  {
    variants: {
      variant: {
        default: 'bg-orange-200 text-primary',
        selectable:
          'data-[selected="true"]:bg-orange-200 data-[selected="true"]:text-primary bg-gray-200 text-gray-500 cursor-pointer',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
);

export const Tag = ({ children, selected, name, onClick, isCheck, className, style }: TagProps) => {
  return (
    <div
      data-selected={selected}
      className={cn(tagVariants({ variant: isCheck ? 'selectable' : 'default', className: cn(className) }))}
      onClick={() => onClick && onClick(name)}
      style={style}
    >
      {children}
    </div>
  );
};

import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  tfoot .ant-table-cell-fix-right-first {
    display: none;
  }

  .ant-spin-container,
  .ant-spin-nested-loading {
    width: 100%;
  }
`;

import { useMutation } from '@tanstack/react-query';
import { usePage } from 'hooks/page';
import api from 'services/api/api';

export const useStorePackageMutation = () => {
  const { alertStatus } = usePage();

  return useMutation({
    mutationKey: ['storePackage'],
    mutationFn: async (data: FormData) => {
      data.delete('id');
      const response = await api.post('credit-request/package', data);
      if (response.status !== 200) throw new Error(response.data?.message || 'Error storing package');
      if (!response.data) return null;
      return response.data;
    },
    onError: error => {
      alertStatus(error.message, 'error');
    },
  });
};

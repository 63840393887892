import { Table } from 'antd';
import styled from 'styled-components';

export const ContainerTable = styled(Table)`
  .ant-table,
  .ant-table.ant-table-middle,
  .ant-table.ant-table-small {
    overflow: auto;

    .ant-table-thead {
      > tr > th {
        background: #ededed;
        color: ${({ theme: { colors } }) => colors.text};
        font-size: 14px;
        font-family: ${({ theme: { fonts } }) => fonts.primary};
        font-weight: 600;
        margin-top: 16px;
        padding: 16px 16px;

        .ant-table-filter-column {
          margin: 0;

          .ant-table-filter-column-title {
            padding: 0;
          }

          .ant-table-filter-trigger {
            width: 16px;
          }
        }

        .ant-table-column-sorters {
          padding: 0;
        }

        /** Sort inactive button */
        .ant-table-column-sorter {
          color: ${({ theme: { colors } }) => colors.greys.grey04};
        }

        /** Sort active button */
        .ant-table-column-sorter-up.active,
        .ant-table-column-sorter-down.active {
          color: ${({ theme: { colors } }) => colors.primary};
        }

        /** Sorted column color */
        &.ant-table-column-sort {
          background: #ffffff;
        }

        &.ant-table-column-has-sorters {
          padding: 16px 16px;

          &:hover {
            & .ant-table-filter-trigger-container {
              background: #efefef;
            }

            background: #efefef;
          }
        }
      }
    }

    .ant-table-thead > tr > th .ant-table-filter-column .ant-table-filter-column-title .ant-table-column-sorters {
      min-width: 100px;
    }
  }

  &.table-with-status.ant-table-wrapper .ant-table .ant-table-tbody > tr.ant-table-row > td,
  &.table-with-status.ant-table-wrapper
    .ant-table.ant-table-large
    .ant-table-body
    > tr.ant-table-row
    > td.ant-table-cell,
  &.table-with-status.ant-table-wrapper
    .ant-table.ant-table-middle
    .ant-table-body
    > tr.ant-table-row
    > td.ant-table-cell,
  &.table-with-status.ant-table-wrapper
    .ant-table.ant-table-small
    .ant-table-body
    > tr.ant-table-row
    > td.ant-table-cell {
    padding: 0 8px;
  }

  &.table-with-status.ant-table-wrapper .ant-table .ant-table-tbody > tr.ant-table-row > td:first-of-type,
  &.table-with-status.ant-table-wrapper
    .ant-table.ant-table-large
    .ant-table-body
    > tr.ant-table-row
    > td.ant-table-cell:first-of-type,
  &.table-with-status.ant-table-wrapper
    .ant-table.ant-table-middle
    .ant-table-body
    > tr.ant-table-row
    > td.ant-table-cell:first-of-type,
  &.table-with-status.ant-table-wrapper
    .ant-table.ant-table-small
    .ant-table-body
    > tr.ant-table-row
    > td.ant-table-cell:first-of-type {
    padding: 0;
  }

  &.ant-table-wrapper .ant-table-scroll-horizontal .ant-table-content {
    overflow: auto hidden !important;
  }

  &.ant-table-wrapper .ant-table .ant-table-tbody > tr.ant-table-row > td.ant-table-cell-fix-right {
    text-align: center;
  }
`;

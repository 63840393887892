/**
 * @see {@doc http://www.regexlib.com/(X(1)A(P7PwUe3Y_2mGAr7zsCYv66JWfymofTSDeDkZxwP4SXHk9IXKavVOp8-eUur4MA05UHr7DoR4GoZMf9vema2_cdIS59XXmPS-clUpooLE-hD0Odp92KR4TjYIEj_lyYCoHT51_Jo9mVpxYiYZ3wm8NCE_k1udxvlDWB46JQ9b6WRtqJ56MOBTa6untpwlwJJh0))/UserPatterns.aspx?authorId=b1f7714b-dd59-4459-aa1e-a5af0fd1b339}
 * @see {@doc http://www.regexlib.com/(X(1)A(P7PwUe3Y_2mGAr7zsCYv66JWfymofTSDeDkZxwP4SXHk9IXKavVOp8-eUur4MA05UHr7DoR4GoZMf9vema2_cdIS59XXmPS-clUpooLE-hD0Odp92KR4TjYIEj_lyYCoHT51_Jo9mVpxYiYZ3wm8NCE_k1udxvlDWB46JQ9b6WRtqJ56MOBTa6untpwlwJJh0))/Search.aspx?k=latitude&AspxAutoDetectCookieSupport=1}
 */
export const Coordinate = {
  regexDMS:
    /([0-9]{1,2})(:|°)( |)([0-9]{1,2})( )?(:|'|′)( |)?([0-9]{1,2}(?:.[0-9]+){0,1})?( )?("|″)( |)([N|S])( |)(,)( |)([0-9]{1,3})(:|°)( |)([0-9]{1,2})( )?(:|'|′)( |)?([0-9]{1,2}(?:.[0-9]+){0,1})?( )?("|″)( |)([E|W])/i,
  regexLat: /^-?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,6})?))$/,
  regexLon: /^-?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[1-7][0-9])(?:(?:\.[0-9]{1,6})?))$/,
};

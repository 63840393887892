/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { forwardRef, useEffect, useImperativeHandle } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useQueryClient } from '@tanstack/react-query';
import { PermissionedField } from 'components/PermissionedField';
import { ShouldRender } from 'components/ShouldRender';
import { useAlertDialog } from 'components/ui/alert-dialog';
import { Form } from 'components/ui/form';
import { FormItem } from 'components/ui/form-item';
import { GroupForm } from 'components/ui/group-form';
import { Input, TextArea } from 'components/ui/input';
import appConfigs from 'configs/app';
import moment from 'moment';
import { useGetCheckPackagesMutation } from 'pages/CreditRequest/api/useGetCheckPackagesMutation';
import { useStorePackageMutation } from 'pages/CreditRequest/api/useStorePackageMutation';
import { useUpdatePackageMutation } from 'pages/CreditRequest/api/useUpdatePackageMutation';
import { useFormalizationGuaranteesStore } from 'pages/CreditRequest/store/useFormalizationGuaranteesStore';
import { usePackageFormStateStore } from 'pages/CreditRequest/store/usePackageFormStateStore';
import { DocumentData } from 'types/Api/DocumentData';
import PaginationData from 'types/Api/PaginationData';
import { ResponseSingle } from 'types/Api/ResponseSingleData';
import { CreditRequestPackageData } from 'types/CreditRequest/CreditRequestPackageData';
import { DocumentToCollectFormData } from 'types/CreditRequest/forms/DocumentToCollectFormData';
import { PackageFormData } from 'types/CreditRequest/forms/PackageFormData';
import { v4 as uuid } from 'uuid';

import { PackageTypeSelect } from '../package-type.select';
import { DocumentsToCollectSection } from './documents-collect/documents-collect.section';
import { PackageAttachmentsSection } from './package-attachments.section';
import { PackageExistsTable } from './package-exists.table';
import { PackageFormConverter } from './package.form.converter';

export const packageFormInitialValues = {
  id: ``,
  name: '',
  type_id: '',
  description: '',
  template: '',
  documentsToCollect: [] as DocumentToCollectFormData[],
  files: [] as File[],
  documents: [] as DocumentData[],
};

export type PackageFormProps = {
  onSuccess?: () => void;
};

export const PackageForm = forwardRef(({ onSuccess }: PackageFormProps, ref) => {
  const { t } = useTranslation();
  const form = useForm({
    defaultValues: { ...packageFormInitialValues, id: `new-${uuid()}` },
  });
  const { alert } = useAlertDialog();
  const queryClient = useQueryClient();

  const selectedPackage = useFormalizationGuaranteesStore(state => state.selectedPackage);
  const requesterPersonId = useFormalizationGuaranteesStore(state => state.requesterPersonId);
  const setSelectedPackage = useFormalizationGuaranteesStore(state => state.setSelectedPackage);
  const creditRequestId = useFormalizationGuaranteesStore(state => state.creditRequestId);
  const phase = useFormalizationGuaranteesStore(state => state.phase);

  const resetFormState = usePackageFormStateStore(state => state.resetFormState);

  const checkPackagesMutation = useGetCheckPackagesMutation();
  const storePackageMutation = useStorePackageMutation();
  const updatePackageMutation = useUpdatePackageMutation();

  const storePackage = async (formData: FormData) => {
    formData.delete('id');
    await storePackageMutation.mutateAsync(formData, {
      onSuccess: async response => {
        alert({
          type: 'success',
          title: t('modal.success'),
          subTitle: response.message,
          onOk: () => {
            onSuccess();
          },
        });
        await queryClient.invalidateQueries({
          queryKey: ['credit-request/package'],
        });
        if (onSuccess) onSuccess();
      },
    });
  };

  const updatePackage = async (formData: FormData) => {
    await updatePackageMutation.mutateAsync(formData, {
      onSuccess: response => {
        alert({
          type: 'success',
          title: t('modal.success'),
          subTitle: response.message,
          onOk: () => {
            onSuccess();
          },
        });
        queryClient.invalidateQueries({
          queryKey: ['credit-request/package'],
        });
      },
    });
  };

  const handleRegister = async (newPackage: PackageFormData, formData: FormData) => {
    if (formData.get('id').toString()?.includes('new-')) return storePackage(formData);
    return handleEdit(formData);
  };

  const handleEdit = async (formData: FormData) => {
    await updatePackage(formData);
  };

  const submit = () => {
    form.handleSubmit(async (newPackage: PackageFormData) => {
      const formData = await PackageFormConverter.toApiFormData({ ...newPackage, credit_request_id: creditRequestId });

      const checkPackageResponse: ResponseSingle<PaginationData<CreditRequestPackageData>> =
        await checkPackagesMutation.mutateAsync({
          params: {
            params: {
              checkName: newPackage.name,
              checkRegistration: newPackage.type_id,
              requesterPerson: requesterPersonId,
            },
          },
        });

      if (checkPackageResponse?.data?.total && !newPackage.id) {
        return alert({
          type: 'warning',
          title: t(`modal.warning`),
          subTitle: 'Já existe uma solicitação com essa mesma identificação e tipo',
          description: <PackageExistsTable packages={checkPackageResponse.data.data} />,
          cancelText: t('hooks.creditStatus.modal-status-buttons.no'),
          okText: t('hooks.creditStatus.modal-status-buttons.yes'),
          onOk: async () => {
            handleRegister(newPackage, formData);
          },
        });
      }
      return newPackage.id?.includes('new-') ? handleRegister(newPackage, formData) : handleEdit(formData);
    })();
  };

  useImperativeHandle(ref, () => ({
    form,
    submit,
  }));

  useEffect(() => {
    if (selectedPackage) {
      form.setValue('id', selectedPackage.id);
      form.setValue('name', selectedPackage.name);
      form.setValue('type_id', selectedPackage.type_id);
      form.setValue('description', selectedPackage.description || '');
      form.setValue('documents', selectedPackage.documents);
      form.setValue(
        'documentsToCollect',
        selectedPackage?.items?.map(item => ({
          ...item,
          registered_by: item.user?.name,
          comments: item.comments.map(comment => ({
            ...comment,
            registered_by: comment.user?.name,
            dt_comment: moment(comment.created_at).format(appConfigs.formatDate),
          })),
        })) || [],
      );
    }
  }, [selectedPackage]);

  useEffect(() => {
    return () => {
      resetFormState();
      setSelectedPackage(null);
      form.reset(packageFormInitialValues);
    };
  }, []);

  return (
    <Form onSubmit={console.log} form={form}>
      <FormItem name="id" style={{ display: 'none' }}>
        <Input />
      </FormItem>

      <div>
        <GroupForm
          title={t('pages.credit-request.formalization-guarantees.pre-registration-docs.modalPackage.packageData')}
        >
          <div className="flex gap-2 items-center w-full md:flex-nowrap flex-wrap">
            <FormItem
              label={t(
                `pages.credit-request.formalization-guarantees.pre-registration-docs.modalPackage.package-identification`,
              )}
              name="name"
              rules={{ required: true }}
              className="w-full"
            >
              <Input type="text" maxLength={255} className="w-full" />
            </FormItem>

            <FormItem
              label={t('pages.credit-request.formalization-guarantees.pre-registration-docs.modalPackage.package-type')}
              name="type_id"
              rules={{ required: true }}
              className="w-full"
            >
              <PermissionedField permission="credit.request.packagetype.all">
                <PackageTypeSelect />
              </PermissionedField>
            </FormItem>
          </div>

          <FormItem
            label={t(
              'pages.credit-request.formalization-guarantees.pre-registration-docs.modalPackage.package-comments',
            )}
            name="description"
          >
            <TextArea maxLength={5000} />
          </FormItem>
        </GroupForm>

        {/* <ShouldRender condition={phase > 1}> */}
        <DocumentsToCollectSection />
        {/* </ShouldRender> */}

        <PackageAttachmentsSection />
      </div>
    </Form>
  );
});

PackageForm.displayName = 'PackageForm';

import React, { createContext, useState } from 'react';

interface Props {
  increaseScale?: () => void;
  reduceScale?: () => void;
  scale: number;
}

export const ImageViewerContext = createContext<Props>({ scale: 0.2 });

export const ImageViewerProvider: React.FC = ({ children }) => {
  const [scale, setScale] = useState(0.2);

  const increaseScale = () => setScale(oldScale => oldScale + 0.2);
  const reduceScale = () => scale >= 0.2 && setScale(oldScale => oldScale - 0.2);

  return (
    <ImageViewerContext.Provider value={{ scale, increaseScale, reduceScale }}>{children}</ImageViewerContext.Provider>
  );
};

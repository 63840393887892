import React from 'react';
import { useTranslation } from 'react-i18next';

import { NotificationBadge } from 'components/ui/notification-badge';
import appConfigs from 'configs/app';
import { cn } from 'lib/utils';
import { MessageCircleMore, Paperclip } from 'lucide-react';
import moment from 'moment';
import { usePackageFormStateStore } from 'pages/CreditRequest/store/usePackageFormStateStore';

import { DocumentsToCollectModal } from './documents-collect/documents-collect.modal';

type Props = {
  packageItem: any;
};

export const PackageItem = ({ packageItem }: Props) => {
  const { t } = useTranslation();

  const setSelectedDocumentToCollectToEdit = usePackageFormStateStore(
    state => state.setSelectedDocumentToCollectToEdit,
  );

  return (
    <DocumentsToCollectModal className="col-span-8 py-4 border-y border-gray-100">
      <div
        className="grid grid-cols-[repeat(8,_minmax(150px,1fr))] gap-6 w-full row-item col-span-4 font-normal"
        key={`item-${packageItem.id}`}
        onClick={async () => {
          setSelectedDocumentToCollectToEdit(packageItem);
        }}
      >
        <div className="flex items-center w-full col-span-3">
          <div className="w-[60px] min-w-[60px] flex justify-center gap-4 mr-4">
            <NotificationBadge count={packageItem.documents_count ? packageItem.documents_count : 0}>
              <span title={t('pages.monitoring.registration.form.iconSurvey')}>
                <Paperclip size={18} />
              </span>
            </NotificationBadge>
            <NotificationBadge count={packageItem.comments_count ? packageItem.comments_count : 0}>
              <span title={t('pages.monitoring.registration.form.iconSurvey')}>
                <MessageCircleMore size={18} />
              </span>
            </NotificationBadge>
          </div>
          <span
            className={cn(
              'h-4 w-4 rounded-full min-w-4 inline-block ml-3 mr-1',
              packageItem.status?.slug === 'DI' ? 'bg-[#e03400]' : '',
              packageItem.status?.slug === 'DS' ? 'bg-[#ffd966]' : '',
              packageItem.status?.slug === 'DC' ? 'bg-[#a9d18e]' : '',
            )}
          />
          <span className="text-start">{packageItem.name}</span>
        </div>
        <span>
          {packageItem.requested?.created_at && moment(packageItem.requested?.created_at).format(appConfigs.formatDate)}
        </span>
        <span className=" overflow-ellipsis overflow-hidden break-words">
          {packageItem.requested?.name ?? packageItem.status?.slug === 'DC' ? packageItem.user?.name : ''}
        </span>
        <span>
          {packageItem.collected?.created_at && moment(packageItem.collected?.created_at).format(appConfigs.formatDate)}
        </span>
        <span className="overflow-ellipsis overflow-hidden break-words">{packageItem.collected?.name}</span>
        <span>{packageItem.notary?.name}</span>
      </div>
    </DocumentsToCollectModal>
  );
};

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Input as AntInput, Modal, Form } from 'antd';

import { FormItem } from '../../../../../components';
import { ModalAddProps } from './types';

export const ModalAdd = ({ setModalAddVisible, modalAddVisible, form, saveJustification }: ModalAddProps) => {
  const { t } = useTranslation();
  const [description, setDescription] = useState('');
  return (
    <Modal
      title={t('pages.credit-request.justification.modalAdd.title')}
      visible={modalAddVisible}
      onCancel={() => {
        setModalAddVisible(false);
        form.setFieldsValue({ justification: '' });
      }}
      onOk={() => form.getFieldValue('justification') && saveJustification()}
    >
      <Form form={form}>
        <FormItem
          label={t('pages.credit-request.justification.modalAdd.form.justification')}
          name="justification"
          extra={`${description.length}/500`}
        >
          <AntInput.TextArea
            rows={5}
            maxLength={500}
            onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
              const { value } = event.target;
              setDescription(value);
            }}
          />
        </FormItem>
      </Form>
    </Modal>
  );
};

import { useMutation } from '@tanstack/react-query';
import { usePage } from 'hooks/page';
import api from 'services/api/api';

export const useDownloadAttachment = () => {
  const { alertStatus } = usePage();

  return useMutation({
    mutationFn: async (fileId: string) => {
      if (!fileId) return null;

      const response = await api.get(`document/download/${fileId}`, {
        responseType: 'blob',
      });

      if (response.status !== 200) throw new Error('Error downloading file');

      return response.data;
    },
    onError: err => alertStatus(err.message, 'error'),
  });
};

import styled from 'styled-components';

interface TeamTagProps {
  team: 'commercial' | 'prefin' | 'credit';
}

export const SRoot = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    width: 98%;
    margin: 0 auto;
    align-items: center;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    overflow: hidden;
  `,
  ValidityContainer: styled.div`
    width: 100%;
    text-align: center;
    padding: 5px 0;
    background: ${({ theme: { colors } }) => colors.lightOrange};
    color: ${({ theme: { colors } }) => colors.greys.grey07};
    font-weight: bold;
  `,
  DatasourceTag: styled.div`
    width: 100%;
  `,
  SapStatusTagContainer: styled.div`
		padding 5px 0 5px 0;
		display: flex;
		gap: 5px;
	`,
  Header: styled.div`
    width: 100%;
    align-items: center;
    padding: 10px;
    display: flex;
    gap: 5px;
    background: ${({ theme: { colors } }) => colors.greys.grey01};
  `,
  HeaderValue: styled.div``,
  Body: styled.div`
    display: flex;
    width: 100%;
  `,
  Footer: styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: column;
  `,
  LogList: styled.div`
    width: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  `,
  LogListItem: styled.div`
    border-bottom: 1px solid ${({ theme: { colors } }) => colors.greys.grey03};
    padding-bottom: 10px;
    width: 100%;

    &:last-of-type {
      border-bottom: none;
    }
  `,
  LogListItemDataContainer: styled.div`
    display: flex;
    gap: 4px;
    width: 100%;
    justify-content: space-between;
  `,
  LogListStatusContainer: styled.div`
    padding: 3px 10px;
    border-radius: 5px;
    height: min-content;
    width: 120px;
    text-align: center;
    &[data-status='validated'],
    &[data-status='approved'] {
      background: ${({ theme: { colors } }) => colors.lightGreen};
      color: ${({ theme: { colors } }) => colors.success};
      font-weight: bold;
      box-shadow: 0px 0px 5px 0px lightgreen;
    }
    &[data-status='rejected'],
    &[data-status='reproved'] {
      background: ${({ theme: { colors } }) => colors.lightRed};
      color: ${({ theme: { colors } }) => colors.danger};
      font-weight: bold;
      box-shadow: 0px 0px 5px 0px #ffcccb;
    }
    &[data-status='created'] {
      background: ${({ theme: { colors } }) => colors.lightBlue};
      color: ${({ theme: { colors } }) => colors.info};
      font-weight: bold;
      box-shadow: 0px 0px 5px 0px lightBlue;
    }
  `,
  LogListItemData: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    span:first-of-type {
      font-weight: bold;
    }

    span:last-of-type {
      font-size: 0.9rem;
      font-weight: bold;
      color: ${({ theme: { colors } }) => colors.greys.grey05};
    }
  `,
  LogListDocumentsList: styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
  `,
  LogListDocument: styled.div`
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 0 10px #f0f0f0f0;
    display: flex;
    justify-content: space-between;
    span {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  `,
  LogListDocumentIcon: styled.div`
    padding: 10px;
    background: ${({ theme: { colors } }) => colors.primary};
    color: white;
    width: min-content;
    display: flex;
    border-radius: 10px;
  `,
  LogListDocumentTitle: styled.div``,
  LogListDocumentButtons: styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
  `,
  TeamTag: styled.div<TeamTagProps>`
    width: 100%;
    padding: 2px 10px;
    text-align: center;
    flex-grow: 1;
    background-color: ${({ team, theme: { colors } }) => {
      switch (team) {
        case 'commercial':
          return `${colors.lightBlue}`;
        case 'prefin':
          return colors.lightRed;
        case 'credit':
          return colors.lightOrange;
        default:
          return colors.lightBlue;
      }
    }};
    border: 1px solid
      ${({ team, theme: { colors } }) => {
        switch (team) {
          case 'commercial':
            return colors.info;
          case 'prefin':
            return colors.warning;
          case 'credit':
            return colors.primary;
          default:
            return colors.info;
        }
      }};
  `,
  ApprovalButtonsContainer: styled.div`
    display: flex;
    justify-content: start;
    gap: 10px;
    > button {
      width: 100%;
    }
  `,
};

export const SApprovalStatus = {
  Container: styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;
    background: ${({ theme: { colors } }) => colors.lightGreen};
    border: 1px solid ${({ theme: { colors } }) => colors.success};
  `,
};

export const SIntegrationStatus = {
  Container: styled.div`
    padding: 2px 5px;
    background: ${({ theme: { colors } }) => colors.lightGreen};
    border: 1px solid ${({ theme: { colors } }) => colors.success};
    border-radius: 5px;
  `,
};

export const SValue = {
  Container: styled.p`
    color: ${({ theme: { colors } }) => colors.greys.grey08};
  `,
};

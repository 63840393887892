import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Dialog,
  DialogContent,
  DialogFooterForm,
  DialogHeader,
  DialogPortal,
  DialogTrigger,
} from 'components/ui/dialog';

import { useRegisteredCustomerStore } from '../stores/use-registered-customer-store';
import { mainClientInitialValues, MainClientFormData, MainClientForm } from './main-clients.form';

type Props = {
  children?: React.ReactNode;
  onSuccess?: (data: MainClientFormData) => void;
};

export const MainClientModal = ({ children, onSuccess }: Props) => {
  const formRef = useRef<any>(null);
  const { t } = useTranslation();

  const isMainClientModalOpen = useRegisteredCustomerStore(state => state.isMainClientModalOpen);
  const setMainClientToEdit = useRegisteredCustomerStore(state => state.setMainClientToEdit);
  const mainClientToEdit = useRegisteredCustomerStore(state => state.mainClientToEdit);
  const setIsMainClientModalOpen = useRegisteredCustomerStore(state => state.setIsMainClientModalOpen);

  return (
    <Dialog
      open={isMainClientModalOpen}
      onOpenChange={value => {
        setIsMainClientModalOpen(value);
        if (!value) {
          setMainClientToEdit(null);
          formRef.current?.form.reset(mainClientInitialValues);
        }
      }}
    >
      <DialogTrigger>{children}</DialogTrigger>
      <DialogPortal>
        <DialogContent className="md:w-[50%] w-[96%]">
          <DialogHeader>
            {mainClientToEdit ? t('edit') : t('new-m')} {t('main-client')}
          </DialogHeader>
          <MainClientForm
            ref={formRef}
            onSuccess={data => {
              setIsMainClientModalOpen(false);
              setMainClientToEdit(null);
              onSuccess?.(data);
              formRef.current?.form.reset(mainClientInitialValues);
            }}
          />
          <DialogFooterForm
            onConfirm={() => {
              formRef.current?.submit();
            }}
            onCancel={() => {
              formRef.current?.form.reset(mainClientInitialValues);
              setMainClientToEdit(null);
              setIsMainClientModalOpen(false);
            }}
          />
        </DialogContent>
      </DialogPortal>
    </Dialog>
  );
};

import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ColumnDef } from '@tanstack/react-table';
import { IconWithTooltip } from 'components';
import { ShouldRender } from 'components/ShouldRender';
import { useAlertDialog } from 'components/ui/alert-dialog';
import { DataTable } from 'components/ui/data-table';
import { Can } from 'hooks/ability';
import { Formatter } from 'utils/Formatter';

import { useRegisteredCustomerStore } from '../stores/use-registered-customer-store';
import { PartnerFormData } from './partner.form';

export const PartnerTable = () => {
  const { t } = useTranslation();
  const form = useFormContext();
  const { alert } = useAlertDialog();

  const partners = form.watch('partners') as PartnerFormData[];

  const setPartnerToEdit = useRegisteredCustomerStore(state => state.setPartnerToEdit);
  const setIsPartnerModalOpen = useRegisteredCustomerStore(state => state.setIsPartnerModalOpen);
  const isViewing = useRegisteredCustomerStore(state => state.isViewing);

  const onDelete = (id: string) => {
    const partners = form.getValues('partners') as PartnerFormData[];
    form.setValue(
      'partners',
      partners.filter(partner => partner.id !== id),
    );
  };

  const handleDelete = (partner: PartnerFormData) => {
    alert({
      type: 'warning',
      title: t('delete-partner'),
      description: t('delete-partner-confirm', { partnerName: partner.name }),
      onOk: async () => {
        if (partner.id) await onDelete(partner.id.toString());
        alert({
          type: 'success',
          title: t('partner-deleted'),
          description: '',
        });
      },
    });
  };

  const columns: ColumnDef<PartnerFormData>[] = [
    {
      accessorKey: 'name',
      header: t('pages.registered-customers.form.pj.partner.name'),
    },
    {
      accessorKey: 'person_type',
      header: t('pages.registered-customers.form.pj.partner.person_type'),
      cell: ({ row }) => {
        const partner = row.original as PartnerFormData;
        return partner?.person_type === 'physical' ? t('physical') : t('legal');
      },
    },
    {
      accessorKey: 'doc_number',
      header: t('pages.registered-customers.form.pj.partner.doc_number.cpf-cnpj'),
      cell: ({ row }) => {
        const partner = row.original as PartnerFormData;
        return partner?.person_type === 'physical'
          ? Formatter.cpf(partner.doc_number)
          : Formatter.cnpj(partner.doc_number);
      },
    },
    {
      accessorKey: 'birth_date',
      header: t('pages.registered-customers.form.pj.partner.date_of_birth'),
      cell: ({ row }) => {
        const partner = row.original as PartnerFormData;
        return Formatter.date(partner.birth_date);
      },
    },
    {
      id: 'actions',
      enablePinning: true,
      accessorKey: 'action',
      size: 100,
      header: t('actions'),
      cell: ({ row }) => {
        const partner = row.original as PartnerFormData;

        return (
          <ShouldRender condition={!isViewing}>
            <IconWithTooltip
              permission="credit.request.package.destroy"
              action="destroy"
              title={t('pages.registered-customers.table.icons.remove')}
              // loading={partner.id && partnerToDelete === partner.id}
              onClick={() => handleDelete(partner)}
            />
            <Can I="credit.request.package.update">
              <IconWithTooltip
                action="edit"
                onClick={() => {
                  setPartnerToEdit(partner);
                  setIsPartnerModalOpen(true);
                }}
                title={t('pages.registered-customers.table.icons.edit')}
              />
            </Can>
          </ShouldRender>
        );
      },
    },
  ];

  return <DataTable columns={columns} data={partners ?? []} />;
};

import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AiFillFileAdd } from 'react-icons/ai';

import { Button } from '../../../../../components';
import theme from '../../../../../styles/theme';
import { DraftModelContext } from '../../../context/DraftModelContext';

export const ConfirmModalBody = ({ closeModal }: { closeModal: () => void }) => {
  const { acceptDraft } = useContext(DraftModelContext);
  const { t } = useTranslation();

  const handleAccept = () => {
    acceptDraft('');
    closeModal();
  };

  return (
    <>
      <h2>{t('pages.edocuments.emission.draftEmission.validateModalTitle')}</h2>
      <AiFillFileAdd size={60} color={theme.colors.primary} />
      <span>{t('pages.edocuments.emission.draftEmission.validateModalDescription')}</span>
      <Button onClick={handleAccept} permission="contract.validatecontract">
        {t('pages.edocuments.emission.draftEmission.validateModalConfirm')}
      </Button>
    </>
  );
};

import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsCheckAll } from 'react-icons/bs';
import { FaFileWord } from 'react-icons/fa';
import { MdOpenInNew } from 'react-icons/md';

import { Button } from '@agrodatabr/agrodataui';
import { Col, Form, Modal } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import moment from 'moment';
import { useTheme } from 'styled-components';

import { ModalStatus, Row, Spin } from '../../../../../../../../components';
import appConfigs from '../../../../../../../../configs/app';
import {
  CONTRACT_TYPE_FIELD_TYPE_DATE,
  CONTRACT_TYPE_FIELD_TYPE_DATETIME,
  CONTRACT_TYPE_FIELD_TYPE_TABLE,
} from '../../../../../../../../configs/constants';
import { useDraft } from '../../../../../../../../hooks/fetch/useDraft';
import { useModal } from '../../../../../../../../hooks/modals/useModal';
import { CustomButton } from '../../../../../../components/CustomButton';
import { DraftModelField } from '../../../../../../components/DraftModelField';
import { EmissionSuccessModal } from '../../../../../../components/EmissionSuccessModal';
import { IconWrapper } from '../../../../../../components/IconWrapper';
import { TableFieldModal } from '../../../../../../components/TableFieldModal';
import { ValidateEmissionModal } from '../../../../../../components/ValidateEmissionModal';
import { useContractContext } from '../../../../../../context/ContractContext';
import { DraftModelContext } from '../../../../../../context/DraftModelContext';
import { Container, FieldsWrapper } from './styles';

export const DraftModelFields = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const {
    modelFields,
    isFetchingFields,
    selectedDraftModelId,
    setTablesState,
    tablesState,
    isIssued,
    setIsIssued,
    needEmissionValidation,
  } = useContext(DraftModelContext);
  const {
    contractId,
    contract,
    updateContract,
    isValidatedOnEmission,
    findChildrenTypes,
    canCurrentUserValidateOnEmission,
    isStored,
    isSelectedDocumentMain,
    updateFileToPreview,
  } = useContractContext();
  const [form] = useForm();
  const { issueDraft } = useDraft();
  const { close: closeExtendedFormModal, isOpen: isExtendedFormModalOpen, open: openExtendedFormModal } = useModal();

  const {
    isOpen: isEmissionSuccessModalOpen,
    close: closeEmissionSuccessModal,
    open: openEmissionSuccessModal,
  } = useModal();

  const [validationModal, setValidationModal] = useState<'cancel' | 'confirm' | null>();
  const [isPreviewed, setIsPreviewed] = useState(isIssued);

  const convertFormValuesToApiFormat = (values: any) => {
    return modelFields.map(modelField => {
      const formattedModelField = {
        key: modelField.name,
        contract_type_field_id: modelField.id,
      } as { [name: string]: any };

      if (modelField.type_id === CONTRACT_TYPE_FIELD_TYPE_TABLE)
        formattedModelField.rows =
          tablesState[modelField.name]?.registers?.map((register: any) =>
            Object.keys(register)
              .filter(key => key !== 'id' && key !== 'order')
              .map(key => {
                const { contract_type_field_id, order, row, value } = register[key];
                return { contract_type_field_id, order, row, value };
              }),
          ) ?? [];
      else if (modelField.type_id === CONTRACT_TYPE_FIELD_TYPE_DATE)
        formattedModelField.value = values[modelField.name]
          ? moment(values[modelField.name]).format(appConfigs.formatApiDate)
          : values[modelField.name];
      else formattedModelField.value = values[modelField.name];

      return formattedModelField;
    });
  };

  const shouldShowValidationButtons = needEmissionValidation && !isValidatedOnEmission && !isStored;

  const handleSave = async () => {
    ModalStatus({
      type: 'confirm',
      subTitle: t('pages.edocuments.emission.draftEmission.modal.issueDraft.title'),
      onOk: async () => {
        form.validateFields().then(async values => {
          const response = await issueDraft(contractId, selectedDraftModelId, convertFormValuesToApiFormat(values));
          if (response) {
            setIsIssued(true);
            findChildrenTypes();
            openEmissionSuccessModal();
            updateContract(response, isSelectedDocumentMain);
          }
        });
      },
    });
  };

  const handleClickDeny = () => setValidationModal('cancel');

  const handleClickConfirm = () => setValidationModal('confirm');

  const closeModal = () => setValidationModal(null);

  const isTableField = (typeId: string) => typeId === CONTRACT_TYPE_FIELD_TYPE_TABLE;
  const isDateTimeField = (typeId: string) => typeId === CONTRACT_TYPE_FIELD_TYPE_DATETIME;
  const isDateField = (typeId: string) => typeId === CONTRACT_TYPE_FIELD_TYPE_DATE;

  const formatParentField = (field: any, values: any, tables: any) => {
    if (isDateField(field.contract_type_field?.type_id) || isDateTimeField(field.contract_type_field?.type_id))
      values[field.contract_type_field.name] = moment(field.value);
    else if (!isTableField(field.contract_type_field.type_id)) values[field.contract_type_field.name] = field.value;
    else formatTableFields(field, tables);
  };

  const formatTableFields = (field: any, tables: any) => {
    const { name } = field.contract_type_field;
    if (!tables[name]) tables[name] = { registers: [] };

    const registersFields: any[] = [];

    field.table_values.forEach((child: any) => {
      const newChildField = { [child.contract_type_field.name]: { ...child, key: child.name } };
      if (!registersFields.length) registersFields.push(newChildField);
      else {
        const registerIndex = registersFields.findIndex(
          register => register[Object.keys(register)[0]].row === child.row,
        );

        if (registerIndex > -1 && registersFields[registerIndex]) {
          registersFields[registerIndex] = {
            ...registersFields[registerIndex],
            ...newChildField,
            order: child.order,
          };
        } else registersFields.push(newChildField);
      }
    });

    tables[name] = {
      ...tables[name],
      registers: [...registersFields],
    };
  };

  const fillFieldsFormWithDraftData = () => {
    const values = {} as { [name: string]: any };
    const tables = {} as { [name: string]: any };
    contract?.fields?.forEach(field => formatParentField(field, values, tables));
    setTablesState(tables);
    form.setFieldsValue(values);
  };

  const handlePreviewDraft = async () => {
    await updateFileToPreview(contractId, {
      fields: convertFormValuesToApiFormat(form.getFieldsValue()),
      contract_template_id: selectedDraftModelId,
    });
    // if (response) setIsPreviewed(true);
  };

  useEffect(() => {
    fillFieldsFormWithDraftData();
  }, [contract]);

  useEffect(() => {
    setIsPreviewed(isIssued);
  }, [isIssued]);

  return (
    <Container>
      <Spin spinning={isFetchingFields}>
        <Form form={form} style={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
          {modelFields.filter(field => !field.parent_id).length ? (
            <a onClick={() => openExtendedFormModal()} style={{ float: 'right' }}>
              <MdOpenInNew color={theme.colors.primary} size={18} />
            </a>
          ) : null}
          <div style={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
            <Col xl={24} xxl={24} lg={24} md={24} sm={24} xs={24}>
              <FieldsWrapper>
                {modelFields
                  .filter(field => !field.parent_id)
                  .map(field => (
                    <DraftModelField
                      key={field.name}
                      field={field}
                      disabled={isIssued}
                      initialValue={field.default_value}
                      tableRegisterCount={tablesState[field.name]?.registers?.length}
                    />
                  ))}
              </FieldsWrapper>
            </Col>
            {!isIssued ? (
              <>
                {!isPreviewed ? (
                  <div
                    style={{ display: 'flex', flexDirection: 'column', gap: '20px', width: '100%', margin: '0 auto' }}
                  >
                    <CustomButton onClick={handlePreviewDraft} isLoading={isFetchingFields} disabled={isFetchingFields}>
                      <IconWrapper>
                        <FaFileWord />
                      </IconWrapper>
                      {t('pages.edocuments.emission.previewDraft')}
                    </CustomButton>
                    <CustomButton
                      permissions="contract.issue"
                      onClick={handleSave}
                      isLoading={isFetchingFields}
                      disabled={isFetchingFields}
                    >
                      <IconWrapper>
                        <BsCheckAll />
                      </IconWrapper>
                      {t('pages.edocuments.emission.draftEmission.issueDraft')}
                    </CustomButton>
                  </div>
                ) : (
                  <div />
                )}
              </>
            ) : (
              shouldShowValidationButtons && (
                <>
                  {canCurrentUserValidateOnEmission ? (
                    <>
                      <Row gutter={[20, 20]}>
                        <Col xl={24} xxl={12} lg={12} md={12} sm={12} xs={12}>
                          <CustomButton onClick={handleClickDeny}>
                            <IconWrapper>
                              <BsCheckAll />
                            </IconWrapper>
                            {t('pages.edocuments.emission.draftEmission.reprove')}
                          </CustomButton>
                        </Col>

                        <Col xl={24} xxl={12} lg={12} md={12} sm={12} xs={12}>
                          <CustomButton onClick={handleClickConfirm}>
                            <IconWrapper>
                              <BsCheckAll />
                            </IconWrapper>
                            {t('pages.edocuments.emission.draftEmission.validate')}
                          </CustomButton>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <CustomButton disabled>
                      <IconWrapper>
                        <BsCheckAll />
                      </IconWrapper>
                      {t('pages.edocuments.emission.draftEmission.waitingValidation')}
                    </CustomButton>
                  )}
                </>
              )
            )}
          </div>
          <ValidateEmissionModal type={validationModal} isOpen={!!validationModal} closeModal={closeModal} />
          {modelFields
            .filter(field => field.type_id === CONTRACT_TYPE_FIELD_TYPE_TABLE)
            .map(field => (
              <TableFieldModal disabled={isIssued} key={field.name} field={field} />
            ))}
        </Form>
      </Spin>

      <EmissionSuccessModal isOpen={isEmissionSuccessModalOpen} close={closeEmissionSuccessModal} />

      <Modal
        visible={isExtendedFormModalOpen}
        width="70%"
        onCancel={closeExtendedFormModal}
        footer={
          <>
            <div className="buttons">
              <Button
                status="secondary"
                onClick={() => {
                  closeExtendedFormModal();
                }}
              >
                {t('pages.credit-request.modal-reports.buttons.close')}
              </Button>
            </div>
          </>
        }
      >
        <Form form={form}>
          <Row>
            <Col xl={24} xxl={25} lg={24} md={24} sm={24} xs={24}>
              <FieldsWrapper>
                {modelFields
                  .filter(field => !field.parent_id)
                  .map(field => (
                    <DraftModelField
                      isExtended
                      key={field.name}
                      field={field}
                      disabled={isIssued}
                      initialValue={field.default_value}
                      tableRegisterCount={tablesState[field.name]?.registers?.length}
                    />
                  ))}
              </FieldsWrapper>
            </Col>
          </Row>
        </Form>
      </Modal>
    </Container>
  );
};

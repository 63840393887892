import React from 'react';
import { useTranslation } from 'react-i18next';

import { Separator } from '@radix-ui/react-select';
import { ShouldRender } from 'components/ShouldRender';
import { Button } from 'components/ui/button';
import Loading from 'components/ui/loading';
import { PaginationList } from 'components/ui/pagination.list';
import { Timeline, TimelineStep } from 'components/ui/timeline';
import appConfigs from 'configs/app';
import { Teams } from 'configs/constants';
import { Can } from 'hooks/ability';
import { useAuth } from 'hooks/auth';
import { usePagination } from 'hooks/usePagination';
import { cn } from 'lib/utils';
import { FileSymlink, SquarePen } from 'lucide-react';
import moment from 'moment';
import { useGetDraftMutation } from 'pages/CreditRequest/api/useGetDraftMutation';
import { useGetDraftsQuery } from 'pages/CreditRequest/api/useGetDrafts';
import { useFormalizationGuaranteesStore } from 'pages/CreditRequest/store/useFormalizationGuaranteesStore';
import { v4 as uuid } from 'uuid';

import { ContainerRegistration } from '../../styles';

export const DraftList = () => {
  const { t } = useTranslation();
  const { paginationConfig, dispatchPagination } = usePagination();
  const { isUserOnTeam } = useAuth();

  const openDraftModal = useFormalizationGuaranteesStore(state => state.openDraftModal);
  const setSelectedDraft = useFormalizationGuaranteesStore(state => state.setSelectedDraft);
  const creditRequestId = useFormalizationGuaranteesStore(state => state.creditRequestId);

  const draftsQuery = useGetDraftsQuery({ creditRequestId, ...paginationConfig, dispatchPagination });
  const draftMutation = useGetDraftMutation();

  const handleDraft = () => openDraftModal();

  const handleOpenDocusign = (id: string) => {
    draftMutation.mutateAsync(id, {
      onSuccess: response => {
        window.open(response.url, '_blank');
      },
    });
  };

  return (
    <Loading isLoading={draftsQuery.isLoading}>
      <div className="flex flex-col gap-4 items-end overflow-y-hidden">
        {isUserOnTeam(Teams.PREFIN) ? (
          <Button permissions={['credit.request.envelope.store']} onClick={handleDraft}>
            {t('pages.credit-request.formalization-guarantees.draft.addTitle')}
          </Button>
        ) : null}
        <ContainerRegistration
          className={cn(
            'w-full bg-gray-100 rounded-lg p-4 flex justify-center ',
            !draftsQuery.data?.data?.length ? 'min-h-0' : '',
          )}
        >
          {draftsQuery.data?.data?.length ? (
            <div className="flex flex-col w-full items-end">
              <div className="gap-6 flex flex-col w-full h-full pr-4 overflow-y-auto">
                {draftsQuery?.data?.data?.map(draft => {
                  let count = 0;
                  return (
                    <div key={draft.id || uuid()} className="overflow-hidden shadow-lg rounded-lg h-min shrink-0">
                      <div className="bg-primary-200 p-2 text-md font-bold">{draft.email_subject}</div>
                      <div className="p-4 gap-6 grid-cols-4 w-full grid ">
                        <div className="flex flex-col">
                          <p className="font-bold text-gray-950">
                            {t('pages.credit-request.formalization-guarantees.pre-registration-docs.created_by')}
                          </p>
                          <p className="text-gray-950">{draft.user?.name}</p>
                        </div>
                        <div className="grid grid-cols-[repeat(2,minmax(100px,1fr))] gap-x-4 w-full col-span-2">
                          <p className="font-bold text-gray-950">
                            {t('pages.credit-request.formalization-guarantees.pre-registration-docs.signatures')}
                          </p>
                          <p className="font-bold text-gray-950 whitespace-nowrap">
                            {t('pages.credit-request.formalization-guarantees.pre-registration-docs.signature_date')}
                          </p>
                          {draft.recipients.map(recipient => (
                            <>
                              <p className="text-gray-950 mb-2" key={recipient.id + recipient.email}>
                                {recipient.name}
                              </p>
                              <p className="text-gray-950" key={recipient.id + recipient.email}>
                                {recipient.dt_sign ? moment(recipient.dt_sign).format(appConfigs.formatDate) : ''}
                              </p>
                            </>
                          ))}
                        </div>
                        <div className="flex flex-col" />
                      </div>

                      {draft.timeline.forEach(history => {
                        count += history.date ? 1 : 0;
                      })}
                      <Separator />
                      <Timeline className=" py-4">
                        {draft.timeline.map(history => {
                          return (
                            <TimelineStep
                              key={history.date + history.status}
                              title={t(
                                `pages.credit-request.formalization-guarantees.pre-registration-docs.${history.status}`,
                              )}
                              date={history.date}
                              isActive={history.status === draft.status.slug}
                            />
                          );
                        })}
                      </Timeline>
                      <div
                        className={cn(
                          'bg-primary h-min p-1 items-center px-2 text-xs text-white m-0 font-bold flex justify-between',
                          count >= 4 && draft.status.order >= 7 ? 'bg-red-600' : '',
                          count >= 4 && draft.status.order < 7 ? 'bg-green-600' : '',
                          count < 4 ? 'bg-primary' : '',
                        )}
                      >
                        <div>
                          {`${t(
                            `pages.credit-request.formalization-guarantees.pre-registration-docs.${draft.status.slug}`,
                          )} ${
                            draft.timeline[count - 1]
                              ? moment(draft.timeline[count - 1].date).format(appConfigs.formatDateTime)
                              : ''
                          }`}
                        </div>
                        <div className="flex gap-4">
                          <Can I="credit.request.envelope.update">
                            <a
                              onClick={async () => {
                                openDraftModal();
                                setSelectedDraft(draft);
                              }}
                            >
                              <SquarePen className="text-white w-5 h-5 min-w-5" />
                            </a>
                          </Can>
                          <Can I="credit.request.envelope.index">
                            <ShouldRender condition={draft.envelope_url && draft.status.order <= 2}>
                              <a
                                onClick={async () => handleOpenDocusign(draft.id)}
                                title="Agrodata"
                                rel="noopener noreferrer"
                              >
                                <FileSymlink className="text-white" />
                              </a>
                            </ShouldRender>
                          </Can>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="mt-2 relative">
                <PaginationList
                  paginationState={paginationConfig}
                  paginationDispatch={dispatchPagination}
                  isLoading={draftsQuery.isLoading || draftsQuery.isFetching}
                />
              </div>
            </div>
          ) : (
            <div className="h-full w-full flex items-center justify-center py-16">
              <p className="font-bold text-gray-400">
                {draftsQuery.isLoading
                  ? ''
                  : t('pages.credit-request.formalization-guarantees.pre-registration-docs.noDrafts')}
              </p>
            </div>
          )}
        </ContainerRegistration>
      </div>
    </Loading>
  );
};

import { FixedType } from 'rc-table/lib/interface';

export const columns = [
  {
    title: 'pages.serasa.parameterization.table.description',
    dataIndex: 'description',
    key: 'description',
    sorter: false,
    filtered: false,
  },
  {
    title: 'pages.serasa.parameterization.table.value',
    dataIndex: 'value',
    key: 'value',
    sorter: false,
    filtered: false,
  },
  {
    title: 'pages.edocuments.contracttypes.table.actions',
    dataIndex: 'action',
    key: 'action',
    width: '100px',
    fixed: 'right' as FixedType,
  },
];

import React, { useState } from 'react';
import { MdAttachFile } from 'react-icons/md';

import { message, Spin } from 'antd';
import { DraftButton } from 'pages/Contracts/components/DraftButton';

import { useAttachment } from '../../../../../../../hooks/fetch/useAttachment';
import { useDownload } from '../../../../../../../hooks/helpers/useDownload';
import { useModal } from '../../../../../../../hooks/modals/useModal';
import { downloadHandler } from '../../../../../../../services/download';
import { AttachmentsList } from '../../../../../components/AttachmentsList';
import { IconWrapper } from '../../../../../components/IconWrapper';
import { ShowAttachmentModal } from '../../../../../components/ShowAttachmentModal';
import { useContractContext } from '../../../../../context/ContractContext';
import { Container, Wrapper } from './styles';

interface Props {
  showUpload?: () => void;
  lowerButton?: boolean;
  selectedAttachmentId?: string;
  changeSelectedAttachment: (document: any) => void;
  isCompare: boolean;
}

export const Body = ({
  showUpload,
  lowerButton = false,
  changeSelectedAttachment,
  selectedAttachmentId,
  isCompare,
}: Props) => {
  const { contractAttachments, destroyAttachment, isFetching, isSelectedContractCancelled } = useContractContext();
  const { preview } = useAttachment();
  const { download } = useDownload();

  const {
    isOpen: isShowAttachmentModalOpen,
    close: closeShowAttachmentModal,
    open: openShowAttachmentModal,
  } = useModal();

  const [selectedAttachment, setSelectedAttachment] = useState<any>();

  const showAttachment = async (attachmentId: string, name: string, isDocxOrPdf: boolean) => {
    const url = await preview(attachmentId);
    if (url) {
      setSelectedAttachment({ name, url, isDocxOrPdf });
      openShowAttachmentModal();
    }
  };

  const downloadAttachment = async (attachmentId: string) => {
    message.info('Baixando anexo...');
    downloadHandler(await download(attachmentId));
  };

  return (
    <Wrapper>
      <Spin spinning={isFetching} style={{ height: '100%' }}>
        <Container lowerButton={lowerButton}>
          <AttachmentsList
            attachments={contractAttachments}
            handleDeleteAttachment={destroyAttachment}
            handleShowAttachment={showAttachment}
            handleDownloadAttachment={downloadAttachment}
            changeSelectedAttachment={changeSelectedAttachment}
            selectedAttachmentId={selectedAttachmentId}
            isCompare={isCompare}
          />

          <DraftButton onClick={showUpload ?? undefined} disabled={isSelectedContractCancelled}>
            <IconWrapper>
              <MdAttachFile />
            </IconWrapper>{' '}
            Anexar Arquivo
          </DraftButton>

          <ShowAttachmentModal
            isOpen={isShowAttachmentModalOpen}
            close={closeShowAttachmentModal}
            name={selectedAttachment?.name}
            url={selectedAttachment?.url}
            isFromGoogleDocs={selectedAttachment?.isDocxOrPdf}
          />
        </Container>
      </Spin>
    </Wrapper>
  );
};

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Breadcrumb } from 'components';
import { Button } from 'components/ui/button';
import { handleRequest } from 'services/api/api';
import dashboard from 'services/api/dashboard';

import { Report } from '../components';
import { ExposuresTable } from './exposures.table';

const Exposures = () => {
  const { t } = useTranslation();

  const [exportingGeneral, setExportingGeneral] = useState(false);

  const getReport = async (params: any) => {
    const response = await handleRequest(() => dashboard.reports.listReportTimelineExposures(params));
    return response;
  };

  const generateExport = (values: any) => {
    return dashboard.reports.generateExportTimelineExposures({ ...values });
  };

  return (
    <div className="h-full overflow-hidden flex flex-col">
      <Breadcrumb items={[{ title: t('breadcrumb.home'), to: '/home' }, { title: t('exposures') }]} />

      <div className="w-full flex justify-between p-4 pb-0">
        <span className="text-lg text-primary font-bold">{t('exposures')}</span>
        <Button
          variant="default"
          onClick={() => {
            setExportingGeneral(true);
          }}
          // permissions={['dashboard.exposure.operationalrisk.report', 'dashboard.exposure.operationalrisk.report']}
        >
          {t('pages.sustainability.form.buttonExport')}
        </Button>
      </div>

      <div className="gap-6 flex flex-col p-6 pt-0 max-h-full overflow-hidden">
        <ExposuresTable />
      </div>

      <Report
        searchFilters={[]}
        exportingGeneral={exportingGeneral}
        setExportingGeneral={setExportingGeneral}
        getReport={getReport}
        generateExport={generateExport}
        hideHarvest
        withDateFilter={false}
      />
    </div>
  );
};

export default Exposures;

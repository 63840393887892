import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Breadcrumb } from '../../../components';
import { SContainer, SHeader, STitlePage } from '../../../styles';
import { Formatter } from '../../../utils/Formatter';
import { CCard, Filters, Chart, CSkeleton, TableTimeline } from '../components';
import { TopCards, ChartsArea, TableArea, NotificationPtax } from '../styles';
import { PropsHandleOptionButtons } from '../types';
import { getCards, getItemsChart } from '../utils';
import { usePrepay } from './hooks/usePrepay';
import { propsSkeletonCard, propsSkeletonCharts, propsFilters, useChartProps } from './props';

const Prepay: React.FC = () => {
  const {
    handleCardsPrepay,
    handleChartsPrepay,
    getTimelineReport,
    generateExportTimeline,
    filterForm,
    searchFormTimeline,
    isFetchingCharts,
    isFetchingCards,
    isFetchingTimeline,
    handleChangeTableTimeline,
  } = usePrepay();
  const { propsChartPrepayOperation, propsChartPrepayLimit, dataChartPrepayProgress, dataChartPrepayExposition } =
    useChartProps();
  const { t } = useTranslation();
  const [chartEvent, setChartEvent] = useState([]);
  const [propsCards, setPropsCards] = useState([]);
  const [propsCharts, setPropsChart] = useState([]);
  const [handleOptionButtons, setHandleOptionButtons] = useState<PropsHandleOptionButtons>({
    key: undefined,
    buttonValue: 0,
  });
  const [propsChartCopy, setPropsChartCopy] = useState([]);
  const [searchFiltersTimeline, setSearchFiltersTimeline] = useState({});
  const [loading, setLoading] = useState(true);

  const getCharts = async () => {
    const { dataChartOperation, dataChartLimit, dataChartProgress, dataChartExposition } = await handleChartsPrepay();
    setPropsCards((prev: any) => [
      ...prev,
      { description: 'exposure', title: Formatter.abbreviated(parseInt(dataChartExposition.exposure, 10)), key: 3 },
      { description: 'losers', title: Formatter.abbreviated(parseInt(dataChartExposition.losers, 10)), key: 4 },
    ]);
    setPropsChart([
      propsChartPrepayOperation(dataChartOperation, t),
      propsChartPrepayLimit(dataChartLimit, t),
      dataChartPrepayProgress(dataChartProgress, t),
      dataChartPrepayExposition(dataChartExposition, t),
    ]);
  };

  const handleGet = () => {
    setPropsCards([]);
    setPropsChart([]);
    setPropsChartCopy([]);
    setChartEvent([]);
    getCards(handleCardsPrepay, setPropsCards, propsCards);
    getCharts();
  };

  useLayoutEffect(() => {
    handleGet();
  }, []);

  useEffect(() => {
    const filter = filterForm.getFieldsValue();
    if (filter) {
      filter.requesterDate = undefined;
      setSearchFiltersTimeline(filter);
    }
  }, [filterForm]);

  useEffect(() => {
    if (propsCharts.length) setPropsChartCopy(propsCharts);
  }, [propsCharts]);

  useEffect(() => {
    if (isFetchingCards && isFetchingCharts) setLoading(true);
    if (!isFetchingCards && !isFetchingCharts) setLoading(false);
  }, [isFetchingCards, isFetchingCharts]);

  const getItems = (item: any, buttonValue: any, prop: any) => {
    getItemsChart({ prop, item, buttonValue, chartEvent, setChartEvent, setPropsChart });
  };

  useEffect(() => {
    if (handleOptionButtons.key) {
      getItems(handleOptionButtons.key, handleOptionButtons.buttonValue, propsChartCopy);
    }
  }, [handleOptionButtons]);

  return (
    <>
      <Breadcrumb items={[{ title: t('breadcrumb.home'), to: '/home' }, { title: t('breadcrumb.prepay') }]} />

      <SContainer>
        <SHeader>
          <STitlePage>{t('pages.dashboard.prepay.title')}</STitlePage>
        </SHeader>
        <Filters
          props={propsFilters}
          filterForm={filterForm}
          handleGet={handleGet}
          setSearchFiltersTableFirst={setSearchFiltersTimeline}
          searchForm={[searchFormTimeline]}
        />
        <NotificationPtax>{t('pages.dashboard.prepay.notificationPTAX')}</NotificationPtax>
        <TopCards>
          {loading ? (
            <CSkeleton style={propsSkeletonCard} numberItems={propsSkeletonCard.number} />
          ) : (
            propsCards.map((item: any) => <CCard key={item.key} item={item} />)
          )}
        </TopCards>
        <ChartsArea>
          {isFetchingCharts ? (
            <CSkeleton style={propsSkeletonCharts} numberItems={propsSkeletonCharts.number} />
          ) : (
            propsCharts.map((item: any) => (
              <Chart
                key={item.key}
                chartProps={{ ...item }}
                setChartEvent={setChartEvent}
                chartEvent={chartEvent}
                getItems={getItems}
                referenceKey={item.key}
                setHandleOptionButtons={setHandleOptionButtons}
                setPropsChart={setPropsChart}
                propsChartCopy={propsChartCopy}
                propsChartsOriginal={propsCharts}
                setPropsChartCopy={setPropsChartCopy}
              />
            ))
          )}
        </ChartsArea>
        <TableArea>
          {Object.keys(searchFiltersTimeline).length && (
            <TableTimeline
              searchFilters={searchFiltersTimeline}
              handleChangeTableTimeline={handleChangeTableTimeline}
              isFetchingTimeline={isFetchingTimeline}
              setSearchFilters={setSearchFiltersTimeline}
              searchForm={searchFormTimeline}
              prepay
              title={t('pages.dashboard.timeline.title')}
              listReportPermissions={['dashboard.export.timelineprepay.index']}
              generateReportPermissions={['dashboard.export.timelineprepay.store']}
              getReport={getTimelineReport}
              generateExport={generateExportTimeline}
            />
          )}
        </TableArea>
      </SContainer>
    </>
  );
};

export default Prepay;

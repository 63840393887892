export default {
  survey: [
    {
      title: 'pages.monitoring.survey.table.header.survey_stage',
      dataIndex: 'survey_stage',
      key: 'survey_stage',
      sorter: true,
    },
    {
      title: 'pages.monitoring.survey.table.header.date_survey',
      dataIndex: 'date_survey',
      key: 'date_survey',
      sorter: true,
    },
    {
      title: 'pages.monitoring.survey.table.header.planting_start',
      dataIndex: 'planting_start',
      key: 'planting_start',
      sorter: true,
    },
    {
      title: 'pages.monitoring.survey.table.header.crop_condition',
      dataIndex: 'crop_condition',
      key: 'crop_condition',
    },
    {
      title: 'pages.monitoring.survey.table.header.survey_type',
      dataIndex: 'survey_type',
      key: 'survey_type',
      sorter: true,
    },
    {
      title: 'pages.monitoring.survey.table.header.estimated_production',
      dataIndex: 'estimated_production',
      key: 'estimated_production',
      sorter: true,
    },
    {
      title: 'pages.monitoring.survey.table.header.harvested',
      dataIndex: ['crop_stage_data', 'harvested'],
      key: 'harvested',
    },
    {
      title: 'pages.monitoring.survey.table.header.approval_status',
      dataIndex: 'approval_status',
      key: 'approval_status',
    },
    {
      title: 'pages.monitoring.survey.table.header.culture',
      dataIndex: 'culture',
      key: 'culture',
    },
    {
      title: '',
      dataIndex: 'color_condition',
      key: 'color_condition',
      sorter: false,
      align: 'center',
      width: '30px',
      fixed: 'right',
    },
    {
      title: 'pages.monitoring.survey.table.header.actions',
      key: 'action',
      dataIndex: 'action',
      align: 'center',
      width: '180px',
      fixed: 'right',
    },
  ],
  documents: [
    {
      title: 'pages.monitoring.survey.table.header.type_photo',
      dataIndex: 'type_id',
      key: 'type_id',
      sorter: false,
      filtered: false,
    },
    {
      title: 'pages.registered-customers.form.pf.documents.name',
      dataIndex: 'name',
      key: 'name',
      sorter: false,
      filtered: false,
    },
    {
      title: 'pages.monitoring.cpr.table.header.latitude',
      dataIndex: 'latitude',
      key: 'latitude',
      sorter: false,
      filtered: false,
      width: '200px',
    },
    {
      title: 'pages.monitoring.cpr.table.header.longitude',
      dataIndex: 'longitude',
      key: 'longitude',
      sorter: false,
      filtered: false,
      width: '200px',
    },
    {
      title: 'pages.registered-customers.table.headers.actions',
      dataIndex: 'action',
      key: 'action',
      width: '100px',
      fixed: 'right',
    },
  ],
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import { AiFillWarning } from 'react-icons/ai';

import { ColumnType } from 'antd/lib/table';
import { IconWithTooltip, ModalStatus } from 'components';
import { TableData } from 'compositions';
import { Can } from 'hooks/ability';
import { useTheme } from 'styled-components';

import CityData from '../../../types/Location/CityData';
import StateData from '../../../types/Location/StateData';
import { SustainabilityRegistrationData } from '../../../types/Sustainability/SustainabilityRegistrationData';
import { SustainabilityRegistrationStatusData } from '../../../types/Sustainability/SustainabilityRegistrationStatusData';
import { getDecisionColor } from '../utils';
import { columns } from './columns';

interface Props {
  registrations: SustainabilityRegistrationData[];
  removeRecord: (registrationNumber: string) => void;
  editRecord: (registrationNumber: string) => void;
  isReadOnly: boolean;
  registrationsNumbers: string[];
  isEditing?: boolean;
}

export const RegistrationsTable = ({
  registrations,
  removeRecord,
  editRecord,
  isReadOnly,
  registrationsNumbers,
  isEditing = false,
}: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const customColumns = (column: ColumnType<object>) => {
    let customColumn = {
      ...column,
      title: t(column.title.toString()),
    } as ColumnType<object>;

    switch (column.key) {
      case 'registration_number':
        customColumn = {
          ...customColumn,
          render: (registration_number: string) => {
            const existsInContract = registrationsNumbers.includes(registration_number);
            return (
              <span style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
                {!existsInContract && !isEditing && <AiFillWarning size={20} color={theme.colors.danger} />}
                {registration_number ?? ''}
              </span>
            );
          },
        };
        break;
      case 'city':
        customColumn = {
          ...customColumn,
          render: (county: CityData) => {
            return county?.name ?? '';
          },
        };
        break;
      case 'state':
        customColumn = {
          ...customColumn,
          render: (uf: StateData, value: any) => {
            return value?.city?.state?.name ?? '';
          },
        };
        break;
      case 'sustainability_registration_status':
        customColumn = {
          ...customColumn,
          render: (status: SustainabilityRegistrationStatusData) => {
            return (
              <span style={{ color: getDecisionColor(status?.id, true), fontWeight: 'bold' }}>
                {status?.name ?? t('inAnalysis').toUpperCase()}
              </span>
            );
          },
        };
        break;
      default:
        return customColumn;
    }

    return customColumn;
  };

  const getActions = () => ({
    render: (active: number, record: SustainabilityRegistrationData) => (
      <>
        {!isReadOnly && (
          <Can I="sustainability.registration.destroy" a="">
            <IconWithTooltip
              action="destroy"
              title={t('pages.admin.role.table.row.removeIcon')}
              onClick={() => {
                ModalStatus({
                  type: 'delete',
                  title: t('pages.admin.permission.delete.title'),
                  subTitle: t('pages.admin.permission.delete.subtitle'),
                  cancelText: t('pages.admin.permission.delete.cancel'),
                  okText: t('pages.admin.permission.delete.confirm'),
                  onOk: () => {
                    removeRecord(record.registration_number);
                  },
                });
              }}
            />
          </Can>
        )}
        <Can I="sustainability.registration.update" a="">
          <IconWithTooltip
            action="edit"
            title={t('pages.admin.role.table.row.editIcon')}
            onClick={() => editRecord(record.id)}
          />
        </Can>
      </>
    ),
  });

  return (
    <TableData
      columns={columns.map(column => customColumns(column))}
      dataSource={registrations}
      tableConfig={{
        getActions,
        nopagination: true,
      }}
      pagination={{ position: ['bottomCenter'] }}
      loading={false}
    />
  );
};

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaPlus, FaUpload } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';

import {
  Form as AntForm,
  Tabs as ATabs,
  Col,
  Dropdown,
  Form,
  Menu,
  message,
  Modal,
  Pagination,
  Radio,
  Upload,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { checkIfFilePreviewerSupportsExtension, FilePreviewer } from 'components/FilePreviewer';
import PermissionedComponent from 'components/PermissionedComponent';
import { Can, useAbility } from 'hooks/ability';
import { useCreditRequest } from 'hooks/fetch/useCreditRequest';
import { useDocuments } from 'hooks/fetch/useDocuments';
import { useSustainability } from 'hooks/fetch/useSustainability';
import { useSustainabilityRegistration } from 'hooks/fetch/useSustainabilityRegistration';
import { useDownload } from 'hooks/helpers/useDownload';
import { useModalMessage } from 'hooks/helpers/useModalMessage';
import { useModal } from 'hooks/modals/useModal';
import moment from 'moment';
import datasulApi from 'services/api/datasul';
import { useTheme } from 'styled-components';
import { ThirdProviderEnum } from 'types/CreditRequest/enums/ThirdProvidersEnum';
import { Formatter } from 'utils/Formatter';
import { v4 as uuid } from 'uuid';

import { SustainabilityRegistrationData } from '../../types/Sustainability/SustainabilityRegistrationData';
// import { useCreditRequest } from 'hooks/fetch/useCreditRequest';
import DTOErrorReponse from '../../@types/dtos/DTOErrorReponse';
import {
  Button,
  FixedFooter,
  FormItem,
  IconWithTooltip,
  Input,
  ModalStatus,
  Row,
  Select,
  Spin,
  Tabs,
} from '../../components';
import appConfigs from '../../configs/app';
import {
  PHYSICAL_PERSON_TYPE,
  SUSTAINABILITY_DECISION_APPROVED_WITH_RESERVATIONS,
  SUSTAINABILITY_DECISION_DISAPPROVED,
  SUSTAINABILITY_NEXT_STATUS_FINISHED,
  SUSTAINABILITY_NEXT_STATUS_REQUESTED,
  SUSTAINABILITY_NEXT_UNBLOCKED,
  SUSTAINABILITY_NEXT_WAITING_UNBLOCK,
  SUSTAINABILITY_REGISTRATION_IN_ANALYSIS,
  SUSTAINABILITY_REGISTRATION_STATUS_IN_ANALYSIS,
  SUSTAINABILITY_STATUS_BLOQUEIO,
  SUSTAINABILITY_STATUS_CANCELLED,
  SUSTAINABILITY_STATUS_FINALIZADO,
  SUSTAINABILITY_STATUS_PREFIN,
  SUSTAINABILITY_STATUS_PROSPECCAO,
  SUSTAINABILITY_STATUS_REPROVED,
  SUSTAINABILITY_STATUS_UNBLOCKED,
  SUSTAINABILITY_STATUS_WAITING_UNBLOCK,
} from '../../configs/constants';
import { useCache } from '../../hooks/cache';
import { ItemsType, usePage } from '../../hooks/page';
import documentApi from '../../services/api/document';
import sustainabilityApi from '../../services/api/sustainability';
import { downloadHandler } from '../../services/download';
import { downloadDocument, getOriginalName } from '../../services/files';
import { formatValuesToFormData } from '../../services/form';
import type CreditRequestData from '../../types/CreditRequest/CreditRequestData';
import DTODatasulProvider from '../../types/Datasul/DTODatasulProvider';
import renderComponent from '../../utils/RenderComponent';
import { BlockForm } from './BlockForm';
import Comments from './Comments';
import HistoricStatus from './HistoricStatus';
import { NewRegistrationModal } from './NewRegistrationModal';
import { PrefinForm } from './PrefinForm';
import { ProspectionForm } from './ProspectionForm';
import { RegistrationsTable } from './RegistrationsTable';
import { RequestsContract } from './RequestsContract';
import { Container, FileContainer, RegistrationHeader, RegistrationHeaderTitle, StatusLink } from './styles';
import { PageParams } from './types';

const sustainabilityReturnFields = [
  'is_ibama',
  'is_sema',
  'is_illegal_deforestation',
  'is_green_grain_protocol',
  'is_moratorium',
  'is_ibama_area',
  'is_sema_area',
  'is_moratorium_area',
  'is_historical_moratorium',
  'is_prodes_amazon',
  'is_prodes_cerrado',
  'is_indigenous_land',
  'is_conservation_unit',
  'is_settlements',
  'is_have_condition',
  'is_pendencies',
];

const Sustainability: React.FC = () => {
  const [formModalStatus] = Form.useForm();
  const {
    close: closeNewRegistrationModal,
    isOpen: isNewRegistrationModalOpen,
    open: openNewRegistrationModal,
  } = useModal();
  const { destroy: destroyRegistration, isDeleting } = useSustainabilityRegistration();
  const { downloadMultiple } = useDownload();
  const { showReport, getReportStatusesOptions } = useSustainability();
  const theme = useTheme();
  const { handleError } = useModalMessage();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [form] = Form.useForm();
  const { alertStatus } = usePage();
  const cache = useCache();
  const { destroyFile } = useDocuments();
  const params = useParams<PageParams>();
  const { id: sustainabilityId, creditrequest: creditRequestId } = params;
  const clonedSustainabilityId = new URLSearchParams(window.location.search).get('clonedSustainabilityId');
  const { show: getCreditRequest } = useCreditRequest();
  const ability = useAbility();

  const { TabPane } = ATabs;
  const [isLoadingDocuments, setIsLoadingDocuments] = useState(false);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [modalLoading, setModalLoading] = useState<boolean>(false);
  const [fileToPreview, setFileToPreview] = useState<any>(null);

  const [documentLoading, setDocumentLoading] = useState('');
  const [statusCurrent, setStatusCurrent] = useState({ name: '', id: '' });
  const [statusNext, setStatusNext] = useState('');
  const [documentList, setDocumentList] = useState<any[]>([]);
  const [documentListStatus, setDocumentListStatus] = useState<any[]>([]);
  const [listOfNextTeamStatus, setListOfNextTeamStatus] = useState([]);
  const [documentRemoving, setDocumentRemoving] = useState('');
  const [documentTotal, setDocumentTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [disabledCodeDataSul, setDisabledCodeDataSul] = useState(false);
  const [disabledProvider, setDisabledProvider] = useState(true);
  const [visibleDivergentNf, setVisibleDivergentNf] = useState(false);
  const [statusReadOnly, setStatusReadOnly] = useState(false);
  const [isRemovingRegistration, setIsRemovingRegistration] = useState(false);
  const [visibleRequestsContract, setVisibleRequestsContract] = useState(false);
  const [isContractVisible, setIsContractVisible] = useState(false);
  const [registrationsNumbers, setRegistrationsNumbers] = useState<string[]>([]);
  const [decisionId, setDecisionId] = useState('');
  const [thirdProviders, setThirdProviders] = useState<ThirdProviderEnum[]>([]);

  const [type, setType] = useState('');
  const [typePerson, setTypePerson] = useState(PHYSICAL_PERSON_TYPE);
  const [creditRequest, setCreditRequest] = useState<CreditRequestData | undefined>();

  const [registrations, setRegistrations] = useState<SustainabilityRegistrationData[]>([]);
  const [registrationToEditId, setRegistrationToEditId] = useState<string>('');
  const [hasNotSavedEmptySustainabilityReturnFields, setHasNotSavedEmptySustainabilityReturnFields] = useState(true);

  const docRef = useRef<HTMLInputElement>();
  const providerRef = useRef<HTMLInputElement>();

  const isPrefinComingFromCreditRequest = useMemo(() => !!creditRequestId, [creditRequestId]);
  const isEditing = sustainabilityId;
  const isCloning = clonedSustainabilityId;
  const shouldLoadRequestData = isEditing || isCloning;

  const checkEmptySustainabilityReturnFields = (customValues?: any) => {
    const values = customValues || form.getFieldsValue();
    return !!Object.keys(values).filter(key => {
      if (!sustainabilityReturnFields.includes(key)) return false;

      return values[key] === null;
    }).length;
  };

  const handleRemoveRegistration = (registrationNumber: string) => {
    if (registrations.length <= 1) {
      ModalStatus({ type: 'error', subTitle: t('pages.sustainability.form.error.oneRegistration') });
    } else {
      setRegistrations(oldRegistrations => {
        const newRegistrations = [...oldRegistrations];
        const registrationIndex = oldRegistrations.findIndex(
          registration => registration.registration_number === registrationNumber,
        );

        const registrationId = oldRegistrations[registrationIndex].id;
        try {
          if (registrationId) {
            setIsRemovingRegistration(true);
            destroyRegistration(registrationId).then(() => {
              setIsRemovingRegistration(false);
            });
          }

          newRegistrations.splice(registrationIndex, 1);
        } catch (err) {
          ModalStatus({ type: 'error', description: err as any });
        }

        return newRegistrations;
      });
    }
  };

  const handleEditRegistration = (registrationId: string) => {
    setRegistrationToEditId(registrationId);
    openNewRegistrationModal();
  };

  const hasRegistrationsInAnalysis = !!registrations.filter(
    registration =>
      registration.sustainability_registration_status_id === SUSTAINABILITY_REGISTRATION_STATUS_IN_ANALYSIS,
  ).length;

  // sustainabilitTypes
  const [sustainabilitTypes, setSustainabilitTypes] = useState<ItemsType[]>(() => {
    let sustainabilitTypesOptions: ItemsType[] = [];
    sustainabilityApi.reporttype
      .get()
      .then((response: any) => {
        const { data: sustainabilitTypeData } = response.data;
        sustainabilitTypesOptions = sustainabilitTypeData.map((sustainabilitType: { id: any; name: any }) => ({
          key: sustainabilitType.id,
          value: sustainabilitType.id,
          label: sustainabilitType.name,
        }));
        if (!creditRequestId && !sustainabilityId)
          sustainabilitTypesOptions = sustainabilitTypesOptions.filter(
            option => option.value !== SUSTAINABILITY_STATUS_PREFIN,
          );
        setSustainabilitTypes(sustainabilitTypesOptions);
      })
      .catch((err: DTOErrorReponse) => alertStatus(err, 'error'));
    return sustainabilitTypesOptions;
  });

  // sustainabilitDecisions
  const [sustainabilitDecisions, setSustainabilitDecisions] = useState<ItemsType[]>(() => {
    let sustainabilitDecisionsOptions: ItemsType[] = [];
    sustainabilityApi.reportdecisions
      .get()
      .then((response: any) => {
        const { data: sustainabilitDecisionsData } = response.data;
        sustainabilitDecisionsOptions = sustainabilitDecisionsData.map(
          (sustainabilitDecision: { id: any; name: any }) => ({
            key: sustainabilitDecision.id,
            value: sustainabilitDecision.id,
            label: sustainabilitDecision.name,
          }),
        );
        setSustainabilitDecisions(sustainabilitDecisionsOptions);
      })
      .catch((err: DTOErrorReponse) => alertStatus(err, 'error'));
    return sustainabilitDecisionsOptions;
  });

  const addRegistration = (registration: SustainabilityRegistrationData) => {
    setRegistrations(oldRegistrations => [
      ...oldRegistrations,
      { ...registration, id: `temp-${uuid()}`, documents: registration.documents ?? [] },
    ]);
  };

  const editRegistration = (registration: SustainabilityRegistrationData) => {
    setRegistrations(oldRegistrations => {
      const newRegistrations = [...oldRegistrations];

      const registrationBeingEditedIndex = oldRegistrations.findIndex(
        oldRegistration => oldRegistration.id === registration.id,
      );

      newRegistrations[registrationBeingEditedIndex] = {
        ...registration,
        documents: [
          ...newRegistrations[registrationBeingEditedIndex].documents.filter(document => !!document.id),
          ...registration.documents,
        ],
      };

      return newRegistrations;
    });
    closeNewRegistrationModal();
  };

  function handleModalClose() {
    setModalVisible(false);
    setModalLoading(false);
  }

  const handleSuccess = (messageSuccess: string) =>
    ModalStatus({
      type: 'success',
      title: t('modal.success'),
      subTitle: messageSuccess,
    });

  const handleChangeProfile = (value: any) => {
    if (value) setTypePerson(value.toString());
  };

  const handlePreviewFile = (document: any) => setFileToPreview(document);

  const storeRequest = async (formData: FormData) => {
    sustainabilityApi.reports
      .store(formData)
      .then(async (response: any) => {
        handleSuccess(response.message);
        form.resetFields();
        setRegistrations([]);
        setDocumentList([]);
        setType('');
        setLoading(false);
        setTypePerson(PHYSICAL_PERSON_TYPE);
        if (clonedSustainabilityId) navigate(`/sustainability/request-report/${response.data.id}`);
      })
      .catch((err: DTOErrorReponse) => {
        alertStatus(err, 'error');
        setLoading(false);
      });
  };

  async function handleSubmit() {
    if (!registrations.length) {
      ModalStatus({ type: 'error', subTitle: t('pages.sustainability.form.error.noRegistration') });
    } else {
      setLoading(true);
      form
        .validateFields()
        .then(() => {
          const formValues = form.getFieldsValue();
          formValues.language = i18n.language;
          let formData = new FormData();
          formData = formatValuesToFormData(formValues, formData, [], ['documents']);

          formData.append(
            'start_date',
            formValues.start_date ? moment(formValues.start_date).format(appConfigs.formatApiDate) : '',
          );

          formData.append(
            'end_date',
            formValues.end_date ? moment(formValues.end_date).format(appConfigs.formatApiDate) : '',
          );

          documentList.forEach((document: any, index) => {
            if (clonedSustainabilityId && document.id) formData.append(`documents[${index}][id]`, document.id);
            else formData.append(`documents[${index}][file]`, document.file);
            formData.append(`documents[${index}][name]`, document.name);
            formData.append(`documents[${index}][type_id]`, document.type_id);
          });

          registrations.forEach((registration, index) => {
            formData.append(`sustainability_registrations[${index}][state_id]`, registration.city.state_id);
            formData.append(`sustainability_registrations[${index}][city_id]`, registration.city_id);
            formData.append(
              `sustainability_registrations[${index}][registration_number]`,
              registration.registration_number,
            );
            formData.append(`sustainability_registrations[${index}][farm]`, registration.farm);

            formData.append(`sustainability_registrations[${index}][comments]`, registration.comments ?? '');
            formData.append(`sustainability_registrations[${index}][owner]`, registration.owner);

            registration.documents.forEach((document, registrationDocumentIndex) => {
              if (clonedSustainabilityId && document.id) {
                formData.append(
                  `sustainability_registrations[${index}][documents][${registrationDocumentIndex}][id]`,
                  document.id,
                );
              } else
                formData.append(
                  `sustainability_registrations[${index}][documents][${registrationDocumentIndex}][file]`,
                  document,
                );

              formData.append(
                `sustainability_registrations[${index}][documents][${registrationDocumentIndex}][name]`,
                document.name,
              );
              // formData.append(
              //   `sustainability_registrations[${index}][documents][${registrationDocumentIndex}][type_id]`,
              //   document.type_id,
              // );
            });
          });

          if (clonedSustainabilityId) formData.append('cloned', '1');

          if (creditRequestId) {
            formData.append(
              'provider',
              creditRequest?.requester_person?.name || creditRequest?.requester_person?.company_name || '',
            );
            formData.append(
              'doc_number',
              creditRequest?.requester_person?.cpf || creditRequest?.requester_person?.cnpj || '',
            );
          }

          if (documentList.length === 0) {
            ModalStatus({
              type: 'error',
              subTitle: t('pages.sustainability.form.error.noDocuments'),
              onOk: () => setLoading(false),
              onCancel: () => setLoading(false),
            });
          } else {
            storeRequest(formData);
            setVisibleRequestsContract(false);
          }
        })
        .catch((err: DTOErrorReponse) => {
          alertStatus(err, 'error');
          setLoading(false);
        });
    }
  }

  const handleClone = async () => {
    window.open(`/sustainability/request-report?clonedSustainabilityId=${sustainabilityId}`, '_blank');
  };

  const handleEdit = () => {
    if (!registrations.length) {
      ModalStatus({ type: 'error', subTitle: t('pages.sustainability.form.error.noRegistration') });
    } else {
      setLoading(true);
      form
        .validateFields()
        .then(() => {
          const formValues = form.getFieldsValue();
          if (!formValues.check_contract_number) {
            formValues.contract_number = '';
          }

          let formData = new FormData();
          formValues.documents = [];
          formData = formatValuesToFormData(formValues, formData);

          formData.append(
            'start_date',
            formValues.start_date ? moment(formValues.start_date).format(appConfigs.formatApiDate) : '',
          );

          formData.append(
            'end_date',
            formValues.end_date ? moment(formValues.end_date).format(appConfigs.formatApiDate) : '',
          );

          documentList.forEach((document: any, index) => {
            if (document.file) formData.append(`documents[${index}][file]`, document.file);
            else formData.append(`documents[${index}][id]`, document.id);
            formData.append(`documents[${index}][name]`, document.name);
            formData.append(`documents[${index}][type_id]`, document.type_id);
          });

          registrations.forEach((registration, index) => {
            if (registration.id && !registration.id.includes('temp-'))
              formData.append(`sustainability_registrations[${index}][id]`, registration.id);
            formData.append(`sustainability_registrations[${index}][state_id]`, registration.city.state_id);
            formData.append(`sustainability_registrations[${index}][city_id]`, registration.city_id);
            formData.append(
              `sustainability_registrations[${index}][registration_number]`,
              registration.registration_number,
            );
            formData.append(`sustainability_registrations[${index}][farm]`, registration.farm);

            formData.append(`sustainability_registrations[${index}][comments]`, registration.comments ?? '');
            formData.append(`sustainability_registrations[${index}][owner]`, registration.owner);

            registration.documents.forEach((document, registrationDocumentIndex) => {
              if (document.id)
                formData.append(
                  `sustainability_registrations[${index}][documents][${registrationDocumentIndex}][id]`,
                  document.id,
                );
              else
                formData.append(
                  `sustainability_registrations[${index}][documents][${registrationDocumentIndex}][file]`,
                  document,
                );
            });
          });

          formData.append('_method', 'PUT');

          if (sustainabilityId)
            sustainabilityApi.reports
              .update(sustainabilityId, formData)
              .then(async (response: any) => {
                handleSuccess(response.message);
                loadData();
                setLoading(false);
              })
              .catch((err: DTOErrorReponse) => {
                alertStatus(err, 'error');
                setLoading(false);
              });
        })
        .catch((err: DTOErrorReponse) => {
          alertStatus(err, 'error');
          setLoading(false);
        });
    }
  };

  async function handleModalChange() {
    setModalLoading(true);

    formModalStatus
      .validateFields()
      .then(async (formValues: any) => {
        formValues['documents_status[]'] = documentListStatus;

        let formData = new FormData();
        formData = formatValuesToFormData(formValues, formData, ['documents_status[]']);

        documentListStatus
          .filter((entry: any) => entry.file)
          .forEach((document: any, index) => {
            formData.append(`documents_status[${index}][file]`, document.file);
            formData.append(`documents_status[${index}][name]`, document.name);
            formData.append(`documents_status[${index}][type_id]`, document.type_id);
          });

        formData.append('language', i18n.language);
        formData.append('sustainability_report_id', sustainabilityId ?? '');
        formData.append('_method', 'PATCH');

        if (sustainabilityId)
          await sustainabilityApi.reports
            .updateStatus(sustainabilityId, formData)
            .then(() => {
              alertStatus(t('hooks.creditStatus.modal-status-success'));
              formModalStatus.setFieldsValue({ comments: '', 'documents_status[]': [] });
              setDocumentListStatus([]);
              loadData();
            })
            .catch(err => alertStatus(err || t('hooks.creditStatus.modal-status-unknown-error'), 'error'))
            .finally(() => {
              handleModalClose();
            });
      })
      .catch(() => {
        setModalLoading(false);
      });

    return false;
  }

  async function uploadFiles(info: any) {
    const file: any = {
      file: info.file,
      name: getOriginalName(info.file.name, true),
      type_id: null,
    };

    setDocumentList((documentListState: any[]) => [...(documentListState as any[]), file]);
  }

  const fileTypes = useMemo(() => {
    return cache.getCreditOptions('document_type').filter((x: any) => x.tag === 'sustainability');
  }, [cache]);

  const operationTypes = useMemo(() => {
    return cache.getCreditOptions('operation').filter((x: any) => x.tag === 'sustainability');
  }, [cache]);

  const loadDocuments = async (docParams = {}) => {
    if (!sustainabilityId) return;
    const response = await sustainabilityApi.reports.getDocuments(sustainabilityId, docParams);
    setDocumentList(response.data.data ?? []);
    setDocumentTotal(response.data.total);
    return response.data.data;
  };

  const handleChangeDocumentList = async (page: number, pageSize: number) => {
    try {
      setIsLoadingDocuments(true);
      const response = await loadDocuments({ params: { page, per_page: pageSize } });
      form.setFieldsValue({ documents: response });
    } catch (err) {
      alertStatus(err as string, 'error');
    } finally {
      setIsLoadingDocuments(false);
    }
  };

  const handleDownloadAllDocuments = async () => {
    message.loading(t('messages.downloadingAllFiles'), 3);
    await downloadMultiple(documentList.map(document => document.id));
    setDocumentList(documentList.map(document => ({ ...document, downloads_user: 1 })));
  };

  const loadPrefinFields = () => {
    setType(SUSTAINABILITY_STATUS_PREFIN);
    form.setFieldsValue({
      sustainability_report_type_id: SUSTAINABILITY_STATUS_PREFIN,
      credit_request_id: creditRequestId,
    });
  };

  const updateStatusReadOnly = (sustainabilityReportStatusId: string) => {
    const isFinished = sustainabilityReportStatusId === SUSTAINABILITY_STATUS_FINALIZADO;
    // const hasReservation = sustainabilityDecisionId === SUSTAINABILITY_APROVADO_RESSALVAS;
    // const isFinishedWithReservation = isFinished && hasReservation;
    const isCancelled = sustainabilityReportStatusId === SUSTAINABILITY_STATUS_CANCELLED;
    const isWaitingUnblock = sustainabilityReportStatusId === SUSTAINABILITY_STATUS_WAITING_UNBLOCK;
    const isUnblocked = sustainabilityReportStatusId === SUSTAINABILITY_STATUS_UNBLOCKED;
    const isReproved = sustainabilityReportStatusId === SUSTAINABILITY_STATUS_REPROVED;
    const isReadOnly = isCancelled || isWaitingUnblock || isUnblocked || isFinished || isReproved;

    setStatusReadOnly(isReadOnly);
  };

  const formatRegistrationsToClone = (clonedRegistrations: SustainabilityRegistrationData[]) =>
    clonedRegistrations.map(clonedRegistration => ({
      ...clonedRegistration,
      sustainability_registration_status_id: SUSTAINABILITY_REGISTRATION_IN_ANALYSIS,
      sustainability_registration_status: {
        ...clonedRegistration.sustainability_registration_status,
        id: SUSTAINABILITY_REGISTRATION_IN_ANALYSIS,
        name: 'EM ANÁLISE',
      },
    }));

  const getNextStatusList = async (sustainabilityReportStatusId: string) => {
    if (!sustainabilityId) return;
    const statusesOptions = await getReportStatusesOptions(sustainabilityReportStatusId, sustainabilityId);
    setListOfNextTeamStatus(statusesOptions || []);
    return statusesOptions;
  };

  const loadData = useCallback(async () => {
    if (isPrefinComingFromCreditRequest) loadPrefinFields();

    if (!shouldLoadRequestData) return;

    setLoading(true);
    const data = await showReport(clonedSustainabilityId || sustainabilityId || '');
    if (!data) return handleError(t('noPermission'), () => navigate('/sustainability/requests'));

    const {
      sustainability_report_type,
      sustainability_report_status,
      documents,
      contract_number,
      nf_issuer_name,
      requester_person_type,
      registrations: registrationsData,
      description,
      start_date,
      end_date,
      sustainability_report_status_id,
      sustainability_decision_id,
      credit_request,
    } = data;

    setType(sustainability_report_type.id);
    setStatusCurrent({ name: sustainability_report_status.name, id: sustainability_report_status.id });
    setDocumentList(documents);
    setIsContractVisible(!!contract_number);
    setVisibleDivergentNf(!!nf_issuer_name);
    setTypePerson(requester_person_type ?? '');
    setCreditRequest(credit_request);
    setDecisionId(sustainability_decision_id ?? '');
    if (registrationsData)
      setRegistrations(isCloning ? formatRegistrationsToClone(registrationsData) : registrationsData);

    const dataThirdProviders: ThirdProviderEnum[] = [];
    if (data.code_datasul) dataThirdProviders.push('datasul');
    if (data.cod_sap) dataThirdProviders.push('sap');
    setThirdProviders(dataThirdProviders);

    form.setFieldsValue({
      ...data,
      description: description ?? '',
      check_contract_number: !!contract_number,
      start_date: start_date ? Formatter.date(start_date, true) : undefined,
      end_date: end_date ? Formatter.date(end_date, true) : undefined,
    });

    if (data) setHasNotSavedEmptySustainabilityReturnFields(checkEmptySustainabilityReturnFields(data));

    if (!isCloning) {
      updateStatusReadOnly(sustainability_report_status_id);
      await getNextStatusList(sustainability_report_status_id);
    }
    setLoading(false);
  }, [sustainabilityId]);

  const handleFindProvider = useCallback(async () => {
    const formValues = form.getFieldsValue();

    if (!formValues.doc_number) {
      return;
    }
    const cpf = formValues.doc_number.replace(/\D/g, '');

    setLoading(true);

    await datasulApi.provider
      .search({ params: { search: cpf } }, true)
      .then(response => {
        const data: DTODatasulProvider = response.data as DTODatasulProvider;

        const dataThirdProviders: ThirdProviderEnum[] = [];
        if (data.cod_datasul) dataThirdProviders.push('datasul');
        if (data.cod_sap) dataThirdProviders.push('sap');
        setThirdProviders(dataThirdProviders);

        setVisibleRequestsContract(true);
        setDisabledCodeDataSul(true);
        setDisabledProvider(true);
        form.setFieldsValue({
          code_datasul: data.cod_datasul ? data.cod_datasul : undefined,
          cod_sap: data.cod_sap,
          provider: data.name ? data.name : data.fantasy_name,
        });
      })
      .catch((err: any) => {
        setDisabledCodeDataSul(true);
        setDisabledProvider(true);

        if (type !== SUSTAINABILITY_STATUS_PROSPECCAO && type !== SUSTAINABILITY_STATUS_BLOQUEIO) {
          ModalStatus({
            type: 'error',
            title: t('modal.error'),
            subTitle: err.response.data,
          });
          return;
        }
        setDisabledProvider(false);

        if (type === SUSTAINABILITY_STATUS_BLOQUEIO) setDisabledCodeDataSul(false);

        ModalStatus({
          type: 'confirm',
          title: t('pages.sustainability.form.providerNotFound'),
          subTitle: t('pages.sustainability.form.providerNotFoundDescription'),
          onOk: () => {
            if (providerRef.current) providerRef.current?.focus();
          },
          onCancel: () => {
            setDisabledProvider(true);
            if (docRef.current) docRef.current?.focus();
          },
        });
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line
  }, [type]);

  const removeFileFromState = (file: any) => {
    setDocumentList(documentList.filter(d => d.id !== documentList[file.name].id));
  };

  const handleRemoveFile = async (file: any) => {
    setDocumentRemoving(documentList[file.name]?.id);

    if (documentList[file.name].id) {
      if (!clonedSustainabilityId) await destroyFile(documentList[file.name].id);
      removeFileFromState(file);
    } else {
      setDocumentList(documentList.filter((doc: any, index: any) => index !== file.name));
    }
    setDocumentRemoving('');
  };

  useEffect(() => {
    loadData();
  }, [loadData, clonedSustainabilityId]);

  useEffect(() => {
    form.setFieldsValue({ documents: documentList });
    formModalStatus.setFieldsValue({ documents_status: documentListStatus });
  }, [documentList, documentListStatus]);

  useEffect(() => {
    const loadCreditRequestData = async () => {
      if (creditRequestId) {
        const response = await getCreditRequest(creditRequestId);
        setCreditRequest(response);
      }
    };
    loadCreditRequestData();
  }, [creditRequestId]);

  return (
    <Container>
      <Spin spinning={loading || isRemovingRegistration || isDeleting}>
        <Tabs defaultActiveKey="tab-general-data">
          <TabPane tab={t('pages.sustainability.form.request_data')} key="tab-general-data">
            <div className="content-pannel">
              <>
                <Form form={form} initialValues={{ harvest_id: cache.current_harvest }}>
                  <Row gutter={[24, 24]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                      <h2>{t('pages.sustainability.title.data_to_request')}</h2>
                    </Col>
                  </Row>
                  {sustainabilityId && (
                    <Row gutter={[24, 24]}>
                      <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
                        <FormItem label={t('pages.sustainability.form.number')} name="number">
                          <Input disabled />
                        </FormItem>
                      </Col>
                    </Row>
                  )}
                  <Row gutter={[24, 24]}>
                    <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
                      <FormItem
                        rules={[{ required: true }]}
                        label={t('pages.sustainability.form.type')}
                        name="sustainability_report_type_id"
                      >
                        <Select
                          onChange={(value: any) => {
                            setType(value);
                            if (value === SUSTAINABILITY_STATUS_BLOQUEIO) setIsContractVisible(true);
                          }}
                          options={sustainabilitTypes}
                          disabled={
                            statusReadOnly ||
                            sustainabilityId !== undefined ||
                            !!creditRequestId ||
                            !!clonedSustainabilityId
                          }
                        />
                      </FormItem>
                    </Col>

                    <PrefinForm
                      isFormVisible={type === SUSTAINABILITY_STATUS_PREFIN}
                      statusReadOnly={statusReadOnly}
                      creditIdEdit={creditRequest?.id ?? ''}
                      form={form}
                      sustainabilityId={sustainabilityId}
                      creditRequest={creditRequest}
                    />

                    <BlockForm
                      isFormVisible={type === SUSTAINABILITY_STATUS_BLOQUEIO}
                      statusReadOnly={statusReadOnly}
                      form={form}
                      operationTypes={operationTypes}
                      setDisabledCodeDataSul={setDisabledCodeDataSul}
                      setDisabledProvider={setDisabledProvider}
                      setLoading={setLoading}
                      typePerson={typePerson}
                      setTypePerson={setTypePerson}
                      handleFindProvider={handleFindProvider}
                      handleChangeProfile={handleChangeProfile}
                      disabledCodeDataSul={disabledCodeDataSul}
                      disabledProvider={disabledProvider}
                      sustainabilityId={sustainabilityId}
                      setVisibleRequestsContract={setVisibleRequestsContract}
                      isClone={!!clonedSustainabilityId}
                      setIsContractVisible={setIsContractVisible}
                      isContractVisible={isContractVisible}
                      setRegistrationsNumbers={setRegistrationsNumbers}
                      thirdProviders={thirdProviders}
                    />

                    {type === SUSTAINABILITY_STATUS_PROSPECCAO && (
                      <>
                        <Col xs={24} sm={24} md={24} lg={16} xl={16} xxl={16}>
                          <h3>
                            <FormItem name="is_financing" label={t('pages.sustainability.form.is_financing')}>
                              <Radio.Group buttonStyle="solid">
                                <Radio.Button value>{t('pages.edocuments.contracttypes.form.yes')}</Radio.Button>
                                <Radio.Button value={false}>{t('pages.edocuments.contracttypes.form.no')}</Radio.Button>
                              </Radio.Group>
                            </FormItem>
                          </h3>
                        </Col>
                      </>
                    )}

                    <ProspectionForm
                      isFormVisible={type === SUSTAINABILITY_STATUS_PROSPECCAO}
                      handleChangeProfile={handleChangeProfile}
                      handleFindProvider={handleFindProvider}
                      statusReadOnly={statusReadOnly}
                      typePerson={typePerson}
                      disabledProvider={disabledProvider}
                      sustainabilityId={sustainabilityId}
                      docRef={docRef}
                      providerRef={providerRef}
                      thirdProviders={thirdProviders}
                    />
                  </Row>
                  {type === SUSTAINABILITY_STATUS_BLOQUEIO && (
                    <>
                      <div className="divergent_nf">
                        <Row style={{ backgroundColor: '#f7e2c9' }} gutter={[24, 24]}>
                          <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
                            <FormItem
                              name="is_divergent_nf"
                              label={t('pages.sustainability.form.is_divergent_nf')}
                              rules={[{ required: true }]}
                            >
                              <Radio.Group buttonStyle="solid" disabled={statusReadOnly}>
                                <Radio.Button onClick={() => setVisibleDivergentNf(true)} value>
                                  {t('pages.sustainability.form.yes')}
                                </Radio.Button>
                                <Radio.Button
                                  onClick={() => {
                                    form.setFieldsValue({ nf_issuer_name: '', nf_issuer_document: '' });
                                    setVisibleDivergentNf(false);
                                  }}
                                  value={false}
                                >
                                  {t('pages.sustainability.form.no')}
                                </Radio.Button>
                              </Radio.Group>
                            </FormItem>
                          </Col>

                          {visibleDivergentNf && (
                            <>
                              <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
                                <FormItem
                                  label={t('pages.sustainability.form.nf_issuer_name')}
                                  name="nf_issuer_name"
                                  rules={[{ required: true }]}
                                >
                                  <Input disabled={statusReadOnly} maxLength={255} />
                                </FormItem>
                              </Col>
                              <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
                                <FormItem
                                  label={t('pages.sustainability.form.nf_issuer_document')}
                                  name="nf_issuer_document"
                                  rules={[{ required: true }]}
                                >
                                  <Input disabled={statusReadOnly} maxLength={255} />
                                </FormItem>
                              </Col>
                            </>
                          )}
                        </Row>
                      </div>
                    </>
                  )}
                  <RegistrationHeader>
                    <RegistrationHeaderTitle>{t('pages.sustainability.title.enrollment')}</RegistrationHeaderTitle>
                    {!statusReadOnly && (
                      <Can I="sustainability.registration.store" a="">
                        <button
                          aria-label={t('pages.sustainability.title.enrollment')}
                          type="button"
                          onClick={openNewRegistrationModal}
                        >
                          <span>
                            <FaPlus />
                          </span>
                        </button>
                      </Can>
                    )}
                  </RegistrationHeader>
                  <span style={{ paddingBottom: '60px' }}>
                    <RegistrationsTable
                      removeRecord={handleRemoveRegistration}
                      registrations={registrations}
                      editRecord={handleEditRegistration}
                      isReadOnly={statusReadOnly}
                      registrationsNumbers={registrationsNumbers}
                      isEditing={!!sustainabilityId}
                    />
                  </span>
                  <Row gutter={[24, 24]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                      <FormItem label={t('pages.sustainability.form.description')} name="description">
                        <TextArea autoSize={{ minRows: 3, maxRows: 5 }} maxLength={5000} disabled={statusReadOnly} />
                      </FormItem>
                    </Col>
                  </Row>{' '}
                  {visibleRequestsContract && (
                    <div>
                      <h2>{t('pages.sustainability.title.requests_made_to_the_provider')}</h2>
                      <RequestsContract form={form} />
                    </div>
                  )}
                  <h2>{t('pages.sustainability.title.evidence')}</h2>
                  <h1>{t('pages.sustainability.title.evidence_description')}</h1>
                  <>
                    {!statusReadOnly ? (
                      <Row gutter={[24, 24]}>
                        <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                          <FileContainer>
                            <Upload
                              multiple
                              showUploadList={false}
                              customRequest={async (info: any) => uploadFiles(info)}
                              disabled={statusReadOnly}
                            >
                              <Button status="primary-outline" icon={<FaUpload />}>
                                {t('pages.credit-request.form.documents.select_files')}
                              </Button>
                            </Upload>
                          </FileContainer>
                        </Col>
                      </Row>
                    ) : (
                      <div />
                    )}

                    <Row gutter={[26, 26]}>
                      <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                        <Spin spinning={isLoadingDocuments}>
                          <AntForm.List name="documents">
                            {fields => (
                              <>
                                {fields.map((field: any) => (
                                  <Row
                                    gutter={[26, 26]}
                                    key={`field-list-item-${field.key}-${documentList[field.name]?.name}`}
                                  >
                                    <FormItem name={[field.name, 'id']} hidden>
                                      <Input />
                                    </FormItem>
                                    <FormItem name={[field.name, 'file']} hidden>
                                      <Input />
                                    </FormItem>

                                    <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                                      {renderComponent(
                                        documentList[field.name] &&
                                          documentList[field.name].id &&
                                          documentList[field.name].name,
                                        <FormItem
                                          label={t('pages.credit-request.form.documents.name')}
                                          name={[field.name, 'name']}
                                        >
                                          <Input disabled />
                                        </FormItem>,
                                        <FormItem
                                          label={t('pages.credit-request.form.documents.name')}
                                          name={[field.name, 'name']}
                                          extra={t('pages.sustainability.form.the_file_was_not_send')}
                                          validateStatus="warning"
                                        >
                                          <Input readOnly />
                                        </FormItem>,
                                      )}
                                    </Col>

                                    <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
                                      <FormItem
                                        label={t('pages.credit-request.form.documents.type_id')}
                                        name={[field.name, 'type_id']}
                                        rules={[{ required: true }]}
                                      >
                                        <Select
                                          options={fileTypes}
                                          onChange={(value: any, option: any) => {
                                            const newDocumentList = documentList;
                                            newDocumentList[field.name].type_id = option.value;
                                            setDocumentList(newDocumentList);
                                          }}
                                          disabled={statusReadOnly}
                                        />
                                      </FormItem>
                                    </Col>

                                    <Col xs={24} sm={24} md={12} lg={4} xl={4} xxl={4}>
                                      <div className="action-buttons">
                                        {documentList[field.name]?.id &&
                                          checkIfFilePreviewerSupportsExtension(
                                            documentList[field.name]?.extension,
                                          ) && (
                                            <IconWithTooltip
                                              action="detail"
                                              title={t('pages.credit-request.table.icons.preview')}
                                              onClick={() => {
                                                handlePreviewFile(documentList[field.name]);
                                              }}
                                            />
                                          )}
                                        <IconWithTooltip
                                          action="download"
                                          title={t('pages.credit-request.table.icons.download')}
                                          loading={
                                            documentList[field.name]?.id &&
                                            documentLoading === documentList[field.name].id
                                          }
                                          color={
                                            documentList[field.name]?.downloads_user
                                              ? theme.colors.success
                                              : theme.colors.text
                                          }
                                          onClick={async () => {
                                            if (documentList[field.name] && documentList[field.name].id) {
                                              setDocumentLoading(documentList[field.name].id);
                                              await documentApi
                                                .download(documentList[field.name].id)
                                                .then((response: any) => {
                                                  downloadHandler(response);
                                                  if (!documentList[field.name]?.downloads_user) {
                                                    const newDocumentList = [...documentList];
                                                    newDocumentList[field.name].downloads_user = 1;
                                                    setDocumentList(newDocumentList);
                                                  }
                                                })
                                                .catch((err: DTOErrorReponse) => alertStatus(err, 'error'))
                                                .finally(() => setDocumentLoading(''));
                                            } else {
                                              downloadDocument(
                                                documentList[field.name].image,
                                                documentList[field.name].name,
                                              );
                                              setDocumentLoading('');
                                            }
                                          }}
                                        />
                                        {!statusReadOnly && (
                                          <Can I="document.destroy">
                                            <IconWithTooltip
                                              action="destroy"
                                              title={t('pages.credit-request.table.icons.remove')}
                                              loading={
                                                documentList[field.name]?.id &&
                                                documentRemoving === documentList[field.name].id
                                              }
                                              onClick={() => handleRemoveFile(field)}
                                            />
                                          </Can>
                                        )}
                                      </div>
                                    </Col>
                                  </Row>
                                ))}
                              </>
                            )}
                          </AntForm.List>
                        </Spin>
                        {sustainabilityId && (
                          <Pagination
                            defaultCurrent={1}
                            total={documentTotal}
                            onChange={handleChangeDocumentList}
                            showSizeChanger
                          />
                        )}
                      </Col>
                    </Row>
                    <Row gutter={[26, 26]}>
                      <span
                        style={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'end',
                          gap: '10px',
                          marginTop: '10px',
                          marginRight: '10px',
                        }}
                      >
                        {/* <Button
                          disabled={!documentList.filter(document => document.downloads_user === 0).length}
                          onClick={handleDownloadNotDownloadedDocuments}
                        >
                          {t('downloadNotDownloaded')}
                        </Button> */}
                        <Button disabled={!documentList.length} onClick={handleDownloadAllDocuments}>
                          {t('downloadAll')}
                        </Button>
                      </span>
                    </Row>
                  </>
                  {sustainabilityId && statusCurrent.id !== SUSTAINABILITY_NEXT_STATUS_REQUESTED && (
                    <>
                      <h2>{t('pages.sustainability.title.return_sustainability')}</h2>
                      <Row gutter={[26, 26]}>
                        <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8} className="col_sust">
                          <h2 className="h2-sustainability">
                            {t('pages.sustainability.title.socio_environmental_lists')}
                          </h2>
                          <FormItem name="is_ibama" label={t('pages.sustainability.form.is_ibama')}>
                            <Radio.Group buttonStyle="solid" disabled={statusReadOnly}>
                              <Radio.Button value>{t('pages.sustainability.form.yes')}</Radio.Button>
                              <Radio.Button value={false}>{t('pages.sustainability.form.no')}</Radio.Button>
                            </Radio.Group>
                          </FormItem>
                          <FormItem name="is_sema" label={t('pages.sustainability.form.is_sema')}>
                            <Radio.Group buttonStyle="solid" disabled={statusReadOnly}>
                              <Radio.Button value>{t('pages.sustainability.form.yes')}</Radio.Button>
                              <Radio.Button value={false}>{t('pages.sustainability.form.no')}</Radio.Button>
                            </Radio.Group>
                          </FormItem>
                          <FormItem
                            name="is_illegal_deforestation"
                            label={t('pages.sustainability.form.is_illegal_deforestation')}
                          >
                            <Radio.Group buttonStyle="solid" disabled={statusReadOnly}>
                              <Radio.Button value>{t('pages.sustainability.form.yes')}</Radio.Button>
                              <Radio.Button value={false}>{t('pages.sustainability.form.no')}</Radio.Button>
                            </Radio.Group>
                          </FormItem>
                          <FormItem
                            name="is_green_grain_protocol"
                            label={t('pages.sustainability.form.is_green_grain_protocol')}
                          >
                            <Radio.Group buttonStyle="solid" disabled={statusReadOnly}>
                              <Radio.Button value>{t('pages.sustainability.form.yes')}</Radio.Button>
                              <Radio.Button value={false}>{t('pages.sustainability.form.no')}</Radio.Button>
                            </Radio.Group>
                          </FormItem>
                          <FormItem name="is_moratorium" label={t('pages.sustainability.form.is_moratorium')}>
                            <Radio.Group buttonStyle="solid" disabled={statusReadOnly}>
                              <Radio.Button value>{t('pages.sustainability.form.yes')}</Radio.Button>
                              <Radio.Button value={false}>{t('pages.sustainability.form.no')}</Radio.Button>
                            </Radio.Group>
                          </FormItem>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8} className="col_sust_area">
                          <h2 className="h2-sustainability">
                            {t('pages.sustainability.title.socio_environmental_areas')}
                          </h2>
                          <FormItem name="is_ibama_area" label={t('pages.sustainability.form.is_ibama_area')}>
                            <Radio.Group buttonStyle="solid" disabled={statusReadOnly}>
                              <Radio.Button value>{t('pages.sustainability.form.yes')}</Radio.Button>
                              <Radio.Button value={false}>{t('pages.sustainability.form.no')}</Radio.Button>
                            </Radio.Group>
                          </FormItem>
                          <FormItem name="is_sema_area" label={t('pages.sustainability.form.is_sema_area')}>
                            <Radio.Group buttonStyle="solid" disabled={statusReadOnly}>
                              <Radio.Button value>{t('pages.sustainability.form.yes')}</Radio.Button>
                              <Radio.Button value={false}>{t('pages.sustainability.form.no')}</Radio.Button>
                            </Radio.Group>
                          </FormItem>
                          <FormItem name="is_moratorium_area" label={t('pages.sustainability.form.is_moratorium_area')}>
                            <Radio.Group buttonStyle="solid" disabled={statusReadOnly}>
                              <Radio.Button value>{t('pages.sustainability.form.yes')}</Radio.Button>
                              <Radio.Button value={false}>{t('pages.sustainability.form.no')}</Radio.Button>
                            </Radio.Group>
                          </FormItem>
                          <FormItem
                            name="is_historical_moratorium"
                            label={t('pages.sustainability.form.is_historical_moratorium')}
                          >
                            <Radio.Group buttonStyle="solid" disabled={statusReadOnly}>
                              <Radio.Button value>{t('pages.sustainability.form.yes')}</Radio.Button>
                              <Radio.Button value={false}>{t('pages.sustainability.form.no')}</Radio.Button>
                            </Radio.Group>
                          </FormItem>
                          <FormItem name="is_prodes_amazon" label={t('pages.sustainability.form.is_prodes_amazon')}>
                            <Radio.Group buttonStyle="solid" disabled={statusReadOnly}>
                              <Radio.Button value>{t('pages.sustainability.form.yes')}</Radio.Button>
                              <Radio.Button value={false}>{t('pages.sustainability.form.no')}</Radio.Button>
                            </Radio.Group>
                          </FormItem>
                          <FormItem name="is_prodes_cerrado" label={t('pages.sustainability.form.is_prodes_cerrado')}>
                            <Radio.Group buttonStyle="solid" disabled={statusReadOnly}>
                              <Radio.Button value>{t('pages.sustainability.form.yes')}</Radio.Button>
                              <Radio.Button value={false}>{t('pages.sustainability.form.no')}</Radio.Button>
                            </Radio.Group>
                          </FormItem>
                          <FormItem name="is_indigenous_land" label={t('pages.sustainability.form.is_indigenous_land')}>
                            <Radio.Group buttonStyle="solid" disabled={statusReadOnly}>
                              <Radio.Button value>{t('pages.sustainability.form.yes')}</Radio.Button>
                              <Radio.Button value={false}>{t('pages.sustainability.form.no')}</Radio.Button>
                            </Radio.Group>
                          </FormItem>
                          <FormItem
                            name="is_conservation_unit"
                            label={t('pages.sustainability.form.is_conservation_unit')}
                          >
                            <Radio.Group buttonStyle="solid" disabled={statusReadOnly}>
                              <Radio.Button value>{t('pages.sustainability.form.yes')}</Radio.Button>
                              <Radio.Button value={false}>{t('pages.sustainability.form.no')}</Radio.Button>
                            </Radio.Group>
                          </FormItem>
                          <FormItem name="is_settlements" label={t('pages.sustainability.form.is_settlements')}>
                            <Radio.Group buttonStyle="solid" disabled={statusReadOnly}>
                              <Radio.Button value>{t('pages.sustainability.form.yes')}</Radio.Button>
                              <Radio.Button value={false}>{t('pages.sustainability.form.no')}</Radio.Button>
                            </Radio.Group>
                          </FormItem>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8} className="col_sust_status">
                          <h2 className="h2-sustainability">{t('pages.sustainability.title.business_status')}</h2>
                          <FormItem name="is_have_condition" label={t('pages.sustainability.form.is_have_condition')}>
                            <Radio.Group buttonStyle="solid" disabled={statusReadOnly}>
                              <Radio.Button value>{t('pages.sustainability.form.yes')}</Radio.Button>
                              <Radio.Button value={false}>{t('pages.sustainability.form.no')}</Radio.Button>
                            </Radio.Group>
                          </FormItem>
                          <FormItem name="is_pendencies" label={t('pages.sustainability.form.is_pendencies')}>
                            <Radio.Group buttonStyle="solid" disabled={statusReadOnly}>
                              <Radio.Button value>{t('pages.sustainability.form.yes')}</Radio.Button>
                              <Radio.Button value={false}>{t('pages.sustainability.form.no')}</Radio.Button>
                            </Radio.Group>
                          </FormItem>
                          {(statusCurrent.id === SUSTAINABILITY_NEXT_STATUS_FINISHED ||
                            statusCurrent.id === SUSTAINABILITY_NEXT_WAITING_UNBLOCK ||
                            statusCurrent.id === SUSTAINABILITY_NEXT_UNBLOCKED ||
                            decisionId === SUSTAINABILITY_DECISION_APPROVED_WITH_RESERVATIONS ||
                            decisionId === SUSTAINABILITY_DECISION_DISAPPROVED) && (
                            <FormItem label={t('pages.sustainability.form.final_opinion')} name="final_opinion">
                              <TextArea autoSize={{ minRows: 5, maxRows: 10 }} maxLength={5000} disabled />
                            </FormItem>
                          )}
                        </Col>
                      </Row>
                    </>
                  )}
                  <FixedFooter>
                    <div className="form-footer-feedback" />
                    <div className="form-footer-actions">
                      {!creditRequestId && sustainabilityId && (
                        <Button status="primary-outline" htmlType="submit" onClick={handleClone}>
                          {t('pages.sustainability.clone')}
                        </Button>
                      )}
                      {sustainabilityId && (
                        <>
                          <Dropdown
                            overlay={
                              <Menu>
                                {listOfNextTeamStatus.map((status: any, index) => (
                                  <Menu.Item key={status.value}>
                                    <StatusLink
                                      onClick={() => {
                                        setStatusNext(status.label);
                                        const errors: string[] = [];

                                        if (hasRegistrationsInAnalysis)
                                          errors.push(t('pages.sustainability.statusModal.hasRegistrationsInAnalysis'));

                                        if (
                                          checkEmptySustainabilityReturnFields() &&
                                          !hasNotSavedEmptySustainabilityReturnFields
                                        )
                                          errors.push(
                                            t('pages.sustainability.statusModal.hasEmptySustainabilityReturnFields'),
                                          );

                                        if (hasNotSavedEmptySustainabilityReturnFields)
                                          errors.push(
                                            t('pages.sustainability.statusModal.notSavedSustainabilityReturnFields'),
                                          );

                                        if (status.label === 'FINALIZADO' && errors.length) {
                                          handleError(errors);
                                          return false;
                                        }

                                        setModalVisible(true);
                                        formModalStatus.setFieldsValue({
                                          sustainability_report_status_id: status.value,
                                        });

                                        return true;
                                      }}
                                      onKeyPress={() => false}
                                      role="menuitem"
                                      tabIndex={index}
                                    >
                                      {status.label}
                                    </StatusLink>
                                  </Menu.Item>
                                ))}
                              </Menu>
                            }
                            /* isabled={blockTeamStatusButton} */
                            disabled={listOfNextTeamStatus.length === 0}
                            placement="topRight"
                            arrow
                          >
                            <Button status="secondary-outline">{statusCurrent.name}</Button>
                          </Dropdown>
                          {listOfNextTeamStatus.length > 0 && (
                            <Button status="primary" htmlType="submit" onClick={() => handleEdit()}>
                              {t('pages.sustainability.button_edit')}
                            </Button>
                          )}
                        </>
                      )}
                      {!sustainabilityId && (
                        <Button status="primary" htmlType="submit" onClick={() => handleSubmit()}>
                          {t('pages.sustainability.button_save')}
                        </Button>
                      )}
                    </div>
                  </FixedFooter>
                </Form>
              </>
            </div>
          </TabPane>
          {sustainabilityId && (
            <>
              <TabPane
                tab={t('pages.sustainability.form.comments')}
                key="tab-comments"
                disabled={!ability.can('sustainability.comment.index', '')}
              >
                <PermissionedComponent permission="sustainability.comment.index" className="h-full">
                  <Comments sustainabilityReportId={sustainabilityId} />
                </PermissionedComponent>
              </TabPane>
              <TabPane tab={t('pages.sustainability.form.historic')} key="tab-historic">
                <HistoricStatus sustainabilityReportId={sustainabilityId} />
              </TabPane>
            </>
          )}
        </Tabs>
      </Spin>

      <Modal
        visible={modalVisible}
        title={t('pages.sustainability.form.modalStatus.modal-status-title')}
        className="modal-with-custom-footer"
        closeIcon={<MdClose onClick={() => handleModalClose()} />}
        footer={
          <>
            <div className="buttons">
              <Button status="secondary" onClick={() => handleModalClose()} disabled={modalLoading}>
                {t('hooks.creditStatus.modal-status-buttons.cancel')}
              </Button>

              <Button
                status="primary"
                onClick={() => handleModalChange()}
                loading={modalLoading}
                disabled={modalLoading}
              >
                {t('hooks.creditStatus.modal-status-buttons.save')}
              </Button>
            </div>
          </>
        }
      >
        <Spin spinning={modalLoading}>
          <p>
            {t('pages.sustainability.form.modalStatus.modal-status-description-next-status')}
            {'  '}
            <strong>{statusNext || ''}</strong>
          </p>
          <br />
          <Form form={formModalStatus} name="status-change-form" className="form-secondary">
            <FormItem hidden name="sustainability_report_status_id">
              <Input />
            </FormItem>
            {statusNext === 'FINALIZADO' && (
              <>
                <FormItem
                  label={t('pages.sustainability.form.sustainability_decision')}
                  name="sustainability_decision_id"
                  rules={[{ required: true }]}
                >
                  <Select options={sustainabilitDecisions} />
                </FormItem>
                <FormItem
                  label={t('pages.sustainability.form.final_opinion')}
                  name="final_opinion"
                  rules={[{ required: true }]}
                >
                  <TextArea autoSize={{ minRows: 5, maxRows: 10 }} maxLength={5000} />
                </FormItem>
              </>
            )}
            <FormItem
              label={t('hooks.creditStatus.modal-status-comments-field')}
              name="comments"
              rules={[{ required: statusNext !== 'FINALIZADO' && statusNext !== 'EM ANÁLISE' }]}
            >
              <TextArea autoSize={{ minRows: 5, maxRows: 10 }} maxLength={5000} />
            </FormItem>

            <FormItem>
              <Upload
                multiple
                showUploadList={false}
                customRequest={async (info: any) => {
                  setDocumentListStatus((documentListStatusState: any[]) => [...documentListStatusState, info.file]);
                }}
              >
                <Button status="primary-outline" icon={<FaUpload />}>
                  {t('hooks.creditStatus.modal-status-select-files')}
                </Button>
              </Upload>
            </FormItem>

            <Form.List name="documents_status">
              {fieldsStatus => (
                <>
                  {fieldsStatus.map((field: any) => (
                    <>
                      <Row gutter={[26, 26]} key={`files-item-${field.key}-${documentList[field.name]?.name}`}>
                        <FormItem name={[field.name, 'original']} hidden>
                          <Input />
                        </FormItem>
                        <FormItem name={[field.name, 'mime']} hidden>
                          <Input />
                        </FormItem>
                        <FormItem name={[field.name, 'image']} hidden>
                          <Input />
                        </FormItem>
                        <FormItem name={[field.name, 'name']} hidden>
                          <Input />
                        </FormItem>

                        <Col xs={24} sm={24} md={12} lg={18} xl={18} xxl={18}>
                          <div>{documentListStatus[field.name] ? documentListStatus[field.name].name : ''}</div>
                        </Col>

                        <Col xs={24} sm={24} md={12} lg={4} xl={4} xxl={4}>
                          <div className="action-buttons">
                            <IconWithTooltip
                              action="download"
                              title={t('hooks.creditStatus.modal-status-icon-download')}
                              loading={documentLoading === field.name}
                              onClick={async () => {
                                downloadDocument(
                                  documentListStatus[field.name].image,
                                  documentListStatus[field.name].name,
                                );
                                setDocumentLoading('');
                              }}
                            />
                            <IconWithTooltip
                              action="destroy"
                              title={t('hooks.creditStatus.modal-status-icon-remove')}
                              loading={documentRemoving === field.name}
                              onClick={() => {
                                setDocumentRemoving(documentListStatus[field.name]?.id);
                                setDocumentRemoving('');
                                setDocumentListStatus(
                                  documentListStatus.filter((doc: any, index: any) => index !== field.name),
                                );
                              }}
                            />
                          </div>
                        </Col>
                      </Row>
                    </>
                  ))}
                </>
              )}
            </Form.List>
          </Form>
        </Spin>
      </Modal>

      <NewRegistrationModal
        isOpen={isNewRegistrationModalOpen}
        canShowRegistrationWarning={type === SUSTAINABILITY_STATUS_BLOQUEIO && isContractVisible}
        isEditing={!!sustainabilityId}
        close={() => {
          closeNewRegistrationModal();
          setRegistrationToEditId('');
        }}
        handlePreviewFile={handlePreviewFile}
        addRegistration={addRegistration}
        editRegistration={editRegistration}
        registrationToEdit={registrations.find(registration => registration.id === registrationToEditId)}
        isReadOnly={statusReadOnly}
        registrations={registrationsNumbers}
        isClone={!!clonedSustainabilityId}
      />

      {fileToPreview && <FilePreviewer document={fileToPreview} onClose={() => setFileToPreview(null)} />}
    </Container>
  );
};

export default Sustainability;

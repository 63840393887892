import styled, { css, keyframes } from 'styled-components';

export const ScrollBox = css<{ background: string }>`
  ${({ background }) => css`
    overflow: auto;
    width: 100%;
    height: 100%;
    background: /* Shadow covers */ linear-gradient(${background} 30%, rgba(255, 255, 255, 0)),
      linear-gradient(rgba(255, 255, 255, 0), ${background} 70%) 0 100%,
      /* Shadows */ radial-gradient(50% 0, farthest-side, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)),
      radial-gradient(50% 100%, farthest-side, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) 0 100%;
    background: /* Shadow covers */ linear-gradient(${background} 30%, rgba(255, 255, 255, 0)),
      linear-gradient(rgba(255, 255, 255, 0), ${background} 70%) 0 100%,
      /* Shadows */ radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)),
      radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) 0 100%;
    background-repeat: no-repeat;
    background-color: ${background};
    background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
    /* Opera doesn't support this in the shorthand */
    background-attachment: local, local, scroll, scroll;
  `}
`;

const fadeInFwd = keyframes`
 0% {
   -webkit-transform: translateZ(-80px);
           transform: translateZ(-80px);
   opacity: 0;
 }
 100% {
   -webkit-transform: translateZ(0);
           transform: translateZ(0);
   opacity: 1;
 }
`;

export const SContainer = styled.div`
  padding: 8px 8px 24px;
`;

export const SHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
`;

export const STitlePage = styled.h2`
  color: ${({ theme: { colors } }) => colors.primary};
  font-family: ${({ theme: { fonts } }) => fonts.primary};
  font-size: 18px;
  font-weight: 600;
`;

export const SFormButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  button + button {
    margin-left: 8px;
  }
`;

interface FormContainer {
  visible?: boolean;
}

export const SFormContainer = styled.div<FormContainer>`
  display: ${({ visible = true }) => (visible ? 'block' : 'none')};
  border: solid 1px ${({ theme: { colors } }) => colors.greys.grey03};
  border-radius: 4px;
  background: #ffffff;
  padding: 8px;
  margin-top: 8px;
  margin-bottom: 8px;
  animation: ${fadeInFwd} 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;

  h2 {
    color: ${({ theme: { colors } }) => colors.greys.grey08};
    font-family: ${({ theme }) => theme.fonts.primary};
    font-size: 16px;
    font-weight: 600;
    display: block;
    margin-bottom: 16px;
  }
`;

export const GridFilters = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr) 1.2fr;

  .grid-buttons {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 8px;
    padding-top: 23px;
    padding-left: 4px;
  }
`;

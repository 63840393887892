/* eslint-disable no-console */
import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';

import App from './App';
import { Spin } from './components';

// mascara todos os console.* do projeto
// if (!['local', 'dev', 'development'].includes(process.env.REACT_APP_ENVIRONMENT)) {
//   console.log = (): any => null;
//   console.error = (): any => null;
//   console.debug = (): any => null;
// }
const isClient = process.env.REACT_APP_IS_CLIENT === 'true';

const favicon = document.getElementsByTagName('link')?.[0];
favicon.href = isClient ? `${process.env.PUBLIC_URL}/favicon.ico` : `${process.env.PUBLIC_URL}/company-favicon.png`;

const siteTitle = document.getElementById('siteTitle');
siteTitle.innerText = isClient ? process.env.REACT_APP_TITLE_CLIENT : process.env.REACT_APP_TITLE_COMPANY;

const root = createRoot(document.getElementById('root'));
root.render(
  <Suspense
    fallback={
      <Spin spinning>
        <div style={{ width: '100vw', height: '100vh', margin: 'auto' }} />
      </Spin>
    }
  >
    <App />
  </Suspense>,
);

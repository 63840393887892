import React from 'react';
import { useTranslation } from 'react-i18next';

import { Col, InputNumber } from 'antd';
import { Rule } from 'antd/lib/form';
import { FormItem, IconWithTooltip, Input, MaskedInput, Select } from 'components';
import { RegionSelect } from 'components/RegionSelect';
import { SubsidiarySelect } from 'components/SubsidiarySelect';
import { LEGAL_PERSON_TYPE, PHYSICAL_PERSON_TYPE } from 'configs/constants';
import { useCache } from 'hooks/cache';
import { Formatter } from 'utils/Formatter';

interface FieldProps {
  isDisabled: boolean;
  onChange?: (value: any) => void;
  onBlur?: () => void;
  options?: any[];
  typePerson?: string;
  initialValue?: any;
  rules?: Rule[];
}

interface DefaultFieldProps extends FieldProps {
  fieldName: 'harvest' | 'commodity' | 'registrationNumber' | 'volume' | 'farm' | 'subsidiary' | 'region';
}

interface SubsidiaryFieldProps extends FieldProps {
  fieldName: 'subsidiary';
  regionId?: string;
}

interface CityFieldProps extends FieldProps {
  fieldName: 'city';
  options: any[];
}

interface StateFieldProps extends FieldProps {
  fieldName: 'state';
  onChange: (value: any) => void;
  options: any[];
}

interface ProfileFieldProps extends FieldProps {
  fieldName: 'profile';
  onChange: (value: any) => void;
}

interface DocNumberFieldProps extends FieldProps {
  fieldName: 'docNumber';
  onBlur: () => void;
  typePerson: string;
}

type Props =
  | CityFieldProps
  | StateFieldProps
  | ProfileFieldProps
  | DocNumberFieldProps
  | SubsidiaryFieldProps
  | DefaultFieldProps;

export const RenderFormField = React.forwardRef(
  (
    { fieldName, isDisabled, onChange, options, onBlur, typePerson, initialValue, rules = [], ...props }: Props,
    ref,
  ) => {
    const { t } = useTranslation();
    const cache = useCache();
    switch (fieldName) {
      case 'harvest':
        return (
          <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
            <FormItem rules={[{ required: true }]} label={t('pages.sustainability.form.harvest')} name="harvest_id">
              <Select ref={ref} options={cache.getCreditOptions('harvest')} disabled={isDisabled} />
            </FormItem>
          </Col>
        );
      case 'commodity':
        return (
          <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
            <FormItem
              rules={[{ required: true }]}
              label={t('pages.sustainability.form.commodity')}
              name="product_id"
              initialValue={initialValue ?? ''}
            >
              <Select ref={ref} options={cache.getCreditOptions('product')} disabled={isDisabled} showSearch />
            </FormItem>
          </Col>
        );
      case 'registrationNumber':
        return (
          <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
            <FormItem
              rules={[{ required: true }]}
              label={t('pages.sustainability.form.registration_number')}
              name="registration_number"
            >
              <Input ref={ref} disabled={isDisabled} maxLength={255} />
            </FormItem>
          </Col>
        );
      case 'state':
        return (
          <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
            <FormItem label={t('pages.sustainability.form.state')} name="state_id" rules={[{ required: true }]}>
              <Select ref={ref} options={options} onChange={onChange} disabled={isDisabled} />
            </FormItem>
          </Col>
        );
      case 'city':
        return (
          <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
            <FormItem style={{ paddingRight: '10px' }} label={t('pages.sustainability.form.city')} name="city_id">
              <Select ref={ref} options={options} disabled={isDisabled} />
            </FormItem>
          </Col>
        );
      case 'volume':
        return (
          <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
            <FormItem rules={[{ required: true }]} label={t('pages.sustainability.form.volume')} name="volume">
              <InputNumber
                precision={2}
                min={0}
                formatter={(value: string | number) => Formatter.decimal(value, 2, 3, null, 'kg')}
                parser={(value: string) => Number(Formatter.decimalParser(value))}
                disabled={isDisabled}
                maxLength={19}
              />
            </FormItem>
          </Col>
        );
      case 'profile':
        return (
          <Col xs={24} sm={18} md={12} lg={4} xl={4} xxl={4}>
            <FormItem
              label={t('pages.credit-request.form.requester_person_type')}
              name="requester_person_type"
              rules={[{ required: true }]}
              initialValue={PHYSICAL_PERSON_TYPE}
            >
              <Select
                ref={ref}
                options={[
                  {
                    key: 'physical',
                    value: PHYSICAL_PERSON_TYPE,
                    label: t('pages.credit-request.form.requester_person_type_physical'),
                  },
                  {
                    key: 'legal',
                    value: LEGAL_PERSON_TYPE,
                    label: t('pages.credit-request.form.requester_person_type_legal'),
                  },
                ]}
                onChange={onChange}
                disabled={isDisabled}
              />
            </FormItem>
          </Col>
        );
      case 'docNumber':
        return (
          <Col xs={24} sm={24} md={24} lg={4} xl={4} xxl={4}>
            <FormItem
              label={
                typePerson === PHYSICAL_PERSON_TYPE
                  ? t('pages.sustainability.form.cpf')
                  : t('pages.sustainability.form.cnpj')
              }
              name="doc_number"
            >
              {typePerson === PHYSICAL_PERSON_TYPE ? (
                <MaskedInput
                  ref={ref}
                  name="doc_number"
                  suffix={
                    <IconWithTooltip
                      action="search"
                      className="icon-in-input-suffix"
                      title={t('pages.sustainability.form.person-search')}
                    />
                  }
                  mask="111.111.111-11"
                  placeholder="___.___.___-__"
                  disabled={isDisabled}
                  onBlur={onBlur}
                />
              ) : (
                <MaskedInput
                  ref={ref}
                  name="doc_number"
                  mask="11.111.111/1111-11"
                  placeholder="__.___.___/____-__"
                  suffix={
                    <IconWithTooltip
                      action="search"
                      className="icon-in-input-suffix"
                      title={t('pages.sustainability.form.person-search')}
                      onClick={onBlur}
                    />
                  }
                  disabled={isDisabled}
                />
              )}
            </FormItem>
          </Col>
        );
      case 'farm':
        return (
          <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
            <FormItem label={t('pages.sustainability.form.farm')} name="farm">
              <Input disabled={isDisabled} maxLength={255} ref={ref} />
            </FormItem>
          </Col>
        );
      case 'subsidiary':
        const { regionId } = props as SubsidiaryFieldProps;
        return (
          <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
            <FormItem label={t('subsidiary')} name="subsidiary_id" rules={rules}>
              <SubsidiarySelect regionId={regionId} disabled={isDisabled || !regionId} />
            </FormItem>
          </Col>
        );
      case 'region':
        return (
          <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
            <FormItem label={t('region')} name="region_id" rules={rules}>
              <RegionSelect disabled={isDisabled} onChange={onChange} />
            </FormItem>
          </Col>
        );
      default:
        return <div />;
    }
  },
);

import { ImportGroupEnum, SapStatusAdvanceProgEnum } from 'configs/constants';

export enum SapStatusEnum {
  WARNING = 'W',
  SUCCESS = 'S',
  ERROR = 'E',
  MANUAL = 'MANUAL',
  FAILED = 'F',
}

export interface SapStatusProps {
  sapStatus: SapStatusEnum | SapStatusAdvanceProgEnum;
  sapStatusUpdatedAt: string;
  workerId?: string;
  showTime?: boolean;
  workerGroupId?: ImportGroupEnum;
}

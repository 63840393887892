import styled from 'styled-components';

export const SContainer = styled.div`
  padding: 8px 8px 24px;

  .ant-badge-status-dot {
    position: relative;
    top: -1px;
    display: inline-block;
    width: 15px;
    height: 15px;
    vertical-align: middle;
    border-radius: 50%;
  }

  .faquestion {
    position: relative;
    top: -1px;
    display: inline-block;
    width: 15px;
    height: 15px;
    vertical-align: middle;
  }
`;

export const SHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const STitlePage = styled.h2`
  color: ${({ theme: { colors } }) => colors.primary};
  font-family: ${({ theme: { fonts } }) => fonts.primary};
  font-size: 18px;
  font-weight: 600;
`;

export const SFilterBoxContainer = styled.div``;

export const SFilterContainer = styled.div`
  display: flex;
  align-items: flex-end;

  .grid-filters {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    grid-template-areas: 'filter-search filter-button filter-clear';
    padding: 8px 0;
    width: 100%;

    .filter-search {
      grid-area: filter-search;
    }

    .filter-button {
      grid-area: filter-button;
      align-items: flex-end;
      display: flex;
    }

    .filter-clear {
      grid-area: filter-clear;
      align-items: flex-end;
      display: flex;
      justify-content: flex-end;
      padding-bottom: 7px;
    }
  }
`;

import React from 'react';
import { useTranslation } from 'react-i18next';

import { InputNumber } from 'antd';
import { FormItem } from 'components';
import { Formatter } from 'utils/Formatter';

export const GrossValueInput = () => {
  const { t } = useTranslation();

  return (
    <FormItem label={t('pages.credit-request.form.prepayment_requested_amount')} name="json_data.gross_value">
      <InputNumber<string>
        min="0"
        formatter={(value: string | number) => Formatter.money(value)}
        parser={(value: string) => Formatter.moneyParser(value, 2)}
        disabled
      />
    </FormItem>
  );
};

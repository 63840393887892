import { useMutation } from '@tanstack/react-query';
import { useAlertDialog } from 'components/ui/alert-dialog';
import api from 'services/api/api';

export const useUpdateDocumentToCollectMutation = () => {
  const { alert } = useAlertDialog();

  return useMutation({
    mutationKey: ['updateDocumentToCollect'],
    mutationFn: async (documentToCollectData: FormData) => {
      const response = await api.post(
        `credit-request/package/document/${documentToCollectData.get('id')}`,
        documentToCollectData,
        {
          params: { _method: 'PUT' },
        },
      );
      return response?.data;
    },
    onError: err => {
      alert({ title: err.message, type: 'error' });
    },
  });
};

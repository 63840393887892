import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ShouldRender } from 'components/ShouldRender';
import { FormItem } from 'components/ui/form-item';
import { GroupForm } from 'components/ui/group-form';
import { Upload } from 'components/ui/upload';
import { useAbility } from 'hooks/ability';
import { AttachmentFormTable } from 'pages/CreditRequest/components/organisms/FormalizationGuarantees/components/draft/attachment-form.table';

import { useRegisteredCustomerStore } from './stores/use-registered-customer-store';

export const RegisteredCustomerDocumentsSection = () => {
  const { t } = useTranslation();
  const form = useFormContext();
  const ability = useAbility();

  const files = form.watch('files');

  const isViewing = useRegisteredCustomerStore(state => state.isViewing);
  const isModulePerson = useRegisteredCustomerStore(state => state.isModulePerson);

  return (
    <GroupForm title={t('docs')}>
      <ShouldRender condition={!isViewing && isModulePerson}>
        <FormItem name="files">
          <Upload
            permissions={['credit.request.package.upload']}
            allowedExtensions={[
              '.docx',
              '.jpg',
              '.png',
              '.jpeg',
              '.xls',
              '.xlsx',
              '.doc',
              '.ppt',
              '.pptx',
              '.pdf',
              '.xml',
            ]}
            showUploadList
            maxFiles={0}
          />
        </FormItem>
      </ShouldRender>

      <div className="py-2">
        <AttachmentFormTable
          formFieldName="documents"
          delete={{
            callback: id => {
              form.setValue(
                'files',
                files.filter((file: any) => file.id !== id),
              );

              return true;
            },
          }}
          preview={{
            isVisible: true,
          }}
          download={{
            isVisible: ability.can('credit.request.package.download', ''),
          }}
        />
      </div>
    </GroupForm>
  );
};

import { useCreditRequestContext } from 'context/CreditRequestContext';
import DTODatasulProvider from 'types/Datasul/DTODatasulProvider';

import { useOperationHasThirdProviderEnabled } from '../rules/general/useOperationHasThirdProviderEnabled';
import { useFillProviderDataPrepay } from '../rules/prepay/useFillProviderDataPrepay';
import { useCreditRequestStore } from '../store/useCreditRequestStore';
import { useGetThirdProvidersFromProvider } from './useGetThirdProvidersFromProvider';

const INITIAL_PROVIDER_VALUES: any = {
  requester_person_id: undefined,
  'requester_person.cpf': undefined,
  'requester_person.cnpj': undefined,
  requester_person_name: undefined,
  'requester_person.json_data.code_erp': undefined,
  'json_data.warranty_pledge_areas': [],
};

export const useFillProviderData = () => {
  const { fillProviderDataPrepay } = useFillProviderDataPrepay();
  const { getThirdProvidersFromProvider } = useGetThirdProvidersFromProvider();
  const { operationHasThirdProviderEnabled } = useOperationHasThirdProviderEnabled();
  const { creditForm } = useCreditRequestContext();

  const isPrepay = useCreditRequestStore(state => state.isPrepay);
  const setThirdProviders = useCreditRequestStore(state => state.setThirdProviders);

  const fillProviderData = (data: DTODatasulProvider, providerId: string) => {
    if (isPrepay) fillProviderDataPrepay(INITIAL_PROVIDER_VALUES);
    else creditForm.setFieldsValue(INITIAL_PROVIDER_VALUES);

    const thirdProviders = getThirdProvidersFromProvider(data);
    if (operationHasThirdProviderEnabled) setThirdProviders(thirdProviders);

    creditForm.setFieldsValue({
      'requester_person.json_data.code_erp': data.cod_datasul ? data.cod_datasul : undefined,
      sap_bp_headoffice_subsidiary_code: data.sap_bp_subsidiary_code ? data.sap_bp_subsidiary_code : undefined,
      requester_person_name: data.name ?? data.fantasy_name,
      'requester_person.name': data.name ?? data.fantasy_name,
      requester_person_id: providerId,
    });
  };

  return { fillProviderData };
};

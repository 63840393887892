import { PaginatedQueryParams, usePaginatedQuery } from 'hooks/usePaginatedQuery';
import { SelectInfiniteQueryParams, useSelectInfiniteQuery } from 'hooks/useSelectInfiniteQuery';
import PaginationData from 'types/Api/PaginationData';
import { CreditRequestPackageTypeData } from 'types/CreditRequest/CreditRequestPackageTypeData';

export const useGetPackageTypesOptionsQuery = (params?: SelectInfiniteQueryParams) => {
  const query = useSelectInfiniteQuery<PaginationData<CreditRequestPackageTypeData>>(
    `credit-request/package-type`,
    {
      queryKey: ['credit-request/package-type'],
      staleTime: 1000 * 60 * 5,
      permissions: ['credit.request.packagetype.all'],
    },
    params,
  );

  return query;
};

export const useGetPackageTypesQuery = (params?: PaginatedQueryParams) => {
  return usePaginatedQuery<CreditRequestPackageTypeData>({
    queryKey: ['credit-request/package-type'],
    staleTime: 1000 * 60 * 5,
    url: `credit-request/package-type`,
    params: {
      ...params,
    },
    permissions: 'credit.request.packagetype.all',
  });
};

import { useState } from 'react';

import { DTOErrorResponse } from '@agrodatabr/agrodataui';

import DTOErrorReponse from '../../@types/dtos/DTOErrorReponse';
import api, { handleRequest } from '../../services/api/api';
import documentApi from '../../services/api/document';
import { ItemsType, usePage } from '../page';

export const useAttachment = () => {
  const { alertStatus } = usePage();

  const [isLoading, setIsLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const destroy = async (id: string) => {
    try {
      setIsLoading(true);
      const response = await documentApi.delete(id);
      return response;
    } catch (err) {
      alertStatus(err as DTOErrorReponse);
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  const types = async (params = {}) => {
    try {
      setIsFetching(true);
      const response = await documentApi.documentType(params);
      return response.data.data;
    } catch (err) {
      alertStatus(err as DTOErrorResponse, 'error');
      return null;
    } finally {
      setIsFetching(false);
    }
  };

  const typesOptions = async (params = {}) => {
    const response = await types(params);

    if (!response) return { data: [] as ItemsType[], lastPage: 1 };

    return {
      data: response.data.map(type => ({
        key: type.id,
        value: type.id,
        label: type.name,
      })) as ItemsType[],
      lastPage: response.last_page,
    };
  };

  const preview = async (attachmentId: string) => {
    const response = await handleRequest(() => api.get(`/document/preview/${attachmentId}?encoded=1`), setIsFetching);
    return response;
  };

  return { isLoading, isFetching, destroy, types, typesOptions, preview };
};

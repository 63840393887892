import React from 'react';
import { useTranslation } from 'react-i18next';

import { ShouldRender } from 'components/ShouldRender';
import { OPERATION_PREFIN_ID, OPERATION_PREFIN_SUGAR_MILLS_ID } from 'configs/constants';
import { usePrefinModalityRules } from 'pages/CreditRequest/rules/prefin/usePrefinModalityRules';
import { Formatter } from 'utils/Formatter';

import { CreditValueType } from '../types';
import { ApprovedValueProps } from './types';

export const ApprovedValue = ({
  currencyTypeName,
  operationId,
  subDivisionId,
  modalityId,
  approvedValue: requestedAmount,
  approvedValueBarter: requestedAmountBarter,
  approvedValueCash: requestedAmountCash,
}: ApprovedValueProps) => {
  const { t } = useTranslation();

  const { getShouldShowLimitBarterInputs, getShouldShowLimitCashInputs } = usePrefinModalityRules();

  const getLabels = (type: CreditValueType) => {
    switch (type) {
      case CreditValueType.CASH:
        return t('pages.credit-request.kanban.cards.approved_value_cash');
      case CreditValueType.BARTER:
        return t('pages.credit-request.kanban.cards.approved_value_barter');
      default:
        return t('pages.credit-request.kanban.cards.approved_value');
    }
  };

  const formatValue = (value: number | string, type: CreditValueType = CreditValueType.DEFAULT) => {
    return `${getLabels(type)}: ${Formatter.money(value, 2, currencyTypeName ?? '', true)}`;
  };

  const isPrefinOperation = operationId === OPERATION_PREFIN_ID || operationId === OPERATION_PREFIN_SUGAR_MILLS_ID;
  const shouldShowLimitBarterInputs = getShouldShowLimitBarterInputs(modalityId, subDivisionId);
  const shouldShowLimitCashInputs = getShouldShowLimitCashInputs(modalityId, subDivisionId);

  return (
    <div className="money">
      <ShouldRender condition={!isPrefinOperation || (!shouldShowLimitBarterInputs && !shouldShowLimitCashInputs)}>
        <p>{formatValue(requestedAmount)}</p>
      </ShouldRender>

      <ShouldRender condition={shouldShowLimitBarterInputs}>
        <p>{formatValue(requestedAmountBarter, CreditValueType.BARTER)}</p>
      </ShouldRender>

      <ShouldRender condition={shouldShowLimitCashInputs}>
        <p>{formatValue(requestedAmountCash, CreditValueType.CASH)}</p>
      </ShouldRender>
    </div>
  );
};

import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Col, Form, Row, Spin } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import { Button, FormItem, Input, Select } from 'components';
import { useCache } from 'hooks/cache';

import type { CacheOptions } from '../../../../types/Hooks/cache';
import { SFormButtons, SFormContainer } from '../styles';

interface Props {
  visibilityForm: boolean;
  loading: boolean;
  form: FormInstance;
  statesOptions: CacheOptions[];
  handleHideForm: () => void;
  disabledButton: boolean;
  editing: boolean;
  handleStoreOrUpdate: (data: any) => void;
  loadingButton: boolean;
}

export const BasePortForm = forwardRef(
  (
    {
      visibilityForm,
      loading,
      form,
      statesOptions,
      handleHideForm,
      disabledButton,
      editing,
      handleStoreOrUpdate,
      loadingButton,
    }: Props,
    ref,
  ) => {
    const { t } = useTranslation();
    const [disabledCities, setDisabledCities] = useState(true);
    const [citiesOptions, setCitiesOptions] = useState([]);
    const cache = useCache();

    const loadCityOptions = useCallback(
      async (stateId: string) => {
        setDisabledCities(false);
        setCitiesOptions(cache.getCityByStateOptions(stateId));
      },
      [cache],
    );

    useEffect(() => {
      if (form.getFieldValue('state_id')) {
        loadCityOptions(form.getFieldValue('state_id'));
      }
    }, [visibilityForm]);

    useImperativeHandle(
      ref,
      () => {
        return {
          loadCityOptions,
        };
      },
      [],
    );

    return (
      <SFormContainer visible={visibilityForm}>
        <Spin spinning={loading}>
          <h2>{t('pages.register.baseport.registerTitle')}</h2>
          <Form form={form}>
            <FormItem name="id" style={{ display: 'none' }}>
              <Input />
            </FormItem>
            <Row gutter={[26, 26]}>
              <Col xs={24} sm={12} md={8} lg={8} xl={8} xxl={8}>
                <FormItem
                  style={{ paddingRight: '10px' }}
                  label={t('pages.register.port.form.name')}
                  name="name"
                  rules={[{ required: true }]}
                >
                  <Input maxLength={255} type="text" />
                </FormItem>
              </Col>
              <Col xs={24} sm={12} md={8} lg={8} xl={8} xxl={8}>
                <FormItem
                  style={{ paddingRight: '10px' }}
                  label={t('pages.register.port.form.number')}
                  name="number"
                  rules={[{ required: true }]}
                >
                  <Input maxLength={255} type="text" />
                </FormItem>
              </Col>
              <Col xs={24} sm={12} md={8} lg={8} xl={8} xxl={8}>
                <FormItem
                  style={{ paddingLeft: '10px' }}
                  label={t('pages.register.port.form.issuer')}
                  name="issuer"
                  rules={[{ required: true }]}
                >
                  <Input maxLength={255} type="text" />
                </FormItem>
              </Col>
              <Col xs={24} sm={12} md={8} lg={8} xl={8} xxl={8}>
                <FormItem
                  style={{ paddingLeft: '10px' }}
                  label={t('pages.register.port.form.state')}
                  name="state_id"
                  rules={[{ required: true }]}
                >
                  <Select
                    options={statesOptions}
                    onChange={value => {
                      if (value) loadCityOptions(value.toString());
                    }}
                    onClear={() => {
                      setDisabledCities(true);
                      form.setFieldsValue({ city_id: '' });
                    }}
                  />
                </FormItem>
              </Col>
              <Col xs={24} sm={12} md={8} lg={8} xl={8} xxl={8}>
                <FormItem
                  style={{ paddingLeft: '10px' }}
                  label={t('pages.register.port.form.city')}
                  name="city_id"
                  rules={[{ required: true }]}
                >
                  <Select options={citiesOptions} disabled={disabledCities} />
                </FormItem>
              </Col>
            </Row>
            <SFormButtons>
              <Button status="secondary" htmlType="reset" onClick={handleHideForm} disabled={disabledButton}>
                {t('pages.register.harvest.buttonCancel')}
              </Button>
              {editing && (
                <Button
                  status="primary"
                  htmlType="submit"
                  onClick={() => handleStoreOrUpdate('update')}
                  loading={loadingButton}
                  disabled={disabledButton}
                >
                  {t('pages.register.harvest.buttonEdit')}
                </Button>
              )}
              {!editing && (
                <Button
                  status="primary"
                  htmlType="submit"
                  onClick={() => handleStoreOrUpdate('store')}
                  loading={loadingButton}
                  disabled={disabledButton}
                >
                  {t('pages.register.harvest.buttonRegister')}
                </Button>
              )}
            </SFormButtons>
          </Form>
        </Spin>
      </SFormContainer>
    );
  },
);

import React, { forwardRef, useEffect, useImperativeHandle } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ComboBox } from 'components/ui/combo';
import { Form } from 'components/ui/form';
import { FormGrid } from 'components/ui/form-grid';
import { FormItem } from 'components/ui/form-item';
import { Input, InputWithMask } from 'components/ui/input';
import { useCache } from 'hooks/cache';

import { useRegisteredCustomerStore } from '../stores/use-registered-customer-store';

type Props = {
  onSuccess?: (data: BankReferenceFormData) => void;
};

export type BankReferenceFormData = {
  id?: string;
  bank_id?: string;
  agency?: string;
  city_id?: string;
  state_id?: string;
  phone_number?: string;
  contact_name?: string;
};

export const bankReferenceInitialValues = {
  id: '',
  bank_id: '',
  agency: '',
  state_id: '',
  city_id: '',
  phone_number: '',
  contact_name: '',
};

export const BankReferenceForm = forwardRef(({ onSuccess }: Props, ref) => {
  const { t } = useTranslation();
  const cache = useCache();
  const form = useForm<BankReferenceFormData>({
    defaultValues: bankReferenceInitialValues,
    mode: 'onBlur',
  });

  const stateId = form.watch('state_id');

  const stateOptions = cache.getCreditOptions('state');
  const cityOptions = cache.getCityByStateOptions(stateId);

  const bankReferenceToEdit = useRegisteredCustomerStore(state => state.bankReferenceToEdit);

  const sendForm = (values: BankReferenceFormData) => {
    onSuccess?.(values);
  };

  const submit = () => {
    form.handleSubmit(sendForm, console.log)();
  };

  useImperativeHandle(ref, () => ({
    form: form as UseFormReturn<BankReferenceFormData>,
    submit,
  }));

  useEffect(() => {
    form.reset(bankReferenceToEdit || bankReferenceInitialValues);
  }, [bankReferenceToEdit]);

  return (
    <Form form={form} onSubmit={form.handleSubmit(sendForm)}>
      <FormGrid maxCols={2}>
        <FormItem
          label={t('pages.registered-customers.form.pj.bank_reference.name')}
          name="bank_id"
          rules={{ required: true }}
        >
          <ComboBox options={cache.getPersonOptions('bank')} />
        </FormItem>

        <FormItem
          label={t('pages.registered-customers.form.pj.bank_reference.agency')}
          name="agency"
          rules={{ required: true }}
        >
          <Input />
        </FormItem>

        <FormItem
          label={t('pages.registered-customers.form.pj.bank_reference.state_id')}
          name="state_id"
          rules={{ required: true }}
        >
          <ComboBox
            options={stateOptions}
            onChange={value => {
              if (value) form.setValue('city_id', '');
            }}
          />
        </FormItem>

        <FormItem
          label={t('pages.registered-customers.form.pj.bank_reference.city_id')}
          name="city_id"
          rules={{ required: true }}
        >
          <ComboBox options={cityOptions} disabled={!stateId} />
        </FormItem>

        <FormItem
          label={t('pages.registered-customers.form.pj.bank_reference.phone_number')}
          name="phone_number"
          rules={{ required: true }}
        >
          <InputWithMask mask="(99) 9999-99999" placeholder="(__) _____-____" />
        </FormItem>

        <FormItem
          label={t('pages.registered-customers.form.pj.bank_reference.contact_name')}
          name="contact_name"
          rules={{ required: true }}
        >
          <Input />
        </FormItem>
      </FormGrid>
    </Form>
  );
});

BankReferenceForm.displayName = 'BankReferenceForm';

import styled from 'styled-components';

export const ButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end !important;
  flex-direction: row !important;
`;

export const ContractSelect = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;

  p {
    color: ${({ theme }) => theme.colors.primary};
    font-weight: bold;
  }
`;

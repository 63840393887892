import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Spin } from '../../../../../../components';
import { Container } from './styles';

// eslint-disable-next-line no-shadow
const Docusing: React.FC = () => {
  const { t } = useTranslation();
  const [loading] = useState(false);

  return (
    <>
      <Spin spinning={loading}>
        <Container>
          <div className="row">
            <div className="col col-content">
              <div className="form">
                <div className="switch-agrodata">
                  <h2>
                    {t('pages.credit-request.formalization-guarantees.pre-registration-docs.modalDraft.send_message')}
                  </h2>
                </div>
                <div className="switch-agrodata">
                  <p>
                    {t(
                      'pages.credit-request.formalization-guarantees.pre-registration-docs.modalDraft.send_sub_message',
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </Spin>
    </>
  );
};

export default Docusing;

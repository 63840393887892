import { Card } from 'antd';
import styled from 'styled-components';

export const SCard = styled(Card)`
  width: 100%;
  padding: 10px;
  margin: 5px;
  border-radius: 10px;
  background: ${({ theme: { colors } }) => colors.darkBlue};
  min-width: 310px;

  .ant-card-body {
    padding: 10px;
  }

  .ant-card-meta-title {
    color: ${({ theme: { colors } }) => colors.white};
    font-size: 40px;
  }
  .ant-card-meta-description {
    color: ${({ theme: { colors } }) => colors.white};
  }
`;

import React from 'react';
import { useTranslation } from 'react-i18next';

import { InfiniteSelect } from '@agrodatabr/agrodataui';
import { FormItem } from 'components';
import { useCreditRequestContext } from 'context/CreditRequestContext';
import { useCustody } from 'hooks/fetch/useCustody';
import { useCreditRequestStore } from 'pages/CreditRequest/store/useCreditRequestStore';
import { OptionData } from 'rc-select/lib/interface';
import { Converter } from 'utils/Converter';

export const CustodiaStateIdSelect = () => {
  const { t } = useTranslation();
  const { canEditCustodyFields, creditForm } = useCreditRequestContext();
  const { getStates } = useCustody();

  const setIsBlocking = useCreditRequestStore(state => state.setIsBlocking);
  const custodyState = useCreditRequestStore(state => state.custodyState);
  const custodyWarehouseAddressIsLegacy = useCreditRequestStore(state => state.custodyWarehouseAddressIsLegacy);
  const setCustodyStateId = useCreditRequestStore(state => state.setCustodyStateId);
  const setStateIsSelected = useCreditRequestStore(state => state.setStateIsSelected);

  const handleGetStateOptions = async (search: string, page: number) => {
    const { data, last_page } = await getStates({ params: { fill: search, page } });
    return { data: data.map((state: any) => Converter.selectOption(state, 'id', 'id', 'name')), lastPage: last_page };
  };

  return (
    <FormItem
      permission="custodia.states.index"
      label={t('pages.credit-request.form.json_data_warehouse_state_id')}
      name="custodia_state_id"
      rules={[{ required: !custodyWarehouseAddressIsLegacy }]}
      hidden={custodyWarehouseAddressIsLegacy}
    >
      <InfiniteSelect
        listHeight={250}
        showSearch
        getOptions={handleGetStateOptions}
        disabled={!canEditCustodyFields}
        options={custodyState ? [{ ...(Converter.selectOption(custodyState, 'id', 'id', 'name') as OptionData) }] : []}
        onChange={(value: any, option: any) => {
          if (value) {
            setIsBlocking(true);

            setStateIsSelected(true);
            setCustodyStateId(option.data.external_id);
          }
          creditForm.setFieldsValue({
            custodia_city_id: undefined,
            custodia_storage_unit_id: undefined,
            custodia_storage_commodity_division_id: undefined,
            'json_data.warehouse_street': '',
            custodia_approval_status: '',
            custodia_storage_category: '',
          });
        }}
      />
    </FormItem>
  );
};

import React from 'react';

import { Spin } from 'antd';

import { Banner } from '..';

import Sidebar from './Sidebar';
// import useController from './hooks/useController';
import { Container } from './styles';

function AuthPage({ loading = false, elements, children }: any) {
  // const [loading] = useController();

  return (
    <Container>
      <div className="row">
        <Spin spinning={loading}>
          <Sidebar show={elements || ['languages', 'client_logo', 'form', 'company_logo']}>{children}</Sidebar>
        </Spin>
        <div className="col col-banner">
          <Banner />
        </div>
      </div>
    </Container>
  );
}

export default AuthPage;

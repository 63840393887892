import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FaPlus, FaMinus, FaSearch } from 'react-icons/fa';

import { Col, Form, Spin } from 'antd';
import { Store } from 'antd/lib/form/interface';
import { TablePaginationConfig, TableProps } from 'antd/lib/table';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';

import HarvestData from '../../../@types/data/HarvestData';
import PaginationData from '../../../@types/data/PaginationData';
import { ResponseListData } from '../../../@types/data/ResponseData';
import DTOErrorReponse from '../../../@types/dtos/DTOErrorReponse';
import { Breadcrumb, Button, Input, ModalStatus, FormItem, Row, IconWithTooltip } from '../../../components';
import { TableData } from '../../../compositions';
import { Can } from '../../../hooks/ability';
import { useAuth } from '../../../hooks/auth';
import { usePage } from '../../../hooks/page';
import adminApi from '../../../services/api/admin';
import HarvestForm from '../../Registers/Harvest/HarvestForm';
import columns from './columns';
import { SContainer, SHeader, STitlePage, SFilterContainer, SFormContainer, SFormButtons } from './styles';

const Permission: React.FC = () => {
  const { t, i18n } = useTranslation();
  const [form] = Form.useForm();

  const { updateUserData } = useAuth();
  const { alertStatus } = usePage();

  const [data, setData] = useState([]);
  const [disabledButton, setDisabledButton] = useState(false);
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [paginationConfig, setPaginationConfig] = useState<TablePaginationConfig>({
    current: 1,
    total: 1,
    pageSize: 20,
  });
  const [visibilityForm, setVisibilityForm] = useState(false);

  const handleSuccess = (messageSuccess: string) =>
    ModalStatus({
      type: 'success',
      title: t('modal.success'),
      subTitle: messageSuccess,
    });

  const updateData = (row: Store) => {
    let dataUpdated: Array<Store> = data;
    if (data.length > 0) {
      const rowIndex = data.findIndex((item: Store) => row.id === item.id);
      // Se encontrar o item remove e adiciona ele atualizado na mesma posição
      if (rowIndex > -1) {
        dataUpdated.splice(rowIndex, 1, row);
      } else {
        if (dataUpdated.length === paginationConfig.pageSize) {
          // Se não encontrar, remove o último (por conta da paginação) e
          // adiciona o item na primeira posição
          dataUpdated.splice(-1, 1);
        }
        dataUpdated = [row, ...dataUpdated];
      }
    } else {
      dataUpdated.push(row);
    }

    setData(dataUpdated);
  };

  const loadTableData = useCallback(
    (values: Partial<HarvestForm & PaginationData<HarvestData>>) => {
      setLoading(true);
      const dataSend = {
        params: {
          per_page: paginationConfig.pageSize,
          ...values,
        },
      };

      adminApi.permissions
        .get(dataSend)
        .then((response: ResponseListData<HarvestData>) => {
          const result = response.data.data;
          setData(result.data);
          setPaginationConfig(paginationConfigState => ({
            ...paginationConfigState,
            current: result.current_page,
            total: result.total,
          }));
        })
        .catch((err: DTOErrorReponse) => alertStatus(err, 'error'))
        .finally(() => setLoading(false));
    },
    // eslint-disable-next-line
    [i18n.language, paginationConfig.pageSize],
  );

  const handleDisplayForm = () => {
    setVisibilityForm(!visibilityForm);
    setEditing(false);
    setDisabledButton(false);
    setLoadingButton(false);
    form.resetFields();
  };

  const handleHideForm = () => {
    setVisibilityForm(false);
  };

  const handleRegister = () => {
    setLoading(true);
    setLoadingButton(true);
    setDisabledButton(true);
    form
      .validateFields()
      .then(() => {
        const formValues = form.getFieldsValue();
        adminApi.permissions
          .store({
            ...formValues,
          })
          .then(response => {
            const { data: permission } = response.data;
            updateData(permission);
            handleSuccess(response.data.message);
            handleHideForm();
            setLoading(false);
            setLoadingButton(false);
            setDisabledButton(false);
            form.resetFields();
            updateUserData();
          })
          .catch((err: DTOErrorReponse) => {
            alertStatus(err, 'error');
            setLoading(false);
            setLoadingButton(false);
            setDisabledButton(false);
          });
      })
      .catch((err: DTOErrorReponse) => {
        alertStatus(err, 'error');
        setLoading(false);
        setLoadingButton(false);
        setDisabledButton(false);
      });
  };

  const handleEdit = () => {
    setLoading(true);
    setLoadingButton(true);
    setDisabledButton(true);
    form
      .validateFields()
      .then(() => {
        const formValues = form.getFieldsValue();

        adminApi.permissions
          .update(formValues.id, {
            ...formValues,
          })
          .then(response => {
            const { data: permission } = response.data;
            updateData(permission);
            handleSuccess(response.data.message);
            handleHideForm();
            setLoading(false);
            setLoadingButton(false);
            setDisabledButton(false);
            form.resetFields();
            updateUserData();
          })
          .catch((err: DTOErrorReponse) => {
            alertStatus(err, 'error');
            setLoading(false);
            setLoadingButton(false);
            setDisabledButton(false);
          });
      })
      .catch((err: DTOErrorReponse) => {
        alertStatus(err, 'error');
        setLoading(false);
        setLoadingButton(false);
        setDisabledButton(false);
      });
  };

  // Table DATA Config: BEGIN
  const getActions = () => ({
    render: (_: number, record: any) => (
      <>
        <Can I="admin.permission.destroy" a="">
          <IconWithTooltip
            action="destroy"
            title={t('pages.admin.permission.table.row.removeIcon')}
            onClick={() => handleRemoveChange(record)}
          />
        </Can>
        <Can I="admin.permission.update" a="">
          <IconWithTooltip
            action="edit"
            title={t('pages.admin.permission.table.row.editIcon')}
            onClick={() => {
              form.setFieldsValue({
                ...record,
              });

              form.scrollToField('name', {
                scrollMode: 'always',
                block: 'start',
                behavior: actions =>
                  actions.forEach(({ el, top }) => {
                    el.scrollTop = top - 280;
                  }),
              });

              setVisibilityForm(true);
              setEditing(true);
              setLoading(false);
              setLoadingButton(false);
              setDisabledButton(false);
            }}
          />
        </Can>
      </>
    ),
  });

  const [searchForm] = Form.useForm();
  const tableConfig = {
    search: '',
    filtered: {},
    sorter: {},
    getActions,
  };

  const [tableDataConfig, setTableDataConfig] = useState(tableConfig);

  const handleFastSearch = () => {
    const searchValue = searchForm.getFieldsValue();
    setTableDataConfig(tableDataConfigState => ({
      ...tableDataConfigState,
      search: searchValue.search,
    }));
    loadTableData({
      name: searchValue.search,
    });
  };

  const tableDataClearAllFilters = () => {
    searchForm.resetFields();
    setTableDataConfig(tableDataConfigState => ({
      ...tableDataConfigState,
      filtered: {},
      search: '',
      sorter: {},
    }));
    loadTableData({ page: 1 });
  };

  const handleTableChange: TableProps<any>['onChange'] = useCallback(
    (
      pagination: TablePaginationConfig,
      filters: Record<string, FilterValue | null>,
      sorter: SorterResult<any> | SorterResult<any>[],
    ) => {
      const searchValue = searchForm.getFieldValue('search') as string;

      setLoading(true);
      setTableDataConfig(tableDataConfigState => ({
        ...tableDataConfigState,
        filtered: filters,
        sorter,
      }));

      let direction = '';
      let search = {};
      let sort = '';

      if (sorter) {
        const { field, order } = sorter as SorterResult<any>;

        if (order === 'ascend') direction = 'asc';
        if (order === 'descend') direction = 'desc';
        sort = field?.toString();
      }

      if (filters) {
        const { name } = filters;

        search = {
          name: name !== null && name !== undefined ? name[0].toString() : '',
        };
      }

      setPaginationConfig(paginationConfigState => ({
        ...paginationConfigState,
        pageSize: pagination.pageSize,
        current: pagination.current,
      }));

      loadTableData({
        page: pagination.current,
        sort,
        direction,
        name: searchValue || '',
        ...search,
      });
    },
    // eslint-disable-next-line
    [loadTableData],
  );
  // Table DATA Config: END

  const handleRemoveChange = (record: any) => {
    ModalStatus({
      type: 'delete',
      title: t('pages.admin.permission.delete.title'),
      subTitle: t('pages.admin.permission.delete.subtitle'),
      cancelText: t('pages.admin.permission.delete.cancel'),
      okText: t('pages.admin.permission.delete.confirm'),
      onOk: () => {
        adminApi.permissions
          .delete(record.id, {
            params: {},
          })
          .then(response => {
            handleSuccess(response.data.message);
            handleTableChange({ current: 1 }, { active: [1] }, {}, null);
          })
          .catch((err: DTOErrorReponse) => alertStatus(err, 'error'));
      },
    });
  };

  useEffect(() => {
    const filters: any = {};
    handleTableChange({ current: 1, pageSize: paginationConfig.pageSize }, filters, {}, null);
  }, [handleTableChange, paginationConfig.pageSize]);

  return (
    <>
      <Breadcrumb
        items={[{ title: t('breadcrumb.admin') }, { title: t('breadcrumb.permissions'), to: '/admin/permission' }]}
      />

      <SContainer>
        <SHeader>
          <STitlePage>{t('pages.admin.permission.title_page')}</STitlePage>

          <Can I="admin.permission.store" a="">
            <Button status="primary" icon={visibilityForm ? <FaMinus /> : <FaPlus />} onClick={handleDisplayForm}>
              {t('pages.admin.permission.buttonAdd')}
            </Button>
          </Can>
        </SHeader>

        <SFormContainer visible={visibilityForm}>
          <Spin spinning={loading}>
            <h2>{t('pages.admin.permission.registerTitle')}</h2>
            <Form form={form}>
              <FormItem name="id" style={{ display: 'none' }}>
                <Input />
              </FormItem>
              <Row gutter={[26, 26]}>
                <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                  <FormItem
                    label={t('pages.admin.permission.form.name')}
                    name="name"
                    rules={[{ required: true, min: 3, max: 255 }]}
                  >
                    <Input type="text" />
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={[26, 26]}>
                <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                  <FormItem
                    label={t('pages.admin.permission.form.description')}
                    name="description"
                    rules={[{ required: true, min: 3, max: 255 }]}
                  >
                    <Input type="text" />
                  </FormItem>
                </Col>
              </Row>
              <SFormButtons>
                <Button status="secondary" htmlType="reset" onClick={handleHideForm} disabled={disabledButton}>
                  {t('pages.admin.permission.buttonCancel')}
                </Button>
                {editing && (
                  <Button
                    status="primary"
                    htmlType="submit"
                    onClick={handleEdit}
                    loading={loadingButton}
                    disabled={disabledButton}
                  >
                    {t('pages.admin.permission.buttonEdit')}
                  </Button>
                )}
                {!editing && (
                  <Button
                    status="primary"
                    htmlType="submit"
                    onClick={handleRegister}
                    loading={loadingButton}
                    disabled={disabledButton}
                  >
                    {t('pages.admin.permission.buttonRegister')}
                  </Button>
                )}
              </SFormButtons>
            </Form>
          </Spin>
        </SFormContainer>

        <SFilterContainer>
          <Form
            form={searchForm}
            name="filter-permission"
            className="form-secondary form-filters grid-filters"
            onFinish={handleFastSearch}
          >
            <div className="filter-search">
              <FormItem name="search" label={t('pages.admin.permission.search')}>
                <Input />
              </FormItem>
            </div>
            <div className="filter-button">
              <FormItem label="" className="form-item-without-label">
                <Button status="primary" text="uppercase" htmlType="submit">
                  <FaSearch /> {t('form.actions.search')}
                </Button>
              </FormItem>
            </div>
            <div className="filter-clear">
              <Button status="secondary" size="middle" onClick={tableDataClearAllFilters}>
                {t('pages.admin.permission.buttonClearFilter')}
              </Button>
            </div>
          </Form>
        </SFilterContainer>

        <TableData
          rowKey="id"
          columns={columns}
          dataSource={data}
          loading={loading}
          onChange={handleTableChange}
          pagination={paginationConfig}
          tableConfig={tableDataConfig}
        />
      </SContainer>
    </>
  );
};

export default Permission;

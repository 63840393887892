import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;

  h1 {
    color: ${({ theme: { colors } }) => colors.primary};
    font-family: ${({ theme: { fonts } }) => fonts.primary};
    font-size: 24px;
    font-weight: 700;
  }
`;

export const LinksNotificationContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

export const LinkNotification = styled.a`
  color: ${({ theme: { colors } }) => colors.text};
  font-family: ${({ theme: { fonts } }) => fonts.primary};
  font-size: 14px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding-left: 8px;
  padding-right: 8px;

  & + a {
    border-left: solid ${({ theme: { colors } }) => colors.greys.grey02} 1px;
  }

  &:hover {
    color: ${({ theme: { colors } }) => colors.primary};
  }

  svg {
    font-size: 16px;
    margin-right: 4px;
  }
`;

export const NotificationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 16px;
`;

export const Notification = styled.a`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border-top: solid 1px transparent;
  color: ${({ theme: { colors } }) => colors.text};
  font-family: ${({ theme: { fonts } }) => fonts.primary};
  font-size: 12px;
  padding: 8px;

  & + a {
    border-top: solid 1px #42424240;
  }

  &.not-read {
    background: rgba(255, 162, 60, 0.2);
  }

  &:hover {
    background: ${({ theme: { colors } }) => colors.primary};
    /* border-top: solid 1px ${({ theme: { colors } }) => colors.greys.grey03};
    border-bottom: solid 1px ${({ theme: { colors } }) => colors.greys.grey03}; */
    color: #ffffff;
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;

    * + * {
      padding-left: 12px;
    }
  }
`;

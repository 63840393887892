import React from 'react';
import { useTranslation } from 'react-i18next';
import { ImSearch } from 'react-icons/im';

import { useDraftModelContext } from '../../context/DraftModelContext';
import { Container, SInput } from './styles';

export const SearchInput = () => {
  const { changePage } = useDraftModelContext();
  const { t } = useTranslation();

  const [value, setValue] = React.useState('');

  const handleChangeValue = (evt: React.ChangeEvent<HTMLInputElement>) => setValue(evt.target.value);

  const handleSearch = () => changePage(1, 5, value);

  return (
    <Container>
      <ImSearch />
      <SInput
        placeholder={t('pages.edocuments.emission.draftEmission.search')}
        value={value}
        onChange={handleChangeValue}
        allowClear
        onPressEnter={handleSearch}
      />
    </Container>
  );
};

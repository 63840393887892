import * as React from 'react';

import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import { cn } from 'lib/utils';
import { Check } from 'lucide-react';
import { v4 as uuid } from 'uuid';

import { Label } from './label';

const BaseCheckbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root> & {
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  }
>(({ className, ...props }, ref) => (
  <CheckboxPrimitive.Root
    ref={ref}
    className={cn(
      'peer h-4 w-4 shrink-0 rounded-sm border border-primary ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-primary data-[state=checked]:text-primary-foreground',
      className,
    )}
    checked={!!props.value || props.checked}
    onCheckedChange={value => {
      if (props.onChange) {
        props.onChange({
          target: {
            value,
            name: props.name,
          },
        } as React.ChangeEvent<HTMLInputElement>);
      }
    }}
    {...props}
  >
    <CheckboxPrimitive.Indicator className={cn('flex items-center justify-center text-current')}>
      <Check className="h-4 w-4" />
    </CheckboxPrimitive.Indicator>
  </CheckboxPrimitive.Root>
));
BaseCheckbox.displayName = CheckboxPrimitive.Root.displayName;

const Checkbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root> & {
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    label: string;
  }
>(({ className, label, ...props }, ref) => {
  const id = React.useMemo(() => uuid(), []);

  return (
    <div className="flex gap-2 w-full items-center">
      <BaseCheckbox
        ref={ref}
        title={label}
        className={cn(
          'peer h-4 w-4 shrink-0 rounded-sm border border-primary ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-primary data-[state=checked]:text-primary-foreground',
          className,
        )}
        id={id}
        {...props}
      />
      <Label htmlFor={id}>{label}</Label>
    </div>
  );
});

export { BaseCheckbox, Checkbox };

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaSearch } from 'react-icons/fa';

import { TableData, TablePaginationConfig, TableTranslation } from '@agrodatabr/agrodataui';
import { Form } from 'antd';
import { Button, FormItem, IconWithTooltip, Input, ModalStatus } from 'components';
import { Can } from 'hooks/ability';
import { useIntegrationGroups } from 'hooks/fetch/useIntegrationGroups';
import { useExpandable } from 'hooks/helpers/useExpandable';

import { IntegrationGroupData } from '../../../../@types/data/IntegrationGroupData';
import { columns } from './columns';
import { ExpandedRow } from './ExpandedRow';
import { ListProps } from './types';
import { SFilterContainer } from 'pages/Integrations/styles';

export const List = ({ setGroupToEdit, controller }: ListProps) => {
  const { getGroups, isFetching, isFetchingGroupUsers, removeGroup, isRemovingGroup } = useIntegrationGroups();
  const { t } = useTranslation();
  const { onExpand, expandedRowKeys } = useExpandable(true);
  const [searchForm] = Form.useForm();

  const [filters, setFilters] = useState({});

  const handleFetchData = async (pagination: TablePaginationConfig) => {
    const response = await getGroups({
      params: {
        page: pagination.current ?? 1,
        per_page: pagination.pageSize ?? 10,
        ...filters,
      },
    });

    if (!response) return { data: [], total: 0 };

    return response;
  };

  const handleFastSearch = () => {
    const value = searchForm.getFieldsValue();
    setFilters(value);
  };

  const tableDataClearAllFilters = () => {
    searchForm.resetFields();
    setFilters({});
  };

  const handleRemoveGroup = async (groupId: string) => {
    await removeGroup(groupId);

    controller.refresh();
  };

  const getActions = () => ({
    render: (_1: number, record: IntegrationGroupData) => (
      <>
        <Can I="worker.group.delete">
          <IconWithTooltip
            action="destroy"
            title={t('pages.admin.changelog.table.icons.remove')}
            onClick={() =>
              ModalStatus({
                type: 'warning',
                iconName: 'warning',
                title: t(`modal.warning`),
                subTitle: t('pages.integrations.group.table.action.removeConfirm'),
                cancelText: t('hooks.creditStatus.modal-status-buttons.no'),
                okText: t('hooks.creditStatus.modal-status-buttons.yes'),
                onOk: () => {
                  handleRemoveGroup(record.id);
                },
              })
            }
          />
        </Can>
        <Can I="worker.group.update">
          <IconWithTooltip
            action="edit"
            title={t('pages.admin.changelog.table.icons.edit')}
            onClick={() => {
              setGroupToEdit(record);
            }}
          />
        </Can>
      </>
    ),
  });

  return (
    <div>
      <SFilterContainer>
        <Form
          form={searchForm}
          name="filter-harvest"
          className="form-secondary form-filters grid-filters"
          onFinish={handleFastSearch}
        >
          <div className="filter-search">
            <FormItem name="search" label={t('pages.register.harvest.search')}>
              <Input />
            </FormItem>
          </div>
          <div className="filter-button">
            <FormItem label="" className="form-item-without-label">
              <Button status="primary" text="uppercase" htmlType="submit">
                <FaSearch /> {t('form.actions.search')}
              </Button>
            </FormItem>
          </div>
          <div className="filter-clear">
            <Button status="secondary" size="middle" onClick={tableDataClearAllFilters}>
              {t('pages.register.harvest.buttonClearFilter')}
            </Button>
          </div>
        </Form>
      </SFilterContainer>

      <TableData
        rowKey="id"
        controller={controller}
        searchRequest={handleFetchData}
        searchFilters={filters}
        width="100%"
        expandable={{
          expandedRowKeys,
          onExpand: async (expanded, record: any) => {
            onExpand(expanded, record);
          },
          expandedRowRender: (data: any) => (
            <p style={{ margin: 0 }}>
              <ExpandedRow groupId={data.id} isLoading={isFetchingGroupUsers} />
            </p>
          ),
        }}
        columns={columns.map((column: any) => {
          const columnRender: any = {
            ...column,
            title: t(column.title),
          };

          return columnRender;
        })}
        loading={isFetching || isRemovingGroup}
        tableConfig={{
          getActions,
        }}
        translation={
          {
            pagination: { totalOfRegisters: t('table.totalOfRegisters') },
            filterDropdown: {
              filterActive: 'Ativo',
              filterInactive: 'Inativo',
            },
            filterConfirm: 'Buscar',
            filterReset: 'Limpar',
          } as TableTranslation
        }
        pagination={{ position: ['bottomCenter'] }}
      />
    </div>
  );
};

import { PaginatedQueryParams } from 'hooks/usePaginatedQuery';
import { useSelectInfiniteQuery } from 'hooks/useSelectInfiniteQuery';
import PaginationData from 'types/Api/PaginationData';

export const useGetBpsOptionsQuery = (params?: PaginatedQueryParams & any) => {
  const query = useSelectInfiniteQuery<PaginationData<any>>(
    'dashboard/exposure/bppartner',
    {
      queryKey: ['dashboard/exposure/bppartner'],
      staleTime: 1000 * 60 * 5,
      permissions: ['dashboard.exposure.bppartner'],
    },
    params,
    option => {
      return option.map(item => ({
        label: item.partner,
        value: item.partner_name,
      }));
    },
  );

  return query;
};

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Form } from 'antd';
import { Store } from 'antd/lib/form/interface';

import DTOErrorReponse from '../../../@types/dtos/DTOErrorReponse';
import { Button, Input, FormItem, ModalStatus, Back } from '../../../components';
import AuthPage from '../../../components/AuthPage';
import { usePage } from '../../../hooks/page';
import authService from '../../../services/api/auth';

const Forgot: React.FC = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const { alertStatus } = usePage();

  const [loading, setLoading] = useState(false);

  const onFinish = (values: Store) => {
    setLoading(true);
    authService
      .forgotPassword({
        ...values,
      })
      .then(response => {
        ModalStatus({
          type: 'success',
          title: t('modal.success'),
          subTitle: response.data.message,
          onOk: () => form.resetFields(),
        });
      })
      .catch((err: DTOErrorReponse) => alertStatus(err, 'errorLogin'))
      .finally(() => setLoading(false));
  };

  const onFinishFailed = (errorInfo: any) => console.error(errorInfo);

  return (
    <AuthPage loading={loading}>
      <Back to="/" />
      <Form
        form={form}
        name="forgot-password"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        className="form-primary"
      >
        <FormItem
          label={t('forgot.form.email')}
          name="email"
          rules={[
            {
              required: true,
              message: t('login.required.email'),
            },
          ]}
        >
          <Input type="email" />
        </FormItem>
        <FormItem distance="small">
          <div className="button">
            <Button htmlType="submit">{t('forgot.form.submit')}</Button>
          </div>
        </FormItem>
      </Form>
    </AuthPage>
  );
};

export default Forgot;

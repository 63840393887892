import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Dialog,
  DialogContent,
  DialogFooterForm,
  DialogHeader,
  DialogPortal,
  DialogTrigger,
} from 'components/ui/dialog';

import { useRegisteredCustomerStore } from '../stores/use-registered-customer-store';
import { deliveryHistoryInitialValues, DeliveryHistoryFormData, DeliveryHistoryForm } from './delivery-history.form';

type Props = {
  children?: React.ReactNode;
  onSuccess?: (data: DeliveryHistoryFormData) => void;
};

export const DeliveryHistoryModal = ({ children, onSuccess }: Props) => {
  const formRef = useRef<any>(null);
  const { t } = useTranslation();

  const isDeliveryHistoryModalOpen = useRegisteredCustomerStore(state => state.isDeliveryHistoryModalOpen);
  const setDeliveryHistoryToEdit = useRegisteredCustomerStore(state => state.setDeliveryHistoryToEdit);
  const deliveryHistoryToEdit = useRegisteredCustomerStore(state => state.deliveryHistoryToEdit);
  const setIsDeliveryHistoryModalOpen = useRegisteredCustomerStore(state => state.setIsDeliveryHistoryModalOpen);

  return (
    <Dialog
      open={isDeliveryHistoryModalOpen}
      onOpenChange={value => {
        setIsDeliveryHistoryModalOpen(value);
        if (!value) {
          setDeliveryHistoryToEdit(null);
          formRef.current?.form.reset(deliveryHistoryInitialValues);
        }
      }}
    >
      <DialogTrigger>{children}</DialogTrigger>
      <DialogPortal>
        <DialogContent className="md:w-[50%] w-[96%]">
          <DialogHeader>
            {deliveryHistoryToEdit ? t('edit') : t('new-m')} {t('delivery-history')}
          </DialogHeader>
          <DeliveryHistoryForm
            ref={formRef}
            onSuccess={data => {
              setIsDeliveryHistoryModalOpen(false);
              setDeliveryHistoryToEdit(null);
              onSuccess?.(data);
              formRef.current?.form.reset(deliveryHistoryInitialValues);
            }}
          />
          <DialogFooterForm
            onConfirm={() => {
              formRef.current?.submit();
            }}
            onCancel={() => {
              formRef.current?.form.reset(deliveryHistoryInitialValues);
              setDeliveryHistoryToEdit(null);
              setIsDeliveryHistoryModalOpen(false);
            }}
          />
        </DialogContent>
      </DialogPortal>
    </Dialog>
  );
};

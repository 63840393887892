import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'components';
import { useDraftSignatures } from 'hooks/fetch/useDraftSignatures';
import { useModalMessage } from 'hooks/helpers/useModalMessage';
import { useContractContext } from 'pages/Contracts/context/ContractContext';

import { SignatureRequestCard } from '../../../../../components/SignatureRequestCard';
import { SignatureRequest } from '../types';
import { Container } from './styles';

interface Props {
  signaturesRequests: SignatureRequest[];
  allowManualStatusChange?: boolean;
  openSignatureRequestStatusChangeModal: (id: string) => void;
}

export const SignaturesRequests = ({
  signaturesRequests,
  allowManualStatusChange = false,
  openSignatureRequestStatusChangeModal,
}: Props) => {
  const { sync, isSending, resend } = useDraftSignatures();
  const { shouldShowSyncSubscribersButton, selectedContract, updateContract, shouldShowResendSubscribersButton } =
    useContractContext();
  const { t } = useTranslation();
  const { handleSuccess } = useModalMessage();

  const handleSyncSubscribers = async () => {
    const response = await sync(selectedContract.id);
    if (response) {
      handleSuccess(t('pages.edocuments.emission.signatures.syncSuccess'));
      updateContract();
    }
  };

  const handleResendSubscribers = async () => {
    const response = await resend(selectedContract.id);
    if (response) {
      handleSuccess(t('pages.edocuments.emission.signatures.resendSuccess'));
      updateContract();
    }
  };

  return (
    <Container>
      {shouldShowSyncSubscribersButton ? (
        <Button onClick={handleSyncSubscribers} permission="contract.sync" loading={isSending}>
          {t('pages.edocuments.emission.signatures.sync')}
        </Button>
      ) : null}

      {shouldShowResendSubscribersButton ? (
        <Button permission="contract.resend" onClick={handleResendSubscribers} loading={isSending}>
          {t('pages.edocuments.emission.signatures.resend')}
        </Button>
      ) : null}
      {signaturesRequests.map(signatureRequest => (
        <SignatureRequestCard
          key={signatureRequest.id}
          allowManualStatusChange={allowManualStatusChange}
          signatureRequest={signatureRequest}
          openSignatureRequestStatusChangeModal={openSignatureRequestStatusChangeModal}
        />
      ))}
    </Container>
  );
};

import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AiFillFileText } from 'react-icons/ai';
import { GiChecklist } from 'react-icons/gi';

import { RadioChangeEvent } from 'antd/lib/radio';
import { DraftButton } from 'pages/Contracts/components/DraftButton';

import { DraftFormatRadio } from '../../../../../../components/DraftFormatRadio';
import { SearchInput } from '../../../../../../components/SearchInput';
import { DraftModelContext } from '../../../../../../context/DraftModelContext';
import { RadioGroup, SPagination } from './styles';

const PAGE_SIZE = 5;

export const DraftModelList = () => {
  const {
    paginatedDraftModels: { total, data: draftModels },
    setSelectedDraftModelId,
    isSelectedModelConfirmed,
    confirmModel,
    changePage,
    isFetchingSelectedDraftModel,
    currentPage,
    selectedDraftModelId,
  } = useContext(DraftModelContext);
  const { t } = useTranslation();

  const showTotal = () =>
    `${t('pages.edocuments.emission.draftEmission.table.showing')} ${draftModels.length} ${t(
      'pages.edocuments.emission.draftEmission.table.from',
    )} ${total}`;

  const handleChange = (evt: RadioChangeEvent) => {
    draftModels.find(model => model.id === evt.target.value);

    // if (selectedModel) setFileToPreview({ type: 'docx', url: selectedModel.url });

    setSelectedDraftModelId(evt.target.value);
  };

  return (
    <>
      <SearchInput />

      <RadioGroup onChange={handleChange}>
        {draftModels?.map(draft => (
          <DraftFormatRadio
            key={draft.id}
            name={draft.number}
            value={draft.id}
            label={draft.number}
            icon={AiFillFileText}
            isSelected={isSelectedModelConfirmed(draft.id)}
            onShowButtonClick={() => {
              window.open(`/edocuments/templates?templateId=${draft.id}`, '_blank');
            }}
          />
        ))}
      </RadioGroup>

      <DraftButton
        onClick={confirmModel}
        disabled={!selectedDraftModelId || isFetchingSelectedDraftModel}
        isLoading={isFetchingSelectedDraftModel}
      >
        <GiChecklist /> {t('pages.edocuments.emission.draftEmission.confirm')}
      </DraftButton>

      <SPagination
        size="small"
        defaultCurrent={1}
        total={total}
        pageSize={PAGE_SIZE}
        showTotal={showTotal}
        onChange={changePage}
        showSizeChanger={false}
        current={currentPage}
        showLessItems
      />
    </>
  );
};

import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100% !important;
  .ant-spin-nested-loading {
    height: 100% !important;
    .ant-spin-container {
      height: 100% !important;
    }
  }
`;

export const Container = styled.div<{ lowerButton: boolean }>`
  display: grid;
  grid-template-rows: ${({ lowerButton }) => (lowerButton ? '95% 15%' : '85% 15%')};
  gap: 20px;
  height: 100%;
  position: relative;
`;

import React from 'react';
import { useTranslation } from 'react-i18next';

import { InputNumber } from 'antd';
import { FormItem } from 'components';
import { useCreditRequestStore } from 'pages/CreditRequest/store/useCreditRequestStore';
import { Formatter } from 'utils/Formatter';

interface Props {
  disabled?: boolean;
  disableOnChange?: boolean;
  required?: boolean;
}

export const RequestedAmountInput = ({ disabled = false, disableOnChange = false, required = false }: Props) => {
  const { t } = useTranslation();

  const setIsBlocking = useCreditRequestStore(state => state.setIsBlocking);

  return (
    <FormItem
      label={t('pages.credit-request.form.json_data_requested_amount')}
      name="requested_amount"
      rules={[
        { required },
        {
          validator(_, value) {
            if ((!value || value <= 0) && required) {
              return Promise.reject(t('pages.credit-request.form.requested_amount_empty'));
            }
            return Promise.resolve();
          },
        },
      ]}
    >
      <InputNumber<string>
        min="0"
        formatter={(value: string | number) => Formatter.money(value)}
        parser={(value: string) => Formatter.moneyParser(value)}
        disabled={disabled}
        onChange={value => {
          if (value && !disableOnChange) {
            setIsBlocking(true);
          }
        }}
      />
    </FormItem>
  );
};

import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';

import { Col, Row } from 'antd';
import { ShouldRender } from 'components/ShouldRender';
import { useCreditRequestStore } from 'pages/CreditRequest/store/useCreditRequestStore';
import { Formatter } from 'utils/Formatter';
import { v4 as uuid } from 'uuid';

import { GuaranteeValueProps, GuaranteeValueTypeProps, RootProps, ValueProps } from './GuaranteesResume.types';

export const GuaranteeRequestedValue = ({ value }: Pick<GuaranteeValueProps, 'value'>) => {
  const { t } = useTranslation();

  return <GuaranteeValue value={value} label={t('pages.credit-request.modal-limit.approved_value')} />;
};

export const GuaranteeRequestedCashValue = ({ value }: Pick<GuaranteeValueProps, 'value'>) => {
  const { t } = useTranslation();

  return <GuaranteeValue value={value} label={t('pages.credit-request.modal-limit.approved_value_cash')} />;
};

export const GuaranteeRequestedBarterValue = ({ value }: Pick<GuaranteeValueProps, 'value'>) => {
  const { t } = useTranslation();

  return <GuaranteeValue value={value} label={t('pages.credit-request.modal-limit.approved_value_barter')} />;
};

export const GuaranteeApprovedValue = ({ value }: Pick<GuaranteeValueProps, 'value'>) => {
  const { t } = useTranslation();

  return <GuaranteeValue value={value} label={t('pages.credit-request.modal-limit.approved_value_guarantee')} />;
};

export const GuaranteeApprovedCashValue = ({ value }: Pick<GuaranteeValueProps, 'value'>) => {
  const { t } = useTranslation();

  return <GuaranteeValue value={value} label={t('pages.credit-request.modal-limit.approved_value_cash_guarantee')} />;
};

export const GuaranteeApprovedBarterValue = ({ value }: Pick<GuaranteeValueProps, 'value'>) => {
  const { t } = useTranslation();

  return <GuaranteeValue value={value} label={t('pages.credit-request.modal-limit.approved_value_barter_guarantee')} />;
};

export const GuaranteeOpenValue = ({ value }: Pick<GuaranteeValueProps, 'value'>) => {
  const { t } = useTranslation();

  return <GuaranteeValue value={value} label={t('pages.credit-request.modal-limit.open_value')} />;
};

export const GuaranteeOpenCashValue = ({ value }: Pick<GuaranteeValueProps, 'value'>) => {
  const { t } = useTranslation();

  return <GuaranteeValue value={value} label={t('pages.credit-request.modal-limit.open_value_cash')} />;
};

export const GuaranteeOpenBarterValue = ({ value }: Pick<GuaranteeValueProps, 'value'>) => {
  const { t } = useTranslation();

  return <GuaranteeValue value={value} label={t('pages.credit-request.modal-limit.open_value_barter')} />;
};
export const GuaranteeDifferenceValue = ({ value }: Pick<GuaranteeValueProps, 'value'>) => {
  const { t } = useTranslation();

  return <GuaranteeValue value={value} label={t('pages.credit-request.modal-limit.difference_value')} />;
};
export const GuaranteeDifferenceCashValue = ({ value }: Pick<GuaranteeValueProps, 'value'>) => {
  const { t } = useTranslation();

  return <GuaranteeValue value={value} label={t('pages.credit-request.modal-limit.difference_value_cash')} />;
};
export const GuaranteeDifferenceBarterValue = ({ value }: Pick<GuaranteeValueProps, 'value'>) => {
  const { t } = useTranslation();

  return <GuaranteeValue value={value} label={t('pages.credit-request.modal-limit.difference_value_barter')} />;
};

export const GuaranteeValue = ({ value, label }: GuaranteeValueProps) => {
  return <Value label={label} value={(value ?? 0).toFixed(2)} />;
};

export const GuaranteeValueType = ({ children, isVisible }: GuaranteeValueTypeProps) => {
  return (
    <ShouldRender condition={isVisible}>
      <Row style={{ width: '100%', padding: '0 5px' }}>
        {Array.isArray(children)
          ? children.map(child => {
              return (
                <Col xs={24} sm={12} md={12} lg={6} xl={6} key={uuid()}>
                  {child}
                </Col>
              );
            })
          : children}
      </Row>
    </ShouldRender>
  );
};

export const Value = ({ label, value }: ValueProps) => {
  const creditRequestData = useCreditRequestStore(state => state.creditRequestData);

  return (
    <p style={{ whiteSpace: 'nowrap', display: 'flex', flexDirection: 'column' }}>
      <b>{label}:</b> {Formatter.money(value, 2, creditRequestData.currency_type.slug, true)}
    </p>
  );
};

export const Root = ({ children }: RootProps) => {
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <Children>{children}</Children>
    </ErrorBoundary>
  );
};

const ErrorFallback = ({ error }: any) => {
  const { t } = useTranslation();

  return (
    <div role="alert">
      <strong style={{ color: 'red' }}>{t('somethingWentWrong')}</strong>
      <pre>{process.env.REACT_APP_ENVIRONMENT === 'prod' ? t('contactAgrodata') : error.message}</pre>
    </div>
  );
};

const Children = ({ children }: RootProps) => {
  return (
    <Col span={20} style={{ padding: '15px 10px' }}>
      {children}
    </Col>
  );
};

export const GuaranteesResume = {
  Root,
  GuaranteeRequestedValue,
  GuaranteeRequestedBarterValue,
  GuaranteeRequestedCashValue,
  GuaranteeApprovedValue,
  GuaranteeApprovedBarterValue,
  GuaranteeApprovedCashValue,
  GuaranteeOpenValue,
  GuaranteeOpenBarterValue,
  GuaranteeOpenCashValue,
  GuaranteeDifferenceValue,
  GuaranteeDifferenceCashValue,
  GuaranteeDifferenceBarterValue,
  GuaranteeValueType,
};

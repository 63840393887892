import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useAuth } from 'hooks/auth';
import { usePage } from 'hooks/page';

export const UserLinkedAlert = ({ children }: { children: React.ReactNode }) => {
  const { t } = useTranslation();
  const { userIsLinked } = useAuth();
  const { alertStatus } = usePage();

  useEffect(() => {
    if (!userIsLinked) {
      alertStatus(
        [t('geral.alerts.credit-request.user-unlinked'), t('geral.alerts.contact-the-administrator')],
        'error',
      );
    }
    // eslint-disable-next-line
  }, [userIsLinked]);

  return <>{children}</>;
};

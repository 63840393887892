import styled from 'styled-components';

export const TopCards = styled.div`
  display: flex;
  width: 100%;
  overflow: auto;
`;

export const BoxChart = styled.div`
  border: 1px solid;
  border-color: ${({ theme: { colors } }) => colors.greys.grey04};
  border-radius: 10px;
  padding: 10px;
  margin: 5px;
  min-width: 550px;
  width: 100%;
  .highcharts-credits {
    display: none;
  }
  .area-title-options {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    .ant-radio-group {
      margin-top: 15px;
    }
    .ant-radio-button-wrapper {
      min-width: 60px;
    }
  }
  .expand {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
  }
  .normal {
    flex: 2;
  }
`;

export const Title = styled.h3`
  font-weight: 700;
  margin-top: 15px;
  margin: 5px;
`;

export const ChartsArea = styled.div`
  display: flex;
  flex-wrap: wrap;
  min-height: 500px;
`;

export const TableArea = styled.div`
  border: 1px solid;
  border-color: ${({ theme: { colors } }) => colors.greys.grey04};
  border-radius: 10px;
  padding: 10px;
  margin: 5px;
  margin-bottom: 10px;
`;

export const NotificationPtax = styled.div`
  font-weight: bold;
  color: ${({ theme: { colors } }) => colors.status.status01};
  padding: 10px;
`;

import { KanbanColumnCreditRequest } from '../../../../../../../@types/responseKanban/KanbanColumn';

export enum CreditValueType {
  BARTER = 'barter',
  CASH = 'cash',
  DEFAULT = 'default',
}

export interface CardHiddenFooterProps {
  data: KanbanColumnCreditRequest;
  setLoadingCard: (value: boolean) => void;
  setChangingRequestId: (value: string) => void;
  userPositionId: string;
  setCreditReportId: (value: string) => void;
  setExporting: (value: boolean) => void;
}

import { useMemo } from 'react';

import {
  DOCUMENT_TYPE_INSS,
  DOCUMENT_TYPE_SINTEGRA,
  DOCUMENT_TYPE_CONTRACT,
  DOCUMENT_DEPOSITARY,
  DOCUMENT_STATEMENT_OR_INJUNCTION,
  DOCUMENT_ASSIGNEE_CREDIT,
  DOCUMENT_MONSANTO,
} from 'configs/constants';
import { useCache } from 'hooks/cache';

export const useGetFileTypesFromCache = () => {
  const cache = useCache();

  const fileTypeInss = useMemo(() => {
    return cache.getCreditOptions('document_type_credit').find((x: any) => x.value === DOCUMENT_TYPE_INSS);
  }, [cache]);

  const fileTypeFethab = useMemo(() => {
    return cache.getCreditOptions('document_type_credit').find((x: any) => x.value === DOCUMENT_TYPE_SINTEGRA);
  }, [cache]);

  const fileTypeContract = useMemo(() => {
    return cache.getCreditOptions('document_type_credit').find((x: any) => x.value === DOCUMENT_TYPE_CONTRACT);
  }, [cache]);

  const fileTypeDepositary = useMemo(() => {
    return cache.getCreditOptions('document_type_credit').find((x: any) => x.value === DOCUMENT_DEPOSITARY);
  }, [cache]);

  const fileTypeStatementOrInjunction = useMemo(() => {
    return cache
      .getCreditOptions('document_type_credit')
      .find((x: any) => x.value === DOCUMENT_STATEMENT_OR_INJUNCTION);
  }, [cache]);

  const fileTypeMonsantoReceipt = useMemo(() => {
    return cache.getCreditOptions('document_type_credit').find((x: any) => x.value === DOCUMENT_MONSANTO);
  }, [cache]);

  const fileTypeAssigneeCredit = useMemo(() => {
    return cache.getCreditOptions('document_type_credit').find((x: any) => x.value === DOCUMENT_ASSIGNEE_CREDIT);
  }, [cache]);

  return {
    fileTypeInss,
    fileTypeFethab,
    fileTypeContract,
    fileTypeDepositary,
    fileTypeStatementOrInjunction,
    fileTypeMonsantoReceipt,
    fileTypeAssigneeCredit,
  };
};

import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FaPlus, FaMinus, FaSearch } from 'react-icons/fa';

import { Select as AllSelect } from '@agrodatabr/agrodataui';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Col, Form, Select, Spin, Tabs as AntTabs, Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import { Store } from 'antd/lib/form/interface';
import { TablePaginationConfig, TableProps } from 'antd/lib/table';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';

import PaginationData from '../../../@types/data/PaginationData';
import RegionData from '../../../@types/data/RegionData';
import { ResponseList } from '../../../@types/data/ResponseData';
import SubsidiaryData from '../../../@types/data/SubsidiaryData';
import DTOTeam from '../../../@types/dtos/credit-request/DTOTeam';
import DTOErrorReponse from '../../../@types/dtos/DTOErrorReponse';
import DTOOperator from '../../../@types/dtos/monitoring/SurveyRequest/DTOOperator';
import DTORole from '../../../@types/dtos/user/DTORole';
import {
  Breadcrumb,
  Button,
  Ellipsis,
  FormItem,
  IconWithTooltip,
  Input,
  ModalStatus,
  Password,
  Row,
  Tabs,
} from '../../../components';
// import { Select as AllSelect } from '../../../components/AllSelect';
import { TableData } from '../../../compositions';
import { Can } from '../../../hooks/ability';
import { useAuth } from '../../../hooks/auth';
import { useCache } from '../../../hooks/cache';
import { useUser } from '../../../hooks/fetch/useUsers';
import { usePage } from '../../../hooks/page';
import { usePasswordValidator } from '../../../hooks/usePasswordValidator';
import api from '../../../services/api/admin';
import monitoringApi from '../../../services/api/monitoring';
import UserData from '../../../types/User/UserData';
import { generatePassword } from '../../../utils/Password';
import { AuthTab } from './AuthTab';
import columns from './columns';
import { NotificationConfigTab } from './NotificationConfigTab';
import { useNotificationController } from './notificationController';
import {
  SContainer,
  SFilterContainer,
  SFormButtons,
  SFormContainer,
  SHeader,
  STitlePage,
  UploadImageContainer,
} from './styles';
import UserForm from './UserForm';

const { TabPane } = AntTabs;

const User: React.FC = () => {
  const { t, i18n } = useTranslation(['translation', 'admin']);
  const { updateUserData, roles: userRoles } = useAuth();
  const notificationTreeController = useNotificationController();

  const { getCreditOptions } = useCache();
  const { alertStatus } = usePage();
  const passwordValidator = usePasswordValidator();
  const { destroy: destroyUser } = useUser();

  const [data, setData] = useState([]);
  const [disabledButton, setDisabledButton] = useState(false);
  const [editing, setEditing] = useState(false);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [paginationConfig, setPaginationConfig] = useState<TablePaginationConfig>({
    current: 1,
    total: 1,
    pageSize: 20,
  });
  const [visibilityForm, setVisibilityForm] = useState(false);
  // const [TwoFAConfigured, setTwoFAConfigured] = useState(false);
  const [isExternal, setIsExternal] = useState(false);
  const [user, setUser] = useState<UserData>();

  const [roles, setRoles] = useState([]);
  const [teams, setTeams] = useState([]);
  const [regions, setRegions] = useState([]);
  const [subsidiaries, setSubsidiaries] = useState([]);
  const [operators, setOperators] = useState([]);
  const [disabledSubsidiaries, setDisabledSubsidiaries] = useState(true);
  const [loadingSubsidiaries, setLoadingSubsidiaries] = useState(false);

  const handleSuccess = (messageSuccess = '') =>
    ModalStatus({
      type: 'success',
      title: t('modal.success'),
      subTitle: messageSuccess,
    });

  const updateData = (row: Store) => {
    let dataUpdated: Array<Store> = data;
    if (data.length > 0) {
      const rowIndex = data.findIndex((item: Store) => row.id === item.id);
      // Se encontrar o item remove e adiciona ele atualizado na mesma posição
      if (rowIndex > -1) {
        dataUpdated.splice(rowIndex, 1, row);
      } else {
        if (dataUpdated.length === paginationConfig.pageSize) {
          // Se não encontrar, remove o último (por conta da paginação) e
          // adiciona o item na primeira posição
          dataUpdated.splice(-1, 1);
        }
        dataUpdated = [row, ...dataUpdated];
      }
    } else {
      dataUpdated.push(row);
    }

    setData(dataUpdated);
  };

  const loadOperators = async () => {
    await monitoringApi.operator
      .get()
      .then((response: any) => {
        setOperators(response.data.data.data);
      })
      .catch((err: DTOErrorReponse) => alertStatus(err, 'error'));
  };

  useEffect(() => {
    setRoles([...userRoles].map(r => ({ value: r.id, key: r.id, label: r.name })));
    setRegions(getCreditOptions('region'));
    loadOperators();

    setSubsidiaries(getCreditOptions('subsidiary'));
    setTeams(getCreditOptions('team'));
    // eslint-disable-next-line
  }, [getCreditOptions]);

  const loadTableData = useCallback(
    (values: Partial<UserForm & PaginationData<UserData>>) => {
      setLoading(true);
      const dataSend = {
        params: {
          ...values,
        },
      };

      api.users
        .get(dataSend)
        .then((response: ResponseList<UserData>) => {
          const result = response.data;
          setData(result.data);
          setPaginationConfig(paginationConfigState => ({
            ...paginationConfigState,
            current: result.current_page,
            total: result.total,
          }));
          setLoading(false);
        })
        .catch((err: DTOErrorReponse) => {
          alertStatus(err, 'error');
          setLoading(false);
        });
    },
    // eslint-disable-next-line
    [i18n.language],
  );

  const [loadingImage, setLoadingImage] = useState(false);
  const [avatarImageUrl, setAvatarImageUrl] = useState('');

  const handleDisplayForm = () => {
    setAvatarImageUrl('');
    setVisibilityForm(!visibilityForm);
    setEditing(false);
    setDisabledButton(false);
    setLoadingButton(false);
    form.resetFields();
  };

  const handleHideForm = () => {
    setVisibilityForm(false);
    setDisabledSubsidiaries(true);
  };

  const handleRegister = () => {
    setLoading(true);
    setLoadingButton(true);
    setDisabledButton(true);
    form
      .validateFields()
      .then(values => {
        api.users
          .store({
            ...values,
            photo: avatarImageUrl,
            language: i18n.language,
          })
          .then(response => {
            const { data: userData } = response;

            updateData(userData);
            handleSuccess(response.data.message);
            handleHideForm();
            setLoading(false);
            setLoadingButton(false);
            setDisabledButton(false);
            form.resetFields();
            updateUserData();
          })
          .catch((err: DTOErrorReponse) => {
            alertStatus(err, 'error');
            setLoading(false);
            setLoadingButton(false);
            setDisabledButton(false);
          });
      })
      .catch(() => {
        setLoading(false);
        setLoadingButton(false);
        setDisabledButton(false);
      });
  };

  const handleEdit = () => {
    setLoading(true);
    setLoadingButton(true);
    setDisabledButton(true);
    form
      .validateFields()
      .then(values => {
        api.users
          .update(values.id, { ...values, photo: avatarImageUrl, language: i18n.language })
          .then(response => {
            const { data: userData } = response;
            updateData(userData);
            handleSuccess(response.data.message);
            handleHideForm();
            form.resetFields();
            setLoading(false);
            setLoadingButton(false);
            setDisabledButton(false);

            updateUserData();
          })
          .catch((err: DTOErrorReponse) => {
            alertStatus(err, 'error');
            setLoading(false);
            setLoadingButton(false);
            setDisabledButton(false);
          });
      })
      .catch(() => {
        setLoading(false);
        setLoadingButton(false);
        setDisabledButton(false);
      });
  };

  // Table DATA Config: BEGIN
  function loadSubsidiaries(regionsValue: string[]) {
    setDisabledSubsidiaries(true);
    setLoadingSubsidiaries(true);
    const filteredSubsidiaries = getCreditOptions('subsidiary').filter(s => regionsValue.includes(s.parent_id));
    setSubsidiaries(filteredSubsidiaries);

    const subsidiariesForm = form.getFieldValue('subsidiaries');

    if (subsidiariesForm) {
      const valuesFiltered = filteredSubsidiaries.map(item => item.value);
      const filteredForm = valuesFiltered.filter(id => subsidiariesForm.includes(id));

      form.setFieldsValue({ subsidiaries: filteredForm });
    }

    if (filteredSubsidiaries.length) setDisabledSubsidiaries(false);
    setLoadingSubsidiaries(false);
  }

  const getActions = () => ({
    render: (active: number, record: UserData) => (
      <>
        <Can I="admin.user.update" a="">
          <IconWithTooltip
            action="edit"
            title={t('pages.admin.user.table.row.editIcon')}
            onClick={() => {
              const uniqueRegions = [...new Set(record.subsidiaries?.map((item: SubsidiaryData) => item.region_id))];
              loadSubsidiaries(uniqueRegions);

              form.resetFields();
              form.setFieldsValue({
                ...record,
                roles: record.roles?.map((role: DTORole) => role.id),
                regions: uniqueRegions,
                subsidiaries: record.subsidiaries?.map((subsidiary: SubsidiaryData) => subsidiary.id) ?? [],
                teams: record.teams?.map((team: DTOTeam) => team.id) ?? [],
                operators: record.operators?.map((operator: DTOOperator) => operator.id) ?? [],
              });

              // setTwoFAConfigured(record.two_factor_is_configured);
              setIsExternal(record.external_id !== undefined && record.external_id !== null);

              if (record.photo) {
                setAvatarImageUrl(record.photo);
              } else {
                setAvatarImageUrl('');
              }

              form.scrollToField('name', {
                scrollMode: 'always',
                block: 'start',
                behavior: actions =>
                  actions.forEach(({ el, top }) => {
                    el.scrollTop = top - 280;
                  }),
              });

              setUser(record);

              setVisibilityForm(true);
              setEditing(true);
              setLoading(false);
              setLoadingButton(false);
              setDisabledButton(false);
              setDisabledSubsidiaries(false);
            }}
          />
        </Can>
      </>
    ),
  });

  const [searchForm] = Form.useForm();

  const handleSwitchChange = async (record: any) => {
    const response = await destroyUser(record.id);

    if (response) handleTableChange({ current: 1 }, { active: [1] }, {}, null);
  };

  // Table DATA Config: BEGIN
  const tableConfig = {
    search: '',
    filtered: {},
    sorter: {},
    getActions,
    handleSwitchChange,
  };

  const [tableDataConfig, setTableDataConfig] = useState(tableConfig);

  const handleFastSearch = () => {
    const searchValue = searchForm.getFieldsValue();
    setTableDataConfig(tableDataConfigState => ({
      ...tableDataConfigState,
      search: searchValue.search,
    }));
    loadTableData({
      name: searchValue.search,
      email: searchValue.search,
    });
  };

  const tableDataClearAllFilters = () => {
    searchForm.resetFields();
    setTableDataConfig(tableDataConfigState => ({
      ...tableDataConfigState,
      filtered: {},
      search: '',
      sorter: {},
    }));
    loadTableData({ page: 1 });
  };

  const handleGeneratePassword = () => {
    const password = generatePassword();
    form.setFieldsValue({ confirm: password, password });
  };

  const handleTableChange: TableProps<any>['onChange'] = useCallback(
    (
      pagination: TablePaginationConfig,
      filters: Record<string, FilterValue | null>,
      sorter: SorterResult<any> | SorterResult<any>[],
    ) => {
      setLoading(true);
      setTableDataConfig(tableDataConfigState => ({
        ...tableDataConfigState,
        filtered: filters,
        sorter,
      }));

      let direction = '';
      const search: any = {};
      let sort = '';

      if (sorter) {
        const { field, order } = sorter as SorterResult<any>;

        if (order === 'ascend') direction = 'asc';
        if (order === 'descend') direction = 'desc';
        sort = field === 'posision_id' ? 'position.name' : field?.toString();
      }

      if (filters) {
        const dataFilter: any = filters;

        if (dataFilter) {
          Object.keys(dataFilter).forEach((key: string) => {
            if (dataFilter[key] !== null && dataFilter[key] !== undefined && dataFilter[key][0]) {
              search[key] = dataFilter[key][0].toString();
            } else if (dataFilter[key] !== null && dataFilter[key] !== undefined) {
              search[key] = dataFilter[key].toString();
            } else {
              search[key] = '';
            }
          });
        }
      }

      setPaginationConfig(paginationConfigState => ({
        ...paginationConfigState,
        pageSize: pagination.pageSize,
        current: pagination.current,
      }));

      const searchValue = searchForm.getFieldsValue();
      loadTableData({
        page: pagination.current,
        per_page: pagination.pageSize,
        sort,
        direction,
        name: searchValue.search,
        email: searchValue.search,
        ...search,
      });
    },
    [loadTableData],
  );
  // Table DATA Config: END

  useEffect(() => {
    handleTableChange({ current: 1, pageSize: paginationConfig.pageSize }, {}, {}, null);
    // eslint-disable-next-line
  }, [handleTableChange]);

  function getBase64(img: any, callback: any) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  function uploadImage(info: any) {
    setLoadingImage(true);
    getBase64(info.file, (imageUrl: any) => {
      setAvatarImageUrl(imageUrl);
      setLoadingImage(false);
    });
  }

  return (
    <>
      <Breadcrumb items={[{ title: t('breadcrumb.admin') }, { title: t('breadcrumb.users') }]} />

      <SContainer>
        <SHeader>
          <STitlePage>{t('pages.admin.user.title_page')}</STitlePage>

          <Can I="admin.user.store" a="">
            <Button status="primary" icon={visibilityForm ? <FaMinus /> : <FaPlus />} onClick={handleDisplayForm}>
              {t('pages.admin.user.buttonAdd')}
            </Button>
          </Can>
        </SHeader>
        <br />
        {visibilityForm && (
          <Tabs type="card">
            <TabPane tab={t('pages.admin.user.defaultTab')} key="1">
              <SFormContainer visible={visibilityForm}>
                <Spin spinning={loading}>
                  <h2>{t('pages.admin.user.registerTitle')}</h2>
                  <Form form={form} scrollToFirstError autoComplete="none">
                    <FormItem name="id" style={{ display: 'none' }}>
                      <Input />
                    </FormItem>

                    <UploadImageContainer>
                      <FormItem label={t('pages.admin.user.form.photo')}>
                        <ImgCrop rotate modalTitle={t('edit-image')}>
                          <Upload
                            name="avatar"
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            customRequest={uploadImage}
                          >
                            {avatarImageUrl && !loadingImage ? (
                              <img src={avatarImageUrl} alt="avatar" style={{ width: '100%' }} />
                            ) : (
                              <div>
                                {loadingImage ? <LoadingOutlined /> : <PlusOutlined />}
                                <div style={{ marginTop: 8 }}>Upload</div>
                              </div>
                            )}
                          </Upload>
                        </ImgCrop>
                      </FormItem>
                    </UploadImageContainer>

                    <Row gutter={[26, 26]}>
                      <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={8}>
                        <FormItem label={t('pages.admin.user.form.name')} name="name" rules={[{ required: true }]}>
                          <Input maxLength={255} />
                        </FormItem>
                      </Col>
                      <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={8}>
                        <FormItem label={t('pages.admin.user.form.email')} name="email" rules={[{ required: true }]}>
                          <Input type="email" maxLength={255} autoComplete="none" />
                        </FormItem>
                      </Col>
                    </Row>

                    {!editing && (
                      <Row gutter={[26, 26]}>
                        <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={8}>
                          <FormItem
                            label={t('pages.admin.user.form.new_password')}
                            name="password"
                            rules={[...passwordValidator]}
                          >
                            <Password disabled={isExternal} />
                          </FormItem>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={8}>
                          <FormItem
                            label={t('pages.admin.user.form.confirmpassword')}
                            dependencies={['password']}
                            name="confirm"
                            rules={[
                              ({ getFieldValue }) => ({
                                validator(rule, value) {
                                  if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                  }
                                  return Promise.reject(t('pages.admin.user.form.not_same'));
                                },
                              }),
                            ]}
                          >
                            <Password disabled={isExternal} />
                          </FormItem>
                        </Col>
                        <Col
                          xs={24}
                          sm={12}
                          md={12}
                          lg={8}
                          xl={8}
                          xxl={8}
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          <Button onClick={handleGeneratePassword} style={{ marginBottom: '10px' }}>
                            {t('pages.admin.user.form.suggest_password')}
                          </Button>
                        </Col>
                      </Row>
                    )}

                    <Row gutter={[26, 26]}>
                      <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={8}>
                        <FormItem label={t('pages.admin.user.form.position')} name="position_id">
                          <Select
                            options={getCreditOptions('position')}
                            showSearch
                            filterOption={(input, option) =>
                              option?.label.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                          />
                        </FormItem>
                      </Col>
                      <Col xs={24} sm={12} md={12} lg={16} xl={16} xxl={16}>
                        <FormItem label={t('pages.admin.user.form.roles')} name="roles" rules={[{ required: true }]}>
                          <Select
                            options={[...roles]}
                            showSearch
                            mode="multiple"
                            filterOption={(input, option) =>
                              option?.label.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                          />
                        </FormItem>
                      </Col>
                    </Row>

                    <Row gutter={[26, 26]}>
                      <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                        <FormItem label={t('pages.admin.user.form.teams')} name="teams">
                          <Select
                            options={[...teams]}
                            showSearch
                            mode="multiple"
                            filterOption={(input, option) =>
                              option?.label.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                          />
                        </FormItem>
                      </Col>
                    </Row>
                    <Row gutter={[26, 26]}>
                      <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                        <FormItem label={t('pages.admin.user.form.region')} name="regions">
                          <Select
                            options={regions}
                            showSearch
                            onChange={(values: any) => loadSubsidiaries(values)}
                            mode="multiple"
                            filterOption={(input, option) =>
                              option?.label.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                          />
                        </FormItem>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                        <FormItem
                          label={t('pages.admin.user.form.subsidiary')}
                          name="subsidiaries"
                          rules={[{ required: !disabledSubsidiaries }]}
                        >
                          <AllSelect
                            translation={{ all: 'Todos' }}
                            allOption
                            options={subsidiaries}
                            showSearch
                            mode="multiple"
                            loading={loadingSubsidiaries}
                            disabled={disabledSubsidiaries}
                          />
                        </FormItem>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                        <FormItem label={t('pages.admin.user.form.operator')} name="operators">
                          <AllSelect
                            translation={{ all: 'Todos' }}
                            options={operators?.map((item: DTOOperator) => {
                              return {
                                key: item.id,
                                label: item.name,
                                value: item.id,
                              };
                            })}
                            showSearch
                            mode="multiple"
                          />
                        </FormItem>
                      </Col>
                    </Row>
                    <SFormButtons>
                      <Button status="secondary" htmlType="reset" onClick={handleHideForm} disabled={disabledButton}>
                        {t('pages.admin.user.buttonCancel')}
                      </Button>
                      <>
                        {editing && (
                          <Button
                            status="primary"
                            htmlType="submit"
                            onClick={handleEdit}
                            loading={loadingButton}
                            disabled={disabledButton}
                          >
                            {t('pages.admin.user.buttonEdit')}
                          </Button>
                        )}
                        {!editing && (
                          <Button
                            status="primary"
                            htmlType="submit"
                            onClick={handleRegister}
                            loading={loadingButton}
                            disabled={disabledButton}
                          >
                            {t('pages.admin.user.buttonRegister')}
                          </Button>
                        )}
                      </>
                    </SFormButtons>
                  </Form>
                </Spin>
              </SFormContainer>
            </TabPane>
            {editing && (
              <>
                <TabPane tab={t('pages.admin.user.authTab')} key="2">
                  <AuthTab handleHideForm={handleHideForm} isExternal={isExternal} user={user} />
                </TabPane>
                <TabPane tab={t('pages.admin.user.notificationConfiguration')} key="3">
                  <NotificationConfigTab
                    handleHideForm={handleHideForm}
                    treeController={notificationTreeController}
                    userId={user.id}
                  />
                </TabPane>
              </>
            )}
          </Tabs>
        )}

        <SFilterContainer>
          <Form
            form={searchForm}
            name="filter-user"
            className="form-secondary form-filters grid-filters"
            onFinish={handleFastSearch}
          >
            <div className="filter-search">
              <FormItem name="search" label={t('pages.admin.user.search')}>
                <Input />
              </FormItem>
            </div>
            <div className="filter-button">
              <FormItem label="" className="form-item-without-label">
                <Button status="primary" text="uppercase" htmlType="submit">
                  <FaSearch /> {t('form.actions.search')}
                </Button>
              </FormItem>
            </div>
            <div className="filter-clear">
              <Button status="secondary" size="middle" onClick={tableDataClearAllFilters}>
                {t('pages.admin.user.buttonClearFilter')}
              </Button>
            </div>
          </Form>
        </SFilterContainer>

        <TableData
          rowKey="id"
          columns={columns.map(column => {
            let columnRender: any = {
              ...column,
            };
            if (column.key === 'position_id') {
              columnRender = {
                ...columnRender,
                render: (value: any, record: UserData) => {
                  return record && record.position ? record.position?.name : '';
                },
              };
            }

            if (column.key === 'regions') {
              columnRender = {
                ...columnRender,
                render: (value: Array<RegionData>) => {
                  const unique = [...new Set(value?.map((item: RegionData) => item.name))];
                  return <Ellipsis value={unique?.join(', ')} />;
                },
              };
            }

            if (column.key === 'subsidiaries') {
              columnRender = {
                ...columnRender,
                render: (value: Array<SubsidiaryData>) => (
                  <Ellipsis value={value?.map((item: SubsidiaryData) => item.name).join(', ')} />
                ),
              };
            }

            if (column.key === 'teams') {
              columnRender = {
                ...columnRender,
                render: (value: Array<DTOTeam>) => (
                  <Ellipsis value={value?.map((item: DTOTeam) => item.name).join(', ')} />
                ),
              };
            }

            if (column.key === 'roles') {
              columnRender = {
                ...columnRender,
                render: (value: Array<DTORole>) => (
                  <Ellipsis value={value?.map((item: DTORole) => item.name).join(', ')} />
                ),
              };
            }

            if (column.key === 'external_id') {
              columnRender = {
                ...columnRender,
                render: (value: any, record: UserData) => {
                  return record && record.external_id ? t('Yes') : t('No');
                },
              };
            }

            return columnRender;
          })}
          dataSource={data}
          loading={loading}
          onChange={handleTableChange}
          pagination={paginationConfig}
          tableConfig={tableDataConfig}
        />
      </SContainer>
    </>
  );
};

export default User;

import Dragger from 'antd/lib/upload/Dragger';
import styled from 'styled-components';

export const Container = styled.div``;

export const SDragger = styled(Dragger)`
  background: none !important;
  border-width: 2px !important;

  .ant-upload-drag-container {
    display: flex !important;
    flex-direction: column;
    align-items: center;
    padding: 0 20px;
  }
`;

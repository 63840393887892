import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ColumnDef } from '@tanstack/react-table';
import { IconWithTooltip } from 'components';
import { ShouldRender } from 'components/ShouldRender';
import { useAlertDialog } from 'components/ui/alert-dialog';
import { DataTable } from 'components/ui/data-table';
import { Can } from 'hooks/ability';
import { Formatter } from 'utils/Formatter';

import { useRegisteredCustomerStore } from '../stores/use-registered-customer-store';
import { CommercialReferenceFormData } from './commercial-reference.form';

export const CommercialReferenceTable = () => {
  const { t } = useTranslation();
  const form = useFormContext();
  const { alert } = useAlertDialog();

  const commercialReferences = form.watch('commercial_reference') as CommercialReferenceFormData[];

  const setCommercialReferenceToEdit = useRegisteredCustomerStore(state => state.setCommercialReferenceToEdit);
  const setIsCommercialReferenceModalOpen = useRegisteredCustomerStore(
    state => state.setIsCommercialReferenceModalOpen,
  );
  const isViewing = useRegisteredCustomerStore(state => state.isViewing);

  const onDelete = (id: string) => {
    const commercialReferences = form.getValues('commercial_reference') as CommercialReferenceFormData[];
    form.setValue(
      'commercial_reference',
      commercialReferences.filter(commercialReference => commercialReference.id !== id),
    );
  };

  const handleDelete = (commercialReference: CommercialReferenceFormData) => {
    alert({
      type: 'warning',
      title: t('delete-commercial-reference'),
      description: t('delete-commercial-reference-confirm', {
        commercialReference: commercialReference.name,
      }),
      onOk: async () => {
        if (commercialReference.id) await onDelete(commercialReference.id.toString());
        alert({
          type: 'success',
          title: t('commercial-reference-deleted'),
          description: '',
        });
      },
    });
  };

  const columns: ColumnDef<CommercialReferenceFormData>[] = [
    {
      accessorKey: 'name',
      header: t('name'),
    },
    {
      accessorKey: 'credit_limit',
      header: t('credit_limit'),
      cell: ({ row }) => {
        const commercialReference = row.original as CommercialReferenceFormData;
        return Formatter.money(commercialReference.credit_limit, 2, 'R$', true);
      },
    },
    {
      accessorKey: 'warranty',
      header: t('warranties'),
    },
    {
      accessorKey: 'phone_number',
      header: t('phone_number'),
      cell: ({ row }) => {
        const commercialReference = row.original as CommercialReferenceFormData;
        return commercialReference.phone_number.replaceAll(/\D/g, '')?.length === 11
          ? Formatter.cellphoneNumber(commercialReference.phone_number)
          : Formatter.phoneNumber(commercialReference.phone_number);
      },
    },
    {
      accessorKey: 'contact_name',
      header: t('contact_name'),
    },
    {
      id: 'actions',
      enablePinning: true,
      accessorKey: 'action',
      size: 100,
      header: t('actions'),
      cell: ({ row }) => {
        const partner = row.original as CommercialReferenceFormData;

        return (
          <ShouldRender condition={!isViewing}>
            <IconWithTooltip
              permission="credit.request.package.destroy"
              action="destroy"
              title={t('pages.registered-customers.table.icons.remove')}
              // loading={partner.id && partnerToDelete === partner.id}
              onClick={() => handleDelete(partner)}
            />
            <Can I="credit.request.package.update">
              <IconWithTooltip
                action="edit"
                onClick={() => {
                  setCommercialReferenceToEdit(partner);
                  setIsCommercialReferenceModalOpen(true);
                }}
                title={t('pages.registered-customers.table.icons.edit')}
              />
            </Can>
          </ShouldRender>
        );
      },
    },
  ];

  return <DataTable columns={columns} data={commercialReferences ?? []} />;
};

import { create, StateCreator } from 'zustand';

export type Subform = 'checklist_document_reference';

export type DraftFormStateSlice = {
  subscriberBeingRemovedId: string | null;
  setSubscriberBeingRemovedId: (subscriberBeingRemovedId: string | null) => void;
  commentBeingRemovedId: string | null;
  setCommentBeingRemovedId: (commentBeingRemovedId: string | null) => void;
};

export const packgeFormStateSliceInitialState = {
  subscriberBeingRemovedId: null,
  commentBeingRemovedId: null,
} as DraftFormStateSlice;

export const createDraftFormStateSlice: StateCreator<DraftFormStateSlice, [], [], DraftFormStateSlice> = set => ({
  ...packgeFormStateSliceInitialState,
  resetFormState: () => set(packgeFormStateSliceInitialState),
  setSubscriberBeingRemovedId: isRemovingComment => set({ subscriberBeingRemovedId: isRemovingComment }),
  setCommentBeingRemovedId: isRemovingComment => set({ commentBeingRemovedId: isRemovingComment }),
});

export const useDraftFormStateStore = create<DraftFormStateSlice>()((...a) => ({
  ...createDraftFormStateSlice(...a),
}));

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Tabs as ATabs } from 'antd';

import { Tabs } from '../../../components';
import { PageParams } from '../types';
import { RegisteredCustomerForm } from './registered-customer.form';
import { Container } from './styles';
import { DetailViewProps } from './types';

const DetailView: React.FC<DetailViewProps> = ({
  personTypeExternal,
  personId,
  isNotModulePerson = false,
  isLoading = false,
}) => {
  const { personType, permission } = useParams<PageParams>();

  const { TabPane } = ATabs;
  const { t } = useTranslation();

  function renderPersonalData() {
    if (!personType && !personTypeExternal) {
      return <div />;
    }

    return (
      <>
        <RegisteredCustomerForm
          type={
            personType === 'physical' || personTypeExternal === 'physical'
              ? 'App\\Model\\PhysicalPerson'
              : 'App\\Model\\LegalPerson'
          }
          customerId={personId}
          isModulePerson
          isLoading={isLoading}
        />
      </>
    );
  }

  return (
    <>
      {!isNotModulePerson && (
        <Container>
          <Tabs>
            <TabPane tab={t('pages.registered-customers.tabs.general-data')} key="tab-general-data">
              <div
                className={`content-pannel ${
                  personTypeExternal || (!personTypeExternal && permission === 'view') ? 'content-pannel-full' : ''
                }`}
              >
                {renderPersonalData()}
              </div>
            </TabPane>
          </Tabs>
        </Container>
      )}
      {isNotModulePerson && (
        <Container>
          <div className="content-pannel content-pannel-full">{renderPersonalData()}</div>
        </Container>
      )}
    </>
  );
};

export default DetailView;

import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ColumnDef } from '@tanstack/react-table';
import { IconWithTooltip } from 'components';
import { ShouldRender } from 'components/ShouldRender';
import { useAlertDialog } from 'components/ui/alert-dialog';
import { DataTable } from 'components/ui/data-table';
import { Can } from 'hooks/ability';
import { useCache } from 'hooks/cache';
import { Formatter } from 'utils/Formatter';

import { useRegisteredCustomerStore } from '../stores/use-registered-customer-store';
import { BankReferenceFormData } from './bank-reference.form';

export const BankReferenceTable = () => {
  const { t } = useTranslation();
  const form = useFormContext();
  const { alert } = useAlertDialog();
  const cache = useCache();

  const bankReferences = form.watch('bank_reference') as BankReferenceFormData[];

  const setBankReferenceToEdit = useRegisteredCustomerStore(state => state.setBankReferenceToEdit);
  const setIsBankReferenceModalOpen = useRegisteredCustomerStore(state => state.setIsBankReferenceModalOpen);
  const isViewing = useRegisteredCustomerStore(state => state.isViewing);

  const onDelete = (id: string) => {
    const bankReferences = form.getValues('bank_reference') as BankReferenceFormData[];
    form.setValue(
      'bank_reference',
      bankReferences.filter(bankReference => bankReference.id !== id),
    );
  };

  const handleDelete = (bankReference: BankReferenceFormData) => {
    alert({
      type: 'warning',
      title: t('delete-bank-reference'),
      description: t('delete-bank-reference-confirm', {
        bankReferenceName: cache.getPersonOptions('bank').find(bank => bankReference.bank_id === bank.value)?.label,
      }),
      onOk: async () => {
        if (bankReference.id) await onDelete(bankReference.id.toString());
        alert({
          type: 'success',
          title: t('bank-reference-deleted'),
          description: '',
        });
      },
    });
  };

  const columns: ColumnDef<BankReferenceFormData>[] = [
    {
      accessorKey: 'bank_id',
      header: t('pages.registered-customers.form.pj.bank_reference.name'),
      cell: ({ row }) => {
        const bankReference = row.original as BankReferenceFormData;
        return cache.getPersonOptions('bank').find(bank => bank.value === bankReference.bank_id)?.label;
      },
    },
    {
      accessorKey: 'agency',
      header: t('pages.registered-customers.form.pj.bank_reference.agency'),
    },
    {
      accessorKey: 'state_id',
      header: t('pages.registered-customers.form.pj.bank_reference.state_id'),
      cell: ({ row }) => {
        const bankReference = row.original as BankReferenceFormData;
        return cache.getCreditOptions('state').find(state => state.value === bankReference.state_id)?.label;
      },
    },
    {
      accessorKey: 'city_id',
      header: t('pages.registered-customers.form.pj.bank_reference.city_id'),
      cell: ({ row }) => {
        const bankReference = row.original as BankReferenceFormData;
        return cache.getCityByStateOptions(bankReference.state_id).find(city => city.value === bankReference.city_id)
          ?.label;
      },
    },
    {
      accessorKey: 'phone_number',
      header: t('pages.registered-customers.form.pj.bank_reference.phone_number'),
      cell: ({ row }) => {
        const bankReference = row.original as BankReferenceFormData;
        return bankReference.phone_number?.replaceAll(/\D/g, '')?.length === 11
          ? Formatter.cellphoneNumber(bankReference.phone_number)
          : Formatter.phoneNumber(bankReference.phone_number);
      },
    },
    {
      accessorKey: 'contact_name',
      header: t('pages.registered-customers.form.pj.bank_reference.contact_name'),
    },
    {
      id: 'actions',
      enablePinning: true,
      accessorKey: 'action',
      size: 100,
      header: t('actions'),
      cell: ({ row }) => {
        const partner = row.original as BankReferenceFormData;

        return (
          <ShouldRender condition={!isViewing}>
            <IconWithTooltip
              permission="credit.request.package.destroy"
              action="destroy"
              title={t('pages.registered-customers.table.icons.remove')}
              // loading={partner.id && partnerToDelete === partner.id}
              onClick={() => handleDelete(partner)}
            />
            <Can I="credit.request.package.update">
              <IconWithTooltip
                action="edit"
                onClick={() => {
                  setBankReferenceToEdit(partner);
                  setIsBankReferenceModalOpen(true);
                }}
                title={t('pages.registered-customers.table.icons.edit')}
              />
            </Can>
          </ShouldRender>
        );
      },
    },
  ];

  return <DataTable columns={columns} data={bankReferences ?? []} />;
};

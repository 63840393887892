import { FixedType } from 'rc-table/lib/interface';

export const columns = [
  {
    title: 'pages.serasa.integrations.table.description',
    dataIndex: 'description',
    key: 'description',
    sorter: false,
    filtered: false,
  },
  {
    title: 'pages.serasa.integrations.table.created_at',
    dataIndex: 'created_at',
    key: 'created_at',
    sorter: false,
    filtered: false,
  },
  {
    title: 'pages.serasa.integrations.table.actions',
    dataIndex: 'action',
    key: 'action',
    width: '100px',
    fixed: 'center' as FixedType,
  },
];

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaMinus, FaPlus } from 'react-icons/fa';

import { useTable } from '@agrodatabr/agrodataui';
import { Form as AntForm } from 'antd';
import { Breadcrumb, Button } from 'components';
import { Can } from 'hooks/ability';

import { IntegrationRuleData } from '../../../@types/data/IntegrationRuleData';
import { Form } from './Form';
import { List } from './List';
import { SContainer, SHeader, STitlePage } from 'styles';

const { useForm } = AntForm;

export const IntegrationRules = () => {
  const { t } = useTranslation();
  const [form] = useForm();
  const [visibilityForm, setVisibilityForm] = useState(false);

  const controller = useTable();

  const [ruleToEdit, setRuleToEdit] = useState<IntegrationRuleData | null>(null);

  const handleDisplayForm = () => {
    setVisibilityForm(!visibilityForm);
    form.resetFields();
  };

  const handleHideForm = () => {
    setVisibilityForm(false);
    setRuleToEdit(undefined);
  };

  const handleSetRuleToEdit = (group: IntegrationRuleData) => {
    setVisibilityForm(true);
    setRuleToEdit(group);
  };

  return (
    <>
      <Breadcrumb
        items={[{ title: t('breadcrumb.integration') }, { title: t('breadcrumb.rules'), to: '/integrations/rules' }]}
      />
      <SContainer>
        <SHeader>
          <STitlePage>{t('pages.integration.rules.title_page')}</STitlePage>

          <Can I="admin.integration.rule.store" a="">
            <Button status="primary" icon={visibilityForm ? <FaMinus /> : <FaPlus />} onClick={handleDisplayForm}>
              {t('pages.integrations.rules.buttonAdd')}
            </Button>
          </Can>
        </SHeader>

        <Form
          isVisible={visibilityForm}
          handleHideForm={handleHideForm}
          form={form}
          refetchTable={controller.refresh}
          ruleToEdit={ruleToEdit}
        />

        <List setRuleToEdit={handleSetRuleToEdit} controller={controller} />
      </SContainer>
    </>
  );
};

export default IntegrationRules;

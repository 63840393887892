import { PHYSICAL_PERSON_TYPE } from 'configs/constants';
import { useCreditRequestContext } from 'context/CreditRequestContext';
import moment from 'moment';
import { Formatter } from 'utils/Formatter';

import creditFormData from '../components/templates/DetailView/formData';
import { useCreditRequestStore } from '../store/useCreditRequestStore';
import { useLoadSubsidiaries } from './useLoadSubsidiaries';

export const useLoadCreditRequestForm = () => {
  const { loadSubsidiaries } = useLoadSubsidiaries();
  const { creditForm, amortizationForm } = useCreditRequestContext();

  const setFormIsBlocked = useCreditRequestStore(state => state.setFormIsBlocked);
  const formIsBlocked = useCreditRequestStore(state => state.formIsBlocked);
  const isPhysicalPerson = useCreditRequestStore(state => state.isPhysicalPerson);
  const documentList = useCreditRequestStore(state => state.documentList);
  const setJustificationVisible = useCreditRequestStore(state => state.setJustificationVisible);

  const loadForm = async (dataCredit: any) => {
    setFormIsBlocked(!formIsBlocked);

    await loadSubsidiaries(dataCredit.region_id);

    const formDataCR: any = creditForm.getFieldsValue();

    Object.keys(formDataCR).forEach((field: string) => {
      const keys = field.split('.');
      if (keys.length === 1) {
        formDataCR[field] = dataCredit[field] ?? undefined;
      }
      if (keys.length === 2) {
        formDataCR[field] = dataCredit?.[keys[0]]?.[keys[1]] ?? undefined;
      }
      if (keys.length === 3) {
        formDataCR[field] = dataCredit?.[keys[0]]?.[keys[1]]?.[keys[2]] ?? undefined;
      }

      switch (field) {
        case 'date_of_establishment':
        case 'json_data.withdrawal_period':
        case 'json_data.expected_payment_at':
        case 'negotiated_fixation_date':
        case 'credit_due_date':
        case 'limit_expiration_date':
          formDataCR[field] = formDataCR[field] ? Formatter.date(formDataCR[field], true) : '';
          break;
        case 'start_withdrawal_deadline':
          formDataCR[field] = formDataCR[field] ? Formatter.date(formDataCR[field], true) : '';
          break;
        case 'json_data.price_value':
          formDataCR[field] = dataCredit.contract_fixation?.price
            ? Number(dataCredit.contract_fixation.price)
            : undefined;
          break;
        case 'last_import_sap.description':
          formDataCR[field] =
            formDataCR[field] && dataCredit?.last_import_sap?.created_at
              ? `${formDataCR[field]} - ${Formatter.datetime(dataCredit.last_import_sap.created_at)}`
              : formDataCR[field];
          break;
        case 'last_import_prepay_advance.description':
          formDataCR[field] =
            formDataCR[field] && dataCredit?.last_import_prepay_advance?.created_at
              ? `${formDataCR[field]} - ${Formatter.datetime(dataCredit.last_import_prepay_advance.created_at)}`
              : formDataCR[field];
          break;
        case 'json_data.is_exportation':
        case 'is_fundeinfra':
          formDataCR[field] = formDataCR[field] ? 1 : 0;
          break;
        case 'documents':
          formDataCR[field] = dataCredit.documents;
          break;
        case 'json_data.contract.requester_person_document':
          formDataCR[field] =
            dataCredit.json_data?.contract?.requester_person_cnpj ||
            dataCredit.json_data?.contract?.requester_person_cpf;
          break;
        case 'has_assignees':
          formDataCR[field] = dataCredit.has_assignees ? 1 : 0;
          break;
        case 'requester_person.name':
          formDataCR[field] = dataCredit.requester_person?.name || dataCredit.requester_person?.fantasy_name;
          break;
        default:
          break;
      }
    });

    formDataCR.user_name = dataCredit.user ? dataCredit.user.name : undefined;

    if (dataCredit.requester_person) {
      if (isPhysicalPerson === true) {
        formDataCR['requester_person.cpf'] = dataCredit.requester_person.cpf || undefined;
      } else {
        formDataCR['requester_person.cnpj'] = dataCredit.requester_person.cnpj?.padStart(14, '0') || undefined;
      }
      formDataCR['requester_person.document'] = dataCredit.requester_person.cpf ?? dataCredit.requester_person.cnpj;

      if (dataCredit.requester_person_type === PHYSICAL_PERSON_TYPE) {
        formDataCR.requester_person_name = dataCredit.requester_person.name || undefined;
      } else {
        formDataCR.requester_person_name = dataCredit.requester_person.company_name || undefined;
      }

      if (dataCredit.requester_person.json_data) {
        formDataCR['requester_person.json_data.code_erp'] =
          dataCredit.requester_person.json_data?.code_erp || undefined;
      }

      if (dataCredit.json_data?.code_erp) {
        formDataCR['requester_person.json_data.code_erp'] = dataCredit.json_data?.code_erp || undefined;
      }

      if (dataCredit.requester_person?.cod_datasul) {
        formDataCR['requester_person.json_data.code_erp'] = dataCredit.requester_person?.cod_datasul || undefined;
      }
    }

    amortizationForm?.setFieldsValue({
      liquidated_advance: dataCredit.liquidated_advance ?? '',
      liquidation_date: dataCredit.liquidation_date ? moment(dataCredit.liquidation_date) : '',
      delivered_volume: dataCredit.delivered_volume ?? '',
    });

    if (dataCredit.custodia_storage_unit) {
      formDataCR.custodia_approval_status =
        dataCredit.custodia_storage_unit.commodities?.[0]?.pivot?.approval_status ?? '';
      formDataCR.custodia_storage_category = dataCredit.custodia_storage_unit?.storage?.category?.name ?? '';
    }

    if (!formDataCR) {
      // Preenche com as informações iniciais padrão
      creditForm.setFieldsValue(creditFormData.credit.form);
    } else {
      // Resolve o problema de campos vazios validados com sucesso no carregamento inicial
      Object.keys(formDataCR).forEach((field: string) => {
        if (formDataCR[field] === null || formDataCR[field] === '') {
          formDataCR[field] = undefined;
        }
      });

      if (documentList.length) formDataCR.documents = documentList;
      creditForm.setFieldsValue({
        ...formDataCR,
        documents: formDataCR.documents
          ? formDataCR.documents.map((field: any) => ({
              ...field,
            }))
          : [],
      });
      const expectedPayment = formDataCR['json_data.withdrawal_period'];
      setJustificationVisible(moment(expectedPayment).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD'));
    }

    setFormIsBlocked(false);
  };

  return {
    loadForm,
  };
};

import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  .ant-table-tbody > tr.row-item.row-dragging {
    display: table;
  }

  .ant-table-tbody > tr.row-item td:last-of-type {
    width: 100px;
  }

  .ant-table-placeholder td {
    width: 100%;
    column-span: all;
  }
`;

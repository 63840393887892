import styled from 'styled-components';

export const SContainer = styled.div`
  padding: 8px 8px 24px;
`;

export const SHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const STitlePage = styled.h2`
  color: ${({ theme: { colors } }) => colors.primary};
  font-family: ${({ theme: { fonts } }) => fonts.primary};
  font-size: 18px;
  font-weight: 600;
`;

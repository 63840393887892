const columns: any = [
  {
    title: 'pages.dashboard.expoprepay.table.header.provider',
    dataIndex: 'provider_name',
    key: 'requester_person',
    sorter: true,
  },
  {
    title: 'pages.dashboard.expoprepay.table.header.contract',
    dataIndex: 'contract_number',
    key: 'contract',
    sorter: true,
  },
  {
    title: 'pages.dashboard.expoprepay.table.header.number',
    dataIndex: 'number',
    key: 'request',
    sorter: true,
  },
  {
    title: 'pages.dashboard.expoprepay.table.header.request_date',
    dataIndex: 'credit_request_created_at_timestamp',
    key: 'credit_request_created_at_timestamp',
    sorter: true,
  },
  {
    title: 'pages.dashboard.expoprepay.table.header.planned_value',
    dataIndex: 'advance_dol',
    key: 'advance',
    // sorter: true,
    align: 'right',
  },
  {
    title: 'pages.dashboard.expoprepay.table.header.delivered_value',
    dataIndex: 'liquidated_dol',
    key: 'liquidated',
    // sorter: true,
    align: 'right',
  },

  {
    title: 'pages.dashboard.expoprepay.table.header.currency',
    dataIndex: 'currency',
    key: 'currency',
    // sorter: true,
    align: 'right',
  },
  {
    title: 'PTAX',
    dataIndex: 'ptax',
    key: 'ptax',
    // sorter: true,
    align: 'right',
  },
  {
    title: 'pages.dashboard.expoprepay.table.header.credit_request_expected_payment_at_timestamp',
    dataIndex: 'credit_request_expected_payment_at_timestamp',
    key: 'credit_request_expected_payment_at_timestamp',
    // sorter: true,
  },
  {
    title: 'pages.dashboard.expoprepay.table.header.planned_volume',
    dataIndex: 'quantity',
    key: 'planned_volume',
    // sorter: true,
    align: 'right',
  },
  {
    title: 'pages.dashboard.expoprepay.table.header.delivered_volume',
    dataIndex: 'delivered',
    key: 'delivered_volume',
    // sorter: true,
    align: 'right',
  },
  {
    title: 'pages.dashboard.expoprepay.table.header.to_receive',
    dataIndex: 'to_receive',
    key: 'to_receive',
    // sorter: true
  },
  {
    title: 'pages.dashboard.expoprepay.table.header.start_withdrawal_deadline',
    dataIndex: 'start_withdrawal_deadline',
    key: 'start_withdrawal_deadline',
    // sorter: true,
  },
  {
    title: 'pages.dashboard.expoprepay.table.header.withdraw_period',
    dataIndex: 'withdraw_period',
    key: 'withdraw_period',
    // sorter: true,
  },
  {
    title: 'pages.dashboard.expoprepay.table.header.expo_date',
    dataIndex: 'exposure_timeline_days',
    key: 'exposure',
    // sorter: true,
    align: 'right',
  },
  {
    title: 'pages.dashboard.expoprepay.table.header.warehouse_shipping_capacity',
    dataIndex: 'warehouse_shipping_capacity',
    key: 'warehouse_shipping_capacity',
    // sorter: true,
  },
  {
    title: 'pages.dashboard.expoprepay.table.header.dollar_balance',
    dataIndex: 'dollar_balance',
    key: 'dollar_balance',
    // sorter: true,
  },
  {
    title: 'pages.dashboard.expoprepay.table.header.actions',
    key: 'action',
    dataIndex: 'action',
    align: 'center',
    width: '80px',
    fixed: 'right',
  },
];

export default columns;

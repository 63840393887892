import { PaginatedQueryParams, usePaginatedQuery } from 'hooks/usePaginatedQuery';
import { SelectInfiniteQueryParams, useSelectInfiniteQuery } from 'hooks/useSelectInfiniteQuery';
import PaginationData from 'types/Api/PaginationData';
import { EnvelopeStatusData } from 'types/CreditRequest/EnvelopeStatusData';

export const useGetEnvelopeStatusQuery = (params?: PaginatedQueryParams & any) => {
  return usePaginatedQuery<EnvelopeStatusData>({
    queryKey: ['envelope-status'],
    staleTime: 1000 * 60 * 5,
    url: `envelope-status`,
    params: {
      ...params,
    },
  });
};

export const useGetEnvelopeStatusOptionsQuery = (params: SelectInfiniteQueryParams) => {
  const query = useSelectInfiniteQuery<PaginationData<EnvelopeStatusData>>(
    'envelope-status',
    {
      queryKey: ['envelope-status'],
      staleTime: 1000 * 60 * 5,
      permissions: ['credit.request.envelope.index'],
    },
    params,
  );

  return query;
};

import { Divider } from 'antd';
import styled, { keyframes } from 'styled-components';

const fadeInFwd = keyframes`
 0% {
   -webkit-transform: translateZ(-80px);
           transform: translateZ(-80px);
   opacity: 0;
 }
 100% {
   -webkit-transform: translateZ(0);
           transform: translateZ(0);
   opacity: 1;
 }
`;

interface FormContainer {
  visible: boolean;
}

export const StatusLink = styled.a``;

export const SFormContainer = styled.div<FormContainer>`
  display: ${({ visible }) => (visible ? 'block' : 'none')};
  border: solid 1px ${({ theme: { colors } }) => colors.greys.grey03};
  border-radius: 4px;
  background: #ffffff;
  padding: 8px;
  margin-top: 8px;
  margin-bottom: 8px;
  animation: ${fadeInFwd} 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;

  h2 {
    color: ${({ theme: { colors } }) => colors.greys.grey08};
    font-family: ${({ theme }) => theme.fonts.primary};
    font-size: 16px;
    font-weight: 600;
    display: block;
    margin-bottom: 16px;
  }

  .divergent_nf {
    background-color: #f7e2c9 !important;
    padding: 8px;
    margin-bottom: 13px;
  }
`;

export const SContainer = styled.div`
  padding: 8px 8px 24px;
`;

export const SHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const STitlePage = styled.h2`
  color: ${({ theme: { colors } }) => colors.primary};
  font-family: ${({ theme: { fonts } }) => fonts.primary};
  font-size: 18px;
  font-weight: 600;
`;

export const SFilterBoxContainer = styled.div``;

export const SFilterContainer = styled.div`
  display: flex;
  align-items: flex-end;

  .grid-filters {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    grid-template-areas: 'filter-search filter-button filter-clear';
    padding: 8px 0;
    width: 100%;

    .filter-search {
      grid-area: filter-search;
    }

    .filter-button {
      grid-area: filter-button;
      align-items: flex-end;
      display: flex;
    }

    .filter-clear {
      grid-area: filter-clear;
      align-items: flex-end;
      display: flex;
      justify-content: flex-end;
      padding-bottom: 7px;
    }
  }
`;

export const SFormButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  button + button {
    margin-left: 8px;
  }
`;

export const SectionTitle = styled.div`
  background: ${({ theme: { colors } }) => colors.greys.grey01};
  color: ${({ theme: { colors } }) => colors.secondary};
  font-family: ${({ theme: { fonts } }) => fonts.primary};
  font-weight: 700;
  font-size: 16px;
  padding: 8px;
  display: block;
  text-align: center;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 16px;
`;

export const Container = styled.div`
  padding: 8px 8px 90px;

  .divergent_nf {
    background-color: #f5f5f5 !important;
    padding: 8px;
    margin-bottom: 13px;
  }

  .ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked {
    color: rgba(0, 0, 0, 0.25) !important;
    background-color: #ffae545f !important;
    border-color: #d9d9d9 !important;
    box-shadow: none;
  }

  .view {
    margin-top: 25px;
  }

  .h2-sustainability {
    min-height: 65px !important;
    text-align: center !important;
    display: grid !important;
    align-items: center !important;
  }

  .col_sust {
    padding-right: 30px;
  }

  .col_sust_area {
    padding-right: 30px;
    padding-left: 30px;
  }

  .col_sust_status {
    padding-left: 30px;
  }

  .form-footer-actions {
    display: flex !important;
    justify-content: flex-end !important;
    align-items: center !important;

    > button {
      margin-left: 8px !important;
      margin-right: 8px !important;
    }
  }

  h2 {
    background: ${({ theme: { colors } }) => colors.greys.grey01};
    color: ${({ theme: { colors } }) => colors.secondary};
    font-family: ${({ theme: { fonts } }) => fonts.primary};
    font-weight: 700;
    font-size: 16px;
    padding: 8px;
    display: block;
    text-align: center;
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 16px;
  }

  h1 {
    color: ${({ theme: { colors } }) => colors.danger};
    font-family: ${({ theme: { fonts } }) => fonts.primary};
    font-weight: 400;
    font-size: 16px;
    display: block;
    text-align: center;
    margin-top: 0;
    margin-bottom: 16px;
  }

  h3 {
    color: ${({ theme: { colors } }) => colors.danger};
    font-family: ${({ theme: { fonts } }) => fonts.primary};
    font-weight: 400;
    font-size: 16px;
    display: block;
    text-align: left;
    margin-top: 0;
    margin-bottom: 16px;
  }

  .action-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .button-left {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: flex-end;
  }
`;

export const SDivider = styled(Divider)`
  &.ant-divider-with-text {
    margin: 0;
    color: ${({ theme: { colors } }) => colors.secondary};
  }
`;

export const FileContainer = styled.div`
  padding: 16px 8px;
`;

export const RegistrationHeader = styled.span`
  display: grid;
  grid-template-columns: 1fr 5fr 1fr;
  background: ${({ theme }) => theme.colors.greys.grey01};
  padding: 8px;
  justify-content: end;

  button {
    border-radius: 50px;
    width: min-content;
    padding: 6px;
    background: ${({ theme: { colors } }) => colors.primary};
    color: white;
    border: none;
    cursor: pointer;
    justify-self: end;

    &:hover {
      color: ${({ theme: { colors } }) => colors.primary};
      background: white;
    }

    span {
      display: flex;
      align-items: center;
    }
  }
`;

export const RegistrationHeaderTitle = styled.span`
  grid-column: 2;
  color: ${({ theme: { colors } }) => colors.secondary};
  font-size: 16px;
  text-align: center;
  width: 100%;
  font-weight: bold;
  text-transform: uppercase;
`;

import React from 'react';
import { useTranslation } from 'react-i18next';

import { Breadcrumb } from '../../../components';
import { ContractListFilterProvider } from '../context/ContractListFilterContext';
import { useContractListStore } from '../store/useContractListStore';
import { Table } from './Table';

const withContractListFilterProvider = (Component: React.FC) => (props: any) =>
  (
    <ContractListFilterProvider>
      <Component {...props} />
    </ContractListFilterProvider>
  );

export const ContractList = withContractListFilterProvider(() => {
  const { t } = useTranslation();
  const total = useContractListStore(state => state.total);

  return (
    <div className="h-full w-full overflow-hidden flex flex-col">
      <Breadcrumb items={[{ title: t('breadcrumb.edocuments') }, { title: t('breadcrumb.contractlist') }]} />
      <div className="p-[8px_8px_24px] h-full overflow-hidden">
        <h2 className="text-primary text-[16px] font-bold block mb-[16px]">
          {t('pages.edocuments.contractlist.title')} ({total})
        </h2>

        <Table />
      </div>
    </div>
  );
});

import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FaPlus, FaMinus, FaSearch, FaUserPlus } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import { Col, Form, Spin, DatePicker, InputNumber, Space, Tooltip, Badge } from 'antd';
import ptBR from 'antd/es/date-picker/locale/pt_BR';
import { Store } from 'antd/lib/form/interface';
import TextArea from 'antd/lib/input/TextArea';
import Modal from 'antd/lib/modal/Modal';
import { TablePaginationConfig, TableProps } from 'antd/lib/table';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import Dragger from 'antd/lib/upload/Dragger';
import PermissionedComponent from 'components/PermissionedComponent';
import { useDownload } from 'hooks/helpers/useDownload';
import moment from 'moment';
import { v4 as uuid } from 'uuid';

import CprData from '../../../@types/data/CprData';
import FarmData from '../../../@types/data/FarmData';
import HarvestData from '../../../@types/data/HarvestData';
import OwnerData from '../../../@types/data/OwnerData';
import PaginationData from '../../../@types/data/PaginationData';
import { ResponseListData } from '../../../@types/data/ResponseData';
import SubsidiaryData from '../../../@types/data/SubsidiaryData';
import DTOErrorReponse from '../../../@types/dtos/DTOErrorReponse';
import DTOCprModel from '../../../@types/dtos/monitoring/DTOCprModel';
import DTOCulture from '../../../@types/dtos/monitoring/DTOCulture';
import DTOSurvey from '../../../@types/dtos/monitoring/DTOSurvey';
import { Breadcrumb, Button, Input, ModalStatus, FormItem, Row, Select, IconWithTooltip } from '../../../components';
import { TableData } from '../../../compositions';
import appConfigs from '../../../configs/app';
import { Can, useAbility } from '../../../hooks/ability';
import { useAuth } from '../../../hooks/auth';
import { useCache } from '../../../hooks/cache';
import { ItemsType, usePage } from '../../../hooks/page';
import documentApi from '../../../services/api/document';
import monitoringApi from '../../../services/api/monitoring';
import registerApi from '../../../services/api/register';
import { downloadHandler } from '../../../services/download';
import exceptionHandler from '../../../services/exceptions';
import { downloadDocument, getBase64, getOriginalName } from '../../../services/files';
import theme from '../../../styles/theme';
import { DocumentModelData } from '../../../types/Document/DocumentModelData';
import { Formatter } from '../../../utils/Formatter';
import OwnerForm from '../Owner/OwnerForm';
import columns from './columns';
import FarmModal from './FarmModal';
import { FieldNotNecessaryTooltip } from './FieldNotNecessaryTooltip';
import OwnerModal from './OwnerModal';
import {
  SContainer,
  SHeader,
  STitlePage,
  SFilterContainer,
  SFormContainer,
  SFormButtons,
  SIconContainer,
  SectionTitle,
  DeadlineTooltipWrapper,
} from './styles';
import { NodeParam } from './types';

const Cpr: React.FC = () => {
  const { t, i18n } = useTranslation();
  const [form] = Form.useForm();

  const { updateUserData } = useAuth();
  const { getPersonOptions } = useCache();
  const { alertStatus } = usePage();
  const { downloadMultiple, isDownloading } = useDownload();
  const ability = useAbility();

  const [options, setOptions] = useState(() => ({
    cultures: [],
  }));
  const attachments: any[] = [];

  const [loadingButton, setLoadingButton] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);
  const [visibilityFarm, setVisibilityFarm] = useState(false);
  const [visibilityOwner, setVisibilityOwner] = useState(false);

  const [data, setData] = useState([]);
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [paginationConfig, setPaginationConfig] = useState<TablePaginationConfig>({
    current: 1,
    total: 1,
    pageSize: 20,
  });
  const [visibilityForm, setVisibilityForm] = useState(false);

  // Harvests
  const [harvests, setHarvests] = useState<ItemsType[]>(() => {
    let harvestOptions: ItemsType[] = [];
    setLoading(true);
    registerApi.harvest
      .all()
      .then((response: ResponseListData<HarvestData>) => {
        const { data: harvestData } = response.data;
        harvestOptions = harvestData.map((harvest: { id: string; name: string }) => ({
          key: harvest.id,
          value: harvest.id,
          label: harvest.name,
        }));
        setHarvests(harvestOptions);
        harvestData.forEach((element: { is_current: any; id: any }) => {
          if (element.is_current) {
            searchForm.setFieldsValue({ harvest_id: element.id });
          }
        });

        handleFastSearch();
      })
      .catch(exceptionHandler);
    return harvestOptions;
  });

  // Fazendas
  const [farms, setFarms] = useState<ItemsType[]>(() => {
    let farmsOptions: ItemsType[] = [];
    if (ability.can('monitoring.farm.all', ''))
      monitoringApi.farm
        .all()
        .then((response: ResponseListData<FarmData>) => {
          const { data: farmData } = response.data;
          farmsOptions = farmData.map((farm: { id: any; name: any; city: any }) => ({
            key: farm.id,
            value: farm.id,
            label: `${farm.name} - ${farm.city.state.name} - ${farm.city.name}`,
          }));
          setFarms(farmsOptions);
        })
        .catch((err: DTOErrorReponse) => alertStatus(err, 'error'));
    return farmsOptions;
  });

  // Subsidiaries
  const [subsidiaries, setSubsidiaries] = useState<ItemsType[]>(() => {
    let subsidiariesOptions: ItemsType[] = [];
    registerApi.subsidiary
      .all()
      .then((response: ResponseListData<SubsidiaryData>) => {
        const { data: subsidiariesData } = response.data;
        subsidiariesOptions = subsidiariesData.map((subsidiary: { id: any; name: any }) => ({
          key: subsidiary.id,
          value: subsidiary.id,
          label: subsidiary.name,
        }));
        setSubsidiaries(subsidiariesOptions);
      })
      .catch((err: DTOErrorReponse) => alertStatus(err, 'error'));
    return subsidiariesOptions;
  });

  // Documents
  const [alreadySelectedTypeFile, setAlreadySelectedTypeFile] = useState(false);
  const [alreadySelectedFile, setAlreadySelectedFile] = useState(false);
  const [documents_form] = Form.useForm();
  const [documents, setDocuments] = useState([]);

  const [subforms, setSubforms] = useState(null);
  useEffect(() => {
    setSubforms({
      documents: documents_form,
    });
  }, [documents_form]);

  const [subformsLoading, setSubformsLoading] = useState({
    documents: { editing: null, removing: null, form: null, other: null },
  });

  const [visibilitySubforms, setVisibilitySubforms] = useState({
    documents: null,
  });

  const updateData = (row: Store) => {
    let dataUpdated: Array<Store> = data;
    if (data.length > 0) {
      const rowIndex = data.findIndex((item: Store) => row.id === item.id);
      // Se encontrar o item remove e adiciona ele atualizado na mesma posição
      if (rowIndex > -1) {
        dataUpdated.splice(rowIndex, 1, row);
      } else {
        if (dataUpdated.length === paginationConfig.pageSize) {
          // Se não encontrar, remove o último (por conta da paginação) e
          // adiciona o item na primeira posição
          dataUpdated.splice(-1, 1);
        }
        dataUpdated = [row, ...dataUpdated];
      }
    } else {
      dataUpdated.push(row);
    }

    setData(dataUpdated);
  };

  const handleAddFarm = (contactData: { data: FarmData }) => {
    const farm = contactData.data;
    const farmOption: ItemsType = {
      key: farm.id,
      value: farm.id,
      label: farm.name,
    };
    setFarms([...farms, farmOption]);

    let contactNextValue = [];
    const contactPrevValue = form.getFieldValue('farm_id');
    if (contactPrevValue) {
      contactNextValue = [...contactPrevValue, farmOption.value];
    } else {
      contactNextValue = [farmOption.value];
    }

    form.setFieldsValue({ farm_id: contactNextValue[0] });
    setVisibilityFarm(false);
  };

  const handleCancelFarm = () => {
    setVisibilityFarm(false);
  };

  // Proprietários
  const [owners, setOwners] = useState<ItemsType[]>(() => {
    let ownersOptions: ItemsType[] = [];
    monitoringApi.owner
      .all()
      .then((response: ResponseListData<OwnerData>) => {
        const { data: ownersData } = response.data;
        ownersOptions = ownersData.map((owner: { id: any; name: any }) => ({
          key: owner.id,
          value: owner.id,
          label: owner.name,
        }));
        setOwners(ownersOptions);
      })
      .catch((err: DTOErrorReponse) => alertStatus(err, 'error'));
    return ownersOptions;
  });

  // document type
  const [documentType, setDocumentType] = useState<ItemsType[]>(() => {
    let documentTypeOptions: ItemsType[] = [];
    if (ability.can('document.type.index', ''))
      documentApi
        .documentType({ params: { tag: 'monitoring' } })
        .then((response: { data: any }) => {
          const { data: documentTypeData } = response.data.data;
          documentTypeOptions = documentTypeData.map((document: { id: any; name: any }) => ({
            key: document.id,
            value: document.id,
            label: document.name,
            saved: true,
          }));
          setDocumentType(documentTypeOptions);
        })
        .catch((err: DTOErrorReponse) => alertStatus(err, 'error'));
    return documentTypeOptions;
  });

  const handleSuccess = (messageSuccess: string) =>
    ModalStatus({
      type: 'success',
      title: t('modal.success'),
      subTitle: messageSuccess,
    });

  const loadTableData = useCallback(
    (values: Partial<OwnerForm & PaginationData<OwnerData>>) => {
      setLoading(true);
      setLoadingButton(true);
      setDisabledButton(true);
      const dataSend = {
        params: {
          per_page: paginationConfig.pageSize,
          ...values,
        },
      };

      monitoringApi.cpr
        .get(dataSend)
        .then((response: ResponseListData<DTOCprModel>) => {
          const result = response.data.data;
          setData(result.data);
          setPaginationConfig(paginationConfigState => ({
            ...paginationConfigState,
            current: result.current_page,
            total: result.total,
          }));
        })
        .catch((err: DTOErrorReponse) => alertStatus(err, 'error'))
        .finally(() => {
          setLoading(false);
          setLoadingButton(false);
          setDisabledButton(false);
        });
    },
    // eslint-disable-next-line
    [i18n.language, paginationConfig.pageSize],
  );

  function handleStatus(deadline: string, row: DTOCprModel, survey: string) {
    const value = row.surveys.find((element: DTOSurvey) => element.survey_stage.order === survey);
    const date = value !== undefined ? value.date_survey : moment().format('YYYY-MM-DD');

    if (date < deadline) {
      return 'light-green';
    }
    if (date === deadline) {
      return 'light-yellow';
    }
    return 'light-danger';
  }

  const handleDisplayForm = () => {
    setVisibilityForm(!visibilityForm);
    setEditing(false);
    setDisabledButton(false);
    setLoadingButton(false);
    form.resetFields();
  };

  const handleHideForm = () => {
    setVisibilityForm(false);
    setDocuments([]);
  };

  const handleAddOwner = (ownerData: { data: OwnerData }) => {
    const owner = ownerData.data;
    const ownerOption: ItemsType = {
      key: owner.id,
      value: owner.id,
      label: owner.name,
    };
    setOwners([...owners, ownerOption]);

    let ownerNextValue = [];
    const ownerPrevValue = form.getFieldValue('owner_id');
    if (ownerPrevValue) {
      ownerNextValue = [...ownerPrevValue, ownerOption.value];
    } else {
      ownerNextValue = [ownerOption.value];
    }

    form.setFieldsValue({ owner_id: ownerNextValue[0] });
    setVisibilityOwner(false);
  };

  const handleCancelOwner = () => {
    setVisibilityOwner(false);
  };

  const handleRemoveChange = async (record: any) => {
    ModalStatus({
      type: 'delete',
      title: t('pages.monitoring.cpr.delete.title'),
      subTitle: t('pages.monitoring.cpr.delete.subtitle'),
      cancelText: t('pages.monitoring.cpr.delete.cancel'),
      okText: t('pages.monitoring.cpr.delete.confirm'),
      onOk: () => {
        monitoringApi.cpr
          .destroy(record.id, {
            params: {},
          })
          .then(response => {
            handleSuccess(response.data.message);
            handleTableChange({ current: 1 }, { active: [1] }, {}, null);
          })
          .catch((err: DTOErrorReponse) => alertStatus(err, 'error'));
      },
    });
  };

  const handleRegister = () => {
    setLoading(true);
    setLoadingButton(true);
    setDisabledButton(true);
    form
      .validateFields()
      .then(() => {
        const formValues = form.getFieldsValue();
        formValues.documents = [];
        if (documents.length > 0) {
          documents.forEach((record: any, key) => {
            if (formValues.documents[key]) {
              formValues.documents[key].name = record.name;
              formValues.documents[key].type_id = record.type_id;
              formValues.documents[key].label = 'survey';
              formValues.documents[key].mime = record.mime;
              formValues.documents[key].image = record.image;
              formValues.documents[key].extension = record.mime;
            } else {
              formValues.documents.push(record);
            }
          });
        }

        monitoringApi.cpr
          .store({
            ...formValues,
          })
          .then((response: ResponseListData<CprData>) => {
            const { data: cpr } = response.data;
            handleSuccess(response.data.message);
            updateData(cpr);
            handleHideForm();
            setLoading(false);
            setLoadingButton(false);
            setDisabledButton(false);
            form.resetFields();
            updateUserData();
          })
          .catch((err: DTOErrorReponse) => {
            alertStatus(err, 'error');
            setLoading(false);
            setLoadingButton(false);
            setDisabledButton(false);
          });
      })
      .catch((err: DTOErrorReponse) => {
        alertStatus(err, 'error');
        setLoading(false);
        setLoadingButton(false);
        setDisabledButton(false);
      });
  };

  const handleEdit = () => {
    setLoading(true);
    setLoadingButton(true);
    setDisabledButton(true);
    form
      .validateFields()
      .then(() => {
        const formValues = form.getFieldsValue();
        formValues.documents = [];
        if (documents.length > 0) {
          documents.forEach((record: any, key) => {
            if (formValues.documents[key]) {
              formValues.documents[key].name = record.name;
              formValues.documents[key].type_id = record.type_id;
              formValues.documents[key].label = 'survey';
              formValues.documents[key].mime = record.mime;
              formValues.documents[key].image = record.image;
              formValues.documents[key].extension = record.mime;
            } else {
              formValues.documents.push(record);
            }
          });
        }

        monitoringApi.cpr
          .update(formValues.id, {
            ...formValues,
          })
          .then((response: ResponseListData<CprData>) => {
            const { data: cpr } = response.data;
            handleSuccess(response.data.message);
            updateUserData();
            handleHideForm();
            updateData(cpr);
            setLoading(false);
            setLoadingButton(false);
            setDisabledButton(false);
            form.resetFields();
          })
          .catch((err: DTOErrorReponse) => {
            alertStatus(err, 'error');
            setLoading(false);
            setLoadingButton(false);
            setDisabledButton(false);
          });
      })
      .catch((err: DTOErrorReponse) => {
        alertStatus(err, 'error');
        setLoading(false);
        setLoadingButton(false);
        setDisabledButton(false);
      });
  };

  // Table DATA Config: BEGIN
  const getActions = () => ({
    render: (active: number, record: CprData) => (
      <>
        {/* <IconWithTooltip
          action="csv"
          title={t('pages.monitoring.cpr.table.icons.survey_request')}
          onClick={() => console.log('Generate Report')}
        /> */}
        <Can I="monitoring.cpr.destroy" a="">
          <IconWithTooltip
            action="destroy"
            title={t('pages.monitoring.cpr.table.icons.remove')}
            onClick={() => handleRemoveChange(record)}
          />
        </Can>
        <Can I="monitoring.cpr.update" a="">
          <IconWithTooltip
            action="edit"
            title={t('pages.monitoring.cpr.table.icons.edit')}
            onClick={() => {
              form.setFieldsValue({
                ...record,
                development_deadline: record.development_deadline
                  ? Formatter.date(record.development_deadline, true)
                  : '',
                planting_deadline: record.planting_deadline ? Formatter.date(record.planting_deadline, true) : '',
                harvest_deadline: record.harvest_deadline ? Formatter.date(record.harvest_deadline, true) : '',
              });

              setDocuments(record.documents);

              form.scrollToField('name', {
                scrollMode: 'always',
                block: 'start',
                behavior: actions =>
                  actions.forEach(({ el, top }) => {
                    el.scrollTop = top - 280;
                  }),
              });

              setVisibilityForm(true);
              setEditing(true);
              setLoading(false);
              setLoadingButton(false);
              setDisabledButton(false);
            }}
          />
        </Can>
        <Can I="monitoring.registration.index" a="">
          <Link to={`/monitoring/cpr/${record.id}/registration`}>
            <IconWithTooltip action="registrationArea" title={t('pages.monitoring.cpr.form.iconRegistration')} />
          </Link>
        </Can>
        <SIconContainer>
          <Tooltip title={t('pages.monitoring.cpr.form.iconRegistration')} placement="top">
            <Badge count={record.registration.length ?? 0} />
          </Tooltip>
        </SIconContainer>
        <SIconContainer>
          <Tooltip title={t('pages.monitoring.cpr.form.iconSurveyMade')} placement="topRight">
            <Badge count={record.surveys.length ?? 0} style={{ backgroundColor: theme.colors.info }} />
          </Tooltip>
        </SIconContainer>
      </>
    ),
  });

  const [searchForm] = Form.useForm();
  const tableConfig = {
    search: '',
    filtered: {},
    sorter: {},
    getActions,
  };

  const [tableDataConfig, setTableDataConfig] = useState(tableConfig);

  const handleFastSearch = () => {
    const value = searchForm.getFieldsValue();
    setTableDataConfig(tableDataConfigState => ({
      ...tableDataConfigState,
      search: value.search,
      survey: value.search_survey,
      harvest: value.harvest_id,
    }));
    loadTableData({
      search: value.search,
      survey: value.search_survey,
      harvest: value.harvest_id,
      page: 1,
    });
  };

  const tableDataClearAllFilters = () => {
    searchForm.resetFields();
    setTableDataConfig(tableDataConfigState => ({
      ...tableDataConfigState,
      filtered: {},
      search: '',
      sorter: {},
    }));
    loadTableData({ page: 1 });
  };

  const handleTableChange: TableProps<any>['onChange'] = useCallback(
    (
      pagination: TablePaginationConfig,
      filters: Record<string, FilterValue | null>,
      sorter: SorterResult<any> | SorterResult<any>[],
    ) => {
      setLoading(true);
      setTableDataConfig(tableDataConfigState => ({
        ...tableDataConfigState,
        filtered: filters,
        sorter,
      }));

      let direction = '';
      const search: any = {};
      let sort = '';

      if (sorter) {
        const { field, order } = sorter as SorterResult<any>;

        if (order === 'ascend') direction = 'asc';
        if (order === 'descend') direction = 'desc';
        sort = field?.toString() ?? '';
      }

      if (filters) {
        const dataFilter: any = filters;

        if (dataFilter) {
          Object.keys(dataFilter).forEach((key: string) => {
            if (dataFilter[key] !== null && dataFilter[key] !== undefined && dataFilter[key][0]) {
              search[key] = dataFilter[key][0].toString();
            } else if (dataFilter[key] !== null && dataFilter[key] !== undefined) {
              search[key] = dataFilter[key].toString();
            } else {
              search[key] = '';
            }
          });
        }
      }

      setPaginationConfig(paginationConfigState => ({
        ...paginationConfigState,
        pageSize: pagination.pageSize,
        current: pagination.current,
      }));
      const value = searchForm.getFieldsValue();
      loadTableData({
        page: pagination.current,
        per_page: pagination.pageSize,
        sort,
        direction,
        search: value.search,
        survey: value.search_survey,
        harvest: value.harvest_id,
      });
    },
    [loadTableData],
  );
  // Table DATA Config: END

  useEffect(() => {
    setOptions({
      cultures: getPersonOptions('culture'),
    });
    // eslint-disable-next-line
  }, [handleTableChange, paginationConfig.pageSize]);

  async function subformsOpen(node: NodeParam) {
    subforms[node].resetFields();
    setVisibilitySubforms(curState => ({ ...curState, [node]: true }));
  }

  async function handleClickAddDocuments() {
    subformsOpen('documents');
    setAlreadySelectedTypeFile(false);
    setAlreadySelectedFile(false);
    subforms.documents.setFieldsValue({ id: uuid() });
  }

  async function handleTableDocumentsChange() {
    documents_form
      .validateFields()
      .then(() => {
        if (documents_form.getFieldsValue().name !== undefined) {
          setDocuments([...documents, { ...documents_form.getFieldsValue(), editing: true }]);

          setLoading(true);
          setLoading(false);

          setVisibilitySubforms(curState => ({
            ...curState,
            documents: false,
          }));
        } else {
          alertStatus(t('form.validation.hasErrors'), 'error');
          setLoading(false);
          setLoadingButton(false);
          setDisabledButton(false);
        }
      })
      .catch(() => {
        setLoading(false);
        setLoadingButton(false);
        setDisabledButton(false);
      });
  }

  async function handleRemoveFile(id: string, index: number, isEditing: boolean) {
    if (!isEditing) await documentApi.delete(id);
    documents.splice(index, 1);
    setDocuments([...documents]);
  }

  return (
    <>
      <Breadcrumb items={[{ title: t('breadcrumb.monitoring') }, { title: t('breadcrumb.cpr') }]} />

      <SContainer>
        <SHeader>
          <STitlePage>{t('pages.monitoring.cpr.title_page')}</STitlePage>

          <Can I="monitoring.cpr.store" a="">
            <Button status="primary" icon={visibilityForm ? <FaMinus /> : <FaPlus />} onClick={handleDisplayForm}>
              {t('pages.monitoring.cpr.buttonAdd')}
            </Button>
          </Can>
        </SHeader>

        <SFormContainer visible={visibilityForm}>
          <Spin spinning={loading || isDownloading}>
            <h2>{t('pages.monitoring.cpr.registerTitle')}</h2>
            <Form form={form}>
              <FormItem name="id" style={{ display: 'none' }}>
                <Input />
              </FormItem>
              <Row gutter={[26, 26]}>
                <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <FormItem label={t('pages.monitoring.cpr.form.farm')} name="farm_id" rules={[{ required: true }]}>
                    <Select
                      options={farms}
                      dropdownOptions={{
                        buttonIcon: () => (
                          <Can I="monitoring.farm.store">
                            <FaUserPlus />
                          </Can>
                        ),
                        buttonText: t('pages.monitoring.farm.buttonAdd'),
                        buttonOnClick: () =>
                          ability.can('monitoring.farm.store', '') ? setVisibilityFarm(true) : undefined,
                      }}
                    />
                  </FormItem>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <FormItem label={t('pages.monitoring.cpr.form.owner')} name="owner_id" rules={[{ required: true }]}>
                    <Select
                      options={owners}
                      dropdownOptions={{
                        buttonIcon: () => <FaUserPlus />,
                        buttonText: t('pages.monitoring.owner.buttonAdd'),
                        buttonOnClick: () => setVisibilityOwner(true),
                      }}
                    />
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={[26, 26]}>
                <Col xs={24} sm={18} md={12} lg={9} xl={8} xxl={8}>
                  <FormItem
                    label={t('pages.monitoring.cpr.form.harvest')}
                    name="harvest_id"
                    rules={[{ required: true }]}
                  >
                    <Select options={harvests} />
                  </FormItem>
                </Col>
                <Col xs={24} sm={18} md={12} lg={9} xl={8} xxl={8}>
                  <FormItem
                    label={t('pages.monitoring.cpr.form.subsidiary')}
                    name="subsidiary_id"
                    rules={[{ required: true }]}
                  >
                    <Select options={subsidiaries} />
                  </FormItem>
                </Col>
                <Col xs={24} sm={18} md={12} lg={9} xl={8} xxl={8}>
                  <FormItem
                    style={{ paddingRight: '10px' }}
                    label={t('pages.monitoring.cpr.form.county')}
                    name="county"
                    rules={[{ required: true }]}
                  >
                    <Input type="text" />
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={[26, 26]}>
                <Col xs={24} sm={18} md={12} lg={9} xl={8} xxl={8}>
                  <FormItem
                    label={t('pages.monitoring.cpr.form.product')}
                    name="culture_id"
                    rules={[{ required: true }]}
                  >
                    <Select options={options.cultures} />
                  </FormItem>
                </Col>
                <Col xs={24} sm={18} md={12} lg={9} xl={8} xxl={8}>
                  <FormItem
                    style={{ paddingRight: '10px' }}
                    label={t('pages.monitoring.cpr.form.number')}
                    name="number"
                    rules={[{ required: true }]}
                  >
                    <Input type="text" />
                  </FormItem>
                </Col>
                <Col xs={24} sm={18} md={12} lg={9} xl={8} xxl={8}>
                  <FormItem
                    style={{ paddingRight: '10px' }}
                    label={t('pages.monitoring.cpr.form.warranty_area')}
                    name="warranty_area"
                    rules={[{ required: true }]}
                  >
                    <InputNumber
                      precision={2}
                      min={0}
                      formatter={(value: string | number) => Formatter.decimal(value, 2, 3)}
                      parser={(value: string) => Number(Formatter.decimalParser(value))}
                    />
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={[26, 26]}>
                <Col xs={24} sm={18} md={12} lg={9} xl={8} xxl={8}>
                  <FormItem
                    style={{ paddingRight: '10px' }}
                    label={t('pages.monitoring.cpr.form.warranty_volume')}
                    name="warranty_volume"
                    rules={[{ required: true }]}
                    initialValue={0.0}
                  >
                    <InputNumber
                      precision={2}
                      min={0}
                      formatter={(value: string | number) => Formatter.decimal(value, 2, 3, null, 'kg')}
                      parser={(value: string) => Number(Formatter.decimalParser(value))}
                    />
                  </FormItem>
                </Col>
                <Col xs={24} sm={18} md={12} lg={9} xl={8} xxl={8}>
                  <FormItem
                    label={
                      <DeadlineTooltipWrapper>
                        {t('pages.monitoring.cpr.form.planting_deadline')}
                        <FieldNotNecessaryTooltip />
                      </DeadlineTooltipWrapper>
                    }
                    name="planting_deadline"
                  >
                    <DatePicker
                      locale={ptBR}
                      mode="date"
                      format={appConfigs.formatDate}
                      disabledDate={currentDate => moment() >= currentDate}
                      onBlur={evt => evt.currentTarget.value === '' && form.setFieldsValue({ planting_deadline: '' })}
                    />
                  </FormItem>
                </Col>
                <Col xs={24} sm={18} md={12} lg={9} xl={8} xxl={8}>
                  <FormItem
                    label={
                      <DeadlineTooltipWrapper>
                        {t('pages.monitoring.cpr.form.development_deadline')}
                        <FieldNotNecessaryTooltip />
                      </DeadlineTooltipWrapper>
                    }
                    name="development_deadline"
                  >
                    <DatePicker
                      locale={ptBR}
                      mode="date"
                      format={appConfigs.formatDate}
                      disabledDate={currentDate => form.getFieldValue('planting_deadline') > currentDate}
                      onBlur={evt =>
                        evt.currentTarget.value === '' && form.setFieldsValue({ development_deadline: '' })
                      }
                    />
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={[26, 26]}>
                <Col xs={24} sm={18} md={12} lg={9} xl={8} xxl={8}>
                  <FormItem
                    label={
                      <DeadlineTooltipWrapper>
                        {t('pages.monitoring.cpr.form.harvest_deadline')}
                        <FieldNotNecessaryTooltip />
                      </DeadlineTooltipWrapper>
                    }
                    name="harvest_deadline"
                  >
                    <DatePicker
                      locale={ptBR}
                      mode="date"
                      format={appConfigs.formatDate}
                      disabledDate={currentDate => form.getFieldValue('development_deadline') > currentDate}
                      onBlur={evt => evt.currentTarget.value === '' && form.setFieldsValue({ harvest_deadline: '' })}
                    />
                  </FormItem>
                </Col>
              </Row>

              <Row gutter={[26, 26]}>
                <Col xs={24} sm={18} md={12} lg={9} xl={8} xxl={8}>
                  <FormItem label={t('pages.monitoring.cpr.form.details')} name="details">
                    <TextArea maxLength={3000} />
                  </FormItem>
                </Col>
              </Row>

              <SectionTitle>{t('pages.monitoring.cpr.form.files')}</SectionTitle>
              <>
                <SFormButtons style={{ paddingTop: '15px' }}>
                  <>
                    <Button status="primary-outline" onClick={handleClickAddDocuments}>
                      {t('pages.monitoring.cpr.form.attachFile')}
                    </Button>
                    {editing && (
                      <Button
                        status="primary-outline"
                        onClick={async () => {
                          if (documents.length) {
                            let c = 0;

                            attachments.push({
                              attachment_id: [],
                            });
                            documents.forEach(element => {
                              attachments[0].attachment_id[c] = element.id;
                              c += 1;
                            });
                            downloadMultiple(attachments[0].attachment_id);
                          } else {
                            ModalStatus({
                              type: 'error',
                              subTitle: t('pages.monitoring.cpr.files.download.nothing'),
                            });
                          }
                        }}
                      >
                        <IconWithTooltip
                          action="download"
                          title={t('pages.registered-customers.table.icons.download')}
                        />
                        {t('pages.monitoring.survey.form.downloadall')}
                      </Button>
                    )}
                  </>
                </SFormButtons>
                <>
                  <TableData
                    rowKey="id"
                    columns={columns.documents.map((column: { key: any }) => {
                      let columnRender: any = column;

                      switch (column.key) {
                        case 'type_id':
                          columnRender = {
                            ...columnRender,
                            render: (active: number, record: any) => {
                              const findState = documentType.find(s => s.value === record.type_id);
                              return findState?.label;
                            },
                          };
                          break;
                        case 'action':
                          columnRender = {
                            ...columnRender,
                            render: (active: number, record: DocumentModelData, index: number) => (
                              <>
                                <IconWithTooltip
                                  action="download"
                                  title={t('pages.registered-customers.table.icons.download')}
                                  loading={subformsLoading.documents.other === record.id}
                                  onClick={async () => {
                                    setSubformsLoading(curState => ({
                                      ...curState,
                                      documents: { ...subformsLoading.documents, other: record.id },
                                    }));
                                    if (!record?.image) {
                                      await documentApi
                                        .download(record.id, {
                                          params: {
                                            'attachment_id[]': record.id,
                                          },
                                          responseType: 'blob',
                                        })
                                        .then(downloadHandler)
                                        .catch((err: DTOErrorReponse) => alertStatus(err, 'error'))
                                        .finally(() => {
                                          setSubformsLoading(curState => ({
                                            ...curState,
                                            documents: { ...subformsLoading.documents, other: null },
                                          }));
                                        });
                                    } else {
                                      setSubformsLoading(curState => ({
                                        ...curState,
                                        documents: { ...subformsLoading.documents, other: null },
                                      }));
                                      downloadDocument(record.image, record.name);
                                    }
                                  }}
                                />

                                <Can I="document.destroy">
                                  <IconWithTooltip
                                    action="destroy"
                                    title={t('pages.registered-customers.table.icons.remove')}
                                    loading={subformsLoading.documents.removing === record.id}
                                    onClick={() => {
                                      ModalStatus({
                                        type: 'delete',
                                        title: t('pages.monitoring.cpr.files.delete.title'),
                                        subTitle: t('pages.monitoring.cpr.files.delete.subtitle'),
                                        cancelText: t('pages.monitoring.cpr.files.delete.cancel'),
                                        okText: t('pages.monitoring.cpr.files.delete.confirm'),
                                        onOk: async () => handleRemoveFile(record.id, index, record.editing),
                                      });
                                    }}
                                  />
                                </Can>
                              </>
                            ),
                          };
                          break;
                        default:
                          return columnRender;
                      }

                      return columnRender;
                    })}
                    dataSource={documents || null}
                    tableConfig={{ nopagination: true }}
                    rowClassName={record => record.editing && 'editing'}
                  />

                  <br />
                </>

                <SFormButtons>
                  <Button status="secondary" htmlType="reset" onClick={handleHideForm} disabled={disabledButton}>
                    {t('pages.monitoring.cpr.buttonCancel')}
                  </Button>
                  {editing && (
                    <Button
                      status="primary"
                      htmlType="submit"
                      onClick={handleEdit}
                      loading={loadingButton}
                      disabled={disabledButton}
                      permission="monitoring.cpr.update"
                    >
                      {t('pages.monitoring.cpr.buttonEdit')}
                    </Button>
                  )}
                  {!editing && (
                    <Button
                      status="primary"
                      htmlType="submit"
                      onClick={handleRegister}
                      loading={loadingButton}
                      disabled={disabledButton}
                      permission="monitoring.cpr.store"
                    >
                      {t('pages.monitoring.cpr.buttonRegister')}
                    </Button>
                  )}
                </SFormButtons>
              </>
            </Form>
          </Spin>
        </SFormContainer>

        <SFilterContainer>
          <Form
            form={searchForm}
            name="filter-owner"
            className="form-secondary form-filters grid-filters"
            onFinish={handleFastSearch}
          >
            <div className="filter-search">
              <FormItem name="search" label={t('pages.monitoring.cpr.search')}>
                <Input placeholder={t(`pages.monitoring.cpr.filters.search`)} />
              </FormItem>
            </div>
            <div className="filter-search2">
              <FormItem label="" name="search_survey" className="form-item-without-label">
                <Select
                  defaultValue="all"
                  options={[
                    { value: 'all', label: t('pages.monitoring.cpr.filters.all') },
                    { value: 'survey', label: t('pages.monitoring.cpr.filters.survey') },
                    { value: 'notsurvey', label: t('pages.monitoring.cpr.filters.notsurvey') },
                  ]}
                />
              </FormItem>
            </div>
            <div className="filter-search3">
              <FormItem name="harvest_id" label={t('pages.monitoring.cpr.filters.search_harvest')}>
                <Select options={harvests} />
              </FormItem>
            </div>
            <div className="filter-button">
              <FormItem label="" className="form-item-without-label">
                <Button status="primary" text="uppercase" htmlType="submit">
                  <FaSearch /> {t('form.actions.search')}
                </Button>
              </FormItem>
            </div>
            <div className="filter-clear">
              <Button status="secondary" size="middle" onClick={tableDataClearAllFilters}>
                {t('pages.register.harvest.buttonClearFilter')}
              </Button>
            </div>
          </Form>
        </SFilterContainer>

        <TableData
          rowKey="id"
          columns={columns.cpr.map((column: any) => {
            let columnRender: any = {
              ...column,
            };

            if (column.key === 'info') {
              columnRender = {
                ...columnRender,
                render: (_: any, row: DTOCprModel) =>
                  row.culture_id &&
                  row.surveys.find(element => element.culture_id && element.culture_id !== row.culture_id) && (
                    <IconWithTooltip
                      placement="right"
                      action="high-priority"
                      title={t('pages.monitoring.cpr.alert-survey')}
                    />
                  ),
              };
            }

            if (column.key === 'farm') {
              columnRender = {
                ...columnRender,
                render: (value: FarmData) => (value ? value.name : ''),
              };
            }

            if (column.key === 'subsidiary') {
              columnRender = {
                ...columnRender,
                render: (value: SubsidiaryData) => (value ? value.name : ''),
              };
            }

            if (column.key === 'owner') {
              columnRender = {
                ...columnRender,
                render: (value: OwnerData) => (value ? value.name : ''),
              };
            }

            if (column.key === 'culture') {
              columnRender = {
                ...columnRender,
                render: (value: DTOCulture) => (value ? value.name : ''),
              };
            }

            if (column.key === 'planting') {
              columnRender = {
                ...columnRender,
                render: (value: any, row: any) => {
                  return (
                    <Space>
                      <Button
                        key={value}
                        status={row.planting_deadline ? handleStatus(row.planting_deadline, row, '1') : 'grey'}
                      >
                        {row.planting_deadline
                          ? Formatter.date(row.planting_deadline)
                          : t('pages.monitoring.cpr.table.notNecessary')}
                      </Button>
                    </Space>
                  );
                },
              };
            }

            if (column.key === 'development') {
              columnRender = {
                ...columnRender,
                render: (value: any, row: any) => {
                  return (
                    <Space>
                      <Button
                        key={value}
                        status={row.development_deadline ? handleStatus(row.development_deadline, row, '2') : 'grey'}
                      >
                        {row.development_deadline
                          ? Formatter.date(row.development_deadline)
                          : t('pages.monitoring.cpr.table.notNecessary')}
                      </Button>
                    </Space>
                  );
                },
              };
            }

            if (column.key === 'harvest') {
              columnRender = {
                ...columnRender,
                render: (value: any, row: any) => {
                  return (
                    <Space>
                      <Button
                        key={value}
                        status={row.harvest_deadline ? handleStatus(row.harvest_deadline, row, '3') : 'grey'}
                      >
                        {row.harvest_deadline
                          ? Formatter.date(row.harvest_deadline)
                          : t('pages.monitoring.cpr.table.notNecessary')}
                      </Button>
                    </Space>
                  );
                },
              };
            }

            return columnRender;
          })}
          dataSource={data}
          loading={loading}
          onChange={handleTableChange}
          pagination={paginationConfig}
          tableConfig={tableDataConfig}
        />
        <Can I="monitoring.farm.store">
          <FarmModal visibility={visibilityFarm} onOkCallback={handleAddFarm} onCancelCallback={handleCancelFarm} />
        </Can>
        <OwnerModal visibility={visibilityOwner} onOkCallback={handleAddOwner} onCancelCallback={handleCancelOwner} />
        <Modal
          title={t('pages.monitoring.cpr.document.modal.title')}
          visible={visibilitySubforms.documents}
          onCancel={() => setVisibilitySubforms(curState => ({ ...curState, documents: false }))}
          afterClose={() => {
            documents_form.resetFields();
            setAlreadySelectedTypeFile(false);
          }}
          width="80%"
          footer={
            <>
              <div className="buttons">
                <Button
                  status="primary"
                  onClick={() => handleTableDocumentsChange()}
                  disabled={subformsLoading.documents.form}
                >
                  {t('pages.registered-customers.form.buttons.save')}
                </Button>

                <Button
                  status="secondary"
                  onClick={() => {
                    setVisibilitySubforms(curState => ({ ...curState, documents: false }));
                    documents_form.resetFields();
                  }}
                  disabled={subformsLoading.documents.form}
                >
                  {t('pages.registered-customers.form.buttons.cancel')}
                </Button>
              </div>
            </>
          }
          className="modal-with-custom-footer"
        >
          <Spin spinning={subformsLoading.documents.form}>
            <Form name="subform_documents" form={documents_form}>
              <FormItem name="id" hidden>
                <Input />
              </FormItem>
              <FormItem name="original" hidden>
                <Input />
              </FormItem>
              <FormItem name="name" hidden>
                <Input />
              </FormItem>
              <FormItem name="mime" hidden>
                <Input />
              </FormItem>
              <FormItem name="image" hidden>
                <Input />
              </FormItem>

              <Row gutter={[8, 8]}>
                <Col xs={24} sm={24} md={24} lg={12} xl={9} xxl={9}>
                  <FormItem
                    label={t('pages.monitoring.cpr.form.type_file')}
                    name="type_id"
                    rules={[{ required: true }]}
                  >
                    <PermissionedComponent permission="document.type.index">
                      <Select
                        options={documentType}
                        onChange={() => {
                          setAlreadySelectedTypeFile(true);
                          setAlreadySelectedFile(false);
                        }}
                      />
                    </PermissionedComponent>
                  </FormItem>
                </Col>
              </Row>

              {alreadySelectedTypeFile && (
                <>
                  <Row gutter={[8, 8]}>
                    <Col xs={24} sm={24} md={24} lg={12} xl={9} xxl={9}>
                      {alreadySelectedFile ? (
                        <>
                          <div className="selected-document">
                            <span>{documents_form.getFieldValue('name')}</span>
                            <Button
                              status="primary-outline"
                              onClick={() => {
                                setAlreadySelectedFile(false);
                              }}
                            >
                              {t('pages.registered-customers.form.pf.documents.changeFile')}
                            </Button>
                          </div>
                        </>
                      ) : (
                        <>
                          <Dragger
                            className="custom-dragger"
                            accept=".jpg,.png,.jpeg,.pdf,.gtm"
                            action={(file: any) => {
                              getBase64(file, (documentBase64: any) => {
                                documents_form.setFieldsValue({
                                  image: documentBase64,
                                  mime: file.type,
                                  name: getOriginalName(file.name, true),
                                  original: getOriginalName(file.name),
                                });
                                setAlreadySelectedFile(true);
                              });
                              return file;
                            }}
                            customRequest={() => 'done'}
                          >
                            {t('pages.registered-customers.form.pf.documents.dragText')}
                          </Dragger>
                        </>
                      )}
                    </Col>
                  </Row>
                </>
              )}
            </Form>
          </Spin>
        </Modal>
      </SContainer>
    </>
  );
};

export default Cpr;

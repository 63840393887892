import styled from 'styled-components';

export const Container = styled.div`
  padding: 8px 8px 24px;

  .content-pannel {
    padding: 24px;
    display: grid;
    grid-gap: 16px;
    grid-template-columns: repeat(4, 1fr);

    > .item {
      border: 1px solid #514e49;
      border-radius: 8px;
      color: #514e49;
      font-weight: bold;
      font-size: 16px;
      padding: 32px 8px;
      width: 100%;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      display: grid;
      grid-template-columns: 1fr 2fr;

      &:hover {
        border-color: ${({ theme: { colors } }) => colors.primary};
        color: ${({ theme: { colors } }) => colors.primary};
        transition: 0.2s;
      }

      > svg,
      > img {
        margin: auto;
      }

      > img {
        max-width: 100%;
        height: 32px;
      }

      > svg {
        max-width: 100%;
        height: 32px;
      }

      > span {
        text-align: left;
        padding: 0 8px;
      }
    }
  }
`;

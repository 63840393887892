import React from 'react';
import { useTranslation } from 'react-i18next';

import { InfiniteSelectAsync } from '@agrodatabr/agrodataui';
import { InboxOutlined } from '@ant-design/icons';
import { Row, Spin, Form as AntForm, Col, Input as AntInput, Input } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { v4 as uuid } from 'uuid';

import { Button, FormItem } from '../../../../components';
import { CustomFormContainer } from '../../../../components/CustomFormContainer';
import { DocxViewer } from '../../../../components/DocxViewer';
import { SDragger } from '../../../../components/UploadAttachment/styles';
import { ModelFields } from '../../components/ModelFields';
import { useFormController } from '../controllers/useFormController';
import { SFormButtons } from './styles';
import { FormProps } from './types';

export const Form = ({ isEditing, closeForm, templateId, refresh }: FormProps) => {
  const { t } = useTranslation();

  const [form] = useForm();
  const {
    isLoading,
    handleEdit,
    handleRegister,
    handleFileUpload,
    handleFileRemove,
    fileProcessedData,
    handleCustomInputChangeValue,
    searchRequest,
    editedTemplate,
    uploadedFile,
    isStoring,
  } = useFormController({
    closeForm,
    refresh,
    form,
    isEditing,
    templateId,
  });

  return (
    <CustomFormContainer>
      <Spin spinning={isLoading}>
        <h2>Registrar Modelo de Contrato</h2>

        <AntForm form={form}>
          <Row gutter={26}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
              <FormItem name="number" label={t('pages.edocuments.templates.form.name')} rules={[{ required: true }]}>
                <Input maxLength={255} />
              </FormItem>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
              <FormItem
                name="contract_type_id"
                label={t('pages.edocuments.templates.form.contractType')}
                rules={[{ required: true }]}
              >
                <InfiniteSelectAsync
                  initialRequest
                  searchRequest={searchRequest}
                  options={
                    editedTemplate
                      ? [
                          {
                            key: editedTemplate.type.id,
                            value: editedTemplate.type.id,
                            label: editedTemplate.type.name,
                          },
                        ]
                      : []
                  }
                />
              </FormItem>
            </Col>
          </Row>

          <Row gutter={26}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <FormItem
                name="description"
                label={t('pages.edocuments.templates.form.description')}
                rules={[{ required: true }]}
              >
                <AntInput.TextArea maxLength={255} />
              </FormItem>
            </Col>
          </Row>

          {true && (
            <Row gutter={26}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                <FormItem name="template" label={t('pages.edocuments.templates.form.template')}>
                  <SDragger
                    accept=".docx"
                    beforeUpload={handleFileUpload}
                    onRemove={handleFileRemove}
                    maxCount={1}
                    fileList={
                      // eslint-disable-next-line no-nested-ternary
                      editedTemplate?.template
                        ? [{ ...(editedTemplate?.template as any), uid: uuid() }]
                        : uploadedFile
                        ? [uploadedFile]
                        : []
                    }
                  >
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">{t('pages.edocuments.templates.form.uploadText')}</p>
                    <p className="ant-upload-hint">{t('pages.edocuments.templates.form.uploadHint')}</p>
                  </SDragger>
                  <FormItem />
                </FormItem>
              </Col>
            </Row>
          )}

          {(fileProcessedData || editedTemplate) && (
            <>
              {(fileProcessedData?.url || editedTemplate?.url) && (
                <Row gutter={26} style={{ marginTop: '40px' }}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                    <div style={{ height: '500px' }}>
                      <DocxViewer
                        useExternalRenderApi
                        url={fileProcessedData?.url ?? editedTemplate?.url}
                        height="100%"
                      />
                    </div>
                  </Col>
                </Row>
              )}

              <ModelFields data={fileProcessedData?.fields || []} handleChange={handleCustomInputChangeValue} />
            </>
          )}

          <SFormButtons>
            <Button status="secondary" htmlType="reset" onClick={closeForm} disabled={isLoading}>
              {t('pages.edocuments.contracttypes.buttonCancel')}
            </Button>
            {isEditing && (
              <Button
                status="primary"
                htmlType="submit"
                onClick={() => handleEdit(templateId, form.getFieldsValue())}
                loading={isLoading || isStoring}
              >
                {t('pages.edocuments.contracttypes.buttonEdit')}
              </Button>
            )}
            {!isEditing && (
              <Button
                status="primary"
                htmlType="submit"
                onClick={() => handleRegister(form.getFieldsValue())}
                loading={isLoading || isStoring}
              >
                {t('pages.edocuments.contracttypes.buttonRegister')}
              </Button>
            )}
          </SFormButtons>
        </AntForm>
      </Spin>
    </CustomFormContainer>
  );
};

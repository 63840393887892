import { useState } from 'react';

import { OptionsType } from 'rc-select/lib/interface';
import { ContractTypeData } from 'types/Contract/ContractTypeData';

import api, { handleRequest } from '../../services/api/api';
import contractApi from '../../services/api/contract';
import { DTOContractTypeFieldType } from '../../types/Contract/DTOContractTypeFieldType';

export const useContractType = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [isFetchingTemplates] = useState(false);
  const [isStoring, setIsStoring] = useState(false);

  const sendRequest = async (request: any) => {
    return handleRequest(request, setIsFetching);
  };

  const showType = async (id: string): Promise<ContractTypeData | null> =>
    sendRequest(() => contractApi.types.show(id));

  const storeType = async (payload: unknown) => handleRequest(() => contractApi.types.store(payload), setIsStoring);

  const updateType = async (id: string, payload: unknown) =>
    handleRequest(() => contractApi.types.update(id, payload), setIsStoring);

  const deleteType = async (id: string) => sendRequest(() => contractApi.types.destroy(id));

  const getTypes = async (params: object = {}, filter = 'all') => {
    const response = await sendRequest(() => contractApi.types.getAll({ params: { ...params, parent: filter } }));

    return response ?? { data: [] as any[], total: 0, last_page: 0 };
  };

  const getTypesOptions = async (params: object = {}, filter = 'all') => {
    const response = await getTypes(params, filter);
    if (response) {
      return {
        data: response.data.map((type: { id: string; name: string; fields: any }) => ({
          key: type.id,
          value: type.id,
          label: type.name,
          fields: type.fields,
        })),
        lastPage: response.last_page,
      };
    }

    return { data: [] as any[], lastPage: 0 };
  };

  const getFieldTypesNoPagination = async (params: object = {}, filter = 'all') => {
    const response = await sendRequest(() =>
      contractApi.types.getAllFieldTypes({ params: { ...params, parent: filter } }),
    );

    return response;
  };

  const getFieldTypesOptionsNoPagination = async (params: object = {}, filter = 'all') => {
    const response = await getFieldTypesNoPagination(params, filter);
    if (response)
      return response?.map((type: DTOContractTypeFieldType) => ({
        key: type.id,
        value: type.id,
        label: type.name,
      })) as OptionsType;

    return [];
  };

  const getFieldTypes = async (params?: { params: unknown }) => {
    const response = await sendRequest(() => contractApi.types.getAllFieldTypes(params));

    return response ?? { data: [] as any[], total: 0, last_page: 0 };
  };

  const getFieldTypesOptions = async (params?: { params: unknown }) => {
    const response = await getFieldTypes(params);
    if (!response) return { data: [] as OptionsType, last_page: 1 };

    return {
      data: response?.data.map((type: DTOContractTypeFieldType) => ({
        key: type.id,
        value: type.id,
        label: type.name,
      })) as OptionsType,
      last_page: response.last_page,
    };
  };

  const destroyField = async (id: string, params = {}) => {
    sendRequest(() => contractApi.types.fields.destroy(id, params));
  };

  const getTemplates = async (typeId: string, params = {}) => {
    const response = await handleRequest(() => api.get(`/contract/type/${typeId}/get-templates`, params));
    return response;
  };

  return {
    isFetching,
    getFieldTypes,
    getFieldTypesOptions,
    destroyField,
    isStoring,
    getTypes,
    storeType,
    updateType,
    deleteType,
    showType,
    getTypesOptions,
    getTemplates,
    isFetchingTemplates,
    getFieldTypesNoPagination,
    getFieldTypesOptionsNoPagination,
  };
};

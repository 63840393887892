import React, { useRef } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import { Col, RadioChangeEvent, Row } from 'antd';
import { SelectValue } from 'antd/lib/select';
import { CONTRACT_TYPE_FIELD_TYPE_TABLE } from 'configs/constants';
import { PropNameOptions } from 'pages/Contracts/ContractTypes/controllers/types';
import { OptionsType } from 'rc-select/lib/interface';

import type { DTOContractTypeField } from '../../../../types/Contract/DTOContractTypeField';
import { ContractTypeField } from '../ContractTypeField';

interface Props {
  field: DTOContractTypeField;
  addNewFieldToTable: (tableFieldId: string) => void;
  handleChangeField: (
    propName: PropNameOptions,
    { target }: RadioChangeEvent | React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>,
    id: string,
    parentId?: string,
  ) => void;
  handleChangeSelectField: (propName: PropNameOptions, value: SelectValue, id: string, parentId?: string) => void;
  removeFieldFromTable: (tableFieldId: string, childFieldId: string) => void;
  fieldTypes: OptionsType;
  updateField: (field: DTOContractTypeField) => void;
}

export const TableFieldList = ({
  field,
  addNewFieldToTable,
  handleChangeField,
  handleChangeSelectField,
  removeFieldFromTable,
  fieldTypes,
  updateField,
}: Props) => {
  const divRef = useRef<HTMLDivElement>();

  return (
    <DragDropContext
      onDragEnd={result => {
        const newColumns = [...field.columns];
        newColumns.splice(result.destination.index, 0, newColumns.splice(result.source.index, 1)[0]);
        updateField({ ...field, columns: newColumns.map((column, index) => ({ ...column, order: index })) });
      }}
    >
      <Droppable droppableId="contractTypeFieldsTable">
        {provided => (
          <>
            <fieldset {...provided.droppableProps} ref={provided.innerRef}>
              <div ref={divRef} style={{ height: 'auto' }}>
                {field.columns.map((column, index) => (
                  <Row key={column.id} style={{ paddingLeft: '30px' }}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                      <ContractTypeField
                        updateField={updateField}
                        addNewFieldToTable={addNewFieldToTable}
                        field={column}
                        handleChangeField={handleChangeField}
                        handleChangeSelectField={handleChangeSelectField}
                        removeFieldFromTable={removeFieldFromTable}
                        isChildren
                        parentId={field.id}
                        fieldTypes={fieldTypes.filter(fieldType => fieldType.value !== CONTRACT_TYPE_FIELD_TYPE_TABLE)}
                        index={index}
                      />
                    </Col>
                  </Row>
                ))}
              </div>
            </fieldset>
            {provided.placeholder}
          </>
        )}
      </Droppable>
    </DragDropContext>
  );
};

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { Form, Spin } from 'antd';
import { Store } from 'antd/lib/form/interface';
import queryString from 'query-string';

import DTOErrorReponse from '../../../@types/dtos/DTOErrorReponse';
import { Button, Input, FormItem, Password, ModalStatus } from '../../../components';
import AuthPage from '../../../components/AuthPage';
import { usePage } from '../../../hooks/page';
import { usePasswordValidator } from '../../../hooks/usePasswordValidator';
import authService from '../../../services/api/auth';

const Recover: React.FC = () => {
  const location = useLocation();
  const params = queryString.parse(location.search);

  const passwordValidator = usePasswordValidator();

  const email: string = params.email ? params.email.toString() : '';
  const token: string = params.token ? params.token.toString() : '';

  const { t } = useTranslation();
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const { alertStatus } = usePage();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (token.length <= 0) {
      navigate('/error');
    }
  }, [token, navigate]);

  const onFinish = (values: Store) => {
    setLoading(true);

    authService
      .recoverPassword({
        ...values,
        token: params.token,
      })
      .then(response => {
        ModalStatus({
          type: 'success',
          title: t('modal.success'),
          subTitle: response.data.message,
          onOk: () => navigate('/login'),
        });
      })
      .catch((err: DTOErrorReponse) => alertStatus(err, 'error'))
      .finally(() => setLoading(false));
  };

  return (
    <AuthPage loading={loading}>
      <Spin spinning={loading}>
        <Form form={form} initialValues={{ email }} onFinish={onFinish} className="form-primary">
          <FormItem
            label={t('recover.form.email')}
            name="email"
            rules={[
              {
                required: true,
                message: t('recover.required.email'),
              },
            ]}
          >
            <Input readOnly />
          </FormItem>
          <FormItem
            label={t('recover.form.new_password')}
            name="password"
            rules={[
              {
                required: true,
                message: t('recover.required.password'),
              },
              ...passwordValidator,
            ]}
            hasFeedback
          >
            <Password />
          </FormItem>
          <FormItem
            label={t('recover.form.confirm_password')}
            dependencies={['password']}
            name="confirm"
            rules={[
              {
                required: true,
                message: t('recover.required.confirm'),
              },
              ({ getFieldValue }: any) => ({
                validator(rule: any, value: any) {
                  return !value || getFieldValue('password') === value
                    ? Promise.resolve()
                    : Promise.reject(t('recover.required.equals'));
                },
              }),
            ]}
            hasFeedback
          >
            <Password />
          </FormItem>
          <FormItem distance="small">
            <div className="button">
              <Button block htmlType="submit">
                {t('recover.form.submit')}
              </Button>
            </div>
          </FormItem>
        </Form>
      </Spin>
    </AuthPage>
  );
};

export default Recover;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { GiHand } from 'react-icons/gi';

import appConfigs from 'configs/app';
// import { useGuarantees } from 'hooks/fetch/useGuarantees';
import moment from 'moment';
import { Formatter } from 'utils/Formatter';

import { Container } from './styles';
import { DatasulStatusProps } from './types';

const DatasulStatusText = ({ date, showTime }: { date: string; showTime: boolean }) => {
  const { t } = useTranslation();

  const formattedDate = showTime ? moment(date).format(appConfigs.formatDateFullTime) : Formatter.date(date);

  return (
    <span
      style={{
        width: '100%',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '5px',
      }}
      title={moment(date).format(appConfigs.formatDateFullTime).toString()}
    >
      <span style={{ display: 'flex' }}>
        <GiHand style={{ color: 'green' }} />
      </span>
      <span>{`${t('pages.credit-request.kanban.cards.datasul_integration_date')} ${formattedDate}`}</span>
    </span>
  );
};

export const DatasulStatus = ({ datasulStatus, datasulStatusUpdatedAt, showTime }: DatasulStatusProps) => {
  return (
    <Container data-status={datasulStatus}>
      <DatasulStatusText date={datasulStatusUpdatedAt} showTime={showTime} />
    </Container>
  );
};

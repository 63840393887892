import { Radio } from 'antd';
import styled from 'styled-components';

export const Title = styled.h4`
  color: ${({ theme: { colors } }) => colors.primary};
  display: flex;
  gap: 10px;
  align-items: center;
  padding-bottom: 20px;
`;

export const Body = styled.div`
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
`;

export const RadioGroup = styled(Radio.Group)`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background: none !important;
`;

export const DraftEmissionSelectedOptions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;

  .ant-spin-container,
  .ant-spin-nested-loading {
    width: 100%;
    height: 100%;
  }
`;

/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AiFillFileText } from 'react-icons/ai';

import { Modal } from 'antd';

import theme from '../../../../styles/theme';
import { Container } from './styles';

interface Props {
  isOpen: boolean;
  close: () => void;
}

export const SignatureSuccessModal = ({ isOpen, close }: Props) => {
  const { t } = useTranslation();
  return (
    <Modal visible={isOpen} footer={null} onCancel={close}>
      <Container>
        <h2>{t('pages.edocuments.emission.signature.signaturesSentSuccess')}</h2>
        <AiFillFileText size={64} color={theme.colors.primary} />
        <p>{t('pages.edocuments.emission.signature.signaturesSentSuccessDescription')}</p>
        <a onClick={close}>{t('pages.edocuments.emission.signature.signaturesSentSuccessOk')}</a>
      </Container>
    </Modal>
  );
};

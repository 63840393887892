import { useState } from 'react';

import { ContractSubscriberData } from 'types/Contract/ContractSubscriberData';

import api, { handleRequest } from '../../services/api/api';

export const useDraftSignatures = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [isChangingStatus, setIsChangingStatus] = useState(false);
  const [isValidating, setIsValidating] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [isSyncing, setIsSyncing] = useState(false);
  const [isFetchingTypes, setIsFetchingTypes] = useState(false);
  const [isDestroyingSubscriber, setIsDestroyingSubscriber] = useState(false);

  const send = async (contractId: string, subscribers: any[], type: 'digital' | 'manual' | '', params = {}) =>
    handleRequest(
      () =>
        api.post(`contract/${contractId}/subscriber/store`, { subscribers, is_digital: type === 'digital' }, params),
      setIsFetching,
    );

  const sendDocusign = async (contractId: string, signatures: { subscribers: any[] }, type: string, params = {}) =>
    handleRequest(
      () => api.post(`contract/${contractId}/send`, { signatures, is_digital: type === 'digital' }, params),
      setIsFetching,
    );

  const changeSignatureStatus = async (
    id: string,
    status: string | null,
    params = {},
  ): Promise<ContractSubscriberData | null> =>
    handleRequest(
      () => api.post(`/contract/subscriber/${id}/changestatus`, { signed_at: status, ...params }),
      setIsChangingStatus,
    );

  const validateSignatures = async (
    contractId: string,
    stage: 'after' | 'before',
    action: 'approval' | 'refuse',
    payload = {},
  ) =>
    handleRequest(
      () => api.post(`/contract/${contractId}/validatecontract`, { stage, action, ...payload }),
      setIsValidating,
    );

  const resend = async (contractId: string) =>
    handleRequest(() => api.post(`/contract/${contractId}/resend`), setIsSending);

  const getTypes = async () => {
    const response = await handleRequest(() => api.get('/signature-type/all'), setIsFetchingTypes);
    return response?.data ?? [];
  };

  const sync = (contractId: string) => handleRequest(() => api.post(`/contract/${contractId}/sync`), setIsSyncing);

  const getTypesOptions = async () => {
    const types = await getTypes();
    return types.map((type: any) => ({
      value: type.id,
      key: type.id,
      label: type.name,
    }));
  };

  const destroySubscriber = async (subscriberId: string) => {
    handleRequest(() => api.delete(`/contract/subscriber/${subscriberId}/destroy`), setIsDestroyingSubscriber);
  };

  return {
    send,
    isFetching,
    changeSignatureStatus,
    isChangingStatus,
    validateSignatures,
    isValidating,
    resend,
    isSending,
    sendDocusign,
    isFetchingTypes,
    getTypesOptions,
    destroySubscriber,
    isDestroyingSubscriber,
    sync,
    isSyncing,
  };
};

import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormItem, Input } from 'components';

interface Props {
  isProvider?: boolean;
}

export const RequesterNameInput = ({ isProvider }: Props) => {
  const { t } = useTranslation();

  return (
    <FormItem
      label={
        isProvider ? t(`pages.credit-request.form.provider`) : t(`pages.credit-request.form.requester_person_name`)
      }
      name="requester_person_name"
      rules={[{ required: true }]}
    >
      <Input disabled />
    </FormItem>
  );
};

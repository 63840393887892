import React from 'react';
import { useTranslation } from 'react-i18next';

import { Col } from 'antd';
import { FormItem, Input } from 'components';
import { useCreditRequestStore } from 'pages/CreditRequest/store/useCreditRequestStore';

export const NumberInput = () => {
  const { t } = useTranslation();

  const pageStatus = useCreditRequestStore(state => state.pageStatus);

  return !pageStatus.new ? (
    <>
      {/* Number */}
      <Col xs={24} sm={18} md={12} lg={8} xl={6} xxl={6}>
        <FormItem label={t('pages.credit-request.form.number')} name="number">
          <Input disabled />
        </FormItem>
      </Col>
    </>
  ) : null;
};

import React from 'react';
import { useTranslation } from 'react-i18next';

import i18n from 'services/i18next';
import { useTheme } from 'styled-components';

interface Props {
  counterparty: any;
  statusColor?: string;
}

export const RestrictionsModalTitle = ({ counterparty, statusColor }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <div>
      {counterparty.name} ({counterparty.doc_number}) -{' '}
      <span style={{ color: statusColor }}>
        {counterparty.status?.translations[i18n.language].title?.toUpperCase() ?? t('notProvided')}
      </span>{' '}
      -{' '}
      {counterparty.is_judicial_recuperation ? (
        <span style={{ textTransform: 'uppercase', color: theme.colors.danger }}>{t('in_recovery')}</span>
      ) : null}
    </div>
  );
};

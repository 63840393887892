import styled from 'styled-components';

import { Button, FormItem } from '../../components';

export const SForm = styled.div`
  display: flex;
`;

export const SItemSearch = styled(FormItem)`
  &.ant-form-item {
    margin: 0;
    padding: 0;

    .ant-input {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
  }
`;

export const SButton = styled(Button)`
  &.btn-search {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
  }
`;

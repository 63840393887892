import React from 'react';
import { useTranslation } from 'react-i18next';

import { DatePicker } from 'antd';
import ptBR from 'antd/es/date-picker/locale/pt_BR';
import { FormItem } from 'components';
import appConfigs from 'configs/app';
import { useCreditRequestContext } from 'context/CreditRequestContext';
import moment from 'moment';
import { useCreditRequestStore } from 'pages/CreditRequest/store/useCreditRequestStore';

export const JsonDataWithdrawalPeriodInput = () => {
  const { t } = useTranslation();
  const { creditForm } = useCreditRequestContext();

  const setIsBlocking = useCreditRequestStore(state => state.setIsBlocking);
  const pageStatus = useCreditRequestStore(state => state.pageStatus);

  return (
    <FormItem
      label={t('pages.credit-request.form.json_data_withdrawal_period')}
      name="json_data.withdrawal_period"
      rules={[{ required: true }]}
    >
      <DatePicker
        locale={ptBR}
        mode="date"
        format={appConfigs.formatDate}
        disabled={pageStatus.viewing}
        onChange={value => {
          if (value) {
            setIsBlocking(true);
          }
        }}
        disabledDate={current => {
          const startWithdrawalPeriod = creditForm.getFieldValue('start_withdrawal_deadline');
          if (!startWithdrawalPeriod) return false;

          return (
            moment(startWithdrawalPeriod).add(0, 'day') >= current ||
            moment(startWithdrawalPeriod).add(31, 'day') <= current
          );
        }}
      />
    </FormItem>
  );
};

import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  bottom: 0;
  left: 0;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
  transition: opacity 200ms ease-in-out;
  opacity: 0;
  user-select: none;
`;

/* eslint-disable import/no-unresolved */
/* eslint-disable global-require */
import React from 'react';
import { useTranslation } from 'react-i18next';

import logoClient from '../../../assets/img/client/logo.png';
import logoCompany from '../../../assets/img/company/logo.png';
import brazil from '../../../assets/img/flags/brazil.png';
import china from '../../../assets/img/flags/china.png';
import us from '../../../assets/img/flags/us.png';
import appConfigs from '../../../configs/app';

function AuthPage({ show, children }: any) {
  const { t, i18n } = useTranslation();
  const languages = [
    { key: 'en', value: 'en', label: t('header.lang.en'), flag: us },
    { key: 'pt-br', value: 'pt-br', label: t('header.lang.pt-br'), flag: brazil },
    { key: 'cn', value: 'cn', label: t('header.lang.cn'), flag: china },
  ];

  const isClient = process.env.REACT_APP_IS_CLIENT;

  return (
    <div className="col col-content">
      {show.includes('languages') && (
        <div className="languages">
          {languages.map(language => (
            <button
              key={language.key}
              type="button"
              className={`language-item ${
                i18n.language === language.value
                  ? 'language-active'
                  : `${language.value === 'cn' ? 'language-inactive' : ''}`
              }`}
              onClick={() => i18n.changeLanguage(language.value)}
            >
              <img alt={language.label} src={language.flag} />
            </button>
          ))}
        </div>
      )}
      {show.includes('client_logo') && (
        <div className="company-logo">
          <a href="http://agrodata.agr.br/" title="Agrodata" rel="noopener noreferrer" target="_blank">
            <img src={isClient ? logoClient : logoCompany} alt={appConfigs.companyTitle} />
          </a>
        </div>
      )}

      {show.includes('form') && <div className="form">{children}</div>}
      {show.includes('company_logo') && (
        <div className="company-logo">
          <a href="http://agrodata.agr.br/" title="Agrodata" rel="noopener noreferrer" target="_blank">
            <img src={logoCompany} alt={appConfigs.companyTitle} />
          </a>
        </div>
      )}
    </div>
  );
}

export default AuthPage;

import { Form } from 'antd';
import { TablePaginationConfig } from 'antd/lib/table';

import { PHASE2_PRE_REGISTRATION, PHASE3_POST_REGISTRATION } from '../../../../configs/constants';
import { useDashboardWarrantyPrefin } from '../../../../hooks/fetch/useDashboardWarrantyPrefin';
import { useDashboards } from '../../hooks/useDashboards';
import { PropsFilterTimeline } from './types';

export const useWarrantyPrefin = () => {
  const {
    getWarrantyPrefinCards,
    getWarrantyPrefinCharts,
    getWarrantyPrefinTimelineRegister,
    getWarrantyPrefinTimelineSignature,
    getTimelineReport,
    generateExportTimeline,
    isFetchingTimelineSignature,
    isFetchingTimelineRegister,
    isFetchingCharts,
    isFetchingCards,
  } = useDashboardWarrantyPrefin();

  const { handleCards, handleCharts, handleChangeTable } = useDashboards();

  const [filterForm] = Form.useForm();
  const [searchFormTimelineSignature] = Form.useForm();
  const [searchFormTimelineRegister] = Form.useForm();

  const handleCardsWarrantyPrefin = async () => {
    const response = await handleCards(getWarrantyPrefinCards, filterForm);
    return response;
  };

  const handleChartsWarrantyPrefin = async () => {
    const response = await handleCharts(getWarrantyPrefinCharts, filterForm);
    const { dataChartCreditWarrantyOperation, dataChartRequestInProgressWarranty, dataCardChartWarranty } = response;
    return { dataChartCreditWarrantyOperation, dataChartRequestInProgressWarranty, dataCardChartWarranty };
  };

  const handleChangeTableTimelineRegister = async (
    pagination: TablePaginationConfig,
    filters: PropsFilterTimeline,
    sorter: any,
  ) => {
    if (Object.keys(filters).length) {
      if (filters.phases === undefined) filters.phases = [PHASE2_PRE_REGISTRATION, PHASE3_POST_REGISTRATION];
      const response = await handleChangeTable(pagination, filters, sorter, getWarrantyPrefinTimelineRegister);
      return response;
    }
    return { data: [], total: 0 };
  };

  const handleChangeTableTimelineSignature = async (pagination: TablePaginationConfig, filters: any, sorter: any) => {
    if (Object.keys(filters).length) {
      const response = await handleChangeTable(pagination, filters, sorter, getWarrantyPrefinTimelineSignature);
      return response;
    }
    return { data: [], total: 0 };
  };

  return {
    handleCardsWarrantyPrefin,
    handleChartsWarrantyPrefin,
    handleChangeTableTimelineRegister,
    handleChangeTableTimelineSignature,
    getTimelineReport,
    generateExportTimeline,
    isFetchingTimelineSignature,
    isFetchingTimelineRegister,
    isFetchingCharts,
    isFetchingCards,
    filterForm,
    searchFormTimelineSignature,
    searchFormTimelineRegister,
  };
};

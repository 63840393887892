import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Form } from 'antd';
import { TablePaginationConfig } from 'antd/lib/table';

import { ModalStatus } from '../../../../components';
import { useSustainabilityRule } from '../../../../hooks/fetch/useSustainabilityRule';
import { useModalMessage } from '../../../../hooks/helpers/useModalMessage';
import { RulesProps } from './types';

export const useRule = ({ refresh }: RulesProps) => {
  const { t } = useTranslation();
  const { getRule, isFetching, isFetchingForm, post, update, remove, getStatusList, getPositionsList } =
    useSustainabilityRule();
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [searchFilters, setSearchFilters] = useState<object>();
  const [form] = Form.useForm();
  const { handleSuccess } = useModalMessage();

  const handleTableChange = async (pagination: TablePaginationConfig, filters: object, sorter: any) => {
    const search: any = {};
    const { data, total } = await getRule({
      params: {
        page: pagination.current,
        per_page: pagination.pageSize,
        sort: sorter.sort,
        direction: sorter.direction,
        ...filters,
        ...search,
      },
    });

    return { data, total };
  };

  const toggleForm = () => {
    setIsFormOpen(!isFormOpen);
    form.resetFields();
  };

  const handleEdit = (record: any, setDataForm: any) => {
    setIsFormOpen(true);
    setDataForm(record);
  };

  const handleRemove = async (id: string) => {
    ModalStatus({
      type: 'confirm',
      title: t('pages.sustainability.team-status.actions.confirm-delete'),
      onOk: () => removeItem(id),
    });
  };

  const removeItem = async (record: any) => {
    const response = await remove(record.id);
    if (response) {
      handleSuccess(t('pages.sustainability.team-status.actions.message_success'));
      refresh();
    }
  };

  const handleSave = async (data: any) => {
    let response;
    const newData = {
      sustainability_report_status_id: data.status_origin,
      sustainability_report_status_next_id: data.status_next,
      is_attachment_required: data.is_attachment_required,
      is_comments_required: data.is_comments_required,
      position_id: data.position,
      id: data.id,
    };
    if (newData.id) response = await update(newData);
    else response = await post(newData);
    if (response) {
      handleSuccess(t('pages.sustainability.team-status.actions.message_success'));
      setIsFormOpen(false);
      form.resetFields();
      refresh();
    }
  };

  const handleStatus = async (value: string, page: number) => {
    const response = await getStatusList({ params: { search: value, page } });
    return { data: response.data, lastPage: response.lastPage };
  };

  const handlePositions = async (value: string, page: number) => {
    const response = await getPositionsList({ params: { search: value, page } });
    return { data: response.data, lastPage: response.lastPage };
  };

  return {
    handleTableChange,
    handleEdit,
    handleRemove,
    isFetching,
    isFetchingForm,
    toggleForm,
    isFormOpen,
    setIsFormOpen,
    form,
    handleSave,
    handleStatus,
    handlePositions,
    searchFilters,
    setSearchFilters,
  };
};

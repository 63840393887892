import React from 'react';
import { useTranslation } from 'react-i18next';
import { MdClose } from 'react-icons/md';

import { Modal } from 'antd';
import { Button } from 'components';
import { GuaranteeStatus } from 'configs/constants';

import { GuaranteeApprovalModalProps } from './GuaranteeApprovalModal.types';

export const GuaranteeApprovalModal = ({
  beforeClose,
  close,
  isVisible,
  onSubmit,
  children,
  statusId,
}: GuaranteeApprovalModalProps) => {
  const { t } = useTranslation();

  const handleClose = () => {
    beforeClose();
    close();
  };

  return (
    <Modal
      visible={isVisible}
      width="60%"
      closeIcon={<MdClose onClick={handleClose} />}
      footer={
        <>
          <div className="buttons">
            <Button
              status="primary"
              permission={statusId === GuaranteeStatus.APPROVED ? 'credit.request.partial.limit.approval' : ''}
              onClick={onSubmit}
            >
              {statusId === GuaranteeStatus.APPROVED ? t('pages.credit-request.modal-limit.buttonApprove') : null}
              {statusId === GuaranteeStatus.REPROVED ? t('pages.credit-request.modal-limit.buttonReprove') : null}
              {statusId === GuaranteeStatus.VALIDATED ? t('pages.credit-request.modal-limit.buttonValidate') : null}
              {statusId === GuaranteeStatus.REJECTED ? t('pages.credit-request.modal-limit.buttonReject') : null}
            </Button>
            <Button status="secondary" onClick={handleClose}>
              {t('pages.credit-request.modal-reports.buttons.close')}
            </Button>
          </div>
        </>
      }
    >
      {children}
    </Modal>
  );
};

import { Dispatch } from 'react';

import axios from 'axios';
import { getURL } from 'configs/apiURL';

import { SESSION_USER_TOKEN } from '../../configs/storage';
import exceptionHandler from '../exceptions';
import i18n from '../i18next';

const api = axios.create({
  baseURL: process.env.REACT_APP_ENVIRONMENT === 'local' ? getURL() : process.env.REACT_APP_API_BASE_URL,
  // headers: {
  //   'Content-Type': 'application/x-www-form-urlencoded',
  // },
  timeout: 300000,
});

api.interceptors.request.use(async config => {
  const token = localStorage.getItem(SESSION_USER_TOKEN);

  config.params = config.params || {};
  config.params.language = i18n.language;
  const configs = config;

  if (token) {
    configs.headers.Authorization = `Bearer ${token}`;
  }

  return { ...configs };
});

export const handleRequest = async (
  request: any,
  loadingCallback: Dispatch<React.SetStateAction<boolean>> = () => false,
) => {
  try {
    loadingCallback(true);
    const response = await request();
    return response.data;
  } catch (err) {
    exceptionHandler(err);
    return null;
  } finally {
    loadingCallback(false);
  }
};

export default api;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { MdClose } from 'react-icons/md';

import { Modal } from 'antd';
import { Button } from 'components';

import { NewGuaranteeModalProps } from './NewGuaranteeModal.types';

export const NewGuaranteeModal = ({
  isVisible,
  close,
  beforeClose,
  children,
  onSubmit,
  isSubmitDisabled,
}: NewGuaranteeModalProps) => {
  const { t } = useTranslation();

  const handleCloseModal = () => {
    beforeClose();
    close();
  };

  return (
    <Modal
      visible={isVisible}
      width="60%"
      closeIcon={<MdClose onClick={handleCloseModal} />}
      footer={
        <>
          <div className="buttons">
            <Button status="primary" disabled={isSubmitDisabled} onClick={onSubmit}>
              {t('pages.credit-request.modal-limit.buttonAddConfirm')}
            </Button>
            <Button status="secondary" onClick={handleCloseModal}>
              {t('pages.credit-request.modal-reports.buttons.close')}
            </Button>
          </div>
        </>
      }
    >
      {children}
    </Modal>
  );
};

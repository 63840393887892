import React, { forwardRef, useEffect, useImperativeHandle } from 'react';
import { useForm, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Form } from 'components/ui/form';
import { FormItem } from 'components/ui/form-item';
import { GroupForm } from 'components/ui/group-form';
import { Input, TextArea } from 'components/ui/input';
import { useAuth } from 'hooks/auth';
import moment from 'moment';
import { v4 as uuid } from 'uuid';

export type CommentsFormRef = {
  form: ReturnType<typeof useForm>;
  submit: () => void;
};

export const CommentsForm = forwardRef((_, ref) => {
  const { user } = useAuth();
  const { t } = useTranslation();

  const { setValue, getValues } = useFormContext();

  const initialValues = {
    dt_comment: moment().format('YYYY-MM-DD'),
    registered_by: user.name,
    comment: '',
  };

  const form = useForm({
    defaultValues: initialValues,
  });

  const submit = () => {
    form.handleSubmit(async data => {
      const { comments } = getValues();
      const newComments = [
        ...(comments || []),
        {
          ...data,
          id: uuid(),
          isNew: true,
          user: {
            id: user.id,
            name: user.name,
          },
          created_at: moment().format('YYYY-MM-DD'),
        },
      ];
      setValue('comments', newComments);
    })();
  };

  useImperativeHandle(ref, () => ({
    form,
    submit,
  }));

  useEffect(() => {
    return () => {
      form.reset();
    };
  }, []);

  return (
    <Form form={form} onSubmit={console.log}>
      <GroupForm
        title={t(
          'pages.credit-request.formalization-guarantees.pre-registration-docs.modalDocument.modalComments.commentData',
        )}
      >
        <FormItem name="registered_by" hidden>
          <Input />
        </FormItem>
        <FormItem name="dt_comment" hidden>
          <Input />
        </FormItem>
        <FormItem
          label={t('pages.credit-request.formalization-guarantees.pre-registration-docs.modalDocument.comment')}
          name="comment"
          rules={{ required: true }}
        >
          <TextArea maxLength={5000} />
        </FormItem>
      </GroupForm>
    </Form>
  );
});

CommentsForm.displayName = 'CommentsForm';

import { Form } from 'antd';
import { TablePaginationConfig } from 'antd/lib/table';

import { OPERATION_PREFIN_ID } from '../../../../configs/constants';
import { useDashboardRequestPrefin } from '../../../../hooks/fetch/useDashboardRequestPrefin';
import { useDashboards } from '../../hooks/useDashboards';
import { PropsFilterTimeline } from './types';

export const useRequestPrefin = () => {
  const {
    isFetchingCharts,
    isFetchingCards,
    isFetchingTimeline,
    getRequestPrefinCards,
    getRequestPrefinCharts,
    getRequestPrefinTimeline,
    getTimelineReport,
    generateExportTimeline,
  } = useDashboardRequestPrefin();

  const { handleCards, handleCharts, handleChangeTable } = useDashboards();

  const [filterForm] = Form.useForm();
  const [searchFormTimeline] = Form.useForm();

  const handleCardsRequestsPrefin = async () => {
    const response = await handleCards(getRequestPrefinCards, filterForm);
    return response;
  };

  const handleChartsRequestsPrefin = async () => {
    const response = await handleCharts(getRequestPrefinCharts, filterForm);
    const { dataChartCredit, dataChartRequestInProgres, dataChartOperationModality } = response;
    return { dataChartCredit, dataChartRequestInProgres, dataChartOperationModality };
  };

  const handleChangeTableTimeline = async (
    pagination: TablePaginationConfig,
    filters: PropsFilterTimeline,
    sorter: any,
  ) => {
    if (Object.keys(filters).length) {
      filters.operation = OPERATION_PREFIN_ID;
      const response = await handleChangeTable(pagination, filters, sorter, getRequestPrefinTimeline);
      return response;
    }
    return { data: [], total: 0 };
  };

  return {
    handleCardsRequestsPrefin,
    handleChartsRequestsPrefin,
    handleChangeTableTimeline,
    getTimelineReport,
    generateExportTimeline,
    isFetchingCharts,
    isFetchingCards,
    isFetchingTimeline,
    filterForm,
    searchFormTimeline,
  };
};

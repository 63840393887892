import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaPlus, FaQuestionCircle } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';
import { useNavigate, useParams, Link, useLocation } from 'react-router-dom';

import { downloadHandler, DTOErrorResponse, Formatter, InfiniteSelectAsync, Select } from '@agrodatabr/agrodataui';
import { GoogleMap, Marker, Polygon, useLoadScript } from '@react-google-maps/api';
import { Badge, Col, DatePicker, Form, InputNumber, Modal, Radio, Slider, Spin, Row, Descriptions } from 'antd';
import ptBR from 'antd/es/date-picker/locale/pt_BR';
import TextArea from 'antd/lib/input/TextArea';
import Dragger from 'antd/lib/upload/Dragger';
import { FilePreviewer } from 'components/FilePreviewer';
import PermissionedComponent from 'components/PermissionedComponent';
import queryString from 'query-string';
import { OptionData, OptionGroupData, OptionsType } from 'rc-select/lib/interface';
import { useTheme } from 'styled-components';
import { v4 as uuid } from 'uuid';

import DTOErrorReponse from '../../../../@types/dtos/DTOErrorReponse';
import DTORegistrationModel from '../../../../@types/dtos/monitoring/DTORegistrationModel';
import DTOSurvey from '../../../../@types/dtos/monitoring/DTOSurvey';
import { DTOSurveyCprModel } from '../../../../@types/dtos/monitoring/DTOSurveyCprModel';
import { DTOSurveyFields } from '../../../../@types/dtos/monitoring/DTOSurveyFields';
import { Breadcrumb, Button, FormItem, IconWithTooltip, Input, ModalStatus } from '../../../../components';
import { SurveyRequestModal } from '../../../../components/SurveyRequestModal';
import { TableData } from '../../../../compositions';
import appConfigs from '../../../../configs/app';
import { SURVEY_APPROVAL_STATUS_APPROVED } from '../../../../configs/constants';
import { Can, useAbility } from '../../../../hooks/ability';
import { useCache } from '../../../../hooks/cache';
import { useCpr } from '../../../../hooks/fetch/useCpr';
import { useCrop } from '../../../../hooks/fetch/useCrop';
import { useDocuments } from '../../../../hooks/fetch/useDocuments';
import { useOperator } from '../../../../hooks/fetch/useOperator';
import { useRegistration } from '../../../../hooks/fetch/useRegistration';
import { useSurvey } from '../../../../hooks/fetch/useSurvey';
import { useDownload } from '../../../../hooks/helpers/useDownload';
import { useModalMessage } from '../../../../hooks/helpers/useModalMessage';
import { useSurveyStatusInfoModal } from '../../../../hooks/modals/useSurveyStatusInfoModal';
import { ItemsType, usePage } from '../../../../hooks/page';
import { useCprFields } from '../../../../hooks/useCprFields';
import documentApi from '../../../../services/api/document';
import monitoringApi from '../../../../services/api/monitoring';
import { downloadDocument, getBase64, getOriginalName } from '../../../../services/files';
import { DocumentModelData } from '../../../../types/Document/DocumentModelData';
import { Coordinate } from '../../../../utils/Coordinate';
import columns from '../columns';
import { NodeParam } from '../types';
import { SFormButtons, SFormContainer } from './styles';
import { Params } from './types';

const initialVisibilitySubforms = {
  documents: null,
} as { documents: boolean };

const defaultMapPosition = {
  lat: -11.19504003082751,
  lng: -45.90291406160487,
};

export const Update = () => {
  const [form] = Form.useForm();
  const { t, i18n } = useTranslation();
  const { info } = useSurveyStatusInfoModal();
  const { alertStatus } = usePage();
  const { getPersonOptions } = useCache();
  const navigate = useNavigate();
  const { handleSuccess } = useModalMessage();
  const theme = useTheme();
  const location = useLocation();

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_MAP_KEY}`,
  });

  const { getSurveyCprById: getCprById, isFetching: isFetchingCpr } = useCpr();
  const {
    getTypes: getSurveyTypes,
    update: updateSurvey,
    store: storeSurvey,
    find: getSurvey,
    getStageOptions,
  } = useSurvey();
  const { getConditionsOptions: getCropConditions } = useCrop();
  const { downloadAll } = useDownload();
  const { getTypesOptions: getDocumentTypes } = useDocuments();
  const { get: getRegistration } = useRegistration();
  const { cpr: cprId, registration: registrationId, survey: surveyId } = useParams<Params>();
  const ability = useAbility();

  const [loading, setLoading] = useState(false);
  const [cpr, setCpr] = useState<DTOSurveyCprModel | undefined>({} as DTOSurveyCprModel);
  const [registration, setRegistration] = useState<DTORegistrationModel | undefined>();
  const { getFields, isFetching: isFetchingFields } = useCprFields();
  const [typesSurvey, seTypesSurvey] = useState<ItemsType[]>([]);
  const [cropConditions, setCropConditions] = useState<ItemsType[]>([]);
  const [cultures, setCultures] = useState<ItemsType[]>([]);
  const [selectedFileToPreview, setSelectedFileToPreview] = useState<DocumentModelData | undefined>(undefined);

  const [cprFields, setCprFields] = useState({});
  const [selectedFoundCultureId, setSelectedFoundCultureId] = useState('');

  const [loadingButton, setLoadingButton] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);
  const [degrees, setDegrees] = useState(true);
  const [plantingWindow, setPlantingWindow] = useState('');
  const [harvestData, setHarvestData] = useState({} as { [name: string]: number });
  const [subforms, setSubforms] = useState(null);
  const [visibilitySubforms, setVisibilitySubforms] = useState(initialVisibilitySubforms);
  const [alreadySelectedTypeFile, setAlreadySelectedTypeFile] = useState(false);
  const [alreadySelectedFile, setAlreadySelectedFile] = useState(false);
  const [editing, setEditing] = useState(false);
  const [documents_form] = Form.useForm();
  const [documents, setDocuments] = useState([]);
  const [registerUser, setRegisterUser] = useState<any>({});
  const [documentTypes, setDocumentTypes] = useState<ItemsType[]>([]);
  const [surveyStatusId, setSurveyStatusId] = useState(null);
  const { getOptions: getOperators, isFetching: isFetchingOperators } = useOperator();
  const [isShowingSurveyRequestModal, setIsShowingSurveyRequestModal] = useState(false);
  const [mapPosition, setMapPosition] = useState(defaultMapPosition);
  const [zoom] = useState(10);
  const [kmls, setKmls] = useState<any[]>([]);
  const [markerPosition, setMarkerPosition] = useState<{ lat?: number; lng?: number }>({
    lat: undefined,
    lng: undefined,
  });

  const [subformsLoading, setSubformsLoading] = useState({
    documents: { editing: null, removing: null, form: null, other: null },
  });

  const attachments: any[] = [];
  const search = queryString.parse(location.search);
  const [surveyRequestId, setSurveyRequestId] = useState<string | null>(
    search.survey_request ? search.survey_request.toString() : null,
  );
  const surveyTypeId: string | null = search.survey_type ? search.survey_type.toString() : null;
  const surveyStageId: string | null = search.survey_stage ? search.survey_stage.toString() : null;
  const surveyOperatorId: string | null = search.operator_id ? search.operator_id.toString() : null;

  const handleChangeHarvestData = (key: string, value: string | number) => {
    setHarvestData(oldHarvestData => ({ ...oldHarvestData, [key]: Number(value) }));
    form.setFieldsValue({ [key]: value });
  };

  const handleChangeLatitude = (e: React.ChangeEvent<HTMLInputElement>) => {
    const lat = e.target.value ?? undefined;
    setMarkerPosition(oldMarkerPosition => ({ ...oldMarkerPosition, lat: Number(lat) }));
  };

  const handleChangeLongitude = (e: React.ChangeEvent<HTMLInputElement>) => {
    const lng = e.target.value ?? undefined;
    setMarkerPosition(oldMarkerPosition => ({ ...oldMarkerPosition, lng: Number(lng) }));
  };

  /**
   * Faz a ação de abrir um modal de subform de acordo com o 'node' passado
   * @param node NodeParam
   */
  const subformsOpen = (node: NodeParam) => {
    subforms[node].resetFields();
    setVisibilitySubforms(curState => ({ ...curState, [node]: true }));
  };

  const loadSurveyData = async () => {
    if (surveyId) {
      setLoading(true);
      setLoadingButton(true);
      setDisabledButton(true);

      const survey: DTOSurvey = await getSurvey(surveyId, {
        params: {},
      });
      if (survey) {
        setSurveyStatusId(survey.status_id);
        form.setFieldsValue({
          ...survey,
          documents: survey.documents,
          planting_start: survey.planting_start ? Formatter.date(survey.planting_start, true) : null,
          harvest_forecast: survey.harvest_forecast ? Formatter.date(survey.harvest_forecast, true) : '',
          date_survey: survey.date_survey ? Formatter.date(survey.date_survey, true) : '',
          is_dms: survey.is_dms ? 'degrees' : 'decimal',
          risk_level: survey.color_condition,
        });

        setDegrees(survey.is_dms);

        if (!surveyRequestId && survey.survey_request) {
          setSurveyRequestId(survey.survey_request.id);
        }

        Object.entries(survey.crop_stage_data ?? {}).forEach(([field, value]) => {
          handleChangeHarvestData(field, Number(value));
        });

        setDocuments(survey.documents);
        setRegisterUser(survey?.user ?? {});
        setPlantingWindow(survey?.survey_stage?.name ?? '');
        setMarkerPosition({ lat: Number(survey.latitude), lng: Number(survey.longitude) });

        form.scrollToField('name', {
          scrollMode: 'always',
          block: 'start',
          behavior: actions =>
            actions.forEach(({ el, top }) => {
              el.scrollTop = top - 280;
            }),
        });
      }
      setLoading(false);
      setLoadingButton(false);
      setDisabledButton(false);
    }
  };

  const handleShowSurveyRequestModal = () => {
    setIsShowingSurveyRequestModal(true);
  };
  const handleTableDocumentsChange = () => {
    documents_form
      .validateFields()
      .then(() => {
        if (documents_form.getFieldsValue().name !== undefined) {
          const values = documents_form.getFieldsValue();
          setDocuments((prev: any) => [
            ...prev,
            { ...values, latitude: values?.latitude, longitude: values?.longitude, editing: true },
          ]);

          setLoading(true);
          setLoading(false);

          setVisibilitySubforms(curState => ({
            ...curState,
            documents: false,
          }));
        } else {
          ModalStatus({
            type: 'error',
            title: t('modal.error'),
            subTitle: t('form.validation.imageNotFound'),
          });
          setLoading(false);
          setLoadingButton(false);
          setDisabledButton(false);
        }
      })
      .catch((err: DTOErrorReponse) => {
        alertStatus(err, 'error');
        setLoading(false);
        setLoadingButton(false);
        setDisabledButton(false);
      });
  };

  const handleClickAddDocuments = () => {
    subformsOpen('documents');
    setAlreadySelectedTypeFile(false);
    setAlreadySelectedFile(false);
    subforms.documents.setFieldsValue({ id: uuid() });
  };

  const separeCropData = (values: unknown) => {
    const cropStageData = {} as DTOSurveyFields;
    Object.entries(values).forEach(([key, val]) => {
      if (Object.entries(cprFields).filter(entry => entry[0] === key).length) {
        cropStageData[key] = val as number;
      }
    });

    return cropStageData;
  };

  const handleRegister = () => {
    setLoading(true);
    setLoadingButton(true);
    setDisabledButton(true);
    form
      .validateFields()
      .then(async values => {
        const cropStageData = separeCropData(values);
        const response = await storeSurvey({
          ...values,
          crop_stage_data: cropStageData,
          is_dms: values.is_dms === 'decimal' ? 0 : 1,
          registration_id: registrationId,
          documents: Array.from(documents ?? []),
          survey_request_id: surveyRequestId,
        });

        if (response) {
          handleSuccess(response.data.message, () =>
            navigate(`/monitoring/cpr/${cprId}/registration/${registrationId}/survey`),
          );
        }
      })
      .catch((err: DTOErrorResponse) => {
        alertStatus(err, 'error');
      })
      .finally(() => {
        setLoading(false);
        setLoadingButton(false);
        setDisabledButton(false);
      });
  };

  const handleEdit = () => {
    setLoading(true);
    setLoadingButton(true);
    setDisabledButton(true);
    form
      .validateFields()
      .then(async values => {
        const cropStageData = separeCropData(values);
        const response = await updateSurvey(values.id, {
          ...values,
          crop_stage_data: cropStageData,
          is_dms: values.is_dms === 'decimal' ? 0 : 1,
          registration_id: registrationId,
          documents: Array.from(documents ?? []),
        });

        if (response) {
          handleSuccess(response.data.message, () =>
            navigate(`/monitoring/cpr/${cprId}/registration/${registrationId}/survey`),
          );
        }
      })
      .catch((err: DTOErrorResponse) => {
        alertStatus(err, 'error');
      })
      .finally(() => {
        setLoading(false);
        setLoadingButton(false);
        setDisabledButton(false);
      });
  };

  const loadData = async () => {
    setCpr(await getCprById(cprId));
    const registrationResponse = await getRegistration(registrationId);
    setRegistration(registrationResponse);
    const kmlsFromRegistration = [] as any[];
    registrationResponse?.kml.forEach((kml: any) => {
      const parsedJson = kml.json ? JSON.parse(kml.json).coordinates[0] : [];
      kmlsFromRegistration.push({
        id: kml.id,
        coords: parsedJson.map((json: any) => ({
          lat: Number(json[1]),
          lng: Number(json[0]),
        })),
      });
    });
    setKmls(kmlsFromRegistration);

    seTypesSurvey(await getSurveyTypes());
    setCropConditions(await getCropConditions());
    setCultures(getPersonOptions('culture'));
    setDocumentTypes(
      await getDocumentTypes({
        params: {
          tag: 'monitoring',
        },
      }),
    );
  };

  const loadFields = async () => {
    setCprFields(await getFields(selectedFoundCultureId ?? ''));
  };

  const loadSurveyStages = (value: string, page: number) => {
    return getStageOptions({ params: { search: value, page } });
  };

  const handleSurveyStatusInfoKeyPress = (evt: React.KeyboardEvent<HTMLAnchorElement>) => evt.key === 'Enter' && info();

  const handleChangeSelectedFoundCulture = (cultureId: any) => setSelectedFoundCultureId(cultureId || cpr.culture.id);

  useEffect(() => {
    loadData();

    if (surveyId) {
      loadSurveyData();
      setEditing(true);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (cpr) setSelectedFoundCultureId(cpr.culture?.id ?? '');
  }, [cpr]);

  useEffect(() => {
    loadFields();
    // eslint-disable-next-line
  }, [selectedFoundCultureId]);

  useEffect(() => {
    setSubforms({
      documents: documents_form,
    });
    // eslint-disable-next-line
  }, [documents_form]);

  useEffect(() => {
    if (surveyId && !editing) setEditing(true);
    // eslint-disable-next-line
  }, [surveyId]);

  useEffect(() => {
    if (kmls?.length) {
      setMapPosition(kmls[0].coords[0]);
    }
  }, [kmls]);

  const [visibilityApprove, setVisibilityApprove] = useState(false);
  const [loadingApprove, setLoadingApprove] = useState(false);
  const [approveId, setApproveId] = useState('false');

  return (
    <>
      <Breadcrumb
        items={[
          { title: t('breadcrumb.monitoring') },
          { title: t('breadcrumb.cpr'), to: '/monitoring/cpr' },
          { title: t('breadcrumb.registrations'), to: `/monitoring/cpr/${cprId}/registration` },
          { title: t('breadcrumb.surveys'), to: `/monitoring/cpr/${cprId}/registration/${registrationId}/survey` },
          { title: t('breadcrumb.survey') },
        ]}
      />

      <Modal
        visible={visibilityApprove}
        width="20%"
        title={t('pages.monitoring.survey.modalApprove.title')}
        onCancel={() => setVisibilityApprove(false)}
        closable={!loadingApprove}
        closeIcon={<MdClose onClick={() => setVisibilityApprove(false)} />}
        footer={
          <>
            <div className="buttons">
              <Button
                status="primary"
                disabled={loadingApprove}
                onClick={() => {
                  setLoadingApprove(true);
                  monitoringApi.survey
                    .approve(approveId)
                    .then((response: any) => {
                      setSurveyStatusId(response.data.status_id);
                      handleSuccess(response.data.message);
                    })
                    .catch((err: DTOErrorReponse) => alertStatus(err, 'error'))
                    .finally(() => {
                      setLoadingApprove(false);
                      setVisibilityApprove(false);
                    });
                }}
              >
                {t('pages.registered-customers.form.buttons.save')}
              </Button>
              <Button status="secondary" onClick={() => setVisibilityApprove(false)}>
                {t('pages.credit-request.modal-reports.buttons.close')}
              </Button>
            </div>
          </>
        }
      >
        <Spin spinning={loadingApprove}>{t('pages.monitoring.survey.modalApprove.confirm')}</Spin>
      </Modal>
      <SFormContainer>
        <Spin spinning={loading || isFetchingCpr || isFetchingFields}>
          <div style={{ marginBottom: '10px' }}>
            {surveyStatusId && surveyStatusId !== SURVEY_APPROVAL_STATUS_APPROVED && (
              <>
                <Can I="monitoring.survey.approve" a="">
                  <Button
                    status="primary"
                    onClick={() => {
                      setVisibilityApprove(true);
                      setApproveId(surveyId);
                    }}
                  >
                    {t('pages.monitoring.survey.modalApproval.title')}
                  </Button>
                </Can>
              </>
            )}
            {editing && typeof surveyRequestId !== 'string' && (
              <Can I="monitoring.surveyrequest.store" a="">
                <Button status="primary" icon={<FaPlus />} onClick={handleShowSurveyRequestModal}>
                  {t('pages.monitoring.survey.buttonAddSurveyRequest')}
                </Button>
              </Can>
            )}
          </div>

          <h2>{t('pages.monitoring.survey.form.details_header')}</h2>
          <>
            <p>
              {t('pages.monitoring.cpr.table.header.status')}:&nbsp;
              {surveyStatusId === SURVEY_APPROVAL_STATUS_APPROVED
                ? t('pages.monitoring.survey.approved')
                : t('pages.monitoring.survey.draft')}
            </p>
            <br />

            <PermissionedComponent
              permission="monitoring.registration.show"
              description={t('permission.message.monitoring.registration.show')}
            >
              <Descriptions bordered title="" size="small">
                <Descriptions.Item label={t('pages.monitoring.cpr.table.header.number')}>
                  {cpr?.number}
                </Descriptions.Item>
                <Descriptions.Item label={t('pages.monitoring.cpr.table.header.farm')}>{cpr?.farm}</Descriptions.Item>
                <Descriptions.Item label={t('pages.monitoring.cpr.table.header.warranty_area')}>
                  {cpr?.warranty_area}
                </Descriptions.Item>
                <Descriptions.Item label={t('pages.monitoring.cpr.table.header.culture')}>
                  {cpr?.cultureName}
                </Descriptions.Item>
                <Descriptions.Item span={2} label={t('pages.monitoring.cpr.table.header.owner')}>
                  {cpr?.owner}
                </Descriptions.Item>
                <Descriptions.Item label={t('pages.monitoring.cpr.table.header.registration_number')}>
                  {registration?.number}
                </Descriptions.Item>
                <Descriptions.Item label={t('pages.monitoring.cpr.table.header.estimated_production')}>
                  {registration?.estimated_production}
                </Descriptions.Item>
                <Descriptions.Item label={t('pages.monitoring.cpr.table.header.total_area')}>
                  {registration?.total_area ?? '0'}
                </Descriptions.Item>
                <Descriptions.Item label={t('pages.monitoring.cpr.table.header.longitude')}>
                  {registration?.longitude ?? t('not_provided')}
                </Descriptions.Item>
                <Descriptions.Item label={t('pages.monitoring.cpr.table.header.latitude')}>
                  {registration?.latitude ?? t('not_provided')}
                </Descriptions.Item>
                <Descriptions.Item label={t('pages.monitoring.cpr.table.header.register_user')}>
                  {registerUser.name}
                </Descriptions.Item>
              </Descriptions>
            </PermissionedComponent>

            <br />
            <p style={{ display: form.getFieldValue('risk_level') ? 'block' : 'none' }}>
              {`${t('pages.monitoring.cpr.table.header.risk_level')}:`}{' '}
              <Badge size="default" color={form.getFieldValue('risk_level')} text="" />
              {/* eslint-disable-next-line */}
              <a onClick={info} onKeyPress={handleSurveyStatusInfoKeyPress} role="button" tabIndex={0}>
                <FaQuestionCircle className="faquestion" />
              </a>
            </p>
            {surveyRequestId && (
              <p style={{ margin: '10px 0' }}>
                <Link style={{ cursor: 'pointer' }} to={`/monitoring/surveyrequest/edit/${surveyRequestId}`}>
                  {t('pages.survey.form.survey-request')}
                </Link>
              </p>
            )}
          </>

          <h2>{t('pages.monitoring.survey.form.planting_information')}</h2>
          <Form form={form}>
            <FormItem name="id" style={{ display: 'none' }}>
              <Input />
            </FormItem>
            <FormItem name="survey_request_id" initialValue={surveyRequestId} hidden>
              <Input defaultValue={surveyRequestId} />
            </FormItem>
            <FormItem name="risk_level" style={{ display: 'none' }}>
              <Input />
            </FormItem>
            <Row gutter={[26, 26]}>
              <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                <FormItem
                  rules={[{ required: true }]}
                  label={t('pages.monitoring.survey.form.type_survey')}
                  name="survey_type_id"
                  initialValue={surveyTypeId}
                  permission="monitoring.survey.type.index"
                >
                  <Select defaultValue={surveyTypeId} options={typesSurvey} />
                </FormItem>
              </Col>
              <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                <FormItem
                  rules={[{ required: true }]}
                  label={t('pages.monitoring.survey.form.survey_stage')}
                  name="survey_stage_id"
                  initialValue={surveyStageId}
                  permission="monitoring.survey.stage.index"
                >
                  <InfiniteSelectAsync
                    defaultValue={surveyStageId}
                    searchRequest={loadSurveyStages}
                    initialRequest
                    onChange={(_, option: OptionsType | OptionData | OptionGroupData) =>
                      setPlantingWindow((option as OptionData).label.toString())
                    }
                  />
                </FormItem>
              </Col>
              <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                <FormItem
                  rules={[{ required: true }]}
                  name="operator_id"
                  label={t('pages.monitoring.survey.form.operator')}
                  initialValue={surveyOperatorId}
                >
                  <InfiniteSelectAsync
                    defaultValue={surveyOperatorId}
                    loading={isFetchingOperators}
                    initialRequest
                    searchRequest={async () => getOperators()}
                  />
                </FormItem>
              </Col>
              <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                <FormItem
                  label={t('pages.monitoring.survey.form.date_survey')}
                  name="date_survey"
                  rules={[{ required: true }]}
                >
                  <DatePicker locale={ptBR} mode="date" format={appConfigs.formatDate} />
                </FormItem>
              </Col>
              <Col xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
                <FormItem
                  label={t('pages.monitoring.survey.form.crop_condition')}
                  name="crop_condition_id"
                  rules={[{ required: true }]}
                >
                  <PermissionedComponent permission="monitoring.cropcondition.all">
                    <Select options={cropConditions || []} />
                  </PermissionedComponent>
                </FormItem>
              </Col>
              <Col xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
                <FormItem
                  style={{ paddingRight: '10px' }}
                  label={t('pages.monitoring.cpr.form.estimated_production')}
                  name="estimated_production"
                  rules={[{ required: true }]}
                >
                  <InputNumber
                    min={0}
                    maxLength={21}
                    formatter={(value: string | number) => Formatter.decimal(value, 2, 3, null)}
                    parser={(value: string) => Number(Formatter.decimalParser(value))}
                  />
                </FormItem>
              </Col>
            </Row>
            <Row gutter={[26, 26]}>
              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <FormItem
                  label={t('pages.monitoring.survey.form.planting_start')}
                  name="planting_start"
                  rules={[{ required: true }]}
                >
                  <DatePicker locale={ptBR} mode="date" format={appConfigs.formatDate} />
                </FormItem>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <FormItem
                  label={t('pages.monitoring.survey.form.harvest_forecast')}
                  name="harvest_forecast"
                  rules={[{ required: true }]}
                >
                  <DatePicker
                    locale={ptBR}
                    mode="date"
                    format={appConfigs.formatDate}
                    disabledDate={currentDate => form.getFieldValue('planting_start') > currentDate}
                  />
                </FormItem>
              </Col>
              <Col xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
                <FormItem
                  label={t('pages.monitoring.survey.form.found_culture')}
                  name="culture_id"
                  rules={[{ required: true }]}
                >
                  <Select options={cultures} onChange={handleChangeSelectedFoundCulture} />
                </FormItem>
              </Col>
            </Row>
            <Row gutter={[26, 26]}>
              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <FormItem
                  label={`${plantingWindow}${t(`pages.monitoring.survey.form.is_correct_window`)}`}
                  name="is_correct_planting_window"
                  rules={[{ required: true }]}
                >
                  <Radio.Group buttonStyle="solid">
                    <Radio.Button value>{t('pages.monitoring.survey.form.yes')}</Radio.Button>
                    <Radio.Button value={false}>{t('pages.monitoring.survey.form.no')}</Radio.Button>
                  </Radio.Group>
                </FormItem>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <FormItem
                  label={t('pages.monitoring.survey.form.is_harvest_broken_risk')}
                  name="is_harvest_broken_risk"
                  rules={[{ required: true }]}
                >
                  <Radio.Group buttonStyle="solid">
                    <Radio.Button value>{t('pages.monitoring.survey.form.yes')}</Radio.Button>
                    <Radio.Button value={false}>{t('pages.monitoring.survey.form.no')}</Radio.Button>
                  </Radio.Group>
                </FormItem>
              </Col>
            </Row>
            <Row gutter={[26, 26]}>
              <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={8}>
                <FormItem label={t('pages.monitoring.registration.form.coordinateType')} name="is_dms">
                  <Select
                    onChange={value => setDegrees(value === 'degrees')}
                    options={[
                      { key: 'degrees', value: 'degrees', label: t('pages.monitoring.registration.form.degrees') },
                      { key: 'decimal', value: 'decimal', label: t('pages.monitoring.registration.form.decimal') },
                    ]}
                  />
                </FormItem>
              </Col>

              {degrees ? (
                <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={8}>
                  <FormItem
                    label={t('pages.monitoring.survey.form.degrees')}
                    name="dms"
                    rules={[
                      {
                        pattern: Coordinate.regexDMS,
                        message: t('validator.dms'),
                      },
                    ]}
                  >
                    <Input placeholder="__° __′ __″ _, ___° __′ __″ _" />
                  </FormItem>
                </Col>
              ) : (
                <>
                  <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={8}>
                    <FormItem
                      label={t('pages.monitoring.survey.form.latitude')}
                      name="latitude"
                      rules={[
                        {
                          pattern: Coordinate.regexLat,
                          message: t('validator.lat'),
                        },
                      ]}
                    >
                      <Input max={12} hidden={degrees} onChange={handleChangeLatitude} />
                    </FormItem>
                  </Col>
                  <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={8}>
                    <FormItem
                      label={t('pages.monitoring.survey.form.longitude')}
                      name="longitude"
                      rules={[
                        {
                          pattern: Coordinate.regexLon,
                          message: t('validator.lon'),
                        },
                      ]}
                    >
                      <Input max={12} hidden={degrees} onChange={handleChangeLongitude} />
                    </FormItem>
                  </Col>
                </>
              )}
            </Row>
            <div className="group-form">
              <h2>{t('pages.monitoring.survey.form.tilling_stage')}</h2>
              <Row gutter={[26, 26]}>
                {Object.entries(cprFields).map(entry => {
                  const fieldName = entry[0];
                  const shouldShowField = entry[1];

                  return (
                    shouldShowField && (
                      <>
                        <Col xs={12} sm={6} md={6} lg={4} xl={4} xxl={4} key={fieldName}>
                          <FormItem
                            style={{ paddingRight: '10px' }}
                            label={t(`pages.monitoring.survey.form.${fieldName}`)}
                            name={fieldName}
                            initialValue={0}
                          >
                            <InputNumber
                              formatter={(value: string | number) => Formatter.integer(value, null, null, '%')}
                              parser={(value: string) => Number(Formatter.integerParser(value))}
                              max={100}
                              min={0}
                              value={harvestData[fieldName]}
                              onChange={(value: string | number) => handleChangeHarvestData(fieldName, value)}
                            />
                          </FormItem>
                        </Col>
                        <Col xs={12} sm={6} md={6} lg={4} xl={4} xxl={4}>
                          <Slider
                            style={{ paddingTop: '45px' }}
                            min={0}
                            max={100}
                            value={harvestData[fieldName]}
                            onChange={(value: number) => handleChangeHarvestData(fieldName, value)}
                          />
                        </Col>
                      </>
                    )
                  );
                })}
              </Row>
            </div>
            <h2>{t('pages.monitoring.survey.form.additional_information')}</h2>
            <Row gutter={[26, 26]}>
              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <FormItem
                  label={t('pages.monitoring.survey.form.is_soil_conservation')}
                  name="is_soil_conservation"
                  rules={[{ required: true }]}
                >
                  <Radio.Group buttonStyle="solid">
                    <Radio.Button value>{t('pages.monitoring.survey.form.proper')}</Radio.Button>
                    <Radio.Button value={false}>{t('pages.monitoring.survey.form.inappropriate')}</Radio.Button>
                  </Radio.Group>
                </FormItem>
              </Col>
            </Row>
            <Row gutter={[26, 26]}>
              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <FormItem
                  style={{ paddingRight: '10px' }}
                  label={t('pages.monitoring.survey.form.fertilizer_used_kg_ha')}
                  name="fertilizer_used_kg_ha"
                  rules={[{ required: true }]}
                >
                  <InputNumber
                    min={0}
                    maxLength={21}
                    formatter={(value: string | number) => Formatter.decimal(value, 2, 3, null)}
                    parser={(value: string) => Number(Formatter.decimalParser(value))}
                  />
                </FormItem>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <FormItem
                  style={{ paddingRight: '10px' }}
                  label={t('pages.monitoring.survey.form.fertilizer_used_brand')}
                  name="fertilizer_used_brand"
                  rules={[{ required: true }]}
                >
                  <Input type="text" />
                </FormItem>
              </Col>
            </Row>
            <Row gutter={[26, 26]}>
              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <FormItem
                  label={t('pages.monitoring.survey.form.is_control_performed')}
                  name="is_control_performed"
                  rules={[{ required: true }]}
                >
                  <Radio.Group buttonStyle="solid">
                    <Radio.Button value>{t('pages.monitoring.survey.form.proper')}</Radio.Button>
                    <Radio.Button value={false}>{t('pages.monitoring.survey.form.inappropriate')}</Radio.Button>
                  </Radio.Group>
                </FormItem>
              </Col>
            </Row>
            <Row gutter={[26, 26]}>
              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <FormItem
                  label={t('pages.monitoring.survey.form.rust_control_applications')}
                  name="is_rust_control"
                  rules={[{ required: true }]}
                >
                  <Radio.Group buttonStyle="solid">
                    <Radio.Button value>{t('pages.monitoring.survey.form.proper')}</Radio.Button>
                    <Radio.Button value={false}>{t('pages.monitoring.survey.form.inappropriate')}</Radio.Button>
                  </Radio.Group>
                </FormItem>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <FormItem
                  style={{ paddingRight: '10px' }}
                  label={t('pages.monitoring.survey.form.rust_control_brand')}
                  name="rust_control_brand"
                >
                  <Input type="text" />
                </FormItem>
              </Col>
            </Row>
            <Row gutter={[26, 26]}>
              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <FormItem
                  label={t('pages.monitoring.survey.form.insecticide_control_applications')}
                  name="is_insecticide_control"
                  rules={[{ required: true }]}
                >
                  <Radio.Group buttonStyle="solid">
                    <Radio.Button value>{t('pages.monitoring.survey.form.proper')}</Radio.Button>
                    <Radio.Button value={false}>{t('pages.monitoring.survey.form.inappropriate')}</Radio.Button>
                  </Radio.Group>
                </FormItem>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <FormItem
                  style={{ paddingRight: '10px' }}
                  label={t('pages.monitoring.survey.form.insecticide_control_brand')}
                  name="insecticide_control_brand"
                >
                  <Input type="text" />
                </FormItem>
              </Col>
            </Row>
            <Row gutter={[26, 26]}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                <FormItem label={t('pages.monitoring.survey.form.comments')} name="comments">
                  <TextArea autoSize={{ minRows: 3, maxRows: 5 }} />
                </FormItem>
              </Col>
            </Row>
            {/* Documentos */}
            <h2>{t('pages.monitoring.survey.form.photos')}</h2>
            <>
              <SFormButtons style={{ paddingTop: '15px' }}>
                <>
                  <Button status="primary-outline" onClick={handleClickAddDocuments}>
                    {t('pages.monitoring.survey.form.addPhotos')}
                  </Button>
                  {editing && documents?.length > 0 && (
                    <Button
                      status="primary-outline"
                      onClick={async () => {
                        setLoading(true);
                        let c = 0;
                        let haveUnsavedFiles = false;

                        attachments.push({
                          attachment_id: [],
                        });

                        documents.forEach(element => {
                          if (element.created_at) {
                            attachments[0].attachment_id[c] = element.id;
                            c += 1;
                          } else {
                            haveUnsavedFiles = true;
                          }
                        });
                        if (attachments[0].attachment_id?.length > 0 && haveUnsavedFiles) {
                          ModalStatus({
                            type: 'warning',
                            title: t('modal.warning'),
                            subTitle: t('download.not-saved-files'),
                            onOk: () => {
                              downloadAll(attachments[0]);
                            },
                          });
                        } else {
                          downloadAll(attachments[0]);
                        }
                      }}
                    >
                      <IconWithTooltip action="download" title={t('pages.registered-customers.table.icons.download')} />
                      {t('pages.monitoring.survey.form.downloadall')}
                    </Button>
                  )}
                </>
              </SFormButtons>

              <FormItem name="documents" hidden>
                <Input />
              </FormItem>
            </>

            <>
              <TableData
                rowKey="id"
                columns={columns.documents.map((column: { key: any }) => {
                  let columnRender: any = column;

                  switch (column.key) {
                    case 'type_id':
                      columnRender = {
                        ...columnRender,
                        render: (active: number, record: any) => {
                          const findState = ability.can('document.type.index', '')
                            ? documentTypes.find(s => s.value === record.type_id)
                            : null;
                          return findState?.label;
                        },
                      };
                      break;
                    case 'latitude':
                      columnRender = {
                        ...columnRender,
                        render: (active: number, record: any) => {
                          const latitude = record.latitude ? record.latitude : '';
                          return latitude;
                        },
                      };
                      break;
                    case 'longitude':
                      columnRender = {
                        ...columnRender,
                        render: (active: number, record: any) => {
                          const longitude = record.longitude ? record.longitude : '';
                          return longitude;
                        },
                      };
                      break;
                    case 'action':
                      columnRender = {
                        ...columnRender,
                        width: '120px',
                        render: (active: number, record: DocumentModelData, index: number) => (
                          <span>
                            <IconWithTooltip
                              action="download"
                              title={t('pages.registered-customers.table.icons.download')}
                              loading={subformsLoading.documents.other === record.id}
                              onClick={async () => {
                                if (record.created_at) {
                                  setSubformsLoading(curState => ({
                                    ...curState,
                                    documents: { ...subformsLoading.documents, other: record.id },
                                  }));

                                  await documentApi
                                    .download(record.id)
                                    .then((response: any) => downloadHandler(response))
                                    .catch((err: DTOErrorReponse) => alertStatus(err, 'error'))
                                    .finally(() => {
                                      setSubformsLoading(curState => ({
                                        ...curState,
                                        documents: { ...subformsLoading.documents, other: null },
                                      }));
                                    });
                                } else {
                                  downloadDocument(record.image, record.name);
                                  setSubformsLoading(curState => ({
                                    ...curState,
                                    documents: { ...subformsLoading.documents, other: null },
                                  }));
                                }
                              }}
                            />

                            <IconWithTooltip
                              action="detail"
                              title={t('preview')}
                              loading={subformsLoading.documents.other === record.id}
                              onClick={async () => {
                                if (record.created_at) {
                                  setSelectedFileToPreview(record);
                                }
                              }}
                            />

                            <Can I="document.destroy">
                              <IconWithTooltip
                                action="destroy"
                                title={t('pages.registered-customers.table.icons.remove')}
                                loading={subformsLoading.documents.removing === record.id}
                                onClick={() => {
                                  ModalStatus({
                                    type: 'confirm',
                                    title: t('modal.confirm'),
                                    subTitle: t('documents.confirm-remove'),
                                    cancelText: t('modal.cancel'),
                                    onOk: () => {
                                      setSubformsLoading(curState => ({
                                        ...curState,
                                        documents: { ...subformsLoading.documents, removing: record.id },
                                      }));

                                      documentApi
                                        .delete(record.id, { params: { language: i18n.language } })
                                        .then(response => {
                                          documents.splice(index, 1);
                                          setDocuments([...documents]);
                                          alertStatus(response.data.message, 'success');
                                        })
                                        .catch((err: DTOErrorReponse) => alertStatus(err, 'error'))
                                        .finally(() => {
                                          setSubformsLoading(curState => ({
                                            ...curState,
                                            documents: { ...subformsLoading.documents, removing: null },
                                          }));
                                        });
                                    },
                                  });
                                }}
                              />
                            </Can>
                          </span>
                        ),
                      };
                      break;
                    default:
                      return columnRender;
                  }

                  return columnRender;
                })}
                dataSource={documents || null}
                tableConfig={{ nopagination: true }}
                rowClassName={record => record.editing && 'editing'}
              />
              {!!kmls?.length && (
                <>
                  <h2>{t('pages.monitoring.survey.form.car')}</h2>
                  {isLoaded && (
                    <GoogleMap
                      center={mapPosition}
                      zoom={zoom}
                      mapContainerStyle={{
                        height: '50vh',
                        width: '100%',
                      }}
                    >
                      {markerPosition.lat && markerPosition.lng && (
                        <Marker
                          position={{ lat: markerPosition.lat ?? 0, lng: markerPosition.lng ?? 0 }}
                          onLoad={marker => {
                            const customIcon = (opts: {
                              fillColor: string;
                              path: string;
                              strokeColor: string;
                              scale: number;
                            }) => ({
                              fillOpacity: 1,
                              strokeWeight: 0,
                              rotation: 0,
                              ...opts,
                            });
                            marker.setIcon(
                              customIcon({
                                fillColor: theme.colors.info,
                                path: `M12 0c-4.198 0-8 3.403-8 7.602 0 4.198 3.469 9.21 8 16.398 4.531-7.188 8-12.2 8-16.398 0-4.199-3.801-7.602-8-7.602zm0 11c-1.657 0-3-1.343-3-3s1.343-3 3-3 3 1.343 3 3-1.343 3-3 3z`,
                                strokeColor: 'white',
                                scale: 1.5,
                              }),
                            );
                          }}
                        />
                      )}
                      {kmls.map(kml => {
                        return (
                          <Polygon
                            key={kml.id}
                            path={kml.coords}
                            options={{
                              fillColor: theme.colors.primary,
                              fillOpacity: 1,
                              strokeColor: '#6d6d6d',
                              strokeOpacity: 0.5,
                              strokeWeight: 1,
                            }}
                          />
                        );
                      })}
                    </GoogleMap>
                  )}
                </>
              )}
            </>
            <SFormButtons style={{ paddingTop: '15px' }}>
              <>
                <Button
                  status="secondary"
                  htmlType="reset"
                  onClick={() => navigate(`/monitoring/cpr/${cprId}/registration/${registrationId}/survey`)}
                  disabled={disabledButton}
                >
                  {t('pages.monitoring.survey.buttonCancel')}
                </Button>
                {editing && (
                  <Button
                    status="primary"
                    htmlType="submit"
                    onClick={handleEdit}
                    loading={loadingButton}
                    disabled={disabledButton}
                  >
                    {t('pages.monitoring.survey.buttonEdit')}
                  </Button>
                )}
                {!editing && (
                  <Button
                    status="primary"
                    htmlType="submit"
                    onClick={handleRegister}
                    loading={loadingButton}
                    disabled={disabledButton}
                  >
                    {t('pages.monitoring.survey.buttonRegister')}
                  </Button>
                )}
              </>
            </SFormButtons>
          </Form>
        </Spin>
      </SFormContainer>

      <Modal
        title={t('pages.monitoring.survey.form.photos')}
        visible={visibilitySubforms.documents}
        onCancel={() => setVisibilitySubforms(curState => ({ ...curState, documents: false }))}
        afterClose={() => {
          documents_form.resetFields();
          setAlreadySelectedTypeFile(false);
        }}
        width="80%"
        footer={
          <>
            <div className="buttons">
              <Button
                status="primary"
                onClick={() => handleTableDocumentsChange()}
                disabled={subformsLoading.documents.form}
              >
                {t('pages.registered-customers.form.buttons.save')}
              </Button>

              <Button
                status="secondary"
                onClick={() => {
                  setVisibilitySubforms(curState => ({ ...curState, documents: false }));
                  documents_form.resetFields();
                }}
                disabled={subformsLoading.documents.form}
              >
                {t('pages.registered-customers.form.buttons.cancel')}
              </Button>
            </div>
          </>
        }
        className="modal-with-custom-footer"
      >
        <Spin spinning={subformsLoading.documents.form}>
          <Form name="subform_documents" form={documents_form}>
            <FormItem name="id" hidden>
              <Input />
            </FormItem>
            <FormItem name="original" hidden>
              <Input />
            </FormItem>
            <FormItem name="name" hidden>
              <Input />
            </FormItem>
            <FormItem name="mime" hidden>
              <Input />
            </FormItem>
            <FormItem name="image" hidden>
              <Input />
            </FormItem>

            <Row gutter={[8, 8]}>
              <Col xs={24} sm={24} md={24} lg={12} xl={9} xxl={9}>
                <FormItem
                  label={t('pages.monitoring.survey.form.type_photo')}
                  name="type_id"
                  rules={[{ required: true }]}
                  permission="document.type.index"
                >
                  <Select
                    options={documentTypes}
                    onChange={() => {
                      setAlreadySelectedTypeFile(true);
                      setAlreadySelectedFile(false);
                    }}
                  />
                </FormItem>
              </Col>
              <Col xs={12} sm={12} md={8} lg={8} xl={6} xxl={6}>
                <FormItem
                  label={t('pages.monitoring.farm.form.latitude')}
                  name="latitude"
                  rules={[
                    {
                      pattern: Coordinate.regexLat,
                      message: t('pages.monitoring.farm.validator.lat'),
                    },
                  ]}
                >
                  <Input />
                </FormItem>
              </Col>
              <Col xs={12} sm={12} md={8} lg={8} xl={6} xxl={6}>
                <FormItem
                  label={t('pages.monitoring.farm.form.longitude')}
                  name="longitude"
                  rules={[
                    {
                      pattern: Coordinate.regexLon,
                      message: t('pages.monitoring.farm.validator.lon'),
                    },
                  ]}
                >
                  <Input />
                </FormItem>
              </Col>
            </Row>

            {alreadySelectedTypeFile && (
              <>
                <Row gutter={[8, 8]}>
                  <Col xs={24} sm={24} md={24} lg={12} xl={9} xxl={9}>
                    {alreadySelectedFile ? (
                      <>
                        <div className="selected-document">
                          <span>{documents_form.getFieldValue('name')}</span>
                          <Button
                            status="primary-outline"
                            onClick={() => {
                              setAlreadySelectedFile(false);
                            }}
                          >
                            {t('pages.registered-customers.form.pf.documents.changeFile')}
                          </Button>
                        </div>
                      </>
                    ) : (
                      <>
                        <Dragger
                          className="custom-dragger"
                          accept=".jpeg,.jpg,.png,.pdf"
                          action={(file: any) => {
                            if (
                              !file.name.includes('.pdf') &&
                              !file.name.includes('.png') &&
                              !file.name.includes('.jpeg') &&
                              !file.name.includes('.jpg')
                            ) {
                              alertStatus(
                                {
                                  message: t('pages.registered-customers.form.pf.documents.invalidFile'),
                                } as DTOErrorReponse,
                                'error',
                              );
                              setAlreadySelectedTypeFile(false);
                              documents_form.resetFields();
                              return;
                            }

                            getBase64(file, (documentBase64: any) => {
                              documents_form.setFieldsValue({
                                image: documentBase64,
                                mime: file.type,
                                name: getOriginalName(file.name, true),
                                original: getOriginalName(file.name),
                                stored: false,
                              });
                              setAlreadySelectedFile(true);
                            });
                            return file;
                          }}
                          customRequest={() => 'done'}
                        >
                          {t('upload.only_image')}
                        </Dragger>
                      </>
                    )}
                  </Col>
                </Row>
              </>
            )}
          </Form>
        </Spin>
      </Modal>
      {editing && (
        <SurveyRequestModal
          isShowing={isShowingSurveyRequestModal}
          updateIsShowing={setIsShowingSurveyRequestModal}
          cprId={cprId}
          registrationId={registrationId}
        />
      )}

      {selectedFileToPreview && (
        <FilePreviewer document={selectedFileToPreview} onClose={() => setSelectedFileToPreview(null)} />
      )}
    </>
  );
};

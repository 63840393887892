const columns: any = [
  {
    title: 'pages.register.baseport.table.header.name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'pages.register.baseport.table.header.city',
    dataIndex: 'city',
    key: 'city',
  },
  // {
  //   title: 'pages.register.baseport.table.header.state',
  //   dataIndex: 'state',
  //   key: 'state',
  // },
  // {
  //   title: 'pages.register.baseport.table.header.country',
  //   dataIndex: 'country',
  //   key: 'country',
  // },
  {
    title: 'pages.register.harvest.table.header.actions',
    key: 'action',
    dataIndex: 'action',
    align: 'center',
    width: '180px',
    fixed: 'right',
  },
];

export default columns;

import React from 'react';
import { useTranslation } from 'react-i18next';

import { InputNumber } from 'antd';
import { FormItem } from 'components';
import { useCreditRequestStore } from 'pages/CreditRequest/store/useCreditRequestStore';
import { Formatter } from 'utils/Formatter';

interface Props {
  label?: string;
  required?: boolean;
  disabled?: boolean;
}

export const JsonDataPriceInput = ({ label, required = false, disabled }: Props) => {
  const { t } = useTranslation();

  const setIsBlocking = useCreditRequestStore(state => state.setIsBlocking);

  return (
    <FormItem
      label={label || t('pages.credit-request.form.json_data_price')}
      name="json_data.price"
      rules={[{ required }]}
    >
      <InputNumber<string>
        min="0"
        formatter={(value: string | number) => Formatter.money(value)}
        parser={(value: string) => Formatter.moneyParser(value)}
        disabled={disabled}
        onChange={value => {
          if (value) {
            setIsBlocking(true);
          }
        }}
      />
    </FormItem>
  );
};

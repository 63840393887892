import { useMutation } from '@tanstack/react-query';
import { useAlertDialog } from 'components/ui/alert-dialog';
import api from 'services/api/api';

export const usePrepareDraftMutation = () => {
  const { alert } = useAlertDialog();

  return useMutation({
    mutationKey: ['prepareDraft'],
    mutationFn: async (draftData: FormData) => {
      const response = await api.post(`envelope/send`, draftData);
      return response?.data;
    },
    onError: err => {
      alert({ title: err.message, type: 'error' });
    },
  });
};

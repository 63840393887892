import styled, { css } from 'styled-components';

interface ContainerProps {
  minimized: boolean;
  hidden: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 50px 1fr;
  flex-direction: column;
  height: 100%;
  margin: 0 5px;
  background: ${({ theme: { colors } }) => colors.greys.grey01};
  border-radius: 4px;
  padding: 4px;

  > header {
    display: flex;
    align-items: flex-start;
    margin-bottom: 8px;

    > div {
      display: flex;
      flex: 1;
      justify-content: center;
      padding: 4px 8px;

      color: ${({ theme: { colors } }) => colors.text};
      font: 700 14px/18px ${({ theme: { fonts } }) => fonts.primary};

      > span {
        margin-right: 8px;
      }
    }

    > span {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 28px;
      height: 28px;
      border-radius: 16px;
      padding-left: 4px;
      padding-right: 4px;

      color: #ffffff;
      background: ${({ theme: { colors } }) => colors.primary};
      font: 700 14px/18px ${({ theme: { fonts } }) => fonts.primary};
    }
  }

  > .content {
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
    padding: 6px;
    height: 100%;
    overflow-y: auto;
    flex: 1;
  }

  > .pagination {
    display: flex;
    justify-content: center;
    padding: 6px;

    .ant-pagination.mini {
      .ant-pagination-item {
        &.ant-pagination-item-active {
          border-color: ${({ theme: { colors } }) => colors.primary};
          background-color: ${({ theme: { colors } }) => colors.primary};

          a,
          a:hover {
            color: #ffffff;
            font-weight: bold;
          }
        }

        a:hover {
          color: ${({ theme: { colors } }) => colors.primary};
        }

        .anticon.anticon-double-right,
        .anticon.anticon-double-right:hover,
        .anticon.anticon-double-right svg,
        .anticon.anticon-double-right:hover svg {
          color: ${({ theme: { colors } }) => colors.primary};
        }
      }
    }
  }

  > .content + .pagination {
    height: 37px;
  }

  ${({ minimized }) => {
    if (minimized) {
      return css`
        position: relative;
        min-width: 50px;
        width: 50px;
        padding: 5px 6px;

        > header {
          justify-content: flex-start;
          position: absolute;
          top: 16px;
          left: 0;
          display: block;
          width: 100%;
          text-align: left;
          word-break: keep-all;
          transform: rotate(90deg);

          > div {
            display: block;
            padding: 16px 28px;
            position: relative;
            width: 100%;
            white-space: nowrap;

            > svg {
              font-size: 20px;
              position: absolute;
              left: 0;
            }
          }

          > span {
            display: none;
          }
        }

        > .content,
        > .pagination {
          display: none;
        }
      `;
    }

    return css`
      min-width: 280px;
      width: 280px;
    `;
  }}

  ${({ hidden }) => {
    if (hidden) {
      return css`
        display: none;
      `;
    }
    return css``;
  }}
`;

import React, { forwardRef } from 'react';

import { PermissionedField } from 'components/PermissionedField';
import { ComboBoxInfinite, ComboBoxInfiniteProps } from 'components/ui/combo.infinite';
import { usePagination } from 'hooks/usePagination';
import { useGetEnvelopeStatusOptionsQuery } from 'pages/CreditRequest/api/useGetEnvelopeStatusQuery';
import { Converter } from 'utils/Converter';

type Props<T = unknown> = Omit<ComboBoxInfiniteProps<T>, 'infiniteQuery' | 'initialOptions'> & {
  initialOptions?: T[];
};

export const EnvelopeStatusSelect = forwardRef((props: Props, ref: React.Ref<HTMLInputElement> | null) => {
  const { dispatchPagination: dispatchPackageTypes, paginationConfig: packageTypesPaginationConfig } = usePagination({
    initialPagination: {
      pageSize: 10,
      current: 1,
      total: 0,
      lastPage: 1,
    },
  });

  const documentStatusInfiniteQuery = useGetEnvelopeStatusOptionsQuery({ ...packageTypesPaginationConfig });

  return (
    <PermissionedField permission={['credit.request.envelope.index']}>
      <ComboBoxInfinite
        {...props}
        ref={ref}
        initialOptions={Converter.selectOptionArray((props.initialOptions || []).filter(option => !!option))}
        infiniteQuery={documentStatusInfiniteQuery}
        onSearch={value => dispatchPackageTypes({ type: 'SET_SEARCH', payload: value })}
      />
    </PermissionedField>
  );
});
EnvelopeStatusSelect.displayName = 'EnvelopeStatusSelect';

import React from 'react';
import { useTranslation } from 'react-i18next';

import { Card } from 'antd';

import { SCard } from './styles';
import { PropsCard } from './types';

const { Meta } = Card;

export const CCard = ({ item, width = '100%' }: PropsCard) => {
  const { t } = useTranslation();
  return (
    <SCard style={{ width }}>
      <Meta title={item.title} description={t(`pages.dashboard.card.description.${item.description}`)} />
    </SCard>
  );
};

import React, { useState, useCallback, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Form as AntForm, Input as AntInput } from 'antd';

import { Button, FormItem, EmptyComponent, Spin } from '../../../components';
import { LEGAL_PERSON_TYPE, PHYSICAL_PERSON_TYPE } from '../../../configs/constants';
import { useAuth } from '../../../hooks/auth';
import { usePage } from '../../../hooks/page';
import sustainabilityApi from '../../../services/api/sustainability';
import renderComponent from '../../../utils/RenderComponent';
import CommentItem from './CommentItem';
import ChatItemProps from './CommentItem/types';
import { Container } from './styles';
import CommentsProps, { CommentRaw, SustainabilityResume } from './types';

const Comments: React.FC<CommentsProps> = ({ sustainabilityReportId }) => {
  const [commentForm] = AntForm.useForm();
  const { t } = useTranslation();
  const { user } = useAuth();
  const { alertStatus } = usePage();

  const scrollObserve = useRef();
  const scrollBottom = useRef<null | HTMLDivElement>(null);

  const [comment, setComment] = useState('');
  const [loading, setLoading] = useState(true);
  const [scrollRadio, setScrollRadio] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [blockLoadComments, setBlockLoadComments] = useState(false);
  const [forceLoadComments, setForceLoadComments] = useState(false);
  const [commentsRaw, setCommentsRaw] = useState<CommentRaw[]>([]);
  const [sustainabilityResume, setSustainabilityResume] = useState<SustainabilityResume>({} as SustainabilityResume);

  const getComments = useCallback(
    async (pageNumber: number) => {
      if (!sustainabilityReportId) {
        alertStatus('Não encontrado o número da solicitação de crédito!', 'error');
        return;
      }

      if (blockLoadComments && forceLoadComments === false) {
        return;
      }

      setLoading(true);

      await sustainabilityApi.reports
        .getComments(sustainabilityReportId, { params: { order_by: 'desc', per_page: 10, page: pageNumber } })
        .then(response => {
          const totalPagesQtd = response.data.last_page;
          setTotalPages(totalPagesQtd);

          if (pageNumber === 1) {
            setCommentsRaw([...response.data.data]);
          } else {
            setCommentsRaw(commentsState => [...commentsState, ...response.data.data]);
          }

          if (scrollBottom && scrollBottom.current) {
            const { scrollHeight } = scrollBottom.current;
            const pageHeight = scrollBottom.current.scrollHeight / pageNumber;
            scrollBottom.current.scrollTop = scrollHeight - (pageHeight * pageNumber - 1) + pageHeight;
          }
        })
        .catch(err => {
          alertStatus(err, 'error');
        })
        .finally(() => {
          setLoading(false);
          setForceLoadComments(false);

          if (pageNumber === 1) {
            if (scrollBottom?.current) {
              scrollBottom.current.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'end' });
              scrollBottom.current.scrollTop = scrollBottom.current.scrollHeight;
            }
          }
        });
    },
    // eslint-disable-next-line
    [alertStatus, blockLoadComments, sustainabilityReportId],
  );

  useEffect(() => {
    getComments(1);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (forceLoadComments === true) {
      getComments(1);
    }
    // eslint-disable-next-line
  }, [forceLoadComments, getComments]);

  const getSustainabilityResume = useCallback(() => {
    sustainabilityApi.reports
      .show(sustainabilityReportId)
      .then(async response => {
        const resumeData = response;
        const resumeCreditRequest: SustainabilityResume = {
          sustainability_report_type: resumeData?.sustainability_report_type?.name,
          requester_type:
            resumeData.requester_person_type === PHYSICAL_PERSON_TYPE
              ? t('geral.person_type.physical')
              : resumeData.requester_person_type === LEGAL_PERSON_TYPE
              ? t('geral.person_type.legal')
              : '',
          doc_number: resumeData?.doc_number ?? '',
          harvest: resumeData.harvest?.name ?? '',
          provider: resumeData?.provider ?? '',
          product: resumeData?.product?.name ?? '',
          status: resumeData?.sustainability_report_status?.name,
          last_changed_by: resumeData?.user?.name ?? '',
        };

        setSustainabilityResume(resumeCreditRequest);
      })
      .catch(err => {
        alertStatus(err, 'error');
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line
  }, [alertStatus]);

  useEffect(() => getSustainabilityResume(), [getSustainabilityResume]);

  const intersectionObserver = new IntersectionObserver(entries => {
    const radio = entries[0].intersectionRatio;
    setScrollRadio(Math.round(radio));
  });

  useEffect(() => {
    intersectionObserver.observe(scrollObserve.current);
    return () => {
      intersectionObserver.disconnect();
    };
    // eslint-disable-next-line
  }, [intersectionObserver]);

  useEffect(() => {
    if (scrollBottom) {
      scrollBottom.current.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'end' });
      scrollBottom.current.scrollTop = scrollBottom.current.scrollHeight;
    }
    // eslint-disable-next-line
  }, [scrollBottom]);

  useEffect(() => {
    if (scrollRadio > 0) {
      const newPage = page + 1;

      if (page >= totalPages) {
        setBlockLoadComments(true);
      } else {
        if (!blockLoadComments) {
          setBlockLoadComments(false);
          setPage(newPage);
          getComments(newPage);
        }
      }
    }
    // eslint-disable-next-line
  }, [scrollRadio, getComments, page, totalPages]);

  const comments = useMemo(() => {
    const commentsReversed = commentsRaw;
    // Agrupa os commentários por datas
    const commentsByDate = commentsReversed.reduceRight((prev: any, cur) => {
      const curDate = cur.created_at.split(' ')[0];
      prev[curDate] = [...(prev[curDate] ?? []), cur];
      return prev;
    }, {});

    // Agrupa as mensagens na sequência por usuários
    const groupedComments = Object.values(commentsByDate).reduce((prev: any, cur: any) => {
      const total = cur.length;
      let prevDate = '';
      const all = [];
      let messages = [];

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < total; i++) {
        const curItem = cur[i];
        const nextItem = i < total ? cur[i + 1] : {};

        // eslint-disable-next-line prefer-destructuring
        prevDate = curItem.created_at.split(' ')[0];

        // Format the message - BEGIN
        const curHour = curItem.created_at.split(' ')[1];
        const curTimes = curHour.split(':');
        const messageComment = {
          id: curItem.id,
          comment: curItem.comment,
          hour: `${curTimes[0]}:${curTimes[1]}`,
        };
        // Format the message - END

        if (nextItem && curItem.user_id === nextItem.user_id) {
          messages.push(messageComment);
        }

        if ((nextItem && curItem.user_id !== nextItem.user_id) || !nextItem) {
          messages.push(messageComment);

          // Get the initials - BEGIN
          let username = curItem.user.name.split('(')[0].trim();
          username = username.split(' ');
          let initials = '';
          if (username.length === 1) {
            initials = username[0].substring(0, 2);
          } else if (username.length > 2) {
            initials = `${username[0].substring(0, 1)}${username[username.length - 1].substring(0, 1)}`;
          } else {
            initials = `${username[0].substring(0, 1)}${username[1].substring(0, 1)}`;
          }
          // Get the initials - END

          const userComments: ChatItemProps = {
            key: curItem.id,
            isMe: curItem.user_id === user.id,
            user: {
              name: curItem.user.name,
              initials,
              photo: curItem.user.photo,
            },
            messages,
          };

          all.push(userComments);
          messages = [];
        }
      }
      prev[prevDate] = all;

      return prev;
    }, {});

    return groupedComments;
    // eslint-disable-next-line
  }, [commentsRaw, user.id]);

  const sendComment = useCallback(
    async values => {
      if (!sustainabilityReportId) {
        alertStatus('Não encontrado o número da solicitação de crédito!', 'error');
        return;
      }

      await sustainabilityApi.reports
        .comment(sustainabilityReportId, values)
        .then(() => {
          setBlockLoadComments(false);
          setForceLoadComments(true);
          alertStatus('Comentário enviado com sucesso');
          setPage(1);
        })
        .catch(err => {
          alertStatus(err, 'error');
        })
        .finally(() => {
          setComment('');
          commentForm.resetFields();
        });
    },
    // eslint-disable-next-line
    [alertStatus, commentForm, sustainabilityReportId, getComments],
  );

  return (
    <Spin spinning={loading}>
      <Container>
        <div className="request-comments-container">
          <div className="request-comments-comments">
            <div className="comments-container" ref={scrollBottom}>
              <Spin spinning={false}>
                <div ref={scrollObserve} style={{ marginTop: '10px' }} />
                {Object.keys(comments).length === 0 ? (
                  <EmptyComponent
                    description={t('pages.credit-request.contact-registration.request-has-no-comments')}
                  />
                ) : (
                  Object.keys(comments).map((keyComment: any) => {
                    const date = keyComment.split(' ')[0];
                    const times = date.split('-');
                    let commentDate: ChatItemProps | null = {
                      key: date,
                      date: {
                        key: date,
                        day: Number(times[2]),
                        month: times[1],
                        year: Number(times[0]),
                      },
                    };
                    const commentsList: any = comments;
                    let alreadyHasDate = 0;
                    return commentsList[keyComment].map((messageItem: ChatItemProps) => {
                      alreadyHasDate += 1;
                      if (alreadyHasDate === 2) {
                        commentDate = null;
                      }

                      return (
                        <CommentItem
                          key={`${keyComment}-${messageItem.key}`}
                          date={commentDate ? commentDate.date : null}
                          messages={messageItem.messages}
                          user={messageItem.user}
                          isMe={messageItem.isMe}
                        />
                      );
                    });
                  })
                )}
              </Spin>
            </div>

            <AntForm form={commentForm} onFinish={values => sendComment(values)} className="request-comments-form">
              <FormItem name="comment" extra={`${comment.length}/500`}>
                <AntInput.TextArea
                  rows={3}
                  maxLength={500}
                  onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                    const { value } = event.target;
                    setComment(value);
                  }}
                />
              </FormItem>

              <div className="request-comments-form-item-button">
                <Button htmlType="submit" status="primary" permission="sustainability.comment.store">
                  {t('pages.credit-request.contact-registration.button.send-message')}
                </Button>
              </div>
            </AntForm>
          </div>

          <div className="request-comments-client-data">
            {renderComponent(
              !!sustainabilityResume && !!sustainabilityResume.harvest,
              <>
                <div className="requester-data-container">
                  <div className="requester-name">{sustainabilityResume.sustainability_report_type}</div>
                  <div className="requester-person-type">{sustainabilityResume.product}</div>
                  <div className="requester-person-type">{sustainabilityResume.provider}</div>
                  <div className="requester-person-type">{sustainabilityResume.requester_type}</div>
                  <div className="requester-person-type">{sustainabilityResume.doc_number}</div>
                </div>
                <div className="info-container">
                  <div className="info-extra">
                    {`${t('pages.credit-request.contact-registration.current_status')}:`}
                    <br />
                    {sustainabilityResume.status}
                  </div>
                  <div className="info-extra">
                    {`${t('pages.credit-request.contact-registration.updated_by')}:`}
                    <br />
                    {sustainabilityResume.last_changed_by}
                  </div>
                </div>
              </>,
            )}
          </div>
        </div>
      </Container>
    </Spin>
  );
};

export default Comments;

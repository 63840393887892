import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FaPlus, FaMinus, FaSearch } from 'react-icons/fa';

import { Col, Form, Spin, Radio } from 'antd';
import { Store } from 'antd/lib/form/interface';
import { TableProps, TablePaginationConfig } from 'antd/lib/table';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';

import HarvestData from '../../../@types/data/HarvestData';
import PaginationData from '../../../@types/data/PaginationData';
import { ResponseListData } from '../../../@types/data/ResponseData';
import DTOErrorReponse from '../../../@types/dtos/DTOErrorReponse';
import { Breadcrumb, Button, Input, ModalStatus, FormItem, Row, IconWithTooltip } from '../../../components';
import { TableData } from '../../../compositions';
import { Can } from '../../../hooks/ability';
import { useAuth } from '../../../hooks/auth';
import { usePage } from '../../../hooks/page';
import registerApi from '../../../services/api/register';
import columns from './columns';
import HarvestForm from './HarvestForm';
import { SContainer, SHeader, STitlePage, SFilterContainer, SFormContainer, SFormButtons } from './styles';

const Harvest: React.FC = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const { updateUserData } = useAuth();
  const { alertStatus } = usePage();

  const [loadingButton, setLoadingButton] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);

  const [data, setData] = useState([]);
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [paginationConfig, setPaginationConfig] = useState<TablePaginationConfig>({
    current: 1,
    total: 1,
    pageSize: 20,
  });
  const [visibilityForm, setVisibilityForm] = useState(false);

  const updateData = (row: Store) => {
    let dataUpdated: Array<Store> = data;
    if (data.length > 0) {
      const rowIndex = data.findIndex((item: Store) => row.id === item.id);
      // Se encontrar o item remove e adiciona ele atualizado na mesma posição
      if (rowIndex > -1) {
        dataUpdated.splice(rowIndex, 1, row);
      } else {
        if (dataUpdated.length === paginationConfig.pageSize) {
          // Se não encontrar, remove o último (por conta da paginação) e
          // adiciona o item na primeira posição
          dataUpdated.splice(-1, 1);
        }
        dataUpdated = [row, ...dataUpdated];
      }
    } else {
      dataUpdated.push(row);
    }

    setData(dataUpdated);
  };

  const handleSuccess = (messageSuccess: string) =>
    ModalStatus({
      type: 'success',
      title: t('modal.success'),
      subTitle: messageSuccess,
    });

  const loadTableData = useCallback(
    (values: Partial<HarvestForm & PaginationData<HarvestData>>) => {
      setLoading(true);
      setLoadingButton(true);
      setDisabledButton(true);
      const dataSend = {
        params: {
          per_page: paginationConfig.pageSize,
          ...values,
        },
      };

      registerApi.harvest
        .get(dataSend)
        .then((response: ResponseListData<HarvestData>) => {
          const result = response.data.data;
          setData(result.data);
          setPaginationConfig(paginationConfigState => ({
            ...paginationConfigState,
            current: result.current_page,
            total: result.total,
          }));
        })
        .catch((err: DTOErrorReponse) => alertStatus(err, 'error'))
        .finally(() => {
          setLoading(false);
          setLoadingButton(false);
          setDisabledButton(false);
        });
    },
    [paginationConfig.pageSize, alertStatus],
  );

  const handleDisplayForm = () => {
    setVisibilityForm(!visibilityForm);
    setEditing(false);
    setDisabledButton(false);
    setLoadingButton(false);
    form.resetFields();
  };

  const handleHideForm = () => {
    setVisibilityForm(false);
  };

  const handleRemoveChange = async (record: any) => {
    ModalStatus({
      type: 'delete',
      title: t('pages.register.harvest.delete.title'),
      subTitle: t('pages.register.harvest.delete.subtitle'),
      cancelText: t('pages.register.harvest.delete.cancel'),
      okText: t('pages.register.harvest.delete.confirm'),
      onOk: () => {
        registerApi.harvest
          .destroy(record.id, {
            params: {},
          })
          .then(response => {
            handleSuccess(response.data.message);
            handleTableChange({ current: 1 }, { active: [1] }, {}, null);
          })
          .catch((err: DTOErrorReponse) => alertStatus(err, 'error'));
      },
    });
  };

  const handleRegister = () => {
    setLoading(true);
    setLoadingButton(true);
    setDisabledButton(true);
    form
      .validateFields()
      .then(() => {
        const formValues = form.getFieldsValue();
        registerApi.harvest
          .store({
            ...formValues,
          })
          .then((response: ResponseListData<HarvestData>) => {
            const { data: harvest } = response.data;
            handleSuccess(response.data.message);
            updateData(harvest);
            handleHideForm();
            setLoading(false);
            setLoadingButton(false);
            setDisabledButton(false);
            form.resetFields();
            updateUserData();
          })
          .catch((err: DTOErrorReponse) => {
            alertStatus(err, 'error');
            setLoading(false);
            setLoadingButton(false);
            setDisabledButton(false);
          });
      })
      .catch((err: DTOErrorReponse) => {
        alertStatus(err, 'error');
        setLoading(false);
        setLoadingButton(false);
        setDisabledButton(false);
      });
  };

  const handleEdit = () => {
    setLoading(true);
    setLoadingButton(true);
    setDisabledButton(true);
    form
      .validateFields()
      .then(() => {
        const formValues = form.getFieldsValue();

        registerApi.harvest
          .update(formValues.id, {
            ...formValues,
          })
          .then((response: ResponseListData<HarvestData>) => {
            const { data: harvest } = response.data;
            handleSuccess(response.data.message);
            updateData(harvest);
            handleHideForm();
            setLoading(false);
            setLoadingButton(false);
            setDisabledButton(false);
            form.resetFields();
            updateUserData();
          })
          .catch((err: DTOErrorReponse) => {
            alertStatus(err, 'error');
            setLoading(false);
            setLoadingButton(false);
            setDisabledButton(false);
          });
      })
      .catch((err: DTOErrorReponse) => {
        alertStatus(err, 'error');
        setLoading(false);
        setLoadingButton(false);
        setDisabledButton(false);
      });
  };

  // Table DATA Config: BEGIN
  const getActions = () => ({
    render: (active: number, record: HarvestData) => (
      <>
        <Can I="register.harvest.destroy" a="">
          <IconWithTooltip
            action="destroy"
            title={t('pages.register.harvest.table.icons.remove')}
            onClick={() => handleRemoveChange(record)}
          />
        </Can>
        <Can I="register.harvest.update" a="">
          <IconWithTooltip
            action="edit"
            title={t('pages.register.harvest.table.icons.edit')}
            onClick={() => {
              form.setFieldsValue({
                ...record,
                is_current: record.is_current === t('Yes') || Boolean(record.is_current) === true ? 1 : 0,
              });

              form.scrollToField('name', {
                scrollMode: 'always',
                block: 'start',
                behavior: actions =>
                  actions.forEach(({ el, top }) => {
                    el.scrollTop = top - 280;
                  }),
              });

              setVisibilityForm(true);
              setEditing(true);
              setLoading(false);
              setLoadingButton(false);
              setDisabledButton(false);
            }}
          />
        </Can>
      </>
    ),
  });

  const [searchForm] = Form.useForm();
  const tableConfig = {
    search: '',
    filtered: {},
    sorter: {},
    getActions,
  };

  const [tableDataConfig, setTableDataConfig] = useState(tableConfig);

  const handleFastSearch = () => {
    const value = searchForm.getFieldsValue();
    setTableDataConfig(tableDataConfigState => ({
      ...tableDataConfigState,
      search: value.search,
    }));
    loadTableData({
      search: value.search,
    });
  };

  const tableDataClearAllFilters = () => {
    searchForm.resetFields();
    setTableDataConfig(tableDataConfigState => ({
      ...tableDataConfigState,
      filtered: {},
      search: '',
      sorter: {},
    }));
    loadTableData({ page: 1 });
  };

  const handleTableChange: TableProps<any>['onChange'] = useCallback(
    (
      pagination: TablePaginationConfig,
      filters: Record<string, FilterValue | null>,
      sorter: SorterResult<any> | SorterResult<any>[],
    ) => {
      setLoading(true);
      setTableDataConfig(tableDataConfigState => ({
        ...tableDataConfigState,
        filtered: filters,
        sorter,
      }));

      let direction = '';
      const search: any = {};
      let sort = '';

      if (sorter) {
        const { field, order } = sorter as SorterResult<any>;

        if (order === 'ascend') direction = 'asc';
        if (order === 'descend') direction = 'desc';
        sort = field?.toString() ?? '';
      }

      if (filters) {
        const dataFilter: any = filters;

        if (dataFilter) {
          Object.keys(dataFilter).forEach((key: string) => {
            if (dataFilter[key] !== null && dataFilter[key] !== undefined && dataFilter[key][0]) {
              search[key] = dataFilter[key][0].toString();
            } else if (dataFilter[key] !== null && dataFilter[key] !== undefined) {
              search[key] = dataFilter[key].toString();
            } else {
              search[key] = '';
            }
          });
        }
      }

      setPaginationConfig(paginationConfigState => ({
        ...paginationConfigState,
        pageSize: pagination.pageSize,
        current: pagination.current,
      }));

      loadTableData({
        page: pagination.current,
        per_page: pagination.pageSize,
        sort,
        direction,
        ...search,
      });
    },
    [loadTableData],
  );
  // Table DATA Config: END

  useEffect(() => {
    const filters: any = {};

    handleTableChange({ current: 1, pageSize: paginationConfig.pageSize }, filters, {}, null);
  }, [handleTableChange, paginationConfig.pageSize]);

  return (
    <>
      <Breadcrumb
        items={[{ title: t('breadcrumb.register') }, { title: t('breadcrumb.harvest'), to: '/register/harvest' }]}
      />

      <SContainer>
        <SHeader>
          <STitlePage>{t('pages.register.harvest.title_page')}</STitlePage>

          <Can I="register.harvest.store" a="">
            <Button status="primary" icon={visibilityForm ? <FaMinus /> : <FaPlus />} onClick={handleDisplayForm}>
              {t('pages.register.harvest.buttonAdd')}
            </Button>
          </Can>
        </SHeader>

        <SFormContainer visible={visibilityForm}>
          <Spin spinning={loading}>
            <h2>{t('pages.register.harvest.registerTitle')}</h2>
            <Form form={form}>
              <FormItem name="id" style={{ display: 'none' }}>
                <Input />
              </FormItem>
              <Row gutter={[26, 26]}>
                <Col xs={24} sm={12} md={16} lg={16} xl={16} xxl={16}>
                  <FormItem
                    style={{ paddingRight: '10px' }}
                    label={t('pages.register.harvest.form.name')}
                    name="name"
                    rules={[{ required: true }]}
                  >
                    <Input maxLength={80} type="text" />
                  </FormItem>
                </Col>
                <Col xs={24} sm={12} md={8} lg={8} xl={8} xxl={8}>
                  <FormItem
                    style={{ paddingLeft: '10px' }}
                    label={t('pages.register.harvest.form.is_current')}
                    name="is_current"
                    rules={[{ required: true }]}
                  >
                    <Radio.Group buttonStyle="solid">
                      <Radio.Button value={1}>{t('Yes')}</Radio.Button>
                      <Radio.Button value={0}>{t('No')}</Radio.Button>
                    </Radio.Group>
                  </FormItem>
                </Col>
              </Row>
              <SFormButtons>
                <Button status="secondary" htmlType="reset" onClick={handleHideForm} disabled={disabledButton}>
                  {t('pages.register.harvest.buttonCancel')}
                </Button>
                {editing && (
                  <Button
                    status="primary"
                    htmlType="submit"
                    onClick={handleEdit}
                    loading={loadingButton}
                    disabled={disabledButton}
                  >
                    {t('pages.register.harvest.buttonEdit')}
                  </Button>
                )}
                {!editing && (
                  <Button
                    status="primary"
                    htmlType="submit"
                    onClick={handleRegister}
                    loading={loadingButton}
                    disabled={disabledButton}
                  >
                    {t('pages.register.harvest.buttonRegister')}
                  </Button>
                )}
              </SFormButtons>
            </Form>
          </Spin>
        </SFormContainer>

        <SFilterContainer>
          <Form
            form={searchForm}
            name="filter-harvest"
            className="form-secondary form-filters grid-filters"
            onFinish={handleFastSearch}
          >
            <div className="filter-search">
              <FormItem name="search" label={t('pages.register.harvest.search')}>
                <Input />
              </FormItem>
            </div>
            <div className="filter-button">
              <FormItem label="" className="form-item-without-label">
                <Button status="primary" text="uppercase" htmlType="submit">
                  <FaSearch /> {t('form.actions.search')}
                </Button>
              </FormItem>
            </div>
            <div className="filter-clear">
              <Button status="secondary" size="middle" onClick={tableDataClearAllFilters}>
                {t('pages.register.harvest.buttonClearFilter')}
              </Button>
            </div>
          </Form>
        </SFilterContainer>

        <TableData
          rowKey="id"
          columns={columns.map((column: any) => {
            let columnRender: any = {
              ...column,
            };

            if (column.dataIndex === 'is_current') {
              columnRender = {
                ...columnRender,
                render: (value: any) => (value === 1 || value === true ? t('Yes') : t('No')),
              };
            }

            return columnRender;
          })}
          dataSource={data}
          loading={loading}
          onChange={handleTableChange}
          pagination={paginationConfig}
          tableConfig={tableDataConfig}
        />
      </SContainer>
    </>
  );
};

export default Harvest;

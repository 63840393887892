import styled, { css } from 'styled-components';

interface CardState {
  status?: 'default' | 'warning' | 'danger';
  color?: string | null;
}

const containerStatus = {
  default: css`
    border: 2px solid #ffffff;
  `,
  warning: css`
    border: 2px solid ${({ theme: { colors } }) => colors.warning};
  `,
  danger: css`
    border: 2px solid ${({ theme: { colors } }) => colors.verydanger};
  `,
};

export const Container = styled.div<CardState>`
  display: flex;
  flex-direction: column;
  margin: 0 0 10px 0;
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.19);
  border: 0;
  overflow: hidden;
`;

export const CardBody = styled.div<CardState>`
  display: flex;
  flex-direction: column;
  margin: 0 0 00px 0;
  background: #ffffff;
  border-radius: 4px;

  ${({ status }) => {
    switch (status) {
      case 'warning':
        return containerStatus.warning;
      case 'danger':
        return containerStatus.danger;
      default:
        return containerStatus.default;
    }
  }}
  border-left: 0;

  .card-content-container {
    border-left: solid 4px ${({ color }) => color || 'transparent'};
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
  }

  .card-tag {
    background: ${({ color }) => color || 'transparent'};
    border-radius: 7px;
    color: ${({ theme: { colors } }) => colors.secondary};
    padding: 0 6px;
    font-family: ${({ theme: { fonts } }) => fonts.primary};
    font-size: 12px;
    line-height: 14px;
    height: 14px;
    min-width: 14px;
    padding: 0 8px;
  }
`;

const footerDateStatus = {
  default: css`
    background: #ffffff;
    color: ${({ theme: { colors } }) => colors.text};
  `,
  warning: css`
    background: ${({ theme: { colors } }) => colors.warning};
    color: #ffffff;
  `,
  danger: css`
    background: ${({ theme: { colors } }) => colors.danger};
    color: #ffffff;
  `,
};

const footerRequestedStatus = {
  default: css`
    color: ${({ theme: { colors } }) => colors.warning};
  `,
  warning: css`
    color: ${({ theme: { colors } }) => colors.warning};
  `,
  danger: css`
    color: ${({ theme: { colors } }) => colors.danger};
  `,
};

export const Footer = styled.footer<CardState>`
  display: flex;
  flex-direction: column;

  .footer-visible-always {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    padding: 8px 0 8px;

    > div {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      padding: 4px 4px 4px 8px;
      font: 600 11px/16px ${({ theme: { fonts } }) => fonts.primary};
      flex: 1;
      margin-right: 8px;

      .updated-by {
        font-weight: 400;

        strong.updated-by-name {
          font-weight: 600;
        }
      }

      ${({ status }) => {
        switch (status) {
          case 'warning':
            return footerDateStatus.warning;
          case 'danger':
            return footerDateStatus.danger;
          default:
            return footerDateStatus.default;
        }
      }}
    }

    > button {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 24px;
      margin-right: 12px;
      cursor: pointer;
      background: #ffffff;
      border: none;
      color: ${({ theme: { colors } }) => colors.greys.grey08};
      font-size: 16px;
    }
  }

  .footer-hide-data {
    display: flex;
    flex-direction: column;
    padding: 8px 8px 0;
    border-top: 1px solid ${({ theme: { colors } }) => colors.greys.grey03};

    > div {
      color: ${({ theme: { colors } }) => colors.text};
      font: 600 11px/16px ${({ theme: { fonts } }) => fonts.primary};
      margin-bottom: 8px;
    }

    .money {
      color: ${({ theme: { colors } }) => colors.primary};
      font: 600 11px/16px ${({ theme: { fonts } }) => fonts.primary};
      margin-bottom: 8px;

      ${({ status }) => {
        switch (status) {
          case 'warning':
            return footerRequestedStatus.warning;
          case 'danger':
            return footerRequestedStatus.danger;
          default:
            return footerRequestedStatus.default;
        }
      }}
    }

    .text-muted {
      color: ${({ theme: { colors } }) => colors.greys.grey08};
    }
  }
`;

export const columns: any = [
  {
    title: 'pages.integration.dataProviders.table.header.name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'pages.integration.dataProviders.table.header.origin',
    dataIndex: 'origin',
    key: 'origin',
  },
  {
    title: 'pages.integration.dataProviders.table.header.destiny',
    dataIndex: 'destiny',
    key: 'destiny',
  },
  // {
  //   title: 'pages.integration.dataProviders.table.header.actions',
  //   key: 'action',
  //   dataIndex: 'action',
  //   align: 'center',
  //   width: '130px',
  //   fixed: 'right',
  // },
];

export default columns;

import styled from 'styled-components';

export const LabelWithIcon = styled.div`
  display: flex;
  align-items: top;

  div {
    padding: 0;
  }
`;

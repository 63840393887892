import { useState } from 'react';

import { IntegrationData } from 'context/IntegrationContext';
import api, { handleRequest } from 'services/api/api';

export const useIntegrationRules = () => {
  const [isStoring, setIsStoring] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);

  const storeRule = (ruleData = {}, params = {}) =>
    handleRequest(() => api.post(`/admin/integration-erp-rules`, ruleData, params), setIsStoring);

  const updateRule = (ruleId: string, data = {} as IntegrationData, params = {}) =>
    handleRequest(() => api.put(`/admin/integration-erp-rules/${ruleId}`, data, params), setIsUpdating);

  const getRules = (params = {}) => handleRequest(() => api.get('/admin/integration-erp-rules', params), setIsFetching);

  const removeRule = (ruleId: string, params = {}) =>
    handleRequest(() => api.delete(`/admin/integration-erp-rules/${ruleId}`, params), setIsRemoving);

  return {
    isStoring,
    storeRule,
    isFetching,
    getRules,
    isUpdating,
    updateRule,
    removeRule,
    isRemoving,
  };
};

import React from 'react';

import { useContractContext } from '../../context/ContractContext';
import { AttachmentCompare } from '../AttachmentCompare';
import { ContractStages } from '../modules/ContractStages';
import { FilePreview } from '../modules/FilePreview';
import { RelatedDocuments } from '../modules/RelatedDocuments';
import { Container } from './styles';

export const Body = () => {
  const { isShowingAttachmentCompare } = useContractContext();

  return (
    <>
      {isShowingAttachmentCompare ? (
        <AttachmentCompare />
      ) : (
        <Container>
          <RelatedDocuments />
          <FilePreview />
          <ContractStages />
        </Container>
      )}
    </>
  );
};

import moment, { Moment } from 'moment';

import appConfigs from '../configs/app';

export const Formatter = {
  /**
   * Retorna uma string com valor formatado com separadores de milhar
   * @param value É o valor que será formatado
   * @param thousands É a quantidade de dígitos que serão agrupados
   * @param prefix É o prefixo que o valor formatado pode ter (irá imprimir antes do valor)
   * @param suffix É o sufixo que o valor formatado pode ter (irá imprimir depois do valor)
   * @returns string
   */
  integer: (
    value: string | number,
    thousands: number | null = 3,
    prefix: string | null = null,
    suffix: string | null = null,
  ) => {
    // Cortar zeros desnecessários
    const valueNumberType = Number(value || 0);

    if (Number(valueNumberType) === 0) {
      return '0';
    }

    // Transformar em string para ser usado nos próximos passos
    const valueStringType = valueNumberType.toString();
    // Retirar qualquer formatação e pegar apenas os números
    let formattedValue = valueStringType.replace(/\D/g, '');

    if (thousands !== null) {
      const regexString = new RegExp(`\\B(?=(\\d{${thousands}})+(?!\\d))`, 'g');
      formattedValue = formattedValue.replace(regexString, '.');
    }

    if (prefix !== null) {
      return `${prefix} ${formattedValue}`;
    }

    if (suffix !== null) {
      return `${formattedValue} ${suffix}`;
    }

    return formattedValue;
  },

  /**
   * Retorna uma string com o formato inteiro
   * @returns string
   */
  integerParser: (value: string) => {
    return value.replace(/\D/g, '');
  },

  /**
   * Retorna string com o formato decimal (com separação de milhares e casas decimais)
   * Obs: Não é possível usar prefixo e sufixo ao mesmo tempo
   * @param value É o valor que será formatado
   * @param decimals É a quantidade de casas decimais que o valor terá
   * @param thousands É a quantidade de dígitos que serão agrupados
   * @param prefix É o prefixo que o valor formatado pode ter (irá imprimir antes do valor)
   * @param suffix É o sufixo que o valor formatado pode ter (irá imprimir depois do valor)
   * @param useZero Para definir, se, quando o valor for vazio retornar zero(s) com a formatação
   * @returns string
   */
  decimal: (
    value: string | number,
    decimals: number | null = 2,
    thousands: number | null = 3,
    prefix: string | null = null,
    suffix: string | null = null,
    useZero = false,
  ) => {
    let originalNumber = (value || 0).toString();
    const numbers = Number(originalNumber).toFixed(decimals).replace(/\D/g, '');

    if (!useZero && Number(numbers) === 0) {
      return '0';
    }

    if (decimals === null) {
      decimals = 2;
    }

    let decimalsPart = numbers.slice(-decimals);
    let digitsPart = numbers.substr(0, numbers.length - decimals);

    if (Number.isInteger(Number(originalNumber))) {
      originalNumber = Number(originalNumber).toFixed(decimals);
    }

    const splitted = originalNumber.split('.');
    if (splitted[1]?.toString()) {
      [digitsPart, decimalsPart] = splitted;
      if (splitted[1].length < decimals) {
        decimalsPart = String(splitted[1]).padEnd(decimals, '0');
      }
      if (splitted[1].length > decimals) {
        decimalsPart = splitted[1].slice(0, decimals);
      }
    } else {
      if (decimalsPart.length < decimals) {
        decimalsPart = String(decimalsPart).padStart(decimals, '0');
      }
    }

    if (thousands !== null) {
      const regexString = new RegExp(`\\B(?=(\\d{${thousands}})+(?!\\d))`, 'g');
      digitsPart = Number(digitsPart).toString().replace(regexString, '.');
    }

    const formattedValue = `${digitsPart},${decimalsPart}`;

    if (prefix !== null) {
      return `${prefix} ${formattedValue}`;
    }

    if (suffix !== null) {
      return `${formattedValue} ${suffix}`;
    }

    return formattedValue;
  },

  /**
   * Retorna string com o formato decimal (com separação de milhares e casas decimais)
   * @param value É o valor que será formatado
   * @param decimals É a quantidade de casas decimais que o valor terá
   * @returns string
   */
  decimalParser: (value: string, decimals: number | null = 2) => {
    const numbers = (value || 0).toString().replace(/\D/g, '');

    if (Number(numbers) === 0) {
      return '';
    }

    let decimalsPart = numbers.slice(-(decimals ?? 0));
    const digitsPart = numbers.substr(0, numbers.length - (decimals ?? 0));

    if (Number(decimalsPart) === 0 && Number(digitsPart) === 0) {
      return '';
    }

    if (decimalsPart.length < (decimals ?? 0)) {
      decimalsPart = String(decimalsPart).padStart(decimals ?? 0, '0');
    }

    return `${Number(digitsPart)}.${decimalsPart}`;
  },

  /**
   * Retorna string com o formato monetário
   * @param value É o valor que será formatado
   * @param decimals É a quantidade de casas decimais que o valor terá
   * @param prefix É o prefixo que o valor formatado pode ter (irá imprimir antes do valor)
   * @param useZero Para definir, se, quando o valor for vazio retornar zero(s) com a formatação
   * @returns string
   */
  money: (
    value: string | number | undefined,
    decimals: number | null = 2,
    prefix: string | null = null,
    useZero = false,
  ) => {
    let originalNumber = (value || 0).toString();
    const numbers = originalNumber.replace(/\D/g, '');

    if (!useZero && Number(numbers) === 0) {
      return '';
    }

    if (decimals !== null) {
      let decimalsPart = numbers.slice(-decimals);
      let digitsPart = numbers.substr(0, numbers.length - decimals);

      if (Number.isInteger(Number(originalNumber))) {
        originalNumber = Number(originalNumber).toFixed(decimals);
      }

      const splitted = originalNumber.split('.');
      if (splitted[1]?.toString()) {
        [digitsPart, decimalsPart] = splitted;
        if (splitted[1].length < decimals) {
          decimalsPart = String(splitted[1]).padEnd(decimals, '0');
        }
      } else {
        if (decimalsPart.length < decimals) {
          decimalsPart = String(decimalsPart).padStart(decimals, '0');
        }
      }

      const money = `${Number(digitsPart)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, '.')},${decimalsPart}`;
      const formattedValue = prefix !== null ? `${prefix} ${money}` : money;

      return formattedValue;
    }

    const money = numbers.substr(0, numbers.length - (decimals ?? 0)).replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    const formattedValue = prefix !== null ? `${prefix} ${money}` : money;

    return formattedValue;
  },

  /**
   * Retorna uma string com valor formatado como float
   * @param value É o valor que será parseado para float
   * @param decimals É a quantidade de casas decimais que o valor terá
   * @returns string
   */
  moneyParser: (value: string | undefined, decimals: number | null = 2, emptyZeros = true) => {
    const numbers = (value || 0).toString().replace(/\D/g, '');

    if (Number(numbers) === 0) {
      return emptyZeros ? '' : '0.00';
    }

    if (decimals !== null) {
      let decimalsPart = numbers.slice(-decimals);
      const digitsPart = numbers.substr(0, numbers.length - decimals);

      if (Number(decimalsPart) === 0 && Number(digitsPart) === 0) {
        return '';
      }

      if (decimalsPart.length < decimals) {
        decimalsPart = String(decimalsPart).padStart(decimals, '0');
      }

      return `${Number(digitsPart)}.${decimalsPart}`;
    }

    return numbers;
  },

  /**
   * Formata uma data no formato americano para o formato brasileiro
   * @param value É uma data para ser formatada
   * @param instanceMoment Informa se o @value é uma instância de Moment
   * @returns string | Moment
   */
  date(value: string | Moment, instanceMoment = false): string | Moment {
    if (value === undefined) {
      return '';
    }
    if (instanceMoment) {
      return moment(value, 'YYYY-MM-DD');
    }
    if (value) {
      return moment(value, 'YYYY-MM-DD').format(appConfigs.formatDate).toString();
    }
    return value;
  },

  /**
   * Formata uma data para o formato americano ou o objeto Moment da data
   * @param value É uma data para ser formatada
   * @param instanceMoment Informa se o @value é uma instância de Moment
   * @returns string | Moment
   */
  dateAmerican(value: string | Moment, instanceMoment = false): string | Moment {
    if (value === undefined) {
      return '';
    }
    if (instanceMoment) {
      return moment(value, 'YYYY-MM-DD');
    }
    return value;
  },

  datetimeAmerican(value: string | Moment, instanceMoment = false): string | Moment {
    if (value === undefined) {
      return '';
    }
    if (instanceMoment) {
      return moment(value, 'YYYY-MM-DD h:mm:ss').format(appConfigs.formatDateFullTime).toString();
    }
    return value;
  },

  /**
   * Formata uma data com hora no formato americano para o formato brasileiro
   * @param value É uma data para ser formatada
   * @param instanceMoment Informa se o @value é uma instância de Moment
   * @returns string | Moment
   */
  datetime(value: string | Moment, instanceMoment = false): string | Moment {
    if (value === undefined) {
      return '';
    }
    if (instanceMoment) {
      return moment(value, 'YYYY-MM-DD');
    }
    if (value) {
      return moment(value, 'YYYY-MM-DD h:mm:ss').format(appConfigs.formatDateFullTime).toString();
    }
    return value;
  },

  /**
   * Retorna string com o formato: 00.000-000
   * @returns string
   */
  cep: (value: string | number) => {
    const formattedValue = value
      .toString()
      .replace(/\D/g, '')
      .replace(/\B(?=((\d{3})$)+(?!\d))/, '-')
      .replace(/\B(?=(\d{3})+(?!\d))/, '.')
      .replace(/\B(?=(\d{2}))/, '');

    return formattedValue;
  },

  /**
   * Retorna string com o formato: (00) 0000-0000
   * @returns string
   */
  phoneNumber: (value: string | number) => {
    if (!value) return '';

    let formattedValue = value
      .toString()
      .replace(/\D/g, '')
      .replace(/\B(?=((\d{4})$)+(?!\d))/, '-')
      .replace(/\B(?=(\d{4})+(?!\d))/, ') ')
      .replace(/\B(?=(\d{2}))/, '');
    formattedValue = `(${formattedValue}`;

    return formattedValue;
  },

  /**
   * Retorna string com o formato: (00) 00000-0000
   * @returns string
   */
  cellphoneNumber: (value: string | number) => {
    if (!value) return '';
    let formattedValue = value
      .toString()
      .replace(/\D/g, '')
      .replace(/\B(?=((\d{4})$)+(?!\d))/, '-')
      .replace(/\B(?=(\d{5})+(?!\d))/, ') ')
      .replace(/\B(?=(\d{2}))/, '');
    formattedValue = `(${formattedValue}`;

    return formattedValue;
  },

  /**
   * retorna string com o formato: 2.560,50 kg
   */
  kilogram: (value: string | number, suffix = false, noDecimal = false) => {
    if (!value.toString().includes(',') && !value.toString().includes('.')) value += '00';
    const kilogram = value.toString().replace(/\D/g, '');

    const decimalKilogram = noDecimal
      ? kilogram
      : kilogram.replace(/\B(?=((\d{2})$)+(?!\d))/, ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.');

    return Number(suffix) === 1 ? `${decimalKilogram} kg` : decimalKilogram;
  },
  cpf: (cpf: string) => {
    if (!cpf) return '';
    cpf = cpf.replace(/[^\d]/g, '');

    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  },
  cnpj: (cnpj: string) => {
    if (!cnpj) return '';
    cnpj = cnpj.replace(/[^\d]/g, '');

    return cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5');
  },
  abbreviated: (item: number) => {
    const value = item.toString();
    const format = (convert: any) => {
      convert.toFixed(2);
      parseFloat(convert);
      return convert.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    };
    if (value.length > 9) {
      const convert = Number(value) / 1000000000;
      const valueConvert = format(convert);
      return `${valueConvert} BI`;
    }
    if (value.length <= 9 && value.length > 6) {
      const convert = Number(value) / 1000000;
      const valueConvert = format(convert);
      return `${valueConvert} MI`;
    }
    const valueConvert = parseFloat(value);
    return valueConvert.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
  },
  apiDate: (value: string) => {
    if (!value) return value;

    return moment(value, 'YYYY-MM-DD').format(appConfigs.formatApiDate).toString();
  },
};

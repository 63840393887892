import { Link } from 'react-router-dom';

import styled from 'styled-components';

export const Header = styled(Link)`
  display: flex;
  flex-direction: column;
  padding: 8px 8px 0;

  &:hover {
    cursor: pointer;

    * {
      color: ${({ theme: { colors } }) => colors.primary};
    }
  }

  .header-tiny {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;

    > div:first-of-type {
      color: ${({ theme: { colors } }) => colors.primary};
      padding-right: 8px;
      text-align: left;
    }

    > div:nth-of-type(2) {
      padding-left: 8px;
      text-align: right;

      span {
        border-radius: 7px;
        background: ${({ theme: { colors } }) => colors.verydanger};
        color: #ffffff;
        font-family: ${({ theme: { fonts } }) => fonts.primary};
        font-size: 12px;
        line-height: 14px;
        height: 14px;
        min-width: 14px;
        padding: 0 8px;
      }
    }
  }

  .header-body {
    flex: 1;
  }

  div {
    color: ${({ theme: { colors } }) => colors.text};
    font: 700 12px/16px ${({ theme: { fonts } }) => fonts.primary};

    span {
      font-weight: 400;
      padding-left: 8px;
    }
  }
`;

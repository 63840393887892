import React from 'react';
import { useTranslation } from 'react-i18next';

import logoSmallColor from '../../assets/img/logo_small.png';
import logoHorizontalColor from '../../assets/img/logo.png';
import { LogoContainer, LogoSmall } from './styles';
import { LogoProps } from './types';

const Logo: React.FC<LogoProps> = ({ small, size }) => {
  const { t } = useTranslation();

  return (
    <LogoContainer>
      {!small && <img src={logoHorizontalColor} alt={t('home_site')} />}
      {small && small === true && (
        <LogoSmall size={size}>
          <img src={logoSmallColor} alt={t('home_site')} />
        </LogoSmall>
      )}
    </LogoContainer>
  );
};

export default Logo;

import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FormItem, MaskedInput } from 'components';
import { useCreditRequestContext } from 'context/CreditRequestContext';
import { useFindProvider } from 'pages/CreditRequest/hooks/useFindProvider';

export const JsonDataRequesterPersonDocumentInput = () => {
  const { t } = useTranslation();
  const ref = useRef();
  const { findProvider } = useFindProvider();
  const { creditForm } = useCreditRequestContext();

  const [isLegal, setIsLegal] = useState(false);

  const documentValue = creditForm.getFieldValue('json_data.contract.requester_person_document');

  useEffect(() => {
    setIsLegal(documentValue?.replace(/D/g, '').length > 11);
  }, [documentValue]);

  return (
    <FormItem
      label={
        isLegal
          ? t('pages.credit-request.form.requester_person_cnpj')
          : t('pages.credit-request.form.requester_person_cpf')
      }
      name="json_data.contract.requester_person_document"
      rules={[{ required: true }]}
    >
      {isLegal ? (
        <MaskedInput
          key="legal"
          ref={ref}
          name="json_data.contract.requester_person_document"
          mask={!isLegal ? '111.111.111-11' : '11.111.111/1111-11'}
          placeholder={isLegal ? '__.___.___/____-__' : '___.___.___-__'}
          onBlur={() => {
            if (
              creditForm.getFieldValue('json_data.contract.requester_person_document')?.replace(/\D/g, '').length > 10
            )
              findProvider();
          }}
          onPasteCapture={evt => {
            evt.clipboardData.items[0].getAsString(text => {
              const rawValue = text.replace(/\D/g, '');
              if (rawValue.length <= 11) setIsLegal(false);
              if (rawValue.length > 11) setIsLegal(true);
              creditForm.setFieldsValue({ 'json_data.contract.requester_person_document': rawValue });
            });
          }}
          onKeyDownCapture={evt => {
            const rawValue = evt.currentTarget.value.replace(/\D/g, '');
            if (rawValue.length === 11 && evt.key.match(/\d/g)) setIsLegal(true);
            if (rawValue.length === 12 && evt.key === 'Backspace') setIsLegal(false);
          }}
          onChange={e => {
            if (e.currentTarget.value.length === 0) setIsLegal(false);
          }}
          disabled
        />
      ) : (
        <MaskedInput
          key="physical"
          ref={ref}
          name="json_data.contract.requester_person_document"
          mask="111.111.111-11"
          placeholder="___.___.___-__"
          onBlur={() => {
            if (
              creditForm.getFieldValue('json_data.contract.requester_person_document')?.replace(/\D/g, '').length > 10
            )
              findProvider();
          }}
          onPasteCapture={evt => {
            evt.clipboardData.items[0].getAsString(text => {
              const rawValue = text.replace(/\D/g, '');
              if (rawValue.length <= 11) setIsLegal(false);
              if (rawValue.length > 11) setIsLegal(true);
              creditForm.setFieldsValue({ 'json_data.contract.requester_person_document': rawValue });
            });
          }}
          onKeyDownCapture={evt => {
            const rawValue = evt.currentTarget.value.replace(/\D/g, '');
            if (rawValue.length === 11 && evt.key.match(/\d/g)) setIsLegal(true);
            if (rawValue.length === 12 && evt.key === 'Backspace') setIsLegal(false);
          }}
          onChange={e => {
            if (e.currentTarget.value.length === 0) setIsLegal(false);
          }}
          disabled
        />
      )}
    </FormItem>
  );
};

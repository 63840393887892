import { useTranslation } from 'react-i18next';
import { IconType } from 'react-icons';
import { AiFillFileText, AiFillBell, AiFillCalendar } from 'react-icons/ai';
import { BiCheckDouble, BiTime } from 'react-icons/bi';
import { FaBox, FaFileSignature } from 'react-icons/fa';
import { GiBackwardTime } from 'react-icons/gi';
import { MdClose, MdCloudUpload } from 'react-icons/md';
import { RiSendPlaneFill } from 'react-icons/ri';

import {
  CONTRACT_STATUS_FULLY_SIGNED,
  CONTRACT_STATUS_REISSUE_DRAFT,
  CONTRACT_STATUS_WAITING,
} from 'configs/constants';

import theme from '../../../styles/theme';

export const useContractStatus = () => {
  const { t } = useTranslation();
  const contractStatusData = {
    created: {
      icon: MdCloudUpload,
      color: theme.colors.darkBlue,
      background: theme.colors.lightBlue,
      id: '7dedf1b5-691c-4921-baf2-80500c41dbfd',
      name: 'Criado',
    },
    reissue: {
      icon: MdCloudUpload,
      color: theme.colors.darkBlue,
      background: theme.colors.lightBlue,
      id: '025147a2-600d-410c-b129-e835977a9f80',
      name: 'Reemitir Minuta',
    },
    reissueRefused: {
      icon: MdClose,
      color: theme.colors.danger,
      background: theme.colors.lightRed,
      id: '025147a2-600d-410c-b129-e835977a9f80-refused',
      name: 'Reprovação de Emissão',
    },
    issued: {
      icon: AiFillFileText,
      color: theme.colors.darkBlue,
      background: theme.colors.lightBlue,
      id: '17440652-0882-4dae-a6b8-fe76842fdad1',
      name: 'Minuta Preenchida',
    },
    fullySigned: {
      icon: FaFileSignature,
      color: theme.colors.darkBlue,
      background: theme.colors.lightBlue,
      id: CONTRACT_STATUS_FULLY_SIGNED,
      name: 'Assinado completamente',
    },
    validation: {
      icon: AiFillBell,
      color: theme.colors.warning,
      background: theme.colors.lightOrange,
      id: '2e329a64-2c38-4726-8bd2-cce2903e9488',
      name: 'Em validação (Emissão)',
    },
    validated: {
      icon: BiCheckDouble,
      color: theme.colors.success,
      background: theme.colors.lightGreen,
      id: 'c6436076-82e6-4dce-93ee-9cbad3d3bef1',
      name: 'Emissão Aprovada',
    },
    waiting: {
      icon: BiTime,
      color: theme.colors.warning,
      background: theme.colors.lightOrange,
      id: CONTRACT_STATUS_WAITING,
      name: t('pages.edocuments.status.waiting'),
    },
    sent: {
      icon: RiSendPlaneFill,
      color: theme.colors.darkBlue,
      background: theme.colors.lightBlue,
      id: '80522ca1-1080-4f01-bfc3-786a4fb81f96',
      name: 'Enviado para Assinatura',
    },
    requestNewSignatures: {
      icon: GiBackwardTime,
      color: theme.colors.darkBlue,
      background: theme.colors.lightBlue,
      id: 'e21f2e68-214d-432f-91ea-ff3c1a2d8528',
      name: 'Solicitar Novas Assinaturas',
    },
    requestNewSignaturesRefused: {
      icon: MdClose,
      color: theme.colors.danger,
      background: theme.colors.lightRed,
      id: 'e21f2e68-214d-432f-91ea-ff3c1a2d8528-refused',
      name: 'Reprovação de Assinaturas',
    },
    signature: {
      icon: GiBackwardTime,
      color: theme.colors.darkBlue,
      background: theme.colors.lightBlue,
      id: '63d5ca24-e881-4a8a-a6c9-8d62847dabd8',
      name: 'Assinado Parcialmente',
    },
    finished: {
      icon: GiBackwardTime,
      color: theme.colors.darkBlue,
      background: theme.colors.lightBlue,
      id: 'f32b1a9d-9d3d-43d2-978a-1a14e5ad7b00',
      name: 'Assinado Completamente',
    },
    validationSignatures: {
      icon: AiFillBell,
      color: theme.colors.warning,
      background: theme.colors.lightOrange,
      id: 'd22de461-d5c4-4730-9f54-16c77b616e96',
      name: 'Em Validação (Assinatura)',
    },
    validatedSignatures: {
      icon: BiCheckDouble,
      color: theme.colors.success,
      background: theme.colors.lightGreen,
      id: '4c83a2ad-f137-4535-a440-4ccbc87db142',
      name: 'Assinatura Aprovada',
    },
    stored: {
      icon: FaBox,
      color: theme.colors.greys.grey07,
      background: theme.colors.greys.grey03,
      id: '3f1ea808-2b71-43ac-bb18-c30f74f95f0a',
    },
    overdue: {
      icon: AiFillCalendar,
      color: theme.colors.danger,
      background: theme.colors.lightRed,
      id: 'dca6f316-71e0-4895-a149-0495131f59e6',
    },
    vigent: {
      icon: AiFillCalendar,
      color: theme.colors.danger,
      background: theme.colors.lightRed,
      id: '795629f0-6522-41ae-b5fd-6a6cd684b36f',
      name: 'Em Vigência',
    },
    reissueDraft: {
      icon: MdCloudUpload,
      color: theme.colors.darkBlue,
      background: theme.colors.lightBlue,
      id: CONTRACT_STATUS_REISSUE_DRAFT,
      name: t('pages.contract.status.reissueDraft'),
    },
  } as { [name: string]: { icon: IconType; color: string; background: string; id: string; name?: string } };
  const getContractStatusDataById = (id: string) => {
    const data = Object.entries(contractStatusData).find(([, value]) => value.id === id);
    return data ? data[1] : null;
  };

  return { contractStatusData, getContractStatusDataById };
};

import React, { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { MdClose } from 'react-icons/md';
import { Link } from 'react-router-dom';

import { Input, Form, Modal, Upload } from 'antd';
import { TableProps, TablePaginationConfig } from 'antd/lib/table';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import moment from 'moment';

import DTOErrorReponse from '../../../@types/dtos/DTOErrorReponse';
import DTOPersonLegal from '../../../@types/dtos/person/DTOPersonLegal';
import DTOPersonPhysical from '../../../@types/dtos/person/DTOPersonPhysical';
import { Select, IconWithTooltip, CellStatus, FormItem, Button, Spin } from '../../../components';
import { StatusColors } from '../../../components/CellStatus/types';
import { TableData } from '../../../compositions';
import { Can } from '../../../hooks/ability';
import { useCache } from '../../../hooks/cache';
import { usePage } from '../../../hooks/page';
import personApi from '../../../services/api/person';
import exportExcel from '../../../services/export';
import { CacheOptions } from '../../../types/Hooks/cache';
import { Formatter } from '../../../utils/Formatter';
import columns from './columns';
import { Container, SUpload } from './styles';
import { ListViewProps, PersonDataTable } from './types';

const ListView: React.FC<ListViewProps> = ({ filterData, triggerExport, personType }) => {
  const { t } = useTranslation();
  const [formModalStatus] = Form.useForm();

  const { getPersonOptions, person_stage } = useCache();
  const { alertStatus } = usePage();

  const { Dragger } = Upload;
  const [uploadList, setUploadList] = useState([]);

  const [data, setData] = useState<(DTOPersonPhysical | DTOPersonLegal)[]>([]);
  const [loading, setLoading] = useState(false);
  const [paginationConfig, setPaginationConfig] = useState<TablePaginationConfig>({
    current: 1,
    total: 1,
    pageSize: 20,
  });
  const [statusOptions, setStatusOptions] = useState<CacheOptions[]>([]);
  const [modalStatusVisible, setModalStatusVisible] = useState(false);
  const [modalStatusLoading, setModalStatusLoading] = useState(false);
  const [changingRequestId, setChangingRequestId] = useState('');
  const [personName, setPersonName] = useState('');
  const [nextStatus, setNextStatus] = useState<CacheOptions>({} as CacheOptions);

  async function getBase64(img: any, callback: any) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  function handleChangeUploadFile(info: any) {
    let fileList = [...info.fileList];

    // 1. Limit the number of uploaded files
    // Only to show one recent uploaded files, and old ones will be replaced by the new
    fileList = fileList.slice(-1);

    // 2. Read from response and show file link
    fileList = fileList.map(file => {
      if (file.response) {
        // Component will show file.url as link
        file.url = file.response.url;
      }
      return file;
    });
    setUploadList(fileList);
  }

  function getOriginalName(fileName: string, withExtension = false) {
    const splitName = fileName.split('.');
    const splitLength = splitName.length;
    const extension = splitName[splitLength - 1];
    delete splitName[splitLength - 1];
    const finalFileName = splitName
      .filter((s: string) => !!s)
      .join('_')
      .replace(/ /g, '_');
    return !withExtension ? finalFileName : `${finalFileName}.${extension}`;
  }

  const loadTableData = useCallback(
    (values: PersonDataTable) => {
      setLoading(true);
      personApi[personType]
        .get({
          params: {
            ...values,
          },
        })
        .then(response => {
          const result = response.data.data;
          setData(result.data);
          setPaginationConfig(paginationConfigState => ({
            ...paginationConfigState,
            current: result.current_page,
            total: result.total,
          }));
        })
        .catch((err: DTOErrorReponse) => alertStatus(err, 'error'))
        .finally(() => setLoading(false));
    },
    [personType, alertStatus],
  );

  useEffect(() => {
    setStatusOptions(getPersonOptions('person_stage'));
  }, [getPersonOptions]);

  // Table DATA Config: BEGIN
  const tableConfig = {
    search: '',
    filtered: {},
    sorter: {},
  };

  const [tableDataConfig, setTableDataConfig] = useState(tableConfig);

  const handleTableChange: TableProps<any>['onChange'] = useCallback(
    (
      pagination: TablePaginationConfig,
      filters: Record<string, FilterValue | null>,
      sorter: SorterResult<any> | SorterResult<any>[],
    ) => {
      setLoading(true);
      setTableDataConfig(tableDataConfigState => ({
        ...tableDataConfigState,
        filtered: filters,
        sorter,
      }));

      let direction = '';
      const search: any = {};
      let sort = '';

      if (sorter) {
        const { field, order } = sorter as SorterResult<any>;

        if (order === 'ascend') direction = 'asc';
        if (order === 'descend') direction = 'desc';
        sort = field?.toString() ?? '';
      }

      if (filters) {
        const dataFilter: any = filters;

        if (dataFilter) {
          Object.keys(dataFilter).forEach((key: string) => {
            if (dataFilter[key] !== null && dataFilter[key] !== undefined && dataFilter[key][0]) {
              search[key] = dataFilter[key][0].toString();
            } else if (dataFilter[key] !== null && dataFilter[key] !== undefined) {
              search[key] = dataFilter[key].toString();
            } else {
              search[key] = '';
            }
          });
        }
      }

      setPaginationConfig(paginationConfigState => ({
        ...paginationConfigState,
        pageSize: pagination.pageSize,
        current: pagination.current,
      }));

      loadTableData({
        page: pagination.current,
        per_page: pagination.pageSize,
        sort,
        direction,
        ...search,
      });
    },
    [loadTableData],
  );

  useEffect(() => {
    if (filterData) {
      const filters: any = {};
      const dataFilter: any = filterData;

      if (dataFilter) {
        Object.keys(dataFilter).forEach((key: string) => {
          filters[key] = [dataFilter[key]];
        });
      }

      handleTableChange({ current: 1, pageSize: paginationConfig.pageSize }, filters, {}, null);
    } else {
      handleTableChange({ current: 1, pageSize: paginationConfig.pageSize }, { active: [1] }, {}, null);
    }
  }, [filterData, handleTableChange, paginationConfig.pageSize]);

  useEffect(() => {
    if (triggerExport) {
      const headerExcel = {
        physical: {
          stage: {
            name: t('pages.registered-customers.export.columns.status'),
          },
          name: t('pages.registered-customers.export.columns.name'),
          email: t('pages.registered-customers.export.columns.email'),
          cpf: t('pages.registered-customers.export.columns.cpf'),
          rg: t('pages.registered-customers.export.columns.rg'),
          nationality: t('pages.registered-customers.export.columns.nationality'),
          age: t('pages.registered-customers.export.columns.age'),
          city: {
            name: t('pages.registered-customers.export.columns.city'),
          },
          state: t('pages.registered-customers.export.columns.state'),
          json_data: {
            cel_number: t('pages.registered-customers.export.columns.cel_number'),
            irpf_annual_income: t('pages.registered-customers.export.columns.irpf_annual_income'),
            customer_cofco_years: t('pages.registered-customers.export.columns.customer_cofco_years'),
            own_planting_resource: t('pages.registered-customers.export.columns.own_planting_resource'),
          },
          structure_conservation: {
            name: t('pages.registered-customers.export.columns.structure_conservation'),
          },
          technological_level: {
            name: t('pages.registered-customers.export.columns.technological_level'),
          },
          productivity: {
            name: t('pages.registered-customers.export.columns.productivity'),
          },
          activity_diversification: {
            name: t('pages.registered-customers.export.columns.activity_diversification'),
          },
          managerial_skill: {
            name: t('pages.registered-customers.export.columns.managerial_skill'),
          },
          credit_history: {
            name: t('pages.registered-customers.export.columns.credit_history'),
          },
          created_at: t('pages.registered-customers.export.columns.created_at'),
        },
        legal: {
          stage: {
            name: t('pages.registered-customers.export.columns.status'),
          },
          fantasy_name: t('pages.registered-customers.export.columns.fantasy_name'),
          company_name: t('pages.registered-customers.export.columns.company_name'),
          cnpj: t('pages.registered-customers.export.columns.cnpj'),
          city: {
            name: t('pages.registered-customers.export.columns.city'),
          },
          state: t('pages.registered-customers.export.columns.state'),
          json_data: {
            cel_number: t('pages.registered-customers.export.columns.cel_number'),
          },
          created_at: t('pages.registered-customers.export.columns.created_at'),
        },
      };

      const formatExcel = {
        physical: { created_at: '__formatDateTime' },
        legal: { created_at: '__formatDateTime' },
      };

      exportExcel(
        `${moment().format('YYYYMMDD-hhmmss')}-person-${personType}`,
        data,
        headerExcel[personType],
        formatExcel[personType],
      );
    }
  }, [personType, triggerExport, data, t]);

  async function handleChangeStatus() {
    setModalStatusLoading(true);
    const dataForm = formModalStatus.getFieldsValue();
    if (!dataForm.stage_id) {
      dataForm.stage_id = nextStatus?.value;
    }

    await personApi[personType]
      .updateStatus(changingRequestId, dataForm)
      .then(() => {
        const stage: any = person_stage.find(f => f.id === dataForm.stage_id);
        setData(
          data.map(row => {
            if (row.id === changingRequestId) {
              return { ...row, stage_id: dataForm.stage_id, stage };
            }
            return row;
          }),
        );
      })
      .finally(() => {
        setModalStatusVisible(false);
        setModalStatusLoading(false);
        setChangingRequestId('');
        setNextStatus({} as CacheOptions);
        formModalStatus.resetFields();
      });
  }

  const displayModalChangeStatus = useCallback(async (id: string, name: string) => {
    setChangingRequestId(id);
    setPersonName(name);
    setModalStatusVisible(true);
  }, []);

  return (
    <Container>
      <TableData
        rowKey="id"
        className="table-with-status"
        columns={columns[personType].map(col => {
          let column: any = {
            ...col,
          };

          switch (column.key) {
            case 'company_name':
              column = {
                ...col,
                render: (_: any, row: DTOPersonLegal) => {
                  if (row.company_name) {
                    return `${row.company_name.slice(0, 30)}${row.company_name.length > 30 ? '...' : ''}`;
                  }
                  return '-';
                },
              };
              break;
            case 'name':
              column = {
                ...col,
                render: (_: any, row: DTOPersonPhysical) => {
                  if (row.name) {
                    return `${row.name.slice(0, 30)}${row.name.length > 30 ? '...' : ''}`;
                  }
                  return '-';
                },
              };
              break;
            case 'phone':
              column = {
                ...col,
                render: (_: any, row: DTOPersonPhysical) => {
                  if (row.json_data) {
                    if (row.json_data?.cel_number && row.json_data?.phone_number) {
                      return [
                        Formatter.cellphoneNumber(row.json_data?.cel_number.replaceAll(/\D/g, '')),
                        Formatter.cellphoneNumber(row.json_data?.phone_number.replaceAll(/\D/g, '')),
                      ].join(', ');
                    }

                    if (row.json_data?.cel_number) {
                      return Formatter.cellphoneNumber(row.json_data?.cel_number.replaceAll(/\D/g, ''));
                    }

                    if (row.json_data?.phone_number) {
                      return Formatter.cellphoneNumber(row.json_data?.phone_number.replaceAll(/\D/g, ''));
                    }
                  }
                  return '-';
                },
              };
              break;
            case 'code_erp':
              column = {
                ...col,
                render: (_: any, row: DTOPersonPhysical | DTOPersonLegal) => {
                  if (row.json_data) {
                    if (row.json_data?.code_erp) {
                      return row.json_data?.code_erp;
                    }
                  }
                  return '';
                },
              };
              break;
            case 'email':
              column = {
                ...col,
                render: (_: any, row: DTOPersonPhysical) => {
                  return row.email || '-';
                },
              };
              break;
            case 'created_at':
              column = {
                ...col,
                render: (_: any, row: DTOPersonPhysical) => {
                  return row.created_at ? Formatter.datetime(row.created_at) : '-';
                },
              };
              break;
            case 'stage':
              column = {
                ...column,
                render: (_: any, row: DTOPersonPhysical | DTOPersonLegal) => {
                  let status: StatusColors | null = null;

                  if (row.stage) {
                    switch (row.stage.slug) {
                      case 'draft':
                        status = 'status01';
                        break;
                      case 'pending':
                        status = 'status02';
                        break;
                      case 'registered':
                        status = 'status05';
                        break;
                      case 'canceled':
                        status = 'status09';
                        break;
                      default:
                        status = null;
                    }
                  }

                  return (
                    <CellStatus status={status}>
                      <Select
                        allowClear={false}
                        onChange={(value, option: any) => {
                          setNextStatus(option as CacheOptions);
                          formModalStatus.setFieldsValue({ stage_id: value });

                          if (personType === 'legal') {
                            const { company_name } = row as DTOPersonLegal;
                            displayModalChangeStatus(row.id, company_name);
                          } else if (personType === 'physical') {
                            const { name } = row as DTOPersonPhysical;
                            displayModalChangeStatus(row.id, name);
                          }
                        }}
                        options={statusOptions}
                        value={row.stage_id}
                      />
                    </CellStatus>
                  );
                },
              };
              break;
            case 'action':
              column = {
                ...column,
                render: (active: number, record: any) => (
                  <>
                    <Link to={`/registered-customers/${personType}/view/${record.id}`}>
                      <IconWithTooltip action="view" title={t('pages.registered-customers.table.icons.detail')} />
                    </Link>

                    <Can I={`person.${personType}.update`} a="">
                      <Link to={`/registered-customers/${personType}/edit/${record.id}`}>
                        <IconWithTooltip action="edit" title={t('pages.registered-customers.table.icons.edit')} />
                      </Link>
                    </Can>
                  </>
                ),
              };
              break;
            default:
              return column;
          }

          return column;
        })}
        dataSource={data}
        loading={loading}
        onChange={handleTableChange}
        pagination={paginationConfig}
        tableConfig={tableDataConfig}
      />

      <Modal
        visible={modalStatusVisible}
        title={t(`pages.registered-customers.modal-status-change.title-${personType === 'legal' ? 'pj' : 'pf'}`)}
        className="modal-with-custom-footer"
        closeIcon={
          <MdClose
            onClick={() => {
              setModalStatusVisible(false);
              setModalStatusLoading(false);
              formModalStatus.resetFields();
              setUploadList([]);
            }}
          />
        }
        footer={
          <>
            <div className="buttons">
              <Button
                status="secondary"
                onClick={() => {
                  setModalStatusVisible(false);
                  setModalStatusLoading(false);
                  formModalStatus.resetFields();
                  setUploadList([]);
                }}
                disabled={modalStatusLoading}
              >
                {t('pages.registered-customers.kanban.modals.buttons.cancel')}
              </Button>

              <Button
                status="primary"
                onClick={async () => {
                  await handleChangeStatus();
                }}
                loading={modalStatusLoading}
                disabled={modalStatusLoading}
              >
                {t('pages.registered-customers.kanban.modals.buttons.save')}
              </Button>
            </div>
          </>
        }
      >
        <Spin spinning={modalStatusLoading}>
          <p>
            {t('pages.registered-customers.modal-status-change.description').replace('{person}', personName)}
            {'  '}
            <strong>{t(`pages.registered-customers.kanban.status.${nextStatus.slug}`)}</strong>
          </p>
          <br />

          <p>{t('pages.credit-request.modal-status-change.description2')}</p>
          <br />

          <Form
            form={formModalStatus}
            name="status-change-form"
            className="form-secondary"
            initialValues={{ stage_id: nextStatus?.value }}
          >
            <FormItem hidden name="stage_id" initialValue={nextStatus?.value}>
              <Input />
            </FormItem>

            <FormItem name="comments" label={t('pages.credit-request.modal-status-change.comments')}>
              <Input.TextArea autoSize={{ minRows: 5, maxRows: 10 }} />
            </FormItem>

            <FormItem hidden name="documents">
              <Input />
            </FormItem>

            <>
              <SUpload>
                <Dragger
                  showUploadList
                  multiple
                  action={(file: any) => {
                    getBase64(file, (documentBase64: any) => {
                      const files =
                        formModalStatus.getFieldValue('documents') !== undefined
                          ? formModalStatus.getFieldValue('documents')
                          : [];
                      formModalStatus.setFieldsValue({
                        documents: [
                          ...files,
                          {
                            image: documentBase64,
                            mime: file.type,
                            name: getOriginalName(file.name, true),
                            original: getOriginalName(file.name),
                            label: 'status',
                          },
                        ],
                      });
                    });
                    return file;
                  }}
                  onChange={handleChangeUploadFile}
                  fileList={uploadList}
                  customRequest={() => 'done'}
                >
                  {t('pages.registered-customers.form.pf.documents.dragText')}
                </Dragger>
              </SUpload>
            </>
          </Form>
        </Spin>
      </Modal>
    </Container>
  );
};

export default ListView;

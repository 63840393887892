import styled from 'styled-components';

export const Container = styled.div`
  border: 0;
  padding: 16px 0;

  & + div {
    border-top: 1px solid ${({ theme: { colors } }) => colors.greys.grey02};
  }

  strong {
    font: 700 14px/18px ${({ theme: { fonts } }) => fonts.primary};
  }

  p {
    font: 400 14px/18px ${({ theme: { fonts } }) => fonts.primary};
  }
`;

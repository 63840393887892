import { PaginatedQueryParams, usePaginatedQuery } from 'hooks/usePaginatedQuery';
import { SelectInfiniteQueryParams, useSelectInfiniteQuery } from 'hooks/useSelectInfiniteQuery';
import PaginationData from 'types/Api/PaginationData';
import { RecipientTypeData } from 'types/CreditRequest/RecipientTypeData';

export const useGetRecipientTypeQuery = (params?: PaginatedQueryParams) => {
  return usePaginatedQuery<RecipientTypeData>({
    queryKey: ['recipient-type'],
    staleTime: 1000 * 60 * 5,
    url: `recipient-type`,
    permissions: 'credit.request.envelope.index',
    params: {
      ...params,
    },
  });
};

export const useGetRecipientTypeOptionsQuery = (params: SelectInfiniteQueryParams) => {
  const query = useSelectInfiniteQuery<PaginationData<RecipientTypeData>>(
    'recipient-type',
    {
      queryKey: ['recipient-type'],
      staleTime: 1000 * 60 * 5,
      permissions: ['credit.request.envelope.index'],
    },
    params,
  );

  return query;
};

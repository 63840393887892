import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import creditApi from 'services/api/credit';

export const useGetContractVolume = () => {
  const { i18n } = useTranslation();

  const getContractVolume = useCallback(async (contractNumber: string) => {
    const response = await creditApi.requests.contractVolume(contractNumber, {
      params: { language: i18n.language },
    });
    return response.data;
  }, []);
  return {
    getContractVolume,
  };
};

import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdDeleteSweep, MdNotificationsNone } from 'react-icons/md';

import { Empty } from 'antd';
import moment from 'moment';

import { Spin } from '../../components';
import appConfigs from '../../configs/app';
import { useNotifications } from '../../hooks/notifications';
import renderComponent from '../../utils/RenderComponent';
import {
  Container,
  LinkNotification,
  LinksNotificationContainer,
  NotificationsContainer,
  Notification,
} from './styles';

const Notifications: React.FC = () => {
  const { t, i18n } = useTranslation();

  const {
    handleMenuClick,
    handleReadAll,
    handleRemoveAll,
    loadingNotifications,
    getAllNotifications,
    totalPagesAllNotifications,
    allNotifications,
  } = useNotifications();

  const scrollObserve = useRef();
  const scrollBottom = useRef<null | HTMLDivElement>(null);

  const [scrollRadio, setScrollRadio] = useState(null);
  const [page, setPage] = useState(1);

  useEffect(() => {
    getAllNotifications(1);
    // eslint-disable-next-line
  }, []);

  const intersectionObserver = new IntersectionObserver(entries => {
    const radio = entries[0].intersectionRatio;
    setScrollRadio(Math.round(radio));
  });

  useEffect(() => {
    intersectionObserver.observe(scrollObserve.current);
    return () => {
      intersectionObserver.disconnect();
    };
    // eslint-disable-next-line
  }, [intersectionObserver]);

  useEffect(() => {
    const newPage = page + 1;

    if (scrollRadio > 0 && newPage <= totalPagesAllNotifications) {
      setPage(newPage);
      getAllNotifications(newPage);
    }
    // eslint-disable-next-line
  }, [scrollRadio, getAllNotifications, page, totalPagesAllNotifications]);

  return (
    <Container ref={scrollBottom}>
      <h1>{t('pages.notifications.title')}</h1>

      {renderComponent(
        allNotifications.length > 0,
        <>
          <LinksNotificationContainer>
            <LinkNotification
              onClick={() => {
                handleReadAll(() => getAllNotifications(1));
              }}
            >
              <MdNotificationsNone /> {t('pages.notifications.readAll')}
            </LinkNotification>
            <LinkNotification
              onClick={() => {
                handleRemoveAll(() => getAllNotifications(1));
              }}
            >
              <MdDeleteSweep /> {t('pages.notifications.removeAll')}
            </LinkNotification>
          </LinksNotificationContainer>

          <NotificationsContainer>
            {allNotifications.map(notification => (
              <Notification
                key={notification.id}
                onClick={() => handleMenuClick(notification.id, () => false, true)}
                className={!notification.read_at ? 'not-read' : ''}
              >
                <span>
                  {typeof notification.data.title === 'string'
                    ? notification.data.title
                    : notification.data.title[i18n.language]}
                </span>
                <span>
                  {typeof notification.data.body === 'string'
                    ? notification.data.body
                    : notification.data.body[i18n.language]}
                </span>
                <div>
                  <strong>{notification.data.data?.notifiable?.name}</strong>
                  <span>{moment(notification.created_at).format(appConfigs.formatDateFullTime)}</span>
                </div>
              </Notification>
            ))}
          </NotificationsContainer>
        </>,
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('pages.notifications.empty')} />,
      )}

      <div ref={scrollObserve} />
      <Spin spinning={loadingNotifications}>
        <div style={{ width: '100%', height: '32px' }} />
      </Spin>
    </Container>
  );
};

export default Notifications;

import { useState } from 'react';

import { useAbility } from 'hooks/ability';

import DTOErrorReponse from '../../@types/dtos/DTOErrorReponse';
import { handleRequest } from '../../services/api/api';
import monitoringApi from '../../services/api/monitoring';
import { ItemsType, usePage } from '../page';

export const useSurvey = () => {
  const { alertStatus } = usePage();
  const ability = useAbility();

  const [isFetching, setIsFetching] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const getAll = async (params: object) => {
    const response = await handleRequest(() => monitoringApi.survey.get(params), setIsFetching);
    return response ? response.data : response;
  };

  const get = async (id: string, params: object) => {
    const response = await handleRequest(() => monitoringApi.survey.find(id, params), setIsFetching);
    return response ? response.data : response;
  };

  const getTypes = async () => {
    const response = ability.can('monitoring.survey.type.index', '')
      ? await handleRequest(() => monitoringApi.survey.type.get(), setIsFetching)
      : null;
    const types = response ? response.data : [];
    return types.map((type: { id: any; name: any }) => ({
      key: type.id,
      value: type.id,
      label: type.name,
    }));
  };

  const store = async (survey: any) => handleRequest(() => monitoringApi.survey.store(survey), setIsFetching);

  const update = async (id: string, survey: any) =>
    handleRequest(() => monitoringApi.survey.update(id, survey), setIsUpdating);

  const getStages = async (params = {}) => {
    const response = ability.can('monitoring.survey.stage.index', '')
      ? await handleRequest(() => monitoringApi.survey.stage.get(params), setIsFetching)
      : null;
    return response ? response.data : response;
  };

  const getStageOptions = async (params = {}) => {
    const response = await getStages(params);
    if (!response) return { data: [] as ItemsType[], lastPage: 1 };

    return {
      data: response.map(
        (stage: any) =>
          ({
            key: stage.id,
            value: stage.id,
            label: stage.name,
            order: stage.order,
          } as ItemsType),
      ),
      lastPage: response.last_page,
    };
  };

  const getHighRiskList = async (params = {}) => {
    try {
      setIsFetching(true);
      const response = await monitoringApi.survey.highRisk(params);
      return response.data;
    } catch (err) {
      alertStatus(err as DTOErrorReponse, 'error');
      return null;
    } finally {
      setIsFetching(false);
    }
  };

  return {
    isFetching,
    getTypes,
    isUpdating,
    update,
    store,
    getAll,
    find: get,
    getStages,
    getStageOptions,
    getHighRiskList,
  };
};

import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormItem, Input } from 'components';

export const BPCodeInput = () => {
  const { t } = useTranslation();

  return (
    <FormItem label={t('pages.credit-request.form.bp_code')} name="code_bp">
      <Input disabled />
    </FormItem>
  );
};

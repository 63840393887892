import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding-top: 16px;
  max-height: calc(100vh - 130px);

  h2 {
    margin-top: 8px;
    margin-bottom: 16px;
  }

  .ant-spin-nested-loading {
    width: 100%;
  }

  .historic-status-bar-container {
    display: flex;
    justify-content: flex-start;
    flex-wrap: nowrap;
    flex: 1;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    min-height: 150px;

    .historic-empty {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .historic-status-bar-item {
      display: flex;
      align-items: center;
      flex: 2;
      justify-content: flex-start;
      position: relative;
      height: 120px;
      width: 100%;
      min-width: 240px;

      &:first-of-type {
        margin-left: 100px;
      }

      &:last-of-type {
        flex: 0.5;
        margin-right: 50px;
        min-width: 180px;
      }

      &:first-of-type:last-of-type {
        flex: 1;
      }

      .historic-status-ball {
        background: ${({ theme: { colors } }) => colors.primary};
        border-radius: 50%;
        height: 18px;
        width: 18px;
      }

      .historic-status-ball-label-top {
        position: absolute;
        top: 0;
        left: -50%;
        text-align: center;
        width: 100%;
        font-weight: 700;
        line-height: 1;
        color: ${({ theme: { colors } }) => colors.secondary};
        display: flex;
        justify-content: center;

        div {
          max-width: 180px;
        }
      }

      .historic-status-ball-label-bottom {
        position: absolute;
        bottom: 14px;
        left: -50%;
        text-align: center;
        width: 100%;
        color: ${({ theme: { colors } }) => colors.secondary};
        line-height: 1;
      }

      .historic-status-bar {
        background: #ffa23c;
        width: 100%;
        height: 5px;
        position: relative;
      }

      .historic-status-bar-label-top {
        position: absolute;
        bottom: 9px;
        left: 0;
        text-align: center;
        width: 100%;
        font-weight: 500;
        line-height: 1;
        color: ${({ theme: { colors } }) => colors.secondary};
      }

      .historic-status-bar-label-bottom {
        position: absolute;
        top: 8px;
        left: 0;
        text-align: center;
        width: 100%;
        font-weight: 500;
        line-height: 1;
        color: ${({ theme: { colors } }) => colors.secondary};
      }
    }
  }

  .historic-changelog-container {
    padding: 0 8px;
    max-height: calc(100vh - 280px);
    overflow-y: auto;
    width: 100%;
  }
`;

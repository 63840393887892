import CreditRequestData from 'types/CreditRequest/CreditRequestData';
import { LimitRulesData } from 'types/CreditRequest/LimitRulesData';

export const useCheckRequestHasLimitRules = () => {
  /**
   *
   * @returns TRUE se a solicitação tiver um status e esse status estiver habilitado dentro do "rules_limit"
   * e a solicitação tiver o "operation_id" igual ao "operation_id" do usuário logado
   * e do "rules_limit" que tem o mesmo id de team status
   */
  const checkRequestHasLimitRules = (data: CreditRequestData) => {
    if (data.team_status) {
      if (data.team_status.rules_limit) {
        return data.team_status.rules_limit.some((row: LimitRulesData) => {
          if (row.team_credit_status_id === data.team_status.id) {
            if (row.operation_id === data.operation_id) {
              return true;
            }
          }
          return false;
        });
      }
    }
    return false;
  };

  return {
    checkRequestHasLimitRules,
  };
};

import { Form } from 'antd';
import styled from 'styled-components';

import { Input } from '../../../../../../../../components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  .ant-col .ant-form-item-label {
    white-space: normal;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background: none;
  }

  .ant-form-item-control {
    width: 100%;
  }
`;

export const FormItem = styled(Form.Item)`
  flex-direction: column !important;
  align-items: start;
`;

export const SInput = styled(Input)`
  background: none !important;

  input {
    background: none !important;
  }
`;

export const TableFields = styled.div`
  border: 1px dashed ${({ theme: { colors } }) => colors.primary};
  padding: 0px 10px;
`;

export const FieldsWrapper = styled.div`
  input {
    background: none !important;
  }
`;

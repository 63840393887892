import React from 'react';
import { useTranslation } from 'react-i18next';

import { InputNumber } from 'antd';
import { FormItem } from 'components';
import { useCreditRequestStore } from 'pages/CreditRequest/store/useCreditRequestStore';
import { Formatter } from 'utils/Formatter';

interface Props {
  disabled?: boolean;
  disableOnChange?: boolean;
  required?: boolean;
}

export const RequestedAmountBarterInput = ({ required, disabled, disableOnChange }: Props) => {
  const { t } = useTranslation();

  const setIsBlocking = useCreditRequestStore(state => state.setIsBlocking);

  return (
    <FormItem
      label={t('pages.credit-request.form.requested_amount_barter')}
      name="requested_amount_barter"
      rules={[{ required }]}
    >
      <InputNumber<string>
        min="0"
        formatter={(value: string | number) => Formatter.money(value)}
        parser={(value: string) => Formatter.moneyParser(value)}
        disabled={disabled}
        onChange={value => {
          if (value && !disableOnChange) {
            setIsBlocking(true);
          }
        }}
      />
    </FormItem>
  );
};

import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormItem, Select } from 'components';
import { useCreditRequestStore } from 'pages/CreditRequest/store/useCreditRequestStore';

export const JsonDataWarehouseCityIdSelect = () => {
  const { t } = useTranslation();

  const setIsBlocking = useCreditRequestStore(state => state.setIsBlocking);
  const custodyWarehouseAddressIsLegacy = useCreditRequestStore(state => state.custodyWarehouseAddressIsLegacy);
  const citiesWarehouse = useCreditRequestStore(state => state.citiesWarehouse);
  const stateIsSelected = useCreditRequestStore(state => state.stateIsSelected);
  const pageStatus = useCreditRequestStore(state => state.pageStatus);

  return (
    <FormItem
      label={t('pages.credit-request.form.json_data_warehouse_city_id')}
      name="json_data.warehouse_city_id"
      rules={[{ required: custodyWarehouseAddressIsLegacy }]}
      hidden={!custodyWarehouseAddressIsLegacy}
    >
      <Select
        options={citiesWarehouse}
        disabled={pageStatus.viewing || !stateIsSelected}
        onChange={(value: any) => {
          if (value) {
            setIsBlocking(true);
          }
        }}
      />
    </FormItem>
  );
};

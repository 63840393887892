/* eslint-disable react/no-unused-prop-types */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ColumnDef } from '@tanstack/react-table';
import { useDeleteAttachment } from 'api/useDeleteAttachment';
import { useDownloadAttachment } from 'api/useDownloadAttachment';
import { useGetAttachmentWithWatermarkMutation } from 'api/useGetAttachmentWithWatermarkMutation';
import { IconWithTooltip } from 'components';
import { checkIfFilePreviewerSupportsExtension, FilePreviewer } from 'components/FilePreviewer';
import { useAlertDialog } from 'components/ui/alert-dialog';
import { Button } from 'components/ui/button';
import { DataTable } from 'components/ui/data-table';
import { useToast } from 'components/ui/use-toast';
import { Can } from 'hooks/ability';
import { useCache } from 'hooks/cache';
import { useDownload } from 'hooks/helpers/useDownload';
import { cn } from 'lib/utils';
import { downloadHandler } from 'services/download';
import { DocumentData } from 'types/Api/DocumentData';
import { Formatter } from 'utils/Formatter';

export type AttachmentTableProps = {
  files: DocumentData[];
  // removeFile: (id: string) => void;
  children?: React.ReactNode;
  isReadonly?: boolean;
  columnsConfig?: {
    type?: boolean;
  };
  enableWatermark?: boolean;
  hideDownloadAll?: boolean;
  hideDownloadNotDownloaded?: boolean;
};

type AttachmentTableButtonProps = {
  attachment?: DocumentData;
  callback?: (id: string, data: DocumentData) => void;
  isVisible?: boolean;
  isReadonly?: boolean;
  enableWatermark?: boolean;
};

export type AttachmentTableDownloadProps = AttachmentTableButtonProps;
export type AttachmentTablePreviewProps = Omit<AttachmentTableButtonProps, 'callback'>;
export type AttachmentTableDeleteProps = AttachmentTableButtonProps;

export const AttachmentTable = ({
  files,
  children,
  columnsConfig,
  isReadonly,
  enableWatermark,
  hideDownloadAll,
  hideDownloadNotDownloaded,
}: AttachmentTableProps) => {
  const { t } = useTranslation();
  const cache = useCache();
  const { downloadMultiple } = useDownload();
  const { alert } = useAlertDialog();
  const { toast } = useToast();

  const [downloadedFiles, setDownloadedFiles] = React.useState<string[]>([]);

  const additionalColumns: ColumnDef<any>[] = [];

  const columns: ColumnDef<any>[] = [
    {
      header: t('pages.registered-customers.form.pf.documents.name'),
      accessorKey: 'name',
    },
    {
      header: t('pages.registered-customers.form.pf.documents.user'),
      accessorKey: 'user',
      cell: ({ row }) => {
        const attachment = row.original as DocumentData;
        return attachment.user?.name || '';
      },
    },
    {
      accessorKey: 'created_at',
      header: t('pages.registered-customers.form.pf.documents.date'),
      cell: ({ row }) => {
        const attachment = row.original as DocumentData;
        return Formatter.datetime(attachment.created_at);
      },
    },
    {
      id: 'actions',
      enablePinning: true,
      accessorKey: 'action',
      header: t('actions'),
      cell: ({ row }) => {
        const attachment = row.original;

        const parsedChildren = React.Children.map(children, child => {
          if (React.isValidElement(child)) {
            if (true) {
              const callback: AttachmentTableButtonProps['callback'] =
                child.type === AttachmentTableDownload
                  ? (id, data) => {
                      if (child.props.callback) child.props.callback(id, data);
                      markAsDownloaded(id);
                    }
                  : child.props.callback;

              return React.cloneElement(child, {
                ...child.props,
                attachment: {
                  ...attachment,
                  downloads_user: downloadedFiles.includes(attachment.id) ? 1 : 0,
                },
                isReadonly,
                callback,
                isPresigned: enableWatermark,
              });
            }
          }
          return child;
        });

        return <div className="flex items-center max-w-min flex-nowrap">{parsedChildren}</div>;
      },
    },
  ];

  if (columnsConfig?.type) {
    additionalColumns.push({
      header: t('file-identification'),
      accessorKey: 'type_id',
      cell: ({ row }) => {
        const attachment = row.original as DocumentData;
        const type = cache.getCreditOptions('document_type_credit').find(t => t.value === attachment.type_id);
        return type?.label ?? '';
      },
    });
  }

  const markAsDownloaded = (id: string) => {
    const index = files.findIndex(file => file.id === id);
    if (index !== -1) {
      setDownloadedFiles([...downloadedFiles, id]);
    }
  };

  const handleDownloadNotDownloaded = () => {
    const filesToDownload = files.filter(file => !downloadedFiles.includes(file.id));
    handleDownloadAll(filesToDownload);
  };

  const handleDownloadAll = async (filesToDownload?: DocumentData[]) => {
    const { dismiss } = toast({
      title: t('messages.downloadingAllFiles'),
      description: `🕒 ${t('can-take-a-few-seconds')}`,
    });
    try {
      await downloadMultiple(filesToDownload.map(document => document.id));

      filesToDownload.forEach(file => markAsDownloaded(file.id));
    } catch (err) {
      alert({
        type: 'error',
        title: t('error'),
        description: t('error-download-all-files'),
      });
    }
    dismiss();
  };

  return (
    <>
      <DataTable
        columns={[...columns.slice(0, columns.length - 1), ...additionalColumns, columns[columns.length - 1]]}
        data={files || []}
      />
      {files.length ? (
        <div className="mt-2 flex items-center gap-2">
          {hideDownloadAll ? null : (
            <Button
              type="button"
              onClick={() => handleDownloadAll(files)}
              permissions="credit.request.package.download"
            >
              {t('downloadAll')}
            </Button>
          )}
          {hideDownloadNotDownloaded ? null : (
            <Button type="button" onClick={handleDownloadNotDownloaded} permissions="credit.request.package.download">
              {t('downloadNotDownloaded')}
            </Button>
          )}
        </div>
      ) : null}
    </>
  );
};

export const AttachmentTableDownload = ({ attachment, callback, isVisible = true }: AttachmentTableButtonProps) => {
  const { t } = useTranslation();

  const mutationDownloadFile = useDownloadAttachment();

  const handleDownloadFile = async (record: DocumentData) => {
    if (record.id) {
      await mutationDownloadFile.mutateAsync(record.id, {
        onSuccess: (response: File) => {
          if (!response) return;
          callback(record.id, { ...record, downloads_user: 1 });
          downloadHandler(response, record.name);
        },
      });
    }
  };

  if (!attachment || !isVisible) return null;

  return (
    <IconWithTooltip
      action="download"
      title={t('pages.registered-customers.table.icons.download')}
      className={cn(attachment.downloads_user ? '!text-green-600' : 'text-black')}
      onClick={() => handleDownloadFile(attachment)}
      visibility={attachment.id ? '' : 'visibility_hidden'}
      loading={attachment.id && mutationDownloadFile.isPending}
    />
  );
};

export const AttachmentTableWatermarkDownload = ({
  attachment,
  callback,
  isVisible = true,
}: AttachmentTableButtonProps) => {
  const { t } = useTranslation();

  const getPresignedURLMutation = useGetAttachmentWithWatermarkMutation();

  const handleDownloadFile = async (record: DocumentData) => {
    if (record.id) {
      return getPresignedURLMutation.mutateAsync(record.id, {
        onSuccess: (response: string) => {
          if (!response) return;
          callback(record.id, { ...record, downloads_user: 1 });
          downloadHandler(response, record.name);
        },
      });
    }
  };

  if (!attachment || !isVisible) return null;

  return (
    <IconWithTooltip
      action="download"
      title={t('download-watermark')}
      className={cn(attachment.downloads_user ? '!text-green-600' : 'text-black')}
      onClick={() => handleDownloadFile(attachment)}
      visibility={attachment.id ? '' : 'visibility_hidden'}
      loading={attachment.id && getPresignedURLMutation.isPending}
    />
  );
};

export const AttachmentTablePreview = ({
  attachment,
  isVisible,
  enableWatermark,
}: Omit<AttachmentTableButtonProps, 'callback'>) => {
  const { t } = useTranslation();

  const getPresignedURLMutation = useGetAttachmentWithWatermarkMutation();

  const [isPreviewingFile, setIsPreviewingFile] = React.useState<boolean>(false);
  const [file, setFile] = React.useState<File | null | string | any>(null);

  const handlePreviewFile = () => {
    if (enableWatermark) {
      return getPresignedURLMutation.mutateAsync(attachment.id, {
        onSuccess: (response: string) => {
          if (!response) return;
          setIsPreviewingFile(true);
          setFile(response);
        },
      });
    }
    setIsPreviewingFile(true);
  };

  useEffect(() => {
    setFile(enableWatermark ? null : attachment);
  }, [enableWatermark, attachment]);

  if (
    !isVisible ||
    !attachment ||
    (attachment && !checkIfFilePreviewerSupportsExtension(attachment.extension)) ||
    (enableWatermark && (!attachment.extension || attachment.extension !== 'pdf'))
  )
    return null;

  return (
    <>
      <IconWithTooltip
        action={enableWatermark ? 'preview-watermark' : 'detail'}
        title={enableWatermark ? t('preview-watermark') : t('pages.credit-request.table.icons.preview')}
        onClick={() => handlePreviewFile()}
        loading={getPresignedURLMutation.isPending}
      />
      {isPreviewingFile && <FilePreviewer document={file} onClose={() => setIsPreviewingFile(false)} />}
    </>
  );
};

export const AttachmentTableDelete = ({
  attachment,
  callback,
  isVisible = true,
  isReadonly,
}: AttachmentTableButtonProps) => {
  const { t } = useTranslation();

  const mutationDeleteFile = useDeleteAttachment();

  const handleDelete = async () => {
    await mutationDeleteFile.mutateAsync(attachment.id, {
      onSuccess: () => {
        callback(attachment.id, attachment);
      },
    });
  };

  if (!isVisible || !attachment || isReadonly) return null;

  return (
    <Can I="document.destroy">
      <IconWithTooltip
        action="destroy"
        title={t('pages.registered-customers.table.icons.remove')}
        loading={attachment.id && mutationDeleteFile.isPending}
        onClick={handleDelete}
      />
    </Can>
  );
};

import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';

import { Menu } from 'antd';
import { IntegrationStatuses } from 'compositions/IntegrationStatuses';

import logoClient from '../../../assets/img/client/logo-light.png';
import logoMiniClient from '../../../assets/img/client/logo-mini.png';
import logoMiniCompany from '../../../assets/img/company/logo-mini.png';
import logoCompany from '../../../assets/img/company/logo.png';
import appConfigs from '../../../configs/app';
import { useAuth } from '../../../hooks/auth';
import { useSidebar } from '../../../hooks/sidebar';
import routes, { Route } from '../../../routes/routes';
import { Container, SStrip } from './styles';

interface MenuItem {
  [key: string]: Route[];
}

const LateralMenu: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { isChanging, isMini } = useSidebar();
  const { permissions } = useAuth();

  const { SubMenu } = Menu;

  const [menu, setMenu] = useState<MenuItem>({} as MenuItem);
  const [submenu, setSubmenu] = useState<MenuItem>({} as MenuItem);
  const [environment, setEnvironment] = useState<any>();

  const isClient = process.env.REACT_APP_IS_CLIENT === 'true';
  const logo = isClient ? logoClient : logoCompany;
  const miniLogo = isClient ? logoMiniClient : logoMiniCompany;

  useEffect(() => {
    const parents: MenuItem = {} as MenuItem;

    const splited = permissions.map(permission => permission.split('.')?.shift());
    const parentPermissions = [...new Set(splited)];

    routes
      .filter(route => (route.menu && route.menu.parent) ?? false)
      .forEach(parent => {
        if (parentPermissions.includes(parent.permission) || parent.forAll === true) {
          parents[parent.menu.parent] = parents[parent.menu.parent] ?? [];
          parents[parent.menu.parent].push(parent);
        }
      });
    setMenu(parents);

    const menuItems: MenuItem = {} as MenuItem;
    routes
      .filter(route => (route.menu && route.menu.submenu) ?? false)
      .forEach(child => {
        menuItems[child.menu.submenu] = menuItems[child.menu.submenu] ?? [];
        menuItems[child.menu.submenu].push(child);
      });

    // Apply permissions over routes with menu
    const permissionedMenu: MenuItem = {};
    Object.keys(menuItems).forEach(key => {
      const hasPermissionChild = menuItems[key]?.filter(
        child => permissions.findIndex(p => child.permission === p) > -1 || child.forAll === true,
      );

      if (hasPermissionChild?.length > 0) {
        permissionedMenu[key] = hasPermissionChild;
      }
    });
    setSubmenu(permissionedMenu);
  }, [permissions]);

  const activeSubmenu = useMemo(() => {
    const uri = location.pathname.split('/');

    return uri[1];
    // eslint-disable-next-line
  }, []);

  function renderIcon(icon: any, label: string) {
    if (typeof icon === 'function') {
      const Icon = icon;
      return <Icon />;
    }
    return <img src={icon} alt={t(label)} />;
  }

  const classCSS = useMemo(() => {
    if (isChanging) {
      return isMini ? 'sidebar-mini' : 'sidebar-large';
    }
    return '';
    // eslint-disable-next-line
  }, [isChanging]);

  const classShowEnvironment = useMemo(() => {
    if (environment) {
      return 'show-strip-environment';
    }
    return '';
    // eslint-disable-next-line
  }, [environment]);

  const systemEnvironment = window.location.hostname;
  useEffect(() => {
    switch (systemEnvironment) {
      case 'localhost':
        setEnvironment(isMini ? 'LOCAL' : 'Ambiente Local');
        break;
      case 'cofcodev.agrodata.agr.br':
        setEnvironment(isMini ? 'DESENV' : 'Ambiente de Desenvolvimento');
        break;
      case 'cofcotest.agrodata.agr.br':
        setEnvironment(isMini ? 'TESTE' : 'Ambiente de Teste');
        break;
      case 'cofcohomolog.agrodata.agr.br':
        setEnvironment(isMini ? 'HOMOLOG' : 'Ambiente de Homologação');
        break;
      case 'cofcoqas.agrodata.agr.br':
        setEnvironment(isMini ? 'QAS' : 'Ambiente de Qualidade');
        break;
      case 'cofcosap.agrodata.agr.br':
        setEnvironment(isMini ? 'SAP' : 'Ambiente SAP');
        break;
      case 'cofcosap-qas.agrodata.agr.br':
        setEnvironment(isMini ? 'SAP-QAS' : 'Ambiente SAP-QAS');
        break;
      case 'cofcosap-uat.agrodata.agr.br':
        setEnvironment(isMini ? 'SAP-UAT' : 'Ambiente SAP-UAT');
        break;
      case 'cofcofeature01.agrodata.agr.br':
        setEnvironment(isMini ? 'FEATURE01' : 'Ambiente FEATURE01');
        break;
      case 'cofcofeature02.agrodata.agr.br':
        setEnvironment(isMini ? 'FEATURE02' : 'Ambiente FEATURE02');
        break;
      case 'cofcofeature03.agrodata.agr.br':
        setEnvironment(isMini ? 'FEATURE03' : 'Ambiente FEATURE03');
        break;
      case 'cofcofeature04.agrodata.agr.br':
        setEnvironment(isMini ? 'FEATURE04' : 'Ambiente FEATURE04');
        break;
      default:
        break;
    }
  }, [isMini]);
  return (
    <>
      <Container className={classCSS} isMini={isMini}>
        {environment && <SStrip environment={systemEnvironment}>{environment}</SStrip>}
        <Menu
          mode="inline"
          theme="dark"
          selectable={false}
          inlineCollapsed={isMini}
          defaultOpenKeys={!isMini ? [activeSubmenu] : []}
        >
          <Menu.Item
            key="client-logo"
            icon={
              <div className="logo-client">
                <img src={isMini ? miniLogo : logo} alt={appConfigs.companyTitle} />
              </div>
            }
            title={appConfigs.clientTitle}
            className="menu-first-item"
          />

          <Menu.Divider />

          <Menu.ItemGroup className={classShowEnvironment}>
            {Object.keys(menu).map(parent => {
              if (submenu[parent] && submenu[parent].length > 0) {
                return (
                  <SubMenu
                    key={menu[parent][0].menu.key}
                    icon={
                      menu[parent][0].path ? (
                        <NavLink to={menu[parent][0].path}>
                          {menu[parent][0].menu.icon &&
                            renderIcon(menu[parent][0].menu.icon, menu[parent][0].menu.label)}
                        </NavLink>
                      ) : (
                        menu[parent][0].menu.icon && renderIcon(menu[parent][0].menu.icon, menu[parent][0].menu.label)
                      )
                    }
                    title={t(menu[parent][0].menu.label)}
                  >
                    {submenu[parent].map(child => (
                      <Menu.Item
                        key={child.menu.key}
                        icon={
                          child.path ? (
                            <NavLink to={child.path}>
                              {child.menu.icon && renderIcon(child.menu.icon, child.menu.label)}
                            </NavLink>
                          ) : (
                            child.menu.icon && renderIcon(child.menu.icon, child.menu.label)
                          )
                        }
                      >
                        {child.path ? <NavLink to={child.path}>{t(child.menu.label)}</NavLink> : t(child.menu.label)}
                      </Menu.Item>
                    ))}
                  </SubMenu>
                );
              }

              return (
                <Menu.Item
                  key={menu[parent][0].menu.key}
                  icon={
                    menu[parent][0].path ? (
                      <NavLink to={menu[parent][0].path}>
                        {menu[parent][0].menu.icon && renderIcon(menu[parent][0].menu.icon, menu[parent][0].menu.label)}
                      </NavLink>
                    ) : (
                      menu[parent][0].menu.icon && renderIcon(menu[parent][0].menu.icon, menu[parent][0].menu.label)
                    )
                  }
                >
                  {menu[parent][0].path ? (
                    <NavLink to={menu[parent][0].path}>{t(menu[parent][0].menu.label)}</NavLink>
                  ) : (
                    t(menu[parent][0].menu.label)
                  )}
                </Menu.Item>
              );
            })}
          </Menu.ItemGroup>

          <IntegrationStatuses isMini={isMini} />

          <Menu.Divider />
          <Menu.Item
            key="company-logo"
            icon={
              <>
                <div className="logo-company">
                  <strong>Powered By</strong>
                  <a href={appConfigs.companySite} target="_blank" rel="noopener noreferrer">
                    <img src={isMini ? logoMiniCompany : logoCompany} alt={appConfigs.companyTitle} />
                  </a>
                </div>
              </>
            }
            title={appConfigs.companyTitle}
            className="menu-last-item"
          />
        </Menu>
      </Container>
    </>
  );
};

export default LateralMenu;

import React, { useEffect } from 'react';

import { Col, DatePicker, Form, Input, Modal, Row, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import moment from 'moment';

import { FormItem } from '../../../../components';

interface Props {
  isOpen: boolean;
  close: () => void;
  onOk: (values: any) => void;
  isAlreadySigned: boolean;
}

const statusOptions = [
  { value: 'pendant', label: 'Pendente', key: 'pendant' },
  { value: 'signed', label: 'Assinado', key: 'signed' },
];

export const SignatureRequestStatusChangeModal = ({ isOpen, close, onOk, isAlreadySigned }: Props) => {
  const [form] = useForm();

  const isDateFieldRequired = !isAlreadySigned;

  const handleSubmitForm = () =>
    form.validateFields().then(values => {
      onOk(values);
      form.resetFields();
    });

  const initialStatusKey = statusOptions[isAlreadySigned ? 0 : 1].key;

  useEffect(() => {
    if (!isDateFieldRequired) form.setFieldsValue({ updateDate: '' });
  }, [isDateFieldRequired]);

  return (
    <Modal visible={isOpen} onCancel={close} title="Alterar status" onOk={handleSubmitForm} destroyOnClose>
      <Form form={form}>
        <Row gutter={[26, 26]}>
          <Col span={24}>
            <FormItem name="status" label="Status" rules={[{ required: true }]} initialValue={initialStatusKey}>
              <Select options={statusOptions} disabled />
            </FormItem>
          </Col>
          {/* <Col span={24}>
            <FormItem name="requester" label="Responsável" rules={[{ required: true }]}>
              <Select options={userOptions} />
            </FormItem>
          </Col> */}
          <Col span={24}>
            <FormItem name="updateDate" label="Data e Hora" rules={[{ required: isDateFieldRequired }]}>
              <DatePicker
                style={{ background: 'none' }}
                disabled={!isDateFieldRequired}
                format="DD/MM/YYYY HH:mm:ss"
                disabledDate={current => current && current > moment().endOf('day')}
                disabledHours={() => Array.from({ length: 24 }, (_, i) => i).filter(i => i > moment().hour())}
                showTime
              />
            </FormItem>
          </Col>
          <Col span={24}>
            <FormItem name="observation" label="Observação">
              <Input.TextArea />
            </FormItem>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

import { useQuery } from '@tanstack/react-query';
import { useAbility } from 'hooks/ability';
import api from 'services/api/api';
import { ExposureData } from 'types/Dashboard/ExposureData';

type GetExposuresResponse = ExposureData[];

type UseGetExposuresQuery = {
  filter: any;
  onSuccess?: (data: GetExposuresResponse) => void;
};

export const useGetExposuresQuery = ({ filter, onSuccess }: UseGetExposuresQuery) => {
  const ability = useAbility();

  return useQuery({
    queryKey: ['dashboard/exposure/operationalrisk', filter],
    enabled: filter?.source && ability.can('dashboard.exposure.operationalrisk', ''),
    queryFn: async () => {
      const response = await api.get<GetExposuresResponse>(
        !filter?.partner?.length ? '/dashboard/exposure/operationalrisk/all' : '/dashboard/exposure/operationalrisk',
        {
          params: {
            ...filter,
          },
        },
      );

      const data = !filter?.partner?.length
        ? [
            {
              ...response.data,
              bp: 'all',
            },
          ]
        : (Object.keys(response.data ?? {}).map(key => ({
            ...response.data[key as any],
            bp: key,
          })) as any);
      data?.sort((a: any, b: any) => (a.bp === 'total' ? 1 : b.bp === 'total' ? -1 : 0));
      if (onSuccess) onSuccess(data);

      return data;
    },
  });
};

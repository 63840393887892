import styled from 'styled-components';

// const maxHeightContainers = css`
//   max-height: calc(100vh - 144px);
// `;

export const ContainerDocument = styled.div`
  display: flex;
  margin: 0 5px;
  border: solid 1px #d9d9d9;
  background-color: #ffffff;
  min-height: 200px;
  padding: 0px;

  > header {
    color: #ffffff;
    background-color: ${({ theme: { colors } }) => colors.primary};
    text-align: center;
    text-transform: uppercase;
    padding-right: 0px !important;
    writing-mode: vertical-lr;
  }

  .anticon {
    font-size: 18px;
  }

  .iconComments {
    margin-left: 13px;
  }

  .colIcons {
    width: 100px;
  }

  .first-title {
    padding-left: 65px;
  }

  .title {
    color: ${({ theme: { colors } }) => colors.text};
    padding: 5px;
    font: 700 14px/18px ${({ theme: { fonts } }) => fonts.primary};
  }

  .row-item {
    cursor: pointer;
  }

  .DC {
    height: 15px;
    width: 15px;
    background-color: #a9d18e;
    border-radius: 50%;
    display: inline-block;
    margin-left: 13px;
    margin-right: 5px;
  }

  .DS {
    height: 15px;
    width: 15px;
    background-color: #ffd966;
    border-radius: 50%;
    display: inline-block;
    margin-left: 13px;
    margin-right: 5px;
  }

  .DI {
    height: 15px;
    width: 15px;
    background-color: #e03400;
    border-radius: 50%;
    display: inline-block;
    margin-left: 13px;
    margin-right: 5px;
  }

  .table {
    justify-content: center;
    padding: 4px 8px;
    width: 100%;

    > span {
      font-size: 25px;
    }
  }
`;

export const ContainerDraft = styled.div`
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    display: grid !important;
  }

  .titleColapse > span > svg {
    font-size: 35px;
  }

  .titleColapse > a > span {
    word-wrap: break-word;
  }

  .title-draft {
    padding-top: 20px;
    padding-left: 20px;
    margin-bottom: 0px !important;
  }

  .description-draft {
    padding-left: 20px;
  }

  .ant-steps-item-title {
    font-size: 14px;
  }

  .ant-card-body {
    border-radius: 10px 10px 0px 0px;
    border: 1px solid #d9d9d9;
    background-color: #f5f5f5;
    padding: 0px;
  }

  .icons-draft {
    padding: 0px !important;
    font-size: 25px;
    text-align: right;
  }

  .card-action {
    background-color: #00b050;
    color: #ffffff;
    margin: 0px !important;
    font-weight: 700;
  }

  .card-cancel {
    background-color: #e03400;
    color: #ffffff;
    margin: 0px !important;
    font-weight: 700;
  }

  .card-action-process {
    background-color: #ffa23c;
    color: #ffffff;
    margin: 0px !important;
    font-weight: 700;
  }

  .card-cancel a {
    color: #ffffff;
  }

  .card-action a {
    color: #ffffff;
  }

  .card-action-process a {
    color: #ffffff;
  }

  .card-action-process span {
    margin-right: 10px;
    padding-top: 10px;
  }

  .card-action span {
    margin-right: 10px;
    padding-top: 10px;
  }

  .title {
    color: #ffa23c;
    font-weight: 700;
  }

  Panel > header {
    color: #ffffff;
    background-color: #ffa23c;
    text-align: center;
    height: 94px;
    display: flex;
    margin-right: 9px !important;
    text-transform: uppercase;
    padding-right: 0px !important;
    position: absolute;
    -webkit-writing-mode: vertical-lr;
    -ms-writing-mode: tb;
    writing-mode: vertical-lr;
    padding-top: 21px;
    margin-top: 11px;
    border-radius: 10px 0px 0 0px;
    text-align: center !important;
  }

  .titleColapse > span {
    font-size: 16px;
    margin-top: 25px;
    padding-left: 30px;
    padding-bottom: 0px;
  }

  .ant-collapse-header > .ant-row {
    margin: -13px -13px 0px !important;
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header > .ant-row > Button {
    color: #ffffff;
    background-color: #ffa23c;
    text-align: center;
    height: 94px;
    display: flex;
    margin-right: 9px !important;
    text-transform: uppercase;
    padding-right: 0px !important;
    position: absolute;
    -webkit-writing-mode: vertical-lr;
    -ms-writing-mode: tb;
    writing-mode: vertical-lr;
    padding-top: 21px;
    margin-top: 11px;
    border-radius: 10px 0px 0 0px;
    text-align: center !important;
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    display: none;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding-left: 13px;
    padding-bottom: 0px;
    padding-top: 0px;
    background-color: #f5f5f5;
    border-radius: 10px 10px 0px 0px;
  }
  .ant-collapse > .ant-collapse-item {
    border: 1px solid #d9d9d9;
    padding-top: 10px;
    border-radius: 10px;
    padding-right: 10px;
    margin-bottom: 10px;
    padding-bottom: 10px;
    background-color: #f5f5f5;
  }
  .ant-collapse {
    border: none;
    background-color: #ffffff;
  }
  .ant-collapse-content {
    background-color: #f5f5f5;
  }
  .ant-card-bordered {
    margin-top: 15px;
    border-radius: 10px;
    padding-bottom: 0px;
  }
  .cardApproval > .ant-card-body {
    padding: 10px;
    text-align: center;
    background-color: #f4b183;
    border-radius: 10px;
    margin-bottom: 0px;
  }
  .cardDocs > .ant-card-body {
    padding: 10px;
    text-align: center;
    background-color: #ffd966;
    border-radius: 10px;
    margin-bottom: 0px;
  }
  .cardAnexos > .ant-card-body {
    padding: 10px;
    text-align: center;
    background-color: #d9d9d9;
    border-radius: 10px;
    margin-bottom: 0px;
  }
  Row {
    margin: -13px -13px 0px;
  }

  .titleDocument {
    transform: rotate(-90deg);
    text-transform: uppercase;
  }
`;

export const ContainerRegistration = styled.div`
  .titleColapse > span > svg {
    font-size: 35px;
  }

  .titleColapse > a > span {
    word-wrap: break-word;
  }

  Panel > header {
    color: #ffffff;
    background-color: #ffa23c;
    text-align: center;
    height: 94px;
    display: flex;
    margin-right: 9px !important;
    text-transform: uppercase;
    padding-right: 0px !important;
    position: absolute;
    -webkit-writing-mode: vertical-lr;
    -ms-writing-mode: tb;
    writing-mode: vertical-lr;
    padding-top: 21px;
    margin-top: 11px;
    border-radius: 10px 0px 0 0px;
    text-align: center !important;
  }

  .titleColapse > span {
    font-size: 16px;
    margin-top: 25px;
    padding-left: 30px;
    padding-bottom: 0px;
  }

  .ant-collapse-header > .ant-row {
    margin: -13px -13px 0px !important;
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header > .ant-row > Button {
    color: #ffffff;
    background-color: #ffa23c;
    text-align: center;
    height: 94px;
    display: flex;
    margin-right: 9px !important;
    text-transform: uppercase;
    padding-right: 0px !important;
    position: absolute;
    -webkit-writing-mode: vertical-lr;
    -ms-writing-mode: tb;
    writing-mode: vertical-lr;
    padding-top: 21px;
    margin-top: 11px;
    border-radius: 10px 0px 0 0px;
    text-align: center !important;
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    display: none;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding-left: 13px;
    padding-bottom: 0px;
    padding-top: 0px;
    background-color: #f5f5f5;
    border-radius: 10px 10px 0px 0px;
  }
  .ant-collapse > .ant-collapse-item {
    border: 1px solid #d9d9d9;
    padding-top: 10px;
    border-radius: 10px;
    padding-right: 10px;
    margin-bottom: 10px;
    padding-bottom: 10px;
    background-color: #f5f5f5;
  }
  .ant-collapse {
    border: none;
    background-color: #ffffff;
  }
  .ant-collapse-content {
    background-color: #f5f5f5;
  }
  .ant-card-bordered {
    margin-top: 15px;
    border-radius: 10px;
    padding-bottom: 0px;
  }
  .cardApproval > .ant-card-body {
    padding: 10px;
    text-align: center;
    background-color: #f4b183;
    border-radius: 10px;
    margin-bottom: 0px;
  }
  .cardDocs > .ant-card-body {
    padding: 10px;
    text-align: center;
    background-color: #ffd966;
    border-radius: 10px;
    margin-bottom: 0px;
  }
  .cardAnexos > .ant-card-body {
    padding: 10px;
    text-align: center;
    background-color: #d9d9d9;
    border-radius: 10px;
    margin-bottom: 0px;
  }
  Row {
    margin: -13px -13px 0px;
  }

  .titleDocument {
    transform: rotate(-90deg);
    text-transform: uppercase;
  }
`;

export const ContainerAdd = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 30vh;

  h1 {
    font-weight: bold;
    font-size: 24px;
    color: #cacaca;
  }

  span {
    width: 100%;
  }

  .iconAdd {
    font-size: 43px;
  }
`;

export const Container = styled.div`
  height: 100%;
  padding: 0 8px;

  .group-form {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-bottom: 16px;

    > h2 {
      background: ${({ theme: { colors } }) => colors.greys.grey01};
      color: ${({ theme: { colors } }) => colors.secondary};
      font-family: ${({ theme: { fonts } }) => fonts.primary};
      font-weight: 700;
      font-size: 16px;
      padding: 8px;
      display: block;
      text-align: center;
      text-transform: uppercase;
      margin-top: 0;
      margin-bottom: 16px;
    }

    > .group-header-actions {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      > button {
        margin-left: 8px;
        margin-right: 8px;
      }
    }

    > .group-header-values {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      color: ${({ theme: { colors } }) => colors.secondary};
      font-family: ${({ theme: { fonts } }) => fonts.primary};
      font-weight: 500;
      font-size: 14px;

      > button {
        margin-left: 8px;
        margin-right: 8px;
      }
    }

    .form-grid {
      /* display: grid;
      gap: 0 8px;
      grid-template-columns: 33% 33% 33%;
      grid-template-rows: 76px;
      grid-auto-flow: row;
      width: 100%;
      position: relative;
      align-items: center; */

      display: inline;

      > * {
        max-width: 33%;
        display: inline-flex;
        flex: 1;
      }

      > fieldset {
        display: block;
        border: solid 1px ${({ theme: { colors } }) => colors.primary};
        border-radius: 5px;
        max-width: 100%;
        margin: 8px 0;
        padding-top: 16px;

        > h6 {
          background: ${({ theme: { colors } }) => colors.greys.grey01};
          border-radius: 5px;
          color: ${({ theme: { colors } }) => colors.primary};
          font-family: ${({ theme: { fonts } }) => fonts.primary};
          font-weight: 700;
          font-size: 16px;
          padding: 4px 8px;
        }

        .fields {
          display: inline;

          > * {
            max-width: 33%;
            display: inline-flex;
            flex: 1;
          }
        }
      }
    }

    &.inatived > * {
      display: none;

      > * {
        display: none;
      }
    }
  }
`;

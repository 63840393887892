import styled, { keyframes } from 'styled-components';

const fadeInFwd = keyframes`
 0% {
    height: 0px;
 }
 100% {
    height: 5px;
 }
`;

export const Container = styled.div<{ isSelected: boolean; isDisabled: boolean }>`
  width: 64px;
  height: 64px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};

  background: ${({ theme: { colors }, isSelected, isDisabled }) =>
    isDisabled ? colors.greys.grey02 : isSelected && colors.lightGrey};

  &:hover {
    background: ${({ theme: { colors }, isDisabled }) => (isDisabled ? colors.greys.grey02 : colors.greys.grey03)};
  }

  &::before {
    animation: ${fadeInFwd} 0.2s cubic-bezier(0.1, 0.1, 0.1, 1) both;
    content: '';
    display: ${({ isSelected }) => (isSelected ? 'block' : 'none')};
    width: 100%;
    background: ${({ theme: { colors }, isDisabled }) => (isDisabled ? colors.greys.grey07 : colors.primary)};
    position: absolute;
    bottom: 0;
    left: 0;
    border-radius: 3px 3px 0 0;
  }
`;

import styled from 'styled-components';

import { OverflowAxisProps } from '../../../../@types/cssProps';

export const Container = styled.div<{ overflowY?: OverflowAxisProps }>`
  padding: 45px 5px;
  // Reduz pelo tamanho da barra de abas
  height: calc(100vh - 140px);
  background: ${({ theme: { colors } }) => colors.lightGrey};
  overflow-y: ${({ overflowY }) => overflowY || 'visible'};
  position: relative;
`;

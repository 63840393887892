import styled from 'styled-components';

export const Container = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 33px;
  font-weight: bold;
  color: ${({ theme: { colors } }) => colors.primary};
  width: 100%;
`;

import { FixedType, AlignType } from 'rc-table/lib/interface';

export default {
  justification: [
    {
      title: 'pages.credit-request.justification.table.description',
      dataIndex: 'description',
      key: 'description',
      sorter: false,
      filtered: false,
    },
    {
      title: 'pages.credit-request.justification.table.created_at',
      dataIndex: 'created_at',
      key: 'created_at',
      sorter: false,
      filtered: false,
      align: 'center' as AlignType,
      width: '200px',
    },
    {
      title: 'pages.credit-request.justification.table.user',
      dataIndex: 'user',
      key: 'user',
      sorter: false,
      filtered: false,
      width: '300px',
    },
    {
      title: 'pages.credit-request.justification.table.actions',
      dataIndex: 'id',
      key: 'id',
      align: 'center' as AlignType,
      width: '50px',
      fixed: 'right' as FixedType,
    },
  ],
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaDesktop, FaMobileAlt, FaFilePdf } from 'react-icons/fa';

import { Tabs as ATabs } from 'antd';

import appStoreImg from '../../assets/img/icon-app-store.svg';
import playStoreImg from '../../assets/img/icon-google-play.svg';
import apkAndroidImg from '../../assets/img/logo-android.png';
import { Tabs } from '../../components';
import { Container } from './styles';

const Downloads: React.FC = () => {
  const { TabPane } = ATabs;
  const { t } = useTranslation();

  let linkAPKAndroid = process.env.REACT_APP_APK_ANDROID_DEV;
  let linkApkMonitoring = process.env.REACT_APP_APK_MONITORING_DEV;

  if (process.env.REACT_APP_ENVIRONMENT === 'homolog') {
    linkAPKAndroid = process.env.REACT_APP_APK_ANDROID_HOMOLOG;
    linkApkMonitoring = process.env.REACT_APP_APK_MONITORING_HOMOLOG;
  }
  if (process.env.REACT_APP_ENVIRONMENT === 'prod') {
    linkAPKAndroid = process.env.REACT_APP_APK_ANDROID_PROD;
  }

  return (
    <Container>
      <Tabs className="downloads-tabs">
        <TabPane tab={t('pages.downloads.tabs.downloads')} key="tab-downloads">
          <h3>Prefin/Prepay</h3>
          <div className="content-pannel">
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://play.google.com/store/apps/details?id=org.nativescript.sassproject"
              className="item"
            >
              <img src={playStoreImg} alt="App download" />
              <span>Google Play</span>
            </a>

            <span className="item">
              <img src={appStoreImg} alt="App download" />
              <span>App Store</span>
            </span>

            <a rel="noopener noreferrer" target="_blank" href={linkAPKAndroid} className="item">
              <img src={apkAndroidImg} alt="APK download" />
              <span>APK Android</span>
            </a>
          </div>
          <h3>Monitoring</h3>
          <div className="content-pannel">
            <a rel="noopener noreferrer" target="_blank" href={linkApkMonitoring} className="item">
              <img src={apkAndroidImg} alt="APK download" />
              <span>APK Monitoring</span>
            </a>
          </div>
        </TabPane>
        <TabPane tab={t('pages.downloads.tabs.manuals')} key="tab-manuals">
          <div className="content-pannel">
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://cofco-prod.s3-sa-east-1.amazonaws.com/manuals/Agrodata+COFCO+-+Tutorial+Web.pdf"
              className="item"
            >
              <FaDesktop />
              <span>{t('pages.downloads.downloads.webManual')}</span>
            </a>

            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://cofco-prod.s3-sa-east-1.amazonaws.com/manuals/Agrodata_COFCO_-_Tutorial_Appv2.pdf"
              className="item"
            >
              <FaMobileAlt />
              <span>{t('pages.downloads.downloads.appManual')}</span>
            </a>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://cofco-prod.s3-sa-east-1.amazonaws.com/manuals/Agrodata+COFCO+-+Tutorial+Web.pdf"
              className="item"
            >
              <FaDesktop />
              <span>{t('pages.downloads.downloads.webManual')}</span>
            </a>

            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://cofco-prod.s3.sa-east-1.amazonaws.com/app/agrodata_cofco_Guarantee_formalization.pdf"
              className="item"
            >
              <FaFilePdf />
              <span>{t('pages.downloads.downloads.comercialManual')}</span>
            </a>
          </div>
        </TabPane>
      </Tabs>
    </Container>
  );
};

export default Downloads;

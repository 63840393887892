const columns: any = [
  {
    title: 'pages.profile.userLog.table.event',
    dataIndex: 'event',
    key: 'event',
    sorter: true,
  },
  {
    title: 'pages.profile.userLog.table.auditableType',
    dataIndex: 'auditable_type',
    key: 'auditable_type',
    sorter: true,
  },
  {
    title: 'pages.profile.userLog.table.value',
    dataIndex: 'value',
    key: 'value',
    sorter: true,
  },
  {
    title: 'pages.profile.userLog.table.createdAt',
    dataIndex: 'created_at',
    key: 'created_at',
    sorter: true,
  },
];

export default columns;

import { IconType } from 'react-icons';
import { FaFileUpload } from 'react-icons/fa';
import { MdComputer } from 'react-icons/md';

import { CONTRACT_SIGNATURE_DSELETRONIC, CONTRACT_SIGNATURE_ICPBRASIL } from 'configs/constants';

export const formats = new Map<'digital' | 'manual', { name: string; value: string; label: string; icon: IconType }>();

formats.set('digital', { name: 'digital', value: 'digital', label: 'Digital/Eletrônica', icon: MdComputer });
formats.set('manual', { name: 'manual', value: 'manual', label: 'Manual', icon: FaFileUpload });

export const types = new Map<
  'dseletronic' | 'icpbrasil',
  { name: string; value: string; label: string; icon: IconType }
>();

types.set('dseletronic', {
  name: 'dseletronic',
  value: CONTRACT_SIGNATURE_DSELETRONIC,
  label: 'DS Eletronic',
  icon: MdComputer,
});
types.set('icpbrasil', {
  name: 'icpbrasil',
  value: CONTRACT_SIGNATURE_ICPBRASIL,
  label: 'ICP-Brasil',
  icon: FaFileUpload,
});

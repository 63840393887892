import React from 'react';

export const TotvsSvgLogo = ({ width }: { width?: number }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || '13'}
      preserveAspectRatio="xMinYMid meet"
      viewBox="0 0 502 512"
    >
      <path d="M125.003 34.453c-166.67 98.17-166.67 344.925 0 443.094S501.143 452.338 501.143 256 291.673-63.716 125.003 34.453zm301.01 303.298c-.906 19.505-11.037 20.79-94.348-2.04 1.84 35.856-1.415 75.346-10.41 91.435-64.701-6.94-211.24-41.978-243.681-65.176-9.151-74.707-11.536-143.398-1.466-201.02 6.246-13.781 73.45 5.304 93.45 13.108-1.978-35.28-2.668-82.19 8.489-94.48 61.281 8.832 194.795 46.116 218.231 52.92 15.425 4.478 30.069 5 31.878 41.737 3.744 75.994-1.235 144.012-2.142 163.516zM326.677 232.29c2.082 18.047 4.99 103.42 4.99 103.42l-139.307-38.283a28.993 28.993 0 01-19.94-25.44l-2.861-97.929c.002 0 36.627 7.148 76.541 16.901 73.17 17.88 79.216 29.537 80.577 41.33z" />
    </svg>
  );
};

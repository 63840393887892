import React from 'react';
import { useTranslation } from 'react-i18next';

import { InputNumber } from 'antd';
import { FormItem } from 'components';
import { Formatter } from 'utils/Formatter';

interface Props {
  disabled?: boolean;
  onChange?: (value: string) => void;
  required?: boolean;
}

export const ApprovedValueBarterInput = ({ disabled, onChange, required }: Props) => {
  const { t } = useTranslation();

  return (
    <FormItem
      label={t('pages.credit-request.form.approved_value_barter')}
      name="approved_value_barter"
      rules={[{ required }]}
    >
      <InputNumber<string>
        min="0"
        formatter={(value: string | number) => Formatter.money(value)}
        parser={(value: string) => Formatter.moneyParser(value)}
        disabled={disabled}
        onChange={onChange}
      />
    </FormItem>
  );
};

import React from 'react';
import { useTranslation } from 'react-i18next';

import { Select } from 'components';
import { SelectOptionType } from 'components/AllSelect';
import { ShouldRender } from 'components/ShouldRender';
import appConfigs from 'configs/app';
import {
  PARTIAL_LIMIT_STATUS_DISAPPROVED,
  PHYSICAL_PERSON_TYPE,
  TEAM_STATUS_AWAITING_GR_APPROVAL_COMMERCIAL_ID,
  TEAM_STATUS_ON_QUEUE_PREFIN_ID,
} from 'configs/constants';
import { Can } from 'hooks/ability';
import { useCreditStatus } from 'hooks/creditStatus';
import { useCreditRequestRules } from 'hooks/rules/useCreditRequestRules';
import moment from 'moment';
import { useFilterListOfNextTeamStatus } from 'pages/CreditRequest/hooks/useFilterListOfNextTeamStatus';
import { OptionData, OptionGroupData, OptionsType } from 'rc-select/lib/interface';
import { Formatter } from 'utils/Formatter';

import DTOCreditOperation from '../../../../../../../@types/dtos/credit-request/DTOCreditOperation';
import DTOCreditPartialLimit from '../../../../../../../@types/dtos/credit-request/DTOCreditPartialLimit';
import { ApprovedValue } from './ApprovedValue';
import { RequestedValue } from './RequestedValue';
import { HidenFooterHeader } from './styles';
import { CardHiddenFooterProps } from './types';

export const CardHiddenFooter = ({
  data: {
    requester_person_type,
    team_status,
    team_status_id,
    requested_amount,
    currency_type,
    harvest,
    created_at,
    id,
    number,
    operation_id,
    approved_value,
    enabled_value,
    partial_limit_approved,
    partial_limit,
    json_data,
    last_import_sap,
    limit_expiration_date,
    sub_division_id,
    requested_amount_barter,
    requested_amount_cash,
    approved_value_barter,
    approved_value_cash,
    enabled_value_barter,
    enabled_value_cash,
    has_assignees,
    sap_bp_headoffice_subsidiary_code,
    datasource,
  },
  setLoadingCard,
  setChangingRequestId,
  userPositionId,
  setCreditReportId,
  setExporting,
}: CardHiddenFooterProps) => {
  const { t } = useTranslation();
  const { getListOfNextTeamStatus, getOperation } = useCreditStatus();
  const { canShowChangeStatusSelect, STATUS_CHANGE_RULES } = useCreditRequestRules();
  const { filterListOfNextTeamStatus } = useFilterListOfNextTeamStatus();

  const isPhysicalPerson = requester_person_type === PHYSICAL_PERSON_TYPE;

  const isInPrefinStatus = team_status.id === TEAM_STATUS_ON_QUEUE_PREFIN_ID;

  const isInAwaitingGrApprovalCommercialStatus = team_status?.id === TEAM_STATUS_AWAITING_GR_APPROVAL_COMMERCIAL_ID;

  const enabledValue = partial_limit_approved
    ? `${t('pages.credit-request.kanban.cards.enabled_value')}: ${Formatter.money(
        partial_limit_approved,
        2,
        currency_type ? currency_type.name || '' : '',
        true,
      )}`
    : null;

  const [totalGuaranteesValue, totalPendingApprovalValue] = partial_limit.reduce(
    ([totalValue, pendingApprovalValue]: number[], guarantee: DTOCreditPartialLimit) => {
      if (guarantee?.approval?.length === 0) return [totalValue, pendingApprovalValue + guarantee.value];

      if (guarantee?.approval?.credit_request_partial_limit_status_id === PARTIAL_LIMIT_STATUS_DISAPPROVED)
        return [totalValue, pendingApprovalValue + guarantee.value];

      return [totalValue, pendingApprovalValue];
    },
    [0],
  );

  const partialLimit = totalGuaranteesValue + totalPendingApprovalValue; // partial_limit.reduce((a: number, b: DTOCreditPartialLimit) => a + b.value, 0);

  const partialLimitPercent = Math.floor((partialLimit * 100) / Number(approved_value));

  const limitEnabled = partialLimit
    ? `${t('pages.credit-request.kanban.cards.partial_limit')}: ${Formatter.money(
        partialLimit,
        2,
        currency_type ? currency_type.name || '' : '',
        true,
      )} (${partialLimitPercent}%)`
    : null;

  const operation = getOperation(operation_id) as DTOCreditOperation;

  const listOfTeamStatus = getListOfNextTeamStatus(
    team_status_id,
    operation_id,
    userPositionId,
    true,
  ) as SelectOptionType[];

  const canShowOptions = canShowChangeStatusSelect(team_status.team_id, listOfTeamStatus.length);

  const handleChangeStatus = async (
    value: string | SelectOptionType[],
    option: OptionsType | OptionData | OptionGroupData,
  ) => {
    // Apenas valores unicos são aceitos
    if (Array.isArray(value) || Array.isArray(option)) return;
    setLoadingCard(true);

    await STATUS_CHANGE_RULES.tryChangeStatus({
      datasource,
      operationId: operation_id,
      partialLimit,
      currentStatusId: team_status_id,
      approvedValue: Number(approved_value ?? 0),
      currencyTypeSlug: currency_type?.slug,
      creditRequestNumber: number,
      newStatusId: value,
      creditRequestId: id,
      netAmount: Number(json_data?.net_amount ?? 0),
      requestedAmount: Number(requested_amount ?? 0),
      enabledValue: enabled_value,
      requestedAmountBarter: requested_amount_barter,
      requestedAmountCash: requested_amount_cash,
      approvedValueBarter: approved_value_barter,
      approvedValueCash: approved_value_cash,
      enabledValueBarter: enabled_value_barter,
      enabledValueCash: enabled_value_cash,
      modalityId: json_data.modality_id,
      subDivisionId: sub_division_id,
      providerSapCode: sap_bp_headoffice_subsidiary_code ?? '',
      isImported: !!last_import_sap?.description,
      limitExpirationDate: limit_expiration_date,
    });

    setChangingRequestId(id);
    setLoadingCard(false);
    // if (option.value === TEAM_STATUS_ON_QUEUE_PREFIN_PRE_REGISTRATION) {
    //   setLoadingCard(true);
    //   api.verifyPackageIntegrity
    //     .get(id)
    //     .then(response => {
    //       if (response.data) {
    //         handleChangeStatusCreditRequest(
    //           operation_id,
    //           team_status_id,
    //           value.toString(),
    //           id,
    //           number,
    //           currency_type.slug,
    //           approved_value ?? 0,
    //           partial_limit,
    //           operation_id === OPERATION_DELIVERED_PRODUCT_ADVANCE_ID ? json_data?.net_amount ?? '0' : requested_amount,
    //           enabled_value,
    //         );
    //         setChangingRequestId(id);
    //       } else {
    //         handleStatus(id);
    //       }
    //     })
    //     .finally(() => {
    //       setLoadingCard(false);
    //     });
    // } else {
    //   if (option.value === TEAM_STATUS_SUSTAINABILITY_SOLICITATION_ID) {
    //     setLoadingCard(true);
    //     apiSustainability.reports
    //       .get({ params: { credit_request: id } })
    //       .then(response => {
    //         if (response.data.data.length > 0) {
    //           handleChangeStatusCreditRequest(
    //             operation_id,
    //             team_status_id,
    //             value.toString(),
    //             id,
    //             number,
    //             currency_type.slug,
    //             approved_value ?? 0,
    //             partial_limit,
    //             operation_id === OPERATION_DELIVERED_PRODUCT_ADVANCE_ID
    //               ? json_data?.net_amount ?? '0'
    //               : requested_amount,
    //             enabled_value,
    //           );
    //           setChangingRequestId(id);
    //         } else {
    //           handleStatusSustainability(id);
    //         }
    //       })
    //       .finally(() => {
    //         setLoadingCard(false);
    //       });
    //   } else {
    //     handleChangeStatusCreditRequest(
    //       operation_id,
    //       team_status_id,
    //       value.toString(),
    //       id,
    //       number,
    //       currency_type.slug,
    //       approved_value ?? 0,
    //       partial_limit,
    //       operation_id === OPERATION_DELIVERED_PRODUCT_ADVANCE_ID ? json_data?.net_amount ?? '0' : requested_amount,
    //       enabled_value,
    //     );
    //     setChangingRequestId(id);
    //   }
    // }
  };

  return (
    <>
      <HidenFooterHeader>
        <>
          <span>{`${t('pages.credit-request.kanban.cards.harvest')} ${harvest?.name ?? '-'}`}</span>
          {isPhysicalPerson && operation.slug !== 'prepayment' && (
            <Can I="credit.request.report.index">
              <Can I="credit.request.report.store">
                <button
                  type="button"
                  onClick={() => {
                    setCreditReportId(id);
                    setExporting(true);
                  }}
                >
                  {t('pages.credit-request.table.icons.reports')}
                </button>
              </Can>
            </Can>
          )}
        </>
      </HidenFooterHeader>

      <RequestedValue
        currencyTypeName={currency_type?.name}
        modalityId={json_data?.modality_id}
        operationId={operation_id}
        requestedAmount={requested_amount}
        requestedAmountBarter={requested_amount_barter}
        requestedAmountCash={requested_amount_cash}
        subDivisionId={sub_division_id}
      />

      <ApprovedValue
        currencyTypeName={currency_type?.name}
        modalityId={json_data.modality_id}
        operationId={operation_id}
        approvedValue={approved_value}
        approvedValueBarter={approved_value_barter}
        approvedValueCash={approved_value_cash}
        subDivisionId={sub_division_id}
      />

      {/* <EnabledValue currencyTypeName={currency_type?.name} enabledValue={enabled_value} /> */}

      {enabledValue && <div className="money">{enabledValue}</div>}
      {limitEnabled && <div className="money">{limitEnabled}</div>}

      <ShouldRender condition={!(isInPrefinStatus || isInAwaitingGrApprovalCommercialStatus)}>
        <div className="text-muted">
          {`${t('pages.credit-request.kanban.cards.created_at')}: ${moment(created_at).format(appConfigs.formatDate)}`}
        </div>
      </ShouldRender>

      <ShouldRender condition={canShowOptions}>
        <Select
          allowClear={false}
          onChange={handleChangeStatus}
          options={filterListOfNextTeamStatus({
            listOfTeamStatus,
            team_status_id,
            datasource,
            has_assignees,
            approved_value: Number(approved_value || 0),
            operation: operation.id,
          })}
          value={team_status_id}
        />
      </ShouldRender>
    </>
  );
};

import React from 'react';

import { RadioGroupProps } from '@radix-ui/react-radio-group';
import { Label } from 'components/ui/label';
import { RadioGroup, RadioGroupItem } from 'components/ui/radio-group';
import { v4 as uuid } from 'uuid';

type Props = RadioGroupProps & {
  options: { value: string; label: string }[];
};

export const DraftRadioGroup = ({ options, ...props }: Props) => {
  const [id] = React.useState(uuid());

  return (
    <RadioGroup
      {...props}
      defaultValue={props.defaultValue || (options.length ? options[0].value : '')}
      className="flex gap-2 w-full"
    >
      {options?.map(option => (
        <div className="flex items-center space-x-2 relative w-full  max-w-[300px]" key={id + option.value}>
          <RadioGroupItem value={option.value} id={id + option.value} className="peer sr-only" />
          <Label
            htmlFor={id + option.value}
            className="uppercase font-bold relative peer-data-[state=checked]:text-white peer-data-[state=checked]:bg-primary p-4 rounded-lg border border-dashed border-gray-400 bg-gray-200 w-full text-center"
          >
            {option.label}
          </Label>
        </div>
      ))}
    </RadioGroup>
  );
};

import { DocumentToCollectFormData } from 'types/CreditRequest/forms/DocumentToCollectFormData';
import { FileUtils } from 'utils/File';

const convertDocumentToCollectFormData = async ({
  data,
  formData,
  index,
  keyPrefix,
}: {
  data: DocumentToCollectFormData;
  formData: FormData;
  index?: number;
  keyPrefix?: string;
}) => {
  const base64DocumentsToCollectAttachments: string[] = await Promise.all(
    data.documents_files?.map(async file => {
      const base64 = await FileUtils.getBase64(file);
      return base64;
    }) || [],
  );

  const hasIndex = typeof index === 'number';
  const prefix = `${keyPrefix || ''}${hasIndex ? `[${index}]` : keyPrefix ? '.' : ''}`;

  const parseKey = (key: string) => `${prefix || ''}${hasIndex ? `[${key}]` : key}`;

  if (!data?.id?.includes('new-')) formData.append(parseKey('id'), data.id);

  formData.append(parseKey('name'), data.name);
  formData.append(parseKey('city_id'), data.city_id ? data.city_id : '');
  formData.append(parseKey('status_id'), data.status_id ? data.status_id : '');
  formData.append(parseKey('notary_id'), data.notary_id ? data.notary_id : '');
  formData.append(parseKey('collection_cost'), data.collection_cost ? data.collection_cost.toString() : '');
  formData.append(parseKey('description'), data.description ? data.description : '');
  data.documents_files?.forEach((file, fileIndex) => {
    const { type, name } = file as File;

    formData.append(`${parseKey('documents')}[${fileIndex}][image]`, base64DocumentsToCollectAttachments[fileIndex]);
    formData.append(`${parseKey('documents')}[${fileIndex}][mime]`, type);
    formData.append(`${parseKey('documents')}[${fileIndex}][name]`, name);
    formData.append(`${parseKey('documents')}[${fileIndex}][original]`, FileUtils.getOriginalName(name));
  });

  data.comments
    ?.filter(comment => comment.isNew)
    .forEach((comment, commentIndex) => {
      formData.append(`${parseKey('comments')}[${commentIndex}][dt_comment]`, comment.dt_comment);
      formData.append(`${parseKey('comments')}[${commentIndex}][comment]`, comment.comment);
    });
};

const toApiFormData = async (
  data: DocumentToCollectFormData | DocumentToCollectFormData[],
  formData?: FormData,
  keyPrefix?: string,
) => {
  const newFormData = formData || new FormData();

  if (Array.isArray(data)) {
    await Promise.all(
      data.map(async (document, documentIndex) =>
        convertDocumentToCollectFormData({
          data: document,
          formData: newFormData,
          index: documentIndex,
          keyPrefix,
        }),
      ),
    );
  } else {
    await convertDocumentToCollectFormData({ data, formData: newFormData, keyPrefix });
  }

  return newFormData;
};

export const DocumentsToCollectFormConverter = {
  toApiFormData,
};

export default {
  registrationColumns: [
    {
      title: 'pages.monitoring.registration.table.header.number',
      dataIndex: 'number',
      key: 'number',
      sorter: true,
    },
    {
      title: 'pages.monitoring.registration.table.header.incra_code',
      dataIndex: 'incra_code',
      key: 'incra_code',
      sorter: true,
    },
    {
      title: 'pages.monitoring.registration.table.header.scripture_date',
      dataIndex: 'scripture_date',
      key: 'scripture_date',
      sorter: true,
    },
    {
      title: 'pages.monitoring.registration.table.header.emission_date',
      dataIndex: 'emission_date',
      key: 'emission_date',
      sorter: true,
    },
    {
      title: 'pages.monitoring.registration.table.header.planting',
      dataIndex: 'planting',
      key: 'planting',
    },
    {
      title: 'pages.monitoring.registration.table.header.development',
      dataIndex: 'development',
      key: 'development',
    },
    {
      title: 'pages.monitoring.registration.table.header.harvest',
      dataIndex: 'harvest',
      key: 'harvest',
    },
    {
      title: 'pages.monitoring.registration.table.header.car',
      dataIndex: 'kml',
      key: 'kml',
    },
    {
      title: 'pages.monitoring.registration.table.header.actions',
      key: 'action',
      dataIndex: 'action',
      align: 'center',
      width: '180px',
      fixed: 'right',
    },
  ],
  reportsColumns: [
    {
      title: 'pages.monitoring.registration.modal-reports.table.headers.name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'pages.monitoring.registration.modal-reports.table.headers.user',
      dataIndex: 'user',
      key: 'user',
    },
    {
      title: 'pages.monitoring.registration.modal-reports.table.headers.created_at',
      dataIndex: 'created_at',
      key: 'created_at',
    },
    {
      title: 'pages.monitoring.registration.modal-reports.table.headers.status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'pages.monitoring.registration.modal-reports.table.headers.actions',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      fixed: 'right',
      width: '150px',
    },
  ],
};

import React from 'react';
import { useTranslation } from 'react-i18next';

import { Col, Row, Spin } from 'antd';
import parse from 'html-react-parser';
import { Formatter } from 'utils/Formatter';

import { FieldGroup } from '../../../../../components/FieldGroup';
import { useContractContext } from '../../../../../context/ContractContext';
import { Title, Container } from './styles';

export const RelatedDocumentDetails = () => {
  const { selectedContract, selectedContractHasFixation } = useContractContext();
  const { t } = useTranslation();

  // const handleChangeFixation = (value: any) => {
  //   updateDocumentProperties({ fixation_index: value });
  // };

  return (
    <Container>
      <Title>Dados Documento Acessório</Title>
      <FieldGroup title={t('pages.edocuments.emission.relatedDocumentDetails.observation')}>
        {parse(selectedContract.create_comment ?? t('notProvided'))}
      </FieldGroup>

      {selectedContractHasFixation && (
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col style={{ width: '100%' }}>
            <Spin spinning={false}>
              <FieldGroup title={t('pages.edocuments.emission.relatedDocumentDetails.fixation')}>
                {/* {selectedContractHasFixation && selectedContract?.selected_fixation ? ( */}
                <span>
                  {`${selectedContract.selected_fixation.tradeslip} | ${selectedContract.selected_fixation.currency} ${
                    selectedContract.selected_fixation.price
                  } | ${Formatter.integer(selectedContract.selected_fixation.quantity)}` ??
                    t('pages.edocuments.emission.contractDetails.notProvided')}
                </span>
                {/* ) : (
                //   <Select
                //     options={fixations}
                //     value={selectedContract.fixation_index}
                //     onChange={handleChangeFixation}
                //     style={{ width: '80%' }}
                //   />
                // )} */}
              </FieldGroup>
            </Spin>
          </Col>
        </Row>
      )}
    </Container>
  );
};

import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormItem, Select } from 'components';
import { OPERATION_DELIVERED_PRODUCT_ADVANCE_ID } from 'configs/constants';
import { useCreditRequestContext } from 'context/CreditRequestContext';
import { useIsFethabAttachmentRequired } from 'pages/CreditRequest/rules/general/useIsFethabAttachmentRequired';
import { useCreditRequestStore } from 'pages/CreditRequest/store/useCreditRequestStore';

interface Props {
  isVisible?: boolean;
}

export const JsonDataFethabSelect = ({ isVisible = true }: Props) => {
  const { t } = useTranslation();
  const { creditForm } = useCreditRequestContext();

  const selectedOperationId = useCreditRequestStore(state => state.selectedOperationId);
  const setIsBlocking = useCreditRequestStore(state => state.setIsBlocking);
  const isRequiredAttachFethab = useCreditRequestStore(state => state.isRequiredAttachFethab);
  const setIsRequiredAttachFethab = useCreditRequestStore(state => state.setIsRequiredAttachFethab);
  const pageStatus = useCreditRequestStore(state => state.pageStatus);

  const { checkIsFethabAttachmentRequired } = useIsFethabAttachmentRequired();

  return (
    <FormItem
      label={t('pages.credit-request.form.json_data_fethab')}
      name="json_data.fethab"
      validateStatus={isRequiredAttachFethab ? 'warning' : ''}
      extra={
        isRequiredAttachFethab && selectedOperationId !== OPERATION_DELIVERED_PRODUCT_ADVANCE_ID
          ? t('pages.credit-request.form.fethab_warning_file')
          : ''
      }
      style={{ display: isVisible ? 'block' : 'none' }}
    >
      <Select
        options={[
          {
            key: 'fethab_yes',
            label: t('pages.credit-request.form.fethab_yes'),
            value: 1,
          },
          { key: 'fethab_no', label: t('pages.credit-request.form.fethab_no'), value: 0 },
        ]}
        disabled={pageStatus.viewing}
        onChange={(value: any) => {
          const stateIdSelected = creditForm.getFieldValue('state_id');
          setIsRequiredAttachFethab(checkIsFethabAttachmentRequired(stateIdSelected, value));

          if (value) {
            setIsBlocking(true);
          }
        }}
      />
    </FormItem>
  );
};

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Form, Input, Modal } from 'antd';
import { FormItem } from 'components';
import { useContractContext } from 'pages/Contracts/context/ContractContext';

interface Props {
  initialReferenceNumber: string;
  isOpen: boolean;
  close: () => void;
}

export const ReferenceNumberModal = ({ initialReferenceNumber, isOpen, close }: Props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { updateContract, updateDocumentProperties } = useContractContext();

  const handleSubmit = () => {
    form.validateFields().then(async values => {
      const response = await updateDocumentProperties({ reference: values.reference });
      form.resetFields();
      updateContract({ ...response, reference: values.reference }, false, response.id);
      close();
    });
  };

  const handleClose = () => {
    form.resetFields();
    close();
  };

  useEffect(() => {
    form.setFieldsValue({ reference: initialReferenceNumber });
  }, [initialReferenceNumber]);

  return (
    isOpen && (
      <Modal onOk={handleSubmit} onCancel={handleClose} visible={isOpen} destroyOnClose>
        <Form form={form}>
          <FormItem name="reference" label={t('pages.edocuments.emission.contractDetails.reference') as string}>
            <Input />
          </FormItem>
        </Form>
      </Modal>
    )
  );
};

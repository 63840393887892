import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ShouldRender } from 'components/ShouldRender';
import { useAlertDialog } from 'components/ui/alert-dialog';
import { Button } from 'components/ui/button';
import { GroupForm } from 'components/ui/group-form';
import { randomUUID } from 'lib/utils';

import { BankReferenceFormData } from './bank-reference/bank-reference.form';
import { BankReferenceModal } from './bank-reference/bank-reference.modal';
import { BankReferenceTable } from './bank-reference/bank-reference.table';
import { useRegisteredCustomerStore } from './stores/use-registered-customer-store';

export const RegisteredCustomerBankReferenceSection = () => {
  const { t } = useTranslation();
  const form = useFormContext();
  const { alert } = useAlertDialog();

  const isViewing = useRegisteredCustomerStore(state => state.isViewing);
  const isModulePerson = useRegisteredCustomerStore(state => state.isModulePerson);
  const bankReferenceToEdit = useRegisteredCustomerStore(state => state.bankReferenceToEdit);
  const setIsBankReferenceModalOpen = useRegisteredCustomerStore(state => state.setIsBankReferenceModalOpen);

  const handleAddBankReference = (data: BankReferenceFormData) => {
    const bankReference = form.getValues('bank_reference');
    form.setValue(
      'bank_reference',
      [
        ...(bankReference ?? []),
        {
          ...data,
          id: `new-${randomUUID()}`,
        },
      ],
      {
        shouldTouch: true,
      },
    );
  };

  const handleEditBankReference = (data: BankReferenceFormData) => {
    const bankReferences = form.getValues('bank_reference') as BankReferenceFormData[];
    const index = bankReferences.findIndex(bankReference => bankReference.id === data.id);
    bankReferences[index] = data;
    form.setValue('bank_reference', bankReferences);
    alert({
      type: 'success',
      title: t('bank-reference-updated'),
      description: '',
    });
  };

  return (
    <GroupForm title={t('bank-reference')}>
      <ShouldRender condition={!isViewing && isModulePerson}>
        <div className="flex justify-end w-full">
          <Button variant="default" type="button" onClick={() => setIsBankReferenceModalOpen(true)}>
            {t('add-bank-reference')}
          </Button>
        </div>
      </ShouldRender>

      <div className="mt-6">
        <BankReferenceTable />
      </div>
      <BankReferenceModal onSuccess={bankReferenceToEdit ? handleEditBankReference : handleAddBankReference} />
    </GroupForm>
  );
};

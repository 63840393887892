import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CommentOutlined } from '@ant-design/icons';
import { Spin, Steps, Popover } from 'antd';
import type { StepsProps } from 'antd';

import { usePage } from '../../../../hooks/page';
import creditApi from '../../../../services/api/credit';
import { Title } from '../../styles';
import { PropsSteps } from './types';

const { Step } = Steps;

export const StepsTimeline = ({ creditRequestId }: PropsSteps) => {
  const { t } = useTranslation();
  const { alertStatus } = usePage();
  const [creditRequestStatusHistory, setCreditRequestStatusHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const getCreditRequestStatusHistory = useCallback(() => {
    setLoading(true);
    creditApi.requests
      .getCreditRequestStatusHistoryAll(creditRequestId)
      .then((response: any) => {
        response.data.status_history.sort((a: any, b: any) => {
          const value1 = new Date(a.created_at);
          const value2 = new Date(b.created_at);
          return value1.getTime() - value2.getTime();
        });
        const filterData = response.data.status_history.filter((item: any) => item.to);
        setCreditRequestStatusHistory(filterData);
      })
      .catch(err => {
        alertStatus(err, 'error');
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (creditRequestId) getCreditRequestStatusHistory();
  }, [creditRequestId]);

  const formatDate = (date: any) => {
    const newDate = new Date(date);
    return newDate.toLocaleString('pt-br');
  };

  const popoverComment = (history: any) => {
    return (
      <>
        <Popover content={<div style={{ maxWidth: '300px' }}>{history.comments}</div>}>
          <div>{history.to.name}</div>
        </Popover>
      </>
    );
  };

  const customDot: StepsProps['progressDot'] = (dot, { index }) => {
    const data = creditRequestStatusHistory[index];
    const customIcon =
      data.comments !== '' ? (
        <span style={{ position: 'absolute', left: '-7px', top: '-4px', color: '#3470AD' }}>
          <CommentOutlined />
        </span>
      ) : (
        <span>{dot}</span>
      );
    return customIcon;
  };

  return (
    <>
      <Title style={{ marginBottom: '20px' }}>{t('pages.dashboard.timeline.table.status-history')}</Title>
      <Spin spinning={loading}>
        <Steps
          style={{ overflow: 'auto', padding: '5px' }}
          progressDot={customDot}
          current={creditRequestStatusHistory.length}
        >
          {creditRequestStatusHistory.map((history: any) => {
            return (
              <Step
                key={history.to.name}
                status="finish"
                title={history.comments !== '' ? popoverComment(history) : history.to.name}
                subTitle={history.user.name}
                description={formatDate(history?.created_at)}
              />
            );
          })}
        </Steps>
      </Spin>
    </>
  );
};

import api from './api';

export default {
  dashboard: {
    getRequester: async (): Promise<any> => {
      return api.get('credit-request/requester/all');
    },
    getProviders: async (params = {}): Promise<any> => {
      return api.get('dashboard/exposure/providers', params);
    },
  },

  management: {
    showCards: async (params: object = {}): Promise<any> => {
      return api.get(`dashboard/management`, params);
    },
    showChartCreditOperation: async (params = {}): Promise<any> => {
      return api.get(`dashboard/management-credits-operation`, params);
    },
    showChartRequest: async (params = {}): Promise<any> => {
      return api.get(`dashboard/management-requests-progress`, params);
    },
    showChartPrepay: async (params = {}): Promise<any> => {
      return api.get(`dashboard/management-balance`, params);
    },
    showChartCredit: async (params = {}): Promise<any> => {
      return api.get(`dashboard/management-credit`, params);
    },
    showTimeline: async (params = {}): Promise<any> => {
      return api.get(`dashboard/management-timeline`, params);
    },
    showResume: async (params = {}): Promise<any> => {
      return api.get(`dashboard/management-summary-operations`, params);
    },
  },

  requestPrefin: {
    showCards: async (params = {}): Promise<any> => {
      return api.get(`dashboard/prefin`, params);
    },
    showChartCredit: (params = {}): Promise<any> => {
      return api.get(`dashboard/prefin-credits-operation`, params);
    },
    showChartRequestInProgres: async (params = {}): Promise<any> => {
      return api.get(`dashboard/prefin-requests-in-progress`, params);
    },
    showChartOperationModality: async (params = {}): Promise<any> => {
      return api.get(`dashboard/prefin-operation-modality`, params);
    },
    showTimeline: async (params = {}): Promise<any> => {
      return api.get(`dashboard/management-timeline`, params);
    },
  },

  warrantyPrefin: {
    showCards: async (params = {}): Promise<any> => {
      return api.get(`dashboard/prefin-guarantees`, params);
    },
    showChartCreditsOperation: async (params = {}): Promise<any> => {
      return api.get(`dashboard/prefin-guarantees-credits-operation`, params);
    },
    showChartRequestInProgress: async (params = {}): Promise<any> => {
      return api.get(`dashboard/prefin-guarantees-requests-in-progress`, params);
    },
    showCardsChart: async (params = {}): Promise<any> => {
      return api.get(`dashboard/prefin-guarantees-sumary`, params);
    },
    showTimelineRegister: async (params = {}): Promise<any> => {
      return api.get(`dashboard/prefin-guarantees-timeline`, params);
    },
    showTimelineSignature: async (params = {}): Promise<any> => {
      return api.get(`dashboard/prefin-guarantees-timeline-signature`, params);
    },
  },

  monitoringPrefin: {
    showCards: async (params = {}): Promise<any> => {
      return api.get(`dashboard/monitoring`, params);
    },
    showChartVisitsMonitoring: async (params = {}): Promise<any> => {
      return api.get(`dashboard/survey-requests`, params);
    },
    showChartVisitsCondition: async (params = {}): Promise<any> => {
      return api.get(`dashboard/survey-stage-condition`, params);
    },
    showMap: async (params = {}): Promise<any> => {
      return api.get(`dashboard/map-surveys`, params);
    },
    showResume: async (params = {}): Promise<any> => {
      return api.get(`dashboard/summary-surveys`, params);
    },
  },

  prepay: {
    showCards: async (params = {}): Promise<any> => {
      return api.get(`dashboard/prepay`, params);
    },
    showChartPrepayOperation: async (params = {}): Promise<any> => {
      return api.get(`dashboard/prepay-credits-operation`, params);
    },
    showChartPrepayLimit: async (params = {}): Promise<any> => {
      return api.get(`dashboard/management-balance`, params);
    },
    showChartRequestProgress: async (params = {}): Promise<any> => {
      return api.get(`dashboard/prepay-requests-in-progress`, params);
    },
    showChartExposition: async (params = {}): Promise<any> => {
      return api.get(`dashboard/prepay-requests-days`, params);
    },
    showTimeline: async (params = {}): Promise<any> => {
      return api.get(`dashboard/management-timeline`, params);
    },
  },

  reports: {
    listReportResumeManagement: async (params: object = null): Promise<any> => {
      return api.get(`dashboard/export/summary-operations`, params);
    },
    generateExportResumeManagement: async (params: object = null): Promise<any> => {
      return api.post(`dashboard/export/summary-operations`, params);
    },
    listReportTimelineExposures: async (params: object = null): Promise<any> => {
      return api.get(`dashboard/exposure/report/operationalrisk`, params);
    },
    generateExportTimelineExposures: async (params: object = null): Promise<any> => {
      return api.post(`dashboard/exposure/report/operationalrisk`, params);
    },
    listReportTimelineManagement: async (params: object = null): Promise<any> => {
      return api.get(`dashboard/export/timeline`, params);
    },
    generateExportTimelineManagement: async (params: object = null): Promise<any> => {
      return api.post(`dashboard/export/timeline`, params);
    },
    listReportTimelineRequestPrefin: async (params: object = null): Promise<any> => {
      return api.get(`/dashboard/export/timeline-prefin`, params);
    },
    generateExportTimelineRequestPrefin: async (params: object = null): Promise<any> => {
      return api.post(`/dashboard/export/timeline-prefin`, params);
    },
    listReportTimelineWarranty: async (params: object = null): Promise<any> => {
      return api.get(`/dashboard/export/timeline-warranty-prefin`, params);
    },
    generateExportTimelineWarranty: async (params: object = null): Promise<any> => {
      return api.post(`/dashboard/export/timeline-warranty-prefin`, params);
    },
    listReportTimelinePrepay: async (params: object = null): Promise<any> => {
      return api.get(`/dashboard/export/timeline-prepay`, params);
    },
    generateExportTimelinePrepay: async (params: object = null): Promise<any> => {
      return api.post(`/dashboard/export/timeline-prepay`, params);
    },
    listReportResumeMonitoring: async (params: object = null): Promise<any> => {
      return api.get(`/dashboard/export/summary-survey`, params);
    },
    generateExportResumeMonitoring: async (params: object = null): Promise<any> => {
      return api.post(`/dashboard/export/summary-survey`, params);
    },
  },

  prepayExposure: {
    chart: async (params: object = null): Promise<any> => {
      return api.get(`/dashboard/exposure`, params);
    },
    table: async (params: object = null): Promise<any> => {
      return api.get(`/dashboard/exposure/list`, params);
    },
    providers: async (params: object = null): Promise<any> => {
      return api.get(`/dashboard/exposure/providers`, params);
    },
    listReport: async (params: object = null): Promise<any> => {
      return api.get('/dashboard/export/timeline-exposure', params);
    },
    generateReport: async (_ = {}, params = {}): Promise<any> => {
      return api.post('/dashboard/export/timeline-exposure', params);
    },
  },
};

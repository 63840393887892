import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useAbility } from 'hooks/ability';

import { SSwitch } from './styles';
import { SwitchProps } from './types';

const Switch: React.RefForwardingComponent<any, SwitchProps> = ({ disabled, title, permission, ...props }, ref) => {
  const ability = useAbility();
  const { t } = useTranslation();

  const hasPermission = !permission || (permission && ability.can(permission, ''));
  return (
    <SSwitch
      {...props}
      ref={ref}
      disabled={disabled || !hasPermission}
      title={!hasPermission ? t('noPermission') : title}
    />
  );
};

export default forwardRef(Switch);

import { ThirdProviderEnum } from 'types/CreditRequest/enums/ThirdProvidersEnum';
import DTODatasulProvider from 'types/Datasul/DTODatasulProvider';

export const useGetThirdProvidersFromProvider = () => {
  const getThirdProvidersFromProvider = (data: DTODatasulProvider) => {
    const dataThirdProviders: ThirdProviderEnum[] = [];
    if (data.cod_datasul) dataThirdProviders.push('datasul');
    if (data.cod_sap) dataThirdProviders.push('sap');
    return dataThirdProviders;
  };

  return { getThirdProvidersFromProvider };
};

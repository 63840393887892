import React from 'react';
import { useTranslation } from 'react-i18next';

import { Accordion } from 'components/ui/accordion';
import Loading from 'components/ui/loading';
import { PaginationList } from 'components/ui/pagination.list';
import { usePagination } from 'hooks/usePagination';
import { cn } from 'lib/utils';
import { useGetCreditRequestPackagesQuery } from 'pages/CreditRequest/api/useGetCreditRequestPackagesQuery';
import { useFormalizationGuaranteesStore } from 'pages/CreditRequest/store/useFormalizationGuaranteesStore';
import { CreditRequestPackageData } from 'types/CreditRequest/CreditRequestPackageData';

import { ContainerRegistration } from '../../styles';
import { Package } from './package';

export const PackageList = () => {
  const { paginationConfig, dispatchPagination } = usePagination();
  const { t } = useTranslation();

  const creditRequestId = useFormalizationGuaranteesStore(state => state.creditRequestId);

  const creditRequestPackagesQuery = useGetCreditRequestPackagesQuery(creditRequestId, {
    ...paginationConfig,
    dispatchPagination,
  });

  return (
    <Loading isLoading={creditRequestPackagesQuery.isLoading} className="w-full">
      <ContainerRegistration
        className={cn(
          'max-h-[500px] w-full bg-gray-100 rounded-lg p-4 flex justify-center min-h-[500px] overflow-y-auto',
          !creditRequestPackagesQuery.data?.data?.length ? 'min-h-0' : '',
        )}
      >
        {creditRequestPackagesQuery.data?.data?.length ? (
          <Accordion type="single" collapsible className="w-full pr-4">
            {creditRequestPackagesQuery.data?.data?.map((packageData: CreditRequestPackageData) => (
              <Package packageData={packageData} key={packageData.id + packageData.name} />
            ))}
          </Accordion>
        ) : (
          <div className="h-full w-full flex items-center justify-center py-16">
            <p className="font-bold text-gray-400">
              {creditRequestPackagesQuery.isLoading
                ? ''
                : t('pages.credit-request.formalization-guarantees.pre-registration-docs.noPackages')}
            </p>
          </div>
        )}
      </ContainerRegistration>

      <PaginationList
        paginationState={paginationConfig}
        paginationDispatch={dispatchPagination}
        isLoading={creditRequestPackagesQuery.isLoading || creditRequestPackagesQuery.isFetching}
      />
    </Loading>
  );
};

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaSearch } from 'react-icons/fa';

import { TableData, TablePaginationConfig, TableTranslation } from '@agrodatabr/agrodataui';
import { Form } from 'antd';
import { Button, FormItem, IconWithTooltip, Input, ModalStatus } from 'components';
import { Can } from 'hooks/ability';
import { useIntegrationRules } from 'hooks/fetch/useIntegrationRules';

import { IntegrationRuleData } from '../../../../@types/data/IntegrationRuleData';
import DTOCreditOperation from '../../../../@types/dtos/credit-request/DTOCreditOperation';
import { columns } from './columns';
import { ListProps } from './types';
import { SFilterContainer } from 'pages/Integrations/styles';

export const List = ({ setRuleToEdit, controller }: ListProps) => {
  const { getRules, isFetching, removeRule, isRemoving } = useIntegrationRules();
  const { t } = useTranslation();
  const [searchForm] = Form.useForm();

  const [filters, setFilters] = useState({});

  const handleFetchData = async (pagination: TablePaginationConfig) => {
    const response = await getRules({
      params: {
        page: pagination.current ?? 1,
        per_page: pagination.pageSize ?? 10,
        ...filters,
      },
    });

    return response;
  };

  const handleFastSearch = () => {
    const value = searchForm.getFieldsValue();
    setFilters(value);
  };

  const tableDataClearAllFilters = () => {
    searchForm.resetFields();
    setFilters({});
  };

  const handleRemoveGroup = async (groupId: string) => {
    await removeRule(groupId);

    controller.refresh();
  };

  const getActions = () => ({
    render: (_1: number, record: IntegrationRuleData) => (
      <>
        <Can I="admin.integration.rule.destroy">
          <IconWithTooltip
            action="destroy"
            title={t('pages.admin.changelog.table.icons.remove')}
            onClick={() =>
              ModalStatus({
                type: 'warning',
                iconName: 'warning',
                title: t(`modal.warning`),
                subTitle: t('pages.integrations.group.table.action.removeConfirm'),
                cancelText: t('hooks.creditStatus.modal-status-buttons.no'),
                okText: t('hooks.creditStatus.modal-status-buttons.yes'),
                onOk: () => {
                  handleRemoveGroup(record.id);
                },
              })
            }
          />
        </Can>
        <Can I="admin.integration.rule.update">
          <IconWithTooltip
            action="edit"
            title={t('pages.admin.changelog.table.icons.edit')}
            onClick={() => {
              setRuleToEdit(record);
            }}
          />
        </Can>
      </>
    ),
  });

  return (
    <div>
      <SFilterContainer>
        <Form
          form={searchForm}
          name="filter-harvest"
          className="form-secondary form-filters grid-filters"
          onFinish={handleFastSearch}
        >
          <div className="filter-search">
            <FormItem name="search" label={t('pages.register.harvest.search')}>
              <Input />
            </FormItem>
          </div>
          <div className="filter-button">
            <FormItem label="" className="form-item-without-label">
              <Button status="primary" text="uppercase" htmlType="submit">
                <FaSearch /> {t('form.actions.search')}
              </Button>
            </FormItem>
          </div>
          <div className="filter-clear">
            <Button status="secondary" size="middle" onClick={tableDataClearAllFilters}>
              {t('pages.register.harvest.buttonClearFilter')}
            </Button>
          </div>
        </Form>
      </SFilterContainer>

      <TableData
        rowKey="id"
        controller={controller}
        searchRequest={handleFetchData}
        searchFilters={filters}
        width="100%"
        columns={columns.map((column: any) => {
          let columnRender: any = {
            ...column,
            title: t(column.title),
          };

          if (column.key === 'operations') {
            columnRender = {
              ...columnRender,
              render: (value: DTOCreditOperation) => value?.name ?? '',
            };
          }

          if (column.key === 'sub_divisions') {
            columnRender = {
              ...columnRender,
              render: (value: DTOCreditOperation) => value?.name ?? '',
            };
          }

          if (column.key === 'subsidiaries') {
            columnRender = {
              ...columnRender,
              render: (value: DTOCreditOperation) => value?.name ?? '',
            };
          }

          if (column.key === 'modalities') {
            columnRender = {
              ...columnRender,
              render: (value: DTOCreditOperation) => value?.name ?? '',
            };
          }

          if (column.key === 'erp') {
            columnRender = {
              ...columnRender,
              render: (value: string) => value ?? '',
            };
          }

          return columnRender;
        })}
        loading={isFetching || isRemoving}
        tableConfig={{
          getActions,
        }}
        translation={
          {
            pagination: { totalOfRegisters: t('table.totalOfRegisters') },
            filterDropdown: {
              filterActive: 'Ativo',
              filterInactive: 'Inativo',
            },
            filterConfirm: 'Buscar',
            filterReset: 'Limpar',
          } as TableTranslation
        }
        pagination={{ position: ['bottomCenter'] }}
      />
    </div>
  );
};

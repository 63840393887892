import { useCache } from 'hooks/cache';

import { useCreditRequestStore } from '../store/useCreditRequestStore';

export const useLoadSubsidiaries = () => {
  const cache = useCache();
  const setSubsidiaries = useCreditRequestStore(state => state.setSubsidiaries);

  const loadSubsidiaries = async (regionId: string) => {
    setSubsidiaries(cache.getCreditOptions('subsidiary').filter(s => s.region_id === regionId));
  };

  return { loadSubsidiaries };
};

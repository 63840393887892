import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Dialog,
  DialogContent,
  DialogFooterForm,
  DialogHeader,
  DialogPortal,
  DialogTrigger,
} from 'components/ui/dialog';

import { useRegisteredCustomerStore } from '../stores/use-registered-customer-store';
import { liquidReceiptInitialValues, LiquidReceiptFormData, LiquidReceiptForm } from './liquid-receipt.form';

type Props = {
  children?: React.ReactNode;
  onSuccess?: (data: LiquidReceiptFormData) => void;
};

export const LiquidReceiptModal = ({ children, onSuccess }: Props) => {
  const formRef = useRef<any>(null);
  const { t } = useTranslation();

  const isLiquidReceiptModalOpen = useRegisteredCustomerStore(state => state.isLiquidReceiptModalOpen);
  const setLiquidReceiptToEdit = useRegisteredCustomerStore(state => state.setLiquidReceiptToEdit);
  const liquidReceiptToEdit = useRegisteredCustomerStore(state => state.liquidReceiptToEdit);
  const setIsLiquidReceiptModalOpen = useRegisteredCustomerStore(state => state.setIsLiquidReceiptModalOpen);

  return (
    <Dialog
      open={isLiquidReceiptModalOpen}
      onOpenChange={value => {
        setIsLiquidReceiptModalOpen(value);
        if (!value) {
          setLiquidReceiptToEdit(null);
          formRef.current?.form.reset(liquidReceiptInitialValues);
        }
      }}
    >
      <DialogTrigger>{children}</DialogTrigger>
      <DialogPortal>
        <DialogContent className="md:w-[50%] w-[96%]">
          <DialogHeader>
            {liquidReceiptToEdit ? t('edit') : t('new-f')} {t('liquid-receipt')}
          </DialogHeader>
          <LiquidReceiptForm
            ref={formRef}
            onSuccess={data => {
              setIsLiquidReceiptModalOpen(false);
              setLiquidReceiptToEdit(null);
              onSuccess?.(data);
              formRef.current?.form.reset(liquidReceiptInitialValues);
            }}
          />
          <DialogFooterForm
            onConfirm={() => {
              formRef.current?.submit();
            }}
            onCancel={() => {
              formRef.current?.form.reset(liquidReceiptInitialValues);
              setLiquidReceiptToEdit(null);
              setIsLiquidReceiptModalOpen(false);
            }}
          />
        </DialogContent>
      </DialogPortal>
    </Dialog>
  );
};

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: min-content;
  height: 100%;
`;

export const Wrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  height: 100%;
`;

export const Dot = styled.div<{ completed: boolean }>`
  height: 20px;
  width: 20px;
  background: ${({ theme: { colors }, completed }) => (completed ? colors.primary : colors.lightOrange)};
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Bar = styled.div<{ completed: boolean }>`
  height: 2px;
  min-width: 200px;
  background: ${({ theme: { colors }, completed }) => (completed ? colors.primary : colors.lightOrange)};
`;

export const Name = styled.span`
  font-size: 12px;
  display: flex;
  padding-top: 5px;
  white-space: nowrap;
  gap: 5px;
`;

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
`;

export const TitleWrapper = styled.div`
  color: ${({ theme: { colors } }) => colors.primary};
  font-size: 18px;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 20px;
  width: 80%;
`;

export const ContractName = styled.span`
  max-width: min-content;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: copy;
`;

export const DateInfo = styled.span`
  font-size: 12px;
  color: ${({ theme: { colors } }) => colors.greys.grey08};
`;

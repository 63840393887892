import { useState } from 'react';

import { handleRequest } from '../../services/api/api';
import serasaApi from '../../services/api/serasa';

export const useSerasaParameter = () => {
  const [isFetching, setIsFetching] = useState(false);

  const sendRequest = async (request: any) => {
    return handleRequest(request, setIsFetching);
  };

  const getSerasa = async (params?: { params: unknown }, filter = 'all') => {
    const response = await sendRequest(() => serasaApi.parameterization.show({ ...params, parent: filter }));

    return response || { data: [] as any[], total: 0, last_page: 0 };
  };

  const update = async (parameterizartioId: string, value: any, params = {}) => {
    const response = await sendRequest(() => serasaApi.parameterization.update(parameterizartioId, value, params));
    return response;
  };

  return {
    isFetching,
    getSerasa,
    update,
  };
};

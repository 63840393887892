import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ShouldRender } from 'components/ShouldRender';
import { DatePicker } from 'components/ui/date-picker';
import { FormItem } from 'components/ui/form-item';
import { Input, InputWithMask } from 'components/ui/input';
import { isAfter } from 'date-fns';

import { useRegisteredCustomerStore } from './stores/use-registered-customer-store';

export const RegisteredCustomerCompanyData = () => {
  const { t } = useTranslation();
  const form = useFormContext();

  const codDatasul = form.watch('cod_datasul');
  const codSap = form.watch('sap_bp_subsidiary_code');

  const isViewing = useRegisteredCustomerStore(state => state.isViewing);
  const isModulePerson = useRegisteredCustomerStore(state => state.isModulePerson);
  const isFinalizing = useRegisteredCustomerStore(state => state.isFinalizing);

  return (
    <>
      <FormItem
        label={t('pages.registered-customers.form.pj.fantasy_name')}
        name="fantasy_name"
        rules={{ required: true }}
      >
        <Input disabled={isViewing || !isModulePerson} />
      </FormItem>

      <FormItem
        label={t('pages.registered-customers.form.pj.state_registration')}
        name="state_registration"
        rules={{ required: isFinalizing }}
      >
        <Input disabled={isViewing || !isModulePerson} />
      </FormItem>

      <FormItem
        label={t('pages.registered-customers.form.pj.date_of_establishment')}
        name="date_of_establishment"
        rules={{ required: isFinalizing }}
      >
        <DatePicker
          className="w-full"
          disabledDates={day => isAfter(day, new Date())}
          disabled={isViewing || !isModulePerson}
        />
      </FormItem>

      <ShouldRender condition={!!codDatasul}>
        <FormItem name="cod_datasul" label={t('pages.registered-customers.form.pj.cod_datasul')}>
          <Input disabled />
        </FormItem>
      </ShouldRender>

      <ShouldRender condition={!!codSap}>
        <FormItem name="sap_bp_subsidiary_code" label={t('pages.registered-customers.form.pj.cod_sap')}>
          <Input disabled />
        </FormItem>
      </ShouldRender>

      <FormItem
        label={t('pages.registered-customers.form.pj.json_data.phone_number')}
        name="json_data.phone_number"
        rules={{ required: isFinalizing }}
      >
        <InputWithMask
          name="json_data.phone_number"
          mask="(99) 99999-9999"
          placeholder="(__) _____-____"
          disabled={isViewing || !isModulePerson}
        />
      </FormItem>

      <FormItem label={t('pages.registered-customers.form.pj.json_data.cel_number')} name="json_data.cel_number">
        <InputWithMask
          name="json_data.cel_number"
          mask="(99) 99999-9999"
          placeholder="(__) _____-____"
          disabled={isViewing || !isModulePerson}
        />
      </FormItem>
    </>
  );
};

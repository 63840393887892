import { PaginatedQueryParams, usePaginatedQuery } from 'hooks/usePaginatedQuery';
import { SelectInfiniteQueryParams, useSelectInfiniteQuery } from 'hooks/useSelectInfiniteQuery';
import PaginationData from 'types/Api/PaginationData';
import DraftData from 'types/CreditRequest/DraftData';

type DocumentStatusParams = {
  creditRequestId: string;
};

export const useGetDraftsQuery = (params?: PaginatedQueryParams & DocumentStatusParams) => {
  return usePaginatedQuery<DraftData>({
    queryKey: ['envelope', params.creditRequestId],
    staleTime: 1000 * 60 * 5,
    url: `envelope`,
    enabled: !!params.creditRequestId,
    params: {
      ...params,
      params: { creditRequest: params?.creditRequestId || '' },
    },
  });
};

export const useGetDraftsOptionsQuery = (params: SelectInfiniteQueryParams & DocumentStatusParams) => {
  const query = useSelectInfiniteQuery<PaginationData<DraftData>>(
    'envelope',
    {
      queryKey: ['envelope', params.creditRequestId || ''],
      staleTime: 1000 * 60 * 5,
    },
    params,
  );

  return query;
};

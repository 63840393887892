import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormItem, Select } from 'components';
import { useCreditRequestStore } from 'pages/CreditRequest/store/useCreditRequestStore';

export const JsonDataRenovation = () => {
  const { t } = useTranslation();

  const setIsBlocking = useCreditRequestStore(state => state.setIsBlocking);
  const pageStatus = useCreditRequestStore(state => state.pageStatus);

  return (
    <FormItem
      label={t('pages.credit-request.form.renovation')}
      name="json_data.renovation"
      rules={[{ required: true }]}
    >
      <Select
        options={[
          {
            key: 'exportation_yes',
            value: 1,
            label: t('pages.credit-request.form.renovation_yes'),
          },
          {
            key: 'exportation_not',
            value: 0,
            label: t('pages.credit-request.form.renovation_not'),
          },
        ]}
        disabled={pageStatus.viewing}
        onChange={async (value: any) => {
          if (value !== undefined || value !== null) {
            setIsBlocking(true);
          }
        }}
      />
    </FormItem>
  );
};

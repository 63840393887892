import { useState } from 'react';

import adminApi from '../../services/api/admin';
import { handleRequest } from '../../services/api/api';
import sustainabilityApi from '../../services/api/sustainability';

export const useSustainabilityRule = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [isFetchingForm, setIsFetchingForm] = useState(false);

  const sendRequest = async (request: any) => {
    return handleRequest(request, setIsFetching);
  };

  const getRule = async (params?: { params: unknown }, filter = 'all') => {
    const response = await sendRequest(() => sustainabilityApi.rule.get({ ...params, parent: filter }));
    return response || { data: [] as any[], total: 0, last_page: 0 };
  };

  const post = async (value: any, params = {}) => {
    const response = await handleRequest(() => sustainabilityApi.rule.store(value, params), setIsFetchingForm);
    return response;
  };

  const update = async (value: any, params = {}) => {
    const response = await handleRequest(
      () => sustainabilityApi.rule.update(value.id, value, params),
      setIsFetchingForm,
    );
    return response;
  };

  const getStatusList = async (params = {}) => {
    const response = await handleRequest(() => sustainabilityApi.reportstatus.get(params));
    if (!response)
      return {
        data: [] as any[],
        lastPage: 1,
      };

    return {
      data: response.data.map((item: any) => ({
        label: item.name,
        value: item.id,
        key: item.id,
      })),
      lastPage: response.last_page,
    };
  };

  const getPositionsList = async (params = {}) => {
    const response = await handleRequest(() => adminApi.position.get(params));
    if (!response)
      return {
        data: [] as any[],
        lastPage: 1,
      };

    return {
      data: response.data.data.map((item: any) => ({
        label: item.name,
        value: item.id,
        key: item.id,
      })),
      lastPage: response.data.last_page,
    };
  };

  const remove = async (id: string) => {
    const response = await sendRequest(() => sustainabilityApi.rule.destroy(id));
    return response;
  };

  return {
    isFetching,
    isFetchingForm,
    getRule,
    post,
    update,
    remove,
    getStatusList,
    getPositionsList,
  };
};

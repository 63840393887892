import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import PermissionedComponent from 'components/PermissionedComponent';
import { Tabs, TabsContent, TabsList, TabsTrigger } from 'components/ui/tabs';
import { useCreditRequestContext } from 'context/CreditRequestContext';
import { useAbility } from 'hooks/ability';
import { useCreditStatus } from 'hooks/creditStatus';
import { useLoadCreditRequestData } from 'pages/CreditRequest/hooks/useLoadCreditRequestData';
import { useCreditRequestStore } from 'pages/CreditRequest/store/useCreditRequestStore';

import { EmptyComponent } from '../../../../../components';
import { OPERATION_DELIVERED_PRODUCT_ADVANCE_ID, OPERATION_PREPAY_ID } from '../../../../../configs/constants';
import FormPerson from '../../../../RegisteredCustomers/DetailView';
import { useHandleActiveTab } from '../../../hooks/useHandleActiveTab';
import { PageParams } from '../../../types';
import Comments from '../../organisms/Comments';
// import Documents from './Documents';
import { SerasaRestriction } from '../../organisms/CreditKanban/Card/SerasaRestriction';
import { CreditRequest } from '../../organisms/CreditRequest/CreditRequest';
import FormalizationGuarantees from '../../organisms/FormalizationGuarantees';
import HistoricStatus from '../../organisms/HistoricStatus';
import { UserLinkedAlert } from '../../organisms/UserLinkedAlert/UserLinkedAlert';
import creditFormData from './formData';
import { Container } from './styles';
import { DetailViewProps } from './types';

export interface selectOptions {
  key?: string | number;
  extra?: any;
  value: string | number;
  label: string | number;
}

const DetailView: React.FC<DetailViewProps> = ({ creditRequestId }) => {
  const { t } = useTranslation();
  const ability = useAbility();

  const { setFormStatus, creditForm } = useCreditRequestContext();
  const { loadCreditRequestData } = useLoadCreditRequestData();
  const { teamStatusHasChanged, setTeamStatusHasChanged } = useCreditStatus();

  const selectedOperationId = useCreditRequestStore(state => state.selectedOperationId);
  const documentList = useCreditRequestStore(state => state.documentList);
  const serasaRestriction = useCreditRequestStore(state => state.serasaRestriction);
  const selectedPersonType = useCreditRequestStore(state => state.selectedPersonType);
  const selectedPersonId = useCreditRequestStore(state => state.selectedPersonId);
  const isBlocking = useCreditRequestStore(state => state.isBlocking);
  const isLoading = useCreditRequestStore(state => state.isLoading);
  const formIsBlocked = useCreditRequestStore(state => state.formIsBlocked);
  const setFormIsBlocked = useCreditRequestStore(state => state.setFormIsBlocked);
  const isPrepay = useCreditRequestStore(state => state.isPrepay);
  const isPhysicalPerson = useCreditRequestStore(state => state.isPhysicalPerson);
  const setPageStatus = useCreditRequestStore(state => state.setPageStatus);
  const pageStatus = useCreditRequestStore(state => state.pageStatus);
  const setCreditRequestId = useCreditRequestStore(state => state.setCreditRequestId);
  const reset = useCreditRequestStore(state => state.reset);
  const isEditable = useCreditRequestStore(state => state.isEditable);

  const params = useParams<PageParams>();
  const { permission: permissionParam, activeTab } = params;

  const [_, setMadeInitialLoad] = useState(false);

  const { selectedTab, handleActiveTab } = useHandleActiveTab({
    onSelectTab: {
      'tab-credit-request': () => loadCreditRequestData(creditRequestId),
    },
  });

  const [blockTeamStatusButton, setBlockTeamStatusButton] = useState<boolean>(false);

  useEffect(() => {
    if (activeTab) {
      handleActiveTab(activeTab);
    }
  }, [activeTab]);

  useEffect(() => {
    setFormIsBlocked(true);

    if (creditRequestId) {
      if (permissionParam === 'view' || (selectedOperationId === OPERATION_PREPAY_ID && permissionParam !== 'new')) {
        setPageStatus({ editing: false, viewing: true, new: false });
      } else if (permissionParam === 'edit') {
        setPageStatus({ editing: true, viewing: false, new: false });
      }
    }
    if (permissionParam === 'new') {
      setPageStatus({ editing: false, viewing: false, new: true });
    }

    setFormIsBlocked(false);

    // eslint-disable-next-line
  }, [permissionParam, creditRequestId, loadCreditRequestData, selectedOperationId]);

  // Set block/prevent leave from this page
  useEffect(() => {
    if (pageStatus.editing) {
      if (formIsBlocked) {
        setFormStatus(null);
      } else if (isBlocking) {
        setFormStatus('warning');
      }
    }
    // eslint-disable-next-line
  }, [isBlocking, formIsBlocked, pageStatus.editing]);

  useEffect(() => {
    (async () => {
      if (!creditRequestId) return;
      await loadCreditRequestData(creditRequestId);
      setMadeInitialLoad(true);
    })();
  }, [creditRequestId]);

  // Inicializa página de cadastro
  useEffect(() => {
    if (pageStatus.new) {
      creditForm.resetFields();
      creditForm.setFieldsValue(creditFormData.credit.form);
    }
    // eslint-disable-next-line
  }, [pageStatus.new]);

  useEffect(() => {
    creditForm.setFieldsValue({
      documents: documentList.map((document: any) => ({
        ...document,
      })),
    });
    // eslint-disable-next-line
  }, [documentList]);

  useEffect(() => {
    setCreditRequestId(creditRequestId);
  }, [creditRequestId]);

  useEffect(() => {
    const unloadCallback = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      event.returnValue = '';
      return '';
    };

    if (isBlocking) window.addEventListener('beforeunload', unloadCallback);
    return () => window.removeEventListener('beforeunload', unloadCallback);
  }, [isBlocking]);

  useEffect(() => {
    return () => {
      reset();
    };
  }, []);

  useEffect(() => {
    if (teamStatusHasChanged) {
      if (isEditable && pageStatus.editing && formIsBlocked) setBlockTeamStatusButton(true);
      else loadCreditRequestData();

      setTeamStatusHasChanged(false);
    }
    // eslint-disable-next-line
  }, [teamStatusHasChanged]);

  return (
    <UserLinkedAlert>
      <Container className="max-h-full h-full">
        {serasaRestriction && isPrepay && !pageStatus.new && (
          <SerasaRestriction
            fullSize
            serasaStatus={serasaRestriction?.restriction}
            document={serasaRestriction?.counterpartyDocument}
            thick
          />
        )}
        <Tabs
          defaultValue="account"
          className="w-full h-full max-h-full flex flex-col"
          value={selectedTab}
          onValueChange={activeKey => {
            handleActiveTab(activeKey);
          }}
        >
          <TabsList className="w-full pt-2">
            <TabsTrigger value="tab-general-data" title={t('pages.credit-request.tabs.general-data')}>
              {t('pages.credit-request.tabs.general-data')}
            </TabsTrigger>
            <TabsTrigger value="tab-credit-request" title={t('pages.credit-request.tabs.credit-request')}>
              {t('pages.credit-request.tabs.credit-request')}
            </TabsTrigger>
            {!pageStatus.new && (
              <>
                <TabsTrigger
                  value="tab-formalization-guarantees"
                  disabled={selectedOperationId === OPERATION_DELIVERED_PRODUCT_ADVANCE_ID}
                  title={t('pages.credit-request.tabs.formalization-guarantees')}
                >
                  {t('pages.credit-request.tabs.formalization-guarantees')}
                </TabsTrigger>
                <TabsTrigger
                  value="tab-comments"
                  disabled={
                    !ability.can('credit.request.history.resume', '') ||
                    !ability.can('credit.request.comment.index', '')
                  }
                  title={t('pages.credit-request.tabs.comments')}
                >
                  {t('pages.credit-request.tabs.comments')}
                </TabsTrigger>
                <TabsTrigger
                  value="tab-historic"
                  disabled={
                    !ability.can('credit.request.history.all', '') ||
                    !ability.can('credit.request.history.changelog', '')
                  }
                  title={t('pages.credit-request.tabs.historic')}
                >
                  {t('pages.credit-request.tabs.historic')}
                </TabsTrigger>
              </>
            )}
          </TabsList>
          <TabsContent value="tab-general-data" className="w-full max-h-full overflow-y-auto">
            <div className="">
              <>
                {selectedPersonType && (
                  <FormPerson
                    isNotModulePerson
                    personId={selectedPersonId}
                    personTypeExternal={isPhysicalPerson === true ? 'physical' : 'legal'}
                    isLoading={isLoading}
                  />
                )}
                {!selectedPersonType && <EmptyComponent description={t('pages.credit-request.tabs.notFoundPerson')} />}
              </>
            </div>
          </TabsContent>
          <TabsContent value="tab-credit-request">
            <div className="content-pannel">
              <>{/* Formulário Principal de Solicitação de Crédito */}</>
              <CreditRequest blockTeamStatusButton={blockTeamStatusButton} />
            </div>
          </TabsContent>
          {!pageStatus.new && (
            <>
              <TabsContent value="tab-formalization-guarantees" className="w-full max-h-full overflow-hidden">
                <div className=" h-[calc(100vh-140px)]">
                  <FormalizationGuarantees creditRequestId={creditRequestId} />
                </div>
              </TabsContent>
              <TabsContent value="tab-comments">
                <PermissionedComponent
                  needAllPermissions
                  permission={['credit.request.history.resume', 'credit.request.comment.index', 'credit.request.index']}
                  className="h-full"
                  description={t('permission.message.credit.request.history.resume')}
                >
                  <div className="custom-content-pannel">
                    <Comments creditRequestId={creditRequestId} />
                  </div>
                </PermissionedComponent>
              </TabsContent>
              <TabsContent value="tab-historic">
                <PermissionedComponent
                  permission={['credit.request.history.all', 'credit.request.history.changelog']}
                  description={t('permission.message.credit.request.history.all')}
                  needAllPermissions
                  className="h-full"
                >
                  <div className="historic-container">
                    <HistoricStatus creditRequestId={creditRequestId} />
                  </div>
                </PermissionedComponent>
              </TabsContent>
            </>
          )}
        </Tabs>
      </Container>
    </UserLinkedAlert>
  );
};

export default DetailView;

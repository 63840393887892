import React from 'react';

import { Modal } from 'antd';

import { DocxViewer } from '../../../../components/DocxViewer';

interface Props {
  isOpen: boolean;
  close: () => void;
  url?: string;
  name?: string;
  isFromGoogleDocs?: boolean;
}

export const ShowAttachmentModal = ({ isOpen, close, name, url, isFromGoogleDocs = false }: Props) => {
  return (
    <Modal
      width="60%"
      visible={isOpen}
      footer={null}
      onCancel={close}
      title={`Visualizar Anexo${name && ' - '}${name}`}
    >
      <div style={{ minHeight: '600px' }}>
        <DocxViewer url={url} useExternalRenderApi={isFromGoogleDocs} />
      </div>
    </Modal>
  );
};

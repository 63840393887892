import styled from 'styled-components';

export const SFilterContainer = styled.div`
  display: flex;
  align-items: flex-end;

  .grid-filters {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-areas: 'filter-start filter-end filter-button filter-clear';
    padding: 8px 0;
    width: 100%;
  }
`;

export const FilterStart = styled.div`
  grid-area: filter-start;
`;

export const FilterEnd = styled.div`
  grid-area: filter-end;
`;

export const FilterButton = styled.div`
  grid-area: filter-button;
  align-items: flex-end;
  display: flex;
`;

const Button = styled.div`
  align-items: flex-end;
  display: flex;
  padding-bottom: 7px;
`;

export const FilterClear = styled(Button)`
  grid-area: filter-clear;
  justify-content: flex-end;
`;

import React from 'react';
import { useTranslation } from 'react-i18next';

import { ColumnDef } from '@tanstack/react-table';
import { DataTable } from 'components/ui/data-table';
import { Eye } from 'lucide-react';
import moment from 'moment';
import { CreditRequestPackageData } from 'types/CreditRequest/CreditRequestPackageData';
import { Formatter } from 'utils/Formatter';

type PackageExistsTableProps = {
  packages: CreditRequestPackageData[];
};

export const PackageExistsTable = ({ packages }: PackageExistsTableProps) => {
  const { t } = useTranslation();

  const columns: ColumnDef<CreditRequestPackageData>[] = [
    {
      header: t(
        'pages.credit-request.formalization-guarantees.pre-registration-docs.modalPackage.table.header.identification',
      ),
      accessorKey: 'name',
      cell: ({ row }) => `${row.original.credit_request?.number}`,
    },
    {
      header: t(
        'pages.credit-request.formalization-guarantees.pre-registration-docs.modalPackage.table.header.requester_person',
      ),
      accessorKey: 'requester_person',
      cell: ({ row }) => `${row.original.credit_request?.requester_person?.name}`,
    },
    {
      header: t(
        'pages.credit-request.formalization-guarantees.pre-registration-docs.modalPackage.table.header.subsidiary',
      ),
      accessorKey: 'subsidiary',
      cell: ({ row }) => `${row.original.credit_request?.subsidiary?.name}`,
    },
    {
      header: t(
        'pages.credit-request.formalization-guarantees.pre-registration-docs.modalPackage.table.header.requested_amount',
      ),
      accessorKey: 'requested_amount',
      cell: ({ row }) =>
        `${Formatter.money(
          row.original.credit_request?.requested_amount,
          2,
          row.original.credit_request?.currency_type?.name
            ? row.original.credit_request?.currency_type?.name || ''
            : '',
          true,
        )}`,
    },
    {
      header: t(
        'pages.credit-request.formalization-guarantees.pre-registration-docs.modalPackage.table.header.created_at',
      ),
      accessorKey: 'created_at',
      cell: ({ row }) => `${moment(row.original.credit_request?.created_at).format('DD/MM/YYYY')}`,
    },
    {
      header: t(
        'pages.credit-request.formalization-guarantees.pre-registration-docs.modalPackage.table.header.harvest',
      ),
      accessorKey: 'harvest',
      cell: ({ row }) => `${row.original.credit_request?.harvest?.name}`,
    },
    {
      header: t('pages.credit-request.formalization-guarantees.pre-registration-docs.modalPackage.table.header.status'),
      accessorKey: 'status',
      cell: ({ row }) => `${row.original.credit_request?.team_status?.name}`,
    },
    {
      id: 'actions',
      enablePinning: true,
      accessorKey: 'action',
      size: 100,
      header: t('actions'),
      cell: ({ row }) => {
        return row.original?.credit_request ? (
          <Eye
            onClick={async () => {
              window.open(
                `/credit-request/edit/${row.original?.credit_request?.id}/tab-formalization-guarantees`,
                '_blank',
              );
            }}
          />
        ) : null;
      },
    },
  ];

  return (
    <>
      <DataTable columns={columns as any} data={packages || []} />
      <p>Deseja Cadastrar mesmo assim?</p>
    </>
  );
};

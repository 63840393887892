import React, { useEffect, useState } from 'react';

import { Col, Row, Select } from 'antd';
import { OptionsType } from 'rc-select/lib/interface';

import { FormItem } from '../../../../components';
import { useContract } from '../../../../hooks/fetch/useContract';
import { FieldWrapper, Container } from './styles';
import { ModelFieldsProps } from './types';

export const ModelFields = ({ data, handleChange }: ModelFieldsProps) => {
  const { getDatasulFieldsOptions } = useContract();

  const [datasulFields, setDatasulFields] = useState<OptionsType>([]);

  const renderField = (field: { key: string; key_api: string }) => {
    return (
      <FieldWrapper key={field.key}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <FormItem
            name={field.key}
            initialValue={field.key_api === 'undefined' ? '' : field.key_api}
            label={field.key}
          >
            <Select
              showSearch
              options={datasulFields}
              allowClear
              onChange={value => handleChange((value ?? '').toString(), field.key)}
            />
          </FormItem>
        </Col>
      </FieldWrapper>
    );
  };

  const initializeValues = async () => {
    setDatasulFields(await getDatasulFieldsOptions());
  };

  useEffect(() => {
    initializeValues();
  }, []);

  return (
    <Container>
      <h2>Campos</h2>
      <Row gutter={26}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          {data.map((field: any) => renderField(field))}
        </Col>
      </Row>
    </Container>
  );
};

import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Empty, Form } from 'antd';
import { Button, FormItem, Input, Spin } from 'components';
import { useContract } from 'hooks/fetch/useContract';
import { useModalMessage } from 'hooks/helpers/useModalMessage';
import { ContractListFilterContext } from 'pages/Contracts/context/ContractListFilterContext';
// import { Button, FormItem, Input } from 'components';
// import { useContract } from 'hooks/fetch/useContract';
// import { useModalMessage } from 'hooks/helpers/useModalMessage';
// import { ContractListFilterContext } from 'pages/Contracts/context/ContractListFilterContext';

interface Props {
  hasSearched: string;
}

export const EmptyTable = ({ hasSearched }: Props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { handleSuccess, handleError } = useModalMessage();
  const { forceContractDatasul, isForcingContractDatasul } = useContract();
  const { addFilter } = useContext(ContractListFilterContext);

  const handleForceUpdate = () => {
    form.validateFields().then(async values => {
      const response = await forceContractDatasul(values.contract_number);
      if (response) {
        addFilter({ search: values.contract_number });
        handleSuccess(t('pages.edocuments.refreshSuccess'));
      } else {
        handleError(t('pages.edocuments.refreshFail'));
      }
    });
  };

  useEffect(() => {
    form.setFieldsValue({ contract_number: hasSearched });
  }, [hasSearched]);

  return !hasSearched || hasSearched.length !== 11 ? (
    <Empty description={t('noData').toString()} />
  ) : (
    <Spin spinning={isForcingContractDatasul}>
      <div
        style={{
          width: '60%',
          margin: '0 auto',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          padding: '10px',
        }}
      >
        <h2>{t('pages.edocuments.contractlist.contractNotFound.title')}</h2>
        <h4>{t('pages.edocuments.contractlist.contractNotFound.description')}</h4>
        <br />
        <Form form={form}>
          <FormItem name="contract_number" label={t('pages.edocuments.contractlist.contractNotFound.contractNumber')}>
            <Input maxLength={255} />
          </FormItem>
        </Form>
        <Button onClick={handleForceUpdate} permission="contract.forceupdatebynumber">
          {t('pages.edocuments.contractlist.contractNotFound.force')}
        </Button>
      </div>
    </Spin>
  );
};

import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Badge } from 'antd';
import { Button } from 'components';
import { DraftModelContext } from 'pages/Contracts/context/DraftModelContext';

import { DTOContractTypeField } from '../../../../../types/Contract/DTOContractTypeField';
import { Table } from '../../TableFieldModal/Table';
import { Container } from './styles';

interface Props {
  name: string;
  isExtended?: boolean;
  field?: DTOContractTypeField;
  disabled?: boolean;
}

export const TableField = ({ name, isExtended, field, disabled }: Props) => {
  const { t } = useTranslation();
  const { handleOpenTable, getTableNumberOfRegisters } = useContext(DraftModelContext);

  return (
    <Container>
      <Badge count={getTableNumberOfRegisters(name)} showZero offset={[-5, 10]}>
        <Button onClick={() => handleOpenTable(name)}>
          {t('pages.edocuments.emission.draftEmission.fields.openTable')}
        </Button>
      </Badge>

      {isExtended && field ? <Table field={field} disabled={disabled} /> : null}
    </Container>
  );
};

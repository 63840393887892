/* eslint-disable import-helpers/order-imports */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IconWithTooltip } from '../../../../../components';
import { TableData } from '../../../../../compositions';
import { ViewDocument } from './ViewDocument';
import { Formatter } from '../../../../../utils/Formatter';
import { columns } from './columns';

import { TableProps } from './types';

export const Table = ({ documents, downloadDocument }: TableProps) => {
  const [idDocument, setIdDocument] = useState('');
  const [loading, setLoading] = useState({ type: '', loading: false });
  const { t } = useTranslation();
  const download = async (value: any) => {
    setLoading({ type: 'download', loading: true });
    const response = await downloadDocument(value);
    setLoading({ type: '', loading: response });
  };
  return (
    <div>
      <TableData
        columns={columns.map(col => {
          switch (col.key) {
            case 'created_at':
              return {
                ...col,
                render: (value: any, row: any) => {
                  return row.created_at ? Formatter.datetime(row.created_at) : '-';
                },
              };
            case 'id':
              return {
                ...col,
                render: (value: any, row: any) => {
                  return (
                    <>
                      {row.extension !== 'txt' && (
                        <IconWithTooltip
                          action="view"
                          title={t('pages.serasa.integrations.documents.table.view')}
                          onClick={() => setIdDocument(value)}
                          loading={loading.type === 'view' && loading.loading}
                        />
                      )}

                      <IconWithTooltip
                        action="download"
                        title={t('pages.serasa.integrations.documents.table.download')}
                        onClick={() => download(value)}
                        loading={loading.type === 'download' && loading.loading}
                      />
                    </>
                  );
                },
              };
            default:
              return {
                ...col,
                render: (record: any) => {
                  return record;
                },
              };
          }
        })}
        dataSource={documents}
        tableConfig={{ nopagination: true }}
      />
      <ViewDocument id={idDocument} setIdDocument={setIdDocument} setLoading={setLoading} />
    </div>
  );
};

import React from 'react';

import { SListMenu } from './styles';
import { ListMenuProps } from './types';

const ListMenu: React.FC<ListMenuProps> = ({ children, ...rest }) => {
  return (
    <SListMenu type="desktop" {...rest}>
      {children}
    </SListMenu>
  );
};

export default ListMenu;

import React from 'react';

import { SContainer } from './styles';
import { ContainerProps } from './types';

const Container: React.FC<ContainerProps> = ({ isSite, children }) => {
  return <SContainer isSite={isSite}>{children}</SContainer>;
};

export default Container;

import React from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import { Container } from './styles';

interface BackProps {
  text?: string;
  to?: string;
}

const Back: React.FC<BackProps> = ({ text, to }) => {
  return (
    <Container>
      <div className="back-icon">
        {to ? (
          <Link to={to}>
            <FaArrowLeft />
          </Link>
        ) : (
          <FaArrowLeft />
        )}
      </div>
      <div className="back-text">{text}</div>
    </Container>
  );
};

export default Back;

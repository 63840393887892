import React from 'react';
import { useTranslation } from 'react-i18next';

import { Form, Input, Modal, ModalProps } from 'antd';
import { FormItem, ModalStatus } from 'components';
import { usePage } from 'hooks/page';
import { useContractContext } from 'pages/Contracts/context/ContractContext';

export const DeleteReasonModal = ({ relatedDocumentId, ...props }: ModalProps & { relatedDocumentId: string }) => {
  const { alertStatus } = usePage();
  const { t } = useTranslation();
  const { deleteRelatedDocument, updateRelatedDocument, updateContract } = useContractContext();

  const [form] = Form.useForm();

  const { TextArea } = Input;

  const askConfirmation = () => {
    ModalStatus({
      type: 'confirm',
      title: t('modal.confirm'),
      subTitle: t('pages.edocuments.relatedDocuments.delete.confirm'),
      cancelText: t('modal.cancel'),
      onOk: async () => {
        const response = await deleteRelatedDocument({ id: relatedDocumentId, reason: form.getFieldValue('reason') });
        if (response) {
          alertStatus(t('pages.edocuments.relatedDocuments.delete.success'), 'success', 'success');
          updateRelatedDocument(response);
          updateContract(response, false);
          props.afterClose();
        }
      },
    });
  };

  const handleSubmit = () => {
    form.validateFields().then(() => {
      askConfirmation();
    });
  };

  return (
    <Modal {...props} onOk={handleSubmit}>
      <Form form={form}>
        <FormItem
          name="reason"
          label={t('pages.edocuments.emission.relatedDocuments.cancel.form.reason')}
          rules={[{ required: true }]}
        >
          <TextArea maxLength={255} />
        </FormItem>
      </Form>
    </Modal>
  );
};

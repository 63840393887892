import React from 'react';
import { useTranslation } from 'react-i18next';

import { Form as AntForm, Input, Col } from 'antd';

import { FormItem, Select, MaskedInput, Row } from '../../components';
import { useCache } from '../../hooks/cache';
import { FormProps, FieldData } from './types';

const Form: React.FC<FormProps> = ({ fields, ...rest }) => {
  const { t } = useTranslation();
  const { getOptions } = useCache();

  function renderFields() {
    return Object.keys(fields).map((value, index) => {
      const { name, label, options, type } = fields[index] as FieldData;

      switch (type) {
        case 'hidden':
          return (
            <FormItem hidden name={name} key={name.toString()}>
              <Input />
            </FormItem>
          );
        case 'select':
          return (
            <Col xs={24} sm={18} md={12} lg={9} xl={6} xxl={6} key={name.toString()}>
              <FormItem name={name} label={t(label)}>
                <Select
                  options={options.select.optionsofcache ? getOptions(options.select.optionsofcache) : []}
                  {...options.select}
                />
              </FormItem>
            </Col>
          );
        case 'mask':
          return (
            <Col xs={24} sm={18} md={12} lg={9} xl={6} xxl={6} key={name.toString()}>
              <FormItem name={name} label={t(label)}>
                <MaskedInput {...options.mask} />
              </FormItem>
            </Col>
          );
        default:
          return (
            <Col xs={24} sm={18} md={12} lg={9} xl={6} xxl={6} key={name.toString()}>
              <FormItem name={name} label={t(label)}>
                <Input />
              </FormItem>
            </Col>
          );
      }
    });
  }

  return (
    <AntForm {...rest}>
      <Row gutter={[8, 8]}>{renderFields()}</Row>
    </AntForm>
  );
};

export default Form;

import React from 'react';

import { DispatchPagination, PaginationState } from 'hooks/usePagination';
import { cn } from 'lib/utils';
import { v4 as uuid } from 'uuid';

import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from './pagination';

type Props = {
  paginationState: PaginationState;
  paginationDispatch: DispatchPagination;
  isLoading?: boolean;
};

export const PaginationList = ({ paginationDispatch, paginationState, isLoading }: Props) => {
  const handlePrevious = () =>
    paginationDispatch({
      type: 'PREVIOUS_PAGE',
    });

  const handleNext = () =>
    paginationDispatch({
      type: 'NEXT_PAGE',
    });

  const handlePage = (page: number) =>
    paginationDispatch({
      type: 'SET_CURRENT',
      payload: page,
    });

  if (paginationState.total === 0) return null;

  return (
    <Pagination className="mx-0 w-min">
      <PaginationContent>
        <PaginationItem>
          <PaginationPrevious isLoading={isLoading} disabled={paginationState.current === 1} onClick={handlePrevious} />
        </PaginationItem>
        <PaginationItem
          className={cn('hidden', paginationState.current > (paginationState.lastPage > 3 ? 2 : 3) ? 'block' : '')}
        >
          <PaginationEllipsis />
        </PaginationItem>

        {Array.from({ length: paginationState.lastPage }).map((_, index) => (
          <PaginationItem
            key={uuid()}
            className={cn(
              'hidden overflow-hidden',
              paginationState.current === 1 && index < 3
                ? 'block'
                : paginationState.current > 1 &&
                  paginationState.current < paginationState.lastPage &&
                  (index === paginationState.current - 2 ||
                    index === paginationState.current - 1 ||
                    index === paginationState.current)
                ? 'block'
                : paginationState.current === paginationState.lastPage && index > paginationState.lastPage - 4
                ? 'block'
                : '',
            )}
          >
            <PaginationLink
              isActive={paginationState.current === index + 1}
              isLoading={isLoading}
              onClick={() => handlePage(index + 1)}
            >
              {index + 1}
            </PaginationLink>
          </PaginationItem>
        ))}
        <PaginationItem
          className={cn(
            'hidden',
            paginationState.current === 1
              ? paginationState.current + 1 < paginationState.lastPage - 1
                ? 'block'
                : ''
              : paginationState.current + 1 < paginationState.lastPage
              ? 'block'
              : '',
          )}
        >
          <PaginationEllipsis />
        </PaginationItem>
        <PaginationItem>
          <PaginationNext
            isLoading={isLoading}
            disabled={paginationState.current >= paginationState.lastPage}
            onClick={handleNext}
          />
        </PaginationItem>
      </PaginationContent>
    </Pagination>
  );
};

import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormItem, Input } from 'components';
import { useCreditRequestStore } from 'pages/CreditRequest/store/useCreditRequestStore';

interface Props {
  isVisible: boolean;
  isRequired: boolean;
}

export const RequesterPersonSapCodeInput = ({ isVisible, isRequired }: Props) => {
  const { t } = useTranslation();

  const setIsBlocking = useCreditRequestStore(state => state.setIsBlocking);

  return (
    <FormItem
      label={t('pages.credit-request.form.requester_person_sap_code')}
      name="sap_bp_headoffice_subsidiary_code"
      style={{ display: !isVisible ? 'none' : '' }}
      rules={[{ required: isRequired }]}
    >
      <Input
        disabled
        onChange={value => {
          if (value) {
            setIsBlocking(true);
          }
        }}
      />
    </FormItem>
  );
};

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaFileExcel } from 'react-icons/fa';

import { Col, message } from 'antd';
import { DropdownButton, FormItem, Select } from 'components';
import { useButtonHover } from 'components/hooks';
import { ReportModal } from 'components/ReportModal';
import { getReportModalTranslation } from 'componentsTranslation/reportModal';
import { Can, useAbility } from 'hooks/ability';
import { useCache } from 'hooks/cache';
import creditApi from 'services/api/credit';
import documentApi from 'services/api/document';

const CreditRequestReport = () => {
  const { t } = useTranslation();
  const [onButtonHover, setButtonHover] = useButtonHover();
  const { getCreditOptions } = useCache();
  const ability = useAbility();

  const [exportingGeneral, setExportingGeneral] = useState(false);
  const [exportingExposure, setExportingExposure] = useState(false);
  const [exportingAdvance, setExportingAdvance] = useState(false);
  const [exportingPrefinLimits, setExportingPrefinLimits] = useState(false);

  return (
    <>
      <DropdownButton
        text="uppercase"
        hoverstate={[onButtonHover, setButtonHover]}
        listnames={[
          'Relatório Geral',
          'Relatório Exposure (PrePay)',
          'Habilitações Pré-fin',
          t('delivered_product_advance'),
        ]}
        permissions={[
          'credit.request.report.export.index',
          'credit.request.report.exposure',
          'credit.request.report.index',
          'credit.request.report.advance.index',
        ]}
        handlers={[
          () => setExportingGeneral(true),
          () =>
            ability.can('credit.request.report.exposure.index', '')
              ? setExportingExposure(true)
              : message.error(t('noPermission')),
          () => setExportingPrefinLimits(true),
          () =>
            ability.can('credit.request.report.advance.index', '')
              ? setExportingAdvance(true)
              : message.error(t('noPermission')),
        ]}
        childnode={
          <div className="flex items-center gap-1">
            <FaFileExcel />
            {t('form.actions.export')}
          </div>
        }
      />

      <Can I="credit.request.report.export.index">
        <ReportModal
          translation={getReportModalTranslation(t)}
          withDateFilter
          width="80%"
          visibility={exportingGeneral}
          afterClose={() => {
            setExportingGeneral(false);
            setButtonHover(false);
          }}
          listReportApi={creditApi.requests.listExport}
          generateReportPermission="credit.request.report.export.store"
          generateReportApi={creditApi.requests.generateExport}
          downloadReportApi={documentApi.download}
        />
      </Can>

      <Can I="credit.request.report.exposure.index">
        <ReportModal
          translation={getReportModalTranslation(t)}
          width="80%"
          visibility={exportingExposure}
          afterClose={() => {
            setExportingExposure(false);
            setButtonHover(false);
          }}
          listReportApi={creditApi.requests.listReportExposure}
          generateReportPermission="credit.request.report.exposure.store"
          generateReportApi={creditApi.requests.generateExportExposure}
          downloadReportApi={documentApi.download}
          customFields={
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <FormItem
                name="harvest_id"
                label={t('pages.edocuments.contractlist.filters.menu.harvest')}
                rules={[{ required: true }]}
              >
                <Select options={getCreditOptions('harvest')} showSearch mode="multiple" />
              </FormItem>
            </Col>
          }
        />
      </Can>
      <Can I="credit.request.report.advance.index">
        <ReportModal
          translation={getReportModalTranslation(t)}
          width="80%"
          visibility={exportingAdvance}
          afterClose={() => {
            setExportingAdvance(false);
            setButtonHover(false);
          }}
          listReportApi={creditApi.requests.listReportAdvance}
          generateReportPermission="credit.request.report.advance.store"
          generateReportApi={creditApi.requests.generateExportAdvance}
          downloadReportApi={documentApi.download}
        />
      </Can>

      <ReportModal
        translation={getReportModalTranslation(t)}
        width="80%"
        visibility={exportingPrefinLimits}
        afterClose={() => {
          setExportingPrefinLimits(false);
          setButtonHover(false);
        }}
        withDateFilter
        listReportApi={creditApi.requests.listReportPrefinLimits}
        generateReportApi={creditApi.requests.generateExportPrefinLimits}
        generateReportPermission=""
        downloadReportApi={documentApi.download}
        customFields={
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <FormItem
              name="harvest"
              label={t('pages.edocuments.contractlist.filters.menu.harvest')}
              rules={[{ required: true }]}
            >
              <Select options={getCreditOptions('harvest')} mode="multiple" />
            </FormItem>
          </Col>
        }
      />
    </>
  );
};

export default CreditRequestReport;

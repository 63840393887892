import { useCallback } from 'react';
import { useNavigate } from 'react-router';

import { SelectOptionType } from 'components/Select/types';
import { CREDIT_REQUEST_TEAM_STATUS_EDITABLE_RULE, PHYSICAL_PERSON_TYPE } from 'configs/constants';
import { useAbility } from 'hooks/ability';
import { useAuth } from 'hooks/auth';
import { useCache } from 'hooks/cache';
import { useCreditStatus } from 'hooks/creditStatus';
import { ItemsType } from 'hooks/page';
import { useCreditRequestRules } from 'hooks/rules/useCreditRequestRules';
import creditApi from 'services/api/credit';
import { ThirdProviderEnum } from 'types/CreditRequest/enums/ThirdProvidersEnum';
import { Formatter } from 'utils/Formatter';

import { useCheckCurrentUserCanApproveGuarantee } from '../rules/general/useCheckCurrentUserCanApproveGuarantee';
import { useCheckRequestHasLimitRules } from '../rules/general/useCheckRequestHasLimitRules';
import { Divisions, Operations, SubDivisions } from '../store/slices/creditRequestSlices/divisionSlice';
import { useCreditRequestStore } from '../store/useCreditRequestStore';
import { useFilterListOfNextTeamStatus } from './useFilterListOfNextTeamStatus';
import { useGetCreditRequestDepositaries } from './useGetCreditRequestDepositaries';
import { useLoadCreditRequestDocuments } from './useLoadCreditRequestDocuments';
import { useLoadCreditRequestForm } from './useLoadCreditRequestForm';
import { useLoadOperations } from './useLoadOperations';
import { useLoadSubdivisions } from './useLoadSubdivisions';

export const useLoadCreditRequestData = () => {
  const { checkRequestHasLimitRules } = useCheckRequestHasLimitRules();
  const { checkCurrentUserCanApproveGuarantee } = useCheckCurrentUserCanApproveGuarantee();
  const { loadDocuments } = useLoadCreditRequestDocuments();
  const { getDepositaries } = useGetCreditRequestDepositaries();
  const { loadSubDivisions } = useLoadSubdivisions();
  const { loadOperations } = useLoadOperations();
  const { loadForm } = useLoadCreditRequestForm();
  const { user } = useAuth();
  const { getListOfNextTeamStatus } = useCreditStatus();
  const { canShowChangeStatusSelect } = useCreditRequestRules();
  const { filterListOfNextTeamStatus } = useFilterListOfNextTeamStatus();
  const ability = useAbility();
  const cache = useCache();

  const navigate = useNavigate();

  const setIsDolarSelected = useCreditRequestStore(state => state.setIsDolarSelected);
  const setIsLoading = useCreditRequestStore(state => state.setIsLoading);
  const setBlockTeamStatusButton = useCreditRequestStore(state => state.setBlockTeamStatusButton);
  const creditRequestId = useCreditRequestStore(state => state.creditRequestId);
  const setCreditRequestData = useCreditRequestStore(state => state.setCreditRequestData);
  const setLimitRules = useCreditRequestStore(state => state.setLimitRules);
  const setCanCurrentUserApproveGuarantee = useCreditRequestStore(state => state.setCanCurrentUserApproveGuarantee);
  const setPeople = useCreditRequestStore(state => state.setPeople);
  const setSelectedDivisionId = useCreditRequestStore(state => state.setSelectedDivisionId);
  const setSelectedSubDivisionId = useCreditRequestStore(state => state.setSelectedSubDivisionId);
  const setSelectedOperationId = useCreditRequestStore(state => state.setSelectedOperationId);
  const setSerasaRestriction = useCreditRequestStore(state => state.setSerasaRestriction);
  const setPartialLimitData = useCreditRequestStore(state => state.setPartialLimitData);
  const setSelectedPersonType = useCreditRequestStore(state => state.setSelectedPersonType);
  const setDepositaryList = useCreditRequestStore(state => state.setDepositaryList);
  const setApprovedValue = useCreditRequestStore(state => state.setApprovedValue);
  const setJsonDataQuantity = useCreditRequestStore(state => state.setJsonDataQuantity);
  const setJsonDataPrice = useCreditRequestStore(state => state.setJsonDataPrice);
  const setModalityId = useCreditRequestStore(state => state.setModalityId);
  const setParamRequestedValue = useCreditRequestStore(state => state.setParamRequestedValue);
  const setSelectedPersonId = useCreditRequestStore(state => state.setSelectedPersonId);
  const setSelectedStateId = useCreditRequestStore(state => state.setSelectedStateId);
  const setStateIsSelected = useCreditRequestStore(state => state.setStateIsSelected);
  const setCustodyStateId = useCreditRequestStore(state => state.setCustodyStateId);
  const setCustodyCityId = useCreditRequestStore(state => state.setCustodyCityId);
  const setCustodyStoreUnitCommodities = useCreditRequestStore(state => state.setCustodyStoreUnitCommodities);
  const setSelectedContractId = useCreditRequestStore(state => state.setSelectedContractId);
  const setIsEditable = useCreditRequestStore(state => state.setIsEditable);
  const setThirdProviders = useCreditRequestStore(state => state.setThirdProviders);
  const setListJustification = useCreditRequestStore(state => state.setListJustification);
  const setContractTypeVisible = useCreditRequestStore(state => state.setContractTypeVisible);
  const setCustodyWarehouseAddressIsLegacy = useCreditRequestStore(state => state.setCustodyWarehouseAddressIsLegacy);
  const setTeamStatusChangeMoneyPrefix = useCreditRequestStore(state => state.setTeamStatusChangeMoneyPrefix);
  const setSelectedContractNumber = useCreditRequestStore(state => state.setSelectedContractNumber);
  const setCustodyCity = useCreditRequestStore(state => state.setCustodyCity);
  const setCustodyState = useCreditRequestStore(state => state.setCustodyState);
  const setCustodyStoreUnit = useCreditRequestStore(state => state.setCustodyStoreUnit);
  const setTeamStatusChangeApprovedValue = useCreditRequestStore(state => state.setTeamStatusChangeApprovedValue);
  const setStatusHistory = useCreditRequestStore(state => state.setStatusHistory);
  const historicStatusTablePaginationConfig = useCreditRequestStore(state => state.historicStatusTablePaginationConfig);
  const setHistoricStatusTablePaginationConfig = useCreditRequestStore(
    state => state.setHistoricStatusTablePaginationConfig,
  );
  const setTeamStatusChangeCreditRequestNumber = useCreditRequestStore(
    state => state.setTeamStatusChangeCreditRequestNumber,
  );
  const setTeamStatusChangePhaseCurrentId = useCreditRequestStore(state => state.setTeamStatusChangePhaseCurrentId);
  const setTeamStatusChangeTeamStatusCurrentId = useCreditRequestStore(
    state => state.setTeamStatusChangeTeamStatusCurrentId,
  );
  const setTeamStatusChangeRequestedAmount = useCreditRequestStore(state => state.setTeamStatusChangeRequestedAmount);
  const setTeamStatusChangeCreditRequestId = useCreditRequestStore(state => state.setTeamStatusChangeCreditRequestId);
  const setTeamStatusChangeOperationCurrentId = useCreditRequestStore(
    state => state.setTeamStatusChangeOperationCurrentId,
  );
  const setBasePort = useCreditRequestStore(state => state.setBasePort);
  const setIsFundeinfra = useCreditRequestStore(state => state.setIsFundeinfra);
  const setCanShowStatusOptions = useCreditRequestStore(state => state.setCanShowStatusOptions);
  const setListOfNextTeamStatus = useCreditRequestStore(state => state.setListOfNextTeamStatus);
  const setIsRequiredAttachFethab = useCreditRequestStore(state => state.setIsRequiredAttachFethab);
  const setIsRequiredAttachINSS = useCreditRequestStore(state => state.setIsRequiredAttachINSS);

  const loadCreditRequestData = useCallback(
    async (id?: string) => {
      setIsLoading(true);
      setBlockTeamStatusButton(false);

      if (id || creditRequestId) {
        try {
          const response = await creditApi.requests.getById(id || creditRequestId);

          const { data } = response;
          if (data) setCreditRequestData(data);

          const listOfTeamStatus = getListOfNextTeamStatus(
            data.team_status_id,
            data.operation_id,
            user.position_id,
            true,
            false,
          ) as SelectOptionType[];

          if (listOfTeamStatus) {
            setCanShowStatusOptions(canShowChangeStatusSelect(data.team_status.team_id, listOfTeamStatus.length));
            setListOfNextTeamStatus(
              filterListOfNextTeamStatus({
                listOfTeamStatus,
                team_status_id: data.team_status_id,
                datasource: data.datasource,
                has_assignees: data.has_assignees,
                approved_value: data.approved_value,
                operation: data.operation_id,
              }),
            );
          }

          setLimitRules(checkRequestHasLimitRules(data));
          setCanCurrentUserApproveGuarantee(checkCurrentUserCanApproveGuarantee(data));

          if (ability.can('credit.request.history.index', '')) {
            await creditApi.requests
              .getCreditRequestStatusHistory(id || creditRequestId, {
                params: { per_page: historicStatusTablePaginationConfig.pageSize },
              })
              .then((res: any) => {
                setHistoricStatusTablePaginationConfig({
                  ...historicStatusTablePaginationConfig,
                  current: res.data.current_page,
                  total: res.data.total,
                });
                setStatusHistory(res.data.data ?? []);
              });
          }

          if (data.requester_person) {
            const personName =
              data.requester_person_type === PHYSICAL_PERSON_TYPE
                ? data.requester_person.name
                : data.requester_person.company_name;
            const personDocument =
              data.requester_person_type === PHYSICAL_PERSON_TYPE
                ? data.requester_person.cpf
                : data.requester_person.cnpj?.padStart(14, '0');
            setPeople([
              {
                doc_number: personDocument,
                label: personName,
                value: data.requester_person_id,
              } as ItemsType,
            ]);
          }

          const dataDocuments = ability.can('credit.request.attachment.index', '')
            ? await loadDocuments({ params: { per_page: 10 } }, id || creditRequestId)
            : [];
          const returnDataDepositary = ability.can('credit.request.depositary.index', '')
            ? await getDepositaries(id || creditRequestId)
            : [];
          const dataDepositary = returnDataDepositary.map((depositary: any, index: any) => ({
            ...depositary,
            idList: index + 1,
          }));

          if (data.division?.id) {
            loadSubDivisions(data.division.id as Divisions);
            setSelectedDivisionId(data.division.id as Divisions);
          }
          if (data.sub_division?.id) {
            loadOperations(data.sub_division.id as SubDivisions);
            setSelectedSubDivisionId(data.sub_division.id as SubDivisions);
          }
          if (data.operation?.id) setSelectedOperationId(data.operation.id as Operations);

          if (data.requester_person?.serasa_counterparty)
            setSerasaRestriction({
              counterpartyDocument: data.requester_person?.serasa_counterparty?.doc_number,
              restriction: data.requester_person.serasa_counterparty.serasa_counterparty_status_id,
            });

          if (data.contract_type) {
            if (data.contract_type.name?.toLowerCase() === 'fixo') {
              setContractTypeVisible(true);
            }
          }

          if (data.json_data && data.json_data?.currency_type_id) {
            const findCurrencyType = cache.currency_type?.find(
              option => option.id === data.json_data?.currency_type_id,
            );
            if (findCurrencyType) setIsDolarSelected(!!findCurrencyType.name);
          }

          setPartialLimitData(data.partial_limit ? data.partial_limit : []);
          setSelectedPersonType(data.requester_person_type ?? 'App\\Model\\PhysicalPerson');
          setDepositaryList(dataDepositary);
          setApprovedValue(data.approved_value ?? 0);
          if (data.json_data) {
            setJsonDataQuantity(data.json_data.quantity ?? '0');
            setJsonDataPrice(data.json_data.price ?? 0);
            setModalityId(data.json_data.modality_id ?? '');
          }
          setParamRequestedValue(data.requested_amount ?? 0);
          setSelectedPersonId(data.requester_person_id ?? '');

          setSelectedStateId(data.state_id ?? '');
          setStateIsSelected(!!data.custodia_state_id);
          if (data.custodia_state) setCustodyStateId(data.custodia_state.external_id ?? '');
          if (data.custodia_city) setCustodyCityId(data.custodia_city.external_id ?? '');
          if (data.custodia_storage_unit) setCustodyStoreUnitCommodities(data.custodia_storage_unit.commodities ?? []);
          if (data.contract_fixation?.contract_id) setSelectedContractId(data.contract_fixation.contract_id);
          if (data?.justifications) setListJustification(data.justifications);
          setIsEditable(CREDIT_REQUEST_TEAM_STATUS_EDITABLE_RULE.includes(data.team_status.id));
          if (data.json_data && data.json_data?.contract_type_id) {
            setSelectedContractNumber(data.json_data?.contract_type_id);
          }

          if (data.custodia_city) setCustodyCity(data.custodia_city);
          if (data.custodia_state) setCustodyState(data.custodia_state);
          if (data.custodia_storage_unit) setCustodyStoreUnit(data.custodia_storage_unit);
          if (data.custodia_storage_unit?.commodities)
            setCustodyStoreUnitCommodities(data.custodia_storage_unit.commodities);
          setSelectedPersonId(data.requester_person_id);
          setCustodyWarehouseAddressIsLegacy(!data.custodia_state_id);

          setTeamStatusChangeApprovedValue(data.approved_value);
          setTeamStatusChangeCreditRequestNumber(data.number.toString());
          setTeamStatusChangePhaseCurrentId(data.team_status.phase_id);
          setTeamStatusChangeTeamStatusCurrentId(data.team_status.id);
          setTeamStatusChangeRequestedAmount(data.requested_amount);
          setTeamStatusChangeCreditRequestId(data.id);
          setTeamStatusChangeOperationCurrentId(data.operation_id);
          setBasePort(data.base_port);
          setIsFundeinfra(data.is_fundeinfra);
          setSelectedOperationId(data.operation_id as Operations);
          if (data.currency_type) setTeamStatusChangeMoneyPrefix(data.currency_type.slug);
          if (data.json_data.fethab === false) setIsRequiredAttachFethab(true);
          if (data.json_data.opting_inss === true) setIsRequiredAttachINSS(true);
          if (data?.justifications) setListJustification(data.justifications);
          setThirdProviders([data.datasource?.toLowerCase() as ThirdProviderEnum]);

          await loadForm({
            ...data,
            documents: dataDocuments.length
              ? dataDocuments.map((field: any) => ({
                  ...field,
                  created_at: Formatter.datetime(field.created_at, false),
                }))
              : [],
            limitpartial: undefined,
          });
        } catch (err) {
          console.error(err);
        } finally {
          setIsLoading(false);
        }
      }

      setIsLoading(false);
      // eslint-disable-next-line
    },
    [
      creditRequestId,
      navigate,
      loadSubDivisions,
      loadOperations,
      // loadForm,
    ],
  );

  return {
    loadCreditRequestData,
  };
};

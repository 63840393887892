import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AiFillFileText } from 'react-icons/ai';
import { FaDownload, FaEye, FaSync } from 'react-icons/fa';
import { GiHand } from 'react-icons/gi';

import { Col, Row } from 'antd';
import { Button, ModalStatus } from 'components';
import { DatasulStatus } from 'components/DatasulStatus';
import { SapStatus } from 'components/SapStatus';
import { SapStatusProps, SapStatusEnum } from 'components/SapStatus/types';
import { ShouldRender } from 'components/ShouldRender';
import appConfigs from 'configs/app';
import { GUARANTEE_TYPE_LABEL, GuaranteeStatus as GuaranteeStatusEnum } from 'configs/constants';
import { Can } from 'hooks/ability';
import { useGuarantees } from 'hooks/fetch/useGuarantees';
import { useDownload } from 'hooks/helpers/useDownload';
import moment from 'moment';
import { usePrefinModalityRules } from 'pages/CreditRequest/rules/prefin/usePrefinModalityRules';
import { useCreditRequestStore } from 'pages/CreditRequest/store/useCreditRequestStore';
import { downloadHandler } from 'services/download';
import { Formatter } from 'utils/Formatter';

import { SApprovalStatus, SIntegrationStatus, SRoot, SValue } from './GuaranteeCard.styles';
import {
  ValidationButtonsProps,
  ApprovalButtonProps,
  FooterProps,
  HeaderValueNumber,
  HeaderValueString,
  LabelProps,
  RootProps,
  ValueProps,
  LogListProps,
  LogListItemProps,
  GuaranteeStatusProps,
  ValidityProps,
} from './GuaranteeCard.types';

const ApprovalButton = ({ text, onClick, status, permission }: ApprovalButtonProps) => {
  return (
    <Can I={permission}>
      <Button status={status} onClick={onClick}>
        {text}
      </Button>
    </Can>
  );
};

const ApproveButton = (props: Pick<ApprovalButtonProps, 'onClick' | 'permission'>) => {
  return <ApprovalButton text="Aprovar" status="success" {...props} />;
};

const ReproveButton = (props: Pick<ApprovalButtonProps, 'onClick' | 'permission'>) => {
  return <ApprovalButton text="Reprovar" {...props} status="danger" />;
};

const ValidateButton = (props: Pick<ApprovalButtonProps, 'onClick' | 'permission'>) => {
  return <ApprovalButton text="Validar" {...props} status="success" />;
};

const RejectButton = (props: Pick<ApprovalButtonProps, 'onClick' | 'permission'>) => {
  return <ApprovalButton text="Rejeitar" {...props} status="danger" />;
};

const ValidationButtons = ({ children }: ValidationButtonsProps) => {
  return <SRoot.ApprovalButtonsContainer>{children}</SRoot.ApprovalButtonsContainer>;
};

const Label = ({ children }: LabelProps) => {
  return <strong>{children}</strong>;
};

const Value = ({ children }: ValueProps) => {
  return <SValue.Container>{children}</SValue.Container>;
};

const Footer = ({ children }: FooterProps) => {
  return <SRoot.Footer>{children}</SRoot.Footer>;
};

const ApprovalStatus = () => {
  return (
    <SApprovalStatus.Container>
      <strong>Habilitação Aprovada</strong>
    </SApprovalStatus.Container>
  );
};

const IntegrationStatus = () => {
  return <SIntegrationStatus.Container>Integração SAP em 20/08/2023</SIntegrationStatus.Container>;
};

const GuaranteeValue = ({ value }: HeaderValueNumber) => {
  const { t } = useTranslation();

  const { shouldShowLimitInputs } = usePrefinModalityRules();
  const creditRequestData = useCreditRequestStore(state => state.creditRequestData);

  return (
    <ShouldRender condition={shouldShowLimitInputs}>
      <Col xs={24} sm={18} md={12} lg={9} xl={5} xxl={5}>
        <SRoot.HeaderValue>
          <Label>{t('pages.credit-request.modal-limit.value')}</Label>
          <Value>{Formatter.money(value, 2, creditRequestData.currency_type.slug, true)}</Value>
        </SRoot.HeaderValue>
      </Col>
    </ShouldRender>
  );
};

const GuaranteeValueCASH = ({ value }: HeaderValueNumber) => {
  const { t } = useTranslation();

  const { shouldShowLimitCashInputs } = usePrefinModalityRules();
  const creditRequestData = useCreditRequestStore(state => state.creditRequestData);

  return (
    <ShouldRender condition={shouldShowLimitCashInputs}>
      <Col xs={24} sm={18} md={12} lg={9} xl={5} xxl={5}>
        <SRoot.HeaderValue>
          <Label>{t('pages.credit-request.modal-limit.value_cash')}</Label>
          <Value>{Formatter.money(value, 2, creditRequestData.currency_type.slug, true)}</Value>
        </SRoot.HeaderValue>
      </Col>
    </ShouldRender>
  );
};

const GuaranteeValueBARTER = ({ value }: HeaderValueNumber) => {
  const { t } = useTranslation();

  const { shouldShowLimitBarterInputs } = usePrefinModalityRules();
  const creditRequestData = useCreditRequestStore(state => state.creditRequestData);

  return (
    <ShouldRender condition={shouldShowLimitBarterInputs}>
      <Col xs={24} sm={18} md={12} lg={9} xl={5} xxl={5}>
        <SRoot.HeaderValue>
          <Label>{t('pages.credit-request.modal-limit.value_barter')}</Label>
          <Value>{Formatter.money(value, 2, creditRequestData.currency_type.slug, true)}</Value>
        </SRoot.HeaderValue>
      </Col>
    </ShouldRender>
  );
};

const GuaranteeType = ({ value }: HeaderValueString) => {
  const { t } = useTranslation();

  return (
    <Col xs={24} sm={18} md={12} lg={9} xl={5} xxl={5}>
      <SRoot.HeaderValue>
        <Label>{t('pages.credit-request.modal-limit.credit_request_partial_limit_type_id')}</Label>
        <Value>
          {GUARANTEE_TYPE_LABEL[value]
            ? t(`pages.credit-request.modal-limit.credit_request_partial_limit_type_${GUARANTEE_TYPE_LABEL[value]}`)
            : t('notProvided')}
        </Value>
      </SRoot.HeaderValue>
    </Col>
  );
};
const GuaranteeRequester = ({ value }: HeaderValueString) => {
  const { t } = useTranslation();

  return (
    <Col xs={24} sm={18} md={12} lg={9} xl={5} xxl={5}>
      <SRoot.HeaderValue>
        <Label>{t('pages.credit-request.modal-limit.requester_name')}</Label>
        <Value>{value}</Value>
      </SRoot.HeaderValue>
    </Col>
  );
};

const GuaranteeDate = ({ value }: HeaderValueString) => {
  const { t } = useTranslation();

  return (
    <Col xs={24} sm={18} md={12} lg={9} xl={5} xxl={5}>
      <SRoot.HeaderValue>
        <Label>{t('pages.credit-request.modal-limit.created_at')}</Label>
        <Value>{moment(value).format(appConfigs.formatDateFullTime)}</Value>
      </SRoot.HeaderValue>
    </Col>
  );
};

const Header = ({ children }: { children: React.ReactNode }) => {
  const rest = React.Children.toArray(children).filter((child: any) => child.type !== ValidationButtons);

  const validationButtons = React.Children.toArray(children).filter((child: any) => child.type === ValidationButtons);

  return (
    <SRoot.Header>
      <Row style={{ width: '100%' }}>{rest}</Row>
      {validationButtons}
    </SRoot.Header>
  );
};

// const DatasourceTag = ({ datasource }: { datasource: string }) => {
//   return (
//     <SRoot.DatasourceTag>
//       <div>{datasource.toLowerCase() === 'sap' ? <SapSvgLogo /> : <TotvsSvgLogo />}</div>
//     </SRoot.DatasourceTag>
//   );
// };

const Validity = ({ validity }: ValidityProps) => {
  if (!validity) return null;
  return <SRoot.ValidityContainer>test</SRoot.ValidityContainer>;
};

const GuaranteeStatus = ({ statusId }: GuaranteeStatusProps) => {
  const { t } = useTranslation();

  const statusIndex = useMemo(() => {
    switch (statusId) {
      case GuaranteeStatusEnum.VALIDATED:
        return 'validated';
      case GuaranteeStatusEnum.APPROVED:
        return 'approved';
      case GuaranteeStatusEnum.REJECTED:
        return 'rejected';
      case GuaranteeStatusEnum.REPROVED:
        return 'reproved';
      default:
        return 'created';
    }
  }, [statusId]);

  return (
    <SRoot.LogListStatusContainer data-status={statusIndex}>
      <span>{t(statusIndex)}</span>
    </SRoot.LogListStatusContainer>
  );
};

const LogListItem = ({ log, setFileToPreview, validityDate }: LogListItemProps) => {
  const { t } = useTranslation();

  const { download } = useDownload();

  return (
    <SRoot.LogListItem>
      <SRoot.LogListItemDataContainer>
        <SRoot.LogListItemData>
          <span>{log.user?.name ?? t('notProvided')}</span>
          <span>
            {moment(log.created_at).format(appConfigs.formatDateFullTime)}
            {log.credit_request_partial_limit_status_id === GuaranteeStatusEnum.VALIDATED && validityDate
              ? ` - ${t('pages.credit-request.modal-limit.expires_at')}: ${Formatter.date(validityDate)}`
              : ''}
          </span>
        </SRoot.LogListItemData>
        <GuaranteeStatus statusId={log.credit_request_partial_limit_status_id} />
      </SRoot.LogListItemDataContainer>
      {log.comment ? <div>{log.comment}</div> : null}
      <SRoot.LogListDocumentsList>
        {log.documents.map(document => (
          <SRoot.LogListDocument key={document.id}>
            <span>
              <SRoot.LogListDocumentIcon>
                <AiFillFileText size={24} />
              </SRoot.LogListDocumentIcon>
              <SRoot.LogListDocumentTitle>{document.name}</SRoot.LogListDocumentTitle>
            </span>
            <SRoot.LogListDocumentButtons>
              <Button size="small" type="primary" onClick={async () => downloadHandler(await download(document.id))}>
                <span style={{ display: 'flex' }}>
                  <FaDownload />
                </span>
              </Button>
              <Button size="small" type="primary" onClick={() => setFileToPreview(document)}>
                <span style={{ display: 'flex' }}>
                  <FaEye />
                </span>
              </Button>
            </SRoot.LogListDocumentButtons>
          </SRoot.LogListDocument>
        ))}
      </SRoot.LogListDocumentsList>
    </SRoot.LogListItem>
  );
};

const LogList = ({ logs, setFileToPreview, validityDate }: LogListProps) => {
  if (!logs.length) return null;

  return (
    <SRoot.LogList>
      {logs.map(log => {
        return <LogListItem setFileToPreview={setFileToPreview} log={log} validityDate={validityDate} key={log.id} />;
      })}
    </SRoot.LogList>
  );
};

const DatasulStatusTag = ({ status, approvalDate }: { status: string; approvalDate: string }) => {
  return <DatasulStatus datasulStatus={status} datasulStatusUpdatedAt={approvalDate} showTime />;
};

const SapStatusTag = (
  props: SapStatusProps & {
    guaranteeId: string;
    lastIntegrationId: string;
    datasource: string;
    refetchList: () => void;
    workerId: string;
  },
) => {
  const { t } = useTranslation();
  const { forceSapIntegration, isForcingSapIntegration, updateSapStatus, isUpdatingSapStatus } = useGuarantees();

  const { sapStatus, guaranteeId, lastIntegrationId, refetchList } = props;

  const changeSapStatusManually = async (integrationId: string) => {
    const response = await updateSapStatus(integrationId, {
      description: 'MANUAL',
      status: 'processed',
    });
    if (response) refetchList();
    return response;
  };

  const handleChangeSapStatusManually = async () => {
    if (!lastIntegrationId) return;
    ModalStatus({
      type: 'warning',
      title: t('pages.credit-request.form.action.sap_status_manually'),
      subTitle: t('pages.credit-request.form.action.sap_status_manually_description'),
      cancelText: t('pages.admin.operation.table.actions.cancel-delete'),
      okText: t('pages.admin.operation.table.actions.confirm-delete'),
      onOk: async () => {
        const response = await changeSapStatusManually(lastIntegrationId);
        if (response) {
          ModalStatus({
            type: 'success',
            title: t('modal.success'),
            subTitle: t('pages.credit-request.form.action.sap_status_manually_success'),
          });
        }
      },
    });
  };

  const handleChangeForceSapStatusIntegration = async () => {
    if (!guaranteeId) return;

    const response = await forceSapIntegration(guaranteeId);
    if (response) {
      ModalStatus({
        type: 'success',
        title: t('modal.success'),
        subTitle: t('pages.credit-request.form.action.sap_status_manually_success'),
      });
      refetchList();
    }
  };

  return (
    <ShouldRender condition={!!sapStatus}>
      <Col span={24}>
        <SRoot.SapStatusTagContainer>
          <SapStatus {...props} showTime />
          <ShouldRender condition={sapStatus === SapStatusEnum.ERROR}>
            <>
              <Can I="partial.limit.approval.store" a="">
                <Button
                  size="small"
                  onClick={handleChangeForceSapStatusIntegration}
                  loading={isForcingSapIntegration}
                  disabled={isForcingSapIntegration || !guaranteeId}
                  title={t('pages.credit-request.form.sap_status_force')}
                >
                  <span style={{ display: 'flex' }}>
                    <FaSync />
                  </span>
                </Button>
              </Can>
              <Can I="partial.limit.import.update" a="">
                <Button
                  size="small"
                  onClick={handleChangeSapStatusManually}
                  loading={isUpdatingSapStatus}
                  disabled={isUpdatingSapStatus || !guaranteeId}
                  title={t('pages.credit-request.form.sap_status_manually')}
                >
                  <span style={{ display: 'flex' }}>
                    <GiHand />
                  </span>
                </Button>
              </Can>
            </>
          </ShouldRender>
        </SRoot.SapStatusTagContainer>
      </Col>
    </ShouldRender>
  );
};

const Root = ({ children }: RootProps) => {
  const rest = React.Children.toArray(children).filter((child: any) => child.type !== Footer);

  return (
    <SRoot.Container>
      <SRoot.Body>
        <Row style={{ width: '100%' }}>{rest}</Row>
      </SRoot.Body>
    </SRoot.Container>
  );
};

export const GuaranteeCard = {
  Root,
  Footer,
  ApprovalStatus,
  ValidationButtons,
  IntegrationStatus,
  Header,
  LogList,
  GuaranteeValue,
  GuaranteeValueCASH,
  GuaranteeValueBARTER,
  GuaranteeType,
  GuaranteeRequester,
  GuaranteeDate,
  Validity,
  ValidateButton,
  ApproveButton,
  ReproveButton,
  RejectButton,
  GuaranteeStatus,
  SapStatusTag,
  DatasulStatusTag,
};

import React from 'react';

import { Tabs as ATabs } from 'antd';
import { TabsProps } from 'antd/lib/tabs';

const Tabs: React.FC<TabsProps> = ({ children, className, ...rest }) => {
  return (
    <div className={`custom-tabs ${className}`}>
      <ATabs animated tabPosition="top" type="line" {...rest} className="custom-ant-tabs">
        {children}
      </ATabs>
    </div>
  );
};

export default Tabs;

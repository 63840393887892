import React from 'react';
import { Link } from 'react-router-dom';

import { Card } from 'antd';

import { Can } from '../../../../hooks/ability';
import { SCoverCard, SAreaCard } from './styles';
import { CardProps } from './types';

const { Meta } = Card;

export const CCard = ({ listCard }: CardProps) => {
  return (
    <SAreaCard>
      {listCard.map((item: any) =>
        item.permission ? (
          <Can key={item.key} I={item.permission}>
            <Link to={item.link}>
              <Card key={item.key} hoverable className="area-card" cover={<SCoverCard>{item.icon}</SCoverCard>}>
                <Meta description={item.title} />
              </Card>
            </Link>
          </Can>
        ) : (
          <Link to={item.link}>
            <Card key={item.key} hoverable className="area-card" cover={<SCoverCard>{item.icon}</SCoverCard>}>
              <Meta description={item.title} />
            </Card>
          </Link>
        ),
      )}
    </SAreaCard>
  );
};

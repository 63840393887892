import React from 'react';

import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from './accordion';

type Props = {
  title: React.ReactNode;
  children: React.ReactNode;
  defaultOpen?: boolean;
};

export const GroupForm = ({ title, children, defaultOpen = true }: Props) => {
  return (
    <Accordion type="single" collapsible defaultValue={defaultOpen ? 'item-1' : undefined}>
      <AccordionItem value="item-1" className=" mb-2 border-0">
        <AccordionTrigger className="bg-gray-100 w-full p-0 rounded-t-lg overflow-hidden data-[state='closed']:rounded-b-lg group">
          <h2 className="bg-gray-100 w-full text-center text-md uppercase p-2 font-bold group-focus:text-primary">
            {title}
          </h2>
        </AccordionTrigger>
        <AccordionContent className="py-4 bg-gray-50 px-2 rounded-b-lg h-full">{children}</AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};

import React, { useMemo } from 'react';
import { FaArrowRight } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

import { ContractRefusalData } from 'types/Contract/ContractRefusalData';

import { ContractData } from '../../../types/Contract/ContractData';
import { Formatter } from '../../../utils/Formatter';

interface Props {
  refusals: ContractRefusalData[];
  contractData: ContractData;
}

export const RefusalsPopover = ({ refusals, contractData }: Props) => {
  const navigate = useNavigate();

  const orderedRefusals = useMemo(() => refusals.reverse(), [refusals]);

  const handleSeeMore = () =>
    navigate(
      `emission/${contractData.parent_id ?? contractData.id}?stage=6${
        contractData.parent_id ? `&related=${contractData.id}` : ''
      }`,
      { state: contractData },
    );

  return (
    <div className="flex flex-col items-center gap-[20px]">
      {orderedRefusals.slice(0, 3).map(refusal => (
        <div className="flex flex-col items-center">
          <div className="text-gray-500">{Formatter.datetime(refusal.updated_at)}</div>
          <span style={{ display: 'flex', flexDirection: 'row', gap: '10px', alignItems: 'center' }}>
            <p>{refusal.old_status.name}</p> <FaArrowRight />
            <div className="text-red-600">{refusal.new_status.name}</div>
          </span>
          <div className="font-bold text-gray-800">{refusal.user.name}</div>
        </div>
      ))}
      <span>
        <a onClick={handleSeeMore}>Ver Mais</a>
      </span>
    </div>
  );
};

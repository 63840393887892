import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100%;
  overflow-y: auto;

  .ant-spin-nested-loading {
    height: 100%;
    .ant-spin-container {
      height: 100%;
    }
  }
`;

export const Title = styled.h4`
  color: ${({ theme: { colors } }) => colors.primary};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100%-20px);
  padding-top: 20px;
`;

export const Date = styled.p`
  width: 100%;
  text-align: center;
  color: ${({ theme: { colors } }) => colors.primary};
  font-weight: bold;
`;

export const Refusal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0px;
`;

export const NewStatus = styled.p`
  color: ${({ theme: { colors } }) => colors.danger};
  font-weight: bold;
`;

export const OldStatus = styled.p`
  font-weight: bold;
`;

export const UserName = styled.p`
  font-weight: bold;
  color: ${({ theme: { colors } }) => colors.greys.grey05};
`;
export const Observation = styled.p`
  color: ${({ theme: { colors } }) => colors.greys.grey07};
  font-size: 14px;
`;

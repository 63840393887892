import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FaPlus, FaMinus, FaSearch } from 'react-icons/fa';

import { Col, Form, Spin, Modal } from 'antd';
import { Store } from 'antd/lib/form/interface';
import { TablePaginationConfig, TableProps } from 'antd/lib/table';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import ContactData from 'types/Contract/ContactData';

import PaginationData from '../../../@types/data/PaginationData';
import { ResponseListData } from '../../../@types/data/ResponseData';
import DTOErrorReponse from '../../../@types/dtos/DTOErrorReponse';
import { Breadcrumb, Button, Input, ModalStatus, FormItem, Row, IconWithTooltip } from '../../../components';
import { TableData } from '../../../compositions';
import { Can } from '../../../hooks/ability';
import { useAuth } from '../../../hooks/auth';
import { useCache } from '../../../hooks/cache';
import { usePage } from '../../../hooks/page';
import edocumentApi from '../../../services/api/edocuments';
import { formatValuesToFormData } from '../../../services/form';
import TemplateDocsData from '../../../types/CreditRequest/TemplateDocsData';
import OwnerForm from '../../Monitoring/Owner/OwnerForm';
import columns from './columns';
import { SContainer, SHeader, STitlePage, SFilterContainer, SFormContainer, SFormButtons } from './styles';
import { NodeParam } from './types';

const Farm: React.FC = () => {
  const { t, i18n } = useTranslation();
  const [form] = Form.useForm();

  const { updateUserData } = useAuth();
  const cache = useCache();
  const { alertStatus } = usePage();

  const [loadingButton, setLoadingButton] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);
  const setDisabledCities = useState(true)[1];

  // const [degrees, setDegrees] = useState(true);

  const [data, setData] = useState([]);
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [paginationConfig, setPaginationConfig] = useState<TablePaginationConfig>({
    current: 1,
    total: 1,
    pageSize: 20,
  });

  const [item_reference_form] = Form.useForm();
  const [documents, setDocument] = useState([]);

  const [visibilityForm, setVisibilityForm] = useState(false);
  const [subforms, setSubforms] = useState(null);
  useEffect(() => {
    setSubforms({
      contact_reference: item_reference_form,
    });
  }, [item_reference_form]);

  const [subformsLoading] = useState({
    contact_reference: {
      editing: null,
      removing: null,
      form: null,
      other: null,
    },
  });

  const [visibilitySubforms, setVisibilitySubforms] = useState({
    contact_reference: null,
  });

  const updateData = (row: Store) => {
    let dataUpdated: Array<Store> = data;
    if (data.length > 0) {
      const rowIndex = data.findIndex((item: Store) => row.id === item.id);
      // Se encontrar o item remove e adiciona ele atualizado na mesma posição
      if (rowIndex > -1) {
        dataUpdated.splice(rowIndex, 1, row);
      } else {
        if (dataUpdated.length === paginationConfig.pageSize) {
          // Se não encontrar, remove o último (por conta da paginação) e
          // adiciona o item na primeira posição
          dataUpdated.splice(-1, 1);
        }
        dataUpdated = [row, ...dataUpdated];
      }
    } else {
      dataUpdated.push(row);
    }

    setData(dataUpdated);
  };

  /**
   * Faz a ação de abrir um modal de subform de acordo com o 'node' passado
   * @param node NodeParam
   */
  async function subformsOpen(node: NodeParam) {
    subforms[node].resetFields();
    setVisibilitySubforms(curState => ({ ...curState, [node]: true }));
  }

  async function handleClickAddRegistrationReference() {
    subformsOpen('contact_reference');
  }

  async function handleTableRegistrationsChange() {
    item_reference_form
      .validateFields()
      .then(() => {
        setDocument([...documents, item_reference_form.getFieldsValue()]);

        setLoading(true);
        setLoading(false);

        setVisibilitySubforms(curState => ({
          ...curState,
          contact_reference: false,
        }));
      })
      .catch(() => {
        setLoading(false);
        setLoadingButton(false);
        setDisabledButton(false);
      });
  }

  const setFormOptions = useState({
    state: [],
    cities: [],
    document_type: [],
  })[1];

  // const loadCityOptions = useCallback(
  //   async (stateId: string) => {
  //     setFormOptions(state => ({
  //       ...state,
  //       cities: cache.getCityByStateOptions(stateId),
  //     }));
  //     setDisabledCities(false);
  //   },
  //   [cache],
  // );

  const handleSuccess = (messageSuccess: string) =>
    ModalStatus({
      type: 'success',
      title: t('modal.success'),
      subTitle: messageSuccess,
    });

  const loadTableData = useCallback(
    (values: Partial<OwnerForm & PaginationData<TemplateDocsData>>) => {
      setLoading(true);
      setLoadingButton(true);
      setDisabledButton(true);
      const dataSend = {
        params: {
          per_page: paginationConfig.pageSize,
          ...values,
        },
      };

      edocumentApi.template
        .get(dataSend)
        .then((response: ResponseListData<TemplateDocsData>) => {
          const result = response.data.data;
          setData(result.data);
          setPaginationConfig(paginationConfigState => ({
            ...paginationConfigState,
            current: result.current_page,
            total: result.total,
          }));
        })
        .catch((err: DTOErrorReponse) => alertStatus(err, 'error'))
        .finally(() => {
          setLoading(false);
          setLoadingButton(false);
          setDisabledButton(false);
        });
    },
    // eslint-disable-next-line
    [i18n.language, paginationConfig.pageSize],
  );

  const handleDisplayForm = () => {
    setVisibilityForm(!visibilityForm);
    setEditing(false);
    setDisabledButton(false);
    setLoadingButton(false);
    setDisabledCities(true);
    form.resetFields();
    setDocument([]);
  };

  const handleHideForm = () => {
    setVisibilityForm(false);
  };

  const handleRemoveContactChange = async (record: any, index: number) => {
    ModalStatus({
      type: 'delete',
      title: t('pages.edocuments.template_docs.delete.title'),
      subTitle: t('pages.edocuments.template_docs.delete.subtitle'),
      cancelText: t('pages.edocuments.template_docs.delete.cancel'),
      okText: t('pages.edocuments.template_docs.delete.confirm'),
      onOk: () => {
        setLoading(true);
        if (record.id !== undefined) {
          edocumentApi.template
            .destroyDocument(record.id, {
              params: {},
            })
            .then(response => {
              handleSuccess(response.data.message);
              documents.splice(index, 1);
              setDocument([...documents]);
              setLoading(false);
              handleTableChange({ current: 1 }, { active: [1] }, {}, null);
            })
            .catch((err: DTOErrorReponse) => alertStatus(err, 'error'));
        } else {
          documents.splice(index, 1);
          setDocument([...documents]);
          setLoading(false);
        }
      },
    });
  };

  const handleRemoveChange = async (record: any) => {
    ModalStatus({
      type: 'delete',
      title: t('pages.edocuments.template_docs.delete.title'),
      subTitle: t('pages.edocuments.template_docs.delete.subtitle'),
      cancelText: t('pages.edocuments.template_docs.delete.cancel'),
      okText: t('pages.edocuments.template_docs.delete.confirm'),
      onOk: () => {
        setLoading(true);
        edocumentApi.template
          .destroy(record.id, {
            params: {},
          })
          .then(response => {
            handleSuccess(response.data.message);
            handleTableChange({ current: 1 }, { active: [1] }, {}, null);
          })
          .catch((err: DTOErrorReponse) => alertStatus(err, 'error'));
      },
    });
  };

  const handleRegister = () => {
    setLoading(true);
    setLoadingButton(true);
    setDisabledButton(true);
    form
      .validateFields()
      .then(() => {
        const formValues = form.getFieldsValue();
        delete formValues.state;
        let formData = new FormData();
        formData = formatValuesToFormData(formValues, formData);

        if (documents.length > 0) {
          documents.forEach((record: any, key) => {
            formData.append(`documents[${key}][name]`, record.name);
          });
        }

        formData.append('language', i18n.language);

        edocumentApi.template
          .store(formData)
          .then((response: ResponseListData<TemplateDocsData>) => {
            const { data: farm } = response.data;
            handleSuccess(response.data.message);
            updateData(farm);
            handleHideForm();
            setLoading(false);
            setLoadingButton(false);
            setDisabledButton(false);
            form.resetFields();
            updateUserData();
          })
          .catch((err: DTOErrorReponse) => {
            alertStatus(err, 'error');
            setLoading(false);
            setLoadingButton(false);
            setDisabledButton(false);
          });
      })
      .catch((err: DTOErrorReponse) => {
        alertStatus(err, 'error');
        setLoading(false);
        setLoadingButton(false);
        setDisabledButton(false);
      });
  };

  const handleEdit = () => {
    setLoading(true);
    setLoadingButton(true);
    setDisabledButton(true);
    form
      .validateFields()
      .then(() => {
        const formValues = form.getFieldsValue();
        if (documents.length > 0) {
          documents.forEach((record: any, key) => {
            if (formValues.documents[key]) {
              formValues.documents[key].name = record.name;
            } else {
              formValues.documents.push(record);
            }
          });
        }

        formValues.language = i18n.language;

        edocumentApi.template
          .update(formValues.id, formValues)
          .then((response: ResponseListData<TemplateDocsData>) => {
            const { data: farm } = response.data;
            handleSuccess(response.data.message);
            updateUserData();
            handleHideForm();
            updateData(farm);
            setLoading(false);
            setLoadingButton(false);
            setDisabledButton(false);
            form.resetFields();
          })
          .catch((err: DTOErrorReponse) => {
            alertStatus(err, 'error');
            setLoading(false);
            setLoadingButton(false);
            setDisabledButton(false);
          });
      })
      .catch((err: DTOErrorReponse) => {
        alertStatus(err, 'error');
        setLoading(false);
        setLoadingButton(false);
        setDisabledButton(false);
      });
  };

  // Table DATA Config: BEGIN
  const getActions = () => ({
    render: (active: number, record: TemplateDocsData) => (
      <>
        <Can I="monitoring.farm.destroy" a="">
          <IconWithTooltip
            action="destroy"
            title={t('pages.edocuments.template_docs.table.icons.remove')}
            onClick={() => handleRemoveChange(record)}
          />
        </Can>
        <Can I="monitoring.farm.update" a="">
          <IconWithTooltip
            action="edit"
            title={t('pages.edocuments.template_docs.table.icons.edit')}
            onClick={() => {
              form.setFieldsValue({
                ...record,
                name: record.name,
                documents: record.documents,
              });

              setDocument(record.documents);

              form.scrollToField('name', {
                scrollMode: 'always',
                block: 'start',
                behavior: actions =>
                  actions.forEach(({ el, top }) => {
                    el.scrollTop = top - 280;
                  }),
              });

              setVisibilityForm(true);
              setEditing(true);
              setLoading(false);
              setLoadingButton(false);
              setDisabledButton(false);
            }}
          />
        </Can>
      </>
    ),
  });

  const tableConfigContact = {
    search: '',
    filtered: {},
    sorter: {},
    nopagination: true,
    getActions: () => ({
      render: (_active: number, record: ContactData, index: number) => (
        <>
          <IconWithTooltip
            action="destroy"
            title={t('pages.edocuments.template_docs.table.icons.remove')}
            onClick={() => {
              handleRemoveContactChange(record, index);
            }}
          />
        </>
      ),
    }),
  };

  const [searchForm] = Form.useForm();
  const tableConfig = {
    search: '',
    filtered: {},
    sorter: {},
    getActions,
  };

  const [tableDataConfig, setTableDataConfig] = useState(tableConfig);

  const handleFastSearch = () => {
    const value = searchForm.getFieldsValue();
    setTableDataConfig(tableDataConfigState => ({
      ...tableDataConfigState,
      search: value.search,
    }));
    loadTableData({
      search: value.search,
    });
  };

  const tableDataClearAllFilters = () => {
    searchForm.resetFields();
    setDocument([]);
    setTableDataConfig(tableDataConfigState => ({
      ...tableDataConfigState,
      filtered: {},
      search: '',
      sorter: {},
    }));
    loadTableData({ page: 1 });
  };

  const handleTableChange: TableProps<any>['onChange'] = useCallback(
    (
      pagination: TablePaginationConfig,
      filters: Record<string, FilterValue | null>,
      sorter: SorterResult<any> | SorterResult<any>[],
    ) => {
      setLoading(true);
      setTableDataConfig(tableDataConfigState => ({
        ...tableDataConfigState,
        filtered: filters,
        sorter,
      }));

      let direction = '';
      const search: any = {};
      let sort = '';

      if (sorter) {
        const { field, order } = sorter as SorterResult<any>;

        if (order === 'ascend') direction = 'asc';
        if (order === 'descend') direction = 'desc';
        sort = field?.toString();
      }

      if (filters) {
        const dataFilter: any = filters;

        if (dataFilter) {
          Object.keys(dataFilter).forEach((key: string) => {
            if (dataFilter[key] !== null && dataFilter[key] !== undefined && dataFilter[key][0]) {
              search[key] = dataFilter[key][0].toString();
            } else if (dataFilter[key] !== null && dataFilter[key] !== undefined) {
              search[key] = dataFilter[key].toString();
            } else {
              search[key] = '';
            }
          });
        }
      }

      setPaginationConfig(paginationConfigState => ({
        ...paginationConfigState,
        pageSize: pagination.pageSize,
        current: pagination.current,
      }));

      loadTableData({
        page: pagination.current,
        per_page: pagination.pageSize,
        sort,
        direction,
        ...search,
      });
    },
    [loadTableData],
  );
  // Table DATA Config: END

  useEffect(() => {
    const filters: any = {};

    handleTableChange({ current: 1, pageSize: paginationConfig.pageSize }, filters, {}, null);
  }, [handleTableChange, paginationConfig.pageSize]);

  // Load FormOptions
  useEffect(() => {
    setFormOptions(curState => ({
      ...curState,
      state: cache.getCreditOptions('state'),
      cities: cache.getCreditOptions('city'),
      document_type: cache.getPersonOptions('document_type_person'),
    }));
    // eslint-disable-next-line
  }, [cache]);

  return (
    <>
      <Breadcrumb
        items={[
          { title: t('breadcrumb.edocuments') },
          { title: t('breadcrumb.template_docs'), to: '/edocuments/template-docs' },
        ]}
      />

      <SContainer>
        <SHeader>
          <STitlePage>{t('pages.edocuments.template_docs.title_page')}</STitlePage>

          <Can I="edocument.template.store" a="">
            <Button status="primary" icon={visibilityForm ? <FaMinus /> : <FaPlus />} onClick={handleDisplayForm}>
              {t('pages.edocuments.template_docs.buttonAdd')}
            </Button>
          </Can>
        </SHeader>

        <SFormContainer visible={visibilityForm}>
          <Spin spinning={loading}>
            <h2>{t('pages.edocuments.template_docs.registerTitle')}</h2>
            <Form form={form}>
              <FormItem name="id" style={{ display: 'none' }}>
                <Input />
              </FormItem>
              <Row gutter={[26, 26]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                  <FormItem
                    style={{ paddingRight: '10px' }}
                    label={t('pages.edocuments.template_docs.form.name')}
                    name="name"
                    rules={[{ required: true }]}
                  >
                    <Input type="text" maxLength={255} />
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={[26, 26]}>
                <Col xs={20} sm={20} md={20} lg={20} xl={20} xxl={20} />
                {/* Botão Matícula */}

                <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                  <Button block onClick={handleClickAddRegistrationReference}>
                    {t('pages.edocuments.template_docs.form.addItemChecklist')}
                  </Button>
                  <FormItem name="contact_reference" style={{ display: 'none' }}>
                    <Input />
                  </FormItem>
                  <FormItem name="documents" hidden>
                    <Input />
                  </FormItem>
                </Col>
              </Row>
              {/* Tabela Matrícula */}
              <Row gutter={[26, 26]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                  <TableData
                    rowKey="id"
                    columns={columns.itensCkecklist}
                    dataSource={documents}
                    loading={loading}
                    tableConfig={tableConfigContact}
                  />
                </Col>
              </Row>
              <SFormButtons>
                <Button status="secondary" htmlType="reset" onClick={handleHideForm} disabled={disabledButton}>
                  {t('pages.edocuments.template_docs.buttonCancel')}
                </Button>
                {editing && (
                  <Button
                    status="primary"
                    htmlType="submit"
                    onClick={handleEdit}
                    loading={loadingButton}
                    disabled={disabledButton}
                  >
                    {t('pages.edocuments.template_docs.buttonEdit')}
                  </Button>
                )}
                {!editing && (
                  <Button
                    status="primary"
                    htmlType="submit"
                    onClick={handleRegister}
                    loading={loadingButton}
                    disabled={disabledButton}
                  >
                    {t('pages.edocuments.template_docs.buttonRegister')}
                  </Button>
                )}
              </SFormButtons>
            </Form>
          </Spin>
        </SFormContainer>

        {/* Modal Contato  */}
        <Modal
          title={t('pages.edocuments.template_docs.form.addItemChecklist')}
          visible={visibilitySubforms.contact_reference}
          onCancel={() =>
            setVisibilitySubforms(curState => ({
              ...curState,
              contact_reference: false,
            }))
          }
          afterClose={() => item_reference_form.resetFields()}
          width="80%"
          footer={
            <>
              <SFormButtons>
                <Button
                  status="secondary"
                  onClick={() => {
                    setVisibilitySubforms(curState => ({
                      ...curState,
                      contact_reference: false,
                    }));
                    item_reference_form.resetFields();
                  }}
                  disabled={subformsLoading.contact_reference.form}
                >
                  {t('pages.edocuments.template_docs.form.buttons.cancel')}
                </Button>
                <Button
                  status="primary"
                  onClick={() => handleTableRegistrationsChange()}
                  disabled={subformsLoading.contact_reference.form}
                >
                  {t('pages.edocuments.template_docs.form.buttons.save')}
                </Button>
              </SFormButtons>
            </>
          }
          className="modal-with-custom-footer"
        >
          <Spin spinning={subformsLoading.contact_reference.form}>
            <Form name="subform_contact_reference" form={item_reference_form}>
              <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                  <FormItem
                    style={{ paddingRight: '10px' }}
                    label={t('pages.edocuments.template_docs.form.itemChecklist')}
                    name="name"
                    rules={[{ required: true }]}
                  >
                    <Input type="text" maxLength={50} />
                  </FormItem>
                </Col>
              </Row>

              <Row />
            </Form>
          </Spin>
        </Modal>

        <SFilterContainer>
          <Form
            form={searchForm}
            name="filter-owner"
            className="form-secondary form-filters grid-filters"
            onFinish={handleFastSearch}
          >
            <div className="filter-search">
              <FormItem name="search" label={t('pages.edocuments.template_docs.search')}>
                <Input placeholder={t(`pages.edocuments.template_docs.filters.search`)} />
              </FormItem>
            </div>
            <div className="filter-button">
              <FormItem label="" className="form-item-without-label">
                <Button status="primary" text="uppercase" htmlType="submit">
                  <FaSearch /> {t('form.actions.search')}
                </Button>
              </FormItem>
            </div>
            <div className="filter-clear">
              <Button status="secondary" size="middle" onClick={tableDataClearAllFilters}>
                {t('pages.register.harvest.buttonClearFilter')}
              </Button>
            </div>
          </Form>
        </SFilterContainer>

        <TableData
          rowKey="id"
          columns={columns.farm.map((column: any) => {
            let columnRender: any = {
              ...column,
            };

            if (column.key === 'documents') {
              columnRender = {
                ...columnRender,
                render: (value: any, row: any) => {
                  let document = '';
                  row.documents.forEach((element: { name: any }) => {
                    document += document === '' ? element.name : `, ${element.name}`;
                  });

                  return document;
                },
              };
            }

            if (column.key === 'state') {
              columnRender = {
                ...columnRender,
                render: (value: any, row: any) => `${row.city.state.name}`,
              };
            }

            return columnRender;
          })}
          dataSource={data}
          loading={loading}
          onChange={handleTableChange}
          pagination={paginationConfig}
          tableConfig={tableDataConfig}
        />
      </SContainer>
    </>
  );
};

export default Farm;

import React from 'react';
import { BiSearch } from 'react-icons/bi';

import { Container } from './styles';
import { TitleProps } from './types';

export const RelatedDocumentsTitle = ({ text }: TitleProps) => {
  return (
    <Container>
      {text}
      <BiSearch size={18} />
    </Container>
  );
};

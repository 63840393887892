import React from 'react';
import { useTranslation } from 'react-i18next';

import { Col } from 'antd';
import { IconWithTooltip, Ellipsis } from 'components';
import { TableData } from 'compositions';
import appConfigs from 'configs/app';
import moment from 'moment';
import JustificationData from 'types/CreditRequest/JustificationData';

import columns from './columns';
import { TableProps } from './types';

export const TableJustification = ({ listJustification, openModalView }: TableProps) => {
  const { t } = useTranslation();
  return (
    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
      <TableData
        rowKey="id"
        columns={columns.justification.map(col => {
          switch (col.key) {
            case 'created_at':
              return {
                ...col,
                render: (record: JustificationData) => {
                  const value: any = record;
                  return value ? moment(value).format(appConfigs.formatDate) : ' - ';
                },
              };
            case 'id':
              return {
                ...col,
                render: (record: JustificationData) => {
                  return (
                    <>
                      <IconWithTooltip
                        action="view"
                        title={t('pages.credit-request.justification.view.viewJustification')}
                        onClick={() => openModalView(record)}
                      />
                    </>
                  );
                },
              };
            case 'user':
              return {
                ...col,
                render: (record: JustificationData) => {
                  const value: any = record;
                  return <div>{value?.name}</div>;
                },
              };
            default:
              return {
                ...col,
                render: (record: JustificationData) => {
                  const value: any = record;
                  return <Ellipsis value={value} size={150} />;
                },
              };
          }
        })}
        dataSource={listJustification}
        tableConfig={{ nopagination: true }}
      />
    </Col>
  );
};

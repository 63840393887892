import { useCreditRequestContext } from 'context/CreditRequestContext';
import { useCache } from 'hooks/cache';

import {
  advanceProductDeliveredCalculation,
  prepaymentCalculation,
} from '../components/templates/DetailView/prepayment';
import { useCreditRequestStore } from '../store/useCreditRequestStore';

export const useCalculateFields = () => {
  const cache = useCache();
  const { creditForm } = useCreditRequestContext();

  const isPrepay = useCreditRequestStore(state => state.isPrepay);
  const isDeliveredProductAdvance = useCreditRequestStore(state => state.isDeliveredProductAdvance);
  const selectedDivisionId = useCreditRequestStore(state => state.selectedDivisionId);
  const selectedSubDivisionId = useCreditRequestStore(state => state.selectedSubDivisionId);
  const selectedOperationId = useCreditRequestStore(state => state.selectedOperationId);

  function onChangeFieldsRecalculate() {
    if (isPrepay)
      prepaymentCalculation(creditForm, cache, selectedDivisionId, selectedSubDivisionId, selectedOperationId);

    if (isDeliveredProductAdvance)
      // advanceProductDeliveredCalculation(creditForm, cache, selectedSubDivision);
      advanceProductDeliveredCalculation(creditForm, cache);
  }

  return {
    onChangeFieldsRecalculate,
  };
};

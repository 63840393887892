import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormItem, MaskedInput } from 'components';
import { useCreditRequestStore } from 'pages/CreditRequest/store/useCreditRequestStore';

export const JsonDataGuarantorDocNumberInput = () => {
  const { t } = useTranslation();

  const setIsBlocking = useCreditRequestStore(state => state.setIsBlocking);
  const pageStatus = useCreditRequestStore(state => state.pageStatus);

  return (
    <FormItem
      label={t('pages.credit-request.form.json_data_guarantor_doc_number')}
      name="json_data.guarantor_doc_number"
    >
      <MaskedInput
        name="json_data.guarantor_doc_number"
        mask="111.111.111-11"
        placeholder="___.___.___-__"
        disabled={pageStatus.viewing}
        onChange={value => {
          if (value) {
            setIsBlocking(true);
          }
        }}
      />
    </FormItem>
  );
};

import React from 'react';

import { useSidebar } from '../../hooks/sidebar';
import { Container } from './styles';
import { FixedFooterProps } from './types';

const FixedFooter: React.FC<FixedFooterProps> = ({ children, status }) => {
  const { isMini } = useSidebar();
  return (
    <>
      <Container status={status} sidebarMini={isMini}>
        {children}
      </Container>
    </>
  );
};

export default FixedFooter;

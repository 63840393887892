import React from 'react';
import { useTranslation } from 'react-i18next';

import { Dropdown, Menu } from 'antd';
import { useAbility } from 'hooks/ability';
import { useAuth } from 'hooks/auth';

import { Container, DropdownButtonChild } from './styles';
import { ButtonCustomProps, ButtonDropdownCustomProps } from './types';

const Button: React.FC<ButtonCustomProps> = ({ disabled, permission, title, needAllPermissions, ...props }) => {
  const ability = useAbility();
  const { t } = useTranslation();

  const hasPermission =
    !permission ||
    (Array.isArray(permission)
      ? needAllPermissions
        ? permission.every(p => ability.can(p, ''))
        : permission.some(p => ability.can(p, ''))
      : ability.can(permission, ''));

  return (
    <Container
      size="middle"
      {...props}
      disabled={disabled || !hasPermission}
      className="custom-btn"
      title={!hasPermission ? t('noPermission') : title}
    />
  );
};

const DropdownButton: React.FC<ButtonDropdownCustomProps> = ({ ...props }) => {
  // eslint-disable-next-line prefer-destructuring
  const { permissions } = useAuth();
  const { handlers } = props;
  const namesList = props.listnames;
  const permissionsList = props.permissions;
  const [onHover, setHoverState] = props.hoverstate;

  const menu = (
    <Menu>
      {namesList.map((item, index) => {
        let havePermissions = false;
        if (permissionsList) {
          permissions.filter((i: any) => i === permissionsList[index] && (havePermissions = true));
        } else havePermissions = true;

        if (havePermissions) {
          return (
            <Menu.Item key={Math.random()}>
              <span onClick={() => handlers[index]()}>{item}</span>
            </Menu.Item>
          );
        }
        return null;
      })}
    </Menu>
  );
  return (
    <Dropdown onVisibleChange={bool => setHoverState(bool)} overlay={menu} placement="bottomCenter">
      <DropdownButtonChild isdropdownvisible={onHover} size="middle" className="custom-btn" {...props}>
        {props.childnode}
      </DropdownButtonChild>
    </Dropdown>
  );
};

export { DropdownButton };
export default Button;

import React from 'react';
import { useTranslation } from 'react-i18next';

import { GroupForm } from 'components/ui/group-form';

import { ChangelogTable } from './change-log/changelog.table';

type Props = {
  personType: 'physical' | 'legal';
  customerId: string;
};

export const RegisteredCustomerChangeLogSection = ({ personType, customerId }: Props) => {
  const { t } = useTranslation();

  return (
    <GroupForm title={t('change-history')}>
      <ChangelogTable personType={personType} customerId={customerId} />
    </GroupForm>
  );
};

import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormItem } from 'components/ui/form-item';
import { GroupForm } from 'components/ui/group-form';
import { Upload } from 'components/ui/upload';

import { PackageAttachmentsTable } from './package-attachments.table';

export const PackageAttachmentsSection = () => {
  const { t } = useTranslation();

  return (
    <>
      <GroupForm title={t('pages.credit-request.formalization-guarantees.pre-registration-docs.modalPackage.anexos')}>
        <FormItem name="files">
          <Upload
            permissions={['credit.request.package.upload']}
            allowedExtensions={[
              '.docx',
              '.jpg',
              '.png',
              '.jpeg',
              '.xls',
              '.xlsx',
              '.doc',
              '.ppt',
              '.pptx',
              '.pdf',
              '.xml',
            ]}
            showUploadList
          />
        </FormItem>

        <div className="py-2">
          <PackageAttachmentsTable />
        </div>
      </GroupForm>
    </>
  );
};

import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormItem, Input } from 'components';

export const UserNameInput = () => {
  const { t } = useTranslation();
  return (
    <FormItem label={t('pages.credit-request.form.user_id')} name="user_name">
      <Input disabled />
    </FormItem>
  );
};

export default {
  farm: [
    {
      title: 'pages.edocuments.template_docs.table.header.template',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'pages.edocuments.template_docs.table.header.itensChecklist',
      dataIndex: 'documents',
      key: 'documents',
    },
    {
      title: 'pages.edocuments.template_docs.table.header.actions',
      key: 'action',
      dataIndex: 'action',
      align: 'center',
      width: '180px',
      fixed: 'right',
    },
  ],
  itensCkecklist: [
    {
      title: 'pages.edocuments.template_docs.table.header.itemChecklist',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'pages.monitoring.farm.table.header.actions',
      key: 'action',
      dataIndex: 'action',
      width: '100px',
    },
  ],
};

import React, { forwardRef } from 'react';

import { PermissionedField } from 'components/PermissionedField';
import { ComboBoxInfinite, ComboBoxInfiniteProps } from 'components/ui/combo.infinite';
import { usePagination } from 'hooks/usePagination';
import { useGetPackageTypesOptionsQuery } from 'pages/CreditRequest/api/useGetPackageTypesQuery';

type Props<T = unknown> = Omit<ComboBoxInfiniteProps<T>, 'infiniteQuery'>;

export const PackageTypeSelect = forwardRef((props: Props, ref: React.Ref<HTMLInputElement> | null) => {
  const { dispatchPagination: dispatchPackageTypes, paginationConfig: packageTypesPaginationConfig } = usePagination({
    initialPagination: {
      pageSize: 10,
      current: 1,
      total: 0,
      lastPage: 1,
    },
  });

  const packageTypesInfiniteQuery = useGetPackageTypesOptionsQuery({ ...packageTypesPaginationConfig });

  return (
    <PermissionedField permission={['credit.request.packagetype.all']}>
      <ComboBoxInfinite
        {...props}
        ref={ref}
        infiniteQuery={packageTypesInfiniteQuery}
        onSearch={value => dispatchPackageTypes({ type: 'SET_SEARCH', payload: value })}
      />
    </PermissionedField>
  );
});
PackageTypeSelect.displayName = 'PackageTypeSelect';

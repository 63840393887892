import styled from 'styled-components';

export const SDepositary = styled.div`
  .badge-table-depositary-new {
    .ant-badge-count {
      background: ${({ theme: { colors } }) => colors.status.status05};
    }
  }
  .badge-table-depositary-edited {
    .ant-badge-count {
      background: ${({ theme: { colors } }) => colors.status.status03};
    }
  }
`;

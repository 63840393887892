import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ShouldRender } from 'components/ShouldRender';
import { useAlertDialog } from 'components/ui/alert-dialog';
import { Button } from 'components/ui/button';
import { GroupForm } from 'components/ui/group-form';
import { randomUUID } from 'lib/utils';

import { MainProviderFormData } from './main-providers/main-providers.form';
import { MainProvidersModal } from './main-providers/main-providers.modal';
import { MainProvidersTable } from './main-providers/main-providers.table';
import { useRegisteredCustomerStore } from './stores/use-registered-customer-store';

export const RegisteredCustomerMainProvidersSection = () => {
  const { t } = useTranslation();
  const form = useFormContext();
  const { alert } = useAlertDialog();

  const isViewing = useRegisteredCustomerStore(state => state.isViewing);
  const isModulePerson = useRegisteredCustomerStore(state => state.isModulePerson);
  const setIsMainProviderModalOpen = useRegisteredCustomerStore(state => state.setIsMainProviderModalOpen);
  const mainProviderToEdit = useRegisteredCustomerStore(state => state.mainProviderToEdit);

  const handleAddMainProvider = (data: MainProviderFormData) => {
    const mainProviders = form.getValues('main_provider') as MainProviderFormData[];

    form.setValue(
      'main_provider',
      [
        ...(mainProviders ?? []),
        {
          ...data,
          id: `new-${randomUUID()}`,
        },
      ],
      {
        shouldTouch: true,
      },
    );
  };

  const handleEditMainProvider = (data: MainProviderFormData) => {
    const mainProviders = form.getValues('main_provider') as MainProviderFormData[];
    const index = mainProviders.findIndex(bankReference => bankReference.id === data.id);
    mainProviders[index] = data;
    form.setValue('main_provider', mainProviders, {
      shouldTouch: true,
    });
    alert({
      type: 'success',
      title: t('main-provider-updated'),
      description: '',
    });
  };

  return (
    <GroupForm title={t('main-providers')}>
      <ShouldRender condition={!isViewing && isModulePerson}>
        <div className="flex justify-end w-full">
          <Button type="button" variant="default" onClick={() => setIsMainProviderModalOpen(true)}>
            {t('add-main-provider')}
          </Button>
        </div>
      </ShouldRender>

      <div className="mt-6">
        <MainProvidersTable />
      </div>

      <MainProvidersModal onSuccess={mainProviderToEdit ? handleEditMainProvider : handleAddMainProvider} />
    </GroupForm>
  );
};

import React from 'react';
import { useTranslation } from 'react-i18next';

import { InputNumber } from 'antd';
import { FormItem } from 'components';
import { useCreditRequestContext } from 'context/CreditRequestContext';
import { useCreditRequestStore } from 'pages/CreditRequest/store/useCreditRequestStore';
import { CreditRequestValidator } from 'utils/CreditRequestValidator';
import { Formatter } from 'utils/Formatter';

export const QuantityContractInput = () => {
  const { t } = useTranslation();
  const { creditForm } = useCreditRequestContext();

  const setIsBlocking = useCreditRequestStore(state => state.setIsBlocking);
  const contractFieldsValue = useCreditRequestStore(state => state.contractFieldsValue);
  const pageStatus = useCreditRequestStore(state => state.pageStatus);

  return (
    <FormItem
      label={t('pages.credit-request.form.json_data_quantity_contract')}
      name="json_data.quantity_contract"
      rules={[
        {
          validator: async (_, value) => {
            const formValues = creditForm.getFieldsValue();

            const quantityKg = Number(formValues['json_data.quantity_kg']);
            const quantityReceived = Number(formValues['json_data.quantity_received']);
            const quantityAvailable = Number(formValues['json_data.quantity_delivery']);

            creditForm.setFields([
              {
                name: 'json_data.quantity_kg',
                errors: !CreditRequestValidator.volume(quantityKg, value, quantityReceived, quantityAvailable)
                  ? [t('pages.credit-request.messages.contract_volume_exceeded')]
                  : [],
              },
            ]);
          },
        },
      ]}
    >
      <InputNumber<string>
        min="0"
        formatter={(value: string | number) => Formatter.integer(value)}
        parser={(value: string) => Formatter.integerParser(value)}
        disabled={
          pageStatus.viewing ||
          (contractFieldsValue && contractFieldsValue['json_data.quantity_contract'] !== undefined)
        }
        onChange={value => {
          if (value) {
            setIsBlocking(true);
          }
        }}
      />
    </FormItem>
  );
};

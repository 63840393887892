import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  * {
    font-size: 14px;
  }
`;

export const Title = styled.span`
  padding: 20px 0 5px;
  font-size: 10px;
  color: ${({ theme: { colors } }) => colors.greys.grey07};
`;

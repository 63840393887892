import styled from 'styled-components';

export const Container = styled.div`
  width: 80%;
  margin: 0 auto;
  margin-bottom: 50px;

  .ant-spin-nested-loading {
    width: 100%;
    .ant-spin-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
`;

export const Date = styled.span`
  margin-bottom: 16px;
`;

import {
  OPERATION_DELIVERED_PRODUCT_ADVANCE_ID,
  OPERATION_FERTILIZER_SUPPLIERS_ID,
  OPERATION_INTERN_MARKET_ID,
  OPERATION_MTM_ID,
  OPERATION_PREFIN_ID,
  OPERATION_PREFIN_SUGAR_MILLS_ID,
  OPERATION_PREPAY_ID,
} from 'configs/constants';
import { Operations } from 'pages/CreditRequest/store/slices/creditRequestSlices/divisionSlice';

export const useCheckSelectedOperation = () => {
  return {
    checkIsPrefin: (operationId: Operations) => operationId === OPERATION_PREFIN_ID,
    checkIsPrepay: (operationId: Operations) => operationId === OPERATION_PREPAY_ID,
    checkIsMTM: (operationId: Operations) => operationId === OPERATION_MTM_ID,
    checkIsInternMarket: (operationId: Operations) => operationId === OPERATION_INTERN_MARKET_ID,
    checkIsFertilizerSuppliers: (operationId: Operations) => operationId === OPERATION_FERTILIZER_SUPPLIERS_ID,
    checkIsDeliveredProductAdvance: (operationId: Operations) => operationId === OPERATION_DELIVERED_PRODUCT_ADVANCE_ID,
    checkIsPrefinSugarMills: (operationId: Operations) => operationId === OPERATION_PREFIN_SUGAR_MILLS_ID,
  };
};

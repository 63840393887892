import { AiFillDatabase, AiFillDollarCircle, AiOutlineBarChart } from 'react-icons/ai';
import { BsGearFill, BsPersonSquare } from 'react-icons/bs';
import {
  FaBook,
  FaColumns,
  FaDownload,
  FaFileAlt,
  FaFolderOpen,
  FaLeaf,
  FaMapMarkerAlt,
  FaMoneyCheckAlt,
  FaSuitcase,
  FaUser,
  FaUserCheck,
  FaUsers,
  FaUserShield,
  FaUserTag,
  FaUserTie,
  FaFileInvoice,
  FaHome,
  FaShip,
} from 'react-icons/fa';
import { GiField, GiFiles } from 'react-icons/gi';
import { ImInsertTemplate } from 'react-icons/im';
import { MdAssignment, MdDashboard, MdMonetizationOn } from 'react-icons/md';
import { RiRoadMapFill, RiMoneyDollarCircleLine } from 'react-icons/ri';

import * as PageComponents from '../pages';

export interface Route {
  id?: string;
  forAll?: boolean;
  path?: string;
  exact?: boolean;
  isPrivate?: boolean;
  ifAuthRedirect?: boolean;
  permission?: string;
  component?: React.ComponentType;
  menu?: {
    icon?: string | React.ComponentType;
    key: string;
    label: string;
    parent?: string;
    submenu?: string;
  };
}

const routes: Route[] = [
  {
    path: '/',
    exact: true,
    isPrivate: false,
    component: PageComponents.Login,
  },
  {
    path: '/login',
    exact: true,
    isPrivate: false,
    component: PageComponents.Login,
  },
  {
    path: '/forgot', // Recuperação de senha - link para /recover/:token
    exact: true,
    isPrivate: false,
    component: PageComponents.Forgot,
  },
  {
    path: '/expired', // Recuperação de senha - link para /recover/:token
    exact: true,
    component: PageComponents.Expired,
    isPrivate: true,
    ifAuthRedirect: false,
  },
  {
    path: '/email/verify/:user?/:hash?',
    exact: true,
    isPrivate: false,
    component: PageComponents.ValidateEmail,
    ifAuthRedirect: false,
  },
  {
    path: '/two-factor-auth',
    exact: true,
    isPrivate: false,
    component: PageComponents.twoFactorAuth,
    ifAuthRedirect: false,
  },
  {
    path: '/recover', // Criar nova senha
    exact: true,
    isPrivate: false,
    component: PageComponents.Recover,
  },
  {
    path: '/docusing',
    exact: false,
    isPrivate: true,
    component: PageComponents.Docusing,
  },
  {
    path: '/home',
    forAll: true,
    exact: true,
    isPrivate: true,
    permission: 'home',
    component: PageComponents.Home,
    menu: {
      icon: FaHome,
      key: 'home',
      parent: 'home',
      label: 'menu.home',
    },
  },
  // dashboard
  {
    id: 'dashboard',
    permission: 'dashboard',
    exact: true,
    isPrivate: true,
    menu: {
      icon: MdDashboard,
      key: 'dashboard',
      parent: 'dashboard',
      label: 'menu.dashboard',
    },
  },
  {
    path: '/dashboard/management',
    exact: true,
    isPrivate: true,
    permission: 'dashboard.management.index',
    component: PageComponents.Management,
    menu: {
      icon: AiOutlineBarChart,
      key: 'management',
      submenu: 'dashboard',
      label: 'menu.submenu.dashboard.management',
    },
  },
  {
    path: '/dashboard/requests-prefin',
    exact: true,
    isPrivate: true,
    permission: 'dashboard.requests.prefin.index',
    component: PageComponents.RequestsPrefin,
    menu: {
      icon: MdAssignment,
      key: 'requests-prefin',
      submenu: 'dashboard',
      label: 'menu.submenu.dashboard.requests-prefin',
    },
  },
  {
    path: '/dashboard/warranty-prefin',
    exact: true,
    isPrivate: true,
    permission: 'dashboard.warranty.prefin.index',
    component: PageComponents.WarrantyPrefin,
    menu: {
      icon: AiOutlineBarChart,
      key: 'warranty-prefin',
      submenu: 'dashboard',
      label: 'menu.submenu.dashboard.warranty-prefin',
    },
  },
  {
    path: '/dashboard/monitoring-prefin',
    exact: true,
    isPrivate: true,
    permission: 'dashboard.monitoring.prefin.index',
    component: PageComponents.MonitoringPrefin,
    menu: {
      icon: FaMoneyCheckAlt,
      key: 'monitoring-prefin',
      submenu: 'dashboard',
      label: 'menu.submenu.dashboard.monitoring-prefin',
    },
  },
  /** Cadastro de Pessoas */
  {
    path: '/dashboard/prepay',
    exact: true,
    isPrivate: true,
    permission: 'dashboard.prepay.index',
    component: PageComponents.Prepay,
    menu: {
      icon: MdMonetizationOn,
      key: 'prepay',
      submenu: 'dashboard',
      label: 'menu.submenu.dashboard.prepay',
    },
  },
  {
    path: '/dashboard/expoprepay',
    exact: true,
    isPrivate: true,
    permission: 'dashboard.exposure.index',
    component: PageComponents.ExposurePrepay,
    menu: {
      icon: MdMonetizationOn,
      key: 'expoprepay',
      submenu: 'dashboard',
      label: 'menu.submenu.dashboard.expoprepay',
    },
  },
  {
    path: '/dashboard/exposures',
    exact: true,
    isPrivate: true,
    permission: 'dashboard.exposure.operationalrisk',
    component: PageComponents.Exposures,
    menu: {
      icon: MdMonetizationOn,
      key: 'exposures',
      submenu: 'dashboard',
      label: 'menu.submenu.dashboard.exposures',
    },
  },
  {
    path: '/registered-customers',
    exact: true,
    isPrivate: true,
    permission: 'person',
    component: PageComponents.RegisteredCustomers,
    menu: {
      icon: FaUser,
      key: 'register-prefin',
      parent: 'registered-customers',
      label: 'menu.register-prefin',
    },
  },
  {
    path: '/registered-customers/:personType/:permission',
    exact: true,
    isPrivate: true,
    permission: 'person.index',
    component: PageComponents.RegisteredCustomers,
  },
  {
    path: '/registered-customers/:personType/:permission/:id',
    exact: true,
    isPrivate: true,
    permission: 'person.index',
    component: PageComponents.RegisteredCustomers,
  },
  /** Solicitação de Crédito */
  {
    path: '/credit-request',
    exact: true,
    isPrivate: true,
    permission: 'credit',
    component: PageComponents.CreditRequest,
    menu: {
      icon: FaMoneyCheckAlt,
      key: 'credit-request',
      parent: 'credit-request',
      label: 'menu.credit-request',
    },
  },
  {
    path: '/credit-request/:permission/',
    exact: false,
    isPrivate: true,
    permission: 'credit.request.index',
    component: PageComponents.CreditRequest,
  },
  {
    path: '/credit-request/:permission/:id',
    exact: false,
    isPrivate: true,
    permission: 'credit.request.index',
    component: PageComponents.CreditRequest,
  },
  {
    path: '/credit-request/:permission/:id/:activeTab',
    exact: false,
    isPrivate: true,
    permission: 'credit.request.index',
    component: PageComponents.CreditRequest,
  },
  /** Downloads */
  {
    path: '/downloads',
    forAll: true,
    exact: true,
    isPrivate: true,
    permission: 'downloads',
    component: PageComponents.Downloads,
    menu: {
      icon: FaDownload,
      key: 'downloads',
      parent: 'downloads',
      label: 'menu.downloads',
    },
  },
  /** Administração */
  {
    id: 'admin',
    permission: 'admin',
    menu: {
      icon: FaUserShield,
      key: 'admin',
      parent: 'admin',
      label: 'menu.admin',
    },
  },
  {
    path: '/admin/permission',
    exact: true,
    isPrivate: true,
    permission: 'admin.permission.index',
    component: PageComponents.Permission,
    menu: {
      icon: FaUserCheck,
      key: 'permission',
      submenu: 'admin',
      label: 'menu.submenu.admin.menu_permission',
    },
  },
  {
    path: '/admin/role',
    exact: true,
    isPrivate: true,
    permission: 'admin.role.index',
    component: PageComponents.Role,
    menu: {
      icon: FaUserTag,
      key: 'role',
      submenu: 'admin',
      label: 'menu.submenu.admin.menu_role',
    },
  },
  {
    path: '/admin/user',
    exact: true,
    isPrivate: true,
    permission: 'admin.user.index',
    component: PageComponents.User,
    menu: {
      icon: FaUsers,
      key: 'user',
      submenu: 'admin',
      label: 'menu.submenu.admin.menu_users',
    },
  },
  {
    path: '/admin/changelog',
    exact: true,
    isPrivate: true,
    permission: 'admin.changelog.index',
    component: PageComponents.ChangeLog,
    menu: {
      icon: FaBook,
      key: 'changelog',
      submenu: 'admin',
      label: 'menu.submenu.admin.menu_changelog',
    },
  },
  {
    path: '/admin/position',
    exact: true,
    isPrivate: true,
    permission: 'admin.position.index',
    component: PageComponents.Position,
    menu: {
      icon: FaSuitcase,
      key: 'position',
      submenu: 'admin',
      label: 'menu.submenu.admin.menu_position',
    },
  },
  {
    path: '/admin/phase',
    exact: true,
    isPrivate: true,
    permission: 'credit.phase.index',
    forAll: true,
    component: PageComponents.Phases,
    menu: {
      icon: FaColumns,
      key: 'phase',
      submenu: 'admin',
      label: 'menu.submenu.admin.menu_phase',
    },
  },
  {
    path: '/admin/teams',
    exact: true,
    isPrivate: true,
    permission: 'admin.team.index',
    component: PageComponents.Teams,
    menu: {
      icon: FaColumns,
      key: 'teams',
      submenu: 'admin',
      label: 'menu.submenu.admin.menu_teams',
    },
  },
  {
    path: '/admin/status',
    exact: true,
    isPrivate: true,
    permission: 'credit.request.status.index',
    component: PageComponents.Status,
    menu: {
      icon: FaColumns,
      key: 'status',
      submenu: 'admin',
      label: 'menu.submenu.admin.menu_status',
    },
  },
  {
    path: '/admin/team-credit-status',
    exact: true,
    isPrivate: true,
    permission: 'credit.request.teamstatus.index',
    component: PageComponents.TeamStatus,
    menu: {
      icon: FaColumns,
      key: 'team-credit-status',
      submenu: 'admin',
      label: 'menu.submenu.admin.menu_team_credit_status',
    },
  },
  {
    path: '/admin/credit-request-parameterization',
    exact: true,
    isPrivate: true,
    permission: 'credit.request.parametrization',
    component: PageComponents.CreditRequestParameterization,
    menu: {
      icon: BsGearFill,
      key: 'credit-request-parameterization',
      submenu: 'admin',
      label: 'menu.submenu.admin.menu_credit_request_parameterization',
    },
  },
  /** Integrações */
  {
    id: 'integrations',
    permission: 'admin',
    menu: {
      icon: AiFillDatabase,
      key: 'integrations',
      parent: 'integrations',
      label: 'menu.integrations',
    },
  },
  {
    path: '/integrations/dataProviders',
    exact: true,
    isPrivate: true,
    permission: 'admin.integration.import.index',
    component: PageComponents.DataProviders,
    menu: {
      icon: BsPersonSquare,
      key: 'dataProviders',
      submenu: 'integrations',
      label: 'menu.submenu.integrations.dataProviders',
    },
  },
  {
    path: '/integrations/rules',
    exact: true,
    isPrivate: true,
    permission: 'admin.integration.rule.index',
    component: PageComponents.IntegrationRules,
    menu: {
      icon: BsPersonSquare,
      key: 'rules',
      submenu: 'integrations',
      label: 'menu.submenu.integrations.rules',
    },
  },
  {
    path: '/integrations/fileUpload',
    exact: true,
    isPrivate: true,
    permission: 'admin.integration.import.index',
    component: PageComponents.ExposureFileUpload,
    menu: {
      icon: BsPersonSquare,
      key: 'exposurefileupload',
      submenu: 'integrations',
      label: 'menu.submenu.integrations.exposurefileupload',
    },
  },
  {
    path: '/integrations/groups',
    exact: true,
    isPrivate: true,
    permission: 'admin.integration.import.index',
    component: PageComponents.Groups,
    menu: {
      icon: BsPersonSquare,
      key: 'groups',
      submenu: 'integrations',
      label: 'menu.submenu.integrations.groups',
    },
  },
  /** Registros - MasterData */
  {
    id: 'register',
    permission: 'register',
    menu: {
      icon: FaBook,
      key: 'register',
      parent: 'register',
      label: 'menu.register',
    },
  },
  {
    path: '/register/harvest',
    exact: true,
    isPrivate: true,
    permission: 'register.harvest.index',
    component: PageComponents.Harvest,
    menu: {
      icon: FaLeaf,
      key: 'harvest',
      submenu: 'register',
      label: 'menu.submenu.register.menu_harvest',
    },
  },
  {
    path: '/register/operation',
    exact: true,
    isPrivate: true,
    permission: 'register.operation.index',
    component: PageComponents.Operation,
    menu: {
      icon: BsGearFill,
      key: 'operation',
      submenu: 'register',
      label: 'menu.submenu.admin.menu_operation',
    },
  },
  {
    path: '/register/port',
    exact: true,
    isPrivate: true,
    permission: 'register.baseport.index',
    component: PageComponents.BasePort,
    menu: {
      icon: FaShip,
      key: 'port',
      submenu: 'register',
      label: 'menu.submenu.admin.menu_port',
    },
  },
  /** Monitoramento */
  {
    id: 'monitoring',
    permission: 'monitoring',
    menu: {
      icon: FaUserTie,
      key: 'monitoring',
      parent: 'monitoring',
      label: 'menu.monitoring',
    },
  },
  {
    path: '/monitoring/farm',
    exact: true,
    isPrivate: true,
    permission: 'monitoring.farm.index',
    component: PageComponents.Farm,
    menu: {
      icon: GiField,
      key: 'farm',
      submenu: 'monitoring',
      label: 'menu.submenu.register.menu_farm',
    },
  },
  {
    path: '/monitoring/cpr',
    exact: true,
    isPrivate: true,
    permission: 'monitoring.cpr.index',
    component: PageComponents.Cpr,
    menu: {
      icon: FaLeaf,
      key: 'cpr',
      submenu: 'monitoring',
      label: 'menu.submenu.monitoring.menu_cpr',
    },
  },
  {
    path: '/monitoring/cpr/:cpr/registration',
    exact: true,
    isPrivate: true,
    permission: 'monitoring.registration.index',
    component: PageComponents.Registration,
  },
  {
    path: '/monitoring/cpr/:cpr/registration/:registration/survey',
    exact: true,
    isPrivate: true,
    permission: 'monitoring.survey.index',
    component: PageComponents.Survey,
  },
  {
    path: '/monitoring/cpr/:cpr/registration/:registration/survey/new',
    exact: true,
    isPrivate: true,
    permission: 'monitoring.survey.store',
    component: PageComponents.SurveyUpdate,
  },
  {
    path: '/monitoring/cpr/:cpr/registration/:registration/survey/edit/:survey',
    exact: true,
    isPrivate: true,
    permission: 'monitoring.survey.update',
    component: PageComponents.SurveyUpdate,
  },
  {
    path: '/monitoring/operator',
    exact: true,
    isPrivate: true,
    permission: 'monitoring.operator.index',
    component: PageComponents.Operator,
    menu: {
      icon: FaLeaf,
      key: 'operator',
      submenu: 'monitoring',
      label: 'menu.submenu.monitoring.operator',
    },
  },
  {
    path: '/monitoring/surveyrequest',
    exact: true,
    isPrivate: true,
    permission: 'monitoring.surveyrequest.index',
    component: PageComponents.SurveyRequest,
    menu: {
      icon: FaLeaf,
      key: 'surveyrequest',
      submenu: 'monitoring',
      label: 'menu.submenu.monitoring.surveyrequest',
    },
  },
  {
    path: '/monitoring/surveyrequest/new',
    exact: true,
    isPrivate: true,
    permission: 'monitoring.surveyrequest.create',
    component: PageComponents.SurveyForm,
  },
  {
    path: '/monitoring/surveyrequest/edit/:surveyRequest',
    exact: true,
    isPrivate: true,
    permission: 'monitoring.surveyrequest.create',
    component: PageComponents.SurveyForm,
  },
  {
    path: '/register/region',
    exact: true,
    isPrivate: true,
    permission: 'register.region.index',
    component: PageComponents.Region,
    menu: {
      icon: RiRoadMapFill,
      key: 'region',
      submenu: 'register',
      label: 'menu.submenu.register.menu_region',
    },
  },
  {
    path: '/register/price',
    exact: true,
    isPrivate: true,
    permission: 'register.dailyprice.index',
    component: PageComponents.PriceDay,
    menu: {
      icon: AiFillDollarCircle,
      key: 'price',
      submenu: 'register',
      label: 'menu.submenu.register.menu_price',
    },
  },
  {
    path: '/register/subsidiary',
    exact: true,
    isPrivate: true,
    permission: 'register.subsidiary.index',
    component: PageComponents.Subsidiary,
    menu: {
      icon: FaMapMarkerAlt,
      key: 'subsidiary',
      submenu: 'register',
      label: 'menu.submenu.register.menu_subsidiary',
    },
  },
  /** E-Documents */
  {
    id: 'edocuments',
    permission: 'edocument',
    menu: {
      icon: FaFolderOpen,
      key: 'edocuments',
      parent: 'edocuments',
      label: 'menu.edocuments',
    },
  },
  // {
  //   path: '/edocuments/contracts',
  //   exact: true,
  //   isPrivate: true,
  //   permission: 'edocument.contract.index',
  //   component: PageComponents.Contracts,
  //   menu: {
  //     icon: FaFileAlt,
  //     key: 'contracts',
  //     submenu: 'edocuments',
  //     label: 'menu.submenu.edocuments.menu_contracts',
  //   },
  // },
  {
    path: '/edocuments/template-docs',
    exact: true,
    isPrivate: true,
    permission: 'edocument.contract.index',
    component: PageComponents.TemplateDocs,
    menu: {
      icon: FaFileAlt,
      key: 'templatedocs',
      submenu: 'edocuments',
      label: 'menu.submenu.edocuments.menu_template_docs',
    },
  },
  {
    path: '/edocuments/contracts',
    exact: true,
    isPrivate: true,
    permission: 'contract.index',
    component: PageComponents.ContractList,
    menu: {
      icon: GiFiles,
      key: 'constractList',
      submenu: 'edocuments',
      label: 'menu.submenu.edocuments.contractlist',
    },
  },
  {
    path: '/edocuments/emission/:id',
    exact: true,
    isPrivate: true,
    permission: 'contract.show',
    component: PageComponents.Emission,
  },
  {
    path: '/edocuments/contracttypes',
    exact: true,
    isPrivate: true,
    permission: 'contract.type.index',
    component: PageComponents.ContractTypes,
    menu: {
      icon: FaFileInvoice,
      key: 'contracttypes',
      submenu: 'edocuments',
      label: 'menu.submenu.edocuments.contracttypes',
    },
  },
  {
    path: '/edocuments/templates',
    exact: true,
    isPrivate: true,
    permission: 'contract.template.index',
    component: PageComponents.Templates,
    menu: {
      icon: ImInsertTemplate,
      key: 'templates',
      submenu: 'edocuments',
      label: 'menu.submenu.edocuments.templates',
    },
  },
  // {
  //   path: '/edocuments/contracts/importations',
  //   exact: true,
  //   isPrivate: true,
  //   permission: 'admin.permission.index',
  //   component: PageComponents.ContractImportations,
  // },
  /** Notificações */
  {
    path: '/notifications',
    exact: true,
    isPrivate: true,
    component: PageComponents.Notifications,
  },

  /** Sustainability */
  {
    id: 'sustainability',
    exact: true,
    isPrivate: true,
    permission: 'sustainability',
    menu: {
      icon: FaLeaf,
      key: 'sustainability',
      parent: 'sustainability',
      label: 'menu.sustainability',
    },
  },
  {
    path: '/sustainability/request-report',
    forAll: true,
    exact: true,
    isPrivate: true,
    permission: 'sustainability.request.index',
    component: PageComponents.Sustainability,
    menu: {
      icon: FaLeaf,
      key: 'sustainability-request-report',
      submenu: 'sustainability',
      label: 'menu.submenu.sustainability.menu_sustainability_request_report',
    },
  },
  {
    path: '/sustainability/request-report/new/:creditrequest',
    exact: true,
    isPrivate: true,
    permission: 'sustainability.request.index',
    component: PageComponents.Sustainability,
  },
  {
    path: '/sustainability/request-report/:id',
    exact: true,
    isPrivate: true,
    permission: 'sustainability.request.index',
    component: PageComponents.Sustainability,
  },
  {
    path: '/sustainability/requests',
    forAll: true,
    exact: true,
    isPrivate: true,
    permission: 'sustainability.request.index',
    component: PageComponents.SustainabilityRequests,
    menu: {
      icon: FaLeaf,
      key: 'sustainability-requests',
      submenu: 'sustainability',
      label: 'menu.submenu.sustainability.menu_sustainability_requests',
    },
  },
  {
    path: '/sustainability/parameterization',
    forAll: true,
    exact: true,
    isPrivate: true,
    permission: 'sustainability.userrule.index',
    component: PageComponents.SustainabilityUserRules,
    menu: {
      icon: BsGearFill,
      key: 'sustainability_parameterization',
      submenu: 'sustainability',
      label: 'menu.submenu.sustainability.menu_sustainability_parameterization',
    },
  },
  {
    path: '/sustainability/team-status',
    exact: true,
    isPrivate: true,
    permission: 'sustainability.rule.index',
    component: PageComponents.SustainabilityTeamStatus,
    menu: {
      icon: FaColumns,
      key: 'sustainability-team-status',
      submenu: 'sustainability',
      label: 'menu.submenu.sustainability.menu_sustainability_team_status',
    },
  },
  /** Serasa */
  {
    id: 'serasa',
    permission: 'serasa',
    menu: {
      icon: BsGearFill,
      key: 'serasa',
      parent: 'serasa',
      label: 'menu.serasa',
    },
  },
  {
    path: '/serasa/counterparty',
    forAll: true,
    exact: true,
    isPrivate: true,
    permission: 'serasa.counterparty.index',
    component: PageComponents.SerasaCounterparty,
    menu: {
      icon: RiMoneyDollarCircleLine,
      key: 'counterparty-index',
      submenu: 'serasa',
      label: 'menu.submenu.serasa.menu_counterparty',
    },
  },
  {
    path: '/serasa/counterparty/:id',
    forAll: true,
    exact: true,
    isPrivate: true,
    permission: 'counterparty.index',
    component: PageComponents.SerasaCounterpartyEdit,
  },
  {
    path: '/serasa/parameterization',
    forAll: true,
    exact: true,
    isPrivate: true,
    permission: 'serasa.parameterization.index',
    component: PageComponents.SerasaParameterization,
    menu: {
      icon: BsGearFill,
      key: 'parameterization-index',
      submenu: 'serasa',
      label: 'menu.submenu.serasa.menu_parameterization',
    },
  },
  {
    path: '/serasa/integrations',
    forAll: true,
    exact: true,
    isPrivate: true,
    permission: 'serasa.import.index',
    component: PageComponents.SerasaIntegrations,
    menu: {
      icon: BsGearFill,
      key: 'integrations-index',
      submenu: 'serasa',
      label: 'menu.submenu.serasa.menu_integrations',
    },
  },

  /** Profile */
  {
    path: '/profile',
    exact: true,
    isPrivate: true,
    component: PageComponents.Profile,
  },
  /** Error */
  {
    path: '/error',
    isPrivate: false,
    component: PageComponents.NotFound,
  },
  /** Forbidden */
  {
    path: '/not-authorized',
    isPrivate: false,
    component: PageComponents.NotAuthorized,
  },
  /** Network */
  {
    path: '/network-error',
    isPrivate: false,
    component: PageComponents.NetworkError,
  },
  // {
  //   path: '*',
  //   isPrivate: false,
  //   component: PageComponents.NotFound,
  // },
  // {
  //   path: '/contracts',
  //   permission: '',
  //   isPrivate: true,
  //   exact: true,
  //   component: PageComponents.Contract,
  //   menu: {
  //     icon: FaFileAlt,
  //     key: 'contracts',
  //     parent: 'contracts',
  //     label: 'menu.contracts',
  //   },
  // },
];

export default routes;

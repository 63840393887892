import { useState } from 'react';

import api, { handleRequest } from 'services/api/api';

export const useSustainabilityRegistration = () => {
  const [isDeleting, setIsDeleting] = useState(false);

  const destroy = async (registrationId: string) =>
    handleRequest(() => api.delete(`/sustainability/registration/${registrationId}`), setIsDeleting);

  return { destroy, isDeleting };
};

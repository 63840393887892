import styled, { css } from 'styled-components';

export const Container = styled.div<{ isSelected: boolean }>`
  background: white;
  padding: 10px;
  border-radius: 2px;
  cursor: pointer;
  user-select: none;
  font-size: 14px;
  ${({ isSelected }) => css`
    background: ${isSelected ? 'orange' : 'white'};
    color: ${isSelected ? 'white' : '#565656'};
  `}
  margin-left: 10px;
  -webkit-box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.1);
`;

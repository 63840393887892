import React from 'react';

import { cn } from 'lib/utils';

import { Badge } from './badge';

type Props = {
  children: React.ReactNode;
  count: number;
  badgeClassName?: string;
};

export const NotificationBadge = ({ children, count = 0, badgeClassName }: Props) => {
  return (
    <div className="relative max-h-min">
      {children}
      {count ? (
        <Badge
          shape={count > 9 ? 'default' : 'rounded'}
          className={cn('absolute -top-1 left-[30%] max-w-min p-1', badgeClassName)}
        >
          {count}
        </Badge>
      ) : null}
    </div>
  );
};

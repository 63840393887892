import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  closestCenter,
  DndContext,
  MouseSensor,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { message } from 'antd';
import PermissionedComponent from 'components/PermissionedComponent';
import { PermissionedField } from 'components/PermissionedField';
import { ShouldRender } from 'components/ShouldRender';
import { Button } from 'components/ui/button';
import { Checkbox } from 'components/ui/checkbox';
import { DatePicker } from 'components/ui/date-picker';
import { FormGrid } from 'components/ui/form-grid';
import { FormItem } from 'components/ui/form-item';
import { GroupForm } from 'components/ui/group-form';
import { Input } from 'components/ui/input';
import { Label } from 'components/ui/label';
import { PhoneInput } from 'components/ui/phone-input';
import { Popover, PopoverContent, PopoverTrigger } from 'components/ui/popover';
import { RadioGroup, RadioGroupItem } from 'components/ui/radio-group';
import { Separator } from 'components/ui/separator';
import { SortableItem } from 'components/ui/sortable-item';
import { FORMALIZATION_GUARANTEES_SUBSCRIBER_SIGN } from 'configs/constants';
import { useAbility } from 'hooks/ability';
import { usePagination } from 'hooks/usePagination';
import { Cog, Trash } from 'lucide-react';
import moment from 'moment';
import { useDeleteDraftSubscriberMutation } from 'pages/CreditRequest/api/useDeleteDraftSubscriberMutation';
import { useGetRecipientTypeQuery } from 'pages/CreditRequest/api/useGetRecipientTypeQuery';
import { useDraftFormStateStore } from 'pages/CreditRequest/store/useDraftFormStateStore';
import { DraftSubscriberFormData } from 'types/CreditRequest/forms/DraftSubscriberFormData';
import { v4 as uuid } from 'uuid';

import { SignaturesTypeSelect } from '../signatures-type.select';
import { EMPTY_SUBSCRIBER } from './draft.form';

export const DraftSubscribersSection = () => {
  const { t } = useTranslation();
  const form = useFormContext();
  const { paginationConfig, dispatchPagination } = usePagination();
  const ability = useAbility();

  const subscribers = form.watch('subscribers');
  const id = form.watch('id');
  const isDigital = Boolean(Number(form.watch('is_digital')));
  const externalEnvelopeId = form.watch('external_envelope_id');

  const subscriberBeingRemovedId = useDraftFormStateStore(state => state.subscriberBeingRemovedId);
  const setSubscriberBeingRemovedId = useDraftFormStateStore(state => state.setSubscriberBeingRemovedId);

  const recipientTypeQuery = useGetRecipientTypeQuery({
    ...paginationConfig,
    dispatchPagination,
  });
  const deleteSubscriberMutation = useDeleteDraftSubscriberMutation();

  const isFullySigned = subscribers
    .filter((subscriber: any) => subscriber.recipient_type_id === FORMALIZATION_GUARANTEES_SUBSCRIBER_SIGN)
    .every((subscriber: any) => !!subscriber.dt_sign);

  const handleChangeSubscriberSignatureType = (value: string) => {
    const oldSubscribers = [...form.getValues('subscribers')];
    const updatedSubscribers = oldSubscribers.map((subscriber: any) => {
      if (subscriber.recipient_type_id === FORMALIZATION_GUARANTEES_SUBSCRIBER_SIGN) {
        return {
          ...subscriber,
          signature_type_id: value,
        };
      }
      return subscriber;
    });
    form.setValue('subscribers', updatedSubscribers);
  };

  const handleDeleteSubscriber = async (subscriber: any, index: number) => {
    const removeSubscriber = (index: number) => {
      const updatedSubscribers = [...subscribers];
      updatedSubscribers.splice(index, 1);
      form.setValue(
        'subscribers',
        updatedSubscribers.map((subscriber, index) => ({
          ...subscriber,
          routing_order: index,
        })),
      );
    };

    if (subscriber.id && !subscriber.id.includes('new-')) {
      setSubscriberBeingRemovedId(subscriber.id);
      await deleteSubscriberMutation.mutateAsync(subscriber.id, {
        onSuccess: () => {
          removeSubscriber(index);
        },
      });
      setSubscriberBeingRemovedId(null);
    } else {
      removeSubscriber(index);
    }
  };

  const handleAddNewSubscriber = () => {
    form.setValue('subscribers', [
      ...subscribers,
      { ...EMPTY_SUBSCRIBER, id: `new-${uuid()}`, routing_order: subscribers.length },
    ]);
  };

  const [_, setActiveId] = React.useState<string | null>(null);

  const pointerSensor = useSensor(PointerSensor, {
    activationConstraint: {
      distance: 0.01,
    },
  });
  const mouseSensor = useSensor(MouseSensor);
  const touchSensor = useSensor(TouchSensor);

  const sensors = useSensors(mouseSensor, touchSensor, pointerSensor);

  return (
    <GroupForm
      title={t('pages.credit-request.formalization-guarantees.pre-registration-docs.modalDraft.subscribers_copied')}
    >
      <DndContext
        sensors={sensors}
        autoScroll
        cancelDrop={() => {
          const hasPermission = !ability.can('credit.request.package.recipent.order', '');
          if (hasPermission) return false;
          message.error(t('noPermission'));
          return true;
        }}
        collisionDetection={closestCenter}
        onDragStart={e => {
          setActiveId(e.active.id.toString());
        }}
        onDragEnd={e => {
          const { active, over } = e;
          if (active?.id !== over?.id) {
            const overIndex = subscribers.findIndex((subscriber: DraftSubscriberFormData) => subscriber.id === over.id);
            const activeIndex = subscribers.findIndex(
              (subscriber: DraftSubscriberFormData) => subscriber.id === active.id,
            );
            const updatedSubscribers = [...subscribers];
            updatedSubscribers.splice(overIndex, 0, updatedSubscribers.splice(activeIndex, 1)[0]);
            form.setValue(
              'subscribers',
              updatedSubscribers.map((subscriber, index) => ({
                ...subscriber,
                routing_order: index,
              })),
            );
          }
        }}
      >
        <SortableContext items={subscribers} strategy={verticalListSortingStrategy}>
          {subscribers.map((subscriber: DraftSubscriberFormData, index: number) => (
            // eslint-disable-next-line react/no-array-index-key
            <SortableItem key={`${index}${subscriber.id}`} id={subscriber.id}>
              <FormGrid className="flex [&>div]:w-full gap-2">
                <FormItem
                  name={`subscribers[${index}].routing_order`}
                  className="!w-[160px] !max-w-[160px]"
                  defaultValue={subscriber.routing_order}
                  label="Ord."
                >
                  <Input disabled />
                </FormItem>
                <FormItem
                  name={`subscribers[${index}].name`}
                  label={t('pages.credit-request.formalization-guarantees.pre-registration-docs.modalDraft.addressee')}
                  defaultValue={subscriber.name}
                  rules={{ required: true }}
                >
                  <Input />
                </FormItem>
                <FormItem
                  name={`subscribers[${index}].email`}
                  label={t('pages.credit-request.formalization-guarantees.pre-registration-docs.modalDraft.email')}
                  defaultValue={subscriber.email}
                  rules={{
                    required: isDigital,
                    pattern: {
                      value: /^[A-z0-9._%+-]+@[A-z0-9.-]+\.[a-z]{2,4}$/,
                      message: 'E-mail inválido',
                    },
                  }}
                >
                  <Input
                    maxLength={80}
                    disabled={
                      subscriber.recipient_type_id === FORMALIZATION_GUARANTEES_SUBSCRIBER_SIGN
                        ? subscriber.dt_sign
                        : isFullySigned
                    }
                  />
                </FormItem>
                <ShouldRender condition={!isDigital}>
                  <FormItem
                    name={`subscribers[${index}].dt_sign`}
                    label={t('pages.credit-request.formalization-guarantees.pre-registration-docs.modalDraft.dt_sign')}
                    defaultValue={subscriber.dt_sign ? moment(subscriber.dt_sign).toString() : null}
                  >
                    <DatePicker disabled={id && isDigital && !!externalEnvelopeId} />
                  </FormItem>
                </ShouldRender>

                <ShouldRender condition={isDigital}>
                  <FormItem
                    name={`subscribers[${index}].signature_type_id`}
                    label={t(
                      'pages.credit-request.formalization-guarantees.pre-registration-docs.modalDraft.subscription type',
                    )}
                    rules={{ required: isDigital }}
                  >
                    <SignaturesTypeSelect
                      showClearButton={false}
                      className="w-full"
                      disabled={!!id && isDigital && !!externalEnvelopeId}
                      onValueChange={handleChangeSubscriberSignatureType}
                    />
                  </FormItem>
                </ShouldRender>

                <ShouldRender condition={isDigital}>
                  <FormItem
                    name={`subscribers[${index}].sms`}
                    label={t(
                      'pages.credit-request.formalization-guarantees.pre-registration-docs.modalDraft.confirm_sms',
                    )}
                    rules={{ required: isDigital }}
                    description={
                      <strong className="text-xs">
                        {t(
                          'pages.credit-request.formalization-guarantees.pre-registration-docs.modalDraft.confirm_sms_sample',
                        )}
                      </strong>
                    }
                  >
                    <PhoneInput
                      disabled={
                        subscriber.recipient_type_id === FORMALIZATION_GUARANTEES_SUBSCRIBER_SIGN
                          ? subscriber.dt_sign
                          : isFullySigned
                      }
                    />
                  </FormItem>
                </ShouldRender>
                <span className="flex gap-2 items-center">
                  <Button
                    type="button"
                    className="w-8 h-8 p-1"
                    disabled={(id && isDigital && !!externalEnvelopeId) || form.formState.disabled}
                    onClick={() => handleDeleteSubscriber(subscriber, index)}
                    isLoading={deleteSubscriberMutation.isPending && subscriberBeingRemovedId === subscriber.id}
                    permissions="credit.request.envelope.destroy"
                  >
                    <Trash size={16} />
                  </Button>

                  <Popover>
                    <PopoverTrigger tabIndex={-1}>
                      <Button
                        type="button"
                        className="w-8 h-8 p-1"
                        disabled={form.formState.disabled}
                        isLoading={
                          (deleteSubscriberMutation.isPending && subscriberBeingRemovedId === subscriber.id) ||
                          recipientTypeQuery?.isLoading
                        }
                      >
                        <Cog size={16} />
                      </Button>
                    </PopoverTrigger>

                    <PopoverContent>
                      <FormItem
                        name={`subscribers[${index}].recipient_type_id`}
                        label={t(
                          'pages.credit-request.formalization-guarantees.pre-registration-docs.modalDraft.type_addressee',
                        )}
                      >
                        <PermissionedComponent permission={['credit.request.envelope.index']}>
                          <RadioGroup
                            disabled={!!id && isDigital && !!externalEnvelopeId}
                            value={subscriber.recipient_type_id}
                          >
                            {(recipientTypeQuery?.data?.data || [])?.map(option => (
                              <div className="cursor-pointer flex gap-2 items-center" key={option.id}>
                                <RadioGroupItem value={option.id} id={`recipient_type_${option.id}`} />
                                <Label htmlFor={`recipient_type_${option.id}`}>{option.name}</Label>
                              </div>
                            ))}
                          </RadioGroup>
                        </PermissionedComponent>
                      </FormItem>
                      <Separator className="my-4" />
                      <FormItem name={`subscribers[${index}].is_resend`} className="">
                        <PermissionedField permission={['credit.request.envelope.resend']}>
                          <Checkbox label="Reenviar email" />
                        </PermissionedField>
                      </FormItem>
                    </PopoverContent>
                  </Popover>
                </span>
              </FormGrid>
              <Separator className="my-4" />
            </SortableItem>
          ))}
          {/* <DragOverlay>
            {activeId ? (
              <div id={activeId} className="bg-primary p-4 rounded-lg text-white">
                teste
              </div>
            ) : null}
          </DragOverlay> */}
        </SortableContext>
      </DndContext>

      <Button
        type="button"
        disabled={(id && isDigital && !!externalEnvelopeId) || form.formState.disabled}
        onClick={handleAddNewSubscriber}
      >
        {t('pages.credit-request.formalization-guarantees.pre-registration-docs.modalDraft.buttonAddAddressee')}
      </Button>
    </GroupForm>
  );
};

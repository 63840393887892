import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FaMinus, FaPlus } from 'react-icons/fa';

import { useTable } from '@agrodatabr/agrodataui';

import { Breadcrumb, Button } from '../../../components';
// import { Can } from '../../../hooks/ability';
import { Can } from '../../../hooks/ability';
import { useContractTypes } from './controllers/useContractTypes';
import { Form } from './Form';
import { SContainer, SHeader, STitlePage } from './styles';
import { Table } from './Table';

export const ContractTypes = () => {
  const { t } = useTranslation();
  const tableController = useTable();
  const queryParameters = new URLSearchParams(window.location.search);
  const contractTypeIdToEdit = queryParameters.get('contractTypeId');

  const {
    isFormOpen,
    toggleForm,
    isEditing,
    closeForm,
    handleTableChange,
    handleEdit,
    handleRemove,
    isFetching,
    contractBeingEditedId,
    searchFilters,
    setSearchFilters,
    setIsEditing,
  } = useContractTypes({ refresh: tableController.refresh });

  useEffect(() => {
    if (contractTypeIdToEdit) {
      toggleForm();
      setIsEditing(true);
    }
  }, [contractTypeIdToEdit]);

  return (
    <>
      <Breadcrumb items={[{ title: t('breadcrumb.edocuments') }, { title: t('breadcrumb.contracttypes') }]} />

      <SContainer>
        <SHeader>
          <STitlePage>{t('pages.edcouments.contracttypes.title')}</STitlePage>

          <Can I="contract.type.store" a="">
            <Button status="primary" icon={isFormOpen ? <FaMinus /> : <FaPlus />} onClick={toggleForm}>
              {t('pages.edocuments.contracttypes.buttonAdd')}
            </Button>
          </Can>
        </SHeader>

        <div>
          {isFormOpen && (
            <Form
              isEditing={isEditing}
              closeForm={closeForm}
              contractId={contractBeingEditedId || contractTypeIdToEdit}
              refresh={tableController.refresh}
            />
          )}

          <Table
            handleChange={handleTableChange}
            handleEdit={handleEdit}
            handleRemove={handleRemove}
            isLoading={isFetching}
            controller={tableController}
            searchFilters={searchFilters}
            setSearchFilters={setSearchFilters}
          />
        </div>
      </SContainer>
    </>
  );
};

import React from 'react';

import { SelectProps } from 'antd';
import Select from 'components/Select';

export const ErpSelect = (props: Omit<SelectProps<any>, 'options'>) => {
  return (
    <Select
      showSearch
      options={[
        {
          key: 'SAP',
          value: 'SAP',
          label: 'SAP',
        },
        {
          key: 'DATASUL',
          value: 'DATASUL',
          label: 'DATASUL',
        },
      ]}
      {...props}
    />
  );
};

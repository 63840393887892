import React from 'react';

import { Title, Container } from './styles';
import { FieldGroupProps } from './types';

export const FieldGroup = ({ title, children }: FieldGroupProps) => {
  return (
    <Container>
      <Title>{title}</Title>
      <span>{children}</span>
    </Container>
  );
};

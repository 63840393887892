import { PaginatedQueryParams, usePaginatedQuery } from 'hooks/usePaginatedQuery';
import { SelectInfiniteQueryParams, useSelectInfiniteQuery } from 'hooks/useSelectInfiniteQuery';
import PaginationData from 'types/Api/PaginationData';
import { NotaryData } from 'types/CreditRequest/NotaryData';

type NotaryParams = {
  city: string;
};

export const useGetNotariesOptionsQuery = (params: SelectInfiniteQueryParams & NotaryParams) => {
  const query = useSelectInfiniteQuery<PaginationData<NotaryData>>(
    'register/notary',
    {
      queryKey: ['register/notary', params.city || ''],
      staleTime: 1000 * 60 * 5,
      enabled: !!params.city,
      permissions: ['register.notary.index'],
    },
    params,
  );

  return query;
};

export const useGetNotariesQuery = (params?: PaginatedQueryParams & NotaryParams) => {
  return usePaginatedQuery<NotaryData>({
    queryKey: ['register/notary'],
    enabled: !!params.city,
    staleTime: 1000 * 60 * 5,
    url: `register/notary`,
    permissions: ['register.notary.index'],
    params: {
      ...params,
    },
  });
};

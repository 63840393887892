import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FaPlus, FaMinus, FaSearch } from 'react-icons/fa';

import { Col, Form, Spin, Radio, Modal } from 'antd';
import { Store } from 'antd/lib/form/interface';
import { TableProps, TablePaginationConfig } from 'antd/lib/table';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import ContactData from 'types/Contract/ContactData';

import { ResponseListData } from '../../../@types/data/ResponseData';
import DTOContact from '../../../@types/dtos/DTOContact';
import DTOErrorReponse from '../../../@types/dtos/DTOErrorReponse';
import DTOOperator from '../../../@types/dtos/monitoring/SurveyRequest/DTOOperator';
import { Breadcrumb, Button, Input, ModalStatus, FormItem, Row, IconWithTooltip } from '../../../components';
import { TableData } from '../../../compositions';
import { CONTACT_TYPE_MAIL } from '../../../configs/constants';
import { Can } from '../../../hooks/ability';
import { useAuth } from '../../../hooks/auth';
import { usePage } from '../../../hooks/page';
import monitoringApi from '../../../services/api/monitoring';
import columns from './columns';
import { SContainer, SHeader, STitlePage, SFilterContainer, SFormContainer, SFormButtons } from './styles';

const Operator: React.FC = () => {
  const { t, i18n } = useTranslation();
  const [form] = Form.useForm();

  const { updateUserData } = useAuth();
  const { alertStatus } = usePage();

  const [loadingButton, setLoadingButton] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);

  const [data, setData] = useState([]);
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [paginationConfig, setPaginationConfig] = useState<TablePaginationConfig>({
    current: 1,
    total: 1,
    pageSize: 20,
  });
  const [email_reference_form] = Form.useForm();
  const [emails, setEmails] = useState([]);
  const [visibilityForm, setVisibilityForm] = useState(false);
  const [visibilitySubforms, setVisibilitySubforms] = useState({
    email_reference: null,
  });

  async function handleTableContactChange() {
    email_reference_form
      .validateFields()
      .then(() => {
        setEmails([...emails, email_reference_form.getFieldsValue()]);
        setVisibilitySubforms(curState => ({
          ...curState,
          email_reference: false,
        }));
      })
      .catch(() => {
        setLoading(false);
        setLoadingButton(false);
        setDisabledButton(false);
      });
  }

  const handleRemoveContactChange = async (record: any, index: number) => {
    ModalStatus({
      type: 'delete',
      title: t('pages.monitoring.farm.delete.title'),
      subTitle: t('pages.monitoring.farm.delete.subtitle'),
      cancelText: t('pages.monitoring.farm.delete.cancel'),
      okText: t('pages.monitoring.farm.delete.confirm'),
      onOk: () => {
        setLoading(true);
        if (record.id !== undefined) {
          monitoringApi.farm
            .destroyContact(record.id, {
              params: {},
            })
            .then(response => {
              handleSuccess(response.data.message);
              emails.splice(index, 1);
              setEmails([...emails]);
              setLoading(false);
              // eslint-disable-next-line @typescript-eslint/no-use-before-define
              handleTableChange({ current: 1 }, { active: [1] }, {}, null);
            })
            .catch((err: DTOErrorReponse) => alertStatus(err, 'error'));
        } else {
          emails.splice(index, 1);
          setEmails([...emails]);
          setLoading(false);
        }
      },
    });
  };

  const updateData = (row: Store) => {
    let dataUpdated: Array<Store> = data;
    if (data.length > 0) {
      const rowIndex = data.findIndex((item: Store) => row.id === item.id);
      // Se encontrar o item remove e adiciona ele atualizado na mesma posição
      if (rowIndex > -1) {
        dataUpdated.splice(rowIndex, 1, row);
      } else {
        if (dataUpdated.length === paginationConfig.pageSize) {
          // Se não encontrar, remove o último (por conta da paginação) e
          // adiciona o item na primeira posição
          dataUpdated.splice(-1, 1);
        }
        dataUpdated = [row, ...dataUpdated];
      }
    } else {
      dataUpdated.push(row);
    }

    setData(dataUpdated);
  };

  const handleSuccess = (messageSuccess: string) =>
    ModalStatus({
      type: 'success',
      title: t('modal.success'),
      subTitle: messageSuccess,
    });

  const loadTableData = useCallback(
    (values: any) => {
      setLoading(true);
      setLoadingButton(true);
      setDisabledButton(true);
      const dataSend = {
        params: {
          per_page: paginationConfig.pageSize,
          ...values,
        },
      };

      monitoringApi.operator
        .get(dataSend)
        .then((response: ResponseListData<DTOOperator>) => {
          const result = response.data.data;
          setData(result.data);
          setPaginationConfig(paginationConfigState => ({
            ...paginationConfigState,
            current: result.current_page,
            total: result.total,
          }));
        })
        .catch((err: DTOErrorReponse) => alertStatus(err, 'error'))
        .finally(() => {
          setLoading(false);
          setLoadingButton(false);
          setDisabledButton(false);
        });
    },
    // eslint-disable-next-line
    [i18n.language, paginationConfig.pageSize],
  );

  const handleDisplayForm = () => {
    setVisibilityForm(!visibilityForm);
    setEditing(false);
    setDisabledButton(false);
    setLoadingButton(false);
    form.resetFields();
    setEmails([]);
  };

  const handleHideForm = () => {
    setVisibilityForm(false);
  };

  const handleRemoveChange = async (record: any) => {
    ModalStatus({
      type: 'delete',
      title: t('pages.monitoring.operator.delete.title'),
      subTitle: t('pages.monitoring.operator.delete.subtitle'),
      cancelText: t('pages.monitoring.operator.delete.cancel'),
      okText: t('pages.monitoring.operator.delete.confirm'),
      onOk: () => {
        monitoringApi.operator
          .destroy(record.id, {
            params: {},
          })
          .then(response => {
            handleSuccess(response.data.message);
            handleTableChange({ current: 1 }, { active: [1] }, {}, null);
          })
          .catch((err: DTOErrorReponse) => alertStatus(err, 'error'));
      },
    });
  };

  const handleRegister = () => {
    setLoading(true);
    setLoadingButton(true);
    setDisabledButton(true);
    form
      .validateFields()
      .then(() => {
        const formValues = form.getFieldsValue();
        if (emails.length > 0) {
          formValues.emails = [];
          emails.forEach((record: DTOContact) => {
            formValues.emails.push({
              type: CONTACT_TYPE_MAIL,
              value: record.value,
              description: record.description,
            });
          });
        }

        monitoringApi.operator
          .store({
            ...formValues,
          })
          .then((response: ResponseListData<DTOOperator>) => {
            const { data: harvest } = response.data;
            handleSuccess(response.data.message);
            updateData(harvest);
            handleHideForm();
            setLoading(false);
            setLoadingButton(false);
            setDisabledButton(false);
            form.resetFields();
            updateUserData();
          })
          .catch((err: DTOErrorReponse) => {
            alertStatus(err, 'error');
            setLoading(false);
            setLoadingButton(false);
            setDisabledButton(false);
          });
      })
      .catch((err: DTOErrorReponse) => {
        alertStatus(err, 'error');
        setLoading(false);
        setLoadingButton(false);
        setDisabledButton(false);
      });
  };

  const handleEdit = () => {
    setLoading(true);
    setLoadingButton(true);
    setDisabledButton(true);
    form
      .validateFields()
      .then(() => {
        const formValues = form.getFieldsValue();

        if (emails.length > 0) {
          emails.forEach((record: DTOContact, key: number) => {
            if (!formValues.emails) {
              formValues.emails = [];
            }

            const values = {
              type: CONTACT_TYPE_MAIL,
              value: record.value,
              description: record.description,
            };

            if (formValues.emails[key]) {
              formValues.emails[key] = values;
            } else {
              formValues.emails.push(values);
            }
          });
        }

        monitoringApi.operator
          .update(formValues.id, {
            ...formValues,
          })
          .then((response: ResponseListData<DTOOperator>) => {
            const { data: harvest } = response.data;
            handleSuccess(response.data.message);
            updateData(harvest);
            handleHideForm();
            setLoading(false);
            setLoadingButton(false);
            setDisabledButton(false);
            form.resetFields();
            updateUserData();
          })
          .catch((err: DTOErrorReponse) => {
            alertStatus(err, 'error');
            setLoading(false);
            setLoadingButton(false);
            setDisabledButton(false);
          });
      })
      .catch((err: DTOErrorReponse) => {
        alertStatus(err, 'error');
        setLoading(false);
        setLoadingButton(false);
        setDisabledButton(false);
      });
  };

  // Table DATA Config: BEGIN
  const getActions = () => ({
    render: (active: number, record: DTOOperator) => (
      <>
        <Can I="monitoring.operator.destroy" a="">
          <IconWithTooltip
            action="destroy"
            title={t('pages.monitoring.operator.table.icons.remove')}
            onClick={() => handleRemoveChange(record)}
          />
        </Can>
        <Can I="monitoring.operator.update" a="">
          <IconWithTooltip
            action="edit"
            title={t('pages.monitoring.operator.table.icons.edit')}
            onClick={() => {
              form.setFieldsValue(record);
              form.scrollToField('name', {
                scrollMode: 'always',
                block: 'start',
                behavior: actions =>
                  actions.forEach(({ el, top }) => {
                    el.scrollTop = top - 280;
                  }),
              });

              setEmails(record.emails);
              setVisibilityForm(true);
              setEditing(true);
              setLoading(false);
              setLoadingButton(false);
              setDisabledButton(false);
            }}
          />
        </Can>
      </>
    ),
  });

  const [searchForm] = Form.useForm();
  const tableConfig = {
    search: '',
    filtered: {},
    sorter: {},
    getActions,
  };

  const [tableDataConfig, setTableDataConfig] = useState(tableConfig);

  const handleFastSearch = () => {
    const value = searchForm.getFieldsValue();
    setTableDataConfig(tableDataConfigState => ({
      ...tableDataConfigState,
      name: value.search as string,
    }));
    loadTableData({
      name: value.search as string,
    });
  };

  const tableDataClearAllFilters = () => {
    searchForm.resetFields();
    setTableDataConfig(tableDataConfigState => ({
      ...tableDataConfigState,
      filtered: {},
      search: '',
      sorter: {},
    }));
    loadTableData({ page: 1 });
  };

  const handleTableChange: TableProps<any>['onChange'] = useCallback(
    (
      pagination: TablePaginationConfig,
      filters: Record<string, FilterValue | null>,
      sorter: SorterResult<any> | SorterResult<any>[],
    ) => {
      setLoading(true);
      setTableDataConfig(tableDataConfigState => ({
        ...tableDataConfigState,
        filtered: filters,
        sorter,
      }));

      let direction = '';
      const search: any = {};
      let sort = '';

      if (sorter) {
        const { field, order } = sorter as SorterResult<any>;

        if (order === 'ascend') direction = 'asc';
        if (order === 'descend') direction = 'desc';
        sort = field?.toString() ?? '';
      }

      if (filters) {
        const dataFilter: any = filters;

        if (dataFilter) {
          Object.keys(dataFilter).forEach((key: string) => {
            if (dataFilter[key] !== null && dataFilter[key] !== undefined && dataFilter[key][0]) {
              search[key] = dataFilter[key][0].toString();
            } else if (dataFilter[key] !== null && dataFilter[key] !== undefined) {
              search[key] = dataFilter[key].toString();
            } else {
              search[key] = '';
            }
          });
        }
      }

      setPaginationConfig(paginationConfigState => ({
        ...paginationConfigState,
        pageSize: pagination.pageSize,
        current: pagination.current,
      }));

      loadTableData({
        page: pagination.current,
        per_page: pagination.pageSize,
        sort,
        direction,
        ...search,
      });
    },
    [loadTableData],
  );
  // Table DATA Config: END

  useEffect(() => {
    const filters: any = {};

    handleTableChange({ current: 1, pageSize: paginationConfig.pageSize }, filters, {}, null);
  }, [handleTableChange, paginationConfig.pageSize]);

  return (
    <>
      <Breadcrumb
        items={[{ title: t('breadcrumb.monitoring') }, { title: t('breadcrumb.operator'), to: '/monitoring/operator' }]}
      />

      <SContainer>
        <SHeader>
          <STitlePage>{t('pages.monitoring.operator.title_page')}</STitlePage>

          <Can I="monitoring.operator.store" a="">
            <Button status="primary" icon={visibilityForm ? <FaMinus /> : <FaPlus />} onClick={handleDisplayForm}>
              {t('pages.monitoring.operator.buttonAdd')}
            </Button>
          </Can>
        </SHeader>

        <SFormContainer visible={visibilityForm}>
          <Spin spinning={loading}>
            <h2>{t('pages.monitoring.operator.registerTitle')}</h2>
            <Form form={form}>
              <FormItem name="id" style={{ display: 'none' }}>
                <Input />
              </FormItem>
              <Row gutter={[26, 26]}>
                <Col xs={24} sm={12} md={16} lg={16} xl={16} xxl={16}>
                  <FormItem label={t('pages.monitoring.operator.form.name')} name="name" rules={[{ required: true }]}>
                    <Input maxLength={80} type="text" />
                  </FormItem>
                </Col>
                <Col xs={24} sm={12} md={8} lg={8} xl={8} xxl={8}>
                  <FormItem
                    label={t('pages.monitoring.operator.form.has_approval')}
                    name="has_approval"
                    rules={[{ required: true }]}
                  >
                    <Radio.Group buttonStyle="solid">
                      <Radio.Button value>{t('Yes')}</Radio.Button>
                      <Radio.Button value={false}>{t('No')}</Radio.Button>
                    </Radio.Group>
                  </FormItem>
                </Col>
              </Row>
              <Row>
                <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                  <Button
                    block
                    onClick={() => {
                      setVisibilitySubforms(curState => ({
                        ...curState,
                        email_reference: true,
                      }));
                    }}
                  >
                    {t('pages.monitoring.farm.buttonContact')}
                  </Button>
                </Col>
              </Row>
              {/* Tabela Contatos */}
              <Row gutter={[26, 26]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                  <TableData
                    rowKey="id"
                    columns={columns.emails}
                    dataSource={emails}
                    tableConfig={{
                      search: '',
                      filtered: {},
                      sorter: {},
                      nopagination: true,
                      getActions: () => ({
                        render: (_active: number, record: ContactData, index: number) => (
                          <>
                            <IconWithTooltip
                              action="destroy"
                              title={t('pages.monitoring.farm.table.icons.remove')}
                              onClick={() => {
                                handleRemoveContactChange(record, index);
                              }}
                            />
                          </>
                        ),
                      }),
                    }}
                  />
                </Col>
              </Row>
              <SFormButtons>
                <Button status="secondary" htmlType="reset" onClick={handleHideForm} disabled={disabledButton}>
                  {t('pages.monitoring.operator.buttonCancel')}
                </Button>
                {editing && (
                  <Button
                    status="primary"
                    htmlType="submit"
                    onClick={handleEdit}
                    loading={loadingButton}
                    disabled={disabledButton}
                    permission="monitoring.operator.update"
                  >
                    {t('pages.monitoring.operator.buttonEdit')}
                  </Button>
                )}
                {!editing && (
                  <Button
                    status="primary"
                    htmlType="submit"
                    onClick={handleRegister}
                    loading={loadingButton}
                    disabled={disabledButton}
                    permission="monitoring.operator.store"
                  >
                    {t('pages.monitoring.operator.buttonRegister')}
                  </Button>
                )}
              </SFormButtons>
            </Form>
          </Spin>
          {/* Modal Contato  */}
          <Modal
            title={t('pages.monitoring.farm.form.contact')}
            visible={visibilitySubforms.email_reference}
            onCancel={() =>
              setVisibilitySubforms(curState => ({
                ...curState,
                email_reference: false,
              }))
            }
            afterClose={() => email_reference_form.resetFields()}
            width="80%"
            footer={
              <>
                <SFormButtons>
                  <Button
                    status="secondary"
                    onClick={() => {
                      setVisibilitySubforms(curState => ({
                        ...curState,
                        email_reference: false,
                      }));
                      email_reference_form.resetFields();
                    }}
                  >
                    {t('pages.monitoring.farm.form.buttons.cancel')}
                  </Button>
                  <Button status="primary" onClick={() => handleTableContactChange()}>
                    {t('pages.monitoring.farm.form.buttons.save')}
                  </Button>
                </SFormButtons>
              </>
            }
            className="modal-with-custom-footer"
          >
            <Spin spinning={loading}>
              <Form name="subform_email_reference" form={email_reference_form}>
                <Row>
                  <Col xs={24} sm={18} md={12} lg={8} xl={8} xxl={8}>
                    <FormItem
                      label={t('pages.monitoring.operator.emails.description')}
                      name="description"
                      rules={[{ required: true }]}
                    >
                      <Input />
                    </FormItem>
                  </Col>
                  <Col xs={24} sm={18} md={12} lg={8} xl={8} xxl={8}>
                    <FormItem
                      label={t('pages.monitoring.operator.emails.email')}
                      name="value"
                      rules={[{ type: 'email', required: true }]}
                    >
                      <Input />
                    </FormItem>
                  </Col>
                </Row>
              </Form>
            </Spin>
          </Modal>
        </SFormContainer>

        <SFilterContainer>
          <Form
            form={searchForm}
            name="filter-harvest"
            className="form-secondary form-filters grid-filters"
            onFinish={handleFastSearch}
          >
            <div className="filter-search">
              <FormItem name="search" label={t('pages.monitoring.operator.search')}>
                <Input />
              </FormItem>
            </div>
            <div className="filter-button">
              <FormItem label="" className="form-item-without-label">
                <Button status="primary" text="uppercase" htmlType="submit">
                  <FaSearch /> {t('form.actions.search')}
                </Button>
              </FormItem>
            </div>
            <div className="filter-clear">
              <Button status="secondary" size="middle" onClick={tableDataClearAllFilters}>
                {t('pages.monitoring.operator.buttonClearFilter')}
              </Button>
            </div>
          </Form>
        </SFilterContainer>

        <TableData
          rowKey="id"
          columns={columns.operator.map((column: any) => {
            let columnRender: any = {
              ...column,
            };

            if (column.dataIndex === 'has_approval') {
              columnRender = {
                ...columnRender,
                render: (value: any) => (value === 1 || value === true ? t('Yes') : t('No')),
              };
            }

            return columnRender;
          })}
          dataSource={data}
          loading={loading}
          onChange={handleTableChange}
          pagination={paginationConfig}
          tableConfig={tableDataConfig}
        />
      </SContainer>
    </>
  );
};

export default Operator;

import { useMutation } from '@tanstack/react-query';
import { useAlertDialog } from 'components/ui/alert-dialog';
import api from 'services/api/api';

export const useStoreDraftMutation = () => {
  const { alert } = useAlertDialog();

  return useMutation({
    mutationKey: ['storeDraft'],
    mutationFn: async (draftData: FormData) => {
      return api.post(`envelope/store`, draftData);
    },
    onError: err => {
      alert({ title: err.message, type: 'error' });
    },
  });
};

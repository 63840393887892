import { useState } from 'react';

import { handleRequest } from '../../services/api/api';
import dashboardApi from '../../services/api/dashboard';

export const useDashboardWarrantyPrefin = () => {
  const [isFetchingCharts, setIsFetchingCharts] = useState(false);
  const [isFetchingCards, setIsFetchingCards] = useState(false);
  const [isFetchingTimelineRegister, setIsFetchingTimelineRegister] = useState(false);
  const [isFetchingTimelineSignature, setIsFetchingTimelineSignature] = useState(false);

  const getWarrantyPrefinCards = async (params = {}) => {
    const { summary } = await handleRequest(() => dashboardApi.warrantyPrefin.showCards(params), setIsFetchingCards);
    return { summary };
  };

  const getWarrantyPrefinCharts = async (params = {}) => {
    setIsFetchingCharts(true);
    const dataChartCreditWarrantyOperation = await handleRequest(() =>
      dashboardApi.warrantyPrefin.showChartCreditsOperation(params),
    );
    const dataChartRequestInProgressWarranty = await handleRequest(() =>
      dashboardApi.warrantyPrefin.showChartRequestInProgress(params),
    );
    const dataCardChartWarranty = await handleRequest(() => dashboardApi.warrantyPrefin.showCardsChart(params));
    setIsFetchingCharts(false);
    return {
      dataChartCreditWarrantyOperation,
      dataChartRequestInProgressWarranty,
      dataCardChartWarranty,
    };
  };

  const getWarrantyPrefinTimelineRegister = async (params?: { params: unknown }, filter = 'all') => {
    const response = await handleRequest(
      () => dashboardApi.warrantyPrefin.showTimelineRegister({ ...params, parent: filter }),
      setIsFetchingTimelineRegister,
    );
    return response || { data: [] as any[], total: 0, last_page: 0 };
  };

  const getWarrantyPrefinTimelineSignature = async (params?: { params: unknown }, filter = 'all') => {
    const response = await handleRequest(
      () => dashboardApi.warrantyPrefin.showTimelineSignature({ ...params, parent: filter }),
      setIsFetchingTimelineSignature,
    );
    return response || { data: [] as any[], total: 0, last_page: 0 };
  };

  const getTimelineReport = async (params: any) => {
    const response = await handleRequest(() => dashboardApi.reports.listReportTimelineWarranty(params));
    return response;
  };

  const generateExportTimeline = (values: any) => {
    return dashboardApi.reports.generateExportTimelineWarranty({ ...values });
  };

  return {
    isFetchingCharts,
    isFetchingCards,
    isFetchingTimelineRegister,
    isFetchingTimelineSignature,
    getWarrantyPrefinCards,
    getWarrantyPrefinCharts,
    getWarrantyPrefinTimelineRegister,
    getWarrantyPrefinTimelineSignature,
    getTimelineReport,
    generateExportTimeline,
  };
};

import React, { useEffect } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import { useSubscriber } from 'hooks/fetch/useSubscriber';
import { useContractContext } from 'pages/Contracts/context/ContractContext';
import { OptionsType } from 'rc-select/lib/interface';
import { ContractSubscriberData } from 'types/Contract/ContractSubscriberData';

import { Subscriber } from '../../../../../components/Subscriber';

interface Props {
  subscribers: ContractSubscriberData[];
  removeSubscriber: (email: string) => void;
  subscriberParts: any[];
  updateSubscribers: (newSubscribers: ContractSubscriberData[]) => void;
  isIcpBrasil?: boolean;
  isDigital?: boolean;
  fillSubscriber: (option: ContractSubscriberData, subscriberId: string) => void;
  updateRoutingOrder: (suscriberId: string, routingOrder: number) => void;
}

export const SignatureSubscribers = ({
  subscribers,
  removeSubscriber,
  subscriberParts,
  updateSubscribers,
  isIcpBrasil,
  fillSubscriber,
  isDigital,
  updateRoutingOrder,
}: Props) => {
  const { selectedContract, hasSelectedContractPastStatus } = useContractContext();
  const { listOptionsNoPagination: getSubscribersOptions } = useSubscriber();

  const [subscriberOptions, setSubscriberOptions] = React.useState<OptionsType>([]);

  const changeSubscriber = (option: ContractSubscriberData, subscriberId: string) => {
    fillSubscriber(option, subscriberId);
  };

  const onDragEnd = ({ destination, source }: any) => {
    if (!destination || (source.index === destination.index && source.droppableId === destination.droppableId)) return;

    // const aaa = digitalForm.getFieldsValue();
    // debugger;

    // const subscriber = subscribers[source.index];
    const newSubscribers = [...subscribers];
    newSubscribers.splice(source.index, 1);

    const subscribersWithNewOrders = newSubscribers.map((subscriber, index) => {
      if (
        (index < destination.index && index >= source.index) ||
        (index > destination.index - 1 && index < source.index)
      ) {
        if (source.index < destination.index) {
          return { ...subscriber, routing_order: subscriber.routing_order - 1 };
        }
        if (source.index > destination.index) return { ...subscriber, routing_order: subscriber.routing_order + 1 };
      }

      return subscriber;
    });

    subscribersWithNewOrders.splice(destination, 0, {
      ...subscribers[source.index],
      routing_order: subscribers[destination.index].routing_order,
    });

    subscribersWithNewOrders.sort((a, b) => a.routing_order - b.routing_order);

    updateSubscribers(subscribersWithNewOrders);
  };

  const updateSubscriber = (subscriber: ContractSubscriberData) =>
    updateSubscribers(
      subscribers.map(otherSubscriber => (otherSubscriber.id === subscriber.id ? subscriber : otherSubscriber)),
    );

  useEffect(() => {
    const controller = new AbortController();
    getSubscribersOptions({}, controller.signal).then(response => {
      setSubscriberOptions(response);
    });

    return () => {
      controller.abort();
    };
  }, []);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="teste">
        {provided => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}
          >
            {subscribers.map((subscriber: any, index) => {
              return (
                <Subscriber
                  index={index}
                  updateRoutingOrder={updateRoutingOrder}
                  key={subscriber.id}
                  subscriber={subscriber}
                  subscriberParts={subscriberParts}
                  destroy={() => {
                    // if (subscriber.created_at) removeSubscriberFromApi(subscriber.id);
                    removeSubscriber(subscriber.id);
                  }}
                  isIcpBrasil={isIcpBrasil}
                  canRemove={
                    !subscriber.signed_at &&
                    ((!selectedContract.is_digital && !selectedContract.signed_draft) || selectedContract.is_digital) &&
                    !hasSelectedContractPastStatus('sentSignature')
                  }
                  selectSubscriber={changeSubscriber}
                  isDigital={isDigital}
                  updateSubscriber={updateSubscriber}
                  subscriberOptions={subscriberOptions}
                />
              );
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

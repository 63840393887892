export default {
  parameterization: [
    {
      title: 'pages.admin.credit-request-parameterization.table.headers.name',
      dataIndex: 'name',
      key: 'name',
      sorter: false,
      filtered: false,
    },
    {
      title: 'pages.admin.credit-request-parameterization.table.headers.tag',
      dataIndex: 'tag',
      key: 'tag',
      sorter: false,
      filtered: false,
    },
    {
      title: 'pages.admin.credit-request-parameterization.table.headers.value',
      dataIndex: 'value',
      key: 'value',
      sorter: false,
      filtered: false,
    },
    {
      title: 'pages.admin.credit-request-parameterization.table.headers.action',
      key: 'action',
      dataIndex: 'action',
      align: 'center',
      width: '150px',
      fixed: 'right',
    },
  ],
};

import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { message } from 'antd';
import { TablePaginationConfig } from 'antd/lib/table';
import admin from 'services/api/admin';
import api, { handleRequest } from 'services/api/api';

import { ExpositionFileTypeData } from '../../../../@types/data/ExpositionFileTypeData';
import PaginationData from '../../../../@types/data/PaginationData';
import DTOErrorReponse from '../../../../@types/dtos/DTOErrorReponse';
import { useSerasaIntegrations } from '../../../../hooks/fetch/useSerasaIntegrations';
import { usePage } from '../../../../hooks/page';
import documentApi from '../../../../services/api/document';
import { downloadHandler } from '../../../../services/download';

export const useIntegrations = () => {
  const { t } = useTranslation();
  const { alertStatus } = usePage();
  const { getSerasaIntegrations, isFetching } = useSerasaIntegrations();

  const [isUploading, setIsUploading] = useState(false);
  const [isFetchingFiles, setIsFetchingFiles] = useState(false);
  const [isFetchingFileTypes, setIsFetchingFileTypes] = useState(false);

  const getFileUrl = async (id: any) => {
    message.loading(t('components.filePreviewer.loading'), 2);
    const response = await documentApi.download(id, null, 'json');
    return response.data;
  };

  const downloadDocument = async (id: any) => {
    await documentApi
      .download(id)
      .then((response: any) => {
        downloadHandler(response);
      })
      .catch((err: DTOErrorReponse) => alertStatus(err, 'error'));
    return false;
  };

  const handleTableChange = async (pagination: TablePaginationConfig, filters: object, sorter: any) => {
    const { data, total } = await getSerasaIntegrations({
      params: {
        page: pagination.current,
        per_page: pagination.pageSize,
        sort: sorter.sort,
        direction: sorter.direction,
        ...filters,
      },
    });
    return { data, total };
  };

  const uploadExposureFile = async (route: string, data: any, params = {}) => {
    return handleRequest(() => api.post(route, data, params), setIsUploading);
  };

  const getFileTypes = async (params = {}): Promise<PaginationData<ExpositionFileTypeData>> => {
    return handleRequest(() => api.get('/admin/integration/etl', params), setIsFetchingFileTypes);
  };

  const getFileTypesOptions = async (params = {}) => {
    const response = await getFileTypes(params);
    if (!response) return [];
    return response.data
      .filter(fileType => fileType.route)
      .map((item: any) => ({ key: item.id, value: item.id, label: item.name, route: item.route }));
  };

  const getFileList = async (params = {}) => {
    return handleRequest(() => admin.integrations.getFileList(params), setIsFetchingFiles);
  };

  return {
    handleTableChange,
    isFetching,
    downloadDocument,
    getFileUrl,
    uploadExposureFile,
    isUploading,
    getFileTypes,
    isFetchingFileTypes,
    getFileList,
    isFetchingFiles,
    getFileTypesOptions,
  };
};

export const columns = [
  {
    title: 'pages.edocuments.contracts.importations.table.headers.user',
    dataIndex: 'user',
    key: 'user',
    sorter: false,
    filtered: false,
  },
  {
    title: 'pages.edocuments.contracts.importations.table.headers.type',
    dataIndex: 'type',
    key: 'type',
    sorter: false,
    filtered: false,
  },
  {
    title: 'pages.edocuments.contracts.importations.table.headers.errors',
    dataIndex: 'errors',
    key: 'errors',
    sorter: false,
    filtered: false,
  },
  {
    title: 'pages.edocuments.contracts.importations.table.headers.imported',
    dataIndex: 'imported',
    key: 'imported',
    sorter: false,
    filtered: false,
  },
  {
    title: 'pages.edocuments.contracts.importations.table.headers.status',
    dataIndex: 'status',
    key: 'status',
    sorter: false,
    filtered: false,
  },
  {
    title: 'pages.edocuments.contracts.importations.table.headers.created_at',
    dataIndex: 'created_at',
    key: 'created_at',
    sorter: true,
    filtered: false,
  },
  {
    title: 'pages.credit-request.modal-reports.table.headers.actions',
    dataIndex: 'action',
    key: 'action',
    align: 'center',
    fixed: 'right',
    width: '80px',
  },
];

export default columns;

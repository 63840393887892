import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdNotifications, MdNotificationsNone } from 'react-icons/md';
import InfiniteScroll from 'react-infinite-scroll-component';

import { Badge, Collapse, Drawer, Empty } from 'antd';
import { useNotifications } from 'hooks/notifications';
import moment from 'moment';
import { useTheme } from 'styled-components';

import DTONotification from '../../../../@types/dtos/user/DTONotification';
import appConfigs from '../../../../configs/app';
import { NotificationTag } from './NotificationTag';
import { LinksNotificationContainer, LinkNotification, NotificationsContainer, Notification, Subtitle } from './styles';

interface Props {
  toggleVisibility: () => void;
}

export const NotificationsDrawer = ({ toggleVisibility }: Props) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  const {
    unreadNotifications: notifications,
    handleMenuClick,
    handleReadAll,
    updateNotifications,
    notificationCount,
    drawerVisible: isVisible,
  } = useNotifications();

  const [page, setPage] = useState(1);

  return (
    <Drawer
      className="notifications-drawer"
      title={t('pages.notifications.title')}
      closable
      onClose={() => toggleVisibility()}
      width="576px"
      visible={isVisible}
    >
      <LinksNotificationContainer>
        {notifications.length > 0 && (
          <>
            <LinkNotification onClick={() => handleReadAll()}>
              <MdNotificationsNone /> {t('pages.notifications.readAll')}
            </LinkNotification>
          </>
        )}
        <LinkNotification onClick={() => handleMenuClick('view-all')}>
          <MdNotifications /> {t('pages.notifications.viewAll')}
        </LinkNotification>
      </LinksNotificationContainer>

      {notifications.length > 0 ? (
        <>
          <Subtitle>{`${t('pages.notifications.title-not-read')}: ${notificationCount}`}</Subtitle>

          <div id="scrollableDiv" style={{ height: 'calc(100% - 106px)', overflowY: 'scroll' }}>
            <NotificationsContainer>
              <InfiniteScroll
                scrollableTarget="scrollableDiv"
                dataLength={notifications.length}
                next={() => {
                  const newPage = page + 1;
                  setPage(newPage);
                  updateNotifications(newPage, true);
                }}
                style={{ paddingTop: '10px', height: '100vh' }}
                hasMore={notifications.length < notificationCount}
                loader={<h4>{t('pages.notifications.loading')}</h4>}
                endMessage={
                  <p style={{ textAlign: 'center' }}>
                    <b>{t('pages.notifications.end')}</b>
                  </p>
                }
              >
                {(() => {
                  const sections = [] as { number: string; data: DTONotification[] }[];
                  notifications.forEach(n => {
                    const number = n.data.groupby;
                    let sectionIndex = sections.findIndex(s => s.number === number);

                    if (sectionIndex < 0) {
                      sections.push({ number, data: [] as DTONotification[] });
                      sectionIndex = sections.length - 1;
                    }

                    sections[sectionIndex].data.push(n);
                  });

                  return (
                    <Collapse>
                      {sections.map(section => (
                        <Collapse.Panel
                          header={
                            <strong
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '100%',
                                paddingRight: '20px',
                                gap: '10px',
                              }}
                            >
                              <span
                                style={{
                                  whiteSpace: 'nowrap',
                                  textOverflow: 'ellipsis',
                                  overflow: 'hidden',
                                }}
                              >
                                <NotificationTag type={section.data[0].data.tag} />
                                {`${section.number ?? t('notProvided')} `}
                                <span
                                  title={section.data[0].data.requester}
                                  style={{ color: theme.colors.greys.grey07 }}
                                >
                                  {section.data[0].data.requester ?? t('notProvided')}
                                </span>
                              </span>
                              <Badge
                                count={section.data.length}
                                style={{ background: '#fffbe6', color: '#d48806', borderColor: '#ffe58f' }}
                              />
                            </strong>
                          }
                          key={`${section.data[0].data.requester} - ${section.number}`}
                        >
                          {section.data.map((notification: DTONotification) => (
                            <Notification
                              key={notification.id}
                              onClick={() => handleMenuClick(notification.id)}
                              className={!notification.read_at ? 'not-read' : ''}
                            >
                              <strong style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <span>
                                  {typeof notification.data.title === 'string'
                                    ? notification.data.title
                                    : notification.data.title[i18n.language]}
                                </span>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                  <NotificationTag type={notification.data.tag} />
                                </div>
                              </strong>
                              <span>
                                {typeof notification.data.body === 'string'
                                  ? notification.data.body
                                  : notification.data.body[i18n.language]}
                              </span>
                              <div>
                                <strong>{notification.data.requester}</strong>
                                <span>{moment(notification.created_at).format(appConfigs.formatDateFullTime)}</span>
                              </div>
                            </Notification>
                          ))}
                        </Collapse.Panel>
                      ))}
                    </Collapse>
                  );
                })()}
              </InfiniteScroll>
            </NotificationsContainer>
          </div>
        </>
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('pages.notifications.empty')} />
      )}
    </Drawer>
  );
};

import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormItem, Select } from 'components';
import { LEGAL_PERSON_TYPE, PHYSICAL_PERSON_TYPE } from 'configs/constants';
import { useCreditRequestContext } from 'context/CreditRequestContext';
import { PersonType } from 'pages/CreditRequest/components/templates/DetailView/types';
import { useCreditRequestStore } from 'pages/CreditRequest/store/useCreditRequestStore';

export const PersonTypeSelectInput = ({ isVisible }: { isVisible?: boolean }) => {
  const { t } = useTranslation();
  const { creditForm } = useCreditRequestContext();

  const setSelectedPersonType = useCreditRequestStore(state => state.setSelectedPersonType);
  const setPeople = useCreditRequestStore(state => state.setPeople);
  const setIsBlocking = useCreditRequestStore(state => state.setIsBlocking);
  const contractFieldsValue = useCreditRequestStore(state => state.contractFieldsValue);
  const isPrepay = useCreditRequestStore(state => state.isPrepay);
  const pageStatus = useCreditRequestStore(state => state.pageStatus);

  return (
    <FormItem
      label={t('pages.credit-request.form.requester_person_type')}
      name="requester_person_type"
      rules={[{ required: true }]}
      hidden={!isVisible}
    >
      <Select
        options={[
          {
            key: 'physical',
            value: PHYSICAL_PERSON_TYPE,
            label: t('pages.credit-request.form.requester_person_type_physical'),
          },
          {
            key: 'legal',
            value: LEGAL_PERSON_TYPE,
            label: t('pages.credit-request.form.requester_person_type_legal'),
          },
        ]}
        disabled={
          pageStatus.viewing || (contractFieldsValue && contractFieldsValue.requester_person_type !== undefined)
        }
        onChange={async (value: any) => {
          if (value) {
            const personType: PersonType = value.toString();
            setSelectedPersonType(personType);
            setPeople([]);
            setIsBlocking(true);

            const fieldsOverwrideValue: any = {
              requester_person_id: undefined,
              'requester_person.cpf': undefined,
              'requester_person.cnpj': undefined,
              requester_person_name: undefined,
              'requester_person.json_data.code_erp': undefined,
              'json_data.warranty_pledge_areas': [],
            };

            if (isPrepay) {
              creditForm.setFieldsValue({
                ...fieldsOverwrideValue,
                'json_data.opting_inss': 0,
              });
            } else {
              creditForm.setFieldsValue(fieldsOverwrideValue);
            }
          }
        }}
      />
    </FormItem>
  );
};

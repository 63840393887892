import React from 'react';
import { useTranslation } from 'react-i18next';

import PermissionedComponent from 'components/PermissionedComponent';
import { GroupForm } from 'components/ui/group-form';

import { DraftList } from './draft.list';
import { DraftModal } from './draft.modal';

export const Drafts = () => {
  const { t } = useTranslation();

  return (
    <GroupForm title={t('pages.credit-request.form.titles.draft')}>
      <PermissionedComponent permission={['credit.request.envelope.index']}>
        <div className="flex flex-col gap-2 overflow-y-hidden h-full">
          <DraftList />
          <DraftModal />
        </div>
      </PermissionedComponent>
    </GroupForm>
  );
};

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaMinus, FaPlus } from 'react-icons/fa';

import { useTable } from '@agrodatabr/agrodataui';

import { Breadcrumb, Button } from '../../../components';
import { Can } from '../../../hooks/ability';
import { SContainer, SHeader, STitlePage } from '../../../styles';
import { Form } from './Form';
import { useRule } from './hooks/useRule';
import { Table } from './Table';

const TeamStatus: React.FC = () => {
  const { t } = useTranslation();
  const tableController = useTable();
  const {
    handleTableChange,
    handleEdit,
    handleRemove,
    handleSave,
    isFetching,
    isFetchingForm,
    toggleForm,
    isFormOpen,
    setIsFormOpen,
    form,
    handleStatus,
    handlePositions,
    searchFilters,
    setSearchFilters,
  } = useRule({
    refresh: tableController.refresh,
  });
  const [dataForm, setDataForm] = useState<object>({});
  return (
    <>
      <Breadcrumb
        items={[{ title: t('breadcrumb.sustainability') }, { title: t('breadcrumb.sustainability-team-status') }]}
      />
      <SContainer>
        <SHeader>
          <STitlePage>{t('pages.sustainability.team-status.title')}</STitlePage>
          <Can I="sustainability.rule.store" a="">
            <Button status="primary" icon={isFormOpen ? <FaMinus /> : <FaPlus />} onClick={toggleForm}>
              {t('pages.sustainability.team-status.btnAdd')}
            </Button>
          </Can>
        </SHeader>
        <Form
          form={form}
          handleSave={handleSave}
          isOpen={isFormOpen}
          setIsFormOpen={setIsFormOpen}
          handleStatus={handleStatus}
          handlePositions={handlePositions}
          isFetchingForm={isFetchingForm}
          dataForm={dataForm}
          setDataForm={setDataForm}
        />
        <Table
          handleEdit={handleEdit}
          handleRemove={handleRemove}
          handleChange={handleTableChange}
          isLoading={isFetching}
          controller={tableController}
          setDataForm={setDataForm}
          searchFilters={searchFilters}
          setSearchFilters={setSearchFilters}
        />
      </SContainer>
    </>
  );
};

export default TeamStatus;

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AttachmentInput, AttachmentInputTranslation } from '@agrodatabr/agrodataui';
import { Col, Form, Row, Input as AntInput, DatePicker } from 'antd';
import ptBR from 'antd/es/date-picker/locale/pt_BR';
import { FormItem } from 'components';
import { FilePreviewer } from 'components/FilePreviewer';
import appConfigs from 'configs/app';
import { GuaranteeStatus } from 'configs/constants';
import moment from 'moment';

import { GuaranteeApprovalFormProps } from './GuaranteeApprovalForm.types';

export const GuaranteeApprovalForm = ({ form, guaranteeData, statusId }: GuaranteeApprovalFormProps) => {
  const { t } = useTranslation();

  const [fileToPreview, setFileToPreview] = useState<any>(null);

  const attachmentInputTranslation = {
    destroyIconText: t('pages.monitoring.surveyrequest.form.attachments.destroy.icon'),
    downloadIconText: t('pages.monitoring.surveyrequest.form.attachments.download.icon'),
    dragAndDropUploadText: t('pages.monitoring.surveyrequest.form.attachments.drag'),
    existingAttachmentsText: t('pages.monitoring.surveyrequest.form.attachments.existing'),
    onUploadError: t('pages.monitoring.surveyrequest.form.attachments.upload.error'),
    onUploadSuccess: t('pages.monitoring.surveyrequest.form.attachments.upload.success'),
    downloadAllButton: t('downloadAll'),
    downloadingAllMessage: t('messages.downloadingAllFiles'),
    alerts: {
      destroy: {
        success: t('pages.monitoring.surveyrequest.form.attachments.destroy.success'),
      },
    },
  } as AttachmentInputTranslation;

  const handleRemoveAttachment = (file: any) => {
    const newDocuments = form
      .getFieldValue('documents')
      .filter((document: any) => document.uid !== file.uid && !document.id);
    form.setFieldsValue({ documents: newDocuments });
  };

  const handlePreviewFile = (document: any) => setFileToPreview(document);

  useEffect(() => {
    if (guaranteeData.expiration_date)
      form.setFieldsValue({
        expiration_date: moment(guaranteeData.expiration_date),
      });
  }, [guaranteeData]);

  return (
    <Form form={form}>
      <Row gutter={[26, 26]} justify="center">
        <Col span={24}>
          <FormItem
            label={t('pages.credit-request.modal-limit.expiration_date')}
            name="expiration_date"
            rules={[{ required: statusId === GuaranteeStatus.VALIDATED }]}
            style={{ display: statusId === GuaranteeStatus.VALIDATED ? '' : 'none' }}
          >
            <DatePicker
              locale={ptBR}
              mode="date"
              format={appConfigs.formatDate}
              disabledDate={date => date.isBefore(moment().subtract(1, 'days'))}
            />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={[26, 26]} justify="center">
        <Col span={24}>
          <FormItem
            label={t('pages.sustainability.form.registrationModal.attachments')}
            name="documents"
            style={{ width: '100%' }}
          >
            <AttachmentInput
              multiple
              translation={attachmentInputTranslation}
              onRemove={handleRemoveAttachment}
              handleDestroy={(() => {
                return new Promise(() => console.log('destroyed')) as any;
              })()}
              downloadMultiple={() => {
                return new Promise(() => console.log('destroyed')) as any;
              }}
              handleDownload={() => {
                return new Promise(() => console.log('destroyed')) as any;
              }}
              handlePreview={async file => {
                handlePreviewFile(file);
                return true;
              }}
            />
          </FormItem>
        </Col>
      </Row>

      <Row gutter={[26, 26]} justify="center">
        <Col span={24}>
          <FormItem
            label={t('pages.credit-request.modal-limit.form.comment')}
            name="comment_approval"
            rules={[{ required: true }]}
          >
            <AntInput.TextArea rows={3} />
          </FormItem>
        </Col>
      </Row>
      {fileToPreview && <FilePreviewer document={fileToPreview} onClose={() => setFileToPreview(null)} />}
    </Form>
  );
};

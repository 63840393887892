import { PaginatedQueryParams, usePaginatedQuery } from 'hooks/usePaginatedQuery';
import SustainabilityData from 'types/Sustainability/SustainabilityData';

type Params = PaginatedQueryParams;

export const useGetSustentabilitiesQuery = ({ dispatchPagination, ...params }: Params) => {
  return usePaginatedQuery<SustainabilityData>({
    queryKey: ['sustainability/report', params],
    url: `sustainability/report`,
    params: {
      ...params,
      dispatchPagination,
    },
  });
};

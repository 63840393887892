import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Dialog,
  DialogContent,
  DialogFooterForm,
  DialogHeader,
  DialogPortal,
  DialogTrigger,
} from 'components/ui/dialog';

import { useRegisteredCustomerStore } from '../stores/use-registered-customer-store';
import { economicGroupInitialValues, EconomicGroupFormData, EconomicGroupForm } from './economic-groups.form';

type Props = {
  children?: React.ReactNode;
  onSuccess?: (data: EconomicGroupFormData) => void;
  personType: 'legal' | 'physical';
};

export const EconomicGroupModal = ({ children, onSuccess, personType }: Props) => {
  const formRef = useRef<any>(null);
  const { t } = useTranslation();

  const isEconomicGroupModalOpen = useRegisteredCustomerStore(state => state.isEconomicGroupModalOpen);
  const setEconomicGroupToEdit = useRegisteredCustomerStore(state => state.setEconomicGroupToEdit);
  const economicGroupToEdit = useRegisteredCustomerStore(state => state.economicGroupToEdit);
  const setIsEconomicGroupModalOpen = useRegisteredCustomerStore(state => state.setIsEconomicGroupModalOpen);

  return (
    <Dialog
      open={isEconomicGroupModalOpen}
      onOpenChange={value => {
        setIsEconomicGroupModalOpen(value);
        if (!value) {
          setEconomicGroupToEdit(null);
          formRef.current?.form.reset(economicGroupInitialValues);
        }
      }}
    >
      <DialogTrigger>{children}</DialogTrigger>
      <DialogPortal>
        <DialogContent className="md:w-[50%] w-[96%]">
          <DialogHeader>
            {economicGroupToEdit ? t('edit') : t('new-m')} {t('economic-group')}
          </DialogHeader>
          <EconomicGroupForm
            personType={personType}
            ref={formRef}
            onSuccess={data => {
              setIsEconomicGroupModalOpen(false);
              setEconomicGroupToEdit(null);
              onSuccess?.(data);
              formRef.current?.form.reset(economicGroupInitialValues);
            }}
          />
          <DialogFooterForm
            onConfirm={() => {
              formRef.current?.submit();
            }}
            onCancel={() => {
              formRef.current?.form.reset(economicGroupInitialValues);
              setEconomicGroupToEdit(null);
              setIsEconomicGroupModalOpen(false);
            }}
          />
        </DialogContent>
      </DialogPortal>
    </Dialog>
  );
};

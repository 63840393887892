import { useState } from 'react';

import { useAbility } from 'hooks/ability';

import { handleRequest } from '../../../../../services/api/api';
import dashboardApi from '../../../../../services/api/dashboard';
import monitoringApi from '../../../../../services/api/monitoring';

export const useFilters = () => {
  const ability = useAbility();

  const [isFetchingProviders, setIsFetchingProviders] = useState(false);

  const getCounterparty = async () => {
    if (!ability.can('credit.request.requester.all', '')) return [];
    const data = await handleRequest(() => dashboardApi.dashboard.getRequester());
    return data;
  };

  const getOwners = async () => {
    const data = await handleRequest(() => monitoringApi.owner.all());
    return data;
  };

  const getProviders = async (params = {}) => {
    const data = await handleRequest(() => dashboardApi.dashboard.getProviders(params), setIsFetchingProviders);
    return data || [];
  };

  return {
    getProviders,
    getCounterparty,
    getOwners,
    isFetchingProviders,
  };
};

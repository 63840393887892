import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Spin, Form as AntForm, FormInstance, Row, Col } from 'antd';
import { Button, FormItem, Input, Select } from 'components';
import { useIntegrationGroups } from 'hooks/fetch/useIntegrationGroups';
import { useModalMessage } from 'hooks/helpers/useModalMessage';
import { usePage } from 'hooks/page';

import { IntegrationGroupData } from '../../../../@types/data/IntegrationGroupData';
import type DTOErrorReponse from '../../../../@types/dtos/DTOErrorReponse';
import { SFormButtons, SFormContainer } from './styles';

import { IntegrationGroupSource } from '..';

interface Props {
  isVisible: boolean;
  handleHideForm: () => void;
  refetchTable: () => void;
  form: FormInstance;
  groupToEdit: IntegrationGroupData;
}

export const Form = ({ isVisible, handleHideForm, refetchTable, form, groupToEdit }: Props) => {
  const { t } = useTranslation();
  const { alertStatus } = usePage();
  const { handleSuccess } = useModalMessage();
  const { store, isStoring, isUpdating, updateGroup } = useIntegrationGroups();

  const [isFormLocked, setIsFormLocked] = React.useState(false);

  const storeOrUpdate = () => {
    setIsFormLocked(true);
    form
      .validateFields()
      .then(() => {
        const formValues = form.getFieldsValue();
        const requestMethod = groupToEdit ? updateGroup(form.getFieldValue('id'), formValues) : store(formValues);
        requestMethod
          .then(response => {
            if (response) {
              handleSuccess(
                groupToEdit ? t('pages.register.priceDay.edit.success') : t('pages.register.priceDay.register.success'),
              );
              refetchTable();
              handleHideForm();
              setIsFormLocked(false);
              form.resetFields();
            }
          })
          .catch((err: DTOErrorReponse) => {
            alertStatus(err, 'error');
            setIsFormLocked(false);
          });
      })
      .catch((err: DTOErrorReponse) => {
        alertStatus(err, 'error');
        setIsFormLocked(false);
      });
    setIsFormLocked(false);
  };

  useEffect(() => {
    if (groupToEdit) {
      form.setFieldsValue({
        ...groupToEdit,
      });
    }
  }, [groupToEdit?.id]);

  return (
    <SFormContainer visible={isVisible}>
      <Spin spinning={isStoring || isUpdating}>
        <h2>{t('pages.integration.group.title')}</h2>
        <AntForm form={form}>
          <FormItem name="id" style={{ display: 'none' }}>
            <Input />
          </FormItem>
          <FormItem name="name" label={t('pages.integration.group.form.name')} rules={[{ required: true }]}>
            <Input />
          </FormItem>
          <Row>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <FormItem name="origin" label={t('pages.integration.group.form.origin')} rules={[{ required: true }]}>
                <Select
                  options={[IntegrationGroupSource.AGRODATA, IntegrationGroupSource.SAP].map(source => {
                    return { label: source.toUpperCase(), value: source, key: source };
                  })}
                  onChange={value => {
                    if (value === IntegrationGroupSource.AGRODATA)
                      form.setFieldsValue({ destiny: IntegrationGroupSource.SAP });
                    if (value === IntegrationGroupSource.SAP)
                      form.setFieldsValue({ destiny: IntegrationGroupSource.AGRODATA });
                  }}
                />
              </FormItem>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <FormItem name="destiny" label={t('pages.integration.group.form.destiny')} rules={[{ required: true }]}>
                <Select
                  disabled
                  options={[IntegrationGroupSource.AGRODATA, IntegrationGroupSource.SAP].map(source => {
                    return { label: source.toUpperCase(), value: source, key: source };
                  })}
                />
              </FormItem>
            </Col>
          </Row>
          <SFormButtons>
            <Button
              status="secondary"
              htmlType="reset"
              onClick={handleHideForm}
              disabled={isFormLocked || isStoring || isUpdating}
            >
              {t('pages.register.harvest.buttonCancel')}
            </Button>
            {groupToEdit && (
              <Button
                status="primary"
                htmlType="submit"
                onClick={storeOrUpdate}
                loading={isStoring || isUpdating}
                disabled={isFormLocked || isStoring || isUpdating}
                permission="worker.group.update"
              >
                {t('pages.register.harvest.buttonEdit')}
              </Button>
            )}
            {!groupToEdit && (
              <Button
                status="primary"
                htmlType="submit"
                onClick={storeOrUpdate}
                loading={isStoring || isUpdating}
                disabled={isFormLocked || isStoring || isUpdating}
                permission="worker.group.store"
              >
                {t('pages.register.harvest.buttonRegister')}
              </Button>
            )}
          </SFormButtons>
        </AntForm>
      </Spin>
    </SFormContainer>
  );
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import { GoAlert } from 'react-icons/go';
import { HiChevronRight } from 'react-icons/hi';

import { Col, Popover, Row, Spin, Statistic } from 'antd';
import { CONTRACT_SIGNATURE_STATUS_AUTORESPONDED, CONTRACT_SIGNATURE_STATUS_DECLINED } from 'configs/constants';
import { useCanRunFunction } from 'hooks/ability';
import { cn } from 'lib/utils';

import { useDraftSignatures } from '../../../../hooks/fetch/useDraftSignatures';
import theme from '../../../../styles/theme';
import { Formatter } from '../../../../utils/Formatter';
import { SignatureRequest } from '../../Emission/modules/ContractStages/Signatures/types';
import { Tag } from '../Tag';
import { Container, SignedMessage } from './styles';

interface Props {
  signatureRequest: SignatureRequest;
  allowManualStatusChange?: boolean;
  openSignatureRequestStatusChangeModal?: (id: string) => void;
}

const valueStyle = { fontSize: '14px', fontWeight: 'bold' };

export const SignatureRequestCard = ({
  signatureRequest,
  allowManualStatusChange = false,
  openSignatureRequestStatusChangeModal = () => null,
}: Props) => {
  const { isSending } = useDraftSignatures();
  const { i18n } = useTranslation();
  const canRunFunction = useCanRunFunction();

  const isSignatureRequestSigned = !!signatureRequest.signed_at;

  const handleSignatureRequestStatusClick = () =>
    allowManualStatusChange ? openSignatureRequestStatusChangeModal(signatureRequest.id) : null;

  const isAlertStatus =
    signatureRequest.status?.id === CONTRACT_SIGNATURE_STATUS_DECLINED ||
    signatureRequest.status?.id === CONTRACT_SIGNATURE_STATUS_AUTORESPONDED;
  const isDeclined = signatureRequest.status?.id === CONTRACT_SIGNATURE_STATUS_DECLINED;

  const cardStatus = () => {
    if (!isAlertStatus) return 'normal';
    if (isDeclined) return 'declined';
    return 'autoResponded';
  };

  return (
    <Spin spinning={isSending}>
      <Container key={signatureRequest.name} status={cardStatus()}>
        <Row gutter={[10, 10]}>
          {isAlertStatus ? (
            <Popover content={JSON.parse(signatureRequest.status?.translations ?? '{}')?.[i18n.language]?.title ?? ''}>
              <GoAlert color={theme.colors[isDeclined ? 'danger' : 'warning']} />
            </Popover>
          ) : (
            ''
          )}
        </Row>
        <Row gutter={[10, 10]}>
          <Col span={24}>
            <Statistic title="Nome do Signatário" value={signatureRequest.name} valueStyle={valueStyle} />
          </Col>
          <Col span={24}>
            <Statistic
              title="Documento do Signatário"
              value={
                signatureRequest.social_id
                  ? signatureRequest.social_id?.replaceAll(/\D/g, '').length === 11
                    ? Formatter.cpf(signatureRequest.social_id)
                    : Formatter.cnpj(signatureRequest.social_id)
                  : '-'
              }
              valueStyle={valueStyle}
            />
          </Col>
          <Col span={24}>
            <Statistic title="Email do Signatário" value={signatureRequest.email ?? '-'} valueStyle={valueStyle} />
          </Col>
          <Col span={24}>
            <Statistic title="Telefone do Signatário" value={signatureRequest.phoneNumber} valueStyle={valueStyle} />
          </Col>
          <Col span={24}>
            <Statistic title="Papel" value={signatureRequest.role.name} valueStyle={valueStyle} />
          </Col>
          <Col span={12}>
            <div
              style={{ cursor: allowManualStatusChange ? 'pointer' : '' }}
              onClick={() => canRunFunction('contract.changestatus', handleSignatureRequestStatusClick)}
            >
              <Tag
                className={cn(
                  'p-[3px 8px] text-[12px]',
                  isSignatureRequestSigned ? 'bg-lightGreen text-primary' : 'bg-lightOrange text-primary',
                )}
              >
                {signatureRequest?.status?.name}
                {allowManualStatusChange && <HiChevronRight />}
              </Tag>
            </div>
          </Col>
          <Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
            {isSignatureRequestSigned ? (
              <SignedMessage>Assinou em {Formatter.datetime(signatureRequest.signed_at)}</SignedMessage>
            ) : (
              <div />
            )}
          </Col>
        </Row>
      </Container>
    </Spin>
  );
};

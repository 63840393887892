import { useTranslation } from 'react-i18next';

import { ModalStatus } from 'components';
import {
  OPERATION_DELIVERED_PRODUCT_ADVANCE_ID,
  STATE_MT,
  TEAM_STATUS_ON_QUEUE_PREFIN_PRE_REGISTRATION,
  TEAM_STATUS_SUSTAINABILITY_SOLICITATION_ID,
} from 'configs/constants';
import { useAuth } from 'hooks/auth';
import { ChangeCreditRequestStatusDataProps, useCreditStatus } from 'hooks/creditStatus';
import { useSustainability } from 'hooks/fetch/useSustainability';
import { useVerifyPackageIntegrity } from 'hooks/fetch/useVerifyPackageIntegrity';

import type DTOTeam from '../../@types/dtos/credit-request/DTOTeam';

interface TryChangeStatusProps extends ChangeCreditRequestStatusDataProps {
  netAmount: number;
}

export const useCreditRequestRules = () => {
  const { teams } = useAuth();
  const { t } = useTranslation();
  const { verify } = useVerifyPackageIntegrity();
  const { getReports } = useSustainability();
  const { handleChangeStatusCreditRequest } = useCreditStatus();

  const handleStatus = (id: any) => {
    ModalStatus({
      type: 'warning',
      iconName: 'warning',
      title: t(`modal.warning`),
      subTitle: t('hooks.creditStatus.modal-status-prefin'),
      cancelText: t('hooks.creditStatus.modal-status-buttons.no'),
      okText: t('hooks.creditStatus.modal-status-buttons.yes'),
      onOk: () => {
        window.open(`/credit-request/edit/${id}/tab-formalization-guarantees`, '_blank');
      },
    });
  };

  const handleStatusSustainability = (id: any) => {
    ModalStatus({
      type: 'warning',
      iconName: 'warning',
      title: t(`modal.warning`),
      subTitle: t('hooks.creditStatus.modal-status-prefin-sustainability'),
      cancelText: t('hooks.creditStatus.modal-status-buttons.no'),
      okText: t('hooks.creditStatus.modal-status-buttons.yes'),
      onOk: () => {
        window.open(`/sustainability/request-report/new/${id}`, '_blank');
      },
    });
  };

  const canShowChangeStatusSelect = (teamStatusTeamId: string, listOfTeamStatusLength: number) =>
    !!teams.filter((team: DTOTeam) => team.id === teamStatusTeamId).length && listOfTeamStatusLength > 0;

  const ADVANCE_PRODUCT_DELIVERED_RULES = {
    /**
     * O saldo a fixar deve ser igual a quantidade total do contrato, ou seja, nenhuma fixação pode ter sido feita para o contrato
     *
     * @param selectedOperation - The selected operation for the request
     * @param toFixVolume - The value of the volume to fix, volAFixar in the DatasulAPI
     * @param contractQuantity - The value of the contract quantity
     *
     * @returns undefined if the "toFixValue" is equals to the "contractQuantity" and returns an error message otherwise
     *
     * @see {@link https://agrodata.atlassian.net/wiki/spaces/AC/pages/1284243459/Realizando+uma+solicita+o+de+Adiantamento+de+Produto+Entregue}
     */
    toFixEqualsContractQuantityValidate: (selectedOperation: string, toFixVolume: number) => {
      if (selectedOperation === OPERATION_DELIVERED_PRODUCT_ADVANCE_ID && toFixVolume <= 0)
        return t('pages.credit-request.form.error_to_fix_balance');
    },
    /**
     * A quantidade recebida que consta no contrato no Datasul deve ser igual à quantidade total do contrato, pois o usuário só pode solicitar o adiantamento caso o produto já tenha sido recebido em sua totalidade
     *
     * @param selectedOperation - The selected operation for the request
     * @param receivedQuantity - The value of the received quantity for the contract
     * @param contractQuantity - The value of the contract quantity
     *
     * @returns undefined if the "receivedQuantity" is equals or greater than the contract quantity and returns an error message otherwise
     *
     * @see {@link https://agrodata.atlassian.net/wiki/spaces/AC/pages/1284243459/Realizando+uma+solicita+o+de+Adiantamento+de+Produto+Entregue}
     */
    receivedQuantityEqualsContractQuantityValidate: (
      selectedOperation: string,
      receivedQuantity: number,
      contractQuantity: number,
    ) => {
      if (selectedOperation === OPERATION_DELIVERED_PRODUCT_ADVANCE_ID && receivedQuantity < contractQuantity)
        return t('pages.credit-request.messages.contract_quantity_received_missing');
    },

    /**
     * Retorna um boolean indicando se os campos de FETHAB devem ser exibidos ou não e se o imposto de FETHAB deve ser calculado ou não
     *
     * @param selectedStateId - ID of the selected state in the credit request
     *
     * @returns boolean
     *
     * @see {@link https://agrodata.atlassian.net/wiki/spaces/AC/pages/1284243459/Realizando+uma+solicita+o+de+Adiantamento+de+Produto+Entregue}
     */
    isFethabEnableAdvancementForm: (selectedStateId: string) => selectedStateId === STATE_MT,
  };

  const STATUS_CHANGE_RULES = {
    tryChangeStatus: async (changeStatusData: TryChangeStatusProps) => {
      const changeStatus = async () => {
        await handleChangeStatusCreditRequest({
          ...changeStatusData,
          requestedAmount:
            changeStatusData.operationId === OPERATION_DELIVERED_PRODUCT_ADVANCE_ID
              ? changeStatusData.netAmount ?? 0
              : changeStatusData.requestedAmount,
        });
      };

      switch (changeStatusData.newStatusId) {
        case TEAM_STATUS_ON_QUEUE_PREFIN_PRE_REGISTRATION:
          const verificationResponse = await verify(changeStatusData.creditRequestId);
          if (!verificationResponse) {
            await handleStatus(changeStatusData.creditRequestId);
            break;
          }
          await changeStatus();

          break;
        case TEAM_STATUS_SUSTAINABILITY_SOLICITATION_ID:
          const sustainabilityResponse = await getReports({
            params: { credit_request: changeStatusData.creditRequestId },
          });
          if (sustainabilityResponse?.data?.data?.length <= 0)
            return handleStatusSustainability(changeStatusData.creditRequestId);

          await changeStatus();
          break;
        default:
          await changeStatus();
          break;
      }
    },
  };

  return {
    canShowChangeStatusSelect,
    ADVANCE_PRODUCT_DELIVERED_RULES,
    STATUS_CHANGE_RULES,
  };
};

import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FaPlus, FaMinus, FaSearch } from 'react-icons/fa';

import { InfiniteSelect, Select as AllSelect } from '@agrodatabr/agrodataui';
import { Col, Form, Spin } from 'antd';
import { Store } from 'antd/lib/form/interface';
import { TablePaginationConfig, TableProps } from 'antd/lib/table';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';

import PaginationData from '../../../@types/data/PaginationData';
import RegionData from '../../../@types/data/RegionData';
import { ResponseList } from '../../../@types/data/ResponseData';
import SubsidiaryData from '../../../@types/data/SubsidiaryData';
import SustainabilityData from '../../../types/Sustainability/SustainabilityData';
import DTOTeam from '../../../@types/dtos/credit-request/DTOTeam';
import DTOErrorReponse from '../../../@types/dtos/DTOErrorReponse';
import {
  Breadcrumb,
  Button,
  Ellipsis,
  FormItem,
  IconWithTooltip,
  Input,
  ModalStatus,
  Row,
  Select,
} from '../../../components';
import { TableData } from '../../../compositions';
import { Can } from '../../../hooks/ability';
import { useAuth } from '../../../hooks/auth';
import { useCache } from '../../../hooks/cache';
import { useUser } from '../../../hooks/fetch/useUsers';
import { usePage, ItemsType } from '../../../hooks/page';
import sustainabilityApi from '../../../services/api/sustainability';
import UserData from '../../../types/User/UserData';
import { useFormController } from '../controllers/useFormController';
import columns from './columns';
import { SContainer, SFilterContainer, SFormButtons, SFormContainer, SHeader, STitlePage } from './styles';
import UserForm from './UserForm';

const UserRules: React.FC = () => {
  const { t, i18n } = useTranslation(['translation', 'admin']);
  const { updateUserData } = useAuth();
  const { getCreditOptions } = useCache();
  const { alertStatus } = usePage();
  const { destroy: destroyUser } = useUser();

  const [data, setData] = useState([]);
  const [disabledButton, setDisabledButton] = useState(false);
  const [editing, setEditing] = useState(false);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [selectedRules, setSelectedRules] = useState<SustainabilityData>({} as SustainabilityData);
  const [loadingButton, setLoadingButton] = useState(false);
  const [paginationConfig, setPaginationConfig] = useState<TablePaginationConfig>({
    current: 1,
    total: 1,
    pageSize: 20,
  });
  const [visibilityForm, setVisibilityForm] = useState(false);
  const { loadUsers } = useFormController({ form });

  const handleSuccess = (messageSuccess = '') =>
    ModalStatus({
      type: 'success',
      title: t('modal.success'),
      subTitle: messageSuccess,
    });

  // status
  const [status, setStatus] = useState<ItemsType[]>(() => {
    let statusOptions: ItemsType[] = [];
    sustainabilityApi.reportstatus
      .get()
      .then((response: { data: any }) => {
        const { data: statusData } = response.data;
        statusOptions = statusData.map((statusOption: { id: any; name: any }) => ({
          key: statusOption.id,
          value: statusOption.id,
          label: statusOption.name,
        }));
        setStatus(statusOptions);
      })
      .catch((err: DTOErrorReponse) => alertStatus(err, 'error'));
    return statusOptions;
  });

  const updateData = (row: Store) => {
    let dataUpdated: Array<Store> = data;
    if (data.length > 0) {
      const rowIndex = data.findIndex((item: Store) => row.id === item.id);
      // Se encontrar o item remove e adiciona ele atualizado na mesma posição
      if (rowIndex > -1) {
        dataUpdated.splice(rowIndex, 1, row);
      } else {
        if (dataUpdated.length === paginationConfig.pageSize) {
          // Se não encontrar, remove o último (por conta da paginação) e
          // adiciona o item na primeira posição
          dataUpdated.splice(-1, 1);
        }
        dataUpdated = [row, ...dataUpdated];
      }
    } else {
      dataUpdated.push(row);
    }

    setData(dataUpdated);
  };

  // types
  const [types, setTypes] = useState<ItemsType[]>(() => {
    let typeOptions: ItemsType[] = [];
    sustainabilityApi.reporttype
      .get()
      .then((response: { data: any }) => {
        const { data: typeData } = response.data;
        typeOptions = typeData.map((type: { id: any; name: any }) => ({
          key: type.id,
          value: type.id,
          label: type.name,
          saved: true,
        }));
        setTypes(typeOptions);
      })
      .catch((err: DTOErrorReponse) => alertStatus(err, 'error'));
    return typeOptions;
  });

  const loadTableData = useCallback(
    (values: Partial<UserForm & PaginationData<UserData>>) => {
      setLoading(true);
      const dataSend = {
        params: {
          ...values,
        },
      };

      sustainabilityApi.userrule
        .get(dataSend)
        .then((response: ResponseList<UserData>) => {
          const result = response.data;
          setData(result.data);
          setPaginationConfig(paginationConfigState => ({
            ...paginationConfigState,
            current: result.current_page,
            total: result.total,
          }));
          setLoading(false);
        })
        .catch((err: DTOErrorReponse) => {
          alertStatus(err, 'error');
          setLoading(false);
        });
    },
    // eslint-disable-next-line
    [i18n.language],
  );

  const handleDisplayForm = () => {
    setVisibilityForm(!visibilityForm);
    setEditing(false);
    setDisabledButton(false);
    setLoadingButton(false);
    form.resetFields();
  };

  const handleHideForm = () => {
    setVisibilityForm(false);
  };

  const handleRegister = () => {
    setLoading(true);
    setLoadingButton(true);
    setDisabledButton(true);
    form
      .validateFields()
      .then(values => {
        sustainabilityApi.userrule
          .store({
            ...values,
            language: i18n.language,
          })
          .then(response => {
            const { data: userData } = response;
            updateData(userData);
            handleSuccess(response.data.message);
            updateUserData();
            handleHideForm();
            setLoading(false);
            setLoadingButton(false);
            setDisabledButton(false);
            form.resetFields();
          })
          .catch((err: DTOErrorReponse) => {
            alertStatus(err, 'error');
            setLoading(false);
            setLoadingButton(false);
            setDisabledButton(false);
          });
      })
      .catch(() => {
        setLoading(false);
        setLoadingButton(false);
        setDisabledButton(false);
      });
  };

  const handleEdit = () => {
    setLoading(true);
    setLoadingButton(true);
    setDisabledButton(true);
    form
      .validateFields()
      .then(values => {
        sustainabilityApi.userrule
          .update(values.id, { ...values, language: i18n.language })
          .then(response => {
            const { data: userData } = response;
            updateData(userData);
            handleSuccess(response.data.message);
            handleHideForm();
            form.resetFields();
            setLoading(false);
            setLoadingButton(false);
            setDisabledButton(false);

            updateUserData();
          })
          .catch((err: DTOErrorReponse) => {
            alertStatus(err, 'error');
            setLoading(false);
            setLoadingButton(false);
            setDisabledButton(false);
          });
      })
      .catch(() => {
        setLoading(false);
        setLoadingButton(false);
        setDisabledButton(false);
      });
  };

  const getActions = () => ({
    render: (active: number, record: any) => (
      <>
        <Can I="admin.user.destroy" a="">
          <IconWithTooltip
            action="destroy"
            title={t('pages.monitoring.farm.table.icons.remove')}
            onClick={() => {
              ModalStatus({
                type: 'delete',
                title: t('pages.monitoring.farm.delete.title'),
                subTitle: t('pages.monitoring.farm.delete.subtitle'),
                cancelText: t('pages.monitoring.farm.delete.cancel'),
                okText: t('pages.monitoring.farm.delete.confirm'),
                onOk: () => {
                  setLoading(true);
                  sustainabilityApi.userrule
                    .destroy(record.id, {
                      params: {},
                    })
                    .then(response => {
                      handleSuccess(response.data.message);
                      handleTableChange({ current: 1 }, { active: [1] }, {}, null);
                    })
                    .catch((err: DTOErrorReponse) => alertStatus(err, 'error'));
                },
              });
            }}
          />
        </Can>
        <Can I="admin.user.update" a="">
          <IconWithTooltip
            action="edit"
            title={t('pages.admin.user.table.row.editIcon')}
            onClick={() => {
              form.resetFields();
              form.setFieldsValue({
                ...record,
                user_id: record.user?.id,
                types: record.types?.map((type: SubsidiaryData) => type.id) ?? [],
                products: record.products?.map((product: DTOTeam) => product.id) ?? [],
                status: record.status?.map((statusrow: any) => statusrow.id) ?? [],
              });
              setSelectedRules(record);
              form.scrollToField('name', {
                scrollMode: 'always',
                block: 'start',
                behavior: actions =>
                  actions.forEach(({ el, top }) => {
                    el.scrollTop = top - 280;
                  }),
              });

              setVisibilityForm(true);
              setEditing(true);
              setLoading(false);
              setLoadingButton(false);
              setDisabledButton(false);
            }}
          />
        </Can>
      </>
    ),
  });

  const [searchForm] = Form.useForm();

  const handleSwitchChange = async (record: any) => {
    const response = await destroyUser(record.id);

    if (response) handleTableChange({ current: 1 }, { active: [1] }, {}, null);
  };

  // Table DATA Config: BEGIN
  const tableConfig = {
    search: '',
    filtered: {},
    sorter: {},
    getActions,
    handleSwitchChange,
  };

  const [tableDataConfig, setTableDataConfig] = useState(tableConfig);

  const handleFastSearch = () => {
    const searchValue = searchForm.getFieldsValue();
    setTableDataConfig(tableDataConfigState => ({
      ...tableDataConfigState,
      search: searchValue.search,
    }));
    loadTableData({
      name: searchValue.search,
      email: searchValue.search,
      type: searchValue.search,
      product: searchValue.search,
    });
  };

  const tableDataClearAllFilters = () => {
    searchForm.resetFields();
    setTableDataConfig(tableDataConfigState => ({
      ...tableDataConfigState,
      filtered: {},
      search: '',
      sorter: {},
    }));
    loadTableData({ page: 1 });
  };

  const handleTableChange: TableProps<any>['onChange'] = useCallback(
    (
      pagination: TablePaginationConfig,
      filters: Record<string, FilterValue | null>,
      sorter: SorterResult<any> | SorterResult<any>[],
    ) => {
      setLoading(true);
      setTableDataConfig(tableDataConfigState => ({
        ...tableDataConfigState,
        filtered: filters,
        sorter,
      }));

      let direction = '';
      const search: any = {};
      let sort = '';

      if (sorter) {
        const { field, order } = sorter as SorterResult<any>;

        if (order === 'ascend') direction = 'asc';
        if (order === 'descend') direction = 'desc';
        sort = field === 'posision_id' ? 'position.name' : field?.toString();
      }

      if (filters) {
        const dataFilter: any = filters;

        if (dataFilter) {
          Object.keys(dataFilter).forEach((key: string) => {
            if (dataFilter[key] !== null && dataFilter[key] !== undefined && dataFilter[key][0]) {
              search[key] = dataFilter[key][0].toString();
            } else if (dataFilter[key] !== null && dataFilter[key] !== undefined) {
              search[key] = dataFilter[key].toString();
            } else {
              search[key] = '';
            }
          });
        }
      }

      setPaginationConfig(paginationConfigState => ({
        ...paginationConfigState,
        pageSize: pagination.pageSize,
        current: pagination.current,
      }));

      loadTableData({
        page: pagination.current,
        per_page: pagination.pageSize,
        sort,
        direction,
        ...search,
      });
    },
    [loadTableData],
  );
  // Table DATA Config: END

  useEffect(() => {
    handleTableChange({ current: 1, pageSize: paginationConfig.pageSize }, {}, {}, null);
    // eslint-disable-next-line
  }, [handleTableChange]);

  return (
    <>
      <Breadcrumb
        items={[{ title: t('breadcrumb.sustainability') }, { title: t('breadcrumb.sustainability_parameterization') }]}
      />

      <SContainer>
        <SHeader>
          <STitlePage>{t('pages.sustainability.parameterization.title_page')}</STitlePage>

          <Can I="admin.user.store" a="">
            <Button status="primary" icon={visibilityForm ? <FaMinus /> : <FaPlus />} onClick={handleDisplayForm}>
              {t('pages.sustainability.parameterization.buttonAdd')}
            </Button>
          </Can>
        </SHeader>
        <br />
        {visibilityForm && (
          <SFormContainer visible={visibilityForm}>
            <Spin spinning={loading}>
              <Form form={form} scrollToFirstError autoComplete="none">
                <FormItem name="id" style={{ display: 'none' }}>
                  <Input />
                </FormItem>
                <Row gutter={[26, 26]}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                    <FormItem
                      label={t('pages.sustainability.parameterization.form.user')}
                      name="user_id"
                      rules={[{ required: true }]}
                    >
                      <InfiniteSelect
                        options={
                          selectedRules?.user
                            ? [
                                {
                                  key: selectedRules?.user?.id,
                                  value: selectedRules?.user?.name,
                                  label: selectedRules?.user?.name,
                                },
                              ]
                            : []
                        }
                        showSearch
                        getOptions={loadUsers}
                      />
                    </FormItem>
                  </Col>
                </Row>

                <Row gutter={[26, 26]}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                    <FormItem
                      label={t('pages.sustainability.parameterization.form.types')}
                      name="types"
                      rules={[{ required: true }]}
                    >
                      <AllSelect
                        allOption
                        translation={{
                          all: 'Todos',
                        }}
                        options={[...types]}
                        showSearch
                        mode="multiple"
                      />
                    </FormItem>
                  </Col>
                </Row>
                <Row gutter={[26, 26]}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                    <FormItem
                      label={t('pages.sustainability.parameterization.form.sub_divisions')}
                      name="products"
                      rules={[{ required: true }]}
                    >
                      <AllSelect
                        allOption
                        translation={{
                          all: 'Todos',
                        }}
                        options={getCreditOptions('product')}
                        mode="multiple"
                        showSearch
                      />
                    </FormItem>
                  </Col>
                </Row>
                <Row gutter={[26, 26]}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                    <FormItem
                      label={t('pages.sustainability.parameterization.form.status')}
                      name="status"
                      rules={[{ required: true }]}
                    >
                      <Select options={status} mode="multiple" showSearch />
                    </FormItem>
                  </Col>
                </Row>
                <SFormButtons>
                  <Button status="secondary" htmlType="reset" onClick={handleHideForm} disabled={disabledButton}>
                    {t('pages.admin.user.buttonCancel')}
                  </Button>
                  <>
                    {editing && (
                      <Button
                        status="primary"
                        htmlType="submit"
                        onClick={handleEdit}
                        loading={loadingButton}
                        disabled={disabledButton}
                      >
                        {t('pages.admin.user.buttonEdit')}
                      </Button>
                    )}
                    {!editing && (
                      <Button
                        status="primary"
                        htmlType="submit"
                        onClick={handleRegister}
                        loading={loadingButton}
                        disabled={disabledButton}
                      >
                        {t('pages.admin.user.buttonRegister')}
                      </Button>
                    )}
                  </>
                </SFormButtons>
              </Form>
            </Spin>
          </SFormContainer>
        )}

        <SFilterContainer>
          <Form
            form={searchForm}
            name="filter-user"
            className="form-secondary form-filters grid-filters"
            onFinish={handleFastSearch}
          >
            <div className="filter-search">
              <FormItem name="search" label={t('pages.admin.user.search')}>
                <Input placeholder={t('pages.sustainability.parameterization.form.search.placeholder')} />
              </FormItem>
            </div>
            <div className="filter-button">
              <FormItem label="" className="form-item-without-label">
                <Button status="primary" text="uppercase" htmlType="submit">
                  <FaSearch /> {t('form.actions.search')}
                </Button>
              </FormItem>
            </div>
            <div className="filter-clear">
              <Button status="secondary" size="middle" onClick={tableDataClearAllFilters}>
                {t('pages.admin.user.buttonClearFilter')}
              </Button>
            </div>
          </Form>
        </SFilterContainer>

        <TableData
          rowKey="id"
          columns={columns.map(column => {
            let columnRender: any = {
              ...column,
            };
            if (column.key === 'name') {
              columnRender = {
                ...columnRender,
                render: (value: any, record: any) => {
                  return record && record.user ? record.user?.name : '';
                },
              };
            }

            if (column.key === 'email') {
              columnRender = {
                ...columnRender,
                render: (value: any, record: any) => {
                  return record && record.user ? record.user?.email : '';
                },
              };
            }

            if (column.key === 'types') {
              columnRender = {
                ...columnRender,
                render: (value: Array<RegionData>) => {
                  const unique = [...new Set(value?.map((item: RegionData) => item.name))];
                  return <Ellipsis value={unique?.join(', ')} />;
                },
              };
            }

            if (column.key === 'products') {
              columnRender = {
                ...columnRender,
                render: (value: Array<SubsidiaryData>) => (
                  <Ellipsis value={value?.map((item: SubsidiaryData) => item.name).join(', ')} />
                ),
              };
            }

            if (column.key === 'status') {
              columnRender = {
                ...columnRender,
                render: (value: Array<SubsidiaryData>) => (
                  <Ellipsis value={value?.map((item: SubsidiaryData) => item.name).join(', ')} />
                ),
              };
            }

            return columnRender;
          })}
          dataSource={data}
          loading={loading}
          onChange={handleTableChange}
          pagination={paginationConfig}
          tableConfig={tableDataConfig}
        />
      </SContainer>
    </>
  );
};

export default UserRules;

import React from 'react';

export const useExpandable = (singleRow = false) => {
  const [expandedRowKeys, setExpandedRowKeys] = React.useState<string[]>([]);

  const onExpand = React.useCallback(
    (expanded: boolean, record: any) => {
      if (singleRow) {
        setExpandedRowKeys(expanded ? [record.id] : []);
      } else {
        setExpandedRowKeys(expanded ? [...expandedRowKeys, record.id] : expandedRowKeys.filter(id => id !== record.id));
      }
    },
    [expandedRowKeys, singleRow],
  );

  return {
    onExpand,
    expandedRowKeys,
  };
};

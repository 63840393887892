export const columns = [
  {
    title: 'pages.credit-request.table.headers.number',
    dataIndex: 'number',
    key: 'number',
    sorter: false,
    filtered: false,
  },
  {
    title: 'pages.credit-request.table.headers.requester_physical_person',
    dataIndex: 'name',
    key: 'name',
    sorter: false,
    filtered: false,
  },
  {
    title: 'pages.credit-request.table.headers.document_number',
    dataIndex: 'doc_number',
    key: 'doc_number',
    sorter: false,
    filtered: false,
  },
  {
    title: 'pages.credit-request.table.headers.quantity',
    dataIndex: 'quantity',
    key: 'quantity',
    sorter: false,
    filtered: false,
  },
  {
    title: 'pages.credit-request.table.headers.requested_amount',
    dataIndex: 'requested_amount',
    key: 'requested_amount',
    sorter: false,
    filtered: false,
  },
];

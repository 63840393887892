/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FaDownload } from 'react-icons/fa';

import { Modal, Tabs } from 'antd';
import { Button } from 'components';
import { useContract } from 'hooks/fetch/useContract';
import { handleRequest } from 'services/api/api';
import documentApi from 'services/api/document';
import { ContractImportData } from 'types/Contract/ContractImportsData';

import PaginationData from '../../../../../../../@types/data/PaginationData';
import { DataTab } from './DataTab';
import { DictionaryTab } from './DictionaryTab';

interface Props {
  isVisible: boolean;
  close: () => void;
  contractId: string;
  isDisabled: boolean;
}

export const IntegrationsModal = ({ close, isVisible, contractId, isDisabled }: Props) => {
  const { t } = useTranslation();
  const { getImports, isFetchingImports } = useContract();

  const [paginatedImports, setPaginatedImports] = useState<PaginationData<ContractImportData>>(undefined);
  const [firstImportId, setFirstImportId] = useState<string>(undefined);
  const [isLoadingJson, setIsLoadingJson] = useState<boolean>(false);

  const fetchImports = async (pageNumber = 1) => {
    if (!contractId) return;
    const response = await getImports(contractId, { params: { page: pageNumber, per_page: 10 } });
    if (pageNumber === 1 && response.data.length > 0) {
      setFirstImportId(response.data[0]?.attachment?.id);
    }
    setPaginatedImports(response);
  };

  const downloadContractDatasulJson = async () => {
    if (!firstImportId) return;
    const response = await handleRequest(() => documentApi.download(firstImportId), setIsLoadingJson);
    const reader = new FileReader();
    reader.readAsText(response);
    const textJson = await (response as Blob).text();
    const dataStr = `data:text/json;charset=utf-8,${encodeURIComponent(textJson)}`;
    const dlAnchorElem = document.getElementById('downloadAnchorElem');
    dlAnchorElem.setAttribute('href', dataStr);
    dlAnchorElem.setAttribute('download', `${firstImportId}.json`);
    dlAnchorElem.click();
  };

  useEffect(() => {
    if (isVisible) fetchImports();
  }, [contractId, isVisible]);

  return (
    <Modal
      footer={null}
      width="70%"
      title={
        <div style={{ display: 'flex', justifyContent: 'space-between', marginRight: '30px', alignItems: 'center' }}>
          <span>Datasul/SAP Json</span>
          <Button
            onClick={downloadContractDatasulJson}
            disabled={isDisabled || !paginatedImports?.data.length || isLoadingJson}
          >
            <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
              <span>{t('download.download')}</span>
              <FaDownload />
            </div>
          </Button>
        </div>
      }
      onCancel={close}
      visible={isVisible}
    >
      <Tabs defaultActiveKey="data">
        <Tabs.TabPane tab="Json" key="data">
          <DataTab
            paginatedImports={paginatedImports}
            fetchImports={fetchImports}
            isFetchingImports={isFetchingImports}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('dictionary')} key="dictionary">
          <DictionaryTab />
        </Tabs.TabPane>
      </Tabs>
      <a id="downloadAnchorElem" style={{ display: 'none' }} />
    </Modal>
  );
};

import { FixedType } from 'rc-table/lib/interface';

export const columns = [
  {
    title: 'pages.sustainability.table.number',
    dataIndex: 'number',
    key: 'number',
    sorter: true,
  },
  {
    title: 'pages.sustainability.table.provider',
    dataIndex: 'provider',
    key: 'provider',
  },
  {
    title: 'pages.sustainability.table.createdAt',
    dataIndex: 'created_at',
    key: 'created_at',
    sorter: true,
  },
  {
    title: 'pages.sustainability.table.doc_number',
    dataIndex: 'doc_number',
    key: 'doc_number',
  },
  {
    title: 'pages.sustainability.table.commodity',
    dataIndex: 'product',
    key: 'product',
  },
  {
    title: 'pages.sustainability.table.harvest',
    dataIndex: 'harvest',
    key: 'harvest',
  },
  {
    title: 'pages.sustainability.table.status',
    dataIndex: 'sustainability_report_status',
    key: 'sustainability_report_status',
    sorter: true,
  },
  {
    title: 'pages.edocuments.contracttypes.table.actions',
    dataIndex: 'action',
    key: 'action',
    width: '100px',
    fixed: 'right' as FixedType,
  },
];

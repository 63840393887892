import { Moment } from 'moment';

export function getCurrentHourInTimezone(timezone: string) {
  try {
    const currentDate = new Date();
    const formatter = new Intl.DateTimeFormat('en-US', {
      hour: '2-digit',
      timeZone: timezone,
    });
    const hourPart = formatter.formatToParts(currentDate).find(part => part.type === 'hour');
    return parseInt(hourPart.value, 10);
  } catch (error) {
    console.error('Error getting the current hour from the specified timezone:', error);
  }
}

export function addBusinessDays(originalDate: Moment, numDaysToAdd: number) {
  const Sunday = 0;
  const Saturday = 6;
  let daysRemaining = numDaysToAdd;

  const newDate = originalDate.clone();

  while (daysRemaining > 0) {
    newDate.add(1, 'days');
    if (newDate.day() !== Sunday && newDate.day() !== Saturday) {
      daysRemaining -= 1;
    }
  }

  return newDate;
}

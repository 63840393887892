export const Validate = {
  cpf: (inputCPF: string) => {
    inputCPF = inputCPF.toString().replace(/\D/g, '');
    let soma = 0;
    let resto;

    if (inputCPF === '00000000000') return false;
    for (let i = 1; i <= 9; i += 1) {
      soma += parseInt(inputCPF.substring(i - 1, i), 10) * (11 - i);
    }
    resto = (soma * 10) % 11;

    if (resto === 10 || resto === 11) resto = 0;
    if (resto !== parseInt(inputCPF.substring(9, 10), 10)) return false;

    soma = 0;
    for (let i = 1; i <= 10; i += 1) soma += parseInt(inputCPF.substring(i - 1, i), 10) * (12 - i);
    resto = (soma * 10) % 11;

    if (resto === 10 || resto === 11) resto = 0;
    if (resto !== parseInt(inputCPF.substring(10, 11), 10)) return false;
    return true;
  },
  cnpj: (cnpj: string) => {
    cnpj = cnpj.toString().replace(/\D/g, '');

    if (cnpj === '') return false;

    if (cnpj.length !== 14) return false;

    // Elimina CNPJs invalidos conhecidos
    if (
      cnpj === '00000000000000' ||
      cnpj === '11111111111111' ||
      cnpj === '22222222222222' ||
      cnpj === '33333333333333' ||
      cnpj === '44444444444444' ||
      cnpj === '55555555555555' ||
      cnpj === '66666666666666' ||
      cnpj === '77777777777777' ||
      cnpj === '88888888888888' ||
      cnpj === '99999999999999'
    )
      return false;

    // Valida DVs
    let tamanho = cnpj.length - 2;
    let numeros = cnpj.substring(0, tamanho);
    const digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;

    for (let i = tamanho; i >= 1; i -= 1) {
      soma += parseInt(numeros.charAt(tamanho - i), 10) * pos;
      pos -= 1;
      if (pos < 2) pos = 9;
    }

    let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado.toString() !== digitos.charAt(0)) return false;

    tamanho += 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i -= 1) {
      soma += parseInt(numeros.charAt(tamanho - i), 10) * pos;
      pos -= 1;
      if (pos < 2) pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado.toString() !== digitos.charAt(1)) return false;

    return true;
  },
  phone: (phone: string) => {
    return phone.match(/^(\+?[0-9]{2,3}[\s]?)?[(]?[0-9]{2}[)]?[-\s\\.]?[0-9]{5}[-\s\\.]?[0-9]{4}$/im);
  },
};

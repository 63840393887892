import { Input } from 'antd';
import styled from 'styled-components';

export const Container = styled.div`
  border-radius: 50px;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 10px 20px;
  flex-wrap: nowrap;
  background: ${({ theme: { colors } }) => colors.greys.grey11};
  color: ${({ theme: { colors } }) => colors.greys.grey08};
`;

export const SInput = styled(Input)`
  border: none;
  background: none;
  box-shadow: none;

  .ant-input {
    background: transparent;
  }

  &:focus {
    border: none;
    background: none;
    box-shadow: none;
  }
`;

import styled from 'styled-components';

export const Container = styled.div`
  height: calc(100% - 150px);
  padding: 0;

  .content-container {
    padding: 8px 8px 24px;

    .grid-actions {
      display: grid;
      grid-template-columns: 2fr 1fr;
      grid-template-areas: '. grid-button-to-subpage';
      gap: 8px;
      padding: 8px;

      h2 {
        color: ${({ theme: { colors } }) => colors.primary};
        font-family: ${({ theme: { fonts } }) => fonts.primary};
        font-size: 18px;
        font-weight: 600;
      }

      .grid-button-to-subpage {
        grid-area: grid-button-to-subpage;
        margin-top: 0;
        display: flex;
        justify-content: flex-end;
      }
    }
  }
`;

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CellContext, ColumnDefTemplate, createColumnHelper } from '@tanstack/react-table';
import { DataTable } from 'components/ui/data-table';
import appConfigs from 'configs/app';
import { cn } from 'lib/utils';
import moment from 'moment';
import { ExposureData } from 'types/Dashboard/ExposureData';
import { Formatter } from 'utils/Formatter';

import { useGetExposuresQuery } from '../api/use-get-exposures-query';
import { ExposuresFilter } from './exposures-filter';

export const ExposuresTable = () => {
  const { t } = useTranslation();

  const columnHelper = createColumnHelper<any>();
  const [searchParams, setSearchParams] = useState<any>({});
  const [expandedData, setExpandedData] = useState<Record<string, boolean>>({});

  const query = useGetExposuresQuery({
    filter: {
      ...searchParams,
      partner: searchParams.partner?.length ? searchParams.partner : '',
      datasource: searchParams.datasource === 'all' || !searchParams.datasource ? '' : searchParams.datasource,
    },
  });

  const expandedCount = Object.keys(expandedData).filter(key => expandedData[key]).length;
  const isAllExpanded = Array.isArray(query.data) ? expandedCount === query.data?.length : expandedCount === 1;

  const convertData = (data: ExposureData[]) => {
    const valueKeys = ['credit_limit', 'limit_expiring', 'value_usd', 'available_limit'];

    const newData: any[] = [];

    (Array.isArray(data) ? data : [data])?.slice(0, 24).forEach(item => {
      valueKeys.forEach(key => {
        newData.push({
          bp: item.bp === 'all' ? `BP (${t('all')})` : item.bp === 'total' ? 'TOTAL' : item.bp,
          value: key,
          ar: item?.AR?.[key as keyof typeof item.AR],
          mtm: item?.MTM?.[key as keyof typeof item.MTM],
          document: item?.document,
          prefin: {
            soybean:
              key === 'value_usd'
                ? [item.PREFIN?.Soja?.value_usd, item.PREFIN?.Soja?.value_usd_barter]
                : item.PREFIN?.Soja?.[key as keyof typeof item.PREFIN.Soja],
            corn:
              key === 'value_usd'
                ? [item.PREFIN?.Milho?.value_usd, item.PREFIN?.Milho?.value_usd_barter]
                : item.PREFIN?.Milho?.[key as keyof typeof item.PREFIN.Milho],
            cotton: item.PREFIN?.Cotton?.[key as keyof typeof item.PREFIN.Cotton],
            sugar: item.PREFIN?.Sugar?.[key as keyof typeof item.PREFIN.Sugar],
          },
          prepay: {
            soybean_corn: item.PREPAY?.['Soybean/Corn']?.[key as keyof (typeof item.PREPAY)['Soybean/Corn']],
            cotton: item.PREPAY?.Cotton?.[key as keyof typeof item.PREPAY.Cotton],
            fertilizers: item.PREPAY?.Fertilizantes?.[key as keyof typeof item.PREPAY.Fertilizantes],
          },
        });
      });
    });

    return newData;
  };

  const openAll = () => {
    const newExpandedData: Record<string, boolean> = {};
    (Array.isArray(query.data) ? query.data : [query.data])?.forEach(item => {
      newExpandedData[`bp:${item.bp ?? 'BP (Todos)'}`] = true;
    });

    setExpandedData(newExpandedData);
  };

  const closeAll = () => {
    setExpandedData({});
  };

  const cellRender: ColumnDefTemplate<CellContext<any, any>> = ({ getValue, row }) => {
    const obj = row.original;
    const value = getValue();
    const firstValue = Array.isArray(value) ? value[0] : value;
    const secondValue = Array.isArray(value) ? value[1] : null;
    if (obj.value === 'limit_expiring' && value) return moment(value).format(appConfigs.formatDate);
    if (obj.value === 'value_usd')
      return (
        <div className="flex flex-col">
          <span className="whitespace-nowrap">
            {Array.isArray(value) ? <strong>CASH: </strong> : null}
            {Intl.NumberFormat('en', {
              style: 'currency',
              currency: 'USD',
            }).format(firstValue ?? 0)}
          </span>
          {Array.isArray(value) ? (
            <span className="whitespace-nowrap border-t border-gray-200 pt-2 mt-2">
              <strong>BARTER: </strong>
              {Intl.NumberFormat('en', {
                style: 'currency',
                currency: 'USD',
              }).format(secondValue ?? 0)}
            </span>
          ) : null}
        </div>
      );
    if (obj.value === 'available_limit' || obj.value === 'credit_limit')
      return (
        <div className="flex flex-col">
          <span className="whitespace-nowrap">
            {Intl.NumberFormat('pt-br', {
              style: 'currency',
              currency: 'BRL',
            }).format(value ?? 0)}
          </span>
        </div>
      );
    if (obj.value === 'document')
      return value.includes('.') ? value : value.length > 11 ? Formatter.cpf(value) : Formatter.cnpj(value);
    return value;
  };
  return (
    <div className="h-full flex flex-col overflow-hidden gap-2">
      <ExposuresFilter
        onFilter={values => {
          setSearchParams(values);
        }}
      />
      <DataTable
        fixedHeader
        onExpandedChange={expanded => {
          if (typeof expanded === 'function') setExpandedData(expanded(expandedData) as any);
        }}
        state={{
          grouping: ['bp'],
          expanded: expandedData,
        }}
        enableGrouping
        isLoading={query.isLoading}
        isFetching={query.isFetching}
        data={convertData(query.data ?? [])}
        columns={[
          columnHelper.group({
            id: 'bp',
            header: 'BP',
            columns: [
              columnHelper.accessor('bp', {
                header: () => (
                  <button
                    className="h-6 w-6 min-w-6 flex items-center justify-center border border-gray-200 bg-gray-50 rounded-lg"
                    type="button"
                    {...{
                      onClick: isAllExpanded ? closeAll : openAll,
                    }}
                  >
                    {isAllExpanded ? '-' : '+'}
                  </button>
                ),
                cell: ({ row, getValue, cell }) => {
                  return (
                    <div
                      className={cn(
                        'flex gap-2 items-center max-w-[550px]',
                        row.original.bp?.toUpperCase() === 'TOTAL' ? 'font-bold' : '',
                      )}
                    >
                      {row.getCanExpand() || cell.getIsGrouped() ? (
                        <button
                          className="p-1 rounded-md border-gray-200 bg-gray-50 border w-6 h-6 min-w-6 flex items-center justify-center"
                          type="button"
                          {...{
                            onClick: () => {
                              const handler = row.getToggleExpandedHandler();
                              handler();
                            },
                            style: { cursor: 'pointer' },
                          }}
                        >
                          {row.getIsExpanded() ? '-' : '+'}
                        </button>
                      ) : null}
                      {cell.getIsGrouped() &&
                        `${getValue()} ${row.original.document ? `- ${row.original.document}` : ''}`}
                    </div>
                  );
                },
              }),
              columnHelper.accessor('value', {
                header: 'Values',
                cell: ({ getValue }) => {
                  const value = getValue();
                  const label = (() => {
                    switch (value) {
                      case 'credit_limit':
                        return 'Credit Limit';
                      case 'limit_expiring':
                        return 'Limit Expiring';
                      case 'value_usd':
                        return 'Exposure';
                      case 'available_limit':
                        return 'Available Limit';
                      default:
                        return '';
                    }
                  })();
                  return <span className="whitespace-nowrap font-bold">{label}</span>;
                },
              }),
            ],
            getGroupingValue: row => `${row.bp} ${row.value}`,
          }),
          columnHelper.group({
            header: 'Prefin',
            columns: [
              columnHelper.accessor('prefin.soybean', {
                header: () => <span>Soybean</span>,
                aggregationFn: 'mean',
                aggregatedCell: () => ``,
                cell: cellRender,
              }),
              columnHelper.accessor('prefin.corn', {
                header: () => <span>Corn</span>,
                aggregatedCell: () => ``,
                cell: cellRender,
              }),
              columnHelper.accessor('prefin.cotton', {
                header: () => <span>Cotton</span>,
                aggregatedCell: () => ``,
                cell: cellRender,
              }),
              columnHelper.accessor('prefin.sugar', {
                header: () => <span>Sugar</span>,
                aggregatedCell: () => ``,
                cell: cellRender,
              }),
            ],
          }),
          columnHelper.group({
            header: 'Prepay',
            columns: [
              columnHelper.accessor('prepay.soybean_corn', {
                header: () => <span>Soybean/Corn</span>,
                aggregatedCell: () => ``,
                cell: cellRender,
              }),
              columnHelper.accessor('prepay.cotton', {
                header: () => <span>Cotton</span>,
                aggregatedCell: () => ``,
                cell: cellRender,
              }),
              columnHelper.accessor('prepay.fertilizers', {
                header: () => <span>Fertilizantes</span>,
                aggregatedCell: () => ``,
                cell: cellRender,
              }),
            ],
          }),
          columnHelper.group({
            header: 'AR',
            columns: [
              columnHelper.accessor('ar', {
                header: () => '',
                aggregatedCell: () => ``,
                cell: cellRender,
              }),
            ],
          }),
          columnHelper.group({
            header: 'MTM',
            columns: [
              columnHelper.accessor('mtm', {
                header: () => '',
                aggregatedCell: () => ``,
                cell: cellRender,
              }),
            ],
          }),
        ]}
      />
    </div>
  );
};

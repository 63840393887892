import React from 'react';
import { IconType } from 'react-icons';

import { Tooltip } from 'antd';

import { AttachmentIconWrapper } from '../../AttachmentIconWrapper';

interface Props {
  icon: IconType;
  title: string;
  action: () => void;
}

export const ActionButton = ({ icon: Icon, title, action }: Props) => {
  return (
    <Tooltip title={title}>
      <span>
        <AttachmentIconWrapper onClick={action}>
          <Icon />
        </AttachmentIconWrapper>
      </span>
    </Tooltip>
  );
};

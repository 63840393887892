import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FaThLarge, FaThList, FaSearch, FaPlus } from 'react-icons/fa';
import { useParams, Link } from 'react-router-dom';

import { Form as AntForm, Input, InputNumber } from 'antd';
import PermissionedComponent from 'components/PermissionedComponent';
import { Button } from 'components/ui/button';
import { CreditRequestContextProvider } from 'context/CreditRequestContext';
// import { useModal } from 'hooks/modals/useModal';

import { Can } from 'hooks/ability';

import { FormItem, Select, Spin } from '../../components';
// import ReportModal from '../../compositions/ReportModal';
import { divisions, subDivisions } from '../../configs/divisions';
import { useAuth } from '../../hooks/auth';
import { useCache } from '../../hooks/cache';
import { PageModuleConfig, pageConfigDefault } from '../../types/User/UserData';
// import { CreditRequestFilterMenu } from './components/CreditRequestFilterMenu';
import CreditRequestReport from './components/molecules/CreditRequestReport';
import DetailView from './components/templates/DetailView';
import KanbanView from './components/templates/kanban-view';
import ListView from './components/templates/list-view';
import { Divisions, SubDivisions } from './store/slices/creditRequestSlices/divisionSlice';
import { useCreditRequestStore } from './store/useCreditRequestStore';
import { Filter, PageLoadings, PageParams, FormFields } from './types';

const CreditRequest: React.FC = () => {
  const params = useParams<PageParams>();
  const { permission: permissionParam, id: idParam } = params;
  const { t, i18n } = useTranslation();
  const [formFilter] = AntForm.useForm();

  const {
    user: { config: userConfig },
    updateUserConfig,
  } = useAuth();
  const { current_harvest, getCreditOptions } = useCache();

  const setSelectedDivisionId = useCreditRequestStore(state => state.setSelectedDivisionId);
  const selectedDivisionId = useCreditRequestStore(state => state.selectedDivisionId);

  const [isKanban, setIsKanban] = useState(false);

  const [filterValues, setFilterValues] = useState<Filter>();
  const [loadingField, setLoadingField] = useState<string>(null);
  const [loading, setLoading] = useState<PageLoadings>(() => ({
    list: false,
    form: false,
    filter: false,
  }));
  const [options, setOptions] = useState(() => ({
    harvest: [],
    division: [],
    subdivision: [],
    operation: [],
    team_status: [],
  }));

  const [pageConfigs, setPageConfigs] = useState<PageModuleConfig>(() => {
    const configs = userConfig.modules.credit?.pageView;

    if (!configs) {
      const personKanbanDefault: PageModuleConfig = {} as PageModuleConfig;
      personKanbanDefault.credit = pageConfigDefault;
      return personKanbanDefault as PageModuleConfig;
    }

    return configs;
  });

  // const handleToggleAdvancedFilter = () => {
  //   if (isAdvancedFilterOpen) {
  //     closeAdvancedFilter();
  //   } else {
  //     openAdvancedFilter();
  //   }
  // };

  useEffect(() => {
    setOptions({
      harvest: getCreditOptions('harvest'),
      division: getCreditOptions('division'),
      subdivision: getCreditOptions('sub_division'),
      operation: getCreditOptions('operation'),
      team_status: getCreditOptions('team_credit_status'),
    });

    if (pageConfigs && pageConfigs.credit) {
      setIsKanban(pageConfigs.credit === 'kanban');
    }
  }, [pageConfigs, getCreditOptions, i18n.language]);

  const loadSubDivisions = useCallback(
    (divisionId: Divisions) => {
      setLoadingField('sub_division');
      let listOfSubDivisions = getCreditOptions('sub_division');
      if (divisions[divisionId]) {
        listOfSubDivisions = getCreditOptions('sub_division').filter(s => divisions[divisionId].includes(s.value));
      }
      setOptions(stateOptions => ({ ...stateOptions, subdivision: listOfSubDivisions }));
      setLoadingField(null);
    },
    [getCreditOptions, i18n.language, pageConfigs],
  );

  const loadOperations = useCallback(
    (subDivisionId: SubDivisions) => {
      setLoadingField('operation');
      let listOfOperations = getCreditOptions('operation');
      if (subDivisions[subDivisionId]) {
        listOfOperations = getCreditOptions('operation').filter(s => subDivisions[subDivisionId].includes(s.value));
      }
      setOptions(stateOptions => ({ ...stateOptions, operation: listOfOperations }));
      setLoadingField(null);
    },
    [getCreditOptions],
  );

  const toogleView = useCallback(
    (event: any, mode: 'kanban' | 'table') => {
      setIsKanban(mode === 'kanban');
      const updateConfigs: PageModuleConfig = {
        ...pageConfigs,
        credit: mode,
      };

      setPageConfigs(updateConfigs);
      userConfig.modules.credit.pageView = updateConfigs;
      updateUserConfig(userConfig);
    },
    [setIsKanban, setPageConfigs, pageConfigs, updateUserConfig, userConfig],
  );

  const handleFilter = useCallback(
    (values: FormFields) => {
      setLoading({ ...loading, filter: true });

      const filters: any = {};
      Object.keys(values).forEach((value: string) => {
        if (value === 'shouldSaveConfig') return;
        try {
          const val = values[value];
          if (typeof val === 'string' && val.includes(',')) {
            filters[value] = val.split(',');
            return;
          }
          filters[value] = val;
        } catch (e) {
          console.log('Error', e, value);
        }
      });

      if (values.shouldSaveConfig) {
        updateUserConfig({
          ...userConfig,
          modules: {
            ...userConfig.modules,
            credit: {
              ...userConfig.modules.credit,
              filters: {
                ...userConfig.modules.credit.filters,
                ...filters,
              },
            },
          },
        });
      }

      setFilterValues(filters as Filter);
      setLoading({ ...loading, filter: false });
    },
    [loading],
  );

  // const handleClearFilter = () => {
  //   formFilter.resetFields();
  //   handleFilter(formFilter.getFieldsValue() as FormFields);
  // };

  // Inicializa a tela - chamar sem dependências para não entrar em loop
  useEffect(() => {
    handleFilter(formFilter.getFieldsValue() as FormFields);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (selectedDivisionId) loadSubDivisions(selectedDivisionId);
  }, [selectedDivisionId, i18n.language, loadSubDivisions, pageConfigs]);

  useEffect(() => {
    if (userConfig) {
      formFilter.setFieldsValue({
        ...Object.keys(userConfig.modules.credit.filters ?? {}).reduce((acc, key) => {
          if (key === 'shouldSaveConfig') return acc;
          if (userConfig.modules.credit.filters[key]) (acc as any)[key] = userConfig.modules.credit.filters[key];
          return acc;
        }, {}),
        shouldSaveConfig: false,
      });
      setFilterValues(userConfig.modules.credit.filters as Filter);
    }
  }, []);

  return (
    <div className="flex flex-col gap-2 h-full w-full overflow-hidden">
      {permissionParam || (permissionParam && idParam) ? (
        <CreditRequestContextProvider>
          <DetailView creditRequestId={idParam} />
        </CreditRequestContextProvider>
      ) : (
        <>
          <section className="flex justify-end items-center gap-2">
            <CreditRequestReport />
            <Can I="credit.request.kanban.index">
              <Button variant={isKanban ? 'default' : 'secondary'} onClick={e => toogleView(e, 'kanban')}>
                <FaThLarge />
              </Button>
            </Can>
            <Button variant={!isKanban ? 'default' : 'secondary'} onClick={e => toogleView(e, 'table')}>
              <FaThList />
            </Button>
            <Button variant="default">
              <Link to="/credit-request/new">
                <div className="flex items-center gap-1 text-white font-bold">
                  <FaPlus /> {t('pages.credit-request.table.icons.addCreditRequest')}
                </div>
              </Link>
            </Button>
          </section>

          <Spin spinning={loading.filter}>
            <AntForm
              form={formFilter}
              name="filter-credit-request"
              className="form-basic-filter"
              initialValues={{ harvest: current_harvest }}
              onFinish={handleFilter}
            >
              <div className="filter-search">
                <FormItem name="search" label={t('pages.credit-request.filters.search')}>
                  <Input placeholder={t('pages.credit-request.filters.search-placeholder')} />
                </FormItem>
              </div>
              <div className="filter-number">
                <FormItem name="number" label={t('pages.credit-request.filters.number')}>
                  <InputNumber precision={0} min={0} maxLength={9} />
                </FormItem>
              </div>
              <div>
                <FormItem name="harvest" label={t('pages.credit-request.filters.harvest_id')}>
                  <Select options={options.harvest} mode="multiple" />
                </FormItem>
              </div>
              <div>
                <FormItem name="division" label={t('pages.credit-request.filters.division_id')}>
                  <Select
                    options={options.division}
                    onClear={() => {
                      setSelectedDivisionId(null);
                    }}
                    onChange={(value: any, option: any) => {
                      formFilter.setFieldsValue({});
                      if (option) setSelectedDivisionId(option.value);
                    }}
                  />
                </FormItem>
              </div>
              <div>
                <FormItem name="subdivision" label={t('pages.credit-request.filters.sub_division_id')}>
                  <Select
                    options={options.subdivision}
                    disabled={loadingField === 'sub_division' || !formFilter.getFieldValue('division')}
                    loading={loadingField === 'sub_division'}
                    onChange={(value: any, option: any) => {
                      formFilter.setFieldsValue({});
                      loadOperations(option.slug);
                    }}
                  />
                </FormItem>
              </div>
              <div>
                <FormItem name="operation" label={t('pages.credit-request.filters.operation_id')}>
                  <Select
                    options={options.operation}
                    disabled={loadingField === 'operation' || !formFilter.getFieldValue('subdivision')}
                    loading={loadingField === 'operation'}
                  />
                </FormItem>
              </div>
              <Button variant="default" className="uppercase font-bold flex items-center gap-2" type="submit">
                <FaSearch />
                <span>{t('form.actions.search')}</span>
              </Button>
            </AntForm>
          </Spin>

          {isKanban ? (
            <section className="kanban-view">
              <PermissionedComponent
                permission={['credit.request.kanban.index', 'credit.request.kanban.show']}
                className="h-full"
                needAllPermissions
                description={t('permission.message.credit.request.kanban.index')}
              >
                <KanbanView filterData={filterValues} />
              </PermissionedComponent>
            </section>
          ) : (
            <section className="h-full overflow-hidden">
              <ListView filterData={filterValues} />
            </section>
          )}
        </>
      )}
    </div>
  );
};

export default CreditRequest;

import { ResponseType } from 'axios';

import DocumentTypeData from '../../@types/data/DocumentTypeData';
import { ResponseListData } from '../../@types/data/ResponseData';
import api from './api';

export default {
  getById: async (id: string, params: object = null): Promise<any> => {
    return api.get(`document/${id}`, params);
  },

  getAll: async (params: object = null): Promise<any> => {
    return api.get('document', params);
  },

  download: async (id: string, params: object = null, responseType: ResponseType = 'blob'): Promise<any> => {
    return api.get(`document/download/${id}`, {
      params: {
        params,
      },
      responseType,
    });
  },
  downloadMultiple: async (id: string[], params: object = null): Promise<any> => {
    return api.get(`document/download`, {
      params: {
        params,
        attachment_id: id,
      },
      responseType: 'blob',
    });
  },

  downloadmultiplefiles: async (params: object = null): Promise<any> => {
    return api.get('document/download', params);
  },

  store: async (params: object): Promise<any> => {
    return api.post('document', params);
  },

  delete: async (id: string, params: object = null): Promise<any> => {
    return api.delete(`document/${id}`, params);
  },

  documentType: async (params: object = null): Promise<ResponseListData<DocumentTypeData>> => {
    return api.get('document/types', params);
  },

  getByStatus: async (id: string, params: object = null): Promise<any> => {
    return api.get(`document/getbystatus/${id}`, params);
  },
};

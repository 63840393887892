import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { MoneyInput } from '@agrodatabr/masked-inputs';
import { Col, DatePicker, Form, InputNumber, Row } from 'antd';
import ptBR from 'antd/es/date-picker/locale/pt_BR';
import { Button, FormItem, Spin } from 'components';
import { OPERATION_PREPAY_ID } from 'configs/constants';
import { useCreditRequestContext } from 'context/CreditRequestContext';
import { Can } from 'hooks/ability';
import { useCache } from 'hooks/cache';
import { useCreditRequest } from 'hooks/fetch/useCreditRequest';
import moment from 'moment';
import { useCreditRequestStore } from 'pages/CreditRequest/store/useCreditRequestStore';
import { Formatter } from 'utils/Formatter';

import type { AmortizationData } from '../../../../../@types/data/AmortizationData';
import appConfigs from '../../../../../configs/app';

export const Amortization = () => {
  const { t } = useTranslation();
  const { updateAmortization, isUpdatingAmortization } = useCreditRequest();
  const { operation } = useCache();
  const { creditForm, amortizationForm } = useCreditRequestContext();

  const teamStatusChangeTeamStatusCurrentId = useCreditRequestStore(state => state.teamStatusChangeTeamStatusCurrentId);
  const pageStatus = useCreditRequestStore(state => state.pageStatus);
  const creditRequestId = useCreditRequestStore(state => state.creditRequestId);

  const [oldAmortizationData, setOldAmortizationData] = useState<AmortizationData>({
    liquidated_advance: 0,
    liquidation_date: '',
    delivered_volume: 0,
  } as AmortizationData);

  const [disabled, setDisabled] = useState(true);

  const isEnabled = useMemo(() => {
    return operation
      .find(({ id }) => id === OPERATION_PREPAY_ID)
      ?.finished_status?.includes(teamStatusChangeTeamStatusCurrentId);
  }, [operation, teamStatusChangeTeamStatusCurrentId]);

  return (
    <Can I="credit.request.amortization.index" a="">
      <Spin spinning={isUpdatingAmortization}>
        <div className="group-form">
          <h2>{t('pages.credit-request.form.titles.amortization')}</h2>
          {!pageStatus.new && isEnabled && (
            <Can I="credit.request.amortization.edit" a="">
              <div style={{ display: 'flex', gap: '4px', width: '100%', justifyContent: 'end' }}>
                {!disabled ? (
                  <>
                    <div style={{ display: 'flex', justifyContent: 'end', padding: 10, gap: 4 }}>
                      <Button
                        status="secondary-outline"
                        onClick={() => {
                          setDisabled(!disabled);
                          amortizationForm.setFieldsValue({
                            liquidated_advance: oldAmortizationData.liquidated_advance,
                            liquidation_date: oldAmortizationData.liquidation_date,
                            delivered_volume: oldAmortizationData.delivered_volume,
                          });
                        }}
                      >
                        {t('cancel')}
                      </Button>
                      <Button
                        permission="credit.request.updateamortization"
                        status="primary"
                        onClick={async () => {
                          amortizationForm.validateFields().then(async () => {
                            const data = {
                              liquidated_advance: amortizationForm.getFieldValue('liquidated_advance'),
                              liquidation_date: Formatter.apiDate(amortizationForm.getFieldValue('liquidation_date')),
                              delivered_volume: amortizationForm.getFieldValue('delivered_volume'),
                            };
                            const response = await updateAmortization(creditRequestId, data);

                            if (response) {
                              setOldAmortizationData(data);
                              setDisabled(true);
                            }
                          });
                        }}
                      >
                        {t('pages.credit-request.depositary.buttons.saveAmortization')}
                      </Button>
                    </div>
                  </>
                ) : (
                  <div style={{ display: 'flex', justifyContent: 'end', padding: 10 }}>
                    <Button
                      status="primary-outline"
                      onClick={() => {
                        setOldAmortizationData({
                          liquidated_advance: amortizationForm.getFieldValue('liquidated_advance'),
                          liquidation_date: amortizationForm.getFieldValue('liquidation_date'),
                          delivered_volume: amortizationForm.getFieldValue('delivered_volume'),
                        });
                        setDisabled(!disabled);
                      }}
                    >
                      {t('pages.credit-request.depositary.buttons.editAmortization')}
                    </Button>
                  </div>
                )}
              </div>
            </Can>
          )}
          <Form form={amortizationForm} component={false}>
            <Row style={{ display: 'flex' }}>
              <Col xs={24} sm={18} md={8} lg={8} xl={8} xxl={8}>
                <FormItem name="liquidated_advance" label={t('pages.credit-request.form.amortized_advance')}>
                  <MoneyInput disabled={disabled} />
                </FormItem>
              </Col>
              <Col xs={24} sm={18} md={8} lg={8} xl={8} xxl={8}>
                <FormItem
                  name="delivered_volume"
                  label={t('pages.credit-request.form.received_volume')}
                  rules={[
                    {
                      validator: async (_, value) => {
                        const formValues = creditForm.getFieldsValue();
                        const quantity = Number(formValues['json_data.quantity_kg']);

                        if (Number(value) <= quantity) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(t('pages.credit-request.messages.contract_received_volume_exceeded')),
                        );
                      },
                    },
                  ]}
                >
                  <InputNumber<string>
                    min="0"
                    formatter={(value: string | number) => Formatter.integer(value)}
                    parser={(value: string) => Formatter.integerParser(value)}
                    disabled={disabled}
                  />
                </FormItem>
              </Col>
              <Col xs={24} sm={18} md={8} lg={8} xl={8} xxl={8}>
                <FormItem name="liquidation_date" label={t('pages.credit-request.form.amortization_date')}>
                  <DatePicker
                    locale={ptBR}
                    mode="date"
                    format={appConfigs.formatDate}
                    allowClear
                    disabled={disabled}
                    disabledDate={current => {
                      return moment(current) >= moment();
                    }}
                  />
                </FormItem>
              </Col>
            </Row>
          </Form>
        </div>
      </Spin>
    </Can>
  );
};

import { useCreditRequestContext } from 'context/CreditRequestContext';

export const useFillProviderDataPrepay = () => {
  const { creditForm } = useCreditRequestContext();

  const fillProviderDataPrepay = (fieldsOverwrideValue: any) => {
    creditForm.setFieldsValue({
      ...fieldsOverwrideValue,
      'json_data.opting_inss': 0,
    });
  };

  return { fillProviderDataPrepay };
};

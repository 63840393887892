import React, { forwardRef, useEffect, useImperativeHandle } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ComboBox } from 'components/ui/combo';
import { DatePicker } from 'components/ui/date-picker';
import { Form } from 'components/ui/form';
import { FormGrid } from 'components/ui/form-grid';
import { FormItem } from 'components/ui/form-item';
import { Input, InputNumber, InputWithMask } from 'components/ui/input';
import { isAfter } from 'date-fns';
import { Converter } from 'utils/Converter';
import { Validate } from 'utils/Validate';

import { useRegisteredCustomerStore } from '../stores/use-registered-customer-store';

type Props = {
  onSuccess?: (data: PartnerFormData) => void;
};

export type PartnerFormData = {
  id?: string;
  doc_number?: string;
  name?: string;
  person_type?: string;
  age?: number;
  birth_date?: string;
  json_data?: {
    person_type?: string;
  };
};

export const partnerFormInitialValues = {
  doc_number: '',
  id: '',
  name: '',
  age: 0,
  birth_date: '',
  person_type: 'physical',
  json_data: {
    person_type: 'physical',
  },
};

export const PartnerForm = forwardRef(({ onSuccess }: Props, ref) => {
  const { t } = useTranslation();

  const form = useForm<PartnerFormData>({
    defaultValues: partnerFormInitialValues,
    mode: 'onBlur',
  });

  const isViewing = useRegisteredCustomerStore(state => state.isViewing);
  const isModulePerson = useRegisteredCustomerStore(state => state.isModulePerson);
  const partnerToEdit = useRegisteredCustomerStore(state => state.partnerToEdit);

  const personType = form.watch('person_type');
  const isPhysicalPerson = personType === 'physical';

  const sendForm = (values: PartnerFormData) => {
    onSuccess?.(values);
  };

  const submit = () => {
    form.handleSubmit(sendForm, console.log)();
  };

  useImperativeHandle(ref, () => ({
    form: form as UseFormReturn<PartnerFormData>,
    submit,
  }));

  useEffect(() => {
    form.reset(partnerToEdit || partnerFormInitialValues);
  }, [partnerToEdit]);

  return (
    <Form form={form} onSubmit={form.handleSubmit(sendForm)}>
      <FormGrid maxCols={2}>
        <FormItem label={t('pages.registered-customers.form.pj.partner.name')} name="name">
          <Input />
        </FormItem>

        <FormItem label={t('pages.registered-customers.form.pj.partner.person_type')} name="person_type">
          <ComboBox
            options={[
              {
                key: 'physical',
                value: 'physical',
                label: 'Pessoa Física',
              },
              {
                key: 'legal',
                value: 'legal',
                label: 'Pessoa Jurídica',
              },
            ]}
            onChange={value => {
              if (value) form.setValue('doc_number', '');
            }}
          />
        </FormItem>

        <FormItem
          label={
            !isPhysicalPerson
              ? t('pages.registered-customers.form.pj.partner.doc_number.cnpj')
              : t('pages.registered-customers.form.pj.partner.doc_number.cpf')
          }
          rules={{
            validate: {
              isDocInvalid: value => {
                if (isPhysicalPerson ? !Validate.cpf(value) : !Validate.cnpj(value)) return `${t('invalid-document')}`;
                return true;
              },
            },
          }}
          name="doc_number"
        >
          <InputWithMask
            name="doc_number"
            mask={isPhysicalPerson ? '999.999.999-99' : '99.999.999/9999-99'}
            placeholder="__.___.___/____-__"
          />
        </FormItem>

        <FormItem label={t('pages.registered-customers.form.pj.partner.date_of_birth')} name="birth_date">
          <DatePicker
            className="w-full"
            disabledDates={day => isAfter(day, new Date())}
            onDayClick={value => form.setValue('age', Number(Converter.dateToAge(value)))}
            disabled={isViewing || !isModulePerson}
          />
        </FormItem>

        <FormItem label={t('pages.registered-customers.form.pj.partner.age')} name="age">
          <Input disabled />
        </FormItem>

        <FormItem label={t('pages.registered-customers.form.pj.partner.equity_percentage')} name="equity_percentage">
          <InputNumber min={0} max={100} suffix="%" />
        </FormItem>
      </FormGrid>
    </Form>
  );
});

PartnerForm.displayName = 'PartnerForm';

import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  STATE_GO,
  OPERATION_PREPAY_ID,
  OPERATION_PREFIN_ID,
  OPERATION_FERTILIZER_SUPPLIERS_ID,
  SUBDIVISION_SUGAR_MILLS_ID,
} from 'configs/constants';
import { useCreditRequestContext } from 'context/CreditRequestContext';
import { addBusinessDays } from 'date-fns';
import { useCreditStatus } from 'hooks/creditStatus';
import moment from 'moment';
import { Formatter } from 'utils/Formatter';

import DTOCreditOperation from '../../../@types/dtos/credit-request/DTOCreditOperation';
import { useIsFethabAttachmentRequired } from '../rules/general/useIsFethabAttachmentRequired';
import { useCreditRequestStore } from '../store/useCreditRequestStore';
import { useGetFileTypesFromCache } from './useGetFileTypesFromCache';
import { useHandleSubmit } from './useHandleSubmit';

export const useValidateSubmit = () => {
  const {
    fileTypeInss,
    fileTypeFethab,
    fileTypeContract,
    fileTypeDepositary,
    fileTypeStatementOrInjunction,
    fileTypeMonsantoReceipt,
    fileTypeAssigneeCredit,
  } = useGetFileTypesFromCache();
  const { getOperation } = useCreditStatus();
  const { setFormStatus, setFormErrors, addFormErrors, creditForm } = useCreditRequestContext();
  const { handleSubmit } = useHandleSubmit();
  const { t } = useTranslation();

  const pageStatus = useCreditRequestStore(state => state.pageStatus);
  const selectedOperationId = useCreditRequestStore(state => state.selectedOperationId);
  const selectedSubDivisionId = useCreditRequestStore(state => state.selectedSubDivisionId);
  const documentList = useCreditRequestStore(state => state.documentList);
  const depositaryList = useCreditRequestStore(state => state.depositaryList);
  const isFundeinfra = useCreditRequestStore(state => state.isFundeinfra);
  const isFixationQuantityInsufficient = useCreditRequestStore(state => state.isFixationQuantityInsufficient);
  const setIsLoading = useCreditRequestStore(state => state.setIsLoading);

  const { checkIsFethabAttachmentRequired } = useIsFethabAttachmentRequired();

  const [finalizing, setFinalizing] = useState(false);
  const [messagesErrors, setMessagesErrors] = useState<any>({
    division: null,
    operation: null,
    other: null,
    sub_division: null,
  });

  const getEndDeadlinePrepay = (values: any) => {
    const cadency = values['json_data.warehouse_shipping_capacity'];
    const quantity = values['json_data.quantity_kg'];
    const startWithdrawalDeadline = moment(values.start_withdrawal_deadline);
    const endDeadline = addBusinessDays(
      moment(startWithdrawalDeadline)
        .add(quantity / 1000 / cadency, 'days')
        .toString(),
      4,
    );

    return endDeadline;
  };

  const checkIsMissingPrepayAttachments = (values: any) => {
    let formInvalid = false;
    const optingInss = Boolean(values['json_data.opting_inss']);
    const stateIdSelected = values.state_id;
    const fethabRequired = checkIsFethabAttachmentRequired(stateIdSelected, values['json_data.fethab']);

    let countInssAttachments = 0;
    let countFethabMTAttachments = 0;

    documentList.forEach(file => {
      if (fileTypeInss !== undefined && file.type_id === fileTypeInss?.value) {
        countInssAttachments = +1;
      }
      if (fethabRequired && file.type_id === fileTypeFethab?.value) {
        countFethabMTAttachments = +1;
      }
    });

    if (optingInss && countInssAttachments === 0) {
      setFormStatus('danger');
      addFormErrors('opting_inss_need_one_at_least_attachment');
      formInvalid = true;
    }

    if (fethabRequired && countFethabMTAttachments === 0) {
      setFormStatus('danger');
      addFormErrors('fethab_mt_need_one_at_least_attachment');
      formInvalid = true;
    }

    return formInvalid;
  };

  const checkIsFixationQuantityInsufficient = () => {
    if (isFixationQuantityInsufficient) {
      setFormStatus('danger');
      addFormErrors('fixation_quantity_insufficient');
      return true;
    }

    return false;
  };

  const checkWillBeLatePrepay = (values: any) => {
    const periodToDeliverDays = moment(values['json_data.withdrawal_period'])
      .startOf('day')
      .diff(moment(values.start_withdrawal_deadline).startOf('day'), 'days');

    const daysToDeliver = Math.floor(
      values['json_data.quantity_kg'] &&
        values['json_data.warehouse_shipping_capacity'] &&
        Number(values['json_data.quantity_kg']) / 1000 / values['json_data.warehouse_shipping_capacity'],
    );

    if (daysToDeliver > periodToDeliverDays) {
      setFormStatus('danger');
      addFormErrors({ message: 'fix_cadency', period: periodToDeliverDays, days: daysToDeliver });
      return true;
    }

    return false;
  };

  const checkPrepayIsNotPrepay = (values: any) => {
    const expectedPaymentAt = values['json_data.expected_payment_at'];
    const cadency = values['json_data.warehouse_shipping_capacity'];
    const startWithdrawalDeadline = moment(values.start_withdrawal_deadline);
    const endDeadline = getEndDeadlinePrepay(values);

    if (endDeadline && expectedPaymentAt) {
      if (moment(endDeadline) < moment(expectedPaymentAt)) {
        setFormStatus('danger');
        addFormErrors({
          message: 'not_prepay',
          startWithdrawalDeadline: startWithdrawalDeadline ? Formatter.date(startWithdrawalDeadline).toString() : '',
          cadency,
          withdrawalPeriod: endDeadline ? Formatter.date(moment(endDeadline)).toString() : '',
          expectedPaymentAt: expectedPaymentAt ? Formatter.date(expectedPaymentAt).toString() : '',
        });
        return true;
      }
    }
    return false;
  };

  const checkIsMissingAttachments = (values: any) => {
    let countContractAttachments = 0;
    let countMonsantoAttachments = 0;
    let countDepositaryAttachments = 0;
    let countAttachments = 0;
    let countStatementOrInjunctionAttachments = 0;
    let countAssigneeAttachments = 0;

    let formInvalid = false;

    documentList.forEach(file => {
      if (file.type_id === fileTypeContract?.value) {
        countContractAttachments = +1;
      }
      if (file.type_id === fileTypeDepositary?.value) {
        countDepositaryAttachments = +1;
      }
      if (file.type_id === fileTypeStatementOrInjunction?.value) {
        countStatementOrInjunctionAttachments = +1;
      }
      if (file.type_id === fileTypeMonsantoReceipt?.value) {
        countMonsantoAttachments = +1;
      }
      if (file.type_id === fileTypeAssigneeCredit?.value) {
        countAssigneeAttachments = +1;
      }
      countAttachments += 1;
    });

    if (depositaryList.length && countDepositaryAttachments === 0) {
      setFormStatus('danger');
      addFormErrors('depositary_is_required_attachment');
      formInvalid = true;
    }
    if (
      !isFundeinfra &&
      countStatementOrInjunctionAttachments === 0 &&
      selectedOperationId === OPERATION_PREFIN_ID &&
      values.state_id === STATE_GO
    ) {
      setFormStatus('danger');
      addFormErrors('statement_or_injunction_is_required_attachment');
      formInvalid = true;
    }
    if (
      countMonsantoAttachments === 0 &&
      (values['json_data.az_participant'] === 'az_participant' ||
        values['json_data.az_participant'] === 'have_credit') &&
      selectedOperationId === OPERATION_PREPAY_ID
    ) {
      setFormStatus('danger');
      addFormErrors('contract_monsanto_receipt_required');
      formInvalid = true;
    }

    if (
      countContractAttachments === 0 &&
      selectedOperationId !== OPERATION_FERTILIZER_SUPPLIERS_ID &&
      selectedSubDivisionId !== SUBDIVISION_SUGAR_MILLS_ID
    ) {
      setFormStatus('danger');
      addFormErrors('contract_is_required_attachment');
      formInvalid = true;
    }

    if (
      selectedOperationId === OPERATION_PREPAY_ID &&
      creditForm.getFieldValue('has_assignees') === 1 &&
      countAssigneeAttachments < 1
    ) {
      setFormStatus('danger');
      addFormErrors('assignee_is_required_attachment');
      formInvalid = true;
    }

    if (
      countAttachments === 0 &&
      (selectedOperationId === OPERATION_FERTILIZER_SUPPLIERS_ID ||
        selectedSubDivisionId === SUBDIVISION_SUGAR_MILLS_ID)
    ) {
      setFormStatus('danger');
      addFormErrors('fertilizer_contract_is_required_attachment');
      formInvalid = true;
    }

    return formInvalid;
  };

  const checkEndDeadlineExceeds120Prepay = (values: any) => {
    const endDeadline = getEndDeadlinePrepay(values);
    const startDeadline = values.start_withdrawal_deadline;
    const days = moment(endDeadline).diff(moment(startDeadline), 'days');

    if (days > 120) {
      setFormStatus('danger');
      addFormErrors('end_deadline_exceeds_120_days');
      return true;
    }

    return false;
  };

  async function validateSubmit() {
    setIsLoading(true);
    setFinalizing(true);
    setFormStatus(null);
    setFormErrors([]);

    let rulesToValidate: boolean[] = [];
    const formValues = creditForm.getFieldsValue();
    if (pageStatus.new) {
      rulesToValidate.push(checkIsMissingAttachments(formValues));
    }

    if (selectedOperationId === OPERATION_PREPAY_ID) {
      rulesToValidate = [
        ...rulesToValidate,
        checkIsMissingPrepayAttachments(formValues),
        checkIsFixationQuantityInsufficient(),
        checkWillBeLatePrepay(formValues),
        checkPrepayIsNotPrepay(formValues),
        checkEndDeadlineExceeds120Prepay(formValues),
      ];
    }

    await creditForm
      .validateFields()
      .then(async () => {
        if (!rulesToValidate.some(rule => rule)) {
          if (pageStatus.new) {
            // Se as validações deram certo, aplicar status padrão para solicitações de crédito
            const selectedOperationData = getOperation(selectedOperationId) as DTOCreditOperation;

            if (selectedOperationData) {
              creditForm.setFieldsValue({ team_status_id: selectedOperationData.team_credit_status_id });
              await handleSubmit();
            }
          } else {
            await handleSubmit();
          }
        }
      })
      .catch((err: any) => {
        console.error('error', err);
        const formErros = creditForm.getFieldsError();
        const formfilterErrors = formErros.filter(e => e.errors.length > 0);
        if (formfilterErrors.length > 0) {
          setFormStatus('danger');
          setMessagesErrors((errors: any) => ({
            ...errors,
            other: t('pages.credit-request.errors.other'),
          }));
        }
      });

    setIsLoading(false);
    setFinalizing(false);
  }
  return { validateSubmit, messagesErrors, finalizing };
};

import React from 'react';

export const Button = ({
  children,
  ...props
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLButtonElement>, HTMLButtonElement>) => {
  const styles = {
    container: {
      padding: '10px',
      borderRadius: '15px',
      background: 'black',
      color: 'white',
      width: '50px',
      height: '50px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      fontWeight: 'bold',
      border: 'none',
    },
  } as { [name: string]: React.CSSProperties };
  return (
    <button type="button" {...props} style={styles.container}>
      {children}
    </button>
  );
};

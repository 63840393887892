import { CreditRequestPackageData } from 'types/CreditRequest/CreditRequestPackageData';
import DraftData from 'types/CreditRequest/DraftData';
import { create, StateCreator } from 'zustand';

type FormalizationGuaranteesSlice = {
  creditRequestId: string | null;
  setCreditRequestId: (creditRequestId: string | null) => void;
  requesterPersonId: string | null;
  setRequesterPersonId: (requesterPersonId: string | null) => void;
  phase: number | null;
  setPhase: (phase: number | null) => void;
  reset: () => void;
  // Edição
  selectedPackage: CreditRequestPackageData | null;
  setSelectedPackage: (selectedPackage: CreditRequestPackageData) => void;
  selectedPackageItemId: string | null;
  setSelectedPackageItemId: (document: string | null) => void;
  selectedDraft: DraftData | null;
  setSelectedDraft: (selectedDraft: DraftData | null) => void;
  // PackageModal
  isPackageModalOpen: boolean;
  setIsPackageModalOpen: (isPackageModalOpen: boolean) => void;
  closePackageModal: () => void;
  openPackageModal: () => void;
  // DraftModal
  isDraftModalOpen: boolean;
  setIsDraftModalOpen: (isDraftModalOpen: boolean) => void;
  closeDraftModal: () => void;
  openDraftModal: () => void;
};

const formalizationGuaranteesSliceInitialState = {
  phase: null,
  requesterPersonId: null,
  selectedPackage: null,
  selectedPackageItemId: null,
  isPackageModalOpen: false,
  isDraftModalOpen: false,
  creditRequestId: null,
  selectedDraft: null,
} as FormalizationGuaranteesSlice;

const createFormalizationGuaranteesSlice: StateCreator<
  FormalizationGuaranteesSlice,
  [],
  [],
  FormalizationGuaranteesSlice
> = set => ({
  ...formalizationGuaranteesSliceInitialState,
  setCreditRequestId: creditRequestId => set({ creditRequestId }),
  setSelectedPackage: selectedPackage => set({ selectedPackage }),
  setSelectedPackageItemId: selectedPackageItemId => set({ selectedPackageItemId }),
  setIsPackageModalOpen: isPackageModalOpen => set({ isPackageModalOpen }),
  closePackageModal: () => set({ isPackageModalOpen: false }),
  openPackageModal: () => set({ isPackageModalOpen: true }),
  setPhase: phase => set({ phase }),
  setRequesterPersonId: requesterPersonId => set({ requesterPersonId }),
  setIsDraftModalOpen: isDraftModalOpen => set({ isDraftModalOpen }),
  closeDraftModal: () => set({ isDraftModalOpen: false }),
  openDraftModal: () => set({ isDraftModalOpen: true }),
  setSelectedDraft: selectedDraft => set({ selectedDraft }),
  reset: () => set({ ...formalizationGuaranteesSliceInitialState }),
});

export const useFormalizationGuaranteesStore = create<FormalizationGuaranteesSlice>()((...a) => ({
  ...createFormalizationGuaranteesSlice(...a),
}));

import { useCallback } from 'react';

import { SelectOptionType } from 'components/AllSelect';
import {
  OPERATION_DELIVERED_PRODUCT_ADVANCE_ID,
  OPERATION_PREFIN_SUGAR_MILLS_ID,
  TEAM_STATUS_ADVANCE_APPROVING_GR_COMMERCIAL,
  TEAM_STATUS_ADVANCE_PROGRAMMING_DATASUL_COMMERCIAL_REQUEST,
  TEAM_STATUS_CONTRACT_ADVANCE_PROGAMMING,
  TEAM_STATUS_CONTRACT_MANUAL_ADVANCE_PROGAMMING,
  TEAM_STATUS_ENABLED_LIMIT_CONTRACT,
  TEAM_STATUS_ENABLED_LIMIT_CREDIT_SUGAR_MILLS_REQUEST,
  TEAM_STATUS_IN_ANALYSIS_PREFIN_REQUEST,
  TEAM_STATUS_LIMIT_ENABLING_CREDIT,
  TEAM_STATUS_TTP_SIGNED_IN_ANALYSIS_CONTRACT,
} from 'configs/constants';

type FilterListOfNextTeamStatusProps = {
  listOfTeamStatus: SelectOptionType[];
  team_status_id: string;
  datasource: string;
  has_assignees: boolean;
  approved_value?: number;
  operation?: string;
};

export const useFilterListOfNextTeamStatus = () => {
  const filterListOfNextTeamStatus = useCallback(
    ({
      listOfTeamStatus,
      team_status_id,
      datasource,
      has_assignees,
      approved_value,
      operation,
    }: FilterListOfNextTeamStatusProps) => {
      let filteredListOfTeamStatus = [...listOfTeamStatus];

      if (datasource?.toLowerCase() === 'sap') {
        if (
          team_status_id === TEAM_STATUS_ENABLED_LIMIT_CONTRACT &&
          operation === OPERATION_DELIVERED_PRODUCT_ADVANCE_ID
        ) {
          filteredListOfTeamStatus = filteredListOfTeamStatus.filter(
            status =>
              status.key === TEAM_STATUS_CONTRACT_MANUAL_ADVANCE_PROGAMMING ||
              status.key === TEAM_STATUS_LIMIT_ENABLING_CREDIT,
          );
        }

        if (team_status_id === TEAM_STATUS_CONTRACT_MANUAL_ADVANCE_PROGAMMING)
          filteredListOfTeamStatus = filteredListOfTeamStatus.filter(
            status => status.key !== TEAM_STATUS_ADVANCE_APPROVING_GR_COMMERCIAL,
          );

        if (!has_assignees)
          filteredListOfTeamStatus = filteredListOfTeamStatus.filter(
            status => status.key !== TEAM_STATUS_CONTRACT_MANUAL_ADVANCE_PROGAMMING,
          );
        else
          filteredListOfTeamStatus = filteredListOfTeamStatus.filter(
            status => status.key !== TEAM_STATUS_CONTRACT_ADVANCE_PROGAMMING,
          );

        filteredListOfTeamStatus = filteredListOfTeamStatus.filter(
          status => status.key !== TEAM_STATUS_LIMIT_ENABLING_CREDIT,
        );
      }

      if (datasource?.toLowerCase() === 'datasul') {
        if (
          team_status_id === TEAM_STATUS_ENABLED_LIMIT_CONTRACT &&
          operation === OPERATION_DELIVERED_PRODUCT_ADVANCE_ID
        ) {
          filteredListOfTeamStatus = filteredListOfTeamStatus.filter(
            status =>
              status.key === TEAM_STATUS_ADVANCE_PROGRAMMING_DATASUL_COMMERCIAL_REQUEST ||
              status.key === TEAM_STATUS_LIMIT_ENABLING_CREDIT,
          );
        }

        if (team_status_id === TEAM_STATUS_TTP_SIGNED_IN_ANALYSIS_CONTRACT) {
          filteredListOfTeamStatus = filteredListOfTeamStatus.filter(
            status =>
              status.key !== TEAM_STATUS_CONTRACT_ADVANCE_PROGAMMING &&
              status.key !== TEAM_STATUS_CONTRACT_MANUAL_ADVANCE_PROGAMMING,
          );
        }

        if (team_status_id === TEAM_STATUS_ENABLED_LIMIT_CONTRACT)
          filteredListOfTeamStatus = filteredListOfTeamStatus.filter(
            status => status.key !== TEAM_STATUS_CONTRACT_MANUAL_ADVANCE_PROGAMMING,
          );
      }

      if (
        operation === OPERATION_PREFIN_SUGAR_MILLS_ID &&
        team_status_id === TEAM_STATUS_IN_ANALYSIS_PREFIN_REQUEST &&
        !approved_value
      ) {
        filteredListOfTeamStatus = filteredListOfTeamStatus.filter(
          status => status.key !== TEAM_STATUS_ENABLED_LIMIT_CREDIT_SUGAR_MILLS_REQUEST,
        );
      }

      return filteredListOfTeamStatus;
    },
    [],
  );

  return { filterListOfNextTeamStatus };
};

import React from 'react';

import { cva } from 'class-variance-authority';
import { cn } from 'lib/utils';

type Props = {
  children: React.ReactNode;
  variant?: 'default' | 'highlighted';
};

export const PackageInfoCard = ({ children, variant = 'default' }: Props) => {
  const buttonVariants = cva('flex w-full flex-col py-2 rounded-xl justify-center', {
    variants: {
      variant: {
        default: 'bg-gray-200',
        highlighted: 'bg-yellow-200',
      },
    },
  });

  return (
    <div
      className={cn(
        buttonVariants({
          variant,
        }),
      )}
    >
      {children}
    </div>
  );
};

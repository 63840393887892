import React from 'react';
import { useTranslation } from 'react-i18next';
import { MdSmartphone } from 'react-icons/md';

import { Col, Form, Spin } from 'antd';
import { useForm } from 'antd/es/form/Form';

import { Button, FormItem, ModalStatus, Password, Row } from '../../../../components';
import { useAuthentication } from '../../../../hooks/fetch/useAuthentication';
import { useUser } from '../../../../hooks/fetch/useUsers';
import { usePasswordValidator } from '../../../../hooks/usePasswordValidator';
import UserData from '../../../../types/User/UserData';
import { generatePassword } from '../../../../utils/Password';
import { SFormContainer, SFormButtons } from '../styles';

export const AuthTab = ({
  isExternal,
  user,
  handleHideForm,
}: {
  isExternal: boolean;
  user: UserData;
  handleHideForm: () => void;
}) => {
  const { t } = useTranslation();
  const [form] = useForm();
  const passwordValidator = usePasswordValidator();
  const { clear2FA, isFetching } = useAuthentication();
  const { isUpdating, update } = useUser();

  const handleSuccess = (messageSuccess = '') =>
    ModalStatus({
      type: 'success',
      title: t('modal.success'),
      subTitle: messageSuccess,
    });

  const handleEdit = () => {
    form.validateFields().then(async values => {
      const response = await update(user.id, values);

      if (response) {
        handleSuccess(t('pages.admin.user.passwordChanged'));

        handleHideForm();
        form.resetFields();
      }
    });
  };

  const handleGeneratePassword = () => {
    const password = generatePassword();
    form.setFieldsValue({ confirm: password, password });
  };

  return (
    <SFormContainer visible>
      <Spin spinning={isFetching}>
        <h2>{t('pages.admin.user.authTitle')}</h2>
        <Form form={form}>
          <Row gutter={[26, 26]}>
            {' '}
            <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={8}>
              <FormItem label={t('pages.admin.user.form.new_password')} name="password" rules={[...passwordValidator]}>
                <Password disabled={isExternal} />
              </FormItem>
            </Col>
            <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={8}>
              <FormItem
                label={t('pages.admin.user.form.confirmpassword')}
                dependencies={['password']}
                name="confirm"
                rules={[
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(t('pages.admin.user.form.not_same'));
                    },
                  }),
                ]}
              >
                <Password disabled={isExternal} />
              </FormItem>
            </Col>
            <Col
              xs={24}
              sm={12}
              md={12}
              lg={8}
              xl={8}
              xxl={8}
              style={{ display: 'flex', alignItems: 'flex-end', paddingBottom: '20px' }}
            >
              <Button disabled={isExternal} onClick={handleGeneratePassword}>
                {t('pages.admin.user.form.suggest_password')}
              </Button>
            </Col>
          </Row>
          <Row gutter={[26, 26]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <FormItem>
                <Button
                  loading={isFetching}
                  disabled={!user?.two_factor_is_configured}
                  onClick={() => {
                    ModalStatus({
                      type: 'confirm',
                      title: t('pages.admin.permission.delete.title'),
                      subTitle: t('pages.admin.permission.delete.subtitle'),
                      cancelText: t('pages.admin.permission.delete.cancel'),
                      okText: t('pages.admin.permission.delete.confirm'),
                      onOk: () => clear2FA(user.id),
                    });
                  }}
                >
                  <MdSmartphone /> {t('user.form.clear_2fa', { ns: 'admin' })}
                </Button>
              </FormItem>
            </Col>
          </Row>

          <SFormButtons>
            <Button status="secondary" htmlType="reset" onClick={handleHideForm} disabled={isUpdating}>
              {t('pages.admin.user.buttonCancel')}
            </Button>
            <Button status="primary" htmlType="submit" onClick={handleEdit} loading={isUpdating} disabled={isUpdating}>
              {t('pages.admin.user.buttonEdit')}
            </Button>
          </SFormButtons>
        </Form>
      </Spin>
    </SFormContainer>
  );
};

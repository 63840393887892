import React from 'react';
import { useTranslation } from 'react-i18next';

import { TableData, TableTranslation } from '@agrodatabr/agrodataui';
import { ColumnType } from 'antd/lib/table';
import { Formatter } from 'utils/Formatter';

import SustainabilityReportTypeData from '../../../../@types/data/SustainabilityReportTypeData';
import { IconWithTooltip } from '../../../../components';
import { columns } from './columns';
import { TableProps } from './types';

export const Table = ({ handleChange, isFetching = false, controller }: TableProps) => {
  const { t } = useTranslation();

  const getActions = () => ({
    render: (_: unknown, record: any) => (
      <>
        <IconWithTooltip
          action="view"
          title={t('pages.sustainability.table.view')}
          onClick={async () => {
            window.open(`/sustainability/request-report/${record.id}`, '_blank');
          }}
        />
      </>
    ),
  });

  const customColumns = (column: ColumnType<object>) => {
    column = { ...column, title: t(column.title.toString()) };

    switch (column.key) {
      case 'harvest':
        column = {
          ...column,
          render: (value: any) => {
            return value.name;
          },
        };
        break;
      case 'product':
        column = {
          ...column,
          render: (value: any) => {
            return <span>{value?.name ?? '-'}</span>;
          },
        };
        break;
      case 'created_at':
        column = {
          ...column,
          render: (value: any) => {
            return <span>{Formatter.date(value) ?? '-'}</span>;
          },
        };
        break;
      case 'sustainability_report_status':
        column = {
          ...column,
          render: (sustainability_report_status: SustainabilityReportTypeData) => {
            return <span>{sustainability_report_status?.name}</span>;
          },
        };
        break;
      default:
        column = {
          ...column,
          render: (value: any) => {
            return value;
          },
        };
        break;
    }
    return column;
  };

  return (
    <TableData
      columns={columns}
      tableConfig={{
        getActions,
      }}
      customColumns={customColumns}
      searchRequest={handleChange}
      width="100%"
      pagination={{ position: ['bottomCenter'] }}
      loading={isFetching}
      controller={controller}
      translation={{ pagination: { totalOfRegisters: t('table.totalOfRegisters') } } as TableTranslation}
    />
  );
};

import React from 'react';
import { useTranslation } from 'react-i18next';

import { ColumnDef } from '@tanstack/react-table';
import { DataTable } from 'components/ui/data-table';
import { PaginationList } from 'components/ui/pagination.list';
import { usePagination } from 'hooks/usePagination';
import { ChangelogData } from 'types/RegisteredCustomer/ChangelogData';

type Props = {
  data: ChangelogData['values'];
  auditableType?: string;
  personType?: 'physical' | 'legal';
};

const auditableTypes = {
  'App\\Model\\Address': 'addresses.0',
  'App\\Model\\PhysicalPerson': 'physical_person',
  'App\\Model\\LegalPerson': 'legal_person',
  'App\\Model\\CommercialOpinion': 'commercial_opinions',
  'App\\Model\\NetRevenue': 'net_revenues',
  'App\\Model\\ProviderReference': 'provider_reference',
  'App\\Model\\BankReference': 'bank_reference',
  'App\\Model\\EconomicGroup': 'economic_groups',
  'App\\Model\\SimplePlan': 'simple_plans',
  'App\\Model\\CustomerReference': 'customer_reference',
} as { [key: string]: string };

const apiFieldNames = {
  stage_id: 'status_id',
} as { [key: string]: string };

export const ChangelogValueTable = ({ data, auditableType, personType }: Props) => {
  const { paginationConfig, dispatchPagination } = usePagination();
  const { t } = useTranslation();

  const columns: ColumnDef<ChangelogData['values'][number]>[] = [
    {
      header: t('field-changed'),
      accessorKey: 'field',
      cell: ({ row }) => {
        const { field } = row.original;
        const fieldName = apiFieldNames[field as string] || field;

        const auditableTypeTranslation = auditableTypes[auditableType];

        if (personType === 'physical') {
          return t(
            `pages.registered-customers.form.pf${
              auditableTypeTranslation ? `.${auditableTypeTranslation}` : ''
            }.${fieldName}`,
          );
        }
        return t(
          `pages.registered-customers.form.pj${
            auditableTypeTranslation ? `.${auditableTypeTranslation}` : ''
          }.${fieldName}`,
        );
      },
    },
    {
      header: t('old-value'),
      accessorKey: 'old',
    },
    {
      header: t('new-value'),
      accessorKey: 'new',
    },
    {
      header: t('changes'),
      accessorKey: 'formated',
    },
  ];

  console.log(paginationConfig);

  return (
    <div>
      <DataTable
        data={data.slice(paginationConfig.current * 10 - 10, paginationConfig.current * 10) ?? []}
        columns={columns}
      />

      <PaginationList paginationState={paginationConfig} paginationDispatch={dispatchPagination} />
    </div>
  );
};

import React from 'react';
import { useTranslation } from 'react-i18next';

import { DatePicker } from 'antd';
import ptBR from 'antd/es/date-picker/locale/pt_BR';
import { FormItem } from 'components';
import appConfigs from 'configs/app';

import { CreditLimitDateInputProps } from './types';

export const CreditLimitExpirationDateInput = ({ required, disabled }: CreditLimitDateInputProps) => {
  const { t } = useTranslation();

  return (
    <FormItem
      label={t('hooks.creditStatus.modal-status-credit-limit-expiration-date-field')}
      name="limit_expiration_date"
      rules={[{ required }]}
    >
      <DatePicker locale={ptBR} mode="date" format={appConfigs.formatDate} disabled={disabled} />
    </FormItem>
  );
};

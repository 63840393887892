const columns: any = {
  operator: [
    {
      title: 'pages.monitoring.operator.table.header.name',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
    },
    {
      title: 'pages.monitoring.operator.table.header.has_approval',
      dataIndex: 'has_approval',
      key: 'has_approval',
      sorter: true,
    },
    {
      title: 'pages.monitoring.operator.table.header.actions',
      key: 'action',
      dataIndex: 'action',
      align: 'center',
      width: '180px',
      fixed: 'right',
    },
  ],
  emails: [
    {
      title: 'pages.monitoring.operator.emails.description',
      dataIndex: 'description',
      key: 'description',
      sorter: true,
    },
    {
      title: 'pages.monitoring.operator.emails.email',
      dataIndex: 'value',
      key: 'value',
      sorter: true,
    },
    {
      title: 'pages.monitoring.operator.table.header.actions',
      key: 'action',
      dataIndex: 'action',
      align: 'center',
      width: '180px',
      fixed: 'right',
    },
  ],
};

export default columns;

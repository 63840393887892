import React, { forwardRef, useEffect, useImperativeHandle } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ComboBox } from 'components/ui/combo';
import { Form } from 'components/ui/form';
import { FormGrid } from 'components/ui/form-grid';
import { FormItem } from 'components/ui/form-item';
import { InputMoney, InputNumber } from 'components/ui/input';
import { useCache } from 'hooks/cache';

import { useRegisteredCustomerStore } from '../stores/use-registered-customer-store';

type Props = {
  onSuccess?: (data: SimpleAgriculturalPlanFormData) => void;
};

export type SimpleAgriculturalPlanFormData = {
  id?: string;
  culture_id?: string;
  own?: number;
  leased?: number;
  quantity_bags?: number;
  production_cost?: number;
  sale_price?: number;
  total_productivity?: number;
  total_planted_area?: number;
};

export const simpleAgriculturalPlanInitialValues = {
  id: '',
  culture_id: '',
  own: 0,
  leased: 0,
  quantity_bags: 0,
  production_cost: 0,
  sale_price: 0,
  total_productivity: 0,
  total_planted_area: 0,
};

export const SimpleAgriculturalPlanForm = forwardRef(({ onSuccess }: Props, ref) => {
  const { t } = useTranslation();
  const cache = useCache();
  const form = useForm<SimpleAgriculturalPlanFormData>({
    defaultValues: simpleAgriculturalPlanInitialValues,
    mode: 'onBlur',
  });

  const cultureOptions = cache.getPersonOptions('culture');

  const simpleAgriculturalPlanToEdit = useRegisteredCustomerStore(state => state.simpleAgriculturalPlanToEdit);

  const sendForm = (values: SimpleAgriculturalPlanFormData) => {
    onSuccess?.(values);
  };

  const submit = () => {
    form.handleSubmit(sendForm, console.log)();
  };

  async function handleSimplePlansCalculatedFields() {
    const simplePlansValues = form.getValues();

    const { leased, own, quantity_bags } = simplePlansValues;

    form.setValue('total_planted_area', Number(leased || 0) + Number(own || 0));
    form.setValue('total_productivity', (Number(leased || 0) + Number(own || 0)) * Number(quantity_bags || 0));
  }

  useImperativeHandle(ref, () => ({
    form: form as UseFormReturn<SimpleAgriculturalPlanFormData>,
    submit,
  }));

  useEffect(() => {
    form.reset(simpleAgriculturalPlanToEdit || simpleAgriculturalPlanInitialValues);
  }, [simpleAgriculturalPlanToEdit]);

  return (
    <Form form={form} onSubmit={form.handleSubmit(sendForm)} onChange={() => handleSimplePlansCalculatedFields()}>
      <FormGrid maxCols={2}>
        <FormItem
          label={t('pages.registered-customers.form.pf.simple_plans.culture')}
          name="culture_id"
          rules={{ required: false }}
        >
          <ComboBox options={cultureOptions} />
        </FormItem>

        <FormItem
          label={t('pages.registered-customers.form.pf.simple_plans.leased')}
          name="leased"
          rules={{
            required: false,
            validate: {
              hasArea: () => {
                const values = form.getValues();
                if (!values.own && !values.leased) {
                  form.setError('own', t('pages.registered-customers.form.pf.simple_plans.validator.leased_or_own'));
                  return t('pages.registered-customers.form.pf.simple_plans.validator.leased_or_own');
                }

                form.clearErrors('own');
                return true;
              },
            },
          }}
        >
          <InputNumber maxLength={20} min="0" />
        </FormItem>

        <FormItem
          label={t('pages.registered-customers.form.pf.simple_plans.own')}
          name="own"
          rules={{
            required: false,
            validate: {
              hasArea: () => {
                const values = form.getValues();
                if (!values.own && !values.leased) {
                  form.setError('own', t('pages.registered-customers.form.pf.simple_plans.validator.leased_or_own'));
                  return t('pages.registered-customers.form.pf.simple_plans.validator.leased_or_own');
                }

                form.clearErrors('own');
                return true;
              },
            },
          }}
        >
          <InputNumber maxLength={20} />
        </FormItem>

        <FormItem
          label={t('pages.registered-customers.form.pf.simple_plans.quantity_bags')}
          name="quantity_bags"
          rules={{ required: true }}
        >
          <InputNumber maxLength={20} decimals={0} />
        </FormItem>

        <FormItem
          label={t('pages.registered-customers.form.pf.simple_plans.production_cost')}
          name="production_cost"
          rules={{ required: true }}
        >
          <InputMoney maxLength={20} />
        </FormItem>

        <FormItem
          label={t('pages.registered-customers.form.pf.simple_plans.sale_price')}
          name="sale_price"
          rules={{ required: true }}
        >
          <InputMoney maxLength={20} />
        </FormItem>

        <FormItem
          label={t('pages.registered-customers.form.pf.simple_plans.total_productivity')}
          name="total_productivity"
          rules={{ required: true }}
        >
          <InputNumber disabled maxLength={20} />
        </FormItem>

        <FormItem
          label={t('pages.registered-customers.form.pf.simple_plans.total_planted_area')}
          name="total_planted_area"
          rules={{ required: true }}
        >
          <InputNumber disabled maxLength={20} suffix="ha" />
        </FormItem>
      </FormGrid>
    </Form>
  );
});

SimpleAgriculturalPlanForm.displayName = 'SimpleAgriculturalPlanForm';

import React, { forwardRef, useEffect, useImperativeHandle } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ComboBox } from 'components/ui/combo';
import { Form } from 'components/ui/form';
import { FormGrid } from 'components/ui/form-grid';
import { FormItem } from 'components/ui/form-item';
import { Input, TextArea } from 'components/ui/input';
import { useAuth } from 'hooks/auth';
import { useCache } from 'hooks/cache';

import { useRegisteredCustomerStore } from '../stores/use-registered-customer-store';

type Props = {
  onSuccess?: (data: CofcoOpinionFormData) => void;
};

export type CofcoOpinionFormData = {
  id?: string;
  user_id?: string;
  user_name?: string;
  region_id?: string;
  subsidiary_id?: string;
  opinion?: string;
  created_at?: string;
};

export const cofcoOpinionInitialValues = {
  id: '',
  user_id: '',
  user_name: '',
  region_id: '',
  subsidiary_id: '',
  opinion: '',
};

export const CofcoOpinionForm = forwardRef(({ onSuccess }: Props, ref) => {
  const { t } = useTranslation();
  const cache = useCache();
  const { user } = useAuth();
  const form = useForm<CofcoOpinionFormData>({
    defaultValues: { ...cofcoOpinionInitialValues, user_id: user.name },
    mode: 'onBlur',
  });

  const regionId = form.watch('region_id');

  const regionOptions = cache.getCreditOptions('region');
  const subsidiaryOptions = cache.getCreditOptions('subsidiary').filter((s: any) => s.region_id === regionId);

  const cofcoOpinionToEdit = useRegisteredCustomerStore(state => state.cofcoOpinionToEdit);

  const sendForm = (values: CofcoOpinionFormData) => {
    onSuccess?.(values);
  };

  const submit = () => {
    form.handleSubmit(sendForm, console.log)();
  };

  useImperativeHandle(ref, () => ({
    form: form as UseFormReturn<CofcoOpinionFormData>,
    submit,
  }));

  useEffect(() => {
    form.reset(cofcoOpinionToEdit || cofcoOpinionInitialValues);
  }, [cofcoOpinionToEdit]);

  useEffect(() => {
    form.setValue('user_name', user.name);
    form.setValue('user_id', user.id);
  }, [user.name]);

  return (
    <Form form={form} onSubmit={form.handleSubmit(sendForm)}>
      <FormGrid maxCols={2}>
        <FormItem label={t('pages.registered-customers.form.pf.commercial_opinions.user_id')} name="user_name">
          <Input disabled />
        </FormItem>

        <FormItem
          label={t('pages.registered-customers.form.pf.commercial_opinions.region_id')}
          name="region_id"
          rules={{ required: true }}
        >
          <ComboBox options={regionOptions} />
        </FormItem>

        <FormItem
          rules={{ required: true }}
          label={t('pages.registered-customers.form.pf.commercial_opinions.subsidiary_id')}
          name="subsidiary_id"
        >
          <ComboBox options={subsidiaryOptions} />
        </FormItem>

        <FormItem
          className="col-span-full"
          label={t('pages.registered-customers.form.pf.commercial_opinions.opinion')}
          name="opinion"
          rules={{ required: true }}
        >
          <TextArea style={{ height: 100 }} />
        </FormItem>
      </FormGrid>
    </Form>
  );
});

CofcoOpinionForm.displayName = 'CofcoOpinionForm';

import UserData from '../../../../../../types/User/UserData';
import ChatItemProps from '../CommentItem/types';
import { CommentRaw } from '../types';

export const commentsFormatter = (user: UserData = null) => {
  const groupCommentsByDate = (commentsRaw: CommentRaw[]) =>
    commentsRaw.reduceRight((prev: any, cur) => {
      const curDate = cur.created_at.split(' ')[0];
      prev[curDate] = [...(prev[curDate] ?? []), cur];
      return prev;
    }, {});

  const formatCommentMessage = (curItem: any) => {
    const curHour = curItem.created_at.split(' ')[1];
    const curTimes = curHour.split(':');
    return {
      id: curItem.id,
      comment: curItem.comment,
      hour: `${curTimes[0]}:${curTimes[1]}`,
    };
  };

  const getInitials = (curItem: any) => {
    let username = curItem.user.name.split('(')[0].trim();
    username = username.split(' ');
    let initials = '';
    if (username.length === 1) initials = username[0].substring(0, 2);
    else if (username.length > 2)
      initials = `${username[0].substring(0, 1)}${username[username.length - 1].substring(0, 1)}`;
    else initials = `${username[0].substring(0, 1)}${username[1].substring(0, 1)}`;

    return initials;
  };

  const formatComment = (curItem: any, nextItem: any, keyName: { prevDate: string }, messages: any[], all: any[]) => {
    // eslint-disable-next-line prefer-destructuring
    keyName.prevDate = curItem.created_at.split(' ')[0];

    const messageComment = formatCommentMessage(curItem);

    const nextCommentIsFromSameUser = curItem.user_id === nextItem?.user_id;
    if (nextItem && nextCommentIsFromSameUser) messages.push(messageComment);

    if (!nextCommentIsFromSameUser || (!nextItem && user)) {
      messages.push(messageComment);
      const initials = getInitials(curItem);
      const userComments = {
        key: curItem.id,
        isMe: curItem.user_id === user.id,
        user: {
          name: curItem.user.name,
          initials,
          photo: curItem.user.photo,
        },
        messages: [...messages],
      } as ChatItemProps;
      all.push(userComments);
      messages.splice(0, messages.length);
    }
  };

  const handleComments = (cur: any, messages: any[], all: any[], keyName: { prevDate: string }) => {
    const total = cur.length;

    for (let i = 0; i < total; i += 1) formatComment(cur[i], cur[i + 1], keyName, messages, all);
  };

  const groupCommentsByUser = (comments: any) =>
    Object.values(comments).reduce((prev: any, cur: any) => {
      const all = [] as any[];
      const messages = [] as any[];
      const keyName = { prevDate: '' };

      handleComments(cur, messages, all, keyName);

      prev[keyName.prevDate] = all;

      return prev;
    }, {});

  return { groupCommentsByDate, groupCommentsByUser };
};

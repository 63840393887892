import React from 'react';
import { useTranslation } from 'react-i18next';

import { CnpjInput, CpfInput, DecimalInput, MoneyInput } from '@agrodatabr/masked-inputs';
import { DatePicker, Input, InputNumber, Select, TimePicker } from 'antd';
import moment from 'moment';
import { Formatter } from 'utils/Formatter';

import { DTOContractTypeField } from '../../../../types/Contract/DTOContractTypeField';
import { FormItem, ModalStatus } from '../../../../components';
import appConfig from '../../../../configs/app';
import {
  CONTRACT_TYPE_FIELD_TYPE_CNPJ,
  CONTRACT_TYPE_FIELD_TYPE_COMBO,
  CONTRACT_TYPE_FIELD_TYPE_CPF,
  CONTRACT_TYPE_FIELD_TYPE_DATE,
  CONTRACT_TYPE_FIELD_TYPE_DATETIME,
  CONTRACT_TYPE_FIELD_TYPE_DECIMAL,
  CONTRACT_TYPE_FIELD_TYPE_DECIMAL2,
  CONTRACT_TYPE_FIELD_TYPE_DECIMAL3,
  CONTRACT_TYPE_FIELD_TYPE_DECIMAL4,
  CONTRACT_TYPE_FIELD_TYPE_MONEY,
  CONTRACT_TYPE_FIELD_TYPE_NUMBER,
  CONTRACT_TYPE_FIELD_TYPE_TABLE,
  CONTRACT_TYPE_FIELD_TYPE_TEXT,
  CONTRACT_TYPE_FIELD_TYPE_TIME,
} from '../../../../configs/constants';
import { TableField } from './TableField';

export const DraftModelField = ({
  field,
  disabled = false,
  onChange = () => false,
  showLabel = true,
  initialValue = '',
  customLabel,
  customName,
  tableRegisterCount = 0,
  isExtended,
}: {
  field: DTOContractTypeField;
  disabled?: boolean;
  onChange?: (value: string | number) => void;
  showLabel?: boolean;
  initialValue?: any;
  customLabel?: string;
  customName?: string;
  tableRegisterCount?: number;
  isExtended?: boolean;
}) => {
  const { t } = useTranslation();

  const { name, display, type_id, json_data, is_required } = field;

  const comboOptions = () => {
    try {
      return JSON.parse(json_data).map((a: any) => ({ key: a.value, value: a.value, label: a.name }));
    } catch (err) {
      ModalStatus({
        type: 'error',
        title: t('modal.error'),
        subTitle: t('pages.edocuments.emission.draftEmission.fields.invalidJSON') + display,
      });
      return [];
    }
  };

  const formattedInitialValue = () => {
    switch (type_id) {
      case CONTRACT_TYPE_FIELD_TYPE_DATE:
      case CONTRACT_TYPE_FIELD_TYPE_DATETIME:
      case CONTRACT_TYPE_FIELD_TYPE_TIME:
        return initialValue ? moment(initialValue) : undefined;
      default:
        return initialValue;
    }
  };

  return (
    <FormItem
      name={customName ?? name}
      label={showLabel ? customLabel ?? display : ''}
      key={name}
      initialValue={formattedInitialValue()}
      rules={[
        { required: is_required && type_id !== CONTRACT_TYPE_FIELD_TYPE_TABLE },
        () => ({
          validator() {
            if (type_id === CONTRACT_TYPE_FIELD_TYPE_TABLE && !tableRegisterCount && is_required) {
              return Promise.reject(t('tableCannotBeEmpty'));
            }
            return Promise.resolve();
          },
        }),
      ]}
    >
      {(() => {
        switch (type_id) {
          case CONTRACT_TYPE_FIELD_TYPE_NUMBER:
            return (
              <InputNumber
                disabled={disabled}
                style={{ background: 'none' }}
                onChange={value => onChange(value)}
                maxLength={255}
              />
            );
          case CONTRACT_TYPE_FIELD_TYPE_CPF:
            return (
              <CpfInput
                disabled={disabled}
                style={{ background: 'none' }}
                onChange={evt => onChange(evt.target.value.toString())}
                maxLength={255}
              />
            );
          case CONTRACT_TYPE_FIELD_TYPE_CNPJ:
            return (
              <CnpjInput
                disabled={disabled}
                style={{ background: 'none' }}
                onChange={evt => onChange(evt.target.value.toString())}
                maxLength={255}
              />
            );
          case CONTRACT_TYPE_FIELD_TYPE_DECIMAL:
            return (
              // <Input
              //   maxLength={255}
              //   type="number"
              //   disabled={disabled}
              //   style={{ background: 'none' }}
              //   onChange={value => onChange(value)}
              // />
              <InputNumber
                maxLength={255}
                formatter={(value: string | number) => Formatter.integer(value, 3)}
                parser={(value: string) => Number(Formatter.integerParser(value))}
                disabled={disabled}
                style={{ background: 'none' }}
                onChange={value => onChange(value)}
              />
            );
          case CONTRACT_TYPE_FIELD_TYPE_DECIMAL2:
            return (
              <DecimalInput
                decimals={2}
                disabled={disabled}
                style={{ background: 'none' }}
                onChange={value => onChange(value)}
                maxLength={255}
              />
            );
          case CONTRACT_TYPE_FIELD_TYPE_DECIMAL3:
            return (
              <DecimalInput
                decimals={3}
                disabled={disabled}
                style={{ background: 'none' }}
                onChange={value => onChange(value)}
                maxLength={255}
              />
            );
          case CONTRACT_TYPE_FIELD_TYPE_DECIMAL4:
            return (
              <DecimalInput
                decimals={4}
                disabled={disabled}
                style={{ background: 'none' }}
                onChange={value => onChange(value)}
                maxLength={255}
              />
            );
          case CONTRACT_TYPE_FIELD_TYPE_MONEY:
            return (
              <MoneyInput
                prefix="R$"
                disabled={disabled}
                style={{ background: 'none' }}
                onChange={value => onChange(value)}
                maxLength={255}
              />
            );
          case CONTRACT_TYPE_FIELD_TYPE_DATE:
            return (
              <DatePicker
                disabled={disabled}
                style={{ background: 'none' }}
                format="DD/MM/YYYY"
                onChange={value => onChange(value.format(appConfig.formatApiDate))}
              />
            );
          case CONTRACT_TYPE_FIELD_TYPE_DATETIME:
            return (
              <DatePicker
                disabled={disabled}
                style={{ background: 'none' }}
                format="DD/MM/YYYY HH:mm:ss"
                showTime
                onChange={value => onChange(value.toString())}
              />
            );
          case CONTRACT_TYPE_FIELD_TYPE_TIME:
            return (
              <TimePicker
                disabled={disabled}
                style={{ background: 'none' }}
                format="HH:mm:ss"
                onChange={value => onChange(value.toString())}
              />
            );
          case CONTRACT_TYPE_FIELD_TYPE_TEXT:
            return (
              <Input.TextArea
                disabled={disabled}
                style={{ background: 'none' }}
                rows={4}
                onChange={evt => onChange(evt.target.value.toString())}
                maxLength={255}
              />
            );
          case CONTRACT_TYPE_FIELD_TYPE_TABLE:
            return <TableField field={field} disabled={disabled} name={name} isExtended={isExtended} />;
          case CONTRACT_TYPE_FIELD_TYPE_COMBO:
            return (
              <Select
                disabled={disabled}
                style={{ background: 'none' }}
                options={comboOptions()}
                onChange={value => onChange(value.toString())}
              />
            );
          default:
            return (
              <Input
                disabled={disabled}
                style={{ background: 'none' }}
                onChange={evt => onChange(evt.target.value.toString())}
                maxLength={255}
              />
            );
        }
      })()}
    </FormItem>
  );
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import { AiFillDollarCircle, AiOutlineBarChart, AiOutlineLineChart } from 'react-icons/ai';
import { FaMoneyCheckAlt, FaLeaf } from 'react-icons/fa';
import { GiFiles } from 'react-icons/gi';
import { MdAssignment, MdNotifications, MdMonetizationOn } from 'react-icons/md';

import { Content } from './Content';

const Home: React.FC = () => {
  const { t } = useTranslation();
  const propsContent = [
    {
      title: t('pages.home.title.access'),
      key: 0,
      props: [
        {
          title: t('pages.home.card.access.new-credit-request'),
          icon: <FaMoneyCheckAlt />,
          link: '/credit-request/new',
          key: 0,
          permission: 'credit.request.index',
        },
        {
          title: t('pages.home.card.access.new-sustainability-request'),
          icon: <FaLeaf />,
          link: '/sustainability/request-report',
          key: 1,
          permission: 'sustainability.report.store',
        },
        {
          title: t('pages.home.card.access.all-notification'),
          icon: <MdNotifications />,
          link: '/notifications',
          key: 2,
          permission: null,
        },
        {
          title: t('pages.home.card.access.priceday'),
          icon: <AiFillDollarCircle />,
          link: '/register/price',
          key: 3,
          permission: 'register.dailyprice.index',
        },
        {
          title: t('pages.home.card.access.contracts'),
          icon: <GiFiles />,
          link: '/edocuments/contracts',
          key: 4,
          permission: 'edocument.contract.index',
        },
      ],
    },
    {
      title: t('pages.home.title.dashboards'),
      key: 1,
      props: [
        {
          title: t('pages.home.card.dashboards.management'),
          icon: <AiOutlineBarChart />,
          link: '/dashboard/management',
          key: 0,
          permission: 'dashboard.management.index',
        },
        {
          title: t('pages.home.card.dashboards.requests-prefin'),
          icon: <MdAssignment />,
          link: '/dashboard/requests-prefin',
          key: 1,
          permission: 'dashboard.requests.prefin.index',
        },
        {
          title: t('pages.home.card.dashboards.warranty-prefin'),
          icon: <AiOutlineBarChart />,
          link: '/dashboard/warranty-prefin',
          key: 2,
          permission: 'dashboard.warranty.prefin.index',
        },
        {
          title: t('pages.home.card.dashboards.monitoring-prefin'),
          icon: <FaMoneyCheckAlt />,
          link: '/dashboard/monitoring-prefin',
          key: 3,
          permission: 'dashboard.monitoring.prefin.index',
        },
        {
          title: t('pages.home.card.dashboards.prepay'),
          icon: <MdMonetizationOn />,
          link: '/dashboard/prepay',
          key: 4,
          permission: 'dashboard.prepay.index',
        },
        {
          title: t('pages.home.card.dashboards.prepayExposure'),
          icon: <AiOutlineLineChart />,
          link: '/dashboard/expoprepay',
          key: 5,
          permission: 'dashboard.prepay.index',
        },
      ],
    },
  ];
  return (
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      {propsContent.map((item: any) => (
        <Content key={item.key} title={item.title} props={item.props} />
      ))}
    </div>
  );
};

export default Home;

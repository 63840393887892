import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaChevronLeft } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';

import { Image, Space, Form, Tooltip } from 'antd';
import { Input } from 'components';

import AuthPage from '../../../components/AuthPage';
import { HOME_PAGE_URL } from '../../../configs/constants';
import { useAuth } from '../../../hooks/auth';
import { usePage } from '../../../hooks/page';
import authApi from '../../../services/api/auth';
import { Code, ConfigurationText, Container } from './styles';

const TwoFactorAuth: React.FC = () => {
  const { getTokenType, TwoFaChallengeCode, TwoFaQrCode, TwoFactorIsEnabled } = useAuth();
  const navigate = useNavigate();

  const { t } = useTranslation(['auth']);
  const [loading, setLoading] = useState(true);
  const [qrCodeSvg, setQrCodeSvg] = useState('');
  const [code, setCode] = useState('');
  const [form] = Form.useForm();
  const codeRef = React.createRef<any>();
  const [cantScan, setCantScan] = useState(false);
  const [shouldShowCodeTooltip, setShouldShowCodeTooltip] = useState(false);

  const { alertStatus } = usePage();

  const handleCopyCode = () => {
    setShouldShowCodeTooltip(true);
    const range = document.createRange();
    range.selectNode(codeRef.current);
    window.getSelection().removeAllRanges(); // clear current selection
    window.getSelection().addRange(range); // to select text
    document.execCommand('copy');
    window.getSelection().removeAllRanges();
    setTimeout(() => setShouldShowCodeTooltip(false), 2000);
  };

  useEffect(() => {
    (async () => {
      if (getTokenType() !== 'authorization') {
        navigate(HOME_PAGE_URL);
      } else {
        // verifica se o 2fa está ativado para o usuário
        await TwoFactorIsEnabled()
          .then(async (response: any) => {
            if (!response.data) {
              await authApi.twoFactorAuthentication.enable();
              const qrCodeData = await TwoFaQrCode();
              if (qrCodeData) {
                const { svg, code: codeData } = qrCodeData;
                setQrCodeSvg(svg as string);
                setCode(codeData);
              }
            }
          })
          .catch(err => {
            alertStatus(err, 'error');
            if (err.response?.status === 403) {
              navigate('/login');
            }
          })
          .finally(() => {
            setLoading(false);
          });
      }
    })();
  }, []);

  const sendTwoFactorCode = (value: any) => {
    if (value !== null) {
      TwoFaChallengeCode(value, setLoading);
    }
  };

  // eslint-disable-next-line jsx-a11y/alt-text
  return (
    <AuthPage loading={loading}>
      <Link to="/login">
        <FaChevronLeft />
        {t('login.two-factor-auth.back_page')}
      </Link>
      <Form form={form}>
        {qrCodeSvg && (
          <Container>
            <h2>{t('login.two-factor-auth.title')}</h2>
            <br />
            <h4>{t('login.two-factor-auth.step1.title')}</h4>
            <p>
              {t('login.two-factor-auth.step1.text')}
              <br />
            </p>
            <br />
            {!cantScan ? (
              <>
                <h4>{t('login.two-factor-auth.step2.title')}</h4>
                <p>{t('login.two-factor-auth.step2.text')}</p>
                <a onClick={() => setCantScan(true)}>{t('login.two-factor-auth.step2.cantScan')}</a>
                <br />
                <Space direction="vertical" align="center" style={{ width: '100%', display: 'flex' }}>
                  {qrCodeSvg && <Image width={150} src={qrCodeSvg} />}
                </Space>
              </>
            ) : (
              <>
                <h4>{t('login.two-factor-auth.step2.titleCantScan')}</h4>
                <Tooltip
                  title={t('login.two-factor-auth.step2.copied')}
                  placement="top"
                  trigger="click"
                  visible={shouldShowCodeTooltip}
                >
                  <Code ref={codeRef} onClick={handleCopyCode}>
                    {code}
                  </Code>
                </Tooltip>
                <a onClick={() => setCantScan(false)}>{t('login.two-factor-auth.step2.useQr')}</a>
                <ConfigurationText>{t('login.two-factor-auth.step3.cantScan')}</ConfigurationText>
                <p>{t('login.two-factor-auth.step3.type')}: Time-based</p>
                <p>{t('login.two-factor-auth.step3.algorithm')}: SHA1</p>
                <p>{t('login.two-factor-auth.step3.digits')}: 6</p>
                <p>{t('login.two-factor-auth.step3.interval')}: 30</p>
              </>
            )}
            <br />
            <br />
          </Container>
        )}
        <h4>{t('login.two-factor-auth.step3.title')}</h4>
        <p>{t('login.two-factor-auth.step3.text')}</p>
        <Form.Item>
          <Input
            size="large"
            maxLength={6}
            max={999999}
            width="100%"
            type="number"
            onKeyDown={evt => {
              if (evt.currentTarget.value.length === 6 && evt.keyCode >= 48 && evt.keyCode <= 57) {
                evt.preventDefault();
              }
            }}
            onChange={evt => {
              const { value } = evt.target;

              if (`${value}`.length === 6) sendTwoFactorCode(value.toString());
            }}
          />
        </Form.Item>
      </Form>
    </AuthPage>
  );
};

export default TwoFactorAuth;

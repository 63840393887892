import { handleRequest } from 'services/api/api';
import documentApi from 'services/api/document';

export const fileDownload = async (
  id: string,
  name: string,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  const response = await handleRequest(() => documentApi.download(id), setIsLoading);
  const reader = new FileReader();
  reader.readAsText(response);
  const textJson = await (response as Blob).text();
  const dataStr = `data:text/json;charset=utf-8,${encodeURIComponent(textJson)}`;
  const dlAnchorElem = document.getElementById('downloadAnchorElem');
  dlAnchorElem.setAttribute('href', dataStr);
  dlAnchorElem.setAttribute('download', `${name}`);
  dlAnchorElem.click();
};

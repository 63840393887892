import { useState } from 'react';

import api, { handleRequest } from '../../services/api/api';

export const useSubscriberPart = () => {
  const [isFetching, setIsFetching] = useState(false);

  const getAll = async () => handleRequest(() => api.get('/contract/subscriber-part'), setIsFetching);

  const getOptions = async () => {
    const subscribersPart = await getAll();

    if (!subscribersPart) return subscribersPart;

    return {
      data: subscribersPart.data.map((subscriberPart: any) => ({
        key: subscriberPart.id,
        value: subscriberPart.id,
        label: subscriberPart.name,
        ...subscriberPart,
      })),
      lastPage: subscribersPart.lastPage,
    };
  };

  return { getAll, getOptions, isFetching };
};

export const columns: any = [
  {
    title: 'pages.integrations.rules.table.header.operation',
    dataIndex: 'operations',
    key: 'operations',
  },
  {
    title: 'pages.integrations.rules.table.header.subdivision',
    dataIndex: 'sub_divisions',
    key: 'sub_divisions',
  },
  {
    title: 'pages.integrations.rules.table.header.subsidiary',
    dataIndex: 'subsidiaries',
    key: 'subsidiaries',
  },
  {
    title: 'pages.integrations.rules.table.header.modality',
    dataIndex: 'modalities',
    key: 'modalities',
  },
  {
    title: 'pages.integrations.rules.table.header.erp',
    dataIndex: 'erp',
    key: 'erp',
  },
  {
    title: 'pages.integrations.rules.table.header.action',
    key: 'action',
    dataIndex: 'action',
    align: 'center',
    width: '180px',
  },
];

export default columns;

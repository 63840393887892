import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaFilter } from 'react-icons/fa';

import { TablePaginationConfig, TableLocale } from 'antd/lib/table/interface';

import { Switch } from '../../components';
import { FilterDropdown } from '../../partials';
import theme from '../../styles/theme';
import { ContainerTable } from './styles';
import { TableProps } from './types';

const TableData: React.FC<TableProps<any>> = ({ columns, pagination: paginationConfig, tableConfig, ...rest }) => {
  const { t } = useTranslation();

  const [, setSearchedColumn] = useState('');
  const [, setSearchText] = useState('');
  const [tableDataLoading, setTableDataLoading] = useState<string | null>(null);

  const locale: TableLocale = {
    filterConfirm: t('table.filterConfirm'),
    filterReset: t('table.filterReset'),
    emptyText: t('table.emptyText'),
  };

  let pagination: false | TablePaginationConfig = false;

  if (!tableConfig.nopagination) {
    pagination = {
      position: ['bottomLeft'],
      responsive: false,
      current: 1,
      total: 1,
      showSizeChanger: true,
      defaultPageSize: 20,
      defaultCurrent: 1,
      pageSizeOptions: ['10', '20', '50', '100'],
      ...paginationConfig,
    };

    if (paginationConfig) {
      pagination = {
        showTotal: () => `${t('pagination.totalOfRegisters')} ${paginationConfig.total}`,
        ...pagination,
      };
    }
  }

  const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
    confirm(); // close the dropdown filter
  };

  const handleReset = (clearFilters: any) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex: string, title: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
      <FilterDropdown
        buttonClearOnClick={() => handleReset(clearFilters)}
        buttonSearchOnClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
        inputOnChange={e => {
          setSearchText(e.target.value);
          setSelectedKeys(e.target.value ? [e.target.value] : []);
        }}
        inputOnPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        inputValue={selectedKeys[0]}
        title={title}
      />
    ),
  });

  const tableColumns = columns.map((column: any) => {
    let columnRender = {
      ...column,
      title: t(column.title),
      filteredValue: tableConfig.filtered ? tableConfig.filtered[column.dataIndex] : tableConfig.search || null,
      sortOrder: tableConfig.sorter
        ? tableConfig.sorter.columnKey === column.dataIndex && tableConfig.sorter.order
        : false,
    };

    if (column) {
      if (column.title) {
        columnRender = {
          ...columnRender,
          title: t(column.title),
        };
      }

      if (column.sorter) {
        columnRender = {
          ...columnRender,
          sortOrder: tableConfig.sorter
            ? tableConfig.sorter.columnKey === column.dataIndex && tableConfig.sorter.order
            : false,
        };
      }

      if (column.filtered) {
        columnRender = {
          ...columnRender,
          filteredValue: tableConfig.filtered ? tableConfig.filtered[column.dataIndex] : tableConfig.search || null,
          filterIcon: (filtered: boolean) => (
            <span className="anticon anticon-filter">
              <FaFilter color={filtered ? theme.colors.primary : theme.colors.greys.grey08} />
            </span>
          ),
          ...getColumnSearchProps(column.key, column.title),
        };
      }

      if (column.key === 'action' && tableConfig.getActions) {
        columnRender = {
          ...columnRender,
          ...tableConfig.getActions(),
        };
      }

      if (column.key === 'active') {
        const filteredValue =
          columnRender.filteredValue !== undefined && columnRender.filteredValue !== null
            ? columnRender.filteredValue.map((item: string | number) => item.toString())
            : ['1'];

        columnRender = {
          ...columnRender,
          filteredValue,
          filterMultiple: false,
          filters: [
            {
              text: t('table.filterActive'),
              value: '1',
            },
            {
              text: t('table.filterInactive'),
              value: '0',
            },
          ],
          render: (active: boolean | string, record: any, index: number) => {
            return (
              <Switch
                key={index}
                onChange={async (value, e) => {
                  setTableDataLoading(record.id);
                  await tableConfig.handleSwitchChange(record, value, e).finally(() => setTableDataLoading(null));
                }}
                defaultChecked={Number(active) === 1}
                size="small"
                loading={tableDataLoading === record.id}
              />
            );
          },
        };
      }
    }

    return columnRender;
  });

  return (
    <ContainerTable
      locale={locale}
      scroll={{ x: true }}
      size="small"
      tableLayout="auto"
      showSorterTooltip={false}
      {...rest}
      // loading={{
      //   indicator: <Spin />,
      //   spinning: true,
      // }}
      columns={tableColumns}
      pagination={pagination}
    />
  );
};
export default TableData;

import React, { MouseEvent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { CgProfile } from 'react-icons/cg';
import { FaSignOutAlt } from 'react-icons/fa';
// import { IoMdGlobe } from 'react-icons/io';
import { NavLink } from 'react-router-dom';

import { Collapse, List } from 'antd';
import Loading from 'components/ui/loading';
import { useCache } from 'hooks/cache';
import { FolderSync } from 'lucide-react';

import brazil from '../../../../assets/img/flags/brazil.png';
import china from '../../../../assets/img/flags/china.png';
import us from '../../../../assets/img/flags/us.png';
import { useAuth } from '../../../../hooks/auth';
import { Container, CollapseHeader, DropdownItem, SFlag } from './styles';

interface DropdownProps {
  visible: boolean;
}

const Dropdown: React.FC<DropdownProps> = ({ visible }) => {
  const { t, i18n } = useTranslation();
  const { Panel } = Collapse;
  const { updateCache } = useCache();

  const [isUpdatingCache, setIsUpdatingCache] = React.useState(false);

  const { logOut, user, updateUserConfig } = useAuth();

  const languages = [
    { key: 'en', value: 'en', label: t('header.lang.en'), flag: us },
    { key: 'pt-br', value: 'pt-br', label: t('header.lang.pt-br'), flag: brazil },
    { key: 'cn', value: 'cn', label: t('header.lang.cn'), flag: china },
  ];

  const handleLogout = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();
      logOut();
    },
    [logOut],
  );

  const handleUpdateCache = async () => {
    setIsUpdatingCache(true);
    await updateCache();
    setIsUpdatingCache(false);
  };

  return (
    <>
      <Container visible={visible}>
        <Collapse expandIconPosition="right">
          <Panel
            key="language"
            header={
              <CollapseHeader>
                {/* <IoMdGlobe /> */}
                {t('header.internalPage.dropdown.changeLanguage')}
              </CollapseHeader>
            }
          >
            <List
              itemLayout="horizontal"
              dataSource={languages}
              renderItem={language => {
                return (
                  <List.Item
                    key={language.key}
                    onClick={() => {
                      if (language.value !== 'cn') {
                        i18n.changeLanguage(language.value);
                        user.config.app.language = language.value;
                        updateUserConfig(user.config);
                      }
                    }}
                    className={
                      i18n.language === language.value
                        ? 'language-active'
                        : `${language.value === 'cn' ? 'language-inactive' : ''}`
                    }
                  >
                    <SFlag>
                      <img alt={language.label} src={language.flag} />
                      <span>{language.label}</span>
                    </SFlag>
                  </List.Item>
                );
              }}
            />
          </Panel>
        </Collapse>

        <DropdownItem>
          <NavLink to="/profile">
            <CgProfile />
            {t('header.internalPage.dropdown.profile')}
          </NavLink>
        </DropdownItem>

        <DropdownItem>
          <Loading isLoading={isUpdatingCache} size={14}>
            <div
              className="flex gap-2 px-2 items-center text-sm hover:text-primary cursor-pointer"
              onClick={() => (isUpdatingCache ? undefined : handleUpdateCache())}
            >
              <FolderSync size={18} />
              {t('update-cache')}
            </div>
          </Loading>
        </DropdownItem>

        <DropdownItem>
          <a href="#logout" onClick={handleLogout}>
            <FaSignOutAlt />
            {t('Logout')}
          </a>
        </DropdownItem>
      </Container>
    </>
  );
};

export default Dropdown;

import React, { forwardRef, useEffect, useImperativeHandle } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Form } from 'components/ui/form';
import { FormGrid } from 'components/ui/form-grid';
import { FormItem } from 'components/ui/form-item';
import { Input, InputWithMask } from 'components/ui/input';

import { useRegisteredCustomerStore } from '../stores/use-registered-customer-store';

type Props = {
  onSuccess?: (data: EconomicGroupFormData) => void;
  personType: 'physical' | 'legal';
};

export type EconomicGroupFormData = {
  id?: string;
  name?: string;
  doc_number?: string;
};

export const economicGroupInitialValues = {
  id: '',
  name: '',
  doc_number: '',
};

export const EconomicGroupForm = forwardRef(({ onSuccess, personType }: Props, ref) => {
  const { t } = useTranslation();
  const form = useForm<EconomicGroupFormData>({
    defaultValues: economicGroupInitialValues,
    mode: 'onBlur',
  });

  const economicGroupToEdit = useRegisteredCustomerStore(state => state.economicGroupToEdit);

  const sendForm = (values: EconomicGroupFormData) => {
    onSuccess?.(values);
  };

  const submit = () => {
    form.handleSubmit(sendForm, console.log)();
  };

  useImperativeHandle(ref, () => ({
    form: form as UseFormReturn<EconomicGroupFormData>,
    submit,
  }));

  useEffect(() => {
    form.reset(economicGroupToEdit || economicGroupInitialValues);
  }, [economicGroupToEdit]);

  return (
    <Form form={form} onSubmit={form.handleSubmit(sendForm)}>
      <FormGrid maxCols={2}>
        <FormItem label={t('pages.registered-customers.form.pj.economic_groups.name')} name="name">
          <Input />
        </FormItem>
        <FormItem label={personType === 'legal' ? t('cnpj') : t('cpf')} name="doc_number">
          <InputWithMask
            mask={personType === 'legal' ? '99.999.999/9999-99' : '999.999.999-99'}
            placeholder="__.___.___/____-__"
          />
        </FormItem>
      </FormGrid>
    </Form>
  );
});

EconomicGroupForm.displayName = 'EconomicGroupForm';

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Breadcrumb } from '../../../components';
import { SContainer, SHeader, STitlePage } from '../../../styles';
import { CCard, Chart, Filters, CSkeleton } from '../components';
import { TopCards, ChartsArea, BoxChart, TableArea, Title } from '../styles';
import { getCards, getItemsChart } from '../utils';
import { useMonitoringPrefin } from './hooks/useMonitoringPrefin';
import { Map } from './Map';
import { useChartsMonitoringProps, propsSkeletonCard, propsSkeletonCharts, propsFilters } from './props';
import { TableResume } from './TableResume';

const MonitoringPrefin: React.FC = () => {
  const {
    handleCardsMonitoringPrefin,
    handleChartsMonitoringPrefin,
    getResumeReport,
    generateExportResume,
    isFetchingCharts,
    isFetchingCards,
    filterForm,
    searchFormResume,
  } = useMonitoringPrefin();
  const { propsChartVisitsMonitoring, propsChartVisitsConditionMonitoring, propsChartMap } = useChartsMonitoringProps();
  const { t } = useTranslation();
  const [chartEvent, setChartEvent] = useState([]);
  const [propsCards, setPropsCards] = useState([]);
  const [propsCharts, setPropsChart] = useState([]);
  const [propsMap, setPropsMap] = useState([]);
  const [propsChartCopy, setPropsChartCopy] = useState([]);
  const [searchFiltersResume, setSearchFiltersResume] = useState({});

  const getCharts = async () => {
    const { dataChartVisitsMonitoring, dataChartVisitsConditionMonitoring, dataChartMap } =
      await handleChartsMonitoringPrefin();
    setPropsChart([
      propsChartVisitsMonitoring(dataChartVisitsMonitoring, t),
      propsChartVisitsConditionMonitoring(dataChartVisitsConditionMonitoring, t),
    ]);
    setPropsMap(propsChartMap(dataChartMap));
  };

  const handleGet = () => {
    setPropsCards([]);
    setPropsChart([]);
    setPropsChartCopy([]);
    setChartEvent([]);
    getCards(handleCardsMonitoringPrefin, setPropsCards);
    getCharts();
  };

  useEffect(() => {
    handleGet();
  }, []);

  useEffect(() => {
    const filter = filterForm.getFieldsValue();
    if (filter) {
      filter.requesterDate = undefined;
      setSearchFiltersResume(filter);
    }
  }, [filterForm]);

  useEffect(() => {
    if (propsCharts.length) setPropsChartCopy(propsCharts);
  }, [propsCharts]);

  const getItems = (item: any, buttonValue: any, prop: any) => {
    getItemsChart({ prop, item, buttonValue, chartEvent, setChartEvent, setPropsChart });
  };

  return (
    <>
      <Breadcrumb
        items={[{ title: t('breadcrumb.home'), to: '/home' }, { title: t('breadcrumb.monitoring-prefin') }]}
      />

      <SContainer>
        <SHeader>
          <STitlePage>{t('pages.dashboard.monitoring-prefin.title')}</STitlePage>
        </SHeader>
        <Filters
          props={propsFilters}
          filterForm={filterForm}
          handleGet={handleGet}
          setSearchFiltersTableFirst={setSearchFiltersResume}
          searchForm={[searchFormResume]}
          monitoring
        />

        <TopCards>
          {isFetchingCards ? (
            <CSkeleton style={propsSkeletonCard} numberItems={propsSkeletonCard.number} />
          ) : (
            propsCards.map((item: any) => <CCard key={item.key} item={item} />)
          )}
        </TopCards>
        <ChartsArea>
          {isFetchingCharts ? (
            <CSkeleton style={propsSkeletonCharts} numberItems={propsSkeletonCharts.number} />
          ) : (
            <>
              {propsCharts.map((item: any) => (
                <Chart
                  key={item.key}
                  chartProps={{ ...item }}
                  setChartEvent={setChartEvent}
                  chartEvent={chartEvent}
                  getItems={getItems}
                  referenceKey={item.key}
                  setPropsChart={setPropsChart}
                  propsChartCopy={propsChartCopy}
                  propsChartsOriginal={propsCharts}
                  setPropsChartCopy={setPropsChartCopy}
                />
              ))}
              <BoxChart style={{ flex: 1 }}>
                {propsMap.length ? (
                  <Map dataMap={propsMap} />
                ) : (
                  <>
                    <Title>{t('pages.dashboard.monitoring-prefin.map.title')}</Title>
                    <div
                      style={{
                        height: '403px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontSize: '15px',
                        fontWeight: 'bold',
                        color: '#666666',
                      }}
                    >
                      {t('pages.dashboard.noDataToDisplay')}
                    </div>
                  </>
                )}
              </BoxChart>
            </>
          )}
        </ChartsArea>
        <TableArea>
          {Object.keys(searchFiltersResume).length && (
            <TableResume
              searchFilters={searchFiltersResume}
              setSearchFilters={setSearchFiltersResume}
              searchForm={searchFormResume}
              listReportPermissions={['dashboard.export.summarysurvey.index']}
              generateReportPermissions={['dashboard.generate.summarysurvey.store']}
              getReport={getResumeReport}
              generateExport={generateExportResume}
            />
          )}
        </TableArea>
      </SContainer>
    </>
  );
};

export default MonitoringPrefin;

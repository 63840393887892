import { useGetDatasulSapProviderMutation } from 'pages/CreditRequest/api/useGetDatasulSapProviderMutation';

type Props = {
  isPhysicalPerson: boolean;
};

type FindProviderProps = {
  document: string;
};

export const useFindProvider = ({ isPhysicalPerson }: Props) => {
  const datasulSapProviderMutation = useGetDatasulSapProviderMutation();

  const findProvider = async ({ document }: FindProviderProps) => {
    const response = await datasulSapProviderMutation.mutateAsync({
      cnpj: !isPhysicalPerson ? document : '',
      cpf: isPhysicalPerson ? document : '',
      sapFirst: true,
    });
    return response;
  };

  return { findProvider };
};

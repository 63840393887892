import { useTranslation } from 'react-i18next';

import { useMutation } from '@tanstack/react-query';
import { useAlertDialog } from 'components/ui/alert-dialog';
import { RegisteredCustomerFormData } from 'pages/RegisteredCustomers/DetailView/registered-customer.form';
import api from 'services/api/api';
import exceptionHandler from 'services/exceptions';

type Props = {
  personType: 'legal' | 'physical';
};

export const useUpdateRegisteredCustomerMutation = ({ personType }: Props) => {
  const { alert } = useAlertDialog();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: async (data: RegisteredCustomerFormData) => {
      if (!data?.id) return alert({ title: t('record-not-found'), type: 'error' });
      const response = await api.put(`${personType === 'legal' ? 'person/legal' : 'person/physical'}/${data.id}`, data);
      return response.data.data;
    },
    mutationKey: ['updateRegisteredCustomer'],
    onError: err => {
      exceptionHandler(err);
    },
  });
};

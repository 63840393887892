import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  height: calc(100% - 150px);
  grid-template-rows: 85% 15%;
  position: relative;
  margin-top: 20px;
`;

export const UploadWrapper = styled.div`
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const UploadedWrapper = styled.div`
  width: 100%;
  height: min-content;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 10px;
  border: 2px dashed ${({ theme: { colors } }) => colors.primary};
  color: ${({ theme: { colors } }) => colors.primary};

  span {
    text-align: center;
  }
`;

import { useCallback, useRef, useState } from 'react';

import { Form } from 'antd';

import { useAuth } from '../../../../../hooks/auth';
import { useSerasaCounterparty } from '../../../../../hooks/fetch/useSerasaCounterparty';
import { usePage } from '../../../../../hooks/page';
import { CommentRaw } from './types';
import { commentsFormatter } from './utils/commentsFormatter';

interface Props {
  serasaCounterpartyId: string;
}

export const useSerasaCounterpartyCommentsController = ({ serasaCounterpartyId }: Props) => {
  const { alertStatus } = usePage();
  const { getComments, sendComment: publishComment, isFetchingComments } = useSerasaCounterparty();
  const { user } = useAuth();
  const [commentForm] = Form.useForm();

  const scrollBottom = useRef<null | HTMLDivElement>(null);

  const [loading, setLoading] = useState(true);
  const [loadingComments, setLoadingComments] = useState(false);
  const [blockLoadComments, setBlockLoadComments] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [forceLoadComments, setForceLoadComments] = useState(true);
  const [commentsRaw, setCommentsRaw] = useState<CommentRaw[]>([]);
  const [page, setPage] = useState(1);
  const [comment, setComment] = useState('');
  const [loadingButton, setLoadingButton] = useState<boolean>(false);

  const scrollToBottom = (should: boolean, pageNumber = 1) => {
    if (scrollBottom && scrollBottom.current && !should) {
      const { scrollHeight } = scrollBottom.current;
      const pageHeight = scrollBottom.current.scrollHeight / pageNumber;
      scrollBottom.current.scrollTop = scrollHeight - (pageHeight * pageNumber - 1) + pageHeight;
    }

    if (should) {
      if (scrollBottom) {
        scrollBottom.current.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'end' });
        scrollBottom.current.scrollTop = scrollBottom.current.scrollHeight;
      }
    }
  };

  const updateCommentsInState = (response: any, pageNumber: number) => {
    const totalPagesQtd = response.last_page;
    setTotalPages(totalPagesQtd);
    setCommentsRaw(commentsState => (pageNumber === 1 ? response.data : [...commentsState, ...response.data]));
    scrollToBottom(pageNumber === 1, pageNumber);
    setForceLoadComments(false);
  };

  const handleGetComments = useCallback(
    async (pageNumber: number, shouldForceRequest = false) => {
      if (!serasaCounterpartyId) alertStatus('Não encontrado o número da solicitação de crédito!', 'error');

      setLoading(true);
      setLoadingComments(true);
      const params = { page: pageNumber, per_page: 10 };
      const response = await getComments(serasaCounterpartyId, { params });
      const shouldUpdateCommentsInState = response && (shouldForceRequest || (!blockLoadComments && forceLoadComments));
      if (shouldUpdateCommentsInState) updateCommentsInState(response, pageNumber);
      setLoading(false);
      setLoadingComments(false);
      setLoadingButton(false);
    },
    // eslint-disable-next-line
    [alertStatus, blockLoadComments, serasaCounterpartyId],
  );

  const formatComments = () => {
    commentsRaw.sort((a, b) => {
      return new Date(a.created_at).valueOf() - new Date(b.created_at).valueOf();
    });
    const commentsGroupedByDate = commentsFormatter().groupCommentsByDate(commentsRaw.reverse());
    const commentsGroupedByUser = commentsFormatter(user).groupCommentsByUser(commentsGroupedByDate);
    return commentsGroupedByUser;
  };

  const sendComment = useCallback(
    async values => {
      if (!serasaCounterpartyId) alertStatus('Não encontrado o número da solicitação de crédito!', 'error');

      const response = await publishComment(serasaCounterpartyId, values);

      if (response) {
        handleGetComments(1, true);
        setBlockLoadComments(false);
        setForceLoadComments(true);
        alertStatus('Comentário enviado com sucesso');
        setPage(1);
        setComment('');
        commentForm.resetFields();
      }
    },
    // eslint-disable-next-line
    [serasaCounterpartyId, handleGetComments],
  );

  return {
    handleGetComments,
    loading,
    setLoading,
    loadingComments,
    setBlockLoadComments,
    totalPages,
    forceLoadComments,
    setForceLoadComments,
    scrollBottom,
    commentsRaw,
    setCommentsRaw,
    scrollToBottom,
    formatComments,
    page,
    setPage,
    comment,
    setComment,
    sendComment,
    isFetchingComments,
    commentForm,
    loadingButton,
    setLoadingButton,
  };
};

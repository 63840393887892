import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ColumnDef } from '@tanstack/react-table';
import { IconWithTooltip } from 'components';
import { ShouldRender } from 'components/ShouldRender';
import { useAlertDialog } from 'components/ui/alert-dialog';
import { DataTable } from 'components/ui/data-table';
import { Can } from 'hooks/ability';
import { Formatter } from 'utils/Formatter';

import { useRegisteredCustomerStore } from '../stores/use-registered-customer-store';
import { LiquidReceiptFormData } from './liquid-receipt.form';

export const LiquidReceiptTable = () => {
  const { t } = useTranslation();
  const form = useFormContext();
  const { alert } = useAlertDialog();

  const liquidReceipts = form.watch('liquid_receipt') as LiquidReceiptFormData[];

  const setLiquidReceiptToEdit = useRegisteredCustomerStore(state => state.setLiquidReceiptToEdit);
  const setIsLiquidReceiptModalOpen = useRegisteredCustomerStore(state => state.setIsLiquidReceiptModalOpen);
  const isViewing = useRegisteredCustomerStore(state => state.isViewing);

  const onDelete = (id: string) => {
    const liquidReceipts = form.getValues('liquid_receipt') as LiquidReceiptFormData[];
    form.setValue(
      'liquid_receipt',
      liquidReceipts.filter(liquidReceipt => liquidReceipt.id !== id),
    );
  };

  const handleDelete = (liquidReceipt: LiquidReceiptFormData) => {
    alert({
      type: 'warning',
      title: t('delete-liquid-receipt'),
      description: t('delete-liquid-receipt-confirm'),
      onOk: async () => {
        if (liquidReceipt.id) await onDelete(liquidReceipt.id.toString());
        alert({
          type: 'success',
          title: t('liquid-receipt-deleted'),
          description: '',
        });
      },
    });
  };

  const columns: ColumnDef<LiquidReceiptFormData>[] = [
    {
      accessorKey: 'livestock',
      header: t('pages.registered-customers.form.pf.net_revenues.livestock'),
      cell: ({ row }) => {
        const liquidReceipt = row.original as LiquidReceiptFormData;
        return Formatter.money(liquidReceipt.livestock, 2, 'R$');
      },
    },
    {
      accessorKey: 'livestock_commentary',
      header: t('pages.registered-customers.form.pf.net_revenues.livestock_commentary'),
    },
    {
      accessorKey: 'other',
      header: t('pages.registered-customers.form.pf.net_revenues.other'),
      cell: ({ row }) => {
        const liquidReceipt = row.original as LiquidReceiptFormData;
        return Formatter.money(liquidReceipt.other, 2, 'R$');
      },
    },
    {
      accessorKey: 'other_commentary',
      header: t('pages.registered-customers.form.pf.net_revenues.other_commentary'),
    },
    {
      id: 'actions',
      enablePinning: true,
      accessorKey: 'action',
      size: 100,
      header: t('actions'),
      cell: ({ row }) => {
        const partner = row.original as LiquidReceiptFormData;

        return (
          <ShouldRender condition={!isViewing}>
            <IconWithTooltip
              permission="credit.request.package.destroy"
              action="destroy"
              title={t('pages.registered-customers.table.icons.remove')}
              // loading={partner.id && partnerToDelete === partner.id}
              onClick={() => handleDelete(partner)}
            />
            <Can I="credit.request.package.update">
              <IconWithTooltip
                action="edit"
                onClick={() => {
                  setLiquidReceiptToEdit(partner);
                  setIsLiquidReceiptModalOpen(true);
                }}
                title={t('pages.registered-customers.table.icons.edit')}
              />
            </Can>
          </ShouldRender>
        );
      },
    },
  ];

  return <DataTable columns={columns} data={liquidReceipts ?? []} />;
};

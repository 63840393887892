import styled from 'styled-components';

export const Container = styled.div`
  padding: 5px 10px;
  display: flex;
  gap: 5px;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  overflow-y: auto;
  height: calc(100vh - 75px);
`;

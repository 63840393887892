import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FaPlusSquare } from 'react-icons/fa';

import { Pagination } from 'antd';

import { CacheOptions } from '../../../../../types/Hooks/cache';
import { KanbanColumnPersonLegal, KanbanColumnPersonPhysical } from '../../../../../@types/responseKanban/KanbanColumn';
import ApiPagination from '../../../../../@types/responseKanban/Pagination';
import { useCache } from '../../../../../hooks/cache';
import api from '../../../../../services/api/person';
import Card from '../Card';
import { Filter } from '../types';
import { Container } from './styles';
import { ColumnProps } from './types';

const Column: React.FC<ColumnProps> = ({
  title,
  statusColumn,
  data,
  person,
  expandColumn,
  refreshKanban,
  hidden,
  minimized,
  filterValues,
}) => {
  const { t } = useTranslation();
  const { getPersonOptions } = useCache();

  const [, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [column, setColumn] = useState<ApiPagination<KanbanColumnPersonLegal | KanbanColumnPersonPhysical>>(data);
  const [cards, setCards] = useState<(KanbanColumnPersonLegal | KanbanColumnPersonPhysical)[]>([]);
  const [filter] = useState<Filter>(filterValues);
  const [statusOptions, setStatusOptions] = useState<CacheOptions[]>([]);

  useEffect(() => {
    setCards(column.data);
    setTotal(column.total);
    setStatusOptions(getPersonOptions('person_stage'));
  }, [column, getPersonOptions]);

  const removeCard = useCallback((card_id: string) => {
    setCards(cardsState => cardsState.filter(card => card.id !== card_id));
  }, []);

  function renderCards() {
    const dataRendered = cards.map(d => {
      return <Card key={d.id} data={d} model={person} refreshKanban={refreshKanban} removeFromColumn={removeCard} />;
    });

    return dataRendered;
  }

  const handleChangePagination = useCallback(
    async (page: number, pageSize: number) => {
      setLoading(true);

      const filters: any = {};
      const dataFilter: any = filter;

      if (dataFilter) {
        Object.keys(dataFilter).forEach((key: string) => {
          filters[key] = dataFilter[key];
        });
      }

      const params = {
        page,
        per_page: pageSize,
        ...filters,
      };

      const status = statusOptions.find(x => x.slug === statusColumn);
      if (status) {
        await api[person].getKanbanByColumn(status.value, { params }).then(apiResponse => {
          const dataColumn = apiResponse.data;
          setColumn(dataColumn.data as ApiPagination<KanbanColumnPersonLegal | KanbanColumnPersonPhysical>);
        });
      }

      setLoading(false);
    },
    [person, filter, statusOptions, statusColumn],
  );

  return (
    <Container hidden={hidden} minimized={minimized}>
      <header>
        <div>
          {minimized && <FaPlusSquare onClick={() => expandColumn(statusColumn)} />}
          {t(title)}
        </div>{' '}
        <span>{total}</span>
      </header>

      <div className="content">{cards && cards.length > 0 && renderCards()}</div>

      {column && column.total > Number(column.per_page) && (
        <div className="pagination">
          <Pagination
            size="small"
            pageSize={Number(column.per_page)}
            current={column.current_page}
            total={column.total}
            hideOnSinglePage
            showLessItems
            showSizeChanger={false}
            onChange={handleChangePagination}
          />
        </div>
      )}
    </Container>
  );
};

export default Column;

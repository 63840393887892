import React, { forwardRef, useEffect, useImperativeHandle } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ComboBox } from 'components/ui/combo';
import { Form } from 'components/ui/form';
import { FormGrid } from 'components/ui/form-grid';
import { FormItem } from 'components/ui/form-item';
import { Input, InputNumber, InputWithMask } from 'components/ui/input';
import { useCache } from 'hooks/cache';

import { useRegisteredCustomerStore } from '../stores/use-registered-customer-store';

type Props = {
  onSuccess?: (data: MainClientFormData) => void;
};

export type MainClientFormData = {
  id?: string;
  name?: string;
  state_id?: string;
  city_id?: string;
  phone_number?: string;
  contact_name?: string;
  product_name?: string;
  percentage_without_sales?: number;
  json_data?: {
    product_name?: string;
    percentage_without_sales?: number;
  };
};

export const mainClientInitialValues = {
  id: '',
  name: '',
  state_id: '',
  city_id: '',
  phone_number: '',
  contact_name: '',
  product_name: '',
  percentage_without_sales: 0,
  json_data: {
    product_name: '',
    percentage_without_sales: 0,
  },
};

export const MainClientForm = forwardRef(({ onSuccess }: Props, ref) => {
  const { t } = useTranslation();
  const cache = useCache();
  const form = useForm<MainClientFormData>({
    defaultValues: mainClientInitialValues,
    mode: 'onBlur',
  });

  const stateId = form.watch('state_id');

  const stateOptions = cache.getCreditOptions('state');
  const cityOptions = cache.getCityByStateOptions(stateId);

  const mainClientToEdit = useRegisteredCustomerStore(state => state.mainClientToEdit);

  const sendForm = (values: MainClientFormData) => {
    onSuccess?.(values);
  };

  const submit = () => {
    form.handleSubmit(sendForm, console.log)();
  };

  useImperativeHandle(ref, () => ({
    form: form as UseFormReturn<MainClientFormData>,
    submit,
  }));

  useEffect(() => {
    form.reset(mainClientToEdit || mainClientInitialValues);
  }, [mainClientToEdit]);

  return (
    <Form form={form} onSubmit={form.handleSubmit(sendForm)}>
      <FormGrid maxCols={2}>
        <FormItem
          label={t('pages.registered-customers.form.pj.provider_reference.name')}
          name="name"
          rules={{ required: true }}
        >
          <Input maxLength={255} />
        </FormItem>

        <FormItem
          label={t('pages.registered-customers.form.pj.provider_reference.state_id')}
          name="state_id"
          rules={{ required: true }}
        >
          <ComboBox
            options={stateOptions}
            onChange={value => {
              if (value) form.setValue('city_id', undefined);
            }}
          />
        </FormItem>

        <FormItem
          label={t('pages.registered-customers.form.pj.provider_reference.city_id')}
          name="city_id"
          rules={{ required: true }}
        >
          <ComboBox options={cityOptions} disabled={!stateId} />
        </FormItem>

        <FormItem label={t('pages.registered-customers.form.pj.provider_reference.phone_number')} name="phone_number">
          <InputWithMask mask="(99) 9999-9999" placeholder="(__) ____-____" />
        </FormItem>

        <FormItem label={t('pages.registered-customers.form.pj.provider_reference.contact_name')} name="contact_name">
          <Input maxLength={255} />
        </FormItem>

        <FormItem
          label={t('pages.registered-customers.form.pj.customer_reference.product_name')}
          name="product_name"
          rules={{ required: true }}
        >
          <Input />
        </FormItem>

        <FormItem
          label={t('pages.registered-customers.form.pj.customer_reference.percentage_without_sales')}
          name="percentage_without_sales"
          rules={{ required: true }}
        >
          <InputNumber maxLength={8} min={0} suffix="%" />
        </FormItem>
      </FormGrid>
    </Form>
  );
});

MainClientForm.displayName = 'MainClientForm';

import styled from 'styled-components';

export const SFilterContainer = styled.div`
  .grid-filters {
    display: flex;
    padding: 8px 0;
    width: 100%;
    .filter-search {
      width: 100%;
    }
    .filter-button {
      align-self: center;
      margin-top: 24px;
    }
    .filter-clear {
      align-self: center;
      margin-top: 24px;
      margin-left: 5px;
    }
  }
`;

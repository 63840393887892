import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaSearch } from 'react-icons/fa';

import { Form } from 'antd';
import { useForm } from 'antd/lib/form/Form';

import { Input, Select, Button, FormItem } from '../../../../../components';
import { SFilterContainer } from './styles';
import { FilterProps } from './types';

export const Filter = ({ handleFastSearch }: FilterProps) => {
  const [form] = useForm();
  const { t } = useTranslation();

  const tableDataClearAllFilters = () => {
    form.resetFields();
    handleFastSearch({});
  };

  const handleFilter = () => handleFastSearch(form.getFieldsValue());

  return (
    <SFilterContainer>
      <Form form={form} name="filter-owner" className="form-secondary form-filters grid-filters">
        <div className="filter-search">
          <FormItem name="search" label={t('pages.edocuments.contracttypes.search')}>
            <Input placeholder={t(`pages.edocuments.contracttypes.filters.search`)} maxLength={255} />
          </FormItem>
        </div>
        <div className="filter-search2">
          <FormItem label="" name="parent" className="form-item-without-label">
            <Select
              defaultValue="all"
              options={[
                { value: 'all', label: t('pages.monitoring.cpr.filters.all') },
                { value: 'parent', label: t('pages.edocuments.contracttypes.filters.main') },
                { value: 'notparent', label: t('pages.edocuments.contracttypes.filters.notmain') },
              ]}
            />
          </FormItem>
        </div>
        <div className="filter-button">
          <FormItem label="" className="form-item-without-label">
            <Button status="primary" text="uppercase" htmlType="submit" onClick={handleFilter}>
              <FaSearch /> {t('form.actions.search')}
            </Button>
          </FormItem>
        </div>
        <div className="filter-clear">
          <Button status="secondary" size="middle" onClick={tableDataClearAllFilters}>
            {t('pages.edocuments.contracttypes.buttonClearFilter')}
          </Button>
        </div>
      </Form>
    </SFilterContainer>
  );
};

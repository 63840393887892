import React from 'react';
import { BiLinkExternal } from 'react-icons/bi';
import { FaArrowLeft } from 'react-icons/fa';

import { Tooltip } from 'antd';

import { AttachmentIconWrapper } from '../../../../../components/AttachmentIconWrapper';
import { useContractContext } from '../../../../../context/ContractContext';
import { Title } from './styles';

interface Props {
  hideUpload: () => void;
  shouldShowUpload: boolean;
  shouldShowMainContent: boolean;
  isCompare?: boolean;
}

export const Header = ({ hideUpload, shouldShowUpload, shouldShowMainContent, isCompare = false }: Props) => {
  const { showAttachmentCompare, hideAttachmentCompare, contract } = useContractContext();

  return (
    <Title>
      {shouldShowUpload && (
        <span style={{ display: 'flex', cursor: 'pointer' }} onClick={hideUpload}>
          <FaArrowLeft />
        </span>
      )}

      {isCompare ? (
        <>
          {shouldShowMainContent && (
            <span style={{ display: 'flex', cursor: 'pointer' }} onClick={hideAttachmentCompare}>
              <FaArrowLeft />
            </span>
          )}
          <span>Anexos do Contrato {contract?.number}</span>
        </>
      ) : (
        <>
          <span>Anexos do Contrato </span>
          <Tooltip title="Abrir comparação">
            {shouldShowMainContent && (
              <span onClick={showAttachmentCompare}>
                <AttachmentIconWrapper>
                  <BiLinkExternal />
                </AttachmentIconWrapper>
              </span>
            )}
          </Tooltip>
        </>
      )}
    </Title>
  );
};

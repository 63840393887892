import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FormItem, MaskedInput } from 'components';
import { useCreditRequestContext } from 'context/CreditRequestContext';
import { useFindProvider } from 'pages/CreditRequest/hooks/useFindProvider';
import { useCreditRequestStore } from 'pages/CreditRequest/store/useCreditRequestStore';

export const RequesterPersonDocumentInput = () => {
  const { t } = useTranslation();
  const ref = useRef();
  const { creditForm } = useCreditRequestContext();

  const pageStatus = useCreditRequestStore(state => state.pageStatus);

  const [isLegal, setIsLegal] = useState(false);

  const documentValue = creditForm.getFieldValue('requester_person.document');

  const { findProvider } = useFindProvider({
    personType: isLegal ? 'legal' : 'physical',
  });

  useEffect(() => {
    if (documentValue) {
      const rawValue = documentValue.replace(/\D/g, '');
      if (rawValue.length > 11) {
        setIsLegal(true);
        creditForm.setFieldsValue({ 'requester_person.document': rawValue });
      } else {
        setIsLegal(false);
      }
    }
  }, [documentValue]);

  useEffect(() => {
    if (pageStatus.new) return;
    const rawValue = documentValue?.replace(/\D/g, '');
    if (rawValue) creditForm.setFieldsValue({ 'requester_person.document': rawValue });
  }, [isLegal, documentValue]);

  return (
    <FormItem
      label={t('pages.credit-request.form.requester_person_document')}
      name="requester_person.document"
      rules={[{ required: true }]}
    >
      {isLegal || pageStatus.new ? (
        <MaskedInput
          key="legal"
          ref={ref}
          name="requester_person.document"
          mask={!isLegal && pageStatus.new ? '111.111.111-11' : '11.111.111/1111-11'}
          placeholder={isLegal ? '__.___.___/____-__' : '___.___.___-__'}
          onBlur={() => {
            if (creditForm.getFieldValue('requester_person.document')?.replace(/\D/g, '').length > 10) findProvider();
          }}
          onPasteCapture={evt => {
            evt.clipboardData.items[0].getAsString(text => {
              const rawValue = text.replace(/\D/g, '');
              if (rawValue.length <= 11) setIsLegal(false);
              if (rawValue.length > 11) setIsLegal(true);
              creditForm.setFieldsValue({ 'requester_person.document': rawValue });
            });
          }}
          onKeyDownCapture={evt => {
            const rawValue = evt.currentTarget.value.replace(/\D/g, '');
            if (rawValue.length === 11 && evt.key.match(/\d/g)) setIsLegal(true);
            if (rawValue.length === 12 && evt.key === 'Backspace') setIsLegal(false);
          }}
          onChange={e => {
            if (e.currentTarget.value.length === 0) setIsLegal(false);
          }}
          disabled={!pageStatus.new}
        />
      ) : (
        <MaskedInput
          key="physical"
          ref={ref}
          name="requester_person.document"
          mask="111.111.111-11"
          placeholder="___.___.___-__"
          onBlur={() => {
            if (creditForm.getFieldValue('requester_person.document')?.replace(/\D/g, '').length > 10) findProvider();
          }}
          onPasteCapture={evt => {
            evt.clipboardData.items[0].getAsString(text => {
              const rawValue = text.replace(/\D/g, '');
              if (rawValue.length <= 11) setIsLegal(false);
              if (rawValue.length > 11) setIsLegal(true);
              creditForm.setFieldsValue({ 'requester_person.document': rawValue });
            });
          }}
          onKeyDownCapture={evt => {
            const rawValue = evt.currentTarget.value.replace(/\D/g, '');
            if (rawValue.length === 11 && evt.key.match(/\d/g)) setIsLegal(true);
            if (rawValue.length === 12 && evt.key === 'Backspace') setIsLegal(false);
          }}
          onChange={e => {
            if (e.currentTarget.value.length === 0) setIsLegal(false);
          }}
          disabled={!pageStatus.new}
        />
      )}
    </FormItem>
  );
};

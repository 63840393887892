import React from 'react';

import { Checkbox } from 'antd';
import type { ContractSubscriberData } from 'types/Contract/ContractSubscriberData';

import { Container } from './styles';

interface Props {
  signature: ContractSubscriberData;
  onClick: (signature: ContractSubscriberData) => void;
  isSelected: boolean;
}

export const SignatureSelectButton = ({ signature, onClick, isSelected }: Props) => {
  return (
    <Container onClick={() => onClick(signature)}>
      <div style={{ display: 'flex', gap: '10px' }}>
        <Checkbox checked={isSelected} />
        <span>{signature.name}</span>
      </div>
      <span>{signature.email}</span>
      <span>{signature.social_id ?? '-'}</span>
    </Container>
  );
};

import creditApi from 'services/api/credit';

import { useCreditRequestStore } from '../store/useCreditRequestStore';

export const useGetCreditRequestDepositaries = () => {
  const creditRequestId = useCreditRequestStore(state => state.creditRequestId);

  const getDepositaries = async (id?: string) => {
    const response = await creditApi.requests.getDepositary(id || creditRequestId);
    return response.data;
  };

  return {
    getDepositaries,
  };
};

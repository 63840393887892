import React, { useContext } from 'react';

import { Button } from '../Button';
import { ImageViewerContext } from '../context';
import { Container } from './styles';

export const MagnifingButtons = () => {
  const { reduceScale, increaseScale } = useContext(ImageViewerContext);

  return (
    <Container>
      <Button onClick={increaseScale}>+</Button>
      <Button onClick={reduceScale}>-</Button>
    </Container>
  );
};

import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Dialog,
  DialogContent,
  DialogFooterForm,
  DialogHeader,
  DialogPortal,
  DialogTrigger,
} from 'components/ui/dialog';

import { useRegisteredCustomerStore } from '../stores/use-registered-customer-store';
import { mainProviderInitialValues, MainProviderFormData, MainProvidersForm } from './main-providers.form';

type Props = {
  children?: React.ReactNode;
  onSuccess?: (data: MainProviderFormData) => void;
};

export const MainProvidersModal = ({ children, onSuccess }: Props) => {
  const formRef = useRef<any>(null);
  const { t } = useTranslation();

  const isMainProviderModalOpen = useRegisteredCustomerStore(state => state.isMainProviderModalOpen);
  const setMainProviderToEdit = useRegisteredCustomerStore(state => state.setMainProviderToEdit);
  const mainProviderToEdit = useRegisteredCustomerStore(state => state.mainProviderToEdit);
  const setIsMainProviderModalOpen = useRegisteredCustomerStore(state => state.setIsMainProviderModalOpen);

  return (
    <Dialog
      open={isMainProviderModalOpen}
      onOpenChange={value => {
        setIsMainProviderModalOpen(value);
        if (!value) {
          setMainProviderToEdit(null);
          formRef.current?.form.reset(mainProviderInitialValues);
        }
      }}
    >
      <DialogTrigger>{children}</DialogTrigger>
      <DialogPortal>
        <DialogContent className="md:w-[50%] w-[96%]">
          <DialogHeader>
            {mainProviderToEdit ? t('edit') : t('new-m')} {t('main-provider')}
          </DialogHeader>
          <MainProvidersForm
            ref={formRef}
            onSuccess={data => {
              setIsMainProviderModalOpen(false);
              setMainProviderToEdit(null);
              onSuccess?.(data);
              formRef.current?.form.reset(mainProviderInitialValues);
            }}
          />
          <DialogFooterForm
            onConfirm={() => {
              formRef.current?.submit();
            }}
            onCancel={() => {
              formRef.current?.form.reset(mainProviderInitialValues);
              setMainProviderToEdit(null);
              setIsMainProviderModalOpen(false);
            }}
          />
        </DialogContent>
      </DialogPortal>
    </Dialog>
  );
};

import { PaginatedQueryParams, usePaginatedQuery } from 'hooks/usePaginatedQuery';
import { ChangelogData } from 'types/RegisteredCustomer/ChangelogData';

export const useGetRegisteredCustomerChangelogQuery = (
  params: PaginatedQueryParams & {
    customerId: string;
    personType: 'legal' | 'physical';
  },
) => {
  const { customerId, personType, ...rest } = params;
  const url = `${personType === 'legal' ? 'person/legal/changelog' : 'person/physical/changelog'}/${customerId}`;
  return usePaginatedQuery<ChangelogData>({
    queryKey: [url],
    staleTime: 1000 * 60 * 5,
    url: `${url}`,
    params: {
      ...rest,
    },
    enabled: !!customerId,
    permissions: ['credit.request.documentstatus.all'],
  });
};

import { FixedType } from 'rc-table/lib/interface';

export const columns = [
  {
    title: 'pages.edocuments.templates.table.name',
    dataIndex: 'number',
    key: 'number',
  },
  {
    title: 'pages.edocuments.templates.table.type',
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: 'pages.edocuments.contracttypes.table.actions',
    dataIndex: 'action',
    key: 'action',
    width: '100px',
    fixed: 'right' as FixedType,
  },
];

import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100%;

  .ant-spin-nested-loading {
    height: 100%;
    .ant-spin-container {
      height: 100%;
    }
  }
`;

export const Title = styled.h4`
  color: ${({ theme: { colors } }) => colors.primary};
`;

export const Container = styled.div`
  display: grid;
  grid-template-rows: 70% 30%;
  height: 100%;
  padding-top: 20px;
`;

export const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;

  .ant-select-selector,
  .ant-select,
  textarea {
    background: transparent !important;
  }

  .ant-input {
    border-color: ${({ theme: { colors } }) => colors.primary};
    border-radius: 15px;
    height: 320px;
  }
`;

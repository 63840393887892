import React, { useEffect, useState } from 'react';
import { IconContext } from 'react-icons';
import { BsFillExclamationCircleFill } from 'react-icons/bs';
import { HiCheckCircle } from 'react-icons/hi';

import { CONTRACT_SIGNATURE_STATUS_AUTORESPONDED, CONTRACT_SIGNATURE_STATUS_DECLINED } from 'configs/constants';
import randomColor from 'randomcolor';
import { ContractSubscriberStatusData } from 'types/Contract/ContractSubscriberStatusData';

import theme from '../../../styles/theme';

export interface UserIconProps {
  letter: string;
  status?: ContractSubscriberStatusData;
  signedAt?: string;
  offsetSize?: string;
}

export const UserIcon = ({ letter, offsetSize, signedAt, status }: UserIconProps) => {
  const [background, setBackground] = useState(theme.colors.primary);

  const getBadgeType = () => {
    if (signedAt) return <HiCheckCircle enableBackground={theme.colors.white} size={13} color={theme.colors.success} />;
    if (status?.id === CONTRACT_SIGNATURE_STATUS_AUTORESPONDED)
      return (
        <BsFillExclamationCircleFill enableBackground={theme.colors.white} size={13} color={theme.colors.warning} />
      );
    if (status?.id === CONTRACT_SIGNATURE_STATUS_DECLINED)
      return (
        <BsFillExclamationCircleFill enableBackground={theme.colors.white} size={13} color={theme.colors.verydanger} />
      );
    return undefined;
  };

  const badgeType = getBadgeType();

  useEffect(() => {
    setBackground(randomColor({ luminosity: 'dark' }));
  }, []);
  return (
    <div
      className="relative w-[var(--icon-offset)] bg-transparent"
      style={
        {
          '--icon-offset': offsetSize ?? '35px',
        } as React.CSSProperties
      }
    >
      <div
        className="w-[35px] h-[35px] min-w-[35px] rounded-full flex font-bold text-white justify-center items-center bg-[var(--user-icon-background)]"
        style={
          {
            '--user-icon-background': background,
          } as React.CSSProperties
        }
      >
        <span>{letter.toUpperCase()}</span>
      </div>
      <div className="absolute bottom-[-5px] flex right-[-9px] w-[15px] h-[15px] min-w-[15px]">
        {!!badgeType && (
          <IconContext.Provider
            value={{ style: { verticalAlign: 'middle', background: '#fff', borderRadius: '50px' } }}
          >
            {badgeType}
          </IconContext.Provider>
        )}
      </div>
    </div>
  );
};

/* eslint-disable react/no-array-index-key */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineArrowRight } from 'react-icons/ai';

import { TablePaginationConfig } from 'antd/lib/table';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { useDownload } from 'hooks/helpers/useDownload';
import moment from 'moment';

import DTOStatusHistory from '../../../@types/dtos/credit-request/DTOStatusHistory';
import DTOResponsePagination from '../../../@types/dtos/DTOResponsePagination';
import { EmptyComponent, Spin, IconWithTooltip } from '../../../components';
import { TableData } from '../../../compositions';
import { TableProps } from '../../../compositions/TableData/types';
import appConfig from '../../../configs/app';
import { usePage } from '../../../hooks/page';
import sustainabilityApi from '../../../services/api/sustainability';
import SustainabilityHistoryData, {
  SustainabilityFieldsHistoryData,
} from '../../../types/Sustainability/SustainabilityHistoryData';
import renderComponent from '../../../utils/RenderComponent';
import columns from './columns';
import { Container, Row, Data, Body, Info, Status, Change, From, To, Observation, AttachmentButton } from './styles';
import HistoricStatusProps, { DTOStatusHistoryItem } from './types';

const HistoricStatus: React.FC<HistoricStatusProps> = ({ sustainabilityReportId }) => {
  const { t } = useTranslation();
  const { alertStatus } = usePage();
  const { downloadMultiple } = useDownload();

  const [loading, setLoading] = useState(false);
  const [sustainabilityCreatedAt, setSustainabilityCreatedAt] = useState('');
  const [sustainabilityStatusHistory, setSustainabilityStatusHistory] = useState<DTOStatusHistory[]>([]);
  const [sustainabilityHistory, setSustainabilityHistory] = useState<SustainabilityHistoryData[]>([]);
  const [documentLoading, setDocumentLoading] = useState('');

  // Table DATA Config: BEGIN
  const [paginationConfig, setPaginationConfig] = useState<TablePaginationConfig>({
    current: 1,
    total: 1,
    pageSize: 10,
  });
  const tableConfig = {
    search: '',
    filtered: {},
    sorter: {},
  };
  const [tableDataConfig, setTableDataConfig] = useState(tableConfig);

  const getsustainabilityHistory = useCallback((params?: any) => {
    setLoading(false);
    sustainabilityApi.reports
      .getSustainabilityHistory(sustainabilityReportId, { params })
      .then((response: { data: DTOResponsePagination<SustainabilityHistoryData> }) => {
        setSustainabilityHistory([...response.data.data]);
        setPaginationConfig(paginationConfigState => ({
          ...paginationConfigState,
          current: response.data.current_page,
          total: response.data.total,
        }));
      })
      .catch(err => {
        alertStatus(err, 'error');
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line
  }, []);

  const handleTableChange: TableProps<any>['onChange'] = useCallback(
    (
      pagination: TablePaginationConfig,
      filters: Record<string, FilterValue | null>,
      sorter: SorterResult<any> | SorterResult<any>[],
    ) => {
      setLoading(true);
      setTableDataConfig(tableDataConfigState => ({
        ...tableDataConfigState,
        filtered: filters,
        sorter,
      }));

      let direction = '';
      const search: any = {};
      let sort = '';

      if (sorter) {
        const { field, order } = sorter as SorterResult<any>;

        if (order === 'ascend') direction = 'asc';
        if (order === 'descend') direction = 'desc';
        sort = field?.toString();
      }

      if (filters) {
        const dataFilter: any = filters;

        if (dataFilter) {
          Object.keys(dataFilter).forEach((key: string) => {
            if (dataFilter[key] !== null && dataFilter[key] !== undefined && dataFilter[key][0]) {
              search[key] = dataFilter[key][0].toString();
            } else if (dataFilter[key] !== null && dataFilter[key] !== undefined) {
              search[key] = dataFilter[key].toString();
            } else {
              search[key] = '';
            }
          });
        }
      }

      setPaginationConfig(paginationConfigState => ({
        ...paginationConfigState,
        pageSize: pagination.pageSize,
        current: pagination.current,
      }));

      getsustainabilityHistory({
        page: pagination.current,
        per_page: pagination.pageSize,
        sort,
        direction,
        ...search,
      });
    },
    [getsustainabilityHistory],
  );

  useEffect(() => {
    handleTableChange({ current: 1, pageSize: paginationConfig.pageSize }, {}, {}, null);
    // eslint-disable-next-line
  }, [handleTableChange]);
  // Table DATA Config: END

  const getsustainabilityStatusHistory = useCallback(() => {
    setLoading(false);
    sustainabilityApi.reports
      .getStatusHistoryAll(sustainabilityReportId)
      .then((response: { data: any }) => {
        setSustainabilityCreatedAt(response.data.created_at);
        setSustainabilityStatusHistory([...response.data.status_history]);
      })
      .catch(err => {
        alertStatus(err, 'error');
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line
  }, []);

  // eslint-disable-next-line
  useEffect(() => getsustainabilityStatusHistory(), [getsustainabilityStatusHistory]);

  function handleExpandRowHistory(record: SustainabilityHistoryData) {
    return (
      <TableData
        rowKey="field"
        columns={columns.historyRow.map((col: any) => {
          switch (col.key) {
            case 'field':
              return {
                ...col,
                render: (_: any, row: SustainabilityFieldsHistoryData) => {
                  return t(`pages.sustainability.form.${row.field.replace('.', '_')}`);
                },
              };
            case 'new':
              return {
                ...col,
                render: (_: any, row: SustainabilityFieldsHistoryData) => {
                  if (typeof row.new === 'boolean') return row.new ? 'Sim' : 'Não';
                  switch (row.new) {
                    case '1':
                      return 'Sim';
                    case '0':
                      return 'Não';
                    default:
                      return row.new;
                  }
                },
              };
            case 'old':
              return {
                ...col,
                render: (_: any, row: SustainabilityFieldsHistoryData) => {
                  if (typeof row.old === 'boolean') return row.old ? 'Sim' : 'Não';

                  switch (row.old) {
                    case '1':
                      return 'Sim';
                    case '0':
                      return 'Não';
                    default:
                      return row.old;
                  }
                },
              };
            default:
              return col;
          }
        })}
        dataSource={record.values}
        tableConfig={{ nopagination: true }}
      />
    );
  }

  function getTimeDiff(dateTimeDiff: string, dateTimeCurrent: string): { diffTime: number; diffTimeUnity: string } {
    let diffTime = moment(dateTimeDiff).diff(dateTimeCurrent, 'days');
    let diffTimeUnity = 'in_days';

    if (diffTime === 0) {
      diffTime = moment(dateTimeDiff).diff(dateTimeCurrent, 'hours');
      diffTimeUnity = 'in_hours';
      if (diffTime === 0) {
        diffTime = moment(dateTimeDiff).diff(dateTimeCurrent, 'minutes');
        diffTimeUnity = 'in_minutes';
        if (diffTime === 0) {
          diffTime = moment(dateTimeDiff).diff(dateTimeCurrent, 'seconds');
          diffTimeUnity = 'in_seconds';
        }
      }
    }

    return { diffTime, diffTimeUnity };
  }

  const timelineStatus = useMemo(() => {
    if (sustainabilityStatusHistory) {
      const historicInOrder = sustainabilityStatusHistory.map(
        ({ created_at, from, id, user, sustainability_report_status, comments, documents_status }: any) => ({
          created_at,
          from: sustainability_report_status !== null ? sustainability_report_status.name : from,
          doc: documents_status || null,
          comments,
          id,
          user: {
            ...user,
            name: `${user.name.substr(0, 30)}${Number(user.name.length) > 30 ? '...' : ''}`,
          },
        }),
      );

      const timeline = historicInOrder.reverse().map((item: DTOStatusHistoryItem, index) => {
        const nextItem: DTOStatusHistoryItem | null = historicInOrder[index + 1];
        let newData: DTOStatusHistoryItem = {};
        if (nextItem) {
          const { diffTime, diffTimeUnity } = getTimeDiff(nextItem.created_at, item.created_at);
          newData = {
            ...item,
            time: diffTime,
            unity: diffTimeUnity,
          };
        } else {
          newData = {
            ...item,
          };
        }
        return newData;
      });
      return timeline;
    }

    return [];
    // eslint-disable-next-line
  }, [sustainabilityStatusHistory, sustainabilityCreatedAt]);

  return (
    <>
      {renderComponent(
        loading,
        <Spin spinning={loading} />,
        <Container>
          <h2>{t('pages.credit-request.historic.status_history_title')}</h2>
          <div className="historic-status-bar-container">
            {renderComponent(
              timelineStatus.length > 0,
              timelineStatus.map((item: DTOStatusHistoryItem, index) => (
                <>
                  {renderComponent(
                    index < timelineStatus.length - 1,
                    <div className="historic-status-bar-item" key={`${index}-${item.id}`}>
                      <div className="historic-status-ball" />
                      <div className="historic-status-ball-label-top">
                        <div>{item.from}</div>
                      </div>
                      <div className="historic-status-ball-label-bottom">
                        {moment(item.created_at).format(
                          `${appConfig.formatDate} [${t('pages.credit-request.historic.times.time_at')}] ${
                            appConfig.formatTime
                          }`,
                        )}
                      </div>
                      <div className="historic-status-bar">
                        <div className="historic-status-bar-label-top">{item.user.name}</div>
                        <div className="historic-status-bar-label-bottom">
                          {`${item.time} ${t(`pages.credit-request.historic.times.${item.unity}`)}`}
                        </div>
                      </div>
                    </div>,
                  )}
                  {renderComponent(
                    index === timelineStatus.length - 1,
                    <div className="historic-status-bar-item historic-status-bar-item-last" key={`${index}-${item.id}`}>
                      <div className="historic-status-ball" />
                      <div className="historic-status-ball-label-top">
                        <div>{item.from}</div>
                      </div>
                      <div className="historic-status-ball-label-bottom">
                        {moment(item.created_at).format(
                          `${appConfig.formatDate} [${t('pages.credit-request.historic.times.time_at')}] ${
                            appConfig.formatTime
                          }`,
                        )}
                      </div>
                    </div>,
                  )}
                </>
              )),
              <div className="historic-empty">
                <EmptyComponent description={t('pages.credit-request.historic.empty')} />
              </div>,
            )}
          </div>

          <Container>
            {renderComponent(
              timelineStatus.length > 0,
              timelineStatus.map((item: DTOStatusHistoryItem, index) => (
                <>
                  <Row key={item.id}>
                    <Data>{moment(item.created_at).format(`${appConfig.formatDate} ${appConfig.formatTime}`)}</Data>
                    <Body>
                      <Info>{item.user.name}</Info>
                      <Status>
                        <Change>
                          <From>{index >= 1 ? timelineStatus[index - 1].from : ''}</From>
                          {index >= 1 && <AiOutlineArrowRight />}
                          <To>{item.from}</To>
                        </Change>
                        {item.comments && <Observation>{item.comments}</Observation>}
                      </Status>
                      {item.doc.length > 0 && (
                        <AttachmentButton>
                          <IconWithTooltip
                            action="download"
                            title={t('pages.credit-request.table.icons.download')}
                            loading={item.doc && documentLoading === item.doc}
                            onClick={async () => {
                              setDocumentLoading(item.doc);
                              await downloadMultiple(item.doc.map((document: any) => document.id));
                              setDocumentLoading(null);
                            }}
                          />
                        </AttachmentButton>
                      )}
                    </Body>
                  </Row>
                </>
              )),
            )}
          </Container>
          <h2>{t('pages.credit-request.historic.change_history_title')}</h2>
          <div className="historic-changelog-container">
            <TableData
              rowKey="id"
              columns={columns.history.map((column: any) => {
                let columnRender: any = {
                  ...column,
                };

                if (column.dataIndex === 'updated_at') {
                  columnRender = {
                    ...columnRender,
                    render: (value: any) => value && moment(value).format(appConfig.formatDateFullTime),
                  };
                }

                return columnRender;
              })}
              loading={loading}
              expandable={{ expandedRowRender: handleExpandRowHistory }}
              onChange={handleTableChange}
              dataSource={sustainabilityHistory}
              pagination={paginationConfig}
              tableConfig={tableDataConfig}
            />
          </div>
        </Container>,
      )}
    </>
  );
};

export default HistoricStatus;

import React from 'react';
import { useTranslation } from 'react-i18next';

import { ComboBox } from 'components/ui/combo';
import { FormGrid } from 'components/ui/form-grid';
import { FormItem } from 'components/ui/form-item';
import { GroupForm } from 'components/ui/group-form';
import { useCache } from 'hooks/cache';

import { useRegisteredCustomerStore } from './stores/use-registered-customer-store';

export const RegisteredCustomerReferenceSquareSection = () => {
  const { t } = useTranslation();
  const cache = useCache();

  const referenceSquareOptions = cache.getPersonOptions('reference_square');

  const isFinalizing = useRegisteredCustomerStore(state => state.isFinalizing);
  const isViewing = useRegisteredCustomerStore(state => state.isViewing);
  const isModulePerson = useRegisteredCustomerStore(state => state.isModulePerson);

  return (
    <GroupForm title={t('square-reference')}>
      <FormGrid maxCols={1}>
        <FormItem
          label={t('pages.registered-customers.form.pf.reference_square')}
          name="json_data.reference_square_id"
          rules={{ required: isFinalizing }}
        >
          <ComboBox options={referenceSquareOptions} disabled={isViewing || !isModulePerson} />
        </FormItem>
      </FormGrid>
    </GroupForm>
  );
};

import { useMutation } from '@tanstack/react-query';
import api from 'services/api/api';

export const useGetDraftMutation = () => {
  return useMutation({
    mutationKey: ['getDraft'],
    mutationFn: async (draftId: string) => {
      const response = await api.get(`envelope/open/${draftId}`);
      if (response.status !== 200) throw new Error('Error fetching draft');

      return response.data?.data?.[0];
    },
  });
};

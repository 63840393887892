import { FixedType } from 'rc-table/lib/interface';

export const columns = [
  {
    title: 'pages.sustainability.table.created_at',
    dataIndex: 'created_at',
    key: 'created_at',
    sorter: false,
    filtered: false,
  },
  {
    title: 'pages.sustainability.table.user',
    dataIndex: 'user',
    key: 'user',
    sorter: false,
    filtered: false,
  },
  {
    title: 'pages.sustainability.table.volume',
    dataIndex: 'volume',
    key: 'volume',
    sorter: false,
    filtered: false,
  },
  {
    title: 'pages.sustainability.table.status',
    dataIndex: 'sustainability_report_status',
    key: 'sustainability_report_status',
    sorter: true,
  },
  {
    title: 'pages.sustainability.table.decision',
    dataIndex: 'sustainability_decision',
    key: 'sustainability_decision',
    sorter: true,
  },
  {
    title: 'pages.sustainability.table.actions',
    dataIndex: 'action',
    key: 'action',
    width: '100px',
    fixed: 'right' as FixedType,
  },
];

import React from 'react';
import { useTranslation } from 'react-i18next';

import { SERASA_STATUS_WITHOUT_RESTRICTION, SERASA_STATUS_WITH_RESTRICTION } from 'configs/constants';

import { Container, Value } from './styles';

interface Props {
  serasaStatus: string;
  fullSize?: boolean;
  thick?: boolean;
  document?: string;
}

export const SerasaRestriction = ({ serasaStatus, fullSize = false, thick = false, document }: Props) => {
  const { t } = useTranslation();

  const shouldShowCard = serasaStatus !== SERASA_STATUS_WITHOUT_RESTRICTION;
  const hasRestriction = serasaStatus === SERASA_STATUS_WITH_RESTRICTION;

  const handleRedirectToRestrictions = () =>
    document && window.open(`/serasa/counterparty?search=${document.replace(/\D/g, '')}`, '_blank');

  return shouldShowCard ? (
    <Container fullSize={fullSize} thick={thick} isRestriction={hasRestriction}>
      <Value thick={thick} onClick={handleRedirectToRestrictions}>
        {hasRestriction
          ? t('components.serasaRestriction.withRestriction')
          : t('components.serasaRestriction.inAnalysis')}
      </Value>
    </Container>
  ) : (
    <div />
  );
};

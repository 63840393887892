import React from 'react';

import { handleRequest } from 'services/api/api';
import creditApi from 'services/api/credit';

export const useVerifyPackageIntegrity = () => {
  const [isVerifying, setIsVerifying] = React.useState(false);

  const verify = async (id: string) => {
    const response = await handleRequest(() => creditApi.verifyPackageIntegrity.get(id), setIsVerifying);
    return response ?? null;
  };

  return { verify, isVerifying };
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserRouter as Router } from 'react-router-dom';

import { ConfigProvider, Spin as AntSpin } from 'antd';
// eslint-disable-next-line import-helpers/order-imports
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import ptBRLocale from 'antd/es/locale/pt_BR';
import './services/i18next';
import './assets/css/index.css';
import { Toaster } from 'components/ui/toaster';
import { TooltipProvider } from 'components/ui/tooltip';
import { ValidateMessages } from 'rc-field-form/lib/interface';
import { ThemeProvider } from 'styled-components';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { Spin } from './components';
import AppProviders from './hooks/index';
import Routes from './routes';
import { messages } from './services/messages';
import GlobalStyle from './styles/global';
import theme from './styles/theme';

const queryClient = new QueryClient();

const App: React.FC = () => {
  AntSpin.setDefaultIndicator(Spin);

  const { i18n } = useTranslation();
  const validateMessages: ValidateMessages = messages(i18n.language);

  return (
    <Router>
      <ThemeProvider theme={theme}>
        <TooltipProvider>
          <QueryClientProvider client={queryClient}>
            <ConfigProvider form={{ validateMessages }} locale={ptBRLocale}>
              <AppProviders>
                <Routes />
              </AppProviders>
            </ConfigProvider>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
          <GlobalStyle />
        </TooltipProvider>
      </ThemeProvider>
      <Toaster />
    </Router>
  );
};

export default App;

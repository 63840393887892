import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaSearch } from 'react-icons/fa';

import { TableData, TableTranslation, useTable } from '@agrodatabr/agrodataui';
import { Badge, Form, TablePaginationConfig } from 'antd';
import { Breadcrumb, Button, FormItem, IconWithTooltip, Input } from 'components';
// import { Can } from 'hooks/ability';
import appConfigs from 'configs/app';
import { useDataProvidersIntegration } from 'hooks/fetch/useDataProvidersIntegration';
import { useIntegrationGroups } from 'hooks/fetch/useIntegrationGroups';
import { useExpandable } from 'hooks/helpers/useExpandable';
import moment from 'moment';
import { useTheme } from 'styled-components';

import {
  DataIntegrationErros,
  IntegrationDataProvider,
  IntegrationDataProviderStatus,
  DataIntegrationAttachment,
} from '../../../@types/data/IntegrationDataProvider';
import columns from './columns';
import { ExpandedRow } from './ExpandedRow';
import { FileViewModal } from './FileViewModal';
import { ModalErrors } from './ModalErrors';
import { SContainer, SFilterContainer, SHeader, STitlePage } from './styles';

export const DataProviders = () => {
  const { t } = useTranslation();
  const [searchForm] = Form.useForm();
  const { isFetching, forceIntegration, isForcing } = useDataProvidersIntegration();
  const { getGroups, isFetching: isFetchingGroups } = useIntegrationGroups();
  const theme = useTheme();
  const controller = useTable();
  const { onExpand, expandedRowKeys } = useExpandable(true);

  const [filters, setFilters] = useState({});
  const [listErrosIntegration, setListErrorsIntegration] = useState<DataIntegrationErros[]>([]);
  const [modalErrorIntegrationVisible, setModalErrorIntegrationVisible] = useState(false);
  const [dataAttachment, setDataAttachment] = useState<DataIntegrationAttachment[]>([]);
  const [modalFileViewVisible, setModalFileViewVisible] = useState<boolean>(false);
  const [sourceIntegration, setSourceIntegration] = useState({ source_name: '', source_type: '' });

  const handleFastSearch = () => {
    const value = searchForm.getFieldsValue();
    setFilters(value);
  };

  const tableDataClearAllFilters = () => {
    searchForm.resetFields();
    setFilters({});
  };

  const searchRequest = async (pagination: TablePaginationConfig, requestFilters: any) => {
    const response = await getGroups({
      params: { page: pagination.current, per_page: pagination.pageSize, ...requestFilters },
    });

    if (!response) return { data: [], total: 0 };

    return { data: response.data, total: response.total };
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'processing':
        return theme.colors.warning;
      case 'processed':
        return theme.colors.success;
      case 'failed':
        return theme.colors.danger;
      default:
        return theme.colors.text;
    }
  };

  const getActions = () => ({
    render: (_1: number, integrationData: IntegrationDataProvider) => (
      <>
        {integrationData?.attachments ? (
          <IconWithTooltip
            action="detail"
            title={t('pages.integration.dataProviders.table.icons.view')}
            onClick={() => {
              setDataAttachment(integrationData.attachments);
              setModalFileViewVisible(true);
              setSourceIntegration({
                source_name: integrationData.group.name,
                source_type: integrationData.group.origin,
              });
            }}
          />
        ) : (
          <IconWithTooltip action="fake" />
        )}
        {integrationData?.status === IntegrationDataProviderStatus.FAILED ? (
          // <Can I="" a="">
          <IconWithTooltip
            action="refresh"
            title={t('pages.integration.dataProviders.table.icons.refresh')}
            onClick={async () => {
              if (await forceIntegration(integrationData.id)) controller.refresh();
            }}
          />
        ) : (
          <IconWithTooltip action="fake" />
        )}
        {
          integrationData?.errors?.length ? (
            // <Can I="" a="">
            <Badge count={integrationData.errors.length} size="small" offset={[-8, 5]}>
              <IconWithTooltip
                action="error"
                title={t('pages.integration.dataProviders.table.icons.errors')}
                onClick={() => {
                  setListErrorsIntegration(integrationData.errors);
                  setModalErrorIntegrationVisible(true);
                  setSourceIntegration({
                    source_name: integrationData.group.name,
                    source_type: integrationData.group.origin,
                  });
                }}
              />
            </Badge>
          ) : (
            <IconWithTooltip action="fake" />
          )
          // </Can>
        }
      </>
    ),
  });

  return (
    <>
      <Breadcrumb
        items={[
          { title: t('breadcrumb.integration') },
          { title: t('breadcrumb.dataProviders'), to: '/integrations/dataProviders' },
        ]}
      />

      <SContainer>
        <SHeader>
          <STitlePage>{t('pages.integration.dataProviders.title_page')}</STitlePage>
        </SHeader>

        <SFilterContainer>
          <Form
            form={searchForm}
            name="filter-harvest"
            className="form-secondary form-filters grid-filters"
            onFinish={handleFastSearch}
          >
            <div className="filter-search">
              <FormItem name="search" label={t('pages.register.harvest.search')}>
                <Input maxLength={255} />
              </FormItem>
            </div>
            <div className="filter-button">
              <FormItem label="" className="form-item-without-label">
                <Button status="primary" text="uppercase" htmlType="submit">
                  <FaSearch /> {t('form.actions.search')}
                </Button>
              </FormItem>
            </div>
            <div className="filter-clear">
              <Button status="secondary" size="middle" onClick={tableDataClearAllFilters}>
                {t('pages.register.harvest.buttonClearFilter')}
              </Button>
            </div>
          </Form>
        </SFilterContainer>

        <TableData
          rowKey="id"
          controller={controller}
          searchRequest={searchRequest}
          searchFilters={filters}
          width="100%"
          expandable={{
            expandedRowKeys,
            onExpand: async (expanded, record: any) => {
              onExpand(expanded, record);
              // handleExpandGroup(record.id);
            },
            expandedRowRender: (data: any) => (
              <p style={{ margin: 0 }}>
                <ExpandedRow
                  groupId={data.id}
                  setDataAttachment={setDataAttachment}
                  setModalFileViewVisible={setModalFileViewVisible}
                  setSourceIntegration={setSourceIntegration}
                  setListErrorsIntegration={setListErrorsIntegration}
                  setModalErrorIntegrationVisible={setModalErrorIntegrationVisible}
                />
              </p>
            ),
          }}
          columns={columns.map((column: any) => {
            let columnRender: any = {
              ...column,
              title: t(column.title),
            };

            if (column.dataIndex === 'created_at') {
              columnRender = {
                ...columnRender,
                render: (date: string) => moment(date).format(appConfigs.formatDateFullTime),
              };
            }

            if (column.dataIndex === 'source_name') {
              columnRender = {
                ...columnRender,
                render: (source: string) => source?.toString().toUpperCase(),
              };
            }

            if (column.dataIndex === 'status') {
              columnRender = {
                ...columnRender,
                render: (status: string) => (
                  <strong style={{ color: getStatusColor(status), textTransform: 'uppercase' }}>
                    {t(`pages.integration.dataProviders.status.${status}`)}
                  </strong>
                ),
              };
            }

            return columnRender;
          })}
          loading={isFetching || isForcing || isFetchingGroups}
          tableConfig={{
            getActions,
          }}
          translation={
            {
              pagination: { totalOfRegisters: t('table.totalOfRegisters') },
              filterDropdown: {
                filterActive: 'Ativo',
                filterInactive: 'Inativo',
              },
              filterConfirm: 'Buscar',
              filterReset: 'Limpar',
            } as TableTranslation
          }
          pagination={{ position: ['bottomCenter'] }}
        />

        <ModalErrors
          listErrors={listErrosIntegration}
          setIsVisible={setModalErrorIntegrationVisible}
          isVisible={modalErrorIntegrationVisible}
        />
        <FileViewModal
          dataAttachments={dataAttachment}
          isVisible={modalFileViewVisible}
          setModalFileViewVisible={setModalFileViewVisible}
          sourceIntegration={sourceIntegration}
        />
      </SContainer>
    </>
  );
};

export default DataProviders;

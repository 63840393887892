const columns = [
  {
    title: 'pages.sustainability.parameterization.table.user',
    dataIndex: 'name',
    key: 'name',
    sorter: true,
  },
  {
    title: 'pages.sustainability.parameterization.table.email',
    dataIndex: 'email',
    key: 'email',
    sorter: true,
  },
  {
    title: 'pages.sustainability.parameterization.table.types',
    dataIndex: 'types',
    key: 'types',
    sorter: false,
  },
  {
    title: 'pages.sustainability.parameterization.table.sub_divisions',
    dataIndex: 'products',
    key: 'products',
    sorter: false,
  },
  {
    title: 'pages.sustainability.parameterization.table.status',
    dataIndex: 'status',
    key: 'status',
    sorter: false,
  },
  {
    title: 'pages.admin.user.table.header.action',
    key: 'action',
    dataIndex: 'action',
    align: 'center',
    fixed: 'right',
    width: '150px',
  },
];

export default columns;

import React from 'react';
import { AiFillFileText } from 'react-icons/ai';

import { Badge } from 'antd';

import { IconWrapper } from '../IconWrapper';
import { Container } from './styles';
import { StageButtonProps } from './types';

export const StageTab = ({
  isSelected = false,
  icon = AiFillFileText,
  onClick,
  disabled,
  badgeCount,
}: StageButtonProps) => {
  const Icon = icon;

  return (
    <Container isSelected={isSelected} onClick={!disabled ? onClick : undefined} isDisabled={disabled}>
      <Badge count={badgeCount} size="small">
        <IconWrapper disabled={disabled}>
          <Icon />
        </IconWrapper>
      </Badge>
    </Container>
  );
};

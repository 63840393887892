import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaSearch } from 'react-icons/fa';

import { Form } from 'antd';
import { useForm } from 'antd/lib/form/Form';

import { Input, Button, FormItem } from '../../../../../components';
import { SFilterContainer } from './styles';
import { FilterProps } from './types';

export const Filter = ({ handleFastSearch }: FilterProps) => {
  const [formFilter] = useForm();
  const { t } = useTranslation();

  const tableDataClearAllFilters = () => {
    formFilter.resetFields();
    handleFastSearch();
  };

  const handleFilter = () => {
    const values = formFilter.getFieldsValue();
    handleFastSearch(values);
  };

  return (
    <SFilterContainer>
      <Form form={formFilter} name="filter-owner" className="form-secondary form-filters grid-filters">
        <div className="filter-search">
          <FormItem name="search" label={t('pages.sustainability.team-status.filter.label_search')}>
            <Input placeholder={t(`pages.sustainability.team-status.filter.placeholder_serach`)} />
          </FormItem>
        </div>
        <div className="filter-button">
          <Button status="primary" htmlType="submit" onClick={handleFilter}>
            <FaSearch /> {t('pages.sustainability.team-status.filter.searchButton')}
          </Button>
        </div>
        <div className="filter-clear">
          <Button status="secondary" onClick={tableDataClearAllFilters}>
            {t('pages.sustainability.team-status.filter.clearFiltersButton')}
          </Button>
        </div>
      </Form>
    </SFilterContainer>
  );
};

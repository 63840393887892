import moment from 'moment';

import theme from '../../../styles/theme';

export const propsSkeletonCard = {
  minWidth: ['310px', '310px', '310px', '310px'],
  height: '126px',
  borderRadius: '10px',
  margin: '5px',
  number: 5,
};

export const propsSkeletonCharts = {
  width: '100%',
  minWidth: ['99%', '550px', '550px', '550px'],
  height: '492px',
  borderRadius: '10px',
  margin: '5px',
  number: 4,
};

export const propsFilters = [
  {
    name: 'harvest',
    key: 1,
  },
  {
    name: 'region',
    key: 2,
  },
  {
    name: 'subsidiary',
    key: 3,
  },
  {
    name: 'division',
    key: 4,
  },
  {
    name: 'sub_division',
    key: 5,
  },
  {
    name: 'requester',
    key: 6,
  },
  {
    name: 'requesterDate',
    key: 7,
  },
  {
    name: 'start',
    key: 8,
  },
  {
    name: 'end',
    key: 9,
  },
];

export const useChartProps = () => {
  const propsChartPrepayOperation = (data: any, t: any) => {
    if (Object.keys(data).length) {
      const newData = Object.keys(data).map((item: any) => data[item]);
      const legend = [
        { prop: 'requestedAmount', name: t('pages.dashboard.prepay.creditOperation.legends.requestedAmount') },
        { prop: 'approval', name: t('pages.dashboard.prepay.creditOperation.legends.approval') },
      ];

      const colors = [newData[0].color_requested_amount, newData[0].color_approval];

      const values = (value: string) => {
        switch (value) {
          case 'requestedAmount':
            return newData.map((item: any) => ({
              name: item.region,
              y: parseInt(item.sum_requested_amount, 10),
              drilldown: `${item.region}-${value}`,
            }));
          case 'approval':
            return newData.map((item: any) => ({
              name: item.region,
              y: parseInt(item.sum_approval, 10),
              drilldown: `${item.region}-${value}`,
            }));
          default:
            return '';
        }
      };

      const amount = (value: string) => {
        switch (value) {
          case 'requestedAmount':
            return newData.map((item: any) => ({
              name: item.region,
              y: parseInt(item.count_requested_amount, 10),
              drilldown: `${item.region}-${value}`,
            }));
          case 'approval':
            return newData.map((item: any) => ({
              name: item.region,
              y: parseInt(item.count_approval, 10),
              drilldown: `${item.region}-${value}`,
            }));
          default:
            return '';
        }
      };

      const option1 = legend.map((item: any) => ({
        name: item.name,
        data: values(item.prop),
        typeFormatter: 'abbreviatedValue',
      }));

      const option2 = legend.map((item: any) => ({
        name: item.name,
        data: amount(item.prop),
        typeFormatter: 'default',
      }));

      const drilldownValues = (value: string, dataProp: any) => {
        switch (value) {
          case 'requestedAmount':
            return parseInt(dataProp.sum_requested_amount, 10);
          case 'approval':
            return parseInt(dataProp.sum_approval, 10);
          default:
            return '';
        }
      };

      const drilldownAmount = (value: string, dataProp: any) => {
        switch (value) {
          case 'requestedAmount':
            return parseInt(dataProp.count_requested_amount, 10);
          case 'approval':
            return parseInt(dataProp.count_approval, 10);
          default:
            return '';
        }
      };

      const drilldownAssembleOption1 = newData.map((d: any) => {
        const subsidiary = Object.keys(d.subsidiary).map((item: any) => d.subsidiary[item]);
        return legend.map((l: any) => ({
          name: l.name,
          id: `${d.region}-${l.prop}`,
          data: subsidiary.map((r: any) => [r.name, drilldownValues(l.prop, r)]),
        }));
      });
      const drilldownOption1: any = [];
      drilldownAssembleOption1.forEach((d: any) => d.forEach((dd: any) => drilldownOption1.push(dd)));

      const drilldownAssembleOption2 = newData.map((d: any) => {
        const subsidiary = Object.keys(d.subsidiary).map((item: any) => d.subsidiary[item]);
        return legend.map((l: any) => ({
          name: l.name,
          id: `${d.region}-${l.prop}`,
          data: subsidiary.map((r: any) => [r.name, drilldownAmount(l.prop, r)]),
        }));
      });
      const drilldownOption2: any = [];
      drilldownAssembleOption2.forEach((d: any) => d.forEach((dd: any) => drilldownOption2.push(dd)));

      return {
        key: 1,
        title: t('pages.dashboard.prepay.creditOperation.title'),
        buttons: [
          { label: t('pages.dashboard.prepay.creditOperation.buttons.value'), value: 0 },
          { label: t('pages.dashboard.prepay.creditOperation.buttons.amount'), value: 1 },
        ],
        colors,
        type: 'column',
        // yAxisText: 'Valor',
        buttonOptions: [option1, option2],
        drilldownOptions: [drilldownOption1, drilldownOption2],
        defaultExpand: 'auto',
      };
    }

    return {
      key: 1,
      title: t('pages.dashboard.prepay.creditOperation.title'),
      defaultExpand: 'auto',
    };
  };
  const propsChartPrepayLimit = (data: any, t: any) => {
    if (data.length) {
      const categories = data.map((d: any) => moment(d.date).format('DD/MM/YYYY'));
      const dataBalance = data.map((b: any) => parseInt(b.balance, 10));
      const dataLimit = data.map((l: any) => parseInt(l.prepay_limit, 10));

      categories.reverse();
      dataBalance.reverse();
      dataLimit.reverse();

      return {
        key: 2,
        title: t('pages.dashboard.prepay.prepayLimit.title'),
        colors: [`${theme.colors.darkBlue}`, `${theme.colors.success}`],
        type: 'line',
        rotation: -45,
        categories,
        series: [
          {
            name: t('pages.dashboard.prepay.prepayLimit.g&o'),
            data: dataBalance,
          },
          {
            name: t('pages.dashboard.prepay.prepayLimit.limit'),
            data: dataLimit,
          },
        ],
        marker: false,
        plotLabels: false,
        xAxisVisible: true,
        typeFormatter: 'datetime',
      };
    }

    return {
      key: 2,
      title: t('pages.dashboard.prepay.prepayLimit.title'),
      defaultExpand: 'auto',
    };
  };
  const dataChartPrepayProgress = (data: any, t: any) => {
    if (Object.keys(data).length) {
      const newData = Object.keys(data).map((item: any) => data[item]);
      const option1 = [
        {
          name: t('pages.dashboard.prepay.requestProgress.title'),
          data: newData.map((item: any) => ({
            name: item.team,
            y: parseInt(item.count, 10),
            drilldown: item.team,
          })),
          showInLegend: false,
          typeFormatter: 'default',
        },
      ];

      const option2 = [
        {
          name: t('pages.dashboard.prepay.requestProgress.title'),
          data: newData.map((item: any) => ({
            name: item.team,
            y: parseInt(item.days, 10),
            drilldown: item.team,
          })),
          showInLegend: false,
          typeFormatter: 'intConverter',
        },
      ];

      const drilldownOption1 = newData.map((item: any) => {
        const status = Object.keys(item.status).map((s: any) => item.status[s]);
        const option = {
          name: item.team,
          id: item.team,
          data: status.map((s: any) => [s.status, parseInt(s.count, 10)]),
        };
        return option;
      });

      const drilldownOption2 = newData.map((item: any) => {
        const status = Object.keys(item.status).map((s: any) => item.status[s]);
        const option = {
          name: item.team,
          id: item.team,
          data: status.map((s: any) => [s.status, parseInt(s.days, 10)]),
        };
        return option;
      });

      return {
        key: 3,
        title: t('pages.dashboard.prepay.requestProgress.title'),
        buttons: [
          { label: t('pages.dashboard.prepay.requestProgress.buttons.amount'), value: 0 },
          { label: t('pages.dashboard.prepay.requestProgress.buttons.days'), value: 1 },
        ],
        colors: [`${theme.colors.info}`],
        type: 'column',
        // yAxisText: 'Valor',
        buttonOptions: [option1, option2],
        drilldownOptions: [drilldownOption1, drilldownOption2],
      };
    }

    return {
      key: 3,
      title: t('pages.dashboard.prepay.requestProgress.title'),
    };
  };
  const dataChartPrepayExposition = (data: any, t: any) => {
    const legend = [
      { prop: '30days', name: t('pages.dashboard.prepay.requestsDays.legends.30days') },
      { prop: '60days', name: t('pages.dashboard.prepay.requestsDays.legends.60days') },
      { prop: 'more60days', name: t('pages.dashboard.prepay.requestsDays.legends.more60days') },
    ];
    const colors = [`${data[0].color}`, `${data[1].color}`, `${data[2].color}`];
    const categories = [`${legend[0].name}`, `${legend[1].name}`, `${legend[2].name}`];
    const series = legend.map((item: any, key: any) => ({
      name: item.name,
      data: [parseInt(data[key].balance, 10)],
    }));

    return {
      key: 4,
      title: t('pages.dashboard.prepay.requestsDays.title'),
      colors,
      type: 'column',
      categories,
      series,
      typeFormatter: 'abbreviatedValue',
      xAxisVisible: false,
    };
  };
  return {
    propsChartPrepayOperation,
    propsChartPrepayLimit,
    dataChartPrepayProgress,
    dataChartPrepayExposition,
  };
};

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Modal, Form, Col, Row, Checkbox } from 'antd';

import { Button, FormItem, Input, MaskedInput, ModalStatus } from '../../../../../components';
import { Validate } from '../../../../../utils/Validate';
import { ModalAddProps } from './types';

export const ModalAdd = ({
  setDepositaryList,
  depositaryList,
  setModalAddVisible,
  modalAddVisible,
  valuesEditDepositary,
  setValuesEditDepositary,
}: ModalAddProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [hasSpouse, setHasSpouse] = useState(false);

  useEffect(() => {
    if (valuesEditDepositary) {
      form.setFieldsValue(valuesEditDepositary);
      setHasSpouse(form.getFieldValue('has_spouse'));
    }
  }, [valuesEditDepositary]);

  const addDepositary = () => {
    const data = form.getFieldsValue();
    if (data.cpf === data.spouse_cpf || data.rg === data.spouse_rg) {
      ModalStatus({
        type: 'warning',
        title: t('pages.credit-request.depositary.table-form.warning.DataSameSpouse.title'),
        subTitle: t('pages.credit-request.depositary.table-form.warning.DataSameSpouse.subtitle'),
      });
      return;
    }

    const sameDepositaryList = () => {
      ModalStatus({
        type: 'warning',
        title: t('pages.credit-request.depositary.table-form.warning.DataSameList.title'),
        subTitle: t('pages.credit-request.depositary.table-form.warning.DataSameList.subtitle'),
      });
    };

    if (depositaryList.length) {
      const filterListSame = depositaryList.filter(
        (depositary: any) => depositary.cpf === data.cpf || depositary.rg === data.rg,
      );

      if (filterListSame.length && valuesEditDepositary === undefined) {
        sameDepositaryList();
        return;
      }
      if (valuesEditDepositary) {
        const filterEditListCompare = depositaryList.filter((depositary: any) => depositary.idList !== data.idList);
        const filterEditListSame = filterEditListCompare.filter(
          (depositary: any) => depositary.cpf === data.cpf || depositary.rg === data.rg,
        );
        if (filterEditListSame.length) {
          sameDepositaryList();
          return;
        }
      }
    }
    if (data.idList === undefined) {
      data.status = 0;
      const lastElement = depositaryList[depositaryList.length - 1];
      data.idList = lastElement ? lastElement.idList + 1 : 0;
      setDepositaryList([...depositaryList, data]);
    } else {
      data.status = 1;
      const filterList = depositaryList.filter((depositary: any) => depositary.idList !== data.idList);
      setDepositaryList([...filterList, data]);
    }
    closeModalAdd();
    setHasSpouse(false);
  };

  const closeModalAdd = () => {
    form.resetFields();
    setModalAddVisible(false);
    setValuesEditDepositary();
  };

  return (
    <Modal
      title={
        valuesEditDepositary
          ? t('pages.credit-request.depositary.title-modal.edit')
          : t('pages.credit-request.depositary.title-modal.add')
      }
      visible={modalAddVisible}
      onCancel={() => closeModalAdd()}
      footer={null}
      width={820}
    >
      <Form
        form={form}
        initialValues={{
          has_spouse: false,
        }}
        onFinish={() => addDepositary()}
      >
        <>
          <FormItem hidden name="id">
            <Input />
          </FormItem>
          <FormItem hidden name="idList">
            <Input />
          </FormItem>
          <Row gutter={[8, 8]}>
            <Col xs={24} sm={18} md={12} lg={12} xl={12} xxl={12}>
              <FormItem
                label={t('pages.credit-request.depositary.table-form.depositary_name')}
                name="name"
                rules={[{ required: true }]}
              >
                <Input maxLength={100} />
              </FormItem>
            </Col>
            <Col xs={24} sm={18} md={12} lg={8} xl={6} xxl={6}>
              <FormItem
                label={t('pages.credit-request.depositary.table-form.depositary_rg')}
                name="rg"
                rules={[{ required: true }]}
              >
                <Input maxLength={14} />
              </FormItem>
            </Col>
            <Col xs={24} sm={18} md={12} lg={8} xl={6} xxl={6}>
              <FormItem
                label={t('pages.credit-request.depositary.table-form.depositary_cpf')}
                name="cpf"
                rules={[
                  {
                    required: true,
                  },
                  () => ({
                    validator(_, value) {
                      if (!Validate.cpf(value)) {
                        return Promise.reject(t('pages.credit-request.depositary.table-form.validation.cpf'));
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <MaskedInput name="cpf" mask="111.111.111-11" placeholder="___.___.___-__" />
              </FormItem>
            </Col>
          </Row>
          <Row gutter={[8, 8]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} style={{ margin: '20px 0px' }}>
              <FormItem valuePropName="checked" name="has_spouse">
                <Checkbox name="has_spouse" onChange={e => setHasSpouse(e.target.checked)}>
                  {t('pages.credit-request.depositary.table-form.has_spouse')}
                </Checkbox>
              </FormItem>
            </Col>
          </Row>
          {hasSpouse && (
            <Row gutter={[8, 8]}>
              <Col xs={24} sm={18} md={12} lg={12} xl={12} xxl={12}>
                <FormItem
                  label={t('pages.credit-request.depositary.table-form.depositary_spouse_name')}
                  name="spouse_name"
                  rules={[{ required: true }]}
                >
                  <Input maxLength={100} />
                </FormItem>
              </Col>
              <Col xs={24} sm={18} md={12} lg={8} xl={6} xxl={6}>
                <FormItem
                  label={t('pages.credit-request.depositary.table-form.depositary_spouse_rg')}
                  name="spouse_rg"
                  rules={[{ required: true }]}
                >
                  <Input maxLength={14} />
                </FormItem>
              </Col>
              <Col xs={24} sm={18} md={12} lg={8} xl={6} xxl={6}>
                <FormItem
                  label={t('pages.credit-request.depositary.table-form.depositary_spouse_cpf')}
                  name="spouse_cpf"
                  rules={[
                    {
                      required: true,
                    },
                    () => ({
                      validator(_, value) {
                        if (!Validate.cpf(value)) {
                          return Promise.reject(t('pages.credit-request.depositary.table-form.validation.cpf'));
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <MaskedInput name="spouseCpf" mask="111.111.111-11" placeholder="___.___.___-__" />
                </FormItem>
              </Col>
            </Row>
          )}
        </>
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 15 }}>
          <Button status="secondary" onClick={() => closeModalAdd()} style={{ marginRight: 10 }}>
            {t('pages.credit-request.depositary.buttons.cancel')}
          </Button>
          <Button status="primary" htmlType="submit">
            {valuesEditDepositary
              ? t('pages.credit-request.depositary.buttons.edit')
              : t('pages.credit-request.depositary.buttons.add')}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

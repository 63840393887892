import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaArrowLeft, FaClone, FaFileSignature, FaSync, FaUserEdit, FaUserPlus } from 'react-icons/fa';

import { Form, Spin, Tooltip } from 'antd';
import { Button } from 'components';
// import { Can } from 'hooks/ability';
import { useModal } from 'hooks/modals/useModal';
import { DraftButton } from 'pages/Contracts/components/DraftButton';

import { CancelSignaturesModal } from '../../../../components/CancelSignaturesModal';
import { ConfirmSignaturesModal } from '../../../../components/ConfirmSignaturesModal';
import { DraftFormatRadio } from '../../../../components/DraftFormatRadio';
import { SignatureRequestStatusChangeModal } from '../../../../components/SignatureRequestStatusChangeModal';
import { SignatureSuccessModal } from '../../../../components/SignaturesSuccessModal';
import { Tab } from '../../../../components/Tab';
import { UploadSignedContractModal } from '../../../../components/UploadSignedContractModal';
import { useContractContext } from '../../../../context/ContractContext';
import { DraftEmissionSelectedOptions, RadioGroup } from '../DraftEmission/styles';
import { CloneSignaturesModal } from './CloneSignaturesModal';
import { useSignaturesController } from './controller';
import { formats, types } from './formats';
import { SignaturesRequests } from './SignaturesRequests';
import { SignatureSubscribers } from './SignatureSubscribers';
import {
  AddSubscriber,
  ButtonsWrapper,
  Container,
  FormWrapper,
  TabTitleButtonsWrapper,
  TabTitleWrapper,
  Title,
} from './styles';

export const Signatures = () => {
  const {
    shouldDisableValidateButton,
    redirectToDocusign,
    signatureRequestBeingEdited,
    handleSaveStatusChange,
    closeSignatureRequestStatusChangeModal,
    handleUploadSignedContract,
    closeUploadSignedContractModal,
    handleCancelSignatures,
    closeCancelSignaturesModal,
    handleConfirmSignatures,
    closeConfirmSignaturesModal,
    isSignaturesSuccessModalOpen,
    closeSignaturesSuccessModal,
    isConfirmSignaturesModalOpen,
    isCancelSignaturesModalOpen,
    openCancelSignaturesModal,
    isSignatureRequestStatusChangeModalOpen,
    isUploadSignedContractModalOpen,
    shouldShowCancelSignaturesButton,
    handleSubmitForm,
    sendToDocusign,
    shouldShowSendToSignatureButton,
    openConfirmSignaturesModal,
    openUploadSignedContractModal,
    shouldShowButtons,
    handleOpenSignatureRequestStatusChangeModal,
    signatureRequests,
    isManual,
    shouldShowSignaturesRequests,
    removeSubscriber,
    subscriberPartsOptions,
    subscribers,
    digitalForm,
    shouldShowSignaturesForm,
    shouldShowAddSubscriberButton,
    selectedSignatureFormat,
    isEditingSignatures,
    addNewSubscriber,
    shouldShowEditSignaturesButton,
    handleEditSignatures,
    handleChangeSignatureFormat,
    isSendingSignatures,
    isUploading,
    isChangingSignatureStatus,
    isValidatedOnSignature,
    shouldDisableUploadSignedContractButton,
    shouldShowValidateButton,
    shouldShowSendToDocusignButton,
    shouldShowUploadSignedContractButton,
    shouldShowRedirectToDocusignButton,
    updateSubscribers,
    handleChangeSignatureType,
    selectedSignatureType,
    shouldShowBackButton,
    back,
    isUpdatingDocument,
    fillSubscriber,
    updateRoutingOrder,
    shouldShowCloneParentSignaturesButton,
    handleCloneMainSignatures,
    shouldShowCloneSignaturesButton,
    handleUpdateDatasulSyncStatus,
    isUpdatingDatasulSyncStatus,
  } = useSignaturesController();
  const { t } = useTranslation();
  const {
    open: openCloneParentSignaturesModal,
    close: closeCloneParentSignaturesModal,
    isOpen: isCloneParentSignaturesModalOpen,
  } = useModal();

  const { canCurrentUserValidateOnSignature, isValidatingSignatures, isSelectedContractInStatus, mainContract } =
    useContractContext();

  const [isFromAnotherContract, setIsFromAnotherContract] = React.useState(false);

  return (
    <Tab overflowY="scroll">
      <TabTitleWrapper>
        <Title>
          {shouldShowBackButton && (
            <span style={{ cursor: 'pointer' }} onClick={back}>
              <FaArrowLeft />
            </span>
          )}
          {t('pages.edocuments.emission.signatures.title')}
        </Title>
        <TabTitleButtonsWrapper>
          {(selectedSignatureFormat === 'manual' ||
            (selectedSignatureFormat === 'digital' &&
              selectedSignatureType !== '' &&
              !isSelectedContractInStatus('sentSignature'))) &&
            !isValidatedOnSignature &&
            isEditingSignatures && (
              <Tooltip title={t('pages.edocuments.emission.signatures.addSubscriber')} placement="right">
                <Button onClick={addNewSubscriber} size="small">
                  <span style={{ display: 'flex' }}>
                    <FaUserPlus />
                  </span>
                </Button>
              </Tooltip>
            )}

          {(isSelectedContractInStatus('waiting') ||
            isSelectedContractInStatus('sentSignature') ||
            isSelectedContractInStatus('partiallySigned')) && (
            // <Can I="contract.forceremoteupdate">
            <Tooltip title={t('pages.edocuments.emission.signatures.updateDatasulSyncState')} placement="right">
              <Button onClick={handleUpdateDatasulSyncStatus} size="small" loading={isUpdatingDatasulSyncStatus}>
                <span style={{ display: 'flex' }}>
                  <FaSync />
                </span>
              </Button>
            </Tooltip>
            // </Can>
          )}

          {shouldShowEditSignaturesButton && (
            <Tooltip title={t('pages.edocuments.emission.signatures.editSignatures')} placement="right">
              <Button onClick={handleEditSignatures} size="small">
                <span style={{ display: 'flex' }}>
                  <FaUserEdit />
                </span>
              </Button>
            </Tooltip>
          )}

          {shouldShowCloneParentSignaturesButton ? (
            <Tooltip title={t('pages.edocuments.emission.signatures.cloneParentSignatures')} placement="right">
              <Button
                onClick={openCloneParentSignaturesModal}
                size="small"
                disabled={!mainContract?.subscribers.length}
              >
                <span style={{ display: 'flex' }}>
                  <FaClone />
                </span>
              </Button>
            </Tooltip>
          ) : null}

          {shouldShowCloneSignaturesButton ? (
            <Tooltip title={t('pages.edocuments.emission.signatures.cloneSignatures.tooltip')} placement="right">
              <Button
                onClick={() => {
                  setIsFromAnotherContract(true);
                  openCloneParentSignaturesModal();
                }}
                size="small"
              >
                <span style={{ display: 'flex' }}>
                  <FaClone />
                </span>
              </Button>
            </Tooltip>
          ) : null}
        </TabTitleButtonsWrapper>
      </TabTitleWrapper>

      {!selectedSignatureFormat && (
        <>
          <p style={{ fontSize: '12px', marginBottom: '46px' }}>
            {t('pages.edocuments.emission.signatures.description')}
          </p>
          <DraftEmissionSelectedOptions>
            <RadioGroup onChange={handleChangeSignatureFormat} value={selectedSignatureFormat}>
              {Array.from(formats).map(([, format]) => (
                <DraftFormatRadio
                  name={format.name}
                  value={format.value}
                  key={format.value}
                  label={format.label}
                  icon={format.icon}
                  showChangeButton={false}
                />
              ))}
            </RadioGroup>
          </DraftEmissionSelectedOptions>
        </>
      )}

      {selectedSignatureFormat === 'digital' && !selectedSignatureType && (
        <>
          <p style={{ fontSize: '12px', marginBottom: '46px' }}>
            {t('pages.edocuments.emission.signatures.descriptionType')}
          </p>
          <Spin spinning={isUpdatingDocument}>
            <DraftEmissionSelectedOptions>
              <RadioGroup onChange={handleChangeSignatureType} value={selectedSignatureType}>
                {Array.from(types).map(([, format]) => (
                  <DraftFormatRadio
                    name={format.name}
                    value={format.value}
                    key={format.value}
                    label={format.label}
                    icon={format.icon}
                    showChangeButton={false}
                  />
                ))}
              </RadioGroup>
            </DraftEmissionSelectedOptions>
          </Spin>
        </>
      )}

      <Container
        biggerButtonWrapper={
          (shouldShowCancelSignaturesButton || isValidatingSignatures) && shouldShowRedirectToDocusignButton
        }
      >
        {shouldShowAddSubscriberButton && (
          <span style={{ fontSize: '14px' }} onClick={addNewSubscriber}>
            {t('pages.edocuments.emission.signatures.noSubscriberAddedPrefix')}{' '}
            <AddSubscriber>{t('pages.edocuments.emission.signatures.noSubscriberAdded')}</AddSubscriber>{' '}
            {t('pages.edocuments.emission.signatures.noSubscriberAddedSuffix')}
          </span>
        )}
        {(selectedSignatureFormat === 'manual' ||
          (selectedSignatureFormat === 'digital' && selectedSignatureType !== '')) && (
          <>
            <FormWrapper>
              <Spin spinning={isSendingSignatures || isUploading || isChangingSignatureStatus}>
                {shouldShowSignaturesForm && isEditingSignatures && (
                  <Form form={digitalForm}>
                    <SignatureSubscribers
                      updateRoutingOrder={updateRoutingOrder}
                      subscribers={subscribers}
                      isDigital={!isManual}
                      updateSubscribers={updateSubscribers}
                      subscriberParts={subscriberPartsOptions}
                      removeSubscriber={removeSubscriber}
                      isIcpBrasil={selectedSignatureType === types.get('icpbrasil').value}
                      fillSubscriber={fillSubscriber}
                    />
                  </Form>
                )}
              </Spin>

              {shouldShowSignaturesRequests && (
                <SignaturesRequests
                  allowManualStatusChange={isManual && !isValidatedOnSignature}
                  signaturesRequests={signatureRequests}
                  openSignatureRequestStatusChangeModal={handleOpenSignatureRequestStatusChangeModal}
                />
              )}
            </FormWrapper>
            {shouldShowButtons && (
              <>
                <ButtonsWrapper>
                  {!!signatureRequests.length && !isEditingSignatures && shouldShowUploadSignedContractButton && (
                    <DraftButton
                      onClick={openUploadSignedContractModal}
                      disabled={!shouldDisableUploadSignedContractButton}
                      permissions="contract.uploadminuta"
                    >
                      <FaFileSignature /> {t('pages.edocuments.emission.signatures.uploadSignedContract')}
                    </DraftButton>
                  )}

                  {shouldShowValidateButton && (
                    <DraftButton onClick={openConfirmSignaturesModal} disabled={shouldDisableValidateButton}>
                      <FaFileSignature />
                      {canCurrentUserValidateOnSignature
                        ? t('pages.edocuments.emission.signatures.validateSignedContract')
                        : t('pages.edocuments.emission.signatures.validateSignedContractNoPermission')}
                    </DraftButton>
                  )}

                  {shouldShowSendToDocusignButton && (
                    <DraftButton onClick={sendToDocusign} permissions="contract.send">
                      <FaFileSignature /> {t('pages.edocuments.emission.signatures.sendToDocusign')}
                    </DraftButton>
                  )}

                  {shouldShowRedirectToDocusignButton && (
                    <DraftButton onClick={redirectToDocusign} permissions="contract.open">
                      {t('pages.edocuments.emission.signatures.redirectToDocusign')}
                    </DraftButton>
                  )}

                  {shouldShowSendToSignatureButton && (
                    <DraftButton onClick={handleSubmitForm} permissions="contract.addsubscriber">
                      <FaFileSignature /> {t('pages.edocuments.emission.signatures.sendToSignature')}
                    </DraftButton>
                  )}
                  {(shouldShowValidateButton || isSelectedContractInStatus('approvedSignature')) &&
                    !shouldDisableValidateButton && (
                      <a style={{ marginTop: '10px' }} onClick={openCancelSignaturesModal}>
                        {t('pages.edocuments.emission.signatures.cancelSignatures')}
                      </a>
                    )}
                </ButtonsWrapper>
              </>
            )}
          </>
        )}

        <SignatureSuccessModal isOpen={isSignaturesSuccessModalOpen} close={closeSignaturesSuccessModal} />

        <ConfirmSignaturesModal
          isOpen={isConfirmSignaturesModalOpen}
          close={closeConfirmSignaturesModal}
          onOk={handleConfirmSignatures}
        />

        <CancelSignaturesModal
          isOpen={isCancelSignaturesModalOpen}
          close={closeCancelSignaturesModal}
          onOk={handleCancelSignatures}
        />

        <UploadSignedContractModal
          isOpen={isUploadSignedContractModalOpen}
          close={closeUploadSignedContractModal}
          onOk={handleUploadSignedContract}
          isUploading={isUploading}
        />

        <SignatureRequestStatusChangeModal
          isOpen={isSignatureRequestStatusChangeModalOpen}
          isAlreadySigned={!!signatureRequestBeingEdited?.signed_at}
          close={closeSignatureRequestStatusChangeModal}
          onOk={handleSaveStatusChange}
        />

        <CloneSignaturesModal
          isOpen={isCloneParentSignaturesModalOpen}
          onOk={handleCloneMainSignatures}
          close={() => {
            closeCloneParentSignaturesModal();
            setIsFromAnotherContract(false);
          }}
          isFromAnotherContract={isFromAnotherContract}
        />
      </Container>
    </Tab>
  );
};

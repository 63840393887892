import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FaArrowLeft } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';

import { Form } from 'antd';
import { Store } from 'antd/lib/form/interface';
import moment from 'moment';

import DTOErrorReponse from '../../../@types/dtos/DTOErrorReponse';
import { Button, FormItem, Password, ModalStatus } from '../../../components';
import AuthPage from '../../../components/AuthPage';
import { HOME_PAGE_URL } from '../../../configs/constants';
import { useAuth } from '../../../hooks/auth';
import { usePage } from '../../../hooks/page';
import { usePasswordValidator } from '../../../hooks/usePasswordValidator';
import authService from '../../../services/api/auth';

const Expired: React.FC = () => {
  const { t } = useTranslation(['auth']);
  const [form] = Form.useForm();
  const { refreshPasswordLastChanged, updateUserData, clearData } = useAuth();

  const { alertStatus } = usePage();
  const passwordValidator = usePasswordValidator();
  const [loading, setLoading] = useState(false);
  const { getToken, isExpiredPassword } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!getToken()) {
      navigate('/login');
    }
    if (getToken() && !isExpiredPassword()) {
      navigate(HOME_PAGE_URL);
    }
  }, []);

  const onFinish = async (values: Store) => {
    setLoading(true);
    await authService
      .updateExpiredPassword({ ...values })
      .then(async () => {
        await refreshPasswordLastChanged(moment().toString());
        ModalStatus({
          type: 'success',
          title: t('success'),
          subTitle: t('expired.success'),
          onOk: () => {
            setLoading(true);
            updateUserData(true)
              .then(() => {
                navigate(HOME_PAGE_URL);
              })
              .finally(() => {
                setLoading(false);
              });
          },
        });
      })
      .catch((err: DTOErrorReponse) => alertStatus(err, 'error'))
      .finally(() => setLoading(false));
  };

  return (
    <AuthPage loading={loading}>
      <Link
        to="/login"
        onClick={() => {
          clearData();
        }}
      >
        <FaArrowLeft />
      </Link>
      <Form form={form} onFinish={onFinish} className="form-primary">
        <FormItem label={t('expired.form.current_password')} name="current_password">
          <Password />
        </FormItem>
        <FormItem
          label={t('expired.form.new_password')}
          name="password"
          rules={[
            ...passwordValidator,
            {
              required: true,
            },
          ]}
          hasFeedback
        >
          <Password />
        </FormItem>
        <FormItem
          label={t('expired.form.confirm_new_password')}
          dependencies={['password']}
          name="confirm"
          rules={[
            {
              required: true,
              message: t('password.validation.confirm_new_password'),
            },
            ({ getFieldValue }: any) => ({
              validator(rule: any, value: any) {
                return !value || getFieldValue('password') === value
                  ? Promise.resolve()
                  : Promise.reject(t('password.validation.equals'));
              },
            }),
          ]}
          hasFeedback
        >
          <Password />
        </FormItem>
        <FormItem distance="small">
          <div className="button">
            <Button block htmlType="submit">
              {t('expired.form.submit')}
            </Button>
          </div>
        </FormItem>
      </Form>
    </AuthPage>
  );
};

export default Expired;

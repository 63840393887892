import React from 'react';
import { useTranslation } from 'react-i18next';

import { InputNumber } from 'antd';
import { FormItem } from 'components';
import { OPERATION_DELIVERED_PRODUCT_ADVANCE_ID } from 'configs/constants';
import { useCreditRequestContext } from 'context/CreditRequestContext';
import { useCreditRequestStore } from 'pages/CreditRequest/store/useCreditRequestStore';
import { CreditRequestValidator } from 'utils/CreditRequestValidator';
import { Formatter } from 'utils/Formatter';

interface Props {
  disabled?: boolean;
}

export const QuantityDeliveryInput = ({ disabled }: Props) => {
  const { t } = useTranslation();
  const { creditForm } = useCreditRequestContext();

  const selectedOperationId = useCreditRequestStore(state => state.selectedOperationId);
  const setIsBlocking = useCreditRequestStore(state => state.setIsBlocking);

  const label =
    selectedOperationId === OPERATION_DELIVERED_PRODUCT_ADVANCE_ID
      ? t('pages.credit-request.form.json_data_quantity_delivery_advance')
      : t('pages.credit-request.form.json_data_quantity_delivery');

  return (
    <FormItem
      label={label}
      name="json_data.quantity_delivery"
      rules={[
        {
          validator: async (_, value) => {
            const formValues = creditForm.getFieldsValue();

            const quantityKg = Number(formValues['json_data.quantity_kg']);
            const quantityReceived = Number(formValues['json_data.quantity_received']);
            const quantityContract = Number(formValues['json_data.quantity_contract']);

            creditForm.setFields([
              {
                name: 'json_data.quantity_kg',
                errors: !CreditRequestValidator.volume(quantityKg, quantityContract, quantityReceived, value)
                  ? [t('pages.credit-request.messages.contract_volume_exceeded')]
                  : [],
              },
            ]);
          },
        },
      ]}
    >
      <InputNumber<string>
        min="0"
        formatter={(value: string | number) => Formatter.integer(value)}
        parser={(value: string) => Formatter.integerParser(value)}
        disabled={disabled}
        onChange={value => {
          if (value) {
            setIsBlocking(true);
          }
        }}
      />
    </FormItem>
  );
};

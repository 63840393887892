import React from 'react';

import { OverflowAxisProps } from '../../../../@types/cssProps';
import { Container } from './styles';

interface Props {
  children: React.ReactNode;
  overflowY?: OverflowAxisProps;
}

export const Tab = ({ children, overflowY }: Props) => {
  return <Container overflowY={overflowY}>{children}</Container>;
};

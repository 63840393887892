import { AxiosRequestConfig, AxiosResponse } from 'axios';

import type PaginationData from '../../@types/data/PaginationData';
import type { ChangeLogData } from '../../@types/data/PersonChangeLog';
import { ResponseListData } from '../../@types/data/ResponseData';
import { formatValuesToFormData } from '../form';
import api from './api';

export default {
  physical: {
    get: async (params: object = null): Promise<any> => {
      return api.get('person/physical', params);
    },
    getChangeLog: async (personId: string, params = {}): Promise<AxiosResponse<PaginationData<ChangeLogData>>> => {
      return api.get(`person/physical/changelog/${personId}`, params);
    },
    getById: async (id: string, params: object = null): Promise<any> => {
      return api.get(`person/physical/${id}`, params);
    },
    getKanban: async (params: object = null): Promise<any> => {
      return api.get(`person/physical/kanban`, params);
    },
    getKanbanByColumn: async (statusId: string, params: object = null): Promise<any> => {
      return api.get(`person/physical/kanban/${statusId}`, params);
    },
    store: async (params: object = null, config: AxiosRequestConfig = null): Promise<any> => {
      return api.post('person/physical', params, config);
    },
    update: async (
      id: string,
      params: object = null,
      formData = false,
      config: AxiosRequestConfig = null,
    ): Promise<any> => {
      let paramsApi = params;
      if (formData) {
        const data = new FormData();
        paramsApi = formatValuesToFormData(params, data);
      }
      return api.put(`person/physical/${id}`, paramsApi, config);
    },
    updateStatus: async (id: string, params: object): Promise<any> => {
      return api.patch(`person/physical/stage/${id}`, params);
    },
    delete: async (id: string, params: object = null): Promise<any> => {
      return api.delete(`person/physical/${id}`, params);
    },
  },

  legal: {
    get: async (params: object = null): Promise<any> => {
      return api.get('person/legal', params);
    },
    getChangeLog: async (personId: string, params = {}): Promise<AxiosResponse<PaginationData<ChangeLogData>>> => {
      return api.get(`person/legal/changelog/${personId}`, params);
    },
    getById: async (id: string, params: object = null): Promise<any> => {
      return api.get(`person/legal/${id}`, params);
    },
    getKanban: async (params: object = null): Promise<any> => {
      return api.get(`person/legal/kanban`, params);
    },
    getKanbanByColumn: async (statusId: string, params: object = null): Promise<any> => {
      return api.get(`person/legal/kanban/${statusId}`, params);
    },
    store: async (params: object = null, config: AxiosRequestConfig = null): Promise<any> => {
      return api.post('person/legal', params, config);
    },
    update: async (
      id: string,
      params: object = null,
      formData = false,
      config: AxiosRequestConfig = null,
    ): Promise<any> => {
      let paramsApi = params;
      if (formData) {
        const data = new FormData();
        paramsApi = formatValuesToFormData(params, data);
      }
      return api.put(`person/legal/${id}`, paramsApi, config);
    },
    updateStatus: async (id: string, params: object): Promise<any> => {
      return api.patch(`person/legal/stage/${id}`, params);
    },
    delete: async (id: string, params: object = null): Promise<any> => {
      return api.delete(`person/legal/${id}`, params);
    },
  },

  bankReference: {
    store: async (params: object = null): Promise<any> => {
      return api.post('person/bank-reference', params);
    },
    update: async (id: string, params: object = null): Promise<any> => {
      return api.put(`person/bank-reference/${id}`, params);
    },
    delete: async (id: string, params: object = null): Promise<any> => {
      return api.delete(`person/bank-reference/${id}`, params);
    },
  },

  commercialOpinion: {
    store: async (params: object = null): Promise<any> => {
      return api.post('person/commercial-opinion', params);
    },
    update: async (id: string, params: object = null): Promise<any> => {
      return api.put(`person/commercial-opinion/${id}`, params);
    },
    delete: async (id: string, params: object = null): Promise<any> => {
      return api.delete(`person/commercial-opinion/${id}`, params);
    },
  },

  providerReference: {
    store: async (params: object = null): Promise<any> => {
      return api.post('person/provider-reference', params);
    },
    update: async (id: string, params: object = null): Promise<any> => {
      return api.put(`person/provider-reference/${id}`, params);
    },
    delete: async (id: string, params: object = null): Promise<any> => {
      return api.delete(`person/provider-reference/${id}`, params);
    },
  },

  customerReference: {
    store: async (params: object = null): Promise<any> => {
      return api.post('person/customer-reference', params);
    },
    update: async (id: string, params: object = null): Promise<any> => {
      return api.put(`person/customer-reference/${id}`, params);
    },
    delete: async (id: string, params: object = null): Promise<any> => {
      return api.delete(`person/customer-reference/${id}`, params);
    },
  },

  partner: {
    store: async (params: object = null): Promise<any> => {
      return api.post('person/partner', params);
    },
    update: async (id: string, params: object = null): Promise<any> => {
      return api.put(`person/partner/${id}`, params);
    },
    delete: async (id: string, params: object = null): Promise<any> => {
      return api.delete(`person/partner/${id}`, params);
    },
  },

  simplePlan: {
    store: async (params: object = null): Promise<any> => {
      return api.post('person/simple-plan', params);
    },
    update: async (id: string, params: object = null): Promise<any> => {
      return api.put(`person/simple-plan/${id}`, params);
    },
    delete: async (id: string, params: object = null): Promise<any> => {
      return api.delete(`person/simple-plan/${id}`, params);
    },
  },

  netRevenues: {
    store: async (params: object = null): Promise<any> => {
      return api.post('person/net-revenue', params);
    },
    update: async (id: string, params: object = null): Promise<any> => {
      return api.put(`person/net-revenue/${id}`, params);
    },
    delete: async (id: string, params: object = null): Promise<any> => {
      return api.delete(`person/net-revenue/${id}`, params);
    },
  },

  deliveryHistory: {
    store: async (params: object = null): Promise<any> => {
      return api.post('person/delivery-history', params);
    },
    update: async (id: string, params: object = null): Promise<any> => {
      return api.put(`person/delivery-history/${id}`, params);
    },
    delete: async (id: string, params: object = null): Promise<any> => {
      return api.delete(`person/delivery-history/${id}`, params);
    },
  },

  economicGroup: {
    store: async (params: object = null): Promise<any> => {
      return api.post('person/economic-group', params);
    },
    update: async (id: string, params: object = null): Promise<any> => {
      return api.put(`person/economic-group/${id}`, params);
    },
    delete: async (id: string, params: object = null): Promise<any> => {
      return api.delete(`person/economic-group/${id}`, params);
    },
  },

  subscriber: {
    getAll: async (params = {}): Promise<ResponseListData<any>> => {
      return api.get('contract/subscriber', params);
    },
    list: async (params = {}): Promise<ResponseListData<any>> => {
      return api.get('contract/subscriber/list', params);
    },
    remove: async (id: string) => {
      return api.delete(`contract/subscriber/${id}/destroy`);
    },
  },
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaInfoCircle } from 'react-icons/fa';

import { Popover } from 'antd';
import { FormItem, Input } from 'components';

import { Container } from './styles';

export const SapStatusProgrammedInput = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <span style={{ width: '100%' }}>
        <FormItem
          label={
            <span style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
              {t('pages.credit-request.form.sap_status_programmed')}
              <Popover
                title={t('pages.credit-request.form.sap_status_programmed')}
                content={
                  <div style={{ display: 'flex', flexDirection: 'column', gap: '2px' }}>
                    <span>
                      <strong>{t('sap.status.programmed.pending.title')}: </strong>
                      {t('sap.status.programmed.pending.description')}
                    </span>
                    <span>
                      <strong>{t('sap.status.programmed.approved.title')}: </strong>
                      {t('sap.status.programmed.approved.description')}
                    </span>
                    <span>
                      <strong>{t('sap.status.programmed.reproved.title')}: </strong>
                      {t('sap.status.programmed.reproved.description')}
                    </span>
                    <span>
                      <strong>{t('sap.status.programmed.paid.title')}: </strong>
                      {t('sap.status.programmed.paid.description')}
                    </span>
                    <span>
                      <strong>{t('sap.status.programmed.chargedback.title')}: </strong>
                      {t('sap.status.programmed.chargedback.description')}
                    </span>
                  </div>
                }
              >
                <span style={{ display: 'flex' }}>
                  <FaInfoCircle />
                </span>
              </Popover>
            </span>
          }
          name="status_sap"
        >
          <Input disabled />
        </FormItem>
      </span>
    </Container>
  );
};

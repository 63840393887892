export default {
  contracts: [
    {
      title: 'pages.edocuments.contracts.importations.table.headers.user',
      dataIndex: 'user',
      key: 'user',
      sorter: false,
      filtered: false,
    },
    {
      title: 'pages.edocuments.contracts.importations.table.headers.document-name',
      dataIndex: 'documents',
      key: 'documents',
      sorter: false,
      filtered: false,
    },
    {
      title: 'pages.edocuments.contracts.importations.table.headers.type',
      dataIndex: 'type',
      key: 'type',
      sorter: false,
      filtered: false,
    },
    {
      title: 'pages.edocuments.contracts.importations.table.headers.created_at',
      dataIndex: 'created_at',
      key: 'created_at',
      sorter: true,
      filtered: false,
    },
  ],
};

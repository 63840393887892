import styled from 'styled-components';

export const Container = styled.span`
  display: flex;
  flex-direction: column;

  i {
    font-weight: bold;
    color: ${({ theme: { colors } }) => colors.greys.grey07};
  }
`;

import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ShouldRender } from 'components/ShouldRender';
import { useAlertDialog } from 'components/ui/alert-dialog';
import { Button } from 'components/ui/button';
import { GroupForm } from 'components/ui/group-form';
import { randomUUID } from 'lib/utils';

import { CofcoOpinionFormData } from './cofco-opinion/cofco-opinion.form';
import { CofcoOpinionModal } from './cofco-opinion/cofco-opinion.modal';
import { CofcoOpinionTable } from './cofco-opinion/cofco-opinion.table';
import { useRegisteredCustomerStore } from './stores/use-registered-customer-store';

export const RegisteredCustomerCofcoOpinionSection = () => {
  const { t } = useTranslation();
  const form = useFormContext();
  const { alert } = useAlertDialog();

  const isViewing = useRegisteredCustomerStore(state => state.isViewing);
  const isModulePerson = useRegisteredCustomerStore(state => state.isModulePerson);
  const cofcoOpinionToEdit = useRegisteredCustomerStore(state => state.cofcoOpinionToEdit);
  const setIsCofcoOpinionModalOpen = useRegisteredCustomerStore(state => state.setIsCofcoOpinionModalOpen);

  const handleAddCofcoOpinion = (data: CofcoOpinionFormData) => {
    const cofcoOpinions = form.getValues('cofco_opinion') as CofcoOpinionFormData[];

    form.setValue(
      'cofco_opinion',
      [
        ...(cofcoOpinions ?? []),
        {
          ...data,
          id: `new-${randomUUID()}`,
        },
      ],
      {
        shouldTouch: true,
      },
    );
  };

  const handleEditCofcoOpinion = (data: CofcoOpinionFormData) => {
    const cofcoOpinions = form.getValues('cofco_opinion') as CofcoOpinionFormData[];
    const index = cofcoOpinions.findIndex(cofcoOpinion => cofcoOpinion.id === data.id);
    cofcoOpinions[index] = data;
    form.setValue('cofco_opinion', cofcoOpinions, {
      shouldTouch: true,
    });
    alert({
      type: 'success',
      title: t('cofco-opinion-updated'),
      description: '',
    });
  };

  return (
    <GroupForm title={t('cofco-opinions')}>
      <ShouldRender condition={!isViewing && isModulePerson}>
        <div className="flex justify-end w-full">
          <Button variant="default" type="button" onClick={() => setIsCofcoOpinionModalOpen(true)}>
            {t('add-cofco-opinion')}
          </Button>
        </div>
      </ShouldRender>

      <div className="mt-6">
        <CofcoOpinionTable />
      </div>
      <CofcoOpinionModal onSuccess={cofcoOpinionToEdit ? handleEditCofcoOpinion : handleAddCofcoOpinion} />
    </GroupForm>
  );
};

import React from 'react';

import { handleRequest } from 'services/api/api';
import contractApi from 'services/api/contract';

export const useGetContractGroupTypes = () => {
  const [isFetching, setIsFetching] = React.useState(false);

  const getContractGroupTypes = async (params = {}) => {
    return handleRequest(() => contractApi.getGroupTypes(params), setIsFetching);
  };

  return { getContractGroupTypes, isFetching };
};

const columns: any = {
  cpr: [
    {
      title: '',
      dataIndex: 'info',
      key: 'info',
    },
    {
      title: 'pages.monitoring.cpr.table.header.farm',
      dataIndex: 'farm',
      key: 'farm',
      sorter: true,
    },
    {
      title: 'pages.monitoring.cpr.table.header.owner',
      dataIndex: 'owner',
      key: 'owner',
      sorter: true,
    },
    {
      title: 'pages.monitoring.cpr.table.header.subsidiary',
      dataIndex: 'subsidiary',
      key: 'subsidiary',
      sorter: true,
    },
    {
      title: 'pages.monitoring.cpr.table.header.number',
      dataIndex: 'number',
      key: 'number',
      sorter: true,
    },
    {
      title: 'pages.monitoring.cpr.table.header.planting',
      dataIndex: 'planting',
      key: 'planting',
    },
    {
      title: 'pages.monitoring.cpr.table.header.development',
      dataIndex: 'development',
      key: 'development',
    },
    {
      title: 'pages.monitoring.cpr.table.header.harvest',
      dataIndex: 'harvest',
      key: 'harvest',
    },
    {
      title: 'pages.monitoring.cpr.table.header.actions',
      key: 'action',
      dataIndex: 'action',
      align: 'left',
      width: '200px',
      fixed: 'right',
    },
  ],
  documents: [
    {
      title: 'pages.monitoring.cpr.table.header.type_file',
      dataIndex: 'type_id',
      key: 'type_id',
      sorter: false,
      filtered: false,
    },
    {
      title: 'pages.registered-customers.form.pf.documents.name',
      dataIndex: 'name',
      key: 'name',
      sorter: false,
      filtered: false,
    },
    {
      title: 'pages.registered-customers.table.headers.actions',
      dataIndex: 'action',
      key: 'action',
      width: '100px',
      fixed: 'right',
    },
  ],
};

export default columns;

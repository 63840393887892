import styled from 'styled-components';

export const Container = styled.div`
  padding: 3px;
  text-align: center;
  border-radius: 5px;
  background: ${({ theme }) => theme.colors.lightGreen};
  font-size: 12px;
  font-weight: bold;
  display: flex;
  width: min-content;
  white-space: nowrap;
`;

export const DetailsContainer = styled.div`
  max-width: 500px;
  max-height: 500px;
`;
export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;
export const Section = styled.div``;
export const Title = styled.div`
  background: ${({ theme }) => theme.colors.greys.grey02};
  padding: 5px;
  font-weight: bold;
`;
export const Body = styled.div`
  padding: 0px 5px;
`;

import React from 'react';

import { SelectProps } from 'antd';
import Select from 'components/Select';
import { useCache } from 'hooks/cache';

interface Props extends Omit<SelectProps<any>, 'options'> {
  whitelist?: string[];
}

export const OperationSelect = ({ whitelist, ...props }: Props) => {
  const cache = useCache();

  return (
    <Select
      showSearch
      options={cache
        .getCreditOptions('operation')
        .filter(option => (whitelist ? whitelist.includes(option.key) : option))}
      {...props}
    />
  );
};

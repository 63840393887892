import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FaPlusSquare } from 'react-icons/fa';

import { Pagination } from 'antd';

import TeamStatusData from '../../../../../../types/CreditRequest/DTOTeamStatus';
import { KanbanColumnCreditRequest } from '../../../../../../@types/responseKanban/KanbanColumn';
import ApiPagination from '../../../../../../@types/responseKanban/Pagination';
import { useCreditStatus } from '../../../../../../hooks/creditStatus';
import api from '../../../../../../services/api/credit';
import Card from '../Card';
import { Filter } from '../types';
import { Container } from './styles';
import { ColumnProps } from './types';

const Column: React.FC<ColumnProps> = ({
  title,
  statusColumn,
  data,
  expandColumn,
  hidden,
  minimized,
  filterValues,
}) => {
  const { t } = useTranslation();
  const { getTeamStatusBySlug } = useCreditStatus();

  const [, setLoading] = useState(false);
  const [teamStatus, setTeamStatus] = useState<TeamStatusData>(null);
  const [total, setTotal] = useState(0);
  const [column, setColumn] = useState<ApiPagination<KanbanColumnCreditRequest>>(data);
  const [cards, setCards] = useState<KanbanColumnCreditRequest[]>([]);
  const [filter] = useState<Filter>(filterValues);

  useEffect(() => {
    if (column) {
      setCards(column.data);
      setTotal(column.total);
    }
  }, [column]);

  useEffect(() => {
    if (statusColumn) {
      setTeamStatus(getTeamStatusBySlug(statusColumn) as TeamStatusData);
    }
    // eslint-disable-next-line
  }, [statusColumn]);

  const removeCard = useCallback((card_id: string) => {
    setCards(cardsState => cardsState.filter(card => card.id !== card_id));
  }, []);

  function renderCards() {
    const dataRendered = cards.map(cardItem => {
      return (
        <Card key={`kanban-credit-request-column-card-${cardItem.id}`} data={cardItem} removeFromColumn={removeCard} />
      );
    });

    return dataRendered;
  }

  const handleChangePagination = useCallback(
    async (page: number, pageSize: number) => {
      setLoading(true);

      const filters: any = {};
      const dataFilter: any = filter;

      if (dataFilter) {
        Object.keys(dataFilter).forEach((key: string) => {
          filters[key] = dataFilter[key];
        });
      }

      const params = {
        page,
        per_page: pageSize,
        ...filters,
      };

      if (teamStatus) {
        await api.requests.getKanbanByColumn(teamStatus.id, { params }).then(({ data: responseData }) => {
          setColumn(responseData as ApiPagination<KanbanColumnCreditRequest>);
        });
      }

      setLoading(false);
    },
    // eslint-disable-next-line
    [filter, getTeamStatusBySlug, teamStatus],
  );

  return (
    <Container hidden={hidden} minimized={minimized}>
      <header>
        <div>
          {minimized && <FaPlusSquare onClick={() => expandColumn(statusColumn)} />}
          {t(title)}
        </div>{' '}
        <span>{total}</span>
      </header>

      <div className="content">{cards && cards.length > 0 && renderCards()}</div>

      {column && column.total > Number(column.per_page) && (
        <div className="pagination">
          <Pagination
            size="small"
            pageSize={Number(column.per_page)}
            current={column.current_page}
            total={column.total}
            hideOnSinglePage
            showLessItems
            showSizeChanger={false}
            onChange={handleChangePagination}
          />
        </div>
      )}
    </Container>
  );
};

export default Column;

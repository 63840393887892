import React, { createContext, useState } from 'react';

import { ContractListFilterContextProps } from './types';

export const ContractListFilterContext = createContext<ContractListFilterContextProps>({
  filter: { datasource: '' },
});

export const ContractListFilterProvider: React.FC = ({ children }) => {
  const [filter, setFilter] = useState<any>({ datasource: '' });
  const [hasFilterReseted, setHasFilterReseted] = useState(false);
  const [minEndDate, setMinEndDate] = useState<any>(undefined);
  const [minStartDate, setMinStartDate] = useState<any>(undefined);

  const clearFilter = () => {
    setFilter({
      datasource: '',
    });
    setHasFilterReseted(true);
  };

  const clearFilterOnlyMenu = () =>
    setFilter((oldFilter: any) => (oldFilter.search ? { search: oldFilter.search } : {}));

  const addFilter = (newFilter: any) => {
    setFilter((oldFilter: any) => ({ ...oldFilter, ...newFilter }));
    setHasFilterReseted(false);
  };

  return (
    <ContractListFilterContext.Provider
      value={{
        clearFilter,
        clearFilterOnlyMenu,
        addFilter,
        filter,
        hasFilterReseted,
        minEndDate,
        setMinEndDate,
        minStartDate,
        setMinStartDate,
      }}
    >
      {children}
    </ContractListFilterContext.Provider>
  );
};

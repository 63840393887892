import React from 'react';
import { useTranslation } from 'react-i18next';
import { GoPlus } from 'react-icons/go';

import { DraftButton } from '../DraftButton';
import { IconWrapper } from '../IconWrapper';
import { NewRelatedDocumentButtonProps } from './types';

export const NewRelatedDocumentButton = ({
  onClick,
  disabled,
  onClickDisabled = () => false,
  ...props
}: NewRelatedDocumentButtonProps) => {
  const { t } = useTranslation();
  return (
    <DraftButton
      {...props}
      className="gap-2"
      variant="secondary"
      disabled={disabled}
      onClick={!disabled ? onClick : onClickDisabled}
    >
      <IconWrapper>
        <GoPlus />
      </IconWrapper>
      <span>{t('pages.edocuments.emission.newRelatedDocumentButton')}</span>
    </DraftButton>
  );
};

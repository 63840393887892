import appConfigs from 'configs/app';
import moment from 'moment';
import { ContractTypeData } from 'types/Contract/ContractTypeData';

import { ResponseArray, ResponseListData, ResponseSingle, ResponseSingleData } from '../../@types/data/ResponseData';
import { ContractData } from '../../types/Contract/ContractData';
import { DTOContractTypeFieldType } from '../../types/Contract/DTOContractTypeFieldType';
import api from './api';

export default {
  getAll: async (params = {}): Promise<ResponseListData<ContractData>> => {
    return api.get('contract', params);
  },
  get: async (id: string, params = {}): Promise<ResponseSingleData<ContractData>> => {
    return api.get(`contract/${id}`, params);
  },
  toggleActive: async (id: string, params = {}): Promise<ResponseSingleData<ContractData>> => {
    return api.post(`contract/${id}/active`, params);
  },
  listReport: async () => {
    return api.get(`contract/report/control`, {});
  },
  getGroupTypes: async (params = {}) => {
    return api.get('contract/group', params);
  },
  generateReport: async (params = { date_range: [] as any[] }) => {
    return api.post(`contract/report/control`, {
      start: moment(params.date_range[0]).format(appConfigs.formatApiDate),
      end: moment(params.date_range[1]).format(appConfigs.formatApiDate),
    });
  },
  types: {
    getAll: async (params = {}): Promise<ResponseListData<ContractTypeData>> => {
      return api.get('contract/type', params);
    },
    show: async (id: string, params = {}): Promise<ResponseSingleData<ContractTypeData>> => {
      return api.get(`contract/type/${id}`, params);
    },
    store: async (data: unknown, params = {}): Promise<ResponseSingle<ContractTypeData>> => {
      return api.post('contract/type', data, params);
    },
    update: async (id: string, data: unknown, params = {}): Promise<ResponseSingle<ContractTypeData>> => {
      return api.post(`contract/type/${id}`, data, { params: { ...params, _method: 'PUT' } });
    },
    destroy: async (id: string, params = {}): Promise<ResponseSingle<ContractTypeData>> => {
      return api.delete(`contract/type/${id}`, params);
    },
    getAllFieldTypes: async (params = {}): Promise<ResponseArray<DTOContractTypeFieldType>> => {
      return api.get('contract/column', params);
    },
    fields: {
      destroy: async (id: string, params = {}): Promise<ResponseSingle<any>> => {
        return api.delete(`contract/type/destroy-field/${id}`, params);
      },
    },
  },
  templates: {
    getAll: async (params = {}): Promise<ResponseSingle<any>> => {
      return api.get('contract/template', params);
    },
    getFields: async (payload: unknown): Promise<any> => {
      return api.post('contract/template/process', payload);
    },
    store: async (payload: unknown, params = {}): Promise<any> => {
      return api.post('contract/template', payload, params);
    },
    update: async (id: string, payload: unknown, params = {}): Promise<any> => {
      return api.post(`contract/template/${id}`, payload, { params: { ...params, _method: 'PUT' } });
    },
    destroy: async (id: string): Promise<any> => {
      return api.delete(`contract/template/${id}`);
    },
    show: async (id: string, params = {}): Promise<ResponseSingle<any>> => {
      return api.get(`contract/template/${id}`, params);
    },
    datasulFields: async (params = {}): Promise<ResponseSingle<string[]>> => {
      return api.post('contract/template/apifields', {}, params);
    },
  },
};

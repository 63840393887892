import React from 'react';
import { useTranslation } from 'react-i18next';
import { AiFillFileText } from 'react-icons/ai';

import { Modal } from 'antd';

import theme from '../../../../styles/theme';
import { Container } from './styles';

interface Props {
  isOpen: boolean;
  close: () => void;
}

export const EmissionSuccessModal = ({ isOpen, close }: Props) => {
  const { t } = useTranslation();

  return (
    <Modal visible={isOpen} onCancel={close} footer={null}>
      <Container>
        <h2>{t('pages.edocuments.emission.draftEmission.draftIssuedWithSucces')}</h2>
        <AiFillFileText size={64} color={theme.colors.primary} />
        <p style={{ textAlign: 'center' }}>
          {t('pages.edocuments.emission.draftEmission.draftIssuedWithSuccesDescription')}
        </p>
        <a onClick={close}>{t('goBack')}</a>
      </Container>
    </Modal>
  );
};

import React, { useState, useEffect, useRef, useMemo } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { FaSearch } from 'react-icons/fa';

import { TableData } from '@agrodatabr/agrodataui';
import { ReportModal } from '@agrodatabr/react-report-modal';
import { Radio, TablePaginationConfig, Tooltip } from 'antd';
import { getReportModalTranslation } from 'componentsTranslation/reportModal';
import { Can, useAbility } from 'hooks/ability';
import { useExposurePrepay } from 'hooks/fetch/useExposurePrepay';
import moment from 'moment';
import dashboardApi from 'services/api/dashboard';
import documentApi from 'services/api/document';
import { Formatter } from 'utils/Formatter';

import { Breadcrumb, Button, IconWithTooltip, Input } from '../../../components';
import appConfigs from '../../../configs/app';
import { SContainer, SHeader, STitlePage } from '../../../styles';
import { Filters, CSkeleton } from '../components';
import { BoxChart, ChartsArea, TableArea, Title } from '../styles';
import { Chart as CChart, DrilldownConfig } from './Chart';
import columns from './columns';
import { usePrepay } from './hooks/usePrepay';
import { propsSkeletonCharts, propsFilters } from './props';

interface ExposurePrepayChartData {
  day: string;
  providers: {
    name: string;
    requests: {
      id: string;
      number: number;
      total: number;
      plannedAmount: number;
      estimated_value: number;
      estimated_volume: number;
      value: number;
      volume: number;

      credit_request: {
        id: string;
        number: string;
        contract: string;
      };
    }[];
    total: number | string;
    plannedAmount: number;
  }[];
  plannedAmount: number;
  total: number | string;
}

const makeDrilldownConfig = ({
  isValue = false,
  t,
}: {
  isValue: boolean;
  t: TFunction<'translation', undefined>;
}): DrilldownConfig[] => [
  {
    label: 'date',
    labelFormatter: (label: string) => moment(label).format(appConfigs.formatDate),
    values: [
      {
        dataKey: isValue ? 'estimated_total_value' : 'estimated_total_volume',
        strokeColor: '#ff7300',
        type: 'line',
        formatValue: (value: number | string) => {
          return isValue ? Number(value) : Number(value) / 1000;
        },
        name: isValue
          ? t('pages.dashboard.expoprepay.chart.plannedAmountUSD')
          : t('pages.dashboard.expoprepay.chart.plannedAmount'),
      },
      {
        dataKey: isValue ? 'realized_total_value' : 'realized_total_volume',
        strokeColor: '#8884d89f',
        type: 'area-stacked',
        formatValue: (value: number | string) => {
          return isValue ? Number(value) : Number(value) / 1000;
        },
        name: isValue
          ? t('pages.dashboard.expoprepay.chart.realizedAmountUSD')
          : t('pages.dashboard.expoprepay.chart.realizedAmount'),
      },
      {
        dataKey: isValue ? 'scheduled_total_value' : 'scheduled_total_volume',
        strokeColor: '#82ca9d9f',
        type: 'area-stacked',
        formatValue: (value: number | string) => {
          return isValue ? Number(value) : Number(value) / 1000;
        },
        name: isValue
          ? t('pages.dashboard.expoprepay.chart.scheduledAmountUSD')
          : t('pages.dashboard.expoprepay.chart.scheduledAmount'),
      },
      {
        dataKey: isValue ? 'requested_total_value' : 'requested_total_volume',
        strokeColor: '#ffc6589f',
        type: 'area-stacked',
        formatValue: (value: number | string) => {
          return isValue ? Number(value) : Number(value) / 1000;
        },
        name: isValue
          ? t('pages.dashboard.expoprepay.chart.requestedAmountUSD')
          : t('pages.dashboard.expoprepay.chart.requestedAmount'),
      },
    ],
    drilldownKey: 'providers',
  },
  {
    label: 'provider_name',
    type: 'bar',
    values: [
      {
        dataKey: isValue ? 'estimated_total_value' : 'estimated_total_volume',
        strokeColor: '#ff7300',
        type: 'bar',
        formatValue: (value: number | string) => {
          return isValue ? Number(value) : Number(value) / 1000;
        },
        name: isValue
          ? t('pages.dashboard.expoprepay.chart.plannedAmountUSD')
          : t('pages.dashboard.expoprepay.chart.plannedAmount'),
      },
      {
        dataKey: isValue ? 'realized_total_value' : 'realized_total_volume',
        strokeColor: '#8884d8',
        type: 'bar',
        formatValue: (value: number | string) => {
          return isValue ? Number(value) : Number(value) / 1000;
        },
        name: isValue
          ? t('pages.dashboard.expoprepay.chart.realizedAmountUSD')
          : t('pages.dashboard.expoprepay.chart.realizedAmount'),
      },
      {
        dataKey: isValue ? 'scheduled_total_value' : 'scheduled_total_volume',
        strokeColor: '#82ca9d9f',
        type: 'bar',
        formatValue: (value: number | string) => {
          return isValue ? Number(value) : Number(value) / 1000;
        },
        name: isValue
          ? t('pages.dashboard.expoprepay.chart.scheduledAmountUSD')
          : t('pages.dashboard.expoprepay.chart.scheduledAmount'),
      },
      {
        dataKey: isValue ? 'requested_total_value' : 'requested_total_volume',
        strokeColor: '#ffc6589f',
        type: 'bar',
        formatValue: (value: number | string) => {
          return isValue ? Number(value) : Number(value) / 1000;
        },
        name: isValue
          ? t('pages.dashboard.expoprepay.chart.requestedAmountUSD')
          : t('pages.dashboard.expoprepay.chart.requestedAmount'),
      },
    ],
    drilldownKey: 'requests',
  },
  {
    label: 'request_number',
    type: 'bar',
    values: [
      {
        dataKey: isValue ? 'estimated_total_value' : 'estimated_total_volume',
        strokeColor: '#ff7300',
        type: 'bar',
        formatValue: (value: number | string) => {
          return isValue ? Number(value) : Number(value) / 1000;
        },
        name: isValue
          ? t('pages.dashboard.expoprepay.chart.plannedAmountUSD')
          : t('pages.dashboard.expoprepay.chart.plannedAmount'),
      },
      {
        dataKey: isValue ? 'realized_total_value' : 'realized_total_volume',
        strokeColor: '#8884d8',
        type: 'bar',
        formatValue: (value: number | string) => {
          return isValue ? Number(value) : Number(value) / 1000;
        },
        name: isValue
          ? t('pages.dashboard.expoprepay.chart.realizedAmountUSD')
          : t('pages.dashboard.expoprepay.chart.realizedAmount'),
      },
      {
        dataKey: isValue ? 'scheduled_total_value' : 'scheduled_total_volume',
        strokeColor: '#82ca9d9f',
        type: 'bar',
        formatValue: (value: number | string) => {
          return isValue ? Number(value) : Number(value) / 1000;
        },
        name: isValue
          ? t('pages.dashboard.expoprepay.chart.scheduledAmountUSD')
          : t('pages.dashboard.expoprepay.chart.scheduledAmount'),
      },
      {
        dataKey: isValue ? 'requested_total_value' : 'requested_total_volume',
        strokeColor: '#ffc6589f',
        type: 'bar',
        formatValue: (value: number | string) => {
          return isValue ? Number(value) : Number(value) / 1000;
        },
        name: isValue
          ? t('pages.dashboard.expoprepay.chart.requestedAmountUSD')
          : t('pages.dashboard.expoprepay.chart.requestedAmount'),
      },
    ],
  },
  // {
  //   label: 'contract',
  //   type: 'bar',
  //   values: [
  //     // {
  //     //   dataKey: isValue ? 'value' : 'volume',
  //     //   strokeColor: '#413ea0',
  //     //   type: 'bar',
  //     //   formatValue: (value: number | string) => {
  //     //     return Number(value) / 1000;
  //     //   },
  //     //   name: isValue
  //     //     ? t('pages.dashboard.expoprepay.chart.deliveredAmountUSD')
  //     //     : t('pages.dashboard.expoprepay.chart.deliveredAmount'),
  //     // },
  //     {
  //       dataKey: isValue ? 'realized_total_value' : 'realized_total_volume',
  //       strokeColor: '#8884d8',
  //       type: 'bar',

  //       formatValue: (value: number | string) => {
  //         return isValue ? Number(value) : Number(value) / 1000;
  //       },
  //       name: isValue
  //         ? t('pages.dashboard.expoprepay.chart.realizedAmountUSD')
  //         : t('pages.dashboard.expoprepay.chart.realizedAmount'),
  //     },
  //     {
  //       dataKey: isValue ? 'scheduled_total_value' : 'scheduled_total_volume',
  //       strokeColor: '#82ca9d9f',
  //       type: 'bar',

  //       formatValue: (value: number | string) => {
  //         return isValue ? Number(value) : Number(value) / 1000;
  //       },
  //       name: isValue
  //         ? t('pages.dashboard.expoprepay.chart.scheduledAmountUSD')
  //         : t('pages.dashboard.expoprepay.chart.scheduledAmount'),
  //     },
  //     {
  //       dataKey: isValue ? 'requested_total_value' : 'requested_total_volume',
  //       strokeColor: '#ffc6589f',
  //       type: 'bar',

  //       formatValue: (value: number | string) => {
  //         return isValue ? Number(value) : Number(value) / 1000;
  //       },
  //       name: isValue
  //         ? t('pages.dashboard.expoprepay.chart.requestedAmountUSD')
  //         : t('pages.dashboard.expoprepay.chart.requestedAmount'),
  //     },
  //     {
  //       dataKey: isValue ? 'estimated_value' : 'estimated_volume',
  //       strokeColor: '#ff7300',
  //       formatValue: (value: number | string) => {
  //         return Number(value) / 1000;
  //       },
  //       name: isValue
  //         ? t('pages.dashboard.expoprepay.chart.plannedAmountUSD')
  //         : t('pages.dashboard.expoprepay.chart.plannedAmount'),
  //     },
  //   ],
  // },
];

const currentWeek = moment().clone().weekday(1);
const nextWeek = moment().clone().weekday(7).add(7, 'days');

const ExposurePrepay: React.FC = () => {
  const lastTableFilterRef = React.useRef<{ start: string; end: string; search: string }>();
  const shouldDrilldownWhenRedraw = useRef<boolean>(false);
  const ability = useAbility();

  const { filterForm, searchFormTimeline } = usePrepay();
  const {
    getChart: getExposurePrepayChartData,
    isFetchingChart,
    getTable: getExposurePrepayTableData,
    isFetchingTable,
  } = useExposurePrepay();

  const { t } = useTranslation();
  const [searchFiltersTimeline, setSearchFiltersTimeline] = useState({
    start: currentWeek.format(appConfigs.formatApiDate),
    end: nextWeek.format(appConfigs.formatApiDate),
  });
  const [exposurePrepayChartData, setExposurePrepayChartData] = useState<any>([]);
  const [searchFiltersTable, setSearchFiltersTable] = useState({
    start: currentWeek.format(appConfigs.formatApiDate),
    end: nextWeek.format(appConfigs.formatApiDate),
    search: '',
    providers: [],
    numbers: [],
    payment_date: undefined,
  });

  const [isValue, setIsValue] = useState(true);
  const [isExporting, setIsExporting] = useState(false);

  const drilldownConfig = useMemo(() => {
    return makeDrilldownConfig({ isValue, t });
  }, [isValue]);

  const handleGetExposurePrepayTableData = async (pagination: TablePaginationConfig, _: object, sorter: any) => {
    const response = await getExposurePrepayTableData({
      params: {
        start: searchFiltersTable.start,
        end: searchFiltersTable.end,
        search: searchFiltersTable.search,
        numbers: searchFiltersTable.numbers,
        sort: sorter.sort ? sorter.sort : '',
        direction: sorter.direction ? sorter.direction : '',
        page: pagination.current,
        per_page: pagination.pageSize,
        providers: searchFiltersTable.providers,
        payment_date: searchFiltersTable.payment_date,
      },
    });
    return {
      data: response?.data,
      total: response?.total,
    };
  };

  const handleFilter = (filters: any) => {
    if (!filters.weekRange) {
      filterForm.setFields([{ name: 'weekRange', errors: [t('pages.dashboard.expoprepay.emptyRange')] }]);
      return;
    }

    // if (filters.weekRange?.length > 1) {
    //   if (filters.weekRange[1].diff(filters.weekRange[0], 'days') > 120) {
    //     filterForm.setFieldsValue({ weekRange: undefined });
    //     filterForm.setFields([{ name: 'weekRange', errors: [t('pages.dashboard.expoprepay.wrongRange')] }]);
    //     return;
    //   }
    // }
    filterForm.setFields([{ name: 'weekRange', errors: undefined }]);

    const newFilter = {
      ...searchFiltersTimeline,
      ...filters,
      providers: filters.provider_multiple,
      start: filters.weekRange[0].format(appConfigs.formatApiDate),
      end: filters.weekRange[1].format(appConfigs.formatApiDate),
      numbers: filters.numbers,
    };

    delete newFilter.weekRange;
    delete newFilter.provider_multiple;

    setSearchFiltersTimeline(newFilter);
  };

  const getActions = () => ({
    render: (_: number, record: any) => (
      <>
        <IconWithTooltip
          action="view"
          title={t('pages.dashobard.exposure.table.actions.view')}
          onClick={() => {
            window.open(`/credit-request/view/${record.credit_request_id}`, '_blank');
          }}
        />
      </>
    ),
  });

  useEffect(() => {
    filterForm.setFieldsValue({ weekRange: [nextWeek, currentWeek] });
  }, []);

  useEffect(() => {
    (async () => {
      const response = (await getExposurePrepayChartData({
        params: {
          ...searchFiltersTimeline,
        },
      })) as ExposurePrepayChartData[];

      const formattedResponse = response?.map(day => {
        return {
          ...day,
          providers: [],
        };
      });

      if (response) setExposurePrepayChartData(formattedResponse);
    })();
    if (
      JSON.stringify(lastTableFilterRef.current) !== JSON.stringify({ ...searchFiltersTable, ...searchFiltersTimeline })
    ) {
      lastTableFilterRef.current = { ...searchFiltersTable, ...searchFiltersTimeline };
      setSearchFiltersTable({ ...searchFiltersTable, ...searchFiltersTimeline });
    }
  }, [searchFiltersTimeline]);

  return (
    <>
      <Breadcrumb items={[{ title: t('breadcrumb.home'), to: '/home' }, { title: t('breadcrumb.expoprepay') }]} />

      <SContainer>
        <SHeader>
          <STitlePage>{t('pages.dashboard.expoprepay.title')}</STitlePage>
        </SHeader>
        <Filters
          props={propsFilters}
          filterForm={filterForm}
          setSearchFiltersTableFirst={(values: any) =>
            handleFilter({
              ...values,
              numbers: values.numbers
                ?.replaceAll(' ', '')
                .replaceAll('-', '')
                .split(',')
                .filter((value: string) => value),
            })
          }
          searchForm={[searchFormTimeline]}
        />
        <ChartsArea>
          {isFetchingChart ? (
            <CSkeleton style={propsSkeletonCharts} numberItems={propsSkeletonCharts.number} />
          ) : (
            <>
              <BoxChart style={{ flex: '1', height: '689px', position: 'relative', float: 'left' }}>
                <div className="area-title-options">
                  <Title>{t('pages.dashboard.expoprepay.chart.title')}</Title>
                  <Radio.Group
                    buttonStyle="solid"
                    value={isValue}
                    onChange={e => {
                      setIsValue(e.target.value);
                      shouldDrilldownWhenRedraw.current = true;
                    }}
                  >
                    <Radio.Button value>
                      <div style={{ whiteSpace: 'nowrap' }}>{t('exposition')} USD</div>
                    </Radio.Button>
                    <Radio.Button value={false}>{t('volume')}</Radio.Button>
                  </Radio.Group>
                </div>
                <div style={{ height: '90%' }}>
                  <CChart
                    // isValue={isValue}
                    filter={searchFiltersTable}
                    setSearchFiltersTable={setSearchFiltersTable}
                    yAxisLabel={isValue ? 'US$' : '(TONs)'}
                    data={exposurePrepayChartData}
                    drilldownConfig={drilldownConfig}
                  />
                </div>
              </BoxChart>
            </>
          )}
        </ChartsArea>
        <TableArea>
          <h2 style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <span>Resumo das exposições</span>
            <Button
              status="primary"
              onClick={() => setIsExporting(true)}
              permission="dashboard.export.timelineexposure.index"
            >
              {t('export')}
            </Button>
          </h2>
          <br />
          <Input
            prefix={<FaSearch />}
            placeholder={t('search')}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                setSearchFiltersTable({
                  ...searchFiltersTable,
                  search: e.currentTarget.value,
                });
              }
            }}
          />
          <TableData
            pagination={{
              position: ['bottomCenter'],
            }}
            customColumns={(column: any) => {
              const customColumn = {
                ...column,
                title: t(column.title),
              };

              if (column.dataIndex === 'credit_request_created_at_timestamp') {
                customColumn.render = (text: any) => {
                  return (
                    <Tooltip title={moment(text).format('DD/MM/YYYY HH:mm:ss')}>
                      {moment(text).format('DD/MM/YYYY')}
                    </Tooltip>
                  );
                };
              }

              if (
                column.dataIndex === 'start_withdrawal_deadline' ||
                column.dataIndex === 'withdraw_period' ||
                column.dataIndex === 'credit_request_expected_payment_at_timestamp'
              ) {
                customColumn.render = (text: any) => {
                  return (
                    <Tooltip title={moment(text).format('DD/MM/YYYY HH:mm:ss')}>
                      {moment(text).format('DD/MM/YYYY')}
                    </Tooltip>
                  );
                };
              }

              if (column.key === 'to_receive' || column.key === 'planned_volume' || column.key === 'delivered_volume') {
                customColumn.render = (text: any) => {
                  return text ? Formatter.integer(Number(text) || '0', 3) : '-';
                };
              }

              if (column.dataIndex === 'ptax' || column.dataIndex === 'dollar_balance') {
                customColumn.render = (text: any) => {
                  return (
                    <Tooltip title={text}>{Formatter.decimal(Number(text)?.toFixed(2) || '0', 2, 3, 'R$')}</Tooltip>
                  );
                };
              }

              if (column.dataIndex === 'liquidated_dol' || column.dataIndex === 'advance_dol') {
                customColumn.render = (text: any) => {
                  return <Tooltip title={text}>{Formatter.decimal(Number(text)?.toFixed(2) || '0', 2, 3)}</Tooltip>;
                };
              }
              return customColumn;
            }}
            tableConfig={{ getActions }}
            scroll={{ x: 'max-content' }}
            searchRequest={handleGetExposurePrepayTableData}
            searchFilters={searchFiltersTable}
            columns={columns}
            loading={isFetchingTable}
            width="100%"
          />
        </TableArea>
      </SContainer>

      <Can I="dashboard.export.timelineexposure.index">
        <ReportModal
          translation={getReportModalTranslation(t)}
          width="70%"
          visibility={isExporting}
          afterClose={() => setIsExporting(false)}
          listReportApi={async () => (await dashboardApi.prepayExposure.listReport()).data}
          generateReportApi={values =>
            ability.can('dashboard.export.timelineexposure.store', '')
              ? dashboardApi.prepayExposure.generateReport(values, searchFiltersTable)
              : undefined
          }
          downloadReportApi={documentApi.download}
          close={() => setIsExporting(false)}
          filenameKey="name"
        />
      </Can>
    </>
  );
};

export default ExposurePrepay;

import { formatValuesToFormData } from '../form';
import api from './api';

export default {
  harvest: {
    get: async (params: object = null): Promise<any> => {
      return api.get(`register/harvest`, params);
    },
    all: async (params: object = null): Promise<any> => {
      return api.get(`register/harvest/all`, params);
    },
    update: async (id: string, params: object = null): Promise<any> => {
      return api.put(`register/harvest/${id}`, params);
    },
    store: async (params: object = null): Promise<any> => {
      return api.post(`register/harvest`, params);
    },
    destroy: async (id: string, params: object = null): Promise<any> => {
      return api.delete(`register/harvest/${id}`, params);
    },
  },
  priceDay: {
    get: async (params: object = null) => {
      return api.get(`register/dailyprice`, params);
    },
    index: async <T>(params: object = null) => {
      return api.get<T>(`register/dailyprice`, params);
    },
    update: async <T>(id: string, params: object = null) => {
      return api.put<T>(`register/dailyprice/${id}`, params);
    },
    store: async <T>(params: object = null) => {
      return api.post<T>(`register/dailyprice`, params);
    },
    destroy: async <T>(id: string, params: object = null) => {
      return api.delete<T>(`register/dailyprice/${id}`, params);
    },
  },

  baseport: {
    get: async (params: object = null): Promise<any> => {
      return api.get(`register/baseport`, params);
    },
    all: async (params: object = null): Promise<any> => {
      return api.get(`register/baseport/all`, params);
    },
    update: async (id: string, params: object = null): Promise<any> => {
      return api.put(`register/baseport/${id}`, params);
    },
    store: async (params: object = null): Promise<any> => {
      return api.post(`register/baseport`, params);
    },
    destroy: async (id: string, params: object = null): Promise<any> => {
      return api.delete(`register/baseport/${id}`, params);
    },
  },
  region: {
    get: async (params: object = null): Promise<any> => {
      return api.get(`register/region`, params);
    },
    all: async (params: object = null): Promise<any> => {
      return api.get(`register/region/all`, params);
    },
    update: async (id: string, params: object = null, formData = false): Promise<any> => {
      let paramsApi;
      if (formData) {
        const data = new FormData();
        paramsApi = formatValuesToFormData(params, data);
        paramsApi.append('_method', 'PUT');
      } else {
        paramsApi = {
          ...params,
          _method: 'PUT',
        };
      }
      return api.put(`register/region/${id}`, params);
    },
    store: async (params: object = null): Promise<any> => {
      return api.post(`register/region`, params);
    },
    destroy: async (id: string, params: object = null): Promise<any> => {
      return api.delete(`register/region/${id}`, params);
    },
  },
  subsidiary: {
    get: async (params: object = null): Promise<any> => {
      return api.get(`register/subsidiary`, params);
    },
    all: async (params: object = null): Promise<any> => {
      return api.get(`register/subsidiary/all`, params);
    },
    update: async (id: string, params: object = null): Promise<any> => {
      return api.put(`register/subsidiary/${id}`, params);
    },
    store: async (params: object = null): Promise<any> => {
      return api.post(`register/subsidiary`, params);
    },
    destroy: async (id: string, params: object = null): Promise<any> => {
      return api.delete(`register/subsidiary/${id}`, params);
    },
  },
  operation: {
    get: async (params: object = null): Promise<any> => {
      return api.get('register/operation', params);
    },
    show: async (operation_id: string, params: object = null): Promise<any> => {
      return api.get(`register/operation/${operation_id}`, params);
    },
    store: async (params: object = null): Promise<any> => {
      return api.post('register/operation', params);
    },
    update: async (operation_id: string, params: object = null, formData = false): Promise<any> => {
      let paramsApi;
      if (formData) {
        const data = new FormData();
        paramsApi = formatValuesToFormData(params, data);
        paramsApi.append('_method', 'PUT');
      } else {
        paramsApi = {
          ...params,
          _method: 'PUT',
        };
      }
      return api.post(`register/operation/${operation_id}`, paramsApi);
    },
    delete: async (operation_id: string, params: object = null): Promise<any> => {
      return api.delete(`register/operation/${operation_id}`, params);
    },
  },
  notary: {
    get: async (params: object = null): Promise<any> => {
      return api.get(`register/notary`, params);
    },
    all: async (params: object = null): Promise<any> => {
      return api.get(`register/notary/all`, params);
    },
    update: async (id: string, params: object = null): Promise<any> => {
      return api.put(`register/notary/${id}`, params);
    },
    store: async (params: object = null): Promise<any> => {
      return api.post(`register/notary`, params);
    },
    destroy: async (id: string, params: object = null): Promise<any> => {
      return api.delete(`register/notary/${id}`, params);
    },
  },
};

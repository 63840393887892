import React from 'react';

import { Divider, Empty } from 'antd';
import { ContractAttachmentData } from 'types/Contract/ContractAttachmentData';

import theme from '../../../../styles/theme';
import { AttachmentRow } from '../AttachmentRow';
import { Container } from './styles';

interface Props {
  attachments: ContractAttachmentData[];
  handleDeleteAttachment: (attachmentId: string) => void;
  handleShowAttachment: (attachmentId: string, name: string, isDocxOrPdf: boolean) => void;
  handleDownloadAttachment: (attachmentId: string) => void;
  changeSelectedAttachment: (document: any) => void;
  selectedAttachmentId?: string;
  isCompare: boolean;
}

export const AttachmentsList = ({
  attachments,
  handleDeleteAttachment,
  handleShowAttachment,
  handleDownloadAttachment,
  changeSelectedAttachment,
  selectedAttachmentId,
  isCompare,
}: Props) => {
  return (
    <Container>
      {attachments.length ? (
        attachments.map(attachment => (
          <span key={attachment.id}>
            <div>
              <AttachmentRow
                isSelected={selectedAttachmentId === attachment?.id}
                attachment={attachment}
                handleDeleteAttachment={handleDeleteAttachment}
                handleShowAttachment={handleShowAttachment}
                handleDownloadAttachment={handleDownloadAttachment}
                changeSelectedAttachment={changeSelectedAttachment}
                isCompare={isCompare}
              />
            </div>
            <Divider style={{ margin: 0 }} />
          </span>
        ))
      ) : (
        <Empty
          description="Não encontramos nenhum anexo"
          style={{ color: theme.colors.greys.grey07, fontSize: '16px' }}
          imageStyle={{ color: 'red' }}
        >
          Nenhum anexo com pré-visualização disponível. Adicione um anexo válido para visualizar
        </Empty>
      )}
    </Container>
  );
};

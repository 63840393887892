import React from 'react';

import { Radio } from 'antd';

import theme from '../../../../styles/theme';
import { Container, DraftFormatName } from './styles';
import { DraftRadioProps } from './types';

export const DraftRadio = ({ name, value, label, icon: Icon, children, ...rest }: DraftRadioProps) => {
  return (
    <Container>
      <label htmlFor={name} style={{ display: 'flex', alignItems: 'center' }}>
        <Radio id={name} value={value} {...rest} />
        {Icon ? <Icon size={30} color={theme.colors.primary} /> : null}
        <DraftFormatName isShortText={!!children} color={theme.colors.primary}>
          {label}
        </DraftFormatName>
      </label>
      <span style={{ display: 'flex', alignItems: 'center', gap: '2px' }}>{children}</span>
    </Container>
  );
};

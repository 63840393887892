import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormGrid } from 'components/ui/form-grid';
import { FormItem } from 'components/ui/form-item';
import { GroupForm } from 'components/ui/group-form';
import { InputMoney } from 'components/ui/input';

import { useRegisteredCustomerStore } from './stores/use-registered-customer-store';

export const RegisteredCustomerGoodsAndDebtsSection = () => {
  const { t } = useTranslation();

  const isFinalizing = useRegisteredCustomerStore(state => state.isFinalizing);
  const isViewing = useRegisteredCustomerStore(state => state.isViewing);
  const isModulePerson = useRegisteredCustomerStore(state => state.isModulePerson);

  return (
    <GroupForm title={t('goods-and-debts')}>
      <FormGrid maxCols={3}>
        <FormItem
          label={t('pages.registered-customers.form.pf.physical_person.json_data.assets_debts.properties_value_total')}
          name="json_data.assets_debts.properties_value_total"
          rules={{ required: isFinalizing }}
        >
          <InputMoney disabled={isViewing || !isModulePerson} />
        </FormItem>

        <FormItem
          label={t('pages.registered-customers.form.pf.physical_person.json_data.assets_debts.equipments_value_total')}
          name="json_data.assets_debts.equipments_value_total"
          rules={{ required: isFinalizing }}
        >
          <InputMoney disabled={isViewing || !isModulePerson} />
        </FormItem>

        <FormItem
          label={t('pages.registered-customers.form.pf.physical_person.json_data.assets_debts.debts_value_total')}
          name="json_data.assets_debts.debts_value_total"
          rules={{ required: isFinalizing }}
        >
          <InputMoney disabled={isViewing || !isModulePerson} />
        </FormItem>
      </FormGrid>
    </GroupForm>
  );
};

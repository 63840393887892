import React from 'react';
import { useTranslation } from 'react-i18next';

import { FileTextOutlined } from '@ant-design/icons';
import { AccordionContent, AccordionItem, AccordionTrigger } from 'components/ui/accordion';
import { useAbility } from 'hooks/ability';
import { useFormalizationGuaranteesStore } from 'pages/CreditRequest/store/useFormalizationGuaranteesStore';
import { CreditRequestPackageData } from 'types/CreditRequest/CreditRequestPackageData';
import { Formatter } from 'utils/Formatter';

import { PackageInfoCard } from './package-info-card';
import { PackageItem } from './package-item';

type Props = {
  packageData: CreditRequestPackageData;
};

export const Package = ({ packageData }: Props) => {
  const { t } = useTranslation();
  const ability = useAbility();

  const setSelectedPackage = useFormalizationGuaranteesStore(state => state.setSelectedPackage);
  const openPackageModal = useFormalizationGuaranteesStore(state => state.openPackageModal);

  return (
    <AccordionItem value={`kanban-credit-request-column-card-${packageData.id}`} className="w-full">
      <AccordionTrigger className="w-full">
        <div className="grid grid-cols-4 w-full gap-2">
          {/* eslint-disable-next-line */}
          <div className="flex gap-2 items-center">
            <FileTextOutlined />
            <a
              onClick={async () => {
                if (!ability.can('credit.request.package.destroy', '')) return;
                setSelectedPackage(packageData);
                openPackageModal();
              }}
            >
              <span className="min-w-[200px]">{packageData.name}</span>
            </a>
          </div>
          <PackageInfoCard variant="highlighted">
            <p>{t('pages.credit-request.formalization-guarantees.pre-registration-docs.collected_docs')}</p>
            <p>
              {packageData.items_collected_count} / {packageData.items_count}
            </p>
          </PackageInfoCard>
          <PackageInfoCard>
            <p>{t('pages.credit-request.formalization-guarantees.pre-registration-docs.attachments')}</p>
            <p>{packageData.documents_count}</p>
          </PackageInfoCard>
          <PackageInfoCard>
            <p>{t('pages.credit-request.formalization-guarantees.pre-registration-docs.total_cost')}</p>
            <p>
              {Formatter.money(packageData.collected_cost, 2, null, true)}/
              {Formatter.money(packageData.total_cost, 2, null, true)}
            </p>
          </PackageInfoCard>
        </div>
      </AccordionTrigger>
      <AccordionContent>
        <div className="flex mx-5 border border-gray-300 bg-white min-h-[200px] p-0 rounded-lg overflow-hidden">
          <header className="text-white bg-primary bg-primary-300 text-center uppercase pr-0 [writing-mode:vertical-lr] bold">
            {t('pages.credit-request.formalization-guarantees.pre-registration-docs.collected')}
          </header>
          <div className="overflow-x-auto">
            <div className="grid grid-cols-[repeat(8,_minmax(150px,1fr))] gap-6 w-full text-center font-bold min-w-fit">
              <span className="min-w-max col-span-3">
                {t('pages.credit-request.formalization-guarantees.pre-registration-docs.document_collected')}
              </span>
              <span className="min-w-fit">
                {t('pages.credit-request.formalization-guarantees.pre-registration-docs.requested_in')}
              </span>
              <span className="min-w-fit uppercase">{t('requested-by')}</span>
              <span className="min-w-fit uppercase">{t('collected-in')}</span>

              <span className="min-w-fit">
                {t('pages.credit-request.formalization-guarantees.pre-registration-docs.collected_by')}
              </span>
              <span className="min-w-fit">
                {t('pages.credit-request.formalization-guarantees.pre-registration-docs.county')}
              </span>

              {packageData.items.map(packageItem => (
                <PackageItem packageItem={packageItem} key={packageItem.id} />
              ))}
            </div>
          </div>
        </div>
      </AccordionContent>
    </AccordionItem>
  );
};

import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ShouldRender } from 'components/ShouldRender';
import { useAlertDialog } from 'components/ui/alert-dialog';
import { Button } from 'components/ui/button';
import { FormItem } from 'components/ui/form-item';
import { GroupForm } from 'components/ui/group-form';
import { Switch } from 'components/ui/switch';
import { randomUUID } from 'lib/utils';

import { DeliveryHistoryFormData } from './delivery-history/delivery-history.form';
import { DeliveryHistoryModal } from './delivery-history/delivery-history.modal';
import { DeliveryHistoryTable } from './delivery-history/delivery-history.table';
import { useRegisteredCustomerStore } from './stores/use-registered-customer-store';

export const RegisteredCustomerDeliveryHistorySection = () => {
  const { t } = useTranslation();
  const form = useFormContext();
  const { alert } = useAlertDialog();

  const isNewCustomer = useWatch({ name: 'json_data.is_new_customer' });

  const isViewing = useRegisteredCustomerStore(state => state.isViewing);
  const isModulePerson = useRegisteredCustomerStore(state => state.isModulePerson);

  const setIsDeliveryHistoryModalOpen = useRegisteredCustomerStore(state => state.setIsDeliveryHistoryModalOpen);
  const deliveryHistoryToEdit = useRegisteredCustomerStore(state => state.deliveryHistoryToEdit);

  async function handleDeliveryHistoriesClear() {
    form.setValue('delivery_history', []);
    form.setValue('json_data.is_new_customer', true);
  }

  async function confirmDeliveryHistoriesClear(value: boolean) {
    const deliveryHistories = form.getValues('delivery_history');

    if (deliveryHistories && deliveryHistories.length > 0 && value) {
      form.setValue('json_data.is_new_customer', false);
      return alert({
        type: 'confirm',
        title: t(`modal.warning`),
        subTitle: t('pages.registered-customers.messages.pf.warningDeliveryHistories'),
        onOk: () => handleDeliveryHistoriesClear(),
        okText: t('pages.registered-customers.messages.confirmButton'),
        onCancel: () => {
          form.setValue('json_data.is_new_customer', false);
        },
      });
    }
    return true;
  }

  const handleAddDeliveryHistory = (data: DeliveryHistoryFormData) => {
    const deliveryHistories = form.getValues('delivery_history') as DeliveryHistoryFormData[];

    form.setValue(
      'delivery_history',
      [
        ...(deliveryHistories ?? []),
        {
          ...data,
          id: `new-${randomUUID()}`,
        },
      ],
      {
        shouldTouch: true,
      },
    );
  };

  const handleEditDeliveryHistory = (data: DeliveryHistoryFormData) => {
    const deliveryHistories = form.getValues('delivery_history') as DeliveryHistoryFormData[];
    const index = deliveryHistories.findIndex(deliveryHistory => deliveryHistory.id === data.id);
    deliveryHistories[index] = data;
    form.setValue('delivery_history', deliveryHistories, {
      shouldTouch: true,
    });
    alert({
      type: 'success',
      title: t('delivery-history-updated'),
      description: '',
    });
  };

  return (
    <GroupForm title={t('delivery-histories')}>
      <FormItem
        label={t('pages.registered-customers.form.pf.delivery_histories.is_new_customer')}
        name="json_data.is_new_customer"
      >
        <Switch
          onValueChange={value => {
            confirmDeliveryHistoriesClear(value);
          }}
          disabled={isViewing || !isModulePerson}
        />
      </FormItem>
      <ShouldRender condition={!isNewCustomer}>
        <ShouldRender condition={!isViewing && isModulePerson}>
          <div className="flex justify-end w-full">
            <Button type="button" variant="default" onClick={() => setIsDeliveryHistoryModalOpen(true)}>
              {t('add-delivery-history')}
            </Button>
          </div>
        </ShouldRender>

        <div className="mt-6">
          <DeliveryHistoryTable />
        </div>
        <DeliveryHistoryModal
          onSuccess={deliveryHistoryToEdit ? handleEditDeliveryHistory : handleAddDeliveryHistory}
        />
      </ShouldRender>
    </GroupForm>
  );
};

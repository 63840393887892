import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Empty } from 'antd';

import { Spin } from '../../../../../components';
import { DocxViewer } from '../../../../../components/DocxViewer';
import theme from '../../../../../styles/theme';
import { DraftProgress } from '../../../components/DraftProgress';
import { ContractContext } from '../../../context/ContractContext';
import { Header } from './Header';
import { Container, NewDraftText } from './styles';

export const FilePreview = () => {
  const { isFetching, fileToPreview, setSelectedStage, isDocumentCancelled, hasContractType, isMainContractCancelled } =
    useContext(ContractContext);
  const { t } = useTranslation();

  return (
    <Container>
      <Spin spinning={isFetching}>
        <Header />

        <DraftProgress />
        {!fileToPreview?.url ? (
          <Empty
            description={t('pages.edocuments.emission.filePreviewEmpty')}
            style={{ color: theme.colors.greys.grey09, fontSize: '16px' }}
          >
            {isDocumentCancelled && <NewDraftText>{t('pages.edocuments.emission.cancelled')}</NewDraftText>}
            {!isDocumentCancelled && !hasContractType && (
              <NewDraftText>{t('pages.edocuments.emission.noContractType')}</NewDraftText>
            )}
            {!isDocumentCancelled && hasContractType && isMainContractCancelled && (
              <NewDraftText onClick={() => setSelectedStage(2)}>
                {t('pages.edocuments.emission.filePreviewNewDraftTextPrefix')}{' '}
                <a>{t('pages.edocuments.emission.filePreviewNewDraftText')}</a>{' '}
                {t('pages.edocuments.emission.filePreviewNewDraftTextSufix')}
              </NewDraftText>
            )}
          </Empty>
        ) : (
          <DocxViewer
            useExternalRenderApi={fileToPreview?.type === 'docx'}
            url={fileToPreview?.url}
            height="calc(100vh - 400px)"
          />
        )}
      </Spin>
    </Container>
  );
};

import React from 'react';
import { useTranslation } from 'react-i18next';

import { ModalStatus } from '../../components';

export const useModalMessage = () => {
  const { t } = useTranslation();

  const handleSuccess = (messageSuccess: string, callback?: () => any) =>
    ModalStatus({
      type: 'success',
      title: t('modal.success'),
      subTitle: messageSuccess,
      onOk: () => {
        if (typeof callback === 'function') {
          callback();
        }
      },
    });

  const handleError = (messageError: string | string[], callback?: () => any) =>
    ModalStatus({
      type: 'error',
      title: t('modal.error'),
      subTitle: Array.isArray(messageError)
        ? messageError.map(message => <p style={{ marginBottom: '10px' }}>{message}</p>)
        : messageError,
      onOk: () => {
        if (typeof callback === 'function') {
          callback();
        }
      },
    });

  return { handleSuccess, handleError };
};

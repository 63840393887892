import { Form } from 'antd';
import { TablePaginationConfig } from 'antd/lib/table';

import { useDashboardManagement } from '../../../../hooks/fetch/useDashboardManagement';
import { useDashboards } from '../../hooks/useDashboards';

export const useManagement = () => {
  const {
    getManagementCards,
    getManagementCharts,
    getManagementResume,
    getManagementTimeline,
    getTimelineReport,
    generateExportTimeline,
    getResumeReport,
    generateExportResume,
    isFetchingCharts,
    isFetchingCards,
    isFetchingTimeline,
    isFetchingResume,
  } = useDashboardManagement();

  const { handleCards, handleCharts, handleChangeTable } = useDashboards();

  const [filterForm] = Form.useForm();
  const [searchFormTimeline] = Form.useForm();
  const [searchFormResume] = Form.useForm();

  const handleCardsManagement = async () => {
    const response = await handleCards(getManagementCards, filterForm);
    return response;
  };

  const handleChartsManagement = async () => {
    const response = await handleCharts(getManagementCharts, filterForm);
    const { dataChartCreditOperation, dataChartRequest, dataChartPrepay, dataChartCredit } = response;
    return { dataChartCreditOperation, dataChartRequest, dataChartPrepay, dataChartCredit };
  };

  const handleChangeTableTimeline = async (pagination: TablePaginationConfig, filters: any, sorter: any) => {
    if (Object.keys(filters).length) {
      const response = await handleChangeTable(pagination, filters, sorter, getManagementTimeline);
      return response;
    }
    return { data: [], total: 0 };
  };

  const handleChangeTableResume = async (pagination: TablePaginationConfig, filters: any, sorter: any) => {
    if (Object.keys(filters).length) {
      const response = await handleChangeTable(pagination, filters, sorter, getManagementResume);
      return response;
    }
    return { data: [], total: 0 };
  };

  return {
    handleCardsManagement,
    handleChartsManagement,
    handleChangeTableResume,
    handleChangeTableTimeline,
    getTimelineReport,
    generateExportTimeline,
    getResumeReport,
    generateExportResume,
    isFetchingCharts,
    isFetchingCards,
    isFetchingTimeline,
    isFetchingResume,
    filterForm,
    searchFormTimeline,
    searchFormResume,
  };
};

import { OptionData } from 'components/ui/combo';
import { differenceInYears } from 'date-fns';
import moment, { Moment } from 'moment';

import appConfigs from '../configs/app';

export const Converter = {
  /**
   * Retorna string com o formato: R$ 0.000
   */
  fixed: (value: number, fractionDigits = 2) => {
    return parseFloat(value.toString()).toFixed(fractionDigits);
  },

  dateToAge: (date: Moment | Date) => {
    if (date instanceof Date) return date ? differenceInYears(new Date(), date) : 0;
    return date ? Number(moment().year()) - Number(moment(date, appConfigs.formatDate).year()) : '';
  },

  dateRangeToDays: (start: string, end: string) => {
    const startDate = new Date(start);
    const endDate = new Date(end);

    const diffInTime = Math.abs(endDate.getTime() - startDate.getTime());

    return Math.ceil(diffInTime / (1000 * 60 * 60 * 24));
  },
  selectOption: <T>(
    data: T,
    key: keyof T = 'id' as keyof T,
    valueKey: keyof T = 'id' as keyof T,
    labelKey: keyof T = 'name' as keyof T,
  ): OptionData<T> => {
    if (!data) return {} as OptionData<T>;
    const parse = (item: T) => ({
      data: item,
      key: item[key] as string,
      value: item[valueKey] as string,
      label: item[labelKey] as string,
    });
    return parse(data);
  },
  selectOptionArray: <T>(
    data: T[],
    key: keyof T = 'id' as keyof T,
    valueKey: keyof T = 'id' as keyof T,
    labelKey: keyof T = 'name' as keyof T,
  ): OptionData<T>[] => {
    return data?.map(option => Converter.selectOption(option, key, valueKey, labelKey)) || [];
  },
};

import React from 'react';
import { useTranslation } from 'react-i18next';

import { format, parseISO } from 'date-fns';
import { enUS, ptBR } from 'date-fns/locale';
import { cn } from 'lib/utils';

type TimelineStepProps = {
  title: string | React.ReactNode;
  description?: string | React.ReactNode;
  isActive?: boolean;
  date?: string;
};

export const TimelineStep = ({ title, description, isActive, date }: TimelineStepProps) => {
  const { i18n } = useTranslation();

  const locale = (() => {
    switch (i18n.language) {
      case 'en-US':
        return enUS;
      default:
        return ptBR;
    }
  })();

  return (
    <li className="relative mb-6 sm:mb-0 w-full">
      <div className="flex items-center justify-center relative">
        <div
          className={cn(
            'z-10 flex items-center justify-center w-3 h-3 bg-gray-200 rounded-full ring-0 ring-white  sm:ring-2  shrink-0',
            isActive ? 'bg-primary' : '',
          )}
        />
        <div className={cn('absolute hidden sm:flex w-full bg-gray-200 h-0.5', isActive ? 'bg-primary' : '')} />
      </div>
      <div className="mt-3 flex items-center flex-col px-4">
        <h3 className="text-sm font-semibold text-gray-900 text-center dark:text-white">{title}</h3>
        <time className="block mb-2 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">
          {description}
        </time>
        <p className="text-xs font-normal text-gray-500 dark:text-gray-400">
          {date
            ? format(parseISO(date), 'PPP', {
                locale,
              })
            : ''}
        </p>
      </div>
    </li>
  );
};

type TimelineProps = {
  children?: React.ReactNode;
  className?: string;
};

export const Timeline = ({ children, className }: TimelineProps) => {
  return <ol className={cn('m-0 sm:flex items-start w-full', className)}>{children}</ol>;
};

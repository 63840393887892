import React from 'react';

import CreditKanban from '../organisms/CreditKanban';

export interface KanbanProps {
  filterData?: {
    division: string;
    harvest: string;
    number: string;
    operation: string;
    search: string;
    subdivision: string;
  };
}

const KanbanView: React.FC<KanbanProps> = ({ filterData }) => {
  return (
    <div className="flex flex-col w-full">
      <CreditKanban filterValues={filterData} />
    </div>
  );
};

export default KanbanView;

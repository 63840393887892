import { useQuery } from '@tanstack/react-query';
import api from 'services/api/api';
import exceptionHandler from 'services/exceptions';
import { ResponseSingle } from 'types/Api/ResponseSingleData';
import { RegisteredCustomerData } from 'types/CreditRequest/RegisteredCustomerData';

type Props = {
  id?: string;
  personType?: 'legal' | 'physical';
  onSuccess?: (data: RegisteredCustomerData) => void;
};

export const useGetRegisteredCustomerQuery = ({ id, personType, onSuccess }: Props) => {
  return useQuery({
    queryKey: [`person/${personType}/`, id],
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    queryFn: async () => {
      try {
        if (!id) return null;
        const response = await api.get<ResponseSingle<RegisteredCustomerData>>(`person/${personType}/${id}`);

        if (onSuccess) onSuccess(response.data.data);

        return response.data.data;
      } catch (err) {
        exceptionHandler(err);
      }
    },
  });
};

import React from 'react';

import { useAuth } from '../../../hooks/auth';
import { CCard } from '../components/Card';
import { Container, Stitle } from './styles';
import { ContentProps } from './types';

export const Content = ({ title, props }: ContentProps) => {
  const { permissions } = useAuth();
  let havePermissions = false;
  props.forEach((item: any) => permissions.filter((i: any) => i === item.permission && (havePermissions = true)));
  return (
    <Container>
      {havePermissions && (
        <>
          <Stitle>{title}</Stitle>
          <CCard listCard={props} />
        </>
      )}
    </Container>
  );
};

import styled, { css } from 'styled-components';

export const Container = styled.div<{ fullSize: boolean; thick: boolean; isRestriction: boolean }>`
  background: ${({ theme, isRestriction }) => (isRestriction ? theme.colors.lightRed : theme.colors.lightOrange)};
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  cursor: pointer;

  ${({ fullSize }) =>
    fullSize &&
    css`
      width: 100%;
      height: 80px;
      min-width: 200px;
    `}

  ${({ thick }) =>
    thick &&
    css`
      padding: 20px;
    `}
`;

export const Value = styled.span<{ thick: boolean }>`
  font-size: ${({ thick }) => (thick ? '15px' : '10px')};
  font-weight: bold;
`;

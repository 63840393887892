import React from 'react';

import { handleRequest } from 'services/api/api';
import registerApi from 'services/api/register';

import { BasePortData } from '../../types/CreditRequest/BasePortData';

export const useBasePort = () => {
  const [isFetchingBasePorts, setIsFetchingBasePorts] = React.useState(false);

  const getBasePorts = async (params = {}) => {
    const response = await handleRequest(() => registerApi.baseport.get(params), setIsFetchingBasePorts);
    return response;
  };

  const getBasePortsOptions = async (params = {}) => {
    const response = await getBasePorts(params);
    return {
      data: response?.data?.map((basePort: BasePortData) => ({
        ...basePort,
        label: basePort.name,
        value: basePort.id,
        key: basePort.id,
      })),
      lastPage: response.last_page,
    };
  };

  const destroyBasePort = async (id: string) => {
    const response = await handleRequest(() => registerApi.baseport.destroy(id), setIsFetchingBasePorts);
    return response;
  };

  return {
    getBasePorts,
    isFetchingBasePorts,
    getBasePortsOptions,
    destroyBasePort,
  };
};

import DTOTeam from '../../@types/dtos/credit-request/DTOTeam';
import DTOEntityModel from '../../@types/dtos/DTOEntityModel';
import DTORegionModel from '../../@types/dtos/location/DTORegionModel';
import DTOSubsidiaryModel from '../../@types/dtos/location/DTOSubsidiaryModel';
import DTOOperator from '../../@types/dtos/monitoring/SurveyRequest/DTOOperator';
import DTORole from '../../@types/dtos/user/DTORole';

export interface UserConfig {
  app: {
    sidebar: SidebarConfig;
    language: string;
  };
  modules: {
    credit: ModuleConfig;
    person: ModuleConfig;
  };
}

export default interface UserData {
  id: string;
  active?: string;
  config?: UserConfig;
  locale?: string;
  language?: string;
  email: string;
  email_verified_at?: string;
  external_id?: string;
  name: string;
  regions?: DTORegionModel[];
  roles?: DTORole[];
  position: DTOEntityModel;
  position_id: string;
  teams?: DTOTeam[];
  subsidiaries?: DTOSubsidiaryModel[];
  operators?: DTOOperator[];
  created_at?: string;
  deleted_at?: string;
  updated_at?: string;
  password_changed_at?: string;
  two_factor_is_configured: boolean;
  photo?: string;
  send_email?: boolean;
}

export interface KanbanConfig {
  allColumnsMinimized?: boolean;
  allColumnsExpanded?: boolean;
  expandedColumns?: string[];
  hiddenColumns?: string[];
  lastPhase?: number;
  lastTeam?: number;
}

export interface KanbanModuleConfig {
  [subModule: string]: KanbanConfig;
}

export type PageConfig = 'kanban' | 'table';

export interface PageModuleConfig {
  [subModule: string]: PageConfig;
}

export interface ModuleConfig {
  pageView: PageModuleConfig;
  kanban: KanbanModuleConfig;
  filters: any;
}

export interface SidebarConfig {
  isMini: boolean;
}

/* Valores padrão para todas as possibilidades de configurações: */
export const kanbanConfigDefault: KanbanConfig = {
  allColumnsExpanded: true,
  allColumnsMinimized: false,
  expandedColumns: [],
  hiddenColumns: [],
};

export const pageConfigDefault: PageConfig = 'table';

export const modulePersonConfigDefault: ModuleConfig = {
  pageView: {
    physical: pageConfigDefault,
    legal: pageConfigDefault,
  },
  kanban: {
    physical: kanbanConfigDefault,
    legal: kanbanConfigDefault,
  },
  filters: {
    team: '',
  },
};

export const moduleCreditConfigDefault: ModuleConfig = {
  pageView: {
    credit: pageConfigDefault,
  },
  kanban: {
    prefin: kanbanConfigDefault,
    commercial: kanbanConfigDefault,
    credit: kanbanConfigDefault,
    legal: kanbanConfigDefault,
  },
  filters: {
    team: '',
  },
};

export const sidebarConfigDefault: SidebarConfig = {
  isMini: false,
};

export const userConfigDefault: UserConfig = {
  app: {
    sidebar: sidebarConfigDefault,
    language: 'en',
  },
  modules: {
    credit: moduleCreditConfigDefault,
    person: modulePersonConfigDefault,
  },
};

import { useMutation } from '@tanstack/react-query';
import { usePage } from 'hooks/page';
import api from 'services/api/api';

export const useDeleteAttachment = () => {
  const { alertStatus } = usePage();

  return useMutation({
    mutationFn: async (attachmentId: string) => {
      return api.delete(`document/${attachmentId}`);
    },
    onError: err => {
      alertStatus(err.message, 'error');
    },
  });
};

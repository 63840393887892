import React from 'react';
import { useTranslation } from 'react-i18next';

import { Col, Badge } from 'antd';
import { Can } from 'hooks/ability';

import DTODepositary from '../../../../../@types/dtos/credit-request/DTODepositary';
import { IconWithTooltip, ModalStatus } from '../../../../../components';
import { TableData } from '../../../../../compositions';
import { columns } from './columns';
import { TableProps } from './types';
import { useDepositary } from './useDepositary';

export const TableDepositary = ({
  depositaryList,
  pageStatus,
  setValuesEditDepositary,
  setModalAddVisible,
  setDepositaryList,
  setLoading,
}: TableProps) => {
  const { t } = useTranslation();
  const { deteleDepositary } = useDepositary();
  const edit = (id: any) => {
    const filter = depositaryList.filter((data: any) => data.idList === id);
    setValuesEditDepositary(filter[0]);
    setModalAddVisible(true);
  };
  const remove = (id: any) => {
    const filter = depositaryList.filter((data: any) => data.idList === id);
    if (pageStatus.editing && filter[0].id !== undefined) {
      setLoading(true);
      deteleDepositary(filter[0].id, setLoading, setDepositaryList);
    } else {
      const filterDifferent = depositaryList.filter((data: any) => data.idList !== id);
      setDepositaryList(filterDifferent);
    }
  };
  return (
    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
      <TableData
        columns={columns.map(col => {
          switch (col.key) {
            case 'idList':
              return {
                ...col,
                render: (record: DTODepositary) => {
                  return (
                    !pageStatus.viewing && (
                      <>
                        <IconWithTooltip
                          action="edit"
                          title={t('pages.credit-request.depositary.table-form.edit')}
                          onClick={() => edit(record)}
                        />
                        <Can I="credit.request.depositary.destroy">
                          <IconWithTooltip
                            action="destroy"
                            title={t('pages.credit-request.depositary.table-form.remove')}
                            onClick={() => {
                              ModalStatus({
                                type: 'delete',
                                title: t('pages.credit-request.depositary.table-form.delete.title'),
                                subTitle: t('pages.credit-request.depositary.table-form.delete.subtitle'),
                                cancelText: t('pages.credit-request.depositary.table-form.delete.cancel'),
                                okText: t('pages.credit-request.depositary.table-form.delete.confirm'),
                                onOk: () => {
                                  remove(record);
                                },
                              });
                            }}
                          />
                        </Can>
                      </>
                    )
                  );
                },
              };
            case 'status':
              return {
                ...col,
                render: (record: DTODepositary) => {
                  const value: any = record;
                  if (pageStatus.editing) {
                    switch (value) {
                      case 0:
                        return (
                          <Badge
                            count={t('pages.credit-request.depositary.table-form.badge.new')}
                            className="badge-table-depositary-new"
                          />
                        );
                      case 1:
                        return (
                          <Badge
                            count={t('pages.credit-request.depositary.table-form.badge.edited')}
                            className="badge-table-depositary-edited"
                          />
                        );
                      default:
                    }
                  }
                  return <></>;
                },
              };
            default:
              return {
                ...col,
                render: (record: DTODepositary) => {
                  const value: any = record;
                  return value;
                },
              };
          }
        })}
        dataSource={depositaryList}
        tableConfig={{ nopagination: true }}
      />
    </Col>
  );
};

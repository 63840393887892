import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useMsal } from '@azure/msal-react';
import { Form as AntdForm, Space } from 'antd';
import { loginRequest } from 'authConfig';

import DTOErrorReponse from '../../../@types/dtos/DTOErrorReponse';
import logoMiniClient from '../../../assets/img/client/logo-mini.png';
import logoMiniCompany from '../../../assets/img/company/logo-mini.png';
import { Button, Input, FormItem, Password, Switch } from '../../../components';
import AuthPage from '../../../components/AuthPage';
import appConfigs from '../../../configs/app';
import { useAuth } from '../../../hooks/auth';
import { usePage } from '../../../hooks/page';

const Login: React.FC = () => {
  const { logIn, microsoftLogIn } = useAuth();
  const { alertStatus } = usePage();
  const [showLoginAgrodata, setShowLoginAgrodata] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [form] = AntdForm.useForm();
  const { instance } = useMsal();

  const shouldLogionLastValue = useRef(false);
  const [shouldLogin, setShouldLogin] = useState(false);
  const [lastAccessToken, setLastAccessToken] = useState<any>();

  const isClient = process.env.REACT_APP_IS_CLIENT;

  const onLogin = async () => {
    await form.validateFields().then(async values => {
      await logIn({
        email: values.email,
        password: values.password,
        setLoading,
      }).catch((err: DTOErrorReponse) => alertStatus(err, 'errorLogin'));
    });

    // eslint-disable-next-line
  };

  const handleMsalLogin = useCallback(async (loginType = 'redirect') => {
    if (loginType === 'redirect') {
      await instance.loginPopup(loginRequest).then(response => {
        setLastAccessToken(response.accessToken);
        setShouldLogin(true);
      });
    }
  }, []);

  useEffect(() => {
    setLoading(false);
    if (shouldLogin && shouldLogionLastValue.current !== shouldLogin && lastAccessToken) {
      (async () => {
        microsoftLogIn({
          accessToken: lastAccessToken,
          setLoading,
        })
          .then(() => {
            shouldLogionLastValue.current = false;
            setLastAccessToken(undefined);
            setShouldLogin(false);
          })
          .catch((err: DTOErrorReponse) => alertStatus(err, 'errorLogin'));
      })();
    }
  }, [shouldLogin]);

  return (
    <AuthPage loading={loading}>
      <Space className="microsoft-button-space">
        {/* <MicrosoftLogin
          className="microsoft-button"
          clientId={process.env.REACT_APP_MS_CLIENT_ID}
          tenantUrl={process.env.REACT_APP_MS_TENANT_URL}
          forceRedirectStrategy
          authCallback={onMicrosoftLogin}
          debug={process.env.REACT_APP_ENVIRONMENT === 'dev' || process.env.REACT_APP_ENVIRONMENT === 'local'}
        > */}
        <div className="button">
          <Button
            status={isClient ? 'primary-outline' : 'info-outline'}
            htmlType="submit"
            onClick={() => handleMsalLogin()}
          >
            <img
              className="button-agrodata"
              src={isClient ? logoMiniClient : logoMiniCompany}
              alt={appConfigs.companyTitle}
            />
            {t(isClient ? 'login.form.title-cofco' : 'login.form.button-agrodata-ad')}
          </Button>
        </div>
        {/* </MicrosoftLogin> */}
      </Space>

      <div className="switch-agrodata">
        <Switch
          size="small"
          onChange={value => {
            setShowLoginAgrodata(Boolean(value));
          }}
        />
        <span>{t('login.form.switch-agrodata')}</span>
      </div>

      {showLoginAgrodata && (
        <>
          <hr className="separator-login" />

          <AntdForm form={form} className="form-primary">
            <FormItem
              label={t('login.form.email')}
              name="email"
              hasFeedback
              rules={[
                {
                  required: true,
                },
                () => ({
                  validator(_, value) {
                    if (!value.includes('cofcointernational')) {
                      return Promise.resolve();
                    }
                    return Promise.reject(t('login.required.error-is-a-cofco-email'));
                  },
                }),
              ]}
              // validateStatus="warning"
            >
              <Input allowClear type="email" />
            </FormItem>

            <FormItem
              distance="small"
              label={t('login.form.password')}
              name="password"
              rules={[
                { required: true },
                {
                  // min: 6,
                  max: 50,
                  message: t('login.max.password'),
                },
              ]}
            >
              <Password />
            </FormItem>

            <FormItem distance="small">
              <div className="forgot-link">
                <Link to="/forgot">{t('login.form.forgot')}</Link>
              </div>
            </FormItem>
            <FormItem distance="small">
              <div className="button">
                <Button status="info-outline" htmlType="submit" onClick={onLogin}>
                  <img className="button-agrodata" src={logoMiniCompany} alt={appConfigs.companyTitle} />
                  {t('login.form.button-agrodata')}
                </Button>
              </div>
            </FormItem>
          </AntdForm>
        </>
      )}
    </AuthPage>
  );
};

export default Login;

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Breadcrumb } from '../../../components';
import { SContainer, SHeader, STitlePage } from '../../../styles';
import { CCard, Chart, CSkeleton, TableTimeline } from '../components';
import { Filters } from '../components/Filters';
import { TopCards, ChartsArea, BoxChart, TableArea, NotificationPtax } from '../styles';
import { PropsHandleOptionButtons } from '../types';
import { getCards, getItemsChart } from '../utils';
import { useWarrantyPrefin } from './hooks/useWarrantyPrefin';
import { useChartProps, propsSkeletonCard, propsSkeletonCharts, propsFilters } from './props';

const WarrantyPrefin: React.FC = () => {
  const {
    handleCardsWarrantyPrefin,
    handleChartsWarrantyPrefin,
    handleChangeTableTimelineRegister,
    getTimelineReport,
    generateExportTimeline,
    isFetchingCharts,
    isFetchingCards,
    isFetchingTimelineRegister,
    filterForm,
    searchFormTimelineSignature,
    searchFormTimelineRegister,
  } = useWarrantyPrefin();
  const { propsChartCreditWarrantyOperation, propsChartRequestInProgressWarranty, propsCardChart } = useChartProps();
  const { t } = useTranslation();
  const [chartEvent, setChartEvent] = useState([]);
  const [propsCards, setPropsCards] = useState([]);
  const [propsCharts, setPropsChart] = useState([]);
  const [cardChart, setCardChart] = useState([]);
  const [handleOptionButtons, setHandleOptionButtons] = useState<PropsHandleOptionButtons>({
    key: undefined,
    buttonValue: 0,
  });
  const [propsChartCopy, setPropsChartCopy] = useState([]);
  const [searchFiltersRegister, setSearchFiltersRegister] = useState({});

  const getCharts = async () => {
    const { dataChartCreditWarrantyOperation, dataChartRequestInProgressWarranty, dataCardChartWarranty } =
      await handleChartsWarrantyPrefin();
    setPropsChart([
      propsChartCreditWarrantyOperation(dataChartCreditWarrantyOperation, t),
      propsChartRequestInProgressWarranty(dataChartRequestInProgressWarranty, t),
    ]);
    setCardChart(propsCardChart(dataCardChartWarranty));
  };

  const handleGet = () => {
    setPropsCards([]);
    setPropsChart([]);
    setPropsChartCopy([]);
    setChartEvent([]);
    getCards(handleCardsWarrantyPrefin, setPropsCards);
    getCharts();
  };

  useEffect(() => {
    handleGet();
  }, []);

  useEffect(() => {
    const filter = filterForm.getFieldsValue();
    if (filter) {
      filter.requesterDate = undefined;
      setSearchFiltersRegister(filter);
    }
  }, [filterForm]);

  useEffect(() => {
    if (propsCharts.length) setPropsChartCopy(propsCharts);
  }, [propsCharts]);

  const getItems = (item: any, buttonValue: any, prop: any) => {
    getItemsChart({ prop, item, buttonValue, chartEvent, setChartEvent, setPropsChart });
  };

  useEffect(() => {
    if (handleOptionButtons.key) {
      getItems(handleOptionButtons.key, handleOptionButtons.buttonValue, propsChartCopy);
    }
  }, [handleOptionButtons]);

  return (
    <>
      <Breadcrumb items={[{ title: t('breadcrumb.home'), to: '/home' }, { title: t('breadcrumb.warranty-prefin') }]} />

      <SContainer>
        <SHeader>
          <STitlePage>{t('pages.dashboard.warranty-prefin.title')}</STitlePage>
        </SHeader>
        <Filters
          props={propsFilters}
          filterForm={filterForm}
          handleGet={handleGet}
          setSearchFiltersTableSecond={setSearchFiltersRegister}
          searchForm={[searchFormTimelineSignature, searchFormTimelineRegister]}
        />
        <NotificationPtax>{t('pages.dashboard.notificationPTAX')}</NotificationPtax>
        <TopCards>
          {isFetchingCards ? (
            <CSkeleton style={propsSkeletonCard} numberItems={propsSkeletonCard.number} />
          ) : (
            propsCards.map((item: any) => <CCard key={item.key} item={item} />)
          )}
        </TopCards>
        <ChartsArea>
          {isFetchingCharts ? (
            <CSkeleton style={propsSkeletonCharts} numberItems={propsSkeletonCharts.number} />
          ) : (
            <>
              {propsCharts.map((item: any) => (
                <Chart
                  key={item.key}
                  chartProps={{ ...item }}
                  setChartEvent={setChartEvent}
                  chartEvent={chartEvent}
                  getItems={getItems}
                  referenceKey={item.key}
                  setHandleOptionButtons={setHandleOptionButtons}
                  setPropsChart={setPropsChart}
                  propsChartCopy={propsChartCopy}
                  propsChartsOriginal={propsCharts}
                  setPropsChartCopy={setPropsChartCopy}
                />
              ))}
              <BoxChart style={{ flex: 2, display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                {cardChart.length && cardChart.map((item: any) => <CCard key={item.key} item={item} width="auto" />)}
              </BoxChart>
            </>
          )}
        </ChartsArea>
        <TableArea>
          {Object.keys(searchFiltersRegister).length && (
            <TableTimeline
              searchFilters={searchFiltersRegister}
              handleChangeTableTimeline={handleChangeTableTimelineRegister}
              isFetchingTimeline={isFetchingTimelineRegister}
              setSearchFilters={setSearchFiltersRegister}
              searchForm={searchFormTimelineRegister}
              warranty
              title={t('pages.dashboard.timeline.titleRegister')}
              listReportPermissions={['dashboard.export.timelinewarrantyprefin.index']}
              generateReportPermissions={['dashboard.export.timelinewarrantyprefin.store']}
              getReport={getTimelineReport}
              generateExport={generateExportTimeline}
            />
          )}

          {/* <Title>{t('pages.dashboard.timeline.titleSignature')}</Title>
          <TableTimeline
            searchFilters={searchFiltersSignature}
            handleChangeTableTimeline={handleChangeTableTimelineSignature}
            isFetchingTimeline={isFetchingTimelineSignature}
            setSearchFilters={setSearchFiltersSignature}
            searchForm={searchFormTimelineSignature}
          /> */}
        </TableArea>
      </SContainer>
    </>
  );
};

export default WarrantyPrefin;

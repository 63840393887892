import { useState } from 'react';

import api, { handleRequest } from 'services/api/api';

export const useSustainabilityRegistrationStatus = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [isChangingStatus, setIsChangingStatus] = useState(false);

  const get = async (params = {}) => {
    const response = await handleRequest(
      () => api.get('/sustainability/reportregistrationstatus', params),
      setIsFetching,
    );

    return response;
  };

  const getOptions = async (params = {}) => {
    const response = await get(params);

    if (!response)
      return {
        data: [] as any[],
        total: 0,
        lastPage: 1,
      };

    return {
      data: response.data.map((status: any) => ({
        key: status.id,
        value: status.id,
        label: status.name,
      })),
      total: response.total,
      lastPage: response.last_page,
    };
  };

  const changeStatus = async (registrationId: string, statusId: string) => {
    const response = handleRequest(
      () =>
        api.patch(`/sustainability/registration/${registrationId}`, {
          sustainability_registration_status_id: statusId,
          comments: '',
        }),
      setIsChangingStatus,
    );

    return response;
  };

  return { getOptions, get, isFetching, isChangingStatus, changeStatus };
};

import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { BsArrowsAngleExpand, BsArrowsAngleContract } from 'react-icons/bs';

import { Radio } from 'antd';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsDrilldown from 'highcharts/modules/drilldown';
import noDataToDisplay from 'highcharts/modules/no-data-to-display';
import moment from 'moment';

import { Formatter } from '../../../../utils/Formatter';
import { BoxChart, Title } from '../../styles';
import { ChartOptions, PropsChart } from './type';

const { Group, Button } = Radio;

export const Chart = ({
  chartProps,
  setChartEvent,
  chartEvent,
  getItems,
  referenceKey,
  setHandleOptionButtons,
  setPropsChart,
  propsChartCopy,
  propsChartsOriginal,
  setPropsChartCopy,
}: ChartOptions) => {
  const {
    key,
    title,
    buttons,
    colors,
    type,
    yAxisText,
    yAxisMax = null,
    categories = null,
    xAxisVisible = true,
    plotLabels = true,
    marker = false,
    defaultExpand = '',
    height = 'auto',
    typeFormatter,
    series,
    buttonValue,
    drilldown = null,
    xAxisType = 'category',
    rotation = null,
  }: PropsChart = chartProps;

  const [flexExpand, setFlexExpand] = useState(defaultExpand === '' ? '2' : defaultExpand);

  const { t } = useTranslation();

  noDataToDisplay(Highcharts);

  HighchartsDrilldown(Highcharts);

  useEffect(() => {
    if (propsChartCopy.length === 0) {
      getItems(referenceKey, 0, propsChartsOriginal);
    }
  }, []);

  function lableFormatter() {
    const { y, value } = this;
    if (typeFormatter) {
      switch (typeFormatter) {
        case 'abbreviatedValue':
          return Formatter.abbreviated(y);
        case 'intConverter':
          return parseInt(y, 10);
        case 'default':
          return y;
        case 'datetime':
          return Highcharts.dateFormat('%b/%e/%Y', value);
        default:
          break;
      }
    }
    return y;
  }

  const options = useMemo(() => {
    const chartOptions = {
      colors,
      chart: {
        type,
        style: {
          fontFamily: '"Open Sans", Arial, Helvetica, sans-serif',
          fontSize: '12px',
          height,
        },
      },
      credits: {
        enabled: false,
      },
      title: {
        text: '',
      },
      legend: {
        enabled: true,
      },
      xAxis: {
        visible: xAxisVisible,
        categories,
        type: xAxisType,
        labels:
          xAxisType === 'datetime'
            ? {
                formatter() {
                  const { value } = this;
                  return moment(value).format('DD/MM/YYYY');
                },
                rotation,
              }
            : { rotation },
      },
      yAxis: {
        title: {
          text: yAxisText,
        },
        max: yAxisMax,
      },
      plotOptions: {
        spline: {
          marker: {
            enabled: marker,
          },
        },
        series: {
          cursor: 'pointer',
          dataLabels: {
            enabled: plotLabels,
            formatter: lableFormatter,
          },
        },
        pie: {
          showInLegend: true,
        },
      },
      series,
      drilldown: {
        breadcrumbs: {
          format: '{level.name}',
          position: {
            align: 'right',
          },
        },
        series: drilldown,
      },
      lang: {
        noData: `${t('pages.dashboard.noDataToDisplay')}`,
      },
      noData: {
        style: {
          fontWeight: 'bold',
          fontSize: '15px',
          fontFamily: 'Montserrat,sans serif',
        },
        position: {
          x: 0,
          y: 0,
          align: 'center',
          verticalAlign: 'middle',
        },
      },
    };

    return chartOptions;
  }, [series]);

  const handleExpand = () => {
    setFlexExpand(flexExpand === 'auto' ? '2' : 'auto');
    setPropsChartCopy((prev: any) => {
      const filter = prev.filter((f: any) => f.key === key);
      filter[0].defaultExpand = flexExpand === 'auto' ? '2' : 'auto';
      return prev;
    });
  };

  const getChart = (chart: any) => {
    setChartEvent((prev: any) => [...prev, chart]);
  };

  const handleChange = async (target: any) => {
    const filterProps = propsChartCopy.filter((item: any) => item.key !== target.name);
    setHandleOptionButtons({ key: target.name, buttonValue: target.value });
    setPropsChart(filterProps);
  };

  useEffect(() => {
    if (chartEvent.length) {
      chartEvent.forEach((event: any) => {
        event.reflow();
      });
    }
  }, [flexExpand]);

  return (
    <BoxChart style={{ flex: flexExpand }}>
      <div className="area-title-options">
        <Title>{title}</Title>
        {buttons && (
          <Group name={referenceKey} value={buttonValue} buttonStyle="solid" onChange={e => handleChange(e.target)}>
            {buttons.map((item: any) => (
              <Button key={item.value} value={item.value}>
                {item.label}
              </Button>
            ))}
          </Group>
        )}
        <div className="expand">
          {flexExpand === 'auto' ? (
            <BsArrowsAngleContract onClick={() => handleExpand()} />
          ) : (
            <BsArrowsAngleExpand onClick={() => handleExpand()} />
          )}
        </div>
      </div>
      <HighchartsReact highcharts={Highcharts} options={options} callback={getChart} />
    </BoxChart>
  );
};

import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import PermissionedComponent from 'components/PermissionedComponent';
import { Button } from 'components/ui/button';
import { ComboBox, OptionData } from 'components/ui/combo';
import { FormItem } from 'components/ui/form-item';
import { GroupForm } from 'components/ui/group-form';
import {
  FormalizationTemplateOption,
  useGetFormalizationTemplatesQuery,
} from 'pages/CreditRequest/api/useGetFormalizationTemplatesQuery';
import { usePackageFormStateStore } from 'pages/CreditRequest/store/usePackageFormStateStore';
import { DocumentToCollectData } from 'types/CreditRequest/DocumentToCollectData';
import { DocumentToCollectFormData } from 'types/CreditRequest/forms/DocumentToCollectFormData';
import { v4 as uuid } from 'uuid';

import { DocumentsToCollectModal } from './documents-collect.modal';
import { DocumentsToCollectTable } from './documents-collect.table';

export const DocumentsToCollectSection = () => {
  const { t } = useTranslation();
  const form = useFormContext();

  const selectedDocumentToCollectToEdit = usePackageFormStateStore(state => state.selectedDocumentToCollectToEdit);

  const formalizationTemplatesQuery = useGetFormalizationTemplatesQuery({
    asSelectOptions: true,
  });

  const updateDocumentsToCollectTable = (data: DocumentToCollectFormData) => {
    const { documentsToCollect } = form.getValues();
    const oldDocuments = [...documentsToCollect] as DocumentToCollectFormData[];
    const index = oldDocuments.findIndex(
      document =>
        (document.id && document.id === selectedDocumentToCollectToEdit.newId) ||
        (document.id && document.id === selectedDocumentToCollectToEdit.id),
    );
    oldDocuments[index] = { ...oldDocuments[index], ...data };
    form.setValue('documentsToCollect', oldDocuments);
  };

  const handleRegisterDocumentToCollect = (data: DocumentToCollectFormData) => {
    if (data.id && !data.id.includes('new-')) return updateDocumentsToCollectTable(data);

    const { documentsToCollect } = form.getValues();
    form.setValue('documentsToCollect', [...documentsToCollect, { ...data, id: `new-${uuid()}` }]);
  };

  const handleSelectTemplate = (_: string, option: OptionData<DocumentToCollectFormData>) => {
    const array: DocumentToCollectFormData[] = [];
    option.data.documents.forEach((item: any, index: number) => {
      array[index] = {
        documents_files: [],
        comments: [],
        name: item.name,
        index,
        id: `new-${uuid()}`,
      } as DocumentToCollectFormData;
    });

    // if (option) setDocumentsToCollect([...selectedTemplates, ...array]);
    const selectedTemplates = form.getValues('documentsToCollect') as DocumentToCollectData[];
    form.setValue('documentsToCollect', [...selectedTemplates, ...array]);
  };

  return (
    <>
      <GroupForm
        title={t('pages.credit-request.formalization-guarantees.pre-registration-docs.modalPackage.ckecklists')}
      >
        <div className="flex gap-2 items-end w-full">
          <FormItem
            className="w-full"
            label={t('pages.credit-request.formalization-guarantees.pre-registration-docs.modalPackage.template')}
            name="template"
          >
            <PermissionedComponent permission={['edocument.template.templatedocument.show']}>
              <ComboBox
                className="w-full"
                options={formalizationTemplatesQuery.data as FormalizationTemplateOption[]}
                onValueChange={handleSelectTemplate}
              />
            </PermissionedComponent>
          </FormItem>

          <DocumentsToCollectModal
            className="w-full overflow-x-hidden max-w-[300px]"
            onSuccess={handleRegisterDocumentToCollect}
            shouldRegister={!!selectedDocumentToCollectToEdit}
          >
            <Button
              type="button"
              className="w-full"
              title={t(
                'pages.credit-request.formalization-guarantees.pre-registration-docs.modalPackage.buttonChecklist',
              )}
            >
              <span className="overflow-hidden overflow-ellipsis whitespace-nowrap min-w-0 w-full block">
                {t('pages.credit-request.formalization-guarantees.pre-registration-docs.modalPackage.buttonChecklist')}
              </span>
            </Button>
          </DocumentsToCollectModal>
        </div>

        <div className="mt-2">
          <DocumentsToCollectTable onEdit={updateDocumentsToCollectTable} />
        </div>
      </GroupForm>
    </>
  );
};

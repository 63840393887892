import styled from 'styled-components';

export const SDataJustification = styled.div`
  .area-details {
    margin: 10px;
    .label {
      font-weight: bold;
    }
    .description {
    }
  }
`;

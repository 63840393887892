import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 20% 60% 20%;
  height: 100%;
  width: 100%;

  @media (max-width: 1500px) {
    grid-template-columns: 70% 30%;
  }
  @media (max-width: 1100px) {
    grid-template-columns: 100%;
  }
`;

import React from 'react';
import { useFormContext } from 'react-hook-form';

import {
  AttachmentTable,
  AttachmentTableDelete,
  AttachmentTableDeleteProps,
  AttachmentTableDownload,
  AttachmentTableDownloadProps,
  AttachmentTablePreview,
  AttachmentTablePreviewProps,
  AttachmentTableProps,
} from './attachment.table';

type AttachmentFormTableProps = {
  formFieldName: string;
  delete?: AttachmentTableDeleteProps;
  preview?: AttachmentTablePreviewProps;
  previewWatermark?: AttachmentTablePreviewProps;
  download?: AttachmentTableDownloadProps;
  downloadWatermark?: AttachmentTableDownloadProps;
  enableWatermark?: boolean;
};

export const AttachmentFormTable = ({
  formFieldName,
  preview: previewProps = {},
  previewWatermark: previewWatermarkProps = {},
  download: downloadProps = {},
  delete: deleteProps = {},
  columnsConfig,
  enableWatermark,
  ...props
}: AttachmentFormTableProps &
  Pick<AttachmentTableProps, 'columnsConfig' | 'hideDownloadAll' | 'hideDownloadNotDownloaded'>) => {
  const form = useFormContext();

  const files = form.watch(formFieldName);

  return (
    <AttachmentTable
      files={files || []}
      columnsConfig={columnsConfig}
      isReadonly={form.formState.disabled}
      enableWatermark={enableWatermark}
      {...props}
    >
      <AttachmentTableDownload
        {...downloadProps}
        callback={(id, file) => {
          const files = form.getValues(formFieldName).map((f: any) => (f.id === id ? file : f));
          form.setValue(formFieldName, files);
        }}
      />
      <AttachmentTablePreview {...previewProps} />
      <AttachmentTablePreview {...previewWatermarkProps} enableWatermark={enableWatermark} />
      <AttachmentTableDelete
        {...deleteProps}
        callback={(id, doc) => {
          form.setValue(
            formFieldName,
            form.getValues(formFieldName).filter((f: any) => f.id !== id),
          );
          if (deleteProps.callback) deleteProps.callback(id, doc);
        }}
      />
    </AttachmentTable>
  );
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import { AiFillInfoCircle } from 'react-icons/ai';

import { Popover } from 'antd';

export const FieldNotNecessaryTooltip = () => {
  const { t } = useTranslation();

  return (
    <Popover content={() => t('pages.monitoring.cpr.form.notNecessaryMessage')}>
      <AiFillInfoCircle />
    </Popover>
  );
};

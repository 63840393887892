import { Radio, Pagination } from 'antd';
import styled from 'styled-components';

export const RadioGroup = styled(Radio.Group)`
  display: flex;
  flex-direction: column;
  gap: 20px;
  background: none !important;
`;

export const SPagination = styled(Pagination)`
  display: flex !important;
  gap: 5px;

  .ant-pagination-total-text {
    font-size: 12px;
    color: ${({ theme: { colors } }) => colors.greys.grey07};
  }
`;

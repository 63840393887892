/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';

import dictionaryData from './dictionaryData.json';
import { Body, Header, HeaderRow, SectionLabel } from './styles';

// Como atualizar o dicionário:
// Inspecionar a tabela do confluence e copiar o HTML
// Jogar o HTML nesse site https://www.convertjson.com/html-table-to-json.htm
// Copiar o JSON gerado e colar no arquivo dictionaryData.json

export const DictionaryTab = () => {
  const getHeader = () => {
    return Object.values(dictionaryData[0]).map(key => {
      return <HeaderRow key={key}>{key}</HeaderRow>;
    });
  };

  const getRows = () => {
    if (!dictionaryData) return null;
    const dictionaryDataClone = [...dictionaryData];
    return dictionaryDataClone.slice(1, dictionaryDataClone.length - 1).map(item => {
      if (!item.FIELD2 && !item.FIELD3 && !item.FIELD4 && !item.FIELD5)
        return (
          <SectionLabel key={item.FIELD1}>
            <td>{item.FIELD1}</td>
            <td />
            <td />
            <td />
            <td />
          </SectionLabel>
        );

      return (
        <tr key={item.FIELD1}>
          <td>{item.FIELD1}</td>
          <td>{item.FIELD2}</td>
          <td>{item.FIELD3}</td>
          <td>{item.FIELD4}</td>
          <td>{item.FIELD5}</td>
        </tr>
      );
    });
  };

  return (
    <div style={{ overflowX: 'auto' }}>
      <table>
        <Header>
          <tr>{getHeader()}</tr>
        </Header>
        <Body>{getRows()}</Body>
      </table>
    </div>
  );
};

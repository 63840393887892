import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Col, Row, TablePaginationConfig, Tooltip } from 'antd';
import { IconWithTooltip } from 'components';
import { checkIfFilePreviewerSupportsExtension } from 'components/FilePreviewer';
import { TableData } from 'compositions';
import { useCreditRequestContext } from 'context/CreditRequestContext';
import { usePage } from 'hooks/page';
import moment from 'moment';
import { useCreditRequestStore } from 'pages/CreditRequest/store/useCreditRequestStore';
import creditRequestApi from 'services/api/credit';
import documentApi from 'services/api/document';
import { downloadHandler } from 'services/download';

import type DTOStatusHistory from '../../../../../@types/dtos/credit-request/DTOStatusHistory';
import type DTOErrorReponse from '../../../../../@types/dtos/DTOErrorReponse';
import appConfigs from '../../../../../configs/app';
import { columns } from './columns';

export const StatusHistory = () => {
  const { t } = useTranslation();
  const { alertStatus } = usePage();
  const { handlePreviewFile } = useCreditRequestContext();

  const [isLoadingCreditRequestStatusHistory, setIsLoadingCreditRequestStatusHistory] = useState(false);
  const [statusHistory, setStatusHistory] = useState<DTOStatusHistory[]>([]);

  const statusHistoryStore = useCreditRequestStore(state => state.statusHistory);
  const creditRequestId = useCreditRequestStore(state => state.creditRequestId);

  const [paginationConfigStatusHistory, setPaginationConfigStatusHistory] = useState<
    TablePaginationConfig & { perPage: number }
  >({
    current: 1,
    total: 1,
    perPage: 10,
  });

  const documentLoading = useCreditRequestStore(state => state.documentLoading);
  const setDocumentLoading = useCreditRequestStore(state => state.setDocumentLoading);

  useEffect(() => setStatusHistory(statusHistoryStore), [statusHistoryStore]);

  return (
    <div className="group-form">
      <h2>{t('pages.credit-request.form.titles.status-history')}</h2>

      <Row gutter={[8, 8]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <TableData
            rowKey="id"
            columns={columns.map(col => {
              switch (col.key) {
                case 'from':
                case 'to':
                case 'who':
                  return {
                    ...col,
                    render: (active: number, record: DTOStatusHistory) => {
                      const value: any = record;
                      if (col.key === 'who') {
                        return value.user?.name || ' - ';
                      }
                      return value[col.key]?.name || ' - ';
                    },
                  };
                case 'when':
                  return {
                    ...col,
                    render: (active: number, record: DTOStatusHistory) => {
                      return record.created_at
                        ? moment(record.created_at).format(appConfigs.formatDateFullTime)
                        : ' - ';
                    },
                  };
                case 'description':
                  return {
                    ...col,
                    render: (active: number, record: DTOStatusHistory) => {
                      if (record.comments) {
                        const commentPartial =
                          record.comments.length > 25
                            ? `${record.comments.substr(0, 25)}...`
                            : record.comments.substr(0, 25);

                        return (
                          <Tooltip title={record.comments} placement="top" overlayClassName="status-historic-tooltip">
                            {commentPartial}
                          </Tooltip>
                        );
                      }
                      return ' - ';
                    },
                  };
                case 'documents':
                  return {
                    ...col,
                    render: (active: number, record: DTOStatusHistory) => {
                      return (
                        <ul style={{ margin: '0' }}>
                          {record.documents?.map(doc => (
                            <>
                              {doc && (
                                <li key={doc.id} style={{ display: 'flex' }}>
                                  <div>{doc.name || ''}</div>
                                  <IconWithTooltip
                                    action="download"
                                    title={t('pages.credit-request.table.icons.download')}
                                    loading={doc.id && documentLoading === doc.id}
                                    onClick={async () => {
                                      setDocumentLoading(doc.id);
                                      await documentApi
                                        .download(doc.id)
                                        .then((response: any) => downloadHandler(response))
                                        .catch((err: DTOErrorReponse) => alertStatus(err, 'error'))
                                        .finally(() => setDocumentLoading(null));
                                    }}
                                  />
                                  {checkIfFilePreviewerSupportsExtension(doc.extension) && (
                                    <IconWithTooltip
                                      action="detail"
                                      title={t('pages.credit-request.table.icons.preview')}
                                      onClick={() => {
                                        handlePreviewFile(doc);
                                      }}
                                    />
                                  )}
                                </li>
                              )}
                            </>
                          ))}
                        </ul>
                      );
                    },
                  };
                default:
                  return {
                    ...col,
                    render: (active: number, record: DTOStatusHistory) => {
                      const value: any = record;
                      return value[col.key];
                    },
                  };
              }
            })}
            loading={isLoadingCreditRequestStatusHistory}
            dataSource={statusHistory ?? []}
            onChange={(pagination: TablePaginationConfig) => {
              setPaginationConfigStatusHistory(paginationConfigState => ({
                ...paginationConfigState,
                current: pagination.current,
                per_page: pagination.pageSize,
              }));

              setIsLoadingCreditRequestStatusHistory(true);
              creditRequestApi.requests
                .getCreditRequestStatusHistory(creditRequestId, {
                  params: {
                    page: pagination.current,
                    per_page: pagination.pageSize,
                  },
                })
                .then((res: any) => {
                  setPaginationConfigStatusHistory(paginationConfigState => ({
                    ...paginationConfigState,
                    total: res.data.total,
                  }));

                  setStatusHistory(res.data.data ?? []);
                })
                .finally(() => setIsLoadingCreditRequestStatusHistory(false));
            }}
            pagination={paginationConfigStatusHistory}
            tableConfig={{ nopagination: false }}
          />
        </Col>
      </Row>
    </div>
  );
};

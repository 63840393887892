import React from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from 'antd';

export const useSurveyStatusInfoModal = () => {
  const { t } = useTranslation();

  const info = () => {
    Modal.info({
      title: t('pages.monitoring.survey.modalInfo.title'),
      content: (
        <div>
          <p>
            <b>{t('pages.monitoring.survey.modalInfo.crop_condition')}</b>
          </p>
          <p>
            {t('pages.monitoring.survey.modalInfo.red')} - {t('pages.monitoring.survey.modalInfo.crop_condition_red')}
          </p>
          <p>
            {t('pages.monitoring.survey.modalInfo.yellow')} -{' '}
            {t('pages.monitoring.survey.modalInfo.crop_condition_yellow')}
          </p>
          <p>
            {t('pages.monitoring.survey.modalInfo.green')} -{' '}
            {t('pages.monitoring.survey.modalInfo.crop_condition_green')}
          </p>

          <p>
            <b>{t('pages.monitoring.survey.modalInfo.is_correct_window_2')}</b>
          </p>
          <p>
            {t('pages.monitoring.survey.modalInfo.green')} - {t('pages.monitoring.survey.modalInfo.yes')}
          </p>
          <p>
            {t('pages.monitoring.survey.modalInfo.yellow')} - {t('pages.monitoring.survey.modalInfo.no')}
          </p>

          <p>
            <b>{t('pages.monitoring.survey.modalInfo.is_harvest_broken_risk')}</b>
          </p>
          <p>
            {t('pages.monitoring.survey.modalInfo.red')} - {t('pages.monitoring.survey.modalInfo.yes')}
          </p>
          <p>
            {t('pages.monitoring.survey.modalInfo.green')} - {t('pages.monitoring.survey.modalInfo.no')}
          </p>

          <p>
            <b>{t('pages.monitoring.survey.modalInfo.is_soil_conservation')}</b>
          </p>
          <p>
            {t('pages.monitoring.survey.modalInfo.green')} - {t('pages.monitoring.survey.modalInfo.proper')}
          </p>
          <p>
            {t('pages.monitoring.survey.modalInfo.yellow')} - {t('pages.monitoring.survey.modalInfo.inappropriate')}
          </p>

          <p>
            <b>{t('pages.monitoring.survey.modalInfo.is_control_performed')}</b>
          </p>
          <p>
            {t('pages.monitoring.survey.modalInfo.green')} - {t('pages.monitoring.survey.modalInfo.proper')}
          </p>
          <p>
            {t('pages.monitoring.survey.modalInfo.yellow')} - {t('pages.monitoring.survey.modalInfo.inappropriate')}
          </p>

          <p>
            <b>{t('pages.monitoring.survey.modalInfo.rust_control_applications')}</b>
          </p>
          <p>
            {t('pages.monitoring.survey.modalInfo.green')} - {t('pages.monitoring.survey.modalInfo.proper')}
          </p>
          <p>
            {t('pages.monitoring.survey.modalInfo.yellow')} - {t('pages.monitoring.survey.modalInfo.inappropriate')}
          </p>

          <p>
            <b>{t('pages.monitoring.survey.modalInfo.insecticide_control_applications')}</b>
          </p>
          <p>
            {t('pages.monitoring.survey.modalInfo.green')} - {t('pages.monitoring.survey.modalInfo.proper')}
          </p>
          <p>
            {t('pages.monitoring.survey.modalInfo.red')} - {t('pages.monitoring.survey.modalInfo.inappropriate')}
          </p>

          <p>
            <b>{t('pages.monitoring.survey.modalInfo.traffic_light_rules')}</b>
          </p>
          <p>{t('pages.monitoring.survey.modalInfo.rule1')}</p>
          <p>{t('pages.monitoring.survey.modalInfo.rule2')}</p>
          <p>{t('pages.monitoring.survey.modalInfo.rule3')}</p>
        </div>
      ),
    });
  };

  return { info };
};

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useTable } from '@agrodatabr/agrodataui';
import { Modal } from 'antd';

import { Breadcrumb } from '../../../components';
import { SContainer, SHeader, STitlePage } from '../../../styles';
import { Documents } from './Documents';
import { useIntegrations } from './hooks/useIntegrations';
import { Table } from './Table';

export const SerasaIntegrations: React.FC = () => {
  const [documents, setDocuments] = useState([]);
  const [modalDocumentsVisible, setModalDocumentsVisible] = useState(false);
  const { t } = useTranslation();
  const tableController = useTable();
  const { handleTableChange, isFetching, downloadDocument } = useIntegrations();

  return (
    <div>
      <Breadcrumb items={[{ title: t('breadcrumb.serasa') }, { title: t('breadcrumb.serasa_integrations') }]} />
      <SContainer>
        <SHeader>
          <STitlePage>{t('pages.serasa.integrations.title')}</STitlePage>
        </SHeader>

        <Table
          setModalDocumentsVisible={setModalDocumentsVisible}
          setDocuments={setDocuments}
          controller={tableController}
          isFetching={isFetching}
          handleChange={handleTableChange}
        />
        <Modal
          title={t('pages.serasa.integrations.modal.title')}
          visible={modalDocumentsVisible}
          width={780}
          onCancel={() => setModalDocumentsVisible(false)}
          footer={null}
        >
          <Documents documents={documents} downloadDocument={downloadDocument} />
        </Modal>
      </SContainer>
    </div>
  );
};

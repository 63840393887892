import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ComboBox } from 'components/ui/combo';
import { FormGrid } from 'components/ui/form-grid';
import { FormItem } from 'components/ui/form-item';
import { GroupForm } from 'components/ui/group-form';
import { Input, InputWithMask } from 'components/ui/input';
import { useCache } from 'hooks/cache';

import { useRegisteredCustomerStore } from './stores/use-registered-customer-store';

export const RegisteredCustomerAddressSection = () => {
  const { t } = useTranslation();
  const cache = useCache();
  const form = useFormContext();

  const stateId = form.watch('addresses.0.state_id');

  const stateOptions = cache.getCreditOptions('state');
  const cityOptions = useMemo(() => cache.getCityByStateOptions(stateId), [stateId]);

  const isFinalizing = useRegisteredCustomerStore(state => state.isFinalizing);
  const isViewing = useRegisteredCustomerStore(state => state.isViewing);
  const isModulePerson = useRegisteredCustomerStore(state => state.isModulePerson);

  return (
    <div>
      <GroupForm title={t('address')}>
        <FormGrid>
          <FormItem
            label={t('pages.registered-customers.form.pj.addresses.0.zip_code')}
            name="addresses.0.zip_code"
            rules={{ required: isFinalizing }}
          >
            <InputWithMask
              name="addresses.0.zip_code"
              mask="99999-999"
              placeholder="_____-___"
              disabled={isViewing || !isModulePerson}
            />
          </FormItem>

          <FormItem
            label={t('pages.registered-customers.form.pj.addresses.0.state_id')}
            name="addresses.0.state_id"
            rules={{ required: isFinalizing }}
          >
            <ComboBox options={stateOptions} disabled={isViewing || !isModulePerson} />
          </FormItem>

          <FormItem
            label={t('pages.registered-customers.form.pj.addresses.0.city_id')}
            name="addresses.0.city_id"
            rules={{ required: isFinalizing }}
          >
            <ComboBox options={cityOptions} disabled={!stateId || isViewing || !isModulePerson} />
          </FormItem>

          <FormItem
            label={t('pages.registered-customers.form.pj.addresses.0.street')}
            name="addresses.0.neighborhood"
            rules={{ required: isFinalizing }}
          >
            <Input disabled={isViewing || !isModulePerson} />
          </FormItem>

          <FormItem
            label={t('pages.registered-customers.form.pj.addresses.0.neighborhood')}
            name="addresses.0.street"
            className="col-span-2"
            rules={{ required: isFinalizing }}
          >
            <Input disabled={isViewing || !isModulePerson} />
          </FormItem>

          <FormItem
            label={t('pages.registered-customers.form.pj.addresses.0.complement')}
            name="addresses.0.complement"
            className="col-span-2"
          >
            <Input disabled={isViewing || !isModulePerson} />
          </FormItem>
        </FormGrid>
      </GroupForm>
    </div>
  );
};

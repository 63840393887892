import React from 'react';
import { useFormContext } from 'react-hook-form';

import { useDeleteDocumentToCollectCommentMutation } from 'pages/CreditRequest/api/useDeleteDocumentToCollectCommentMutation';
import { usePackageFormStateStore } from 'pages/CreditRequest/store/usePackageFormStateStore';
import { DocumentToCollectCommentData } from 'types/CreditRequest/DocumentToCollectCommentData';

import { BaseCommentData, CommentsTable } from '../../comments-table';

export const DocumentsToCollectCommentsTable = () => {
  const { watch, setValue } = useFormContext();

  const comments = watch('comments') as DocumentToCollectCommentData[];

  const documentToCollectCommentBeingDeletedId = usePackageFormStateStore(
    state => state.documentToCollectCommentBeingDeletedId,
  );
  const setDocumentToCollectCommentBeingDeletedId = usePackageFormStateStore(
    state => state.setDocumentToCollectCommentBeingDeletedId,
  );

  const deleteCommentMutation = useDeleteDocumentToCollectCommentMutation();

  const handleDeleteComment = async (comment: BaseCommentData) => {
    const commentId = comment.id;
    if (!comment.isNew) {
      setDocumentToCollectCommentBeingDeletedId(commentId);
      await deleteCommentMutation.mutateAsync(commentId, {
        onSuccess: () => {
          setValue(
            'comments',
            comments.filter(comment => comment.id !== commentId),
          );
        },
      });
      setDocumentToCollectCommentBeingDeletedId(null);
    } else
      setValue(
        'comments',
        comments.filter(comment => comment.id !== commentId),
      );
  };

  return (
    <CommentsTable
      comments={comments.map(comment => ({
        ...comment,
      }))}
      entityBeingDeletedId={documentToCollectCommentBeingDeletedId}
      handleDeleteComment={handleDeleteComment}
      deletePermission="credit.request.package.destroy"
    />
  );
};

import {
  DIVISION_GEO_ID,
  DIVISION_SOFT_ID,
  OPERATION_DELIVERED_PRODUCT_ADVANCE_ID,
  OPERATION_FERTILIZER_SUPPLIERS_ID,
  OPERATION_INTERN_MARKET_ID,
  OPERATION_MTM_ID,
  OPERATION_PREFIN_ID,
  OPERATION_PREFIN_SUGAR_MILLS_ID,
  OPERATION_PREPAY_ID,
  SUBDIVISION_COFFEE_ID,
  SUBDIVISION_CORN_ID,
  SUBDIVISION_COTTON_ID,
  SUBDIVISION_COTTON_SEEDS_ID,
  SUBDIVISION_CRUSHING_ID,
  SUBDIVISION_ENERGY_ID,
  SUBDIVISION_ETHANOL_ID,
  SUBDIVISION_FERTILIZER_ID,
  SUBDIVISION_SOYBEAN_ID,
  SUBDIVISION_SUGAR_MILLS_ID,
  SUBDIVISION_SUGAR_TRADING_ID,
  SUBDIVISION_WHEAT_ID,
} from 'configs/constants';
import { StateCreator } from 'zustand';

import { CreditRequestSlice } from '../../useCreditRequestStore';

export type Operations =
  | typeof OPERATION_PREFIN_ID
  | typeof OPERATION_PREFIN_SUGAR_MILLS_ID
  | typeof OPERATION_PREPAY_ID
  | typeof OPERATION_MTM_ID
  | typeof OPERATION_INTERN_MARKET_ID
  | typeof OPERATION_FERTILIZER_SUPPLIERS_ID
  | typeof OPERATION_DELIVERED_PRODUCT_ADVANCE_ID;

export type Divisions = typeof DIVISION_GEO_ID | typeof DIVISION_SOFT_ID;

export type SubDivisions =
  | typeof SUBDIVISION_SOYBEAN_ID
  | typeof SUBDIVISION_CORN_ID
  | typeof SUBDIVISION_COTTON_ID
  | typeof SUBDIVISION_COTTON_SEEDS_ID
  | typeof SUBDIVISION_CRUSHING_ID
  | typeof SUBDIVISION_WHEAT_ID
  | typeof SUBDIVISION_COFFEE_ID
  | typeof SUBDIVISION_SUGAR_MILLS_ID
  | typeof SUBDIVISION_SUGAR_TRADING_ID
  | typeof SUBDIVISION_ETHANOL_ID
  | typeof SUBDIVISION_ENERGY_ID
  | typeof SUBDIVISION_FERTILIZER_ID;

export type DivisionSlice = {
  selectedOperationId: Operations | null;
  setSelectedOperationId: (id: Operations | null) => void;
  selectedDivisionId: Divisions | null;
  setSelectedDivisionId: (id: Divisions | null) => void;
  selectedSubDivisionId: SubDivisions | null;
  setSelectedSubDivisionId: (id: SubDivisions | null) => void;
  isGEO: boolean;
  isSOFT: boolean;
  isPrepay: boolean;
  isPrefin: boolean;
  isPrefinSugarMills: boolean;
  isMTM: boolean;
  isInternMarket: boolean;
  isDeliveredProductAdvance: boolean;
  isCoffee: boolean;
  isCotton: boolean;
  isSugarMills: boolean;
  isSugarTrading: boolean;
};

export const divisionSliceInitialState = {
  selectedOperationId: null,
  selectedDivisionId: null,
  selectedSubDivisionId: null,
  isGEO: false,
  isSOFT: false,
  isPrepay: false,
  isPrefin: false,
  isPrefinSugarMills: false,
  isMTM: false,
  isInternMarket: false,
  isDeliveredProductAdvance: false,
  isCoffee: false,
  isCotton: false,
  isSugarMills: false,
  isSugarTrading: false,
} as DivisionSlice;

export const createDivisionSlice: StateCreator<DivisionSlice & CreditRequestSlice, [], [], DivisionSlice> = set => ({
  ...divisionSliceInitialState,
  setSelectedOperationId: id =>
    set({
      selectedOperationId: id,
      isPrepay: id === OPERATION_PREPAY_ID,
      isDeliveredProductAdvance: id === OPERATION_DELIVERED_PRODUCT_ADVANCE_ID,
      isPrefin: id === OPERATION_PREFIN_ID,
      isPrefinSugarMills: id === OPERATION_PREFIN_SUGAR_MILLS_ID,
      isMTM: id === OPERATION_MTM_ID,
      isInternMarket: id === OPERATION_INTERN_MARKET_ID,
    }),
  setSelectedDivisionId: id =>
    set({ selectedDivisionId: id, isGEO: id === DIVISION_GEO_ID, isSOFT: id === DIVISION_SOFT_ID }),
  setSelectedSubDivisionId: id =>
    set({
      selectedSubDivisionId: id,
      isCoffee: id === SUBDIVISION_COFFEE_ID,
      isCotton: id === SUBDIVISION_COTTON_ID,
      isSugarMills: id === SUBDIVISION_SUGAR_MILLS_ID,
      isSugarTrading: id === SUBDIVISION_SUGAR_TRADING_ID,
    }),
});

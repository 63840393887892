import { LEGAL_PERSON_TYPE } from 'configs/constants';
import { RegisteredCustomerData } from 'types/CreditRequest/RegisteredCustomerData';
import { FileUtils } from 'utils/File';
import { Formatter } from 'utils/Formatter';

import { RegisteredCustomerFormData } from './registered-customer.form';

export const toApi = async ({
  partners,
  files,
  main_provider,
  liquid_receipt,
  main_client,
  economic_group,
  cofco_opinion,
  delivery_history,
  simple_agricultural_plan,
  commercial_reference,
  bank_reference,
  ...data
}: RegisteredCustomerFormData) => {
  const base64Attachments = files?.length
    ? await Promise.all(
        files?.map(async file => {
          const base64 = await FileUtils.getBase64(file);
          return base64;
        }),
      )
    : [];

  return {
    ...data,
    id: data.id?.includes('new-') ? null : data.id,
    cnpj: data.cnpj && data.cnpj?.includes('.') ? data.cnpj : Formatter.cnpj(data.cnpj),
    cpf: data.cpf && data.cpf?.includes('.') ? data.cpf : Formatter.cpf(data.cpf),
    json_data: {
      ...data.json_data,
      person_type: data.type,
      phone_number: data.json_data?.phone_number?.replace(/\D/g, ''),
      cel_number: data.json_data?.cel_number?.replace(/\D/g, ''),
    },
    bank_reference: bank_reference?.map(reference => ({
      ...reference,
      id: reference.id?.includes('new-') ? null : reference.id,
      json_data: {
        agency: reference.agency,
        bank_id: reference.bank_id,
      },
    })),
    compliance:
      data.json_data?.is_employee_relationship || data.json_data?.has_conflict_with_employee
        ? data.compliance?.map(compliance => ({
            ...compliance,
            id: compliance.id?.includes('new-') ? null : compliance.id,
          }))
        : [],
    partner: partners?.map(partner => ({
      ...partner,
      doc_number: partner.doc_number.replace(/\D/g, ''),
      id: partner.id?.includes('new-') ? null : partner.id,
    })),
    documents:
      base64Attachments?.map((base64, index) => {
        const file = files[index];
        return {
          image: base64,
          mime: file.type,
          name: FileUtils.getOriginalName(file.name, true),
          original: FileUtils.getOriginalName(file.name),
        };
      }) ?? [],
    provider_reference:
      data.type === 'App\\Model\\LegalPerson'
        ? main_provider?.map(provider => ({
            ...provider,
            phone_number: provider.phone_number?.replace(/\D/g, ''),
            id: provider.id?.includes('new-') ? null : provider.id,
          }))
        : commercial_reference?.map(reference => ({
            ...reference,
            phone_number: reference.phone_number?.replace(/\D/g, ''),
            id: reference.id?.includes('new-') ? null : reference.id,
            json_data: {
              ...reference.json_data,
              credit_limit: reference.credit_limit,
              warranty: reference.warranty,
            },
          })),
    net_revenues: liquid_receipt?.map(revenue => ({
      ...revenue,
      id: revenue.id?.includes('new-') ? null : revenue.id,
    })),
    customer_reference: main_client?.map(customer => ({
      ...customer,
      phone_number: customer.phone_number?.replace(/\D/g, ''),
      id: customer.id?.includes('new-') ? null : customer.id,
    })),
    economic_groups: economic_group?.map(group => ({
      ...group,
      doc_number: group.doc_number?.replace(/\D/g, ''),
      id: group.id?.includes('new-') ? null : group.id,
    })),
    commercial_opinions:
      cofco_opinion?.map(opinion => ({
        ...opinion,
        id: opinion.id?.includes('new-') ? null : opinion.id,
      })) ?? [],
    delivery_histories:
      delivery_history?.map(history => ({
        ...history,
        id: history.id?.includes('new-') ? null : history.id,
      })) ?? [],
    simple_plans:
      simple_agricultural_plan?.map(plan => ({
        ...plan,
        id: plan.id?.includes('new-') ? null : plan.id,
      })) ?? [],
  };
};

export const toForm = (data: RegisteredCustomerData): RegisteredCustomerFormData => {
  return {
    ...data,
    type: data.json_data?.person_type,
    birth_state_id: data.city?.state_id,
    'addresses.0.state_id': data.addresses?.[0]?.city?.state_id,
    simple_agricultural_plan: data.simple_plans ?? [],
    liquid_receipt: data.net_revenues ?? [],
    main_provider:
      data.provider_reference?.map(reference => ({
        ...reference,
        state_id: reference.city?.state_id,
      })) ?? [],
    // main_client: data.customer_reference,
    partners: data.partner ?? [],
    economic_group: data.economic_groups ?? [],
    cofco_opinion: data.commercial_opinions?.map(opinion => ({
      ...opinion,
      user_name: opinion.user?.name,
    })),
    main_client: data.customer_reference?.map(customer => ({
      ...customer,
      state_id: customer.city?.state_id,
    })),
    delivery_history: data.delivery_histories ?? [],
    bank_reference:
      data.bank_reference?.map(reference => ({
        ...reference,
        bank_id: reference.json_data?.bank_id,
        agency: reference.json_data?.agency,
        state_id: reference.city?.state_id,
      })) ?? [],
    commercial_reference:
      data.json_data?.person_type !== LEGAL_PERSON_TYPE
        ? data.provider_reference.map(commercialReference => ({
            ...commercialReference,
            warranty: commercialReference.json_data?.warranty,
            credit_limit: commercialReference.json_data?.credit_limit,
          }))
        : [],
    spouse: data.spouse?.map(spouse => ({
      ...spouse,
      json_data: Array.isArray(spouse.json_data) ? {} : spouse.json_data,
    })),
  } as any as RegisteredCustomerFormData;
};

export const RegisteredCustomerFormConverter = {
  toApi,
  toForm,
};

import React, { createContext, useState, useEffect, useContext, useRef } from 'react';

import { v4 as uuid } from 'uuid';

import { DTOContractTypeField } from '../../../types/Contract/DTOContractTypeField';
import { CONTRACT_STATUS_REISSUE_DRAFT, CONTRACT_STATUS_STORED } from '../../../configs/constants';
import { useContract } from '../../../hooks/fetch/useContract';
import { useContractType } from '../../../hooks/fetch/useContractType';
import { useDraft } from '../../../hooks/fetch/useDraft';
import { useContractContext } from './ContractContext';
import { DraftModelContextProps } from './types';

export const DraftModelContext = createContext<DraftModelContextProps>(undefined);

export const useDraftModelContext = () => useContext(DraftModelContext);

const defaultPaginatedDraftModels = {
  data: [] as any[],
  total: 0,
};

export const DraftModelProvider: React.FC = ({ children }) => {
  const {
    contract,
    setSelectedStage,
    needEmissionValidation,
    contractId,
    updateContract,
    isSelectedDocumentMain,
    selectedContract,
    reissueDraft,
  } = useContractContext();

  const { validate } = useContract();
  const { getDraftModel } = useDraft();
  const { showType, isFetching: isFetchingFields, getTemplates } = useContractType();

  const [paginatedDraftModels, setPaginatedDraftModels] = useState(defaultPaginatedDraftModels);
  const [selectedDraftModelId, setSelectedDraftModelId] = useState('');
  const [selectedDraftModel, setSelectedDraftModel] = useState<any>();
  const [isFetchingSelectedDraftModel, setIsFetchingSelectedDraftModel] = useState(false);
  const [isModelConfirmed, setIsModelConfirmed] = useState(false);
  const [modelFields, setModelFields] = useState<DTOContractTypeField[]>([]);
  const [tablesState, setTablesState] = useState<any>({});
  const [selectedEmissionFormatName, setSelectedEmissionFormatName] = useState('');
  const [isIssued, setIsIssued] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const isFormatSelected = (format: any) => format.name === selectedEmissionFormatName;

  const changePage = async (page = 1, perPage = 5, search = '', type = 'parent') => {
    let response = [] as any;

    if (!selectedContract.type) return response;

    if (isSelectedDocumentMain) {
      response = await getTemplates(selectedContract?.type?.id, {
        page,
        perPage,
        params: { search, parent: type },
      });
    } else {
      response = await getTemplates(selectedContract?.type?.id, {
        page,
        perPage,
        params: { search },
      });
    }
    setCurrentPage(page);
    setPaginatedDraftModels(response);
    return response;
  };

  const isDraftModelSelected = (modelId: string) => selectedDraftModelId === modelId;

  const isSelectedModelConfirmed = (modelId: string) => isDraftModelSelected(modelId) && isModelConfirmed;

  const getTableNumberOfRegisters = (key: string) => tablesState[key]?.registers?.length ?? 0;

  const confirmModel = async () => {
    setIsModelConfirmed(true);
    const contractTypeData = await showType(selectedDraftModel.contract_type_id);
    setModelFields(
      contractTypeData?.fields
        ?.map(field => ({
          ...field,
          columns: [
            ...(field.columns ?? []),
            { id: '', is_required: false, name: 'actions', display: 'Options', type_id: '' },
          ],
        }))
        .sort((a, b) => a.order - b.order) ?? [],
    );
  };

  const unconfirmModel = () => setIsModelConfirmed(false);

  const getSelectedDraftModel = async () => {
    if (selectedDraftModelId) {
      const response = await getDraftModel(selectedDraftModelId);
      return response;
    }

    return null;
  };

  const acceptDraft = async () => {
    const response = await validate(contractId, 'before', 'approval');

    if (response) updateContract(response, isSelectedDocumentMain);
  };

  const denyDraft = async (message: string) => {
    const response = await validate(contractId, 'before', 'refuse', message);
    if (response) {
      updateContract(response, isSelectedDocumentMain);
      setSelectedStage(1);
    }
  };

  const handleOpenTable = (name: string) =>
    setTablesState((oldTablesState: any) => ({ ...oldTablesState, [name]: { ...oldTablesState[name], isOpen: true } }));

  const handleCloseTable = (name: string) =>
    setTablesState((oldTablesState: any) => ({
      ...oldTablesState,
      [name]: { ...oldTablesState[name], isOpen: false },
    }));

  const handleAddRegisterToTable = async (field: DTOContractTypeField, values: any) => {
    const formattedValues = {} as { [name: string]: any };
    const rowIndex = uuid();
    Object.keys(values).forEach((key, index) => {
      const fieldTypeData = field.columns?.find((column: any) => column.name === key);

      formattedValues[key] = {
        key,
        value: values[key],
        contract_type_field_id: fieldTypeData.id,
        order: index,
        row: rowIndex,
      };
    });

    setTablesState((oldTablesState: any) => ({
      ...oldTablesState,
      [field.name]: {
        ...oldTablesState[field.name],
        registers: [
          ...(oldTablesState[field.name].registers ?? []),
          { ...formattedValues, id: uuid(), order: oldTablesState[field.name].registers?.length ?? 0 },
        ],
      },
    }));
  };

  const handleRemoveRegisterFromTable = async (tableName: string, registerId: string) => {
    const newRegisters = tablesState[tableName].registers;
    const index = tablesState[tableName].registers?.findIndex((register: any) => register.id === registerId);

    if (index >= 0) {
      newRegisters.splice(index, 1);

      newRegisters.forEach((register: any, i: number) => {
        register.order = i;
      });

      setTablesState((oldTablesState: any) => ({
        ...oldTablesState,
        [tableName]: { ...oldTablesState[tableName], registers: [...newRegisters] },
      }));
    }
  };

  const resetTablesState = () => setTablesState({});

  const updateSelectedModelInState = async () => {
    setIsFetchingSelectedDraftModel(true);
    const response = await getSelectedDraftModel();
    setIsFetchingSelectedDraftModel(false);
    setSelectedDraftModel(response);
  };

  const selectedContractRef = useRef('');

  useEffect(() => {
    updateSelectedModelInState();
  }, [selectedDraftModelId]);

  useEffect(() => {
    if (selectedContract) {
      if (selectedContract.id !== selectedContractRef.current) {
        setCurrentPage(1);
        setSelectedStage(1);
        selectedContractRef.current = selectedContract.id;
      }
    }
  }, [selectedContract]);

  useEffect(() => {
    if (contract) {
      (async () => {
        changePage(1, 5);
        setIsIssued(
          contract.status &&
            contract.status.order > 1 &&
            contract.status.id !== CONTRACT_STATUS_REISSUE_DRAFT &&
            contract.status.id !== CONTRACT_STATUS_STORED,
        );

        if (contract.is_upload || contract.template)
          setSelectedEmissionFormatName(contract?.is_upload ? 'upload' : 'model');
        else if (contract.type?.can_template && contract.type.can_upload) setSelectedEmissionFormatName('');
        else if (contract.type?.can_template) setSelectedEmissionFormatName('model');
        else if (contract.type?.can_upload) setSelectedEmissionFormatName('upload');
        else setSelectedEmissionFormatName('');

        setIsModelConfirmed(!!contract.template);

        setSelectedDraftModelId(contract?.template?.id);
        if (contract.template) {
          const contractTypeData = await showType(contract.template.contract_type_id);
          setModelFields(
            contractTypeData?.fields?.map(field => ({
              ...field,
              columns: [
                ...(field.columns ?? []),
                { id: '', is_required: false, name: 'actions', display: 'Options', type_id: '' },
              ],
            })) ?? [],
          );
        } else setModelFields([]);
      })();
    }
  }, [contract]);

  const value = {
    selectedDraftModelId,
    selectedDraftModel,
    isModelConfirmed,
    paginatedDraftModels,
    isSelectedModelConfirmed,
    isDraftModelSelected,
    confirmModel,
    changePage,
    setSelectedDraftModelId,
    setSelectedDraftModel,
    setIsModelConfirmed,
    unconfirmModel,
    modelFields,
    isFetchingFields,
    handleOpenTable,
    handleCloseTable,
    tablesState,
    handleAddRegisterToTable,
    handleRemoveRegisterFromTable,
    selectedEmissionFormatName,
    setSelectedEmissionFormatName,
    isFormatSelected,
    getTableNumberOfRegisters,
    acceptDraft,
    denyDraft,
    isIssued,
    setIsIssued,
    resetTablesState,
    setTablesState,
    needEmissionValidation,
    setCurrentPage,
    currentPage,
    reissueDraft,
    isFetchingSelectedDraftModel,
  };

  return <DraftModelContext.Provider value={value}>{children}</DraftModelContext.Provider>;
};

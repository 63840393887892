import { useMutation } from '@tanstack/react-query';
import api from 'services/api/api';

export const useDeleteDraftCommentMutation = () => {
  return useMutation({
    mutationKey: ['deleteDraftComment'],
    mutationFn: async (commentId: string) => {
      const response = await api.delete(`envelope/destroy-comment/${commentId}`);
      return response.data;
    },
  });
};

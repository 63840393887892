import React from 'react';
import { useTranslation } from 'react-i18next';

import { InfiniteSelect } from '@agrodatabr/agrodataui';
import { FormItem } from 'components';
import { useCreditRequestContext } from 'context/CreditRequestContext';
import { useCustody } from 'hooks/fetch/useCustody';
import { useCreditRequestStore } from 'pages/CreditRequest/store/useCreditRequestStore';
import { OptionData } from 'rc-select/lib/interface';
import { CustodyStoreUnitdata } from 'types/CreditRequest/CustodyStoreUnitData';
import { Converter } from 'utils/Converter';

interface Props {
  setCommodities: (commodities: any[]) => void;
}

export const CustodiaStorageUnitIdSelect = ({ setCommodities }: Props) => {
  const { t } = useTranslation();
  const { canEditCustodyFields, creditForm } = useCreditRequestContext();
  const { getStorages } = useCustody();

  const setIsBlocking = useCreditRequestStore(state => state.setIsBlocking);
  const custodyCityId = useCreditRequestStore(state => state.custodyCityId);
  const custodyStoreUnit = useCreditRequestStore(state => state.custodyStoreUnit);
  const custodyWarehouseAddressIsLegacy = useCreditRequestStore(state => state.custodyWarehouseAddressIsLegacy);

  const handleGetStorageOptions = async (search: string, page: number) => {
    const { data, last_page } = await getStorages({
      params: {
        fill: search,
        page,
        city_external_id: custodyCityId,
      },
    });
    return {
      data: data
        .map((storage: any) =>
          storage.units
            .filter((unit: any) => {
              const grainCommodities = unit.commodities.filter((commodities: any) => commodities.external_id === 2);
              return !!grainCommodities.length;
            })
            .map((unit: any) => ({
              ...unit,
              category: storage.category,
              address: storage.address,
              name: `${storage.name} - ${unit.name}`,
            })),
        )
        .flat()
        .map((unit: any) => Converter.selectOption(unit, 'id', 'id', 'name')),
      lastPage: last_page,
    };
  };

  return (
    <FormItem
      label={t('pages.credit-request.form.json_data_store_location')}
      name="custodia_storage_unit_id"
      rules={[{ required: !custodyWarehouseAddressIsLegacy }]}
      validateTrigger="onSubmit"
      hidden={custodyWarehouseAddressIsLegacy}
      permission="custodia.storages.index"
    >
      <InfiniteSelect
        breakOptions
        showSearch
        getOptions={handleGetStorageOptions}
        listHeight={250}
        disabled={!canEditCustodyFields || !custodyCityId}
        deps={[custodyCityId]}
        options={
          custodyStoreUnit
            ? [
                {
                  ...Converter.selectOption<CustodyStoreUnitdata>(
                    {
                      ...custodyStoreUnit,
                      name: `${custodyStoreUnit.storage.name} - ${custodyStoreUnit.name}`,
                      storage: undefined,
                    },
                    'id',
                    'id',
                    'name',
                  ),
                } as OptionData,
              ]
            : []
        }
        onChange={(value, option) => {
          if (value && !Array.isArray(option)) {
            setCommodities(option.data.commodities.filter((commodity: any) => commodity.external_id === 2));
            creditForm.setFieldsValue({
              custodia_storage_category: option.data.category.name,
              'json_data.warehouse_street': option.data.address,
              custodia_storage_commodity_division_id: undefined,
              custodia_approval_status: '',
            });
            setIsBlocking(true);
          }
        }}
      />
    </FormItem>
  );
};

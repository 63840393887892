export const columns = [
  {
    title: 'pages.serasacounterparty.historic.table.date',
    dataIndex: 'updated_at',
    key: 'updated_at',
  },
  {
    title: 'pages.serasacounterparty.historic.table.username',
    dataIndex: 'username',
    key: 'username',
  },
  {
    title: 'pages.serasacounterparty.historic.table.description',
    dataIndex: 'message',
    key: 'message',
  },
];

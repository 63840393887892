import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Col, Form, InputNumber } from 'antd';

import { Button, FormItem, Input, Row, Spin } from '../../../../components';
import { SFormButtons, SFormContainer } from '../../../../styles';
import { Formatter } from '../../../../utils/Formatter';
import { FormProps } from './types';

export const FormEdit = ({ isFetching, dataForm, setVisibleForm, setDataForm, handleSave }: FormProps) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  useEffect(() => {
    form.setFieldsValue({ ...dataForm });
  }, [dataForm]);

  const handleSubmit = () => {
    const data = form.getFieldsValue();
    handleSave(data, setVisibleForm, setDataForm);
  };

  return (
    <Spin spinning={isFetching}>
      <SFormContainer>
        <h2>{t('pages.serasa.parameterization.title-edit')}</h2>
        <Form form={form} onSubmitCapture={handleSubmit}>
          <FormItem name="id" hidden>
            <Input />
          </FormItem>

          <Row gutter={[26, 26]}>
            <Col xs={24} sm={24} md={24} lg={9} xl={9} xxl={9}>
              <FormItem label={t('pages.serasa.parameterization.table.description')} name="description">
                <Input disabled />
              </FormItem>
            </Col>
            <Col xs={24} sm={24} md={24} lg={9} xl={9} xxl={9}>
              <FormItem label={t('pages.serasa.parameterization.table.name')} name="name">
                <Input disabled />
              </FormItem>
            </Col>
            <Col xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}>
              <FormItem
                label={t('pages.serasa.parameterization.table.value')}
                name="value"
                rules={[{ required: true }]}
              >
                {dataForm.name === 'restriction_value' ? (
                  <InputNumber<string>
                    formatter={(value: string | number) => Formatter.money(value)}
                    parser={(value: string) => Formatter.moneyParser(value)}
                    maxLength={20}
                  />
                ) : (
                  <Input min={0} maxLength={12} />
                )}
              </FormItem>
            </Col>
          </Row>
          <SFormButtons>
            <Button status="secondary" htmlType="reset" onClick={() => setVisibleForm(false)}>
              {t('pages.serasa.parameterization.buttons.cancel')}
            </Button>

            <Button status="primary" htmlType="submit">
              {t('pages.serasa.parameterization.buttons.edit')}
            </Button>
          </SFormButtons>
        </Form>
      </SFormContainer>
    </Spin>
  );
};

export default {
  status: [
    {
      title: 'pages.admin.team-credit-status.table.headers.title',
      dataIndex: 'title',
      key: 'title',
      sorter: false,
      filtered: false,
    },
    {
      title: 'pages.admin.team-credit-status.table.headers.name',
      dataIndex: 'name',
      key: 'name',
      sorter: false,
      filtered: false,
    },
    {
      title: 'pages.admin.team-credit-status.table.headers.slug',
      dataIndex: 'slug',
      key: 'slug',
      sorter: true,
      filtered: false,
    },
    {
      title: 'pages.admin.team-credit-status.table.headers.order',
      dataIndex: 'order',
      key: 'order',
      sorter: true,
      filtered: false,
    },
    {
      title: 'pages.admin.team-credit-status.table.headers.action',
      key: 'action',
      dataIndex: 'action',
      align: 'center',
      width: '150px',
      fixed: 'right',
    },
  ],
};

/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useContext } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';

import { Table as AntTable, Empty } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { isMoment } from 'moment';

import { DTOContractTypeField } from '../../../../../types/Contract/DTOContractTypeField';
import { IconWithTooltip } from '../../../../../components';
import {
  CONTRACT_TYPE_FIELD_TYPE_DATE,
  CONTRACT_TYPE_FIELD_TYPE_DATETIME,
  CONTRACT_TYPE_FIELD_TYPE_TIME,
} from '../../../../../configs/constants';
import { DraftModelContext } from '../../../context/DraftModelContext';
import { Container } from './styles';

export const Table = ({ field, disabled }: { field: DTOContractTypeField; disabled: boolean }) => {
  const { t } = useTranslation();
  const { tablesState, handleRemoveRegisterFromTable, isIssued, setTablesState } = useContext(DraftModelContext);

  const DroppableBody = ({ className, ...props }: any) => {
    return (
      <Droppable droppableId={field.name} type="table">
        {provided => (
          <>
            <tbody ref={provided.innerRef} {...props} {...provided.droppableProps} className={`${className}`} />
            {provided.placeholder}
          </>
        )}
      </Droppable>
    );
  };

  const DraggableTableRow = ({ index, ...props }: any) => {
    if (!tablesState[field.name]?.registers?.length) {
      return (
        <tr className="ant-table-placeholder row-item" {...props}>
          <td className="ant-table-cell" colSpan={100}>
            <div className="ant-empty ant-empty-normal">
              <Empty />
            </div>
          </td>
        </tr>
      );
    }

    return (
      <Draggable
        key={props['data-row-key']}
        draggableId={props['data-row-key'].toString()}
        index={index}
        isDragDisabled={disabled}
      >
        {(provided, snapshot) => {
          return (
            <tr
              ref={provided.innerRef}
              {...props}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              className={`row-item ${snapshot.isDragging ? 'row-dragging' : ''}`}
            />
          );
        }}
      </Draggable>
    );
  };

  return (
    <Container>
      <DragDropContext
        onDragEnd={result => {
          // reorder the table items
          const { source, destination } = result;
          if (!destination) return;
          const { registers } = tablesState[field.name];
          const [removed] = registers.splice(source.index, 1);
          registers.splice(destination.index, 0, removed);

          // update the order of the items
          registers.forEach((register: any, index: number) => {
            register.order = index;
          });

          // update the table state
          setTablesState({
            ...tablesState,
            [field.name]: {
              ...tablesState[field.name],
              registers,
            },
          });
        }}
      >
        <AntTable
          key="id"
          rowKey="order"
          components={{
            body: {
              wrapper: (val: any) => {
                return DroppableBody(val);
              },
              row: (val: any) => {
                const itemIndex = tablesState[field.name]?.registers?.findIndex(
                  (register: any) => register.order === val['data-row-key'],
                );

                return DraggableTableRow({
                  ...val,
                  index: itemIndex ?? 0,
                });
              },
            },
          }}
          dataSource={[...(tablesState[field.name]?.registers ?? [])].sort((a: any, b: any) => a.order - b.order)}
          pagination={false}
          columns={field.columns.map(column => {
            let customColumn: ColumnType<any> = {};
            customColumn = {
              key: column.name,
              title: column.display,
              // width: !column.type_id ? 30 : 'auto',
              render: (record: any) => {
                const value = record[column.name]?.value;
                if (isMoment(value) && column.type_id === CONTRACT_TYPE_FIELD_TYPE_DATE)
                  return value.format('DD/MM/YYYY');
                if (isMoment(value) && column.type_id === CONTRACT_TYPE_FIELD_TYPE_DATETIME)
                  return value.format('DD/MM/YYYY HH:MM:SS');
                if (isMoment(value) && column.type_id === CONTRACT_TYPE_FIELD_TYPE_TIME)
                  return value.format('HH:MM:SS');
                if (!column.type_id)
                  return (
                    <span>
                      {!isIssued && (
                        <IconWithTooltip
                          title={t('pages.edocuments.emission.draftEmission.remove')}
                          action="destroy"
                          onClick={() => handleRemoveRegisterFromTable(field.name, record.id)}
                        />
                      )}
                    </span>
                  );
                return value;
              },
            };

            return customColumn;
          })}
        />
      </DragDropContext>
    </Container>
  );
};

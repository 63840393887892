import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Spin, Form as AntForm, FormInstance, Row, Col } from 'antd';
import { Button, FormItem, Input } from 'components';
import { ErpSelect } from 'components/ErpSelect';
import { ModalitySelect } from 'components/ModalitySelect';
import { OperationSelect } from 'components/OperationSelect';
import { SubdivisionSelect } from 'components/SubdivisionSelect';
import { SubsidiarySelect } from 'components/SubsidiarySelect';
import { TeamStatusSelect } from 'components/TeamStatusSelect';
import { useIntegrationRules } from 'hooks/fetch/useIntegrationRules';
import { useModalMessage } from 'hooks/helpers/useModalMessage';
import { usePage } from 'hooks/page';

import { IntegrationRuleData } from '../../../../@types/data/IntegrationRuleData';
import type DTOErrorReponse from '../../../../@types/dtos/DTOErrorReponse';
import { SFormButtons, SFormContainer } from './styles';

interface Props {
  isVisible: boolean;
  handleHideForm: () => void;
  refetchTable: () => void;
  form: FormInstance;
  ruleToEdit: IntegrationRuleData;
}

export const Form = ({ isVisible, handleHideForm, refetchTable, form, ruleToEdit }: Props) => {
  const { t } = useTranslation();
  const { alertStatus } = usePage();
  const { handleSuccess } = useModalMessage();
  const { storeRule, isStoring, isUpdating, updateRule } = useIntegrationRules();

  const [isFormLocked, setIsFormLocked] = React.useState(false);

  const storeOrUpdate = () => {
    setIsFormLocked(true);
    form
      .validateFields()
      .then(() => {
        const formValues = form.getFieldsValue();
        const requestMethod = ruleToEdit ? updateRule(form.getFieldValue('id'), formValues) : storeRule(formValues);
        requestMethod
          .then(response => {
            if (response) {
              handleSuccess(
                ruleToEdit ? t('pages.register.priceDay.edit.success') : t('pages.register.priceDay.register.success'),
              );
              refetchTable();
              handleHideForm();
              setIsFormLocked(false);
              form.resetFields();
            }
          })
          .catch((err: DTOErrorReponse) => {
            alertStatus(err, 'error');
            setIsFormLocked(false);
          });
      })
      .catch((err: DTOErrorReponse) => {
        alertStatus(err, 'error');
        setIsFormLocked(false);
      });
    setIsFormLocked(false);
  };

  useEffect(() => {
    if (ruleToEdit) {
      form.setFieldsValue({
        ...ruleToEdit,
      });
    }
  }, [ruleToEdit?.id]);

  return (
    <SFormContainer visible={isVisible}>
      <Spin spinning={isStoring || isUpdating}>
        <h2>{t('pages.integration.rules.title')}</h2>
        <AntForm form={form}>
          <FormItem name="id" style={{ display: 'none' }}>
            <Input />
          </FormItem>
          <FormItem
            name="operation_id"
            label={t('pages.integration.rules.form.operation_id')}
            rules={[{ required: true }]}
          >
            <OperationSelect allowClear={false} />
          </FormItem>
          <Row>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <FormItem
                name="sub_division_id"
                label={t('pages.integration.rules.form.subdivision_id')}
                rules={[{ required: true }]}
              >
                <SubdivisionSelect allowClear={false} />
              </FormItem>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <FormItem name="subsidiary_id" label={t('pages.integration.rules.form.subsidiary_id')}>
                <SubsidiarySelect />
              </FormItem>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <FormItem name="modality_id" label={t('pages.integration.rules.form.modality_id')}>
                <ModalitySelect allowClear />
              </FormItem>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <FormItem name="erp" label={t('pages.integration.rules.form.erp')} rules={[{ required: true }]}>
                <ErpSelect />
              </FormItem>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <FormItem
                name="team_status_id"
                label={t('pages.integration.rules.form.team_status_id')}
                rules={[{ required: true }]}
              >
                <TeamStatusSelect />
              </FormItem>
            </Col>
          </Row>
          <SFormButtons>
            <Button
              status="secondary"
              htmlType="reset"
              onClick={handleHideForm}
              disabled={isFormLocked || isStoring || isUpdating}
            >
              {t('pages.register.harvest.buttonCancel')}
            </Button>
            {ruleToEdit && (
              <Button
                status="primary"
                htmlType="submit"
                onClick={storeOrUpdate}
                loading={isStoring || isUpdating}
                disabled={isFormLocked || isStoring || isUpdating}
              >
                {t('pages.register.harvest.buttonEdit')}
              </Button>
            )}
            {!ruleToEdit && (
              <Button
                status="primary"
                htmlType="submit"
                onClick={storeOrUpdate}
                loading={isStoring || isUpdating}
                disabled={isFormLocked || isStoring || isUpdating}
              >
                {t('pages.register.harvest.buttonRegister')}
              </Button>
            )}
          </SFormButtons>
        </AntForm>
      </Spin>
    </SFormContainer>
  );
};

import React, { ReactNode } from 'react';

import { cva, VariantProps } from 'class-variance-authority';
import { cn } from 'lib/utils';

const wrapperVariants = cva(
  'flex place-items-center group-disabled/button:pointer-events-none p-2 rounded-lg z-10 group-disabled/button:bg-gray-400 group-disabled/button:text-gray-200 group-disabled/button:cursor-not-allowed',
  {
    variants: {
      variant: {
        default: 'text-light-primary bg-light-primary-foreground',
        close: 'bg-red-200 text-red-600',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
);

type Props = {
  children: ReactNode;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent> | React.KeyboardEvent<HTMLDivElement>) => void;
  disabled?: boolean;
} & VariantProps<typeof wrapperVariants>;

export const IconWrapper = ({ children, onClick, variant, disabled }: Props) => {
  return (
    <div
      onClick={onClick}
      className={cn(
        wrapperVariants({ variant }),
        disabled && 'pointer-events-none bg-gray-400 text-gray-200 cursor-not-allowed',
      )}
    >
      {children}
    </div>
  );
};

import { useMutation } from '@tanstack/react-query';
import { useAlertDialog } from 'components/ui/alert-dialog';
import api from 'services/api/api';

export const useUpdateDraftMutation = () => {
  const { alert } = useAlertDialog();

  return useMutation({
    mutationKey: ['updateDraft'],
    mutationFn: async (draftData: FormData) => {
      return api.post(`envelope/update/${draftData.get('id')}`, draftData, {
        params: { _method: 'PUT' },
      });
    },
    onError: err => {
      alert({ title: err.message, type: 'error' });
    },
  });
};

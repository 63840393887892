import React from 'react';

import { Col } from 'antd';
import { OPERATION_PREFIN_ID, OPERATION_PREFIN_SUGAR_MILLS_ID, OPERATION_PREPAY_ID } from 'configs/constants';
import { useSapRules } from 'pages/CreditRequest/rules/sap/useSapRules';
import { useCreditRequestStore } from 'pages/CreditRequest/store/useCreditRequestStore';

import { JsonDataRequesterPersonNameInput } from '../../atoms/JsonDataRequesterPersonNameInput';
import { RequesterPersonDocumentInput } from '../../atoms/RequesterPersonDocumentInput';
import { RequesterPersonJsonDataCodeErpInput } from '../../atoms/RequesterPersonJsonDataCodeErpInput';
import { RequesterPersonSapCodeInput } from '../../atoms/RequesterPersonSapCodeInput';

export const PersonFields = () => {
  const { shouldShowRequesterPersonSapCode, shouldRequireRequesterPersonSapCode } = useSapRules();

  const selectedOperationId = useCreditRequestStore(state => state.selectedOperationId);
  const shouldShowSap = useCreditRequestStore(state => state.shouldShowSap);
  const shouldShowDatasul = useCreditRequestStore(state => state.shouldShowDatasul);

  return (
    <>
      {/* <Col xs={24} sm={18} md={12} lg={8} xl={6} xxl={6}>
        <PersonTypeSelectInput />
      </Col> */}

      <Col xs={24} sm={18} md={12} lg={8} xl={6} xxl={6}>
        <RequesterPersonDocumentInput />
      </Col>
      <Col xs={24} sm={18} md={12} lg={8} xl={12} xxl={12}>
        <JsonDataRequesterPersonNameInput />
        {/* <RequesterPersonIdSelect></RequesterPersonIdSelect> */}
      </Col>
      <Col xs={24} sm={18} md={12} lg={8} xl={6} xxl={6} style={{ display: shouldShowDatasul ? '' : 'none' }}>
        <RequesterPersonJsonDataCodeErpInput
          isVisible={shouldShowDatasul}
          isRequired={selectedOperationId === OPERATION_PREPAY_ID && shouldShowDatasul}
        />
      </Col>
      <Col
        xs={24}
        sm={18}
        md={12}
        lg={8}
        xl={6}
        xxl={6}
        style={{
          display:
            shouldShowSap ||
            selectedOperationId === OPERATION_PREFIN_ID ||
            selectedOperationId === OPERATION_PREFIN_SUGAR_MILLS_ID
              ? ''
              : 'none',
        }}
      >
        <RequesterPersonSapCodeInput
          isVisible={shouldShowRequesterPersonSapCode}
          isRequired={shouldRequireRequesterPersonSapCode}
        />
      </Col>
    </>
  );
};

import styled from 'styled-components';

export const Container = styled.div`
  padding: 60px 50px 0px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  .ant-spin-nested-loading {
    height: 100%;

    .ant-spin-container {
      height: 100%;
      display: flex;
      flex-direction: column;
      gap: 40px;

      @media (max-width: 1500px) {
        margin-bottom: 0px;
      }

      @media (max-width: 1100px) {
        margin-bottom: 100px;
      }
    }
  }
`;

export const NewDraftText = styled.p`
  color: ${({ theme: { colors } }) => colors.greys.grey07};
  font-size: 14px;
  cursor: pointer;
`;

import React from 'react';
import { useTranslation } from 'react-i18next';

import { GroupForm } from 'components/ui/group-form';

import { Add } from '../../add';
import { DocumentsToCollectCommentsModal } from './documents-collect-comments.modal';
import { DocumentsToCollectCommentsTable } from './documents-collect-comments.table';

export const DocumentsToCollectComments = () => {
  const { t } = useTranslation();

  return (
    <GroupForm title={t('pages.credit-request.formalization-guarantees.pre-registration-docs.modalDocument.comments')}>
      <DocumentsToCollectCommentsTable />

      <DocumentsToCollectCommentsModal>
        <Add
          text={t('pages.credit-request.formalization-guarantees.pre-registration-docs.modalDocument.buttonAddComment')}
          className="h-auto min-h-0 py-10"
        />
      </DocumentsToCollectCommentsModal>
    </GroupForm>
  );
};

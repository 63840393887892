import styled, { css } from 'styled-components';

export const Container = styled.div<{ isClickable: boolean }>`
  display: flex;
  justify-content: space-between;
  overflow-x: hidden;
  gap: 10px;
  padding: 10px 0;
  cursor: ${({ isClickable }) => isClickable && 'pointer'};
`;

export const Name = styled.div<{ isSelected: boolean }>`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${({ isSelected, theme }) =>
    isSelected &&
    css`
      color: ${theme.colors.primary};
    `}
`;

export const Buttons = styled.div`
  display: flex;
  gap: 5px;
`;

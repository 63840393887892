import React, { useState } from 'react';

import { Tree } from 'antd';

import { NotificationController } from '../../notificationController';

interface Props {
  controller: NotificationController;
  treeData: any[];
}

export const NotificationTree = ({ controller, treeData }: Props) => {
  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [autoExpandParent, setAutoExpandParent] = useState(true);

  const onExpand = (expandedKeysValue: React.Key[]) => {
    setExpandedKeys(expandedKeysValue);
    setAutoExpandParent(false);
  };

  const onCheck = (checkedKeysValue: React.Key[] | { checked: React.Key[]; halfChecked: React.Key[] }) => {
    controller.updateKeys(checkedKeysValue);
  };

  const onSelect = (selectedKeysValue: React.Key[]) => setSelectedKeys(selectedKeysValue);

  return (
    <div style={{ minHeight: '150px' }}>
      <Tree
        checkable
        onExpand={onExpand}
        expandedKeys={expandedKeys}
        autoExpandParent={autoExpandParent}
        onCheck={onCheck}
        checkedKeys={controller.checkedKeys}
        onSelect={onSelect}
        selectedKeys={selectedKeys}
        treeData={treeData}
      />
    </div>
  );
};

import styled from 'styled-components';

export const Button = styled.div`
  background: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
  padding: 4px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
`;

import React from 'react';

import { ContractProvider } from '../context/ContractContext';
import { DraftModelProvider } from '../context/DraftModelContext';
import { Body } from './Body';

export const Emission = () => {
  return (
    <ContractProvider>
      <DraftModelProvider>
        <Body />
      </DraftModelProvider>
    </ContractProvider>
  );
};

import { useTranslation } from 'react-i18next';

export const usePasswordValidator = () => {
  const { t } = useTranslation(['admin']);

  return [
    () => ({
      validator(_: any, value: string) {
        return value !== null && value !== undefined && !value.match(/(?=.*[a-z])/)
          ? Promise.reject(t('user.form.password.validator.lower'))
          : Promise.resolve();
      },
    }),
    () => ({
      validator(_: any, value: string) {
        return value !== null && value !== undefined && !value.match(/(?=.*[A-Z])/)
          ? Promise.reject(t('user.form.password.validator.upper'))
          : Promise.resolve();
      },
    }),
    () => ({
      validator(_: any, value: string) {
        return value !== null && value !== undefined && !value.match(/(?=.*[0-9])/)
          ? Promise.reject(t('user.form.password.validator.numeric'))
          : Promise.resolve();
      },
    }),
    () => ({
      validator(_: any, value: string) {
        return value !== null && value !== undefined && !value.match(/(?=.*[-+_!@#$%^&*., ?^()=§{}[\]ªº¨¢'"~^|<>:;`´])/)
          ? Promise.reject(t('user.form.password.validator.especial_char'))
          : Promise.resolve();
      },
    }),
    {
      min: 8,
      message: t('user.form.password.validator.min'),
    },
  ];
};

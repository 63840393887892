import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ShouldRender } from 'components/ShouldRender';
import { ComboBox } from 'components/ui/combo';
import { DatePicker } from 'components/ui/date-picker';
import { FormItem } from 'components/ui/form-item';
import { Input, InputWithMask } from 'components/ui/input';
import { differenceInYears, isAfter, sub } from 'date-fns';
import { useCache } from 'hooks/cache';
import { Validate } from 'utils/Validate';

import { useVerifyIsMarried } from './hooks/use-verify-is-married';
import { useRegisteredCustomerStore } from './stores/use-registered-customer-store';

export const RegisteredCustomerPersonalData = () => {
  const { t } = useTranslation();
  const form = useFormContext();
  const cache = useCache();

  const { verifyIsMarried } = useVerifyIsMarried();

  const codDatasul = form.watch('cod_datasul');
  const codSap = form.watch('sap_bp_subsidiary_code');
  const birthStateId = form.watch('birth_state_id');
  const maritalStatusId = form.watch('marital_status_id');

  const birthCityOptions = useMemo(() => cache.getCityByStateOptions(birthStateId), [birthStateId]);

  const suitabilityOptions = cache.getPersonOptions('suitability');
  const stateOptions = cache.getCreditOptions('state');
  const maritalStatusOptions = cache.getPersonOptions('marital_status');
  const communionRegimeOptions = cache.getPersonOptions('communion_regime');

  const isViewing = useRegisteredCustomerStore(state => state.isViewing);
  const isModulePerson = useRegisteredCustomerStore(state => state.isModulePerson);
  const isFinalizing = useRegisteredCustomerStore(state => state.isFinalizing);

  const isMarried = useMemo(() => verifyIsMarried(maritalStatusId), [maritalStatusId]);

  const handleChangeMaritalStatus = (value: string) => {
    if (value) {
      if (!verifyIsMarried(value.toString())) {
        form.reset({
          ...form.getValues(),
          'spouse.0.name': undefined,
          'spouse.0.json_data.rg': undefined,
          'spouse.0.json_data.cpf': undefined,
          communion_regime_id: undefined,
        });
      }
    }
  };

  return (
    <>
      <FormItem name="name" label={t('full-name')} rules={{ required: true }}>
        <Input disabled={isViewing || !isModulePerson} />
      </FormItem>

      <FormItem label={t('pages.registered-customers.form.pf.rg')} name="rg" rules={{ required: isFinalizing }}>
        <Input maxLength={20} disabled={isViewing || !isModulePerson} />
      </FormItem>

      <FormItem
        label={t('pages.registered-customers.form.pf.nationality')}
        name="nationality"
        rules={{ required: isFinalizing }}
      >
        <Input maxLength={20} disabled={isViewing || !isModulePerson} />
      </FormItem>

      <FormItem label={t('pages.registered-customers.form.pf.email')} name="email" rules={{ required: true }}>
        <Input disabled={isViewing || !isModulePerson} />
      </FormItem>

      <ShouldRender condition={!!codDatasul}>
        <FormItem name="cod_datasul" label={t('pages.registered-customers.form.pf.cod_datasul')}>
          <Input disabled />
        </FormItem>
      </ShouldRender>

      <ShouldRender condition={!!codSap}>
        <FormItem name="sap_bp_subsidiary_code" label={t('pages.registered-customers.form.pf.cod_sap')}>
          <Input disabled />
        </FormItem>
      </ShouldRender>

      <FormItem
        label={t('pages.registered-customers.form.pf.json_data.phone_number')}
        name="json_data.phone_number"
        rules={{ required: isFinalizing }}
      >
        <InputWithMask
          name="json_data.phone_number"
          mask="(99) 99999-9999"
          placeholder="(__) _____-____"
          disabled={isViewing || !isModulePerson}
        />
      </FormItem>

      <FormItem
        label={t('pages.registered-customers.form.pf.json_data.cel_number')}
        name="json_data.cel_number"
        rules={{ required: true }}
      >
        <InputWithMask
          name="json_data.cel_number"
          mask="(99) 99999-9999"
          placeholder="(__) _____-____"
          disabled={isViewing || !isModulePerson}
        />
      </FormItem>

      <FormItem
        label={t('pages.registered-customers.form.pf.json_data.years_in_square')}
        name="json_data.years_in_square"
        rules={{ required: isFinalizing }}
      >
        <Input disabled={isViewing || !isModulePerson} />
      </FormItem>

      <FormItem
        label={t('pages.registered-customers.form.pf.json_data.rural_producer_time')}
        name="json_data.rural_producer_time"
        rules={{ required: isFinalizing }}
      >
        <Input disabled={isViewing || !isModulePerson} />
      </FormItem>

      <FormItem
        label={t('pages.registered-customers.form.pf.json_data.rural_registration')}
        name="json_data.rural_registration"
        rules={{ required: isFinalizing }}
      >
        <Input disabled={isViewing || !isModulePerson} />
      </FormItem>

      <FormItem
        label={t('pages.registered-customers.form.pf.json_data.suitability_id')}
        name="json_data.suitability_id"
        rules={{ required: isFinalizing }}
      >
        <ComboBox className="w-full" options={suitabilityOptions} disabled={isViewing || !isModulePerson} />
      </FormItem>

      <FormItem
        label={t('pages.registered-customers.form.pf.state_of_birth')}
        name="birth_state_id"
        rules={{ required: isFinalizing }}
      >
        <ComboBox
          className="w-full"
          options={stateOptions}
          onChange={() => form.setValue('city_id', undefined)}
          disabled={isViewing || !isModulePerson}
        />
      </FormItem>

      <FormItem
        label={t('pages.registered-customers.form.pf.city_of_birth')}
        name="city_id"
        rules={{ required: isFinalizing }}
      >
        <ComboBox
          className="w-full"
          options={birthCityOptions}
          disabled={!birthStateId || isViewing || !isModulePerson}
        />
      </FormItem>

      <FormItem
        label={t('pages.registered-customers.form.pf.date_of_birth')}
        name="birth_date"
        rules={{
          required: isFinalizing,
          validate: {
            isNotUnderAge: value => {
              if (value) {
                const eighteenYearsAgo = sub(new Date(), { years: 18 });
                const isUnderAge = isAfter(new Date(value), eighteenYearsAgo);
                if (isUnderAge) return t('pages.registered-customers.form.pf.date_of_birth_error');
              }
              return true;
            },
          },
        }}
        description={t('pages.registered-customers.form.pf.date_of_birth_help')}
      >
        <DatePicker
          className="w-full"
          disabledDates={(day: Date) => {
            const currentDate = new Date();
            const eighteenYearsAgo = sub(currentDate, { years: 18 });
            return isAfter(day, eighteenYearsAgo);
          }}
          defaultMonth={sub(new Date(), { years: 18 })}
          onDayClick={(day: Date) => {
            const age = differenceInYears(new Date(), day);
            form.setValue('age', age);
          }}
          disabled={isViewing || !isModulePerson}
        />
      </FormItem>

      <FormItem label={t('pages.registered-customers.form.pf.age')} name="age">
        <Input disabled />
      </FormItem>

      <FormItem
        label={t('pages.registered-customers.form.pf.marital_status_id')}
        name="marital_status_id"
        rules={{ required: isFinalizing }}
      >
        <ComboBox
          options={maritalStatusOptions}
          onValueChange={handleChangeMaritalStatus}
          disabled={isViewing || !isModulePerson}
        />
      </FormItem>

      <ShouldRender condition={isMarried}>
        <FormItem
          label={t('pages.registered-customers.form.pf.communion_regime')}
          name="communion_regime_id"
          rules={{
            required: isFinalizing && isMarried,
          }}
        >
          <ComboBox className="w-full" options={communionRegimeOptions} disabled={isViewing || !isModulePerson} />
        </FormItem>
        <FormItem
          label={t('pages.registered-customers.form.pf.spouse.0.name')}
          name="spouse.0.name"
          rules={{
            required: isFinalizing && isMarried,
          }}
        >
          <Input disabled={isViewing || !isModulePerson} />
        </FormItem>
        <FormItem
          label={t('pages.registered-customers.form.pf.spouse.0.json_data.rg')}
          name="spouse.0.json_data.rg"
          rules={{
            required: isFinalizing && isMarried,
          }}
        >
          <Input maxLength={20} disabled={isViewing || !isModulePerson} />
        </FormItem>
        <FormItem
          label={t('pages.registered-customers.form.pf.spouse.0.json_data.cpf')}
          name="spouse.0.json_data.cpf"
          rules={{
            required: isFinalizing && isMarried,
            validate: {
              isDocumentInvalid: value => (value ? (!Validate.cpf(value) ? t('invalid-document') : true) : true),
            },
          }}
        >
          <InputWithMask
            name="spouse.0.json_data.cpf"
            mask="999.999.999-99"
            placeholder="___.___.___-__"
            disabled={isViewing || !isModulePerson}
          />
        </FormItem>
      </ShouldRender>

      <FormItem
        label={t('pages.registered-customers.form.pf.number_of_dependents')}
        name="number_of_dependents"
        rules={{ required: isFinalizing }}
      >
        <Input disabled={isViewing || !isModulePerson} />
      </FormItem>

      <FormItem
        label={t('pages.registered-customers.form.pf.father.0.name')}
        name="father.0.name"
        rules={{ required: isFinalizing }}
      >
        <Input disabled={isViewing || !isModulePerson} />
      </FormItem>
      <FormItem
        label={t('pages.registered-customers.form.pf.mother.0.name')}
        name="mother.0.name"
        rules={{ required: isFinalizing }}
      >
        <Input disabled={isViewing || !isModulePerson} />
      </FormItem>
    </>
  );
};

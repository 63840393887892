import React, { useState } from 'react';
import { AiFillFileText } from 'react-icons/ai';
import { FaFileUpload } from 'react-icons/fa';

import { UploadChangeParam } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';

import { CustomButton } from '../../pages/Contracts/components/CustomButton';
import { IconWrapper } from '../../pages/Contracts/components/IconWrapper';
import theme from '../../styles/theme';
import { SDragger, Container } from './styles';

interface Props {
  onChange?: (file: any) => void;
  maxFiles?: number;
}

export const UploadAttachment = ({ onChange, maxFiles = null }: Props) => {
  const [shouldDisable, setShouldDisable] = useState(false);

  const isSingleFile = maxFiles && maxFiles === 1;

  const handleFileChange = (fileInput: UploadChangeParam<UploadFile<any>>) => {
    const fileList = fileInput.fileList.length ? fileInput.fileList : null;
    if (maxFiles) setShouldDisable(fileList?.length >= maxFiles);
    const files = isSingleFile && fileList ? fileList[0] : fileList;
    if (onChange) onChange(files);
  };

  return (
    <Container>
      <SDragger
        name="file"
        onChange={handleFileChange}
        maxCount={maxFiles}
        multiple={!isSingleFile}
        beforeUpload={() => false}
      >
        <p className="ant-upload-drag-icon">
          <AiFillFileText size={58} color={theme.colors.primary} />
        </p>

        {!shouldDisable ? (
          <CustomButton disabled={shouldDisable}>
            <IconWrapper>
              <FaFileUpload />
            </IconWrapper>
            Selecione o arquivo
          </CustomButton>
        ) : (
          `Arquivo já selecionado! Você pode selecionar apenas ${maxFiles} arquivo por vez`
        )}

        <p style={{ color: theme.colors.primary, fontWeight: 'bold', marginTop: '20px' }}>
          Ou arraste o arquivo para esta area
        </p>
        <p className="ant-upload-hint">No máximo 20mb, no formato .doc, ou .docx</p>
      </SDragger>
    </Container>
  );
};

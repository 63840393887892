import styled from 'styled-components';

import { Container as MagContainer } from './MagnifingButtons/styles';

export const Container = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: flex-end;

  &:hover {
    ${MagContainer} {
      opacity: 1;
    }
  }
`;

export const Image = styled.img<{ scale: number }>`
  width: 100%;
  height: min-content;
  transform: scale(${props => props.scale});
  image-rendering: high-quality;
`;

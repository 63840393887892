import React from 'react';

import CustomersKanban from './CustomersKanban';
import { Container } from './styles';
import { KanbanProps } from './types';

const KanbanView: React.FC<KanbanProps> = ({ filterData, person }) => {
  return (
    <Container>
      <CustomersKanban filterValues={filterData} person={person} />
    </Container>
  );
};

export default KanbanView;

import React, { useContext } from 'react';

import { DraftModelContext } from '../../../../../context/DraftModelContext';
import { DraftModelFields } from './DraftModelFields';
import { DraftModelList } from './DraftModelList';
import { Container } from './styles';

export const DraftModelEmission = () => {
  const { isModelConfirmed } = useContext(DraftModelContext);

  return <Container>{isModelConfirmed ? <DraftModelFields /> : <DraftModelList />}</Container>;
};

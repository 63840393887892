import React from 'react';

import { FormItem, Input } from 'components';

export const UserIdInput = () => {
  return (
    <FormItem name="user_id" hidden>
      <Input />
    </FormItem>
  );
};

import React from 'react';
import { useTranslation } from 'react-i18next';

import { InfiniteSelectAsync } from '@agrodatabr/agrodataui';
import { Col } from 'antd';
import { FormItem, IconWithTooltip } from 'components';

import CreditRequestData from '../../../types/CreditRequest/CreditRequestData';
import { useFormController } from '../controllers/useFormController';
import { RenderFormField } from '../RenderFormField';
import { LabelWithIcon } from './styles';

interface Props {
  statusReadOnly: boolean;
  creditIdEdit: string;
  form: any;
  isFormVisible: boolean;
  sustainabilityId: any;
  creditRequest: CreditRequestData | undefined;
}

export const PrefinForm = ({
  statusReadOnly,
  creditIdEdit,
  form,
  isFormVisible,
  sustainabilityId,
  creditRequest,
}: Props) => {
  const { t } = useTranslation();
  const { loadCreditRequestsByPrefin } = useFormController({ form });

  return isFormVisible ? (
    <>
      <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
        <FormItem
          rules={[{ required: true }]}
          label={
            creditIdEdit ? (
              <LabelWithIcon>
                {t('pages.sustainability.form.credit_request')}
                <IconWithTooltip
                  action="view"
                  title={t('pages.sustainability.form.view_credit_request')}
                  onClick={() => {
                    window.open(`/credit-request/view/${creditIdEdit}`, '_blank');
                  }}
                />
              </LabelWithIcon>
            ) : (
              t('pages.sustainability.form.credit_request')
            )
          }
          name="credit_request_id"
        >
          <InfiniteSelectAsync
            searchRequest={loadCreditRequestsByPrefin}
            initialRequest
            disabled
            options={
              creditRequest
                ? [
                    {
                      key: creditRequest.id ?? '',
                      value: creditRequest.id ?? '',
                      label: `${creditRequest.number} - ${
                        creditRequest?.requester_person?.name
                          ? creditRequest?.requester_person?.name
                          : creditRequest?.requester_person?.company_name
                      }`,
                    },
                  ]
                : []
            }
          />
        </FormItem>
      </Col>
      <RenderFormField fieldName="harvest" isDisabled={statusReadOnly || sustainabilityId || creditIdEdit} />
      <RenderFormField fieldName="commodity" isDisabled={statusReadOnly || sustainabilityId} />
      <RenderFormField fieldName="volume" isDisabled={statusReadOnly} />
    </>
  ) : null;
};

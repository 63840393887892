import styled from 'styled-components';

export const Wrapper = styled.div`
  .ant-spin-nested-loading {
    height: min-content;
  }
`;

export const Container = styled.div`
  display: flex;
  gap: 10px;
  padding-bottom: 20px;
  max-width: 100%;
  height: 140px;
  position: relative;
  overflow-y: auto;
`;

import React, { useEffect, useState, useMemo } from 'react';

import { Spin } from 'antd';
import { useExposurePrepay } from 'hooks/fetch/useExposurePrepay';
import moment from 'moment';
import { useGetProviderRequestsQuery } from 'pages/Dashboard/api/use-get-provider-requests-query';
import {
  ComposedChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Line,
  Bar,
  Area,
} from 'recharts';
import { Formatter } from 'utils/Formatter';

import { DrillUp } from './DrillUp';
import { Container, CustomTooltip } from './styles';

interface Props {
  data: any[];
  drilldownConfig: DrilldownConfig[];
  yAxisLabel: string;
  filter?: {
    start: string;
    end: string;
    search: string;
    providers: any[];
    numbers: any[];
    payment_date: any;
  };
  setSearchFiltersTable: React.Dispatch<
    React.SetStateAction<{
      start: string;
      end: string;
      search: string;
      providers: any[];
      numbers: any[];
      payment_date: any;
    }>
  >;
}

interface DrilldownValue {
  dataKey: string;
  strokeColor: string;
  formatValue?: (value: number | string) => number | string;
  name: string;
  type?: 'line' | 'bar' | 'area' | 'area-stacked';
}

export interface DrilldownConfig {
  label: string;
  labelFormatter?: (label: string) => string;
  type?: 'line' | 'bar' | 'area';
  values: DrilldownValue[];
  drilldownKey?: string;
  isValue?: boolean;
}

export const Chart = ({ data, drilldownConfig, yAxisLabel, setSearchFiltersTable, filter }: Props) => {
  const { getProviders } = useExposurePrepay();

  const [chartData, setChartData] = useState([]);
  const [drilldownHistory, setDrilldownHistory] = useState<string[]>([]);
  const [isGettingDrilldownData, setIsGettingDrilldownData] = useState(false);
  const [selectedDayId, setSelectedDayId] = useState<string | undefined>(undefined);
  const [selectedProvider, setSelectedProvider] = useState<{ name: string; id: string } | undefined>(undefined);

  const { isLoading: _ } = useGetProviderRequestsQuery({
    date: selectedDayId,
    providerId: selectedProvider?.id,
    filter,
    onSuccess: data => {
      setChartData(
        chartData.map(day =>
          Formatter.apiDate(day.date) === Formatter.apiDate(selectedDayId)
            ? {
                ...day,
                providers:
                  day.providers?.map((provider: any) => ({
                    ...provider,
                    requests: provider.provider_id === selectedProvider.id ? data || [] : provider.requests || [],
                  })) || [],
              }
            : day,
        ),
      );
      if (selectedProvider) setDrilldownHistory(old => [...old, selectedProvider.name]);
    },
  });

  const currentConfig = useMemo(() => {
    if (!drilldownConfig) return undefined;
    const drilldownHistoryLength = drilldownHistory.length;
    if (drilldownConfig.length <= drilldownHistory.length) return drilldownConfig[drilldownConfig.length - 1];
    return drilldownConfig[drilldownHistoryLength];
  }, [drilldownConfig, drilldownHistory]);

  const doDrilldown = async (id: string, value: any) => {
    if (drilldownHistory.length === 0) {
      setSelectedDayId(id);
      setIsGettingDrilldownData(true);
      setSearchFiltersTable(old => ({ ...old, payment_date: id }));
      const response = await getProviders({
        params: { date: id, is_chart: 1, ...filter },
      });
      const newChartData = chartData.map(day =>
        Formatter.apiDate(day.date) === Formatter.apiDate(id)
          ? {
              ...day,
              providers: response.map((provider: any) => ({
                ...provider,
                requests:
                  provider.requests?.map((request: any) => ({
                    ...request,
                    ...request.credit_request,
                  })) || [],
              })),
            }
          : day,
      );
      setIsGettingDrilldownData(false);
      setChartData(newChartData);
      setDrilldownHistory(old => [...old, id]);
    } else if (drilldownHistory.length === 1) {
      setSelectedProvider({
        id: value.activePayload[0].payload.provider_id,
        name: value.activePayload[0].payload.provider_name,
      });
    } else {
      if (drilldownConfig.length > drilldownHistory.length + 1) setDrilldownHistory(old => [...old, id]);
    }
  };

  const doDrillUp = async (id: string) => {
    const drilldownIndex = drilldownHistory.findIndex(drilldownId => drilldownId === id);
    if (drilldownIndex === 0) {
      setSearchFiltersTable(old => ({ ...old, payment_date: undefined }));
      setSelectedDayId(undefined);
      setSelectedProvider(undefined);
      return setDrilldownHistory([]);
    }
    if (drilldownIndex <= 1) {
      setSelectedProvider(undefined);
    }
    const newDrilldownHistory = drilldownHistory.slice(0, drilldownIndex);
    setDrilldownHistory(newDrilldownHistory);
  };

  const findCurrentDrilldown = () => {
    let drilldownData = chartData;

    // const currentId = drilldownHistory[drilldownHistory.length - 1];
    const previousIds = drilldownHistory.slice(0, drilldownHistory.length);
    previousIds.forEach((id, index) => {
      const previousConfig = drilldownConfig[index];
      drilldownData =
        drilldownData.find((entry: any) => entry[previousConfig.label] === id)?.[previousConfig.drilldownKey] || [];
    });

    currentConfig.values.forEach(configValue => {
      drilldownData = drilldownData.map((drilldownEntry: any) => ({
        ...drilldownEntry,
        [configValue.dataKey]: configValue.formatValue
          ? configValue.formatValue(drilldownEntry[configValue.dataKey])
          : drilldownEntry[configValue.dataKey],
      }));
    });

    return drilldownData;
  };

  const customTooltipFormatter = (value: number, name: string) => {
    const formattedValue = Formatter.integer(value.toString().split('.')[0], 3);

    return (
      <div>
        <strong>{name}: </strong>
        <span>{formattedValue === 'NaN' ? '' : formattedValue}</span>
      </div>
    );
  };

  const customTooltipContent = ({ active, payload, label }: any) => {
    const total = payload?.reduce((acc: any, curr: any, index: number) => (index > 0 ? acc + curr.value : 0), 0);

    if (active && payload && payload.length) {
      return (
        <CustomTooltip>
          <p className="label">{currentConfig.labelFormatter ? currentConfig.labelFormatter(label) : label}</p>
          {payload.map((item: any) => (
            <div key={`item-${item.value}-${item.name}`} style={{ color: item.color || item.stroke }}>
              {customTooltipFormatter(item.value, item.name)}
            </div>
          ))}
          <div key={`item-${total}-${total}`} style={{ color: '#514E49' }}>
            {customTooltipFormatter(total, 'Total')}
          </div>
        </CustomTooltip>
      );
    }

    return null;
  };

  useEffect(() => {
    setChartData(
      data.map(entry =>
        moment(entry.day).isAfter(moment()) ? { ...entry, total_value: undefined, total_volume: undefined } : entry,
      ),
    );
  }, [JSON.stringify(data)]);

  return (
    <Container>
      <Spin spinning={isGettingDrilldownData}>
        <DrillUp drilldownConfig={drilldownConfig} drilldownHistory={drilldownHistory} doDrillUp={doDrillUp} />

        <ResponsiveContainer width="100%" height="100%">
          <ComposedChart
            height={100}
            data={findCurrentDrilldown()}
            onClick={e => {
              const id = e.activePayload?.[0]?.payload?.date || e.activePayload?.[0]?.payload?.provider_name;
              if (id) doDrilldown(id, e);
            }}
            margin={{ top: 15, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid stroke="#f5f5f5" />
            <XAxis
              dataKey={currentConfig.label}
              tickFormatter={value => (currentConfig.labelFormatter ? currentConfig.labelFormatter(value) : value)}
              style={{ fontSize: 12 }}
              onClick={(e: any) => {
                doDrilldown(e.value, e);
              }}
            />
            <YAxis style={{ fontSize: 12 }} label={{ value: yAxisLabel, angle: -90, position: 'left' }} />
            <Tooltip content={customTooltipContent} />
            <Legend
              verticalAlign="top"
              height={36}
              style={{ fontSize: 12 }}
              formatter={value => <span style={{ fontSize: 12, fontWeight: 'bold' }}>{value}</span>}
            />
            {currentConfig.values.map((config: DrilldownValue) => {
              switch (config.type ?? currentConfig.type) {
                case 'bar':
                  return (
                    <Bar key={config.dataKey} dataKey={config.dataKey} fill={config.strokeColor} name={config.name} />
                  );
                case 'area':
                  return (
                    <Area
                      type="monotone"
                      key={config.dataKey}
                      dataKey={config.dataKey}
                      fill={config.strokeColor}
                      stroke={config.strokeColor}
                      name={config.name}
                      fillOpacity={1}
                    />
                  );
                case 'area-stacked':
                  return (
                    <Area
                      stackId="1"
                      type="monotone"
                      key={config.dataKey}
                      dataKey={config.dataKey}
                      fill={config.strokeColor}
                      stroke={config.strokeColor}
                      name={config.name}
                      fillOpacity={1}
                    />
                  );
                default:
                  return (
                    <Line
                      key={config.dataKey}
                      dataKey={config.dataKey}
                      stroke={config.strokeColor}
                      name={config.name}
                      type="monotone"
                      strokeWidth={5}
                    />
                  );
              }
            })}
          </ComposedChart>
        </ResponsiveContainer>
      </Spin>
    </Container>
  );
};

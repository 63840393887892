import styled from 'styled-components';

export const FormItemStyle = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  .ant-select {
    background-color: #ffffff;
    border: solid 1px #dae3e3;
    border-radius: 5px;
    color: #ffa23c;
    font-size: 16px;
    font-weight: 700;
    height: 32px;
    overflow: hidden;
    .ant-select-selector {
      border: 0;
      border-radius: 0;
      color: #ffa23c;
      font-size: 16px;
      font-weight: 700;
      min-height: 33px;
    }
    .ant-select-selection-overflow {
      align-self: flex-start;
    }
  }
`;

export const FormLabelStyle = styled.div`
  margin-right: 8px;
`;

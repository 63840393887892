import { useState } from 'react';

import { handleRequest } from '../../services/api/api';
import dashboardApi from '../../services/api/dashboard';

export interface SummaryData {
  summary: {
    approval_limit: number | string;
    approved_value: number | string;
    balance: number | string;
    count_credit_request: number | string;
    disapproved_limit: number | string;
    sum_requested_amount: number | string;
  };
}

export const useDashboardManagement = () => {
  const [isFetchingCharts, setIsFetchingCharts] = useState(false);
  const [isFetchingCards, setIsFetchingCards] = useState(false);
  const [isFetchingTimeline, setIsFechingTimeline] = useState(false);
  const [isFetchingResume, setIsFechingResume] = useState(false);

  const getManagementCards = async (params = {}) => {
    const { summary }: SummaryData = await handleRequest(
      () => dashboardApi.management.showCards(params),
      setIsFetchingCards,
    );
    return { summary };
  };

  const getManagementCharts = async (params = {}) => {
    setIsFetchingCharts(true);
    const dataChartCreditOperation = await handleRequest(() =>
      dashboardApi.management.showChartCreditOperation(params),
    );
    const dataChartRequest = await handleRequest(() => dashboardApi.management.showChartRequest(params));
    const dataChartPrepay = await handleRequest(() => dashboardApi.management.showChartPrepay(params));
    const dataChartCredit = await handleRequest(() => dashboardApi.management.showChartCredit(params));
    setIsFetchingCharts(false);
    return {
      dataChartCreditOperation,
      dataChartRequest,
      dataChartPrepay,
      dataChartCredit,
    };
  };

  const getManagementTimeline = async (params?: { params: unknown }, filter = 'all') => {
    const response = await handleRequest(
      () => dashboardApi.management.showTimeline({ ...params, parent: filter }),
      setIsFechingTimeline,
    );
    return response || { data: [] as any[], total: 0, last_page: 0 };
  };

  const getManagementResume = async (params?: { params: unknown }, filter = 'all') => {
    const response = await handleRequest(
      () => dashboardApi.management.showResume({ ...params, parent: filter }),
      setIsFechingResume,
    );
    return response || { data: [] as any[], total: 0, last_page: 0 };
  };

  const getResumeReport = async (params: any) => {
    const response = await handleRequest(() => dashboardApi.reports.listReportResumeManagement(params));
    return response;
  };

  const generateExportResume = (values: any) => {
    return dashboardApi.reports.generateExportResumeManagement({ ...values });
  };

  const getTimelineReport = async (params: any) => {
    const response = await handleRequest(() => dashboardApi.reports.listReportTimelineManagement(params));
    return response;
  };

  const generateExportTimeline = (values: any) => {
    return dashboardApi.reports.generateExportTimelineManagement({ ...values });
  };

  return {
    isFetchingCharts,
    isFetchingCards,
    isFetchingTimeline,
    isFetchingResume,
    getManagementCards,
    getManagementCharts,
    getManagementResume,
    getManagementTimeline,
    getResumeReport,
    generateExportResume,
    getTimelineReport,
    generateExportTimeline,
  };
};

import styled from 'styled-components';

import { ScrollBox } from '../../../../../../styles';

export const Title = styled.h4`
  color: ${({ theme: { colors } }) => colors.primary};
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
`;

export const DatasulButtonsWrapper = styled.div`
  display: flex;
  gap: 5px;
`;

export const Status = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

export const Fields = styled.div`
  margin-bottom: 30px;
  margin-top: 30px;
  overflow-y: auto;
  max-height: 80%;
  padding: 10px 0;

  ${ScrollBox};
  overflow-x: hidden;
`;

export const ContractNumber = styled.span`
  font-weight: bold;
  color: ${({ theme: { colors } }) => colors.primary};
`;

export const TagValue = styled.span`
  font-size: 12px;
  font-weight: bold;
  line-height: 14px;
`;

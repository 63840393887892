import React from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { useAbility } from 'hooks/ability';

import { AttachmentFormTable } from '../../draft/attachment-form.table';

export const DocumentsToCollectAttachmentTable = () => {
  const queryClient = useQueryClient();
  const ability = useAbility();

  return (
    <AttachmentFormTable
      formFieldName="documents"
      preview={{ isVisible: true }}
      delete={{
        callback: () =>
          queryClient.invalidateQueries({
            queryKey: ['credit-request/package'],
          }),
      }}
      download={{
        isVisible: ability.can('credit.request.package.download', ''),
      }}
    />
  );
};

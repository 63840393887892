import React from 'react';

import { Modal as AntModal } from 'antd';

import DynamicForm from '../DynamicForm';
import { ModalProps } from './types';

const DynamicFormModal: React.FC<ModalProps> = ({ children, data, form, ...modalProps }) => {
  function renderForm() {
    return <DynamicForm data={data} form={form} />;
  }

  return (
    <>
      <AntModal {...modalProps}>
        {renderForm()}
        {children}
      </AntModal>
    </>
  );
};

export default DynamicFormModal;

import React, { useContext } from 'react';

import { Modal } from 'antd';

import { DTOContractTypeField } from '../../../../types/Contract/DTOContractTypeField';
import { DraftModelContext } from '../../context/DraftModelContext';
import { Form } from './Form';
import { Table } from './Table';

export const TableFieldModal = ({ field, disabled }: { field: DTOContractTypeField; disabled: boolean }) => {
  const { handleCloseTable, tablesState } = useContext(DraftModelContext);

  return (
    <Modal
      key={field.name}
      onOk={() => handleCloseTable(field.name)}
      onCancel={() => handleCloseTable(field.name)}
      visible={tablesState[field.name]?.isOpen}
      width="80%"
    >
      <h2>Registros</h2>
      {!disabled && <Form field={field} />}
      <Table field={field} disabled={disabled} />
    </Modal>
  );
};

/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';

import { useContractContext } from '../../context/ContractContext';
import { useDraftModelContext } from '../../context/DraftModelContext';
import { stages } from '../../Emission/modules/ContractStages/stages';
import { StageTab } from '../StageTab';
import { Container } from './styles';
import { StageTabListProps } from './types';

export const StageTabList = ({ isStageSelected, setSelectedStage }: StageTabListProps) => {
  const { isIssued, needEmissionValidation } = useDraftModelContext();
  const { contract, isValidatedOnEmission, isDocumentCancelled, hasContractType, mainContract } = useContractContext();

  const getBadgeCount = (stage: 'Details' | 'Draft' | 'Signature' | 'Attach' | 'Comments' | 'ReprovalHistoric') => {
    if (stage === 'Attach') return contract?.attachments?.length;
    if (stage === 'Comments') return contract?.comments?.length;
    if (stage === 'ReprovalHistoric') return contract?.refusals?.length;
    return 0;
  };

  return (
    <Container>
      {Array.from(stages).map(([name, stage]) => {
        const badgeCount = getBadgeCount(name);

        const isEmissionTab = stage.index === 2;
        const shouldDisableEmissionTab = isEmissionTab && ((isDocumentCancelled && !isIssued) || !hasContractType);

        return (
          <StageTab
            icon={stage.icon}
            key={stage.index}
            disabled={
              (stage.index === 3 && (!isIssued || (needEmissionValidation && !isValidatedOnEmission))) ||
              shouldDisableEmissionTab ||
              !contract?.active
            }
            isSelected={isStageSelected(stage.index)}
            onClick={() => setSelectedStage(stage.index)}
            badgeCount={badgeCount}
          />
        );
      })}
    </Container>
  );
};

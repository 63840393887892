import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Col } from 'antd';
import { Row } from 'components';
import PermissionedComponent from 'components/PermissionedComponent';
import {
  CREDIT_REQUEST_TEAM_STATUS_EDITABLE_RULE_PREFIN_SUGAR_MILLS,
  OPERATION_PREFIN_SUGAR_MILLS_ID,
} from 'configs/constants';
import { useCreditRequestContext } from 'context/CreditRequestContext';
import { Can } from 'hooks/ability';
import { usePrefinModalityRules } from 'pages/CreditRequest/rules/prefin/usePrefinModalityRules';
import { useCreditRequestStore } from 'pages/CreditRequest/store/useCreditRequestStore';

import { ApprovedValueBarterInput } from '../atoms/ApprovedValueBarterInput';
import { ApprovedValueCashInput } from '../atoms/ApprovedValueCashInput';
import { ApprovedValueInput } from '../atoms/ApprovedValueInput';
import { CommentsInput } from '../atoms/comments.input';
import { CreditLimitDateInput } from '../atoms/CreditLimitDateInput';
import { JsonDataContractTypeIdSelect } from '../atoms/JsonDataContractTypeIdSelect';
import { JsonDataCurrencyTypeIdSelect } from '../atoms/JsonDataCurrencyTypeIdSelect';
import { JsonDataGuarantorCodeErpInput } from '../atoms/JsonDataGuarantorCodeErpInput';
import { JsonDataGuarantorCodeSapInput } from '../atoms/JsonDataGuarantorCodeSapInput';
import { JsonDataGuarantorDocNumberInput } from '../atoms/JsonDataGuarantorDocNumberInput';
import { JsonDataGuarantorNameInput } from '../atoms/JsonDataGuarantorNameInput';
import { JsonDataIncotermIdSelect } from '../atoms/JsonDataIncotermIdSelect';
import { JsonDataInterestRateInput } from '../atoms/JsonDataInterestRateInput';
import { JsonDataModalityIdSelect } from '../atoms/JsonDataModalityIdSelect';
import { JsonDataPriceInput } from '../atoms/JsonDataPriceInput';
import { JsonDataQuantityInput } from '../atoms/JsonDataQuantityInput';
import { JsonDataWarrantyPledgeAreasSelect } from '../atoms/JsonDataWarrantyPledgeAreasSelect';
import { JsonDataWarrantyTypesSelect } from '../atoms/JsonDataWarrantyTypesSelect';
import { NumberInput } from '../atoms/NumberInput';
import { RequestedAmountBarterInput } from '../atoms/RequestedAmountBarterInput';
import { RequestedAmountCashInput } from '../atoms/RequestedAmountCashInput';
import { RequestedAmountInput } from '../atoms/RequestedAmountInput';
import { WarrantyRegisterNumberInput } from '../atoms/WarrantyRegisterNumberInput';
import { DocumentsList } from '../molecules/DocumentsList';
import Guarantees from '../organisms/Guarantees';
import { OperationalFields } from '../organisms/OperationalFields';
import { PersonFields } from '../organisms/PersonFields';
import { StatusHistory } from '../organisms/StatusHistory';
import { Sustainability } from '../organisms/Sustainability';

export const PrefinForm = () => {
  const { t } = useTranslation();

  const { shouldShowLimitBarterInputs, shouldShowLimitCashInputs, shouldShowLimitInputs } = usePrefinModalityRules();
  const { creditForm } = useCreditRequestContext();

  const pageStatus = useCreditRequestStore(state => state.pageStatus);
  const selectedOperationId = useCreditRequestStore(state => state.selectedOperationId);
  const canCurrentUserApproveGuarantee = useCreditRequestStore(state => state.canCurrentUserApproveGuarantee);
  const limitRules = useCreditRequestStore(state => state.limitRules);
  const sustainabilityHistory = useCreditRequestStore(state => state.sustainabilityHistory);
  const contractTypeVisible = useCreditRequestStore(state => state.contractTypeVisible);
  const creditRequestId = useCreditRequestStore(state => state.creditRequestId);
  const creditRequestData = useCreditRequestStore(state => state.creditRequestData);

  const shouldShowApprovedValueInput = useMemo(
    () =>
      creditForm.getFieldValue('approved_value') ||
      creditForm.getFieldValue('approved_value_cash') ||
      creditForm.getFieldValue('approved_value_barter'),
    [
      creditForm.getFieldValue('approved_value'),
      creditForm.getFieldValue('approved_value_cash'),
      creditForm.getFieldValue('approved_value_barter'),
    ],
  );

  const isRequestedAmountDisabled =
    pageStatus.viewing &&
    !(
      selectedOperationId === OPERATION_PREFIN_SUGAR_MILLS_ID &&
      CREDIT_REQUEST_TEAM_STATUS_EDITABLE_RULE_PREFIN_SUGAR_MILLS.includes(creditRequestData?.team_status)
    );

  return (
    <>
      {/* Dados Gerais */}
      <div className="group-form">
        <h2>{t('pages.credit-request.form.titles.general-data')}</h2>

        <Row gutter={[8, 8]}>
          <NumberInput />

          <PersonFields />
        </Row>
      </div>

      {/* Dados Operacionais */}
      <div className="group-form">
        <h2>{t('pages.credit-request.form.titles.operational-data')}</h2>

        <Row gutter={[8, 8]}>
          <OperationalFields />

          {/* Modalidade */}
          <Col xs={24} sm={18} md={12} lg={8} xl={6} xxl={6}>
            <JsonDataModalityIdSelect />
          </Col>
          {/* Currency Type */}
          <Col xs={24} sm={18} md={12} lg={8} xl={6} xxl={6}>
            <JsonDataCurrencyTypeIdSelect disabled={pageStatus.viewing} />
          </Col>
          {/* Requested Amount */}
          <Col xs={24} sm={18} md={12} lg={8} xl={6} xxl={6} style={{ display: shouldShowLimitInputs ? '' : 'none' }}>
            <RequestedAmountInput disabled={isRequestedAmountDisabled} required={shouldShowLimitInputs} />
          </Col>
          <Col
            xs={24}
            sm={18}
            md={12}
            lg={8}
            xl={6}
            xxl={6}
            style={{ display: shouldShowLimitBarterInputs ? '' : 'none' }}
          >
            <RequestedAmountBarterInput disabled={isRequestedAmountDisabled} required={shouldShowLimitBarterInputs} />
          </Col>
          <Col
            xs={24}
            sm={18}
            md={12}
            lg={8}
            xl={6}
            xxl={6}
            style={{ display: shouldShowLimitCashInputs ? '' : 'none' }}
          >
            <RequestedAmountCashInput disabled={isRequestedAmountDisabled} required={shouldShowLimitCashInputs} />
          </Col>
          {/* Approved Value */}
          {/* {shouldShowApprovedValueInput && ( */}
          <>
            <Col
              xs={24}
              sm={18}
              md={12}
              lg={8}
              xl={6}
              xxl={6}
              style={{ display: shouldShowApprovedValueInput && shouldShowLimitInputs ? '' : 'none' }}
            >
              <ApprovedValueInput disabled required={shouldShowApprovedValueInput && shouldShowLimitInputs} />
            </Col>
            <Col
              xs={24}
              sm={18}
              md={12}
              lg={8}
              xl={6}
              xxl={6}
              style={{ display: shouldShowApprovedValueInput && shouldShowLimitBarterInputs ? '' : 'none' }}
            >
              <ApprovedValueBarterInput
                disabled
                required={shouldShowApprovedValueInput && shouldShowLimitBarterInputs}
              />
            </Col>
            <Col
              xs={24}
              sm={18}
              md={12}
              lg={8}
              xl={6}
              xxl={6}
              style={{ display: shouldShowApprovedValueInput && shouldShowLimitCashInputs ? '' : 'none' }}
            >
              <ApprovedValueCashInput disabled required={shouldShowApprovedValueInput && shouldShowLimitCashInputs} />
            </Col>
          </>
          {/* )} */}
          <Col
            xs={24}
            sm={18}
            md={12}
            lg={8}
            xl={6}
            xxl={6}
            style={{ display: creditForm.getFieldValue('credit_due_date') ? '' : 'none' }}
          >
            <CreditLimitDateInput required={!!creditForm.getFieldValue('credit_due_date')} disabled={!pageStatus.new} />
          </Col>
          {/* Contract Type */}
          <Col xs={24} sm={18} md={12} lg={8} xl={6} xxl={6}>
            <JsonDataContractTypeIdSelect />
          </Col>
          {contractTypeVisible && (
            <>
              <Col xs={24} sm={18} md={12} lg={8} xl={6} xxl={6}>
                <JsonDataQuantityInput />
              </Col>
              <Col xs={24} sm={18} md={12} lg={8} xl={6} xxl={6}>
                <JsonDataPriceInput disabled={pageStatus.viewing} />
              </Col>
            </>
          )}
          {/* Interest Rate */}
          <Col xs={24} sm={18} md={12} lg={8} xl={6} xxl={6}>
            <JsonDataInterestRateInput />
          </Col>
          {/* Incoterm */}
          <Col xs={24} sm={18} md={12} lg={8} xl={6} xxl={6}>
            <JsonDataIncotermIdSelect />
          </Col>
        </Row>
      </div>

      {/* Garantias sugeridas */}
      <div className="group-form">
        <h2>{t('pages.credit-request.form.titles.suggested-warranties')}</h2>

        <Row gutter={[8, 8]}>
          {/* Pledge Area */}
          <Col xs={24} sm={18} md={12} lg={8} xl={6} xxl={6}>
            <JsonDataWarrantyPledgeAreasSelect />
          </Col>
          {/* Guarantor Name */}
          <Col xs={24} sm={18} md={12} lg={8} xl={6} xxl={6}>
            <JsonDataGuarantorNameInput />
          </Col>
          {/* Guarantor Code ERP */}
          <Col xs={24} sm={18} md={12} lg={8} xl={6} xxl={6}>
            <JsonDataGuarantorCodeErpInput />
          </Col>
          <Col xs={24} sm={18} md={12} lg={8} xl={6} xxl={6}>
            <JsonDataGuarantorCodeSapInput />
          </Col>
          {/* Guarantor Doc Number */}
          <Col xs={24} sm={18} md={12} lg={8} xl={6} xxl={6}>
            <JsonDataGuarantorDocNumberInput />
          </Col>
          {/* Warranty Type */}
          <Col xs={24} sm={18} md={12} lg={8} xl={6} xxl={6}>
            <JsonDataWarrantyTypesSelect />
          </Col>
          {/* Warranty Register Number */}
          <Col xs={24} sm={18} md={12} lg={8} xl={6} xxl={6}>
            <WarrantyRegisterNumberInput />
          </Col>
          {/* Comments */}
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <CommentsInput rows={5} />
          </Col>
        </Row>
      </div>

      <Can I="credit.request.attachment.index">
        <DocumentsList />
      </Can>

      {!pageStatus.new ? (
        <PermissionedComponent
          permission="credit.request.attachment.index"
          description={t('permission.message.credit.request.history.index')}
        >
          <StatusHistory />
        </PermissionedComponent>
      ) : null}

      {/* Histórico de Sustentabilidade */}
      {!pageStatus.new && sustainabilityHistory.length >= 1 && (
        <div className="group-form">
          <h2>{t('pages.credit-request.form.titles.sustainability-history')}</h2>

          <Row gutter={[8, 8]}>
            <Sustainability />
          </Row>
        </div>
      )}

      {/* Garantias de Crédito */}
      {/* {(limitRules || partialLimitData) && !!paramApprovedValue && ( */}
      {!pageStatus.new ? (
        <div className="group-form">
          <h2>{t('pages.credit-request.form.titles.limit-partial')}</h2>

          <Row gutter={[8, 8]}>
            <PermissionedComponent
              permission={['credit.request.partial.limit.index', 'credit.request.resumelimits.index']}
              needAllPermissions
              description={t('permission.message.credit.request.partial.limit.index')}
            >
              <Guarantees
                isEditable
                canCurrentUserApproveGuarantee={canCurrentUserApproveGuarantee}
                limitRules={limitRules}
                creditRequestId={creditRequestId}
              />
            </PermissionedComponent>
          </Row>
        </div>
      ) : null}
    </>
  );
};

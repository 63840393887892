export default {
  contracts: [
    {
      title: 'pages.edocuments.contracts.table.headers.contract',
      dataIndex: 'contract',
      key: 'contract',
      sorter: false,
      filtered: false,
    },
    {
      title: 'pages.edocuments.contracts.table.headers.code',
      dataIndex: 'code',
      key: 'code',
      sorter: false,
      filtered: false,
    },
    {
      title: 'pages.edocuments.contracts.table.headers.provider',
      dataIndex: 'provider_name',
      key: 'provider_name',
      sorter: false,
      filtered: false,
    },
    {
      title: 'pages.edocuments.contracts.table.headers.desc_item',
      dataIndex: 'desc_item',
      key: 'desc_item',
      sorter: false,
      filtered: false,
    },
    {
      title: 'pages.edocuments.contracts.table.headers.fixed_price',
      dataIndex: 'fixed_price',
      key: 'fixed_price',
      sorter: false,
      filtered: false,
    },
    {
      title: 'pages.edocuments.contracts.table.headers.price_unit',
      dataIndex: 'price_unit',
      key: 'price_unit',
      sorter: false,
      filtered: false,
    },
    {
      title: 'pages.edocuments.contracts.table.headers.quantity_delivery',
      dataIndex: 'quantity_delivery',
      key: 'quantity_delivery',
      sorter: false,
      filtered: false,
    },
    {
      title: 'pages.edocuments.contracts.table.headers.payment_forecast',
      dataIndex: 'payment_forecast',
      key: 'payment_forecast',
      sorter: false,
      filtered: false,
    },
    {
      title: 'pages.edocuments.contracts.table.headers.harvest_orig',
      dataIndex: 'harvest_orig',
      key: 'harvest_orig',
      sorter: false,
      filtered: false,
    },
    {
      title: 'pages.edocuments.contracts.table.headers.adm',
      dataIndex: 'adm',
      key: 'adm',
      sorter: false,
      filtered: false,
    },
    {
      title: 'pages.edocuments.contracts.table.headers.uf',
      dataIndex: 'uf',
      key: 'uf',
      sorter: false,
      filtered: false,
    },
    {
      title: 'pages.edocuments.contracts.table.headers.subsidiary_id',
      dataIndex: 'subsidiary_id',
      key: 'subsidiary_id',
      sorter: false,
      filtered: false,
    },
    {
      title: 'pages.edocuments.contracts.table.headers.type',
      dataIndex: 'type',
      key: 'type',
      sorter: false,
      filtered: false,
    },
    {
      title: 'pages.edocuments.contracts.table.headers.created_at',
      dataIndex: 'created_at',
      key: 'created_at',
      sorter: true,
      filtered: false,
    },
    {
      title: 'pages.edocuments.contracts.table.headers.updated_at',
      dataIndex: 'updated_at',
      key: 'updated_at',
      sorter: false,
      filtered: false,
    },
  ],
};

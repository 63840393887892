import styled from 'styled-components';

export const SFormButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  button + button {
    margin-left: 8px;
  }
`;

export const FieldsDragDropWrapper = styled.div`
  .draggable {
    top: auto !important;
    left: auto !important;
  }
`;

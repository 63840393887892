import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactJson from 'react-json-view';

import { Collapse, Pagination, Spin } from 'antd';
import { handleRequest } from 'services/api/api';
import documentApi from 'services/api/document';
import { ContractImportData } from 'types/Contract/ContractImportsData';
import { Formatter } from 'utils/Formatter';

import PaginationData from '../../../../../../../../@types/data/PaginationData';

interface DataTabProps {
  paginatedImports: PaginationData<ContractImportData>;
  isFetchingImports: boolean;
  fetchImports: (pageNumber?: number) => void;
}

export const DataTab = ({ fetchImports, isFetchingImports, paginatedImports }: DataTabProps) => {
  const { t } = useTranslation();
  const { Panel } = Collapse;

  const [json, setJson] = useState<string>('');
  const [active, setActive] = useState('');
  const [isLoadingJson, setIsLoadingJson] = useState(false);

  const handleChangePage = (pageNumber: number) => fetchImports(pageNumber);

  const handleGetJson = async (key: string | string[]) => {
    const id = Array.isArray(key) ? key[1] : key;
    setActive(id);
    if (!id) return;
    const response = await handleRequest(() => documentApi.download(id), setIsLoadingJson);
    const reader = new FileReader();
    reader.readAsText(response);
    const textJson = await (response as Blob).text();
    setJson(textJson);
  };

  return (
    <Spin spinning={isLoadingJson || isFetchingImports}>
      {isFetchingImports && paginatedImports?.data?.length === 0 ? <div style={{ height: '200px' }} /> : null}
      <Collapse activeKey={active} accordion onChange={handleGetJson}>
        {!paginatedImports?.data?.length ? (
          <div style={{ height: '100px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {t('pages.credit-request.kanban.cards.sap_integration_log_not_found')}
          </div>
        ) : null}
        {paginatedImports?.data.map(jsonFileData => (
          <Panel key={jsonFileData.attachment?.id} header={`${Formatter.datetime(jsonFileData.created_at)}`}>
            <ReactJson src={json ? JSON.parse(json) : ''} />
          </Panel>
        )) || null}
      </Collapse>
      <div style={{ display: 'flex', width: '100%', justifyContent: 'center', marginTop: '10px' }}>
        <Pagination defaultCurrent={1} total={paginatedImports?.total ?? 0} pageSize={10} onChange={handleChangePage} />
      </div>
    </Spin>
  );
};

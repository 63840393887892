import React, { useState } from 'react';
import { BiCheckDouble } from 'react-icons/bi';

import { Checkbox, Spin } from 'antd';

import { UploadAttachment } from '../../../../../../../components/UploadAttachment';
import { CustomButton } from '../../../../../components/CustomButton';
import { IconWrapper } from '../../../../../components/IconWrapper';
import { useContractContext } from '../../../../../context/ContractContext';
import { Container, Wrapper } from './styles';

interface Props {
  hideUpload?: () => void;
}

export const UploadBody = ({ hideUpload }: Props) => {
  const { updateContractAttachments, isAddingAttachments, canUploadAttachmentsToSignature } = useContractContext();

  const [files, setFiles] = useState([]);
  const [sendToSignature, setSendToSignature] = useState(false);

  const updateFiles = (newFiles: any[] | any) => setFiles(newFiles.length ? newFiles : [newFiles]);

  const sendFiles = async () => {
    const couldAddNewAttachment = await updateContractAttachments(files, sendToSignature);
    if (hideUpload && couldAddNewAttachment) hideUpload();
  };

  return (
    <Wrapper>
      <Spin spinning={isAddingAttachments}>
        <Container>
          <div style={{ overflowX: 'hidden', overflowY: 'auto' }}>
            <UploadAttachment onChange={updateFiles} maxFiles={1} />
            {canUploadAttachmentsToSignature() && (
              <Checkbox value={sendToSignature} onChange={e => setSendToSignature(e.target.checked)}>
                Enviar para assinatura?
              </Checkbox>
            )}
          </div>
          <CustomButton onClick={sendFiles} disabled={!files.length} permissions="contract.uploadextra">
            <IconWrapper>
              <BiCheckDouble />
            </IconWrapper>
            Confirmar Anexos
          </CustomButton>
        </Container>
      </Spin>
    </Wrapper>
  );
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaArrowDown } from 'react-icons/fa';

import { Empty, Spin } from 'antd';
import { useTheme } from 'styled-components';

import { Formatter } from '../../../../../../utils/Formatter';
import { Tab } from '../../../../components/Tab';
import { useContractContext } from '../../../../context/ContractContext';
import { Container, Wrapper, Title, Date, Refusal, NewStatus, OldStatus, UserName, Observation } from './styles';

export const ReprovalHistoric = () => {
  const { t, i18n } = useTranslation();
  const { contract, isFetching } = useContractContext();
  const { colors } = useTheme();

  return (
    <Tab>
      <Wrapper>
        <Spin spinning={isFetching}>
          <Title>{t('pages.edocuments.emission.reprovalHistoric.title')}</Title>
          <Container>
            {contract?.refusals.length ? (
              contract.refusals?.map(refusal => (
                <Refusal>
                  <Date>{Formatter.datetime(refusal.updated_at)}</Date>
                  <OldStatus>{JSON.parse(refusal.old_status.translations)[i18n.language].title}</OldStatus>
                  <FaArrowDown color={colors.primary} />
                  <NewStatus>{JSON.parse(refusal.new_status.translations)[i18n.language].title}</NewStatus>
                  <Observation>{refusal.obs}</Observation>
                  <UserName>{refusal.user.name}</UserName>
                </Refusal>
              ))
            ) : (
              <Empty />
            )}
          </Container>
        </Spin>
      </Wrapper>
    </Tab>
  );
};

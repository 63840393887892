'use client';

import * as React from 'react';
import { Matcher } from 'react-day-picker';
import { useTranslation } from 'react-i18next';

import { format, getYear, parseISO, sub } from 'date-fns';
import { ptBR, enUS } from 'date-fns/locale';
import { cn } from 'lib/utils';
import { Calendar as CalendarIcon } from 'lucide-react';

import { Button } from './button';
import { Calendar, CalendarProps } from './calendar';
import { Popover, PopoverContent, PopoverTrigger } from './popover';

type DatePickerProps = {
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  disabled?: boolean;
  name?: string;
  disabledDates?: Matcher | Matcher[];
  className?: string;
} & Pick<CalendarProps, 'defaultMonth' | 'onDayClick'>;

export const DatePicker = React.forwardRef(
  (
    { onChange, value, disabled, name, disabledDates, className, ...props }: DatePickerProps,
    ref: React.Ref<HTMLInputElement>,
  ) => {
    const { i18n, t } = useTranslation();

    const date = value || undefined;

    const handleChangeValue = (newDate: Date) => {
      onChange?.({
        target: {
          value: newDate ? format(newDate, 'yyyy-MM-dd') : '',
          name,
        },
      } as React.ChangeEvent<HTMLInputElement>);
    };

    const locale = (() => {
      switch (i18n.language) {
        case 'en-US':
          return enUS;
        default:
          return ptBR;
      }
    })();

    return (
      <Popover>
        <input className="w-0 h-0 absolute" name={name} value={date} tabIndex={-1} ref={ref} />
        <PopoverTrigger asChild>
          <Button
            type="button"
            variant="outline"
            className={cn('w-[280px] justify-start text-left font-normal', !date && 'text-muted-foreground', className)}
            disabled={disabled}
          >
            <CalendarIcon className="mr-2 h-4 w-4" />
            {date ? (
              format(parseISO(date), 'PPP', {
                locale,
              })
            ) : (
              <span>{t('pickDate')}</span>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-min p-0">
          <Calendar
            locale={locale}
            selected={date ? parseISO(date) : undefined}
            initialFocus
            disabled={disabledDates ?? []}
            onSelect={handleChangeValue}
            fromYear={getYear(sub(new Date(), { years: 200 }))}
            toYear={getYear(new Date())}
            {...props}
            mode="single"
            fixedWeeks
            captionLayout="dropdown-buttons"
          />
        </PopoverContent>
      </Popover>
    );
  },
);

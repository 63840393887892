import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormItem } from 'components/ui/form-item';
import { GroupForm } from 'components/ui/group-form';
import { Input, TextArea } from 'components/ui/input';

export const DraftEmailDataSection = () => {
  const { t } = useTranslation();
  const form = useFormContext();

  const id = form.watch('id');
  const isDigital = Boolean(Number(form.watch('is_digital')));
  const externalEnvelopeId = form.watch('external_envelope_id');
  return (
    <GroupForm
      title={
        isDigital
          ? t('pages.credit-request.formalization-guarantees.pre-registration-docs.modalDraft.email_data')
          : t('pages.credit-request.formalization-guarantees.pre-registration-docs.modalDraft.identification')
      }
    >
      <FormItem
        name="email_subject"
        rules={{ required: true }}
        label={
          isDigital
            ? t('pages.credit-request.formalization-guarantees.pre-registration-docs.modalDraft.email_title')
            : t(
                'pages.credit-request.formalization-guarantees.pre-registration-docs.modalDraft.document_identification',
              )
        }
        className="w-full"
      >
        <Input type="email" maxLength={80} disabled={id && isDigital && !!externalEnvelopeId} />
      </FormItem>

      <FormItem
        name="email_message"
        rules={{ required: true }}
        label={
          isDigital
            ? t('pages.credit-request.formalization-guarantees.pre-registration-docs.modalDraft.body_message')
            : t('pages.credit-request.formalization-guarantees.pre-registration-docs.modalDraft.comments')
        }
        className="w-full"
      >
        <TextArea maxLength={500} disabled={id && isDigital && !!externalEnvelopeId} />
      </FormItem>
    </GroupForm>
  );
};

import { useCallback } from 'react';

import { subDivisions } from 'configs/divisions';
import { useCache } from 'hooks/cache';

import { useCreditRequestStore } from '../store/useCreditRequestStore';

export const useLoadOperations = () => {
  const cache = useCache();

  const setOperationOptions = useCreditRequestStore(state => state.setOperationOptions);

  const loadOperations = useCallback(
    (subDivisionId: string) => {
      let listOfOperations = cache.getCreditOptions('operation');
      if (subDivisions[subDivisionId]) {
        listOfOperations = cache
          .getCreditOptions('operation')
          .filter(s => subDivisions[subDivisionId].includes(s.value));
      }
      setOperationOptions(listOfOperations);
    },
    // eslint-disable-next-line
    [cache],
  );

  return { loadOperations };
};

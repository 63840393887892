import { PaginatedQueryParams, usePaginatedQuery } from 'hooks/usePaginatedQuery';
import { SelectInfiniteQueryParams, useSelectInfiniteQuery } from 'hooks/useSelectInfiniteQuery';
import PaginationData from 'types/Api/PaginationData';
import SignatureTypeData from 'types/CreditRequest/SignatureTypeData';

export const useGetSignatureTypesQuery = (params?: PaginatedQueryParams) => {
  return usePaginatedQuery<SignatureTypeData>({
    queryKey: ['signature-type'],
    staleTime: 1000 * 60 * 5,
    url: `signature-type`,
    permissions: ['credit.request.envelope.index'],
    params: {
      ...params,
    },
  });
};

export const useGetSignatureTypesOptionsQuery = (params: SelectInfiniteQueryParams) => {
  return useSelectInfiniteQuery<PaginationData<SignatureTypeData>>(
    'signature-type',
    {
      queryKey: ['infinity/signature-type'],
      staleTime: 1000 * 60 * 5,
      permissions: ['credit.request.envelope.index'],
    },
    params,
  );
};

import React, { forwardRef } from 'react';

import { PermissionedField } from 'components/PermissionedField';
import { ComboBoxInfinite, ComboBoxInfiniteProps } from 'components/ui/combo.infinite';
import { usePagination } from 'hooks/usePagination';
import { useGetDocumentStatusOptionsQuery } from 'pages/CreditRequest/api/useGetDocumentStatusQuery';
import { Converter } from 'utils/Converter';

type Props<T = unknown> = Omit<ComboBoxInfiniteProps<T>, 'infiniteQuery' | 'initialOptions'> & {
  initialOptions?: T[];
};

export const DocumentStatusSelect = forwardRef((props: Props, ref: React.Ref<HTMLInputElement> | null) => {
  const { dispatchPagination, paginationConfig } = usePagination();
  const documentStatusInfiniteQuery = useGetDocumentStatusOptionsQuery({ ...paginationConfig });

  return (
    <PermissionedField permission={['credit.request.documentstatus.all']}>
      <ComboBoxInfinite
        {...props}
        ref={ref}
        initialOptions={Converter.selectOptionArray((props.initialOptions || []).filter(option => !!option))}
        infiniteQuery={documentStatusInfiniteQuery}
        onSearch={value => dispatchPagination({ type: 'SET_SEARCH', payload: value })}
      />
    </PermissionedField>
  );
});
DocumentStatusSelect.displayName = 'DocumentStatusSelect';

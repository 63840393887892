import { Card, Form, Divider } from 'antd';
import styled from 'styled-components';

// import { TableData } from '../../../compositions';

export const Container = styled.div`
  min-height: 100%;
  padding: 20px;
  width: 100%;
  z-index: 1;
  display: flex;
  justify-content: center;

  .ant-tabs {
    width: 100%;
  }
`;

export const FormContainer = styled(Form)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 20px 0 0;

  .ant-form-item {
    margin: 10px 0;
    padding: 0 10px;
  }
`;

export const CardContainer = styled(Card)`
  height: 100%;
  width: 100%;
  border: 1px solid ${({ theme: { colors } }) => colors.primary};

  .ant-card-body {
    display: flex;
    flex-direction: column;
    align-items: center;

    input {
      margin: 5px 0;
    }
  }

  .ant-card-loading-content {
    width: 100%;
  }
`;

export const UploadImageContainer = styled.div`
  .ant-upload {
    margin: 0;
  }

  .avatar-uploader > .ant-upload {
    width: 180px;
    height: 180px;
    border-radius: 50%;
  }

  img {
    border-radius: 50%;
  }
`;

// export const TableDataContainer = styled(TableData)`
//   width: 100%;
//   padding: 0 10px;
// `;

export const DividerContainer = styled(Divider)`
  margin: 60px 0 16px !important;
`;

const columns = [
  {
    title: 'pages.admin.user.table.header.name',
    dataIndex: 'name',
    key: 'name',
    sorter: true,
  },
  {
    title: 'pages.admin.user.table.header.email',
    dataIndex: 'email',
    key: 'email',
    sorter: true,
  },
  {
    title: 'pages.admin.user.table.header.position',
    dataIndex: 'position_id',
    key: 'position_id',
    sorter: true,
  },
  {
    title: 'pages.admin.user.table.header.external',
    dataIndex: 'external_id',
    key: 'external_id',
    sorter: true,
  },
  {
    title: 'pages.admin.user.table.header.regions',
    dataIndex: 'regions',
    key: 'regions',
    sorter: false,
  },
  {
    title: 'pages.admin.user.table.header.subsidiaries',
    dataIndex: 'subsidiaries',
    key: 'subsidiaries',
    sorter: false,
  },
  {
    title: 'pages.admin.user.table.header.teams',
    dataIndex: 'teams',
    key: 'teams',
    sorter: false,
  },
  {
    title: 'pages.admin.user.table.header.roles',
    dataIndex: 'roles',
    key: 'roles',
    sorter: false,
  },
  {
    title: 'pages.admin.user.table.header.active',
    dataIndex: 'active',
    key: 'active',
    sorter: false,
  },
  {
    title: 'pages.admin.user.table.header.action',
    key: 'action',
    dataIndex: 'action',
    align: 'center',
    fixed: 'right',
    width: '150px',
  },
];

export default columns;

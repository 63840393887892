import React from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from 'antd';
import appConfigs from 'configs/app';
import moment from 'moment';

import { SDataJustification } from './styles';
import { ModalViewProps } from './types';

export const ModalView = ({ setModalViewVisible, modalViewVisible, dataJustificationView }: ModalViewProps) => {
  const { t } = useTranslation();
  if (dataJustificationView) {
    return (
      <Modal
        title={t('pages.credit-request.justification.modalView.title')}
        visible={modalViewVisible}
        onCancel={() => {
          setModalViewVisible(false);
        }}
        footer={null}
        // onOk={() => setModalViewVisible(false)}
      >
        <SDataJustification>
          <div className="area-details">
            <div className="label">{t('pages.credit-request.justification.modalView.description')}:</div>
            <div className="detail">{dataJustificationView.description}</div>
          </div>
          <div className="area-details">
            <div className="label">{t('pages.credit-request.justification.modalView.user')}:</div>
            <div className="detail">{dataJustificationView.user.name}</div>
          </div>
          <div className="area-details">
            <div className="label">{t('pages.credit-request.justification.modalView.date')}:</div>
            <div className="detail">{moment(dataJustificationView.created_at).format(appConfigs.formatDate)}</div>
          </div>
        </SDataJustification>
      </Modal>
    );
  }
  return null;
};

import React from 'react';
import { useTranslation } from 'react-i18next';

import { LogsTable } from './LogsTable';
import { Restrictions } from './Restrictions';
import { Container } from './styles';

interface Props {
  counterparty: any;
}

export const History = ({ counterparty }: Props) => {
  const { t } = useTranslation();

  return (
    <Container>
      <h2>
        {`${`${t('pages.serasacounterparty.historic.restrictionsTitle')} ${counterparty.name}`} - ${
          counterparty.doc_number
        }`}
      </h2>
      <Restrictions counterparty={counterparty} totalRestrictions={counterparty.restrictions_sum_value} />
      <h2>{t('pages.serasacounterparty.historic.logs')}</h2>
      <LogsTable counterpartyId={counterparty.id} />
    </Container>
  );
};

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TableData, TableTranslation } from '@agrodatabr/agrodataui';
import { ColumnType } from 'antd/lib/table';

import SerasaIntegrations from '../../../../@types/data/SerasaIntegrations';
import { IconWithTooltip } from '../../../../components';
import { Formatter } from '../../../../utils/Formatter';
// eslint-disable-next-line import-helpers/order-imports
import { columns } from './columns';

import { Filter } from './Filter';
import { TableProps } from './types';

export const Table = ({
  handleChange,
  isFetching = false,
  controller,
  setDocuments,
  setModalDocumentsVisible,
}: TableProps) => {
  const { t } = useTranslation();

  const [filters, setFilters] = useState<{ [name: string]: unknown }>({});

  const changeFilters = (values: { [name: string]: unknown }) => setFilters(values);

  const customColumns = (column: ColumnType<object>) => {
    column = { ...column, title: t(column.title.toString()) };
    switch (column.key) {
      case 'created_at':
        column = {
          ...column,
          render: (value: any, row: any) => {
            return row.created_at ? Formatter.datetime(row.created_at) : '-';
          },
        };
        break;
      default:
        break;
    }
    return column;
  };

  const getActions = () => ({
    render: (_: unknown, record: SerasaIntegrations) => (
      <>
        <IconWithTooltip
          action="view"
          title={t('pages.serasa.integrations.table.viewDocuments')}
          onClick={() => {
            setDocuments(record.documents);
            setModalDocumentsVisible(true);
          }}
        />
      </>
    ),
  });

  return (
    <div>
      <Filter changeFilters={changeFilters} />

      <TableData
        searchRequest={handleChange}
        customColumns={customColumns}
        tableConfig={{ getActions }}
        columns={columns}
        width="100%"
        pagination={{ position: ['bottomCenter'] }}
        controller={controller}
        loading={isFetching}
        translation={{ pagination: { totalOfRegisters: 'Total de registros' } } as TableTranslation}
        searchFilters={filters}
      />
    </div>
  );
};

export default {
  teams: [
    {
      title: 'pages.admin.operation.table.headers.title',
      dataIndex: 'title',
      key: 'title',
      sorter: false,
      filtered: false,
    },
    {
      title: 'pages.admin.operation.table.headers.name',
      dataIndex: 'name',
      key: 'name',
      sorter: false,
      filtered: false,
    },
    {
      title: 'pages.admin.operation.table.headers.slug',
      dataIndex: 'slug',
      key: 'slug',
      sorter: true,
      filtered: false,
    },
    {
      title: 'pages.admin.operation.table.headers.team_credit_status_id',
      dataIndex: 'team_credit_status_id',
      key: 'team_credit_status_id',
      sorter: true,
      filtered: false,
    },
    {
      title: 'pages.admin.operation.table.headers.created_at',
      dataIndex: 'created_at',
      key: 'created_at',
      sorter: true,
      filtered: false,
    },
    {
      title: 'pages.admin.operation.table.headers.updated_at',
      dataIndex: 'updated_at',
      key: 'updated_at',
      sorter: true,
      filtered: false,
    },
    {
      title: 'pages.admin.operation.table.headers.action',
      key: 'action',
      dataIndex: 'action',
      align: 'center',
      width: '150px',
      fixed: 'right',
    },
  ],
};

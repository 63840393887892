import { useMutation } from '@tanstack/react-query';
import { usePage } from 'hooks/page';
import api from 'services/api/api';

export const useDeletePackageDocumentToCollectMutation = () => {
  const { alertStatus } = usePage();

  return useMutation({
    mutationKey: ['deletePackageDocumentToCollect'],
    mutationFn: async (itemId: string) => {
      return api.delete(`credit-request/package/document/${itemId}`);
    },
    onError: err => {
      alertStatus(err.message, 'error');
    },
  });
};

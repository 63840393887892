import { FixedType, AlignType } from 'rc-table/lib/interface';

export const columns = [
  {
    title: 'pages.dashboard.resumeVisits.table.owner',
    dataIndex: 'owner',
    key: 'owner',
    sorter: false,
    filtered: false,
  },
  {
    title: 'pages.dashboard.resumeVisits.table.cpr',
    dataIndex: 'cpr_number',
    key: 'cpr_number',
    sorter: false,
    filtered: false,
  },
  {
    title: 'pages.dashboard.resumeVisits.table.number',
    dataIndex: 'number',
    key: 'number',
    sorter: false,
    filtered: false,
  },
  {
    title: 'pages.dashboard.resumeVisits.table.product',
    dataIndex: 'culture',
    key: 'culture',
    sorter: false,
    filtered: false,
  },
  {
    title: 'pages.dashboard.resumeVisits.table.region',
    dataIndex: 'region',
    key: 'region',
    sorter: false,
    filtered: false,
  },
  {
    title: 'pages.dashboard.resumeVisits.table.subsidiary',
    dataIndex: 'subsidiary',
    key: 'subsidiary',
    sorter: false,
    filtered: false,
  },
  {
    title: 'pages.dashboard.resumeVisits.table.risk',
    dataIndex: 'condition',
    key: 'condition',
    sorter: false,
    filtered: false,
    align: 'center' as AlignType,
  },
  {
    title: 'pages.dashboard.resumeVisits.table.planting',
    dataIndex: 'planting',
    key: 'planting',
    sorter: false,
    filtered: false,
  },
  {
    title: 'pages.dashboard.resumeVisits.table.development',
    dataIndex: 'development',
    key: 'development',
    sorter: false,
    filtered: false,
  },
  {
    title: 'pages.dashboard.resumeVisits.table.harvest',
    dataIndex: 'harvest',
    key: 'harvest',
    sorter: false,
    filtered: false,
  },
  {
    title: 'pages.dashboard.resumeVisits.table.actions',
    key: 'action',
    dataIndex: 'action',
    width: '90px',
    fixed: 'right' as FixedType,
    align: 'center' as AlignType,
  },
];

import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ShouldRender } from 'components/ShouldRender';
import { useAlertDialog } from 'components/ui/alert-dialog';
import { Button } from 'components/ui/button';
import { GroupForm } from 'components/ui/group-form';
import { randomUUID } from 'lib/utils';

import { SimpleAgriculturalPlanFormData } from './simple-agricultural-plan/simple-agricultural-plan.form';
import { SimpleAgriculturalPlanModal } from './simple-agricultural-plan/simple-agricultural-plan.modal';
import { SimpleAgriculturalPlanTable } from './simple-agricultural-plan/simple-agricultural-plan.table';
import { useRegisteredCustomerStore } from './stores/use-registered-customer-store';

export const RegisteredCustomerSimpleAgriculturalPlanSection = () => {
  const { t } = useTranslation();
  const form = useFormContext();
  const { alert } = useAlertDialog();

  const isViewing = useRegisteredCustomerStore(state => state.isViewing);
  const isModulePerson = useRegisteredCustomerStore(state => state.isModulePerson);
  const setIsSimpleAgriculturalPlanModalOpen = useRegisteredCustomerStore(
    state => state.setIsSimpleAgriculturalPlanModalOpen,
  );
  const simpleAgriculturalPlanToEdit = useRegisteredCustomerStore(state => state.simpleAgriculturalPlanToEdit);

  const handleAddSimpleAgriculturalPlan = (data: SimpleAgriculturalPlanFormData) => {
    const simpleAgriculturalPlans = form.getValues('simple_agricultural_plan') as SimpleAgriculturalPlanFormData[];

    form.setValue(
      'simple_agricultural_plan',
      [
        ...(simpleAgriculturalPlans ?? []),
        {
          ...data,
          id: `new-${randomUUID()}`,
        },
      ],
      {
        shouldTouch: true,
      },
    );
  };

  const handleEditSimpleAgriculturalPlan = (data: SimpleAgriculturalPlanFormData) => {
    const simpleAgriculturalPlans = form.getValues('simple_agricultural_plan') as SimpleAgriculturalPlanFormData[];
    const index = simpleAgriculturalPlans.findIndex(simpleAgriculturalPlan => simpleAgriculturalPlan.id === data.id);
    simpleAgriculturalPlans[index] = data;
    form.setValue('simple_agricultural_plan', simpleAgriculturalPlans, {
      shouldTouch: true,
    });
    alert({
      type: 'success',
      title: t('simple-agricultural-plan-updated'),
      description: '',
    });
  };

  return (
    <GroupForm title={t('simple-agricultural-plan')}>
      <ShouldRender condition={!isViewing && isModulePerson}>
        <div className="flex justify-end w-full">
          <Button type="button" variant="default" onClick={() => setIsSimpleAgriculturalPlanModalOpen(true)}>
            {t('add-plan')}
          </Button>
        </div>
      </ShouldRender>

      <div className="mt-6">
        <SimpleAgriculturalPlanTable />
      </div>

      <SimpleAgriculturalPlanModal
        onSuccess={simpleAgriculturalPlanToEdit ? handleEditSimpleAgriculturalPlan : handleAddSimpleAgriculturalPlan}
      />
    </GroupForm>
  );
};

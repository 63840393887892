import styled, { css } from 'styled-components';

const isTotalCard = css`
  background: ${({ theme: { colors } }) => colors.lightBlue};
  border-color: ${({ theme: { colors } }) => colors.info};
`;

const hasRestrictionCard = css`
  background: ${({ theme: { colors } }) => colors.lightRed};
  border-color: ${({ theme: { colors } }) => colors.danger};
`;

const hasNotRestrictionCard = css`
  background: ${({ theme: { colors } }) => colors.lightGreen};
  border-color: ${({ theme: { colors } }) => colors.success};
`;

export const Container = styled.div<{ isTotal: boolean; hasRestriction: boolean }>`
  border: 1px solid ${({ theme }) => theme.colors.white};
  ${({ isTotal, hasRestriction }) => {
    if (!isTotal) return isTotalCard;

    return hasRestriction ? hasRestrictionCard : hasNotRestrictionCard;
  }};

  padding: 20px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const Title = styled.div`
  font-weight: bold;
`;

export const Value = styled.div``;

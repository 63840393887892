import { useMutation } from '@tanstack/react-query';
import api from 'services/api/api';
import exceptionHandler from 'services/exceptions';
import { ResponseListData } from 'types/Api/ResponseListData';
import { RegisteredCustomerData } from 'types/CreditRequest/RegisteredCustomerData';

type Props = {
  personType: 'legal' | 'physical';
};

export const useFindRegisteredCustomerMutation = ({ personType }: Props) => {
  return useMutation({
    mutationFn: async (search: string) => {
      const response = await api.get<ResponseListData<RegisteredCustomerData>>(
        personType === 'legal' ? 'person/legal' : 'person/physical',
        {
          params: {
            search,
            page: 1,
            per_page: 1,
          },
        },
      );
      return response.data.data;
    },
    mutationKey: ['findRegisteredCustomer'],
    onError: err => {
      exceptionHandler(err);
    },
  });
};

import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ColumnDef } from '@tanstack/react-table';
import { DataTable } from 'components/ui/data-table';
import { SUSTAINABILITY_STATUS_CANCELLED } from 'configs/constants';
import { usePagination } from 'hooks/usePagination';
import { useGetSustentabilitiesQuery } from 'pages/Sustainability/api/use-get-sustentabilities-query';
import { getDecisionColor } from 'pages/Sustainability/utils';
import { Formatter } from 'utils/Formatter';

import { IconWithTooltip } from '../../../../components';
import { Can } from '../../../../hooks/ability';
import SustainabilityData from '../../../../types/Sustainability/SustainabilityData';
import { Filter } from './Filter';
import { TableProps } from './types';

export const Table = ({ handleEdit }: TableProps) => {
  const { t } = useTranslation();
  const { dispatchPagination, paginationConfig } = usePagination();

  const getSustainabilitiesQuery = useGetSustentabilitiesQuery({
    ...paginationConfig,
    dispatchPagination,
  });

  const handleFastSearch = (values = {}) => {
    dispatchPagination({
      type: 'SET_FILTER',
      payload: values,
    });
  };

  const getActions = (record: SustainabilityData) => (
    <>
      {record.sustainability_report_status?.id === SUSTAINABILITY_STATUS_CANCELLED ? (
        <IconWithTooltip
          action="view"
          title={t('pages.edocuments.contracttypes.table.view')}
          onClick={() => handleEdit(record.id)}
        />
      ) : (
        <Can I="sustainability.report.update" a="">
          <IconWithTooltip
            action="edit"
            title={t('pages.edocuments.contracttypes.table.edit')}
            onClick={() => handleEdit(record.id)}
          />
        </Can>
      )}
      <Can I="sustainability.report.store" a="">
        <IconWithTooltip
          action="clone"
          title={t('pages.sustainability.clone')}
          onClick={async () => {
            window.open(`/sustainability/request-report?clonedSustainabilityId=${record.id}`, '_blank');
          }}
          placement="right"
        />
      </Can>
    </>
  );

  const columns = useMemo(
    (): ColumnDef<any>[] => [
      {
        header: t('request-type'),
        accessorKey: 'sustainability_report_type',
        cell: ({ row }) => <span>{row.original.sustainability_report_type?.name ?? '-'}</span>,
      },
      {
        header: t('number'),
        accessorKey: 'number',
      },
      {
        header: t('created_at'),
        accessorKey: 'created_at',
        cell: ({ row }) => <span>{Formatter.date(row.original.created_at)}</span>,
      },
      {
        header: t('provider'),
        accessorKey: 'provider',
        cell: ({ row }) => <span className="whitespace-nowrap">{row.original.provider ?? '-'}</span>,
      },
      {
        header: t('cpf-cnpj'),
        accessorKey: 'doc_number',
        cell: ({ row }) => (
          <span className="whitespace-nowrap">
            {row.original.doc_number
              ? row.original.doc_number.includes('.')
                ? row.original.doc_number
                : row.original.doc_number?.length === 11
                ? Formatter.cpf(row.original.doc_number)
                : Formatter.cnpj(row.original.doc_number)
              : '-'}
          </span>
        ),
      },
      {
        header: t('commodity'),
        accessorKey: 'product',
        cell: ({ row }) => <span className="whitespace-nowrap">{row.original.product?.name ?? '-'}</span>,
      },
      {
        header: t('harvest'),
        accessorKey: 'harvest',
        cell: ({ row }) => <span>{row.original.harvest?.name ?? '-'}</span>,
      },
      {
        header: t('operation-type'),
        accessorKey: 'operation',
        cell: ({ row }) => <span>{row.original.operation?.name ?? '-'}</span>,
      },
      {
        header: t('start-date'),
        accessorKey: 'start_date',
        cell: ({ row }) => <span>{Formatter.date(row.original.start_date)}</span>,
      },
      {
        header: t('end-date'),
        accessorKey: 'end_date',
        cell: ({ row }) => <span>{Formatter.date(row.original.end_date)}</span>,
      },
      {
        header: t('status'),
        accessorKey: 'sustainability_report_status',
        cell: ({ row }) => (
          <span className="whitespace-nowrap">{row.original.sustainability_report_status?.name ?? '-'}</span>
        ),
      },
      {
        header: t('last-status-date'),
        accessorKey: 'last_history_change_date',
        cell: ({ row }) => <span>{Formatter.date(row.original.last_history_change_date)}</span>,
      },
      {
        header: t('decision'),
        accessorKey: 'sustainability_decision',
        cell: ({ row }) => (
          <span
            className="whitespace-nowrap"
            style={{ color: getDecisionColor(row.original.sustainability_decision?.id), fontWeight: 'bold' }}
          >
            {row.original.sustainability_decision?.name ?? t('inAnalysis').toUpperCase()}
          </span>
        ),
      },
      {
        header: t('actions'),
        accessorKey: 'actions',
        cell: ({ row }) => <span className="flex">{getActions(row.original)}</span>,
        enableSorting: false,
      },
    ],
    [],
  );
  return (
    <div className="h-full flex flex-col w-full">
      <Filter handleFastSearch={handleFastSearch} />

      <DataTable
        columns={columns}
        fixedHeader
        data={getSustainabilitiesQuery?.data?.data ?? []}
        withPagination
        onPaginationChange={newPagination => {
          dispatchPagination({
            payload: newPagination.pageSize,
            type: 'SET_PER_PAGE',
          });
          dispatchPagination({
            payload: newPagination.pageIndex,
            type: 'SET_CURRENT',
          });
        }}
        state={{
          pagination: {
            pageSize: paginationConfig.pageSize,
            pageIndex: paginationConfig.current,
          },
        }}
        onSortingChange={newSort => {
          if (typeof newSort === 'function') {
            const newSorting = newSort(
              paginationConfig.sort.map(sort => ({ id: sort.field, desc: sort.order === 'desc' })),
            );
            dispatchPagination({
              payload: newSorting.map(sort => ({
                field: sort.id,
                order: sort.desc ? 'desc' : 'asc',
              })),
              type: 'SET_SORT',
            });
          }
        }}
        isLoading={getSustainabilitiesQuery?.isLoading}
        isFetching={getSustainabilitiesQuery?.isFetching}
        pageCount={getSustainabilitiesQuery?.data?.last_page ?? 0}
        defaultColumn={{
          enableSorting: true,
        }}
      />
    </div>
  );
};

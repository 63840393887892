import React from 'react';

import { handleRequest } from 'services/api/api';
import dashboardApi from 'services/api/dashboard';

export const useExposurePrepay = () => {
  const [isFetchingChart, setIsFetchingChart] = React.useState(false);
  const [isFetchingTable, setIsFetchingTable] = React.useState(false);
  const [isFetchingProviders, setIsFetchingProviders] = React.useState(false);

  const getChart = async (params = {}) => {
    const response = handleRequest(() => dashboardApi.prepayExposure.chart(params), setIsFetchingChart);
    return response;
  };

  const getProviders = async (params = {}) => {
    const response = await handleRequest(() => dashboardApi.prepayExposure.providers(params), setIsFetchingProviders);
    return response;
  };

  const getTable = async (params = {}) => {
    const response = await handleRequest(() => dashboardApi.prepayExposure.table(params), setIsFetchingTable);
    return response;
  };

  return { getChart, isFetchingChart, getTable, isFetchingTable, getProviders, isFetchingProviders };
};

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { useAlertDialog } from 'components/ui/alert-dialog';
import { Formatter } from 'utils/Formatter';

type WarnUserIsRegisteredProps = {
  personType: 'physical' | 'legal';
  userId: string;
  document: string;
};

export const useWarnUserIsRegistered = () => {
  const navigate = useNavigate();
  const { alert } = useAlertDialog();
  const { t } = useTranslation();

  const warnUserIsRegistered = ({ personType, userId, document }: WarnUserIsRegisteredProps) => {
    alert({
      type: 'warning',
      title: t('pages.registered-customers.form.pf.store.user_already_exists_title'),
      subTitle: t('pages.registered-customers.form.pf.store.user_already_exists', {
        documentType: personType === 'physical' ? t('cpf') : t('cnpj'),
        document: personType === 'physical' ? Formatter.cpf(document) : Formatter.cnpj(document),
      }),
      onOk: () => navigate(`/registered-customers/${personType}/edit/${userId}`),
    });
  };

  return {
    warnUserIsRegistered,
  };
};

import styled from 'styled-components';

export const Container = styled.div<{ status: 'normal' | 'declined' | 'autoResponded' }>`
  background: ${({ theme: { colors }, status }) => {
    if (status === 'declined') return colors.lightRed;
    if (status === 'autoResponded') return colors.lightOrange;
    return colors.white;
  }};
  border-radius: 10px;
  padding: 20px 25px;
  width: 100%;

  .ant-statistic-title {
    margin-bottom: 0;
    font-size: 12px;
    font-weight: bold;
  }
  .ant-statistic-content-value {
    word-wrap: break-word;
    display: initial;
  }
`;

export const SignedMessage = styled.div`
  color: ${({ theme: { colors } }) => colors.success};
  font-size: 12px;
`;

export const ResendWrapper = styled.span`
  display: flex;
  gap: 5px;
  cursor: pointer;

  span {
    font-size: 0.1rem;
  }
`;

import { create } from 'zustand';

export type ContractListStore = {
  total: number;
  setTotal: (total: number) => void;
};

export const useContractListStore = create<ContractListStore>()(set => ({
  total: 0,
  setTotal: (total: number) => set({ total }),
}));

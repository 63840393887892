import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FaPlus, FaMinus, FaSearch } from 'react-icons/fa';

import { Col, Form, Spin, Modal, Input as AInput, Tree } from 'antd';
import { Store } from 'antd/lib/form/interface';
import { TablePaginationConfig, TableProps } from 'antd/lib/table';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';

import HarvestData from '../../../@types/data/HarvestData';
import PaginationData from '../../../@types/data/PaginationData';
import { ResponseListData } from '../../../@types/data/ResponseData';
import DTOErrorReponse from '../../../@types/dtos/DTOErrorReponse';
import DTORole from '../../../@types/dtos/user/DTORole';
import { Breadcrumb, Button, Input, ModalStatus, FormItem, Row, IconWithTooltip } from '../../../components';
import { TableData } from '../../../compositions';
import { Can } from '../../../hooks/ability';
import { useAuth } from '../../../hooks/auth';
import { usePage } from '../../../hooks/page';
import adminApi from '../../../services/api/admin';
import HarvestForm from '../../Registers/Harvest/HarvestForm';
import columns from './columns';
import { SContainer, SHeader, STitlePage, SFilterContainer, SFormContainer, SFormButtons } from './styles';

const Role: React.FC = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const { updateUserData } = useAuth();
  const { alertStatus } = usePage();

  const [permissionTree, setPermissionTree] = useState([]);
  const [permissionData, setPermissionData] = useState([]);

  const [checkedKeys, setCheckedKeys] = useState([]);

  const [data, setData] = useState([]);
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [paginationConfig, setPaginationConfig] = useState<TablePaginationConfig>({
    current: 1,
    total: 1,
    pageSize: 20,
  });

  const [visibilityForm, setVisibilityForm] = useState(false);

  const [permissionVisible, setPermissionVisible] = useState(false);

  const [roleId, setRoleId] = useState('');
  const [selectedPermissions, setSelectedPermissions] = useState([]);

  const [loadingModal, setLoadingModal] = useState(false);

  const [searchValue, setSearchValue] = useState('');
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [autoExpandParent, setAutoExpandParent] = useState(false);

  const handleSuccess = (messageSuccess: string) =>
    ModalStatus({
      type: 'success',
      title: t('modal.success'),
      subTitle: messageSuccess,
    });

  const updateData = (row: Store) => {
    let dataUpdated: Array<Store> = data;
    if (data.length > 0) {
      const rowIndex = data.findIndex((item: Store) => row.id === item.id);
      // Se encontrar o item remove e adiciona ele atualizado na mesma posição
      if (rowIndex > -1) {
        dataUpdated.splice(rowIndex, 1, row);
      } else {
        if (dataUpdated.length === paginationConfig.pageSize) {
          // Se não encontrar, remove o último (por conta da paginação) e
          // adiciona o item na primeira posição
          dataUpdated.splice(-1, 1);
        }
        dataUpdated = [row, ...dataUpdated];
      }
    } else {
      dataUpdated.push(row);
    }

    setData(dataUpdated);
  };

  const loadTableData = (values: Partial<HarvestForm & PaginationData<HarvestData>>) => {
    setLoading(true);
    const dataSend = {
      params: {
        per_page: paginationConfig.pageSize,
        ...values,
      },
    };

    adminApi.roles
      .get(dataSend)
      .then((response: ResponseListData<HarvestData>) => {
        const result = response.data.data;
        setData(result.data);
        setPaginationConfig(paginationConfigState => ({
          ...paginationConfigState,
          current: result.current_page,
          total: result.total,
        }));
      })
      .catch((err: DTOErrorReponse) => alertStatus(err, 'error'))
      .finally(() => setLoading(false));
  };

  const handleDisplayForm = () => {
    setVisibilityForm(!visibilityForm);
    setEditing(false);
    form.resetFields();
  };

  const handleHideForm = () => {
    setVisibilityForm(false);
  };

  const handleRegister = () => {
    setLoading(true);
    form
      .validateFields()
      .then(() => {
        const formValues = form.getFieldsValue();
        adminApi.roles
          .store({
            ...formValues,
          })
          .then(response => {
            const { data: role } = response.data;
            updateData(role);
            handleSuccess(response.data.message);
            handleHideForm();
            setLoading(false);
            form.resetFields();

            updateUserData();
          })
          .catch((err: DTOErrorReponse) => {
            alertStatus(err, 'error');
            setLoading(false);
          });
      })
      .catch((err: DTOErrorReponse) => {
        alertStatus(err, 'error');
        setLoading(false);
      });
  };

  const handleEdit = () => {
    setLoading(true);
    form
      .validateFields()
      .then(() => {
        const formValues = form.getFieldsValue();

        adminApi.roles
          .update(
            formValues.id,
            {
              ...formValues,
            },
            true,
          )
          .then(response => {
            const { data: role } = response.data;
            updateData(role);
            handleSuccess(response.data.message);
            handleHideForm();
            setLoading(false);
            form.resetFields();
            updateUserData();
          })
          .catch((err: DTOErrorReponse) => {
            alertStatus(err, 'error');
            setLoading(false);
          });
      })
      .catch((err: DTOErrorReponse) => {
        alertStatus(err, 'error');
        setLoading(false);
      });
  };

  function onCheck(checked: any) {
    setCheckedKeys(checked);

    setSelectedPermissions(checked.map((item: string) => item.replace(/-/g, '.')));
  }

  const loadPermissions = useCallback(() => {
    adminApi.permissions
      .getTree()
      .then(response => {
        setPermissionTree(response.data.data.tree);
        setPermissionData(response.data.data.permissions);
      })
      .catch((err: DTOErrorReponse) => alertStatus(err, 'error'))
      .finally(() => setLoadingModal(false));
    // eslint-disable-next-line
  }, []);

  function showTreeModal(record: DTORole) {
    setRoleId(record.id);
    setPermissionVisible(true);

    const checked = record.permissions.map(item => item.name.replace(/\./g, '-'));

    setCheckedKeys(checked);
    setSelectedPermissions(checked.map(item => item.replace(/-/g, '.')));
  }

  const handleRemoveChange = (record: any) => {
    ModalStatus({
      type: 'delete',
      title: t('pages.admin.role.delete.title'),
      subTitle: t('pages.admin.role.delete.subtitle'),
      cancelText: t('pages.admin.role.delete.cancel'),
      okText: t('pages.admin.role.delete.confirm'),
      onOk: () => {
        adminApi.roles
          .delete(record.id, {
            params: {},
          })
          .then(response => {
            handleSuccess(response.data.message);
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            handleTableChange({ current: 1 }, { active: [1] }, {}, null);
          })
          .catch((err: DTOErrorReponse) => alertStatus(err, 'error'));
      },
    });
  };

  // Table DATA Config: BEGIN
  const getActions = () => ({
    render: (active: number, record: DTORole) => (
      <>
        <Can I="admin.role.destroy" a="">
          <IconWithTooltip
            action="destroy"
            title={t('pages.admin.role.table.row.removeIcon')}
            onClick={() => handleRemoveChange(record)}
          />
        </Can>
        <Can I="admin.role.update" a="">
          <IconWithTooltip
            action="edit"
            title={t('pages.admin.role.table.row.editIcon')}
            onClick={() => {
              form.setFieldsValue({
                ...record,
              });

              form.scrollToField('name', {
                scrollMode: 'always',
                block: 'start',
                behavior: actions =>
                  actions.forEach(({ el, top }) => {
                    el.scrollTop = top - 280;
                  }),
              });

              setVisibilityForm(true);
              setEditing(true);
              setLoading(false);
            }}
          />
        </Can>
        <IconWithTooltip
          action="permission"
          title={t('pages.admin.role.table.row.permissionIcon')}
          onClick={() => showTreeModal(record)}
        />
      </>
    ),
  });

  const [searchForm] = Form.useForm();
  const tableConfig = {
    search: '',
    filtered: {},
    sorter: {},
    getActions,
  };

  const [tableDataConfig, setTableDataConfig] = useState(tableConfig);

  const handleFastSearch = () => {
    const value = searchForm.getFieldsValue();
    setTableDataConfig(tableDataConfigState => ({
      ...tableDataConfigState,
      search: value.search,
    }));
    loadTableData({
      name: value.search,
    });
  };

  const tableDataClearAllFilters = () => {
    searchForm.resetFields();
    setTableDataConfig(tableDataConfigState => ({
      ...tableDataConfigState,
      filtered: {},
      search: '',
      sorter: {},
    }));
    loadTableData({ page: 1 });
  };

  const handleTableChange: TableProps<any>['onChange'] = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<any> | SorterResult<any>[],
  ) => {
    setLoading(true);
    setTableDataConfig(tableDataConfigState => ({
      ...tableDataConfigState,
      filtered: filters,
      sorter,
    }));

    let direction = '';
    let search = {};
    let sort = '';

    if (sorter) {
      const { field, order } = sorter as SorterResult<any>;

      if (order === 'ascend') direction = 'asc';
      if (order === 'descend') direction = 'desc';
      sort = field?.toString();
    }

    if (filters) {
      const { name } = filters;

      search = {
        name: name !== null && name !== undefined ? name[0].toString() : '',
      };
    }

    setPaginationConfig(paginationConfigState => ({
      ...paginationConfigState,
      pageSize: pagination.pageSize,
      current: pagination.current,
    }));

    loadTableData({
      page: pagination.current,
      sort,
      direction,
      ...search,
    });
  };

  // Table DATA Config: END

  function handlePermissions() {
    setLoadingModal(true);
    adminApi.roles
      .update(roleId, {
        permissions: selectedPermissions,
      })
      .then(response => {
        handleSuccess(response.data.message);
        handleTableChange({ ...paginationConfig }, { active: [1] }, {}, null);
      })
      .catch((err: DTOErrorReponse) => alertStatus(err, 'error'))
      .finally(() => {
        setPermissionVisible(false);
        setLoadingModal(false);

        updateUserData();
      });
  }

  function getParentKey(key: any, tree: any): any {
    let parentKey;
    for (let i = 0; i < tree.length; i += 1) {
      const node = tree[i];
      if (node.children) {
        if (node.children.some((item: any) => item.key === key)) {
          parentKey = node.key;
        } else if (getParentKey(key, node.children)) {
          parentKey = getParentKey(key, node.children);
        }
      }
    }
    return parentKey;
  }

  function onChange(e: any) {
    const { value } = e.target;
    const expanded = permissionData
      .map((item: any) => {
        if (item.title.indexOf(value) > -1) {
          return getParentKey(item.key, permissionTree);
        }
        return null;
      })
      .filter((item, i, self) => item && self.indexOf(item) === i);

    setExpandedKeys(expanded);
    setSearchValue(value);
    setAutoExpandParent(true);
  }

  function loop(dataValue: any) {
    return dataValue.map((item: any) => {
      const index = item.title.indexOf(searchValue.trim());
      const beforeStr = item.title.substr(0, index);
      const afterStr = item.title.substr(index + searchValue.trim().length);
      const title =
        index > -1 ? (
          <span>
            {beforeStr}
            <span style={{ background: 'yellow' }}>{searchValue}</span>
            {afterStr}
          </span>
        ) : (
          <span>{item.title}</span>
        );
      if (item.children) {
        return { title, key: item.key, children: loop(item.children) };
      }

      return {
        title,
        key: item.key,
      };
    });
  }

  useEffect(() => {
    loadPermissions();
    handleTableChange({ current: 1, pageSize: paginationConfig.pageSize }, { active: [1] }, {}, null);
    // eslint-disable-next-line
  }, [loadPermissions]);

  function onExpand(keys: any) {
    setExpandedKeys(keys);
    setAutoExpandParent(false);
  }

  return (
    <>
      <Breadcrumb items={[{ title: t('breadcrumb.admin') }, { title: t('breadcrumb.roles'), to: '/admin/role' }]} />

      <SContainer>
        <SHeader>
          <STitlePage>{t('pages.admin.role.title_page')}</STitlePage>

          <Can I="admin.role.store" a="">
            <Button status="primary" icon={visibilityForm ? <FaMinus /> : <FaPlus />} onClick={handleDisplayForm}>
              {t('pages.admin.role.buttonAdd')}
            </Button>
          </Can>
        </SHeader>

        <SFormContainer visible={visibilityForm}>
          <Spin spinning={loading}>
            <h2>{t('pages.admin.role.registerTitle')}</h2>
            <Form form={form}>
              <FormItem name="id" style={{ display: 'none' }}>
                <Input />
              </FormItem>
              <Row gutter={[26, 26]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                  <FormItem
                    label={t('pages.admin.role.form.name')}
                    name="name"
                    rules={[{ required: true, min: 3, max: 255 }]}
                  >
                    <Input type="text" />
                  </FormItem>
                </Col>
              </Row>
              <SFormButtons>
                <Button status="secondary" htmlType="reset" onClick={handleHideForm}>
                  {t('pages.admin.role.buttonCancel')}
                </Button>
                {editing && (
                  <Button status="primary" htmlType="submit" onClick={handleEdit}>
                    {t('pages.admin.role.buttonEdit')}
                  </Button>
                )}
                {!editing && (
                  <Button status="primary" htmlType="submit" onClick={handleRegister}>
                    {t('pages.admin.role.buttonRegister')}
                  </Button>
                )}
              </SFormButtons>
            </Form>
          </Spin>
        </SFormContainer>

        <SFilterContainer>
          <Form
            form={searchForm}
            name="filter-role"
            className="form-secondary form-filters grid-filters"
            onFinish={handleFastSearch}
          >
            <div className="filter-search">
              <FormItem name="search" label={t('pages.admin.role.search')}>
                <Input />
              </FormItem>
            </div>
            <div className="filter-button">
              <FormItem label="" className="form-item-without-label">
                <Button status="primary" text="uppercase" htmlType="submit">
                  <FaSearch /> {t('form.actions.search')}
                </Button>
              </FormItem>
            </div>
            <div className="filter-clear">
              <Button status="secondary" size="middle" onClick={tableDataClearAllFilters}>
                {t('pages.admin.role.buttonClearFilter')}
              </Button>
            </div>
          </Form>
        </SFilterContainer>

        <TableData
          rowKey="id"
          columns={columns}
          dataSource={data}
          loading={loading}
          onChange={handleTableChange}
          pagination={paginationConfig}
          tableConfig={tableDataConfig}
        />

        <Modal
          confirmLoading={loadingModal}
          style={{ top: 20 }}
          title={t('pages.admin.role.modal.title')}
          visible={permissionVisible}
          onOk={() => handlePermissions()}
          onCancel={() => setPermissionVisible(false)}
        >
          <Spin spinning={loadingModal}>
            <Form>
              <FormItem>
                <AInput.Search allowClear placeholder={t('pages.admin.role.search')} onChange={onChange} />
              </FormItem>
              <FormItem>
                <Tree
                  checkable
                  defaultExpandAll
                  checkedKeys={checkedKeys}
                  onExpand={onExpand}
                  expandedKeys={expandedKeys}
                  autoExpandParent={autoExpandParent}
                  onCheck={onCheck}
                  treeData={loop(permissionTree)}
                />
              </FormItem>
            </Form>
          </Spin>
        </Modal>
      </SContainer>
    </>
  );
};

export default Role;

import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormItem, Select } from 'components';
import { useCreditRequestContext } from 'context/CreditRequestContext';
import { useCache } from 'hooks/cache';
import { useLoadSubsidiaries } from 'pages/CreditRequest/hooks/useLoadSubsidiaries';
import { useCreditRequestStore } from 'pages/CreditRequest/store/useCreditRequestStore';

export const RegionIdSelect = () => {
  const { t } = useTranslation();
  const cache = useCache();
  const { setIsLoadingSubsidiaries } = useCreditRequestContext();
  const { loadSubsidiaries } = useLoadSubsidiaries();

  const setIsBlocking = useCreditRequestStore(state => state.setIsBlocking);
  const contractFieldsValue = useCreditRequestStore(state => state.contractFieldsValue);
  const pageStatus = useCreditRequestStore(state => state.pageStatus);

  return (
    <FormItem label={t('pages.credit-request.form.region_id')} name="region_id" rules={[{ required: true }]}>
      <Select
        options={cache.getCreditOptions('region')}
        disabled={pageStatus.viewing || (contractFieldsValue && contractFieldsValue.region_id !== undefined)}
        onChange={async (value: any) => {
          if (value) {
            setIsBlocking(true);
            setIsLoadingSubsidiaries(true);
            await loadSubsidiaries(value);
            setIsLoadingSubsidiaries(false);
          }
        }}
      />
    </FormItem>
  );
};

import React from 'react';

import { handleRequest } from 'services/api/api';
import creditApi from 'services/api/credit';

interface GetStorageParams {
  params?: {
    city_external_id: string;
    fill: string;
    [key: string]: unknown;
  };
  [key: string]: unknown;
}

interface GetCitiesParams {
  params?: {
    state_external_id: string;
    fill: string;
    [key: string]: unknown;
  };
  [key: string]: unknown;
}

interface GetStatesParams {
  params?: {
    fill: string;
    [key: string]: unknown;
  };
  [key: string]: unknown;
}

export const useCustody = () => {
  const [isFetchingStates, setIsFetchingStates] = React.useState(false);
  const [isFetchingCities, setIsFetchingCities] = React.useState(false);
  const [isFetchingStorages, setIsFetchingStorages] = React.useState(false);

  const getStates = (params?: GetStatesParams) => {
    return handleRequest(() => creditApi.custody.states(params), setIsFetchingStates);
  };

  const getCities = (params?: GetCitiesParams) => {
    return handleRequest(() => creditApi.custody.cities(params), setIsFetchingCities);
  };

  const getStorages = async (params?: GetStorageParams) => {
    const response = await handleRequest(() => creditApi.custody.warehouses(params), setIsFetchingStorages);
    return response;
  };

  return { getStates, getCities, getStorages, isFetchingStates, isFetchingCities, isFetchingStorages };
};

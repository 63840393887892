import { useState } from 'react';

import api, { handleRequest } from 'services/api/api';

export const useUpdateDatasulSyncStatus = () => {
  const [isUpdating, setIsUpdating] = useState(false);

  const updateDatasulSyncStatus = async (contractId: string) => {
    const response = await handleRequest(() => api.put(`contract/${contractId}/force-remote-update`), setIsUpdating);
    return response;
  };

  return {
    updateDatasulSyncStatus,
    isUpdating,
  };
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaEye, FaSync } from 'react-icons/fa';

import { Button } from 'components';
import { Can } from 'hooks/ability';

import { DraftRadio } from '../DraftRadio';
// import { ChangeButton } from './styles';
import { DraftFormatRadioProps } from './types';

export const DraftFormatRadio = ({
  onChangeButtonClick,
  isSelected,
  showChangeButton = false,
  onShowButtonClick = undefined,
  ...rest
}: DraftFormatRadioProps) => {
  const { t } = useTranslation();
  return (
    <DraftRadio onChangeButtonClick={onChangeButtonClick} isSelected={isSelected} {...rest}>
      {isSelected && showChangeButton && (
        <Button
          onClick={onChangeButtonClick}
          style={{ padding: 0 }}
          title={t('pages.edocuments.emission.draftFormatRadio.change')}
        >
          {/* {t('pages.edocuments.emission.draftFormatRadio.change')} */}
          <span style={{ display: 'flex', padding: '0px 5px' }}>
            <FaSync size={10} />
          </span>
        </Button>
      )}
      {onShowButtonClick ? (
        <Can I="contract.type.index" a="">
          <Button
            onClick={onShowButtonClick}
            style={{ padding: 0 }}
            title={t('pages.edocuments.emission.draftFormatRadio.show')}
          >
            {/* {t('pages.edocuments.emission.draftFormatRadio.change')} */}
            <span style={{ display: 'flex', padding: '0px 5px' }}>
              <FaEye size={10} />
            </span>
          </Button>
        </Can>
      ) : null}
    </DraftRadio>
  );
};

import React, { memo } from 'react';

import { Container } from './styles';
import ModalInfoItemProps from './types';

const ModalInfoItem: React.FC<ModalInfoItemProps> = ({ title, description }) => {
  return (
    <Container>
      <strong>{title}</strong>
      <p>{description}</p>
    </Container>
  );
};

export default memo(ModalInfoItem);

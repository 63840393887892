import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormItem, Select } from 'components';
import { useCreditRequestContext } from 'context/CreditRequestContext';
import { useCreditRequestStore } from 'pages/CreditRequest/store/useCreditRequestStore';

interface Props {
  commodities: any[];
}

export const CustodiaStorageCommodityDivisionIdSelect = ({ commodities }: Props) => {
  const { canEditCustodyFields, creditForm } = useCreditRequestContext();
  const { t } = useTranslation();

  const custodyStoreUnitCommodities = useCreditRequestStore(state => state.custodyStoreUnitCommodities);
  const creditRequestId = useCreditRequestStore(state => state.creditRequestId);

  return (
    <FormItem
      label={t('pages.credit-request.form.custody_commodity_id')}
      name="custodia_storage_commodity_division_id"
      rules={[{ required: true }]}
      validateTrigger="onSubmit"
    >
      <Select
        options={
          creditRequestId
            ? custodyStoreUnitCommodities.map(commodity => ({
                ...commodity,
                key: commodity.id,
                value: commodity.id,
                label: commodity.name,
              })) ?? []
            : commodities.map(commodity => ({
                ...commodity,
                key: commodity.id,
                value: commodity.id,
                label: commodity.name,
              }))
        }
        onChange={(_, option: any) => {
          creditForm.setFieldsValue({ custodia_approval_status: option?.pivot?.approval_status });
        }}
        listHeight={250}
        disabled={!canEditCustodyFields || (!custodyStoreUnitCommodities.length && !commodities.length)}
      />
    </FormItem>
  );
};

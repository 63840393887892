import React from 'react';

import { SelectProps } from 'antd';
import Select from 'components/Select';
import { useCache } from 'hooks/cache';

export const SubsidiarySelect = ({
  regionId,
  ...props
}: Omit<
  SelectProps<any> & {
    regionId?: string;
  },
  'options'
>) => {
  const cache = useCache();

  return (
    <Select
      showSearch
      options={cache.getCreditOptions('subsidiary').filter(s => (regionId ? s.region_id === regionId : true))}
      {...props}
    />
  );
};

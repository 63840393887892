import moment from 'moment';
import { formatValuesToFormData } from 'services/form';
import { DraftFormData } from 'types/CreditRequest/forms/DraftFormData';
import { FileUtils } from 'utils/File';

const toApiFormData = async (values: DraftFormData, creditRequestId: string, isPrepare?: boolean) => {
  const formData = formatValuesToFormData(
    values,
    new FormData(),
    [],
    ['subscribers', 'comments', 'people', 'files_to_sign', 'files_to_sign_documents'],
  );

  const filesToSign =
    values.files_to_sign?.length && !isPrepare
      ? await Promise.all(
          values.files_to_sign?.map(async file => {
            const base64 = await FileUtils.getBase64(file);
            return base64;
          }),
        )
      : [];

  const filesSigned =
    values.files_signed?.length && !isPrepare
      ? await Promise.all(
          values.files_signed?.map(async file => {
            const base64 = await FileUtils.getBase64(file);
            return base64;
          }),
        )
      : [];

  values.comments
    .filter(comment => comment.isNew)
    .forEach((recordComment, commentIndex) => {
      formData.append(`comments[${commentIndex}][comment]`, recordComment.comment ?? '');
    });

  if (!isPrepare) {
    values.files_to_sign.forEach((doc, i: number) => {
      const { type, name, type_id } = doc;
      formData.append(`documents[${i}][image]`, filesToSign[i] ?? '');
      formData.append(`documents[${i}][mime]`, type ?? '');
      formData.append(`documents[${i}][name]`, name ?? '');
      formData.append(`documents[${i}][original]`, FileUtils.getOriginalName(doc.name) ?? '');
      if (type_id) formData.append(`documents[${i}][type_id]`, type_id);
    });

    values.files_signed.forEach((doc, i: any) => {
      const { type, name, type_id } = doc;
      formData.append(`documents_certified[${i}][image]`, filesSigned[i] ?? '');
      formData.append(`documents_certified[${i}][mime]`, type ?? '');
      formData.append(`documents_certified[${i}][name]`, name ?? '');
      formData.append(`documents_certified[${i}][original]`, FileUtils.getOriginalName(doc.name) ?? '');
      if (type_id) formData.append(`documents_certified[${i}][type_id]`, type_id);
    });
  } else {
    values.files_to_sign_documents.forEach((doc, i: any) => {
      const { name, id, mime } = doc;
      formData.append(`documents[${i}][image]`, '');
      formData.append(`documents[${i}][id]`, id);
      formData.append(`documents[${i}][mime]`, mime ?? '');
      formData.append(`documents[${i}][name]`, name ?? '');
      formData.append(`documents[${i}][original]`, FileUtils.getOriginalName(doc.original, true) ?? '');
    });

    values.files_signed_documents.forEach((doc, i: any) => {
      const { mime, id, name } = doc;
      formData.append(`documents_certified[${i}][image]`, '');
      formData.append(`documents_certified[${i}][id]`, id);
      formData.append(`documents_certified[${i}][mime]`, mime ?? '');
      formData.append(`documents_certified[${i}][name]`, name ?? '');
      formData.append(`documents_certified[${i}][original]`, FileUtils.getOriginalName(doc.original, true) ?? '');
    });
  }

  values.subscribers.forEach((subscriber, subscriberIndex) => {
    formData.append(
      `recipients[${subscriberIndex}][dt_sign]`,
      !subscriber.dt_sign || !moment(subscriber.dt_sign).isValid()
        ? ''
        : moment(subscriber.dt_sign).format('YYYY-MM-DD'),
    );
    formData.append(`recipients[${subscriberIndex}][email]`, subscriber.email ?? '');
    formData.append(`recipients[${subscriberIndex}][id]`, subscriber.id?.includes('new-') ? '' : subscriber.id ?? '');
    formData.append(`recipients[${subscriberIndex}][name]`, subscriber.name ?? '');
    formData.append(`recipients[${subscriberIndex}][recipient_type_id]`, subscriber.recipient_type_id ?? '');
    formData.append(`recipients[${subscriberIndex}][routing_order]`, subscriber.routing_order?.toString() ?? '');
    formData.append(`recipients[${subscriberIndex}][signature_type_id]`, subscriber.signature_type_id ?? '');
    formData.append(`recipients[${subscriberIndex}][sms]`, subscriber.sms?.replaceAll(/[^+0-9]/g, '') ?? '');
    formData.append(`recipients[${subscriberIndex}][value]`, subscriber.value ?? '');
    formData.append(`recipients[${subscriberIndex}][is_resend]`, subscriber.is_resend?.toString() ?? '');
  });
  formData.append('credit_request_id', creditRequestId);

  return formData;
};

export const DraftFormConverter = {
  toApiFormData,
};

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useTable, TableData, TableTranslation } from '@agrodatabr/agrodataui';
import PermissionedComponent from 'components/PermissionedComponent';

import { IconWithTooltip, Button } from '../../../../components';
import { Title } from '../../styles';
import { customColumnsTimeline } from '../../utils';
import { Report } from '../Report';
import { StepsTimeline } from '../Steps';
import { columnsTimeline, columnsTimelineManagement, columnsTimelinePrepay } from './columnsTimeline';
import { summary } from './summary';
import { TableSearch } from './TableSearch';
import { PropsTimeline } from './types';

export const TableTimeline = ({
  searchFilters,
  setSearchFilters,
  handleChangeTableTimeline,
  isFetchingTimeline,
  searchForm,
  management = false,
  warranty = false,
  prepay = false,
  title,
  getReport,
  generateExport,
  listReportPermissions = [],
  generateReportPermissions = [],
}: PropsTimeline) => {
  const tableController = useTable();
  const { t } = useTranslation();
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [exportingGeneral, setExportingGeneral] = useState(false);

  const onExpand = (expand: any, values: any) => {
    if (expand) setExpandedRowKeys([values.id]);
    else setExpandedRowKeys([]);
  };

  const getActions = () => ({
    render: (active: number, record: any) => (
      <>
        <Link to={`/credit-request/view/${record.id}`} target="_blank">
          <IconWithTooltip action="view" title={t('pages.dashboard.timeline.viewAction')} />
        </Link>
      </>
    ),
  });

  const getSummary = (pageData: any) => {
    return summary(pageData, management, prepay);
  };

  const getColumn = () => {
    if (management) return columnsTimelineManagement;
    if (prepay) return columnsTimelinePrepay;
    return columnsTimeline;
  };

  const getPlaceholder = () => {
    if (prepay) return t('pages.dashboard.search.placeholderPrepay');
    return t('pages.dashboard.search.placeholder');
  };

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Title>{title}</Title>
        <Button
          status="primary"
          onClick={() => {
            setExportingGeneral(true);
          }}
          permission={[...listReportPermissions, ...generateReportPermissions]}
        >
          {t('pages.sustainability.form.buttonExport')}
        </Button>
      </div>
      <TableSearch
        setSearchFilters={setSearchFilters}
        searchForm={searchForm}
        placeholder={getPlaceholder()}
        warranty={warranty}
      />
      <TableData
        searchRequest={handleChangeTableTimeline}
        searchFilters={searchFilters}
        customColumns={customColumnsTimeline}
        columns={getColumn()}
        width="100%"
        pagination={{ position: ['bottomCenter'] }}
        controller={tableController}
        translation={{ pagination: { totalOfRegisters: t('pagination.totalOfRegisters') } } as TableTranslation}
        expandable={{
          expandedRowRender: (record: any) => {
            return (
              <PermissionedComponent permission="credit.request.history.all">
                <StepsTimeline creditRequestId={record.id} />
              </PermissionedComponent>
            );
          },
        }}
        expandedRowKeys={expandedRowKeys}
        onExpand={onExpand}
        scroll={{ x: 1500 }}
        rowKey="id"
        loading={isFetchingTimeline}
        summary={getSummary}
        tableConfig={{
          getActions,
        }}
      />
      <Report
        searchFilters={searchFilters}
        exportingGeneral={exportingGeneral}
        setExportingGeneral={setExportingGeneral}
        getReport={getReport}
        generateExport={generateExport}
      />
    </div>
  );
};

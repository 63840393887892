import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaTrashAlt } from 'react-icons/fa';

import { Comment, Spin } from 'antd';
import { Button } from 'components/ui/button';
import { ContractCommentData } from 'types/Contract/ContractCommentData';

import { ModalStatus } from '../../../../../../../components';
import { useAuth } from '../../../../../../../hooks/auth';
import { useContract } from '../../../../../../../hooks/fetch/useContract';
import { Formatter } from '../../../../../../../utils/Formatter';
import { useContractContext } from '../../../../../context/ContractContext';
import { Container } from './styles';

interface Props {
  comments: Array<ContractCommentData>;
}

export const CommentList = ({ comments }: Props) => {
  const { user } = useAuth();
  const { removeComment, isRemovingComment } = useContract();
  const { updateContract, isSelectedDocumentMain } = useContractContext();
  const { i18n } = useTranslation();

  const handleRemoveClick = (commentId: string) => {
    ModalStatus({
      type: 'confirm',
      title: 'Atenção',
      subTitle: 'Tem certeza que deseja excluir esse comentário?',
      onOk: async () => {
        const response = await removeComment(commentId);
        if (response) updateContract(response, isSelectedDocumentMain);
      },
    });
  };

  const checkIfUserCanSeeActions = (userId: string) => user.id === userId;

  const getActions = (comment: ContractCommentData) =>
    checkIfUserCanSeeActions(comment.user_id) && [
      <Button
        variant="link"
        permissions="contract.comment.destroy"
        onClick={() => handleRemoveClick(comment.id)}
        className="text-red-600 text-xs p-0 gap-1"
        hideOnUnauthorized
      >
        <FaTrashAlt />
        Excluir
      </Button>,
    ];

  return (
    <Spin spinning={isRemovingComment}>
      <Container>
        {comments.map(comment => (
          <span key={comment.id}>
            <Comment
              author={comment.user.name}
              content={
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <strong>{JSON.parse(comment.type.translations)[i18n.language].title}</strong>
                  {comment.comment ? <i style={{ fontWeight: 'bold' }}>{comment.comment}</i> : ''}
                </div>
              }
              datetime={Formatter.datetime(comment.created_at)}
              actions={getActions(comment)}
            />
          </span>
        ))}
      </Container>
    </Spin>
  );
};

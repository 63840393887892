import React from 'react';

import { Container } from './styles';
import CellBorderColorProps from './types';

const CellBorderColor: React.FC<CellBorderColorProps> = ({ children, color }) => {
  return (
    <Container color={color} className="cell-custom-border-color">
      {children}
    </Container>
  );
};

export default CellBorderColor;

import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormItem } from 'components/ui/form-item';
import { GroupForm } from 'components/ui/group-form';
import { Upload } from 'components/ui/upload';

import { DocumentsToCollectAttachmentTable } from './documents-collect-attachments.table';

export const DocumentsToCollectAttachments = () => {
  const { t } = useTranslation();

  return (
    <GroupForm
      title={t(
        'pages.credit-request.formalization-guarantees.pre-registration-docs.modalPackage.documents-to-collect.attachments',
      )}
    >
      <FormItem name="documents_files">
        <Upload
          permissions={['credit.request.package.upload']}
          allowedExtensions={[
            '.docx',
            '.jpg',
            '.png',
            '.jpeg',
            '.xls',
            '.xlsx',
            '.doc',
            '.ppt',
            '.pptx',
            '.pdf',
            '.xml',
          ]}
          showUploadList
        />
      </FormItem>

      <div className="mt-2">
        <DocumentsToCollectAttachmentTable />
      </div>
    </GroupForm>
  );
};

import { useEffect, useState } from 'react';

import cprApi from '../services/api/cpr';

const getSubdivisionFields = (fields: Array<string> = []) => {
  const selectedFields = { ...initialState };
  fields.forEach(field => {
    selectedFields[field] = true;
  });
  return fields.length ? selectedFields : defaultFields;
};

const initialState = {
  planting: false,
  pod_formation: false,
  grain_filling: false,
  maturation: false,
  harvested: false,
  pendant: false,
  cob_formation: false,
  full_flowering: false,
  apple_catch: false,
  apple_stuffing: false,
  bolls_opening: false,
  pre_flowering: false,
  flowery: false,
  lead: false,
  filling: false,
  vegetative: false,
} as { [key: string]: boolean };

const defaultFields = {
  planting: true,
  pod_formation: true,
  grain_filling: true,
  maturation: true,
  harvested: true,
};

export const useCprFields = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [productFields, setProductFields] = useState<{ [name: string]: string[] }>({});

  const getFields = async (cultureId: string) => {
    if (cultureId) {
      setIsFetching(true);
      try {
        return getSubdivisionFields(productFields[cultureId]);
      } catch (err) {
        return initialState;
      } finally {
        setIsFetching(false);
      }
    }
    return initialState;
  };

  const loadProductFields = async () => {
    const response = await cprApi.productFields.get();
    if (response) setProductFields(response.data.data);
  };

  useEffect(() => {
    loadProductFields();
  }, []);

  return { getFields, isFetching };
};

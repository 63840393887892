import React from 'react';

import { handleRequest } from 'services/api/api';
import registerApi from 'services/api/register';

export const usePriceDay = () => {
  const [isFetching, setIsFetching] = React.useState(false);
  const [isStoring, setIsStoring] = React.useState(false);
  const [isUpdating, setIsUpdating] = React.useState(false);
  const [isDestroying, setIsDestroying] = React.useState(false);

  const index = async <T,>(params = {}) => handleRequest(() => registerApi.priceDay.index<T>(params), setIsFetching);

  const store = async <T,>(data = {}) => handleRequest(() => registerApi.priceDay.store<T>(data), setIsStoring);

  const update = async <T,>(id: string, data = {}) =>
    handleRequest(() => registerApi.priceDay.update<T>(id, data), setIsUpdating);

  const destroy = async <T,>(id: string) => handleRequest(() => registerApi.priceDay.destroy<T>(id), setIsDestroying);

  return {
    index,
    isFetching,
    store,
    isStoring,
    update,
    isUpdating,
    destroy,
    isDestroying,
  };
};

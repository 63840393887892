import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Form } from 'antd';

import { FormItem, Button, Input, Select } from '../../../../../components';
import { SelectOptionType } from '../../../../../components/Select/types';
import { PHASE2_PRE_REGISTRATION, PHASE3_POST_REGISTRATION } from '../../../../../configs/constants';
import { SerachArea } from './styles';
import { PropsSearch } from './types';

export const TableSearch = ({
  setSearchFilters,
  searchForm,
  placeholder,
  warranty = false,
  monitoring = false,
}: PropsSearch) => {
  const { t } = useTranslation();
  const [onSerach, setOnSerach] = useState(false);

  const handleSearch = () => {
    const values = searchForm.getFieldsValue();
    if (values.phases) {
      switch (values.phases) {
        case 'pre':
          values.phases = [PHASE2_PRE_REGISTRATION];
          break;
        case 'pos':
          values.phases = [PHASE3_POST_REGISTRATION];
          break;
        default:
          values.phases = [PHASE2_PRE_REGISTRATION, PHASE3_POST_REGISTRATION];
      }
    }
    if (values.deadlineexceeded) {
      switch (values.deadlineexceeded) {
        case 'late':
          values.deadlineexceeded = 0;
          break;
        case 'notLate':
          values.deadlineexceeded = 1;
          break;
        default:
          values.deadlineexceeded = undefined;
      }
    }
    if (values.search !== '') setOnSerach(true);
    else setOnSerach(false);
    const { phases } = values;
    const { deadlineexceeded } = values;
    setSearchFilters((prev: any) => ({ ...prev, search: values.search, phases, deadlineexceeded }));
  };

  const onSearch = (e: any) => {
    if (e.target.value === '' && onSerach) {
      searchForm.setFieldsValue({
        search: '',
      });
      handleSearch();
    }
  };

  const optionsPahses: SelectOptionType[] = [
    {
      value: 'all',
      key: '1',
      label: t('pages.dashboard.search.phases.all'),
    },
    {
      value: 'pre',
      key: '2',
      label: t('pages.dashboard.search.phases.pre'),
    },
    {
      value: 'pos',
      key: '3',
      label: t('pages.dashboard.search.phases.pos'),
    },
  ];

  const optionsDeadlineExceeded: SelectOptionType[] = [
    {
      value: 'all',
      key: '1',
      label: t('pages.dashboard.search.survey.all'),
    },
    {
      value: 'late',
      key: '2',
      label: t('pages.dashboard.search.survey.late'),
    },
    {
      value: 'notLate',
      key: '3',
      label: t('pages.dashboard.search.survey.notLate'),
    },
  ];

  const initialValues = () => {
    if (warranty) return { phases: 'all' };
    if (monitoring) return { deadlineexceeded: 'all' };
    return {};
  };

  return (
    <SerachArea>
      <Form
        initialValues={initialValues()}
        className="form-secondary"
        style={{ display: 'flex' }}
        form={searchForm}
        onFinish={() => handleSearch()}
      >
        <FormItem label={t('pages.dashboard.search.search')} style={{ width: '100%' }} name="search">
          <Input placeholder={placeholder} onChange={onSearch} allowClear />
        </FormItem>
        {warranty && (
          <FormItem label={t('pages.dashboard.search.phase')} name="phases">
            <Select style={{ width: '150px' }} options={optionsPahses} />
          </FormItem>
        )}
        {monitoring && (
          <FormItem label={t('pages.dashboard.search.survey.title')} name="deadlineexceeded">
            <Select style={{ width: '150px' }} options={optionsDeadlineExceeded} />
          </FormItem>
        )}
        <div
          className="grid-buttons"
          style={{ alignSelf: 'center', marginTop: warranty || monitoring ? '2px' : '8px', display: 'flex' }}
        >
          <Button status="primary" htmlType="submit" style={{ marginRight: '5px' }}>
            {t('pages.dashboard.search.buttons.filter')}
          </Button>
          {(warranty || monitoring) && (
            <Button
              status="secondary"
              onClick={() => {
                searchForm.resetFields();
                handleSearch();
              }}
            >
              {t('pages.dashboard.filters.button-clear')}
            </Button>
          )}
        </div>
      </Form>
    </SerachArea>
  );
};

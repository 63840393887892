import styled from 'styled-components';

export const Container = styled.div<{ isSelected?: boolean; isMain?: boolean }>`
  display: flex;
  align-items: center;
  padding: ${({ isMain }) => (isMain ? '18px' : '6px')} 33px;
  gap: 10px;
  position: relative;
  cursor: pointer;
  background: ${({ theme: { colors }, isSelected }) => isSelected && colors.lightGrey};
  width: 100%;
  justify-content: space-between;

  &:hover {
    background: ${({ theme: { colors } }) => colors.greys.grey03};
  }

  &::before {
    content: '';
    display: ${({ isSelected }) => (isSelected ? 'block' : 'none')};
    height: 100%;
    background: ${({ theme: { colors } }) => colors.primary};
    width: 5px;
    position: absolute;
    left: 0;
    z-index: 1;
    border-radius: 0px 3px 3px 0px;
  }
`;

export const ContractName = styled.span`
  color: ${({ theme: { colors } }) => colors.primary};
`;

export const ContractData = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

import React from 'react';
import { BsFillExclamationCircleFill } from 'react-icons/bs';
import { HiCheckCircle } from 'react-icons/hi';

import { CONTRACT_SIGNATURE_STATUS_AUTORESPONDED, CONTRACT_SIGNATURE_STATUS_DECLINED } from 'configs/constants';
import type { ContractSubscriberData } from 'types/Contract/ContractSubscriberData';

import theme from '../../../styles/theme';
import { UserIcon } from './user-icon';

export const SignaturesPopover = ({ signatures }: { signatures: any[] }) => {
  const getBadgeType = (signature: ContractSubscriberData) => {
    if (signature.signed_at)
      return <HiCheckCircle enableBackground={theme.colors.white} size={13} color={theme.colors.success} />;
    if (signature.status?.id === CONTRACT_SIGNATURE_STATUS_AUTORESPONDED)
      return (
        <BsFillExclamationCircleFill enableBackground={theme.colors.white} size={13} color={theme.colors.warning} />
      );
    if (signature.status?.id === CONTRACT_SIGNATURE_STATUS_DECLINED)
      return (
        <BsFillExclamationCircleFill enableBackground={theme.colors.white} size={13} color={theme.colors.verydanger} />
      );
    return undefined;
  };
  return (
    <div className="flex flex-col gap-[10px]">
      {signatures.map(signature => {
        const badgeType = getBadgeType(signature);
        return (
          <div className="flex items-center justify-between gap-[10px]" key={signature.name}>
            <UserIcon letter={signature.name.substring(0, 1)} />
            <span className="w-full text-start">{signature.name}</span>
            {badgeType ?? <div />}
          </div>
        );
      })}
    </div>
  );
};

import React from 'react';

import { Container } from './styles';

interface Props {
  option: any;
}

export const SubscriberAutoCompleteOption = ({ option }: Props) => {
  return (
    <Container>
      <strong>{option.label}</strong>
      <i>{option.email}</i>
    </Container>
  );
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import ErrorSVG from 'assets/svgs/application-error.svg';
import Button from 'components/Button';

import { ButtonsWrapper, Container } from './styles';

export const ErrorFallback = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Container>
      <img src={ErrorSVG} alt="" height="400px" />
      <p>{t('errorFallback.message')}</p>
      <ButtonsWrapper>
        <Button status="primary" size="large" onClick={() => window.open(process.env.REACT_APP_QA_URL, '_blank')}>
          {t('report')}
        </Button>
        <Button status="primary-outline" size="large" onClick={() => navigate(-1)}>
          {t('back')}
        </Button>
      </ButtonsWrapper>
    </Container>
  );
};

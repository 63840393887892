import React from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ShouldRender } from 'components/ShouldRender';
import { useAlertDialog } from 'components/ui/alert-dialog';
import { FormGrid } from 'components/ui/form-grid';
import { FormItem } from 'components/ui/form-item';
import { GroupForm } from 'components/ui/group-form';
import { Input } from 'components/ui/input';
import { Switch } from 'components/ui/switch';

import { useRegisteredCustomerStore } from './stores/use-registered-customer-store';

type Props = {
  personType: 'legal' | 'physical';
};

export const RegisteredCustomerComplianceSection = ({ personType }: Props) => {
  const { t } = useTranslation();
  const form = useForm();
  const { alert } = useAlertDialog();

  const isEmployeeRelationship = useWatch({
    name: personType === 'legal' ? 'json_data.is_employee_relationship' : 'json_data.has_conflict_with_employee',
  });

  const isViewing = useRegisteredCustomerStore(state => state.isViewing);
  const isFinalizing = useRegisteredCustomerStore(state => state.isFinalizing);
  const isModulePerson = useRegisteredCustomerStore(state => state.isModulePerson);

  async function confirmcomplianceClear(value: boolean) {
    const economicGroups = form.getValues('economic_groups');

    if (economicGroups && economicGroups.length > 0 && !value) {
      return alert({
        type: 'confirm',
        title: t(`modal.warning`),
        subTitle: t('pages.registered-customers.messages.pf.warningEconomicGroups'),
        onOk: () => handleEconomicGroupsClear(value),
        okText: t('pages.registered-customers.messages.confirmButton'),
      });
    }

    return true;
  }

  async function handleEconomicGroupsClear(value: boolean) {
    if (!value) form.setValue('economic_groups', []);
  }

  return (
    <GroupForm title={t('compliance')}>
      <FormGrid>
        <FormItem
          label={t('pages.registered-customers.form.pj.is_employee_relationship')}
          name={personType === 'legal' ? 'json_data.is_employee_relationship' : 'json_data.has_conflict_with_employee'}
          className="col-span-full"
        >
          <Switch
            checked={isEmployeeRelationship}
            onValueChange={value => {
              confirmcomplianceClear(value);
            }}
            disabled={isViewing || !isModulePerson}
          />
        </FormItem>

        <ShouldRender condition={isEmployeeRelationship}>
          <FormItem
            label={t('pages.registered-customers.form.pj.collaborator_name')}
            name="compliance.0.name"
            rules={{ required: isFinalizing }}
          >
            <Input disabled={isViewing || !isModulePerson} />
          </FormItem>

          <FormItem
            label={t('pages.registered-customers.form.pj.employee_branch')}
            name="compliance.0.employee_branch"
            rules={{ required: isFinalizing }}
          >
            <Input disabled={isViewing || !isModulePerson} />
          </FormItem>

          <FormItem
            label={t('pages.registered-customers.form.pj.employee_office')}
            name="compliance.0.employee_position"
            rules={{ required: isFinalizing }}
          >
            <Input disabled={isViewing || !isModulePerson} />
          </FormItem>

          <FormItem
            label={t('pages.registered-customers.form.pj.employee_relationship')}
            name="compliance.0.employee_relationship"
            rules={{ required: isFinalizing }}
          >
            <Input disabled={isViewing || !isModulePerson} />
          </FormItem>
        </ShouldRender>
      </FormGrid>
    </GroupForm>
  );
};

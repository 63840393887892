import CreditRequestData from '../../../../../types/CreditRequest/CreditRequestData';
import { FormCreditRequestData } from './types';

const operationData: CreditRequestData = {
  business_unit: undefined,
  start_withdrawal_deadline: undefined,
  withdrawal_period: undefined,
  comments: undefined,
  contract_type: undefined,
  currency_type: undefined,
  division: undefined,
  division_id: undefined,
  harvest: undefined,
  harvest_id: undefined,
  id: null,
  incoterm: null,
  partial_limit: [],
  json_data: {
    az_participant: undefined,
    datasul_contract: undefined,
    contract: {
      requester_person_name: undefined,
      requester_person_cpf: undefined,
      requester_person_cnpj: undefined,
      requester_person_code_erp: undefined,
    },
    contract_type_id: undefined,
    currency_type_id: undefined,
    fethab: undefined,
    freight: undefined,
    guarantor_code_erp: undefined,
    guarantor_code_sap: undefined,
    guarantor_doc_number: undefined,
    guarantor_name: undefined,
    guarantor_person_type: undefined,
    incoterm_id: undefined,
    interest_rate: undefined,
    is_exportation: undefined,
    modality_id: undefined,
    net_amount: undefined,
    opting_inss: undefined,
    price: undefined,
    quantity: undefined,
    quantity_kg: undefined,
    quantity_delivery: undefined,
    quantity_contract: undefined,
    quantity_received: undefined,
    // requested_amount: undefined,
    amount_paid: undefined,
    royalties_monsanto: undefined,
    taxes: undefined,
    warranty_register_number: undefined,
    warranty_pledge_areas: [],
    warranty_types: [],
    weight_type_id: undefined,
    step: undefined,
    total_steps: undefined,
  },
  modality: undefined,
  number: undefined,
  operation: undefined,
  operation_id: undefined,
  product: undefined,
  reason: undefined,
  region: undefined,
  requested_amount: undefined,
  approved_value: undefined,
  requester_person: undefined,
  requester_person_id: undefined,
  requester_person_type: undefined,
  state: undefined,
  team_status: undefined,
  team_status_id: undefined,
  sub_division: undefined,
  sub_division_id: undefined,
  subsidiary: undefined,
  user: undefined,
  user_id: undefined,
};

const operationForm: FormCreditRequestData = {
  comments: undefined,
  'depositary.id': undefined,
  'depositary.name': undefined,
  'depositary.rg': undefined,
  'depositary.cpf': undefined,
  'depositary.spouse.id': undefined,
  'depositary.spouse.name': undefined,
  'depositary.spouse.rg': undefined,
  'depositary.spouse.cpf': undefined,
  division_id: undefined,
  harvest_id: undefined,
  id: undefined,
  'json_data.datasul_contract': undefined,
  'requester_person.name': undefined,
  'json_data.contract.requester_person_cpf': undefined,
  'json_data.contract.requester_person_document': undefined,
  'json_data.contract.requester_person_cnpj': undefined,
  'json_data.contract.requester_person_code_erp': undefined,
  'json_data.business_unit_id': undefined,
  'json_data.contract_type_id': undefined,
  'json_data.currency_type_id': undefined,
  'json_data.fethab': 0,
  'json_data.freight': undefined,
  'json_data.guarantor_code_erp': undefined,
  'json_data.guarantor_code_sap': undefined,
  'json_data.guarantor_doc_number': undefined,
  'json_data.guarantor_name': undefined,
  'json_data.guarantor_person_type': undefined,
  'json_data.incoterm_id': undefined,
  'json_data.is_exportation': undefined,
  'json_data.opting_inss': 0,
  'json_data.interest_rate': undefined,
  'json_data.modality_id': undefined,
  'json_data.net_amount': undefined,
  'json_data.payment_term': undefined,
  'json_data.price': 0,
  'json_data.quantity': undefined,
  'json_data.quantity_kg': undefined,
  'json_data.quantity_delivery': undefined,
  'json_data.quantity_contract': undefined,
  'json_data.quantity_received': undefined,
  'json_data.requested_amount': undefined,
  'json_data.amount_paid': undefined,
  'json_data.region_id': undefined,
  'json_data.royalties_monsanto': 0,
  'json_data.state_id': undefined,
  'json_data.store_location': undefined,
  'json_data.subsidiary_id': undefined,
  'json_data.taxes': 0,
  'json_data.warehouse_city_id': undefined,
  'json_data.warehouse_neighborhood': undefined,
  'json_data.warehouse_shipping_capacity': undefined,
  'json_data.warehouse_state_id': undefined,
  'json_data.warehouse_street': undefined,
  'json_data.warranty_register_number': undefined,
  'json_data.warranty_pledge_areas': [],
  'json_data.warranty_types': [],
  'json_data.weight_type_id': undefined,
  'json_data.withdrawal_period': undefined,
  'json_data.step': 1,
  'json_data.total_steps': 13,
  number: undefined,
  operation_id: undefined,
  product: undefined,
  reason: undefined,
  requested_amount: undefined,
  approved_value: undefined,
  requester_person_type: undefined,
  requester_person_id: undefined,
  'requester_person.json_data.code_erp': undefined,
  'requester_person.cnpj': undefined,
  'requester_person.cpf': undefined,
  team_status_id: undefined,
  sub_division_id: undefined,
  user_id: undefined,
};

export default {
  credit: {
    data: operationData,
    form: operationForm,
  },
};

export default {
  farm: [
    {
      title: 'pages.monitoring.farm.table.header.name',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
    },
    {
      title: 'pages.monitoring.farm.table.header.state',
      dataIndex: 'state',
      key: 'state',
      sorter: true,
    },
    {
      title: 'pages.monitoring.farm.table.header.city',
      dataIndex: 'city',
      key: 'city',
      sorter: true,
    },
    {
      title: 'pages.monitoring.farm.table.header.address',
      dataIndex: 'address',
      key: 'address',
      sorter: true,
    },
    {
      title: 'pages.monitoring.farm.table.header.actions',
      key: 'action',
      dataIndex: 'action',
      align: 'center',
      width: '180px',
      fixed: 'right',
    },
  ],
  contacts: [
    {
      title: 'pages.monitoring.farm.form.typeContact',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'pages.monitoring.farm.form.contact',
      dataIndex: 'value',
      key: 'value',
    },
    {
      title: 'pages.monitoring.farm.form.description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'pages.monitoring.farm.table.header.actions',
      key: 'action',
      dataIndex: 'action',
    },
  ],
  documents: [
    {
      title: 'pages.monitoring.farm.table.header.type_file',
      dataIndex: 'type_id',
      key: 'type_id',
      sorter: false,
      filtered: false,
    },
    {
      title: 'pages.registered-customers.form.pf.documents.name',
      dataIndex: 'name',
      key: 'name',
      sorter: false,
      filtered: false,
    },
    {
      title: 'pages.registered-customers.table.headers.actions',
      dataIndex: 'action',
      key: 'action',
      width: '100px',
      fixed: 'right',
    },
  ],
};

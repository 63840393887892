import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AiFillEye } from 'react-icons/ai';
import { BiRefresh } from 'react-icons/bi';
import { BsListCheck } from 'react-icons/bs';
import { FaEye, FaFileUpload } from 'react-icons/fa';
import { GiPeanut } from 'react-icons/gi';
// import { IoMdCheckmarkCircle } from 'react-icons/io';

import { InfiniteSelect } from '@agrodatabr/agrodataui';
import { Col, Row, Switch, Tooltip } from 'antd';
import { Button } from 'components';
import { ContractDatasource } from 'configs/enum';
import { Can } from 'hooks/ability';
import { useAuth } from 'hooks/auth';
import { useModal } from 'hooks/modals/useModal';
import { DraftButton } from 'pages/Contracts/components/DraftButton';

import theme from '../../../../../../styles/theme';
import { Formatter } from '../../../../../../utils/Formatter';
import { FieldGroup } from '../../../../components/FieldGroup';
import { Tab } from '../../../../components/Tab';
import { Tag } from '../../../../components/Tag';
import { ContractContext } from '../../../../context/ContractContext';
import { IntegrationsModal } from './IntegrationsModal';
import { ReferenceNumberModal } from './ReferenceNumberModal';
import { RelatedDocumentDetails } from './RelatedDocumentDetails';
import { Title, Status, ContractNumber, TagValue, Fields, DatasulButtonsWrapper } from './styles';

export const ContractDetails = ({ changeStage }: { changeStage: React.Dispatch<React.SetStateAction<number>> }) => {
  const {
    mainContract,
    changeSelectedContractStatus,
    alreadyHasDraft,
    selectedContract,
    isSelectedDocumentMain,
    isDocumentCancelled,
    contract,
    handleGetContractTypes,
    hasContractType,
    handleChangeContractType,
    isIssued,
    isChangingContractType,
    forceContractUpdate,
    isLegalProvider,
    providerDocument,
  } = useContext(ContractContext);
  const { permissions } = useAuth();
  const { t } = useTranslation();
  const { close: closeReferenceNumberModal, isOpen: isReferenceModalOpen, open: openReferenceNumberModal } = useModal();
  const { close: closeDatasulJsonModal, isOpen: isDatasulJsonModalOpen, open: openDatasulJsonModal } = useModal();

  const handleShowContractType = () => {
    window.open(`/edocuments/contracttypes?contractTypeId=${mainContract?.type?.id}`, '_blank');
  };

  const isActiveSwitchDisabled = !permissions.includes('contract.active');

  return (
    <Tab overflowY="visible">
      <Title>
        {t('pages.edocuments.emission.contractDetails.title')}
        <DatasulButtonsWrapper>
          {contract?.datasource?.toLowerCase() !== ContractDatasource.SAP ? (
            <Button
              size="small"
              permission="contract.forceupdate"
              onClick={forceContractUpdate}
              disabled={!mainContract?.active}
            >
              <Tooltip placement="topRight" title={t('pages.edocuments.emission.contractDetails.refreshContract')}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <BiRefresh />
                </div>
              </Tooltip>
            </Button>
          ) : null}
          <Button size="small" onClick={openDatasulJsonModal}>
            <Tooltip placement="topRight" title={t('pages.edocuments.emission.contractDetails.jsonDetails')}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <AiFillEye />
              </div>
            </Tooltip>
          </Button>
        </DatasulButtonsWrapper>
      </Title>
      <Can I="contract.active">
        <Status>
          <Switch
            checked={mainContract?.active}
            disabled={isActiveSwitchDisabled}
            onChange={value => changeSelectedContractStatus(value)}
          />
          {mainContract?.active
            ? t('pages.edocuments.emission.contractDetails.activated')
            : t('pages.edocuments.emission.contractDetails.disabled')}
        </Status>
      </Can>

      <Fields background={theme.colors.lightGrey}>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={12}>
            <FieldGroup title={t('pages.edocuments.emission.contractDetails.contractNumber')}>
              <ContractNumber>{mainContract?.number}</ContractNumber>
            </FieldGroup>
          </Col>

          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={12}>
            <FieldGroup title={t('pages.edocuments.emission.contractDetails.contract_group')}>
              <ContractNumber>{mainContract?.group?.name}</ContractNumber>
            </FieldGroup>
          </Col>

          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={12}>
            <FieldGroup title={t('pages.edocuments.emission.contractDetails.datasulStatus')}>
              <span>
                {mainContract?.external_status ?? t('pages.edocuments.emission.contractDetails.notAvaliable')}
              </span>
            </FieldGroup>
          </Col>
        </Row>

        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col>
            <FieldGroup title={t('pages.edocuments.emission.contractDetails.format')}>
              <Tag>
                <FaFileUpload color={theme.colors.primary} />
                <TagValue color={theme.colors.primary}>{mainContract?.is_digital ? 'Digital' : 'Manual'}</TagValue>
              </Tag>
            </FieldGroup>
          </Col>
        </Row>

        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={12}>
            <FieldGroup title={t('pages.edocuments.emission.contractDetails.purchaseType')}>
              <span color={theme.colors.primary}>{mainContract?.purchase_type?.name ?? t('notProvided')}</span>
            </FieldGroup>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={12}>
            <FieldGroup title={t('pages.edocuments.emission.contractDetails.isExport')}>
              <span color={theme.colors.primary}>{mainContract?.ext_export_purpose === '1' ? t('yes') : t('no')}</span>
            </FieldGroup>
          </Col>
        </Row>

        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
          <Col
            style={{ width: '100%', display: 'flex', alignItems: 'end' }}
            xs={22}
            sm={22}
            md={22}
            lg={22}
            xl={22}
            xxl={22}
          >
            <FieldGroup title={t('pages.edocuments.emission.contractDetails.contractType')}>
              {hasContractType && isIssued ? (
                <span>{mainContract?.type?.name ?? t('pages.edocuments.emission.contractDetails.notProvided')}</span>
              ) : (
                <InfiniteSelect
                  getPopupContainer={trigger => trigger.parentNode}
                  style={{ width: '100%' }}
                  loading={isChangingContractType}
                  showSearch
                  onChange={handleChangeContractType}
                  allowClear={false}
                  getOptions={handleGetContractTypes}
                  value={mainContract?.contract_type_id}
                  listHeight={221}
                  breakOptions
                  perPage={10}
                  disabled={!mainContract?.active}
                  options={
                    mainContract?.type
                      ? [
                          {
                            key: mainContract?.type?.id,
                            label: mainContract?.type?.name,
                            value: mainContract?.type?.id,
                          },
                        ]
                      : []
                  }
                />
              )}
            </FieldGroup>
            {mainContract?.type?.id ? (
              <Can I="contract.template.index" a="">
                <Button
                  onClick={handleShowContractType}
                  style={{ padding: 0 }}
                  title={t('pages.edocuments.emission.draftFormatRadio.show')}
                >
                  {/* {t('pages.edocuments.emission.draftFormatRadio.change')} */}
                  <span style={{ display: 'flex', padding: '0px 5px' }}>
                    <FaEye size={10} />
                  </span>
                </Button>
              </Can>
            ) : null}
          </Col>
        </Row>

        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col>
            <FieldGroup title={t('pages.edocuments.emission.contractDetails.provider')}>
              <span>{mainContract?.provider_name ?? t('pages.edocuments.emission.contractDetails.notProvided')}</span>
            </FieldGroup>
          </Col>
        </Row>

        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col>
            <FieldGroup title={t('pages.edocuments.emission.contractDetails.providerDoc')}>
              <span>
                {providerDocument
                  ? isLegalProvider
                    ? Formatter.cnpj(providerDocument)
                    : Formatter.cpf(providerDocument)
                  : t('pages.edocuments.emission.contractDetails.notProvided')}
              </span>
            </FieldGroup>
          </Col>
        </Row>

        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col>
            <FieldGroup title={t('pages.edocuments.emission.contractDetails.product')}>
              {mainContract?.product?.name ? (
                <Tag className="bg-gray-300 text-gray-700">
                  <GiPeanut />
                  <TagValue>{mainContract?.product?.name}</TagValue>
                </Tag>
              ) : (
                'Não informado'
              )}
            </FieldGroup>
          </Col>
        </Row>

        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col>
            <FieldGroup title={t('pages.edocuments.emission.contractDetails.harvest')}>
              <span>{mainContract?.harvest?.name ?? t('pages.edocuments.emission.contractDetails.notProvided')}</span>
            </FieldGroup>
          </Col>
        </Row>

        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col>
            <FieldGroup title={t('pages.edocuments.emission.contractDetails.quantity')}>
              <span>{Formatter.kilogram(mainContract?.quantity_kg ?? 0)}</span>
            </FieldGroup>
          </Col>
        </Row>

        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={12}>
            <FieldGroup title={t('pages.edocuments.emission.contractDetails.vigencyStart')}>
              <span>
                {mainContract?.effective_start_date
                  ? Formatter.date(mainContract?.effective_start_date)
                  : t('pages.edocuments.emission.contractDetails.notProvided')}
              </span>
            </FieldGroup>
          </Col>

          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={12}>
            <FieldGroup title={t('pages.edocuments.emission.contractDetails.vigencyEnd')}>
              <span>
                {mainContract?.effective_end_date
                  ? Formatter.date(mainContract?.effective_end_date)
                  : t('pages.edocuments.emission.contractDetails.notProvided')}
              </span>
            </FieldGroup>
          </Col>
        </Row>

        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col>
            <FieldGroup title={t('pages.edocuments.emission.contractDetails.contractValue')}>
              <span>{Formatter.money(mainContract?.value ?? 0, 2, 'R$', true)}</span>
            </FieldGroup>
          </Col>
        </Row>

        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col>
            <FieldGroup title={t('pages.edocuments.emission.contractDetails.reference')}>
              <span style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                {contract?.reference ?? (t('notProvided') as string)}
                <Button
                  status="primary"
                  size="small"
                  onClick={openReferenceNumberModal}
                  disabled={!mainContract?.active}
                >
                  {t('pages.edocuments.emission.contractDetails.changeReference')}
                </Button>
              </span>
            </FieldGroup>
          </Col>
        </Row>

        {selectedContract && !isSelectedDocumentMain && <RelatedDocumentDetails />}
      </Fields>

      {!alreadyHasDraft && (
        <DraftButton
          onClick={() => changeStage(2)}
          disabled={isDocumentCancelled || !hasContractType || !mainContract?.active}
        >
          <BsListCheck /> {t('pages.edocuments.emission.contractDetails.issueDraftButton')}
        </DraftButton>
      )}

      <ReferenceNumberModal
        initialReferenceNumber={contract?.reference}
        close={closeReferenceNumberModal}
        isOpen={isReferenceModalOpen}
      />
      {contract ? (
        <IntegrationsModal
          close={closeDatasulJsonModal}
          isVisible={isDatasulJsonModalOpen}
          isDisabled={!contract.active}
          contractId={contract.id}
        />
      ) : null}
    </Tab>
  );
};

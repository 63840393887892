import DTOErrorReponse from '../../../../../@types/dtos/DTOErrorReponse';
import { usePage } from '../../../../../hooks/page';
import creditRequestApi from '../../../../../services/api/credit';

export const useDepositary = () => {
  const { alertStatus } = usePage();

  const deteleDepositary = async (id: any, setLoading: any, setDepositaryList: any) => {
    await creditRequestApi.requests
      .deleteDepositary(id)
      .then(() => {
        setDepositaryList((prev: any) => {
          const filter = prev.filter((data: any) => data.id !== id);
          return filter;
        });
        setLoading(false);
      })
      .catch((err: DTOErrorReponse) => alertStatus(err, 'error'))
      .finally(() => {
        setLoading(false);
      });
  };
  return { deteleDepositary };
};

import * as React from 'react';
import { FaSpinner } from 'react-icons/fa';

import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';
import { useAbility } from 'hooks/ability';
import { cn } from 'lib/utils';

const buttonVariants = cva(
  'group/button inline-flex relative items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none disabled:pointer-events-none disabled:opacity-50 disabled:cursor-not-allowed border border-transparent transition-all focus:border-primary/40 focus:shadow-[0px_0px_3px_3px_#ffa13c1A]',
  {
    variants: {
      variant: {
        default:
          'bg-primary text-white hover:text-white hover:bg-primary/90 focus:bg-white focus:text-primary focus:border-primary focus-within:bg-white focus-within:text-primary focus-within:border-primary',
        destructive: 'bg-destructive text-destructive-foreground hover:bg-destructive/90 focus:bg-destructive/50',
        outline: 'border border-gray-200 bg-background hover:bg-accent hover:text-accent-foreground focus:bg-accent',
        secondary: 'bg-secondary text-secondary-foreground hover:bg-secondary/80',
        ghost: 'hover:bg-accent hover:text-accent-foreground',
        link: 'text-primary underline-offset-4 hover:underline',
      },
      size: {
        default: 'px-4 py-2',
        sm: 'rounded-md px-3',
        lg: ' rounded-md px-8',
        icon: 'p-1',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  permissions?: string | string[];
  isLoading?: boolean;
  hideOnUnauthorized?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    { className, variant, size, asChild = false, disabled, permissions, isLoading, hideOnUnauthorized, ...props },
    ref,
  ) => {
    const ability = useAbility();
    const hasPermission =
      !permissions ||
      (Array.isArray(permissions)
        ? permissions.every(permission => ability.can(permission, ''))
        : ability.can(permissions, ''));
    const Comp = (asChild ? Slot : 'button') as any;

    if (hideOnUnauthorized && !hasPermission) return null;

    return (
      <div className="relative">
        <Comp
          className={cn(buttonVariants({ variant, size, className: cn(className, isLoading && 'opacity-30') }))}
          ref={ref}
          {...props}
          disabled={!hasPermission || disabled || isLoading}
        />
        {isLoading ? (
          <span className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-primary">
            <FaSpinner className="animate-spin" />
          </span>
        ) : null}
      </div>
    );
  },
);

Button.displayName = 'Button';

export interface FlatButtonProps extends React.HTMLAttributes<HTMLDivElement>, VariantProps<typeof buttonVariants> {
  isLoading?: boolean;
}

const FlatButton = React.forwardRef<HTMLDivElement, FlatButtonProps>(
  ({ className, variant, size, isLoading, ...props }, ref) => {
    return (
      <div
        className={cn(buttonVariants({ variant, size, className }), isLoading && 'opacity-30')}
        ref={ref}
        {...props}
        onClick={!isLoading ? props.onClick : undefined}
      />
    );
  },
);
FlatButton.displayName = 'FlatButton';

export { Button, buttonVariants, FlatButton };

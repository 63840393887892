import { useQuery } from '@tanstack/react-query';
import api from 'services/api/api';

export const useGetCreditRequestByIdQuery = (creditRequestId: string) => {
  return useQuery({
    staleTime: Infinity,
    queryKey: ['credit-request', creditRequestId],
    queryFn: async () => {
      const response = await api.get(`credit-request/${creditRequestId}`);

      if (response.status !== 200) throw new Error('Error fetching credit request');

      return response.data;
    },
  });
};

import moment from 'moment';

import theme from '../../../styles/theme';

export const propsSkeletonCard = {
  minWidth: ['310px', '310px', '310px', '310px', '310px', '310px'],
  height: '126px',
  borderRadius: '10px',
  margin: '5px',
  number: 6,
};

export const propsSkeletonCharts = {
  minWidth: ['99%', '550px', '550px', '550px'],
  height: '492px',
  borderRadius: '10px',
  margin: '5px',
  number: 4,
};

export const propsFilters = [
  {
    name: 'harvest',
    key: 1,
  },
  {
    name: 'region',
    key: 2,
  },
  {
    name: 'division',
    key: 3,
  },
  {
    name: 'sub_division',
    key: 4,
  },
  {
    name: 'operation',
    key: 5,
  },
  {
    name: 'requester',
    key: 6,
  },
  {
    name: 'requesterDate',
    key: 7,
  },
  {
    name: 'start',
    key: 8,
  },
  {
    name: 'end',
    key: 9,
  },
];

export const useChartCreditProps = () => {
  const propsChartCreditOperation = (data: any, t: any) => {
    if (Object.keys(data).length) {
      const newData = Object.keys(data).map((item: any) => data[item]);
      const legend = [
        { prop: 'requestedAmount', name: t('pages.dashboard.management.creditOperation.legends.requestedAmount') },
        { prop: 'approvalLimits', name: t('pages.dashboard.management.creditOperation.legends.approvalLimits') },
        { prop: 'disapprovedLimits', name: t('pages.dashboard.management.creditOperation.legends.disapprovedLimits') },
        { prop: 'automatic', name: t('pages.dashboard.management.creditOperation.legends.automatic') },
        { prop: 'manual', name: t('pages.dashboard.management.creditOperation.legends.manual') },
      ];

      const colors = [
        newData[0].color_requested_amount,
        newData[0].color_approval_limits,
        newData[0].color_disapproved_limits,
        newData[0].color_automatic,
        newData[0].color_manual,
      ];

      const values = (value: string) => {
        switch (value) {
          case 'requestedAmount':
            return newData.map((item: any) => ({
              name: item.operation,
              y: parseInt(item.sum_requested_amount, 10),
              drilldown: `${item.operation}-${value}`,
            }));
          case 'approvalLimits':
            return newData.map((item: any) => ({
              name: item.operation,
              y: parseInt(item.sum_approval_limits, 10),
              drilldown: `${item.operation}-${value}`,
            }));
          case 'disapprovedLimits':
            return newData.map((item: any) => ({
              name: item.operation,
              y: parseInt(item.sum_disapproved_limits, 10),
              drilldown: `${item.operation}-${value}`,
            }));
          case 'automatic':
            return newData.map((item: any) => ({
              name: item.operation,
              y: parseInt(item.sum_automatic, 10),
              drilldown: `${item.operation}-${value}`,
            }));
          case 'manual':
            return newData.map((item: any) => ({
              name: item.operation,
              y: parseInt(item.sum_manual, 10),
              drilldown: `${item.operation}-${value}`,
            }));
          default:
            return '';
        }
      };

      const amount = (value: string) => {
        switch (value) {
          case 'requestedAmount':
            return newData.map((item: any) => ({
              name: item.operation,
              y: parseInt(item.count_requested_amount, 10),
              drilldown: `${item.operation}-${value}`,
            }));
          case 'approvalLimits':
            return newData.map((item: any) => ({
              name: item.operation,
              y: parseInt(item.count_approval_limits, 10),
              drilldown: `${item.operation}-${value}`,
            }));
          case 'disapprovedLimits':
            return newData.map((item: any) => ({
              name: item.operation,
              y: parseInt(item.count_disapproved_limits, 10),
              drilldown: `${item.operation}-${value}`,
            }));
          case 'automatic':
            return newData.map((item: any) => ({
              name: item.operation,
              y: parseInt(item.count_automatic, 10),
              drilldown: `${item.operation}-${value}`,
            }));
          case 'manual':
            return newData.map((item: any) => ({
              name: item.operation,
              y: parseInt(item.count_manual, 10),
              drilldown: `${item.operation}-${value}`,
            }));
          default:
            return '';
        }
      };

      const option1 = legend.map((item: any) => ({
        name: item.name,
        data: values(item.prop),
        typeFormatter: 'abbreviatedValue',
      }));

      const option2 = legend.map((item: any) => ({
        name: item.name,
        data: amount(item.prop),
        typeFormatter: 'default',
      }));

      const drilldownValues = (value: string, dataProp: any) => {
        switch (value) {
          case 'requestedAmount':
            return parseInt(dataProp.sum_requested_amount, 10);
          case 'approvalLimits':
            return parseInt(dataProp.sum_approval_limits, 10);
          case 'disapprovedLimits':
            return parseInt(dataProp.sum_disapproved_limits, 10);
          case 'automatic':
            return parseInt(dataProp.sum_automatic, 10);
          case 'manual':
            return parseInt(dataProp.sum_manual, 10);
          default:
            return '';
        }
      };

      const drilldownAmount = (value: string, dataProp: any) => {
        switch (value) {
          case 'requestedAmount':
            return parseInt(dataProp.count_requested_amount, 10);
          case 'approvalLimits':
            return parseInt(dataProp.count_approval_limits, 10);
          case 'disapprovedLimits':
            return parseInt(dataProp.count_disapproved_limits, 10);
          case 'automatic':
            return parseInt(dataProp.count_automatic, 10);
          case 'manual':
            return parseInt(dataProp.count_manual, 10);
          default:
            return '';
        }
      };

      const drilldownAssembleOption1 = newData.map((d: any) => {
        const region = Object.keys(d.region).map((item: any) => d.region[item]);
        return legend.map((l: any) => ({
          name: l.name,
          id: `${d.operation}-${l.prop}`,
          data: region.map((r: any) => [r.name, drilldownValues(l.prop, r)]),
        }));
      });
      const drilldownOption1: any = [];
      drilldownAssembleOption1.forEach((d: any) => d.forEach((dd: any) => drilldownOption1.push(dd)));

      const drilldownAssembleOption2 = newData.map((d: any) => {
        const region = Object.keys(d.region).map((item: any) => d.region[item]);
        return legend.map((l: any) => ({
          name: l.name,
          id: `${d.operation}-${l.prop}`,
          data: region.map((r: any) => [r.name, drilldownAmount(l.prop, r)]),
        }));
      });
      const drilldownOption2: any = [];
      drilldownAssembleOption2.forEach((d: any) => d.forEach((dd: any) => drilldownOption2.push(dd)));

      return {
        key: 1,
        title: t('pages.dashboard.management.creditOperation.title'),
        buttons: [
          { label: t('pages.dashboard.management.creditOperation.buttons.value'), value: 0 },
          { label: t('pages.dashboard.management.creditOperation.buttons.amount'), value: 1 },
        ],
        colors,
        type: 'column',
        // yAxisText: 'Valor',
        buttonOptions: [option1, option2],
        drilldownOptions: [drilldownOption1, drilldownOption2],
        defaultExpand: 'auto',
      };
    }

    return {
      key: 1,
      title: t('pages.dashboard.management.creditOperation.title'),
      defaultExpand: 'auto',
    };
  };

  const propsChartRequest = (data: any, t: any) => {
    if (Object.keys(data).length) {
      const newData = Object.keys(data).map((item: any) => data[item]);
      const option1 = [
        {
          name: t('pages.dashboard.management.requestProgress.title'),
          data: newData.map((item: any) => ({
            name: item.operation,
            y: parseInt(item.count, 10),
            drilldown: item.operation,
          })),
          showInLegend: false,
          typeFormatter: 'default',
        },
      ];

      const option2 = [
        {
          name: t('pages.dashboard.management.requestProgress.title'),
          data: newData.map((item: any) => ({
            name: item.operation,
            y: parseInt(item.days, 10),
            drilldown: item.operation,
          })),
          showInLegend: false,
          typeFormatter: 'intConverter',
        },
      ];

      const drilldownOption1 = newData.map((item: any) => {
        const status = Object.keys(item.status).map((s: any) => item.status[s]);
        const option = {
          name: item.operation,
          id: item.operation,
          data: status.map((s: any) => [s.status, parseInt(s.count, 10)]),
        };
        return option;
      });

      const drilldownOption2 = newData.map((item: any) => {
        const status = Object.keys(item.status).map((s: any) => item.status[s]);
        const option = {
          name: item.operation,
          id: item.operation,
          data: status.map((s: any) => [s.status, parseInt(s.days, 10)]),
        };
        return option;
      });

      return {
        key: 2,
        title: t('pages.dashboard.management.requestProgress.title'),
        buttons: [
          { label: t('pages.dashboard.management.requestProgress.buttons.amount'), value: 0 },
          { label: t('pages.dashboard.management.requestProgress.buttons.days'), value: 1 },
        ],
        colors: [`${theme.colors.info}`],
        type: 'column',
        // yAxisText: 'Valor',
        buttonOptions: [option1, option2],
        drilldownOptions: [drilldownOption1, drilldownOption2],
      };
    }

    return {
      key: 2,
      title: t('pages.dashboard.management.requestProgress.title'),
    };
  };

  const propsChartPrepayLimit = (data: any, t: any) => {
    if (data.length) {
      const categories = data.map((d: any) => moment(d.date).format('DD/MM/YYYY'));
      const dataBalance = data.map((b: any) => parseInt(b.balance, 10));
      const dataLimit = data.map((l: any) => parseInt(l.prepay_limit, 10));

      categories.reverse();
      dataBalance.reverse();
      dataLimit.reverse();

      return {
        key: 3,
        title: t('pages.dashboard.management.prepayLimit.title'),
        colors: [`${theme.colors.darkBlue}`, `${theme.colors.success}`],
        type: 'line',
        rotation: -45,
        categories,
        series: [
          {
            name: t('pages.dashboard.management.prepayLimit.g&o'),
            data: dataBalance,
          },
          {
            name: t('pages.dashboard.management.prepayLimit.limit'),
            data: dataLimit,
          },
        ],
        marker: false,
        plotLabels: false,
        xAxisVisible: true,
        typeFormatter: 'datetime',
      };
    }

    return {
      key: 3,
      title: t('pages.dashboard.management.prepayLimit.title'),
    };
  };

  const propsChartCredit = (data: any, t: any) => {
    const listName = Object.keys(data);
    const props = (name: any) => {
      switch (name) {
        case 'sum_analisys':
          return {
            name: t('pages.dashboard.management.credit.legends.analisys'),
            y: parseInt(data[name], 10),
          };
        case 'sum_approved_credit':
          return {
            name: t('pages.dashboard.management.credit.legends.approval'),
            y: parseInt(data[name], 10),
          };
        case 'sum_reproved_credit':
          return {
            name: t('pages.dashboard.management.credit.legends.reproved'),
            y: parseInt(data[name], 10),
          };
        default:
          break;
      }
    };

    const dataProps = listName.map((item: any) => {
      return props(item);
    });

    const verifyValues = dataProps.filter((item: any) => item.y > 0);

    if (verifyValues.length) {
      return {
        key: 4,
        type: 'pie',
        title: t('pages.dashboard.management.credit.title'),
        colors: [`${theme.colors.darkBlue}`, `${theme.colors.success}`, `${theme.colors.primary}`],
        series: [
          {
            // colorByPoint: true,
            data: dataProps,
          },
        ],
        typeFormatter: 'abbreviatedValue',
      };
    }
    return {
      key: 4,
      title: t('pages.dashboard.management.credit.title'),
    };
  };

  return { propsChartCreditOperation, propsChartRequest, propsChartPrepayLimit, propsChartCredit };
};

/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import PermissionedComponent from 'components/PermissionedComponent';
import { useCache } from 'hooks/cache';
import { cn } from 'lib/utils';
import { CloudUpload, FileCheck, FileX, X } from 'lucide-react';
import { v4 as uuid } from 'uuid';

import { Button } from './button';
import { FormItem } from './form-item';
import { Select } from './select';
import { Separator } from './separator';
import { useToast } from './use-toast';

export type Upload = {
  classNames?: {
    container?: string;
    root?: string;
  };
  onChange?: ({ target: { name, value } }: { target: { name: string; value: File[] } }) => void;
  onValueChange?: (value: File[]) => void;
  value?: File[];
  name?: string;
  onError?: (error: string) => void;
  showUploadList?: boolean;
  allowedExtensions?: string[];
  maxFiles?: number;
  disabled?: boolean;
  onUpload?: (file: File[]) => void;
  description?: string;
  permissions?: string | string[];
  fieldsConfig?: {
    type?: boolean;
  };
};

export const Upload = ({
  name,
  onChange,
  classNames,
  value,
  onError,
  showUploadList,
  allowedExtensions,
  maxFiles,
  disabled,
  onValueChange,
  onUpload,
  description,
  permissions,
  fieldsConfig,
}: Upload) => {
  const { toast } = useToast();
  const { t } = useTranslation();
  const cache = useCache();

  const inputRef = useRef<HTMLInputElement>(null);

  const [_, setDragging] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [id] = useState(uuid());

  const isDisabled = (value?.length >= maxFiles && !!maxFiles) || disabled;

  const handleDrag = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragIn = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragOut = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
  };

  const handleDrop = (e: React.DragEvent) => {
    setHasError(false);
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);

    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const validFiles = Array.from(e.dataTransfer.files).filter(file => handleValidateFile(file));

      if (onChange && name) {
        if (maxFiles === 1) {
          if (onValueChange) onValueChange([...validFiles]);
          onChange({ target: { name, value: [...validFiles] } });
        } else {
          if (value.length >= maxFiles || !maxFiles) {
            if (onValueChange)
              onValueChange([...((Array.isArray(value) ? value : [value]) as any), ...(validFiles as any)]);
            onChange({
              target: { name, value: [...((Array.isArray(value) ? value : [value]) as any), ...(validFiles as any)] },
            });
          } else {
            toast({
              title: t('components.upload.maxFiles.title'),
              description: t('components.upload.maxFiles.description'),
            });
          }
        }
      }
      e.dataTransfer.clearData();
    }
  };

  const handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHasError(false);

    const validFiles = Array.from(e.target.files).filter(file => handleValidateFile(file));

    if (validFiles.length) {
      if (onChange && name) {
        if (onValueChange) onValueChange([...(value || []), ...validFiles]);
        if (onUpload) onUpload(validFiles);
        onChange({ target: { name, value: [...(value || []), ...validFiles] } });
      }
    }
  };

  const handleValidateFile = (file: File) => {
    if (file.size / 1024 > 50000) {
      setHasError(true);
      if (onError) onError(t('components.upload.fileTooBig.title'));
      toast({
        title: t('components.upload.fileTooBig.title'),
        description: t('components.upload.fileTooBig.description'),
      });
      return false;
    }
    return true;
  };

  const removeFile = (index: number) => {
    if (onChange && name) {
      const newValue = value.filter((_, i) => i !== index);
      if (onValueChange) onValueChange(newValue);
      onChange({ target: { name, value: newValue } });
      if (inputRef.current) inputRef.current.value = '';
    }
  };

  return (
    <div
      className={cn(
        'w-full flex-wrap flex border border-dashed rounded-lg overflow-hidden bg-white items-center min-h-fit',
        hasError ? 'border-red-600' : '',
      )}
    >
      <PermissionedComponent permission={permissions}>
        <label
          htmlFor={id}
          className={cn(
            'custom-file-input w-full [&:focus-within>*]:bg-blue-100 overflow-hidden',
            classNames?.container,
          )}
        >
          <div
            className="w-full"
            onDragOver={handleDrag}
            onDragEnter={handleDragIn}
            onDragLeave={handleDragOut}
            onDrop={handleDrop}
          >
            <input
              id={id}
              type="file"
              ref={inputRef}
              accept={allowedExtensions?.join(',')}
              className="h-0 w-0 opacity-0"
              disabled={isDisabled}
              onChange={handleChangeFile}
              multiple={maxFiles !== 1}
            />
            <div
              className={cn(
                'w-full relative border-b shadow-sm border-dashed border-gray-100 flex flex-col gap-2 p-4 items-center text-mg font-bold text-gray-400',
                isDisabled ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer',
                hasError ? 'text-red-600' : '',
              )}
            >
              {hasError ? <FileX size={30} /> : <CloudUpload size={30} />}
              <span className="text-center">
                {hasError ? t('components.upload.fileTooBig.title') : t('components.upload.description')}
              </span>
              {description ? <span className="text-center text-gray-500">{description}</span> : null}
              {!hasError && allowedExtensions?.length ? (
                <span className="font-sm font-normal text-gray-400 text-center">
                  {t('components.upload.allowedExtensions', { extensions: allowedExtensions.join(', ') })}
                </span>
              ) : null}
            </div>
          </div>
        </label>
      </PermissionedComponent>
      <div className={cn('w-full transition-all', showUploadList && value?.length ? 'h-auto' : 'h-0')}>
        <div className="flex gap-2 p-4 items-center flex-col text-mg font-bold text-gray-500 max-h-[500px] overflow-y-auto">
          {value &&
            value.map((file, index) => (
              <React.Fragment key={file.name}>
                <div className="flex  min-w-fit gap-2 items-center justify-between w-full font-normal" key={file.name}>
                  <div className="flex gap-4 items-center">
                    <FileCheck size={30} />
                    <div className="flex md:gap-2 flex-wrap text-start text-xs">
                      <span className="">
                        <div className="font-bold">{t('components.upload.fileName')}</div>
                        <div className="max-w-[200px] overflow-hidden overflow-ellipsis break-words">{file.name}</div>
                      </span>
                      <span>
                        <div className="font-bold">{t('components.upload.fileSize')}</div>
                        <div>{(file.size / 1024).toFixed(2)} KB</div>
                      </span>
                      {fieldsConfig?.type ? (
                        <span>
                          <FormItem
                            name={`${name}[${index}].type_id`}
                            rules={{ required: true }}
                            label={<div className="font-bold">{t('file-identification')}</div>}
                            readable={t('file-identification')}
                            className="w-[200px]"
                          >
                            <Select className="w-full" options={cache.getCreditOptions('document_type_credit')} />
                          </FormItem>
                        </span>
                      ) : null}
                    </div>
                  </div>
                  <Button
                    variant="destructive"
                    size="sm"
                    className="p-1"
                    onClick={() => removeFile(value.indexOf(file))}
                  >
                    <X size={18} />
                  </Button>
                </div>
                {index !== value.length - 1 ? <Separator /> : null}
              </React.Fragment>
            ))}
        </div>
      </div>
    </div>
  );
};

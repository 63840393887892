import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  height: 100vh;

  .row {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas: '. .';
    gap: 0;
  }

  .separator-login {
    border: 0;
    border-top: solid ${({ theme: { colors } }) => colors.greys.grey03} 2px;
    height: 0;
    margin: 16px auto;
    width: 50%;
  }

  .col {
    background-color: #ffffff;
    font-family: ${({ theme: { fonts } }) => fonts.primary};
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100vh;

    &.col-content {
      .form {
        margin: auto;
        width: 100%;

        .microsoft-button-space {
          display: flex;
          align-items: center;
          justify-content: center;
          padding-bottom: 20px;
          width: 100%;
        }

        form {
          padding-top: 20px;
        }

        .forgot-link {
          text-align: right;

          a {
            color: ${({ theme: { colors } }) => colors.text};
            font-family: ${({ theme: { fonts } }) => fonts.primary};
            font-size: 12px;
            font-weight: 700;
            text-transform: uppercase;
          }
        }

        .button {
          display: flex;
          place-content: center;
        }
      }
    }

    &.col-banner {
      box-shadow: inset 3px 0px 6px 0px rgba(0, 0, 0, 0.35);
    }
  }

  .client-logo {
    display: flex;
    place-content: center;
    margin: 16px 0;

    img {
      width: 85%;
      max-width: 279px;
    }
  }

  .company-logo {
    display: flex;
    place-content: center;
    margin: 16px 0;

    a {
      width: 40%;
      max-width: 243px;

      img {
        width: 100%;
      }
    }
  }

  .languages {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .language-item {
    background-color: #ffffff;
    border: solid 3px #ffffff;
    border-radius: 50%;
    cursor: pointer;
    margin: 4px 8px;
    position: relative;
    height: 30px;
    width: 30px;

    img {
      height: 24px;
      width: 24px;
      position: absolute;
      top: 0;
      left: 0;
    }

    &.language-inactive,
    &.language-inactive:hover {
      cursor: not-allowed;
      filter: opacity(0.3);
    }
  }

  .language-active {
    background-color: ${({ theme: { colors } }) => colors.primary};
    border: solid 3px ${({ theme: { colors } }) => colors.primary};
    border-radius: 50%;
  }

  .button-cofco {
    max-height: 18px;
    margin-right: 4px;
  }

  .button-agrodata {
    max-height: 18px;
    margin-right: 4px;
  }

  .switch-agrodata {
    color: ${({ theme: { colors } }) => colors.text};
    font-family: ${({ theme: { fonts } }) => fonts.primary};
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      margin-left: 4px;
    }

    h2 {
      font-size: 20px;
    }
  }
`;

import { formatValuesToFormData } from 'services/form';
import { PackageFormData } from 'types/CreditRequest/forms/PackageFormData';
import { FileUtils } from 'utils/File';

import { DocumentsToCollectFormConverter } from './documents-collect/documents-collect.form.converter';

const toApiFormData = async (data: PackageFormData, formData?: FormData) => {
  const newFormData =
    formData ||
    formatValuesToFormData(
      data,
      new FormData(),
      [],
      ['files', 'documentsToCollect', 'comments', 'items', 'template', 'documents'],
    );

  const base64PackageAttachments = data.files?.length
    ? await Promise.all(
        data.files?.map(async file => {
          const base64 = await FileUtils.getBase64(file);
          return base64;
        }),
      )
    : [];

  data.files?.forEach((file, index) => {
    const { type, name, type_id } = file;
    newFormData.append(`documents[${index}][image]`, base64PackageAttachments[index]);
    newFormData.append(`documents[${index}][mime]`, type);
    newFormData.append(`documents[${index}][name]`, name);
    newFormData.append(`documents[${index}][original]`, FileUtils.getOriginalName(name));
    if (type_id) newFormData.append(`documents[${index}][type_id]`, type_id);
  });

  await DocumentsToCollectFormConverter.toApiFormData(
    data.documentsToCollect.filter(document => document.id.includes('new-')),
    newFormData,
    'items',
  );

  return newFormData;
};

export const PackageFormConverter = {
  toApiFormData,
};

import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Col, Row } from 'antd';
import { STATE_GO, SUBDIVISION_SOYBEAN_ID } from 'configs/constants';
import { useCreditRequestContext } from 'context/CreditRequestContext';
import moment from 'moment';
import { useCreditRequestStore } from 'pages/CreditRequest/store/useCreditRequestStore';

import { ApprovedValueInput } from '../../atoms/ApprovedValueInput';
import { CommentsInput } from '../../atoms/comments.input';
import { CreditLimitDateInput } from '../../atoms/CreditLimitDateInput';
import { CreditLimitExpirationDateInput } from '../../atoms/CreditLimitExpirationDateInput';
import { EnabledValueInput } from '../../atoms/EnabledValueInput';
import { FethabQuotationInput } from '../../atoms/FethabQuotationInput';
import { FundeinfraSelect } from '../../atoms/FundeinfraSelect';
import { GrossValueInput } from '../../atoms/GrossValueInput';
import { JsonDataAzParticipantSelect } from '../../atoms/JsonDataAzParticipantSelect';
import { JsonDataCurrencyTypeIdSelect } from '../../atoms/JsonDataCurrencyTypeIdSelect';
import { JsonDataExpactedPaymentAtInput } from '../../atoms/JsonDataExpectedPaymentAtInput';
import { JsonDataFethabSelect } from '../../atoms/JsonDataFethabSelect';
import { JsonDataNetAmountInput } from '../../atoms/JsonDataNetAmountInput';
import { JsonDataOptingInssSelect } from '../../atoms/JsonDataOptingInssSelect';
import { JsonDataPriceSelect } from '../../atoms/JsonDataPriceSelect';
import { JsonDataQuantityKgInput } from '../../atoms/JsonDataQuantityKgInput';
import { JsonDataReasonIdSelect } from '../../atoms/JsonDataReasonIdSelect';
import { JsonDataRoyaltiesMonsantoInput } from '../../atoms/JsonDataRoyaltiesMonsantoInput';
import { JsonDataTaxesInput } from '../../atoms/JsonDataTaxesInput';
import { JsonDataWeightTypeIdSelect } from '../../atoms/JsonDataWeightTypeIdSelect';
import { RequestedAmountInput } from '../../atoms/RequestedAmountInput';

export const AdvanceData = () => {
  const { t } = useTranslation();
  const { creditForm } = useCreditRequestContext();

  const selectedSubDivisionId = useCreditRequestStore(state => state.selectedSubDivisionId);
  const selectedStateId = useCreditRequestStore(state => state.selectedStateId);
  const isDolarSelected = useCreditRequestStore(state => state.isDolarSelected);
  const setIsBlocking = useCreditRequestStore(state => state.setIsBlocking);
  const setIsDolarSelected = useCreditRequestStore(state => state.setIsDolarSelected);
  const isPhysicalPerson = useCreditRequestStore(state => state.isPhysicalPerson);
  const pageStatus = useCreditRequestStore(state => state.pageStatus);

  const isFundeinfraSelectVisible = useMemo(() => {
    return selectedStateId === STATE_GO;
  }, [selectedStateId]);

  // const isJsonDataCurrencyTypeIdSelectDisabled = useMemo(() => {
  //   return (
  //     pageStatus.viewing ||
  //     (contractFieldsValue &&
  //       contractFieldsValue['json_data.currency_type_id'] !== undefined)
  //   );
  // }, [pageStatus, contractFieldsValue]);

  const handleChangeJsonDataCurrencyTypeId = (value: any, option: any) => {
    if (value) setIsDolarSelected(option.label);
  };

  const handleChangeApprovedValue = (value: string) => {
    if (value) {
      setIsBlocking(true);
    }
  };

  return (
    <div className="group-form">
      <h2>{t('pages.credit-request.form.titles.down-payment-data')}</h2>

      <Row gutter={[8, 8]}>
        <Col xs={24} sm={18} md={12} lg={8} xl={6} xxl={6}>
          <JsonDataQuantityKgInput />
        </Col>

        {/* {!bagPriceLength ? (
          <Col xs={24} sm={18} md={12} lg={8} xl={6} xxl={6}>
            <JsonDataPriceInput label={t('pages.credit-request.form.prepayment_json_data_price')} disabled required />
          </Col>
        ) : ( */}
        <Col xs={24} sm={18} md={12} lg={8} xl={6} xxl={6}>
          <JsonDataPriceSelect />
        </Col>
        {/* )} */}

        <Col xs={24} sm={18} md={12} lg={8} xl={6} xxl={6}>
          <JsonDataCurrencyTypeIdSelect onChange={handleChangeJsonDataCurrencyTypeId} disabled />
        </Col>
        <Col xs={24} sm={18} md={12} lg={8} xl={6} xxl={6}>
          <JsonDataWeightTypeIdSelect />
        </Col>

        {/* Monsanto/Bayer */}
        {selectedSubDivisionId === SUBDIVISION_SOYBEAN_ID && (
          <Col xs={24} sm={18} md={12} lg={8} xl={6} xxl={6}>
            <JsonDataAzParticipantSelect />
          </Col>
        )}

        {isPhysicalPerson && (
          <Col xs={24} sm={18} md={12} lg={8} xl={6} xxl={6}>
            <JsonDataOptingInssSelect />
          </Col>
        )}

        {/* FETHAB */}
        <Col xs={24} sm={18} md={12} lg={8} xl={6} xxl={6}>
          <JsonDataFethabSelect />
        </Col>
        <Col xs={24} sm={18} md={12} lg={8} xl={6} xxl={6} style={{ display: !isDolarSelected ? 'none' : '' }}>
          <FethabQuotationInput isVisible={isDolarSelected} />
        </Col>

        <Col
          xs={24}
          sm={18}
          md={12}
          lg={8}
          xl={6}
          xxl={6}
          style={{ display: !isFundeinfraSelectVisible ? 'none' : '' }}
        >
          <FundeinfraSelect isVisible={isFundeinfraSelectVisible} />
        </Col>
        <Col xs={24} sm={18} md={12} lg={8} xl={6} xxl={6}>
          <GrossValueInput />
        </Col>
        <Col xs={24} sm={18} md={12} lg={8} xl={6} xxl={6}>
          <JsonDataTaxesInput />
        </Col>
        <Col xs={24} sm={18} md={12} lg={8} xl={6} xxl={6}>
          <JsonDataRoyaltiesMonsantoInput />
        </Col>
        <Col xs={24} sm={18} md={12} lg={8} xl={6} xxl={6}>
          <JsonDataNetAmountInput />
        </Col>
        <Col
          xs={24}
          sm={18}
          md={12}
          lg={8}
          xl={6}
          xxl={6}
          style={{ display: creditForm.getFieldValue('credit_due_date') ? '' : 'none' }}
        >
          <CreditLimitDateInput required={!!creditForm.getFieldValue('credit_due_date')} disabled={!pageStatus.new} />
        </Col>
        <Col
          xs={24}
          sm={18}
          md={12}
          lg={8}
          xl={6}
          xxl={6}
          style={{ display: creditForm.getFieldValue('limit_expiration_date') ? '' : 'none' }}
        >
          <CreditLimitExpirationDateInput
            required={!!creditForm.getFieldValue('limit_expiration_date')}
            disabled={!pageStatus.new}
          />
        </Col>
      </Row>

      <Row gutter={[8, 8]}>
        <Col xs={24} sm={18} md={12} lg={8} xl={6} xxl={6}>
          <JsonDataExpactedPaymentAtInput
            disabledDate={date => {
              const currentDate = moment().set('hour', 12).set('minute', 0).set('second', 0);
              const sunday = 0;
              const saturday = 6;
              let daysRemaining =
                currentDate.hour() >= 12 &&
                ((currentDate.hour() === 12 && (currentDate.minute() || currentDate.second())) ||
                  currentDate.hour() > 12)
                  ? 4
                  : 3;

              const newDate = currentDate.clone();

              while (daysRemaining > 0) {
                newDate.add(1, 'days');
                if (newDate.day() !== sunday && newDate.day() !== saturday) {
                  daysRemaining -= 1;
                }
              }

              if (date.isBefore(newDate, 'date')) return true;
              return false;
            }}
          />
        </Col>
        <Col xs={24} sm={18} md={12} lg={10} xl={10} xxl={10}>
          <JsonDataReasonIdSelect />
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <CommentsInput />
        </Col>
      </Row>

      <Row gutter={[8, 8]}>
        <Col xs={24} sm={18} md={12} lg={8} xl={6} xxl={6}>
          <RequestedAmountInput disabled disableOnChange />
        </Col>
        {pageStatus.viewing && (
          <>
            <Col xs={24} sm={18} md={12} lg={8} xl={6} xxl={6}>
              <ApprovedValueInput
                onChange={handleChangeApprovedValue}
                disabled={pageStatus.viewing || pageStatus.editing}
              />
            </Col>
            <Col xs={24} sm={18} md={12} lg={8} xl={12} xxl={12}>
              <EnabledValueInput />
            </Col>
          </>
        )}
      </Row>
    </div>
  );
};

import React from 'react';
import { FaSearch } from 'react-icons/fa';

import { Input } from 'antd';

import { SButton, SItemSearch, SForm } from './styles';
import { SearchFormProps } from './types';

const Search: React.FC<SearchFormProps> = ({ fields, onSearch }) => {
  return (
    <SForm>
      {fields.map(({ name }) => (
        <SItemSearch name={name} key={`field-${name}`}>
          <Input
            onKeyDown={(e: any) => {
              if (e.keyCode === 13) {
                onSearch(e.target.value);
              }
            }}
          />
        </SItemSearch>
      ))}
      <SButton
        status="primary"
        button="icon"
        htmlType="button"
        onClick={onSearch}
        style={{
          borderTopLeftRadius: '0 !important',
          borderTopRightRadius: '4px !important',
          borderBottomLeftRadius: '0 !important',
          borderBottomRightRadius: '4px !important',
        }}
      >
        <FaSearch />
      </SButton>
    </SForm>
  );
};

export default Search;

import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormItem, Input } from 'components';

export const RequesterPersonIdInput = () => {
  const { t } = useTranslation();

  return (
    <FormItem
      label={t(`pages.credit-request.form.requester_person_name`)}
      name="requester_person_id"
      style={{ display: 'none' }}
    >
      <Input />
    </FormItem>
  );
};

import React, { useContext, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Form as AntForm } from 'antd';
import { useForm } from 'antd/es/form/Form';

import { DTOContractTypeField } from '../../../../../types/Contract/DTOContractTypeField';
import { Button } from '../../../../../components';
import { DraftModelContext } from '../../../context/DraftModelContext';
import { DraftModelField } from '../../DraftModelField';
import { ButtonWrapper } from './styles';

export const Form = ({ field }: { field: DTOContractTypeField }) => {
  const { handleAddRegisterToTable } = useContext(DraftModelContext);
  const { t } = useTranslation();
  const [form] = useForm();

  const focusFirstField = () => {
    const foundForm = document.getElementById(field.id);
    if (foundForm) {
      const firstInput = foundForm.querySelector('input');
      if (firstInput) {
        firstInput.focus();
      }
    }
  };

  const handleClickSubmit = () => {
    form.validateFields().then(values => {
      handleAddRegisterToTable(field, values);
      focusFirstField();
    });
  };

  const handleClickClear = () => form.resetFields();

  useLayoutEffect(() => {
    focusFirstField();
  }, []);

  return (
    <AntForm form={form} id={field.id}>
      {field.columns
        .filter(column => !!column.type_id)
        .map(column => (
          <DraftModelField key={column.name} field={column} />
        ))}
      <br />
      <ButtonWrapper>
        <a onClick={handleClickClear}>{t('pages.edocuments.emission.draftEmission.table.clear')}</a>
        <Button type="primary" style={{ float: 'right' }} onClick={handleClickSubmit}>
          {t('pages.edocuments.emission.draftEmission.table.save')}
        </Button>
      </ButtonWrapper>
      <br />
    </AntForm>
  );
};

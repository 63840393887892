import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Empty } from 'antd';
import { FilePreviewer } from 'components/FilePreviewer';
import { ShouldRender } from 'components/ShouldRender';
import { GuaranteeStatus, GuaranteeTypeEnum } from 'configs/constants';

import { GuaranteeData } from '../../../../../../types/CreditRequest/GuaranteeData';
import { GuaranteeCard } from '../GuaranteeCard/GuaranteeCard';
import { GuaranteesListProps } from './GuaranteesList.types';

export const GuaranteesList = ({ guarantees, datasource, openValidationModal, refetchList }: GuaranteesListProps) => {
  const { t } = useTranslation();

  const [fileToPreview, setFileToPreview] = useState<any>(null);

  const handleValidate = (guarantee: GuaranteeData) => {
    openValidationModal(guarantee, GuaranteeStatus.VALIDATED);
  };

  const handleReject = (guarantee: GuaranteeData) => {
    openValidationModal(guarantee, GuaranteeStatus.REJECTED);
  };

  const handleReprove = (guarantee: GuaranteeData) => {
    openValidationModal(guarantee, GuaranteeStatus.REPROVED);
  };

  const handleApprove = (guarantee: GuaranteeData) => {
    openValidationModal(guarantee, GuaranteeStatus.APPROVED);
  };

  const getShouldShowApproveAndReproveButtons = useCallback(
    (guarantee: GuaranteeData) => {
      // Na habilitação, quando for processo padrão e datasource SAP não aparecer os botões de APROVAR e REPROVAR, mesmo o usuário tendo permissão
      if (
        guarantee.credit_request_partial_limit_type_id === GuaranteeTypeEnum.DEFAULT &&
        datasource?.toLocaleLowerCase() === 'sap'
      )
        return false;

      return true;
    },
    [datasource],
  );

  const getShouldShowSapStatusTag = useCallback(
    (guarantee: GuaranteeData) => {
      if (datasource?.toLocaleLowerCase() !== 'sap') return false;
      // Na habilitação, quando for processo padrão e datasource SAP, tem que mostrar que foi integrado ao SAP após VALIDADO, se for processo com EXCEÇÃO, mostrar apos aprovado
      if (
        guarantee.credit_request_partial_limit_type_id === GuaranteeTypeEnum.DEFAULT &&
        !!guarantee.approval.find(
          approval => approval.credit_request_partial_limit_status_id === GuaranteeStatus.VALIDATED,
        )
      )
        return true;
      if (
        guarantee.credit_request_partial_limit_type_id === GuaranteeTypeEnum.WITH_EXCEPTION &&
        !!guarantee.approval.find(
          approval => approval.credit_request_partial_limit_status_id === GuaranteeStatus.APPROVED,
        )
      )
        return true;

      return false;
    },
    [datasource],
  );

  const getShouldShowDatasulStatusTag = useCallback(
    (guarantee: GuaranteeData) => {
      if (datasource?.toLocaleLowerCase() !== 'datasul') return false;

      if (
        guarantee.approval.find(
          approval => approval.credit_request_partial_limit_status_id === GuaranteeStatus.APPROVED,
        )
      )
        return true;

      return false;
    },
    [datasource],
  );

  if (!guarantees.length)
    return (
      <div style={{ display: 'flex', flexDirection: 'row', gap: '20px', justifyContent: 'center' }}>
        <Empty description={t('noData').toString()} />
      </div>
    );

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
      {guarantees.map(guarantee => {
        return (
          <GuaranteeCard.Root key={guarantee.id}>
            <GuaranteeCard.Header>
              <GuaranteeCard.GuaranteeValue value={guarantee.value} />
              <GuaranteeCard.GuaranteeValueCASH value={guarantee.value_cash} />
              <GuaranteeCard.GuaranteeValueBARTER value={guarantee.value_barter} />
              <GuaranteeCard.GuaranteeType value={guarantee.credit_request_partial_limit_type_id} />
              <GuaranteeCard.GuaranteeRequester value={guarantee.user.name} />
              <GuaranteeCard.GuaranteeDate value={guarantee.created_at} />
              <GuaranteeCard.ValidationButtons>
                <ShouldRender condition={!guarantee.approval?.length}>
                  <>
                    <GuaranteeCard.ValidateButton
                      onClick={() => handleValidate(guarantee)}
                      permission="credit.request.partial.limit.validated"
                    />
                    <GuaranteeCard.RejectButton
                      onClick={() => handleReject(guarantee)}
                      permission="credit.request.partial.limit.refused"
                    />
                  </>
                </ShouldRender>
                <ShouldRender
                  condition={
                    !!guarantee.approval.find(
                      approval => approval.credit_request_partial_limit_status_id === GuaranteeStatus.VALIDATED,
                    ) &&
                    guarantee.approval.length < 2 &&
                    getShouldShowApproveAndReproveButtons(guarantee)
                  }
                >
                  <>
                    <ShouldRender condition={!guarantee.last_import?.description}>
                      <>
                        <GuaranteeCard.ApproveButton
                          onClick={() => handleApprove(guarantee)}
                          permission="credit.request.partial.limit.approval"
                        />
                        <GuaranteeCard.ReproveButton
                          onClick={() => handleReprove(guarantee)}
                          permission="credit.request.partial.limit.refused"
                        />
                      </>
                    </ShouldRender>
                  </>
                </ShouldRender>
              </GuaranteeCard.ValidationButtons>
              <ShouldRender condition={getShouldShowSapStatusTag(guarantee)}>
                <GuaranteeCard.SapStatusTag
                  datasource={datasource}
                  sapStatus={guarantee.last_import?.description}
                  sapStatusUpdatedAt={guarantee.last_import?.updated_at}
                  guaranteeId={guarantee.id}
                  lastIntegrationId={guarantee.last_import?.id}
                  workerId={guarantee.last_import?.worker_id}
                  refetchList={refetchList}
                />
              </ShouldRender>
              <ShouldRender condition={getShouldShowDatasulStatusTag(guarantee)}>
                <GuaranteeCard.DatasulStatusTag
                  approvalDate={
                    guarantee.approval.find(
                      approval => approval.credit_request_partial_limit_status_id === GuaranteeStatus.APPROVED,
                    )?.created_at
                  }
                  status="approved"
                />
              </ShouldRender>
            </GuaranteeCard.Header>
            <GuaranteeCard.LogList
              setFileToPreview={setFileToPreview}
              validityDate={guarantee.expiration_date}
              logs={[
                {
                  comment: guarantee.comment,
                  created_at: guarantee.created_at,
                  credit_request_partial_limit_id: guarantee.id,
                  credit_request_partial_limit_status_id: '',
                  id: guarantee.id,
                  user: guarantee.user,
                  user_id: guarantee.user_id,
                  value: 0,
                  updated_at: guarantee.updated_at,
                  documents: guarantee.documents,
                },
                ...guarantee.approval,
              ]}
            />
          </GuaranteeCard.Root>
        );
      })}
      {fileToPreview && <FilePreviewer document={fileToPreview} onClose={() => setFileToPreview(null)} />}
    </div>
  );
};

import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { BsGearFill } from 'react-icons/bs';
import { FaSyncAlt, FaInfoCircle, FaPlusCircle, FaMinusCircle } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';

import { Modal } from 'antd';

import { KanbanModuleConfig, kanbanConfigDefault } from '../../../../types/User/UserData';
import KanbanCustomersResponse from '../../../../@types/responseKanban/KanbanCustomersResponse';
import { Button, Spin, Switch } from '../../../../components';
import { useAuth } from '../../../../hooks/auth';
import { useCache } from '../../../../hooks/cache';
import api from '../../../../services/api/person';
import Column from './Column';
import ModalInfoItem from './ModalInfoItem';
import { Container, HeaderActions, Content, Spinner, KanbanInfo, KanbanConfigList } from './styles';
import { KanbanProps } from './types';

const CustomersKanban: React.FC<KanbanProps> = ({ person, filterValues }) => {
  const { t } = useTranslation();
  const {
    user: { config: userConfig },
    updateUserConfig,
  } = useAuth();

  const { getPersonOptions, personData } = useCache();

  const [data, setData] = useState<KanbanCustomersResponse>({} as KanbanCustomersResponse);

  const [updating, setUpdating] = useState(false);

  const [modalVisible, setModalVisible] = useState(false);
  const [kanbanInfo, setKanbanInfo] = useState(false);
  const [kanbanConfig, setKanbanConfig] = useState(false);

  const [expandedAllColumns, setExpandedAllColumns] = useState<boolean>(false);
  const [minimizedAllColumns, setMinimizedAllColumns] = useState<boolean>(false);
  const [, setExpandColumns] = useState<string[]>([]);
  const [hiddenColumns, setHiddenColumns] = useState<string[]>([]);

  const [personConfigs, setPersonConfigs] = useState<KanbanModuleConfig>(() => {
    const configs = userConfig.modules.person.kanban;

    if (!configs) {
      const personKanbanDefault: KanbanModuleConfig = {} as KanbanModuleConfig;
      personKanbanDefault.legal = kanbanConfigDefault;
      personKanbanDefault.physical = kanbanConfigDefault;
      return personKanbanDefault as KanbanModuleConfig;
    }

    return configs;
  });

  const setOptions = useState(() => ({
    person_stage: [],
  }))[1];

  useEffect(() => {
    const configs = userConfig.modules.person.kanban;

    if (configs[person] && configs[person] !== undefined) {
      setExpandedAllColumns(configs[person].allColumnsExpanded);
      setMinimizedAllColumns(configs[person].allColumnsMinimized);
      setExpandColumns(configs[person].expandedColumns);
      setHiddenColumns(configs[person].hiddenColumns);
    }
  }, [person, userConfig.modules.person.kanban]);

  useEffect(() => {
    setOptions({
      person_stage: getPersonOptions('person_stage'),
    });
    // eslint-disable-next-line
  }, [getPersonOptions]);

  const minimizeAllColumns = useCallback(() => {
    const updateConfigs: KanbanModuleConfig = {
      ...personConfigs,
      [person]: {
        ...personConfigs[person],
        allColumnsExpanded: false,
        allColumnsMinimized: true,
        expandedColumns: [],
      },
    };
    setExpandedAllColumns(false);
    setMinimizedAllColumns(true);
    setExpandColumns([]);

    setPersonConfigs(updateConfigs);
    userConfig.modules.person.kanban = updateConfigs;
    updateUserConfig(userConfig);
  }, [personConfigs, person, updateUserConfig, userConfig]);

  const expandAllColumns = useCallback(() => {
    const updateConfigs: KanbanModuleConfig = {
      ...personConfigs,
      [person]: {
        ...personConfigs[person],
        allColumnsExpanded: true,
        allColumnsMinimized: false,
        expandedColumns: [],
      },
    };
    setExpandedAllColumns(true);
    setMinimizedAllColumns(false);
    setExpandColumns([]);

    setPersonConfigs(updateConfigs);
    userConfig.modules.person.kanban = updateConfigs;
    updateUserConfig(userConfig);
  }, [personConfigs, person, updateUserConfig, userConfig]);

  const expandColumn = useCallback(
    (column: string) => {
      const expand = [...personConfigs[person].expandedColumns].filter(eColumn => eColumn !== column);

      const updateConfigs: KanbanModuleConfig = {
        ...personConfigs,
        [person]: {
          ...personConfigs[person],
          allColumnsExpanded: false,
          allColumnsMinimized: false,
          expandedColumns: [...expand, column],
        },
      };
      setExpandedAllColumns(false);
      setMinimizedAllColumns(false);
      setExpandColumns([...expand, column]);

      setPersonConfigs(updateConfigs);
      userConfig.modules.person.kanban = updateConfigs;
      updateUserConfig(userConfig);
    },
    [personConfigs, person, updateUserConfig, userConfig],
  );

  const handleToggleVisibilityColumn = useCallback(
    (column: string) => {
      let hideColumns: string[] = personConfigs[person].hiddenColumns || [];

      if (hideColumns.includes(column)) {
        hideColumns = [...hideColumns].filter(eColumn => eColumn !== column);
      } else {
        hideColumns = [...hideColumns, column];
      }

      const updateConfigs: KanbanModuleConfig = {
        ...personConfigs,
        [person]: {
          ...personConfigs[person],
          hiddenColumns: hideColumns,
        },
      };
      setHiddenColumns(hideColumns);

      setPersonConfigs(updateConfigs);
      userConfig.modules.person.kanban = updateConfigs;
      updateUserConfig(userConfig);
    },
    [personConfigs, person, updateUserConfig, userConfig],
  );

  const isMinimized = useCallback(
    (column: string) => {
      if (personConfigs[person].allColumnsMinimized) {
        return true;
      }

      if (!personConfigs[person].allColumnsExpanded && !personConfigs[person].expandedColumns.includes(column)) {
        return true;
      }

      return false;
    },
    [personConfigs, person],
  );

  const isHidden = useCallback(
    (column: string) => {
      return personConfigs[person].hiddenColumns.includes(column);
    },
    [personConfigs, person],
  );

  const handleUpdateKanban = useCallback(async () => {
    setUpdating(true);

    const filters: any = {};
    const dataFilter: any = filterValues;

    if (dataFilter) {
      Object.keys(dataFilter).forEach((key: string) => {
        filters[key] = dataFilter[key];
      });
    }

    await api[person]
      .getKanban({ params: filters })
      .then(response => {
        setData(response.data.data);
      })
      .finally(() => setUpdating(false));
  }, [person, filterValues]);

  useEffect(() => {
    if (person) {
      handleUpdateKanban();
    }
  }, [handleUpdateKanban, person]);

  const renderColumns = (): any => {
    const { person_stage } = personData;

    const stage_id = Object.entries(filterValues).find(value => value[0] === 'stage_id')?.[1];
    const slug = stage_id ? person_stage.find(p => p.id === stage_id).slug : '';

    if (stage_id) {
      let title;

      if (person === 'legal') {
        title = `pages.registered-customers.kanban.status.${slug}`;
      }
      if (person === 'physical') {
        title = `pages.registered-customers.kanban.status.${slug}`;
      }

      if (Object.keys(data).length === 0) {
        return [];
      }

      return (
        <Column
          key={slug}
          person={person}
          data={data[slug]}
          expandColumn={() => expandColumn(slug)}
          refreshKanban={() => handleUpdateKanban()}
          minimized={isMinimized(slug)}
          hidden={isHidden(slug)}
          statusColumn={slug}
          title={title}
          filterValues={filterValues}
        />
      );
    }

    return Object.keys(data).map(key => {
      let title;

      if (person === 'legal') {
        title = `pages.registered-customers.kanban.status.${key}`;
      }

      if (person === 'physical') {
        title = `pages.registered-customers.kanban.status.${key}`;
      }

      return (
        <Column
          key={key}
          person={person}
          data={data[key]}
          expandColumn={() => expandColumn(key)}
          refreshKanban={() => handleUpdateKanban()}
          minimized={isMinimized(key)}
          hidden={isHidden(key)}
          statusColumn={key}
          title={title}
          filterValues={filterValues}
        />
      );
    });
  };

  function renderModalInfo() {
    let translationPrefixTitleModal = '';
    let translationPrefixTitleModalItem = '';
    let translationPrefixDescriptionModalItem = '';

    if (person === 'legal') {
      translationPrefixTitleModal = 'pages.registered-customers.kanban.modals.information';
      translationPrefixTitleModalItem = 'pages.registered-customers.kanban.modals.information';
      translationPrefixDescriptionModalItem = 'pages.registered-customers.kanban.modals.information';
    }

    if (person === 'physical') {
      translationPrefixTitleModal = 'pages.registered-customers.kanban.modals.information';
      translationPrefixTitleModalItem = 'pages.registered-customers.kanban.modals.information';
      translationPrefixDescriptionModalItem = 'pages.registered-customers.kanban.modals.information';
    }

    if (person) {
      return (
        <>
          <h2>{t(`${translationPrefixTitleModal}.title`)}</h2>

          {Object.keys(data).map(infoItem => (
            <ModalInfoItem
              key={infoItem}
              title={t(`${translationPrefixTitleModalItem}.${infoItem}_title`)}
              description={t(`${translationPrefixDescriptionModalItem}.${infoItem}_description`)}
            />
          ))}
        </>
      );
    }

    return <div />;
  }

  function renderModalConfigs() {
    let title: string;
    let label: string;

    if (person === 'legal') {
      title = 'pages.registered-customers.kanban.modals.configuration.title';
      label = 'pages.registered-customers.kanban.status';
    }

    if (person === 'physical') {
      title = 'pages.registered-customers.kanban.modals.configuration.title';
      label = 'pages.registered-customers.kanban.status';
    }

    return (
      <>
        <h2>{t(title)}</h2>

        {Object.keys(data).map(key => (
          <li key={key}>
            <Switch
              onChange={() => handleToggleVisibilityColumn(key)}
              checked={!hiddenColumns.includes(key)}
              size="small"
            />{' '}
            {t(`${label}.${key}`)}
          </li>
        ))}
      </>
    );
  }

  function renderButtonsMinimizeAndExpand() {
    if (minimizedAllColumns && !expandedAllColumns) {
      return (
        <Button status="secondary-outline" size="small" onClick={expandAllColumns}>
          <FaPlusCircle />
          {t('pages.registered-customers.kanban.buttons.expand-columns')}
        </Button>
      );
    }

    return (
      <Button status="secondary-outline" size="small" onClick={minimizeAllColumns}>
        <FaMinusCircle />
        {t('pages.registered-customers.kanban.buttons.minimize-columns')}
      </Button>
    );
  }

  return (
    <Container>
      {personConfigs && (
        <HeaderActions>
          <Button
            status="secondary-outline"
            size="small"
            onClick={async () => {
              setUpdating(true);
              await handleUpdateKanban();
              setUpdating(false);
            }}
          >
            <FaSyncAlt />
            {t('pages.registered-customers.kanban.buttons.update')}
          </Button>

          <Button
            status="secondary-outline"
            size="small"
            onClick={() => {
              setModalVisible(true);
              setKanbanInfo(true);
            }}
          >
            <FaInfoCircle />
            {t('pages.registered-customers.kanban.buttons.information')}
          </Button>

          <Button
            status="secondary-outline"
            size="small"
            onClick={() => {
              setModalVisible(true);
              setKanbanConfig(true);
            }}
          >
            <BsGearFill />
            {t('pages.registered-customers.kanban.buttons.configurations')}
          </Button>

          {renderButtonsMinimizeAndExpand()}
        </HeaderActions>
      )}

      {updating && (
        <Spin spinning={updating}>
          <Spinner />
        </Spin>
      )}
      {!updating && data && <Content>{renderColumns()}</Content>}

      <Modal
        visible={modalVisible}
        title={
          kanbanInfo
            ? t('pages.registered-customers.kanban.modals.information-title')
            : t('pages.registered-customers.kanban.modals.configuration-title')
        }
        className="modal-with-custom-footer"
        onCancel={() => {
          setModalVisible(false);
          setKanbanInfo(false);
          setKanbanConfig(false);
        }}
        closeIcon={
          <MdClose
            onClick={() => {
              setModalVisible(false);
              setKanbanInfo(false);
              setKanbanConfig(false);
            }}
          />
        }
        width="70%"
        footer={
          <>
            <div className="buttons">
              <Button
                status="primary"
                onClick={() => {
                  setModalVisible(false);
                  setKanbanInfo(false);
                  setKanbanConfig(false);
                }}
              >
                {t('pages.registered-customers.kanban.modals.buttons.ok')}
              </Button>
            </div>
          </>
        }
      >
        {kanbanInfo && <KanbanInfo>{renderModalInfo()}</KanbanInfo>}

        {kanbanConfig && <KanbanConfigList>{renderModalConfigs()}</KanbanConfigList>}
      </Modal>
    </Container>
  );
};

export default CustomersKanban;

import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Form, Col, Upload, Tabs as AntTabs, Tabs } from 'antd';
import ImgCrop from 'antd-img-crop';
import { TablePaginationConfig, TableProps } from 'antd/lib/table';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import moment from 'moment';

import PaginationData from '../../../@types/data/PaginationData';
import { ResponseListData } from '../../../@types/data/ResponseData';
import DTOTeam from '../../../@types/dtos/credit-request/DTOTeam';
import DTOErrorReponse from '../../../@types/dtos/DTOErrorReponse';
import DTORegionModel from '../../../@types/dtos/location/DTORegionModel';
import DTOSubsidiaryModel from '../../../@types/dtos/location/DTOSubsidiaryModel';
import DTORole from '../../../@types/dtos/user/DTORole';
import DTOUserLogData from '../../../@types/dtos/user/DTOUserLogData';
import { Input, Select, Row } from '../../../components';
import { TableData } from '../../../compositions';
import appConfigs from '../../../configs/app';
import { useAuth } from '../../../hooks/auth';
import { usePage } from '../../../hooks/page';
import userApi from '../../../services/api/admin';
import { NotificationConfigTab } from '../../Admin/User/NotificationConfigTab';
import { useNotificationController } from '../../Admin/User/notificationController';
import columns from './columns';
import { Container, CardContainer, FormContainer, UploadImageContainer, DividerContainer } from './styles';
import UserLogForm from './types';

const { TabPane } = AntTabs;

const Profile: React.FC = () => {
  const [form] = Form.useForm();
  const { t, i18n } = useTranslation();
  const notificationTreeController = useNotificationController();

  const { alertStatus } = usePage();

  const [loading, setLoading] = useState(true);

  const { user, subsidiaries, regions, teams, roles, updateUserData } = useAuth();

  const [loadingImage, setLoadingImage] = useState(false);
  const [avatarImageUrl, setAvatarImageUrl] = useState('');

  const [tableLoading, setTableLoading] = useState(true);

  const [data, setData] = useState([]);
  const [paginationConfig, setPaginationConfig] = useState<TablePaginationConfig>({
    current: 1,
    total: 1,
    pageSize: 20,
  });

  useEffect(() => {
    setLoadingImage(true);
    setAvatarImageUrl(user.photo);
    form.validateFields().then(() => {
      form.setFieldsValue({
        username: user.name,
        email: user.email,
        'subsidiaries[]': [...new Set(subsidiaries?.map((item: DTOSubsidiaryModel) => item.name))],
        'teams[]': teams?.map((team: DTOTeam) => team.name),
        'roles[]': roles?.map((role: DTORole) => role.name),
        'regions[]': regions?.map((role: DTORegionModel) => role.name),
      });
      setLoading(false);
      setLoadingImage(false);
    });
    // eslint-disable-next-line
  }, []);

  function getBase64(img: any, callback: any) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  function uploadImage(info: any) {
    setLoadingImage(true);

    getBase64(info.file, (imageUrl: any) => {
      userApi.users
        .update(user.id, { photo: imageUrl })
        .then(async () => {
          await updateUserData();
          setAvatarImageUrl(imageUrl);
        })
        .catch((err: DTOErrorReponse) => alertStatus(err, 'error'))
        .finally(() => {
          setLoadingImage(false);
        });
    });
  }

  const tableConfig = {
    search: '',
    filtered: {},
    sorter: {},
  };

  const [tableDataConfig, setTableDataConfig] = useState(tableConfig);

  const loadTableData = useCallback(
    (values: Partial<UserLogForm & PaginationData<DTOUserLogData>>) => {
      setTableLoading(true);
      const dataSend = {
        params: {
          per_page: paginationConfig.pageSize,
          ...values,
        },
      };

      userApi.users
        .getLog(user.id, dataSend)
        .then((response: ResponseListData<DTOUserLogData>) => {
          const result = response.data.data;
          setData(result.data);
          setPaginationConfig(paginationConfigState => ({
            ...paginationConfigState,
            current: result.current_page,
            total: result.total,
          }));
        })
        .catch((err: DTOErrorReponse) => alertStatus(err, 'error'))
        .finally(() => {
          setTableLoading(false);
        });
    },
    // eslint-disable-next-line
    [i18n.language, paginationConfig.pageSize],
  );

  const handleTableChange: TableProps<any>['onChange'] = useCallback(
    (
      pagination: TablePaginationConfig,
      filters: Record<string, FilterValue | null>,
      sorter: SorterResult<any> | SorterResult<any>[],
    ) => {
      setTableLoading(true);
      setTableDataConfig(tableDataConfigState => ({
        ...tableDataConfigState,
        filtered: filters,
        sorter,
      }));

      let direction = '';
      const search: any = {};
      let sort = '';

      if (sorter) {
        const { field, order } = sorter as SorterResult<any>;

        if (order === 'ascend') direction = 'asc';
        if (order === 'descend') direction = 'desc';
        sort = field?.toString();
      }

      if (filters) {
        const dataFilter: any = filters;

        if (dataFilter) {
          Object.keys(dataFilter).forEach((key: string) => {
            if (dataFilter[key] !== null && dataFilter[key] !== undefined && dataFilter[key][0]) {
              search[key] = dataFilter[key][0].toString();
            } else if (dataFilter[key] !== null && dataFilter[key] !== undefined) {
              search[key] = dataFilter[key].toString();
            } else {
              search[key] = '';
            }
          });
        }
      }

      setPaginationConfig(paginationConfigState => ({
        ...paginationConfigState,
        pageSize: pagination.pageSize,
        current: pagination.current,
      }));

      loadTableData({
        page: pagination.current,
        per_page: pagination.pageSize,
        sort,
        direction,
        ...search,
      });
    },
    [loadTableData],
  );

  useEffect(() => {
    const filters: any = {};

    handleTableChange({ current: 1, pageSize: paginationConfig.pageSize }, filters, {}, null);
  }, [handleTableChange, paginationConfig.pageSize]);

  return (
    <>
      <Container>
        <Tabs type="card">
          <TabPane tab={t('pages.admin.user.defaultTab')} key="1">
            <CardContainer loading={loading}>
              <UploadImageContainer>
                <ImgCrop rotate>
                  <Upload
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    customRequest={uploadImage}
                  >
                    {avatarImageUrl && !loadingImage ? (
                      <img src={avatarImageUrl} alt="avatar" style={{ width: '100%' }} />
                    ) : (
                      <div>
                        {loadingImage ? <LoadingOutlined /> : <PlusOutlined />}
                        <div style={{ marginTop: 8 }}>Upload</div>
                      </div>
                    )}
                  </Upload>
                </ImgCrop>
              </UploadImageContainer>

              <FormContainer form={form} layout="vertical">
                <Row>
                  <Col span={12}>
                    <Form.Item label={t('pages.profile.form.username')} name="username">
                      <Input disabled />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label={t('pages.profile.form.email')} name="email">
                      <Input disabled />
                    </Form.Item>
                  </Col>
                </Row>

                <Form.Item label={t('pages.profile.form.roles')} name="roles[]">
                  <Select disabled mode="multiple" />
                </Form.Item>

                <Form.Item label={t('pages.profile.form.teams')} name="teams[]">
                  <Select disabled mode="multiple" />
                </Form.Item>

                <Form.Item label={t('pages.profile.form.regions')} name="regions[]">
                  <Select disabled mode="multiple" />
                </Form.Item>

                <Form.Item label={t('pages.profile.form.subsidiaries')} name="subsidiaries[]">
                  <Select disabled mode="multiple" />
                </Form.Item>
              </FormContainer>

              <DividerContainer orientation="left" plain>
                {t('pages.profile.userLog.title')}
              </DividerContainer>
              <TableData
                rowKey="id"
                columns={columns.map((column: any) => {
                  let columnRender: any = {
                    ...column,
                  };

                  if (column.dataIndex === 'auditable_type') {
                    columnRender = {
                      ...columnRender,
                      render: (value: any) => value.split('\\')[2],
                    };
                  }
                  if (column.dataIndex === 'created_at') {
                    columnRender = {
                      ...columnRender,
                      render: (value: any) => moment(value).format(appConfigs.formatDate),
                    };
                  }

                  return columnRender;
                })}
                dataSource={data}
                loading={tableLoading}
                onChange={handleTableChange}
                pagination={paginationConfig}
                tableConfig={tableDataConfig}
              />
            </CardContainer>
          </TabPane>

          <TabPane tab={t('pages.admin.user.notificationConfiguration')} key="2">
            <NotificationConfigTab treeController={notificationTreeController} userId={user.id} />
          </TabPane>
        </Tabs>
      </Container>
    </>
  );
};

export default Profile;

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: calc(100% / 2 - 60px);
  min-width: 550px;
  height: 700px;
  flex-shrink: 0;
  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

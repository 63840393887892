import { useMemo } from 'react';

import { SelectOptionType } from 'components/Select/types';
import { useCreditStatus } from 'hooks/creditStatus';

import { useCreditRequestStore } from '../store/useCreditRequestStore';

export const useGetCurrentTeamStatus = () => {
  const { getTeamStatus } = useCreditStatus();

  const teamStatusChangeTeamStatusCurrentId = useCreditRequestStore(state => state.teamStatusChangeTeamStatusCurrentId);

  return useMemo(() => {
    return getTeamStatus(teamStatusChangeTeamStatusCurrentId, true) as SelectOptionType;
    // Troca de Status - END
  }, [getTeamStatus, teamStatusChangeTeamStatusCurrentId]);
};

import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormItem, Select } from 'components';
import { useCreditRequestStore } from 'pages/CreditRequest/store/useCreditRequestStore';

export const ForExportationSelect = () => {
  const { t } = useTranslation();

  const setIsBlocking = useCreditRequestStore(state => state.setIsBlocking);
  const contractFieldsValue = useCreditRequestStore(state => state.contractFieldsValue);
  const pageStatus = useCreditRequestStore(state => state.pageStatus);

  return (
    <FormItem
      label={t('pages.credit-request.form.json_data_is_exportation')}
      name="json_data.is_exportation"
      rules={[{ required: true }]}
    >
      <Select
        options={[
          {
            key: 'exportation_yes',
            value: 1,
            label: t('pages.credit-request.form.exportation_yes'),
          },
          {
            key: 'exportation_not',
            value: 0,
            label: t('pages.credit-request.form.exportation_not'),
          },
        ]}
        disabled={
          pageStatus.viewing || (contractFieldsValue && contractFieldsValue['json_data.is_exportation'] !== undefined)
        }
        onChange={async (value: any) => {
          if (value !== undefined || value !== null) {
            setIsBlocking(true);
          }
        }}
      />
    </FormItem>
  );
};

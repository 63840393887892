import React from 'react';
import { useTranslation } from 'react-i18next';

import { InputNumber } from 'antd';
import { FormItem } from 'components';
import { useCreditRequestStore } from 'pages/CreditRequest/store/useCreditRequestStore';
import { Formatter } from 'utils/Formatter';

export const JsonDataWarehouseShippingCapacityInput = () => {
  const { t } = useTranslation();

  const setIsBlocking = useCreditRequestStore(state => state.setIsBlocking);
  const pageStatus = useCreditRequestStore(state => state.pageStatus);

  return (
    <FormItem
      label={t('pages.credit-request.form.json_data_warehouse_shipping_capacity')}
      name="json_data.warehouse_shipping_capacity"
      rules={[
        {
          validator(_, value) {
            if (!value || value <= 0) {
              return Promise.reject(t('pages.credit-request.form.json_data_warehouse_shipping_capacity_error'));
            }
            return Promise.resolve();
          },
        },
      ]}
    >
      <InputNumber
        formatter={(value: string | number) => Formatter.integer(value)}
        parser={(value: string) => Number(Formatter.integerParser(value))}
        disabled={pageStatus.viewing}
        onChange={value => {
          if (value) {
            setIsBlocking(true);
          }
        }}
      />
    </FormItem>
  );
};

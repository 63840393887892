import React from 'react';

import { ContractTable } from '../contract.table';
import { Filter } from './Filter';

type TableProps = {
  parentId?: string;
};

export const Table = ({ parentId }: TableProps) => {
  return (
    <div className="h-full flex flex-col overflow-hidden">
      {!parentId ? <Filter /> : null}

      <div className="h-full flex overflow-hidden mb-2">
        <ContractTable />
      </div>
    </div>
  );
};

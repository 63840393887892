import React from 'react';
import { useTranslation } from 'react-i18next';

import { ComboBox } from 'components/ui/combo';
import { FormGrid } from 'components/ui/form-grid';
import { FormItem } from 'components/ui/form-item';
import { GroupForm } from 'components/ui/group-form';
import { InputMoney, InputNumber } from 'components/ui/input';
import { useCache } from 'hooks/cache';

import { useRegisteredCustomerStore } from './stores/use-registered-customer-store';

export const RegisteredCustomerGeneralDataSection = () => {
  const { t } = useTranslation();
  const cache = useCache();

  const structureConservationOptions = cache.getPersonOptions('structure_conservation');
  const technologicalLevelOptions = cache.getPersonOptions('technological_level');
  const productivityOptions = cache.getPersonOptions('productivity');
  const activityDiversificationOptions = cache.getPersonOptions('activity_diversification');
  const creditHistoryOptions = cache.getPersonOptions('credit_history');
  const managerialSkillOptions = cache.getPersonOptions('managerial_skill');

  const isViewing = useRegisteredCustomerStore(state => state.isViewing);
  const isFinalizing = useRegisteredCustomerStore(state => state.isFinalizing);
  const isModulePerson = useRegisteredCustomerStore(state => state.isModulePerson);

  return (
    <GroupForm title={t('general-data')}>
      <FormGrid>
        <FormItem
          label={t('pages.registered-customers.form.pf.json_data.irpf_annual_income')}
          name="json_data.irpf_annual_income"
          rules={{ required: isFinalizing }}
        >
          <InputMoney disabled={isViewing || !isModulePerson} />
        </FormItem>

        <FormItem
          label={t('pages.registered-customers.form.pf.json_data.structure_conservation_id')}
          name="json_data.structure_conservation_id"
          rules={{ required: isFinalizing }}
        >
          <ComboBox options={structureConservationOptions} disabled={isViewing || !isModulePerson} />
        </FormItem>

        <FormItem
          label={t('pages.registered-customers.form.pf.json_data.technological_level_id')}
          name="json_data.technological_level_id"
          rules={{ required: isFinalizing }}
        >
          <ComboBox options={technologicalLevelOptions} disabled={isViewing || !isModulePerson} />
        </FormItem>

        <FormItem
          label={t('pages.registered-customers.form.pf.json_data.productivity_id')}
          name="json_data.productivity_id"
          rules={{ required: isFinalizing }}
        >
          <ComboBox options={productivityOptions} disabled={isViewing || !isModulePerson} />
        </FormItem>

        <FormItem
          label={t('pages.registered-customers.form.pf.json_data.activity_diversification_id')}
          name="json_data.activity_diversification_id"
          rules={{ required: isFinalizing }}
        >
          <ComboBox options={activityDiversificationOptions} disabled={isViewing || !isModulePerson} />
        </FormItem>

        <FormItem
          label={t('pages.registered-customers.form.pf.json_data.credit_history_id')}
          name="json_data.credit_history_id"
          rules={{ required: isFinalizing }}
        >
          <ComboBox options={creditHistoryOptions} disabled={isViewing || !isModulePerson} />
        </FormItem>

        <FormItem
          label={t('pages.registered-customers.form.pf.json_data.managerial_skill_id')}
          name="json_data.managerial_skill_id"
          rules={{ required: isFinalizing }}
        >
          <ComboBox options={managerialSkillOptions} disabled={isViewing || !isModulePerson} />
        </FormItem>

        <FormItem
          label={t('pages.registered-customers.form.pf.json_data.customer_cofco_years')}
          name="json_data.customer_cofco_years"
          rules={{ required: isFinalizing }}
        >
          <InputNumber min="0" decimals={0} max="99" disabled={isViewing || !isModulePerson} />
        </FormItem>

        <FormItem
          label={t('pages.registered-customers.form.pf.json_data.own_planting_resource')}
          name="json_data.own_planting_resource"
          rules={{ required: isFinalizing }}
        >
          <InputNumber min={0} suffix="%" max={100} disabled={isViewing || !isModulePerson} />
        </FormItem>
      </FormGrid>
    </GroupForm>
  );
};

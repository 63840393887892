import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaPlus } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';

import { LoadingOutlined } from '@ant-design/icons';
import { Form, Input, List, Modal, Spin, Upload } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import { TableProps, TablePaginationConfig } from 'antd/lib/table';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';

import DTOErrorReponse from '../../@types/dtos/DTOErrorReponse';
import DTOImport from '../../@types/dtos/DTOImport';
import Button from '../../components/Button';
import IconWithTooltip from '../../components/IconWithTooltip';
import { usePage } from '../../hooks/page';
import { CreditRequestDataTable } from '../../pages/CreditRequest/components/organisms/CreditKanban/Card/types';
import documentApi from '../../services/api/document';
import { downloadHandler } from '../../services/download';
import { formatValuesToFormData } from '../../services/form';
import i18n from '../../services/i18next';
import { Formatter } from '../../utils/Formatter';
import TableData from '../TableData';
import columns from './columns';
import { SUpload } from './styles';
import { ModalProps } from './types';

const ImportModal: React.FC<ModalProps> = ({
  afterClose,
  visibility,
  listImportApi,
  generateImportApi,
  ...modalProps
}) => {
  const { t } = useTranslation();

  const { alertStatus } = usePage();

  const processingReload = 4 * 1000;
  const [uploadForm] = Form.useForm();
  const [intervalProcess, setIntervalProcess] = useState(false);
  const [exportsData, setExportsData] = useState<DTOImport[]>([]);
  const [exportsLoading, setExportsLoading] = useState(false);
  const [visibilityUploadModal, setVisibilityUploadModal] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [visibleModalError, setVisibleModalError] = useState(false);
  const [errorData, setErrorData] = useState([]);

  const [uploadList, setUploadList] = useState([]);
  const [tableDataExportsConfig, setTableDataExportsConfig] = useState({
    search: '',
    filtered: {},
    sorter: {},
  });
  const [exportsPaginationConfig, setExportsPaginationConfig] = useState<TablePaginationConfig>({
    current: 1,
    total: 1,
    pageSize: 10,
  });
  const [downloadLoading, setDownloadLoading] = useState([]);
  const { Dragger } = Upload;

  function handleReset() {
    setVisibilityUploadModal(false);
    setUploadList([]);
    uploadForm.resetFields();
  }

  const loadImportsTableData = useCallback(
    (values: CreditRequestDataTable = null, loading = true) => {
      if (loading) {
        setExportsLoading(true);
      }

      const config = {
        params: {
          ...values,
        },
      };

      listImportApi(config)
        .then((response: { data: { data: any } }) => {
          const result = response.data.data;
          const filtered = result.data.find((item: DTOImport) => item.status === 'processing');

          if (typeof filtered === 'undefined') {
            setIntervalProcess(false);
            clearTimeout(1);
          } else if (!intervalProcess) {
            setIntervalProcess(true);
            setTimeout(() => {
              loadImportsTableData(values, false);
            }, processingReload);
          }

          setExportsData(result.data.map((item: DTOImport) => item));
          setExportsPaginationConfig(paginationConfigState => ({
            ...paginationConfigState,
            current: result.current_page,
            total: result.total,
          }));
        })
        .catch((err: DTOErrorReponse) => alertStatus(err, 'error'))
        .finally(() => {
          if (loading) {
            setExportsLoading(false);
          }
        });
    },
    [intervalProcess, processingReload, alertStatus, listImportApi],
  );

  if (visibility) {
    loadImportsTableData();
  }

  const handleTableExportsChange: TableProps<any>['onChange'] = useCallback(
    (
      pagination: TablePaginationConfig,
      filters: Record<string, FilterValue | null>,
      sorter: SorterResult<any> | SorterResult<any>[],
    ) => {
      setExportsLoading(true);
      setTableDataExportsConfig(tableDataConfigState => ({
        ...tableDataConfigState,
        filtered: filters,
        sorter,
      }));

      let direction = '';
      const search: any = {};
      let sort = '';

      if (sorter) {
        const { field, order } = sorter as SorterResult<any>;

        if (order === 'ascend') direction = 'asc';
        if (order === 'descend') direction = 'desc';
        sort = field?.toString();
      }

      if (filters) {
        const dataFilter: any = filters;

        if (dataFilter) {
          Object.keys(dataFilter).forEach((key: string) => {
            if (dataFilter[key] !== null && dataFilter[key] !== undefined && dataFilter[key][0]) {
              search[key] = dataFilter[key][0].toString();
            } else if (dataFilter[key] !== null && dataFilter[key] !== undefined) {
              search[key] = dataFilter[key].toString();
            } else {
              search[key] = '';
            }
          });
        }
      }

      setExportsPaginationConfig(paginationConfigState => ({
        ...paginationConfigState,
        pageSize: pagination.pageSize,
        current: pagination.current,
      }));

      loadImportsTableData({
        page: pagination.current,
        per_page: pagination.pageSize,
        sort,
        direction,
        ...search,
      });
    },
    [loadImportsTableData],
  );

  function handleCustomRequestUploadFile(info: any) {
    const { file } = info;
    uploadForm.setFieldsValue({ document: [file] });
  }

  async function handleImport() {
    setUploadLoading(true);

    const formValues = uploadForm.getFieldsValue();
    let formData = new FormData();
    formData = formatValuesToFormData(formValues, formData, ['document']);

    generateImportApi(formData)
      .then((response: { data: { data: DTOImport } }) => {
        setExportsData(value => [response.data.data, ...value]);
        setExportsPaginationConfig(paginationConfigState => ({
          ...paginationConfigState,
          total: paginationConfigState.total + 1,
        }));

        /** reload reports while processing */
        if (!intervalProcess) {
          setIntervalProcess(true);
          setTimeout(() => {
            loadImportsTableData();
          }, processingReload);
        }

        handleReset();
      })
      .catch((err: DTOErrorReponse) => alertStatus(err, 'error'))
      .finally(() => setUploadLoading(false));
  }

  function handleChangeUploadFile(info: any) {
    let fileList = [...info.fileList];

    // 1. Limit the number of uploaded files
    // Only to show one recent uploaded files, and old ones will be replaced by the new
    fileList = fileList.slice(-1);

    // 2. Read from response and show file link
    fileList = fileList.map(file => {
      if (file.response) {
        // Component will show file.url as link
        file.url = file.response.url;
      }
      return file;
    });
    setUploadList(fileList);
  }

  return (
    <>
      {/** Modal export credit-requests */}
      <Modal
        {...modalProps}
        visible={visibility}
        className="modal-with-custom-footer"
        style={{ top: 20 }}
        width="60%"
        closable
        closeIcon={
          <MdClose
            onClick={() => {
              afterClose();
              clearTimeout(1);
            }}
          />
        }
        footer={
          <>
            <div className="buttons">
              <Button
                status="secondary"
                onClick={() => {
                  afterClose();
                  clearTimeout(1);
                }}
              >
                {t('pages.credit-request.modal-reports.buttons.close')}
              </Button>
            </div>
          </>
        }
      >
        <Button
          status="primary"
          onClick={() => {
            setVisibilityUploadModal(true);
          }}
        >
          <FaPlus /> {t('pages.edocuments.contracts.importations.new-importation')}
        </Button>
        <TableData
          rowKey="id"
          columns={columns.map(col => {
            let column: any = {
              ...col,
            };

            switch (column.key) {
              case 'created_at':
                column = {
                  ...col,
                  render: (_: any, row: DTOImport) => (row.created_at ? Formatter.datetime(row.created_at) : '-'),
                };
                break;
              case 'user':
                column = {
                  ...col,
                  render: (_: any, row: DTOImport) => (row.user ? row.user.name : '-'),
                };
                break;
              case 'status':
                column = {
                  ...col,
                  render: (_: any, row: DTOImport) => (
                    <>
                      <Spin size="small" indicator={<LoadingOutlined spin />} spinning={row.status === 'processing'} />
                      <span style={{ padding: '2px' }}>
                        {t(`pages.edocuments.contracts.importations.status.${row.status}`)}
                      </span>
                    </>
                  ),
                };
                break;
              case 'errors':
                column = {
                  ...col,
                  render: (_: any, row: DTOImport) => {
                    if (row.errors === null || row.errors === undefined) {
                      return 0;
                    }
                    return (
                      <a
                        href="#errors"
                        onClick={() => {
                          setErrorData(row.errors);
                          setVisibleModalError(true);
                        }}
                      >
                        {row.errors?.length ?? 0}
                      </a>
                    );
                  },
                };
                break;
              case 'imported':
                column = {
                  ...col,
                  render: (_: any, row: DTOImport) => {
                    return `${row.imported?.length ?? 0}/${row.total ?? 0}`;
                  },
                };
                break;
              case 'action':
                column = {
                  ...column,
                  render: (active: number, record: DTOImport) =>
                    record.status !== 'failed' && (
                      <IconWithTooltip
                        loading={downloadLoading.includes(record.id)}
                        action="download"
                        title={t('pages.credit-request.modal-reports.buttons.download')}
                        onClick={async () => {
                          setDownloadLoading(value => [...value, record.id]);
                          await documentApi
                            .download(record.document?.id)
                            .then((response: any) => downloadHandler(response))
                            .catch((err: DTOErrorReponse) => alertStatus(err, 'error'))
                            .finally(() => {
                              setDownloadLoading(
                                downloadLoading.filter(item => {
                                  return item.id !== record.id;
                                }),
                              );
                            });
                        }}
                      />
                    ),
                };
                break;
              default:
                return column;
            }

            return column;
          })}
          dataSource={exportsData}
          loading={exportsLoading}
          onChange={handleTableExportsChange}
          pagination={exportsPaginationConfig}
          tableConfig={tableDataExportsConfig}
        />
      </Modal>

      <Modal
        visible={visibleModalError}
        closeIcon={<MdClose onClick={() => setVisibleModalError(false)} />}
        title={t('pages.edocuments.contracts.importations.errors-lines')}
        style={{ top: 20 }}
        footer={
          <>
            <div className="buttons">
              <Button status="secondary" onClick={() => setVisibleModalError(false)}>
                {t('pages.credit-request.modal-reports.buttons.close')}
              </Button>
            </div>
          </>
        }
      >
        <List
          size="small"
          pagination={{
            pageSize: 10,
            showSizeChanger: false,
            size: 'small',
          }}
          dataSource={errorData}
          renderItem={item => <List.Item>{item}</List.Item>}
        />
      </Modal>
      <Modal
        closeIcon={<MdClose onClick={() => setVisibilityUploadModal(false)} />}
        closable={!uploadLoading}
        title={t('pages.edocuments.contracts.importations.title-upload')}
        visible={visibilityUploadModal}
        footer={
          <>
            <div className="buttons">
              <Button
                disabled={uploadLoading || uploadList.length === 0}
                status="primary"
                onClick={() => handleImport()}
              >
                {t('pages.edocuments.contracts.importations.send-upload')}
              </Button>
              <Button disabled={uploadLoading} status="primary-outline" onClick={() => handleReset()}>
                {t('pages.edocuments.contracts.importations.cancel-upload')}
              </Button>
            </div>
          </>
        }
        className="modal-with-custom-footer"
      >
        <Spin spinning={uploadLoading}>
          <Form form={uploadForm} name="contracts_importation">
            <FormItem hidden name="language" initialValue={i18n.language}>
              <Input />
            </FormItem>
            <FormItem hidden name="document">
              <Input />
            </FormItem>

            <SUpload>
              <Dragger
                showUploadList
                onChange={handleChangeUploadFile}
                fileList={uploadList}
                customRequest={handleCustomRequestUploadFile}
                accept=".xls,.xlsx"
              >
                {t('pages.edocuments.contracts.importations.upload-file-text')}
              </Dragger>
            </SUpload>
          </Form>
        </Spin>
      </Modal>
    </>
  );
};

export default ImportModal;

import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ColumnDef } from '@tanstack/react-table';
import { IconWithTooltip } from 'components';
import { ShouldRender } from 'components/ShouldRender';
import { useAlertDialog } from 'components/ui/alert-dialog';
import { DataTable } from 'components/ui/data-table';
import { Can } from 'hooks/ability';
import { useCache } from 'hooks/cache';
import { Formatter } from 'utils/Formatter';

import { useRegisteredCustomerStore } from '../stores/use-registered-customer-store';
import { SimpleAgriculturalPlanFormData } from './simple-agricultural-plan.form';

export const SimpleAgriculturalPlanTable = () => {
  const { t } = useTranslation();
  const form = useFormContext();
  const { alert } = useAlertDialog();
  const cache = useCache();

  const simpleAgriculturalPlans = form.watch('simple_agricultural_plan') as SimpleAgriculturalPlanFormData[];

  const setSimpleAgriculturalPlanToEdit = useRegisteredCustomerStore(state => state.setSimpleAgriculturalPlanToEdit);
  const setIsSimpleAgriculturalPlanModalOpen = useRegisteredCustomerStore(
    state => state.setIsSimpleAgriculturalPlanModalOpen,
  );
  const isViewing = useRegisteredCustomerStore(state => state.isViewing);

  const onDelete = (id: string) => {
    const simpleAgriculturalPlans = form.getValues('simple_agricultural_plan') as SimpleAgriculturalPlanFormData[];
    form.setValue(
      'simple_agricultural_plan',
      simpleAgriculturalPlans.filter(simpleAgriculturalPlan => simpleAgriculturalPlan.id !== id),
    );
  };

  const handleDelete = (simpleAgriculturalPlan: SimpleAgriculturalPlanFormData) => {
    alert({
      type: 'warning',
      title: t('delete-simple-agricultural-plan'),
      description: t('delete-simple-agricultural-plan-confirm', {
        simpleAgriculturalPlanName: cache
          .getPersonOptions('culture')
          .find(culture => simpleAgriculturalPlan.culture_id === culture.value)?.label,
      }),
      onOk: async () => {
        if (simpleAgriculturalPlan.id) await onDelete(simpleAgriculturalPlan.id.toString());
        alert({
          type: 'success',
          title: t('simple-agricultural-plan-deleted'),
          description: '',
        });
      },
    });
  };

  const columns: ColumnDef<SimpleAgriculturalPlanFormData>[] = [
    {
      accessorKey: 'culture_id',
      header: t('culture'),
      cell: ({ row }) => {
        const simpleAgriculturalPlan = row.original as SimpleAgriculturalPlanFormData;
        return cache.getPersonOptions('culture').find(culture => culture.value === simpleAgriculturalPlan.culture_id)
          ?.label;
      },
    },
    {
      accessorKey: 'leased',
      header: t('leased'),
      cell: ({ row }) => {
        const simpleAgriculturalPlan = row.original as SimpleAgriculturalPlanFormData;
        return Formatter.decimal(simpleAgriculturalPlan.leased);
      },
    },
    {
      accessorKey: 'own',
      header: t('own_area'),
      cell: ({ row }) => {
        const simpleAgriculturalPlan = row.original as SimpleAgriculturalPlanFormData;
        return Formatter.decimal(simpleAgriculturalPlan.own, 2, 3);
      },
    },
    {
      accessorKey: 'quantity_bags',
      header: t('quantity_bags'),
      cell: ({ row }) => {
        const simpleAgriculturalPlan = row.original as SimpleAgriculturalPlanFormData;
        return Formatter.decimal(simpleAgriculturalPlan.quantity_bags, 2, 3);
      },
    },
    {
      accessorKey: 'production_cost',
      header: t('production-cost-hectare'),
      cell: ({ row }) => {
        const simpleAgriculturalPlan = row.original as SimpleAgriculturalPlanFormData;
        return Formatter.money(simpleAgriculturalPlan.production_cost);
      },
    },
    {
      accessorKey: 'sale_price',
      header: t('sale-price-sack-arroba'),
      cell: ({ row }) => {
        const simpleAgriculturalPlan = row.original as SimpleAgriculturalPlanFormData;
        return Formatter.money(simpleAgriculturalPlan.sale_price);
      },
    },
    {
      accessorKey: 'total_productivity',
      header: t('total-productivity-sack-arroba'),
      cell: ({ row }) => {
        const simpleAgriculturalPlan = row.original as SimpleAgriculturalPlanFormData;
        return Formatter.decimal(simpleAgriculturalPlan.total_productivity, 2, 3);
      },
    },
    {
      accessorKey: 'total_planted_area',
      header: t('total_planted_area'),
      cell: ({ row }) => {
        const simpleAgriculturalPlan = row.original as SimpleAgriculturalPlanFormData;
        return Formatter.decimal(simpleAgriculturalPlan.total_planted_area, 2, 3);
      },
    },
    {
      id: 'actions',
      enablePinning: true,
      accessorKey: 'action',
      size: 100,
      header: t('actions'),
      cell: ({ row }) => {
        const partner = row.original as SimpleAgriculturalPlanFormData;

        return (
          <ShouldRender condition={!isViewing}>
            <IconWithTooltip
              permission="credit.request.package.destroy"
              action="destroy"
              title={t('pages.registered-customers.table.icons.remove')}
              // loading={partner.id && partnerToDelete === partner.id}
              onClick={() => handleDelete(partner)}
            />
            <Can I="credit.request.package.update">
              <IconWithTooltip
                action="edit"
                onClick={() => {
                  setSimpleAgriculturalPlanToEdit(partner);
                  setIsSimpleAgriculturalPlanModalOpen(true);
                }}
                title={t('pages.registered-customers.table.icons.edit')}
              />
            </Can>
          </ShouldRender>
        );
      },
    },
  ];

  return <DataTable columns={columns} data={simpleAgriculturalPlans ?? []} />;
};

import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';

import { ColumnDef } from '@tanstack/react-table';
import { DataTable } from 'components/ui/data-table';
import { PaginationList } from 'components/ui/pagination.list';
import appConfigs from 'configs/app';
import { usePagination } from 'hooks/usePagination';
import { CircleX } from 'lucide-react';
import moment from 'moment';
import { useGetRegisteredCustomerChangelogQuery } from 'pages/CreditRequest/api/use-get-registered-customer-changelog-query';
import { ChangelogData } from 'types/RegisteredCustomer/ChangelogData';

import { ChangelogValueTable } from './changelog-value.table';

type Props = {
  personType: 'physical' | 'legal';
  customerId: string;
};

export const ChangelogTable = ({ personType, customerId }: Props) => {
  const { paginationConfig, dispatchPagination } = usePagination();
  const { t } = useTranslation();

  const registeredCustomerChangelogQuery = useGetRegisteredCustomerChangelogQuery({
    customerId,
    personType,
    ...paginationConfig,
    dispatchPagination,
  });

  const columns: ColumnDef<ChangelogData>[] = [
    {
      header: t('name'),
      accessorKey: 'name',
      cell: ({ row, getValue }) => {
        return (
          <div className="flex gap-2 items-center">
            {row.getCanExpand() ? (
              <button
                className="p-1 rounded-md border-gray-200 border w-6 h-6 flex items-center justify-center"
                type="button"
                {...{
                  onClick: () => {
                    const handler = row.getToggleExpandedHandler();
                    handler();
                  },
                  style: { cursor: 'pointer' },
                }}
              >
                {row.getIsExpanded() ? '-' : '+'}
              </button>
            ) : null}
            {getValue()}
          </div>
        );
      },
    },
    {
      header: t('pages.registered_customers.detail_view.change_log.columns.event'),
      accessorKey: 'event',
    },
    {
      header: t('when'),
      accessorKey: 'created_at',
      cell: ({ row }) => {
        const changelog = row.original;
        return moment(changelog.created_at).format(appConfigs.formatDateFullTime);
      },
    },
  ];

  return (
    <ErrorBoundary
      FallbackComponent={() => (
        <div className="flex flex-col items-center text-red-600 text-lg font-bold text-center p-6">
          <CircleX size={40} />

          <span>{t('errorFallback.message')}</span>
        </div>
      )}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <div>
        <DataTable
          autoResetExpanded
          data={registeredCustomerChangelogQuery.data?.data ?? []}
          expandedRowContent={row => {
            const changelog = row.original;
            return (
              <tr>
                <td colSpan={row.getVisibleCells().length} aria-label="col">
                  <div className="p-4">
                    <ChangelogValueTable
                      data={changelog.values ?? []}
                      auditableType={changelog.auditable_type ?? ''}
                      personType={personType}
                      key={`${changelog.id}expanded`}
                    />
                  </div>
                </td>
              </tr>
            );
          }}
          columns={columns}
          getRowCanExpand={row => row.original.values.length > 0}
        />

        <PaginationList
          paginationState={paginationConfig}
          paginationDispatch={dispatchPagination}
          isLoading={registeredCustomerChangelogQuery.isLoading || registeredCustomerChangelogQuery.isFetching}
        />
      </div>
    </ErrorBoundary>
  );
};

/* eslint-disable react/jsx-boolean-value */
import React, { useCallback, useEffect, useState } from 'react';
import { ChromePicker } from 'react-color';
import { useTranslation } from 'react-i18next';
import { FaMinus, FaPlus, FaSearch } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';

import { Col, Form, Input as AntInput, InputNumber, Radio } from 'antd';
import { TablePaginationConfig, TableProps } from 'antd/lib/table';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';

import TeamStatusData, { DTOTeamStatusIndexNames } from '../../../types/CreditRequest/DTOTeamStatus';
import { TeamStatusOptionType } from '../../../@types/hooks/creditStatus';
import {
  Breadcrumb,
  Button,
  CellBorderColor,
  FormItem,
  IconWithTooltip,
  Input,
  ModalStatus,
  Row,
  Select,
  Spin,
} from '../../../components';
import { TableData } from '../../../compositions';
import { Can } from '../../../hooks/ability';
import { useCache } from '../../../hooks/cache';
import { useCreditStatus } from '../../../hooks/creditStatus';
import { usePage } from '../../../hooks/page';
import creditApi from '../../../services/api/credit';
import { getTranslations } from '../../../services/translations';
import { Formatter } from '../../../utils/Formatter';
import renderComponent from '../../../utils/RenderComponent';
import columns from './columns';
import {
  BoxColorPicker,
  ButtonColorPicker,
  Container,
  ContainerColorPicker,
  SDivider,
  SFormButtons,
  SFormContainer,
  SFilterContainer,
} from './styles';
import { DataTable } from './types';

const TeamStatus: React.FC = () => {
  const { t, i18n } = useTranslation();
  const [form] = Form.useForm();
  const [searchForm] = Form.useForm();

  const { updateCache, getCreditOptions, getCreditStatusOptions } = useCache();
  const { getListOfPhasesOrdered } = useCreditStatus();
  const { alertStatus } = usePage();

  const [data, setData] = useState<TeamStatusData[]>([]);
  const [loading, setLoading] = useState(false);
  const [paginationConfig, setPaginationConfig] = useState<TablePaginationConfig>({
    current: 1,
    total: 1,
    pageSize: 20,
  });

  const [visibilityForm, setVisibilityForm] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [editing, setEditing] = useState(false);
  const [colorTeamStatus, setColorTeamStatus] = useState<string>('#ffffff');
  const [colorPickerShow, setColorPickerShow] = useState<boolean>(false);

  // Table DATA Config: BEGIN
  const getActions = () => ({
    render: (active: number, record: any) => (
      <>
        <Can I="credit.request.teamstatus.destroy" a="">
          <IconWithTooltip
            action="destroy"
            title={t('pages.admin.team-credit-status.table.actions.removeIcon')}
            onClick={() => {
              ModalStatus({
                type: 'delete',
                title: t('pages.admin.team-credit-status.table.actions.title-delete'),
                subTitle: t('pages.admin.team-credit-status.table.actions.subtitle-delete'),
                cancelText: t('pages.admin.team-credit-status.table.actions.cancel-delete'),
                okText: t('pages.admin.team-credit-status.table.actions.confirm-delete'),
                onOk: () => {
                  creditApi.teamStatus
                    .delete(record.id, {
                      params: {},
                    })
                    .then(async response => {
                      alertStatus(response.message);
                      setLoading(true);
                      await updateCache(null);
                      // eslint-disable-next-line @typescript-eslint/no-use-before-define
                      loadTableData({
                        page: paginationConfig.current,
                        per_page: paginationConfig.pageSize,
                      });
                    })
                    .catch(err => alertStatus(err, 'error'));
                },
              });
            }}
          />
        </Can>
        <Can I="credit.request.teamstatus.update" a="">
          <IconWithTooltip
            action="edit"
            title={t('pages.admin.team-credit-status.table.actions.editIcon')}
            onClick={() => {
              const { translations } = record;

              let translationsPtBr = null;
              let translationsEn = null;
              let translationsCn = null;

              if (translations) {
                translationsPtBr = translations['pt-br'];
                translationsEn = translations.en;
                translationsCn = translations.cn;
              }

              form.setFieldsValue({
                ...record,
                'pt-br.title': translationsPtBr?.title,
                'pt-br.description': translationsPtBr?.description,
                'en.title': translationsEn?.title,
                'en.description': translationsEn?.description,
                'cn.title': translationsCn?.title,
                'cn.description': translationsCn?.description,
              });

              setColorTeamStatus(record.color || '#ffffff');

              form.scrollToField('name', {
                scrollMode: 'always',
                block: 'start',
                behavior: actions =>
                  actions.forEach(({ el, top }) => {
                    el.scrollTop = top - 280;
                  }),
              });

              setVisibilityForm(true);
              setEditing(true);
              setLoading(false);
              setLoadingButton(false);
              setDisabledButton(false);
            }}
          />
        </Can>
      </>
    ),
  });

  const tableConfig = {
    search: '',
    filtered: {},
    sorter: {},
    getActions,
  };

  const [tableDataConfig, setTableDataConfig] = useState(tableConfig);

  const loadTableData = useCallback(
    (values: Partial<DataTable>) => {
      setLoading(true);
      creditApi.teamStatus
        .get({
          params: {
            per_page: paginationConfig.pageSize,
            ...values,
          },
        })
        .then(response => {
          const result = response.data;
          setData(result.data);
          setPaginationConfig(paginationConfigState => ({
            ...paginationConfigState,
            current: result.current_page,
            total: result.total,
          }));
        })
        .catch(err => alertStatus(err, 'error'))
        .finally(() => setLoading(false));
    },
    // eslint-disable-next-line
    [i18n.language],
  );

  const handleFastSearch = (value: any) => {
    const search = value.search as string;

    loadTableData({
      name: search,
    });
  };

  const tableDataClearAllFilters = () => {
    searchForm.resetFields();
    setTableDataConfig(tableDataConfigState => ({
      ...tableDataConfigState,
      filtered: {},
      search: '',
      sorter: {},
    }));
    loadTableData({ page: 1 });
  };

  const handleTableChange: TableProps<any>['onChange'] = useCallback(
    (
      pagination: TablePaginationConfig,
      filters: Record<string, FilterValue | null>,
      sorter: SorterResult<any> | SorterResult<any>[],
    ) => {
      const searchValue = searchForm.getFieldValue('search') as string;

      setLoading(true);
      setTableDataConfig(tableDataConfigState => ({
        ...tableDataConfigState,
        filtered: filters,
        sorter,
      }));

      let direction = '';
      const search: any = {};
      let sort = '';

      if (sorter) {
        const { field, order } = sorter as SorterResult<any>;

        if (order === 'ascend') direction = 'asc';
        if (order === 'descend') direction = 'desc';
        sort = field?.toString();
      }

      if (filters) {
        const dataFilter: any = filters;

        if (dataFilter) {
          Object.keys(dataFilter).forEach((key: string) => {
            if (dataFilter[key] !== null && dataFilter[key] !== undefined && dataFilter[key][0]) {
              search[key] = dataFilter[key][0].toString();
            } else if (dataFilter[key] !== null && dataFilter[key] !== undefined) {
              search[key] = dataFilter[key].toString();
            } else {
              search[key] = '';
            }
          });
        }
      }

      setPaginationConfig(paginationConfigState => ({
        ...paginationConfigState,
        pageSize: pagination.pageSize,
        current: pagination.current,
      }));

      loadTableData({
        page: pagination.current,
        per_page: pagination.pageSize,
        sort,
        direction,
        name: searchValue || '',
        ...search,
      });
    },
    // eslint-disable-next-line
    [loadTableData],
  );

  useEffect(() => {
    const filters: any = {};
    handleTableChange({ current: 1, pageSize: paginationConfig.pageSize }, filters, {}, null);
    // eslint-disable-next-line
  }, [paginationConfig.pageSize]);
  // Table DATA Config: END

  const handleDisplayForm = useCallback(() => {
    setVisibilityForm(true);
    setEditing(false);
  }, []);

  const handleHideForm = useCallback(() => {
    setVisibilityForm(false);
    setEditing(false);
    setColorPickerShow(false);
    form.resetFields();
    // eslint-disable-next-line
  }, []);

  function changeNameSlug(value: any, field: string) {
    if (value) {
      const formValues = form.getFieldsValue();
      const search = (item: any, index: string) =>
        field === index ? item.value === value : item.value === formValues[index];

      const creditStatus = getCreditStatusOptions('credit_status').find(item => search(item, 'credit_status_id'));
      const phase = getCreditStatusOptions('team_credit_status_phase').find(item => search(item, 'phase_id'));
      const team = getCreditStatusOptions('team').find(item => search(item, 'team_id'));

      form.setFieldsValue({
        name:
          (creditStatus ? creditStatus.label : '') +
          (creditStatus && team ? '-' : '') +
          (team ? team.label : '') +
          (creditStatus && team && phase ? '-' : '') +
          (phase ? phase.label : ''),
        slug:
          (creditStatus ? creditStatus.slug : '') +
          (creditStatus && team ? '_' : '') +
          (team ? team.slug : '') +
          (creditStatus && team && phase ? '_' : '') +
          (phase ? phase.slug : ''),
      });
    }
  }

  const handleSave = useCallback(async () => {
    const formValues = form.getFieldsValue();
    setLoadingButton(true);
    setDisabledButton(true);

    const values: any = {
      translations: {
        'pt-br': {},
        en: {},
        cn: {},
      },
    };

    Object.keys(formValues).forEach(key => {
      const formKey = key.split('.');
      if (formKey.length > 1) {
        values.translations[formKey[0]][formKey[1]] = formValues[key];
      } else {
        values[key] = formValues[key];
      }
    });

    let validation = false;

    await form
      .validateFields()
      .then(() => {
        validation = true;
      })
      .catch(err => {
        if (err.errorFields.length === 0) {
          validation = true;
        }
      });

    if (validation) {
      const api = creditApi.teamStatus;
      let response;

      if (!formValues.id) {
        response = api.store({ ...values });
      } else {
        response = api.update(formValues.id, { ...values });
      }

      response
        .then(async () => {
          setLoading(true);
          handleHideForm();
          await updateCache(null);
          alertStatus(t('pages.admin.team-credit-status.form.success-save'));
          loadTableData({
            page: paginationConfig.current,
            per_page: paginationConfig.pageSize,
          });
        })
        .catch(err => alertStatus(err, 'error'))
        .finally(() => {
          handleHideForm();
          setLoadingButton(false);
          setDisabledButton(false);
        });
    }
    setLoadingButton(false);
    setDisabledButton(false);
    // eslint-disable-next-line
  }, []);

  return (
    <Container>
      <Breadcrumb items={[{ title: t('breadcrumb.admin') }, { title: t('breadcrumb.team-credit-status') }]} />

      <div className="content-container">
        <div className="grid-actions">
          <h2>{t('pages.admin.team-credit-status.title')}</h2>

          <div className="grid-button-action-header">
            <Can I="credit.request.teamstatus.store" a="">
              <Button
                status="primary"
                icon={visibilityForm ? <FaMinus /> : <FaPlus />}
                onClick={() => (visibilityForm ? handleHideForm() : handleDisplayForm())}
              >
                {renderComponent(
                  !visibilityForm,
                  t('pages.admin.team-credit-status.buttons.add'),
                  t('pages.admin.team-credit-status.buttons.close'),
                )}
              </Button>
            </Can>
          </div>
        </div>

        <SFormContainer visible={visibilityForm}>
          <Spin spinning={loading}>
            <h2>
              {renderComponent(
                !editing,
                t('pages.admin.team-credit-status.title-add'),
                t('pages.admin.team-credit-status.title-edit'),
              )}
            </h2>

            <Form form={form} onSubmitCapture={handleSave}>
              <FormItem name="id" hidden>
                <Input />
              </FormItem>

              <Row gutter={[26, 26]}>
                <Col xs={24} sm={24} md={24} lg={10} xl={10} xxl={10}>
                  <FormItem
                    label={t('pages.admin.team-credit-status.form.status')}
                    name="credit_status_id"
                    rules={[{ required: true }]}
                  >
                    <Select
                      options={getCreditStatusOptions('credit_status')}
                      onChange={value => changeNameSlug(value, 'credit_status_id')}
                    />
                  </FormItem>
                </Col>
                <Col xs={24} sm={24} md={24} lg={10} xl={10} xxl={10}>
                  <FormItem
                    label={t('pages.admin.team-credit-status.form.team')}
                    name="team_id"
                    // rules={[{ required: true }]}
                  >
                    <Select
                      options={getCreditStatusOptions('team')}
                      onChange={value => changeNameSlug(value, 'team_id')}
                    />
                  </FormItem>
                </Col>
                <Col xs={24} sm={24} md={24} lg={4} xl={4} xxl={4}>
                  <FormItem label={t('pages.admin.team-credit-status.form.order')} name="order">
                    <InputNumber min={0} />
                  </FormItem>
                </Col>
              </Row>

              <Row gutter={[26, 26]}>
                <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                  <FormItem label={t('pages.admin.team-credit-status.form.phase')} name="phase_id">
                    <Select
                      options={getListOfPhasesOrdered(true) as TeamStatusOptionType[]}
                      onChange={value => changeNameSlug(value, 'phase_id')}
                    />
                  </FormItem>
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                  <FormItem label={t('pages.admin.team-credit-status.form.name')} name="name">
                    <Input disabled />
                  </FormItem>
                </Col>

                <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                  <FormItem label={t('pages.admin.team-credit-status.form.slug')} name="slug">
                    <Input disabled />
                  </FormItem>
                </Col>
              </Row>

              <Row gutter={[26, 26]}>
                <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                  <FormItem name="color" initialValue="" hidden>
                    <Input />
                  </FormItem>
                  <FormItem label={t('pages.admin.team-credit-status.form.color')}>
                    <ContainerColorPicker>
                      <ButtonColorPicker
                        colorHex={colorTeamStatus}
                        type="button"
                        onClick={() => setColorPickerShow(true)}
                      />

                      {colorPickerShow && (
                        <BoxColorPicker>
                          <div className="rightClose">
                            <MdClose onClick={() => setColorPickerShow(false)} />
                          </div>
                          <ChromePicker
                            color={colorTeamStatus}
                            onChangeComplete={color => {
                              if (color) {
                                form.setFieldsValue({
                                  color: color.hex,
                                });
                                setColorTeamStatus(color.hex);
                              }
                              return false;
                            }}
                          />
                        </BoxColorPicker>
                      )}
                    </ContainerColorPicker>
                  </FormItem>
                </Col>
              </Row>

              <h2>{t('pages.admin.team-credit-status.form.translations')}</h2>

              <SDivider orientation="left">{t('header.lang.pt-br')}</SDivider>

              <Row gutter={[26, 26]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                  <FormItem
                    label={t('pages.admin.team-credit-status.form.title')}
                    name="pt-br.title"
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </FormItem>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                  <FormItem
                    label={t('pages.admin.team-credit-status.form.description')}
                    name="pt-br.description"
                    rules={[{ required: true }]}
                  >
                    <AntInput.TextArea rows={3} />
                  </FormItem>
                </Col>
              </Row>

              <SDivider orientation="left">{t('header.lang.en')}</SDivider>

              <Row gutter={[26, 26]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                  <FormItem
                    label={t('pages.admin.team-credit-status.form.title')}
                    name="en.title"
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </FormItem>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                  <FormItem
                    label={t('pages.admin.team-credit-status.form.description')}
                    name="en.description"
                    rules={[{ required: true }]}
                  >
                    <AntInput.TextArea rows={3} />
                  </FormItem>
                </Col>
              </Row>

              <SDivider orientation="left">{t('header.lang.cn')}</SDivider>

              <Row gutter={[26, 26]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                  <FormItem
                    label={t('pages.admin.team-credit-status.form.title')}
                    name="cn.title"
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </FormItem>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                  <FormItem
                    label={t('pages.admin.team-credit-status.form.description')}
                    name="cn.description"
                    rules={[{ required: true }]}
                  >
                    <AntInput.TextArea rows={3} />
                  </FormItem>
                </Col>
              </Row>

              <div className="bordered-box">
                <h2>{t('pages.admin.team-credit-status.form.rules.priority.title')}</h2>

                <Row gutter={[26, 26]}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                    <Form.List name="rules_priority">
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map(field => (
                            <Row gutter={[26, 26]} key={`field-list-item-${field.key}`}>
                              <FormItem name={[field.name, 'id']} hidden>
                                <Input />
                              </FormItem>
                              <FormItem name={[field.name, 'type']} hidden initialValue="priority">
                                <Input />
                              </FormItem>

                              <Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={6}>
                                <FormItem
                                  label={t('pages.admin.team-credit-status.form.rules.priority.operation')}
                                  name={[field.name, 'operation_id']}
                                  fieldKey={[field.fieldKey, 'operation_id']}
                                  initialValue={null}
                                  rules={[{ required: true }]}
                                >
                                  <Select options={getCreditOptions('operation')} />
                                </FormItem>
                              </Col>
                              <Col xs={24} sm={24} md={12} lg={7} xl={7} xxl={7}>
                                <FormItem
                                  label={t('pages.admin.team-credit-status.form.rules.priority.target-status')}
                                  name={[field.name, 'team_credit_status_target_id']}
                                  fieldKey={[field.fieldKey, 'team_credit_status_target_id']}
                                  initialValue={null}
                                  rules={[{ required: true }]}
                                >
                                  <Select options={getCreditStatusOptions('team_credit_status')} />
                                </FormItem>
                              </Col>
                              <Col xs={24} sm={24} md={12} lg={7} xl={7} xxl={7}>
                                <FormItem
                                  label={t('pages.admin.team-credit-status.form.rules.priority.position')}
                                  name={[field.name, 'position_id']}
                                  fieldKey={[field.fieldKey, 'position_id']}
                                  initialValue={null}
                                >
                                  <Select options={getCreditOptions('position')} />
                                </FormItem>
                              </Col>

                              <Col xs={24} sm={24} md={12} lg={4} xl={4} xxl={4}>
                                <div className="button-left">
                                  <IconWithTooltip
                                    action="destroy"
                                    title={t('pages.admin.team-credit-status.form.rules.remove-rule')}
                                    onClick={() => remove(field.name)}
                                  />
                                </div>
                              </Col>
                            </Row>
                          ))}

                          <Button status="info-outline" type="dashed" onClick={() => add()} block icon={<FaPlus />}>
                            {t('pages.admin.team-credit-status.form.rules.add-rule')}
                          </Button>
                        </>
                      )}
                    </Form.List>
                  </Col>
                </Row>
              </div>

              <div className="bordered-box">
                <h2>{t('pages.admin.team-credit-status.form.rules.target.title')}</h2>

                <Row gutter={[26, 26]}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                    <Form.List name="rules_target">
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map(field => (
                            <Row gutter={[26, 26]} key={`field-list-item-${field.key}`}>
                              <FormItem name={[field.name, 'id']} hidden>
                                <Input />
                              </FormItem>
                              <FormItem name={[field.name, 'type']} hidden initialValue="target">
                                <Input />
                              </FormItem>

                              <Col xs={24} sm={24} md={8} lg={6} xl={6} xxl={6}>
                                <FormItem
                                  label={t('pages.admin.team-credit-status.form.rules.target.operation')}
                                  name={[field.name, 'operation_id']}
                                  fieldKey={[field.fieldKey, 'operation_id']}
                                  initialValue={null}
                                  rules={[{ required: true }]}
                                >
                                  <Select options={getCreditOptions('operation')} />
                                </FormItem>
                              </Col>
                              <Col xs={24} sm={24} md={8} lg={7} xl={7} xxl={7}>
                                <FormItem
                                  label={t('pages.admin.team-credit-status.form.rules.target.target-status')}
                                  name={[field.name, 'team_credit_status_target_id']}
                                  fieldKey={[field.fieldKey, 'team_credit_status_target_id']}
                                  initialValue={null}
                                  rules={[{ required: true }]}
                                >
                                  <Select options={getCreditStatusOptions('team_credit_status')} />
                                </FormItem>
                              </Col>
                              <Col xs={24} sm={24} md={8} lg={5} xl={5} xxl={5}>
                                <FormItem
                                  label={t('pages.admin.team-credit-status.form.rules.target.position')}
                                  name={[field.name, 'position_id']}
                                  fieldKey={[field.fieldKey, 'position_id']}
                                  initialValue={null}
                                >
                                  <Select options={getCreditOptions('position')} />
                                </FormItem>
                              </Col>
                              <Col xs={20} sm={20} md={20} lg={5} xl={5} xxl={5}>
                                <FormItem
                                  label={t('pages.admin.team-credit-status.form.rules.target.is_attachment_required')}
                                  name={[field.name, 'is_attachment_required']}
                                  fieldKey={[field.fieldKey, 'is_attachment_required']}
                                  initialValue={false}
                                >
                                  <Radio.Group className="custom-radio-group small-radio-group" buttonStyle="solid">
                                    <Radio.Button key="is_attachment_required_yes" value={true}>
                                      {t('pages.admin.team-credit-status.form.rules.target.is_attachment_required_yes')}
                                    </Radio.Button>
                                    <Radio.Button key="is_attachment_required_not" value={false}>
                                      {t('pages.admin.team-credit-status.form.rules.target.is_attachment_required_not')}
                                    </Radio.Button>
                                  </Radio.Group>
                                </FormItem>
                              </Col>

                              <Col xs={4} sm={4} md={4} lg={1} xl={1} xxl={1}>
                                <div className="button-left">
                                  <IconWithTooltip
                                    action="destroy"
                                    title={t('pages.admin.team-credit-status.form.rules.remove-rule')}
                                    onClick={() => remove(field.name)}
                                  />
                                </div>
                              </Col>
                            </Row>
                          ))}

                          <Button status="info-outline" type="dashed" onClick={() => add()} block icon={<FaPlus />}>
                            {t('pages.admin.team-credit-status.form.rules.add-rule')}
                          </Button>
                        </>
                      )}
                    </Form.List>
                  </Col>
                </Row>
              </div>

              <div className="bordered-box">
                <h2>{t('pages.admin.team-credit-status.form.rules.limit.title')}</h2>

                <Row gutter={[26, 26]}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                    <Form.List name="rules_limit">
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map(field => (
                            <Row gutter={[26, 26]} key={`field-list-item-${field.key}`}>
                              <FormItem name={[field.name, 'id']} hidden>
                                <Input />
                              </FormItem>
                              <FormItem name={[field.name, 'type']} hidden initialValue="register">
                                <Input />
                              </FormItem>

                              <Col xs={24} sm={24} md={9} lg={9} xl={9} xxl={9}>
                                <FormItem
                                  label={t('pages.admin.team-credit-status.form.rules.target.operation')}
                                  name={[field.name, 'operation_id']}
                                  fieldKey={[field.fieldKey, 'operation_id']}
                                  initialValue={null}
                                  rules={[{ required: true }]}
                                >
                                  <Select options={getCreditOptions('operation')} />
                                </FormItem>
                              </Col>
                              <Col xs={24} sm={24} md={5} lg={5} xl={5} xxl={5}>
                                <FormItem
                                  label={t('pages.admin.team-credit-status.form.rules.target.sub_division')}
                                  name={[field.name, 'sub_division_id']}
                                  fieldKey={[field.fieldKey, 'sub_division_id']}
                                  initialValue={null}
                                  rules={[{ required: true }]}
                                >
                                  <Select options={getCreditOptions('sub_division')} />
                                </FormItem>
                              </Col>
                              <Col xs={24} sm={24} md={9} lg={9} xl={9} xxl={9}>
                                <FormItem
                                  label={t('pages.admin.team-credit-status.form.rules.target.position')}
                                  name={[field.name, 'position_id']}
                                  fieldKey={[field.fieldKey, 'position_id']}
                                  initialValue={null}
                                >
                                  <Select options={getCreditOptions('position')} />
                                </FormItem>
                              </Col>

                              <Col xs={4} sm={4} md={4} lg={1} xl={1} xxl={1}>
                                <div className="button-left">
                                  <IconWithTooltip
                                    action="destroy"
                                    title={t('pages.admin.team-credit-status.form.rules.remove-rule')}
                                    onClick={() => remove(field.name)}
                                  />
                                </div>
                              </Col>
                            </Row>
                          ))}

                          <Button status="info-outline" type="dashed" onClick={() => add()} block icon={<FaPlus />}>
                            {t('pages.admin.team-credit-status.form.rules.add-rule')}
                          </Button>
                        </>
                      )}
                    </Form.List>
                  </Col>
                </Row>
              </div>

              <div className="bordered-box">
                <h2>{t('pages.admin.team-credit-status.form.rules.approval_limit.title')}</h2>

                <Row gutter={[26, 26]}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                    <Form.List name="rules_approval_limit">
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map(field => (
                            <Row gutter={[26, 26]} key={`field-list-item-${field.key}`}>
                              <FormItem name={[field.name, 'id']} hidden>
                                <Input />
                              </FormItem>
                              <FormItem name={[field.name, 'type']} hidden initialValue="approval">
                                <Input />
                              </FormItem>

                              <Col xs={24} sm={24} md={9} lg={9} xl={9} xxl={9}>
                                <FormItem
                                  label={t('pages.admin.team-credit-status.form.rules.target.operation')}
                                  name={[field.name, 'operation_id']}
                                  fieldKey={[field.fieldKey, 'operation_id']}
                                  initialValue={null}
                                  rules={[{ required: true }]}
                                >
                                  <Select options={getCreditOptions('operation')} />
                                </FormItem>
                              </Col>

                              <Col xs={24} sm={24} md={5} lg={5} xl={5} xxl={5}>
                                <FormItem
                                  label={t('pages.admin.team-credit-status.form.rules.target.sub_division')}
                                  name={[field.name, 'sub_division_id']}
                                  fieldKey={[field.fieldKey, 'sub_division_id']}
                                  initialValue={null}
                                  rules={[{ required: true }]}
                                >
                                  <Select options={getCreditOptions('sub_division')} />
                                </FormItem>
                              </Col>
                              <Col xs={24} sm={24} md={9} lg={9} xl={9} xxl={9}>
                                <FormItem
                                  label={t('pages.admin.team-credit-status.form.rules.target.position')}
                                  name={[field.name, 'position_id']}
                                  fieldKey={[field.fieldKey, 'position_id']}
                                  initialValue={null}
                                >
                                  <Select options={getCreditOptions('position')} />
                                </FormItem>
                              </Col>

                              <Col xs={4} sm={4} md={4} lg={1} xl={1} xxl={1}>
                                <div className="button-left">
                                  <IconWithTooltip
                                    action="destroy"
                                    title={t('pages.admin.team-credit-status.form.rules.remove-rule')}
                                    onClick={() => remove(field.name)}
                                  />
                                </div>
                              </Col>
                            </Row>
                          ))}

                          <Button status="info-outline" type="dashed" onClick={() => add()} block icon={<FaPlus />}>
                            {t('pages.admin.team-credit-status.form.rules.add-rule')}
                          </Button>
                        </>
                      )}
                    </Form.List>
                  </Col>
                </Row>
              </div>

              <SFormButtons>
                <Button status="secondary" htmlType="reset" onClick={handleHideForm} disabled={disabledButton}>
                  {t('pages.admin.team-credit-status.buttons.cancel')}
                </Button>

                {renderComponent(
                  editing,
                  <Button status="primary" htmlType="submit" loading={loadingButton} disabled={disabledButton}>
                    {t('pages.admin.team-credit-status.buttons.edit')}
                  </Button>,
                  <Button status="primary" htmlType="submit" loading={loadingButton} disabled={disabledButton}>
                    {t('pages.admin.team-credit-status.buttons.save')}
                  </Button>,
                )}
              </SFormButtons>
            </Form>
          </Spin>
        </SFormContainer>

        <SFilterContainer>
          <Form
            form={searchForm}
            name="filter-permission"
            className="form-secondary form-filters grid-filters"
            onFinish={handleFastSearch}
          >
            <div className="filter-search">
              <FormItem name="search" label={t('pages.admin.permission.search')}>
                <Input />
              </FormItem>
            </div>
            <div className="filter-button">
              <FormItem label="" className="form-item-without-label">
                <Button status="primary" text="uppercase" htmlType="submit">
                  <FaSearch /> {t('form.actions.search')}
                </Button>
              </FormItem>
            </div>
            <div className="filter-clear">
              <Button status="secondary" size="middle" onClick={tableDataClearAllFilters}>
                {t('pages.admin.permission.buttonClearFilter')}
              </Button>
            </div>
          </Form>
        </SFilterContainer>

        <TableData
          rowKey="id"
          dataSource={data}
          columns={columns.status.map(col => {
            const column: any = col;
            switch (column.key) {
              case 'title':
                return {
                  ...column,
                  render: (_, record: TeamStatusData) => {
                    return (
                      <CellBorderColor color={record.color}>
                        {getTranslations(record, 'title', i18n.language)}
                      </CellBorderColor>
                    );
                  },
                };
              case 'created_at':
              case 'updated_at':
                return {
                  ...column,
                  render: (_, record: TeamStatusData) => {
                    const key: DTOTeamStatusIndexNames = column.key as DTOTeamStatusIndexNames;
                    return record[key] ? Formatter.datetime(record[key].toString()) : '';
                  },
                };
              default:
                return column;
            }
          })}
          loading={loading}
          onChange={handleTableChange}
          pagination={paginationConfig}
          tableConfig={tableDataConfig}
        />
      </div>
    </Container>
  );
};

export default TeamStatus;

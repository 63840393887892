import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Breadcrumb } from '../../../components';
import { SContainer, SHeader, STitlePage } from '../../../styles';
import { CCard, Chart, Filters, CSkeleton, TableTimeline } from '../components';
import { TopCards, ChartsArea, TableArea, NotificationPtax } from '../styles';
import { PropsHandleOptionButtons } from '../types';
import { getCards, getItemsChart } from '../utils';
import { useManagement } from './hooks/useManagement';
import { useChartCreditProps, propsSkeletonCard, propsSkeletonCharts, propsFilters } from './props';
import { TableResume } from './TableResume';

const Management: React.FC = () => {
  const {
    handleCardsManagement,
    handleChartsManagement,
    handleChangeTableTimeline,
    getTimelineReport,
    generateExportTimeline,
    getResumeReport,
    generateExportResume,
    isFetchingCharts,
    isFetchingCards,
    isFetchingTimeline,
    filterForm,
    searchFormTimeline,
    searchFormResume,
  } = useManagement();
  const { propsChartCreditOperation, propsChartRequest, propsChartPrepayLimit, propsChartCredit } =
    useChartCreditProps();
  const { t } = useTranslation();
  const [chartEvent, setChartEvent] = useState([]);
  const [propsCards, setPropsCards] = useState([]);
  const [propsCharts, setPropsChart] = useState([]);
  const [handleOptionButtons, setHandleOptionButtons] = useState<PropsHandleOptionButtons>({
    key: undefined,
    buttonValue: 0,
  });
  const [propsChartCopy, setPropsChartCopy] = useState([]);
  const [searchFiltersTimeline, setSearchFiltersTimeline] = useState({});
  const [searchFiltersResume, setSearchFiltersResume] = useState({});

  const getCharts = async () => {
    const { dataChartCreditOperation, dataChartRequest, dataChartPrepay, dataChartCredit } =
      await handleChartsManagement();
    setPropsChart([
      propsChartCreditOperation(dataChartCreditOperation, t),
      propsChartRequest(dataChartRequest, t),
      propsChartPrepayLimit(dataChartPrepay, t),
      propsChartCredit(dataChartCredit, t),
    ]);
  };

  const getItems = (item: string, buttonValue: number, prop: any) => {
    getItemsChart({ prop, item, buttonValue, chartEvent, setChartEvent, setPropsChart });
  };

  const handleGet = () => {
    setPropsCards([]);
    setPropsChart([]);
    setPropsChartCopy([]);
    setChartEvent([]);
    getCards(handleCardsManagement, setPropsCards);
    getCharts();
  };

  useEffect(() => {
    handleGet();
  }, []);

  useEffect(() => {
    const filter = filterForm.getFieldsValue();
    if (filter) {
      filter.requesterDate = undefined;
      setSearchFiltersTimeline(filter);
      setSearchFiltersResume(filter);
    }
  }, [filterForm]);

  useEffect(() => {
    if (propsCharts.length) setPropsChartCopy(propsCharts);
  }, [propsCharts]);

  useEffect(() => {
    if (handleOptionButtons.key) {
      getItems(handleOptionButtons.key, handleOptionButtons.buttonValue, propsChartCopy);
    }
  }, [handleOptionButtons]);

  return (
    <>
      <Breadcrumb items={[{ title: t('breadcrumb.home'), to: '/home' }, { title: t('breadcrumb.management') }]} />
      <SContainer>
        <SHeader>
          <STitlePage>{t('pages.dashboard.management.title')}</STitlePage>
        </SHeader>
        <Filters
          props={propsFilters}
          filterForm={filterForm}
          handleGet={handleGet}
          setSearchFiltersTableFirst={setSearchFiltersTimeline}
          setSearchFiltersTableSecond={setSearchFiltersResume}
          searchForm={[searchFormTimeline, searchFormResume]}
        />
        <NotificationPtax>{t('pages.dashboard.notificationPTAX')}</NotificationPtax>
        <TopCards>
          {isFetchingCards ? (
            <CSkeleton style={propsSkeletonCard} numberItems={propsSkeletonCard.number} />
          ) : (
            propsCards.map((item: any) => <CCard key={item.key} item={item} />)
          )}
        </TopCards>
        <ChartsArea>
          {isFetchingCharts ? (
            <CSkeleton style={propsSkeletonCharts} numberItems={propsSkeletonCharts.number} />
          ) : (
            propsCharts.map((item: any) => (
              <Chart
                key={item.key}
                chartProps={{ ...item }}
                setChartEvent={setChartEvent}
                chartEvent={chartEvent}
                getItems={getItems}
                referenceKey={item.key}
                setHandleOptionButtons={setHandleOptionButtons}
                setPropsChart={setPropsChart}
                propsChartCopy={propsChartCopy}
                propsChartsOriginal={propsCharts}
                setPropsChartCopy={setPropsChartCopy}
              />
            ))
          )}
        </ChartsArea>
        <TableArea>
          {Object.keys(searchFiltersTimeline).length && (
            <TableTimeline
              searchFilters={searchFiltersTimeline}
              handleChangeTableTimeline={handleChangeTableTimeline}
              isFetchingTimeline={isFetchingTimeline}
              setSearchFilters={setSearchFiltersTimeline}
              searchForm={searchFormTimeline}
              management
              title={t('pages.dashboard.timeline.title')}
              listReportPermissions={['dashboard.export.timeline.index']}
              generateReportPermissions={['dashboard.export.timeline.store']}
              getReport={getTimelineReport}
              generateExport={generateExportTimeline}
            />
          )}
        </TableArea>
        <TableArea>
          {Object.keys(searchFiltersResume).length && (
            <TableResume
              searchFilters={searchFiltersResume}
              setSearchFilters={setSearchFiltersResume}
              searchForm={searchFormResume}
              listReportPermissions={['dashboard.export.summary.index']}
              generateReportPermissions={['dashboard.export.summary.store']}
              getReport={getResumeReport}
              generateExport={generateExportResume}
            />
          )}
        </TableArea>
      </SContainer>
    </>
  );
};

export default Management;

import React, { KeyboardEvent, MouseEvent, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { FaSearch } from 'react-icons/fa';

import { Input, Space } from 'antd';

import { Button } from '../../components';
import { Container } from './styles';

interface FilterDropdownProps {
  title: string;
  inputOnChange(event: ChangeEvent<HTMLInputElement>): void;
  inputOnPressEnter(event: KeyboardEvent<HTMLInputElement>): void;
  inputValue: string;
  buttonClearOnClick(event: MouseEvent<HTMLInputElement>): void;
  buttonSearchOnClick(event: MouseEvent<HTMLInputElement>): void;
}

const FilterDropdown: React.FC<FilterDropdownProps> = ({
  title,
  inputOnChange,
  inputOnPressEnter,
  inputValue,
  buttonClearOnClick,
  buttonSearchOnClick,
}) => {
  const { t } = useTranslation();

  return (
    <Container>
      <Input
        placeholder={`${t('Search')} ${t(title)}`}
        value={inputValue}
        onPressEnter={inputOnPressEnter}
        onChange={inputOnChange}
      />
      <Space>
        <Button status="secondary" size="small" onClick={buttonClearOnClick}>
          {t('table.filterReset')}
        </Button>
        <Button icon={<FaSearch />} status="primary" size="small" onClick={buttonSearchOnClick}>
          {t('table.filterSearch')}
        </Button>
      </Space>
    </Container>
  );
};

export default FilterDropdown;

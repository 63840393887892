import styled from 'styled-components';

export const Container = styled.div`
  h2 {
    font-size: clamp(16px, 1vw, 22px);
  }

  h4 {
    font-size: clamp(14px, 1vw, 16px);
  }

  p {
    font-size: clamp(12px, 1vw, 14px);
  }
`;

export const Code = styled.p`
  width: 100%;
  text-align: center;
  padding: 10px 10px;
  margin: 10px 0;
  letter-spacing: 3px;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    background: ${({ theme: { colors } }) => colors.greys.grey01};
  }
`;

export const ConfigurationText = styled.h4`
  margin: 20px 0;
`;

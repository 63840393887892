import React, { useCallback, useEffect, useState } from 'react';

import { Spin } from 'components';

import { Container } from './styles';

export const DocxViewer = ({
  url,
  useExternalRenderApi,
  height,
}: {
  url: string;
  useExternalRenderApi?: boolean;
  height?: string;
}) => {
  const iframeRef = React.useRef<HTMLIFrameElement | undefined>();

  const [currentUrl, setCurrentUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const getIframeLink = useCallback(async () => {
    await new Promise(() => {
      setIsLoading(true);
      setTimeout(() => {
        setCurrentUrl(
          useExternalRenderApi ? `https://view.officeapps.live.com/op/embed.aspx?src=${url}` : decodeURIComponent(url),
        );

        if (!useExternalRenderApi) setIsLoading(false);
      }, 1);

      // recarrega o iframe para liberar o link do office
      setTimeout(() => {
        if (iframeRef.current && useExternalRenderApi)
          iframeRef.current.src = useExternalRenderApi
            ? `https://view.officeapps.live.com/op/embed.aspx?src=${url}`
            : decodeURIComponent(url);
        setIsLoading(false);
      }, 2000);
    });
  }, [url, useExternalRenderApi]);

  useEffect(() => {
    getIframeLink();
  }, [url]);

  return (
    <Container>
      <Spin spinning={isLoading}>
        {currentUrl && (
          <iframe
            title="Candidate Document"
            style={{ width: '100%', height: height || '90vh', opacity: isLoading ? '0' : '1' }}
            src={currentUrl}
            ref={iframeRef}
          />
        )}
      </Spin>
    </Container>
  );
};

import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FaPlus, FaThLarge, FaThList, FaFileExcel, FaSearch } from 'react-icons/fa';
import { Link, useParams } from 'react-router-dom';

import { Form as AntForm, Input, Radio } from 'antd';

import { PageModuleConfig, pageConfigDefault } from '../../types/User/UserData';
import { Button, FormItem, Select, Spin } from '../../components';
import { Can, useAbility } from '../../hooks/ability';
import { useAuth } from '../../hooks/auth';
import { useCache } from '../../hooks/cache';
import DetailView from './DetailView';
import KanbanView from './KanbanView';
import { Filter } from './KanbanView/CustomersKanban/types';
import ListView from './ListView';
import { Container } from './styles';
import { PageLoadings, PageParams, FormFields } from './types';

const RegisteredCustomers: React.FC = () => {
  const params = useParams<PageParams>();
  const { personType: personTypeParam, permission: actionPermissionParam } = params;

  const [isKanban, setIsKanban] = useState(false);
  const ability = useAbility();
  const { getPersonOptions } = useCache();
  const {
    user: { config: userConfig },
    updateUserConfig,
  } = useAuth();

  const [personType, setPersonType] = useState<'physical' | 'legal'>(
    ability.can('person.physical.index', '') ? 'physical' : 'legal',
  );

  const [filterValues, setFilterValues] = useState<Filter>();
  const [loading, setLoading] = useState<PageLoadings>(() => ({
    list: false,
    form: false,
    filter: false,
  }));
  const [exporting, setExporting] = useState(false);
  const { t, i18n } = useTranslation();
  const [formFilter] = AntForm.useForm();
  const [options, setOptions] = useState(() => ({
    person_stage: [],
  }));

  const [pageConfigs, setPageConfigs] = useState<PageModuleConfig>(() => {
    const configs = userConfig?.modules.person.pageView;

    if (!configs) {
      const personKanbanDefault: PageModuleConfig = {} as PageModuleConfig;
      personKanbanDefault.legal = pageConfigDefault;
      personKanbanDefault.physical = pageConfigDefault;
      return personKanbanDefault as PageModuleConfig;
    }

    return configs;
  });

  useEffect(() => {
    setOptions({
      person_stage: getPersonOptions('person_stage'),
    });
  }, [getPersonOptions, i18n.language]);

  useEffect(() => {
    if (pageConfigs && pageConfigs[personType]) {
      setIsKanban(pageConfigs[personType] === 'kanban');
    }
  }, [pageConfigs, personType]);

  useEffect(() => {
    if (formFilter) {
      const filterData = formFilter.getFieldsValue();
      setFilterValues(filterData as Filter);
    }
  }, [isKanban, formFilter]);

  const toogleView = useCallback(
    (event: any, mode: 'kanban' | 'table') => {
      setIsKanban(mode === 'kanban');
      const updateConfigs: PageModuleConfig = {
        ...pageConfigs,
        [personType]: mode,
      };

      setPageConfigs(updateConfigs);
      userConfig.modules.person.pageView = updateConfigs;
      updateUserConfig(userConfig);
    },
    [setIsKanban, setPageConfigs, pageConfigs, personType, userConfig, updateUserConfig],
  );

  const triggerExport = useCallback(() => {
    setExporting(state => !state);
    setTimeout(() => {
      setExporting(state => !state);
    }, 1000);
  }, [setExporting]);

  const handleFilter = useCallback(
    (values: FormFields) => {
      setLoading({ ...loading, filter: true });

      const filters: any = {};

      Object.keys(values).forEach((value: string) => {
        filters[value] = values[value] ? values[value] : '';
      });

      setFilterValues(filters as Filter);
      setLoading({ ...loading, filter: false });
    },
    [loading],
  );

  function renderVisualization() {
    if (filterValues) {
      return isKanban ? (
        <section className="kanban-view">
          {personType === 'physical' && <KanbanView filterData={filterValues} person="physical" />}
          {personType === 'legal' && <KanbanView filterData={filterValues} person="legal" />}
        </section>
      ) : (
        <section className="list-view">
          <ListView filterData={filterValues} triggerExport={exporting} personType={personType} />
        </section>
      );
    }

    return <div />;
  }

  function renderIndex() {
    if (!(personTypeParam && actionPermissionParam)) {
      return (
        <div className="content-container">
          <section className="grid-actions">
            <div className="export-excel">
              {!isKanban && (
                <Button status="primary-outline" text="uppercase" onClick={triggerExport}>
                  <FaFileExcel /> {t('form.actions.export')}
                </Button>
              )}
            </div>
            <div className="kanban-view">
              <Button button="icon" status={isKanban ? 'primary' : 'secondary'} onClick={e => toogleView(e, 'kanban')}>
                <FaThLarge />
              </Button>
            </div>
            <div className="list-view">
              <Button button="icon" status={!isKanban ? 'primary' : 'secondary'} onClick={e => toogleView(e, 'table')}>
                <FaThList />
              </Button>
            </div>
            <div className="btn-new">
              <Can I={`person.${personType}.store`} a="">
                <Button status="primary" block>
                  <Link to={`/registered-customers/${personType}/new`}>
                    <FaPlus />{' '}
                    {personType === 'legal'
                      ? t('pages.registered-customers.table.icons.addPersonLegal')
                      : t('pages.registered-customers.table.icons.addPersonPhysical')}
                  </Link>
                </Button>
              </Can>
            </div>
          </section>

          <Spin spinning={loading.filter}>
            <AntForm
              form={formFilter}
              name="filter-registered-customers"
              className="form-secondary form-filters grid-filters"
              onFinish={handleFilter}
            >
              <div className="filter-type-person">
                <FormItem id="type_person" className="form-item-without-label">
                  <Radio.Group
                    defaultValue={personType}
                    buttonStyle="solid"
                    onChange={e => {
                      setPersonType(e.target.value.toString());
                    }}
                  >
                    {/* <Can I="person.physical.index" a=""> */}
                    <Radio.Button value="physical">
                      {t('pages.registered-customers.filters.person_types.physical')}
                    </Radio.Button>
                    {/* </Can> */}
                    {/* <Can I="person.legal.index" a=""> */}
                    <Radio.Button value="legal">
                      {t('pages.registered-customers.filters.person_types.legal')}
                    </Radio.Button>
                    {/* </Can> */}
                  </Radio.Group>
                </FormItem>
              </div>
              <div className="filter-search">
                <FormItem name="search" label={t('pages.registered-customers.filters.search')}>
                  <Input
                    maxLength={255}
                    placeholder={t(`pages.registered-customers.filters.search-${personType}-placeholder`)}
                  />
                </FormItem>
              </div>
              <div>
                <FormItem name="stage_id" label={t('pages.registered-customers.filters.stage_id')}>
                  <Select options={options.person_stage} />
                </FormItem>
              </div>

              <div className="grid-buttons">
                <Button status="primary" text="uppercase" htmlType="submit">
                  <FaSearch /> {t('form.actions.search')}
                </Button>
                <Button
                  status="secondary"
                  size="middle"
                  onClick={() => {
                    formFilter.resetFields();
                    handleFilter({});
                  }}
                >
                  {t('clear')}
                </Button>
              </div>
            </AntForm>
          </Spin>

          {renderVisualization()}
        </div>
      );
    }
    return <div />;
  }

  function renderDetail() {
    if (personTypeParam && actionPermissionParam) {
      return <DetailView />;
    }
    return <div />;
  }

  return (
    <Container>
      {renderIndex()}
      {renderDetail()}
    </Container>
  );
};

export default RegisteredCustomers;

import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  padding: 0 4px;

  .content-container {
    padding: 0 4px;
  }

  .grid-actions {
    display: grid;
    grid-template-columns: 4fr 1fr 40px 40px 1fr;
    grid-template-areas: '. export-excel kanban-view list-view btn-new';
    gap: 8px;
    padding: 8px;

    .export-excel {
      grid-area: export-excel;
      display: flex;
      justify-content: flex-end;
      margin-top: 0;
    }

    .kanban-view {
      grid-area: kanban-view;
      margin-top: 0;
    }

    .list-view {
      grid-area: list-view;
      margin-top: 0;
    }

    .btn-new {
      grid-area: btn-new;
      margin-top: 0;
    }
  }

  .grid-filters {
    display: grid;
    grid-template-columns: 2fr 2fr 1fr 1fr;
    grid-template-areas: 'filter-type-person grid-buttons';
    padding: 8px;

    .grid-buttons {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 8px;
      padding-top: 23px;
      padding-left: 4px;
    }

    .filter-type-person {
      grid-area: filter-type-person;
    }
  }

  .list-view {
    display: flex;
    margin-top: 16px;
    flex-direction: column;
    flex: 1;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    justify-items: flex-start;
    position: relative;
  }

  .kanban-view {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    justify-items: flex-start;
    position: relative;
    max-height: 100%;
  }
`;

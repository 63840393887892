import React from 'react';

import { Col, Input } from 'antd';
import { FormItem } from 'components';
import { OPERATION_PREPAY_ID } from 'configs/constants';
import { useCreditRequestStore } from 'pages/CreditRequest/store/useCreditRequestStore';

import { JsonDataRenovation } from '../../atoms/JsonDataRenovation';
import { RequesterNameInput } from '../../atoms/RequesterNameInput';
import { RequesterPersonDocumentInput } from '../../atoms/RequesterPersonDocumentInput';
import { RequesterPersonIdInput } from '../../atoms/RequesterPersonIdInput';
import { RequesterPersonJsonDataCodeErpInput } from '../../atoms/RequesterPersonJsonDataCodeErpInput';
import { RequesterPersonSapCodeInput } from '../../atoms/RequesterPersonSapCodeInput';

interface Props {
  isProvider?: boolean;
}

export const PersonFieldsMtmInternMarket = ({ isProvider = false }: Props) => {
  const selectedOperationId = useCreditRequestStore(state => state.selectedOperationId);
  const shouldShowSap = useCreditRequestStore(state => state.shouldShowSap);
  const shouldShowDatasul = useCreditRequestStore(state => state.shouldShowDatasul);
  const isSugarTrading = useCreditRequestStore(state => state.isSugarTrading);
  const isPrefin = useCreditRequestStore(state => state.isPrefin);
  const isMTM = useCreditRequestStore(state => state.isMTM);

  const isSugarTradingMTMorPrefin = isSugarTrading && (isPrefin || isMTM);

  return (
    <>
      <FormItem name="requester_person_id" hidden>
        <Input hidden />
      </FormItem>
      {/* <Col xs={24} sm={18} md={12} lg={8} xl={6} xxl={6}>
        <PersonTypeSelectInput />
      </Col> */}

      <Col xs={24} sm={18} md={12} lg={8} xl={6} xxl={6}>
        <RequesterPersonDocumentInput />
      </Col>

      <Col xs={24} sm={18} md={12} lg={8} xl={12} xxl={12}>
        <RequesterPersonIdInput />
        {/* <RequesterPersonIdSelect isProvider={isProvider} /> */}
        <RequesterNameInput isProvider={isProvider} />
      </Col>

      {/* requester_person code_erp */}
      <Col
        xs={24}
        sm={18}
        md={12}
        lg={8}
        xl={6}
        xxl={6}
        style={{ display: shouldShowDatasul && !isSugarTradingMTMorPrefin ? '' : 'none' }}
      >
        <RequesterPersonJsonDataCodeErpInput
          isVisible={shouldShowDatasul && !isSugarTradingMTMorPrefin}
          isRequired={selectedOperationId === OPERATION_PREPAY_ID && shouldShowDatasul}
        />
      </Col>
      <Col xs={24} sm={18} md={12} lg={8} xl={6} xxl={6} hidden={!shouldShowSap || isSugarTradingMTMorPrefin}>
        <RequesterPersonSapCodeInput isVisible={shouldShowSap && !isSugarTradingMTMorPrefin} isRequired={false} />
      </Col>
      <Col xs={24} sm={18} md={12} lg={8} xl={6} xxl={6}>
        <JsonDataRenovation />
      </Col>
    </>
  );
};

import React, { useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';

import { ErrorFallback } from 'components/ErrorFallback';

import { useAuth } from '../../hooks/auth';
import { useSidebar } from '../../hooks/sidebar';
import Header from './Header';
import LateralMenu from './LateralMenu';
import { Container } from './styles';

const PrivatePage: React.FC = ({ children }) => {
  const { i18n } = useTranslation();
  const { user } = useAuth();
  const { isChanging, isMini } = useSidebar();

  let classCSS = '';
  if (isChanging) {
    classCSS = isMini ? 'sidebar-mini' : 'sidebar-large';
  }

  useEffect(() => {
    i18n.changeLanguage(user.config?.app?.language);
  }, [user, i18n]);

  return (
    <div className="h-[100vh] overflow-hidden">
      <LateralMenu />
      <Container className={classCSS} sidebarIsMini={isMini}>
        <Header />

        <ErrorBoundary
          FallbackComponent={ErrorFallback}
          onReset={() => {
            // reset the state of your app so the error doesn't happen again
          }}
        >
          <div className="h-full max-h-full overflow-y-auto w-full">{children}</div>
        </ErrorBoundary>
      </Container>
    </div>
  );
};

export default PrivatePage;

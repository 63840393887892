import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useTable, ReportModal } from '@agrodatabr/agrodataui';

import { Breadcrumb, Button } from '../../../components';
import { getReportModalTranslation } from '../../../componentsTranslation/reportModal';
import { Can } from '../../../hooks/ability';
import documentApi from '../../../services/api/document';
import sustainabilityApi from '../../../services/api/sustainability';
import { useSustainabilities } from './hooks/useSustainabilities';
import { SContainer, SHeader, STitlePage } from './styles';
import { Table } from './Table';

export const SustainabilityRequests = () => {
  const { t } = useTranslation();
  const tableController = useTable();
  const [exportingGeneral, setExportingGeneral] = useState(false);

  const { handleTableChange, handleEdit, handleRemove, isFetching, searchFilters, setSearchFilters } =
    useSustainabilities({ refresh: tableController.refresh });

  return (
    <div className="h-full max-h-full overflow-y-scroll flex flex-col">
      <Breadcrumb
        items={[{ title: t('breadcrumb.sustainability') }, { title: t('breadcrumb.sustainability_request') }]}
      />

      <SContainer className="flex flex-col h-full pb-10 overflow-hidden">
        <SHeader>
          <STitlePage>{t('pages.sustainability.title.requests')}</STitlePage>

          <Can I="sustainability.report.index" a="">
            <Button
              status="primary"
              onClick={() => {
                setExportingGeneral(true);
              }}
            >
              {t('pages.sustainability.form.buttonExport')}
            </Button>
          </Can>
        </SHeader>

        <div className="h-full overflow-hidden flex">
          <Table
            handleChange={handleTableChange}
            handleEdit={handleEdit}
            handleRemove={handleRemove}
            isLoading={isFetching}
            controller={tableController}
            searchFilters={searchFilters}
            setSearchFilters={setSearchFilters}
          />
        </div>
        <ReportModal
          translation={getReportModalTranslation(t)}
          withDateFilter
          width="80%"
          visibility={exportingGeneral}
          afterClose={() => {
            setExportingGeneral(false);
          }}
          listReportApi={sustainabilityApi.reports.listReport}
          generateReportApi={sustainabilityApi.reports.generateExport}
          downloadReportApi={documentApi.download}
        />
      </SContainer>
    </div>
  );
};

const toBase64 = (file: File) =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = reject;
  });

export const FileUtils = {
  async getBase64(file: File): Promise<string> {
    try {
      const result = await toBase64(file);
      return result;
    } catch (error) {
      console.error(error);
      return '';
    }
  },
  getOriginalName(fileName: string, withExtension = false) {
    const splitName = fileName.split('.');
    const splitLength = splitName.length;
    const extension = splitName[splitLength - 1];
    delete splitName[splitLength - 1];
    const finalFileName = splitName
      .filter((s: string) => !!s)
      .join('_')
      .replace(/ /g, '_');
    return !withExtension ? finalFileName : `${finalFileName}.${extension}`;
  },
};

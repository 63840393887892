import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ColumnDef } from '@tanstack/react-table';
import PermissionedComponent from 'components/PermissionedComponent';
import { ShouldRender } from 'components/ShouldRender';
import { DataTable } from 'components/ui/data-table';
import { GroupForm } from 'components/ui/group-form';
import { getTranslations } from 'services/translations';
import { StageHistoryData } from 'types/CreditRequest/RegisteredCustomerData';
import { Formatter } from 'utils/Formatter';

import { useRegisteredCustomerStore } from './stores/use-registered-customer-store';

export const RegisteredCustomerStatusHistorySection = () => {
  const { t } = useTranslation();
  const form = useFormContext();
  const { i18n } = useTranslation();

  const stageHistory = form.watch('stage_history');

  const isNew = useRegisteredCustomerStore(state => state.isViewing);

  const columns: ColumnDef<StageHistoryData>[] = [
    {
      accessorKey: 'from',
      header: t('previous-status'),
      cell: ({ row }) => {
        const statusHistory = row.original as StageHistoryData;
        return statusHistory?.from?.translations ? getTranslations(statusHistory.from, 'title', i18n.language) : '-';
      },
    },
    {
      accessorKey: 'to',
      header: t('current-status'),
      cell: ({ row }) => {
        const statusHistory = row.original as StageHistoryData;
        return statusHistory?.to?.translations ? getTranslations(statusHistory.to, 'title', i18n.language) : '-';
      },
    },
    {
      accessorKey: 'who',
      header: t('who-changed'),
      cell: ({ row }) => {
        const statusHistory = row.original as StageHistoryData;
        return statusHistory?.user.name;
      },
    },
    {
      accessorKey: 'when',
      header: t('when'),
      cell: ({ row }) => {
        const statusHistory = row.original as StageHistoryData;
        return Formatter.date(statusHistory.created_at);
      },
    },
    {
      accessorKey: 'description',
      header: t('description'),
    },
  ];

  return (
    <GroupForm title={t('status-change-history')}>
      <PermissionedComponent permission="credit.request.history.index">
        <ShouldRender condition={!isNew}>
          <DataTable columns={columns} data={stageHistory ?? []} />
        </ShouldRender>
      </PermissionedComponent>
    </GroupForm>
  );
};

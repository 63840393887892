import { useAuth } from 'hooks/auth';
import CreditRequestData from 'types/CreditRequest/CreditRequestData';
import { LimitRulesData } from 'types/CreditRequest/LimitRulesData';

export const useCheckCurrentUserCanApproveGuarantee = () => {
  const { user } = useAuth();

  const checkCurrentUserCanApproveGuarantee = (data: CreditRequestData) => {
    if (data.team_status)
      if (data.team_status.rules_approval_limit) {
        data.team_status.rules_approval_limit.forEach((row: LimitRulesData) => {
          if (row.team_credit_status_id === data.team_status.id) {
            if (row.operation_id === data.operation_id) {
              if (row.position_id === null || row.position_id === user.position_id) {
                return true;
              }
            }
          }
        });
      }
    return false;
  };

  return { checkCurrentUserCanApproveGuarantee };
};

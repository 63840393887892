import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormItem, Select } from 'components';
import { OPERATION_PREPAY_ID } from 'configs/constants';
import { useCreditRequestContext } from 'context/CreditRequestContext';
import { useCreditRequestStore } from 'pages/CreditRequest/store/useCreditRequestStore';

export const JsonDataOptingInssSelect = () => {
  const { t } = useTranslation();
  const { creditForm } = useCreditRequestContext();

  const selectedOperationId = useCreditRequestStore(state => state.selectedOperationId);
  const setIsBlocking = useCreditRequestStore(state => state.setIsBlocking);
  const isRequiredAttachINSS = useCreditRequestStore(state => state.isRequiredAttachINSS);
  const setIsRequiredAttachINSS = useCreditRequestStore(state => state.setIsRequiredAttachINSS);
  const pageStatus = useCreditRequestStore(state => state.pageStatus);

  return (
    <FormItem
      label={t('pages.credit-request.form.json_data_opting_inss')}
      name="json_data.opting_inss"
      validateStatus={isRequiredAttachINSS ? 'warning' : ''}
      extra={isRequiredAttachINSS ? t('pages.credit-request.form.opting_inss_required_file') : ''}
      rules={[{ required: isRequiredAttachINSS }]}
      initialValue={0}
    >
      <Select
        options={[
          {
            key: 'opting_inss_yes',
            label: t('pages.credit-request.form.opting_inss_yes'),
            value: 1,
          },
          {
            key: 'opting_inss_no',
            label: t('pages.credit-request.form.opting_inss_no'),
            value: 0,
          },
        ]}
        disabled={
          pageStatus.viewing ||
          (creditForm.getFieldValue('json_data.is_exportation') && selectedOperationId === OPERATION_PREPAY_ID)
        }
        onChange={(value: any) => {
          if (value !== undefined || value !== null) {
            setIsBlocking(true);

            setIsRequiredAttachINSS(Boolean(value));
          }
        }}
      />
    </FormItem>
  );
};

import React from 'react';

import api, { handleRequest } from '../../services/api/api';

export const useContractRelatedDocument = () => {
  const [isStoring, setIsStoring] = React.useState(false);
  const [isDestroying, setIsDestroying] = React.useState(false);

  const addNewRelatedDocument = async (contractId: string, data: { contract_type_id: string; comments: string }) => {
    const response = await handleRequest(
      () => api.post(`contract/${contractId}/generate-dependency`, data),
      setIsStoring,
    );
    return response?.data ?? response;
  };

  const destroy = async (data: { id: string; reason: string }) => {
    const response = await handleRequest(
      () => api.get(`contract/${data.id}/cancel-dependency`, { params: { reason: data.reason } }),
      setIsStoring,
    );
    return response;
  };

  return { isStoring, addNewRelatedDocument, destroy, isDestroying, setIsDestroying };
};

import React from 'react';

import { DraftButton } from '../DraftButton';
import { CustomButtonProps } from './types';

export const CustomButton = ({ children, className, ...rest }: CustomButtonProps) => {
  return (
    <DraftButton variant="default" {...rest} className={className}>
      {children}
    </DraftButton>
  );
};

import styled from 'styled-components';

export const Container = styled.div`
  padding: 20px;
  .area-card {
    width: 240px;
    height: 150px;
    padding: 20px;
    border-radius: 10px;
    border: 1px solid;
    border-color: ${({ theme: { colors } }) => colors.greys.grey04};
    display: flex;
    justify-content: center;
    flex-direction: column;
    justify-content: space-around;
    margin: 10px;
  }
  .ant-card-body {
    padding: 0px;
    .ant-card-meta-description {
      color: #000;
      font-weight: 700;
    }
  }
`;

export const Stitle = styled.h1`
  padding: 20px 20px 0px 20px;
`;

import React from 'react';

import { cva, VariantProps } from 'class-variance-authority';
import { Button, ButtonProps } from 'components/ui/button';
import { cn } from 'lib/utils';

const buttonVariants = cva(
  'bg-white text-primary w-full rounded-full hover:text-white disabled:bg-gray-500 disabled:text-gray-200 text-sm font-semibold flex gap-1',
  {
    variants: {
      variant: {
        default: 'hover:bg-primary',
        secondary:
          'hover:bg-transparent bg-transparent disabled:bg-transparent disabled:text-gray-400 hover:text-primary hover:opacity-50',
        text: 'bg-transparent underline hover:bg-transparent hover:text-primary/50 w-min p-0 disabled:text-gray-600 disabled:bg-transparent',
      },
    },
  },
);

export type DraftButtonProps = Omit<ButtonProps, 'variant'> & VariantProps<typeof buttonVariants>;

export const DraftButton = ({ children, variant, className, ...props }: DraftButtonProps) => {
  return (
    <Button {...props} type="button" className={cn(buttonVariants({ variant }), className)}>
      {children}
    </Button>
  );
};

import React from 'react';
import { useTranslation } from 'react-i18next';

import { twMerge } from 'tailwind-merge';

type Props = {
  className?: string;
};

export const NoPermission = ({ className }: Props) => {
  const { t } = useTranslation();
  return (
    <div
      className={twMerge(
        'w-full h-full flex justify-center items-center py-4 text-gray-600 text-lg font-bold',
        className,
      )}
    >
      <h1>{t('noPermission')}</h1>
    </div>
  );
};

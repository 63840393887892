import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;

  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    display: contents !important;
  }

  .credit-request-custom-tabs-container {
    height: 100%;
  }

  .content-pannel {
    height: calc(100vh - 210px);
    margin-bottom: 80px;
    overflow: auto;

    &.content-pannel-full {
      height: calc(100vh - 130px);
      margin-bottom: 0;
    }
  }

  .action-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .group-form {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-bottom: 16px;

    > h2 {
      background: ${({ theme: { colors } }) => colors.greys.grey01};
      color: ${({ theme: { colors } }) => colors.secondary};
      font-family: ${({ theme: { fonts } }) => fonts.primary};
      font-weight: 700;
      font-size: 16px;
      padding: 8px;
      display: block;
      text-align: center;
      text-transform: uppercase;
      margin-top: 0;
      margin-bottom: 16px;
    }

    > .group-header-actions {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      > button {
        margin-left: 8px;
        margin-right: 8px;
      }
    }

    > .group-header-values {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      color: ${({ theme: { colors } }) => colors.secondary};
      font-family: ${({ theme: { fonts } }) => fonts.primary};
      font-weight: 500;
      font-size: 14px;

      > button {
        margin-left: 8px;
        margin-right: 8px;
      }
    }

    .form-grid {
      /* display: grid;
      gap: 0 8px;
      grid-template-columns: 33% 33% 33%;
      grid-template-rows: 76px;
      grid-auto-flow: row;
      width: 100%;
      position: relative;
      align-items: center; */

      display: inline;

      > * {
        max-width: 33%;
        display: inline-flex;
        flex: 1;
      }

      > fieldset {
        display: block;
        border: solid 1px ${({ theme: { colors } }) => colors.primary};
        border-radius: 5px;
        max-width: 100%;
        margin: 8px 0;
        padding-top: 16px;

        > h6 {
          background: ${({ theme: { colors } }) => colors.greys.grey01};
          border-radius: 5px;
          color: ${({ theme: { colors } }) => colors.primary};
          font-family: ${({ theme: { fonts } }) => fonts.primary};
          font-weight: 700;
          font-size: 16px;
          padding: 4px 8px;
        }

        .fields {
          display: inline;

          > * {
            max-width: 33%;
            display: inline-flex;
            flex: 1;
          }
        }
      }
    }

    &.inatived > * {
      display: none;

      > * {
        display: none;
      }
    }
  }

  .form-footer-actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    > button {
      margin-left: 8px;
      margin-right: 8px;
    }
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    > button {
      margin-left: 8px;
      margin-right: 8px;
    }
  }

  .historic-container {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .formalization-guarantees-container {
    height: 100%;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;

export const StatusLink = styled.a``;

export const FileContainer = styled.div`
  padding: 16px 8px;
`;

export const FileAlertContainer = styled.div`
  padding: 0 8px;
`;

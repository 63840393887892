import { TablePaginationConfig } from 'antd/lib/table';

export const updateData = <T>(
  row: Partial<T & { id: string }>,
  data: Array<Partial<T & { id: string }>>,
  paginationConfig: TablePaginationConfig,
) => {
  let dataUpdated: Array<Partial<T & { id: string }>> = data;
  if (data.length > 0) {
    const rowIndex = data.findIndex(item => row?.id === item.id);
    // Se encontrar o item remove e adiciona ele atualizado na mesma posição
    if (rowIndex > -1) {
      dataUpdated.splice(rowIndex, 1, row);
    } else {
      if (dataUpdated.length === paginationConfig.pageSize) {
        // Se não encontrar, remove o último (por conta da paginação) e
        // adiciona o item na primeira posição
        dataUpdated.splice(-1, 1);
      }
      dataUpdated = [row, ...dataUpdated];
    }
  } else {
    dataUpdated.push(row);
  }

  return dataUpdated as T[];
};

import React, { useEffect, useState } from 'react';

import { Modal } from 'antd';

import { SurveyRequestForm } from '../../pages/Monitoring/SurveyRequest/Form';
import { SurveyRequestModalProps } from './types';

export const SurveyRequestModal = ({
  isShowing: shouldShow,
  cprId = '',
  registrationId = '',
  updateIsShowing,
}: SurveyRequestModalProps) => {
  const [isShowing, setIsShowing] = useState(false);

  const close = () => {
    updateIsShowing(false);
    setIsShowing(false);
  };

  useEffect(() => {
    setIsShowing(shouldShow);
  }, [shouldShow]);

  return (
    isShowing && (
      <Modal visible={isShowing} onCancel={close} footer={null} width="60%">
        <SurveyRequestForm cprId={cprId} registrationId={registrationId} modal={{ closeModal: close }} />
      </Modal>
    )
  );
};

import styled from 'styled-components';

export const Container = styled.div``;

export const Header = styled.thead`
  tr {
    background: ${({ theme }) => theme.colors.greys.grey02};
  }
`;
export const HeaderRow = styled.th`
  white-space: nowrap;
  padding: 5px;
`;
export const SectionLabel = styled.tr`
  background: ${({ theme }) => theme.colors.greys.grey06};
  td {
    padding: 3px;
  }
`;
export const Body = styled.tbody`
  &,
  tr,
  td {
    border: 1px solid ${({ theme }) => theme.colors.greys.grey04};
    border-collapse: collapse;
  }
  tr {
    td {
      padding: 4px;
    }
  }
`;

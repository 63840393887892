import React from 'react';

import { Container } from './styles';
import { CellStatusProps } from './types';

const CellStatus: React.FC<CellStatusProps> = ({ children, status }) => {
  return (
    <Container status={status} className="row-custom">
      {children}
    </Container>
  );
};

export default CellStatus;

import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { InputNumber } from 'antd';
import { FormItem } from 'components';
import { SUBDIVISION_SOYBEAN_ID } from 'configs/constants';
import { useCreditRequestStore } from 'pages/CreditRequest/store/useCreditRequestStore';
import { Formatter } from 'utils/Formatter';

export const JsonDataRoyaltiesMonsantoInput = () => {
  const { t } = useTranslation();

  const selectedSubDivisionId = useCreditRequestStore(state => state.selectedSubDivisionId);
  const isAzParticipant = useCreditRequestStore(state => state.isAzParticipant);

  const msgMonsanto = useMemo(() => {
    if (selectedSubDivisionId === SUBDIVISION_SOYBEAN_ID) {
      if (isAzParticipant !== 'not_az_participant') {
        return t('pages.credit-request.form.royalties_monsanto_verify');
      }
      return t('pages.credit-request.form.royalties_monsanto_required');
    }
    return t('pages.credit-request.form.royalties_monsanto_no_required');
  }, [selectedSubDivisionId, isAzParticipant]);

  return (
    <FormItem
      label={t('pages.credit-request.form.json_data_royalties_monsanto')}
      name="json_data.royalties_monsanto"
      extra={msgMonsanto}
    >
      <InputNumber<string>
        min="0"
        formatter={(value: string | number) => Formatter.money(value)}
        parser={(value: string) => Formatter.moneyParser(value, 2)}
        disabled
      />
    </FormItem>
  );
};

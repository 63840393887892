import React from 'react';

import { InfiniteSelect, InfiniteSelectProps } from '@agrodatabr/agrodataui';
import { useBasePort } from 'hooks/fetch/useBasePort';

type Props = Omit<InfiniteSelectProps, 'getOptions' | 'showSearch'>;

export const BasePortSelect = (props: Props) => {
  const { getBasePorts } = useBasePort();

  const handleGetBasePorts = async (value: string, page: number, perPage: number) => {
    const response = await getBasePorts({ params: { search: value, page, page_size: perPage } });
    return {
      data: response.data.map((port: any) => ({ ...port, key: port.id, value: port.id, label: port.name })),
      lastPage: response.last_page,
    };
  };

  return <InfiniteSelect showSearch getOptions={handleGetBasePorts} {...props} />;
};

import React from 'react';
import { useTranslation } from 'react-i18next';

import { Col } from 'antd';
import { FormItem, Select } from 'components';
import { BUSINESS_FERTILIZER, OPERATION_SLUG_FERTILIZER_SUPPLIERS } from 'configs/constants';
import { useCreditRequestContext } from 'context/CreditRequestContext';
import { useCache } from 'hooks/cache';
import { useLoadOperations } from 'pages/CreditRequest/hooks/useLoadOperations';
import { useLoadSubdivisions } from 'pages/CreditRequest/hooks/useLoadSubdivisions';
import { useCreditRequestStore } from 'pages/CreditRequest/store/useCreditRequestStore';

export const DivisionFields = () => {
  const { t } = useTranslation();
  const { loadOperations } = useLoadOperations();
  const { loadSubDivisions } = useLoadSubdivisions();
  const { creditForm } = useCreditRequestContext();

  const cache = useCache();
  const { current_harvest } = cache;

  const setSelectedDivisionId = useCreditRequestStore(state => state.setSelectedDivisionId);
  const selectedDivisionId = useCreditRequestStore(state => state.selectedDivisionId);
  const setSelectedSubDivisionId = useCreditRequestStore(state => state.setSelectedSubDivisionId);
  const selectedSubDivisionId = useCreditRequestStore(state => state.selectedSubDivisionId);
  const setSelectedOperationId = useCreditRequestStore(state => state.setSelectedOperationId);
  const setCreditRequestOfContract = useCreditRequestStore(state => state.setCreditRequestOfContract);
  const setContractFieldsValue = useCreditRequestStore(state => state.setContractFieldsValue);
  const setDocumentList = useCreditRequestStore(state => state.setDocumentList);
  const setSubDivisionOptions = useCreditRequestStore(state => state.setSubDivisionOptions);
  const setOperationOptions = useCreditRequestStore(state => state.setOperationOptions);
  const setIsBlocking = useCreditRequestStore(state => state.setIsBlocking);
  const subDivisionOptions = useCreditRequestStore(state => state.subDivisionOptions);
  const operationOptions = useCreditRequestStore(state => state.operationOptions);
  const setIsLoading = useCreditRequestStore(state => state.setIsLoading);
  const pageStatus = useCreditRequestStore(state => state.pageStatus);
  const reset = useCreditRequestStore(state => state.reset);

  const [loadingSubDivision, setLoadingSubDivision] = React.useState(false);
  const [loadingOperation, setLoadingOperation] = React.useState(false);

  return (
    <>
      <Col xs={24} sm={18} md={12} lg={8} xl={8} xxl={8}>
        <FormItem label={t('pages.credit-request.form.division_id')} name="division_id">
          <Select
            options={cache.getCreditOptions('division')}
            disabled={pageStatus.viewing}
            onChange={async (value: any, option: any) => {
              reset();

              setLoadingSubDivision(true);
              creditForm.setFieldsValue({
                division_id: value,
                sub_division_id: undefined,
                operation_id: undefined,
              });

              setCreditRequestOfContract([]);
              setContractFieldsValue(null);
              setDocumentList([]);
              setSubDivisionOptions([]);
              setOperationOptions([]);

              setSelectedSubDivisionId(null);
              setSelectedOperationId(null);

              setSelectedDivisionId(option.value);
              loadSubDivisions(option.value);

              if (value) {
                setIsBlocking(true);
              }

              setLoadingSubDivision(false);
            }}
          />
        </FormItem>
      </Col>
      {/* Sub Divisão */}
      <Col xs={24} sm={18} md={12} lg={8} xl={8} xxl={8}>
        <FormItem label={t('pages.credit-request.form.sub_division_id')} name="sub_division_id">
          <Select
            options={subDivisionOptions || cache.getCreditOptions('sub_division')}
            disabled={pageStatus.viewing || !selectedDivisionId || loadingSubDivision}
            loading={loadingSubDivision}
            onChange={async (value: any, option: any) => {
              setLoadingOperation(true);
              const formValues = creditForm.getFieldsValue();
              const divisionId = formValues.division_id;
              creditForm.resetFields();
              creditForm.setFieldsValue({
                division_id: divisionId,
                sub_division_id: value,
                operation_id: undefined,
              });

              setCreditRequestOfContract([]);
              setContractFieldsValue(null);
              setDocumentList([]);
              setOperationOptions([]);

              setSelectedOperationId(null);

              setSelectedSubDivisionId(option.value);
              loadOperations(option.value);

              if (value) {
                setIsBlocking(true);
              }

              setLoadingOperation(false);
            }}
          />
        </FormItem>
      </Col>
      {/* Operação */}
      <Col xs={24} sm={18} md={12} lg={8} xl={8} xxl={8}>
        <FormItem label={t('pages.credit-request.form.operation_id')} name="operation_id">
          <Select
            options={[...(operationOptions || cache.getCreditOptions('operation'))]}
            disabled={pageStatus.viewing || !selectedSubDivisionId || loadingOperation}
            loading={loadingOperation}
            onChange={(value: any, option: any) => {
              setIsLoading(true);
              const formValues = creditForm.getFieldsValue();
              const divisionId = formValues.division_id;
              const subDivisionId = formValues.sub_division_id;
              creditForm.resetFields();
              creditForm.setFieldsValue({
                division_id: divisionId,
                sub_division_id: subDivisionId,
                operation_id: value,
              });

              setCreditRequestOfContract([]);
              setContractFieldsValue(null);
              setDocumentList([]);

              setSelectedOperationId(option.value);

              if (option.slug === OPERATION_SLUG_FERTILIZER_SUPPLIERS) {
                creditForm.setFieldsValue({ business_unit_id: BUSINESS_FERTILIZER, harvest_id: current_harvest });
              }

              if (value) {
                setIsBlocking(true);
              }

              setIsLoading(false);
            }}
          />
        </FormItem>
      </Col>
    </>
  );
};

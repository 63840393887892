import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaMinus, FaPlus, FaSearch } from 'react-icons/fa';

import { TableData, useTable } from '@agrodatabr/agrodataui';
import { Form, Spin, TablePaginationConfig } from 'antd';
import { Breadcrumb, Button, FormItem, IconWithTooltip, Input, ModalStatus } from 'components';
import { Can } from 'hooks/ability';
import { useCache } from 'hooks/cache';
import { useBasePort } from 'hooks/fetch/useBasePort';
import { usePage } from 'hooks/page';
import registerApi from 'services/api/register';

import type { BasePortData } from '../../../types/CreditRequest/BasePortData';
import type { ResponseListData } from '../../../@types/data/ResponseData';
import type DTOErrorReponse from '../../../@types/dtos/DTOErrorReponse';
import columns from './columns';
import { BasePortForm } from './Form';
import { SContainer, SFilterContainer, SHeader, STitlePage } from './styles';

export const BasePort = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [searchForm] = Form.useForm();
  const cache = useCache();
  const { alertStatus } = usePage();
  const tableController = useTable();
  const { getBasePorts, isFetchingBasePorts, destroyBasePort } = useBasePort();
  const formRef = useRef<{ loadCityOptions: (stateId: string) => void | undefined }>();

  const [visibilityForm, setVisibilityForm] = useState(false);
  const [editing, setEditing] = useState(false);

  const [loadingButton, setLoadingButton] = useState(false);
  const [loading, setLoading] = useState(false);

  const [statesOptions, setStatesOptions] = useState([]);

  const [disabledButton, setDisabledButton] = useState(false);

  const [filters, setFilters] = useState({});

  const handleDisplayForm = () => {
    setVisibilityForm(!visibilityForm);
    setEditing(false);
    setDisabledButton(false);
    setLoadingButton(false);
    form.resetFields();
  };

  const handleHideForm = () => setVisibilityForm(false);

  const loadStateOptions = useCallback(async () => {
    setStatesOptions(cache.getCreditOptions('state'));
  }, [cache]);

  const handleSuccess = (messageSuccess: string) =>
    ModalStatus({
      type: 'success',
      title: t('modal.success'),
      subTitle: messageSuccess,
    });

  const handleStoreOrUpdate = (type: 'store' | 'update') => {
    setLoading(true);
    setLoadingButton(true);
    setDisabledButton(true);
    form
      .validateFields()
      .then(() => {
        const formValues = form.getFieldsValue();
        Object.keys(formValues).forEach(key => {
          if (typeof formValues[key] === 'number') {
            formValues[key] = String(formValues[key]);
          }
        });

        const httpMethod =
          type === 'store'
            ? registerApi.baseport.store(formValues)
            : registerApi.baseport.update(formValues.id, formValues);

        httpMethod
          .then((response: ResponseListData<BasePortData>) => {
            // const { data: harvest } = response.data;
            handleSuccess(response.data.message);
            // updateData(harvest);
            handleHideForm();
            setLoading(false);
            setLoadingButton(false);
            setDisabledButton(false);
            tableController.refresh();
            form.resetFields();
          })
          .catch((err: DTOErrorReponse) => {
            alertStatus(err, 'error');
            setLoading(false);
            setLoadingButton(false);
            setDisabledButton(false);
          });
      })
      .catch((err: DTOErrorReponse) => {
        alertStatus(err, 'error');
        setLoading(false);
        setLoadingButton(false);
        setDisabledButton(false);
      });
  };

  const handleFastSearch = () => {
    const value = searchForm.getFieldsValue();
    setFilters(value);
  };

  const tableDataClearAllFilters = () => {
    setFilters({});
  };

  const searchRequest = async (pagination: TablePaginationConfig, requestFilters: any) => {
    const response = await getBasePorts({
      params: { page: pagination.current, per_page: pagination.pageSize, ...requestFilters },
    });
    return { data: response.data, total: response.total };
  };

  const handleRemoveChange = async (record: any) => {
    t('pages.register.baseport.table.header.country');
    ModalStatus({
      type: 'delete',
      title: t('pages.register.harvest.delete.title'),
      subTitle: t('pages.register.harvest.delete.subtitle'),
      cancelText: t('pages.register.harvest.delete.cancel'),
      okText: t('pages.register.harvest.delete.confirm'),
      onOk: () => {
        destroyBasePort(record.id)
          .then(() => {
            handleSuccess(t('pages.register.baseport.delete.success'));
            tableController.refresh();
          })
          .catch((err: DTOErrorReponse) => alertStatus(err, 'error'));
      },
    });
  };

  const getActions = () => ({
    render: (active: number, record: BasePortData) => (
      <>
        <Can I="register.baseport.destroy" a="">
          <IconWithTooltip
            action="destroy"
            title={t('pages.register.harvest.table.icons.remove')}
            onClick={() => handleRemoveChange(record)}
          />
        </Can>
        <Can I="register.baseport.update" a="">
          <IconWithTooltip
            action="edit"
            title={t('pages.register.harvest.table.icons.edit')}
            onClick={() => {
              form.setFieldsValue({
                ...record,
                state_id: record?.city?.state_id,
              });

              form.scrollToField('name', {
                scrollMode: 'always',
                block: 'start',
                behavior: actions =>
                  actions.forEach(({ el, top }) => {
                    el.scrollTop = top - 280;
                  }),
              });

              if (formRef.current) formRef.current.loadCityOptions(record?.city?.state_id);

              setVisibilityForm(true);
              setEditing(true);
              setLoading(false);
              setLoadingButton(false);
              setDisabledButton(false);
            }}
          />
        </Can>
      </>
    ),
  });

  useEffect(() => {
    loadStateOptions();
  }, []);

  return (
    <>
      <Breadcrumb
        items={[{ title: t('breadcrumb.register') }, { title: t('breadcrumb.port'), to: '/register/port' }]}
      />

      <SContainer>
        <SHeader>
          <STitlePage>{t('pages.register.port.title_page')}</STitlePage>

          <Can I="register.baseport.store" a="">
            <Button status="primary" icon={visibilityForm ? <FaMinus /> : <FaPlus />} onClick={handleDisplayForm}>
              {t('pages.register.port.buttonAdd')}
            </Button>
          </Can>
        </SHeader>

        <BasePortForm
          handleStoreOrUpdate={handleStoreOrUpdate}
          statesOptions={statesOptions}
          disabledButton={disabledButton}
          editing={editing}
          form={form}
          ref={formRef}
          loading={loading}
          loadingButton={loadingButton}
          visibilityForm={visibilityForm}
          handleHideForm={handleHideForm}
        />

        <SFilterContainer>
          <Form
            form={searchForm}
            name="filter-harvest"
            className="form-secondary form-filters grid-filters"
            onFinish={handleFastSearch}
          >
            <div className="filter-search">
              <FormItem name="search" label={t('pages.register.harvest.search')}>
                <Input />
              </FormItem>
            </div>
            <div className="filter-button">
              <FormItem label="" className="form-item-without-label">
                <Button status="primary" text="uppercase" htmlType="submit">
                  <FaSearch /> {t('form.actions.search')}
                </Button>
              </FormItem>
            </div>
            <div className="filter-clear">
              <Button status="secondary" size="middle" onClick={tableDataClearAllFilters}>
                {t('pages.register.harvest.buttonClearFilter')}
              </Button>
            </div>
          </Form>
        </SFilterContainer>

        <Spin spinning={isFetchingBasePorts}>
          <TableData
            rowKey="id"
            searchRequest={searchRequest}
            searchFilters={filters}
            width="100%"
            columns={columns.map((column: any) => {
              let columnRender: any = {
                ...column,
                title: t(column.title),
              };

              if (column.dataIndex === 'city') {
                columnRender = {
                  ...columnRender,
                  render: (_: any, record: any) => record.city.name,
                };
              }

              return columnRender;
            })}
            loading={loading}
            tableConfig={{
              getActions,
            }}
            pagination={{ position: ['bottomCenter'] }}
          />
        </Spin>
      </SContainer>
    </>
  );
};

import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ColumnDef } from '@tanstack/react-table';
import { IconWithTooltip } from 'components';
import { ShouldRender } from 'components/ShouldRender';
import { useAlertDialog } from 'components/ui/alert-dialog';
import { DataTable } from 'components/ui/data-table';
import { Can } from 'hooks/ability';
import { useCache } from 'hooks/cache';
import { Formatter } from 'utils/Formatter';

import { useRegisteredCustomerStore } from '../stores/use-registered-customer-store';
import { MainClientFormData } from './main-clients.form';

export const MainClientTable = () => {
  const { t } = useTranslation();
  const form = useFormContext();
  const { alert } = useAlertDialog();
  const cache = useCache();

  const mainProviders = form.watch('main_client') as MainClientFormData[];

  const setMainClientToEdit = useRegisteredCustomerStore(state => state.setMainClientToEdit);
  const setIsMainClientModalOpen = useRegisteredCustomerStore(state => state.setIsMainClientModalOpen);
  const isViewing = useRegisteredCustomerStore(state => state.isViewing);

  const onDelete = (id: string) => {
    const mainClients = form.getValues('main_client') as MainClientFormData[];
    form.setValue(
      'main_client',
      mainClients.filter(mainClient => mainClient.id !== id),
    );
  };

  const handleDelete = (mainClient: MainClientFormData) => {
    alert({
      type: 'warning',
      title: t('delete-main-client'),
      description: t('delete-main-client-confirm', {
        mainClient: mainClient.name,
      }),
      onOk: async () => {
        if (mainClient.id) await onDelete(mainClient.id.toString());
        alert({
          type: 'success',
          title: t('main-client-deleted'),
          description: '',
        });
      },
    });
  };

  const columns: ColumnDef<MainClientFormData>[] = [
    {
      accessorKey: 'name',
      header: t('pages.registered-customers.form.pj.provider_reference.name'),
    },
    {
      accessorKey: 'state_id',
      header: t('pages.registered-customers.form.pj.provider_reference.state_id'),
      cell: ({ row }) => {
        const mainClient = row.original as MainClientFormData;
        return cache.getCreditOptions('state').find(state => state.value === mainClient.state_id)?.label;
      },
    },
    {
      accessorKey: 'city_id',
      header: t('pages.registered-customers.form.pj.provider_reference.city_id'),
      cell: ({ row }) => {
        const mainClient = row.original as MainClientFormData;
        return cache.getCityByStateOptions(mainClient.state_id).find(city => city.value === mainClient.city_id)?.label;
      },
    },
    {
      accessorKey: 'phone_number',
      header: t('pages.registered-customers.form.pj.provider_reference.phone_number'),
      cell: ({ row }) => {
        const mainClient = row.original as MainClientFormData;
        return mainClient.phone_number?.replaceAll(/\D/g, '')?.length === 11
          ? Formatter.cellphoneNumber(mainClient.phone_number)
          : Formatter.phoneNumber(mainClient.phone_number);
      },
    },
    {
      accessorKey: 'contact_name',
      header: t('pages.registered-customers.form.pj.bank_reference.contact_name'),
    },
    {
      accessorKey: 'product_name',
      header: t('pages.registered-customers.form.pj.customer_reference.product_name'),
    },
    {
      accessorKey: 'percentage_without_sales',
      header: t('pages.registered-customers.form.pj.customer_reference.percentage_without_sales'),
      cell: ({ row }) => {
        const mainClient = row.original as MainClientFormData;
        return `${mainClient.json_data?.percentage_without_sales ?? 0}%`;
      },
    },
    {
      id: 'actions',
      enablePinning: true,
      accessorKey: 'action',
      size: 100,
      header: t('actions'),
      cell: ({ row }) => {
        const partner = row.original as MainClientFormData;

        return (
          <ShouldRender condition={!isViewing}>
            <IconWithTooltip
              permission="credit.request.package.destroy"
              action="destroy"
              title={t('pages.registered-customers.table.icons.remove')}
              // loading={partner.id && partnerToDelete === partner.id}
              onClick={() => handleDelete(partner)}
            />
            <Can I="credit.request.package.update">
              <IconWithTooltip
                action="edit"
                onClick={() => {
                  setMainClientToEdit(partner);
                  setIsMainClientModalOpen(true);
                }}
                title={t('pages.registered-customers.table.icons.edit')}
              />
            </Can>
          </ShouldRender>
        );
      },
    },
  ];

  return <DataTable columns={columns} data={mainProviders ?? []} />;
};

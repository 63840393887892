import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Result } from 'antd';

import { Button } from '../../components';

const NotFound: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Result
      status="404"
      title={t('result.not_found.title')}
      subTitle={t('result.not_found.subtitle')}
      extra={() => (
        <Button type="primary">
          <Link to="/">{t('result.not_found.back')}</Link>
        </Button>
      )}
    />
  );
};

const NotAuthorized: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Result
      status="403"
      title={t('result.not_authorized.title')}
      subTitle={t('result.not_authorized.subtitle')}
      extra={() => (
        <Button type="primary">
          <Link to="/">{t('result.not_authorized.back')}</Link>
        </Button>
      )}
    />
  );
};

const NetworkError: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Result
      status="500"
      title={t('result.network_error.title')}
      subTitle={t('result.network_error.subtitle')}
      extra={() => (
        <Button type="primary">
          <Link to="/">{t('result.network_error.back')}</Link>
        </Button>
      )}
    />
  );
};

export { NotFound, NotAuthorized, NetworkError };

import alasql from 'alasql';
import moment from 'moment';
import XLSX from 'xlsx';

alasql.setXLSX(XLSX);

const prefixMoney = '__formatMoney';
const prefixDate = '__formatDate';
const prefixDateTime = '__formatDateTime';

const formatValues = {
  toMoney(value: any): string {
    if (value === undefined) {
      return '';
    }
    if (value) {
      return Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }).format(Number(value));
    }
    return value;
  },

  toDate(value: any): string {
    if (value === undefined) {
      return '';
    }
    if (value) {
      return moment(value, 'YYYY-MM-DD').format('DD/MM/YYYY').toString();
    }
    return value;
  },

  toDateTime(value: any): string {
    if (value === undefined) {
      return '';
    }
    if (value) {
      return moment(value, 'YYYY-MM-DD h:mm:ss').format('DD/MM/YYYY hh:mm:ss').toString();
    }
    return value;
  },
};

function filterColumns(data: any[], headers: any): any[] {
  return data.map(item => {
    const headerKeys = Object.keys(headers);
    const dataFilteredKeys = Object.keys(item).filter(k => headerKeys.includes(k));
    const objItem: any = {};
    dataFilteredKeys.forEach(dKey => {
      if (item[dKey] && typeof item[dKey] === 'object') {
        objItem[dKey] = item[dKey].name;
      } else {
        objItem[dKey] = item[dKey];
      }
    });

    if (headers.json_data && item.json_data) {
      const headerJsonDataKeys = Object.keys(headers.json_data);
      const itemKeys = Object.keys(item.json_data).filter(ikey => headerJsonDataKeys.includes(ikey));
      itemKeys.forEach(oKey => {
        objItem[`json_data.${oKey}`] = item.json_data[oKey];
      });
      delete objItem.json_data;
    }

    return objItem;
  });
}

function formatColumn(item: any, header: any, formatter: any = {}, row: { [key: string]: string | number }) {
  if (item) {
    Object.keys(header).forEach(key => {
      if (key === 'json_data') {
        Object.keys(header.json_data).forEach(jKey => {
          const rowHeader =
            header.json_data[jKey] && typeof header.json_data[jKey] === 'object'
              ? header.json_data[jKey].name
              : header.json_data[jKey];

          const rowItem =
            item[`json_data.${jKey}`] && typeof item[`json_data.${jKey}`] === 'object'
              ? item.json_data[jKey].name
              : item[`json_data.${jKey}`];

          switch (formatter.json_data && formatter.json_data[jKey]) {
            case prefixMoney:
              row[rowHeader.toString()] = rowItem ? formatValues.toMoney(rowItem) : '';
              break;
            case prefixDate:
              row[rowHeader.toString()] = rowItem ? formatValues.toDate(rowItem) : '';
              break;
            case prefixDateTime:
              row[rowHeader.toString()] = rowItem ? formatValues.toDateTime(rowItem) : '';
              break;
            default:
              row[rowHeader.toString()] = rowItem || '';
          }
        });
      } else {
        const rowHeader = header[key] && typeof header[key] === 'object' ? header[key].name : header[key];
        const rowItem = item[key] && typeof item[key] === 'object' ? item[key].name : item[key];

        switch (formatter[key]) {
          case prefixMoney:
            row[rowHeader.toString()] = rowItem ? formatValues.toMoney(rowItem) : '';
            break;
          case prefixDate:
            row[rowHeader.toString()] = rowItem ? formatValues.toDate(rowItem) : '';
            break;
          case prefixDateTime:
            row[rowHeader.toString()] = rowItem ? formatValues.toDateTime(rowItem) : '';
            break;
          default:
            row[rowHeader.toString()] = rowItem || '';
        }
      }
    });
  }

  return row;
}

function assignHeaderAndRows(data: any[], header: object, formatter?: object): { [key: string]: string | number }[] {
  const rows = data.map(item => {
    const row = {};
    formatColumn(item, header, formatter, row);
    return row;
  });
  return rows;
}

export default function exportExcel(fileName: string, data: any[], headers: object, format?: object) {
  const filteredColumns = filterColumns(data, headers);
  const rows = assignHeaderAndRows(filteredColumns, headers, format);
  return alasql(`SELECT * INTO XLSX("${fileName}.xlsx", { headers: true }) FROM ?`, [rows]);
}

import { Form } from 'antd';
import { TablePaginationConfig } from 'antd/lib/table';

import { OPERATION_PREPAY_ID } from '../../../../configs/constants';
import { useDashboardPrepay } from '../../../../hooks/fetch/useDashboardPrepay';
import { useDashboards } from '../../hooks/useDashboards';
import { PropsFilterTimeline } from './types';

export const usePrepay = () => {
  const {
    getPrepayCards,
    getPrepayCharts,
    getPrepayTimeline,
    getTimelineReport,
    generateExportTimeline,
    isFetchingTimeline,
    isFetchingCharts,
    isFetchingCards,
  } = useDashboardPrepay();

  const { handleCards, handleCharts, handleChangeTable } = useDashboards();

  const [filterForm] = Form.useForm();
  const [searchFormTimeline] = Form.useForm();

  const handleCardsPrepay = async () => {
    const response = await handleCards(getPrepayCards, filterForm);
    return response;
  };

  const handleChartsPrepay = async () => {
    const response = await handleCharts(getPrepayCharts, filterForm);
    const { dataChartOperation, dataChartLimit, dataChartProgress, dataChartExposition } = response;
    return { dataChartOperation, dataChartLimit, dataChartProgress, dataChartExposition };
  };

  const handleChangeTableTimeline = async (
    pagination: TablePaginationConfig,
    filters: PropsFilterTimeline,
    sorter: any,
  ) => {
    if (Object.keys(filters).length) {
      filters.operation = OPERATION_PREPAY_ID;
      const response = await handleChangeTable(pagination, filters, sorter, getPrepayTimeline);
      return response;
    }
    return { data: [], total: 0 };
  };

  return {
    handleCardsPrepay,
    handleChartsPrepay,
    handleChangeTableTimeline,
    getTimelineReport,
    generateExportTimeline,
    filterForm,
    searchFormTimeline,
    isFetchingTimeline,
    isFetchingCharts,
    isFetchingCards,
  };
};

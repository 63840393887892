import React, { useCallback, useEffect, useState } from 'react';
import { ChromePicker } from 'react-color';
import { useTranslation } from 'react-i18next';
import { FaMinus, FaPlus } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';

import { Col, Form, InputNumber, Spin } from 'antd';
import { TablePaginationConfig, TableProps } from 'antd/lib/table';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';

import DTOTeam, { DTOTeamIndexNames } from '../../../@types/dtos/credit-request/DTOTeam';
import {
  Breadcrumb,
  Button,
  CellBorderColor,
  FormItem,
  IconWithTooltip,
  Input,
  ModalStatus,
  Row,
} from '../../../components';
import { TableData } from '../../../compositions';
import { Can } from '../../../hooks/ability';
import { useCache } from '../../../hooks/cache';
import { usePage } from '../../../hooks/page';
import adminApi from '../../../services/api/admin';
import { getTranslations } from '../../../services/translations';
import { Formatter } from '../../../utils/Formatter';
import renderComponent from '../../../utils/RenderComponent';
import columns from './columns';
import {
  BoxColorPicker,
  ButtonColorPicker,
  Container,
  ContainerColorPicker,
  SDivider,
  SFormButtons,
  SFormContainer,
} from './styles';
import { DataTable } from './types';

const Teams: React.FC = () => {
  const { t, i18n } = useTranslation();
  const [form] = Form.useForm();

  const { updateCache } = useCache();
  const { alertStatus } = usePage();

  const [data, setData] = useState<DTOTeam[]>([]);
  const [loading, setLoading] = useState(false);
  const [paginationConfig, setPaginationConfig] = useState<TablePaginationConfig>({
    current: 1,
    total: 1,
    pageSize: 20,
  });
  const [visibilityForm, setVisibilityForm] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [editing, setEditing] = useState(false);
  const [colorTeamStatus, setColorTeamStatus] = useState<string>('#ffffff');
  const [colorPickerShow, setColorPickerShow] = useState<boolean>(false);

  // Table DATA Config: BEGIN
  const getActions = () => ({
    render: (active: number, record: any) => (
      <>
        <Can I="admin.team.destroy" a="">
          <IconWithTooltip
            action="destroy"
            title={t('pages.admin.teams.table.actions.removeIcon')}
            onClick={() => {
              ModalStatus({
                type: 'delete',
                title: t('pages.admin.teams.table.actions.title-delete'),
                subTitle: t('pages.admin.teams.table.actions.subtitle-delete'),
                cancelText: t('pages.admin.teams.table.actions.cancel-delete'),
                okText: t('pages.admin.teams.table.actions.confirm-delete'),
                onOk: () => {
                  adminApi.teams
                    .delete(record.id, {
                      params: {},
                    })
                    .then(async response => {
                      setLoading(true);
                      await updateCache(null);
                      alertStatus(response.data.message);
                      // eslint-disable-next-line @typescript-eslint/no-use-before-define
                      loadTableData({
                        page: paginationConfig.current,
                        per_page: paginationConfig.pageSize,
                      });
                    })
                    .catch(err => alertStatus(err, 'error'));
                },
              });
            }}
          />
        </Can>
        <Can I="admin.team.update" a="">
          <IconWithTooltip
            action="edit"
            title={t('pages.admin.teams.table.actions.editIcon')}
            onClick={() => {
              let translationsPtBr = null;
              let translationsEn = null;
              let translationsCn = null;

              if (record.translations) {
                translationsPtBr = record.translations['pt-br'];
                translationsEn = record.translations.en;
                translationsCn = record.translations.cn;
              }

              form.setFieldsValue({
                ...record,
                'pt-br.title': translationsPtBr?.title,
                'en.title': translationsEn?.title,
                'cn.title': translationsCn?.title,
              });

              setColorTeamStatus(record.color || '#ffffff');

              form.scrollToField('name', {
                scrollMode: 'always',
                block: 'start',
                behavior: actions =>
                  actions.forEach(({ el, top }) => {
                    el.scrollTop = top - 280;
                  }),
              });

              setVisibilityForm(true);
              setEditing(true);
              setLoading(false);
              setLoadingButton(false);
              setDisabledButton(false);
            }}
          />
        </Can>
      </>
    ),
  });

  const tableConfig = {
    search: '',
    filtered: {},
    sorter: {},
    getActions,
  };

  const [tableDataConfig, setTableDataConfig] = useState(tableConfig);

  const loadTableData = useCallback(
    (values: DataTable) => {
      setLoading(true);
      adminApi.teams
        .get({
          params: {
            ...values,
          },
        })
        .then(response => {
          const result = response.data.data;
          setData(result.data);
          setPaginationConfig(paginationConfigState => ({
            ...paginationConfigState,
            current: result.current_page,
            total: result.total,
          }));
        })
        .catch(err => alertStatus(err, 'error'))
        .finally(() => setLoading(false));
    },
    // eslint-disable-next-line
    [i18n.language],
  );

  const handleTableChange: TableProps<any>['onChange'] = useCallback(
    (
      pagination: TablePaginationConfig,
      filters: Record<string, FilterValue | null>,
      sorter: SorterResult<any> | SorterResult<any>[],
    ) => {
      setLoading(true);
      setTableDataConfig(tableDataConfigState => ({
        ...tableDataConfigState,
        filtered: filters,
        sorter,
      }));

      let direction = '';
      const search: any = {};
      let sort = '';

      if (sorter) {
        const { field, order } = sorter as SorterResult<any>;

        if (order === 'ascend') direction = 'asc';
        if (order === 'descend') direction = 'desc';
        sort = field?.toString();
      }

      if (filters) {
        const dataFilter: any = filters;

        if (dataFilter) {
          Object.keys(dataFilter).forEach((key: string) => {
            if (dataFilter[key] !== null && dataFilter[key] !== undefined && dataFilter[key][0]) {
              search[key] = dataFilter[key][0].toString();
            } else if (dataFilter[key] !== null && dataFilter[key] !== undefined) {
              search[key] = dataFilter[key].toString();
            } else {
              search[key] = '';
            }
          });
        }
      }

      setPaginationConfig(paginationConfigState => ({
        ...paginationConfigState,
        pageSize: pagination.pageSize,
        current: pagination.current,
      }));

      loadTableData({
        page: pagination.current,
        per_page: pagination.pageSize,
        sort,
        direction,
        ...search,
      });
    },
    [loadTableData],
  );

  useEffect(() => {
    const filters: any = {};
    handleTableChange({ current: 1, pageSize: paginationConfig.pageSize }, filters, {}, null);
  }, [handleTableChange, paginationConfig.pageSize]);
  // Table DATA Config: END

  const handleDisplayForm = useCallback(() => {
    setVisibilityForm(true);
    setEditing(false);
    // eslint-disable-next-line
  }, []);

  const handleHideForm = useCallback(() => {
    setVisibilityForm(false);
    setEditing(false);
    setColorPickerShow(false);
    form.resetFields();
    // eslint-disable-next-line
  }, []);

  const handleSave = useCallback(() => {
    const formValues = form.getFieldsValue();
    setLoadingButton(true);
    setDisabledButton(true);

    const values: any = {
      translations: {
        'pt-br': {},
        en: {},
        cn: {},
      },
    };

    Object.keys(formValues).forEach(key => {
      const formKey = key.split('.');
      if (formKey.length > 1) {
        values.translations[formKey[0]][formKey[1]] = formValues[key];
      } else {
        values[key] = formValues[key];
      }
    });

    values.language = i18n.language;

    const api = adminApi.teams;
    let response;

    if (!formValues.id) {
      response = api.store(values);
    } else {
      response = api.update(formValues.id, values);
    }

    response
      .then(async () => {
        setLoading(true);
        handleHideForm();
        await updateCache(null);
        alertStatus(t('pages.admin.teams.form.success-save'));
        loadTableData({
          page: paginationConfig.current,
          per_page: paginationConfig.pageSize,
        });
      })
      .catch(err => alertStatus(err, 'error'))
      .finally(() => {
        handleHideForm();
        setLoadingButton(false);
        setDisabledButton(false);
      });
    // eslint-disable-next-line
  }, []);

  return (
    <Container>
      <Breadcrumb items={[{ title: t('breadcrumb.admin') }, { title: t('breadcrumb.teams') }]} />

      <div className="content-container">
        <div className="grid-actions">
          <h2>{t('pages.admin.teams.title')}</h2>

          <div className="grid-button-action-header">
            <Can I="admin.team.store" a="">
              <Button
                status="primary"
                icon={visibilityForm ? <FaMinus /> : <FaPlus />}
                onClick={() => (visibilityForm ? handleHideForm() : handleDisplayForm())}
              >
                {renderComponent(
                  !visibilityForm,
                  t('pages.admin.teams.buttons.add'),
                  t('pages.admin.teams.buttons.close'),
                )}
              </Button>
            </Can>
          </div>
        </div>

        <SFormContainer visible={visibilityForm}>
          <Spin spinning={loading}>
            <h2>{renderComponent(!editing, t('pages.admin.teams.title-add'), t('pages.admin.teams.title-edit'))}</h2>

            <Form form={form} onFinish={handleSave}>
              <FormItem name="id" hidden>
                <Input />
              </FormItem>

              <Row gutter={[26, 26]}>
                <Col xs={24} sm={24} md={24} lg={9} xl={9} xxl={9}>
                  <FormItem label={t('pages.admin.teams.form.name')} name="name" rules={[{ required: true }]}>
                    <Input maxLength={255} />
                  </FormItem>
                </Col>

                <Col xs={24} sm={24} md={24} lg={9} xl={9} xxl={9}>
                  <FormItem label={t('pages.admin.teams.form.slug')} name="slug" rules={[{ required: true }]}>
                    <Input maxLength={255} />
                  </FormItem>
                </Col>

                <Col xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}>
                  <FormItem label={t('pages.admin.teams.form.order')} name="order">
                    <InputNumber maxLength={255} />
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={[26, 26]}>
                <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                  <FormItem name="color" initialValue="" hidden>
                    <Input />
                  </FormItem>
                  <FormItem label={t('pages.admin.teams.form.color')}>
                    <ContainerColorPicker>
                      <ButtonColorPicker
                        colorHex={colorTeamStatus}
                        type="button"
                        onClick={() => setColorPickerShow(true)}
                      />

                      {colorPickerShow && (
                        <BoxColorPicker>
                          <div className="rightClose">
                            <MdClose onClick={() => setColorPickerShow(false)} />
                          </div>
                          <ChromePicker
                            color={colorTeamStatus}
                            onChangeComplete={color => {
                              if (color) {
                                form.setFieldsValue({
                                  color: color.hex,
                                });
                                setColorTeamStatus(color.hex);
                              }
                              return false;
                            }}
                          />
                        </BoxColorPicker>
                      )}
                    </ContainerColorPicker>
                  </FormItem>
                </Col>
              </Row>

              <h2>{t('pages.admin.teams.form.translations')}</h2>

              <SDivider orientation="left">{t('header.lang.pt-br')}</SDivider>

              <Row gutter={[26, 26]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                  <FormItem label={t('pages.admin.teams.form.title')} name="pt-br.title" rules={[{ required: true }]}>
                    <Input maxLength={255} />
                  </FormItem>
                </Col>
              </Row>

              <SDivider orientation="left">{t('header.lang.en')}</SDivider>

              <Row gutter={[26, 26]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                  <FormItem label={t('pages.admin.teams.form.title')} name="en.title" rules={[{ required: true }]}>
                    <Input maxLength={255} />
                  </FormItem>
                </Col>
              </Row>

              <SDivider orientation="left">{t('header.lang.cn')}</SDivider>

              <Row gutter={[26, 26]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                  <FormItem label={t('pages.admin.teams.form.title')} name="cn.title" rules={[{ required: true }]}>
                    <Input maxLength={255} />
                  </FormItem>
                </Col>
              </Row>

              <SFormButtons>
                <Button status="secondary" htmlType="reset" onClick={handleHideForm} disabled={disabledButton}>
                  {t('pages.admin.teams.buttons.cancel')}
                </Button>

                {renderComponent(
                  editing,
                  <Button status="primary" htmlType="submit" loading={loadingButton} disabled={disabledButton}>
                    {t('pages.admin.teams.buttons.edit')}
                  </Button>,
                  <Button status="primary" htmlType="submit" loading={loadingButton} disabled={disabledButton}>
                    {t('pages.admin.teams.buttons.save')}
                  </Button>,
                )}
              </SFormButtons>
            </Form>
          </Spin>
        </SFormContainer>

        <TableData
          rowKey="id"
          dataSource={data}
          columns={columns.teams.map(col => {
            const column: any = col;
            switch (column.key) {
              case 'title':
                return {
                  ...column,
                  render: (_, record: DTOTeam) => {
                    return (
                      <CellBorderColor color={record.color}>
                        {getTranslations(record, 'title', i18n.language)}
                      </CellBorderColor>
                    );
                  },
                };
              case 'created_at':
              case 'updated_at':
                return {
                  ...column,
                  render: (_, record: DTOTeam) => {
                    const key: DTOTeamIndexNames = column.key as DTOTeamIndexNames;
                    return record[key] ? Formatter.datetime(record[key].toString()) : '';
                  },
                };
              default:
                return column;
            }
          })}
          loading={loading}
          onChange={handleTableChange}
          pagination={paginationConfig}
          tableConfig={tableDataConfig}
        />
      </div>
    </Container>
  );
};

export default Teams;

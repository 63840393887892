import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaMinus } from 'react-icons/fa';

import { Col, Form } from 'antd';
import { TablePaginationConfig } from 'antd/lib/table';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';

import { Breadcrumb, Button, FormItem, IconWithTooltip, Input, Row, Spin } from '../../../components';
import { TableData } from '../../../compositions';
import { TableProps } from '../../../compositions/TableData/types';
import { Can } from '../../../hooks/ability';
import { useCache } from '../../../hooks/cache';
import { usePage } from '../../../hooks/page';
import adminApi from '../../../services/api/admin';
import columns from './columns';
import { Container, SFormButtons, SFormContainer } from './styles';
import { DataTable } from './types';

const CreditRequestParameterization: React.FC = () => {
  const { t, i18n } = useTranslation();
  const [form] = Form.useForm();

  const { alertStatus } = usePage();
  const { updateCache } = useCache();

  const [data, setData] = useState<[]>([]);
  const [loading, setLoading] = useState(false);
  const [paginationConfig, setPaginationConfig] = useState<TablePaginationConfig>({
    current: 1,
    total: 1,
    pageSize: 20,
  });
  const [visibilityForm, setVisibilityForm] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const setEditing = useState(false)[1];

  // Table DATA Config: BEGIN
  const getActions = () => ({
    render: (active: number, record: any) => (
      <>
        <Can I="credit.teamstatus.update" a="">
          <IconWithTooltip
            action="edit"
            title={t('pages.admin.credit-request-parameterization.table.actions.editIcon')}
            onClick={() => {
              form.setFieldsValue({
                ...record,
              });

              form.scrollToField('name', {
                scrollMode: 'always',
                block: 'start',
                behavior: actions =>
                  actions.forEach(({ el, top }) => {
                    el.scrollTop = top - 280;
                  }),
              });

              setVisibilityForm(true);
              setEditing(true);
              setLoading(false);
              setLoadingButton(false);
              setDisabledButton(false);
            }}
          />
        </Can>
      </>
    ),
  });

  const tableConfig = {
    search: '',
    filtered: {},
    sorter: {},
    getActions,
  };

  const [tableDataConfig, setTableDataConfig] = useState(tableConfig);

  const loadTableData = useCallback(
    (values: DataTable) => {
      setLoading(true);
      adminApi.taxes
        .get({
          params: {
            ...values,
          },
        })
        .then(response => {
          const result = response.data;
          setData(result.data);
          setPaginationConfig(paginationConfigState => ({
            ...paginationConfigState,
            current: result.current_page,
            total: result.total,
          }));
        })
        .catch(err => alertStatus(err, 'error'))
        .finally(() => setLoading(false));
    },
    // eslint-disable-next-line
    [i18n.language],
  );

  const handleTableChange: TableProps<any>['onChange'] = useCallback(
    (
      pagination: TablePaginationConfig,
      filters: Record<string, FilterValue | null>,
      sorter: SorterResult<any> | SorterResult<any>[],
    ) => {
      setLoading(true);
      setTableDataConfig(tableDataConfigState => ({
        ...tableDataConfigState,
        filtered: filters,
        sorter,
      }));

      let direction = '';
      const search: any = {};
      let sort = '';

      if (sorter) {
        const { field, order } = sorter as SorterResult<any>;

        if (order === 'ascend') direction = 'asc';
        if (order === 'descend') direction = 'desc';
        sort = field?.toString();
      }

      if (filters) {
        const dataFilter: any = filters;

        if (dataFilter) {
          Object.keys(dataFilter).forEach((key: string) => {
            if (dataFilter[key] !== null && dataFilter[key] !== undefined && dataFilter[key][0]) {
              search[key] = dataFilter[key][0].toString();
            } else if (dataFilter[key] !== null && dataFilter[key] !== undefined) {
              search[key] = dataFilter[key].toString();
            } else {
              search[key] = '';
            }
          });
        }
      }

      setPaginationConfig(paginationConfigState => ({
        ...paginationConfigState,
        pageSize: pagination.pageSize,
        current: pagination.current,
      }));

      loadTableData({
        page: pagination.current,
        per_page: pagination.pageSize,
        sort,
        direction,
        ...search,
      });
    },
    [loadTableData],
  );

  useEffect(() => {
    const filters: any = {};
    handleTableChange({ current: 1, pageSize: paginationConfig.pageSize }, filters, {}, null);
  }, [handleTableChange, paginationConfig.pageSize]);
  // Table DATA Config: END

  // const handleDisplayForm = useCallback(() => {
  //   setVisibilityForm(true);
  //   setEditing(false);
  // }, []);

  const handleHideForm = useCallback(() => {
    setVisibilityForm(false);
    setEditing(false);
    form.resetFields();
    // eslint-disable-next-line
  }, []);

  const handleSave = useCallback(async () => {
    const formValues = form.getFieldsValue();
    setLoadingButton(true);
    setDisabledButton(true);

    const response = adminApi.taxes.update(formValues.id, { ...formValues });

    response
      .then(async () => {
        setLoading(true);
        handleHideForm();
        await updateCache(null);
        alertStatus(t('pages.admin.credit-request-parameterization.form.success-save'));
        loadTableData({
          page: paginationConfig.current,
          per_page: paginationConfig.pageSize,
        });
      })
      .catch(err => alertStatus(err, 'error'))
      .finally(() => {
        handleHideForm();
        setLoadingButton(false);
        setDisabledButton(false);
      });
    // eslint-disable-next-line
  }, []);

  return (
    <Container>
      <Breadcrumb
        items={[{ title: t('breadcrumb.admin') }, { title: t('breadcrumb.credit_request_parameterization') }]}
      />

      <div className="content-container">
        <div className="grid-actions">
          <h2>{t('pages.admin.credit-request-parameterization.title')}</h2>

          <div className="grid-button-action-header">
            {/* <Can I="credit.teamstatus.store" a=""> */}
            {visibilityForm && (
              <Button status="primary" icon={<FaMinus />} onClick={() => handleHideForm()}>
                {t('pages.admin.credit-request-parameterization.buttons.close')}
              </Button>
            )}
            {/* </Can> */}
          </div>
        </div>

        <SFormContainer visible={visibilityForm}>
          <Spin spinning={loading}>
            <h2>{t('pages.admin.credit-request-parameterization.title-edit')}</h2>

            <Form form={form} onSubmitCapture={handleSave}>
              <FormItem name="id" hidden>
                <Input />
              </FormItem>

              <Row gutter={[26, 26]}>
                <Col xs={24} sm={24} md={24} lg={9} xl={9} xxl={9}>
                  <FormItem label={t('pages.admin.credit-request-parameterization.form.name')} name="name">
                    <Input disabled />
                  </FormItem>
                </Col>
                <Col xs={24} sm={24} md={24} lg={9} xl={9} xxl={9}>
                  <FormItem label={t('pages.admin.credit-request-parameterization.form.tag')} name="tag">
                    <Input disabled />
                  </FormItem>
                </Col>
                <Col xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}>
                  <FormItem
                    label={t('pages.admin.credit-request-parameterization.form.value')}
                    name="value"
                    rules={[{ required: true }]}
                  >
                    <Input min={0} maxLength={12} />
                  </FormItem>
                </Col>
              </Row>

              <SFormButtons>
                <Button status="secondary" htmlType="reset" onClick={handleHideForm} disabled={disabledButton}>
                  {t('pages.admin.credit-request-parameterization.buttons.cancel')}
                </Button>

                <Button status="primary" htmlType="submit" loading={loadingButton} disabled={disabledButton}>
                  {t('pages.admin.credit-request-parameterization.buttons.edit')}
                </Button>
              </SFormButtons>
            </Form>
          </Spin>
        </SFormContainer>

        <TableData
          rowKey="id"
          dataSource={data}
          columns={columns.parameterization.map(col => {
            const column: any = col;
            switch (column.key) {
              default:
                return column;
            }
          })}
          loading={loading}
          onChange={handleTableChange}
          pagination={paginationConfig}
          tableConfig={tableDataConfig}
        />
      </div>
    </Container>
  );
};

export default CreditRequestParameterization;

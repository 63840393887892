import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Form as AntForm, Input as AntInput } from 'antd';

import { Button, FormItem, EmptyComponent, Spin } from '../../../../../components';
import CommentItem from './CommentItem';
import ChatItemProps from './CommentItem/types';
import { useSerasaCounterpartyCommentsController } from './controller';
import { Container } from './styles';
import CommentsProps from './types';

const Comments: React.FC<CommentsProps> = ({ serasaCounterpartyId }) => {
  const { t } = useTranslation();
  const {
    handleGetComments,
    loading,
    setBlockLoadComments,
    totalPages,
    forceLoadComments,
    scrollBottom,
    commentsRaw,
    scrollToBottom,
    formatComments,
    comment,
    setComment,
    page,
    setPage,
    isFetchingComments,
    commentForm,
    sendComment,
    loadingButton,
    setLoadingButton,
  } = useSerasaCounterpartyCommentsController({
    serasaCounterpartyId,
  });

  const scrollObserve = useRef();

  const [scrollRadio, setScrollRadio] = useState(null);

  const intersectionObserver = new IntersectionObserver(entries => {
    const radio = entries[0].intersectionRatio;
    setScrollRadio(Math.round(radio));
  });

  const comments = useMemo(() => {
    return formatComments();
  }, [commentsRaw]);

  // useEffect(() => {
  //   // handleGetComments(1);
  // }, []);

  useEffect(() => {
    if (forceLoadComments === true) handleGetComments(1);
  }, [forceLoadComments]);

  useEffect(() => {
    intersectionObserver.observe(scrollObserve.current);
    return () => {
      intersectionObserver.disconnect();
    };
    // eslint-disable-next-line
  }, [intersectionObserver]);

  useEffect(() => {
    scrollToBottom(true);
  }, [scrollBottom]);

  useEffect(() => {
    if (scrollRadio > 0 && !!Object.keys(comments).length) {
      const newPage = page + 1;
      if (page >= totalPages) {
        setBlockLoadComments(true);
      } else {
        setBlockLoadComments(false);
        setPage(newPage);
        handleGetComments(newPage);
      }
    }
    // eslint-disable-next-line
  }, [scrollRadio]);

  const submitComment = (values: any) => {
    setLoadingButton(true);
    sendComment(values);
  };

  return (
    <Spin spinning={loading}>
      <Container>
        <div className="request-comments-container">
          <div className="request-comments-comments">
            <div className="comments-container" ref={scrollBottom}>
              <Spin spinning={isFetchingComments}>
                <div ref={scrollObserve} style={{ marginTop: '10px' }} />
                {Object.keys(comments).length === 0 ? (
                  <EmptyComponent description={t('pages.serasacounterparty.commentts.nothing')} />
                ) : (
                  Object.keys(comments).map((keyComment: any) => {
                    const date = keyComment.split(' ')[0];
                    const times = date.split('-');
                    let commentDate: ChatItemProps | null = {
                      key: date,
                      date: {
                        key: date,
                        day: Number(times[2]),
                        month: times[1],
                        year: Number(times[0]),
                      },
                    };
                    const commentsList: any = comments;
                    let alreadyHasDate = 0;
                    return commentsList[keyComment].map((messageItem: ChatItemProps) => {
                      alreadyHasDate += 1;
                      if (alreadyHasDate === 2) {
                        commentDate = null;
                      }

                      return messageItem ? (
                        <CommentItem
                          key={`${keyComment}-${messageItem.key}`}
                          date={commentDate ? commentDate.date : null}
                          messages={messageItem?.messages}
                          user={messageItem?.user}
                          isMe={messageItem?.isMe}
                        />
                      ) : (
                        ''
                      );
                    });
                  })
                )}
              </Spin>
            </div>

            <AntForm form={commentForm} onFinish={values => submitComment(values)} className="request-comments-form">
              <FormItem name="comment" extra={`${comment.length}/500`}>
                <AntInput.TextArea
                  rows={3}
                  maxLength={500}
                  onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                    const { value } = event.target;
                    setComment(value);
                  }}
                  style={{ maxHeight: '80px' }}
                />
              </FormItem>

              <div className="request-comments-form-item-button">
                <Button
                  loading={loadingButton}
                  disabled={!commentForm.getFieldValue('comment')}
                  htmlType="submit"
                  status="primary"
                >
                  {t('pages.credit-request.contact-registration.button.send-message')}
                </Button>
              </div>
            </AntForm>
          </div>
        </div>
      </Container>
    </Spin>
  );
};

export default Comments;

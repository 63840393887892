import styled from 'styled-components';

export const Container = styled.div`
  display: flex;

  .comments {
    display: flex;
    flex-direction: column;
    width: 100%;
    word-break: break-word;
  }

  .date {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 16px;
    color: ${({ theme: { colors } }) => colors.text};
    font-size: 14px;
    font-weight: 600;
  }

  .comment {
    display: flex;
    justify-content: flex-start;
    background: ${({ theme: { colors } }) => colors.greys.grey01};
    border-radius: 4px;
    color: ${({ theme: { colors } }) => colors.text};
    padding: 24px 16px;
    position: relative;
    width: 100%;
  }

  .comment-item {
    display: flex;
    position: relative;

    & + .comment-item,
    & + .last-comment-item {
      margin-top: 6px;
    }
  }

  .comment-hour {
    position: absolute;
    bottom: 4px;
    right: 8px;
    font-size: 12px;
  }

  .last-comment-item {
    display: flex;
    position: relative;

    .comment-container {
      display: flex;
      flex-direction: column;
      flex: 1;
    }

    .perfil-container {
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      padding: 0;
      position: absolute;
    }
  }

  .username {
    display: flex;
    justify-content: flex-end;
    color: ${({ theme: { colors } }) => colors.text};
    font-size: 14px;
    font-weight: 400;
  }

  .perfil {
    background: ${({ theme: { colors } }) => colors.primary};
    border-radius: 50%;
    color: ${({ theme: { colors } }) => colors.greys.grey01};
    font-size: 24px;
    font-weight: 600;
    height: 60px;
    width: 60px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.request-comments-user-other {
    .comments {
      order: 2;
    }

    .comment {
      background: ${({ theme: { colors } }) => colors.primary};
      color: #ffffff;
    }

    .comment-item {
      padding-left: 100px;
    }

    .last-comment-item {
      padding-left: 100px;

      .comment-container {
        order: 2;

        .comment::before {
          content: '';
          position: absolute;
          left: -12px;
          top: 20px;
          width: 0;
          height: 0;
          border-top: 10px solid transparent;
          border-bottom: 10px solid transparent;
          border-right: 12px solid ${({ theme: { colors } }) => colors.primary};
        }
      }

      .perfil-container {
        order: 1;
        left: 0;
      }
    }
  }

  &.request-comments-user-me {
    .comment-item {
      padding-right: 100px;
    }

    .last-comment-item {
      padding-right: 100px;

      .comment-container {
        order: 1;

        .comment::after {
          content: '';
          position: absolute;
          right: -12px;
          top: 20px;
          width: 0;
          height: 0;
          border-top: 10px solid transparent;
          border-bottom: 10px solid transparent;
          border-left: 12px solid ${({ theme: { colors } }) => colors.greys.grey01};
        }
      }

      .perfil-container {
        order: 2;
        right: 0;

        .perfil {
          background: ${({ theme: { colors } }) => colors.greys.grey07};
        }
      }
    }
  }
`;

export const UserImg = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
`;

import { useQuery } from '@tanstack/react-query';
import api from 'services/api/api';

type GetProviderRequestsResponse = any;

type UseGetProviderRequestsQuery = {
  providerId: string;
  date: string;
  filter: any;
  onSuccess?: (data: GetProviderRequestsResponse) => void;
};

export const useGetProviderRequestsQuery = ({ providerId, date, filter, onSuccess }: UseGetProviderRequestsQuery) => {
  return useQuery({
    queryKey: ['providerRequests', providerId, date, filter],
    queryFn: async () => {
      if (!providerId || !date) return Promise.resolve([]);

      const response = await api.get<GetProviderRequestsResponse>('/dashboard/exposure/requests', {
        params: {
          date,
          provider: providerId,
          ...filter,
        },
      });

      if (onSuccess) onSuccess(response.data);

      return response.data;
    },
  });
};

import { useMutation } from '@tanstack/react-query';
import { usePage } from 'hooks/page';
import api from 'services/api/api';

export const useDeleteDraftSubscriberMutation = () => {
  const { alertStatus } = usePage();

  return useMutation({
    mutationKey: ['deleteDraftSubscriber'],
    mutationFn: async (subscriberId: string) => {
      return api.delete(`envelope/destroy-recipient/${subscriberId}`);
    },
    onError: err => {
      alertStatus(err.message, 'error');
    },
  });
};

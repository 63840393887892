import React, { useState } from 'react';

import { Tab } from '../../../../components/Tab';
import { Body } from './Body';
import { Header } from './Header';
import { UploadBody } from './UploadBody';

interface Props {
  isCompare?: boolean;
  lowerButton?: boolean;
  controlledSetSelectedAttachment?: React.Dispatch<React.SetStateAction<any>>;
}

export const Attachments = ({ isCompare = false, lowerButton = false, controlledSetSelectedAttachment }: Props) => {
  const [shouldShowUpload, setShouldShowUpload] = useState(false);
  const [selectedAttachment, setSelectedAttachment] = useState<any>();

  const changeSelectedAttachment = (document: any) => {
    if (controlledSetSelectedAttachment) controlledSetSelectedAttachment(document);
    setSelectedAttachment(document);
  };

  const showUpload = () => setShouldShowUpload(true);
  const hideUpload = () => setShouldShowUpload(false);

  const shouldShowMainContent = !shouldShowUpload;

  return (
    <Tab>
      <Header
        isCompare={isCompare}
        hideUpload={hideUpload}
        shouldShowUpload={shouldShowUpload}
        shouldShowMainContent={shouldShowMainContent}
      />

      {shouldShowUpload && <UploadBody hideUpload={hideUpload} />}

      {shouldShowMainContent && (
        <Body
          showUpload={showUpload}
          lowerButton={lowerButton}
          changeSelectedAttachment={changeSelectedAttachment}
          selectedAttachmentId={selectedAttachment?.id}
          isCompare={isCompare}
        />
      )}
    </Tab>
  );
};

import { FixedType } from 'rc-table/lib/interface';

export const columns = [
  {
    title: 'pages.sustainability.registrationTable.registrationNumber',
    dataIndex: 'registration_number',
    key: 'registration_number',
  },
  {
    title: 'pages.sustainability.registrationTable.farmName',
    dataIndex: 'farm',
    key: 'farm',
  },
  {
    title: 'pages.sustainability.registrationTable.owners',
    dataIndex: 'owner',
    key: 'owner',
  },
  {
    title: 'pages.sustainability.registrationTable.county',
    dataIndex: 'city',
    key: 'city',
  },
  {
    title: 'pages.sustainability.registrationTable.uf',
    dataIndex: 'state',
    key: 'state',
  },
  {
    title: 'pages.sustainability.registrationTable.status',
    dataIndex: 'sustainability_registration_status',
    key: 'sustainability_registration_status',
  },
  {
    title: 'pages.sustainability.registrationTable.actions',
    dataIndex: 'action',
    key: 'action',
    width: '100px',
    fixed: 'right' as FixedType,
  },
];

export default {
  history: [
    {
      title: 'pages.credit-request.historic.table.headers.who_changed',
      dataIndex: 'name',
      key: 'name',
      sorter: false,
      filtered: false,
    },
    {
      title: 'pages.credit-request.historic.table.headers.when_changed',
      dataIndex: 'updated_at',
      key: 'updated_at',
      sorter: false,
      filtered: false,
    },
  ],
  historyRow: [
    {
      title: 'pages.credit-request.historic.table.headers.field_changed',
      dataIndex: 'field',
      key: 'field',
      sorter: false,
      filtered: false,
    },
    {
      title: 'pages.credit-request.historic.table.headers.previous_value',
      dataIndex: 'old',
      key: 'old',
      sorter: false,
      filtered: false,
    },
    {
      title: 'pages.credit-request.historic.table.headers.new_value',
      dataIndex: 'new',
      key: 'new',
      sorter: false,
      filtered: false,
    },
  ],
};

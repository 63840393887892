import React from 'react';

import { SelectProps } from 'antd';
import Select from 'components/Select';
import { useCache } from 'hooks/cache';

export const RegionSelect = (props: Omit<SelectProps<any>, 'options'>) => {
  const cache = useCache();

  return <Select showSearch options={cache.getCreditOptions('region')} {...props} />;
};

import React from 'react';
import { FaCheck } from 'react-icons/fa';

import moment from 'moment';

import theme from '../../../../../styles/theme';
import { Bar, Container, Dot, Name, Wrapper } from './styles';
import { StageProps } from './types';

export const Stage = ({ title, completed, date, username }: StageProps) => {
  return (
    <Container>
      <h4>{title}</h4>
      <Wrapper>
        <Dot completed={completed}>{completed && <FaCheck size={12} color={theme.colors.white} />}</Dot>
        <div>
          {completed && <Name>{username}</Name>}

          <Bar completed={completed} />

          {completed && <Name>{date && moment(date).format('DD/MM/YYYY HH:mm:ss')}</Name>}
        </div>
      </Wrapper>
    </Container>
  );
};

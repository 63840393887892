import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Col, Radio, Spin } from 'antd';
import { useAuth } from 'hooks/auth';

import { Button, ModalStatus } from '../../../../components';
import { useUser } from '../../../../hooks/fetch/useUsers';
import { NotificationController } from '../notificationController';
import { SFormButtons, SFormContainer } from '../styles';
import { NotificationTree } from './NotificationTree';

interface Props {
  handleHideForm?: () => void;
  treeController: NotificationController;
  userId: string;
}

export const NotificationConfigTab = ({ handleHideForm = undefined, treeController, userId }: Props) => {
  const { t } = useTranslation();
  const { updateNotificationsConfiguration, getNotificationConfigurationsOptions, isUpdating } = useUser();
  const [shouldSendEmail, setShouldSendEmail] = useState(false);
  const [treeData, setTreeData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { user, updateUserData } = useAuth();

  const handleSuccess = (messageSuccess = '') =>
    ModalStatus({
      type: 'success',
      title: t('modal.success'),
      subTitle: messageSuccess,
    });

  const handleEdit = async () => {
    const keys = treeController.checkedKeys as Array<string>;

    const formData = new FormData();
    keys.map(key => formData.append(`messages[]`, key));

    const response = await updateNotificationsConfiguration(userId, keys, shouldSendEmail);

    treeController.updateConfig(userId);

    if (response) {
      updateUserData();
      handleSuccess(t('user.notification.success'));
      handleHideForm();
    }
  };

  const getTranslatedTitle = (title: string) => t(`notification.types.${title.replaceAll('.', '@')}`);

  const translateTitles = (notificationTypes: any) => {
    if (!notificationTypes) return [];
    return notificationTypes.map((type: any) => ({
      ...type,
      title: getTranslatedTitle(type.title),
      children: translateTitles(type.children),
    }));
  };

  const updateTreeData = async () => {
    setIsLoading(true);
    await treeController.updateConfig(userId);
    const response = await getNotificationConfigurationsOptions();
    if (response) setTreeData(translateTitles(response.tree));
    setIsLoading(false);
  };

  useEffect(() => {
    updateTreeData();
  }, []);

  useEffect(() => {
    if (user) setShouldSendEmail(user.send_email);
  }, [user]);

  return (
    <SFormContainer visible>
      <h2>Configurar notificaçoes</h2>
      <Spin spinning={isLoading}>
        <NotificationTree controller={treeController} treeData={treeData} />

        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
          <strong>{t('pages.admin.user.sendEmail')}</strong>
          <Radio.Group value={shouldSendEmail} buttonStyle="solid" onChange={e => setShouldSendEmail(e.target.value)}>
            <Radio.Button value>{t('pages.sustainability.form.yes')}</Radio.Button>
            <Radio.Button value={false}>{t('pages.sustainability.form.no')}</Radio.Button>
          </Radio.Group>
        </Col>

        <SFormButtons>
          <Button status="secondary" htmlType="reset" onClick={handleHideForm} disabled={false}>
            {t('pages.admin.user.buttonCancel')}
          </Button>
          <Button status="primary" htmlType="submit" onClick={handleEdit} disabled={false} loading={isUpdating}>
            {t('pages.admin.user.buttonEdit')}
          </Button>
        </SFormButtons>
      </Spin>
    </SFormContainer>
  );
};

import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import api, { handleRequest } from '../../services/api/api';
import serasaApi from '../../services/api/serasa';

export const useSerasaCounterparty = () => {
  const { i18n } = useTranslation();

  const [isFetching, setIsFetching] = useState(false);
  const [isFetchingComments, setIsFetchingComments] = useState(false);
  const [isSendingComment, setIsSendingComment] = useState(false);
  const [isFetchingRestrictions, setIsFetchingRestrictions] = useState(false);
  const [isFetchingLogs, setIsFetchingLogs] = useState(false);
  const [isFetchingStatuses, setIsFetchingStatuses] = useState(false);

  const sendRequest = async (request: any) => {
    return handleRequest(request, setIsFetching);
  };

  const storeReport = async (payload: unknown) => {
    const response = await sendRequest(() => serasaApi.counterparties.store(payload));
    return response?.data;
  };

  const deleteReport = async (id: string) => {
    const response = await sendRequest(() => serasaApi.counterparties.destroy(id));
    return response;
  };

  const getSerasa = async (params?: { params: unknown }, filter = 'all') => {
    const response = await sendRequest(() => serasaApi.counterparties.get({ ...params, parent: filter }));

    return response || { data: [] as any[], total: 0, last_page: 0 };
  };

  const showSerasa = async (id: string, params?: { params: unknown }) => {
    const response = await sendRequest(() => serasaApi.counterparties.show(id, { ...params }));

    return response;
  };

  const create = async (values: any, params = {}) => {
    const response = await sendRequest(() => serasaApi.counterparties.create(values, params));
    return response;
  };

  const update = async (counterPartId: string, values: any, params = {}) => {
    const response = await sendRequest(() => serasaApi.counterparties.update(counterPartId, values, params));
    return response;
  };

  const getStatus = async (value: string, page: number, perPage: number) => {
    const response = await sendRequest(() =>
      serasaApi.counterparties.status({ params: { search: value, page, per_page: perPage } }),
    );
    return response?.data;
  };

  const getStatusOptions = async (value: string, page: number, perPage: number) => {
    const response = await getStatus(value, page, perPage);
    if (!response) return { data: [] as any[], total: 0, lastPage: 0 };
    return {
      data: response?.data.map((item: any) => ({ label: item.name, value: item.id, key: item.id })),
      lastPage: response.last_page,
    };
  };

  const toggleActive = async (id: string) => {
    const response = await sendRequest(() => api.delete(`serasa/counterparty/${id}`));
    return response;
  };

  const getComments = async (id: string, params = {}) => {
    const response = await handleRequest(
      () => api.get(`serasa/counterparty/${id}/comment`, params),
      setIsFetchingComments,
    );
    return response;
  };

  const sendComment = async (id: string, payload: unknown) =>
    handleRequest(() => api.post(`serasa/counterparty/${id}/comment`, payload), setIsSendingComment);

  const getRestrictions = async (id: string, params = {}) =>
    handleRequest(() => api.get(`serasa/counterparty/${id}/restriction`, params), setIsFetchingRestrictions);

  const getLogs = async (id: string, params = {}) =>
    handleRequest(() => api.get(`serasa/counterparty/${id}/log`, params), setIsFetchingLogs);

  const getStatusList = async (search: string, page: number, perPage: number) =>
    handleRequest(
      () => api.get(`serasa/counterparty/status`, { params: { search, page, perPage } }),
      setIsFetchingStatuses,
    );

  const getStatusListOptions = async (search: string, page: number, perPage: number) => {
    const response = await getStatusList(search, page, perPage);
    if (!response) return { data: [] as any[], lastPage: 0 };

    return {
      data: response.data.map((item: any) => ({
        label: item.translations[i18n.language].title,
        value: item.id,
        key: item.id,
      })),
      lastPage: response.last_page,
    };
  };

  return {
    isFetching,
    getSerasa,
    storeReport,
    deleteReport,
    create,
    getStatusOptions,
    update,
    toggleActive,
    getComments,
    isFetchingComments,
    sendComment,
    isSendingComment,
    getRestrictions,
    isFetchingRestrictions,
    getLogs,
    isFetchingLogs,
    isFetchingStatuses,
    getStatusList,
    getStatusListOptions,
    showSerasa,
  };
};

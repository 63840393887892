import { useState } from 'react';

import { UserConfig } from '../../types/User/UserData';
import { handleRequest } from '../../services/api/api';
import userApi from '../../services/api/user';

export const useUserConfig = () => {
  const [isStoring, setIsStoring] = useState(false);

  const update = async (id: string, config: UserConfig, params = {}): Promise<UserConfig> => {
    const response = await handleRequest(() => userApi.config.update(id, config, params), setIsStoring);

    return {
      ...response.config,
      app: { ...response.config.app, language: response.locale },
    };
  };

  return { update, isStoring };
};

import { create } from 'zustand';

export type RegisteredCustomerStore = {
  isModulePerson: boolean;
  pageStatus: 'new' | 'edit' | 'view';
  isViewing: boolean;
  isEditing: boolean;
  isNew: boolean;
  draftState: 'idle' | 'saving' | 'finalizing';
  isSaving: boolean;
  isFinalizing: boolean;
  // Partner
  isPartnerModalOpen: boolean;
  partnerToEdit: any | null;
  partnerToDelete: any | null;
  // BankReference
  isBankReferenceModalOpen: boolean;
  bankReferenceToEdit: any | null;
  bankReferenceToDelete: any | null;
  // MainProvider
  isMainProviderModalOpen: boolean;
  mainProviderToEdit: any | null;
  mainProviderToDelete: any | null;
  // MainClient
  isMainClientModalOpen: boolean;
  mainClientToEdit: any | null;
  mainClientToDelete: any | null;
  // EconomicGroup
  isEconomicGroupModalOpen: boolean;
  economicGroupToEdit: any | null;
  economicGroupToDelete: any | null;
  // CommercialReference
  isCommercialReferenceModalOpen: boolean;
  commercialReferenceToEdit: any | null;
  commercialReferenceToDelete: any | null;
  // SimpleAgriculturalPlan
  isSimpleAgriculturalPlanModalOpen: boolean;
  simpleAgriculturalPlanToEdit: any | null;
  simpleAgriculturalPlanToDelete: any | null;
  // LiquidReceipt
  isLiquidReceiptModalOpen: boolean;
  liquidReceiptToEdit: any | null;
  liquidReceiptToDelete: any | null;
  // DeliveryHistory
  isDeliveryHistoryModalOpen: boolean;
  deliveryHistoryToEdit: any | null;
  deliveryHistoryToDelete: any | null;
  // CofcoOpinion
  isCofcoOpinionModalOpen: boolean;
  cofcoOpinionToEdit: any | null;
  cofcoOpinionToDelete: any | null;

  notSavedData: boolean;
  formStatus: '' | 'warning' | 'danger';
  messagesErrors: string[];

  // Partner
  setIsPartnerModalOpen: (isPartnerModalOpen: boolean) => void;
  openPartnerModal: () => void;
  closePartnerModal: () => void;
  setPartnerToEdit: (partner: any | null) => void;
  setPartnerToDelete: (partner: any | null) => void;
  // BankReference
  setIsBankReferenceModalOpen: (isBankReferenceModalOpen: boolean) => void;
  openBankReferenceModal: () => void;
  closeBankReferenceModal: () => void;
  setBankReferenceToEdit: (bankReference: any | null) => void;
  setBankReferenceToDelete: (bankReference: any | null) => void;
  // MainProvider
  setIsMainProviderModalOpen: (isMainProviderModalOpen: boolean) => void;
  openMainProviderModal: () => void;
  closeMainProviderModal: () => void;
  setMainProviderToEdit: (mainProvider: any | null) => void;
  setMainProviderToDelete: (mainProvider: any | null) => void;
  // MainClient
  setIsMainClientModalOpen: (isMainClientModalOpen: boolean) => void;
  openMainClientModal: () => void;
  closeMainClientModal: () => void;
  setMainClientToEdit: (mainClient: any | null) => void;
  setMainClientToDelete: (mainClient: any | null) => void;
  // EconomicGroup
  setIsEconomicGroupModalOpen: (isEconomicGroupModalOpen: boolean) => void;
  openEconomicGroupModal: () => void;
  closeEconomicGroupModal: () => void;
  setEconomicGroupToEdit: (economicGroup: any | null) => void;
  setEconomicGroupToDelete: (economicGroup: any | null) => void;
  // CommercialReference
  setIsCommercialReferenceModalOpen: (isCommercialReferenceModalOpen: boolean) => void;
  openCommercialReferenceModal: () => void;
  closeCommercialReferenceModal: () => void;
  setCommercialReferenceToEdit: (commercialReference: any | null) => void;
  setCommercialReferenceToDelete: (commercialReference: any | null) => void;
  // SimpleAgriculturalPlan
  setIsSimpleAgriculturalPlanModalOpen: (isSimpleAgriculturalPlanModalOpen: boolean) => void;
  openSimpleAgriculturalPlanModal: () => void;
  closeSimpleAgriculturalPlanModal: () => void;
  setSimpleAgriculturalPlanToEdit: (simpleAgriculturalPlan: any | null) => void;
  setSimpleAgriculturalPlanToDelete: (simpleAgriculturalPlan: any | null) => void;
  // LiquidReceipt
  setIsLiquidReceiptModalOpen: (isLiquidReceiptModalOpen: boolean) => void;
  openLiquidReceiptModal: () => void;
  closeLiquidReceiptModal: () => void;
  setLiquidReceiptToEdit: (liquidReceipt: any | null) => void;
  setLiquidReceiptToDelete: (liquidReceipt: any | null) => void;
  // DeliveryHistory
  setIsDeliveryHistoryModalOpen: (isDeliveryHistoryModalOpen: boolean) => void;
  openDeliveryHistoryModal: () => void;
  closeDeliveryHistoryModal: () => void;
  setDeliveryHistoryToEdit: (deliveryHistory: any | null) => void;
  setDeliveryHistoryToDelete: (deliveryHistory: any | null) => void;
  // CofcoOpinion
  setIsCofcoOpinionModalOpen: (isCofcoOpinionModalOpen: boolean) => void;
  openCofcoOpinionModal: () => void;
  closeCofcoOpinionModal: () => void;
  setCofcoOpinionToEdit: (cofcoOpinion: any | null) => void;
  setCofcoOpinionToDelete: (cofcoOpinion: any | null) => void;

  setDraftState: (draftState: 'idle' | 'saving' | 'finalizing') => void;
  setPageStatus: (pageStatus: 'new' | 'edit' | 'view') => void;
  setIsModulePerson: (isModulePerson: boolean) => void;
  setNotSavedData: (notSavedData: boolean) => void;
  setFormStatus: (formStatus: '' | 'warning' | 'danger') => void;
  setMessagesErrors: (messagesErrors: string[]) => void;
};

export const useRegisteredCustomerStore = create<RegisteredCustomerStore>()(set => ({
  isModulePerson: false,
  pageStatus: 'new',
  isViewing: false,
  isEditing: false,
  isNew: false,
  draftState: 'idle',
  isSaving: false,
  isFinalizing: false,
  // Partner
  isPartnerModalOpen: false,
  partnerToEdit: null,
  partnerToDelete: null,
  // BankReference
  isBankReferenceModalOpen: false,
  bankReferenceToEdit: null,
  bankReferenceToDelete: null,
  // MainProvider
  isMainProviderModalOpen: false,
  mainProviderToEdit: null,
  mainProviderToDelete: null,
  // MainClient
  isMainClientModalOpen: false,
  mainClientToEdit: null,
  mainClientToDelete: null,
  // EconomicGroup
  isEconomicGroupModalOpen: false,
  economicGroupToEdit: null,
  economicGroupToDelete: null,
  // CommercialReference
  isCommercialReferenceModalOpen: false,
  commercialReferenceToEdit: null,
  commercialReferenceToDelete: null,
  // SimpleAgriculturalPlan
  isSimpleAgriculturalPlanModalOpen: false,
  simpleAgriculturalPlanToEdit: null,
  simpleAgriculturalPlanToDelete: null,
  // LiquidReceipt
  isLiquidReceiptModalOpen: false,
  liquidReceiptToEdit: null,
  liquidReceiptToDelete: null,
  // DeliveryHistory
  isDeliveryHistoryModalOpen: false,
  deliveryHistoryToEdit: null,
  deliveryHistoryToDelete: null,
  // CofcoOpinion
  isCofcoOpinionModalOpen: false,
  cofcoOpinionToEdit: null,
  cofcoOpinionToDelete: null,

  notSavedData: false,
  formStatus: '',
  messagesErrors: [],

  // Partner
  setIsPartnerModalOpen: isPartnerModalOpen => set({ isPartnerModalOpen }),
  openPartnerModal: () => set({ isPartnerModalOpen: true }),
  closePartnerModal: () => set({ isPartnerModalOpen: false }),
  setPartnerToEdit: partnerToEdit => set({ partnerToEdit }),
  setPartnerToDelete: partnerToDelete => set({ partnerToDelete }),
  // BankReference
  setIsBankReferenceModalOpen: isBankReferenceModalOpen => set({ isBankReferenceModalOpen }),
  openBankReferenceModal: () => set({ isBankReferenceModalOpen: true }),
  closeBankReferenceModal: () => set({ isBankReferenceModalOpen: false }),
  setBankReferenceToEdit: bankReferenceToEdit => set({ bankReferenceToEdit }),
  setBankReferenceToDelete: bankReferenceToDelete => set({ bankReferenceToDelete }),
  // MainProvider
  setIsMainProviderModalOpen: isMainProviderModalOpen => set({ isMainProviderModalOpen }),
  openMainProviderModal: () => set({ isMainProviderModalOpen: true }),
  closeMainProviderModal: () => set({ isMainProviderModalOpen: false }),
  setMainProviderToEdit: mainProviderToEdit => set({ mainProviderToEdit }),
  setMainProviderToDelete: mainProviderToDelete => set({ mainProviderToDelete }),
  // MainClient
  setIsMainClientModalOpen: isMainClientModalOpen => set({ isMainClientModalOpen }),
  openMainClientModal: () => set({ isMainClientModalOpen: true }),
  closeMainClientModal: () => set({ isMainClientModalOpen: false }),
  setMainClientToEdit: mainClientToEdit => set({ mainClientToEdit }),
  setMainClientToDelete: mainClientToDelete => set({ mainClientToDelete }),
  // EconomicGroup
  setIsEconomicGroupModalOpen: isEconomicGroupModalOpen => set({ isEconomicGroupModalOpen }),
  openEconomicGroupModal: () => set({ isEconomicGroupModalOpen: true }),
  closeEconomicGroupModal: () => set({ isEconomicGroupModalOpen: false }),
  setEconomicGroupToEdit: economicGroupToEdit => set({ economicGroupToEdit }),
  setEconomicGroupToDelete: economicGroupToDelete => set({ economicGroupToDelete }),
  // CommercialReference
  setIsCommercialReferenceModalOpen: isCommercialReferenceModalOpen => set({ isCommercialReferenceModalOpen }),
  openCommercialReferenceModal: () => set({ isCommercialReferenceModalOpen: true }),
  closeCommercialReferenceModal: () => set({ isCommercialReferenceModalOpen: false }),
  setCommercialReferenceToEdit: commercialReferenceToEdit => set({ commercialReferenceToEdit }),
  setCommercialReferenceToDelete: commercialReferenceToDelete => set({ commercialReferenceToDelete }),
  // SimpleAgriculturalPlan
  setIsSimpleAgriculturalPlanModalOpen: isSimpleAgriculturalPlanModalOpen => set({ isSimpleAgriculturalPlanModalOpen }),
  openSimpleAgriculturalPlanModal: () => set({ isSimpleAgriculturalPlanModalOpen: true }),
  closeSimpleAgriculturalPlanModal: () => set({ isSimpleAgriculturalPlanModalOpen: false }),
  setSimpleAgriculturalPlanToEdit: simpleAgriculturalPlanToEdit => set({ simpleAgriculturalPlanToEdit }),
  setSimpleAgriculturalPlanToDelete: simpleAgriculturalPlanToDelete => set({ simpleAgriculturalPlanToDelete }),
  // LiquidReceipt
  setIsLiquidReceiptModalOpen: isLiquidReceiptModalOpen => set({ isLiquidReceiptModalOpen }),
  openLiquidReceiptModal: () => set({ isLiquidReceiptModalOpen: true }),
  closeLiquidReceiptModal: () => set({ isLiquidReceiptModalOpen: false }),
  setLiquidReceiptToEdit: liquidReceiptToEdit => set({ liquidReceiptToEdit }),
  setLiquidReceiptToDelete: liquidReceiptToDelete => set({ liquidReceiptToDelete }),
  // DeliveryHistory
  setIsDeliveryHistoryModalOpen: isDeliveryHistoryModalOpen => set({ isDeliveryHistoryModalOpen }),
  openDeliveryHistoryModal: () => set({ isDeliveryHistoryModalOpen: true }),
  closeDeliveryHistoryModal: () => set({ isDeliveryHistoryModalOpen: false }),
  setDeliveryHistoryToEdit: deliveryHistoryToEdit => set({ deliveryHistoryToEdit }),
  setDeliveryHistoryToDelete: deliveryHistoryToDelete => set({ deliveryHistoryToDelete }),
  // CofcoOpinion
  setIsCofcoOpinionModalOpen: isCofcoOpinionModalOpen => set({ isCofcoOpinionModalOpen }),
  openCofcoOpinionModal: () => set({ isCofcoOpinionModalOpen: true }),
  closeCofcoOpinionModal: () => set({ isCofcoOpinionModalOpen: false }),
  setCofcoOpinionToEdit: cofcoOpinionToEdit => set({ cofcoOpinionToEdit }),
  setCofcoOpinionToDelete: cofcoOpinionToDelete => set({ cofcoOpinionToDelete }),

  setDraftState: draftState =>
    set({ draftState, isSaving: draftState === 'saving', isFinalizing: draftState === 'finalizing' }),
  setPageStatus: pageStatus =>
    set(state => ({
      pageStatus,
      isViewing: pageStatus === 'view' || !state.isModulePerson,
      isEditing: pageStatus === 'edit',
      isNew: pageStatus === 'new',
    })),
  setIsModulePerson: isModulePerson => set({ isModulePerson }),
  setNotSavedData: notSavedData => set({ notSavedData, formStatus: notSavedData ? 'warning' : '' }),
  setFormStatus: formStatus => set({ formStatus }),
  setMessagesErrors: messagesErrors => set({ messagesErrors }),
}));

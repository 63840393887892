import React from 'react';
import { useTranslation } from 'react-i18next';

import { Col } from 'antd';
import { Formatter } from 'utils/Formatter';

import { Container, Title, Value } from './styles';

interface Props {
  restriction?: any;
  totalRestrictions?: number;
}

export const Card = ({ restriction, totalRestrictions }: Props) => {
  const { t } = useTranslation();
  const isTotal = !restriction;

  const getTitle = () => {
    if (totalRestrictions && !restriction) return 'Total em Restrições';
    if (!restriction) return 'Sem restrições';

    return restriction?.type?.name;
  };

  const getValue = () => {
    if (typeof restriction?.value === 'boolean') return restriction?.value ? t('yes') : t('no');
    if (totalRestrictions && !restriction) return totalRestrictions;
    if (!restriction) return '';

    return restriction?.value;
  };

  const title = getTitle();
  const value = getValue();

  return (
    <Col xs={24} sm={isTotal ? 24 : 24} md={isTotal ? 24 : 12} lg={isTotal ? 24 : 12} xl={isTotal ? 24 : 6}>
      <Container isTotal={!restriction} hasRestriction={!!totalRestrictions}>
        <Title>{title}</Title>
        <Value>{value && value !== '0' ? Formatter.money(value, null, 'R$') : t('nothingContained')}</Value>
      </Container>
    </Col>
  );
};

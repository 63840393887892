import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiFillWarning } from 'react-icons/ai';
import { FaCheckCircle } from 'react-icons/fa';
import { GiHand } from 'react-icons/gi';
import { ImBlocked } from 'react-icons/im';
import ReactJson from 'react-json-view';

import { Pagination, Popover, Spin } from 'antd';
import PermissionedComponent from 'components/PermissionedComponent';
import { ShouldRender } from 'components/ShouldRender';
import appConfigs from 'configs/app';
import { ImportGroupEnum, SapStatusAdvanceProgEnum } from 'configs/constants';
import { useCreditRequest } from 'hooks/fetch/useCreditRequest';
// import { useGuarantees } from 'hooks/fetch/useGuarantees';
import moment from 'moment';
import { Formatter } from 'utils/Formatter';
import { safeJsonParse } from 'utils/safeJsonParse';

import { DataIntegrationErros } from '../../@types/data/IntegrationDataProvider';
import PaginationData from '../../@types/data/PaginationData';
import { Body, Container, DetailsContainer, Section, Title, Wrapper } from './styles';
import { SapStatusEnum, SapStatusProps } from './types';

const SapStatusDetails = ({ workerId }: { workerId?: string }) => {
  const { getIntegrationsByWorkerId, isFetchingIntegrations: isFetchingCreditRequestIntegrations } = useCreditRequest();
  // const { getIntegrationsByGuaranteeId, isFetchingIntegrations: isFetchingGuaranteeIntegrations } = useGuarantees();
  const { t } = useTranslation();

  const [paginatedIntegrations, setPaginatedIntegrations] = useState<PaginationData<DataIntegrationErros>>(undefined);

  const fetchIntegrations = async (pageNumber = 1) => {
    // if (guaranteeId) {
    //   setPaginatedIntegrations(
    //     (await getIntegrationsByGuaranteeId(guaranteeId, { params: { page: pageNumber, per_page: 10 } })) ?? [],
    //   );
    //   return;
    // }

    setPaginatedIntegrations(
      (await getIntegrationsByWorkerId(workerId, { params: { page: pageNumber, per_page: 10 } })) || [],
    );
  };

  const handleChangePage = (pageNumber: number) => fetchIntegrations(pageNumber);

  useEffect(() => {
    fetchIntegrations();
  }, [workerId]);

  return (
    <PermissionedComponent permission="worker.errors.index">
      <DetailsContainer>
        <Wrapper>
          <Spin spinning={isFetchingCreditRequestIntegrations}>
            {isFetchingCreditRequestIntegrations && !paginatedIntegrations?.data?.length ? (
              <div style={{ height: '200px' }} />
            ) : null}
            {paginatedIntegrations?.data?.length === 0 ? (
              <div style={{ height: '100px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {t('pages.credit-request.kanban.cards.sap_integration_log_not_found')}
              </div>
            ) : null}
            {paginatedIntegrations?.data?.map(integration => {
              const parsedJSON = integration.description
                ? safeJsonParse(integration.description)
                : integration.description;
              return (
                <Section key={integration.id}>
                  <Title>
                    {integration.created_at
                      ? moment(integration.created_at).format(appConfigs.formatDateFullTime)
                      : t('notProvided')}
                  </Title>
                  <Body>
                    {parsedJSON.length > 1 ? (
                      <ReactJson src={parsedJSON ? (parsedJSON[1] as any) : ''} />
                    ) : (
                      integration.description
                    )}
                  </Body>
                </Section>
              );
            })}
          </Spin>
        </Wrapper>
        <div style={{ display: 'flex', width: '100%', justifyContent: 'center', marginTop: '10px' }}>
          <Pagination
            defaultCurrent={1}
            total={paginatedIntegrations?.total ?? 0}
            pageSize={10}
            onChange={handleChangePage}
          />
        </div>
      </DetailsContainer>
    </PermissionedComponent>
  );
};

const SapStatusText = ({
  date,
  status,
  showTime,
  workerGroupId,
}: {
  date: string;
  status: SapStatusEnum | SapStatusAdvanceProgEnum;
  showTime: boolean;
  workerGroupId?: ImportGroupEnum;
}) => {
  const { t } = useTranslation();

  const getIcon = () => {
    switch (status.toUpperCase()) {
      case SapStatusEnum.MANUAL:
        return <GiHand style={{ color: 'green' }} />;
      case SapStatusEnum.SUCCESS:
        return <FaCheckCircle style={{ color: 'green' }} />;
      case SapStatusEnum.ERROR || SapStatusEnum.FAILED:
        return <ImBlocked style={{ color: 'red' }} />;
      case SapStatusEnum.WARNING:
        return <AiFillWarning style={{ color: 'orange' }} />;
      case SapStatusAdvanceProgEnum.APPROVED:
        return <FaCheckCircle style={{ color: 'green' }} />;
      case SapStatusAdvanceProgEnum.REPROVED:
        return <ImBlocked style={{ color: 'red' }} />;
      case SapStatusAdvanceProgEnum.RETURNED:
        return <AiFillWarning style={{ color: 'orange' }} />;
      default:
        return null;
    }
  };

  const formattedDate = showTime ? moment(date).format(appConfigs.formatDateFullTime) : Formatter.date(date);

  const getText = () => {
    switch (status.toUpperCase()) {
      case SapStatusEnum.MANUAL:
      case SapStatusEnum.SUCCESS:
      case SapStatusEnum.ERROR || SapStatusEnum.FAILED:
      case SapStatusEnum.WARNING:
        return `${t('pages.credit-request.kanban.cards.sap_integration_date', {
          importName: workerGroupId !== ImportGroupEnum.CREDIT_ADVANCE_LIMIT ? t('sap_limit') : t('advance_sap'),
        })} ${formattedDate}`;
      case SapStatusAdvanceProgEnum.APPROVED:
      case SapStatusAdvanceProgEnum.REPROVED:
      case SapStatusAdvanceProgEnum.RETURNED:
        return `${status.toUpperCase()} ${t('in')} ${formattedDate}`;

      default:
        return '';
    }
  };

  return (
    <span
      style={{
        maxWidth: '100%',
        width: '100%',
        textAlign: 'center',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        gap: '5px',
        overflow: 'hidden',
        overflowWrap: 'break-word',
      }}
      title={moment(date).format(appConfigs.formatDateFullTime).toString()}
    >
      <span style={{ display: 'flex' }}>{getIcon()}</span>
      <span
        style={{
          overflowX: 'hidden',
          wordBreak: 'break-word',
          display: 'inline-block',
          wordWrap: 'break-word',
          textOverflow: 'ellipsis',
        }}
      >
        {getText()}
      </span>
    </span>
  );
};

export const SapStatus = ({ sapStatus, sapStatusUpdatedAt, workerId, showTime, workerGroupId }: SapStatusProps) => {
  const { t } = useTranslation();

  const shouldShowIntegrationLog = Object.values(SapStatusEnum).includes(sapStatus as SapStatusEnum)
    ? ![SapStatusEnum.MANUAL, SapStatusEnum.SUCCESS].includes(sapStatus as SapStatusEnum)
    : false;

  return (
    <Container data-status={sapStatus}>
      <ShouldRender
        condition={shouldShowIntegrationLog}
        fallback={
          <SapStatusText
            date={sapStatusUpdatedAt}
            workerGroupId={workerGroupId}
            status={sapStatus}
            showTime={showTime}
          />
        }
      >
        <Popover
          content={<SapStatusDetails workerId={workerId} />}
          title={t('pages.credit-request.kanban.cards.sap_integration_log')}
        >
          <span style={{ width: '100%', height: '100%' }}>
            <SapStatusText
              date={sapStatusUpdatedAt}
              workerGroupId={workerGroupId}
              status={sapStatus}
              showTime={false}
            />
          </span>
        </Popover>
      </ShouldRender>
    </Container>
  );
};

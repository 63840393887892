import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Col, Form, Spin, Modal } from 'antd';

import DTOErrorReponse from '../../../../@types/dtos/DTOErrorReponse';
import { Input, ModalStatus, FormItem, Row, Select } from '../../../../components';
import { useCache } from '../../../../hooks/cache';
import { usePage } from '../../../../hooks/page';
import monitoringApi from '../../../../services/api/monitoring';
import { IRegisterModalProps } from './types';

const FarmOwner: React.FC<IRegisterModalProps> = (props: IRegisterModalProps) => {
  const { onOkCallback, onCancelCallback, visibility } = props;
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const cache = useCache();
  const { alertStatus } = usePage();

  const [loading, setLoading] = useState(false);
  const [formOptions, setFormOptions] = useState({
    document_type: [],
  });

  const handleSuccess = (messageSuccess: string) =>
    ModalStatus({
      type: 'success',
      title: t('modal.success'),
      subTitle: messageSuccess,
    });

  const handleRegister = () => {
    setLoading(true);
    form
      .validateFields()
      .then(() => {
        const formValues = form.getFieldsValue();
        monitoringApi.owner
          .store({
            ...formValues,
          })
          .then(response => {
            handleSuccess(response.data.message);
            setLoading(false);
            onOkCallback(response.data);
          })
          .catch((err: DTOErrorReponse) => {
            alertStatus(err, 'error');
            setLoading(false);
          });
      })
      .catch((err: DTOErrorReponse) => {
        alertStatus(err, 'error');
        setLoading(false);
      });
  };

  // Load FormOptions
  useEffect(() => {
    setFormOptions(curState => ({
      ...curState,
      document_type: cache.getPersonOptions('document_type_person'),
    }));
  }, [cache]);

  return (
    <>
      <Modal
        title={t('pages.monitoring.owner.registerTitle')}
        visible={visibility}
        onOk={handleRegister}
        okText={t('pages.monitoring.owner.buttonRegister')}
        cancelText={t('pages.monitoring.owner.buttonCancel')}
        onCancel={() => onCancelCallback()}
        afterClose={() => form.resetFields()}
        width="70%"
      >
        <Spin spinning={loading}>
          <Form form={form}>
            <FormItem name="id" style={{ display: 'none' }}>
              <Input />
            </FormItem>
            <Row gutter={[26, 26]}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                <FormItem label={t('pages.monitoring.owner.form.name')} name="name" rules={[{ required: true }]}>
                  <Input type="text" maxLength={80} />
                </FormItem>
              </Col>
            </Row>
            <Row gutter={[26, 26]}>
              <Col xs={24} sm={12} md={8} lg={8} xl={8} xxl={8}>
                <FormItem
                  label={t('pages.registered-customers.form.pj.documents.type_id')}
                  name="document_type_id"
                  rules={[{ required: true }]}
                >
                  <Select options={formOptions.document_type} />
                </FormItem>
              </Col>
              <Col xs={24} sm={12} md={8} lg={8} xl={8} xxl={8}>
                <FormItem
                  label={t('pages.monitoring.owner.form.doc_number')}
                  name="doc_number"
                  rules={[{ required: true }]}
                >
                  <Input type="text" maxLength={30} />
                </FormItem>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Modal>
    </>
  );
};

export default FarmOwner;

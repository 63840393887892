import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
  gap: 5px;
  align-items: center;
`;

export const DrillUpLink = styled.a`
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 12px;
`;

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Row, Spin } from 'antd';
import { useSerasaCounterparty } from 'hooks/fetch/useSerasaCounterparty';
import { Formatter } from 'utils/Formatter';

import { Card } from './Card';
import { Container, Date } from './styles';

interface Props {
  counterparty: any;
  totalRestrictions?: number;
  showTotal?: boolean;
}

export const Restrictions = ({ counterparty, totalRestrictions, showTotal = true }: Props) => {
  const { t } = useTranslation();
  const { getRestrictions, isFetchingRestrictions } = useSerasaCounterparty();

  const [restrictions, setRestrictions] = React.useState<any[]>([]);
  const loadRestrictions = async () => {
    const response = await getRestrictions(counterparty.id);
    if (response) setRestrictions(response);
  };

  useEffect(() => {
    if (counterparty) loadRestrictions();
  }, [counterparty]);

  return (
    <Container>
      <Spin spinning={isFetchingRestrictions}>
        <Date>
          <strong>{t('pages.serasacounterparty.historic.restriction.lastUpdateDate')}: </strong>
          {Formatter.date(counterparty.updated_at)}
        </Date>
        <Row gutter={[20, 20]} style={{ width: '100%' }}>
          {restrictions.map(restriction => (
            <Card key={restriction.serasa_restriction_id} restriction={restriction} />
          ))}
          {showTotal && <Card key="12121212" totalRestrictions={totalRestrictions} />}
        </Row>
      </Spin>
    </Container>
  );
};

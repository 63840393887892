import React from 'react';

import { FieldWithoutPermission } from 'components/PermissionedField';
import { useAbility } from 'hooks/ability';

import { SFormItem } from './styles';
import { FormItemProps } from './types';

const FormItem: React.FC<FormItemProps & { permission?: string | string[]; needAllPermissions?: boolean }> = ({
  children,
  permission,
  needAllPermissions,
  ...rest
}) => {
  const ability = useAbility();

  const hasPermission = Array.isArray(permission)
    ? permission.length
      ? needAllPermissions
        ? permission.every(p => ability.can(p, ''))
        : permission.some(p => ability.can(p, ''))
      : true
    : !permission || ability.can(permission, '');

  return (
    <SFormItem labelAlign="left" {...rest}>
      {hasPermission ? children : <FieldWithoutPermission />}
    </SFormItem>
  );
};

export default FormItem;

import styled, { css } from 'styled-components';

const maxHeightContainers = css`
  max-height: calc(100vh - 144px);
`;

export const Container = styled.div`
  height: 100%;
  padding: 0 8px;

  .request-comments-container {
    display: flex;
    height: 100%;
    border: solid 1px ${({ theme: { colors } }) => colors.greys.grey01};
    border-radius: 4px;

    .request-comments-comments {
      display: grid;
      grid-template-rows: auto minmax(100px, 140px);
      ${maxHeightContainers}
      height: 100vh;
      width: 100%;
      padding: 0 5px 8px;

      .comments-container {
        width: 100%;
        padding: 0 4px 8px;
        overflow-y: auto;
      }

      .request-comments-messages-container {
        display: none;
        flex-direction: column;
        justify-content: flex-end;
        flex: 1;
        padding-bottom: 16px;

        .date {
          display: flex;
          justify-content: center;
          width: 100%;
          padding: 16px;
          color: ${({ theme: { colors } }) => colors.text};
          font-size: 14px;
          font-weight: 600;
        }

        .message {
          display: flex;
          justify-content: flex-start;
          background: ${({ theme: { colors } }) => colors.greys.grey01};
          border-radius: 4px;
          color: ${({ theme: { colors } }) => colors.text};
          padding: 32px 24px;
          position: relative;
        }

        .username {
          display: flex;
          justify-content: flex-end;
          color: ${({ theme: { colors } }) => colors.text};
          font-size: 14px;
          font-weight: 400;
        }

        .perfil {
          background: ${({ theme: { colors } }) => colors.primary};
          border-radius: 50%;
          color: ${({ theme: { colors } }) => colors.greys.grey01};
          font-size: 24px;
          font-weight: 600;
          height: 60px;
          width: 60px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .request-comments-user-other {
          display: flex;

          .messages {
            display: flex;
            flex-direction: column;
            order: 2;

            .message {
              background: ${({ theme: { colors } }) => colors.primary};
              color: #ffffff;
            }

            .message-item {
              display: flex;
              position: relative;
              padding-left: 100px;

              & + .message-item,
              & + .last-message {
                margin-top: 8px;
              }
            }

            .last-message {
              display: flex;
              position: relative;
              padding-left: 100px;

              .message-container {
                display: flex;
                flex-direction: column;
                flex: 1;
                order: 2;

                .message::before {
                  content: '';
                  position: absolute;
                  left: -12px;
                  width: 0;
                  height: 0;
                  border-top: 10px solid transparent;
                  border-bottom: 10px solid transparent;
                  border-right: 12px solid ${({ theme: { colors } }) => colors.primary};
                }
              }

              .perfil-container {
                display: flex;
                order: 1;
                justify-content: flex-end;
                align-items: flex-start;
                padding: 0;
                position: absolute;
                left: 0;
              }
            }
          }
        }

        .request-comments-user-me {
          display: flex;

          .messages {
            display: flex;
            flex-direction: column;

            .message-item {
              display: flex;
              position: relative;
              padding-right: 100px;

              & + .message-item,
              & + .last-message {
                margin-top: 8px;
              }
            }

            .last-message {
              display: flex;
              position: relative;
              padding-right: 100px;

              .message-container {
                display: flex;
                flex-direction: column;
                flex: 1;
                order: 1;

                .message::after {
                  content: '';
                  position: absolute;
                  right: -12px;
                  width: 0;
                  height: 0;
                  border-top: 10px solid transparent;
                  border-bottom: 10px solid transparent;
                  border-left: 12px solid ${({ theme: { colors } }) => colors.greys.grey01};
                }
              }

              .perfil-container {
                display: flex;
                order: 2;
                justify-content: flex-end;
                align-items: flex-start;
                padding: 0;
                position: absolute;
                right: 0;

                .perfil {
                  background: ${({ theme: { colors } }) => colors.greys.grey07};
                }
              }
            }
          }
        }
      }

      .request-comments-form {
        display: flex;
        flex-direction: column;
        width: 100%;

        .ant-form-item {
          margin-bottom: 0;
        }

        .ant-form-item:not(.ant-input-affix-wrapper) .ant-input {
          border: solid 1px ${({ theme: { colors } }) => colors.secondary};
          border-radius: 4px;
        }

        .request-comments-form-item-button {
          display: flex;
          flex: 1;
          justify-content: flex-end;
        }
      }
    }

    .request-comments-client-data {
      display: grid;
      grid-template-rows: minmax(100px, 1.5fr) minmax(100px, 1fr) minmax(100px, 1fr);
      ${maxHeightContainers}
      height: 100vh;
      max-width: 320px;
      width: 25%;
      padding: 16px;
      background: ${({ theme: { colors } }) => colors.greys.grey01};
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;

      .requester-data-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .requester-image-container {
          display: flex;
          align-items: center;
          justify-content: center;

          .requester-initials-container {
            background: ${({ theme: { colors } }) => colors.primary};
            border-radius: 50%;
            height: 100px;
            width: 100px;

            .requester-initials {
              color: ${({ theme: { colors } }) => colors.greys.grey01};
              font-size: 32px;
              font-weight: 600;
              text-transform: uppercase;
              display: flex;
              align-items: center;
              justify-content: center;
              height: 100%;
              width: 100%;
            }
          }
        }

        .requester-name {
          color: ${({ theme: { colors } }) => colors.text};
          font-family: ${({ theme: { fonts } }) => fonts.primary};
          font-size: 16px;
          font-weight: 700;
          text-align: center;
          padding-top: 24px;
        }

        .requester-person-type {
          color: ${({ theme: { colors } }) => colors.text};
          font-family: ${({ theme: { fonts } }) => fonts.primary};
          font-size: 14px;
          font-weight: 400;
          text-align: center;
          text-transform: capitalize;
        }
      }

      .info-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: 1;

        .info-requested-amount {
          color: ${({ theme: { colors } }) => colors.text};
          font-family: ${({ theme: { fonts } }) => fonts.primary};
          font-size: 14px;
          font-weight: 400;
          text-align: center;

          .info-requested-value {
            color: ${({ theme: { colors } }) => colors.primary};
          }
        }

        .info-extra {
          color: ${({ theme: { colors } }) => colors.text};
          font-family: ${({ theme: { fonts } }) => fonts.primary};
          font-size: 14px;
          font-weight: 400;
          text-align: center;
        }
      }

      .buttons-container {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: flex-end;
        padding-bottom: 32px;

        button + button {
          margin-top: 8px;
        }
      }
    }
  }
`;

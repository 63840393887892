import { useQuery, keepPreviousData, QueryKey, UseQueryOptions } from '@tanstack/react-query';
import { DispatchPagination, PaginationState, paginationToApi } from 'hooks/usePagination';
import api from 'services/api/api';
import PaginationData from 'types/Api/PaginationData';
import { ResponsePaginationData } from 'types/Api/ResponsePaginationData';

import { useAbility } from './ability';

export type PaginatedQueryParams = Partial<PaginationState> & { dispatchPagination: DispatchPagination };

type Props<T> = {
  permissions?: string[] | string;
  params?: { [key: string]: any; params?: { [key: string]: any } } & PaginatedQueryParams;
  url: string;
} & UseQueryOptions<PaginationData<T>, Error, PaginationData<T>, QueryKey>;

export const usePaginatedQuery = <T,>(props: Props<T>) => {
  const ability = useAbility();

  const { permissions } = props;
  if (permissions) {
    if (Array.isArray(permissions)) {
      if (!permissions.some(permission => ability.can(permission, ''))) return null;
    } else {
      if (!ability.can(permissions, '')) return null;
    }
  }

  return useQuery<PaginationData<T>>({
    ...props,
    placeholderData: keepPreviousData,
    queryKey: [
      ...props.queryKey,
      `page=${props.params?.current}&pageSize=${props.params?.pageSize}&search=${props.params?.search}`,
      props.params.sort,
      props.params.filter,
    ],
    queryFn: async () => {
      const response = await api.get<ResponsePaginationData<T> | PaginationData<T>>(props.url, {
        params: { ...paginationToApi(props.params), ...(props.params || { params: {} })?.params },
      });
      if (response.status !== 200) throw new Error('Error');

      let data: PaginationData<T>;
      if ('message' in response.data) data = response.data.data as PaginationData<T>;
      else data = response.data;

      props.params.dispatchPagination({
        type: 'SET_TOTAL',
        payload: data.total,
      });

      props.params.dispatchPagination({
        type: 'SET_LAST_PAGE',
        payload: data.last_page,
      });

      return data;
    },
  });
};

import { useState } from 'react';

import admin from 'services/api/admin';
import { handleRequest } from 'services/api/api';

export const useDataProvidersIntegration = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [isForcing, setIsForcing] = useState(false);

  const getIntegrations = async (params = {}) => {
    return handleRequest(() => admin.getIntegrationHistory(params), setIsFetching);
  };

  const forceIntegration = async (id: string) => {
    return handleRequest(() => admin.forceIntegration(id), setIsForcing);
  };

  return {
    getIntegrations,
    isFetching,
    forceIntegration,
    isForcing,
  };
};

import styled from 'styled-components';

export const Title = styled.h1`
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  a {
    font-size: 12px;
    display: flex;
    align-items: center;
    gap: 10px;
    text-decoration: underline;
  }
`;

export const CheckBoxList = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
`;

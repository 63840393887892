import { PaginatedQueryParams, usePaginatedQuery } from 'hooks/usePaginatedQuery';
import { SelectInfiniteQueryParams, useSelectInfiniteQuery } from 'hooks/useSelectInfiniteQuery';
import PaginationData from 'types/Api/PaginationData';
import DocumentStatusData from 'types/CreditRequest/DocumentStatusData';

export const useGetDocumentStatusQuery = (params?: PaginatedQueryParams & any) => {
  return usePaginatedQuery<DocumentStatusData>({
    queryKey: ['credit-request/document-status'],
    staleTime: 1000 * 60 * 5,
    url: `credit-request/document-status`,
    params: {
      ...params,
    },
    permissions: ['credit.request.documentstatus.all'],
  });
};

export const useGetDocumentStatusOptionsQuery = (params: SelectInfiniteQueryParams) => {
  const query = useSelectInfiniteQuery<PaginationData<DocumentStatusData>>(
    'credit-request/document-status',
    {
      queryKey: ['credit-request/document-status', params.city || ''],
      staleTime: 1000 * 60 * 5,
      permissions: ['credit.request.documentstatus.all'],
    },
    params,
  );

  return query;
};

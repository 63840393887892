import React, { useState } from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

import { Spin } from 'antd';
import { NoPermission } from 'components/NoPermission';
import { Can } from 'hooks/ability';
import { DraftButton } from 'pages/Contracts/components/DraftButton';

import { useContractContext } from '../../context/ContractContext';
import { Attachments } from '../modules/ContractStages/Attachments';
import { PreviewPanel } from './PreviewPanel';
import { Container, Menu, ToggleMenuButton, AttachmentsWrapper } from './styles';

export const AttachmentCompare = () => {
  const { isFetching } = useContractContext();

  const [isMenuCollapsed, setIsMenuCollapsed] = useState(false);
  const [selectedAttachment, setSelectedAttachment] = useState<any>();

  const toggleIsMenuCollapsed = () => setIsMenuCollapsed(!isMenuCollapsed);

  return (
    <Container isMenuCollapsed={isMenuCollapsed}>
      <Menu>
        <ToggleMenuButton isMenuCollapsed={isMenuCollapsed} onClick={toggleIsMenuCollapsed}>
          <DraftButton>{isMenuCollapsed ? <FaChevronRight /> : <FaChevronLeft />}</DraftButton>
        </ToggleMenuButton>
        <AttachmentsWrapper isCollapsed={isMenuCollapsed}>
          <Attachments lowerButton isCompare controlledSetSelectedAttachment={setSelectedAttachment} />
        </AttachmentsWrapper>
      </Menu>
      <Spin spinning={isFetching}>
        <Can I="contract.preview">
          <PreviewPanel selectedAttachment={selectedAttachment} />
        </Can>
        <Can I="contract.preview" not>
          <NoPermission />
        </Can>
      </Spin>
    </Container>
  );
};

import { PaginatedQueryParams, usePaginatedQuery } from 'hooks/usePaginatedQuery';
import { ContractData } from 'types/Contract/ContractData';

type Params = PaginatedQueryParams;

export const useGetContractsQuery = ({ dispatchPagination, ...params }: Params) => {
  return usePaginatedQuery<ContractData>({
    queryKey: ['contract', params],
    url: `contract`,
    params: {
      ...params,
      dispatchPagination,
    },
  });
};

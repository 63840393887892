import { useState } from 'react';

import type { ContractSubscriberData } from 'types/Contract/ContractSubscriberData';

import { handleRequest } from '../../services/api/api';
import personApi from '../../services/api/person';

export const useSubscriber = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);

  const get = async (params = {}) => {
    const response = await handleRequest(() => personApi.subscriber.getAll(params), setIsFetching);

    if (!response?.data) return { data: [] as any[], lastPage: 0 };

    return { data: response.data, lastPage: response.last_page };
  };

  const getOptions = async (params = {}) => {
    const response = await get(params);

    return {
      data: response.data.map((subscriber: ContractSubscriberData) => ({
        key: subscriber.id,
        value: subscriber.id,
        label: subscriber.name,
        email: subscriber.email,
        social_id: subscriber.social_id,
        phone_number: subscriber.phone_number,
      })),
      lastPage: response.data.last_page,
    };
  };

  const remove = async (id: string) => {
    const response = await handleRequest(() => personApi.subscriber.remove(id), setIsRemoving);
    return response;
  };

  const list = async (params = {}) => {
    const response = await handleRequest(() => personApi.subscriber.list({ params }), setIsFetching);

    if (!response?.data) return { data: [] as any[], lastPage: 0 };

    return { data: response.data, lastPage: response.last_page };
  };

  const listNoPagination = async (params = {}, signal?: AbortSignal) => {
    const response = await handleRequest(() => personApi.subscriber.list({ params, signal }), setIsFetching);

    if (!response) return [];

    return response;
  };

  const listOptions = async (params = {}) => {
    const response = await list(params);

    return {
      data: response.data.map((subscriber: ContractSubscriberData) => ({
        key: subscriber.id,
        value: subscriber.name,
        label: subscriber.name,
        email: subscriber.email,
        social_id: subscriber.social_id,
        phone_number: subscriber.phone_number,
        name: subscriber.name,
      })),
      last_page: response.data.last_page,
    };
  };

  const listOptionsNoPagination = async (params = {}, signal?: AbortSignal) => {
    const response = await listNoPagination(params, signal);

    return response.map((subscriber: ContractSubscriberData) => ({
      key: subscriber.id,
      value: subscriber.id,
      label: subscriber.name,
      email: subscriber.email,
      social_id: subscriber.social_id,
      phone_number: subscriber.phone_number,
      name: subscriber.name,
    }));
  };

  return { get, isFetching, getOptions, remove, isRemoving, list, listOptions, listOptionsNoPagination };
};

import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Dialog,
  DialogContent,
  DialogFooterForm,
  DialogHeader,
  DialogPortal,
  DialogTrigger,
} from 'components/ui/dialog';

import { useRegisteredCustomerStore } from '../stores/use-registered-customer-store';
import { bankReferenceInitialValues, BankReferenceFormData, BankReferenceForm } from './bank-reference.form';

type Props = {
  children?: React.ReactNode;
  onSuccess?: (data: BankReferenceFormData) => void;
};

export const BankReferenceModal = ({ children, onSuccess }: Props) => {
  const formRef = useRef<any>(null);
  const { t } = useTranslation();

  const isBankReferenceModalOpen = useRegisteredCustomerStore(state => state.isBankReferenceModalOpen);
  const setBankReferenceToEdit = useRegisteredCustomerStore(state => state.setBankReferenceToEdit);
  const bankReferenceToEdit = useRegisteredCustomerStore(state => state.bankReferenceToEdit);
  const setIsBankReferenceModalOpen = useRegisteredCustomerStore(state => state.setIsBankReferenceModalOpen);

  return (
    <Dialog
      open={isBankReferenceModalOpen}
      onOpenChange={value => {
        setIsBankReferenceModalOpen(value);
        if (!value) {
          setBankReferenceToEdit(null);
          formRef.current?.form.reset(bankReferenceInitialValues);
        }
      }}
    >
      <DialogTrigger>{children}</DialogTrigger>
      <DialogPortal>
        <DialogContent className="md:w-[50%] w-[96%]">
          <DialogHeader>
            {bankReferenceToEdit ? t('edit') : t('new-f')} {t('bank-reference')}
          </DialogHeader>
          <BankReferenceForm
            ref={formRef}
            onSuccess={data => {
              setIsBankReferenceModalOpen(false);
              setBankReferenceToEdit(null);
              onSuccess?.(data);
              formRef.current?.form.reset(bankReferenceInitialValues);
            }}
          />
          <DialogFooterForm
            onConfirm={() => {
              formRef.current?.submit();
            }}
            onCancel={() => {
              formRef.current?.form.reset(bankReferenceInitialValues);
              setBankReferenceToEdit(null);
              setIsBankReferenceModalOpen(false);
            }}
          />
        </DialogContent>
      </DialogPortal>
    </Dialog>
  );
};

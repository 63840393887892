import React from 'react';

import { CardProps } from 'antd/lib/card';

import { SCard } from './styles';

const Card: React.FC<CardProps> = props => {
  return <SCard {...props} />;
};

export default Card;

import React from 'react';
import { useTranslation } from 'react-i18next';

import { Form as AntForm, Col } from 'antd';

import { Row, FormItem, Input, Select, MaskedInput } from '../../components';
import { useCache } from '../../hooks/cache';
import FormItemType from './FormItem';
import { DynamicFormProps } from './types';

const DynamicForm: React.FC<DynamicFormProps> = ({ form }) => {
  const { t } = useTranslation();
  const { getOptions } = useCache();
  const [antForm] = AntForm.useForm();

  function renderFields() {
    return Object.keys(form.fields).map((value, index) => {
      const field: FormItemType = form.fields[index];

      switch (field.type) {
        case 'hidden':
          return (
            <FormItem hidden name={field.name} key={field.name.toString()}>
              <Input />
            </FormItem>
          );
        case 'select':
          return (
            <Col xs={24} sm={18} md={12} lg={9} xl={6} xxl={6} key={field.name.toString()}>
              <FormItem name={field.name} label={t(field.label)}>
                <Select
                  options={field.options.select.optionsofcache ? getOptions(field.options.select.optionsofcache) : []}
                  {...field.options.select}
                />
              </FormItem>
            </Col>
          );
        case 'mask':
          return (
            <Col xs={24} sm={18} md={12} lg={9} xl={6} xxl={6} key={field.name.toString()}>
              <FormItem name={field.name} label={t(field.label)}>
                <MaskedInput {...field.options.mask} />
              </FormItem>
            </Col>
          );
        default:
          return (
            <Col xs={24} sm={18} md={12} lg={9} xl={6} xxl={6} key={field.name.toString()}>
              <FormItem name={field.name} label={t(field.label)}>
                <Input />
              </FormItem>
            </Col>
          );
      }
    });
  }

  return (
    <>
      <AntForm form={antForm} {...form}>
        <Row gutter={[8, 8]}>{renderFields()}</Row>
      </AntForm>
    </>
  );
};

export default DynamicForm;

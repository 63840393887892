import { DefinedInitialDataInfiniteOptions, useInfiniteQuery } from '@tanstack/react-query';
import { OptionData } from 'components/ui/combo';
import api from 'services/api/api';
import PaginationData from 'types/Api/PaginationData';
import { ResponsePaginationData } from 'types/Api/ResponsePaginationData';
import { Converter } from 'utils/Converter';

import { useAbility } from './ability';
import { PaginationState } from './usePagination';

export type SelectInfiniteQueryParser<T = unknown> = (data: T[]) => OptionData<T>[];
export type SelectInfiniteQueryParams = Partial<PaginationState> & { [key: string]: any };

export const useSelectInfiniteQuery = <T>(
  url: string,
  {
    permissions,
    ...queryOptions
  }: Omit<
    DefinedInitialDataInfiniteOptions<PaginationData<OptionData<T>>>,
    'getNextPageParam' | 'initialPageParam' | 'initialData'
  > & {
    permissions?: string[] | string;
  },
  params?: SelectInfiniteQueryParams,
  parseSelectOptions?: SelectInfiniteQueryParser<T>,
) => {
  const ability = useAbility();

  return useInfiniteQuery<PaginationData<OptionData<T>>>({
    ...queryOptions,
    queryKey: [...queryOptions.queryKey, `search=${params?.search || ''}`],
    initialPageParam: 1,
    queryFn: async ({ pageParam = 1 }: { pageParam?: PaginationData<any>['current'] }) => {
      if (permissions && permissions.length) {
        if (Array.isArray(permissions)) {
          if (!permissions.some(permission => ability.can(permission, ''))) return null;
        } else {
          if (!ability.can(permissions, '')) return null;
        }
      }

      delete params?.pageSize;
      delete params?.current;
      delete params?.total;
      delete params?.lastPage;

      const response = await api.get<ResponsePaginationData<T>>(url, {
        params: {
          ...(params || {}),
          page: pageParam.toString(),
          search: params?.search,
          per_page: params?.pageSize || 10,
        },
      });

      let data: PaginationData<T>;
      if ('message' in response.data) data = response.data.data as PaginationData<T>;
      else data = response.data;

      const parseResponseData = (): OptionData<T>[] => {
        if (parseSelectOptions) return parseSelectOptions(data.data);
        return Converter.selectOptionArray(data.data);
      };

      return {
        ...data,
        data: parseResponseData(),
      };
    },
    getNextPageParam: lastPage =>
      lastPage?.current_page < lastPage?.last_page ? lastPage?.current_page + 1 : undefined,
    getPreviousPageParam: lastPage => (lastPage?.current_page > 0 ? lastPage?.current_page - 1 : undefined),
  });
};

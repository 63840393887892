import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { PermissionedField } from 'components/PermissionedField';
import { Button } from 'components/ui/button';
import { ComboBoxInfinite } from 'components/ui/combo.infinite';
import { Form } from 'components/ui/form';
import { FormGrid } from 'components/ui/form-grid';
import { FormItem } from 'components/ui/form-item';
import { Select } from 'components/ui/select';
import { useAbility } from 'hooks/ability';
import { usePagination } from 'hooks/usePagination';

import { useGetBpsOptionsQuery } from '../api/use-get-bps-query';

const initialExposuresFilter = {
  partner: [] as string[],
  datasource: 'all',
};

type Props = {
  onFilter: (filter: typeof initialExposuresFilter) => void;
};

export const ExposuresFilter = ({ onFilter }: Props) => {
  const form = useForm({
    defaultValues: initialExposuresFilter,
  });
  const { t } = useTranslation();
  const ability = useAbility();
  const navigate = useNavigate();

  const { dispatchPagination, paginationConfig } = usePagination();
  const bpsInfiniteQuery = useGetBpsOptionsQuery({ ...paginationConfig });

  if (!ability.can('dashboard.exposure.operationalrisk', '')) navigate('/home');

  return (
    <Form form={form} onSubmit={form.handleSubmit(onFilter)}>
      <FormGrid>
        <FormItem name="partner" className="col-span-2" label="BP">
          <ComboBoxInfinite
            onSearch={value => dispatchPagination({ type: 'SET_SEARCH', payload: value })}
            infiniteQuery={bpsInfiniteQuery}
            mode="multi"
          />
        </FormItem>

        <FormItem name="datasource" className="lg:col-span-1 col-span-2" label="Datasource">
          <PermissionedField permission="dashboard.exposure.bppartner">
            <Select
              className="w-full"
              options={[
                { value: 'all', label: t('all-m') },
                { value: 'DATASUL', label: 'DATASUL' },
                { value: 'SAP', label: 'SAP' },
              ]}
            />
          </PermissionedField>
        </FormItem>

        <div className="lg:col-span-1 col-span-2 flex gap-2 items-end justify-end">
          <Button>{t('filter')}</Button>
          <Button
            type="button"
            onClick={() => {
              form.reset();
              onFilter(initialExposuresFilter);
            }}
          >
            {t('clear')}
          </Button>
        </div>
      </FormGrid>
    </Form>
  );
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaSearch } from 'react-icons/fa';

import { Form } from 'antd';
import { useForm } from 'antd/lib/form/Form';

import { Input, Button, FormItem } from '../../../../../components';
import { SFilterContainer } from './styles';
import { FilterProps } from './types';

export const Filter = ({ handleFastSearch }: FilterProps) => {
  const [form] = useForm();
  const { t } = useTranslation();

  const tableDataClearAllFilters = () => {
    form.resetFields();
    handleFastSearch({});
  };

  const handleFilter = () => {
    const values = form.getFieldsValue();
    if (values.parent !== 'all') {
      values.parent = values.parent === 'parent';
    }
    handleFastSearch(values);
  };

  return (
    <SFilterContainer>
      <Form form={form} name="filter-owner" className="form-secondary form-filters grid-filters">
        <div className="filter-search">
          <FormItem name="search" label={t('pages.edocuments.templates.search')}>
            <Input placeholder={t(`pages.edocuments.templates.filters.search`)} />
          </FormItem>
        </div>
        <div className="filter-button">
          <FormItem label="" className="form-item-without-label">
            <Button status="primary" text="uppercase" htmlType="submit" onClick={handleFilter}>
              <FaSearch /> {t('form.actions.search')}
            </Button>
          </FormItem>
        </div>
        <div className="filter-clear">
          <Button status="secondary" size="middle" onClick={tableDataClearAllFilters}>
            {t('pages.edocuments.contracttypes.buttonClearFilter')}
          </Button>
        </div>
      </Form>
    </SFilterContainer>
  );
};

import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ShouldRender } from 'components/ShouldRender';
import { useAlertDialog } from 'components/ui/alert-dialog';
import { Button } from 'components/ui/button';
import { GroupForm } from 'components/ui/group-form';
import { randomUUID } from 'lib/utils';
import { Formatter } from 'utils/Formatter';

import { LiquidReceiptFormData } from './liquid-receipt/liquid-receipt.form';
import { LiquidReceiptModal } from './liquid-receipt/liquid-receipt.modal';
import { LiquidReceiptTable } from './liquid-receipt/liquid-receipt.table';
import { useRegisteredCustomerStore } from './stores/use-registered-customer-store';

export const RegisteredCustomerLiquidReceiptSection = () => {
  const { t } = useTranslation();
  const form = useFormContext();
  const { alert } = useAlertDialog();

  const liquidReceipts = form.watch('liquid_receipt') as LiquidReceiptFormData[];

  const isViewing = useRegisteredCustomerStore(state => state.isViewing);
  const isModulePerson = useRegisteredCustomerStore(state => state.isModulePerson);
  const liquidReceiptToEdit = useRegisteredCustomerStore(state => state.liquidReceiptToEdit);
  const setIsLiquidReceiptModalOpen = useRegisteredCustomerStore(state => state.setIsLiquidReceiptModalOpen);

  const handleAddLiquidReceipt = (data: LiquidReceiptFormData) => {
    const liquidReceipts = form.getValues('liquid_receipt');
    form.setValue(
      'liquid_receipt',
      [
        ...(liquidReceipts ?? []),
        {
          ...data,
          id: `new-${randomUUID()}`,
        },
      ],
      {
        shouldTouch: true,
      },
    );
  };

  const handleEditLiquidReceipts = (data: LiquidReceiptFormData) => {
    const liquidReceipts = form.getValues('liquid_receipt') as LiquidReceiptFormData[];
    const index = liquidReceipts.findIndex(liquidReceipt => liquidReceipt.id === data.id);
    liquidReceipts[index] = data;
    form.setValue('liquid_receipt', liquidReceipts, {
      shouldTouch: true,
    });
    alert({
      type: 'success',
      title: t('liquid-receipt-updated'),
      description: '',
    });
  };

  const total = liquidReceipts?.reduce((acc, next: LiquidReceiptFormData) => {
    return acc + next.livestock + next.other;
  }, 0);

  return (
    <GroupForm title={t('other-liquid-receipts')}>
      <div className="group-header-values">
        {t('pages.registered-customers.form.pf.net_revenues.total')} {Formatter.money(total ?? 0, 2, null, true)}
      </div>

      <ShouldRender condition={!isViewing && isModulePerson}>
        <div className="flex justify-end w-full">
          <Button type="button" variant="default" onClick={() => setIsLiquidReceiptModalOpen(true)}>
            {t('add-liquid-receipt')}
          </Button>
        </div>
      </ShouldRender>

      <div className="mt-6">
        <LiquidReceiptTable />
      </div>

      <LiquidReceiptModal onSuccess={liquidReceiptToEdit ? handleEditLiquidReceipts : handleAddLiquidReceipt} />
    </GroupForm>
  );
};

import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormItem, Select } from 'components';
import { OPERATION_PREPAY_ID } from 'configs/constants';
import { useCache } from 'hooks/cache';
import { useCreditRequestStore } from 'pages/CreditRequest/store/useCreditRequestStore';

interface Props {
  blacklist?: string[];
}

export const JsonDataWeightTypeIdSelect = ({ blacklist = [] }: Props) => {
  const { t } = useTranslation();
  const cache = useCache();

  const selectedOperationId = useCreditRequestStore(state => state.selectedOperationId);
  const setIsBlocking = useCreditRequestStore(state => state.setIsBlocking);
  const contractFieldsValue = useCreditRequestStore(state => state.contractFieldsValue);
  const pageStatus = useCreditRequestStore(state => state.pageStatus);

  return (
    <FormItem
      label={t('pages.credit-request.form.json_data_weight_type_id')}
      name="json_data.weight_type_id"
      rules={[{ required: true }]}
    >
      <Select
        options={cache
          .getCreditOptions('weight_type')
          .map(weightUnit => (!blacklist.includes(weightUnit.key) ? weightUnit : { ...weightUnit, disabled: true }))}
        disabled={
          pageStatus.viewing ||
          (contractFieldsValue && contractFieldsValue['json_data.weight_type_id'] !== undefined) ||
          selectedOperationId === OPERATION_PREPAY_ID
        }
        onChange={(value: any) => {
          if (value) {
            setIsBlocking(true);
          }
        }}
      />
    </FormItem>
  );
};

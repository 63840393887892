import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { useIsMutating } from '@tanstack/react-query';
import { Dropdown, Form, Menu, Row, Spin } from 'antd';
import { FixedFooter, FormItem, Input, ModalStatus, Button } from 'components';
import { SapSvgLogo } from 'components/SapSvgLogo/SapSvgLogo';
import { TotvsSvgLogo } from 'components/TotvsSvgLogo/TotvsSvgLogo';
import {
  CREDIT_REQUEST_TEAM_STATUS_EDITABLE_RULE_PREPAY,
  PHASE2_PRE_REGISTRATION,
  PHASE3_POST_REGISTRATION,
  TEAM_STATUS_CREDIT_APPROVED_COMERCIAL_ID,
  CREDIT_REQUEST_TEAM_STATUS_EDITABLE_RULE_PREFIN_SUGAR_MILLS,
  OPERATION_PREFIN_ID,
  OPERATION_PREFIN_SUGAR_MILLS_ID,
  OPERATION_PREPAY_ID,
  OPERATION_DELIVERED_PRODUCT_ADVANCE_ID,
  OPERATION_MTM_ID,
  OPERATION_INTERN_MARKET_ID,
  OPERATION_FERTILIZER_SUPPLIERS_ID,
  CURRENCY_USD_ID,
} from 'configs/constants';
import { useCreditRequestContext } from 'context/CreditRequestContext';
import { useAuth } from 'hooks/auth';
import { DivisionFields } from 'pages/CreditRequest/components/organisms/DivisionFields';
import { AdvanceProductDeliveredForm } from 'pages/CreditRequest/components/templates/advance-product-delivered.form';
import { FertilizingForm } from 'pages/CreditRequest/components/templates/fertilizing.form';
import { InternMarketForm } from 'pages/CreditRequest/components/templates/intern-market.form';
import { OperationMTMForm } from 'pages/CreditRequest/components/templates/operation-mtm.form';
import { PrefinForm } from 'pages/CreditRequest/components/templates/prefin.form';
import { PrepaymentForm } from 'pages/CreditRequest/components/templates/prepayment.form';
import { useCalculateFields } from 'pages/CreditRequest/hooks/useCalculateFields';
import { useCreditRequestStore } from 'pages/CreditRequest/store/useCreditRequestStore';

import { useChangeStatus } from '../../../hooks/useChangeStatus';
import { useValidateSubmit } from '../../../hooks/useValidateSubmit';
import { StatusLink } from '../../templates/DetailView/styles';

type Props = {
  blockTeamStatusButton: boolean;
};

export const CreditRequest = ({ blockTeamStatusButton }: Props) => {
  const { user, userIsLinked } = useAuth();
  const { formStatus, setFormStatus, formErrors, teamStatusCurrent, creditForm } = useCreditRequestContext();

  const selectedOperationId = useCreditRequestStore(state => state.selectedOperationId);
  const isLoading = useCreditRequestStore(state => state.isLoading);
  const pageStatus = useCreditRequestStore(state => state.pageStatus);
  const creditRequestData = useCreditRequestStore(state => state.creditRequestData);

  const { t } = useTranslation();
  const { handleChangeStatus } = useChangeStatus();
  const { validateSubmit, messagesErrors, finalizing } = useValidateSubmit();
  const navigate = useNavigate();

  const { onChangeFieldsRecalculate } = useCalculateFields();

  const isEditable = useCreditRequestStore(state => state.isEditable);
  const contractTypeVisible = useCreditRequestStore(state => state.contractTypeVisible);
  const jsonDataPrice = useCreditRequestStore(state => state.jsonDataPrice);
  const jsonDataQuantity = useCreditRequestStore(state => state.jsonDataQuantity);
  const teamStatusChangeTeamStatusCurrentId = useCreditRequestStore(state => state.teamStatusChangeTeamStatusCurrentId);
  const teamStatusChangePhaseCurrentId = useCreditRequestStore(state => state.teamStatusChangePhaseCurrentId);
  const teamStatusChangeApprovedValue = useCreditRequestStore(state => state.teamStatusChangeApprovedValue);
  const canShowStatusOptions = useCreditRequestStore(state => state.canShowStatusOptions);
  const listOfNextTeamStatus = useCreditRequestStore(state => state.listOfNextTeamStatus);

  const isFindingProvider = useIsMutating({
    mutationKey: ['findRegisteredCustomer'],
  });
  const isStoringProvider = useIsMutating({
    mutationKey: ['storeRegisteredCustomer'],
  });

  function renderMessageError(errorMessage: any): JSX.Element | null {
    if (messagesErrors[errorMessage]) {
      const messageError = messagesErrors[errorMessage];
      return (
        <div className="feedback-description" key={errorMessage}>
          {messageError}
        </div>
      );
    }

    return null;
  }

  async function confirmedRequest() {
    await creditForm.validateFields().catch(() => {
      setFormStatus('danger');
    });

    if (
      selectedOperationId === OPERATION_PREPAY_ID &&
      (creditForm.getFieldValue('json_data.warehouse_shipping_capacity') || 0) < 50
    ) {
      return ModalStatus({
        type: 'warning',
        title: t('pages.credit-request.messages.low_cadency_title'),
        subTitle: '',
        cancelText: t('pages.admin.operation.table.actions.cancel-delete'),
        okText: t('pages.admin.operation.table.actions.confirm-delete'),
        onOk: async () => {
          validateSubmit();
        },
      });
    }

    validateSubmit();
  }

  async function confirmRequest() {
    return ModalStatus({
      type: 'confirm',
      title: t(`modal.warning`),
      subTitle: t('pages.credit-request.messages.warningFinalize'),
      onOk: confirmedRequest,
      okText: t('pages.credit-request.messages.confirmButton'),
    });
  }

  function handleClose() {
    try {
      navigate(`/credit-request`, { replace: true });
    } catch (e) {
      console.warn(e);
    }
    try {
      // eslint-disable-next-line no-restricted-globals
      self.close();
    } catch (e) {
      console.warn(e);
    }
  }

  useEffect(() => {
    if (selectedOperationId === OPERATION_DELIVERED_PRODUCT_ADVANCE_ID) {
      creditForm.setFieldsValue({
        'json_data.currency_type_id': CURRENCY_USD_ID,
      });
    }
  }, [selectedOperationId]);

  useEffect(() => {
    if (contractTypeVisible) {
      if (jsonDataPrice && jsonDataQuantity)
        creditForm.setFieldsValue({
          'json_data.quantity': jsonDataQuantity,
          'json_data.price': jsonDataPrice,
        });
    }
  }, [contractTypeVisible]);

  useEffect(() => {
    if (
      teamStatusChangeTeamStatusCurrentId === TEAM_STATUS_CREDIT_APPROVED_COMERCIAL_ID ||
      teamStatusChangePhaseCurrentId === PHASE2_PRE_REGISTRATION ||
      teamStatusChangePhaseCurrentId === PHASE3_POST_REGISTRATION
    )
      creditForm.setFieldsValue({ approved_value: teamStatusChangeApprovedValue });
  }, [teamStatusChangePhaseCurrentId, teamStatusChangeTeamStatusCurrentId]);

  return (
    <Form
      form={creditForm}
      name="credit_request"
      onFieldsChange={() => onChangeFieldsRecalculate()}
      initialValues={{
        user_id: user.id,
        user_name: user.name,
      }}
      scrollToFirstError
    >
      <Spin spinning={isLoading || !!isFindingProvider || !!isStoringProvider}>
        <FormItem hidden name="id">
          <Input />
        </FormItem>
        <FormItem hidden name="team_status_id">
          <Input />
        </FormItem>
        <FormItem hidden name="json_data.step">
          <Input />
        </FormItem>
        <FormItem hidden name="json_data.price_value">
          <Input />
        </FormItem>
        <FormItem hidden name="json_data.total_steps">
          <Input />
        </FormItem>

        <Row gutter={[8, 8]}>
          <DivisionFields />
        </Row>

        {selectedOperationId && (
          <>
            {(selectedOperationId === OPERATION_PREFIN_ID ||
              selectedOperationId === OPERATION_PREFIN_SUGAR_MILLS_ID) && <PrefinForm />}

            {selectedOperationId === OPERATION_PREPAY_ID ? <PrepaymentForm /> : null}

            {selectedOperationId === OPERATION_DELIVERED_PRODUCT_ADVANCE_ID && <AdvanceProductDeliveredForm />}

            {selectedOperationId === OPERATION_MTM_ID && <OperationMTMForm />}

            {selectedOperationId === OPERATION_INTERN_MARKET_ID && <InternMarketForm />}

            {selectedOperationId === OPERATION_FERTILIZER_SUPPLIERS_ID && <FertilizingForm />}
          </>
        )}
      </Spin>

      <FixedFooter status={formStatus}>
        <div style={{ paddingLeft: '10px' }}>
          {creditRequestData?.datasource ? (
            creditRequestData?.datasource?.toLowerCase() === 'sap' ? (
              <SapSvgLogo width={100} />
            ) : (
              <TotvsSvgLogo width={40} />
            )
          ) : null}
        </div>
        <div className="form-footer-feedback">
          {formStatus === 'danger' && (
            <>
              <div className="feedback-title">{t('pages.credit-request.errors.title')}</div>
              {Object.keys(messagesErrors).map((msgKey: string) => renderMessageError(msgKey))}
              {formErrors.map(errorKey => (
                <div className="feedback-description" key={errorKey.message}>
                  {t(`pages.credit-request.errors.${errorKey.message}`, errorKey)}
                </div>
              ))}
            </>
          )}

          {formStatus === 'warning' && (
            <>
              <div className="feedback-title">{t('pages.credit-request.errors.warning')}</div>
            </>
          )}
        </div>

        <div className="form-footer-actions">
          {!pageStatus.new && userIsLinked && canShowStatusOptions && (
            <Dropdown
              overlay={
                <Menu>
                  {listOfNextTeamStatus.map((status, index) => (
                    <Menu.Item key={status.value}>
                      <StatusLink
                        onClick={() => handleChangeStatus(status.value.toString())}
                        onKeyPress={() => false}
                        role="menuitem"
                        tabIndex={index}
                      >
                        {status.label}
                      </StatusLink>
                    </Menu.Item>
                  ))}
                </Menu>
              }
              disabled={blockTeamStatusButton}
              placement="topRight"
              arrow
            >
              <Button status="secondary-outline">{teamStatusCurrent?.label}</Button>
            </Dropdown>
          )}

          {pageStatus.viewing && userIsLinked && (
            <Button
              status={formStatus ? 'white' : 'primary'}
              onClick={handleClose}
              loading={finalizing}
              disabled={isLoading || finalizing}
              className={`utils-${formStatus} utils-${formStatus}-hover`}
            >
              {t('pages.credit-request.form.buttons.close')}
            </Button>
          )}

          {userIsLinked &&
            ((isEditable && pageStatus.editing) ||
              (selectedOperationId === OPERATION_PREPAY_ID &&
                CREDIT_REQUEST_TEAM_STATUS_EDITABLE_RULE_PREPAY.includes(teamStatusCurrent?.key)) ||
              (selectedOperationId === OPERATION_PREFIN_SUGAR_MILLS_ID &&
                CREDIT_REQUEST_TEAM_STATUS_EDITABLE_RULE_PREFIN_SUGAR_MILLS.includes(teamStatusCurrent?.key))) && (
              // eslint-disable-next-line react/jsx-indent
              <Button
                status={formStatus ? 'white' : 'primary'}
                onClick={confirmRequest}
                loading={finalizing}
                disabled={isLoading || finalizing}
                className={`utils-${formStatus} utils-${formStatus}-hover`}
              >
                {t('pages.credit-request.form.buttons.edit-request')}
              </Button>
            )}

          {!pageStatus.viewing && userIsLinked && (
            <>
              {pageStatus.new && (
                <Button
                  status={formStatus ? 'white' : 'primary'}
                  onClick={confirmRequest}
                  loading={finalizing}
                  disabled={isLoading || finalizing}
                  className={`utils-${formStatus} utils-${formStatus}-hover`}
                >
                  {t('pages.credit-request.form.buttons.request')}
                </Button>
              )}

              {!isEditable && pageStatus.editing && (
                <Button
                  status={formStatus ? 'white' : 'primary'}
                  onClick={handleClose}
                  loading={finalizing}
                  disabled={isLoading || finalizing}
                  className={`utils-${formStatus} utils-${formStatus}-hover`}
                >
                  {t('pages.credit-request.form.buttons.close')}
                </Button>
              )}
            </>
          )}
        </div>
      </FixedFooter>
    </Form>
  );
};

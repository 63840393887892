import { TablePaginationConfig } from 'antd/lib/table';

export const useDashboards = () => {
  const handleCards = async (getCards: any, filterForm: any) => {
    const filters = filterForm.getFieldsValue();
    filters.requesterDate = undefined;
    filters.harvests = filters.harvest;
    filters.harvest = undefined;
    const { summary } = await getCards({
      params: {
        ...filters,
      },
    });
    return summary;
  };
  const handleCharts = async (getCharts: any, filterForm: any) => {
    const filters = filterForm.getFieldsValue();
    filters.harvests = filters.harvest;
    filters.harvest = undefined;
    filters.requesterDate = undefined;
    const response = await getCharts({
      params: {
        ...filters,
      },
    });
    return response;
  };
  const handleChangeTable = async (pagination: TablePaginationConfig, filters: any, sorter: any, getTable: any) => {
    filters.requesterDate = undefined;
    filters.harvests = filters.harvest;
    filters.harvest = undefined;
    const { data, total } = await getTable({
      params: {
        page: pagination.current,
        per_page: pagination.pageSize,
        sort: sorter.sort,
        direction: sorter.direction,
        ...filters,
      },
    });
    return { data, total };
  };
  return {
    handleCards,
    handleCharts,
    handleChangeTable,
  };
};

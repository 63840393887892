import { PaginatedQueryParams, usePaginatedQuery } from 'hooks/usePaginatedQuery';
import { ContractData } from 'types/Contract/ContractData';

type Params = PaginatedQueryParams;

export const useGetContractDependenciesQuery = (contractId: string, { dispatchPagination, ...params }: Params) => {
  return usePaginatedQuery<ContractData>({
    queryKey: [`contract/${contractId}/get-dependencies`, params],
    url: `contract/${contractId}/get-dependencies`,
    params: {
      ...params,
      dispatchPagination,
    },
  });
};

import { SelectOptionType } from 'components/Select/types';
import { PHYSICAL_PERSON_TYPE } from 'configs/constants';
import { ItemsType } from 'hooks/page';
import { PersonType } from 'pages/CreditRequest/components/templates/DetailView/types';
import { AssigneeData } from 'types/CreditRequest/AssigneeData';
import { BasePortData } from 'types/CreditRequest/BasePortData';
import { CreditRequestContractFieldsData } from 'types/CreditRequest/CreditRequestContractFieldsData';
import CreditRequestData from 'types/CreditRequest/CreditRequestData';
import { CustodyStoreUnitdata } from 'types/CreditRequest/CustodyStoreUnitData';
import { GuaranteeData } from 'types/CreditRequest/GuaranteeData';
import { DTODatasulContractSaca } from 'types/Datasul/DTODatasulContract';
import { CacheOptions } from 'types/Hooks/cache';
import SustainabilityHistoryData from 'types/Sustainability/SustainabilityHistoryData';
import { StateCreator } from 'zustand';

import { Operations } from './divisionSlice';

export type WithdrawalPeriodStatus = '' | 'success' | 'warning' | 'error' | 'validating';

export type CreditRequestFormStateSlice = {
  contractTotalVolume: number;
  creditRequestOfContract: CreditRequestData[];
  selectedContractNumber: string;
  isDolarSelected: boolean;
  selectedPersonType: PersonType;
  optionsPricePerBag: DTODatasulContractSaca[];
  bagPriceLength: boolean;
  contractFieldsValue: CreditRequestContractFieldsData;
  people: ItemsType[];
  isBlocking: boolean;
  subsidiaries: ItemsType[];
  isAzParticipant: string;
  isRequiredAttachINSS: boolean;
  isRequiredAttachFethab: boolean;
  formIsBlocked: boolean;
  withdrawalPeriodStatus: WithdrawalPeriodStatus;
  withdrawalPeriodVisible: boolean;
  withdrawalPeriodExtra: any;
  stateIsSelected: boolean;
  justificationVisible: boolean;
  listJustification: CacheOptions[];
  blockTeamStatusButton: boolean;
  limitRules: boolean;
  canCurrentUserApproveGuarantee: boolean;
  partialLimitData: GuaranteeData[];
  isEditable: boolean;
  citiesWarehouse: CacheOptions[];
  selectedPersonId: string;
  documentList: any[];
  documentTotal: number;
  depositaryList: any[];
  subDivisionOptions: CacheOptions[];
  operationOptions: CacheOptions[];
  approvedValue: number;
  paramRequestedValue: number;
  serasaRestriction: { counterpartyDocument: string; restriction: string } | undefined;
  statusHistory: any[];
  jsonDataQuantity: string;
  jsonDataPrice: string | number;
  teamStatusChangeTeamStatusCurrentId: string;
  documentLoading: string;
  contractTypeVisible: boolean;
  teamStatusChangePhaseCurrentId: string;
  teamStatusChangeOperationCurrentId: Operations;
  teamStatusChangeCreditRequestId: string;
  teamStatusChangeRequestedAmount: number;
  teamStatusChangeCreditRequestNumber: string;
  teamStatusChangeApprovedValue: number;
  teamStatusChangeMoneyPrefix: string;
  canShowStatusOptions: boolean;
  listOfNextTeamStatus: SelectOptionType[];
  autocompleteLoading: string;
  selectedPeople: ItemsType[];
  sustainabilityHistory: SustainabilityHistoryData[];
  basePort?: BasePortData;
  custodyStateId: string;
  custodyCityId: string;
  custodyStoreUnitId: string;
  custodyWarehouseAddressIsLegacy: boolean;
  custodyState?: {
    id: string;
    name: string;
  };
  custodyCity?: {
    id: string;
    name: string;
  };
  custodyStoreUnit?: CustodyStoreUnitdata;
  custodyStoreUnitCommodities: { name: string; id: string }[];
  isFundeinfra: boolean;
  selectedStateId: string;
  modalityId?: string;
  selectedProviderOption?: SelectOptionType;
  selectedContractId: string;
  isFixationQuantityInsufficient: boolean;
  assigneeList: AssigneeData[];
  hasConsentingIntervener: boolean;
  setContractTotalVolume: (value: number) => void;
  setCreditRequestOfContract: (value: CreditRequestData[]) => void;
  setSelectedContractNumber: (value: string) => void;
  setIsDolarSelected: (value: boolean) => void;
  setSelectedPersonType: (value: PersonType) => void;
  setOptionsPricePerBag: (value: DTODatasulContractSaca[]) => void;
  setBagPriceLength: (value: boolean) => void;
  setContractFieldsValue: (value: CreditRequestContractFieldsData) => void;
  setPeople: (value: ItemsType[]) => void;
  setIsBlocking: (value: boolean) => void;
  setSubsidiaries: (value: ItemsType[]) => void;
  setIsAzParticipant: (value: string) => void;
  setIsRequiredAttachINSS: (value: boolean) => void;
  setIsRequiredAttachFethab: (value: boolean) => void;
  setFormIsBlocked: (value: boolean) => void;
  setWithdrawalPeriodStatus: (value: WithdrawalPeriodStatus) => void;
  setWithdrawalPeriodVisible: (value: boolean) => void;
  setWithdrawalPeriodExtra: (value: any) => void;
  setStateIsSelected: (value: boolean) => void;
  setJustificationVisible: (value: boolean) => void;
  setListJustification: (value: CacheOptions[]) => void;
  setBlockTeamStatusButton: (value: boolean) => void;
  setLimitRules: (value: boolean) => void;
  setCanCurrentUserApproveGuarantee: (value: boolean) => void;
  setPartialLimitData: (value: GuaranteeData[]) => void;
  setIsEditable: (value: boolean) => void;
  setCitiesWarehouse: (value: CacheOptions[]) => void;
  setSelectedPersonId: (value: string) => void;
  setDocumentList: (value: any[]) => void;
  setDocumentTotal: (value: number) => void;
  setDepositaryList: (value: any[]) => void;
  setSubDivisionOptions: (value: CacheOptions[]) => void;
  setOperationOptions: (value: CacheOptions[]) => void;
  setApprovedValue: (value: number) => void;
  setParamRequestedValue: (value: number) => void;
  setSerasaRestriction: (value: { counterpartyDocument: string; restriction: string } | undefined) => void;
  setStatusHistory: (value: any[]) => void;
  setJsonDataQuantity: (value: string) => void;
  setJsonDataPrice: (value: string | number) => void;
  setTeamStatusChangeTeamStatusCurrentId: (value: string) => void;
  setDocumentLoading: (value: string) => void;
  setContractTypeVisible: (value: boolean) => void;
  setTeamStatusChangePhaseCurrentId: (value: string) => void;
  setTeamStatusChangeOperationCurrentId: (value: Operations) => void;
  setTeamStatusChangeCreditRequestId: (value: string) => void;
  setTeamStatusChangeRequestedAmount: (value: number) => void;
  setTeamStatusChangeCreditRequestNumber: (value: string) => void;
  setTeamStatusChangeApprovedValue: (value: number) => void;
  setTeamStatusChangeMoneyPrefix: (value: string) => void;
  setCanShowStatusOptions: (value: boolean) => void;
  setListOfNextTeamStatus: (value: SelectOptionType[]) => void;
  setAutocompleteLoading: (value: string) => void;
  setSelectedPeople: (value: ItemsType[]) => void;
  setSustainabilityHistory: (value: SustainabilityHistoryData[]) => void;
  setBasePort: (value: BasePortData) => void;
  setCustodyStateId: (value: string) => void;
  setCustodyCityId: (value: string) => void;
  setCustodyStoreUnitId: (value: string) => void;
  setCustodyWarehouseAddressIsLegacy: (value: boolean) => void;
  setCustodyState: (value: { id: string; name: string }) => void;
  setCustodyCity: (value: { id: string; name: string }) => void;
  setCustodyStoreUnit: (value: { id: string; name: string; storage: { name: string } }) => void;
  setCustodyStoreUnitCommodities: (value: { name: string; id: string }[]) => void;
  setIsFundeinfra: (value: boolean) => void;
  setSelectedStateId: (value: string) => void;
  setModalityId: (value: string) => void;
  setSelectedProviderOption: (value: SelectOptionType) => void;
  setSelectedContractId: (value: string) => void;
  setIsFixationQuantityInsufficient: (value: boolean) => void;
  setAssigneeList: (value: AssigneeData[]) => void;
  setHasConsentingIntervener: (value: boolean) => void;
};

export const creditRequestFormStateSliceInitialState = {
  assigneeList: [],
  contractTotalVolume: 0,
  selectedProviderOption: undefined,
  creditRequestOfContract: [],
  selectedContractNumber: '',
  isDolarSelected: false,
  selectedPersonType: PHYSICAL_PERSON_TYPE,
  optionsPricePerBag: [],
  bagPriceLength: false,
  contractFieldsValue: {} as CreditRequestContractFieldsData,
  people: [],
  isBlocking: false,
  subsidiaries: [],
  isAzParticipant: '',
  citiesWarehouse: [],
  custodyWarehouseAddressIsLegacy: false,
  isRequiredAttachINSS: false,
  isRequiredAttachFethab: false,
  formIsBlocked: false,
  withdrawalPeriodStatus: '',
  withdrawalPeriodVisible: false,
  withdrawalPeriodExtra: '',
  stateIsSelected: false,
  justificationVisible: false,
  listJustification: [],
  blockTeamStatusButton: false,
  limitRules: false,
  canCurrentUserApproveGuarantee: false,
  partialLimitData: [],
  isEditable: false,
  depositaryList: [],
  documentList: [],
  documentTotal: 0,
  selectedPersonId: '',
  subDivisionOptions: [],
  operationOptions: [],
  approvedValue: 0,
  paramRequestedValue: 0,
  serasaRestriction: undefined,
  statusHistory: [],
  jsonDataPrice: 0,
  jsonDataQuantity: '',
  teamStatusChangeTeamStatusCurrentId: '',
  documentLoading: '',
  contractTypeVisible: false,
  teamStatusChangeApprovedValue: 0,
  teamStatusChangeCreditRequestId: '',
  teamStatusChangeCreditRequestNumber: '',
  teamStatusChangeMoneyPrefix: '',
  teamStatusChangeOperationCurrentId: null,
  teamStatusChangePhaseCurrentId: '',
  teamStatusChangeRequestedAmount: 0,
  canShowStatusOptions: false,
  listOfNextTeamStatus: [],
  autocompleteLoading: '',
  selectedPeople: [],
  sustainabilityHistory: [],
  custodyCityId: '',
  custodyStateId: '',
  custodyCity: undefined,
  custodyState: undefined,
  custodyStoreUnit: undefined,
  custodyStoreUnitId: '',
  custodyStoreUnitCommodities: [],
  isFundeinfra: false,
  selectedStateId: '',
  modalityId: '',
  selectedContractId: '',
  isFixationQuantityInsufficient: false,
  hasConsentingIntervener: false,
} as CreditRequestFormStateSlice;

export const createCreditRequestFormStateSlice: StateCreator<
  CreditRequestFormStateSlice,
  [],
  [],
  CreditRequestFormStateSlice
> = set => ({
  ...creditRequestFormStateSliceInitialState,
  setContractTotalVolume: value => set(state => ({ ...state, contractTotalVolume: value })),
  setCreditRequestOfContract: value => set(state => ({ ...state, creditRequestOfContract: value })),
  setSelectedContractNumber: value => set(state => ({ ...state, selectedContractNumber: value })),
  setIsDolarSelected: value => set(state => ({ ...state, isDolarSelected: value })),
  setSelectedPersonType: value =>
    set(state => ({ ...state, selectedPersonType: value, isPhysicalPerson: value === PHYSICAL_PERSON_TYPE })),
  setOptionsPricePerBag: value => set(state => ({ ...state, optionsPricePerBag: value })),
  setBagPriceLength: value => set(state => ({ ...state, bagPriceLength: value })),
  setContractFieldsValue: value => set(state => ({ ...state, contractFieldsValue: value })),
  setPeople: value => set(state => ({ ...state, people: value })),
  setIsBlocking: value => set(state => ({ ...state, isBlocking: value })),
  setSubsidiaries: value => set(state => ({ ...state, subsidiaries: value })),
  setIsAzParticipant: value => set(state => ({ ...state, isAzParticipant: value })),
  setIsRequiredAttachINSS: value => set(state => ({ ...state, isRequiredAttachINSS: value })),
  setIsRequiredAttachFethab: value => set(state => ({ ...state, isRequiredAttachFethab: value })),
  setFormIsBlocked: value => set(state => ({ ...state, formIsBlocked: value })),
  setWithdrawalPeriodStatus: value => set(state => ({ ...state, withdrawalPeriodStatus: value })),
  setWithdrawalPeriodVisible: value => set(state => ({ ...state, withdrawalPeriodVisible: value })),
  setWithdrawalPeriodExtra: value => set(state => ({ ...state, withdrawalPeriodExtra: value })),
  setStateIsSelected: value => set(state => ({ ...state, stateIsSelected: value })),
  setJustificationVisible: value => set(state => ({ ...state, justificationVisible: value })),
  setListJustification: value => set(state => ({ ...state, listJustification: value })),
  setBlockTeamStatusButton: value => set(state => ({ ...state, blockTeamStatusButton: value })),
  setLimitRules: value => set(state => ({ ...state, limitRules: value })),
  setCanCurrentUserApproveGuarantee: value => set(state => ({ ...state, canCurrentUserApproveGuarantee: value })),
  setPartialLimitData: value => set(state => ({ ...state, partialLimitData: value })),
  setIsEditable: value => set(state => ({ ...state, isEditable: value })),
  setCitiesWarehouse: value => set(state => ({ ...state, citiesWarehouse: value })),
  setSelectedPersonId: value => set(state => ({ ...state, selectedPersonId: value })),
  setDocumentList: value => set(state => ({ ...state, documentList: value })),
  setDocumentTotal: value => set(state => ({ ...state, documentTotal: value })),
  setDepositaryList: value => set(state => ({ ...state, depositaryList: value })),
  setSubDivisionOptions: value => set(state => ({ ...state, subDivisionOptions: value })),
  setOperationOptions: value => set(state => ({ ...state, operationOptions: value })),
  setApprovedValue: value => set(state => ({ ...state, approvedValue: value })),
  setParamRequestedValue: value => set(state => ({ ...state, paramRequestedValue: value })),
  setSerasaRestriction: value => set(state => ({ ...state, serasaRestriction: value })),
  setStatusHistory: value => set(state => ({ ...state, statusHistory: value })),
  setJsonDataPrice: value => set(state => ({ ...state, jsonDataPrice: value })),
  setJsonDataQuantity: value => set(state => ({ ...state, jsonDataQuantity: value })),
  setTeamStatusChangeTeamStatusCurrentId: value =>
    set(state => ({ ...state, teamStatusChangeTeamStatusCurrentId: value })),
  setDocumentLoading: value => set(state => ({ ...state, documentLoading: value })),
  setContractTypeVisible: value => set(state => ({ ...state, contractTypeVisible: value })),
  setTeamStatusChangePhaseCurrentId: value => set(state => ({ ...state, teamStatusChangePhaseCurrentId: value })),
  setTeamStatusChangeOperationCurrentId: value =>
    set(state => ({ ...state, teamStatusChangeOperationCurrentId: value })),
  setTeamStatusChangeCreditRequestId: value => set(state => ({ ...state, teamStatusChangeCreditRequestId: value })),
  setTeamStatusChangeRequestedAmount: value => set(state => ({ ...state, teamStatusChangeRequestedAmount: value })),
  setTeamStatusChangeCreditRequestNumber: value =>
    set(state => ({ ...state, teamStatusChangeCreditRequestNumber: value })),
  setTeamStatusChangeApprovedValue: value => set(state => ({ ...state, teamStatusChangeApprovedValue: value })),
  setTeamStatusChangeMoneyPrefix: value => set(state => ({ ...state, teamStatusChangeMoneyPrefix: value })),
  setCanShowStatusOptions: value => set(state => ({ ...state, canShowStatusOptions: value })),
  setListOfNextTeamStatus: value => set(state => ({ ...state, listOfNextTeamStatus: value })),
  setAutocompleteLoading: value => set(state => ({ ...state, autocompleteLoading: value })),
  setSelectedPeople: value => set(state => ({ ...state, selectedPeople: value })),
  setSustainabilityHistory: value => set(state => ({ ...state, sustainabilityHistory: value })),
  setBasePort: value => set(state => ({ ...state, basePort: value })),
  setCustodyStateId: value => set(state => ({ ...state, custodyStateId: value })),
  setCustodyCityId: value => set(state => ({ ...state, custodyCityId: value })),
  setCustodyStoreUnitId: value => set(state => ({ ...state, custodyStoreUnitId: value })),
  setCustodyWarehouseAddressIsLegacy: value => set(state => ({ ...state, custodyWarehouseAddressIsLegacy: value })),
  setCustodyState: value => set(state => ({ ...state, custodyState: value })),
  setCustodyCity: value => set(state => ({ ...state, custodyCity: value })),
  setCustodyStoreUnit: value => set(state => ({ ...state, custodyStoreUnit: value })),
  setCustodyStoreUnitCommodities: value => set(state => ({ ...state, custodyStoreUnitCommodities: value })),
  setIsFundeinfra: value => set(state => ({ ...state, isFundeinfra: value })),
  setSelectedStateId: value => set(state => ({ ...state, selectedStateId: value })),
  setModalityId: value => set(state => ({ ...state, modalityId: value })),
  setSelectedProviderOption: value => set(state => ({ ...state, selectedProviderOption: value })),
  setSelectedContractId: value => set(state => ({ ...state, selectedContractId: value })),
  setIsFixationQuantityInsufficient: value => set(state => ({ ...state, isFixationQuantityInsufficient: value })),
  setAssigneeList: value => set(state => ({ ...state, assigneeList: value })),
  setHasConsentingIntervener: value => set(state => ({ ...state, hasConsentingIntervener: value })),
});

import { useQuery } from '@tanstack/react-query';
import { OptionData } from 'components/ui/combo';
import { useAbility } from 'hooks/ability';
import api from 'services/api/api';
import TemplateDocsData from 'types/CreditRequest/TemplateDocsData';
import { Converter } from 'utils/Converter';

type UseGetFormalizationTemplates = {
  asSelectOptions?: boolean;
};

export type FormalizationTemplateOption = OptionData & TemplateDocsData;

export const useGetFormalizationTemplatesQuery = (props: UseGetFormalizationTemplates) => {
  const ability = useAbility();

  return useQuery<TemplateDocsData[] | FormalizationTemplateOption[]>({
    staleTime: Infinity,
    queryKey: ['edocument/template/all'],
    queryFn: async () => {
      if (!ability.can('edocument.template.templatedocument.show', '')) return [] as TemplateDocsData[];

      const response = await api.get(`edocument/template/all`);

      if (response.status !== 200) throw new Error('Error fetching credit request');
      if (!response.data) return [] as TemplateDocsData[];

      if (props?.asSelectOptions)
        return Converter.selectOptionArray(response.data?.data || [], `id`, `id`, `name`).map((template: any) => ({
          ...template,
          extra: template.documents,
        })) as FormalizationTemplateOption[];

      return (response.data.data || []) as TemplateDocsData[];
    },
  });
};

import { useCallback } from 'react';

import { TablePaginationConfig } from 'antd';
import { REASON_EXPOSURE } from 'configs/constants';
import { useCreditRequestContext } from 'context/CreditRequestContext';
import { usePtax } from 'hooks/fetch/usePtax';
import { usePagination } from 'hooks/usePagination';
import moment from 'moment';
import creditApi from 'services/api/credit';

import { useCreditRequestStore } from '../store/useCreditRequestStore';
import { useGetContractVolume } from './useGetContractVolume';

export const useContractCreditRequestsTableController = () => {
  const { getPtax } = usePtax();
  const { getContractVolume } = useGetContractVolume();
  const { creditForm } = useCreditRequestContext();

  const { paginationConfig: volumePaginationConfig, dispatchPagination: dispatchVolumePagination } = usePagination({});

  const setCreditRequestOfContract = useCreditRequestStore(state => state.setCreditRequestOfContract);
  const setContractTotalVolume = useCreditRequestStore(state => state.setContractTotalVolume);
  const selectedOperationId = useCreditRequestStore(state => state.selectedOperationId);
  const selectedContractNumber = useCreditRequestStore(state => state.selectedContractNumber);

  const getCreditRequestsWithContract = useCallback(
    async (contractNumber: string, pagination?: TablePaginationConfig) => {
      creditApi.requests
        .get({
          params: {
            contract: contractNumber,
            hide_canceled: true,
            page: pagination?.current ?? 1,
            per_page: pagination?.pageSize ?? volumePaginationConfig.pageSize,
          },
        })
        .then(async response => {
          let brBalance = 0;
          let usdBalanceCalc = 0;
          let date = '';
          creditForm.setFieldsValue({ usdBalance: 0 });
          response.data.data.forEach(async (data: any) => {
            if (data.reason?.id === REASON_EXPOSURE) {
              brBalance = data.json_data?.datasul_contract?.valPago - data.json_data?.datasul_contract?.valorLiquidacao;
              date = moment(data.json_data?.expected_payment_at).utc().format('YYYY-MM-DD');
              const ptax = await getPtax(date);
              usdBalanceCalc += brBalance / ptax;
              creditForm.setFieldsValue({ usdBalance: usdBalanceCalc });
            }
          });

          setCreditRequestOfContract(response.data.data);

          // if (response.data.data.length > 1 && selectedOperation === OPERATION_DELIVERED_PRODUCT_ADVANCE) {
          //   setFormErrors(old => [...old, 'error_multiple_requests']);
          //   setFormStatus('danger');
          // } else {
          //   setFormErrors(old => [...old.filter(message => message !== 'error_multiple_requests')]);
          //   setFormStatus(undefined);
          // }

          const volume = await getContractVolume(contractNumber);
          setContractTotalVolume(volume);

          dispatchVolumePagination({
            type: 'OVERRIDE_PAGINATION',
            payload: {
              current: pagination?.current || 1,
              pageSize: pagination?.pageSize || volumePaginationConfig.pageSize,
              total: response?.data?.total || 0,
              lastPage: response?.data?.last_page || 1,
            },
          });
        });
    },
    [selectedOperationId, volumePaginationConfig, selectedContractNumber],
  );

  return {
    getCreditRequestsWithContract,
    volumePaginationConfig,
  };
};

import { UseFormReturn } from 'react-hook-form';

import { ProviderData } from 'types/CreditRequest/ProviderData';

import { RegisteredCustomerFormData } from '../registered-customer.form';
import { useWarnUserIsRegistered } from './use-warn-user-is-registered';

type Props = {
  isPhysicalPerson?: boolean;
  form: UseFormReturn<RegisteredCustomerFormData, any, undefined>;
};

export const useFillProviderFields = ({ isPhysicalPerson, form }: Props) => {
  const { warnUserIsRegistered } = useWarnUserIsRegistered();

  const fillProviderFields = (providerData: ProviderData) => {
    if (providerData.physical_person_id || providerData.legal_person_id)
      return warnUserIsRegistered({
        personType: isPhysicalPerson ? 'physical' : `legal`,
        document: isPhysicalPerson ? providerData.cpf : providerData.cnpj,
        userId: providerData.physical_person_id || providerData.legal_person_id,
      });

    const oldData = form.getValues();
    form.reset({
      type: oldData.type,
      nationality: oldData.nationality,
      cpf: oldData.cpf,
      cnpj: oldData.cnpj,
      name: providerData.name,
      company_name: providerData.company_name,
      fantasy_name: providerData.fantasy_name,
      email: providerData.email,
      cod_datasul: providerData.cod_datasul,
      sap_bp_subsidiary_code: providerData.sap_bp_subsidiary_code ?? (providerData as any).cod_sap,
      json_data: {
        step: oldData.json_data.step,
        total_steps: oldData.json_data.total_steps,
        code_erp: providerData.cod_datasul,
      },
    });
  };

  return {
    fillProviderFields,
  };
};

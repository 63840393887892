import { IconType } from 'react-icons';
import { BsListCheck } from 'react-icons/bs';
import { FaComments, FaFileSignature, FaHistory } from 'react-icons/fa';
import { MdAttachment } from 'react-icons/md';

export const stages = new Map<
  'Details' | 'Draft' | 'Signature' | 'Attach' | 'Comments' | 'ReprovalHistoric',
  { index: number; icon?: IconType }
>([]);

stages.set('Details', {
  index: 1,
});
stages.set('Draft', {
  index: 2,
  icon: BsListCheck,
});
stages.set('Signature', {
  index: 3,
  icon: FaFileSignature,
});
stages.set('Attach', {
  index: 4,
  icon: MdAttachment,
});
stages.set('Comments', {
  index: 5,
  icon: FaComments,
});
stages.set('ReprovalHistoric', {
  index: 6,
  icon: FaHistory,
});

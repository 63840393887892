import React from 'react';

import { DocxViewer } from '../../../../../components/DocxViewer';
import { ImageViewer } from '../../../components/ImageViewer';
import { ImageViewerProvider } from '../../../components/ImageViewer/context';
import { Container } from './styles';

interface Props {
  url: string;
  isDocument?: boolean;
  isPdf?: boolean;
}

export const PreviewCell = React.memo(({ url, isDocument = true, isPdf = false }: Props) => {
  return (
    <Container>
      {url && (
        <>
          {isDocument ? (
            <DocxViewer url={url} useExternalRenderApi={!isPdf} />
          ) : (
            <ImageViewerProvider>
              <ImageViewer url={decodeURIComponent(url)} />
            </ImageViewerProvider>
          )}
        </>
      )}
    </Container>
  );
});

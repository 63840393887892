import React from 'react';
import { useTranslation } from 'react-i18next';

import { ColumnType } from 'antd/lib/table';

import {
  TEAM_COMMERCIAL,
  TEAM_APPROVATION,
  TEAM_CREDIT,
  TEAM_PREFIN,
  TEAM_LEGAL,
  TEAM_OPERATIONAL_RISK,
  TEAM_CONTRACT,
  TEAM_DOCUMENTS,
  TEAM_SUSTAINABILITY,
} from '../../../configs/constants';
import { Formatter } from '../../../utils/Formatter';
import { SummaryData, ResultCardItems, SetPropsCards, GetItemsChart } from './types';

export const getItemsChart = ({ prop, item, buttonValue, chartEvent, setChartEvent, setPropsChart }: GetItemsChart) => {
  const filter = prop.filter((i: any) => i.key === item);
  const filterChartEvent = chartEvent.filter((i: any) => Object.keys(i).length);
  if (filterChartEvent.length) setChartEvent(filterChartEvent);
  if (chartEvent.length === 1) setChartEvent([]);
  if (filter[0].buttonOptions) {
    const serie = filter[0].buttonOptions[buttonValue];
    filter[0].typeFormatter = serie.typeFormatter !== undefined ? serie.typeFormatter : serie[0].typeFormatter;
    filter[0].buttonValue = buttonValue;
    filter[0].series = serie;
  }
  if (filter[0].drilldownOptions) {
    const drilldown = filter[0].drilldownOptions[buttonValue];
    filter[0].drilldown = drilldown;
  }
  setPropsChart(prop);
};

export const getCards = async (
  handleCards: () => Promise<SummaryData>,
  setPropsCards: SetPropsCards,
  propsCards?: any,
): Promise<void> => {
  const data = await handleCards();
  const result: ResultCardItems[] = Object.keys(data).map((item, key) => {
    let title = '0';
    switch (item) {
      case 'count_credit_request':
      case 'count_credit_request_post':
      case 'count_credit_request_pre':
      case 'cprs':
      case 'registrations':
      case 'surveys':
        title = `${data[item]}`;
        break;
      case 'approval_limit':
      case 'approved_value':
      case 'balance':
      case 'disapproved_limit':
      case 'sum_requested_amount':
        const value = `${data[item]}`;
        title = Formatter.abbreviated(parseInt(value, 10));
        break;
      default:
        break;
    }
    return {
      description: item,
      title,
      key,
    };
  });
  if (propsCards !== undefined) {
    if (propsCards.length) setPropsCards((prev: any) => [...prev, result]);
    else setPropsCards(result);
  } else setPropsCards(result);
};

export const customColumnsTimeline = (column: ColumnType<object>) => {
  const { t } = useTranslation();
  column = { ...column, title: t(column.title.toString()) };
  const getValue = (teamId: string, data: any) => {
    const filter = data.time_per_team.filter((item: any) => item.team_id === teamId);
    let value = '';
    if (filter.length) {
      switch (filter[0].days) {
        case '0':
        case null:
          value = '';
          break;
        case '1':
          value = `${filter[0].days} dia`;
          break;
        default:
          value = `${filter[0].days} dias`;
          break;
      }
    }
    return value;
  };
  switch (column.key) {
    case 'commercial':
      column = {
        ...column,
        render: (record: any, data: any) => {
          return getValue(TEAM_COMMERCIAL, data);
        },
      };
      break;
    case 'approvation':
      column = {
        ...column,
        render: (record: any, data: any) => {
          return getValue(TEAM_APPROVATION, data);
        },
      };
      break;
    case 'credit':
      column = {
        ...column,
        render: (record: any, data: any) => {
          return getValue(TEAM_CREDIT, data);
        },
      };
      break;
    case 'prefin':
      column = {
        ...column,
        render: (record: any, data: any) => {
          return getValue(TEAM_PREFIN, data);
        },
      };
      break;
    case 'legal':
      column = {
        ...column,
        render: (record: any, data: any) => {
          return getValue(TEAM_LEGAL, data);
        },
      };
      break;
    case 'operational-risk':
      column = {
        ...column,
        render: (record: any, data: any) => {
          return getValue(TEAM_OPERATIONAL_RISK, data);
        },
      };
      break;
    case 'contract':
      column = {
        ...column,
        render: (record: any, data: any) => {
          return getValue(TEAM_CONTRACT, data);
        },
      };
      break;
    case 'documents':
      column = {
        ...column,
        render: (record: any, data: any) => {
          return getValue(TEAM_DOCUMENTS, data);
        },
      };
      break;
    case 'sustainability':
      column = {
        ...column,
        render: (record: any, data: any) => {
          return getValue(TEAM_SUSTAINABILITY, data);
        },
      };
      break;
    case 'total_time':
      column = {
        ...column,
        render: (record: any) => {
          return record === 0 ? '' : `${record} dias`;
        },
      };
      break;
    case 'status':
      column = {
        ...column,
        render: (record: any, data: any) => {
          return (
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                background: data.color,
                padding: '5px',
                borderRadius: '5px',
              }}
            >
              <span>{record}</span>
            </div>
          );
        },
      };
      break;
    default:
      break;
  }
  return column;
};

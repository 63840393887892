import React from 'react';

import { cn } from 'lib/utils';
import { CirclePlus } from 'lucide-react';

type Props = {
  onClick?: () => void;
  text: string;
  className?: string;
};

export const Add = ({ onClick, text, className }: Props) => {
  return (
    <button
      type="button"
      onClick={onClick && onClick}
      title={text}
      className={cn(
        'flex flex-col gap-2 justify-center items-center min-h-[400px] w-full text-gray-300 text-2xl font-bold pointer-events-auto focus:text-primary',
        className,
      )}
    >
      <CirclePlus size={40} />
      {text}
    </button>
  );
};

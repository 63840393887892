import { useMemo } from 'react';

import { useCache } from 'hooks/cache';
import { CacheOptions } from 'types/Hooks/cache';

export const useGetCacheState = (stateSail: string) => {
  const cache = useCache();

  return useMemo(() => {
    return cache.getCreditOptions('state').find((x: CacheOptions) => x.slug?.toUpperCase() === stateSail);
  }, [cache]);
};

import React, { useState, useCallback, useEffect, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';

import { Input, Modal, Form, Upload } from 'antd';
import moment from 'moment';

import { CacheOptions } from '../../../../../types/Hooks/cache';
import { KanbanColumnPersonLegal, KanbanColumnPersonPhysical } from '../../../../../@types/responseKanban/KanbanColumn';
import { Select, FormItem, Button, Spin } from '../../../../../components';
import appConfigs from '../../../../../configs/app';
import { useCache } from '../../../../../hooks/cache';
import api from '../../../../../services/api/person';
import { Container, Header, Content, Footer, SUpload } from './styles';
import { CardProps } from './types';

const Card: React.FC<CardProps> = ({ data, model, refreshKanban, removeFromColumn }) => {
  const { t } = useTranslation();
  const [formModalStatus] = Form.useForm();
  const { getPersonOptions } = useCache();
  const { Dragger } = Upload;
  const [uploadList, setUploadList] = useState([]);

  const [footerOpen, setFooterOpen] = useState(false);
  const [modalStatusVisible, setModalStatusVisible] = useState(false);
  const [modalStatusLoading, setModalStatusLoading] = useState(false);
  const [changingRequestId, setChangingRequestId] = useState('');
  const [statusId, setStatusId] = useState('');
  const [nextStatus, setNextStatus] = useState<CacheOptions>({} as CacheOptions);
  const [statusOptions, setStatusOptions] = useState<CacheOptions[]>([]);

  async function getBase64(img: any, callback: any) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  function handleChangeUploadFile(info: any) {
    let fileList = [...info.fileList];

    // 1. Limit the number of uploaded files
    // Only to show one recent uploaded files, and old ones will be replaced by the new
    fileList = fileList.slice(-1);

    // 2. Read from response and show file link
    fileList = fileList.map(file => {
      if (file.response) {
        // Component will show file.url as link
        file.url = file.response.url;
      }
      return file;
    });
    setUploadList(fileList);
  }

  function getOriginalName(fileName: string, withExtension = false) {
    const splitName = fileName.split('.');
    const splitLength = splitName.length;
    const extension = splitName[splitLength - 1];
    delete splitName[splitLength - 1];
    const finalFileName = splitName
      .filter((s: string) => !!s)
      .join('_')
      .replace(/ /g, '_');
    return !withExtension ? finalFileName : `${finalFileName}.${extension}`;
  }

  useEffect(() => {
    setStatusOptions(getPersonOptions('person_stage'));
  }, [model, getPersonOptions]);

  function renderHeader() {
    if (model === 'legal') {
      const { fantasy_name, city, id, stage, created_at } = data as KanbanColumnPersonLegal;

      const isRegisteredColumn = stage && stage.slug === 'registered';

      return (
        <>
          <Header target="_blank" to={`/registered-customers/${model.replace('person-', '')}/edit/${id}`}>
            <div>
              {fantasy_name} {city && city.state && <span>({city.state.code})</span>}
            </div>
            {isRegisteredColumn && (
              <div>{`${t('Registrado em')}: ${moment(created_at).format(appConfigs.formatDate)}`}</div>
            )}
          </Header>
        </>
      );
    }

    if (model === 'physical') {
      const { name, city, id, stage, created_at } = data as KanbanColumnPersonPhysical;

      const isRegisteredColumn = stage && stage.slug === 'registered';

      return (
        <>
          <Header target="_blank" to={`/registered-customers/${model.replace('person-', '')}/edit/${id}`}>
            <div>
              {name} {city && city.state && <span>({city.state.code})</span>}
            </div>
            {isRegisteredColumn && (
              <div>{`${t('Registrado em')}: ${moment(created_at).format(appConfigs.formatDate)}`}</div>
            )}
          </Header>
        </>
      );
    }

    return <div />;
  }

  function renderContent() {
    if (model === 'physical') {
      const { region } = data as KanbanColumnPersonPhysical;

      if (region && region.name) {
        return <div>{`${region.name} / ${region.subsidiary.name}`}</div>;
      }
    }

    return <div />;
  }

  function renderFooter() {
    let labelDate;
    let formattedDate;

    if (model === 'legal') {
      const { stage, created_at, updated_at } = data as KanbanColumnPersonLegal;

      if (stage && stage.slug === 'received') {
        labelDate = t('Registrado em');
        formattedDate = moment(created_at).format(appConfigs.formatDate);
      } else {
        labelDate = t('Atualizado em');
        formattedDate = moment(updated_at).format(appConfigs.formatDate);
      }
    }

    if (model === 'physical') {
      const { stage, created_at, updated_at } = data as KanbanColumnPersonPhysical;

      if (stage && stage.slug === 'received') {
        labelDate = t('Registrado em');
        formattedDate = moment(created_at).format(appConfigs.formatDate);
      } else {
        labelDate = t('Atualizado em');
        formattedDate = moment(updated_at).format(appConfigs.formatDate);
      }
    }

    return (
      <>
        <div>{`${labelDate} ${formattedDate}`}</div>
        <button type="button" onClick={() => setFooterOpen(!footerOpen)}>
          {footerOpen ? <FaChevronUp /> : <FaChevronDown />}
        </button>
      </>
    );
  }

  const displayModalChangeStatus = useCallback(async (id: string) => {
    setChangingRequestId(id);
    setModalStatusVisible(true);
  }, []);

  const handleChangeStatus = useCallback(async () => {
    setModalStatusLoading(true);
    const form = formModalStatus.getFieldsValue();

    if (!form.stage_id) {
      form.stage_id = statusId;
    }

    await api[model]
      .updateStatus(changingRequestId, form)
      .then(async () => {
        removeFromColumn(changingRequestId);
        await refreshKanban();
      })
      .finally(() => {
        setModalStatusVisible(false);
        setModalStatusLoading(false);
        setChangingRequestId('');
        setNextStatus({} as CacheOptions);
        formModalStatus.resetFields();
      });
  }, [model, formModalStatus, changingRequestId, statusId, removeFromColumn, refreshKanban]);

  function renderHideFooter() {
    if (model === 'legal') {
      const { stage_id, id, json_data } = data as KanbanColumnPersonLegal;

      let phones;
      if (json_data) {
        const { cel_number, phone_number } = json_data;
        phones = (
          <>
            {phone_number && <div>{`${t('Telefone')} ${phone_number}`}</div>}
            {cel_number && <div>{`${t('Celular')} ${cel_number}`}</div>}
          </>
        );
      }

      return (
        <>
          {phones}
          <Select
            allowClear={false}
            onChange={async (value, option: any) => {
              setStatusId(value.toString());
              setNextStatus(option as CacheOptions);
              await displayModalChangeStatus(id);
            }}
            options={statusOptions}
            value={stage_id}
          />
        </>
      );
    }

    if (model === 'physical') {
      const { stage_id, id, json_data } = data as KanbanColumnPersonPhysical;

      let phones;
      if (json_data) {
        const { cel_number, phone_number } = json_data;
        phones = (
          <>
            {phone_number && <div>{`${t('Telefone')} ${phone_number}`}</div>}
            {cel_number && <div>{`${t('Celular')} ${cel_number}`}</div>}
          </>
        );
      }

      return (
        <>
          {phones}
          <Select
            allowClear={false}
            onChange={async (value, option: any) => {
              setStatusId(value.toString());
              setNextStatus(option as CacheOptions);
              await displayModalChangeStatus(id);
            }}
            options={statusOptions}
            value={stage_id}
          />
        </>
      );
    }

    return <div />;
  }

  function renderModalHeader() {
    if (model === 'legal') {
      const { fantasy_name } = data as KanbanColumnPersonLegal;
      return (
        <>
          <p>
            {t('pages.registered-customers.modal-status-change.description').replace('{person}', fantasy_name)}
            {'  '}
            <strong>{t(`pages.registered-customers.kanban.status.${nextStatus.slug}`)}</strong>
          </p>
          <br />
        </>
      );
    }

    if (model === 'physical') {
      const { name } = data as KanbanColumnPersonPhysical;
      return (
        <>
          <p>
            {t('pages.registered-customers.modal-status-change.description').replace('{person}', name)}
            {'  '}
            <strong>{t(`pages.registered-customers.kanban.status.${nextStatus.slug}`)}</strong>
          </p>
          <br />
        </>
      );
    }

    return <div />;
  }

  function renderModalTitle() {
    if (model === 'legal') {
      return t('pages.registered-customers.modal-status-change.title-pj');
    }

    if (model === 'physical') {
      return t('pages.registered-customers.modal-status-change.title-pf');
    }

    return '';
  }

  return (
    <>
      {data && (
        <Container status="default">
          {renderHeader()}

          <Content>{renderContent()}</Content>

          <Footer status="default">
            <div className="footer-visible-always">{renderFooter()}</div>
            {footerOpen && <div className="footer-hide-data">{renderHideFooter()}</div>}
          </Footer>
        </Container>
      )}

      <Modal
        visible={modalStatusVisible}
        title={renderModalTitle()}
        className="modal-with-custom-footer"
        closeIcon={
          <MdClose
            onClick={() => {
              setModalStatusVisible(false);
              setModalStatusLoading(false);
              formModalStatus.resetFields();
              setUploadList([]);
            }}
          />
        }
        footer={
          <>
            <div className="buttons">
              <Button
                status="secondary"
                onClick={() => {
                  setModalStatusVisible(false);
                  setModalStatusLoading(false);
                  formModalStatus.resetFields();
                  setUploadList([]);
                }}
                disabled={modalStatusLoading}
              >
                {t('pages.registered-customers.kanban.modals.buttons.cancel')}
              </Button>

              <Button
                status="primary"
                onClick={() => {
                  handleChangeStatus();
                }}
                loading={modalStatusLoading}
                disabled={modalStatusLoading}
              >
                {t('pages.registered-customers.kanban.modals.buttons.save')}
              </Button>
            </div>
          </>
        }
      >
        <Spin spinning={modalStatusLoading}>
          {renderModalHeader()}

          <p>{t('pages.registered-customers.modal-status-change.description2')}</p>
          <br />

          <Form form={formModalStatus} name="status-change-form" className="form-secondary">
            <FormItem hidden name="stage_id" initialValue={nextStatus?.value}>
              <Input />
            </FormItem>

            <FormItem name="comments" label={t('pages.registered-customers.modal-status-change.comments')}>
              <Input.TextArea autoSize={{ minRows: 5, maxRows: 10 }} />
            </FormItem>
            <FormItem hidden name="documents">
              <Input />
            </FormItem>

            <>
              <SUpload>
                <Dragger
                  showUploadList
                  multiple
                  action={(file: any) => {
                    getBase64(file, (documentBase64: any) => {
                      const files =
                        formModalStatus.getFieldValue('documents') !== undefined
                          ? formModalStatus.getFieldValue('documents')
                          : [];
                      formModalStatus.setFieldsValue({
                        documents: [
                          ...files,
                          {
                            image: documentBase64,
                            mime: file.type,
                            name: getOriginalName(file.name, true),
                            original: getOriginalName(file.name),
                            label: 'status',
                          },
                        ],
                      });
                    });
                    return file;
                  }}
                  onChange={handleChangeUploadFile}
                  fileList={uploadList}
                  customRequest={() => 'done'}
                >
                  {t('pages.registered-customers.form.pf.documents.dragText')}
                </Dragger>
              </SUpload>
            </>
          </Form>
        </Spin>
      </Modal>
    </>
  );
};

export default memo(Card);

import React from 'react';

export const SapSvgLogo = ({ width }: { width?: number }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 743.36707 368.43945"
      width={width || '30'}
      preserveAspectRatio="xMinYMid meet"
      version="1"
    >
      <g stroke="none" transform="translate(-446.799 535.914)">
        <path fill="#014185" fillRule="nonzero" d="M446.799-168.028h375.32l368.047-367.886H446.799v367.886" />
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M887.582-466.771l-72.737-.015v174.015l-64.008-174.015h-62.553l-55.28 145.532c-5.819-36.66-43.642-49.33-74.191-58.815-18.912-6.372-40.733-15.74-39.278-25.982 0-8.495 10.183-16.293 32.004-15.129 14.548.742 27.64 1.95 53.825 14.431l26.185-44.325c-24.73-11.987-56.734-19.567-82.92-19.596-32.003 0-58.188 10.067-74.19 26.753-10.184 11.609-17.457 26.345-17.457 42.682 0 22.475 8.728 38.419 24.73 51.119 16.002 10.692 33.459 17.66 50.916 22.766 20.366 6.3 36.368 11.755 36.368 23.45 0 4.321-1.455 8.249-4.364 11.406-5.82 5.222-13.093 7.2-24.73 7.448-20.367.378-34.914-2.866-59.644-17.311l-23.276 44.878c24.73 13.907 53.825 22.083 82.92 22.083l4.364-.03c24.73-.465 46.551-7.811 62.553-21.166 1.455-.785 1.455-1.513 2.91-2.24l-7.274 19.537h66.917l10.184-33.866c11.637 3.899 24.73 6.168 39.277 6.168 14.548 0 26.185-2.197 37.823-5.892l11.638 33.59h107.65v-70.249h24.73c56.735 0 90.194-29.109 90.194-77.93 0-54.348-32.004-79.297-103.286-79.297zM718.833-317.792c-8.728 0-16.002-1.513-23.275-4.16l23.275-73.203 23.276 73.435c-7.274 2.502-14.547 3.928-23.276 3.928zm173.113-42.1h-16.002v-59.018h16.002c21.821 0 39.278 7.142 39.278 29.21 0 22.694-17.457 29.808-39.278 29.808"
        />
        <path
          fill="#014185"
          fillRule="nonzero"
          d="M913.767-191.536c0-11.245 8.728-19.813 18.912-19.813 10.183 0 18.911 8.568 18.911 19.813 0 11.667-8.728 20.075-18.911 20.075-10.184 0-18.912-8.408-18.912-20.075zm18.912 24.061c13.092 0 24.73-10.197 24.73-24.061 0-13.66-11.638-23.698-24.73-23.698-13.093 0-24.73 10.038-24.73 23.698 0 13.864 11.637 24.061 24.73 24.061zm-4.365-22.068h4.365l7.273 11.87h4.364l-7.273-12.103c2.91-.494 7.273-2.633 7.273-7.52 0-5.485-4.364-7.9-10.183-7.9H923.95v27.524h4.364v-11.871zm0-3.55v-8.597h5.82c1.454 0 5.818.67 5.818 4.146 0 4.175-4.364 4.451-7.273 4.451h-4.365"
        />
      </g>
    </svg>
  );
};

import { DocumentToCollectData } from 'types/CreditRequest/DocumentToCollectData';
import { StateCreator } from 'zustand';

export type DocumentToCollectFormStateSlice = {
  selectedDocumentToCollectToEdit: DocumentToCollectData | null;
  setSelectedDocumentToCollectToEdit: (selectedDocumentToCollectToEdit: DocumentToCollectData | null) => void;
  documentToCollectAttachmentBeingDeletedId: string | null;
  setDocumentToCollectAttachmentBeingDeletedId: (documentToCollectAttachmentBeingDeletedId: string | null) => void;
  documentToCollectAttachmentBeingDownloadedId: string | null;
  setDocumentToCollectAttachmentBeingDownloadedId: (
    documentToCollectAttachmentBeingDownloadedId: string | null,
  ) => void;
  documentToCollectCommentBeingDeletedId: string | null;
  setDocumentToCollectCommentBeingDeletedId: (documentToCollectCommentBeingDeletedId: string | null) => void;
  resetDocumentToCollectFormState: () => void;
};

export const documentToCollectFormStateSliceInitialState = {
  documentToCollectAttachmentBeingDeletedId: null,
  documentToCollectAttachmentBeingDownloadedId: null,
  documentToCollectCommentBeingDeletedId: null,
  selectedDocumentToCollectToEdit: null,
} as DocumentToCollectFormStateSlice;

export const createDocumentToCollectFormStateSlice: StateCreator<
  DocumentToCollectFormStateSlice,
  [],
  [],
  DocumentToCollectFormStateSlice
> = set => ({
  ...documentToCollectFormStateSliceInitialState,
  setDocumentToCollectAttachmentBeingDeletedId: documentBeingRemovedId =>
    set({ documentToCollectAttachmentBeingDeletedId: documentBeingRemovedId }),
  setDocumentToCollectAttachmentBeingDownloadedId: documentBeingDownloadedId =>
    set({ documentToCollectAttachmentBeingDownloadedId: documentBeingDownloadedId }),
  resetDocumentToCollectFormState: () => set(documentToCollectFormStateSliceInitialState),
  setDocumentToCollectCommentBeingDeletedId: documentToCollectCommentBeingDeletedId =>
    set({ documentToCollectCommentBeingDeletedId }),
  setSelectedDocumentToCollectToEdit: selectedDocumentToCollectToEdit => set({ selectedDocumentToCollectToEdit }),
});

import { useState } from 'react';

import { handleRequest } from '../../services/api/api';
import dashboardApi from '../../services/api/dashboard';

export const useDashboardPrepay = () => {
  const [isFetchingCharts, setIsFetchingCharts] = useState(false);
  const [isFetchingCards, setIsFetchingCards] = useState(false);
  const [isFetchingTimeline, setIsFechingTimeline] = useState(false);

  const getPrepayCards = async (params = {}) => {
    const { summary } = await handleRequest(() => dashboardApi.prepay.showCards(params), setIsFetchingCards);
    return { summary };
  };

  const getPrepayCharts = async (params = {}) => {
    setIsFetchingCharts(true);
    const dataChartOperation = await handleRequest(() => dashboardApi.prepay.showChartPrepayOperation(params));
    const dataChartLimit = await handleRequest(() => dashboardApi.prepay.showChartPrepayLimit(params));
    const dataChartProgress = await handleRequest(() => dashboardApi.prepay.showChartRequestProgress(params));
    const dataChartExposition = await handleRequest(() => dashboardApi.prepay.showChartExposition(params));
    setIsFetchingCharts(false);
    return {
      dataChartOperation,
      dataChartLimit,
      dataChartProgress,
      dataChartExposition,
    };
  };

  const getPrepayTimeline = async (params?: { params: unknown }, filter = 'all') => {
    const response = await handleRequest(
      () => dashboardApi.prepay.showTimeline({ ...params, parent: filter }),
      setIsFechingTimeline,
    );
    return response || { data: [] as any[], total: 0, last_page: 0 };
  };

  const getTimelineReport = async (params: any) => {
    const response = await handleRequest(() => dashboardApi.reports.listReportTimelinePrepay(params));
    return response;
  };

  const generateExportTimeline = (values: any) => {
    return dashboardApi.reports.generateExportTimelinePrepay({ ...values });
  };

  return {
    isFetchingCharts,
    isFetchingCards,
    isFetchingTimeline,
    getTimelineReport,
    generateExportTimeline,
    getPrepayCards,
    getPrepayCharts,
    getPrepayTimeline,
  };
};

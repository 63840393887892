import styled from 'styled-components';

export const LabelWrapper = styled.div`
	display: flex
	gap: 5px;
	align-items: center;
`;

export const StatusTooltipText = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 16px;
`;

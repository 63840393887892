import { ResponseListData, ResponseSingle } from '../../@types/data/ResponseData';
import SustainabilityData from '../../types/Sustainability/SustainabilityData';
import api from './api';

export default {
  reports: {
    generateExport: async (params: object = null): Promise<any> => {
      return api.post(`sustainability/export/all`, params);
    },
    listReport: async (params: object = null): Promise<any> => {
      return api.get(`sustainability/export/all`, params);
    },

    get: async (params = {}): Promise<ResponseListData<SustainabilityData>> => {
      return api.get('sustainability/report', params);
    },

    show: async (id: string, params = {}): Promise<SustainabilityData> => {
      return api.get(`sustainability/report/${id}`, params);
    },

    getAll: async (params = {}): Promise<ResponseListData<SustainabilityData>> => {
      return api.get('sustainability/report/indexAll', params);
    },

    store: async (data: unknown, params = {}): Promise<ResponseSingle<SustainabilityData>> => {
      return api.post('sustainability/report', data, params);
    },
    update: async (id: string, data: unknown, params = {}): Promise<ResponseSingle<SustainabilityData>> => {
      return api.post(`sustainability/report/${id}`, data, params);
    },
    destroy: async (id: string, params = {}): Promise<ResponseSingle<SustainabilityData>> => {
      return api.delete(`sustainability/report/${id}`, params);
    },
    updateStatus: async (sustainability_report_id: string, params: object): Promise<any> => {
      return api.post(`sustainability/report/${sustainability_report_id}`, params);
    },
    getComments: async (sustainability_report_id: string, params: object = null): Promise<any> => {
      return api.get(`sustainability/${sustainability_report_id}/comment`, params);
    },
    comment: async (sustainability_report_id: string, params: object = null): Promise<any> => {
      return api.post(`sustainability/${sustainability_report_id}/comment`, params);
    },
    getStatusHistoryAll: async (sustainability_report_id: string, params: object = null): Promise<any> => {
      return api.get(`sustainability/report/${sustainability_report_id}/history/all`, params);
    },
    getSustainabilityHistory: async (sustainability_report_id: string, params: object = null): Promise<any> => {
      return api.get(`sustainability/report/${sustainability_report_id}/changelog`, params);
    },
    getDocuments: async (id: string, params: object = null): Promise<any> => {
      return api.get(`sustainability/report/${id}/attachment`, params);
    },
  },
  reporttype: {
    get: async (params = {}): Promise<ResponseListData<SustainabilityData>> => {
      return api.get('sustainability/reporttype', params);
    },
  },
  reporttypestatus: {
    get: async (id: string, sustainabilityId: string, params: object = {}): Promise<any> => {
      return api.get(`sustainability/reporttypestatus/${id}/${sustainabilityId}`, params);
    },
  },
  reportstatus: {
    get: async (params: object = null): Promise<any> => {
      return api.get(`sustainability/reporttypestatus`, params);
    },
  },
  reportdecisions: {
    get: async (params: object = null): Promise<any> => {
      return api.get(`sustainability/decisions`, params);
    },
  },
  userrule: {
    get: async (params: object = null): Promise<any> => {
      return api.get(`sustainability/userrule`, params);
    },
    store: async (data: unknown, params = {}): Promise<any> => {
      return api.post('sustainability/userrule', data, params);
    },
    update: async (id: string, data: unknown, params = {}): Promise<any> => {
      return api.put(`sustainability/userrule/${id}`, data, params);
    },
    destroy: async (id: string, params = {}): Promise<any> => {
      return api.delete(`sustainability/userrule/${id}`, params);
    },
  },
  registrations: {
    delete: async (registrationId: string): Promise<any> => {
      return api.delete(`sustainability/registration/${registrationId}`);
    },
  },
  rule: {
    get: async (params: object = null): Promise<any> => {
      return api.get(`sustainability/rule`, params);
    },
    store: async (data: unknown, params = {}): Promise<any> => {
      return api.post('sustainability/rule', data, params);
    },
    update: async (id: string, data: unknown, params = {}): Promise<any> => {
      return api.put(`sustainability/rule/${id}`, data, params);
    },
    destroy: async (id: string): Promise<any> => {
      return api.delete(`sustainability/rule/${id}`);
    },
  },
};

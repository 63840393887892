import PhoneInput from 'react-phone-number-input';

import styled from 'styled-components';

export const SPhoneInput = styled(PhoneInput)`
  border-bottom: 3px solid ${({ theme, disabled }) => (disabled ? theme.colors.greys.grey05 : theme.colors.text)};
  input {
    border: none;
    height: 33px;
    color: ${({ theme, disabled }) => (disabled ? theme.colors.greys.grey05 : theme.colors.primary)};
    background: transparent;
    font-weight: bold;
    font-size: 16px;
  }
`;

import React from 'react';

import { Container } from './styles';

interface EmptyComponentProps {
  description: string;
}

const EmptyComponent: React.FC<EmptyComponentProps> = ({ description }) => {
  return (
    <Container>
      <div>
        <h1>{description}</h1>
      </div>
    </Container>
  );
};

export default EmptyComponent;

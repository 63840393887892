import { Form } from 'antd';
import { TablePaginationConfig } from 'antd/lib/table';

import { useDashboardMonitoringPrefin } from '../../../../hooks/fetch/useDashboardMonitoringPrefin';
import { useDashboards } from '../../hooks/useDashboards';

export const useMonitoringPrefin = () => {
  const {
    getMonitoringPrefinCards,
    getMonitoringPrefinCharts,
    getMonitoringPrefinResume,
    getResumeReport,
    generateExportResume,
    isFetchingCharts,
    isFetchingCards,
    isFetchingResume,
  } = useDashboardMonitoringPrefin();

  const { handleCards, handleCharts, handleChangeTable } = useDashboards();

  const [filterForm] = Form.useForm();
  const [searchFormResume] = Form.useForm();

  const handleCardsMonitoringPrefin = async () => {
    const response = await handleCards(getMonitoringPrefinCards, filterForm);
    return response;
  };

  const handleChartsMonitoringPrefin = async () => {
    const response = await handleCharts(getMonitoringPrefinCharts, filterForm);
    const { dataChartVisitsMonitoring, dataChartVisitsConditionMonitoring, dataChartMap } = response;
    return { dataChartVisitsMonitoring, dataChartVisitsConditionMonitoring, dataChartMap };
  };

  const handleChangeTableResume = async (pagination: TablePaginationConfig, filters: any, sorter: any) => {
    if (Object.keys(filters).length) {
      const response = await handleChangeTable(pagination, filters, sorter, getMonitoringPrefinResume);
      return response;
    }
    return { data: [], total: 0 };
  };

  return {
    handleCardsMonitoringPrefin,
    handleChartsMonitoringPrefin,
    handleChangeTableResume,
    getResumeReport,
    generateExportResume,
    isFetchingCharts,
    isFetchingCards,
    isFetchingResume,
    filterForm,
    searchFormResume,
  };
};

import React from 'react';

import { Tooltip } from 'antd';

import { EllipsisProps } from './types';

const Ellipsis: React.FC<EllipsisProps> = ({ value, size }) => {
  let ellipsedText = value !== undefined ? value.toString() : '';
  size = size === undefined ? 30 : size;
  ellipsedText = ellipsedText.length > size ? `${ellipsedText.substr(0, size)} ...` : ellipsedText;

  return (
    <Tooltip placement="bottom" title={value} overlayClassName="table-tooltip">
      <span>{ellipsedText}</span>
    </Tooltip>
  );
};

export default Ellipsis;

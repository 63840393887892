import React, { useEffect, useState, useMemo } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { FaCaretDown, FaCaretRight, FaMinus, FaPlus } from 'react-icons/fa';
import { GrDrag } from 'react-icons/gr';

import { Col, Input, Radio, Row, Select } from 'antd';
import { RuleObject } from 'antd/lib/form';
import TextArea from 'antd/lib/input/TextArea';
import { safeJsonParse } from 'utils/safeJsonParse';
import { v4 as uuid } from 'uuid';

import { FormItem, Button } from '../../../../components';
import { CONTRACT_TYPE_FIELD_TYPE_COMBO, CONTRACT_TYPE_FIELD_TYPE_TABLE } from '../../../../configs/constants';
import theme from '../../../../styles/theme';
import { DraftModelField } from '../DraftModelField';
import { TableFieldList } from '../TableFieldList';
import { BorderedBox, Header } from './styles';
import { ContractTypeFieldProps } from './types';

const portal = document.createElement('div');
document.body.appendChild(portal);

export const ContractTypeField = ({
  field,
  handleChangeField,
  removeField,
  addNewFieldToTable,
  removeFieldFromTable,
  handleChangeSelectField,
  isChildren = false,
  fieldTypes,
  index,
  updateField,
}: ContractTypeFieldProps) => {
  const { t } = useTranslation();

  const [supportId, setSupportId] = useState('');
  const [childrenId, setChildrenId] = useState('');
  const [isOpen, setIsOpen] = useState(true);

  const fieldIsTable = useMemo(() => field.type_id === CONTRACT_TYPE_FIELD_TYPE_TABLE, [field.type_id]);
  const fieldIsCombo = useMemo(() => field.type_id === CONTRACT_TYPE_FIELD_TYPE_COMBO, [field.type_id]);
  const columnsSmallSize = useMemo(
    () => (!fieldIsTable && !fieldIsCombo && !isChildren ? 6 : 6),
    [isChildren, fieldIsTable, fieldIsCombo],
  );

  const jsonValidator = (getFieldValue: any, fieldName: string) => ({
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    validator(_: RuleObject, value: any) {
      const fieldValue = getFieldValue(fieldName);
      const [err] = safeJsonParse(fieldValue);
      if (!err) return Promise.resolve();

      return Promise.reject(new Error(t('pages.edocuments.contracttypes.form.fields.invalidJSON')));
    },
  });

  useEffect(() => {
    setSupportId(uuid());

    if (isChildren) setChildrenId(uuid());
  }, []);

  return (
    <Draggable draggableId={field.id} key={field.id} index={index}>
      {(provided, snapshot) => {
        const result = (
          <BorderedBox isChildren={isChildren} {...provided.draggableProps} ref={provided.innerRef}>
            <>
              <Header
                onClick={() => setIsOpen(!isOpen)}
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                {...provided.dragHandleProps}
              >
                <h4 style={{ display: 'flex', alignItems: 'center', marginBottom: '0px !important', gap: 5 }}>
                  <span>
                    <GrDrag size={16} />
                  </span>{' '}
                  <span>{field.display !== '' ? field.display.toUpperCase() : 'Novo campo'}</span>{' '}
                  {isOpen ? <FaCaretDown /> : <FaCaretRight />}
                </h4>
                <Button
                  status="primary"
                  onClick={() =>
                    isChildren
                      ? removeFieldFromTable && removeFieldFromTable(field.parent_id, field.id)
                      : removeField && removeField(field.id)
                  }
                >
                  <FaMinus style={{ margin: '0' }} />
                </Button>
              </Header>

              {isOpen ? (
                <>
                  <Row gutter={26}>
                    <Col xs={24} sm={24} md={24} lg={columnsSmallSize} xl={columnsSmallSize} xxl={columnsSmallSize}>
                      <FormItem
                        name={`${field.id}-name-${supportId}-${isChildren ? 'children' : ''}`}
                        label={t('pages.edocuments.contracttype.form.fields.name')}
                        rules={[{ required: true }]}
                        initialValue={field.name}
                      >
                        <Input
                          maxLength={255}
                          onChange={evt => handleChangeField('name', evt, field.id, isChildren ? field.parent_id : '')}
                        />
                      </FormItem>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={columnsSmallSize} xl={columnsSmallSize} xxl={columnsSmallSize}>
                      <FormItem
                        name={`${field.id}-display-${supportId}-${isChildren ? 'children' : ''}`}
                        label={
                          isChildren
                            ? t('pages.edocuments.contracttypes.form.fields.displayColumn')
                            : t('pages.edocuments.contracttypes.form.fields.displayField')
                        }
                        rules={[{ required: true }]}
                        initialValue={field.display}
                      >
                        <Input
                          maxLength={255}
                          onChange={evt =>
                            handleChangeField('display', evt, field.id, isChildren ? field.parent_id : '')
                          }
                        />
                      </FormItem>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={columnsSmallSize} xl={columnsSmallSize} xxl={columnsSmallSize}>
                      <FormItem
                        name={`${field.id}-type-${supportId}-${isChildren ? 'children' : ''}`}
                        label={t('pages.edocuments.contracttypes.form.fields.type')}
                        rules={[{ required: true }]}
                        initialValue={field.type_id}
                      >
                        <Select
                          options={fieldTypes}
                          showSearch
                          listHeight={212}
                          filterOption={(input, option) =>
                            option?.label.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                          onChange={value => {
                            handleChangeSelectField('type_id', value, field.id, isChildren ? field.parent_id : '');
                          }}
                        />
                      </FormItem>
                    </Col>

                    {!fieldIsTable && (
                      <>
                        {!fieldIsCombo && (
                          <Col
                            xs={24}
                            sm={24}
                            md={24}
                            lg={columnsSmallSize}
                            xl={columnsSmallSize}
                            xxl={columnsSmallSize}
                          >
                            <DraftModelField
                              key={field.name}
                              field={{ ...field, is_required: false }}
                              customName={`${field.id}-defaultValue-${supportId}-${isChildren ? 'children' : ''}`}
                              customLabel={t('pages.edocuments.contracttypes.form.fields.defaultValue')}
                              initialValue={field.default_value ?? undefined}
                              onChange={value =>
                                handleChangeSelectField(
                                  'default_value',
                                  value,
                                  field.id,
                                  isChildren ? field.parent_id : '',
                                )
                              }
                            />
                          </Col>
                        )}
                        <Col xs={24} sm={24} md={24} lg={columnsSmallSize} xl={columnsSmallSize} xxl={columnsSmallSize}>
                          <FormItem
                            name={`${field.id}-isRequired-${supportId}-${isChildren ? 'children' : ''}`}
                            label={t('pages.edocuments.contracttypes.form.fields.required')}
                            rules={[{ required: true }]}
                            initialValue={field.is_required}
                          >
                            <Radio.Group
                              buttonStyle="solid"
                              onChange={evt =>
                                handleChangeField('is_required', evt, field.id, isChildren ? field.parent_id : '')
                              }
                            >
                              <Radio.Button value>{t('pages.edocuments.contracttypes.form.yes')}</Radio.Button>
                              <Radio.Button value={false}>{t('pages.edocuments.contracttypes.form.no')}</Radio.Button>
                            </Radio.Group>
                          </FormItem>
                        </Col>
                      </>
                    )}
                  </Row>

                  {field.type_id === CONTRACT_TYPE_FIELD_TYPE_COMBO && (
                    <Row style={{ paddingLeft: '30px' }}>
                      <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                        <FormItem
                          name={`${field.id}-json-${supportId}-${isChildren ? `children-${childrenId}` : ''}`}
                          label={t('pages.edocuments.contracttypes.form.fields.combo')}
                          rules={[
                            { required: true },
                            ({ getFieldValue }) =>
                              jsonValidator(
                                getFieldValue,
                                `${field.id}-json-${supportId}-${isChildren ? `children-${childrenId}` : ''}`,
                              ),
                          ]}
                          initialValue={field.json_data}
                        >
                          <TextArea
                            onChange={evt =>
                              handleChangeField('json_data', evt, field.id, isChildren ? field.parent_id : '')
                            }
                          />
                        </FormItem>
                      </Col>
                    </Row>
                  )}

                  {fieldIsTable && !isChildren && (
                    <TableFieldList
                      field={field}
                      fieldTypes={fieldTypes}
                      handleChangeField={handleChangeField}
                      handleChangeSelectField={handleChangeSelectField}
                      addNewFieldToTable={addNewFieldToTable}
                      removeFieldFromTable={removeFieldFromTable}
                      updateField={updateField}
                    />
                  )}

                  {fieldIsTable && !isChildren && (
                    <Row style={{ paddingLeft: '30px' }}>
                      <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                        <Button
                          status="primary"
                          onClick={() => addNewFieldToTable(field.id)}
                          icon={<FaPlus style={{ margin: '0' }} />}
                          style={{ background: theme.colors.white, color: theme.colors.primary, width: '100%' }}
                        >
                          {t('pages.edocuments.contracttypes.form.addNewColumn')}
                        </Button>
                      </Col>
                    </Row>
                  )}
                </>
              ) : null}
            </>
          </BorderedBox>
        );

        if (snapshot.isDragging) {
          return ReactDOM.createPortal(result, portal);
        }

        return result;
      }}
    </Draggable>
  );
};

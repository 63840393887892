import React from 'react';
import { useTranslation } from 'react-i18next';

import { Dialog, DialogContent, DialogFooterForm, DialogHeader, DialogTrigger } from 'components/ui/dialog';

import { CommentsForm, CommentsFormRef } from './comment-form';

type Props = {
  children: React.ReactNode;
};

export const CommentsModal = ({ children }: Props) => {
  const { t } = useTranslation();

  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const formRef = React.useRef<CommentsFormRef>(null);

  return (
    <Dialog onOpenChange={setIsModalOpen} open={isModalOpen}>
      <DialogTrigger className="w-full" tabIndex={-1}>
        {children}
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          {t('pages.credit-request.formalization-guarantees.pre-registration-docs.modalDocument.add-comments')}
        </DialogHeader>
        <CommentsForm ref={formRef} />
        <DialogFooterForm
          onConfirm={() => {
            formRef.current?.submit();
            setIsModalOpen(false);
          }}
          onCancel={() => {
            setIsModalOpen(false);
            formRef.current?.form.reset();
            // setIsPackageModalOpen(false);
            // formRef.current?.form.reset();
          }}
        />
      </DialogContent>
    </Dialog>
  );
};

import React from 'react';
import { AiOutlineLike } from 'react-icons/ai';

import { Modal } from 'antd';

import { Button } from '../../../../components';
import theme from '../../../../styles/theme';
import { Container } from './styles';

interface Props {
  isOpen: boolean;
  onOk: () => void;
  close: () => void;
}

export const ConfirmSignaturesModal = ({ isOpen, close, onOk }: Props) => {
  return (
    <Modal visible={isOpen} onCancel={close} title="Validar Assinaturas" footer={null}>
      <Container>
        <AiOutlineLike size={60} color={theme.colors.success} />
        <p>Gostaria realmente de validar o contrato assinado?</p>

        <Button status="success" onClick={onOk}>
          Validar
        </Button>

        <a onClick={close}>Voltar</a>
      </Container>
    </Modal>
  );
};

import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${({ theme: { colors } }) => colors.white};
  border: 1px solid ${({ theme: { colors } }) => colors.greys.grey01};
  border-radius: 10px;
  box-shadow: 0 0 1px ${({ theme: { colors } }) => colors.greys.grey01};
  padding: 5px 10px;

  label {
    display: flex;
    align-items: center;
    gap: 2px;
    cursor: pointer;
  }

  .ant-radio-wrapper {
    display: flex;
    align-items: start;
  }
`;

export const DraftFormatName = styled.span<{ isShortText: boolean }>`
  color: ${({ theme: { colors } }) => colors.primary};
  font-size: 12px;
  font-weight: bold;
  /* max-width: ${({ isShortText }) => (isShortText ? '120px' : '220px')}; */
  white-space: wrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

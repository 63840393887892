import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { usePage } from 'hooks/page';
import api from 'services/api/api';
import { ProviderData } from 'types/CreditRequest/ProviderData';

type Props = {
  cnpj?: string;
  cpf?: string;
  sapFirst?: boolean;
};

export const useGetDatasulSapProviderMutation = () => {
  const { alertStatus } = usePage();

  return useMutation({
    mutationKey: ['getDatasulSapProvider'],
    mutationFn: async ({ cnpj, cpf, sapFirst }: Props) => {
      if (!cnpj && !cpf) return null;
      const url = sapFirst ? 'datasul-sap/provider' : 'datasul/provider';
      const response = await api.get<ProviderData>(url, {
        params: {
          search: cnpj || cpf,
        },
      });

      if (response.status !== 200) throw new Error('Error fetching provider');

      return response.data;
    },
    onError: err => {
      if ((err as AxiosError).response.data)
        return alertStatus(
          {
            message: (err as AxiosError).response.data?.toString(),
          },
          'error',
        );
      alertStatus(err.message, 'error');
    },
  });
};

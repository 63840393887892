import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useQueryClient } from '@tanstack/react-query';
import { ShouldRender } from 'components/ShouldRender';
import { FormItem } from 'components/ui/form-item';
import { GroupForm } from 'components/ui/group-form';
import { Upload } from 'components/ui/upload';
import { RecipientType, Teams } from 'configs/constants';
import { useAbility } from 'hooks/ability';
import { useAuth } from 'hooks/auth';
import { DraftSubscriberFormData } from 'types/CreditRequest/forms/DraftSubscriberFormData';

import { EnvelopeStatusSelect } from '../envelope-status.select';
import { AttachmentFormTable } from './attachment-form.table';
import { DraftRadioGroup } from './draft-radio-group';
import { EMPTY_SUBSCRIBER } from './draft.form';

type PeopleValues = 's' | 'b' | 'c';

export const DraftGeneralDataSection = () => {
  const { t } = useTranslation();
  const { isUserOnTeam, user } = useAuth();
  const form = useFormContext();
  const queryClient = useQueryClient();
  const ability = useAbility();

  const order = form.watch('order');
  const id = form.watch('id');
  const isDigital = Boolean(Number(form.watch('is_digital')));
  const externalEnvelopeId = form.watch('external_envelope_id');

  const me = {
    id: '',
    value: '',
    name: user.name,
    email: user.email,
    recipient_type_id: RecipientType.SUBSCRIBER,
    dt_sign: '',
    signature_type_id: '',
    is_resend: false,
    sms: '',
  };

  const onChangePeople = (value: PeopleValues) => {
    switch (value) {
      case 's':
        return form.reset({
          ...form.getValues(),
          subscribers: [
            {
              ...me,
              routing_order: 0,
            },
          ],
        });
      case 'b':
        return form.reset({
          ...form.getValues(),
          subscribers: [
            {
              ...me,
              routing_order: 0,
            },
            {
              ...EMPTY_SUBSCRIBER,
              routing_order: 1,
            },
          ],
        });
      case 'c':
        return form.reset({
          ...form.getValues(),
          subscribers: [
            {
              ...EMPTY_SUBSCRIBER,
              routing_order: 0,
            },
          ],
        });
      default:
        break;
    }
  };

  return (
    <GroupForm title="Informações do Rascunho">
      <div>
        <ShouldRender condition={!((isDigital && order < 4) || !id)}>
          <FormItem
            name="status_id"
            className="w-full"
            label={t('pages.credit-request.formalization-guarantees.pre-registration-docs.modalDraft.status')}
            rules={{ required: !!(isDigital && id !== undefined) }}
          >
            <EnvelopeStatusSelect
              className="w-full"
              disabled={!isUserOnTeam(Teams.PREFIN) && !isUserOnTeam(Teams.DOCUMENTS)}
            />
          </FormItem>
        </ShouldRender>

        <FormItem
          name="is_digital"
          label={t('pages.credit-request.formalization-guarantees.pre-registration-docs.modalDraft.is_digital')}
          rules={{ required: true }}
          defaultValue={Number(!!(isUserOnTeam(Teams.DOCUMENTS) || isUserOnTeam(Teams.PREFIN))).toString()}
        >
          <DraftRadioGroup
            disabled={!!id}
            options={[
              { value: '1', label: 'Digital' },
              { value: '0', label: 'Manual' },
            ]}
            onValueChange={() => {
              form.reset({
                ...form.getValues(),
                subscribers: form.getValues().subscribers.map((subscriber: DraftSubscriberFormData) => ({
                  ...subscriber,
                  signature_type_id: '',
                  dt_sign: '',
                  sms: '',
                })),
              });
            }}
          />
        </FormItem>

        <FormItem name="people">
          <DraftRadioGroup
            disabled={!!(!id && isDigital && !!externalEnvelopeId)}
            onValueChange={onChangePeople}
            options={[
              {
                value: 's',
                label: t('pages.credit-request.formalization-guarantees.pre-registration-docs.modalDraft.justme'),
              },
              {
                value: 'b',
                label: t('pages.credit-request.formalization-guarantees.pre-registration-docs.modalDraft.meandothers'),
              },
              {
                value: 'c',
                label: t('pages.credit-request.formalization-guarantees.pre-registration-docs.modalDraft.justothers'),
              },
            ]}
          />
        </FormItem>
      </div>

      <div className="flex gap-4 flex-col md:flex-row h-fit">
        <div className="w-full flex flex-col gap-4 h-fit">
          <FormItem name="files_to_sign">
            <Upload
              permissions={['credit.request.envelope.upload']}
              showUploadList
              description="Subir minuta a ser assinada"
              classNames={{ container: 'bg-primary-100' }}
              fieldsConfig={{
                type: true,
              }}
            />
          </FormItem>
          <AttachmentFormTable
            columnsConfig={{
              type: true,
            }}
            hideDownloadAll
            enableWatermark
            hideDownloadNotDownloaded
            formFieldName="files_to_sign_documents"
            delete={{
              isVisible: isDigital
                ? ability.can('credit.request.envelope.remove_digital_draft_doc', '')
                : ability.can('credit.request.envelope.remove_manual_draft_doc', ''),
              callback: () =>
                queryClient.invalidateQueries({
                  queryKey: ['drafts'],
                }),
            }}
            download={{
              isVisible:
                (isDigital && ability.can('credit.request.envelope.digital_download', '')) ||
                (!isDigital && ability.can('credit.request.envelope.manual_download', '')),
            }}
            preview={{
              isVisible:
                (!isDigital && ability.can('credit.request.envelope.manual_preview', '')) ||
                (isDigital && ability.can('credit.request.envelope.digital_preview', '')),
            }}
            previewWatermark={{
              isVisible:
                (!isDigital && ability.can('credit.request.envelope.manual_preview_draft', '')) ||
                (isDigital && ability.can('credit.request.envelope.digital_preview_draft', '')),
            }}
            downloadWatermark={{
              isVisible:
                (isDigital && ability.can('credit.request.envelope.digital_download_draft', '')) ||
                (!isDigital && ability.can('credit.request.envelope.manual_download_draft', '')),
            }}
          />
        </div>
        <div className="w-full flex flex-col gap-4">
          <FormItem name="files_signed">
            <Upload
              showUploadList
              description="Subir minuta assinada"
              classNames={{ container: 'bg-blue-100' }}
              permissions={['credit.request.envelope.upload']}
            />
          </FormItem>
          <AttachmentFormTable
            formFieldName="files_signed_documents"
            delete={{
              isVisible: isDigital
                ? ability.can('credit.request.envelope.remove_digital_signed_doc', '')
                : ability.can('credit.request.envelope.remove_manual_signed_doc', ''),
              callback: () => queryClient.invalidateQueries({ queryKey: ['drafts'] }),
            }}
            hideDownloadAll
            hideDownloadNotDownloaded
            enableWatermark
            download={{
              isVisible:
                (isDigital && ability.can('credit.request.envelope.digital_download', '')) ||
                (!isDigital && ability.can('credit.request.envelope.manual_download', '')),
            }}
            preview={{
              isVisible:
                (!isDigital && ability.can('credit.request.envelope.manual_preview', '')) ||
                (isDigital && ability.can('credit.request.envelope.digital_preview', '')),
            }}
            previewWatermark={{
              isVisible:
                (!isDigital && ability.can('credit.request.envelope.manual_preview_draft', '')) ||
                (isDigital && ability.can('credit.request.envelope.digital_preview_draft', '')),
            }}
            downloadWatermark={{
              isVisible:
                (isDigital && ability.can('credit.request.envelope.digital_download_draft', '')) ||
                (!isDigital && ability.can('credit.request.envelope.manual_download_draft', '')),
            }}
          />
        </div>
      </div>
    </GroupForm>
  );
};
